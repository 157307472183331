<ng-container *ngIf="buttonType$ | async as buttonType">
  <ng-container *ngIf="buttonType === 'basic'">
    <div class="basic">
      <button
        mat-button
        [ngClass]="{ active: isActive$ | async }"
        [disabled]="isDisabled$ | async"
        (click)="runCommand()"
        [matTooltip]="
          tooltip +
          (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
        "
        matTooltipShowDelay="300"
      >
        <mat-icon>{{ icon }}</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="buttonType === 'submenu'">
    <div class="submenu">
      <ng-container *ngIf="compact; else detail">
        <button
          mat-button
          [matTooltip]="
            tooltip +
            (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
          "
          matTooltipShowDelay="300"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>{{ icon }}</mat-icon>
        </button>
      </ng-container>

      <ng-template #detail>
        <button
          mat-menu-item
          class="flex"
          matTooltipShowDelay="300"
          matTooltipPosition="after"
          [matTooltip]="
            tooltip +
            (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
          "
          [matMenuTriggerFor]="menu"
        >
          <div class="flex items-center p-1">
            <mat-icon>{{ icon }}</mat-icon>
            <span class="min-w-0 truncate">{{ buttonText }}</span>
          </div>
        </button>
      </ng-template>

      <mat-menu #menu="matMenu" class="submenu-panel">
        <pt-submenu-bar
          [menuItems]="submenuItems$ | async"
          [editor]="editor$ | async"
        />
      </mat-menu>
    </div>
  </ng-container>

  <ng-container *ngIf="buttonType === 'submenu-detail'">
    <div class="submenu-detail">
      <button
        fxFill
        mat-menu-item
        [ngClass]="{ active: isActive$ | async }"
        [disabled]="isDisabled$ | async"
        (click)="runCommand()"
        [matTooltip]="
          tooltip +
          (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
        "
        matTooltipShowDelay="300"
        matTooltipPosition="after"
        [matTooltipDisabled]="textOnly"
      >
        <div class="flex items-center p-1">
          <mat-icon *ngIf="!textOnly">{{ icon }}</mat-icon>
          <span>{{ buttonText }}</span>
          <span fxFlex></span>
          <span class="shortcut" *ngIf="shortcut">{{
            shortcut | translateForPlatform
          }}</span>
        </div>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="buttonType === 'block'">
    <div class="block-button">
      <button
        fxFill
        mat-button
        [ngClass]="{ active: isActive$ | async, disabled: isDisabled$ | async }"
        (click)="runCommand()"
      >
        <div class="flex items-center p-1">
          <div class="icon">
            <mat-icon>{{ icon }}</mat-icon>
          </div>
          <span>{{ buttonText }}</span>
          <span fxFlex></span>
          <span class="shortcut" *ngIf="shortcut">{{
            shortcut | translateForPlatform
          }}</span>
        </div>
      </button>
    </div>
  </ng-container>
</ng-container>
