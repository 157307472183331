import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  type IChartedItem,
  type IClinicalNote,
} from '@principle-theorem/principle-core/interfaces';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { CALENDAR_TIME_FORMAT, snapshot } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { type VersionedSchema } from '@principle-theorem/editor';
import {
  ChartedItemNotesDialogComponent,
  type IChartedItemNotesDialogData,
} from '../charted-items-display/charted-item-notes-dialog/charted-item-notes-dialog.component';

@Component({
  selector: 'pr-charted-item-notes-list',
  templateUrl: './charted-item-notes-list.component.html',
  styleUrls: ['./charted-item-notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedItemNotesListComponent {
  readonly calendarFormat = CALENDAR_TIME_FORMAT;
  trackByNote = TrackByFunctions.uniqueId<IClinicalNote>();
  @Output() itemChange = new EventEmitter<IChartedItem>();
  item$ = new ReplaySubject<IChartedItem>(1);
  notes$: Observable<IClinicalNote[]>;

  constructor(private _dialog: MatDialog) {
    this.notes$ = this.item$.pipe(map((item) => item.notes));
  }

  @Input()
  set item(item: IChartedItem) {
    if (item) {
      this.item$.next(item);
    }
  }

  async edit(note: IClinicalNote): Promise<void> {
    const data = { content: note.content };
    const content = await this._dialog
      .open<
        ChartedItemNotesDialogComponent,
        IChartedItemNotesDialogData,
        VersionedSchema
      >(ChartedItemNotesDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
    if (!content) {
      return;
    }
    const item = await snapshot(this.item$);
    this.itemChange.emit({
      ...item,
      notes: item.notes.map((clinicalNote) => {
        if (clinicalNote.uid === note.uid) {
          clinicalNote.content = content;
        }
        return clinicalNote;
      }),
    });
  }

  async delete(note: IClinicalNote): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Note',
      prompt: 'Are you sure you want to delete this note?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    const item = await snapshot(this.item$);
    this.itemChange.emit({
      ...item,
      notes: item.notes.filter((clinicalNote) => clinicalNote.uid !== note.uid),
    });
  }
}
