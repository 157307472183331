import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { type MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { type MatSelectChange } from '@angular/material/select';
import {
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { RecurrencePattern } from '@principle-theorem/principle-core';
import {
  TASK_PRIORITIES,
  type IRecurringTaskConfiguration,
  type IStaffer,
  type ITeam,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAYS_OF_WEEK,
  DayOfWeek,
  FREQUENCIES,
  Frequency,
  filterUndefined,
  snapshot,
  toISODate,
  toMoment,
  type IRecurrencePattern,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TaskManager } from '../../task-manager';
import { RecurrenceDialogComponent } from '../recurrence-dialog/recurrence-dialog.component';
import { RecurringTaskConfigurationForm } from './recurring-task-configuration-form';

@Component({
  selector: 'pr-recurring-task-configuration-form',
  exportAs: 'prRecurringTaskConfigurationFormComponent',
  templateUrl: './recurring-task-configuration-form.component.html',
  styleUrls: ['./recurring-task-configuration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class RecurringTaskConfigurationFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByFrequency = TrackByFunctions.variable<Frequency>();
  trackByPriority = TrackByFunctions.variable<TaskPriority>();
  configuration$ = new BehaviorSubject<
    WithRef<IRecurringTaskConfiguration> | undefined
  >(undefined);
  @Output()
  submitted = new EventEmitter<RecurringTaskConfigurationForm>();
  taskPriorities: TaskPriority[] = TASK_PRIORITIES;
  frequencies: Frequency[] = FREQUENCIES;
  hasCustomFrequency$: Observable<boolean>;
  showDaysOfWeek$ = new BehaviorSubject<boolean>(false);
  configurationFormGroup = new RecurringTaskConfigurationForm();
  startDateCtrl = new TypedFormControl<Moment>();
  daysOfWeek = DAYS_OF_WEEK;
  daysOfWeekCtrl = new TypedFormControl<DayOfWeek[]>(this.daysOfWeek);

  get assigneeFormControl(): TypedFormControl<
    WithRef<IStaffer> | WithRef<ITeam>
  > {
    return this.configurationFormGroup.controls.assignee as TypedFormControl<
      WithRef<IStaffer> | WithRef<ITeam>
    >;
  }

  constructor(
    public dateService: DateService,
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {
    this.configuration$
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe((configuration) => {
        if (configuration.recurrencePattern.startDate) {
          this.startDateCtrl.setValue(
            toMoment(configuration.recurrencePattern.startDate)
          );
        }
      });

    this.configuration$
      .pipe(
        filterUndefined(),
        switchMap((configuration) =>
          this._organisation.staffer$.pipe(filterUndefined()).pipe(
            switchMap((staffer) => {
              const taskManager: TaskManager = new TaskManager(staffer);
              return taskManager.convertConfigurationToConfigurationForm(
                configuration
              );
            })
          )
        ),
        tap(() => this.configurationFormGroup.enable()),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((configurationFormData) => {
        this.configurationFormGroup.patchValue(configurationFormData);
      });

    this.hasCustomFrequency$ = this.configuration$.pipe(
      map(
        (configuration) =>
          configuration?.recurrencePattern.frequencyType === Frequency.Custom
      )
    );

    formControlChanges$(this.configurationFormGroup.controls.recurringFrequency)
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((frequency) => {
        const isDaily = frequency === Frequency.Daily;
        this.showDaysOfWeek$.next(isDaily);

        if (!isDaily) {
          this.daysOfWeekCtrl.setValue(this.daysOfWeek);
        }
      });

    formControlChanges$(this.daysOfWeekCtrl)
      .pipe(
        filter((days) => !days?.length),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.daysOfWeekCtrl.setValue(this.daysOfWeek));
  }

  @Input()
  set configuration(configuration: WithRef<IRecurringTaskConfiguration>) {
    if (configuration) {
      this.configuration$.next(configuration);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isSelectedFrequency(
    frequency: Frequency,
    selectedFrequency?: Frequency
  ): boolean {
    if (!selectedFrequency) {
      return false;
    }
    return frequency === selectedFrequency;
  }

  async editCustomRecurrence($event?: MouseEvent): Promise<void> {
    $event?.stopPropagation();
    const configuration = await snapshot(this.configuration$);

    const pattern = await this._dialog
      .open<
        RecurrenceDialogComponent,
        Partial<IRecurrencePattern>,
        IRecurrencePattern
      >(
        RecurrenceDialogComponent,
        DialogPresets.medium({
          data: configuration?.recurrencePattern ?? {},
          autoFocus: true,
        })
      )
      .afterClosed()
      .toPromise();

    if (!pattern) {
      return;
    }
    this.configurationFormGroup.controls.recurrencePattern.setValue(pattern);
  }

  /**
   * Set recurrence value, open custom dialog if requested
   * @param $event MatSelectChange
   * @returns void
   */
  async setRecurrence($event: MatSelectChange): Promise<void> {
    if ($event.value !== Frequency.Custom) {
      this.configurationFormGroup.controls.recurrencePattern.setValue(
        RecurrencePattern.init({
          frequencyType: $event.value as Frequency,
        })
      );
      return;
    }

    await this.editCustomRecurrence();
  }

  setStartDate(date: MatDatepickerInputEvent<Moment>): void {
    if (date.value) {
      this.configurationFormGroup.controls.recurrencePattern.setValue(
        RecurrencePattern.init({
          ...this.configurationFormGroup.value.recurrencePattern,
          startDate: toISODate(date.value),
        })
      );
    }
  }

  setDaysOfWeek(daysOfWeek: DayOfWeek[]): void {
    this.configurationFormGroup.controls.recurrencePattern.setValue(
      RecurrencePattern.init({
        ...this.configurationFormGroup.value.recurrencePattern,
        daysOfWeek,
      })
    );
  }

  clearStartDate(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.configurationFormGroup.controls.recurrencePattern.setValue(
      RecurrencePattern.init({
        startDate: undefined,
      })
    );
    this.startDateCtrl.reset();
  }

  submit(): void {
    this.submitted.emit(this.configurationFormGroup);
  }
}
