<ng-container *ngIf="unit$ | ngrxPush as unit">
  <div class="calendar-container" fxFill>
    <div class="calendar" fxLayout="column">
      <pr-calendar-toolbar />
      <mat-progress-bar *ngIf="loading$ | ngrxPush" mode="indeterminate" />
      <div [ngSwitch]="unit">
        <div class="month-view" *ngSwitchCase="'month'">
          <pr-month-view [range]="range$ | ngrxPush" />
        </div>
        <div class="day-view" *ngSwitchCase="'day'">
          <pr-week-view [unit]="unit" [range]="range$ | ngrxPush" />
        </div>
        <div class="week-view" *ngSwitchDefault>
          <pr-week-view [unit]="unit" [range]="range$ | ngrxPush" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
