export interface IDisplayColour {
  value: string;
  name: string;
}

export enum IconColours {
  Red = 'red',
  Pink = 'pink',
  Purple = 'purple',
  DeepPurple = 'deepPurple',
  Indigo = 'indigo',
  Blue = 'blue',
  LightBlue = 'lightBlue',
  Cyan = 'cyan',
  Teal = 'teal',
  Green = 'green',
  LightGreen = 'lightGreen',
  Lime = 'lime',
  Yellow = 'yellow',
  Amber = 'amber',
  Orange = 'orange',
  DeepOrange = 'deepOrange',
  Brown = 'brown',
  Grey = 'grey',
  BlueGrey = 'blueGrey',
}

export enum IconColourShades {
  A100 = 'a100',
  A200 = 'a200',
  A400 = 'a400',
  A700 = 'a700',
}

export type ColourShadingMap = {
  [shadingKey in IconColourShades]: string;
};

export interface IColourShading {
  label: string;
  shades: ColourShadingMap;
}

export class RedColourShading implements IColourShading {
  label: string = 'Red';
  shades: ColourShadingMap = {
    a100: '#ff8a80',
    a200: '#ff5252',
    a400: '#ff1744',
    a700: '#d50000',
  };
}

export class PinkColourShading implements IColourShading {
  label: string = 'Pink';
  shades: ColourShadingMap = {
    a100: '#ff80ab',
    a200: '#ff4081',
    a400: '#f50057',
    a700: '#c51162',
  };
}

export class PurpleColourShading implements IColourShading {
  label: string = 'Purple';
  shades: ColourShadingMap = {
    a100: '#ea80fc',
    a200: '#e040fb',
    a400: '#d500f9',
    a700: '#aa00ff',
  };
}

export class DeepPurpleColourShading implements IColourShading {
  label: string = 'Deep Purple';
  shades: ColourShadingMap = {
    a100: '#b388ff',
    a200: '#7c4dff',
    a400: '#651fff',
    a700: '#6200ea',
  };
}

export class IndigoColourShading implements IColourShading {
  label: string = 'Indigo';
  shades: ColourShadingMap = {
    a100: '#8c9eff',
    a200: '#536dfe',
    a400: '#3d5afe',
    a700: '#304ffe',
  };
}

export class BlueColourShading implements IColourShading {
  label: string = 'Blue';
  shades: ColourShadingMap = {
    a100: '#82b1ff',
    a200: '#448aff',
    a400: '#2979ff',
    a700: '#2962ff',
  };
}

export class LightBlueColourShading implements IColourShading {
  label: string = 'Light Blue';
  shades: ColourShadingMap = {
    a100: '#80d8ff',
    a200: '#40c4ff',
    a400: '#00b0ff',
    a700: '#0091ea',
  };
}

export class CyanColourShading implements IColourShading {
  label: string = 'Cyan';
  shades: ColourShadingMap = {
    a100: '#84ffff',
    a200: '#18ffff',
    a400: '#00e5ff',
    a700: '#00b8d4',
  };
}

export class TealColourShading implements IColourShading {
  label: string = 'Teal';
  shades: ColourShadingMap = {
    a100: '#a7ffeb',
    a200: '#64ffda',
    a400: '#1de9b6',
    a700: '#00bfa5',
  };
}

export class GreenColourShading implements IColourShading {
  label: string = 'Green';
  shades: ColourShadingMap = {
    a100: '#b9f6ca',
    a200: '#69f0ae',
    a400: '#00e676',
    a700: '#00c853',
  };
}

export class LightGreenColourShading implements IColourShading {
  label: string = 'Light Green';
  shades: ColourShadingMap = {
    a100: '#ccff90',
    a200: '#b2ff59',
    a400: '#76ff03',
    a700: '#64dd17',
  };
}

export class LimeColourShading implements IColourShading {
  label: string = 'Lime';
  shades: ColourShadingMap = {
    a100: '#f4ff81',
    a200: '#eeff41',
    a400: '#c6ff00',
    a700: '#aeea00',
  };
}

export class YellowColourShading implements IColourShading {
  label: string = 'Yellow';
  shades: ColourShadingMap = {
    a100: '#ffff8d',
    a200: '#ffff00',
    a400: '#ffea00',
    a700: '#ffd600',
  };
}

export class AmberColourShading implements IColourShading {
  label: string = 'Amber';
  shades: ColourShadingMap = {
    a100: '#ffe57f',
    a200: '#ffd740',
    a400: '#ffc400',
    a700: '#ffab00',
  };
}

export class OrangeColourShading implements IColourShading {
  label: string = 'Orange';
  shades: ColourShadingMap = {
    a100: '#ffd180',
    a200: '#ffab40',
    a400: '#ff9100',
    a700: '#ff6d00',
  };
}

export class DeepOrangeColourShading implements IColourShading {
  label: string = 'Deep Orange';
  shades: ColourShadingMap = {
    a100: '#ff9e80',
    a200: '#ff6e40',
    a400: '#ff3d00',
    a700: '#dd2c00',
  };
}

export class BrownColourShading implements IColourShading {
  label: string = 'Brown';
  shades: ColourShadingMap = {
    a100: '#d7ccc8',
    a200: '#bcaaa4',
    a400: '#8d6e63',
    a700: '#5d4037',
  };
}

export class GreyColourShading implements IColourShading {
  label: string = 'Grey';
  shades: ColourShadingMap = {
    a100: '#ffffff',
    a200: '#eeeeee',
    a400: '#bdbdbd',
    a700: '#616161',
  };
}

export class BlueGreyColourShading implements IColourShading {
  label: string = 'Blue Grey';
  shades: ColourShadingMap = {
    a100: '#cfd8dc',
    a200: '#b0bec5',
    a400: '#78909c',
    a700: '#455a64',
  };
}

export const ICON_COLOUR_PALETTE: {
  [colourKey in IconColours]: IColourShading;
} = {
  [IconColours.Red]: new RedColourShading(),
  [IconColours.Pink]: new PinkColourShading(),
  [IconColours.Purple]: new PurpleColourShading(),
  [IconColours.DeepPurple]: new DeepPurpleColourShading(),
  [IconColours.Indigo]: new IndigoColourShading(),
  [IconColours.Blue]: new BlueColourShading(),
  [IconColours.LightBlue]: new LightBlueColourShading(),
  [IconColours.Cyan]: new CyanColourShading(),
  [IconColours.Teal]: new TealColourShading(),
  [IconColours.Green]: new GreenColourShading(),
  [IconColours.LightGreen]: new LightGreenColourShading(),
  [IconColours.Lime]: new LimeColourShading(),
  [IconColours.Yellow]: new YellowColourShading(),
  [IconColours.Amber]: new AmberColourShading(),
  [IconColours.Orange]: new OrangeColourShading(),
  [IconColours.DeepOrange]: new DeepOrangeColourShading(),
  [IconColours.Brown]: new BrownColourShading(),
  [IconColours.Grey]: new GreyColourShading(),
  [IconColours.BlueGrey]: new BlueGreyColourShading(),
};

export const CHARTING_COLOURS: IDisplayColour[] = Object.entries(
  ICON_COLOUR_PALETTE
)
  .map(([_key, colourPalette]: [string, IColourShading]) => {
    return Object.entries(colourPalette.shades).map(
      ([shade, value]: [string, string]) => {
        return {
          name: `${colourPalette.label} ${shade}`,
          value,
        };
      }
    );
  })
  .reduce((colours: IDisplayColour[], currentPalette: IDisplayColour[]) => {
    return [...colours, ...currentPalette];
  }, []);

export const COLOUR_PALLETTE: string[] = Object.entries(ICON_COLOUR_PALETTE)
  .map(([_key, colourPalette]: [string, IColourShading]) => {
    return Object.entries(colourPalette.shades).map(
      ([_shade, value]: [string, string]) => {
        return value;
      }
    );
  })
  .reduce((colours: string[], currentPalette: string[]) => {
    return [...colours, ...currentPalette];
  }, []);

export const EVENT_PALETTE = Object.entries(ICON_COLOUR_PALETTE)
  .filter(([key, _]) => key !== String(IconColours.BlueGrey))
  .map(([_, colourPalette]) => colourPalette.shades['a700']);

export const DEADZONE_DEFAULT_COLOUR = '#0000000d';

export const DEADZONE_PALETTE = [
  '#0000000d',
  '#0000001a',
  '#00000026',
  '#00000033',
  '#00000040',
  '#0000004d',
];
