import { type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const UNDO_KEYMAP = 'Mod-z';
export const REDO_KEYMAP = 'Shift-Mod-z';

export function createUndoMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Undo',
      tooltip: 'Undo',
      shortcut: UNDO_KEYMAP,
      icon: 'undo',
      command: (editor) => editor.chain().focus().undo().run(),
      isDisabledFn: (editor) => !editor.can().undo(),
    },
  });
}

export function createRedoMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Redo',
      tooltip: 'Redo',
      shortcut: REDO_KEYMAP,
      icon: 'redo',
      command: (editor) => editor.chain().focus().redo().run(),
      isDisabledFn: (editor) => !editor.can().redo(),
    },
  });
}
