import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  findRenderer,
  type ComponentLoader,
} from '@principle-theorem/ng-shared';
import {
  Transaction,
  TransactionAllocation,
} from '@principle-theorem/principle-core';
import {
  IHealthcareClaim,
  IInvoice,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { GeneralProviderActionsService } from '../../../transaction-providers/general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-providers/transaction-action';
import { TransactionActionFactoryService } from '../../../transaction-providers/transaction-action-factory.service';
import { TRANSACTION_EXTENDED_DATA_RENDERERS } from '../../../transaction-providers/transaction-extended-data-renderers';
import { TransactionProviderType } from '../../../transaction-providers/transaction-provider';
import { TransactionProviders } from '../../../transaction-providers/transaction-providers.service';
import { ITransactionDisplay } from '../../transaction-display';

export interface ITransactionExtendedDataDisplayData<T> {
  transaction: WithRef<ITransaction<T>>;
}

@Component({
    selector: 'pr-transaction-display-line',
    templateUrl: './transaction-display-line.component.html',
    styleUrls: ['./transaction-display-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionDisplayLineComponent {
  dateFormat = `${DAY_MONTH_YEAR_FORMAT} - ${TIME_FORMAT}`;
  transaction$ = new ReplaySubject<WithRef<ITransaction>>(1);
  isOutgoing$: Observable<boolean>;
  extendedDataComponent$: Observable<
    | ComponentLoader<unknown, ITransactionExtendedDataDisplayData<unknown>>
    | undefined
  >;
  hasHistory$: Observable<boolean>;
  hasExtendedData$: Observable<boolean>;
  isRefund$: Observable<boolean>;
  status$: Observable<IClaimStatus>;
  readonly$ = new BehaviorSubject<boolean>(false);
  display$ = new ReplaySubject<ITransactionDisplay>(1);
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  claim$ = new ReplaySubject<IHealthcareClaim | undefined>(1);
  actionsData$: Observable<ITransactionActionsData<unknown>>;
  actions$: Observable<ITransactionAction<unknown>[]>;
  showTransactionAllocation$: Observable<boolean>;

  @Input()
  set readonly(readonly: BooleanInput) {
    this.readonly$.next(coerceBooleanProperty(readonly));
  }

  @Input()
  set transaction(transaction: WithRef<ITransaction>) {
    if (transaction) {
      this.transaction$.next(transaction);
    }
  }

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set transactionDisplay(transactionDisplay: ITransactionDisplay) {
    if (transactionDisplay) {
      this.display$.next(transactionDisplay);
    }
  }

  @Input()
  set claim(claim: IHealthcareClaim | undefined) {
    this.claim$.next(claim);
  }

  constructor(
    private _transactions: TransactionProviders,
    private _actions: TransactionActionFactoryService,
    public action: GeneralProviderActionsService
  ) {
    this.isOutgoing$ = this.transaction$.pipe(
      map((transaction) => transaction.type === TransactionType.Outgoing)
    );
    this.extendedDataComponent$ = this.transaction$.pipe(
      map((transaction) =>
        this._resolveExtendedDataComponentLoader(transaction)
      )
    );

    this.status$ = this.transaction$.pipe(
      switchMap((transaction) => this._getClaimStatus(transaction))
    );
    this.isRefund$ = this.transaction$.pipe(
      map(
        (transaction) =>
          transaction.type === TransactionType.Outgoing &&
          transaction.provider !== TransactionProvider.AccountCreditTransfer
      )
    );

    this.hasHistory$ = this.transaction$.pipe(
      switchMap((transaction) => Transaction.amendmentHistory$(transaction)),
      map((transactions) => transactions.length >= 1)
    );

    this.hasExtendedData$ = combineLatest([
      this.transaction$,
      this.extendedDataComponent$,
    ]).pipe(
      map(
        ([transaction, extendedDataComponent]) =>
          !!transaction.description || !!extendedDataComponent
      )
    );

    this.actionsData$ = combineLatest([
      this.invoice$,
      this.transaction$,
      this.display$,
      this.claim$.pipe(startWith(undefined)),
    ]).pipe(
      map(([invoice, transaction, display, claim]) => ({
        invoice,
        transaction,
        latestTransaction: display.latest,
        claim,
      }))
    );
    this.actions$ = this.actionsData$.pipe(
      switchMap((data) => this._actions.getAvailableActions$(data))
    );

    this.showTransactionAllocation$ = combineLatest([
      this.transaction$,
      this.invoice$.pipe(
        map((invoice) => {
          return (
            TransactionAllocation.getInvoicedAmounts(invoice).length > 2 ||
            TransactionAllocation.hasUnallocatedAmount(invoice)
          );
        })
      ),
    ]).pipe(
      map(
        ([transaction, hasMultipleAllocations]) =>
          !!transaction.attributedTo || hasMultipleAllocations
      )
    );
  }

  private _resolveExtendedDataComponentLoader(
    transaction: WithRef<ITransaction>
  ):
    | ComponentLoader<unknown, ITransactionExtendedDataDisplayData<unknown>>
    | undefined {
    const renderer = findRenderer(
      TRANSACTION_EXTENDED_DATA_RENDERERS,
      transaction
    );
    if (!renderer) {
      return;
    }
    return {
      component: renderer.component,
      data: { transaction },
    };
  }

  private async _getClaimStatus(
    transaction: ITransaction
  ): Promise<IClaimStatus> {
    const provider = await this._transactions.findProviderById(
      transaction.provider
    );
    const claimProviders = [
      TransactionProviderType.Medicare,
      TransactionProviderType.HealthFund,
      TransactionProviderType.DVA,
    ];
    if (provider && claimProviders.includes(provider.provider.providerType)) {
      const successStatus =
        transaction.type === TransactionType.Outgoing
          ? ClaimDisplayStatus.Cancelled
          : ClaimDisplayStatus.Approved;
      const status =
        transaction.status === TransactionStatus.Complete
          ? successStatus
          : transaction.status;
      return { action: 'Claim', status };
    }
    const action =
      transaction.type === TransactionType.Outgoing ? 'Refund' : 'Payment';
    const status =
      transaction.status === TransactionStatus.Complete
        ? ClaimDisplayStatus.success
        : transaction.status;
    return { action, status };
  }
}

enum ClaimDisplayStatus {
  success = 'success',
  Cancelled = 'cancelled',
  Approved = 'approved',
}

interface IClaimStatus {
  action: string;
  status: TransactionStatus | ClaimDisplayStatus;
}
