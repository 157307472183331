<div class="flex flex-col">
  <mat-autocomplete
    #autoItemCodes="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="add($event.option.value)"
  >
    <ng-container *ngIf="filteredSmartGroups$ | async as filteredSmartGroups">
      <mat-optgroup label="Smart Codes" *ngIf="filteredSmartGroups.length">
        <mat-option
          *ngFor="
            let smartGroup of filteredSmartGroups;
            trackBy: trackBySmartGroup
          "
          [value]="smartGroup"
        >
          {{ smartGroup.uid }} - {{ smartGroup.title }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <mat-optgroup
      *ngFor="let group of filteredServiceCodes$ | async; trackBy: trackByGroup"
      [label]="group.name"
    >
      <mat-option
        *ngFor="let codeInfo of group.options; trackBy: trackByCode"
        [value]="codeInfo"
      >
        {{ codeInfo.code }} - {{ codeInfo.title }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>

  <div class="flex gap-4">
    <pt-search-field icon="add" class="flex-1">
      <input
        matInput
        placeholder="Add an Item Code"
        [matAutocomplete]="autoItemCodes"
        [formControl]="itemCodeSelector"
        #autoComplete="matAutocompleteTrigger"
        (click)="autoComplete.openPanel()"
      />
    </pt-search-field>

    <button mat-raised-button color="primary" (click)="addGroup()">
      Add New Group
    </button>
  </div>

  <ng-container *ngIf="treatmentConfig$ | async as treatmentConfig">
    <div
      class="service-code-group"
      *ngIf="treatmentConfig.serviceCodeSmartGroups.length"
    >
      <div class="header flex items-center">
        <div class="label-spacer">
          <span>Smart</span>
        </div>

        <span class="flex-1" class="mat-caption"
          >Smart codes will be automatically selected when the treatment is
          charted.</span
        >
      </div>

      <div
        class="service-code"
        *ngFor="
          let entry of treatmentConfig.serviceCodeSmartGroups;
          trackBy: trackBySmartGroup
        "
      >
        <div class="flex items-center gap-4">
          <span class="flex-1">{{ entry.title }}</span>

          <button
            mat-icon-button
            color="warn"
            (click)="deleteSmartEntry(entry)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <pr-service-groups-editor
      [serviceCodeGroups]="treatmentConfig.serviceCodeGroups"
      (serviceCodeGroupsChange)="updateGroups($event)"
     />
  </ng-container>
</div>
