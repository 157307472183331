import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChildren,
  inject,
  type AfterViewInit,
  type QueryList,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogPresets,
  TrackByFunctions,
  queryListToObservable,
} from '@principle-theorem/ng-shared';
import {
  isRightQuadrant,
  isUpperQuadrant,
} from '@principle-theorem/principle-core';
import {
  type IDentalChartViewTooth,
  type IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import {
  isChanged$,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { ReplaySubject, of, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToothHistoryDialogComponent } from '../../tooth-history/tooth-history-dialog/tooth-history-dialog.component';
import { ChartSurfaceDirective } from '../chart-surface/chart-surface.directive';
import {
  CHART_TOOTH_SURFACES_SVG_MAP,
  type IToothSurfacesSVGMap,
} from '../models/chart-tooth-surfaces-svg-map';
import { ChartElement, type IChartElement } from '../renderers/chart-element';
import { type IChartTooth } from '../renderers/chart-tooth';
import { ChartToothTexture } from '../renderers/chart-tooth-texture';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[prChartTooth]',
    templateUrl: './chart-tooth.component.html',
    styleUrls: ['./chart-tooth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartToothComponent implements AfterViewInit {
  private _dialog = inject(MatDialog);
  trackByPath = TrackByFunctions.variable<string>();
  paths: IToothSurfacesSVGMap = CHART_TOOTH_SURFACES_SVG_MAP;
  view$: ReplaySubject<IDentalChartViewTooth> = new ReplaySubject(1);
  tooth$: ReplaySubject<IChartTooth> = new ReplaySubject(1);
  selector$: Observable<IChartElement>;
  indicator$: Observable<IChartElement>;
  label$: Observable<IChartElement>;
  icon$: Observable<IChartElement>;
  badge$: Observable<number>;
  labelText$: Observable<string>;
  fillPath$: Observable<string>;

  surfaces$: Observable<ChartSurfaceDirective[]> = of([]);
  @ViewChildren(ChartSurfaceDirective)
  _surfaces: QueryList<ChartSurfaceDirective>;

  @Input()
  set view(view: IDentalChartViewTooth) {
    if (view) {
      this.view$.next(view);
    }
  }

  @Input()
  set tooth(tooth: IChartTooth) {
    if (tooth) {
      this.tooth$.next(tooth);
    }
  }

  constructor() {
    const toothRef$ = this.tooth$.pipe(
      map((tooth) => tooth.tooth.toothRef),
      isChanged$(),
      shareReplayCold()
    );

    this.indicator$ = toothRef$.pipe(
      map((toothRef) => this._buildIndicator(toothRef))
    );
    this.icon$ = this.tooth$.pipe(
      isChanged$(),
      map((tooth) => this._buildIcon(tooth))
    );
    this.label$ = toothRef$.pipe(map((toothRef) => this._buildLabel(toothRef)));
    this.badge$ = this.view$.pipe(
      map((view) => view.badge),
      isChanged$(),
      startWith(0)
    );
    this.labelText$ = toothRef$.pipe(
      map((toothRef) => `${toothRef.quadrant}${toothRef.quadrantIndex}`)
    );
    this.fillPath$ = this.labelText$.pipe(
      map((label: string) => `toothFill${label}`)
    );
  }

  ngAfterViewInit(): void {
    this.surfaces$ = queryListToObservable(this._surfaces);
  }

  async openToothHistory(): Promise<void> {
    const { tooth } = await snapshot(this.tooth$);
    this._dialog.open(
      ToothHistoryDialogComponent,
      DialogPresets.almostFullscreen({
        data: { tooth },
      })
    );
  }

  private _buildIndicator(toothRef: IToothRef): IChartElement {
    const indicator: ChartElement = new ChartElement();
    indicator.xPos = 155.5;
    indicator.yPos = 265;
    indicator.yFlipped = isUpperQuadrant(toothRef.quadrant);
    indicator.xFlipped = isRightQuadrant(toothRef.quadrant);
    return indicator.toInterface();
  }

  private _buildLabel(toothRef: IToothRef): IChartElement {
    const label: ChartElement = new ChartElement();
    label.xPos = 100;
    label.yFlipped = isUpperQuadrant(toothRef.quadrant);
    label.xFlipped = isRightQuadrant(toothRef.quadrant);
    return label.toInterface();
  }

  private _buildIcon(parent: IChartTooth): IChartElement {
    return new ChartToothTexture(parent).toInterface();
  }
}
