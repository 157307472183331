<ng-container *ngIf="contact$ | async as contact">
  <div
    class="layout-margin"
    fxLayout="column"
    fxLayoutGap="16px"
    fxLayout.gt-sm="row"
  >
    <div fxFlex="none" fxFlex.gt-sm>
      <pr-contact-card [contact]="contact" />
    </div>

    <div fxFlex="none" fxFlex.gt-sm fxLayout="column" fxLayoutGap="16px">
      <div class="actions-card">
        <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
          <button
            mat-stroked-button
            fxFlex
            [disabled]="!contact.phone"
            (click)="dialogService.openPhone()"
          >
            <mat-icon>phone</mat-icon> Call
          </button>

          <button
            mat-stroked-button
            fxFlex
            [disabled]="!contact.mobileNumber"
            (click)="dialogService.openSMS()"
          >
            <mat-icon>sms</mat-icon> SMS
          </button>

          <button
            mat-stroked-button
            fxFlex
            [disabled]="!contact.email"
            (click)="dialogService.openEmail()"
          >
            <mat-icon>email</mat-icon> Email
          </button>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
          <button mat-stroked-button color="warn" fxFlex (click)="delete()">
            <mat-icon>delete</mat-icon> Delete
          </button>

          <button mat-flat-button color="primary" fxFlex (click)="openEdit()">
            <mat-icon>edit</mat-icon> Edit
          </button>
        </div>
      </div>

      <mat-card appearance="outlined" class="no-padding">
        <mat-card-header>
          <mat-card-title>Notes</mat-card-title>
        </mat-card-header>
        <mat-card-content class="resource-notes">
          <pr-notes [resource]="contact" />
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
