<div
  fxLayout="row"
  fxLayoutAlign="start start"
  *ngIf="labJob$ | async as labJob"
>
  <mat-list>
    <mat-list-item>
      <mat-icon matListItemIcon>business</mat-icon>
      <span matListItemTitle>
        <pr-mention-resource
          [resource]="labMention$ | async"
          [interactiveResource]="labJob"
        >
          {{ labJob.lab.name }}
        </pr-mention-resource>
      </span>
    </mat-list-item>

    <mat-list-item>
      <mat-icon matListItemIcon>person</mat-icon>
      <span matListItemTitle>
        <pr-mention-resource
          [resource]="patientMention$ | async"
          [interactiveResource]="labJob"
        >
          {{ labJob.patient.name }}
        </pr-mention-resource>
      </span>
    </mat-list-item>

    <mat-list-item *ngIf="labJob.dueDate">
      <mat-icon matListItemIcon>access_alarm</mat-icon>
      <span matListItemTitle>Due Date</span>
      <span matListItemLine>
        {{ labJob.dueDate | moment | amCalendar : calendarFormat }}
        &#64;
        {{ labJob.dueDate | moment | amDateFormat : 'hh:mma' }}
      </span>
    </mat-list-item>

    <mat-list-item>
      <mat-icon matListItemIcon>category</mat-icon>
      <span matListItemTitle>{{ labJob.type.name }}</span>
      <span matListItemLine>{{ labJob.cost | currency }}</span>
    </mat-list-item>

    <ng-container *ngIf="appointment$ | async as appointment">
      <mat-list-item *ngIf="appointment.event as event">
        <mat-icon matListItemIcon>airline_seat_flat_angled</mat-icon>
        <span matListItemTitle>
          <ng-container *ngIf="appointment.practitioner">
            {{ appointment.practitioner.name }}
          </ng-container>
        </span>
        <span matListItemLine>
          <pr-formatted-event-field
            field="from"
            [event]="event"
            [format]="dateFormat"
           />
        </span>
      </mat-list-item>
    </ng-container>

    <mat-list-item *ngIf="labJob.description">
      <mat-icon matListItemIcon>description</mat-icon>
      <span matListItemTitle>{{ labJob.description }}</span>
    </mat-list-item>
  </mat-list>

  <div fxFlex></div>
  <div class="layout-padding">
    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item (click)="showNotes()">
        <mat-icon>event_note</mat-icon>
        <span>Lab Job Notes</span>
      </button>
    </mat-menu>
  </div>
</div>
