import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartFacade,
  ChartId,
  FeeScheduleFacade,
  type IChartContextState,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentAppointmentScope,
  CurrentTreatmentPlanScope,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  stafferToNamedDoc,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  type IFeeSchedule,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  filterUndefined,
  isSameRef,
  isWithRef,
  multiMap,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TreatmentPlanningBloc } from '../../treatment-planning-bloc';
import {
  type ITreatmentPlanListDialogData,
  TreatmentPlanListDialogComponent,
} from '../treatment-plan-list-dialog/treatment-plan-list-dialog.component';
import { TreatmentPlanViewType } from '../treatment-steps-editor/treatment-plan-view-type-selector/treatment-plan-view-type-selector.component';

@Component({
  selector: 'pr-treatment-planning',
  templateUrl: './treatment-planning.component.html',
  styleUrls: ['./treatment-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanningComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByPlan = TrackByFunctions.ref<WithRef<ITreatmentPlan>>();
  treatmentPlanning: TreatmentPlanningBloc;
  context: IChartContextState;
  patient$: Observable<WithRef<IPatient>>;
  showPlanView$: Observable<boolean>;
  viewType$ = new BehaviorSubject<TreatmentPlanViewType>(
    TreatmentPlanViewType.TreatmentPlan
  );
  @Input() disabled = false;
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;

  constructor(
    private _treatmentPlanScope: CurrentTreatmentPlanScope,
    private _chartState: ChartFacade,
    private _appointmentScope: CurrentAppointmentScope,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _feeScheduleStore: FeeScheduleFacade,
    private _stateNav: StateBasedNavigationService,
    private _dialog: MatDialog
  ) {
    this.patient$ = this._treatmentPlanScope.doc$.pipe(
      filterUndefined(),
      switchMap((treatmentPlan) => {
        return TreatmentPlan.patient$(treatmentPlan);
      })
    );

    this.showPlanView$ = this.viewType$.pipe(
      map((viewType) => viewType === TreatmentPlanViewType.TreatmentPlan)
    );

    this.treatmentPlanning = new TreatmentPlanningBloc(
      this._chartState,
      this._treatmentPlanFacade,
      this._appointmentScope.doc$
    );

    this._treatmentPlanFacade.selectedTreatmentPlan$
      .pipe(
        filter((plan): plan is undefined => !plan),
        switchMap(() => this._treatmentPlanScope.doc$.pipe(filterUndefined())),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((treatmentPlan) =>
        this._treatmentPlanFacade.selectTreatmentPlan(treatmentPlan.ref)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async createPlan(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const chartingAs = await snapshot(
      this._chartState
        .chartingAs$(ChartId.InAppointment)
        .pipe(map((staffer) => stafferToNamedDoc(staffer)))
    );
    await this._treatmentPlanFacade.generateTreatmentPlan(patient, chartingAs);
  }

  async changePlan(): Promise<void> {
    const data = await this._getPlanListDialogData();
    const selectedPlan = await this._dialog
      .open<
        TreatmentPlanListDialogComponent,
        ITreatmentPlanListDialogData,
        WithRef<ITreatmentPlan> | ITreatmentPlan
      >(
        TreatmentPlanListDialogComponent,
        DialogPresets.large({
          data,
          width: '1000px',
        })
      )
      .afterClosed()
      .toPromise();

    if (!selectedPlan) {
      return;
    }

    if (isWithRef(selectedPlan)) {
      this._treatmentPlanFacade.selectTreatmentPlan(selectedPlan.ref);
      this.showPlan();
      return;
    }

    return this.createPlan();
  }

  isAppointmentPlan(
    plan: WithRef<ITreatmentPlan>,
    appointmentPlan: WithRef<ITreatmentPlan>
  ): boolean {
    return isSameRef(plan, appointmentPlan);
  }

  setChartingAs(chartingAs: WithRef<IStaffer>): void {
    if (chartingAs) {
      this._chartState.setChartingAs(ChartId.InAppointment, chartingAs);
    }
  }

  setFeeSchedule(feeSchedule: WithRef<IFeeSchedule>): void {
    this._feeScheduleStore.selectFeeSchedule(feeSchedule.ref);
  }

  async print(plan: WithRef<ITreatmentPlan>): Promise<void> {
    const patient = await snapshot(this.patient$);

    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'treatment-plans',
      plan.ref.id,
      'print',
    ]);
  }

  isSelectedPlan$(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    return this.treatmentPlanning.selectedPlan$.pipe(
      map((selected) => isSameRef(plan, selected))
    );
  }

  isAppointmentPlan$(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    return this.treatmentPlanning.appointmentPlan$.pipe(
      map((appointmentPlan) =>
        appointmentPlan ? this.isAppointmentPlan(plan, appointmentPlan) : false
      )
    );
  }

  changeView(viewType: TreatmentPlanViewType): void {
    this.viewType$.next(viewType);
  }

  showPlan(): void {
    this.viewType$.next(TreatmentPlanViewType.TreatmentPlan);
  }

  private async _getPlanListDialogData(): Promise<ITreatmentPlanListDialogData> {
    const patient = await snapshot(this.patient$);
    const planPairs = await snapshot(
      this.treatmentPlanning.patientPlans$.pipe(
        multiMap((plan) => ({ plan, patient }))
      )
    );
    const todaysPlan = await snapshot(
      this.treatmentPlanning.appointmentPlan$.pipe(map((plan) => plan?.ref))
    );
    const selectedPlan = await snapshot(
      this._treatmentPlanFacade.selectedTreatmentPlan$.pipe(
        map((plan) => plan?.ref)
      )
    );
    return {
      planPairs,
      todaysPlan,
      selectedPlan,
    };
  }
}
