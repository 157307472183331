<div class="flex max-w-full items-center gap-2">
  <div
    *ngIf="name; else noName"
    class="mat-caption flex-1 truncate"
    [ngClass]="{
      compact: compact,
      'line-item-disabled': compact && disabled
    }"
    [matTooltip]="tooltip"
    [matTooltipDisabled]="!tooltip"
    [matTooltipShowDelay]="500"
  >
    {{ name }}
  </div>
  <ng-template #noName>
    <div class="flex-1"></div>
  </ng-template>

  <ng-container *ngIf="isFlat$ | async; else isTieredOrStepped">
    <div *ngIf="!disableQuantity" class="mat-caption flex items-center gap-1">
      <button
        mat-icon-button
        tabindex="-1"
        class="icon-sm opacity-50"
        (click)="decreaseQty()"
        [disabled]="quantityCtrl.value < 1 || disabled"
      >
        <mat-icon>remove</mat-icon>
      </button>
      {{ quantityCtrl.value }}
      <button
        mat-icon-button
        tabindex="-1"
        [disabled]="disabled"
        class="icon-sm opacity-50"
        (click)="increaseQty()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #isTieredOrStepped>
    <button
      *ngIf="!disableQuantity"
      mat-icon-button
      class="icon-sm opacity-50"
      (click)="addTieredServiceCode()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </ng-template>

  <div
    class="mat-caption flex items-center gap-1"
    [ngClass]="{ invalid: priceCtrl.invalid }"
  >
    $
    <input
      *ngIf="!disabled; else readOnlyPrice"
      [formControl]="priceCtrl"
      type="number"
      min="0"
      autocomplete="off"
      (keyup.enter)="$event.target.blur()"
      class="price-input"
    />
    <ng-template #readOnlyPrice>
      <div class="read-only-price">{{ priceCtrl.value }}</div>
    </ng-template>
  </div>

  <ng-content></ng-content>
</div>
