import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { BaseSourceHandler } from '../source/base-source-handler';
import { FeeScheduleSourceEntity } from './source/entities/fee-schedule';
import { ItemCodeSourceEntity } from './source/entities/item-codes';
import { PatientAppointmentSourceEntity } from './source/entities/patient-appointments';
import { PatientNoteCategorySourceEntity } from './source/entities/patient-note-categories';
import { PatientNoteSourceEntity } from './source/entities/patient-notes';
import { PatientTreatmentPlanTreatmentsSourceEntity } from './source/entities/patient-treatment-plan-treatments';
import { PatientSourceEntity } from './source/entities/patients';
import { ProviderSourceEntity } from './source/entities/providers';
import { ThirdPartySourceEntity } from './source/entities/third-parties';
import { PatientWriteOffSourceEntity } from './source/entities/patient-treatment-write-offs';
import { PatientDiscountSourceEntity } from './source/entities/patient-discounts';
import { PatientPaymentAdjustmentSourceEntity } from './source/entities/patient-payment-adjustments';
import { PatientTreatmentsSourceEntity } from './source/entities/patient-treatments';
import { PatientPaymentSourceEntity } from './source/entities/patient-payments';
import { AppointmentCategorySourceEntity } from './source/entities/appointment-categories';
import { PatientBalanceSourceEntity } from './source/entities/patient-balance';
import { PatientAppointmentBookSourceEntity } from './source/entities/appointment-book';
import { PatientCommunicationSourceEntity } from './source/entities/patient-communication';
import { PatientPeriodontalChartSourceEntity } from './source/entities/patient-periodontal-charts';

export const OASIS_SOURCE: ISource = {
  metadata: {
    name: 'Oasis',
    version: 'v1',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Bucket storage path that patient files live under Eg. "gs://practice-name'`,
      value: '',
    },
  ],
};

export class OasisSourceHandler extends BaseSourceHandler {
  migrationType = 'OASiS';
  migrationVersion = 'v1';
  source = OASIS_SOURCE;

  entityHandlers = [
    new FeeScheduleSourceEntity(),
    new ItemCodeSourceEntity(),
    new PatientAppointmentSourceEntity(),
    new PatientNoteCategorySourceEntity(),
    new PatientNoteSourceEntity(),
    new PatientSourceEntity(),
    new ProviderSourceEntity(),
    new ThirdPartySourceEntity(),
    new PatientTreatmentPlanTreatmentsSourceEntity(),
    new PatientDiscountSourceEntity(),
    new PatientWriteOffSourceEntity(),
    new PatientPaymentAdjustmentSourceEntity(),
    new PatientTreatmentsSourceEntity(),
    new PatientPaymentSourceEntity(),
    new AppointmentCategorySourceEntity(),
    new PatientBalanceSourceEntity(),
    new PatientAppointmentBookSourceEntity(),
    new PatientCommunicationSourceEntity(),
    new PatientPeriodontalChartSourceEntity(),
  ];
}
