<ng-container
  *ngFor="let button of contextButtons$ | async; trackBy: trackByAction"
>
  <span [matTooltip]="button.disabledMessage$ | async">
    <button
      mat-menu-item
      [disabled]="button.isDisabled$ | async"
      (click)="runAction(button.action)"
    >
      <mat-icon>{{ button.action.icon }}</mat-icon>
      {{ button.action.name }}
    </button>
  </span>
</ng-container>
