/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  isToothNumber,
  type ISourceEntity,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  TOOTH_SURFACES,
  type PraktikaToothSurfaces,
} from './patient-tooth-conditions';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_APPOINTMENT_PROCEDURE_RESOURCE_TYPE =
  'patientAppointmentProcedure';

export const PATIENT_APPOINTMENT_PROCEDURE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment Procedure List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_PROCEDURE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum PraktikaTreatmentPlanProcedureStatus {
  Proposed = 1,
  Accepted = 2,
  Appointed = 3,
  Completed = 4,
  Rejected = 5,
  Deleted = 7,
}

export interface IPraktikaAppointmentProcedure {
  id: number;
  patient_id: number;
  provider_id: number | null;
  appointment_id: number | null;
  quote_id: number | null;
  service_item_id: number;
  // tooth_id: number | null; // Refernces tooth on dentition chart
  status_id: PraktikaTreatmentPlanProcedureStatus;
  code: string; // CDBS is shown 88022. This can also be a code for a product...
  ada_code: string | null; // Even if CDBS this will be 022
  description: string;
  tooth_number: ToothNumber | null;
  surfaces: PraktikaToothSurfaces | null;
  has_gst: boolean;
  standard_fee: string;
  total_fee: string;
  cost: number | null;
  duration: number | null;
  benefit: number | null;
  response_code: string | null;
  created_date: string; // '2020-02-24 15:35:06'
  completed_date: string | null; // '2020-02-24'
  accepted_date: string | null; // '2020-02-24
  fee_schedule_id: number | null;
  fee_schedule_type_id: number | null;
  note: string;
  visit_number: number | null;

  // medicareOverrideCode: string | null;
  // hasBenefit: boolean;
  // paymentId: number;
  // feeScheduleName: string;
}

export function isPraktikaAppointmentProcedure(
  item: unknown
): item is IPraktikaAppointmentProcedure {
  return TypeGuard.interface<IPraktikaAppointmentProcedure>({
    id: isNumber,
    patient_id: isNumber,
    provider_id: TypeGuard.nilOr(isNumber),
    appointment_id: TypeGuard.nilOr(isNumber),
    quote_id: TypeGuard.nilOr(isNumber),
    service_item_id: isNumber,
    // tooth_id: TypeGuard.nilOr(isNumber),
    status_id: TypeGuard.enumValue(PraktikaTreatmentPlanProcedureStatus),
    code: isString,
    ada_code: TypeGuard.nilOr(isString),
    description: isString,
    tooth_number: TypeGuard.nilOr(
      (data: unknown): data is ToothNumber =>
        isToothNumber(data) || data === '0'
    ),
    surfaces: TypeGuard.nilOr(
      (data: unknown): data is PraktikaToothSurfaces =>
        isString(data) &&
        data.split('').every((surface) => TOOTH_SURFACES.includes(surface))
    ),
    has_gst: isBoolean,
    cost: TypeGuard.nilOr(isNumber),
    duration: TypeGuard.nilOr(isNumber),
    standard_fee: isString,
    total_fee: isString,
    benefit: TypeGuard.nilOr(isNumber),
    response_code: TypeGuard.nilOr(isString),
    created_date: isString,
    completed_date: TypeGuard.nilOr(isString),
    accepted_date: TypeGuard.nilOr(isString),
    fee_schedule_id: TypeGuard.nilOr(isNumber),
    fee_schedule_type_id: TypeGuard.nilOr(isNumber),
    note: isString,
    visit_number: TypeGuard.nilOr(isNumber),
  })(item);
}

export interface IPraktikaAppointmentProcedureTranslations {
  createdDate: Timestamp;
  completedDate?: Timestamp;
  acceptedDate?: Timestamp;
}

export interface IPraktikaAppointmentProcedureFilters {
  patientId: string;
  practitionerId?: string;
  appointmentId?: string;
  quoteId?: string;
  isFlagged: boolean;
  isUnscheduledAcceptedTreatment: boolean;
  isPlanProposal: boolean;
  isScheduled: boolean;
  createdDate: Timestamp;
}

const PATIENT_APPOINTMENT_PROCEDURE_QUERY = `
SELECT
  quote.quote_id,
  patient.id,
  patient.patient_id,
  patient.provider_id,
  patient.appointment_id,
  patient.status_id,
  patient.visit_number,
  patient.completed_date,
  patient.service_item_id,
  patient.code,
  patient.description,
  patient.ada_code,
  patient.standard_fee,
  patient.total_fee,
  appointment.fee_schedule_type_id,
  appointment.fee_schedule_id,
  patient.has_gst,
  -- patient.tooth_id,
  patient.surfaces,
  patient.tooth_number,
  patient.created_date,
  patient.accepted_date,
  patient.note,
  appointment.benefit,
  appointment.response_code,
  appointment.duration,
  appointment.cost,
  quote.fee_schedule_id
FROM
(
  (SELECT
    iProcedureId AS id,
    CASE WHEN iBenefitClaimed = ''
      THEN 0
      ELSE convert_to_integer(iBenefitClaimed)
      END as benefit,
    vchResponseCode as response_code,
    iRecommendedDuration as duration,
    iCost as cost,
    iGroupId AS fee_schedule_type_id,
    iFeeScheduleId AS fee_schedule_id
  FROM appointment_procedures) appointment
  FULL OUTER JOIN
    (SELECT
      iProcedureId AS id,
      iPatientNumber AS patient_id,
      iProviderId AS provider_id,
      iAppointmentId as appointment_id,
      iStatusId AS status_id,
      dtCompleted AS completed_date,
      iServiceItemId AS service_item_id,
      iVisitNumber AS visit_number,
      vchCode AS code,
      vchCodeDescShort AS description,
      vchADACodeRef::TEXT AS ada_code,
      CAST(nScheduledFee AS NUMERIC) AS standard_fee,
      CAST(nTotalFee AS NUMERIC) AS total_fee,
      bGST AS has_gst,
      -- iToothId AS tooth_id,
      vchSurfaces AS surfaces,
      iToothNumber::TEXT AS tooth_number,
      dtCreated AS created_date,
      dtAppointed AS accepted_date,
      quote_for_export AS note
    FROM patient_procedures
    ORDER BY iProcedureId
    ${OFFSET_PLACEHOLDER}) patient
  ON appointment.id = patient.id
  FULL OUTER JOIN
  (SELECT
    convert_to_integer(iProcedureId) AS id,
    convert_to_integer(iquoteid) as quote_id,
    vchNote as note,
    istatusid as quote_status,
    iFeeScheduleId as fee_schedule_id
  FROM quote_procedures) quote
  ON COALESCE(appointment.id, patient.id) = quote.id
)
WHERE patient.patient_id IS NOT null
ORDER BY patient.created_date DESC
`;

const PATIENT_APPOINTMENT_PROCEDURE_ESTIMATE_QUERY = `
SELECT patient.id FROM (
  (SELECT
    iProcedureId AS id
  FROM appointment_procedures) appointment
  FULL OUTER JOIN
    (SELECT
      iProcedureId AS id,
      iPatientNumber AS patient_id
    FROM patient_procedures
    ORDER BY iProcedureId) patient
  ON appointment.id = patient.id
  FULL OUTER JOIN
  (SELECT
    convert_to_integer(iProcedureId) AS id
  FROM quote_procedures) quote
  ON COALESCE(appointment.id, patient.id) = quote.id
)
WHERE patient.patient_id IS NOT null
`;

export class PatientAppointmentProcedureSourceEntity extends BaseSourceEntity<
  IPraktikaAppointmentProcedure,
  IPraktikaAppointmentProcedureTranslations,
  IPraktikaAppointmentProcedureFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_PROCEDURE_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_PROCEDURE_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_PROCEDURE_QUERY;
  override estimateQuery = PATIENT_APPOINTMENT_PROCEDURE_ESTIMATE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isPraktikaAppointmentProcedure;
  override transformDataFn = flow([]);
  override defaultOffsetSize = 20000;
  override dateFilterField: keyof IPraktikaAppointmentProcedureFilters =
    'createdDate';

  translate(
    note: IPraktikaAppointmentProcedure,
    timezone: Timezone
  ): IPraktikaAppointmentProcedureTranslations {
    return {
      completedDate: note.completed_date
        ? toTimestamp(
            moment
              .tz(note.completed_date, ISO_DATE_FORMAT, timezone)
              .startOf('day')
          )
        : undefined,
      acceptedDate: note.accepted_date
        ? toTimestamp(
            moment
              .tz(note.accepted_date, ISO_DATE_FORMAT, timezone)
              .startOf('day')
          )
        : undefined,
      createdDate: toTimestamp(
        moment.tz(note.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaAppointmentProcedure): string {
    return `${data.quote_id}-${data.id}`;
  }

  getSourceLabel(data: IPraktikaAppointmentProcedure): string {
    return `${data.created_date} - ${data.id}`;
  }

  getFilterData(
    data: IPraktikaAppointmentProcedure,
    timezone: Timezone
  ): IPraktikaAppointmentProcedureFilters {
    const patientId = data.patient_id.toString();
    const practitionerId = data.provider_id?.toString();
    const appointmentId = data.appointment_id?.toString();
    const quoteId = data.quote_id?.toString();
    const status = data.status_id;
    const hasValidVisitNumber =
      !!data.visit_number && data.visit_number !== 99999;

    const isFlagged =
      !quoteId &&
      !appointmentId &&
      !hasValidVisitNumber &&
      [
        PraktikaTreatmentPlanProcedureStatus.Proposed,
        PraktikaTreatmentPlanProcedureStatus.Accepted,
      ].includes(status);
    const isUnscheduledAcceptedTreatment =
      !quoteId &&
      !appointmentId &&
      hasValidVisitNumber &&
      status === PraktikaTreatmentPlanProcedureStatus.Accepted;
    const isScheduled = !!appointmentId;
    const isPlanProposal =
      !appointmentId &&
      !!quoteId &&
      [
        PraktikaTreatmentPlanProcedureStatus.Proposed,
        PraktikaTreatmentPlanProcedureStatus.Accepted,
      ].includes(status);

    return {
      createdDate: toTimestamp(
        moment.tz(data.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
      patientId,
      practitionerId,
      appointmentId,
      quoteId,
      isFlagged,
      isUnscheduledAcceptedTreatment,
      isPlanProposal,
      isScheduled,
    };
  }
}
