<div
  class="flex h-full w-full items-stretch gap-2"
  *ngIf="actionsComponents$ | async as actionsComponents"
>
  <pt-dynamic-component
    class="h-full"
    *ngFor="
      let actionsComponent of actionsComponents;
      trackBy: trackByDefinition
    "
    [definition]="actionsComponent"
  />
</div>
