import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AutomationConfigurationService,
  AutomationDialogService,
  type IAutomatedNotificationConfigurationEditRequest,
} from '@principle-theorem/ng-automations';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  AutomationConfiguration,
  Brand,
  TemplateDefinition,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  AnyAutomationConfiguration,
  TemplateScope,
  type IAutomatedNotificationConfiguration,
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  all$,
  filterUndefined,
  multiFilter,
  patchDoc,
  shareReplayCold,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-brand-automations',
  templateUrl: './brand-automations.component.html',
  styleUrls: ['./brand-automations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BrandAutomationsComponent {
  brand$: Observable<WithRef<IBrand>>;
  automatedNotificationScope = TemplateScope.Appointment;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  practices$: Observable<INamedDocument<IPractice>[]>;
  appointmentConfigs$: Observable<WithRef<AnyAutomationConfiguration>[]>;
  treatmentConfigs$: Observable<WithRef<AnyAutomationConfiguration>[]>;
  deletedConfigs$: Observable<WithRef<AnyAutomationConfiguration>[]>;

  constructor(
    private _snackBar: MatSnackBar,
    currentBrand: CurrentBrandScope,
    private _automationConfiguration: AutomationConfigurationService,
    private _automationDialog: AutomationDialogService
  ) {
    this.brand$ = currentBrand.doc$.pipe(filterUndefined());
    this.practices$ = this.brand$.pipe(
      switchMap((brand) => Brand.practices$(brand)),
      toINamedDocuments()
    );

    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Automations' },
      ])
    );
    const allConfigs$ = this.brand$.pipe(
      switchMap((brand) =>
        all$(AutomationConfiguration.col<AnyAutomationConfiguration>(brand))
      ),
      shareReplayCold()
    );
    const undeletedConfigs$ = allConfigs$.pipe(
      multiFilter((config) => !config.deleted)
    );
    this.appointmentConfigs$ = undeletedConfigs$.pipe(
      multiFilter((config) => AutomationConfiguration.isGlobal(config))
    );
    this.treatmentConfigs$ = undeletedConfigs$.pipe(
      multiFilter((config) => !AutomationConfiguration.isGlobal(config))
    );
    this.deletedConfigs$ = allConfigs$.pipe(
      multiFilter((config) => config.deleted)
    );
  }

  async addNotification(): Promise<void> {
    const practices = await snapshot(this.practices$);
    const data: IAutomatedNotificationConfigurationEditRequest = {
      scope: this.automatedNotificationScope,
      practices,
    };

    const response = await this._automationConfiguration.openEditDialog(data);
    if (!response) {
      return;
    }

    const brand = await snapshot(this.brand$);

    const templateRef = await addDoc(
      Brand.templateCol(brand),
      TemplateDefinition.init({
        ...response.template,
        ownerScope: brand.ref,
      })
    );

    const automatedConfigurationRef = await addDoc(
      AutomationConfiguration.col<IAutomatedNotificationConfiguration>(brand),
      {
        ...response.config,
        templateRef,
      }
    );

    await patchDoc(templateRef, {
      automatedConfigurationRef,
    });

    this._snackBar.open('Notification configuration saved');
  }

  async addTask(): Promise<void> {
    const practices = await snapshot(this.practices$);
    const task = await this._automationDialog.openTaskDialog({
      useRelativeTime: true,
      practices,
    });

    if (!task) {
      return;
    }

    const resource = await snapshot(this.brand$);
    await addDoc(AutomationConfiguration.col(resource), {
      ...task,
      isActive: true,
      deleted: false,
      treatmentRefs: [],
    });
  }

  async addFormIssue(): Promise<void> {
    const practices = await snapshot(this.practices$);
    const issueForm = await this._automationDialog.openAutomatedFormIssueDialog(
      {
        useRelativeTime: true,
        practices,
      }
    );

    if (!issueForm) {
      return;
    }

    const automationConfiguration = AutomationConfiguration.init({
      ...issueForm,
      isActive: false,
      deleted: false,
      treatmentRefs: [],
    });

    const resource = await snapshot(this.brand$);
    await addDoc(
      AutomationConfiguration.col(resource),
      automationConfiguration
    );
  }
}
