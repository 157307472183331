<div fxLayout="row" fxLayoutAlign="start center" class="layout-margin">
  <h1 fxFlex class="mat-headline-6">Members</h1>
  <button mat-flat-button color="primary" (click)="addSubContact()">
    Create Member
  </button>
</div>

<div class="layout-margin mat-elevation-z1 bg-white">
  <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async" />

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="list"
    title="member contacts"
  />

  <ng-container *ngIf="dataSource.data.length">
    <mat-table [dataSource]="dataSource" matSort>
      <mat-header-row *matHeaderRowDef="displayColumns" />
      <mat-row *matRowDef="let contact; columns: displayColumns" />

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let contact" class="truncate">
          {{ contact.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="jobTitle">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
          Job Title
        </mat-header-cell>
        <mat-cell *matCellDef="let contact" class="truncate">
          {{ contact.jobTitle }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
          Phone
        </mat-header-cell>
        <mat-cell *matCellDef="let contact" class="truncate">
          <a [routerLink]="[]" (click)="openPhone(contact)">
            {{ contact.phone }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mobileNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
          Mobile
        </mat-header-cell>
        <mat-cell *matCellDef="let contact" class="truncate">
          <a [routerLink]="[]" (click)="openSMS(contact)">
            {{ contact.mobileNumber }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
          Email
        </mat-header-cell>
        <mat-cell *matCellDef="let contact" class="truncate">
          <a [routerLink]="[]" (click)="openEmail(contact)">
            {{ contact.email }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef />
        <mat-cell *matCellDef="let contact" fxLayout="row" fxLayoutAlign="end">
          <button mat-icon-button [matMenuTriggerFor]="subContactMenu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #subContactMenu="matMenu">
            <button mat-menu-item (click)="openPhone(contact)">
              <mat-icon>phone</mat-icon>
              Call
            </button>
            <button mat-menu-item (click)="openSMS(contact)">
              <mat-icon>sms</mat-icon>
              SMS
            </button>
            <button mat-menu-item (click)="openEmail(contact)">
              <mat-icon>email</mat-icon>
              Email
            </button>
            <button mat-menu-item (click)="editSubContact(contact)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item color="warn" (click)="deleteContact(contact)">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
    </mat-table>

    <mat-paginator
      #paginator
      [length]="dataSource.data.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    />
  </ng-container>
</div>
