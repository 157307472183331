<mat-toolbar color="accent">Update Medicare Card</mat-toolbar>

<mat-dialog-content>
  <pr-medicare-card-form
    #form="prMedicareCardForm"
    [medicareCard]="medicareCard"
    (medicareCardChange)="updateMedicareCard($event)"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close type="button">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.medicareForm.invalid"
      (click)="submit()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
