import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transaction } from '@principle-theorem/principle-core';
import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import { type ArchivedDocument, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface ITransactionAmendmentHistoryDialogData {
  transaction: WithRef<ITransaction>;
}

@Component({
  selector: 'pr-transaction-amendment-history-dialog',
  templateUrl: './transaction-amendment-history-dialog.component.html',
  styleUrls: ['./transaction-amendment-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionAmendmentHistoryDialogComponent {
  amendmentHistory$: Observable<WithRef<ArchivedDocument<ITransaction>>[]>;
  transaction: WithRef<ITransaction>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ITransactionAmendmentHistoryDialogData
  ) {
    this.amendmentHistory$ = Transaction.amendmentHistory$(data.transaction);
    this.transaction = data.transaction;
  }
}
