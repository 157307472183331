import { Component, Output, EventEmitter } from '@angular/core';
import { PasswordSetForm, type IPasswordSet } from './password-forms';

@Component({
    selector: 'pr-password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.sass'],
    exportAs: 'prPasswordForm',
    standalone: false
})
export class PasswordFormComponent {
  form: PasswordSetForm = new PasswordSetForm();
  @Output()
  submitted: EventEmitter<IPasswordSet> = new EventEmitter<IPasswordSet>();

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.submitted.next(this.form.value);
  }
}
