import {
  ChartableSurface,
  CHARTABLE_SURFACES,
  ChecklistItemAction,
  ChecklistType,
  IAutomationConfiguration,
  IConditionConfiguration,
  IGeneratedTaskConfiguration,
  isGeneratedTaskConfiguration,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ServiceCodeType,
  ServiceSmartGroupName,
  TreatmentConfigurationName,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  GreyColourShading,
  IIdentifiable,
  WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { ChecklistItem } from '../../appointment/checklist-item';
import {
  DefaultGeneratedTaskConfiguration,
  findGeneratedTaskConfigRefs,
} from '../../task/generated-task-seed-data';
import {
  getTreatmentCategoryByName,
  TreatmentCategoryName,
} from '../../treatment-category-defaults';
import {
  ConditionConfigurationName,
  getConditionByName,
} from '../conditions/condition-configuration-seed-data';
import { ChartedItemDisplay } from '../core/charted-item-display';
import { getSmartGroupByName } from '../service-codes/charted-service-smart-group';
import { ServiceCodeGroupBuilder } from '../service-codes/service-code-group';
import { TreatmentConfiguration } from './treatment-configuration';

export const ADA_CODE_TREATMENT_CONFIG_ID = 'adaCode';

export function getTreatmentByName(
  name: TreatmentConfigurationName,
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[]
): WithRef<ITreatmentConfiguration> | undefined {
  return treatmentConfigurations.find(
    (treatment) => treatment.name === String(name)
  );
}

export function buildTreatmentConfigurationSeedData(
  conditionConfigurations: WithRef<IConditionConfiguration>[],
  treatmentCategories: WithRef<ITreatmentCategory>[],
  automationConfigurations: WithRef<IAutomationConfiguration>[]
): (ITreatmentConfiguration & Partial<IIdentifiable>)[] {
  const taskConfigs = automationConfigurations.filter(
    (automation): automation is WithRef<IGeneratedTaskConfiguration> =>
      isGeneratedTaskConfiguration(automation)
  );

  const groupBuilder = new ServiceCodeGroupBuilder(ServiceCodeType.ADA);
  const newMocks: (AtLeast<ITreatmentConfiguration, 'name' | 'duration'> &
    Partial<IIdentifiable>)[] = [
    {
      uid: ADA_CODE_TREATMENT_CONFIG_ID,
      name: TreatmentConfigurationName.GeneralADACode,
      duration: 0,
      display: ChartedItemDisplay.init({
        colour: {
          name: new GreyColourShading().label,
          value: new GreyColourShading().shades.a400,
        },
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
        hasFill: true,
      }),
      availableSurfaces: [ChartableSurface.Unscoped, ...CHARTABLE_SURFACES],
    },
    {
      name: TreatmentConfigurationName.PeriodicExamAndClean,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ExistingPatientCheckUpClean,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.WholeMouth],
      duration: 45,
      description: 'Periodic examination and clean for an existing patient',
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(12),
          groupBuilder.code(114),
          groupBuilder.code(121)
        ),
        groupBuilder.optional(
          groupBuilder.code(11),
          groupBuilder.code(22, 4),
          groupBuilder.code(131),
          groupBuilder.code(141),
          groupBuilder.code(221),
          groupBuilder.code(37)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Update patient medical history',
          action: ChecklistItemAction.Print,
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote for further treatment',
        }),
      ],
      // automatedNotifications: [
      //   {
      //     template: yesterdaysAppointmentTemplate,
      //     subject: `Yesterday's dental appointment`,
      //     timing: {
      //       amount: 1,
      //       direction: TimingDirection.After,
      //       unit: TimingUnit.Days,
      //     },
      //     type: AutomatedNotificationType.EMAIL,
      //     name: 'Post-appointment feedback email',
      //   },
      // ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-exam-periodic', name: 'Exam - Periodic' },
        colour: { name: 'Indigo a100', value: '#8c9eff' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PoorOralHygiene,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { name: 'Purple a200', value: '#e040fb' },
        icon: { slug: 'pr-implant', name: 'Implant' },
      }),
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(13))],
      duration: 10,
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.ImplantReviewAfterFixturePlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      description:
        'Review appointment usually 7-10 days after placement and suture removal',
    },
    {
      serviceCodeGroups: [
        groupBuilder.optional(groupBuilder.code(982), groupBuilder.code(862)),
      ],
      availableSurfaces: [ChartableSurface.WholeMouth],
      display: ChartedItemDisplay.init({
        colour: { value: '#3d5afe', name: 'Indigo a400' },
        icon: { name: 'Arch - Periodic', slug: 'pr-arch-periodic' },
      }),
      description:
        'Mid-treatment review of Invisalign treatment including IPR, review or bonding of attachment, review of elastics',
      name: TreatmentConfigurationName.InvisalignMidTreatmentReview,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      duration: 20,
    },
    {
      name: TreatmentConfigurationName.LimitedEmergencyExamination,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Update Patient Medical History',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Provide treatment plan and quote if further treatment required',
        }),
      ],
      availableSurfaces: [ChartableSurface.Unscoped],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      duration: 45,
      description: 'For limited examinations or emergency appointments',
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(13)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(37),
          groupBuilder.code(72),
          groupBuilder.code(61),
          groupBuilder.code(961),
          groupBuilder.code(165)
        ),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#d50000', name: 'Red a700' },
        icon: { slug: 'pr-exam-emergency', name: 'Exam - Emergency' },
      }),
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.Gingivectomy,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      description:
        'Surgical removal of the soft tissue wall of the periodontal pocket, per tooth',
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(231))],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      duration: 30,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Gingivectomy consent form',
        }),
      ],
      display: ChartedItemDisplay.init({
        icon: { name: 'Veneer', slug: 'pr-veneer' },
        hideWhenResolved: false,
        colour: { value: '#c51162', name: 'Pink a700' },
      }),
    },
    {
      duration: 60,
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(236))],
      description:
        'A surgical technique in which a membrane is adapted over a bony defect, tooth root or an implant. GTR.',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallPostOperativeDiscomfort,
      ]),
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      display: ChartedItemDisplay.init({
        icon: { name: 'Pericoronitis', slug: 'pr-pericoronitis' },
        colour: { value: '#00e676', name: 'Green a400' },
        hideWhenResolved: false,
      }),
      name: TreatmentConfigurationName.GuidedTissueRegenerationMembranePlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.WholeTooth],
    },
    {
      duration: 20,
      description: 'Consulation',
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(37),
          groupBuilder.code(61),
          groupBuilder.code(72),
          groupBuilder.code(73),
          groupBuilder.code(71),
          groupBuilder.code(19)
        ),
        groupBuilder.exclusive(
          groupBuilder.code(14),
          groupBuilder.code(15),
          groupBuilder.code(16),
          groupBuilder.code(17)
        ),
      ],
      name: TreatmentConfigurationName.Consultation,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Consultation,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.Unscoped],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Update patient medical history',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan',
          action: ChecklistItemAction.Print,
        }),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#b0bec5', name: 'Blue Grey a200' },
        icon: { name: 'Consultation', slug: 'pr-consultation' },
      }),
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.Consultation),
      ]),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.EnsureTreatmentPlanProvided,
      ]),
    },
    {
      availableSurfaces: [ChartableSurface.Unscoped],
      description:
        'New patient comprehensive exam and clean; incl EO exam, IO exam, charting, BWs, OPG, photos and treatment plan',
      duration: 60,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-exam-new-patient', name: 'Exam - New Patient' },
        colour: { value: '#651fff', name: 'Deep Purple a400' },
      }),
      // automatedNotifications: [
      //   {
      //     subject: 'Your first dental appointment',
      //     name: 'New patient feedback email',
      //     type: AutomatedNotificationType.EMAIL,
      //     timing: {
      //       unit: TimingUnit.Days,
      //       amount: 1,
      //       direction: TimingDirection.After,
      //     },
      //     template: newPatientFeedbackTemplate,
      //   },
      // ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Completed and signed new patient form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote for further treatment',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(11),
          groupBuilder.code(22, 4),
          groupBuilder.code(37),
          groupBuilder.code(72)
        ),
        groupBuilder.optional(
          // Removed
          groupBuilder.code(25),
          // New
          groupBuilder.code(26),
          groupBuilder.code(87),
          groupBuilder.code(88),
          groupBuilder.code(89),
          groupBuilder.code(73),
          groupBuilder.code(61)
        ),
      ],
      name: TreatmentConfigurationName.NewPatientComprehensiveExamination,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.NewPatient,
        treatmentCategories
      ).ref,
    },
    {
      name: TreatmentConfigurationName.RootCanalReTreatmentRemovalOfRootFilling,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Endodontics,
        treatmentCategories
      ).ref,
      description:
        'Removal of the root filling of a previously obturated canal to enable endodontic re-treatment',
      duration: 30,
      availableSurfaces: [ChartableSurface.WholeTooth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(451)),
        groupBuilder.optional(groupBuilder.code(22), groupBuilder.code(452)),
      ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-root-canal', name: 'Root Canal' },
        colour: { value: '#ff8a80', name: 'Red a100' },
      }),
    },
    {
      duration: 20,
      name: TreatmentConfigurationName.InvisalignIssuingFirstAligners,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      description:
        'Issuing first aligners, treatment instructions, chewies, cleaning aids and remote monitoring',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.TwoDayCheckUpAligner,
      ]),
      availableSurfaces: [ChartableSurface.WholeMouth],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Welcome to invisalign checklist',
          action: ChecklistItemAction.Print,
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title:
            'Ensure Invisalign welcome pack is ready: case, chewies/munchies, toothbrush, floss, remover tool',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Check payment plan/item codes for invisalign treatment',
          data: 'Ensure notes detail how account is to be settled',
        }),
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Light Blue a100', value: '#80d8ff' },
        icon: { slug: 'pr-arch-periodic', name: 'Arch - Periodic' },
      }),
      // automatedNotifications: [
      //   {
      //     subject: 'Welcome to Invisalign',
      //     template: welcomeToInvisalignTemplate,
      //     timing: {
      //       unit: TimingUnit.Days,
      //       amount: 1,
      //       direction: TimingDirection.Before,
      //     },
      //     type: AutomatedNotificationType.EMAIL,
      //     name: 'Invisalign day 1',
      //   },
      // ],
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(14))],
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.MandibularAdvancementSplintOneWeekCheckUp,
      ]),
      description:
        'Issuing of Mandibular advancement splint, case and instructions for use',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Give case for MAS, cleaning tablets and care instructions',
        }),
      ],
      name: TreatmentConfigurationName.MandibularAdvancementSplintIssue,
      availableSurfaces: [ChartableSurface.WholeMouth],
      duration: 30,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(984), groupBuilder.code(983)),
      ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-arch-done', name: 'Arch - Done' },
        colour: { name: 'Light Blue a200', value: '#40c4ff' },
      }),
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure pt has case/container and care instructions',
        }),
      ],
      name: TreatmentConfigurationName.MouthguardIssue,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(151)),
        groupBuilder.optional(groupBuilder.code(153)),
      ],
      availableSurfaces: [ChartableSurface.Arch],
      description: 'Issuing of sports mouthguard',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OneWeekCheckUpMouthGuard,
      ]),
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { name: 'Arch - Guard', slug: 'pr-arch-guard' },
        colour: { value: '#00c853', name: 'Green a700' },
      }),
      duration: 15,
    },
    {
      display: ChartedItemDisplay.init({
        icon: { name: 'Restoration', slug: 'pr-restoration' },
        hideWhenResolved: false,
        colour: { value: '#616161', name: 'Grey a700' },
      }),
      name: TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      duration: 45,
      availableSurfaces: [
        ChartableSurface.Distal,
        ChartableSurface.Facial,
        ChartableSurface.Lingual,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
      ],
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(577),
          groupBuilder.code(597),
          groupBuilder.code(578),
          groupBuilder.code(411),
          groupBuilder.code(575)
        ),
      ],
      description: 'Direct metallic restoration',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.RestorativeMetallic),
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      availableSurfaces: [ChartableSurface.WholeMouth],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: `Update patient's medical history`,
          action: ChecklistItemAction.Print,
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote for further treatment',
        }),
      ],
      // automatedNotifications: [
      //   {
      //     name: 'Post-appointment feedback email',
      //     timing: {
      //       amount: 1,
      //       direction: TimingDirection.After,
      //       unit: TimingUnit.Days,
      //     },
      //     template: yesterdaysAppointmentTemplate,
      //     type: AutomatedNotificationType.EMAIL,
      //     subject: `Yesterday's dental appointment`,
      //   },
      // ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PoorOralHygiene,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.PeriodicExamCleanAndXrays,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ExistingPatientCheckUpClean,
        treatmentCategories
      ).ref,
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(12),
          groupBuilder.code(22, 4),
          groupBuilder.code(37),
          groupBuilder.code(114),
          groupBuilder.code(121)
        ),
        groupBuilder.optional(
          groupBuilder.code(72),
          groupBuilder.code(73),
          groupBuilder.code(131),
          groupBuilder.code(141),
          groupBuilder.code(222)
        ),
      ],
      description:
        'Periodic examination, x-rays and clean for an existing patient. BWs are usually taken every 2yrs but variable based on risk assessment. OPGs are usually taken every 2-5 yrs but variable based on risk assessment and individual factors.',
      duration: 45,
      display: ChartedItemDisplay.init({
        colour: { value: '#304ffe', name: 'Indigo a700' },
        icon: { name: 'Exam - Periodic', slug: 'pr-exam-periodic' },
      }),
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(432)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      name: TreatmentConfigurationName.Apicectomy,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Endodontics,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        colour: { name: 'Purple a700', value: '#aa00ff' },
        hideWhenResolved: false,
        icon: { name: 'Root Resorption', slug: 'pr-root-resorption' },
      }),
      description:
        'Surgical exposure of the apical section of the root of a pulpless or root canal treated tooth to remove the apical portion of the root including curettage of the region',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Apicectomy consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
      ],
      duration: 60,
    },
    {
      duration: 60,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure patient has superfloss and/or IP brushes to clean under bonded wire(s)',
        }),
      ],
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(845))],
      name: TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Orthodontics,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.Arch],
      description: 'Bonding of fixed orthodontic retainers with wires',
      display: ChartedItemDisplay.init({
        colour: { value: '#78909c', name: 'Blue Grey a400' },
        hideWhenResolved: false,
        icon: { slug: 'pr-bridge', name: 'Bridge' },
      }),
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(71, 2),
          groupBuilder.code(72),
          groupBuilder.code(73)
        ),
        groupBuilder.optional(
          groupBuilder.code(37),
          groupBuilder.code(82),
          groupBuilder.code(81),
          groupBuilder.code(75)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Send payment plan link/information if requested',
        }),
      ],
      description: 'Intra-oral scan and photos for Invisalign prescription',
      duration: 30,
      name: TreatmentConfigurationName.InvisalignWorkUp,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.InvisalignSubmitPrescription,
      ]),
      availableSurfaces: [ChartableSurface.WholeMouth],
      display: ChartedItemDisplay.init({
        colour: { name: 'Light Blue a100', value: '#80d8ff' },
        hideWhenResolved: false,
        icon: { name: 'Arch', slug: 'pr-arch' },
      }),
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-implant', name: 'Implant' },
        hideWhenResolved: false,
        colour: { name: 'Indigo a700', value: '#304ffe' },
      }),
      name: TreatmentConfigurationName.ImplantRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(661), groupBuilder.code(22)),
        groupBuilder.exclusive(
          groupBuilder.code(671),
          groupBuilder.code(672),
          groupBuilder.code(673)
        ),
      ],
      description: 'Placement of implant abutment and crown to implant fixture',
      duration: 60,
    },
    {
      availableSurfaces: [ChartableSurface.WholeMouth],
      name: TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Periodontics,
        treatmentCategories
      ).ref,
      description:
        'Supportive periodontal therapy subsequent to active periodontal treatment, recurring at regular intervals',
      display: ChartedItemDisplay.init({
        colour: { name: 'Green a700', value: '#00c853' },
        icon: { slug: 'pr-periodontal', name: 'Peridontal' },
      }),
      duration: 45,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(251)),
        groupBuilder.optional(
          groupBuilder.code(13),
          groupBuilder.code(12),
          groupBuilder.code(221),
          groupBuilder.code(141),
          groupBuilder.code(113),
          groupBuilder.code(131),
          groupBuilder.code(142)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure patient has periodontal prevention pack: mouthrinse, toothbrush, interproximal cleaning aids',
        }),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisGeneralised,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      duration: 90,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-abrasion', name: 'Abrasion' },
        hideWhenResolved: false,
        colour: { value: '#aeea00', name: 'Lime a700' },
      }),
      description:
        'Preparation, impression and temporisation of a tooth for an indirect, tooth coloured restoration',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Select movie/music',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Treatment consent form',
        }),
      ],
      name: TreatmentConfigurationName.InlayOnlayPreparationAndImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      description:
        'Removal of a tooth where an incision and the raising of a flap are required (322). This treatment may also require bone removal (323) or bone removal with tooth division (324).',
      display: ChartedItemDisplay.init({
        colour: { name: 'Red a100', value: '#ff8a80' },
        disablesSurface: true,
        hideWhenResolved: false,
        icon: { slug: 'pr-extraction', name: 'Extraction' },
      }),
      serviceCodeGroups: [
        groupBuilder.exclusive(
          groupBuilder.code(322),
          groupBuilder.code(323),
          groupBuilder.code(324)
        ),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(927),
          groupBuilder.code(243),
          groupBuilder.code(235)
        ),
      ],
      name: TreatmentConfigurationName.ExtractionSurgical,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Extraction consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.ImpactedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.UneruptedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Swelling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pericoronitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RetainedRoot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.NecroticPulp,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
      ],
      duration: 60,
    },
    {
      duration: 60,
      description:
        'A procedure to establish a more apical gingival margin for greater exposure of tooth structure, including reshaping of alveolar bone where required. Per tooth.',
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { slug: 'pr-abrasion', name: 'Abrasion' },
        colour: { name: 'Green a400', value: '#00e676' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(238)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Periodontal surgery consent from',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      name: TreatmentConfigurationName.CrownLengtheningPeriodontalSurgery,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
    },
    {
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(14))],
      display: ChartedItemDisplay.init({
        icon: { name: 'Arch - Exam', slug: 'pr-arch-exam' },
        hideWhenResolved: false,
        colour: { name: 'Indigo a100', value: '#8c9eff' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.ApproveClincheck,
      ]),
      duration: 20,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Invisalign consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Payment arrangement forms/applications',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Congratulate patient on deciding to start their Invisalign treatment',
        }),
      ],
      description:
        'Showing the patient a digital simulation of their orthodontic alignment and obtaining consent',
      availableSurfaces: [ChartableSurface.Unscoped],
      name: TreatmentConfigurationName.InvisalignClincheckReview,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          action: ChecklistItemAction.Print,
          title: 'Update patient medical history',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote for further treatment',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(12), groupBuilder.code(22, 4)),
        groupBuilder.optional(groupBuilder.code(37)),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#e040fb', name: 'Purple a200' },
        icon: { slug: 'pr-exam-periodic', name: 'Exam - Periodic' },
      }),
      duration: 45,
      availableSurfaces: [ChartableSurface.WholeMouth],
      name: TreatmentConfigurationName.PeriodicExam,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ExistingPatientCheckUpClean,
        treatmentCategories
      ).ref,
      description: 'Periodic examination for an existing patient',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PoorOralHygiene,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { name: 'Mobile', slug: 'pr-mobile' },
        colour: { name: 'Cyan a700', value: '#00b8d4' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(165)),
        groupBuilder.optional(groupBuilder.code(121), groupBuilder.code(123)),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure patient has desensitising products if required',
        }),
      ],
      name: TreatmentConfigurationName.DesensitisingTreatment,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.AbrasionToothbrush,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityCold,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivitySweet,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityTouchTapping,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityGeneral,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityHot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Erosion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Wear,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Attrition,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivalRecession,
          conditionConfigurations
        ).ref,
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallSensitivity,
      ]),
      description:
        'Desensitising treatment with remineralising agents or adhesive techniques',
      duration: 10,
    },
    {
      display: ChartedItemDisplay.init({
        colour: { value: '#00c853', name: 'Green a700' },
        icon: { slug: 'pr-pericoronitis', name: 'Pericoronitis' },
      }),
      name: TreatmentConfigurationName.GuidedTissueRegenerationMembraneRemoval,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      description:
        'A surgical procedure to remove a previously positioned membrane. GTR',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 60,
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(237))],
    },
    {
      description:
        'Preparation, impression or digital scan and temporisation of multi-unit bridge',
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.MultipleTeeth,
      ],
      name: TreatmentConfigurationName.BridgePreparationAndImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      duration: 120,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Bridge consent form',
        }),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallSensitivity,
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      display: ChartedItemDisplay.init({
        icon: { name: 'Bridge', slug: 'pr-bridge' },
        hideWhenResolved: false,
        colour: { value: '#84ffff', name: 'Cyan a100' },
      }),
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(627),
          groupBuilder.code(597),
          groupBuilder.code(22),
          groupBuilder.code(656),
          groupBuilder.code(655)
        ),
      ],
    },
    {
      duration: 30,
      name: TreatmentConfigurationName.TemporaryFilling,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-abrasion', name: 'Abrasion' },
        colour: { value: '#ff80ab', name: 'Pink a100' },
      }),
      description:
        'A provisional, intermediate or temporary restoration of a tooth designed to last until the definitive restoration can be constructed. Do NOT use this item where the temporary is an intrinsic part of the treatment; eg, access cavities for RCT or temporary crowns',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LeakingRestoration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(572))],
    },
    {
      name: TreatmentConfigurationName.SplintAdjustmentAdditionRepair,
      duration: 15,
      description: 'Adjustment, addition or repair of splint(s)',
      availableSurfaces: [],
      display: ChartedItemDisplay.init({
        colour: { value: '#b9f6ca', name: 'Green a100' },
        icon: { slug: 'pr-arch-periodic', name: 'Arch - Periodic' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(972)),
        groupBuilder.optional(groupBuilder.code(966)),
      ],
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.InternalBleachingRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.PreventativeProphylacticBleachingServices,
        treatmentCategories
      ).ref,
      duration: 15,
      description:
        'Re-access, removal of bleaching material and restoration of access cavity',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(521)),
        groupBuilder.optional(groupBuilder.code(22), groupBuilder.code(72)),
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Light Blue a100', value: '#80d8ff' },
        icon: { slug: 'pr-whitening', name: 'Whitening' },
      }),
    },
    {
      name: TreatmentConfigurationName.BridgeIssuePontics,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      description:
        'Issuing of the pontics of a bridge. Crown(s) or retainer(s) on abutment teeth are to be itemised separately.',
      duration: 120,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-bridge', name: 'Bridge' },
        colour: { value: '#00e5ff', name: 'Cyan a400' },
        hideWhenResolved: false,
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(643))],
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallImplant,
        DefaultGeneratedTaskConfiguration.OrderImplantComponents,
      ]),
      duration: 90,
      name: TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Implant consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          data: 'Post-implant instructions, medications (antibiotics and analgesics), mouthrinse, soft toothbrush.',
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, etc',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Provide doctor's certificate if necessary`,
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.exclusive(groupBuilder.code(688), groupBuilder.code(684)),
        groupBuilder.required(groupBuilder.code(22, 2)),
        groupBuilder.optional(groupBuilder.code(72), groupBuilder.code(679)),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.MissingTooth,
          conditionConfigurations
        ).ref,
      ],
      description: 'Surgical placement of implant fixture',
      display: ChartedItemDisplay.init({
        icon: { name: 'Implant', slug: 'pr-implant' },
        colour: { name: 'Light Blue a200', value: '#40c4ff' },
        hideWhenResolved: false,
      }),
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(417),
          groupBuilder.code(418, 0),
          groupBuilder.code(22, 2)
        ),
        groupBuilder.optional(groupBuilder.code(418), groupBuilder.code(457)),
      ],
      description:
        'The filling of a root canal system, following chemo-mechanical preparation',
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { name: 'Root Canal', slug: 'pr-root-canal' },
        colour: { name: 'Blue Grey a700', value: '#455a64' },
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 45,
      name: TreatmentConfigurationName.RootCanalObturation,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Endodontics,
        treatmentCategories
      ).ref,
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 60,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Bone graft consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
      ],
      description:
        'Per tooth or implant. A surgical procedure in which particulate bone, synthetic substitute or other matrix is used to replace, repair or augment alveolar bone or repair a bony defect around a tooth or dental implant. This is in addition to item numbers 232 ,233, 245, 684',
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(243)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(232),
          groupBuilder.code(233),
          groupBuilder.code(245)
        ),
      ],
      name: TreatmentConfigurationName.BoneGraftParticulate,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { slug: 'pr-candidiasis', name: 'Candidiasis' },
        colour: { value: '#b9f6ca', name: 'Green a100' },
      }),
    },
    {
      duration: 45,
      serviceCodeGroups: [
        groupBuilder.exclusive(groupBuilder.code(711), groupBuilder.code(712)),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure patient has denture care instructions and products',
        }),
      ],
      name: TreatmentConfigurationName.CompleteDenture,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      description:
        'Provision of a removable complete maxillary or mandibular denture',
      availableSurfaces: [ChartableSurface.Arch],
      display: ChartedItemDisplay.init({
        colour: { value: '#ff80ab', name: 'Pink a100' },
        icon: { name: 'Arch - Done', slug: 'pr-arch-done' },
        hideWhenResolved: false,
      }),
    },
    {
      display: ChartedItemDisplay.init({
        colour: { value: '#ff4081', name: 'Pink a200' },
        icon: { name: 'Arch', slug: 'pr-arch' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallInvisalign,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(862)),
        groupBuilder.optional(groupBuilder.code(982)),
      ],
      availableSurfaces: [ChartableSurface.Unscoped],
      duration: 45,
      name: TreatmentConfigurationName.InvisalignBondingOfAttachments,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      description:
        'Bonding of attachments, buttons, elastic hooks and IPR, usually at the beginning of treatment - aligners 1 or 2',
    },
    {
      duration: 15,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure pt has case and care instructions for their splint',
        }),
      ],
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(965))],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { name: 'Deep Orange a100', value: '#ff9e80' },
        icon: { slug: 'pr-arch-guard', name: 'Arch - Guard' },
      }),
      availableSurfaces: [ChartableSurface.WholeMouth, ChartableSurface.Arch],
      name: TreatmentConfigurationName.SplintIssue,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OneWeekCheckUpSplit,
      ]),
      description: 'Issuing of splint with container and care instructions',
    },
    {
      description:
        'Bonding or cementation of a final crown constructed with a tooth-coloured material (eg, all ceramic)',
      duration: 45,
      name: TreatmentConfigurationName.CrownIssueBondingCeramicNonMetallic,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(613)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      availableSurfaces: [ChartableSurface.Crown],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { name: 'Brown a100', value: '#d7ccc8' },
        icon: { slug: 'pr-crown-small', name: 'Crown' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
    },
    {
      name: TreatmentConfigurationName.CBCTBothArchesAcquisitionAndInterpretation,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.RootCanalTreatment,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImpactedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImplantFixture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
      ],
      duration: 15,
      display: ChartedItemDisplay.init({
        colour: { value: '#00b0ff', name: 'Light Blue a400' },
        icon: { name: 'X-rays - 3D', slug: 'pr-x-rays-3d' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(26), groupBuilder.code(89)),
      ],
      availableSurfaces: [ChartableSurface.WholeMouth],
      description: 'CBCT - Both Arches - Acquisition & Interpretation',
    },
    {
      availableSurfaces: [ChartableSurface.WholeMouth],
      description:
        'Impressions or digital intra-oral scan for the fabrication of a mouthguard',
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-arch-guard', name: 'Arch - Guard' },
        colour: { name: 'Green a700', value: '#00c853' },
      }),
      duration: 30,
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(71, 2))],
      name: TreatmentConfigurationName.MouthguardImpressionsScan,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
    },
    {
      name: TreatmentConfigurationName.VeneerIssueBonding,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      duration: 120,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(556)),
        groupBuilder.optional(groupBuilder.code(526), groupBuilder.code(536)),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Congratulate patient on completing their veneer treatment',
        }),
      ],
      description: 'Try in and bonding of veneers',
      display: ChartedItemDisplay.init({
        colour: { name: 'Deep Purple a100', value: '#b388ff' },
        icon: { slug: 'pr-veneer', name: 'Veneer' },
        hideWhenResolved: false,
      }),
    },
    {
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(574))],
      description:
        'The cementation of a metal band for diagnostic, protective purposes or for the placement of a provisional restoration (to be itemised separately)',
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
        colour: { name: 'Blue Grey a700', value: '#455a64' },
      }),
      name: TreatmentConfigurationName.MetalBand,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityTouchTapping,
          conditionConfigurations
        ).ref,
      ],
      duration: 20,
    },
    {
      name: TreatmentConfigurationName.TeethWhiteningImpressions,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.TeethWhitening,
        treatmentCategories
      ).ref,
      // TODO: Move this to seeded data
      // automatedNotifications: [
      //   {
      //     timing: {
      //       unit: TimingUnit.Minutes,
      //       amount: 1,
      //       direction: TimingDirection.Immediately,
      //     },
      //     subject: 'Post impression teeth whitening message',
      //     name: 'Post impression teeth whitening message',
      //     type: AutomatedNotificationType.SMS,
      //     template: postImpressionWhiteningTemplate,
      //   },
      // ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.FabricateWhiteningTrays,
        DefaultGeneratedTaskConfiguration.ContactPatientForWhiteningKitPickUp,
      ]),
      availableSurfaces: [ChartableSurface.WholeMouth],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-whitening', name: 'Whitening' },
        colour: { value: '#80d8ff', name: 'Light Blue a100' },
      }),
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Issue sensitive toothpaste or desensitising products before whitening',
        }),
      ],
      description: 'Impressions for fabrication of custom bleaching trays',
      duration: 15,
    },
    {
      display: ChartedItemDisplay.init({
        icon: { name: 'Linea Alba', slug: 'pr-linea-alba' },
        colour: { name: 'Red a100', value: '#ff8a80' },
      }),
      availableSurfaces: [ChartableSurface.Arch],
      duration: 30,
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(391))],
      description: 'Removal of a frenum',
      name: TreatmentConfigurationName.Frenectomy,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(161))],
      name: TreatmentConfigurationName.FissureSealant,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      description:
        'Sealing of non-carious pits, fissures, smooth surfaces or cracks in a tooth with adhesive material. Preparation prior to application is included in this item number.',
      duration: 30,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.DeepFissure,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.EarlyDemineralisationLesion,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { value: '#ff80ab', name: 'Pink a100' },
        icon: { slug: 'pr-deep-fissures', name: 'Deep Fissures' },
      }),
    },
    {
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(627),
          groupBuilder.code(597),
          groupBuilder.code(22),
          groupBuilder.code(655),
          groupBuilder.code(71, 2),
          groupBuilder.code(72)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Crown consent form',
        }),
      ],
      name: TreatmentConfigurationName.CrownPreparationAndImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        icon: { name: 'Crown', slug: 'pr-crown-small' },
        colour: { name: 'Brown a200', value: '#bcaaa4' },
      }),
      description:
        'Crown preparation, impression or digital scan and temporisation',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallCrownPrep,
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      duration: 90,
      availableSurfaces: [ChartableSurface.Crown],
    },
    {
      name: TreatmentConfigurationName.CosmeticOrRestorativeWorkUp,
      duration: 20,
      availableSurfaces: [],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(71, 2), groupBuilder.code(72)),
        groupBuilder.optional(
          groupBuilder.code(13),
          groupBuilder.code(73),
          groupBuilder.code(963),
          groupBuilder.code(964)
        ),
      ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-arch-exam', name: 'Arch - Exam' },
        colour: { name: 'Cyan a200', value: '#18ffff' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.GenerateTreatmentPlan,
      ]),
      description:
        'Impressions, scan, photos/video for planning of comprehensive restorative treatment',
    },
    {
      description:
        'Provisional restoration of a tooth with a crown that is designed to last until the definitive crown can be constructed or the tooth is removed. NOT to be used for a provisional between appointment during crown construction .',
      name: TreatmentConfigurationName.TemporaryCrown,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(631))],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LeakingRestoration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
      ],
      duration: 30,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-crown-small', name: 'Crown' },
        colour: { name: 'Brown a200', value: '#bcaaa4' },
      }),
      availableSurfaces: [ChartableSurface.Crown],
    },
    {
      availableSurfaces: [ChartableSurface.WholeMouth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendDigitalIOScanToLab,
      ]),
      description: 'Digital intraoral scan / impression',
      name: TreatmentConfigurationName.DigitalIntraoralScan,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(71, 2)),
        groupBuilder.optional(
          groupBuilder.code(963),
          groupBuilder.code(964),
          groupBuilder.code(72),
          groupBuilder.code(73)
        ),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Bruxism,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.TMJDisorder,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Attrition,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crowding,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Spacing,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Wear,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ClassIIMalocclusion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ClassIIIMalocclusion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AnteriorOpenBite,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DeepBite,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PosteriorCrossBite,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Erosion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Abfraction,
          conditionConfigurations
        ).ref,
      ],
      duration: 15,
      display: ChartedItemDisplay.init({
        colour: { value: '#69f0ae', name: 'Green a200' },
        icon: { slug: 'pr-arch-exam', name: 'Arch - Exam' },
      }),
    },
    {
      duration: 45,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      display: ChartedItemDisplay.init({
        icon: { name: 'Cerec', slug: 'pr-cerec' },
        colour: { value: '#d7ccc8', name: 'Brown a100' },
        hideWhenResolved: false,
      }),
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.ToothColouredIndirect),
      ]),
      name: TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      description:
        'Bonding or cementation of a final indirect restoration constructed with a tooth-coloured material (eg, all ceramic)',
      serviceCodeGroups: [
        groupBuilder.optional(groupBuilder.code(22), groupBuilder.code(72)),
      ],
    },
    {
      name: TreatmentConfigurationName.CBCTPartialSegmentAcquisitionAndInterpretation,
      display: ChartedItemDisplay.init({
        colour: { name: 'Light Blue a100', value: '#80d8ff' },
        icon: { name: 'X-rays - 3D', slug: 'pr-x-rays-3d' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(26), groupBuilder.code(87)),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImpactedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImplantFixture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootCanalTreatment,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
      ],
      description: 'CBCT - Partial Segment - Acquisition & Interpretation',
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.Quadrant,
      ],
      duration: 15,
    },
    {
      serviceCodeGroups: [
        groupBuilder.exclusive(
          groupBuilder.code(121),
          groupBuilder.code(122),
          groupBuilder.code(123)
        ),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.EarlyDemineralisationLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DeepFissure,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Orange a400', value: '#ff9100' },
        icon: { slug: 'pr-mobile', name: 'Mobile' },
      }),
      description:
        'Application of remineralising and/or cariostatic agents in caries management and risk reduction',
      name: TreatmentConfigurationName.RemineralisingTreatment,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.PreventativeProphylacticBleachingServices,
        treatmentCategories
      ).ref,
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.WholeMouth,
      ],
      duration: 10,
    },
    {
      description:
        'Planning for implants; Xrays, CBCT, IO scans, photos and consultation for implant treatment',
      name: TreatmentConfigurationName.ImplantPlanningWorkUp,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote',
        }),
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Indigo a400', value: '#3d5afe' },
        icon: { name: 'Implant', slug: 'pr-implant' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OrderImplantComponents2,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(26)),
        groupBuilder.optional(
          groupBuilder.code(13),
          groupBuilder.code(14),
          groupBuilder.code(15),
          groupBuilder.code(71, 2),
          groupBuilder.code(72),
          groupBuilder.code(73),
          groupBuilder.code(679)
        ),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.CBCT),
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.MissingTooth,
          conditionConfigurations
        ).ref,
      ],
      duration: 20,
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(250)),
        groupBuilder.optional(
          groupBuilder.code(221),
          groupBuilder.code(12),
          groupBuilder.code(141),
          groupBuilder.code(131),
          groupBuilder.code(142)
        ),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#00c853', name: 'Green a700' },
        hideWhenResolved: false,
        icon: { name: 'Peridontal', slug: 'pr-periodontal' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallPeriodontal,
      ]),
      availableSurfaces: [ChartableSurface.Quadrant],
      description: 'Active non-surgical periodontal treatment per quadrant',
      duration: 60,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure patient has periodontal prevention pack: mouthrinse, toothbrush, interproximal cleaning aids',
        }),
      ],
      name: TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Periodontics,
        treatmentCategories
      ).ref,
    },
    {
      name: TreatmentConfigurationName.ImplantSurgerySecondStageHealingAbutmentPlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      description:
        'Surgical access to the previously osseointegrated implant to attach a transmucosal component, usually a healing abutment.',
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-linea-alba', name: 'Linea Alba' },
        colour: { name: 'Pink a200', value: '#ff4081' },
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 30,
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(691))],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OrderImplantComponents3,
      ]),
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(961))],
      display: ChartedItemDisplay.init({
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
        colour: { name: 'Lime a400', value: '#c6ff00' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.SensitivityCold,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.TenderToPercussion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityGeneral,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityHot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityTouchTapping,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Overhang,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.OverEruptedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityPostOperative,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.MinorOcclusalAdjustment,
      duration: 15,
      description: 'Minor Occlusal Adjustment',
    },
    {
      duration: 60,
      name: TreatmentConfigurationName.InvisalignEndOfTreatment,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      description:
        'Removal of attachments, elastics, occlusal adjustments, bonding of wire retainers and scan for removable retainers',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Photo consent form',
          action: ChecklistItemAction.Print,
          data: '',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has oral hygiene aids for cleaning under bonded wires - superfloss and IP brushes',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Congratulate patient for completing their Invisalign treatment',
        }),
      ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-arch-done', name: 'Arch - Done' },
        colour: { value: '#a7ffeb', name: 'Teal a100' },
      }),
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(811, 2),
          groupBuilder.code(845, 2),
          groupBuilder.code(961),
          groupBuilder.code(71, 2),
          groupBuilder.code(72)
        ),
      ],
      availableSurfaces: [ChartableSurface.WholeMouth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OrderRetainers,
      ]),
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(72), groupBuilder.code(73)),
      ],
      description:
        'A series of intra-oral and extra-oral DSLR photos for diagnosis, analysis, treatment planning or assessment of results. Per appointment',
      name: TreatmentConfigurationName.PhotographsDSLR,
      availableSurfaces: [],
      duration: 5,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-exam', name: 'Exam' },
        colour: { name: 'Amber a400', value: '#ffc400' },
      }),
    },
    {
      // automatedNotifications: [
      //   {
      //     template: newPatientFeedbackTemplate,
      //     name: 'New patient feedback email',
      //     subject: 'Your first dental appointment',
      //     type: AutomatedNotificationType.EMAIL,
      //     timing: {
      //       unit: TimingUnit.Days,
      //       amount: 1,
      //       direction: TimingDirection.After,
      //     },
      //   },
      // ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Completed and signed new patient form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote for further treatment',
        }),
      ],
      description:
        'New patient comprehensive exam and clean; incl EO exam, IO exam, charting, BWs, OPG, photos, treatment plan and standard clean',
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(11),
          groupBuilder.code(22, 4),
          groupBuilder.code(37),
          groupBuilder.code(72),
          groupBuilder.code(114),
          groupBuilder.code(121)
        ),
        groupBuilder.optional(
          groupBuilder.code(26),
          groupBuilder.code(87),
          groupBuilder.code(88),
          groupBuilder.code(89),
          groupBuilder.code(73),
          groupBuilder.code(61),
          groupBuilder.code(221),
          groupBuilder.code(222),
          groupBuilder.code(111),
          groupBuilder.code(131),
          groupBuilder.code(141)
        ),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#7c4dff', name: 'Deep Purple a200' },
        icon: { name: 'Exam - New Patient', slug: 'pr-exam-new-patient' },
      }),
      availableSurfaces: [],
      name: TreatmentConfigurationName.NewPatientComprehensiveExamAndClean,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.NewPatient,
        treatmentCategories
      ).ref,
      duration: 75,
    },
    {
      display: ChartedItemDisplay.init({
        colour: { name: 'Yellow a400', value: '#ffea00' },
        icon: { name: 'Arch - Guard', slug: 'pr-arch-guard' },
      }),
      description:
        'Impression or digital intra-oral scan for fabrication of a splint',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendLabJobAndPrescriptionForSplit,
      ]),
      name: TreatmentConfigurationName.SplintImpressionScan,
      duration: 15,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Consent form for splint',
        }),
      ],
      availableSurfaces: [ChartableSurface.WholeMouth],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(71, 2)),
        groupBuilder.optional(groupBuilder.code(963), groupBuilder.code(964)),
      ],
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.DiagnosticWaxUpMockUpTryIn,
      duration: 30,
      display: ChartedItemDisplay.init({
        colour: { name: 'Green a200', value: '#69f0ae' },
        icon: { slug: 'pr-arch', name: 'Arch' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(74)),
        groupBuilder.optional(groupBuilder.code(72), groupBuilder.code(71)),
      ],
      description: 'Diagnostic Wax Up / Mock Up Try-in',
    },
    {
      display: ChartedItemDisplay.init({
        colour: { value: '#455a64', name: 'Blue Grey a700' },
        hideWhenResolved: false,
        icon: { slug: 'pr-abrasion', name: 'Abrasion' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(22))],
      description:
        'Bonding or cementation of a final indirect restoration constructed with a metallic material (eg, gold inlay)',
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.MetallicIndirect),
      ]),
      name: TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      duration: 45,
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
    },
    {
      name: TreatmentConfigurationName.BotoxFacialInjectables,
      availableSurfaces: [],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Facial Injectable / Botox consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure patient has post-treatment instructions',
        }),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.TMJDisorder,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Bruxism,
          conditionConfigurations
        ).ref,
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      duration: 30,
      description: 'Facial Injectable',
      display: ChartedItemDisplay.init({
        colour: { value: '#00e5ff', name: 'Cyan a400' },
        icon: { name: 'Exam', slug: 'pr-exam' },
      }),
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Photo consent form',
        }),
      ],
      display: ChartedItemDisplay.init({
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
        colour: { value: '#ffff00', name: 'Yellow a200' },
        hideWhenResolved: false,
        hasFill: true,
      }),
      availableSurfaces: [ChartableSurface.Facial],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(526)),
        groupBuilder.optional(groupBuilder.code(525)),
      ],
      description:
        'Direct bonding of a composite veneer to the surface on an anterior tooth',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Wear,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Discolouration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbrasionToothbrush,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.EnamelDefect,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.CompositeVeneer,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      duration: 60,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
    },
    {
      display: ChartedItemDisplay.init({
        icon: { name: 'Extraction', slug: 'pr-extraction' },
        hideWhenResolved: false,
        disablesSurface: true,
        colour: { value: '#ff1744', name: 'Red a400' },
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RetainedRoot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.NecroticPulp,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      description:
        'Removal of a tooth or part thereof in sections. Bone removal may be necessary.',
      duration: 45,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Extraction consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(314)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(927),
          groupBuilder.code(243),
          groupBuilder.code(235)
        ),
      ],
      name: TreatmentConfigurationName.ExtractionSectional,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
    },
    {
      description: 'Bonding or cementation of an indirect metallic crown',
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(618)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#616161', name: 'Grey a700' },
        icon: { name: 'Crown', slug: 'pr-crown-small' },
        hideWhenResolved: false,
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      duration: 45,
      availableSurfaces: [ChartableSurface.Crown],
      name: TreatmentConfigurationName.CrownIssueBondingMetallic,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
    },
    {
      description:
        'Review, adjustment, reinsertion, addition or repair of a mandibular advancement splint',
      name: TreatmentConfigurationName.MandibularAdvancementSplintReviewAdjustmentRepair,
      availableSurfaces: [ChartableSurface.WholeMouth],
      serviceCodeGroups: [
        groupBuilder.optional(groupBuilder.code(13), groupBuilder.code(985)),
      ],
      duration: 15,
      display: ChartedItemDisplay.init({
        colour: { value: '#80d8ff', name: 'Light Blue a100' },
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
      }),
    },
    {
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Fluorosis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Discolouration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Staining,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.EnamelDefect,
          conditionConfigurations
        ).ref,
      ],
      description: 'Enamel microabrasion',
      display: ChartedItemDisplay.init({
        colour: { name: 'Blue a700', value: '#2962ff' },
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
      }),
      name: TreatmentConfigurationName.EnamelMicroabrasion,
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(116))],
      duration: 45,
      availableSurfaces: [ChartableSurface.WholeTooth],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
    },
    {
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.Cerec),
      ]),
      duration: 90,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LeakingRestoration,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { slug: 'pr-cerec', name: 'Cerec' },
        colour: { name: 'Orange a400', value: '#ff9100' },
      }),
      serviceCodeGroups: [
        groupBuilder.optional(groupBuilder.code(22), groupBuilder.code(577)),
      ],
      description:
        'Indirect chairside restoration of a tooth with an inlay/onlay using CEREC',
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      name: TreatmentConfigurationName.CerecInlayOnlayChairsideIndirectRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Cerec consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Select a movie/music for appointment',
        }),
      ],
    },
    {
      availableSurfaces: [ChartableSurface.WholeMouth],
      // TODO: Move this to seeded data
      // automatedNotifications: [
      //   {
      //     type: AutomatedNotificationType.EMAIL,
      //     template: invisalignExperienceTemplate,
      //     name: 'Feedback about Invisalign Experience',
      //     timing: {
      //       unit: TimingUnit.Days,
      //       amount: 1,
      //       direction: TimingDirection.After,
      //     },
      //     subject: 'Your Invisalign Treatment',
      //   },
      //   {
      //     template: invisalignRetainerReminder,
      //     subject: 'The importance of your retainers',
      //     type: AutomatedNotificationType.EMAIL,
      //     timing: {
      //       direction: TimingDirection.After,
      //       unit: TimingUnit.Days,
      //       amount: 2,
      //     },
      //     name: 'Importance of retainers',
      //   },
      // ],
      duration: 20,
      name: TreatmentConfigurationName.InvisalignIssueRetainers,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos,
      ]),
      description: 'Invisalign Issue Retainers',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Congratulate patient on completing their Invisalign journey!',
        }),
      ],
      display: ChartedItemDisplay.init({
        colour: { value: '#1de9b6', name: 'Teal a400' },
        icon: { name: 'Arch', slug: 'pr-arch' },
      }),
      serviceCodeGroups: [
        groupBuilder.optional(groupBuilder.code(811, 2)),
        groupBuilder.optional(groupBuilder.code(845, 2)),
      ],
    },
    {
      duration: 0,
      display: ChartedItemDisplay.init({
        colour: { name: 'Red a100', value: '#ff8a80' },
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
      }),
      name: TreatmentConfigurationName.DentalTreatment,
    },
    {
      description: 'Dermal fillers',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      availableSurfaces: [],
      name: TreatmentConfigurationName.DermalFillers,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Dermal filler consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure pt has post op instructions/kit',
        }),
      ],
      duration: 60,
      display: ChartedItemDisplay.init({
        colour: { value: '#b388ff', name: 'Deep Purple a100' },
        icon: { name: 'Whitening', slug: 'pr-whitening' },
      }),
    },
    {
      availableSurfaces: [],
      name: TreatmentConfigurationName.NitrousOxideInhalationSedation,
      description:
        'A sedative gas mixed with oxygen is inhaled by the patient while dental treatment is being provided. Per 30mins or part thereof.',
      duration: 5,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.DentalAnxiety,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Blue a200', value: '#448aff' },
        icon: { name: 'X-rays', slug: 'pr-x-rays' },
      }),
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(943))],
    },
    {
      name: TreatmentConfigurationName.PostAndCorePlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.WholeTooth],
      description:
        'Bonding of post and core, often followed by crown preparation',
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(625), groupBuilder.code(22)),
      ],
      duration: 45,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      display: ChartedItemDisplay.init({
        icon: { name: 'Core, Post & Crown', slug: 'pr-core-post-and-crown' },
        hideWhenResolved: false,
        colour: { value: '#536dfe', name: 'Indigo a200' },
      }),
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(415),
          groupBuilder.code(416, 0),
          groupBuilder.code(22)
        ),
        groupBuilder.optional(
          groupBuilder.code(416),
          groupBuilder.code(445),
          groupBuilder.code(455)
        ),
      ],
      name: TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Endodontics,
        treatmentCategories
      ).ref,
      duration: 60,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallPostOperativeDiscomfort,
      ]),
      display: ChartedItemDisplay.init({
        colour: { value: '#616161', name: 'Grey a700' },
        icon: { slug: 'pr-extirpation', name: 'Extirpation' },
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Root Canal Treatment consent form',
        }),
      ],
      description:
        'Complete chemo-mechanical preparation of a root canal system of a tooth including removal of pulp and/or necrotic debris within the canals of a tooth',
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        hasFill: true,
        colour: { name: 'Yellow a400', value: '#ffea00' },
        icon: { name: 'Abrasion', slug: 'pr-abrasion' },
      }),
      duration: 60,
      description:
        'Direct tooth-coloured restoration using an adhesive technique',
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.RestorativeComposite),
      ]),
      name: TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(521),
          groupBuilder.code(522),
          groupBuilder.code(523),
          groupBuilder.code(524),
          groupBuilder.code(525),
          groupBuilder.code(531),
          groupBuilder.code(532),
          groupBuilder.code(533),
          groupBuilder.code(534),
          groupBuilder.code(535),
          groupBuilder.code(578),
          groupBuilder.code(411),
          groupBuilder.code(575)
        ),
      ],
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Abfraction,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Attrition,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbrasionToothbrush,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Erosion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LeakingRestoration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.EnamelDefect,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivitySweet,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Wear,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(949))],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.DentalAnxiety,
          conditionConfigurations
        ).ref,
      ],
      description: 'Treatment to be performed under IV sedation',
      duration: 0,
      name: TreatmentConfigurationName.IVSedation,
      availableSurfaces: [],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Ensure patient has not had any food or drink',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title:
            'Contact details of person collecting the patient after the appointment',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Ensure next review appointment is booked',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Settle account prior to starting appointment',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title:
            'Consent forms and post-op instructions/medications prior to starting appointment',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure patient is accompanied by the responsible person collecting them',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Issue written post-treatment instructions/letter',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Print doctors certificate if required',
        }),
      ],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { value: '#40c4ff', name: 'Light Blue a200' },
        icon: { name: 'Peridontal', slug: 'pr-periodontal' },
      }),
    },
    {
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(615)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      duration: 45,
      name: TreatmentConfigurationName.CrownIssueBondingVeneeredPFM,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      description:
        'Bonding or cementation of final veneered (eg, porcelain fused to metal) crown',
      availableSurfaces: [ChartableSurface.Crown],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { value: '#bcaaa4', name: 'Brown a200' },
        icon: { name: 'Crown', slug: 'pr-crown-small' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure correct record/registration of controlled drugs',
        }),
      ],
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(927))],
      description: 'Prescription or provision of medication after diagnosis',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Candidiasis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LichenPlanus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Ulcer,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
      ],
      duration: 10,
      name: TreatmentConfigurationName.PrescriptionProvisionOfMedication,
      display: ChartedItemDisplay.init({
        icon: { name: 'Consultation', slug: 'pr-consultation' },
        colour: { name: 'Orange a100', value: '#ffd180' },
      }),
      availableSurfaces: [],
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Consent form for Internal Bleaching',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(117)),
        groupBuilder.optional(
          groupBuilder.code(118),
          groupBuilder.code(22),
          groupBuilder.code(72)
        ),
      ],
      name: TreatmentConfigurationName.InternalBleachingPlacement,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.PreventativeProphylacticBleachingServices,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallBleaching,
      ]),
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-whitening', name: 'Whitening' },
        colour: { value: '#d500f9', name: 'Purple a400' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PulpCanalObliteration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Discolouration,
          conditionConfigurations
        ).ref,
      ],
      description: 'Placement of bleaching material',
      duration: 30,
      availableSurfaces: [ChartableSurface.WholeTooth],
    },
    {
      duration: 60,
      serviceCodeGroups: [
        groupBuilder.exclusive(
          groupBuilder.code(613),
          groupBuilder.code(615),
          groupBuilder.code(618),
          groupBuilder.code(649),
          groupBuilder.code(611)
        ),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      name: TreatmentConfigurationName.BridgeIssueAbutmentsOrRetainers,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        colour: { name: 'Cyan a700', value: '#00b8d4' },
        icon: { name: 'Crown', slug: 'pr-crown-small' },
        hideWhenResolved: false,
      }),
      description:
        'Issuing of the abutments or retainers within a fixed bridge. This treatment is for abutment/retainer teeth only. Pontic(s) are to be itemised separately',
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
    },
    {
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(13))],
      description:
        'A planned review of recent treatment including suture removal, minor adjustments or polishing.',
      availableSurfaces: [],
      duration: 15,
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-exam-periodic', name: 'Exam - Periodic' },
        colour: { value: '#1de9b6', name: 'Teal a400' },
      }),
      name: TreatmentConfigurationName.ReviewOfRecentTreatment,
    },
    {
      description:
        'The joining of adjacent teeth to provide mutual support (where not covered by item 386). This item includes the subsequent removal of splinting material. Per tooth.',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.MobileTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
      ],
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(981))],
      duration: 45,
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.SplintingAndStabilisation,
      display: ChartedItemDisplay.init({
        colour: { value: '#ff80ab', name: 'Pink a100' },
        icon: { name: 'Bridge', slug: 'pr-bridge' },
      }),
    },
    {
      display: ChartedItemDisplay.init({
        colour: { value: '#40c4ff', name: 'Light Blue a200' },
        icon: { slug: 'pr-x-rays-3d', name: 'X-rays - 3D' },
      }),
      duration: 15,
      availableSurfaces: [ChartableSurface.Arch],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(26), groupBuilder.code(88)),
      ],
      description: 'CBCT - Single Arch - Acquisition & Interpretation',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImplantFixture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootCanalTreatment,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ImpactedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.CBCTSingleArchAcquisitionAndInterpretation,
    },
    {
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-whitening', name: 'Whitening' },
        colour: { value: '#ffea00', name: 'Yellow a400' },
      }),
      description:
        'Drainage and/or irrigation of an abscess through an incision or inserted tube',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Swelling,
          conditionConfigurations
        ).ref,
      ],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(392)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      name: TreatmentConfigurationName.DrainingOfAbscess,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 30,
    },
    {
      name: TreatmentConfigurationName.AcutePeriodontalInfectionTreatment,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Periodontics,
        treatmentCategories
      ).ref,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      duration: 20,
      description:
        'Treatment of an acute periodontal infection, per appointment.',
      serviceCodeGroups: [
        groupBuilder.exclusive(groupBuilder.code(213)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(221),
          groupBuilder.code(222),
          groupBuilder.code(141),
          groupBuilder.code(142),
          groupBuilder.code(113)
        ),
      ],
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.Quadrant,
      ],
      display: ChartedItemDisplay.init({
        icon: { name: 'Pericoronitis', slug: 'pr-pericoronitis' },
        colour: { name: 'Green a700', value: '#00c853' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pericoronitis,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(71, 2),
          groupBuilder.code(22),
          groupBuilder.code(72)
        ),
      ],
      description:
        'Implant impression with impression coping for fabrication of final implant abutment and crown',
      display: ChartedItemDisplay.init({
        colour: { name: 'Grey a700', value: '#616161' },
        icon: { slug: 'pr-implant', name: 'Implant' },
      }),
      duration: 30,
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.ImplantImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
    },
    {
      duration: 10,
      name: TreatmentConfigurationName.RecontourPolishRestoration,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-linea-alba', name: 'Linea Alba' },
        colour: { name: 'Teal a200', value: '#64ffda' },
      }),
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(113))],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Overhang,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityTouchTapping,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityGeneral,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityCold,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityHot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityPostOperative,
          conditionConfigurations
        ).ref,
      ],
      description: 'Recontour / polish existing restoration',
    },
    {
      name: TreatmentConfigurationName.Pulpotomy,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Endodontics,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        colour: { value: '#aa00ff', name: 'Purple a700' },
        hideWhenResolved: false,
        icon: { name: 'Extirpation', slug: 'pr-extirpation' },
      }),
      duration: 30,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(414)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      description:
        'Amputation within the pulp chamber of part of the vial pulp of a tooth. The pulp remaining is then covered with a protective dressing or cement.',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
    },
    {
      name: TreatmentConfigurationName.BoneGraftBlock,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.Quadrant,
      ],
      description:
        'A surgical procedure in which a block of osseous bone is used for augmentation of a bony ridge secured my screws or fixture devices',
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(244)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(232),
          groupBuilder.code(233),
          groupBuilder.code(245)
        ),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { slug: 'pr-consultation', name: 'Consultation' },
        colour: { value: '#b9f6ca', name: 'Green a100' },
      }),
      duration: 90,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Bone graft consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
    },
    {
      description:
        'Indirect chair-side restoration of a tooth with a full crown using CEREC',
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { name: 'Cerec', slug: 'pr-cerec' },
        colour: { name: 'Orange a400', value: '#ff9100' },
      }),
      duration: 120,
      availableSurfaces: [ChartableSurface.WholeTooth],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(613)),
        groupBuilder.optional(groupBuilder.code(22)),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Cerec consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Select a movie/music for appointment',
        }),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LostFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenFilling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ChippedBrokenTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.LeakingRestoration,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.CerecFullCrown,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
    },
    {
      description: 'Invisalign consultation',
      availableSurfaces: [ChartableSurface.Unscoped],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Crowding,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Spacing,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ClassIIMalocclusion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.ClassIIIMalocclusion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DeepBite,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AnteriorOpenBite,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PosteriorCrossBite,
          conditionConfigurations
        ).ref,
      ],
      name: TreatmentConfigurationName.InvisalignConsultation,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.ClearAligners,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        colour: { name: 'Light Blue a700', value: '#0091ea' },
        hideWhenResolved: false,
        icon: { slug: 'pr-arch-consultation', name: 'Arch - Consultation' },
      }),
      duration: 20,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(15)),
        groupBuilder.optional(
          groupBuilder.code(37),
          groupBuilder.code(36),
          groupBuilder.code(81),
          groupBuilder.code(82)
        ),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendFollowUpEmailForWorkup,
      ]),
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Provide treatment plan and quote',
        }),
      ],
    },
    {
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure patient has oral hygiene aids if required',
        }),
      ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PoorOralHygiene,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Caries,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.EarlyDemineralisationLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbrasionToothbrush,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivalRecession,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Staining,
          conditionConfigurations
        ).ref,
      ],
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        icon: { name: 'Consultation', slug: 'pr-consultation' },
        colour: { name: 'Cyan a200', value: '#18ffff' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(141)),
        groupBuilder.optional(groupBuilder.code(131)),
      ],
      description: 'OHI and other preventative therapies such dietary analysis',
      availableSurfaces: [],
      duration: 10,
      name: TreatmentConfigurationName.OralHygieneInstructions,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Hygiene,
        treatmentCategories
      ).ref,
    },
    {
      // automatedNotifications: [
      //   {
      //     name: 'Post-appointment feedback email',
      //     type: AutomatedNotificationType.EMAIL,
      //     subject: `Yesterday's dental appointment`,
      //     timing: {
      //       amount: 1,
      //       direction: TimingDirection.After,
      //       unit: TimingUnit.Days,
      //     },
      //     template: yesterdaysAppointmentTemplate,
      //   },
      // ],
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PoorOralHygiene,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisGeneralised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.GingivitisLocalised,
          conditionConfigurations
        ).ref,
      ],
      duration: 20,
      description:
        'Standard removal of calculus, plaque and staining and application of remineralising agent. Does not include debridement of deep periodontal pockets - these are to be itemised separately.',
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Update patient medical history',
        }),
      ],
      display: ChartedItemDisplay.init({
        colour: { name: 'Purple a200', value: '#e040fb' },
        icon: { name: 'Scale & Clean', slug: 'pr-scale-and-clean' },
      }),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(114), groupBuilder.code(121)),
        groupBuilder.optional(
          groupBuilder.code(111),
          groupBuilder.code(131),
          groupBuilder.code(141),
          groupBuilder.code(221),
          groupBuilder.code(222),
          groupBuilder.code(115),
          groupBuilder.code(22)
        ),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      name: TreatmentConfigurationName.ScaleAndClean,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Hygiene,
        treatmentCategories
      ).ref,
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallTeethPrep,
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      description: 'Preparation and impression or scan for veneers',
      name: TreatmentConfigurationName.VeneerPreparationAndImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Veneers consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Photo consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Send patient contact details for lab if required',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.optional(
          groupBuilder.code(71, 2),
          groupBuilder.code(72),
          groupBuilder.code(73)
        ),
      ],
      availableSurfaces: [ChartableSurface.WholeTooth],
      duration: 120,
      display: ChartedItemDisplay.init({
        icon: { name: 'Veneer', slug: 'pr-veneer' },
        colour: { name: 'Deep Purple a100', value: '#b388ff' },
      }),
    },
    {
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      description:
        'Periodontal surgery involving one tooth to aid in the resolution of a localised periodontal inflammatory condition',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pericoronitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseLocalised,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalDiseaseGeneralised,
          conditionConfigurations
        ).ref,
      ],
      duration: 60,
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(245))],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-partially-erupted', name: 'Partially Erupted' },
        colour: { value: '#00c853', name: 'Green a700' },
      }),
      availableSurfaces: [
        ChartableSurface.WholeTooth,
        ChartableSurface.MultipleTeeth,
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Periodontal Surgery consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
      ],
      name: TreatmentConfigurationName.PeriodontalSurgery1Tooth,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Periodontics,
        treatmentCategories
      ).ref,
    },
    {
      duration: 45,
      description: 'Removal of a tooth or part thereof - standard.',
      display: ChartedItemDisplay.init({
        colour: { value: '#ff8a80', name: 'Red a100' },
        icon: { slug: 'pr-extraction', name: 'Extraction' },
        disablesSurface: true,
        hideWhenResolved: false,
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Extraction consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      name: TreatmentConfigurationName.ExtractionStandard,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Swelling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.NecroticPulp,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RetainedRoot,
          conditionConfigurations
        ).ref,
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(311)),
        groupBuilder.optional(
          groupBuilder.code(22),
          groupBuilder.code(927),
          groupBuilder.code(243),
          groupBuilder.code(235)
        ),
      ],
    },
    {
      duration: 45,
      name: TreatmentConfigurationName.ExtractionStandardSectionalSurgical,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      description: 'Removal of a tooth or part(s) thereof',
      serviceCodeSmartGroups: compact([
        getSmartGroupByName(ServiceSmartGroupName.Extraction),
      ]),
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { name: 'Extraction', slug: 'pr-extraction' },
        hideWhenResolved: false,
        disablesSurface: true,
        colour: { name: 'Red a400', value: '#ff1744' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Crack,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootFracture,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.NecroticPulp,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.InfectedTooth,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.Swelling,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriodontalAbscess,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.RootResorption,
          conditionConfigurations
        ).ref,
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Extraction consent form',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
        }),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallExtraction,
      ]),
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(22))],
    },
    {
      description: 'Post and core preparation and impression',
      duration: 45,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos,
      ]),
      serviceCodeGroups: [groupBuilder.optional(groupBuilder.code(22))],
      name: TreatmentConfigurationName.PostAndCorePreparationAndImpression,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.Prosthodontics,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        colour: { value: '#64dd17', name: 'Light Green a700' },
        icon: { name: 'Core, Post & Crown', slug: 'pr-core-post-and-crown' },
      }),
      availableSurfaces: [ChartableSurface.WholeTooth],
    },
    {
      duration: 45,
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCall,
      ]),
      name: TreatmentConfigurationName.RootCanalEmergencyExtirpation,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(419)),
        groupBuilder.optional(
          groupBuilder.code(13),
          groupBuilder.code(61),
          groupBuilder.code(22),
          groupBuilder.code(572),
          groupBuilder.code(26),
          groupBuilder.code(87),
          groupBuilder.code(88),
          groupBuilder.code(89)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: 'Ensure post-treatment instructions and medication given',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title: `Print doctor's certificate if required`,
          data: 'To whom it may concern,\nThis letter is to certify that {{patientFullName}} will be unfit to attend work from {{appointmentStartDate}} for a period of 1/2/3/4/5 days due to dental conditions/treatment. \n{{appointmentPractitionerFullName}}\n{{practiceName}}',
          action: ChecklistItemAction.Print,
        }),
      ],
      description:
        'Removal of pulp or debris from the root canal system of a tooth. This is an emergency procedure distinct from visits for scheduled endodontic treatment. Temporisation, other than the closure of an access cavity should be itemised separately.',
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { name: 'Extirpation', slug: 'pr-extirpation' },
        hideWhenResolved: false,
        colour: { name: 'Red a700', value: '#d50000' },
      }),
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Pulpitis,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PainOnBiting,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityHot,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.SensitivityPostOperative,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.AbscessPeriapicalLesion,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.DrainingSinus,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.NecroticPulp,
          conditionConfigurations
        ).ref,
      ],
    },
    {
      display: ChartedItemDisplay.init({
        hideWhenResolved: false,
        colour: { value: '#40c4ff', name: 'Light Blue a200' },
        icon: { slug: 'pr-arch-exam', name: 'Arch - Exam' },
      }),
      description:
        'MAS impressions / scan and recording of centric and protruded position',
      name: TreatmentConfigurationName.MandibularAdvancementSplintImpressionsScan,
      availableSurfaces: [ChartableSurface.WholeMouth],
      duration: 30,
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title: 'Mandibular advancement splint consent form',
        }),
      ],
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(71, 2)),
        groupBuilder.optional(groupBuilder.code(963), groupBuilder.code(964)),
      ],
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.SendLabJobAndPrescription,
      ]),
    },
    {
      availableSurfaces: [ChartableSurface.WholeTooth],
      display: ChartedItemDisplay.init({
        icon: { slug: 'pr-partially-erupted', name: 'Partially Erupted' },
        hideWhenResolved: false,
        colour: { name: 'Pink a200', value: '#ff4081' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallSoftTissueGraft,
      ]),
      name: TreatmentConfigurationName.GingivalSoftTissueGraft,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.OralSurgery,
        treatmentCategories
      ).ref,
      conditions: [
        getConditionByName(
          ConditionConfigurationName.GingivalRecession,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.PeriImplantitis,
          conditionConfigurations
        ).ref,
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Ensure pt has post operative kit: instructions, medications, mouth-rinse, gauze etc.',
        }),
      ],
      serviceCodeGroups: [groupBuilder.required(groupBuilder.code(235))],
      description: `Per tooth, implant or extraction socket. Transference of gingival tissue from a donor area in the patient's mouth to an area around a tooth, implant or extraction socket to remedy a gingival deficiency`,
      duration: 60,
    },
    {
      description: 'Issuing of custom trays and bleaching material',
      serviceCodeGroups: [
        groupBuilder.required(
          groupBuilder.code(119, 2),
          groupBuilder.code(926, 2),
          groupBuilder.code(927)
        ),
      ],
      checklists: [
        ChecklistItem.init({
          type: ChecklistType.Pre,
          title:
            'Ensure whitening kit is ready with trays, container, bleaching material and instructions',
          data: 'Issue whitening trays with case, whitening product/bleaching gel and instructions. \n\nCheck for any upcoming appointments; whitening must cease 1-2 weeks before any restorative treatment. \n\n\n',
        }),
        ChecklistItem.init({
          type: ChecklistType.Post,
          title:
            'Provide teeth whitening instructions and a demonstration of process',
        }),
      ],
      name: TreatmentConfigurationName.TeethWhiteningIssue,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.TeethWhitening,
        treatmentCategories
      ).ref,
      availableSurfaces: [ChartableSurface.WholeMouth],
      duration: 15,
      display: ChartedItemDisplay.init({
        colour: { value: '#80d8ff', name: 'Light Blue a100' },
        icon: { slug: 'pr-whitening', name: 'Whitening' },
      }),
      generatedTaskRefs: findGeneratedTaskConfigRefs(taskConfigs, [
        DefaultGeneratedTaskConfiguration.OneWeekCheckUpWhitening,
      ]),
    },
    {
      description: 'Odontoplasty / Recontouring tooth structure',
      conditions: [
        getConditionByName(
          ConditionConfigurationName.Overhang,
          conditionConfigurations
        ).ref,
        getConditionByName(
          ConditionConfigurationName.OverEruptedTooth,
          conditionConfigurations
        ).ref,
      ],
      availableSurfaces: [
        ChartableSurface.Facial,
        ChartableSurface.Mesial,
        ChartableSurface.Occlusal,
        ChartableSurface.Distal,
        ChartableSurface.Lingual,
      ],
      duration: 10,
      serviceCodeGroups: [
        groupBuilder.required(groupBuilder.code(171)),
        groupBuilder.optional(groupBuilder.code(982)),
      ],
      name: TreatmentConfigurationName.OdontoplastyRecontouringToothStructure,
      category: getTreatmentCategoryByName(
        TreatmentCategoryName.RestorativeServices,
        treatmentCategories
      ).ref,
      display: ChartedItemDisplay.init({
        colour: { value: '#82b1ff', name: 'Blue a100' },
        icon: { name: 'Linea Alba', slug: 'pr-linea-alba' },
      }),
    },
  ];

  return newMocks.map((treatment: Partial<ITreatmentConfiguration>) =>
    TreatmentConfiguration.init(treatment)
  );
}

// https://app.clickup.com/t/1rpmjt8
// const newPatientFeedbackTemplate: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `Thanks for choosing {{practiceName}}. We hope your first experience with us was a positive one. We'd love your feedback about your appointment yesterday. Is there anything we could have done to make it better?`,
//   `Moving forward, if you ever need to get in touch, please don't hesitate to contact us anytime at {{practicePhoneNumber}} or via email on {{practiceEmail}}.`,
// ]);

// const invisalignExperienceTemplate: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `Congratulations on completing your Invisalign journey! We love the results and hope your Invisalign experience with us was a positive one.`,
//   `Is there anything we could have done to make it better for you? Your feedback would be most appreciated.`,
//   `Please rate our service out of 5.`,
//   `Have a great day and we look forward to seeing you again soon.`,
//   `{{practiceName}}`,
// ]);

// const invisalignRetainerReminder: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `Congratulations on completing your invisalign journey with us!`,
//   `In order to maintain the excellent results you've gained through many months of aligner wear, it's critical to wear your retainers as prescribed to avoid the risk of orthodontic relapse. Without retainers, teeth can move back to their old position quite easily, especially in the first year or two after orthodontic treatment. So remember to put your retainers somewhere you'll remember to wear every night and enjoy the results you've gained - long term!`,
//   `{{practiceName}} - {{practicePhoneNumber}}.`,
// ]);

// const welcomeToInvisalignTemplate: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `Congratulations on starting your Invisalign journey! It's completely normal in the first week of treatment to experience some discomfort, but hang in there as it usually disappears after a few days.`,
//   `Here are some tips to maximise the success of your treatment:`,
//   `1) wear your aligners for 22 hrs/day - no less`,
//   `2) use your chewies for a few minutes per day to compress the aligners over your teeth`,
//   `3) remove your aligners to eat meals or if drinking highly coloured or sugary drinks`,
//   `4) maintain good oral hygiene practices throughout treatment; daily brushing and flossing`,
//   `5) do not throw away your used aligners; put them back in their numbered packet and store`,
//   `6) if you lose your aligner, wear your previous aligner immediately and contact us; do not stop wearing your aligners`,
//   `7) watch closely as your teeth get closer to your dream smile week by week!`,
//   `{{practiceName}} - {{practicePhoneNumber}}`,
// ]);

// const yesterdaysAppointmentTemplate: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `We hope your appointment experience with us yesterday was a positive one. Is there anything we could have done to make it better for you? Your feedback would be most appreciated.`,
//   `Have a great day and we look forward to seeing you again soon.`,
//   `{{practiceName}}`,
// ]);

// const postImpressionWhiteningTemplate: VersionedSchema = initVersionedSchema([
//   `Hi {{patientFirstName}},`,
//   `We will contact you as soon as your teeth whitening kit is ready to pick up. In the meantime, to reduce sensitivity, please use sensitive toothpaste or other desensitising products before starting whitening and throughout the whitening process.`,
//   `If you have any questions please contact us on {{practicePhoneNumber}}.`,
// ]);
