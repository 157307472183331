import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IChartElement {
  xFlipped: boolean;
  yFlipped: boolean;
  xPos: number;
  yPos: number;
  width: number;
  height: number;
  transform: string;
}

export class ChartElement {
  xPos$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  yPos$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  width$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  height$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  xFlipped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  yFlipped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  scale$: Observable<string>;
  translate$: Observable<string>;
  transform$: Observable<string>;

  constructor() {
    this.scale$ = combineLatest([this.xFlipped$, this.yFlipped$]).pipe(
      map((args: [boolean, boolean]) => ChartElement.scale(...args))
    );

    this.translate$ = combineLatest([
      this.xFlipped$,
      this.yFlipped$,
      this.xPos$,
      this.yPos$,
      this.width$,
      this.height$,
    ]).pipe(
      map((args: [boolean, boolean, number, number, number, number]) =>
        ChartElement.translate(...args)
      )
    );

    this.transform$ = combineLatest([this.translate$, this.scale$]).pipe(
      map(([translate, scale]: [string, string]) => `${translate} ${scale}`)
    );
  }

  static fromInterface(data: IChartElement): ChartElement {
    const element = new ChartElement();
    element.width = data.width;
    element.height = data.height;
    element.xFlipped = data.xFlipped;
    element.yFlipped = data.yFlipped;
    element.xPos = data.xPos;
    element.yPos = data.yPos;
    return element;
  }

  static getLargestWidth(elements: IChartElement[]): number {
    return elements.reduce((largest: number, element: IChartElement) => {
      return largest > element.width ? largest : element.width;
    }, 0);
  }

  static getLargestHeight(elements: IChartElement[]): number {
    return elements.reduce((largest: number, element: IChartElement) => {
      return largest > element.height ? largest : element.height;
    }, 0);
  }

  static getTotalWidth(elements: IChartElement[]): number {
    return elements.reduce((total: number, element: IChartElement) => {
      return total + element.width;
    }, 0);
  }

  static getTotalHeight(elements: IChartElement[]): number {
    return elements.reduce((total: number, element: IChartElement) => {
      return total + element.height;
    }, 0);
  }

  static calculateTransform(element: IChartElement): string {
    return `${ChartElement.translate(
      element.xFlipped,
      element.yFlipped,
      element.xPos,
      element.yPos,
      element.width,
      element.height
    )} ${ChartElement.scale(element.xFlipped, element.yFlipped)}`;
  }

  /**
   * Compensate for the flipping of axes when translating.
   * Flipping an axis leaves the position on the opposite side.
   */
  static translate(
    xFlipped: boolean,
    yFlipped: boolean,
    xPos: number,
    yPos: number,
    width: number,
    height: number
  ): string {
    const translatedXPos: number = xFlipped ? xPos + width : xPos;
    const translatedYPos: number = yFlipped ? yPos + height : yPos;
    return `translate(${translatedXPos}, ${translatedYPos})`;
  }

  /**
   * Scaling an image by a negative value flips it along that axis.
   */
  static scale(xFlipped: boolean, yFlipped: boolean): string {
    const xScale: number = xFlipped ? -1 : 1;
    const yScale: number = yFlipped ? -1 : 1;
    return `scale(${xScale}, ${yScale})`;
  }

  set xFlipped(xFlipped: boolean) {
    this.xFlipped$.next(xFlipped);
  }

  get xFlipped(): boolean {
    return this.xFlipped$.value;
  }

  set yFlipped(yFlipped: boolean) {
    this.yFlipped$.next(yFlipped);
  }

  get yFlipped(): boolean {
    return this.yFlipped$.value;
  }

  set xPos(xPos: number) {
    this.xPos$.next(xPos);
  }

  get xPos(): number {
    return this.xPos$.value;
  }

  set yPos(yPos: number) {
    this.yPos$.next(yPos);
  }

  get yPos(): number {
    return this.yPos$.value;
  }

  set width(width: number) {
    this.width$.next(width);
  }

  get width(): number {
    return this.width$.value;
  }

  set height(height: number) {
    this.height$.next(height);
  }

  get height(): number {
    return this.height$.value;
  }

  get transform(): string {
    return ChartElement.calculateTransform(this);
  }

  toInterface(): IChartElement {
    return {
      xFlipped: this.xFlipped,
      yFlipped: this.yFlipped,
      xPos: this.xPos,
      yPos: this.yPos,
      width: this.width,
      height: this.height,
      transform: this.transform,
    };
  }
}
