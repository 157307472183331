<mat-toolbar color="accent">
  {{ data.machine ? 'Edit' : 'Create' }} Sterilisation Machine
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col gap-4">
    <mat-form-field>
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" />
      <mat-hint *ngIf="!form.controls.name.value">
        Leave blank to auto-generate from Machine Name
      </mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Location</mat-label>
      <mat-select formControlName="practice" [compareWith]="comparePractice">
        @for (practice of data.practices; track practice.ref.path) {
          <mat-option [value]="practice">
            {{ practice.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="save()"
    >
      {{ data.machine ? 'Update' : 'Create' }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
