import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  MixedSchema,
  getSchemaText,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { compileTemplateSchema } from '@principle-theorem/principle-core';
import {
  TemplateType,
  type ITemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { getError } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-template-preview',
    templateUrl: './template-preview.component.html',
    styleUrls: ['./template-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TemplatePreviewComponent {
  private _template$ = new BehaviorSubject<MixedSchema>(initVersionedSchema());
  private _context$ = new BehaviorSubject<ITemplateContext>({});
  private _type: TemplateType = TemplateType.PlainText;
  result$: Observable<MixedSchema>;
  isHTMLTemplate$ = new BehaviorSubject<boolean>(false);
  plainTextResult$: Observable<string>;
  @HostBinding('class.has-error') templateError?: string;

  @Input()
  set type(type: TemplateType) {
    this._type = type;
    this.isHTMLTemplate$.next(this.type === TemplateType.Html);
  }

  get type(): TemplateType {
    return this._type;
  }

  @Input()
  set template(template: MixedSchema) {
    if (template) {
      this._template$.next(template);
    }
  }

  @Input()
  set context(context: ITemplateContext) {
    if (context) {
      this._context$.next(context);
    }
  }

  constructor() {
    this.result$ = combineLatest([this._template$, this._context$]).pipe(
      map(([template, context]) => {
        return this._compileIgnoreErrors(template, context);
      })
    );
    this.plainTextResult$ = this.result$.pipe(
      map((result) => getSchemaText(result))
    );
  }

  private _compileIgnoreErrors(
    template: MixedSchema,
    context: ITemplateContext
  ): MixedSchema {
    try {
      this.templateError = undefined;
      return compileTemplateSchema(template, context);
    } catch (error) {
      this.templateError = getError(error);
      return initVersionedSchema();
    }
  }
}
