import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { Subject } from 'rxjs';

export interface IReportBuilderMoveToSectionDialogData {
  sectionNames: string[];
}

export interface IReportBuildMoveToSectionFormData {
  sectionName: string;
}

@Component({
  selector: 'pr-report-builder-move-to-section-dialog',
  templateUrl: './report-builder-move-to-section-dialog.component.html',
  styleUrls: ['./report-builder-move-to-section-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderMoveToSectionDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  form = new TypedFormGroup<IReportBuildMoveToSectionFormData>({
    sectionName: new TypedFormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IReportBuilderMoveToSectionDialogData,
    public _dialogRef: MatDialogRef<
      ReportBuilderMoveToSectionDialogComponent,
      IReportBuildMoveToSectionFormData
    >
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
