import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ChecklistItem } from '@principle-theorem/principle-core';
import {
  ChecklistItemAction,
  ChecklistType,
  CHECKLIST_ITEM_ACTIONS,
  CHECKLIST_TYPES,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';

type IChecklistFormData = Omit<IChecklistItem, 'done' | 'deleted'>;
@Component({
    selector: 'pr-add-checklist-items',
    templateUrl: './add-checklist-items.component.html',
    styleUrls: ['./add-checklist-items.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddChecklistItemsComponent {
  trackByAction = TrackByFunctions.variable<ChecklistItemAction>();
  trackByChecklistType = TrackByFunctions.variable<ChecklistType>();
  checklistForm: TypedFormGroup<IChecklistFormData> =
    new TypedFormGroup<IChecklistFormData>({
      title: new TypedFormControl('', Validators.required),
      type: new TypedFormControl(ChecklistType.Pre, Validators.required),
      action: new TypedFormControl(
        ChecklistItemAction.None,
        Validators.required
      ),
      data: new TypedFormControl(''),
    });

  itemActions: ChecklistItemAction[] = CHECKLIST_ITEM_ACTIONS;
  checklistTypes: ChecklistType[] = CHECKLIST_TYPES;

  constructor(
    private _dialogRef: MatDialogRef<
      AddChecklistItemsComponent,
      IChecklistItem
    >,
    @Inject(MAT_DIALOG_DATA) data?: IChecklistItem
  ) {
    if (data) {
      this.checklistForm.patchValue(data);
    }
  }

  save(): void {
    if (!this.checklistForm.valid) {
      return;
    }
    this._dialogRef.close(
      ChecklistItem.init({ ...this.checklistForm.getRawValue() })
    );
  }
}
