import { PrincipleHicapsConnect } from '@principle-theorem/hicaps-connect';
import {
  AnyHicapsConnectExtendedData,
  HicapsConnectExtendedDataTypeGuard,
  IStaffer,
  ITransaction,
  TransactionProvider,
  isDiscountExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import { isNil, omitBy } from 'lodash';
import { BillingRegistry, IBillingEntityInfo } from './billing-registry';

export class TransactionAttributedTo {
  static infer(
    transaction: WithRef<ITransaction>,
    registry: BillingRegistry
  ): DocumentReference<IStaffer> | undefined {
    switch (transaction.provider) {
      case TransactionProvider.Discount:
        return this.inferFromDiscount(transaction.extendedData);
      case TransactionProvider.HicapsConnectHealthFund:
      case TransactionProvider.HicapsConnectMedicare:
      case TransactionProvider.HicapsConnectEftpos:
        return this.inferFromHicaps(transaction.extendedData, registry);
      // TODO: Infer from Tyro
      // TODO: Infer from Medipass
      default:
        return;
    }
  }

  static inferFromDiscount(
    extendedData: unknown
  ): DocumentReference<IStaffer> | undefined {
    if (!isDiscountExtendedData(extendedData)) {
      return;
    }
    return extendedData.practitionerRef;
  }

  static inferFromHicaps(
    extendedData: unknown,
    registry: BillingRegistry
  ): DocumentReference<IStaffer> | undefined {
    if (!HicapsConnectExtendedDataTypeGuard.isAny(extendedData)) {
      return;
    }
    const billingInfo = this.getHicapsBillingEntityInfo(extendedData);
    return registry.findByBillingInfo(billingInfo)?.ref;
  }

  static getHicapsBillingEntityInfo(
    extendedData: AnyHicapsConnectExtendedData
  ): IBillingEntityInfo | undefined {
    if (
      !extendedData.response ||
      !PrincipleHicapsConnect.hasSuccessResult(extendedData.response)
    ) {
      return;
    }
    const providerNumber =
      HicapsConnectExtendedDataTypeGuard.isHealthFund(extendedData) ||
      HicapsConnectExtendedDataTypeGuard.isHealthFundCancel(extendedData)
        ? extendedData.response.data.ProviderNumberId
        : undefined;

    return omitBy(
      {
        providerNumber: providerNumber,
        merchantId: extendedData.response.data.MerchantId,
        terminalId: extendedData.response.data.TerminalId,
      },
      isNil
    );
  }
}
