import { ActivatedRouteSnapshot } from '@angular/router';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';
import { type BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  type IFirestoreModel,
  type ResolverResult,
  type WithRef,
} from '@principle-theorem/shared';

type CurrentModel<T extends WithRef<IFirestoreModel>> =
  BaseCurrentFirestoreModel<T>;

export abstract class FeeScheduleCollectionResolver {
  static readonly resolverKey: string = 'feeScheduleCollection';
  abstract resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IFeeSchedule> | undefined>;
}

export abstract class ScopedFeeScheduleCollectionResolver<
  T extends WithRef<IFirestoreModel>
> extends FeeScheduleCollectionResolver {
  constructor(protected _currentScope: CurrentModel<T>) {
    super();
  }

  abstract override resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IFeeSchedule> | undefined>;
}
