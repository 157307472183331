import {
  initRawSchema,
  InlineNodes,
  ITextNodeSchema,
  NodeSchema,
  RawInlineNodes,
  RawSchemaNodes,
  TextFormatting,
  toMentionContent,
  toTextContent,
  toVersionedSchema,
} from '@principle-theorem/editor';
import {
  IAccountCredit,
  IInteractionV2,
  IInvoice,
  IInvoiceCancellation,
  InteractionType,
  InvoiceStatus,
  IStaffer,
  ITransaction,
  MentionResourceType,
  TransactionAction,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, titlecase, WithRef } from '@principle-theorem/shared';
import { startCase } from 'lodash';
import { stafferToNamedDoc } from '../common';
import {
  formatEnumValueForInteraction,
  Interaction,
} from '../interaction/interaction';
import { toMention } from '../mention/mention';
import { roundTo2Decimals } from '@principle-theorem/accounting';

const ACTOR_DEFAULT = 'Unknown';

export class InvoiceInteractionBuilder {
  static statusChangeInteraction(
    status: InvoiceStatus,
    staffer?: WithRef<IStaffer>
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const formattedStatus = formatEnumValueForInteraction(status);
    return Interaction.init({
      title: [
        getActor(owner),
        toTextContent(` updated status to ${formattedStatus}`),
      ],
      type: InteractionType.Invoice,
      owner,
    });
  }

  static createdInteraction(staffer?: WithRef<IStaffer>): IInteractionV2 {
    const owner = getOwner(staffer);
    const title: RawInlineNodes = [
      getActor(owner),
      toTextContent(` created invoice`),
    ];
    return Interaction.init({
      type: InteractionType.Invoice,
      title,
      owner,
    });
  }

  static cancelledInteraction(
    cancellation: IInvoiceCancellation,
    staffer?: WithRef<IStaffer>
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const formattedStatus = formatEnumValueForInteraction(
      InvoiceStatus.Cancelled
    );
    return Interaction.init({
      type: InteractionType.Invoice,
      owner,
      title: [
        getActor(owner),
        toTextContent(` updated status to ${formattedStatus}`),
      ],
      content: toVersionedSchema(
        initRawSchema([toTextContent(cancellation.reason)])
      ),
    });
  }

  static replacedInteraction(
    replacementInvoice: WithRef<IInvoice>,
    staffer?: WithRef<IStaffer>
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const namedDoc: INamedDocument<IInvoice> = {
      name: replacementInvoice.reference,
      ref: replacementInvoice.ref,
    };

    return Interaction.init({
      type: InteractionType.Invoice,
      owner,
      title: [
        getActor(owner),
        toTextContent(` replaced invoice with `),
        toMentionContent(toMention(namedDoc, MentionResourceType.Invoice)),
      ],
    });
  }

  static addTransactionInteraction(
    transaction: ITransaction,
    action: TransactionAction,
    staffer?: WithRef<IStaffer>
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const message = transactionInteractionContent(transaction, action);
    return Interaction.init({
      title: [
        getActor(owner),
        toTextContent(` ${message} `),
        toTextContent(transaction.reference, undefined, [
          { type: TextFormatting.Code },
        ]),
      ],
      type: InteractionType.Payment,
      owner,
    });
  }

  static updatedDepositPractitioner(
    staffer: WithRef<IStaffer>,
    newPractitioner?: INamedDocument<IStaffer>
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const title: RawInlineNodes = [
      getActor(owner),
      toTextContent(` updated deposit practitioner to `),
      newPractitioner ? getActor(newPractitioner) : toTextContent('none'),
    ];
    return Interaction.init({
      type: InteractionType.Invoice,
      title,
      owner,
    });
  }

  static splitDeposit(
    staffer: WithRef<IStaffer>,
    originalCredit: IAccountCredit,
    newCredit: IAccountCredit
  ): IInteractionV2 {
    const owner = getOwner(staffer);
    const originalDepositAmount = getCurrency(newCredit.amount);
    const newDepositAmount = getCurrency(originalCredit.amount);
    const totalAmount = getCurrency(originalCredit.amount + newCredit.amount);

    const originalCreditAssignee = originalCredit.reservedFor.practitioner
      ? getActor(originalCredit.reservedFor.practitioner)
      : toTextContent(`no one`);
    const newCreditAssignee = newCredit.reservedFor.practitioner
      ? getActor(newCredit.reservedFor.practitioner)
      : toTextContent(`no one`);

    const title: RawInlineNodes = [
      getActor(owner),
      toTextContent(
        ` split a ${totalAmount} deposit. Updating it to a ${originalDepositAmount} deposit reserved for `
      ),
      originalCreditAssignee,
      toTextContent(` and adding a ${newDepositAmount} deposit reserved for `),
      newCreditAssignee,
    ];
    return Interaction.init({
      type: InteractionType.Invoice,
      title,
      owner,
    });
  }
}

function transactionInteractionContent(
  transaction: ITransaction,
  action: TransactionAction
): string {
  const provider = titlecase(startCase(transaction.provider));
  const rawAmount =
    transaction.type === TransactionType.Outgoing
      ? -transaction.amount
      : transaction.amount;
  const amount = rawAmount.toFixed(2);
  const refundAmount = (-rawAmount).toFixed(2);

  const actionMessageMap: Record<TransactionAction, string> = {
    [TransactionAction.Add]: `added a $${amount} ${provider} transaction.`,
    [TransactionAction.Approve]: `approved $${amount} ${provider} transaction.`,
    [TransactionAction.Cancel]: `cancelled $${amount} ${provider} transaction.`,
    [TransactionAction.Refund]: `refunded $${refundAmount} of ${provider} transaction.`,
    [TransactionAction.Update]: `updated $${amount} ${provider} transaction.`,
    [TransactionAction.Delete]: `deleted $${amount} ${provider} transaction.`,
  };
  return actionMessageMap[action];
}

function getActor(
  owner?: INamedDocument<IStaffer>
): NodeSchema<InlineNodes.Mention, RawSchemaNodes> | ITextNodeSchema {
  return owner
    ? toMentionContent(toMention(owner, MentionResourceType.Staffer))
    : toTextContent(ACTOR_DEFAULT);
}

function getOwner(
  staffer?: WithRef<IStaffer> | INamedDocument<IStaffer>
): INamedDocument<IStaffer> | undefined {
  return staffer ? stafferToNamedDoc(staffer) : undefined;
}

function getCurrency(amount: number): string {
  return `$${roundTo2Decimals(amount).toFixed(2)}`;
}
