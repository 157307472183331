import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { CreateCalendarEventActionService } from './create-calendar-event-action.service';
import { PagesModule } from './pages/pages.module';

@NgModule({
  imports: [CommonModule, ComponentsModule, PagesModule],
  exports: [PagesModule],
  providers: [CreateCalendarEventActionService],
})
export class NgCalendarCoreModule {}
