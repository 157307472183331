import { createAction, props } from '@ngrx/store';
import { type AnyChartedItemConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type IQuickChartingConfigurations } from '@principle-theorem/principle-core/interfaces';
import { type IReffable, type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface IQuickChartChange extends IEntityAction<ChartId> {
  quickChartingItem: IReffable<AnyChartedItemConfiguration>;
  itemType: keyof IQuickChartingConfigurations;
}

export const addQuickChartingItem = createAction(
  ChartActionTypes.AddQuickChartingItem,
  props<SerialisedData<IQuickChartChange>>()
);

export const removeQuickChartingItem = createAction(
  ChartActionTypes.RemoveQuickChartingItem,
  props<SerialisedData<IQuickChartChange>>()
);
