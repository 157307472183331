import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorPresetsService } from '@principle-theorem/ng-interactions';
import {
  CustomFormConfiguration,
  Patient,
  PatientForm,
  getCustomFormData,
} from '@principle-theorem/principle-core';
import {
  AnyCustomFormElement,
  ICustomFormConfiguration,
  ICustomFormData,
  IPatient,
  IPatientFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  WithRef,
  firstValueFrom,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomFormToAJSF } from '../../form-builder/lib/custom-form-to-ajsf';

export interface IPreviewFormIssueDialogRequest {
  patientRef: DocumentReference<IPatient>;
  template: INamedDocument<ICustomFormConfiguration>;
}

export interface IPreviewFormIssueDialogResponse {
  formSchema: IPatientFormSchema;
}

@Component({
  selector: 'pr-preview-form-issue-dialog',
  templateUrl: './preview-form-issue-dialog.component.html',
  styleUrl: './preview-form-issue-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'h-full flex flex-col' },
  standalone: false,
})
export class PreviewFormIssueDialogComponent implements OnDestroy {
  private _request = inject<IPreviewFormIssueDialogRequest>(MAT_DIALOG_DATA);
  private _editorPresets = inject(EditorPresetsService);
  private _dialogRef =
    inject<
      MatDialogRef<
        PreviewFormIssueDialogComponent,
        IPreviewFormIssueDialogResponse
      >
    >(MatDialogRef);
  private _onDestroy$ = new Subject<void>();
  layoutChanges$ = new BehaviorSubject<AnyCustomFormElement[] | undefined>(
    undefined
  );
  config$: Observable<WithRef<ICustomFormConfiguration> | undefined>;
  layout$: Observable<AnyCustomFormElement[] | undefined>;
  customFormData$: Observable<ICustomFormData | undefined>;

  constructor() {
    this.config$ = from(Firestore.safeGetDoc(this._request.template.ref));
    const content$ = this.config$.pipe(
      switchMap((config) =>
        config ? CustomFormConfiguration.getContent(config) : of(undefined)
      )
    );

    this.layout$ = content$.pipe(map((content) => content?.layout));

    this.customFormData$ = this.config$.pipe(
      switchMap((config) =>
        config
          ? this._getCustomFormData(this._request.patientRef, config)
          : of(undefined)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const layoutChanges = await firstValueFrom(this.layoutChanges$);
    const customFormData = await firstValueFrom(this.customFormData$);
    const formSchema = this._getPatientFormSchema(
      layoutChanges,
      customFormData
    );
    return this._dialogRef.close({ formSchema });
  }

  private async _getCustomFormData(
    patientRef: DocumentReference<IPatient>,
    config: WithRef<ICustomFormConfiguration>
  ): Promise<ICustomFormData<object> | undefined> {
    const jsonSchema = await CustomFormConfiguration.getJsonSchemaForm(
      config.ref,
      config
    );
    if (!jsonSchema) {
      return;
    }
    if (!config?.prefillFromPreviousSubmission) {
      return getCustomFormData(jsonSchema);
    }
    const forms = await Patient.formsFromTemplate(
      { ref: patientRef },
      config.ref
    );
    const lastSubmitted = PatientForm.latestSubmittedForm(forms);
    return getCustomFormData(jsonSchema, lastSubmitted?.form.data);
  }

  private _getPatientFormSchema(
    layout?: AnyCustomFormElement[],
    customFormData?: ICustomFormData<object>
  ): IPatientFormSchema {
    if (!layout) {
      return customFormData ?? {};
    }
    const extensions = this._editorPresets.defaultToHTMLExtensions();
    const schema = CustomFormToAJSF.transform(layout, extensions);
    return { ...customFormData, ...schema };
  }
}
