import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { ChartOptions, type IChartData } from './chart-builder';

export class AreaChart extends BaseChart {
  data: IChartData = {
    type: ChartType.Area,
    options: ChartOptions.init({
      legend: { position: 'none' },
    }),
  };
}
