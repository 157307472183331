<div class="flex flex-col gap-1 p-4" *ngrxLet="pendingGap$ as pendingGap">
  <pr-timeline-pending-gap-badge [pendingGap]="pendingGap" />
  <div class="mr-6 font-bold">Candidates Shortlisted</div>
  <div class="flex flex-col gap-2">
    @for (candidate of pendingGap.metadata.candidates; track candidate.ref.id) {
      <div class="relative overflow-hidden rounded-sm bg-blue-50 p-2">
        @if (
          candidate | map: getTreatmentCategory$ : this | async;
          as category
        ) {
          <div
            class="absolute bottom-0 left-0 top-0 w-3"
            [matTooltip]="'Treatment Category: ' + category.name"
            [style.backgroundColor]="category.colour.value"
          ></div>
        }
        <div class="ml-3">
          <strong class="truncate">
            {{ candidate.candidate.patient.name }}
          </strong>
          <div class="flex flex-1 flex-col">
            <span class="mat-caption">Portion of gap offered</span>
            <div class="rounded-sm bg-white p-1 text-sm">
              {{ candidate.event.from | moment | amDateFormat: 'hh:mma' }} -
              {{ candidate.event.to | moment | amDateFormat: 'hh:mma' }}
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
