import {
  FormGroup,
  FormControl,
  ValidatorFn,
  AbstractControlOptions,
  AsyncValidatorFn,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { TypeGuardFn } from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export type TypedControl<T> = T extends TypedFormGroup<infer V>
  ? TypedFormGroup<V>
  : T extends TypedFormControl<infer U>
  ? TypedFormControl<U>
  : T extends TypedAbstractControl<infer W>
  ? TypedAbstractControl<W>
  : never;

export type TypedFormGroupControls<K> = {
  [key in keyof K]: TypedControl<K[key]>;
};

export type TypedFormElements<K> = {
  [key in keyof K]-?: TypedAbstractControl<K[key]>;
};

export type TypedFormControlStatus =
  | 'VALID'
  | 'INVALID'
  | 'PENDING'
  | 'DISABLED';

export interface ISetOptionParams {
  onlySelf?: boolean;
  emitEvent?: boolean;
}

export abstract class TypedAbstractControl<T> extends AbstractControl {
  declare readonly valueChanges: Observable<T | undefined>;
  declare readonly statusChanges: Observable<TypedFormControlStatus>;
  declare readonly value: T | undefined | null;
  abstract override reset(value?: T, options?: Record<string, unknown>): void;
}

export class TypedFormGroup<
  T extends object,
  Controls extends TypedFormElements<T> = TypedFormElements<T>
> extends FormGroup {
  declare readonly valueChanges: Observable<T>;
  declare readonly statusChanges: Observable<TypedFormControlStatus>;
  declare readonly value: T;

  declare controls: Controls;

  constructor(
    controls: Controls,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  override getRawValue(): T {
    return super.getRawValue() as T;
  }

  override setValue(value: T, options?: ISetOptionParams): void {
    super.setValue(value, options);
  }

  override patchValue(data: Partial<T>, options?: ISetOptionParams): void {
    return super.patchValue(data, options);
  }
}

export interface IFormState<T> {
  value: T | undefined;
  disabled: boolean;
}

export class TypedFormControl<T> extends FormControl {
  private _guardFn?: TypeGuardFn<T>;
  declare readonly valueChanges: Observable<T>;
  declare readonly statusChanges: Observable<TypedFormControlStatus>;
  declare readonly value: T;

  constructor(
    formState?: T | IFormState<T>,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  override setValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    if (!this._guardFn) {
      super.setValue(value, options);
      return;
    }
    if (this._guardFn(value)) {
      super.setValue(value, options);
      return;
    }
    super.setValue(undefined, options);
  }

  override patchValue(
    data: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    this.setValue(data, options);
  }

  override reset(
    formState?: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    return super.reset(formState, options);
  }

  withGuard(guard: TypeGuardFn<T>): this {
    this._guardFn = guard;
    return this;
  }
}

export class TypedFormArray<T> extends FormArray {
  declare readonly valueChanges: Observable<T[]>;
  declare readonly statusChanges: Observable<TypedFormControlStatus>;
  declare readonly value: T[];

  declare controls: TypedAbstractControl<T>[];

  constructor(
    controls: TypedAbstractControl<T>[] = [],
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  override at(index: number): TypedAbstractControl<T> {
    return super.at(index);
  }

  override push(
    control: TypedAbstractControl<T>,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    return super.push(control, options);
  }

  override insert(
    index: number,
    control: TypedAbstractControl<T>,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    return super.insert(index, control, options);
  }

  override setControl(index: number, control: TypedAbstractControl<T>): void {
    return super.setControl(index, control);
  }

  override setValue(
    value: T[],
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    super.setValue(value, options);
  }

  override patchValue(
    data: Partial<T>[],
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    return super.patchValue(data, options);
  }

  override reset(
    formState?: T[],
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    return super.reset(formState, options);
  }

  override getRawValue(): T[] {
    return super.getRawValue() as T[];
  }
}
