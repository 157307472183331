import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { LabJob, Patient } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type ILabJob,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  multiFilter,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface IPatientLabJobsState {
  labJobs: WithRef<ILabJob>[];
}

const initialState: IPatientLabJobsState = {
  labJobs: [],
};

@Injectable()
export class PatientLabJobsStore extends ComponentStore<IPatientLabJobsState> {
  private _brandScope = inject(CurrentBrandScope);
  labJobs$ = this.select((state) => state.labJobs);

  readonly loadLabJobs = this.effect(
    (patient$: Observable<WithRef<IPatient>>) => {
      return patient$.pipe(
        switchMap((patient) => this._resolveLabJobs$(patient)),
        tap((labJobs) => this.setState({ labJobs }))
      );
    }
  );

  constructor() {
    super(initialState);
  }

  private _resolveLabJobs$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<ILabJob>[]> {
    return this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          (patientReffable) => Patient.labJobs$(patientReffable, brand)
        ).pipe(multiFilter((labJob) => !LabJob.complete(labJob)))
      )
    );
  }
}
