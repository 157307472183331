<pt-profile-pic
  *ngIf="src; else initials"
  [src]="src"
  [diameter]="diameter"
  [matTooltip]="name"
 />

<ng-template #initials>
  <pt-initials-icon
    *ngIf="name"
    [name]="name"
    [diameter]="diameter"
    [matTooltip]="name"
   />
</ng-template>
