import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  SelectionListStore,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  ChartedTreatment,
  FeeScheduleManager,
  stafferToNamedDoc,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IChartedItemSelectionSummary,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedTreatment,
  type IFeeSchedule,
  isChartedMultiStepTreatmentStep,
  type IStaffer,
  isTreatmentStep,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  isReffable,
  isSameRef,
  multiFilter,
  multiSwitchMap,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { first, sortBy } from 'lodash';
import { combineLatest, type Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { MoveChartable, UpdateChartable } from '../../add-chartable';
import { type TreatmentUpdateFn } from '../../charted-surface/add-charted-surface-provider';
import { TreatmentProviderService } from '../../treatment-provider.service';

interface ISelectableTreatmentPlan
  extends Omit<WithRef<ITreatmentPlan>, 'steps'> {
  steps: WithRef<ITreatmentStep>[];
  isCurrentPlan?: boolean;
}

@Component({
    selector: 'pr-charted-item-multi-action-toolbar',
    templateUrl: './charted-item-multi-action-toolbar.component.html',
    styleUrls: ['./charted-item-multi-action-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedItemMultiActionToolbarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByPlan = TrackByFunctions.ref<ISelectableTreatmentPlan>();
  trackByMultiStep = TrackByFunctions.uniqueId<IChartedMultiStepTreatment>();
  multiStepTreatments$: Observable<IChartedMultiStepTreatment[]>;
  multiStepTreatmentsDisabled$: Observable<boolean>;
  treatmentPlans$: Observable<ISelectableTreatmentPlan[]>;
  treatmentPlansDisabled$: Observable<boolean>;
  flaggedTreatmentsDisabled$: Observable<boolean>;

  constructor(
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _chartFacade: ChartFacade,
    private _treatmentProvider: TreatmentProviderService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router,
    public organisation: OrganisationService,
    public selectionList: SelectionListStore<
      IChartedItemSelectionSummary<IChartedTreatment>
    >
  ) {
    this._router.events
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.dismissSelected());

    this.multiStepTreatments$ = this._chartFacade.chartedMultiTreatments$(
      ChartId.InAppointment
    );

    this.multiStepTreatmentsDisabled$ = this.multiStepTreatments$.pipe(
      map((multiTreatments) => !multiTreatments.length)
    );

    this.treatmentPlans$ = combineLatest([
      this._plansInView$(),
      this._allPlans$(),
    ]).pipe(
      map(([currentPlansInView, allPlans]) =>
        allPlans.map((plan) => ({
          ...plan,
          isCurrentPlan: currentPlansInView.some((currentPlan) =>
            isSameRef(currentPlan, plan)
          ),
        }))
      )
    );

    this.treatmentPlansDisabled$ = this.treatmentPlans$.pipe(
      map((plans) => !plans.length)
    );

    this.flaggedTreatmentsDisabled$ = this.selectionList.selected$.pipe(
      map((selected) => selected.every((item) => !item.plan && !item.step))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('document:keydown.escape')
  dismissSelected(): void {
    this.selectionList.resetSelected();
  }

  trackByUuidFn(
    _index: number,
    item: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): string {
    if (isTreatmentStep(item) && isReffable(item)) {
      return item.ref.id;
    }
    return item.uid;
  }

  async confirmDeleteDialog(): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Treatments',
      prompt: 'Are you sure you want to delete the selected treatments?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    await this.deleteTreatments();
  }

  async moveTreatments(
    selectedStep?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>,
    selectedPlan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>
  ): Promise<void> {
    const { plan, step, chartedTreatments } = await this._selectionListData();
    const removeProvider = await this._treatmentProvider.getProvider(
      plan,
      step
    );
    const addProvider = await this._treatmentProvider.getProvider(
      selectedPlan,
      selectedStep
    );

    await new MoveChartable().move(
      chartedTreatments,
      removeProvider,
      addProvider
    );

    await this._updateSelectionList(undefined, selectedStep, selectedPlan);
    this._feedbackMessage(chartedTreatments.length, 'moved');
  }

  async deleteTreatments(): Promise<void> {
    const { plan, step, chartedTreatments } = await this._selectionListData();
    const removeProvider = await this._treatmentProvider.getProvider(
      plan,
      step
    );
    await new MoveChartable().move(chartedTreatments, removeProvider);
    this._feedbackMessage(chartedTreatments.length, 'deleted');
    this.dismissSelected();
  }

  async updateTreatments(updateFn: TreatmentUpdateFn): Promise<void> {
    const { plan, step, chartedTreatments } = await this._selectionListData();
    const updateProvider = await this._treatmentProvider.getProvider(
      plan,
      step
    );

    await new UpdateChartable().update(
      chartedTreatments,
      step?.treatments ?? [],
      updateFn,
      updateProvider
    );

    await this._updateSelectionList(updateFn, step, plan);
    this._feedbackMessage(chartedTreatments.length, 'updated');
  }

  async updateAttributedTo(
    staffer: WithRef<IStaffer> | undefined
  ): Promise<void> {
    await this.updateTreatments((treatment) => ({
      ...treatment,
      attributedTo: staffer ? stafferToNamedDoc(staffer) : undefined,
    }));
  }

  async updateFeeSchedule(feeSchedule: WithRef<IFeeSchedule>): Promise<void> {
    await this.updateTreatments(async (treatment, stepTreatments) =>
      ChartedTreatment.applyFeeSchedule(
        treatment,
        stepTreatments,
        new FeeScheduleManager(of(feeSchedule)),
        true,
        true
      )
    );
  }

  isSameStep$(
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): Observable<boolean> {
    return this.selectionList.selected$.pipe(
      map((selected) =>
        selected.every(
          (item) =>
            this._getStepIdentifier(step) === this._getStepIdentifier(item.step)
        )
      )
    );
  }

  private _getStepIdentifier(
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep> | undefined
  ): string | undefined {
    if (!step) {
      return;
    }
    if (isChartedMultiStepTreatmentStep(step)) {
      return step.uid;
    }
    return step.ref.id;
  }

  private _feedbackMessage(changed: number, action: string): void {
    const message =
      changed > 1 ? `Treatments ${action}` : `Treatment ${action}`;
    this._snackBar.open(message);
  }

  private async _selectionListData(): Promise<{
    chartedTreatments: IChartedTreatment[];
    plan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>;
    step?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>;
  }> {
    const selected = await snapshot(this.selectionList.selected$);
    const plan = first(selected)?.plan;
    const step = first(selected)?.step;
    const chartedTreatments = selected.map(({ item }) => item);
    return { plan, step, chartedTreatments };
  }

  private async _updateSelectionList(
    updateFn?: TreatmentUpdateFn,
    step?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>,
    plan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>
  ): Promise<void> {
    const selected = await snapshot(this.selectionList.selected$);
    this.dismissSelected();

    await asyncForEach(selected, async ({ item }) => {
      const treatments = [...(step?.treatments ?? []), item];
      this.selectionList.setSelected(
        {
          item: updateFn ? await updateFn(item, treatments) : item,
          step,
          plan,
        },
        true
      );
    });
  }

  private _plansInView$(): Observable<WithRef<ITreatmentPlan>[]> {
    return this._treatmentPlanFacade.selectedTreatmentPlan$.pipe(
      switchMap((plan) =>
        plan
          ? TreatmentPlan.getChildren(plan).pipe(
              map((plans) => [plan, ...plans])
            )
          : of([])
      )
    );
  }

  private _allPlans$(): Observable<ISelectableTreatmentPlan[]> {
    return this._treatmentPlanFacade.treatmentPlans$.pipe(
      multiSwitchMap((plan) =>
        this._treatmentPlanFacade.getTreatmentSteps$(plan.ref).pipe(
          multiFilter((step) => !TreatmentStep.isComplete(step)),
          map((steps) => ({ ...plan, steps }))
        )
      ),
      multiFilter((plan) => plan.steps.length > 0),
      map((plans) => sortBy(plans, 'name'))
    );
  }
}
