<mat-toolbar color="accent">
  <span fxFlex>Add Note for {{ data.contact.key }}</span>
</mat-toolbar>

<mat-dialog-content>
  <pr-note-form #form (noteChange)="noteFormData$.next($event)" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.isDisabled$ | async"
      (click)="submit()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
