<div class="flex flex-col gap-2">
  <div class="flex text-base font-bold">
    <div class="flex flex-auto">Treatments</div>
    <div>{{ total$ | async | currency }}</div>
  </div>

  <div *ngIf="treatmentPlan$ | async as treatmentPlan">
    <div class="flex items-center justify-between gap-2">
      <div class="font-bold">{{ treatmentPlan.name }}</div>
      <a
        *ngIf="treatmentPlanLink$ | async as treatmentPlanLink"
        class="text-right"
        [prBrandRouterLink]="treatmentPlanLink"
        >View Plan</a
      >
    </div>
  </div>

  <div>
    <pr-appointment-history-card-treatment
      *ngFor="let treatment of treatments$ | async; trackBy: trackByTreatment"
      [treatment]="treatment"
    />
  </div>
</div>
