import { IXSLXImport } from '@principle-theorem/shared';
import {
  APPOINTMENT_TO_TREATMENT_CATEGORY_HEADERS,
  IAppointmentToTreatmentCategoryXSLX,
} from './appointment-to-treatment-category-to-xslx';
import { Row } from 'exceljs';

export class XSLXToAppointmentCategoryToTreatmentCategory
  implements IXSLXImport<IAppointmentToTreatmentCategoryXSLX>
{
  headers = APPOINTMENT_TO_TREATMENT_CATEGORY_HEADERS;

  translate(row: Row): IAppointmentToTreatmentCategoryXSLX {
    return {
      label: row.getCell('label').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IAppointmentToTreatmentCategoryXSLX['mapTo'],
    };
  }
}
