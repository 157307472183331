import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'pr-google-tag-manager-tracking-code-help',
    imports: [CommonModule],
    templateUrl: './google-tag-manager-tracking-code-help.component.html',
    styleUrl: './google-tag-manager-tracking-code-help.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleTagManagerTrackingCodeHelpComponent {}
