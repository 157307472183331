import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type ISmartpayTerminal,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  sortTimestamp,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ITerminalOption {
  practiceRef: DocumentReference<IPractice>;
  terminal: WithRef<ISmartpayTerminal>;
  isCurrentlyPaired: boolean;
}

export type SmartpayTerminalFormData = Required<
  Pick<ISmartpayTerminal, 'name' | 'uid'>
>;

export interface ISelectedTerminal {
  practiceRef: DocumentReference<IPractice>;
  terminalData: SmartpayTerminalFormData;
}

export interface ISelectTerminalDialogData {
  terminals: WithRef<ISmartpayTerminal>[];
}

@Component({
  selector: 'pr-select-smartpay-terminal',
  templateUrl: './select-smartpay-terminal.component.html',
  styleUrls: ['./select-smartpay-terminal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSmartpayTerminalComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  options$: Observable<ITerminalOption[]>;
  terminals$: Observable<WithRef<ISmartpayTerminal>[]>;
  trackByOptions = TrackByFunctions.ref<ITerminalOption>('terminal.ref');

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISelectTerminalDialogData,
    private _dialogRef: MatDialogRef<
      SelectSmartpayTerminalComponent,
      ISelectedTerminal
    >,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    this.options$ = this._currentScopeFacade.currentPractice$
      .pipe(filterUndefined())
      .pipe(
        map((practice) => this._toTerminalOptions(practice.ref, data.terminals))
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  select(option: ITerminalOption): void {
    this._dialogRef.close(toSelectedTerminal(option));
  }

  private _toTerminalOptions(
    practiceRef: DocumentReference<IPractice>,
    terminals: WithRef<ISmartpayTerminal>[]
  ): ITerminalOption[] {
    const ordered = terminals.sort((terminalA, terminalB) =>
      sortTimestamp(terminalA.lastPaired, terminalB.lastPaired)
    );
    const current = first(ordered);
    return ordered.map((terminal) => ({
      terminal,
      isCurrentlyPaired: isSameRef(current, terminal),
      practiceRef,
    }));
  }
}

export function toSelectedTerminal(
  option: ITerminalOption
): ISelectedTerminal | undefined {
  if (!option.terminal.lastPaired) {
    return;
  }
  return {
    practiceRef: option.practiceRef,
    terminalData: {
      name: option.terminal.name,
      uid: option.terminal.uid,
    },
  };
}
