<form [formGroup]="roleForm" (ngSubmit)="save()">
  <div fxLayout="column" fxLayoutGap="16px" class="layout-margin">
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <pr-permission-selector formControlName="permissions" />

    <pt-buttons-container class="bg-pr-background sticky bottom-0 z-10">
      <button mat-stroked-button type="button" (click)="delete()">
        Delete
      </button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!canSubmit()"
      >
        Save
      </button>
    </pt-buttons-container>
  </div>
</form>
