import { LocationStrategy } from '@angular/common';
import {
  Directive,
  Input,
  Renderer2,
  type OnDestroy,
  ElementRef,
  Attribute,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { StateBasedNavigationService } from './state-based-navigation.service';

@Directive({
    selector: 'a[prPracticeRouterLink], area[prPracticeRouterLink]',
    standalone: false
})
export class PracticeRouterLinkDirective
  extends RouterLinkWithHref
  implements OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _segments$ = new ReplaySubject<string[]>(1);

  constructor(
    private _stateNav: StateBasedNavigationService,
    protected _router: Router,
    route: ActivatedRoute,
    protected _locationStrategy: LocationStrategy,
    _renderer: Renderer2,
    _el: ElementRef,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined
  ) {
    super(_router, route, tabIndexAttribute, _renderer, _el, _locationStrategy);
    this._segments$
      .pipe(
        switchMap((segments) => this._stateNav.link.practice$(segments)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((segments) => (this.routerLink = segments));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set prPracticeRouterLink(segments: string[]) {
    if (segments) {
      this._segments$.next(segments);
    }
  }
}
