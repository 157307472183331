<mat-toolbar color="primary">Edit Chat Members</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <pt-search-field
      icon="person_add"
      ptIntercomTarget="edit-chat-members.member-select"
    >
      <input
        matInput
        placeholder="Add Member"
        [formControl]="searchCtrl"
        [matAutocomplete]="stafferSelect"
      />
      <mat-autocomplete
        #stafferSelect="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayFn"
        (optionSelected)="addMember($event.option.value)"
      >
        <ng-container *ngIf="search.results$ | async as staff">
          <mat-option
            *ngFor="let staffer of staff; trackBy: trackByStaffer"
            [value]="staffer"
          >
            {{ staffer.user.name }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </pt-search-field>

    <mat-list>
      <mat-list-item
        *ngFor="let staffer of selected$ | async; trackBy: trackByStaffer"
      >
        <span matListItemTitle>{{ staffer.user.name }}</span>
        <div matListItemMeta>
          <button mat-icon-button color="warn" (click)="removeMember(staffer)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none" class="flex flex-1">
    <button mat-dialog-close mat-stroked-button>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      ptIntercomTarget="edit-chat-members.save"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
