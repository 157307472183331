<mat-toolbar color="accent">
  {{ data.cycle ? 'Edit' : 'Add' }} Sterilisation Cycle
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col gap-4">
    <div class="flex items-center gap-2">
      <mat-form-field class="flex-1">
        <mat-label>Sterilisation Machine</mat-label>
        <mat-select
          formControlName="machine"
          [compareWith]="isSelectedNamedDocument"
        >
          @for (machine of data.machines; track machine.ref.path) {
            <mat-option [value]="machine">
              {{ machine.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>Cycle ID</mat-label>
        <input matInput formControlName="id" (focusout)="resetOnEmpty()" />
      </mat-form-field>
    </div>

    <div class="flex items-start gap-2">
      <pt-iso-date-selector
        class="w-1/2"
        placeholder="Run Date"
        formControlName="cycleRunDate"
        [max]="maxDate"
        subscriptSizing="dynamic"
      />
      <pt-iso-time-selector
        class="w-1/2"
        label="Run Time"
        formControlName="cycleRunTime"
        floatLabel="always"
        subscriptSizing="dynamic"
      />
    </div>

    <div class="flex items-center gap-2">
      <mat-form-field class="flex-1">
        <mat-label>Cycle Type</mat-label>
        <mat-select
          formControlName="cycleType"
          [compareWith]="isSelectedNamedDocument"
        >
          <mat-select-trigger>
            @if (selectedCycleType$ | async; as cycleType) {
              {{ cycleType.name }}
            }
          </mat-select-trigger>
          @for (cycleType of data.cycleTypes; track cycleType.ref.path) {
            <mat-option [value]="cycleType">
              <div class="flex items-center gap-2">
                {{ cycleType.name }}
                <mat-chip-listbox [selectable]="false">
                  <mat-chip
                    *ngIf="cycleType.isTestType"
                    color="primary"
                    highlighted
                  >
                    TEST
                  </mat-chip>
                </mat-chip-listbox>
              </div>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <pr-staff-selector
        formControlName="staffer"
        class="flex-1"
        [practice]="data.practice"
        [staffer]="staffer$ | async"
        [showClear]="false"
      />
    </div>

    <mat-form-field class="mt-2">
      <mat-label>Cycle Notes</mat-label>
      <pr-content-editor
        placeholder="Cycle Notes"
        format="email"
        formControlName="notes"
        [menuEnabled]="false"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="submit()"
    [disabled]="form.invalid"
  >
    {{ data.cycle ? 'Update' : 'Add' }}
  </button>
</mat-dialog-actions>
