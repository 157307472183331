import { Editor } from '@tiptap/core';
import { ISelectMenuData } from '../../menu-bar/select-menu/select-menu-view';
import { BlockNodes } from '@principle-theorem/editor';
import {
  ITableEditMenuData,
  TableEditMenuComponent,
} from './table-edit-menu/table-edit-menu.component';

export function createTableMenu(
  editor: Editor
): ISelectMenuData<TableEditMenuComponent, ITableEditMenuData> {
  return {
    repositionDialogOnSelectionChange: true,
    shouldShow: (props) => {
      const toNode = props.view.domAtPos(props.to);
      const isCellSelection = (toNode.node as HTMLElement)?.tagName === 'TD';
      return props.editor.isActive(BlockNodes.Table) && isCellSelection;
    },
    pluginKey: `${BlockNodes.Table}-select-menu`,
    nodeType: BlockNodes.Table,
    component: TableEditMenuComponent,
    data: {
      editor,
    },
  };
}
