import { surfaceFromRef } from '@principle-theorem/principle-core';
import {
  type ChartableSurface,
  type IChartedRef,
} from '@principle-theorem/principle-core/interfaces';

export interface IAddChartedItem {
  selectedSurfaces?: Partial<IChartedRef>[];
  name?: string;
}

export interface IAddChartedItemData {
  availableSurfaces: ChartableSurface[];
  name: string;
}

export function toAddChartedItemData(
  addChartedItem: IAddChartedItem
): IAddChartedItemData {
  return {
    name: addChartedItem.name ?? '',
    availableSurfaces: getSurfaces(addChartedItem.selectedSurfaces),
  };
}

export function getSurfaces(
  selectedSurfaces: Partial<IChartedRef>[] = []
): ChartableSurface[] {
  return selectedSurfaces.map((selectedSurface) =>
    surfaceFromRef(selectedSurface)
  );
}
