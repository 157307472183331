<mat-toolbar color="accent"
  ><pr-content-text [content]="data.title" /></mat-toolbar>

<mat-dialog-content>
  <pr-recurring-task-configuration-form
    [configuration]="data.configuration"
    (submitted)="submit()"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.configurationFormGroup.invalid"
      (click)="submit()"
    >
      {{ data.submitLabel }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
