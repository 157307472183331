import { Component } from '@angular/core';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { FactTables } from '@principle-theorem/reporting';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-practitioner-productivity',
    templateUrl: './practitioner-productivity.component.html',
    styleUrls: ['./practitioner-productivity.component.sass'],
    standalone: false
})
export class PractitionerProductivityComponent extends MeasureDataChartComponent {
  tableChartCard: IChartCard;
  timeSpentChartBuilder: ChartBuilder;
  patientSpendChartBuilder: ChartBuilder;

  _redraw(): void {
    this.timeSpentChartBuilder = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            // 'appointmentsDuration',
            FactTables.appointmentEvent.duration.reduceBySum(),
            // 'gapsDuration',
            FactTables.gapEvent.latestEvent.duration.reduceBySum(),
          ],
          FactTables.appointmentEvent.practitioner.name
        )
      )
      .chartBuilder.addChartOptions({ isStacked: true });

    this.patientSpendChartBuilder = this._dualAxisBarChart(
      [
        // 'averageAppointmentDuration',
        FactTables.appointmentEvent.duration.reduceByAverage(),
        // 'averagePatientSpend',
        FactTables.appointmentEvent.treatmentCost.reduceByAverage(),
      ],
      FactTables.appointmentEvent.practitioner.name
    );

    this.tableChartCard = this._tableChartCard(
      [
        // 'gapsDuration',
        FactTables.gapEvent.latestEvent.duration.reduceBySum(),
        // 'averageGapDuration',
        FactTables.gapEvent.latestEvent.duration.reduceByAverage(),
        // 'averagePatientSpend',
        FactTables.appointmentEvent.treatmentCost.reduceByAverage(),
        // 'averageAppointmentDuration',
        FactTables.appointmentEvent.duration.reduceByAverage(),
      ],
      FactTables.appointmentEvent.practitioner.name
    );
  }
}
