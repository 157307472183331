import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type AppointmentItemViewType,
  type IAppointment,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  type INamedDocument,
  multiSwitchMap,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CurrentBrandScope } from '../../app-state/current-brand';
import { GlobalStoreService, TagType } from '../../store/global-store.service';

@Component({
  selector: 'pr-appointment-tags',
  templateUrl: './appointment-tags.component.html',
  styleUrls: ['./appointment-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentTagsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  tagCol$: Observable<CollectionReference<ITag>>;
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  tagsCtrl = new TypedFormControl<INamedDocument<ITag>[]>();
  tagType = TagType.Appointment;
  @Input() viewType: AppointmentItemViewType = 'sidebar';

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(
    brandScope: CurrentBrandScope,
    private _global: GlobalStoreService
  ) {
    this.appointment$
      .pipe(
        map((appointment) => appointment.tags),
        multiSwitchMap((tag) => this._global.getTag$(tag.ref)),
        map(compact),
        takeUntil(this._onDestroy$)
      )
      .subscribe((tags) => this.tagsCtrl.setValue(tags, { emitEvent: false }));

    this.tagCol$ = brandScope.doc$.pipe(
      filterUndefined(),
      map((brand) => Brand.appointmentTagCol(brand))
    );

    this.tagsCtrl.valueChanges
      .pipe(withLatestFrom(this.appointment$), takeUntil(this._onDestroy$))
      .subscribe(
        ([tags, appointment]) => void patchDoc(appointment.ref, { tags })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
