import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

type TeamFormData = Pick<ITeam, 'name'>;

@Component({
    selector: 'pr-team-form',
    templateUrl: './team-form.component.html',
    styleUrls: ['./team-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TeamFormComponent {
  teamForm: TypedFormGroup<TeamFormData> = new TypedFormGroup<TeamFormData>({
    name: new TypedFormControl<string>('', Validators.required),
  });
  @Output() submitted: EventEmitter<Partial<ITeam>> = new EventEmitter<
    Partial<ITeam>
  >();

  @Input()
  set team(team: ITeam) {
    this.teamForm.patchValue(team);
  }

  submit(): void {
    if (this.teamForm.invalid) {
      return;
    }
    this.submitted.next(this.teamForm.value);
  }
}
