import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Patient, PatientForm } from '@principle-theorem/principle-core';
import {
  Gender,
  ICustomFormData,
  IMedicareCard,
  type IPatient,
  type IPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  MILLIS_IN_SECOND,
  MONTH_YEAR_FORMAT,
  resolveProp$,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, combineLatest, of, type Observable } from 'rxjs';
import { delay, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-form-print',
  templateUrl: './patient-form-print.component.html',
  styleUrls: ['./patient-form-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-10 p-4 max-w-6xl mx-auto' },
  standalone: false,
})
export class PatientFormPrintComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = MONTH_YEAR_FORMAT;
  patientForm$: Observable<WithRef<IPatientForm> | undefined>;
  customFormData$: Observable<ICustomFormData | undefined>;
  patient$: Observable<WithRef<IPatient> | undefined>;
  phone$: Observable<string | undefined>;
  address$: Observable<string | undefined>;
  gender$: Observable<Gender | undefined>;
  medicareCard$: Observable<IMedicareCard | undefined>;
  isMedicalHistoryForm$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private _location: Location
  ) {
    this.patientForm$ = resolveProp$<WithRef<IPatientForm>>(
      this._route.data,
      'patientForm'
    );
    this.customFormData$ = this.patientForm$.pipe(
      switchMap((form) =>
        form ? PatientForm.getCustomFormData(form, true) : of(undefined)
      )
    );
    this.patient$ = this.patientForm$.pipe(
      switchMap((form) =>
        form ? Firestore.getDoc(PatientForm.patientRef(form)) : of(undefined)
      )
    );
    this.phone$ = this.patient$.pipe(
      switchMap((patient) =>
        patient ? Patient.resolveFirstContactNumber(patient) : of(undefined)
      )
    );
    this.address$ = this.patient$.pipe(map((patient) => patient?.address));
    this.gender$ = this.patient$.pipe(map((patient) => patient?.gender));
    this.medicareCard$ = this.patient$.pipe(
      map((patient) => patient?.medicareCard)
    );
    this.isMedicalHistoryForm$ = this.patientForm$.pipe(
      map((form) => (form ? PatientForm.isMedicalHistoryForm(form) : false))
    );

    combineLatest([this.customFormData$, this.patient$])
      .pipe(
        map(([formData, patient]) => this._canPrint(formData, patient)),
        delay(MILLIS_IN_SECOND),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((canPrint) => {
        if (canPrint !== undefined) {
          window.print();
        }
        this._location.back();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _canPrint(
    formData?: ICustomFormData,
    patient?: WithRef<IPatient>
  ): boolean {
    return formData !== undefined && patient !== undefined;
  }
}
