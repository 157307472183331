<div *ngIf="invoice$ | async as invoice">
  <pr-invoice-header [invoice]="invoice" />

  <div fxLayout="row" class="layout-margin">
    <div fxFlex="50">
      <h2>From</h2>
      <pr-practice-details-selector
        [brand]="brand$ | async"
        [selected]="invoice.from"
        (selectedChanged)="updateFrom($event)"
      />
    </div>
    <div fxFlex="50">
      <h2>To</h2>
      <pr-invoice-account-details [account]="invoice.to" />
    </div>
  </div>

  <div *ngrxLet="taxRate$ as taxRate" class="layout-margin">
    <pr-invoice-line-item-header />

    <ng-container
      *ngFor="
        let parent of lineItems.withChildren$ | async;
        trackBy: trackByGroup
      "
    >
      <pr-line-item-group [lineItem]="parent">
        <pr-treatment-provider
          class="mat-caption"
          *ngrxLet="parent | map: resolveProvider$ : this as provider"
          [name]="provider?.name"
          [providerNumber]="provider?.providerNumber"
        />
        <pr-invoice-line-item-edit
          *ngFor="let line of parent.items; trackBy: trackByLineItem"
          [lineItem]="line"
          [disabled]="line | map: isLineItemLocked$ : this | ngrxPush"
          (lineItemChanged)="upsertSubLineItem(parent, $event)"
          (openDepositAssignment)="openDepositAssignment($event, taxRate)"
        />
      </pr-line-item-group>
    </ng-container>

    <ng-container
      *ngFor="
        let line of lineItems.withoutChildren$ | async;
        trackBy: trackByLineItem
      "
    >
      <pr-invoice-line-item-edit
        [lineItem]="line"
        [disabled]="line | map: isLineItemLocked$ : this | ngrxPush"
        (lineItemChanged)="upsertLineItem($event, taxRate)"
        (openDepositAssignment)="openDepositAssignment($event, taxRate)"
      />
    </ng-container>

    <pr-line-item-selector
      [treatments]="lineItemsSearch.treatmentWithPlans$ | async"
      [products]="lineItemsSearch.products$ | async"
      [taxRate]="taxRate"
      (optionSelected)="addLineItem($event)"
    />
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="layout-margin">
    <pr-balance-display [balance]="balance$ | async" fxFlex="50" />
  </div>
</div>
