import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { PatientForm } from '@principle-theorem/principle-core';
import {
  PatientFormType,
  type IPatient,
  type IPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, isSameRef, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface IPatientFormState {
  patient: WithRef<IPatient> | undefined;
  form: WithRef<IPatientForm> | undefined;
  relatedForms: WithRef<IPatientForm>[];
  loading: boolean;
  readonly: boolean;
  patientUpdates: Partial<IPatient>;
}

const initialState: IPatientFormState = {
  patient: undefined,
  form: undefined,
  relatedForms: [],
  loading: true,
  readonly: true,
  patientUpdates: {},
};

@Injectable()
export class PatientFormStore extends ComponentStore<IPatientFormState> {
  readonly patient$ = this.select((store) => store.patient);
  readonly form$ = this.select((store) => store.form);
  readonly isPatientDetails$ = this.select(
    this.form$,
    (form) => form?.formType === PatientFormType.PatientDetailsForm
  );
  readonly isCustomForm$ = this.select(
    this.form$,
    (form) => form?.formType === PatientFormType.CustomForm
  );
  readonly relatedForms$ = this.select((store) => store.relatedForms);
  readonly isLatest$ = this.select(
    this.form$,
    this.relatedForms$,
    (form, relatedForms) => {
      const latest = PatientForm.latest(relatedForms);
      return form && latest && isSameRef(form, latest);
    }
  );
  readonly loading$ = this.select((store) => store.loading);
  readonly readonly$ = this.select((store) => store.readonly);
  readonly patientUpdates$ = this.select((store) => store.patientUpdates);
  readonly emptyState$ = this.select(
    this.loading$,
    this.relatedForms$,
    (loading, forms) => !loading && !forms.length
  );

  readonly loadForm = this.effect(
    (form$: Observable<WithRef<IPatientForm> | undefined>) => {
      return form$.pipe(
        switchMap((submittedForm) => this._loadForm(submittedForm))
      );
    }
  );

  readonly loadRelatedForms = this.effect(
    (forms$: Observable<WithRef<IPatientForm>[]>) => {
      return forms$.pipe(
        tap((relatedForms) => {
          this.patchState({ relatedForms });
        })
      );
    }
  );

  constructor() {
    super(initialState);
  }

  setPatientDetailsUpdates(patientDetailsUpdates: Partial<IPatient>): void {
    this.patchState({ patientUpdates: patientDetailsUpdates });
  }

  setReadonly(readonly: boolean): void {
    this.patchState({ readonly });
  }

  private async _loadForm(form?: WithRef<IPatientForm>): Promise<void> {
    if (!form) {
      return this.patchState({
        form: undefined,
        loading: false,
        relatedForms: [],
      });
    }
    const patient = await Firestore.getDoc(PatientForm.patientRef(form));
    const patientUpdates = PatientForm.isPatientDetailsForm(form)
      ? form.form.data
      : {};

    this.patchState({ patient, form, patientUpdates, loading: false });
    this.loadRelatedForms(PatientForm.relatedForms$(form));
  }
}
