<mat-list-item *ngIf="practice$ | async as practice">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <div fxFlex>
      {{ practice.name }}
    </div>
    <div>
      <pt-buttons-container>
        <button mat-icon-button (click)="edit()">
          <mat-icon>edit</mat-icon>
        </button>
      </pt-buttons-container>
    </div>
  </div>
</mat-list-item>
