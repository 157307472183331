import {
  Directive,
  ElementRef,
  EventEmitter,
  type OnDestroy,
  Output,
} from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import { get } from 'lodash';

export interface IRawResizeSensorEvent {
  width: number;
  height: number;
}

export interface IResizeSensorEvent {
  width: number;
  height: number;
  inner: IRawResizeSensorEvent;
}

@Directive({
  selector: '[ptResizeSensor]',
})
export class ResizeSensorDirective implements OnDestroy {
  private _sensor: ResizeSensor;
  private _callback: (size: IRawResizeSensorEvent) => void;
  @Output() resizeEvent = new EventEmitter<IResizeSensorEvent>();

  constructor(private _elementRef: ElementRef<HTMLElement>) {
    this._callback = (event) => this._handleEvent(event);
    this._sensor = new ResizeSensor(
      this._elementRef.nativeElement,
      this._callback
    );
  }

  ngOnDestroy(): void {
    this._sensor.detach(this._callback);
  }

  private _handleEvent(event: IRawResizeSensorEvent): void {
    this.resizeEvent.emit({ ...event, inner: this._getInnerSize(event) });
  }

  private _getInnerSize(event: IRawResizeSensorEvent): IRawResizeSensorEvent {
    const inner = this._safeElementSize(
      get(this._elementRef.nativeElement, 'resizeSensor')
    );
    if (!inner) {
      // eslint-disable-next-line no-console
      console.error('Could not determine ResizeSensor inner values');
      return event;
    }
    return inner;
  }

  private _safeElementSize(elem?: unknown): IRawResizeSensorEvent | undefined {
    if (!elem) {
      return undefined;
    }
    try {
      return this._getElementSize(elem as HTMLElement);
    } catch (e) {
      return;
    }
  }

  private _getElementSize(element: HTMLElement): IRawResizeSensorEvent {
    if (!element.getBoundingClientRect) {
      return {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
    }
    const rect = element.getBoundingClientRect();
    return {
      width: Math.round(rect.width),
      height: Math.round(rect.height),
    };
  }
}
