<mat-toolbar color="accent">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title truncate">{{ label }}</h2>
    <pr-mention-actions
      [resource]="resource$ | async"
      [templateResource]="templateResource$ | async"
      [interactiveResource]="interactiveResource$ | async"
     />
  </mat-toolbar-row>
</mat-toolbar>
