<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="mx-auto flex max-w-5xl flex-col gap-4 p-4">
    <pr-content-container>
      <div class="flex flex-col gap-4">
        <h2 class="text-xl font-bold">Provider Settings</h2>

        <div formArrayName="providerDetails">
          <p class="text-md font-bold">Healthcare Claim Details</p>
          <pr-provider-details-input
            *ngFor="
              let control of providerDetails.controls;
              trackBy: trackByIndex;
              index as index;
              first as isFirst;
              last as isLast
            "
            [form]="control"
          >
            <div class="flex gap-2">
              <button
                mat-icon-button
                [disabled]="isFirst"
                (click)="providerDetailControls.removeControl(index)"
                matTooltip="Remove provider option"
              >
                <mat-icon>remove</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="practices$ | async as practices"
                [disabled]="!isLast || practices.length <= index + 1"
                (click)="providerDetailControls.addControl(index)"
                matTooltip="Add new provider option"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </pr-provider-details-input>
        </div>
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold">Prescription Prescriber Number</p>
          <mat-form-field>
            <mat-label>Prescriber Number</mat-label>
            <input matInput formControlName="prescriberNumber" />
          </mat-form-field>
        </div>
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold">Qualifications</p>
          <mat-form-field>
            <mat-label>Qualifications</mat-label>
            <input matInput formControlName="qualifications" />
            <mat-hint>
              Separate multiple qualifications with a comma e.g. MBBS, FRACGP
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="flex flex-col gap-4">
          <p class="text-md font-bold">Other Details</p>
          <mat-form-field>
            <mat-label>Provider Commission</mat-label>
            <input
              matInput
              type="number"
              min="0"
              max="100"
              formControlName="providerCommission"
            />
            <span matTextSuffix>%</span>
          </mat-form-field>
        </div>
      </div>
    </pr-content-container>

    <pr-content-container>
      <h2 class="text-xl font-bold">Online Profile Settings</h2>

      <div class="flex flex-col gap-4" formGroupName="onlineProfile">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
          <div class="flex flex-col gap-4 md:col-span-3 md:flex-row">
            <pt-profile-image-manager
              [storagePath]="storagePath$ | async"
              [profile]="profile$ | async"
              [diameter]="46"
              (storagePathUrlChange)="updateImageUrl($event)"
            />
            <mat-form-field class="w-full">
              <mat-label>Name</mat-label>

              <input
                [placeholder]="(profile$ | async)?.name"
                matInput
                formControlName="name"
              />
              <mat-hint> Defaults to your user name if left blank. </mat-hint>
            </mat-form-field>
          </div>

          <mat-form-field class="w-full md:col-span-2">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" />
            <mat-hint> e.g Dentist, Orthodontist, Dental Hygienist </mat-hint>
          </mat-form-field>

          <mat-form-field class="w-full md:col-span-1">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              @for (gender of genders; track $index) {
                <mat-option [value]="gender">
                  {{ gender | splitCamel | titlecase }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="w-full">
          <mat-label>Bio</mat-label>
          <textarea matInput formControlName="bio"></textarea>
          <mat-hint>
            A short bio about the provider that will be displayed on their
            online profile.
          </mat-hint>
        </mat-form-field>
      </div>
    </pr-content-container>

    <pr-content-container margin="mb-2" class="sticky bottom-0 z-50">
      <div class="flex items-center justify-end">
        <button mat-flat-button color="primary" [disabled]="form.invalid">
          Save
        </button>
      </div>
    </pr-content-container>
  </div>
</form>
