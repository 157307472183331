import { type IFirestoreModel, isObject } from '@principle-theorem/shared';
import { type IContactable, isContact } from '../contact/contact';

export type ILab = IFirestoreModel &
  IContactable & {
    labJobTypes: ILabJobType[];
  };

export interface ILabJobType extends IFirestoreModel {
  name: string;
  cost: number;
}

export function isLab(item: unknown): item is ILab {
  return (
    isObject(item) &&
    'labJobTypes' in item &&
    Array.isArray(item.labJobTypes) &&
    isContact(item)
  );
}
