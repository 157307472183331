import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IRole } from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-role-assignment',
    templateUrl: './role-assignment.component.html',
    styleUrls: ['./role-assignment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RoleAssignmentComponent {
  private _selected$ = new BehaviorSubject<DocumentReference<IRole>[]>([]);
  trackByRole = TrackByFunctions.ref<WithRef<IRole>>();
  options$ = new ReplaySubject<WithRef<IRole>[]>(1);

  @Output() rolesChange = new EventEmitter<DocumentReference<IRole>[]>();

  @Input()
  set roles(roles: DocumentReference<IRole>[]) {
    this._selected$.next(roles || []);
  }

  @Input()
  set options(options: WithRef<IRole>[]) {
    this.options$.next(options);
  }

  @Input() disabled = false;

  isSelected(role: WithRef<IRole>): boolean {
    return this._selected$.value
      .map((ref: DocumentReference) => ref.path)
      .includes(role.ref.path);
  }

  select(role: WithRef<IRole>, checked: boolean): void {
    if (checked) {
      this._selected$.next([...this._selected$.value, role.ref]);
    } else {
      this._selected$.next([
        ...this._selected$.value.filter((ref) => ref.path !== role.ref.path),
      ]);
    }
    this.rolesChange.next(this._selected$.value);
  }
}
