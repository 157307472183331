import {
  ChartableSurface,
  hasQuadrant,
  hasTooth,
  IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { ArchHierarchy } from './arch-hierarchy';
import { SurfaceHierarchy } from './surface-hierarchy';

type AllowedQuadrantRef =
  | Pick<IChartedRef, 'quadrant'>
  | Pick<IChartedRef, 'tooth'>;

export class QuadrantHierarchy extends SurfaceHierarchy<AllowedQuadrantRef> {
  override surfaces: ChartableSurface[] = [ChartableSurface.Quadrant];
  override parents = [new ArchHierarchy()];

  canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is AllowedQuadrantRef {
    return hasQuadrant(selectedSurface) || hasTooth(selectedSurface);
  }

  toChartedRef(currentRef: AllowedQuadrantRef): Pick<IChartedRef, 'quadrant'> {
    if (hasQuadrant(currentRef)) {
      return {
        quadrant: currentRef.quadrant,
      };
    }
    return {
      quadrant: currentRef.tooth.quadrant,
    };
  }
}
