<mat-tab-group
  fxFlex
  mat-stretch-tabs
  [disablePagination]="true"
  class="sticky"
>
  <mat-tab>
    <ng-template matTabLabel>Scheduling</ng-template>
    <ng-template matTabContent>
      <pr-appointment-interactions
        *ngIf="
          currentAppointment$ | async as appointment;
          else newAppointmentInteractions
        "
        fxFlex
        compact
        editable
        enableScroll
        [appointment]="appointment"
       />

      <ng-template #newAppointmentInteractions>
        <pr-interactions
          fxFlex
          editable
          compact
          [enableScroll]="true"
          [interactions]="interactions$ | async"
          (interactionAdded)="addInteraction($event)"
          (interactionUpdated)="updateInteraction($event)"
          (interactionDeleted)="deleteInteraction($event)"
         />
      </ng-template>
    </ng-template>
  </mat-tab>

  <ng-container *ngrxLet="patient$ as patient">
    <mat-tab [disabled]="!patient">
      <ng-template matTabLabel>Social</ng-template>
      <ng-template matTabContent>
        <pr-notes
          fxFlex
          class="p-4"
          [resource]="patient"
          [scroll]="true"
          [addNoteBottom]="true"
        />
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="!patient">
      <ng-template matTabLabel>
        <div class="mat-tab-label-content">Patient</div>
      </ng-template>
      <ng-template matTabContent>
        <pr-patient-interactions
          fxFlex
          compact
          enableScroll
          [patient]="patient"
         />
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
