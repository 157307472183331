import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientForm } from '@principle-theorem/principle-core';
import { IPatientForm } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { PatientFormStore } from '../patient-form/patient-form.store';

export interface IPatientFormDialogData {
  form: WithRef<IPatientForm>;
  readonly: boolean;
}

@Component({
    selector: 'pr-patient-form-dialog',
    templateUrl: './patient-form-dialog.component.html',
    styleUrls: ['./patient-form-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PatientFormStore],
    standalone: false
})
export class PatientFormDialogComponent {
  private _data = inject<IPatientFormDialogData>(MAT_DIALOG_DATA);
  readonly store = inject(PatientFormStore);
  readonly dialogRef =
    inject<MatDialogRef<PatientFormDialogComponent>>(MatDialogRef);

  constructor() {
    this.store.setReadonly(this._data.readonly);
    this.store.loadForm(this._data.form);
  }

  getName(form: WithRef<IPatientForm>): string {
    return PatientForm.getName(form);
  }
}
