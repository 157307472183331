<mat-card appearance="outlined" fxFill fxLayout="column">
  <mat-card-title class="mat-headline-2">
    <ng-container *ngIf="primaryChart.value">
      {{ primaryChart.value | metric }}
    </ng-container>
  </mat-card-title>
  <mat-card-subtitle>
    {{ primaryChart.title }}
  </mat-card-subtitle>

  <mat-card-content>
    <pr-chart fxFlex [chartBuilder]="primaryChart.chartBuilder" />

    <div fxFlex>
      <div [innerHTML]="primaryChart.summary" class="chart-summary"></div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div
        *ngFor="let header of breakDownData.dataHeaders; trackBy: trackByHeader"
      >
        {{ header }}
      </div>
    </div>
    <mat-list extra-dense>
      <mat-list-item
        *ngFor="
          let point of breakDownData.dataPoints;
          trackBy: trackByDataPoint
        "
      >
        <div fxFlex>
          {{ point.label }}
        </div>
        <div>
          <ng-container
            *ngFor="let value of point.values; trackBy: trackByValue"
          >
            {{ value | metric }}
          </ng-container>
        </div>
        <mat-divider />
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
