import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { FeatureInfoComponent } from '@principle-theorem/ng-feature-flags';
import {
  ContentContainerComponent,
  CurrentBrandScope,
  InformationBoxComponent,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BreadcrumbsComponent,
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  Firestore,
  WithRef,
  filterUndefined,
  findProp,
  multiFilter,
  snapshot,
} from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BrandPrescriptionItemCollectionResolverService } from '../../brand-prescription-item-collection-resolver.service';
import { PrescriptionAddItemComponent } from '../prescription-add-item/prescription-add-item.component';
import { PrescriptionItemService } from '../prescription-item.service';
import { PrescriptionListComponent } from '../prescription-list/prescription-list.component';
import { PrescriptionPrintSettingsComponent } from '../prescription-print-settings/prescription-print-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionPrintContext } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-prescription-items',
    imports: [
        CommonModule,
        PrescriptionListComponent,
        BreadcrumbsComponent,
        FeatureInfoComponent,
        MatButtonModule,
        PrescriptionAddItemComponent,
        ContentContainerComponent,
        InformationBoxComponent,
        PrescriptionPrintSettingsComponent,
    ],
    templateUrl: './prescription-items.component.html',
    styleUrl: './prescription-items.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PrescriptionItemService]
})
export class PrescriptionItemsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _route = inject(ActivatedRoute);
  private _dialog = inject(MatDialog);
  private _currentBrand = inject(CurrentBrandScope);
  private _brand = toSignal(this._currentBrand.doc$);
  organisation = inject(OrganisationService);
  prescription = inject(PrescriptionItemService);
  breadcrumbs = computed(() => {
    const brand = this._brand();
    return brand
      ? [
          { label: 'Settings', path: '../../../' },
          { label: brand.name },
          { label: 'Prescriptions' },
        ]
      : [];
  });

  constructor() {
    this.prescription.loading$.next(true);
    this._loadPrescriptionItems();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async printTest(): Promise<void> {
    const practice = await snapshot(this.organisation.practice$);
    const region = await snapshot(this.organisation.region$);

    if (!practice || !region) {
      return;
    }

    new PrescriptionPrintContext(region).printTestPrescription(practice);
  }

  async confirmDelete(item: WithRef<IPrescriptionItem>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Prescription Item',
      prompt: 'Are you sure you want to delete this prescription item?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirm = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirm) {
      return;
    }
    this.prescription.delete$.next(item);
  }

  private _loadPrescriptionItems(): void {
    this._route.data
      .pipe(
        findProp<CollectionReference<IPrescriptionItem>>(
          BrandPrescriptionItemCollectionResolverService.resolverKey
        ),
        filterUndefined(),
        switchMap((prescriptionCol) => Firestore.getDocs(prescriptionCol)),
        multiFilter((prescription) => !prescription.deleted),
        takeUntil(this._onDestroy$)
      )
      .subscribe((items) =>
        this.prescription.load$.next({ loading: false, items })
      );
  }
}
