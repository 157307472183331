import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ReconciliationReportResponse } from '@principle-theorem/tyro';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-tyro-reconciliation-report',
  templateUrl: './tyro-reconciliation-report.component.html',
  styleUrls: ['./tyro-reconciliation-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyroReconciliationReportComponent {
  report$ = new ReplaySubject<ReconciliationReportResponse | undefined>(1);

  @Input()
  set report(report: ReconciliationReportResponse | undefined) {
    this.report$.next(report);
  }
}
