import { Component, Input, type OnInit } from '@angular/core';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { PaymentPlan, PAYMENT_PLANS } from '@principle-theorem/principle-core';
import {
  type IPaymentPlan,
  PaymentFrequency,
} from '@principle-theorem/principle-core/interfaces';
import * as moment from 'moment-timezone';

interface IPaymentPlanFormData {
  selectedPlan: IPaymentPlan;
  frequency: PaymentFrequency;
  startingDate: moment.Moment;
}

@Component({
    selector: 'pr-payment-plan',
    templateUrl: './payment-plan.component.html',
    styleUrls: ['./payment-plan.component.sass'],
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class PaymentPlanComponent implements OnInit {
  trackByPlan = TrackByFunctions.field<IPaymentPlan>('name');
  trackByFrequency = TrackByFunctions.variable<PaymentFrequency>();
  @Input() total: number;
  paymentPlans: IPaymentPlan[] = PAYMENT_PLANS;
  selectedPlan: IPaymentPlan = this.paymentPlans[0];
  paymentPlanForm = new TypedFormGroup<IPaymentPlanFormData>({
    selectedPlan: new TypedFormControl(this.selectedPlan),
    frequency: new TypedFormControl(this.selectedPlan.paymentFrequency),
    startingDate: new TypedFormControl(moment()).withGuard(moment.isMoment),
  });
  frequencies: PaymentFrequency[] = [
    PaymentFrequency.Weekly,
    PaymentFrequency.Fortnightly,
    PaymentFrequency.Monthly,
  ];

  ngOnInit(): void {
    this.setPlanTotal();
  }

  get repaymentAmount(): number {
    return PaymentPlan.repaymentAmount(this.selectedPlan);
  }

  setPlanTotal(): void {
    this.selectedPlan.total = this.total;
  }
}
