@if (noConfigs$ | async) {
  <pr-empty-state image="list" title="notifications" />
} @else {
  @for (
    configMap of practiceConfigMaps$ | async;
    track configMap.practice?.ref.path
  ) {
    <div class="mb-4">
      @if (configMap.configurations.length) {
        <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
          <h3 class="!m-0 !font-bold">
            {{ configMap.practice ? configMap.practice.name : 'All Practices' }}
          </h3>

          @for (
            automation of configMap.configurations;
            track automation.ref.path
          ) {
            <pr-automation-configuration-list-item [config]="automation">
              @if (!automation.deleted) {
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="edit(automation)">
                    <mat-icon>edit</mat-icon>Edit
                  </button>
                  <button mat-menu-item (click)="openAutomations(automation)">
                    <mat-icon>smart_toy</mat-icon>View Automations
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="!automation.isActive"
                    (click)="setActive(automation, true)"
                  >
                    <mat-icon>check_circle</mat-icon>
                    Enable
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="automation.isActive"
                    (click)="setActive(automation, false)"
                  >
                    <mat-icon>highlight_off</mat-icon>
                    Disable
                  </button>
                  <mat-divider />
                  <button
                    mat-menu-item
                    (click)="delete(automation)"
                    color="warn"
                  >
                    <mat-icon color="warn">delete</mat-icon>
                    Delete
                  </button>
                </mat-menu>
              } @else {
                <button mat-icon-button (click)="restore(automation)">
                  <mat-icon>undo</mat-icon>
                </button>
              }
            </pr-automation-configuration-list-item>
          }
        </div>
      }
    </div>
  }
}
