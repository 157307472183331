<ng-container *ngIf="staffer$ | async as staffer">
  <div
    class="selected-staffer-container"
    (mouseenter)="stafferIsHovered$.next(true)"
    (mouseleave)="stafferIsHovered$.next(false)"
  >
    <pt-user-icon
      [src]="profileImage$ | async"
      [name]="staffer.user.name"
      [diameter]="32"
     />

    <div
      *ngIf="stafferIsHovered$ | async"
      (click)="clearStaffer.emit(staffer)"
      class="close-button"
      matTooltip="Remove {{ staffer.user.name }}"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>close</mat-icon>
    </div>
  </div>
</ng-container>
