import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MixedSchema } from '@principle-theorem/editor';
import { TreatmentPlanFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentBrandScope,
  CurrentPatientScope,
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  NG_SHARED_CONFIG,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  ChartedItemTotalCalculator,
  Patient,
  ScopeDataBuilder,
  Staffer,
  SystemTemplates,
  TreatmentPlanContextBuilder,
  compileTemplateSchema,
  getAppointmentStepStatus,
  treatmentStepToLineItems,
} from '@principle-theorem/principle-core';
import {
  PlanStepPairStatus,
  SystemTemplate,
  type IBrand,
  type IPatient,
  type IPractice,
  type IResolvedTemplateWithName,
  type IServiceCodeLineItem,
  type IStaffer,
  type ITemplateDefinitionWithName,
  type ITreatmentBasePriceLineItem,
  type ITreatmentLineItem,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  filterUndefined,
  resolveSequentially,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';
import { sum } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  from,
  type Observable,
} from 'rxjs';
import {
  concatMap,
  map,
  switchMap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { TreatmentPlanPrintSettingsDialogComponent } from '../treatment-plan-print-settings-dialog/treatment-plan-print-settings-dialog.component';

export interface IPrintTreatmentPlanDialogData {
  templates: ITemplateDefinitionWithName[];
  treatmentSteps: ITreatmentStepWithLineItems[];
  treatmentPlan: WithRef<ITreatmentPlan>;
}

export interface IPrintTreatmentPlanData {
  templates: IResolvedTemplateWithName[];
  treatmentSteps: ITreatmentStepWithLineItems[];
  displayTreatmentPlanName: boolean;
}

export interface ITreatmentStepWithLineItems {
  treatmentStep: WithRef<ITreatmentStep>;
  lineItems: ITreatmentLineItem[];
  summary: ILineItemSummary;
  stepAppointmentStatus: PlanStepPairStatus;
}

interface ILineItemSummary {
  tax: number;
  total: number;
}

@Component({
  selector: 'pr-treatment-plan-print',
  templateUrl: './treatment-plan-print.component.html',
  styleUrls: ['./treatment-plan-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanPrintComponent implements OnDestroy, OnInit {
  private _sharedConfig = inject(NG_SHARED_CONFIG);
  private _onDestroy$ = new Subject<void>();
  private _calculator = new ChartedItemTotalCalculator();
  private _treatmentStepsWithLineItems$: Observable<
    ITreatmentStepWithLineItems[]
  >;
  trackByTreatmentStep =
    TrackByFunctions.ref<ITreatmentStepWithLineItems>('treatmentStep.ref');
  trackByLineItem = TrackByFunctions.uniqueId<
    IServiceCodeLineItem | ITreatmentBasePriceLineItem
  >();
  brand$: Observable<WithRef<IBrand>>;
  practice$: Observable<WithRef<IPractice>>;
  patient$: Observable<WithRef<IPatient>>;
  phone$: Observable<string | undefined>;
  practitioner$: Observable<INamedDocument<IStaffer> | undefined>;
  treatmentPlan$: Observable<WithRef<ITreatmentPlan>>;
  treatmentPlanTotal$ = new ReplaySubject<ILineItemSummary>(1);
  treatmentStepDisplay$ = new ReplaySubject<ITreatmentStepWithLineItems[]>(1);
  treatmentPlanNameDisplay$ = new ReplaySubject<boolean>(1);
  printHeader$ = new ReplaySubject<MixedSchema | undefined>(1);
  printFooter$ = new ReplaySubject<MixedSchema | undefined>(1);
  planHeader$ = new ReplaySubject<MixedSchema | undefined>(1);
  planFooter$ = new ReplaySubject<MixedSchema | undefined>(1);
  loaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    currentScopeFacade: CurrentScopeFacade,
    treatmentPlanFacade: TreatmentPlanFacade,
    patientScope: CurrentPatientScope,
    private _brandScope: CurrentBrandScope,
    private _dialog: MatDialog,
    private _global: GlobalStoreService,
    private _location: Location,
    private _organisation: OrganisationService
  ) {
    this.treatmentPlan$ =
      treatmentPlanFacade.selectedTreatmentPlan$.pipe(filterUndefined());
    this.brand$ = currentScopeFacade.currentBrand$.pipe(filterUndefined());
    this.practice$ =
      currentScopeFacade.currentPractice$.pipe(filterUndefined());
    this.patient$ = patientScope.doc$.pipe(filterUndefined());
    this.practitioner$ = this.treatmentPlan$.pipe(
      map((plan) => plan.practitioner)
    );

    this.phone$ = this.patient$.pipe(
      switchMap((patient) => Patient.resolveFirstContactNumber(patient))
    );

    const treatmentPlanSteps$ = this.treatmentPlan$.pipe(
      switchMap((treatmentPlan) =>
        treatmentPlanFacade.getTreatmentSteps$(treatmentPlan.ref)
      )
    );

    this._treatmentStepsWithLineItems$ = this.treatmentPlan$.pipe(
      withLatestFrom(
        treatmentPlanSteps$,
        this._organisation.taxRate$.pipe(filterUndefined())
      ),
      switchMap(([treatmentPlan, treatmentSteps, taxRate]) =>
        from(treatmentSteps).pipe(
          concatMap(async (treatmentStep) => {
            const lineItems = treatmentStepToLineItems(
              treatmentStep,
              treatmentPlan,
              treatmentPlan.practitioner ?? MockNamedDocument('Default'),
              taxRate
            );
            const summary = this._getStepSummary(treatmentStep);
            const appointment = treatmentStep.appointment
              ? await Firestore.getDoc(treatmentStep.appointment)
              : undefined;
            const stepAppointmentStatus = getAppointmentStepStatus(
              treatmentStep,
              appointment
            );

            return {
              treatmentStep,
              lineItems,
              summary,
              stepAppointmentStatus,
            };
          }),
          toArray()
        )
      )
    );
  }

  async ngOnInit(): Promise<void> {
    const brand = await snapshot(this._brandScope.doc$.pipe(filterUndefined()));
    const treatmentPlan = await snapshot(this.treatmentPlan$);
    const steps = await snapshot(this._treatmentStepsWithLineItems$);
    const data = await this._resolveTreatmentPlanPrintData(
      brand,
      steps,
      treatmentPlan
    );

    if (!data || !data.treatmentSteps.length) {
      this._location.back();
      return;
    }
    this.treatmentStepDisplay$.next(data.treatmentSteps);
    this.treatmentPlanNameDisplay$.next(data.displayTreatmentPlanName);
    this.treatmentPlanTotal$.next({
      tax: sum(data.treatmentSteps.map((step) => step.summary.tax)),
      total: sum(data.treatmentSteps.map((step) => step.summary.total)),
    });

    this._setTemplates(data.templates);
    this.loaded$.next(true);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  resolveProvider$(
    lineItem: ITreatmentLineItem
  ): Observable<INamedDocument & { providerNumber: string | undefined }> {
    return this._global
      .getStaffer$(lineItem.treatmentRef.attributedTo.ref)
      .pipe(
        filterUndefined(),
        withLatestFrom(this.practice$),
        map(([staffer, practice]) => {
          const providerData = Staffer.getProviderData(staffer, practice.ref);
          return {
            ...lineItem.treatmentRef.attributedTo,
            providerNumber: providerData?.providerNumber,
          };
        })
      );
  }

  private _setTemplates(templates: IResolvedTemplateWithName[]): void {
    this.printHeader$.next(
      SystemTemplates.findTemplate(templates, SystemTemplate.PrintHeader)
    );
    this.printFooter$.next(
      SystemTemplates.findTemplate(templates, SystemTemplate.PrintFooter)
    );
    this.planHeader$.next(
      SystemTemplates.findTemplate(
        templates,
        SystemTemplate.PrintTreatmentPlanHeader
      )
    );
    this.planFooter$.next(
      SystemTemplates.findTemplate(
        templates,
        SystemTemplate.PrintTreatmentPlanFooter
      )
    );
  }

  private _getStepSummary(
    treatmentStep: WithRef<ITreatmentStep>
  ): ILineItemSummary {
    return {
      tax: this._calculator.stepTax(treatmentStep),
      total: this._calculator.step(treatmentStep),
    };
  }

  private async _resolveTreatmentPlanPrintData(
    brand: WithRef<IBrand>,
    treatmentSteps: ITreatmentStepWithLineItems[],
    treatmentPlan: WithRef<ITreatmentPlan>
  ): Promise<IPrintTreatmentPlanData | undefined> {
    const templates = await SystemTemplates.resolveSystemTemplates(brand, [
      SystemTemplate.PrintHeader,
      SystemTemplate.PrintFooter,
      SystemTemplate.PrintTreatmentPlanHeader,
      SystemTemplate.PrintTreatmentPlanFooter,
    ]);

    const selected = await this._openDialog({
      templates,
      treatmentSteps,
      treatmentPlan,
    });

    if (!selected) {
      return;
    }

    const resolvedTemplates = await resolveSequentially(
      selected.templates,
      async ({ content, template }) => {
        return {
          template,
          content: await this._resolveTemplate(content, treatmentPlan),
        };
      }
    );

    return {
      templates: resolvedTemplates,
      treatmentSteps: selected.treatmentSteps,
      displayTreatmentPlanName: selected.displayTreatmentPlanName,
    };
  }

  private async _resolveTemplate(
    content: MixedSchema,
    treatmentPlan: WithRef<ITreatmentPlan>
  ): Promise<MixedSchema> {
    const scopeData = await ScopeDataBuilder.buildTreatmentPlanScopeData(
      treatmentPlan,
      this._sharedConfig.appUrl
    );

    const context = new TreatmentPlanContextBuilder(scopeData).build();
    return compileTemplateSchema(content, context);
  }

  private async _openDialog(
    data: IPrintTreatmentPlanDialogData
  ): Promise<IPrintTreatmentPlanData | undefined> {
    return this._dialog
      .open<
        TreatmentPlanPrintSettingsDialogComponent,
        IPrintTreatmentPlanDialogData,
        IPrintTreatmentPlanData
      >(
        TreatmentPlanPrintSettingsDialogComponent,
        DialogPresets.large({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
