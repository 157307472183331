import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { ClinicalChart } from '@principle-theorem/principle-core';
import { type IClinicalChart } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  chartHistoryItems$,
  type IChartHistoryItem,
} from '../../components/charting-history-selector/chart-history-list';

@Component({
  selector: 'pr-charting-history',
  templateUrl: './charting-history.component.html',
  styleUrls: ['./charting-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartingHistoryComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  items$: Observable<IChartHistoryItem[]>;

  constructor(
    private _patientScope: CurrentPatientScope,
    private _chart: ChartFacade,
    private _organisation: OrganisationService
  ) {
    this.items$ = this._patientScope.doc$.pipe(chartHistoryItems$());

    this._organisation.staffer$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((staffer) =>
        this._chart.setChartingAs(ChartId.InAppointment, staffer)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  chartSelected(chart: IClinicalChart): void {
    const id = ChartId.InAppointment;
    if (chart) {
      this._chart.setChart(id, chart);
      return;
    }
    this._chart.resetChart(id);
  }

  async addNewChart(): Promise<void> {
    const patient = await snapshot(
      this._patientScope.doc$.pipe(filterUndefined())
    );
    await ClinicalChart.cloneFromLatest(patient);
  }
}
