import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { BridgeDevice } from '@principle-theorem/principle-core';
import {
  IBridgeDevice,
  ITwainDevice,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

type TwainDeviceSettingsForm = Pick<
  ITwainDevice,
  'multiImageEnabled' | 'vistaSoftConnectEnabled'
>;

@Component({
    selector: 'pr-twain-device-settings',
    templateUrl: './twain-device-settings.component.html',
    styleUrl: './twain-device-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TwainDeviceSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  bridgeDevice$ = new ReplaySubject<WithRef<IBridgeDevice>>(1);
  twainDeviceUid$ = new ReplaySubject<string>(1);
  disabled$ = new ReplaySubject<boolean>(1);
  bridgeVersion$: Observable<string | undefined>;
  minimumVistaSoftVersion = '1.167.0';
  minimumMultiImageVersion = '1.168.0';

  twainDeviceSettingsForm = new TypedFormGroup<TwainDeviceSettingsForm>({
    multiImageEnabled: new TypedFormControl<boolean>(),
    vistaSoftConnectEnabled: new TypedFormControl<boolean>(),
  });

  @Input()
  set bridgeDevice(device: WithRef<IBridgeDevice>) {
    if (device) {
      this.bridgeDevice$.next(device);
    }
  }

  @Input()
  set twainDeviceUid(uid: string) {
    if (uid) {
      this.twainDeviceUid$.next(uid);
    }
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor() {
    combineLatest([this.bridgeDevice$, this.twainDeviceUid$])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([bridgeDevice, uid]) =>
        this._initialiseForm(bridgeDevice, uid)
      );

    validFormGroupChanges$(this.twainDeviceSettingsForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((formChanges) => void this.updateDevice(formChanges));

    this.bridgeVersion$ = this.bridgeDevice$.pipe(
      map((device) => device.settings?.version)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async updateDevice(changes: Partial<ITwainDevice>): Promise<void> {
    const bridgeDevice = await snapshot(this.bridgeDevice$);
    const deviceUid = await snapshot(this.twainDeviceUid$);

    await BridgeDevice.patchTwainDevice(bridgeDevice, deviceUid, changes);
  }

  private _initialiseForm(
    bridgeDevice: WithRef<IBridgeDevice>,
    uid: string
  ): void {
    const twainDeviceSettings = bridgeDevice.twainSettings?.devices.find(
      (device) => device.uid === uid
    );
    if (twainDeviceSettings) {
      this.twainDeviceSettingsForm.patchValue(twainDeviceSettings, {
        emitEvent: false,
      });
    }
  }
}
