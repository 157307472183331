import {
  ICustomFormContent,
  IJsonSchemaForm,
  IOldCustomFormConfiguration,
  IPatientForm,
  ISerialisedCustomFormConfiguration,
  ISerialisedCustomFormContent,
  ISerialisedJsonSchemaForm,
  ISerialisedPatientForm,
  isCustomFormContent,
  isOldCustomFormConfiguration,
  isPatientForm,
  isSerialisedCustomFormConfiguration,
  isSerialisedCustomFormContent,
  isSerialisedPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  ITransformer,
  TransformEvent,
  fromJSON,
  toJSON,
} from '@principle-theorem/shared';
import { omit } from 'lodash';

export function serialiseJsonSchemaForm(
  data: IJsonSchemaForm
): ISerialisedJsonSchemaForm {
  return {
    schema: toJSON(data.schema),
    layout: toJSON(data.layout),
  };
}

export function unserialiseJsonSchemaForm(
  data: ISerialisedJsonSchemaForm
): IJsonSchemaForm {
  return {
    schema: data.schema ? fromJSON(data.schema) : undefined,
    layout: data.layout ? fromJSON(data.layout) : undefined,
  };
}

/**
 * Old Custom Form Configuration
 */

export class CustomFormConfigurationSerialiser
  implements
    ITransformer<
      IOldCustomFormConfiguration,
      ISerialisedCustomFormConfiguration
    >
{
  events = [TransformEvent.ToFirestore];
  guard = isOldCustomFormConfiguration;

  transform(
    item: IOldCustomFormConfiguration
  ): ISerialisedCustomFormConfiguration {
    return {
      ...omit(item, ['schema', 'layout']),
      ...serialiseJsonSchemaForm(item),
    };
  }
}

export class CustomFormConfigurationUnserialiser
  implements
    ITransformer<
      ISerialisedCustomFormConfiguration,
      IOldCustomFormConfiguration
    >
{
  events = [TransformEvent.FromFirestore];
  guard = isSerialisedCustomFormConfiguration;

  transform(
    item: ISerialisedCustomFormConfiguration
  ): IOldCustomFormConfiguration {
    return {
      ...omit(item, ['schema', 'layout']),
      ...unserialiseJsonSchemaForm(item),
    };
  }
}

/**
 * Custom Form Content
 */

export class CustomFormContentSerialiser
  implements ITransformer<ICustomFormContent, ISerialisedCustomFormContent>
{
  events = [TransformEvent.ToFirestore];
  guard = isCustomFormContent;

  transform(item: ICustomFormContent): ISerialisedCustomFormContent {
    return {
      ...item,
      layout: item.layout ? toJSON(item.layout) : undefined,
      jsonSchemaForm: serialiseJsonSchemaForm(item.jsonSchemaForm),
    };
  }
}

export class CustomFormContentUnserialiser
  implements ITransformer<ISerialisedCustomFormContent, ICustomFormContent>
{
  events = [TransformEvent.FromFirestore];
  guard = isSerialisedCustomFormContent;

  transform(item: ISerialisedCustomFormContent): ICustomFormContent {
    return {
      ...item,
      layout: item.layout ? fromJSON(item.layout) : undefined,
      jsonSchemaForm: unserialiseJsonSchemaForm(item.jsonSchemaForm),
    };
  }
}

/**
 * Patient Form
 */

export class PatientFormSerialiser
  implements ITransformer<IPatientForm, ISerialisedPatientForm>
{
  events = [TransformEvent.ToFirestore];
  guard = isPatientForm;

  transform(item: IPatientForm): ISerialisedPatientForm {
    const form = item.form ?? {};
    return {
      ...item,
      form: {
        ...omit(form, ['schema', 'layout']),
        ...serialiseJsonSchemaForm(form ?? {}),
      },
    };
  }
}

export class PatientFormUnserialiser
  implements ITransformer<ISerialisedPatientForm, IPatientForm>
{
  events = [TransformEvent.FromFirestore];
  guard = isSerialisedPatientForm;

  transform(item: ISerialisedPatientForm): IPatientForm {
    const form = item.form ?? {};
    return {
      ...item,
      form: {
        ...omit(form, ['schema', 'layout']),
        ...unserialiseJsonSchemaForm(form),
      },
    };
  }
}
