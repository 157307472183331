import {
  IContextBuilder,
  ITreatmentPlanScopeData,
  ITreatmentPlanTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  DAY_MONTH_YEAR_FORMAT,
  TIME_FORMAT,
  toMoment,
} from '@principle-theorem/shared';
import { PatientContextBuilder } from './patient-context';

export class TreatmentPlanContextBuilder
  implements IContextBuilder<ITreatmentPlanTemplateContext>
{
  private _patientContextBuilder: PatientContextBuilder;

  constructor(private _data: ITreatmentPlanScopeData) {
    this._patientContextBuilder = new PatientContextBuilder(this._data);
  }

  build(): ITreatmentPlanTemplateContext {
    return {
      treatmentPlanName: this._data.treatmentPlan.name,
      treatmentPlanCreatedAt: toMoment(
        this._data.treatmentPlan.createdAt
      ).calendar(undefined, CALENDAR_TIME_FORMAT),
      treatmentPlanCreatedAtDate: toMoment(
        this._data.treatmentPlan.createdAt
      ).format(DAY_MONTH_YEAR_FORMAT),
      treatmentPlanCreatedAtTime: toMoment(
        this._data.treatmentPlan.createdAt
      ).format(TIME_FORMAT),
      ...this._patientContextBuilder.build(),
    };
  }
}
