import { rand, randSentence } from '@ngneat/falso';
import {
  RawInlineNodes,
  VersionedSchema,
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  IInteraction,
  IPatient,
  IPrincipleMention,
  IRecurringTaskConfiguration,
  IStaffer,
  IStatusHistory,
  ITask,
  ITeam,
  InteractionType,
  MentionResourceType,
  TASK_PRIORITIES,
  TASK_STATUSES,
  TASK_TYPES,
  TaskPriority,
  TaskStatus,
  TaskType,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  Frequency,
  INamedDocument,
  IRecurrencePattern,
  MockGenerator,
  MockTimestamp,
  Properties,
  WithRef,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { stafferToNamedDoc } from '../common';
import { Interaction } from '../interaction/interaction';
import { toMention } from '../mention/mention';
import { RecurrencePattern } from '../schedule/recurrence-pattern';
import { Task } from './task';
import { InteractionMock } from '../interaction/interaction.mock';
import { MentionMock } from '../mention/mention.mock';

export class TaskMock extends BaseFirestoreMock implements Properties<ITask> {
  get title(): RawInlineNodes {
    return [toTextContent(randSentence())];
  }

  get description(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get dueDate(): Timestamp {
    return MockTimestamp();
  }

  get completedDate(): Timestamp {
    return MockTimestamp();
  }

  get mentions(): IPrincipleMention[] {
    return [MockGenerator.generate(MentionMock)];
  }

  get interactions(): IInteraction[] {
    return [MockGenerator.generate(InteractionMock)];
  }

  get mentionRefs(): DocumentReference<unknown>[] {
    return [MockDocRef()];
  }

  get statusHistory(): IStatusHistory<TaskStatus>[] {
    return [
      {
        status: TaskStatus.Open,
        updatedAt: MockTimestamp(),
      },
      {
        status: TaskStatus.Complete,
        updatedAt: MockTimestamp(),
      },
    ];
  }

  get priority(): TaskPriority {
    return rand(TASK_PRIORITIES);
  }

  get status(): TaskStatus {
    return rand(TASK_STATUSES);
  }

  get type(): TaskType {
    return rand(TASK_TYPES);
  }

  get recurrenceConfiguration(): DocumentReference<IRecurringTaskConfiguration> {
    return MockDocRef<IRecurringTaskConfiguration>();
  }

  get assignedUser(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get assignedTeam(): INamedDocument<ITeam> {
    return MockNamedDocument<ITeam>();
  }

  get owner(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get visibleFrom(): Timestamp {
    return MockTimestamp();
  }
}

export interface ITaskConfiguration {
  task: ITask;
  recurringPattern?: IRecurrencePattern;
}

export function MockTaskInteractions(
  owner: INamedDocument<IStaffer>,
  assignedUser: INamedDocument<IStaffer>,
  _?: INamedDocument,
  notes?: VersionedSchema[]
): IInteraction[] {
  const taskInteractions: Partial<IInteraction>[] = [
    {
      type: InteractionType.TaskCreate,
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` created task`),
      ],
      owner,
      createdAt: toTimestamp(),
    },
    {
      type: InteractionType.TaskAssign,
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` assigned task to `),
        toMentionContent(toMention(assignedUser, MentionResourceType.Staffer)),
      ],
      owner,
      createdAt: toTimestamp(),
    },
  ];

  if (notes && notes.length) {
    notes.forEach((content) => {
      taskInteractions.push({
        type: InteractionType.Note,
        title: [
          toMentionContent(toMention(owner, MentionResourceType.Staffer)),
          toTextContent(` added comment`),
        ],
        owner,
        createdAt: toTimestamp(),
        content,
      });
    });
  }

  return taskInteractions.map((interaction) => Interaction.init(interaction));
}

export abstract class TaskSeedMock {
  protected _staff: WithRef<IStaffer>[];
  protected _teams: WithRef<ITeam>[];
  protected _patients: WithRef<IPatient>[];
  protected _patient: INamedDocument;

  public owner: INamedDocument<IStaffer>;
  public assignedUser?: INamedDocument<IStaffer>;
  public assignedTeam?: INamedDocument<ITeam>;

  defaults: ITask = {
    title: [],
    description: initVersionedSchema(),

    type: TaskType.Misc,
    priority: TaskPriority.Low,
    status: TaskStatus.Open,
    statusHistory: [],

    recurrenceConfiguration: undefined,

    owner: undefined,
    assignedUser: undefined,
    assignedTeam: undefined,

    interactions: [],
    mentionRefs: [],

    visibleFrom: toTimestamp(moment().startOf('day')),
    dueDate: undefined,
    completedDate: undefined,
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
    deleted: false,
  };

  constructor(
    staff: WithRef<IStaffer>[],
    teams: WithRef<ITeam>[],
    patients: WithRef<IPatient>[]
  ) {
    this._staff = staff;
    this._teams = teams;
    this._patients = patients;

    const randomPatient: WithRef<IPatient> = rand(this._patients);
    this._patient = toNamedDocument(randomPatient);

    const owningStaffer: WithRef<IStaffer> = rand(this._staff);
    this.owner = stafferToNamedDoc(owningStaffer);

    const randomStaffer: WithRef<IStaffer> = rand(this._staff);
    this.assignedUser = stafferToNamedDoc(randomStaffer);

    this.defaults.owner = this.owner;
    this.defaults.assignedUser = this.assignedUser;
    this.defaults.assignedTeam = this.assignedTeam;
    this.defaults.interactions = this.addInteractions();
  }

  abstract getMock(): Partial<ITask>;

  getRecurringPattern(): IRecurrencePattern | undefined {
    return;
  }

  generate(): ITaskConfiguration {
    const task: ITask = Task.init({ ...this.defaults, ...this.getMock() });
    const recurringPattern = this.getRecurringPattern();
    return {
      task,
      recurringPattern,
    };
  }

  addInteractions(interactions: VersionedSchema[] = []): IInteraction[] {
    if (!this.assignedUser) {
      return [];
    }
    return MockTaskInteractions(
      this.owner,
      this.assignedUser,
      this.assignedTeam,
      interactions
    );
  }
}

class CigdemBreakfastTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      title: [toTextContent('Get Cigdem breakfast')],
      interactions: this.addInteractions([
        initVersionedSchema('Cigdem hungry. Must feed!'),
      ]),
      priority: TaskPriority.High,
      dueDate: toTimestamp(moment().subtract(1, 'day').endOf('day')),
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }
}

class ReturnCallTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      interactions: this.addInteractions([
        initVersionedSchema(`Wants info on Invisalign packages`),
      ]),
      visibleFrom: toTimestamp(moment().subtract(1, 'days').startOf('day')),
      title: [
        toTextContent(`Return call from `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
    };
  }
}

class RebookAppointmentTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.RebookAppointment,
      interactions: this.addInteractions([
        initVersionedSchema(
          'Patient needs to confirm a date with manager. Best to send SMS confirmation'
        ),
      ]),
      title: [
        toTextContent(`Rebook Hygiene Appointment for `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }
}

class TreatmentPlanTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.RebookAppointment,
      interactions: this.addInteractions([
        initVersionedSchema(
          `I quoted $5500 in total. I let her know that if she needs a sinus lift, ` +
            `it will be an extra $1500-$1800. Please include in the plan.`
        ),
      ]),
      title: [
        toTextContent(`Create a treatment plan for `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
        toTextContent(` - implant for 46`),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }
}

class SpecialistReferralTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.RebookAppointment,
      interactions: this.addInteractions([
        initVersionedSchema(
          `Write a referral to see endodontist Dr. Peter Casidy in Chatswood. Details: tooth 46, ` +
            `irreversible pulpitis. I did an emergency extirpation yesterday but the ML canal is blocked.`
        ),
      ]),
      title: [
        toTextContent(`Write a referral for `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }
}

class FollowUpInvisalignTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.FollowUp,
      interactions: this.addInteractions([
        initVersionedSchema(
          `Where's he at with his invisalign? He's disappeared.`
        ),
      ]),
      title: [
        toTextContent(`Follow up on `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }
}

class FollowUpToothExtractionTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.FollowUp,
      dueDate: toTimestamp(moment().add(3, 'days').endOf('day')),
      title: [
        toTextContent(`Follow up re: `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
        toTextContent(` Tooth Extraction`),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
      interactions: [],
    };
  }
}

class TheyHadABabyTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      dueDate: toTimestamp(moment().add(3, 'days').endOf('day')),
      title: [
        toTextContent(`Send flowers to Janet and `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
        toTextContent(` - they just had their first baby on the weekend`),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
      interactions: [],
    };
  }
}

class ITHelpTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      dueDate: toTimestamp(moment().add(3, 'days').endOf('day')),
      title: [toTextContent(`Call Jason from IT`)],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
      interactions: this.addInteractions([
        initVersionedSchema(
          `Ask him to link up the new scanner to the M-drive on the WiFi network. ` +
            `Also ask him why we can't use teamviewer anymore?`
        ),
      ]),
    };
  }
}

class SpecialistFollowUpTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      dueDate: toTimestamp(moment().add(3, 'days').endOf('day')),
      title: [
        toTextContent(`Call `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
      interactions: this.addInteractions([
        initVersionedSchema(
          `Ask which oral pathologist specialist he will be seeing so that we can email his referral letter to the correct specialist`
        ),
      ]),
    };
  }
}

class BookWisdomToothExtractionTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      dueDate: toTimestamp(moment().add(3, 'days').endOf('day')),
      title: [
        toTextContent(`Book a 2 hour appointment for `),
        toMentionContent(toMention(this._patient, MentionResourceType.Patient)),
      ],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
      interactions: this.addInteractions([
        initVersionedSchema(
          `4x wisdom teeth removal with IV sedationist Dr Kate Rutherford. Book 30 min set up time and 45 min recovery ` +
            `time after (or book it so there is our lunch break after)`
        ),
      ]),
    };
  }
}

class WeeklyDiagnosticsTaskMock extends TaskSeedMock {
  getMock(): Partial<ITask> {
    return {
      type: TaskType.Misc,
      priority: TaskPriority.Medium,
      dueDate: toTimestamp(moment().add(4, 'days').endOf('day')),
      title: [toTextContent(`Run autoclave diagnostics`)],
      visibleFrom: toTimestamp(moment().subtract(1, 'day').startOf('day')),
    };
  }

  override getRecurringPattern(): IRecurrencePattern {
    return RecurrencePattern.init({
      frequencyType: Frequency.Weekly,
    });
  }
}

export function MockTasks(
  staff: WithRef<IStaffer>[],
  teams: WithRef<ITeam>[],
  patients: WithRef<IPatient>[]
): TaskSeedMock[] {
  return [
    new CigdemBreakfastTaskMock(staff, teams, patients),
    new ReturnCallTaskMock(staff, teams, patients),
    new RebookAppointmentTaskMock(staff, teams, patients),
    new TreatmentPlanTaskMock(staff, teams, patients),
    new SpecialistReferralTaskMock(staff, teams, patients),
    new FollowUpInvisalignTaskMock(staff, teams, patients),
    new FollowUpToothExtractionTaskMock(staff, teams, patients),
    new TheyHadABabyTaskMock(staff, teams, patients),
    new ITHelpTaskMock(staff, teams, patients),
    new SpecialistFollowUpTaskMock(staff, teams, patients),
    new BookWisdomToothExtractionTaskMock(staff, teams, patients),
    new WeeklyDiagnosticsTaskMock(staff, teams, patients),
  ];
}

// export const SCHEDULING_CONFLICTS: Task[] = [{
//   type: TaskType.RebookAppointment,
//   title: 'Unconfirmed Appointment',
//   interactions: MockTaskInteractions([
//     'The patient has not yet confirmed the appointment reminder',
//   ]),
//   priority: TaskPriority.High,
//   // target: MockAppointment(true),
//   dueDate: toTimestamp(),
// },
// {
//   type: TaskType.LabJob,
//   title: 'Overdue Lab Job',
//   interactions: MockTaskInteractions([
//     `The appointment relies on this lab job being complete`
//   ]),
//   priority: TaskPriority.High,
//   // target: MockAppointment(true),
//   dueDate: toTimestamp(),
// },
// {
//   type: TaskType.Gap,
//   title: 'Appointment Gap',
//   interactions: MockTaskInteractions([
//     `An upcoming appointment gap does not have any candidates`
//   ]),
//   priority: TaskPriority.High,
//   // target: GAP_DATES[0].gaps[0],
//   dueDate: toTimestamp(),
// },
// {
//   type: TaskType.FollowUp,
//   title: 'Unconfirmed Appointment',
//   interactions: MockTaskInteractions([
//     'The patient has not yet confirmed the appointment reminder',
//   ]),
//   priority: TaskPriority.High,
//   // target: MockAppointment(true),
//   dueDate: toTimestamp(moment().add(3, 'days')),
// }].map((data: ITask) => MockTask(data));
