export interface ICustomMappingsMetadata {
  label: string;
  description: string;
}

export interface ICustomMappings {
  metadata: ICustomMappingsMetadata;
}

export enum CustomMappingType {
  DocumentReference = 'documentReference',
  SelectionList = 'selectionList',
  ExclusionList = 'exclusionList',
}

export type DocumentReferenceCustomMapping = {
  type: CustomMappingType.DocumentReference;
};

export type ExclusionCustomMapping = {
  type: CustomMappingType.ExclusionList;
};

export type SelectionCustomMapping = {
  type: CustomMappingType.SelectionList;
};

export enum CustomMappingAssociatedValueType {
  NamedDocument = 'Named Document',
  String = 'String',
}

export type CustomMappingOption = {
  value: string;
  description: string;
} & (
  | { hasAssociatedValue: false }
  | {
      hasAssociatedValue: true;
      associatedValueType: CustomMappingAssociatedValueType;
      associatedValueDescription: string;
    }
);

export enum CustomMappingStatus {
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type ICustomMapping = {
  uid: string;
  metadata: ICustomMappingMetadata;
  status: CustomMappingStatus;
} & (
  | {
      type: CustomMappingType.DocumentReference;
    }
  | SelectionCustomMapping
  | ExclusionCustomMapping
);

export interface ICustomMappingMetadata {
  label: string;
  description: string;
  type: string;
}

export interface ICustomMappingSourceOption {
  label: string;
  value: string;
}
