import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { FeatureInfoComponent } from '@principle-theorem/ng-feature-flags';
import {
  ContentContainerComponent,
  CurrentBrandScope,
  CurrentPracticeScope,
  GlobalStoreService,
  InformationBoxComponent,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BreadcrumbsComponent,
  PipesModule,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  BookingWindowType,
  IBookingWindowTiming,
  IStaffOnlineOrder,
  IStaffer,
  StaffOnlineOrderOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  filterUndefined,
  getEnumValues,
  multiSwitchMap,
  snapshot,
  updateDoc,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Subject, of } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { OnlineBookingTimeRestrictionFormComponent } from '../online-booking-time-restriction-form/online-booking-time-restriction-form.component';

@Component({
  selector: 'pr-patient-portal',
  imports: [
    CommonModule,
    BreadcrumbsComponent,
    FeatureInfoComponent,
    MatButtonModule,
    ContentContainerComponent,
    InformationBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatListModule,
    DragDropModule,
    MatSelectModule,
    MatFormFieldModule,
    PipesModule,
    OnlineBookingTimeRestrictionFormComponent,
  ],
  templateUrl: './patient-portal.component.html',
  styleUrl: './patient-portal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientPortalComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _currentBrand = inject(CurrentBrandScope);
  private _global = inject(GlobalStoreService);
  private _currentPractice = inject(CurrentPracticeScope);
  private _organisation = inject(OrganisationService);
  private _brand = toSignal(this._currentBrand.doc$);

  staff = signal<WithRef<IStaffer>[]>([]);
  breadcrumbs = computed(() => {
    const brand = this._brand();
    return brand
      ? [
          { label: 'Settings', path: '../../../' },
          { label: brand.name },
          { label: 'Prescriptions' },
        ]
      : [];
  });

  staffOrderOptions = StaffOnlineOrderOption;
  staffOrderOptionValues = getEnumValues(StaffOnlineOrderOption);
  orderBy = new TypedFormControl(StaffOnlineOrderOption.Alphabetically);
  minBookingWindow = signal<IBookingWindowTiming | undefined>(undefined);
  maxBookingWindow = signal<IBookingWindowTiming | undefined>(undefined);

  constructor() {
    this._currentPractice.doc$
      .pipe(
        filterUndefined(),
        switchMap(async (practice) => {
          const patientPortal = practice.settings?.patientPortal;
          const bookingWindow = patientPortal?.bookingWindow;
          const staffOnlineOrder = patientPortal?.staffOnlineOrder;
          const customOrder = await this._getCustomOrder(staffOnlineOrder);
          return { ...staffOnlineOrder, customOrder, bookingWindow };
        }),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ orderBy, customOrder, bookingWindow }) => {
        const defaultOrder = StaffOnlineOrderOption.Alphabetically;
        this.orderBy.patchValue(orderBy ?? defaultOrder, { emitEvent: false });
        this.staff.set(customOrder);
        this.minBookingWindow.set(bookingWindow?.min);
        this.maxBookingWindow.set(bookingWindow?.max);
      });

    this.orderBy.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        void this._saveStaffOrder();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async onMinBookingWindowChange(
    value: IBookingWindowTiming | undefined
  ): Promise<void> {
    await this.updateBookingWindow(value, BookingWindowType.Min);
  }

  async onMaxBookingWindowChange(
    value: IBookingWindowTiming | undefined
  ): Promise<void> {
    await this.updateBookingWindow(value, BookingWindowType.Max);
  }

  async updateBookingWindow(
    value: IBookingWindowTiming | undefined,
    type: BookingWindowType
  ): Promise<void> {
    const practice = await snapshot(
      this._currentPractice.doc$.pipe(filterUndefined())
    );

    await updateDoc(practice.ref, {
      settings: {
        ...practice.settings,
        patientPortal: {
          ...practice.settings?.patientPortal,
          bookingWindow: {
            ...practice.settings?.patientPortal?.bookingWindow,
            [type]: value,
          },
        },
      },
    });
  }

  async dropStaffer(event: CdkDragDrop<WithRef<IStaffer>[]>): Promise<void> {
    this.staff.update((staff) => {
      moveItemInArray(staff, event.previousIndex, event.currentIndex);
      return staff;
    });
    await this._saveStaffOrder();
  }

  private async _saveStaffOrder(): Promise<void> {
    const orderBy = this.orderBy.value;
    const customOrder =
      orderBy === StaffOnlineOrderOption.Custom
        ? this.staff().map((staff) => staff.ref)
        : [];
    const practice = await snapshot(
      this._currentPractice.doc$.pipe(filterUndefined())
    );
    await Firestore.patchDoc(practice.ref, {
      settings: {
        ...practice.settings,
        patientPortal: {
          ...practice.settings?.patientPortal,
          staffOnlineOrder: { orderBy, customOrder },
        },
      },
    });
  }

  private async _getCustomOrder(
    settings?: IStaffOnlineOrder
  ): Promise<WithRef<IStaffer>[]> {
    if (!settings || settings.orderBy !== StaffOnlineOrderOption.Custom) {
      return snapshot(this._organisation.practicePractitioners$);
    }

    if (settings.customOrder.length) {
      const staff$ = of(settings.customOrder).pipe(
        multiSwitchMap((ref) => this._global.getStaffer$(ref)),
        map((staff) => compact(staff))
      );
      return snapshot(staff$);
    }

    return [];
  }
}
