import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { type IIntegration } from '@principle-theorem/integrations';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  type IMedipassIntegrationData,
  MedipassIntegrationStorage,
} from '../integration/integration-storage';
import {
  type IMedipassFormData,
  MedipassIntegrationFormComponent,
} from '../medipass-integration-form/medipass-integration-form.component';
import {
  type IMedipassConfigProvider,
  NG_MEDIPASS_CONFIG_PROVIDER,
} from '../ng-medipass-config';

@Component({
  selector: 'pr-manage-medipass-integration',
  templateUrl: './manage-medipass-integration.component.html',
  styleUrls: ['./manage-medipass-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMedipassIntegrationComponent {
  private _storage: MedipassIntegrationStorage =
    new MedipassIntegrationStorage();
  private _orgUid$: ReplaySubject<string> = new ReplaySubject(1);
  private _integrationsCol$: ReplaySubject<
    CollectionReference<IIntegration<IMedipassIntegrationData>>
  > = new ReplaySubject(1);
  integration$: Observable<IIntegration<IMedipassIntegrationData> | undefined>;

  constructor(
    @Inject(NG_MEDIPASS_CONFIG_PROVIDER)
    public config: IMedipassConfigProvider,
    private _dialog: MatDialog
  ) {
    this.integration$ = this._integrationsCol$.pipe(
      switchMap((integrationsCol) => this._storage.get$(integrationsCol))
    );
  }

  @Input()
  set integrationsCol(
    integrationsCol: CollectionReference<IIntegration<IMedipassIntegrationData>>
  ) {
    if (integrationsCol) {
      this._integrationsCol$.next(integrationsCol);
    }
  }

  @Input()
  set orgUid(orgUid: string) {
    if (orgUid) {
      this._orgUid$.next(orgUid);
    }
  }

  async removeIntegration(): Promise<void> {
    const col = await snapshot(this._integrationsCol$);
    await this._storage.remove(col);
  }

  async addIntegration(): Promise<void> {
    const data = await this._dialog
      .open<MedipassIntegrationFormComponent, undefined, IMedipassFormData>(
        MedipassIntegrationFormComponent,
        DialogPresets.medium()
      )
      .afterClosed()
      .toPromise();

    if (!data) {
      return;
    }
    await this._storageAdd(data);
  }

  private async _storageAdd(data: IMedipassIntegrationData): Promise<void> {
    const col = await snapshot(this._integrationsCol$);
    await this._storage.add(col, data);
  }
}
