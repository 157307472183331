import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgCalendarModule } from '@principle-theorem/ng-calendar';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgLabsModule } from '@principle-theorem/ng-labs';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgMediaManagerModule } from '@principle-theorem/ng-media-manager';
import { NgNotificationsModule } from '@principle-theorem/ng-notifications';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTasksModule } from '@principle-theorem/ng-tasks';
import { CoreModule } from '../core/core.module';
import { ToolbarDropdownComponent } from './account/toolbar-dropdown/toolbar-dropdown.component';
import { AuthComponentsModule } from './auth/auth-components.module';
import { ManagementJobsModule } from './management-jobs/management-jobs.module';
import { NotificationTypeFilterComponent } from './notifications/notification-type-filter/notification-type-filter.component';
import { NotificationsSidebarComponent } from './notifications/notifications-sidebar/notifications-sidebar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrganisationActionsComponent } from './organisation-actions/organisation-actions.component';
import { OrganisationStatusComponent } from './organisation-status/organisation-status.component';
import { OrganisationWorkspaceSelectorComponent } from './organisation-workspace-selector/organisation-workspace-selector.component';
import { PageSplashComponent } from './page-splash/page-splash.component';
import { ProviderDetailsInputComponent } from './provider-details-input/provider-details-input.component';
import { RolesModule } from './roles/roles.module';
import { SettingsSidebarComponent } from './settings-sidebar/settings-sidebar.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { SnippetFormComponent } from './snippet/snippet-form/snippet-form.component';
import { SearchPlaceholderComponent } from './toolbar/search-placeholder/search-placeholder.component';
import { SearchComponent } from './toolbar/search/search.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UsersModule } from './users/users.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NavbarComponent } from './page-splash/navbar/navbar.component';

@NgModule({
  declarations: [
    OrganisationWorkspaceSelectorComponent,
    OrganisationStatusComponent,
    NotificationsComponent,
    NotificationsSidebarComponent,
    NotificationTypeFilterComponent,
    PageSplashComponent,
    SnippetFormComponent,
    SettingsSidebarComponent,
    ToolbarDropdownComponent,
    ToolbarComponent,
    SearchComponent,
    SearchPlaceholderComponent,
    ProviderDetailsInputComponent,
    OrganisationActionsComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    NgSharedModule,
    ReactiveFormsModule,
    NgEditorModule,
    UsersModule,
    RolesModule,
    NgNotificationsModule,
    CoreModule,
    AuthComponentsModule,
    NgInteractionsModule,
    NgMediaManagerModule,
    SidebarModule,
    NgFeatureFlagsModule,
    NgTasksModule,
    NgLabsModule,
    NgCalendarModule,
    ManagementJobsModule,
    NgIntercomComponentsModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    AuthComponentsModule,
    OrganisationWorkspaceSelectorComponent,
    OrganisationStatusComponent,
    UsersModule,
    RolesModule,
    NotificationsComponent,
    NotificationsSidebarComponent,
    PageSplashComponent,
    SettingsSidebarComponent,
    SidebarModule,
    ToolbarDropdownComponent,
    ToolbarComponent,
    SearchComponent,
    ProviderDetailsInputComponent,
    ManagementJobsModule,
    OrganisationActionsComponent,
  ],
})
export class ComponentsModule {}
