import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../core/reporting-core.module';
import { ReportingComponentsModule } from '../reporting-components/reporting-components.module';
import { DrilldownChartComponent } from './drilldown-chart/drilldown-chart.component';
import { DrilldownPreviewComponent } from './drilldown-preview/drilldown-preview.component';
import { PatientDemographicsComponent } from './patient-demographics/patient-demographics.component';
import { PatientFlowComponent } from './patient-flow.component';

@NgModule({
  imports: [
    ReportingCoreModule,
    ReportingComponentsModule,
    NgSharedModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PatientFlowComponent,
    DrilldownPreviewComponent,
    DrilldownChartComponent,
    PatientDemographicsComponent,
  ],
  exports: [DrilldownPreviewComponent, DrilldownChartComponent],
})
export class PatientFlowModule {}
