<mat-toolbar color="accent">Update Patient</mat-toolbar>

<mat-dialog-content fxLayout="column">
  <pr-edit-patient-form
    #form
    [patient]="patient"
    (submitted)="updatePatient($event)"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.patientForm.invalid"
      (click)="form.submit()"
    >
      Update Patient
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
