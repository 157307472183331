import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type IDVACard } from '@principle-theorem/principle-core/interfaces';
import { sanatiseHealthCardNumber } from '@principle-theorem/principle-core';
import { isChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-dva-card-form',
  templateUrl: './dva-card-form.component.html',
  styleUrls: ['./dva-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  exportAs: 'prDVACardForm',
})
export class DVACardFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dvaForm = new TypedFormGroup<IDVACard>({
    number: new TypedFormControl<string>(undefined, [Validators.required]),
    expiryDate: new TypedFormControl(undefined),
  });
  @Output() dvaCardChange = new EventEmitter<IDVACard>();

  constructor() {
    validFormGroupChanges$(this.dvaForm)
      .pipe(isChanged$(), debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe((dvaCard) =>
        this.dvaCardChange.emit({
          ...dvaCard,
          expiryDate: undefined,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set dvaCard(dvaCard: IDVACard) {
    if (dvaCard) {
      this.dvaForm.patchValue(
        {
          number: sanatiseHealthCardNumber(dvaCard.number),
          expiryDate: undefined,
        },
        { emitEvent: false }
      );
    }
  }
}
