import { Component, Input, type OnDestroy, type OnInit } from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { compact } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  generateBuilderData,
  toMeasureBuilderData,
  type IChartConfig,
} from '@principle-theorem/reporting';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type CanBeChartedProperty } from '@principle-theorem/reporting';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

interface IDrilldownFormData {
  mainMeasure: CanBeChartedProperty;
  optionalMeasure: CanBeChartedProperty;
}

@Component({
  selector: 'pr-drilldown-chart',
  templateUrl: './drilldown-chart.component.html',
  styleUrls: ['./drilldown-chart.component.sass'],
})
export class DrilldownChartComponent
  extends MeasureDataChartComponent
  implements OnInit, OnDestroy
{
  protected override _onDestroy$: Subject<void> = new Subject();
  trackByMeasure =
    TrackByFunctions.nestedField<CanBeChartedProperty>('metadata.label');
  chartBuilder: ChartBuilder;
  form: TypedFormGroup<IDrilldownFormData> =
    new TypedFormGroup<IDrilldownFormData>({
      mainMeasure: new TypedFormControl(),
      optionalMeasure: new TypedFormControl(),
    });
  @Input() measures: CanBeChartedProperty[] = [];

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._redraw());
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get selected(): CanBeChartedProperty[] {
    return compact(
      Object.keys(this.form.controls)
        .map((key) => this.form.controls[key as keyof IDrilldownFormData].value)
        .filter((value) => !!value)
    );
  }

  select(measure: CanBeChartedProperty): void {
    this.form.controls.mainMeasure.setValue(measure);
    this._redraw();
  }

  protected _redraw(): void {
    if (!this.dataBuilder || !this.selected.length) {
      return;
    }

    const chart: ChartBuilder = this.dataBuilder.toLineChart(
      this._config
    ).chartBuilder;

    chart.addChartOptions(this._chartOptions);
    this.chartBuilder = chart;
  }

  private get _config(): IChartConfig {
    return {
      type: CustomChartType.Line,
      builderData: generateBuilderData({
        plottedOverTime: true,
        measures: this.selected.map((measure) => toMeasureBuilderData(measure)),
      }),
      labels: {
        title: '',
      },
    };
  }

  private get _chartOptions(): IDrilldownChartOptions {
    return {
      theme: 'maximized',
      backgroundColor: 'none',
      legend: { position: 'none' },
      height: 250,
      series: {
        0: { axis: 'Axis 0' },
        1: { axis: 'Axis 1' },
      },
      vAxis: {
        viewWindow: {
          min: 0,
        },
      },
    };
  }
}

export interface IDrilldownChartOptions {
  theme: 'maximized';
  backgroundColor: 'none';
  legend: { position: 'none' };
  height: number;
  series: {
    0: { axis: 'Axis 0' };
    1: { axis: 'Axis 1' };
  };
  vAxis: {
    viewWindow: {
      min: 0;
    };
  };
}
