<div fxLayout="column">
  <div class="layout-padding">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 class="mat-headline-6">Patient Visits</h3>
      <pt-download-report
        [data]="records$ | async"
        [dateRange]="dateRange$ | async"
        [csvTranslator]="csvTranslator"
      />
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    fxFlex
    matSort
    matSortActive="appointmentDate"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>Patient</th>
      <td mat-cell *matCellDef="let record">
        <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
          {{ record.patient.name }}
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="mobileNumber">
      <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
      <td mat-cell *matCellDef="let record">
        {{ record.patient | map: getMobileNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let record">
        {{ record.patient.email }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="appointmentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Appointment Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.appointment.event.from | moment | amDateFormat: dateFormat }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="practitioner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Practitioner</th>
      <td mat-cell *matCellDef="let record">
        {{ record.appointment.practitioner.name }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="isFirstAppointment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        First Appointment?
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.isFirstAppointment ? 'Yes' : 'No' }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="referredBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Referred By</th>
      <td mat-cell *matCellDef="let record">
        <pr-patient-referrer [referrer]="record.patient.referrer" />
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
