<div fxFill fxLayout="column">
  <h2 *ngIf="chart.title">{{ chart.title }}</h2>
  <div fxFlex>
    <mat-card appearance="outlined" fxLayout="column">
      <mat-card-content>
        <div class="forecast-card-contents">
          <pr-chart [chartBuilder]="chart.chartBuilder" />
        </div>
        <div class="forecast-card-contents">
          <div [innerHTML]="chart.summary"></div>
        </div>
        <div fxFlex class="report-card-footer forecast-card-range-selector">
          <div fxLayout="column" fxLayoutAlign="end start">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-select
                fxFlex="25"
                placeholder="Report Range"
                [value]="selectedRange"
                (selectionChange)="onDateChange()"
              >
                <mat-option
                  *ngFor="let range of ranges; trackBy: trackByRange"
                  [value]="range"
                >
                  {{ range.title }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
