import {
  IContextBuilder,
  IInvoiceScopeData,
  IInvoiceTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  DAY_MONTH_YEAR_FORMAT,
  TIME_FORMAT,
  toMoment,
} from '@principle-theorem/shared';
import { PatientContextBuilder } from './patient-context';

export class InvoiceContextBuilder
  implements IContextBuilder<IInvoiceTemplateContext>
{
  private _patientContextBuilder: PatientContextBuilder;

  constructor(private _data: IInvoiceScopeData) {
    this._patientContextBuilder = new PatientContextBuilder(this._data);
  }

  build(): IInvoiceTemplateContext {
    return {
      ...this._patientContextBuilder.build(),
      invoiceLink: this._data.invoiceLink,
      invoiceCreatedAt: toMoment(this._data.invoice.createdAt).calendar(
        undefined,
        CALENDAR_TIME_FORMAT
      ),
      invoiceCreatedAtDate: toMoment(this._data.invoice.createdAt).format(
        DAY_MONTH_YEAR_FORMAT
      ),
      invoiceCreatedAtTime: toMoment(this._data.invoice.createdAt).format(
        TIME_FORMAT
      ),
      invoiceReference: this._data.invoice.reference,
      invoicePracticeName: this._data.invoice.practice.name,
    };
  }
}
