<pt-buttons-container>
  <button mat-flat-button color="primary" (click)="addTask()">Add Task</button>
  <button mat-flat-button color="primary" (click)="addForm()">Add Form</button>
  <button mat-flat-button color="primary" (click)="addNotification()">
    Add Notification
  </button>
</pt-buttons-container>

<pr-automation-reschedule-list
  [automations]="automations$ | async"
  [patient]="patient$ | async"
  (editAutomation)="edit($event)"
  (cancelAutomation)="cancel($event)"
  (setAutomationPending)="setPending($event)"
/>
