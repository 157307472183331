<ng-container *ngrxLet="event$ as event">
  <mat-list-item *ngIf="event.participants.length > 1">
    <mat-icon matListItemIcon>group</mat-icon>
    <span matListItemTitle>Participants</span>
    <p
      matListItemLine
      *ngFor="
        let participant of event.participants;
        trackBy: trackByParticipant
      "
    >
      {{ participant.name }}
    </p>
  </mat-list-item>
</ng-container>
