import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import {
  IInvoice,
  IPatient,
  ITooth,
  ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { ToothSelectorComponent } from '../../tooth-selector/tooth-selector.component';
import { TreatmentPlansConsolidatedStore } from '../../treatment-plans-consolidated/treatment-plans-consolidated.store';
import { ToothHistoryListFiltersComponent } from '../tooth-history-list-filters/tooth-history-list-filters.component';
import { ToothHistoryListComponent } from '../tooth-history-list/tooth-history-list.component';
import { ToothHistoryStore } from '../tooth-history.store';

@Component({
  selector: 'pr-tooth-history',
  standalone: true,
  imports: [
    CommonModule,
    ToothHistoryListComponent,
    ToothHistoryListFiltersComponent,
    ToothSelectorComponent,
  ],
  templateUrl: './tooth-history.component.html',
  styleUrls: ['./tooth-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ToothHistoryStore, TreatmentPlansConsolidatedStore],
})
export class ToothHistoryComponent implements OnDestroy {
  private _consolidatedPlanStore = inject(TreatmentPlansConsolidatedStore);
  private _toothHistoryStore = inject(ToothHistoryStore);
  private _onDestroy$ = new Subject<void>();
  private _patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  selectedTeeth$ = new BehaviorSubject<ITooth[]>([]);

  @Output() openPlan = new EventEmitter<DocumentReference<ITreatmentPlan>>();
  @Output() openInvoice = new EventEmitter<DocumentReference<IInvoice>>();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this._patient$.next(patient);
    }
  }

  @Input()
  set selectedTeeth(selectedTeeth: ITooth[]) {
    if (selectedTeeth) {
      this.selectedTeeth$.next(selectedTeeth);
    }
  }

  constructor() {
    this._consolidatedPlanStore.initialiseStore(this._patient$);
    this._toothHistoryStore.loadCharts(this._patient$);

    this._toothHistoryStore.loadToothHistory(
      combineLatest([
        this.selectedTeeth$,
        this._consolidatedPlanStore.consolidatedPlan$,
        this._toothHistoryStore.charts$,
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
