import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StorageModule } from '@ngx-pwa/local-storage';
import { AuthUserCardComponent } from './auth-user-card/auth-user-card.component';
import { SwitchUserDialogComponent } from './switch-user-dialog/switch-user-dialog.component';
import { SwitchUserListComponent } from './switch-user-list/switch-user-list.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { SwitchUserService } from './switch-user-list/switch-user.service';
import { CoreModule } from '../../core/core.module';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';

@NgModule({
  declarations: [
    AuthUserCardComponent,
    SwitchUserDialogComponent,
    SwitchUserListComponent,
    PasswordFormComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    NgPrincipleSharedModule,
    NgFirebaseModule,
    StorageModule,
    NgSharedModule,
    NgAuthModule,
  ],
  providers: [SwitchUserService],
  exports: [
    AuthUserCardComponent,
    SwitchUserDialogComponent,
    SwitchUserListComponent,
    PasswordFormComponent,
  ],
})
export class AuthComponentsModule {}
