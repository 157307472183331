import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateRangeService } from '../date-range.service';

@Component({
    selector: 'pr-date-range-presets-header',
    templateUrl: './date-range-presets-header.component.html',
    styleUrls: ['./date-range-presets-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DateRangePresetsHeaderComponent {
  rangeSummary$: Observable<string>;

  constructor(public rangeService: DateRangeService) {
    this.rangeSummary$ = this.rangeService.selectedDayRange$.pipe(
      map((range) => {
        if (!range) {
          return '';
        }

        if (range > 1) {
          return `${range} days`;
        }

        return '1 day';
      })
    );
  }
}
