<div class="flex flex-col gap-1">
  <ng-container *ngIf="conditions$ | async as conditions">
    <div *ngIf="conditions.length">
      <span>Medical Conditions</span>
      <span
        class="text-xs opacity-50"
        *ngFor="let condition of conditions; trackBy: trackByCondition"
      >
        &bull; {{ condition }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="allergies$ | async as allergies">
    <div *ngIf="allergies.length">
      <span>Allergies</span>
      <span
        class="text-xs opacity-50"
        *ngFor="let allergy of allergies; trackBy: trackByAllergies"
      >
        &bull; {{ allergy }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="history$ | async as history">
    <div *ngIf="history.length">
      <span>Dental History</span>
      <span
        class="text-xs opacity-50"
        *ngFor="let historyItem of history; trackBy: trackByHistory"
      >
        &bull; {{ historyItem }}
      </span>
    </div>
  </ng-container>
</div>
