<div fxLayout="column" fxFill>
  <mat-toolbar color="accent">
    <span fxFlex>Fill Gap</span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <div fxLayout="row">
    <div fxLayout="column" fxFlex class="overflow-hidden">
      <div fxFlex class="overflow-scroll">
        <div class="move-appointment">
          <mat-vertical-stepper
            fxFill
            linear
            class="steps"
            #stepper="matVerticalStepper"
            (selectionChange)="handleStepChange($event)"
          >
            <mat-step [completed]="appointmentDetails.form.valid">
              <ng-template matStepLabel>
                <ng-container
                  *ngrxLet="appointmentSuggestion$ as appointmentSuggestion"
                >
                  Moving Appointment to
                  <pr-formatted-event-field
                    field="from"
                    [event]="appointmentSuggestion"
                    [format]="dateService.format.dateWithTimes"
                  />
                </ng-container>
              </ng-template>
              <pr-appointment-details
                #appointmentDetails
                [appointmentDetails]="appointmentDetails$ | async"
                [practitioners]="practitioners$ | async"
                [practices]="practices$ | async"
              />
              <div>
                <button mat-flat-button color="primary" matStepperNext>
                  Next
                </button>
              </div>
            </mat-step>

            <mat-step [completed]="completed$ | async" #appointmentSelectStep>
              <ng-template matStepLabel>Select a time</ng-template>
              <pr-appointment-options
                [options]="appointmentOptions$ | async"
                [selected]="appointmentSuggestion$ | async"
                (selectedChange)="appointmentSuggestion$.next($event)"
              />

              <div>
                <button mat-flat-button color="primary" matStepperNext>
                  Next
                </button>
              </div>
            </mat-step>

            <mat-step
              [completed]="
                waitlistConfig.form.valid ||
                !waitlistConfig.form.controls.addToWaitlist.value
              "
            >
              <ng-template matStepLabel>Keep on Waitlist?</ng-template>

              <pr-waitlist-configuration
                #waitlistConfig
                [openTime]="openTime$ | async"
                [closeTime]="closeTime$ | async"
                [waitlistDetails]="waitlistDetails$ | async"
                (waitListChange)="waitlistChange($event)"
              />

              <div>
                <button mat-flat-button color="primary" matStepperNext>
                  Next
                </button>
              </div>
            </mat-step>

            <mat-step label="Automations" optional>
              <pr-appointment-automations-reschedule
                class="step-content"
                [treatmentStep]="treatmentStep$ | ngrxPush"
                [newEvent]="newEvent$ | ngrxPush"
                [patient]="data.patient"
              />
            </mat-step>
          </mat-vertical-stepper>
        </div>
      </div>

      <pt-page-footer>
        <pt-buttons-container padding="none">
          <pr-scheduling-event-reason-selector
            [formControl]="reasonControl"
            [reasons]="reasons$ | async"
            [schedulingConditions]="schedulingConditions$ | async"
          />

          <button
            class="h-full"
            mat-flat-button
            color="primary"
            [disabled]="(completed$ | async) === false"
            (click)="submit()"
          >
            Save
          </button>
        </pt-buttons-container>
      </pt-page-footer>
    </div>

    <pr-appointment-sidebar
      [patient]="data.patient"
      [appointment]="data.appointment"
      class="overflow-scroll"
    />
  </div>
</div>
