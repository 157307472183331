import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InteractiveTimelineService } from '@principle-theorem/ng-eventable';
import {
  CurrentScopeFacade,
  GapStoreService,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import { Event, TimezoneResolver } from '@principle-theorem/principle-core';
import {
  EventType,
  IScheduleSummaryEventable,
  IEvent,
} from '@principle-theorem/principle-core/interfaces';
import { TIME_FORMAT, snapshot, toMomentTz } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-gap-item',
  templateUrl: './gap-item.component.html',
  styleUrls: ['./gap-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapItemComponent {
  createModes = [
    EventType.Appointment,
    EventType.PreBlock,
    EventType.Meeting,
    EventType.Misc,
    EventType.Break,
    EventType.Leave,
  ];
  gap$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  timeFormat = TIME_FORMAT;
  eventType = EventType;
  @Input({ transform: coerceBooleanProperty }) isFocusView = false;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  constructor(
    private _globalStore: GlobalStoreService,
    private _interactiveTimeline: InteractiveTimelineService,
    private _currentScope: CurrentScopeFacade,
    private _gapStore: GapStoreService
  ) {}

  getPractitionerImage$(
    gap: IScheduleSummaryEventable
  ): Observable<string | undefined> {
    return this._globalStore.getStafferImage$({
      ref: Event.staff(gap.event)[0].ref,
    });
  }

  getPractitionerName$(
    gap: IScheduleSummaryEventable
  ): Observable<string | undefined> {
    return this._globalStore.getStafferName$({
      ref: Event.staff(gap.event)[0].ref,
    });
  }

  handleMoreMenuClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  async selectGap(gap: IScheduleSummaryEventable): Promise<void> {
    await this._gapStore.displayGapOnTimeline(gap);
  }

  async previewGap(gap: IScheduleSummaryEventable): Promise<void> {
    await this._gapStore.displayGapOnTimeline(
      gap,
      this.isFocusView ? true : false
    );
  }

  async createEventFromGap(
    gap: IScheduleSummaryEventable,
    eventType: EventType
  ): Promise<void> {
    await this._gapStore.cleanUp();

    const practice = await snapshot(this._currentScope.currentPractice$);
    const trackIndex = await this._interactiveTimeline.getTrackIndexFromEvent(
      gap.event
    );
    if (!practice || trackIndex === undefined) {
      return;
    }

    const timezone = practice.settings.timezone;
    const time = {
      from: toMomentTz(gap.event.from, timezone),
      to: toMomentTz(gap.event.to, timezone),
    };
    const day = {
      from: toMomentTz(gap.event.from, timezone).startOf('day'),
      to: toMomentTz(gap.event.to, timezone).endOf('day'),
    };

    const createNodeData = this._interactiveTimeline.createNodeDataFromGap(
      gap,
      eventType
    );

    await this._interactiveTimeline.handleCreateFromGap(
      time,
      day,
      Event.staff(gap.event)[0].ref,
      eventType,
      practice,
      createNodeData,
      trackIndex
    );
  }

  async getEventMoment(event: IEvent): Promise<string> {
    const timezone = await TimezoneResolver.fromEvent(event);
    const from = toMomentTz(event.from, timezone).format(this.timeFormat);
    const to = toMomentTz(event.to, timezone).format(this.timeFormat);
    return `${from} - ${to}`;
  }

  getGapDurationString(gap: IScheduleSummaryEventable): string {
    const duration = Event.duration(gap.event);
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    const formatTime = (value: number, unit: string): string =>
      `${value} ${unit}${value > 1 ? 's' : ''}`;

    if (!hours && !minutes) {
      return '';
    }
    if (!hours) {
      return formatTime(minutes, 'minute');
    }
    if (!minutes) {
      return formatTime(hours, 'hour');
    }

    return `${formatTime(hours, 'hour')} ${formatTime(minutes, 'minute')}`;
  }
}
