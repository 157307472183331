import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action } from '@ngrx/store';
import { Patient, TreatmentPlan } from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  auditUserInput,
  filterUndefined,
  patchDoc,
  serialise$,
  unserialise$,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { TreatmentPlanActions } from '../../actions/treatment-plans';
import {
  addTreatmentSteps,
  removeTreatmentStep,
  updateTreatmentPlan,
} from '../../actions/treatment-plans/treatment-plans';
import { TreatmentPlanFacade } from '../../facades/treatment-plans/treatment-plan.facade';

@Injectable()
export class TreatmentPlansEffects {
  private _actions$ = inject(Actions);
  loadTreatmentPlans$: Observable<Action> = createEffect(() =>
    this._loadTreatmentPlans$()
  );
  triggerAddTreatmentSteps$: Observable<Action> = createEffect(() =>
    this._triggerAddTreatmentSteps$()
  );
  triggerRemoveTreatmentStep$: Observable<Action> = createEffect(() =>
    this._triggerRemoveTreatmentStep$()
  );
  saveTreatmentPlan$: Observable<DocumentReference> = createEffect(
    () => this._saveTreatmentPlan$(),
    { dispatch: false }
  );

  constructor(private _treatmentPlanFacade: TreatmentPlanFacade) {}

  private _loadTreatmentPlans$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(TreatmentPlanActions.loadTreatmentPlans),
      unserialise$(),
      switchMap(({ patient }) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          TreatmentPlan.all$
        )
      ),
      serialise$(),
      map((treatmentPlans) =>
        TreatmentPlanActions.loadTreatmentPlansSuccess({
          treatmentPlans,
        })
      )
    );
  }

  private _triggerAddTreatmentSteps$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(addTreatmentSteps),
      unserialise$(),
      concatLatestFrom((action) =>
        this._treatmentPlanFacade.getTreatmentPlan$(
          asDocRef<ITreatmentPlan>(action.planRef)
        )
      ),
      map(([_, plan]) => plan),
      filterUndefined(),
      serialise$(),
      map((treatmentPlan) =>
        updateTreatmentPlan({
          planRef: treatmentPlan.ref,
          changes: { steps: treatmentPlan.steps },
        })
      )
    );
  }

  private _triggerRemoveTreatmentStep$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(removeTreatmentStep),
      unserialise$(),
      concatLatestFrom((action) =>
        this._treatmentPlanFacade.getTreatmentPlan$(
          asDocRef<ITreatmentPlan>(action.planRef)
        )
      ),
      map(([_, plan]) => plan),
      filterUndefined(),
      serialise$(),
      map((treatmentPlan) =>
        updateTreatmentPlan({
          planRef: treatmentPlan.ref,
          changes: { steps: treatmentPlan.steps },
        })
      )
    );
  }

  private _saveTreatmentPlan$(): Observable<DocumentReference> {
    return this._actions$.pipe(
      ofType(TreatmentPlanActions.updateTreatmentPlan),
      auditUserInput(),
      unserialise$(),
      concatMap(({ planRef, changes }) => patchDoc(planRef, changes))
    );
  }
}
