import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ClinicalChart } from '@principle-theorem/principle-core';
import {
  type IClinicalChart,
  type ITooth,
  type IToothRef,
  type Quadrant,
  QUADRANTS,
  ToothType,
  TOOTH_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { ROOT_OPTIONS } from '../../models/chart-tooth-surfaces-svg-map';

export interface IAddToothDialogData {
  chart: IClinicalChart;
  quadrant?: Quadrant;
}

@Component({
  selector: 'pr-add-tooth-dialog',
  templateUrl: './add-tooth-dialog.component.html',
  styleUrls: ['./add-tooth-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToothDialogComponent {
  trackByQuadrant = TrackByFunctions.variable<Quadrant>();
  trackByToothType = TrackByFunctions.variable<ToothType>();
  trackByRoot = TrackByFunctions.variable<number>();
  toothTypes: ToothType[] = TOOTH_TYPES;
  rootOptions: number[] = ROOT_OPTIONS;
  quadrants: Quadrant[] = QUADRANTS;
  form: TypedFormGroup<ITooth> = new TypedFormGroup<ITooth>({
    type: new TypedFormControl<ToothType>(
      ToothType.Incisor,
      Validators.required
    ),
    roots: new TypedFormControl<number>(1, Validators.required),
    toothRef: new TypedFormGroup<IToothRef>({
      quadrant: new TypedFormControl<Quadrant>(undefined, Validators.required),
      quadrantIndex: new TypedFormControl<number>(
        undefined,
        Validators.required
      ),
    }),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAddToothDialogData,
    private _dialogRef: MatDialogRef<AddToothDialogComponent>,
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade
  ) {
    if (!this.data.quadrant) {
      return;
    }
    this.form.patchValue({
      toothRef: {
        quadrant: this.data.quadrant,
        quadrantIndex: ClinicalChart.getNextToothIndex(
          this.data.chart,
          this.data.quadrant
        ),
      },
    });
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const tooth = this.form.getRawValue();

    if (
      ClinicalChart.hasToothIndex(
        this.data.chart,
        tooth.toothRef.quadrant,
        tooth.toothRef.quadrantIndex
      )
    ) {
      this._snackBar.open('This tooth already exists');
      return;
    }

    this._chartStore.addTooth(ChartId.InAppointment, tooth);
    this._snackBar.open('Tooth Added');
    this._dialogRef.close();
  }
}
