import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getSchemaSize } from '@principle-theorem/editor';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  InteractionType,
  isInteractionV2,
  type IInteraction,
  type IInteractionV2,
  ISchedulingEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  EditInteractionDialogComponent,
  IEditInteractionResponse,
  type IEditInteractionDialogData,
} from '../edit-interaction-dialog/edit-interaction-dialog.component';
import {
  InteractionAmendmentHistoryDialogComponent,
  type IInteractionAmendmentHistoryDialogData,
} from '../interactions/interaction-amendment-history-dialog/interaction-amendment-history-dialog.component';
import { Interaction } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-interaction-actions',
    templateUrl: './interaction-actions.component.html',
    styleUrls: ['./interaction-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionActionsComponent {
  interaction$ = new ReplaySubject<IInteraction | WithRef<IInteractionV2>>(1);
  canEdit$ = new ReplaySubject<boolean>(1);
  canPin$: Observable<boolean>;
  isPinned$: Observable<boolean>;
  hasHistory$: Observable<boolean>;
  pinnableTypes$ = new BehaviorSubject<InteractionType[]>([
    InteractionType.Note,
    InteractionType.Call,
  ]);
  schedulingEvent$: Observable<WithRef<ISchedulingEvent> | undefined>;
  canDelete$: Observable<boolean>;

  @Output() interactionDeleted = new EventEmitter<void>();
  @Output() interactionUpdated = new EventEmitter<
    Partial<IInteraction | WithRef<IInteractionV2>>
  >();

  @Input()
  set canEdit(canEdit: BooleanInput) {
    this.canEdit$.next(coerceBooleanProperty(canEdit));
  }

  @Input()
  set pinnableTypes(pinnableTypes: InteractionType[]) {
    if (pinnableTypes) {
      this.pinnableTypes$.next(pinnableTypes);
    }
  }

  @Input()
  set interaction(interaction: IInteraction | WithRef<IInteractionV2>) {
    if (interaction) {
      this.interaction$.next(interaction);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService
  ) {
    this.canPin$ = combineLatest([this.interaction$, this.pinnableTypes$]).pipe(
      map(
        ([interaction, pinnableTypes]) =>
          getSchemaSize(interaction.content) > 0 &&
          pinnableTypes.includes(interaction.type)
      )
    );
    this.isPinned$ = this.interaction$.pipe(
      map((interaction) => isInteractionV2(interaction) && interaction.pinned)
    );
    this.hasHistory$ = this.interaction$.pipe(
      map(
        (interaction) =>
          isInteractionV2(interaction) && !!interaction.amendmentOf
      )
    );
    this.schedulingEvent$ = this.interaction$.pipe(
      switchMap((interaction) => Interaction.getSchedulingEvent$(interaction))
    );
    this.canDelete$ = combineLatest([
      this.canEdit$,
      this.schedulingEvent$,
    ]).pipe(map(([canEdit, schedulingEvent]) => canEdit && !schedulingEvent));
  }

  async togglePinned(): Promise<void> {
    const interaction = await snapshot(this.interaction$);
    if (!isInteractionV2(interaction)) {
      return;
    }
    this.interactionUpdated.emit({
      pinned: !interaction.pinned,
    });
  }

  async edit(): Promise<void> {
    const interaction = await snapshot(this.interaction$);
    if (!isInteractionV2(interaction)) {
      return;
    }
    const config = DialogPresets.large<IEditInteractionDialogData>({
      data: {
        interaction,
      },
    });

    const response = await this._dialog
      .open<
        EditInteractionDialogComponent,
        IEditInteractionDialogData,
        IEditInteractionResponse
      >(EditInteractionDialogComponent, config)
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    this.interactionUpdated.emit(response.interaction);

    const schedulingEvent = await snapshot(this.schedulingEvent$);
    if (schedulingEvent) {
      await Firestore.patchDoc(schedulingEvent.ref, {
        reason: response.selectedReason.reason,
        reasonSetManually: response.selectedReason.reasonSetManually,
      });
    }
  }

  async delete(): Promise<void> {
    const confirmed = await this._basicDialog.confirm({
      prompt: 'Are you sure you want to delete this interaction?',
      title: 'Delete Interaction',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    this.interactionDeleted.emit();
  }

  async viewHistory(): Promise<void> {
    const interaction = await snapshot(this.interaction$);
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await this._dialog
      .open<
        InteractionAmendmentHistoryDialogComponent,
        IInteractionAmendmentHistoryDialogData,
        undefined
      >(
        InteractionAmendmentHistoryDialogComponent,
        DialogPresets.large({
          width: '80%',
          height: '80%',
          data: { interaction },
        })
      )
      .afterClosed()
      .toPromise();
  }
}
