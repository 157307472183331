<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Media Tags</h1>
  <pr-information-box>
    Manage the tags available for labelling media.
  </pr-information-box>

  <ng-container *ngIf="tags$ | async as tags">
    <pr-tag-list [tags]="tags" (tagAdded)="addTag($event)" />
  </ng-container>
</div>
