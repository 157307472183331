import {
  getSchemaText,
  initRawSchema,
  RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import { IReffable } from '@principle-theorem/shared';
import {
  INotification,
  INotificationContextRenderer,
} from '@principle-theorem/notifications';
import { toMention } from '../../mention/mention';
import { stafferIsOfRole } from './notification-helpers';
import {
  NotificationType,
  ICompletedTaskResources,
  isTaskResource,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';

export class CompletedTaskContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      ICompletedTaskResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.CompletedTask);
  }

  isResource(data: unknown): data is ICompletedTaskResources {
    return isTaskResource(data);
  }

  render(data: ICompletedTaskResources, currentStaffer?: IReffable): RawSchema {
    const isActor: boolean = stafferIsOfRole(currentStaffer, data.interactor);
    const isAssignee: boolean = stafferIsOfRole(
      currentStaffer,
      data.interactor
    );

    const taskMention = toMention(
      { name: getSchemaText(data.task.title), ref: data.task.ref },
      MentionResourceType.Task
    );

    if (isActor || isAssignee) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`You completed task `),
          toMentionContent(taskMention),
        ]),
      ]);
    }

    if (data.interactor) {
      const interactorMention = toMention(
        data.interactor,
        MentionResourceType.Staffer
      );
      return initRawSchema([
        toParagraphContent([
          toMentionContent(interactorMention),
          toTextContent(` completed delegated task `),
          toMentionContent(taskMention),
        ]),
      ]);
    }

    return initRawSchema([
      toParagraphContent([
        toTextContent(`System completed task `),
        toMentionContent(taskMention),
      ]),
    ]);
  }
}
