import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { WithRef } from '@principle-theorem/shared';
import { ISterilisationEquipment } from '@principle-theorem/principle-core/interfaces';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@Component({
    selector: 'pr-sterilisation-equipment-list',
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        NgPrincipleSharedModule,
    ],
    templateUrl: './sterilisation-equipment-list.component.html',
    styleUrl: './sterilisation-equipment-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationEquipmentListComponent {
  equipment$ = new ReplaySubject<WithRef<ISterilisationEquipment>[]>(1);

  @Output() delete = new EventEmitter<WithRef<ISterilisationEquipment>>();
  @Output() edit = new EventEmitter<WithRef<ISterilisationEquipment>>();

  @Input()
  set items(items: WithRef<ISterilisationEquipment>[]) {
    if (items) {
      this.equipment$.next(items);
    }
  }
}
