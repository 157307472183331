<div class="flex flex-col gap-2">
  <div class="text-base font-bold">Notes</div>
  <div class="flex flex-col gap-1">
    <div
      *ngFor="
        let noteSummary of noteSummaries$ | ngrxPush;
        trackBy: trackByNote
      "
    >
      <a class="cursor-pointer" (click)="noteSummary.open()">
        {{ noteSummary.count }} {{ noteSummary.name }}
      </a>

      <ng-container *ngIf="noteSummary.pinnedNotes.length">
        <div
          *ngFor="
            let note of noteSummary.pinnedNotes;
            trackBy: trackByPinnedNote
          "
        >
          <pr-content-text [content]="note.content" />
        </div>
      </ng-container>
    </div>
  </div>
</div>
