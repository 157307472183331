<mat-form-field
  fxFlex
  fxFill
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="timeControl"
    [matAutocomplete]="auto"
    [matAutocompleteDisabled]="timeControl.disabled"
    [required]="required"
    #autoComplete="matAutocompleteTrigger"
    (click)="autoComplete.openPanel()"
    (blur)="blur()"
  />

  <button
    matIconSuffix
    mat-icon-button
    type="button"
    [disabled]="timeControl.disabled"
    (click)="$event.stopPropagation(); setValue()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <mat-autocomplete
    #auto="matAutocomplete"
    [disableRipple]="true"
    [displayWith]="displayFn"
    (optionSelected)="setValue($event.option.value)"
  >
    <mat-option
      *ngFor="let time of searchFilter.results$ | async; trackBy: trackByTime"
      [value]="time"
    >
      {{ time | timeDisplay }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
