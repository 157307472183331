import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IOrganisation } from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import {
  addDoc,
  httpsCallable,
  slugify,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { startWith } from 'rxjs/operators';
import {
  OrganisationAddDialogComponent,
  type OrganisationFormData,
} from '../organisation-add-dialog/organisation-add-dialog.component';
import {
  INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';

@Component({
  selector: 'pr-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationsComponent {
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IOrganisation>>;
  isProduction: boolean;

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IOrganisation>>(
      Organisation.all$(),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );

    this.isProduction = this._config.production;
  }

  async addOrganisation(): Promise<void> {
    const organisationData = await this._dialog
      .open<OrganisationAddDialogComponent, undefined, OrganisationFormData>(
        OrganisationAddDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (!organisationData || !organisationData.name) {
      return;
    }

    const organisation: IOrganisation = Organisation.init(organisationData);

    const alreadyExists: boolean =
      await this._authFunctions.checkOrganisationExists(organisation.slug);

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    await addDoc(Organisation.col(), organisation);
    this._snackBar.open('Organisation Added');
  }

  async addDemoOrganisation(): Promise<void> {
    const organisationData = await this._dialog
      .open<OrganisationAddDialogComponent, undefined, OrganisationFormData>(
        OrganisationAddDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (!organisationData || !organisationData.name) {
      return;
    }

    const alreadyExists: boolean =
      await this._authFunctions.checkOrganisationExists(
        slugify(organisationData.name.toLowerCase())
      );

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    await snapshot(
      httpsCallable('http-demoWorkspace-create')(organisationData)
    );
  }
}
