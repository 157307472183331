<h3>Preselected Filters</h3>
<div *ngIf="dataSource$ | async as dataSource">
  <pr-query-filter-display
    label="Data Source"
    [values]="dataSource.name"
   />
  <pr-query-filter-display
    label="By Date"
    [values]="timestampLabel$ | async"
   />
  <pr-custom-report-filters
    [factTable]="dataSource.factTable"
    [filters]="staticFilters$ | async"
   />
</div>
