import {
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  Interaction,
  ServiceProviderHandler,
  toMention,
} from '@principle-theorem/principle-core';
import {
  IHasSourceIdentifier,
  ITranslationMap,
  InteractionType,
  MentionResourceType,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientInteractionDestinationEntity,
  IBasePatientInteractionJobData,
  IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type ID4WPatient,
} from '../../source/entities/patient';
import { PatientCommunicationSourceEntity } from '../../source/entities/patient-communication';
import { PatientCommunicationReplySourceEntity } from '../../source/entities/patient-communication-reply';
import {
  PatientTreatmentNoteSourceEntity,
  type ID4WPatientTreatmentNote,
  type ID4WPatientTreatmentNoteTranslations,
} from '../../source/entities/patient-treatment-note';
import { D4WItemCodeToNoteMappingHandler } from '../mappings/item-code-to-note';
import { PatientDestinationEntity } from './patients';

export interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<ID4WPatient> {
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class PatientInteractionDestinationEntity extends BasePatientInteractionDestinationEntity<
  ID4WPatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override filters = [
    new PracticeIdFilter<IPatientInteractionJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientInteractionJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatmentNotes: new PatientTreatmentNoteSourceEntity(),
    patientCommunications: new PatientCommunicationSourceEntity(),
    patientCommunicationReplies: new PatientCommunicationReplySourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    itemCodes: new D4WItemCodeToNoteMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientInteractionJobData[]> {
    const sourceItemCodes$ = this.customMappings.itemCodes.getRecords$(
      translationMapHandler
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(sourceItemCodes$),
        map(([patientRecords, sourceItemCodes]) =>
          patientRecords.map((sourcePatient) => ({
            sourcePatient,
            sourceItemCodes,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const patient = await getDoc(patientRef);

    const d4wInteractions =
      await this.sourceEntities.patientCommunications.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const interactions = d4wInteractions.map((interaction) => {
      const sourceIdentifier = interaction.record.uid;

      return {
        sourceIdentifier,
        ...Interaction.init({
          type: InteractionType.Sms,
          title: [
            toTextContent(`SMS sent to `),
            toMentionContent(toMention(patient, MentionResourceType.Patient)),
          ],
          createdAt: interaction.data.translations.createdAt,
          content: initVersionedSchema(interaction.data.data.content || []),
        }),
      };
    });

    const d4wInteractionReplies =
      await this.sourceEntities.patientCommunicationReplies.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const interactionReplies = d4wInteractionReplies.map((interaction) => {
      const sourceIdentifier = interaction.record.uid;

      return {
        sourceIdentifier,
        ...Interaction.init({
          type: InteractionType.SmsReceived,
          title: [
            toMentionContent(toMention(patient, MentionResourceType.Patient)),
            toTextContent(` repied via SMS`),
          ],
          createdAt: interaction.data.translations.createdAt,
          content: initVersionedSchema(interaction.data.data.content || []),
        }),
      };
    });

    const notes = await this.sourceEntities.treatmentNotes.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const treatmentInteractions = buildPatientNotesFromTreatments(notes, data);

    return {
      patientRef,
      interactions: [
        ...interactions,
        ...interactionReplies,
        ...treatmentInteractions,
      ],
    };
  }
}

function buildPatientNotesFromTreatments(
  notes: IGetRecordResponse<
    ID4WPatientTreatmentNote,
    ID4WPatientTreatmentNoteTranslations
  >[],
  data: IPatientInteractionJobData
): (IInteractionV2 & IHasSourceIdentifier)[] {
  return compact(
    notes.map((note) => {
      const procedureCode = note.data.data.item_code;
      const code = ServiceProviderHandler.findServiceCode(procedureCode);

      if (code) {
        return;
      }

      const mappedCode = data.sourceItemCodes.find(
        (sourceItemCode) =>
          sourceItemCode.sourceIdentifier ===
            note.data.data.item_id.toString() &&
          !!sourceItemCode.destinationValue
      );

      if (!mappedCode) {
        return;
      }

      const interactionPartial = {
        content: initVersionedSchema([
          toTextContent(
            `From ${note.data.data.item_code} ${
              note.data.data.tooth_ref ?? ''
            }${note.data.data.tooth_surface ?? ''}`
          ),
          toTextContent(note.data.data.content || ' '),
        ]),
        createdAt: note.data.translations.createdAt,
      };

      const sourceIdentifier = note.record.uid;

      switch (mappedCode.destinationValue) {
        case ItemCodeNoteType.GeneralPatientNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.Note,
              ...interactionPartial,
            }),
          };
        case ItemCodeNoteType.PatientCallNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.Call,
              ...interactionPartial,
            }),
          };
        case ItemCodeNoteType.PatientEmailNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.Email,
              ...interactionPartial,
            }),
          };
        case ItemCodeNoteType.PatientLabJobNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.LabJob,
              ...interactionPartial,
            }),
          };
        case ItemCodeNoteType.PatientPaymentNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.Payment,
              ...interactionPartial,
            }),
          };
        case ItemCodeNoteType.PatientSMSNote:
          return {
            sourceIdentifier,
            ...Interaction.init({
              type: InteractionType.Sms,
              ...interactionPartial,
            }),
          };
        default:
          return;
      }
    })
  );
}
