import { getEnumValues, isObject } from '@principle-theorem/shared';
import { cloneDeep, findLastIndex, get, isArray, isString } from 'lodash';
import { type IMention } from '../mention';
import {
  isNodeSchema,
  isNodeSchemaOfType,
  recursiveFindArray,
} from '../migrations/helpers';
import {
  MixedSchema,
  getMixedContent,
  isMixedSchema,
  isRawInlineNodes,
  isRawSchema,
  isSerialisedVersionedSchema,
  type AttributeSchema,
  type ISerialisedVersionedSchema,
  type MarkSchema,
  type NodeSchema,
  type RawInlineNodes,
  type RawSchema,
  type TextNodeSchema,
  type VersionedSchema,
  ISerialisedRawSchema,
  SerialisedMixedSchema,
  ITextNodeSchema,
  RawSchemaNodes,
} from '../schema';
import {
  BlockNodes,
  InlineNodes,
  ListNodes,
  RootNodes,
} from './available-extensions';
import { isMentionNode } from './mentions';

export function isStringArray(array: unknown[]): array is string[] {
  return isString(array[0]);
}

export function isBlockNodeArray(
  array: unknown[]
): array is NodeSchema<BlockNodes>[] {
  return getEnumValues(BlockNodes).some(
    (node) => isObject(array[0]) && isNodeSchemaOfType(array[0], node)
  );
}

export function isInlineNodeArray(array: unknown[]): array is RawInlineNodes {
  return getEnumValues(InlineNodes).some(
    (node) => isObject(array[0]) && isNodeSchemaOfType(array[0], node)
  );
}

export function isParagraphNode(
  item: unknown
): item is NodeSchema<BlockNodes.Paragraph> {
  return (
    isObject(item) &&
    isNodeSchema(item) &&
    String(item.type) === String(BlockNodes.Paragraph)
  );
}

export class RawSchemaHelpers {
  static docContent(
    content?: string | string[] | NodeSchema<BlockNodes>[] | RawInlineNodes
  ): RawSchema {
    const doc: RawSchema = {
      type: RootNodes.Doc,
      content: [],
    };

    if (isString(content) && content !== '') {
      doc.content = [toParagraphContent(content)];
      return doc;
    }

    if (!content || !content.length) {
      return doc;
    }

    if (isStringArray(content)) {
      doc.content?.push(toParagraphContent(content));
      return doc;
    }

    if (isInlineNodeArray(content)) {
      doc.content?.push(toParagraphContent(content));
      return doc;
    }

    doc.content?.push(...content);
    return doc;
  }

  static paragraphContent(
    content: string | (string | NodeSchema<InlineNodes> | TextNodeSchema)[]
  ): NodeSchema<BlockNodes.Paragraph, InlineNodes> {
    return {
      type: BlockNodes.Paragraph,
      attrs: {
        align: '',
      },
      content: isString(content)
        ? [toTextContent(content)]
        : content.map((data) => (isString(data) ? toTextContent(data) : data)),
    };
  }

  static mentionContent(
    mention: IMention
  ): NodeSchema<InlineNodes.Mention, InlineNodes> {
    return {
      type: InlineNodes.Mention,
      attrs: {
        key: mention.key,
        path: mention.resource.ref.path,
        type: mention.resource.type,
      },
    };
  }

  static linkContent(
    content: string,
    href: string
  ): NodeSchema<InlineNodes.Link, InlineNodes.Text> {
    return {
      type: InlineNodes.Link,
      attrs: {
        href,
      },
      content: [toTextContent(content)],
    };
  }

  static tableContent(
    columns: number,
    rows: number,
    columnWidth: number
  ): NodeSchema<BlockNodes.TableWrapper, BlockNodes.Table> {
    return {
      type: BlockNodes.TableWrapper,
      content: [
        {
          type: BlockNodes.Table,
          content: Array.from(Array(rows).keys()).map(() => {
            return toTableRowContent(columns, columnWidth);
          }),
        },
      ],
    };
  }

  static blockquoteContent(
    text: string
  ): NodeSchema<BlockNodes.Blockquote, BlockNodes> {
    return {
      type: BlockNodes.Blockquote,
      content: [toTextContent(text)],
    };
  }

  static headingContent(
    text: string,
    headingSize: number
  ): NodeSchema<BlockNodes.Heading> {
    return {
      type: BlockNodes.Heading,
      content: [toTextContent(text)],
      attrs: {
        level: headingSize,
      },
    };
  }

  static codeBlockContent(
    text: string
  ): NodeSchema<BlockNodes.CodeBlock, BlockNodes> {
    return {
      type: BlockNodes.CodeBlock,
      content: [toTextContent(text)],
    };
  }

  static imageContent(src: string): NodeSchema<BlockNodes.Image, BlockNodes> {
    return {
      type: BlockNodes.Image,
      attrs: { src },
    };
  }

  static orderedListContent(
    content: (string | NodeSchema<BlockNodes>)[]
  ): NodeSchema<BlockNodes.OrderedList> {
    return {
      type: BlockNodes.OrderedList,
      content: content.map((data) => RawSchemaHelpers.listItemContent(data)),
    };
  }

  static listContent(
    content: (string | NodeSchema<BlockNodes>)[]
  ): NodeSchema<BlockNodes.BulletedList> {
    return {
      type: BlockNodes.BulletedList,
      content: content.map((data) => RawSchemaHelpers.listItemContent(data)),
    };
  }

  static listItemContent(
    content: string | NodeSchema<BlockNodes>
  ): NodeSchema<ListNodes.ListItem, BlockNodes> {
    return {
      type: ListNodes.ListItem,
      content: [isString(content) ? toParagraphContent(content) : content],
    };
  }

  static addAttributes<T extends NodeSchema>(
    node: T,
    attrs: AttributeSchema
  ): T {
    return {
      ...node,
      attrs: {
        ...(node.attrs || {}),
        ...attrs,
      },
    };
  }

  static textContent(
    text: string,
    attrs: AttributeSchema = {},
    marks: MarkSchema[] = []
  ): TextNodeSchema {
    if (!text) {
      throw new Error('No empty text nodes allowed');
    }

    const content: TextNodeSchema = {
      type: InlineNodes.Text,
      text,
      attrs,
      marks,
    };

    return content;
  }

  static isTextNode(
    node: ITextNodeSchema | NodeSchema<RawSchemaNodes, RawSchemaNodes>
  ): node is TextNodeSchema {
    return node.type === InlineNodes.Text;
  }

  static isLinkNode(node: unknown): node is NodeSchema<InlineNodes.Link> {
    return (
      isObject(node) &&
      isNodeSchema(node) &&
      String(InlineNodes.Link) === node.type
    );
  }

  static isHardBreak(
    node: ITextNodeSchema | NodeSchema<RawSchemaNodes, RawSchemaNodes>
  ): node is TextNodeSchema {
    return [InlineNodes.HardBreak, InlineNodes.HardBreakDeprecated]
      .map(String)
      .includes(node.type);
  }

  static isListItemNode(
    item: unknown
  ): item is NodeSchema<BlockNodes.Paragraph> {
    return (
      isObject(item) &&
      isNodeSchema(item) &&
      String(ListNodes.ListItem) === item.type
    );
  }

  static isListNode(item: unknown): item is NodeSchema<BlockNodes.Paragraph> {
    return (
      isObject(item) &&
      isNodeSchema(item) &&
      [
        String(BlockNodes.OrderedList),
        String(BlockNodes.BulletedList),
      ].includes(item.type)
    );
  }
}

export function mergeSchemas(schemas: (RawSchema | MixedSchema)[]): RawSchema {
  return schemas.reduce((doc: RawSchema, schema) => {
    if (isMixedSchema(schema)) {
      schema = getMixedContent(schema);
    }
    if (isNodeSchemaOfType(schema, RootNodes.Doc)) {
      if (!doc.content) {
        doc.content = [];
      }
      doc.content?.push(...(schema.content || []));
      return doc;
    }
    return doc;
  }, initRawSchema([]));
}

export function initRawSchema(
  content?: string | string[] | NodeSchema<BlockNodes>[] | RawInlineNodes
): RawSchema {
  return RawSchemaHelpers.docContent(content);
}

export function toMentionContent(
  mention: IMention
): NodeSchema<InlineNodes.Mention> {
  return RawSchemaHelpers.mentionContent(mention);
}

export function toParagraphContent(
  content: string | (string | NodeSchema<InlineNodes> | TextNodeSchema)[]
): NodeSchema<BlockNodes.Paragraph> {
  return RawSchemaHelpers.paragraphContent(content);
}

export function toListContent(
  content: (string | NodeSchema<BlockNodes>)[]
): NodeSchema<BlockNodes.BulletedList> {
  return RawSchemaHelpers.listContent(content);
}

export function toOrderedListContent(
  content: (string | NodeSchema<BlockNodes>)[]
): NodeSchema<BlockNodes.OrderedList> {
  return RawSchemaHelpers.orderedListContent(content);
}

export function toBlockquoteContent(
  text: string
): NodeSchema<BlockNodes.Blockquote> {
  return RawSchemaHelpers.blockquoteContent(text);
}

export function toCodeBlockContent(
  text: string
): NodeSchema<BlockNodes.CodeBlock> {
  return RawSchemaHelpers.codeBlockContent(text);
}

export function toHeadingContent(
  text: string,
  headingSize: number = 1
): NodeSchema<BlockNodes.Heading> {
  return RawSchemaHelpers.headingContent(text, headingSize);
}

export function toTextContent(
  text: string,
  attrs: AttributeSchema = {},
  marks: MarkSchema[] = []
): TextNodeSchema {
  return RawSchemaHelpers.textContent(text, attrs, marks);
}

export function toLinkContent(
  content: string,
  href: string
): NodeSchema<InlineNodes.Link, InlineNodes.Text> {
  return RawSchemaHelpers.linkContent(content, href);
}

export function toTableContent(
  columns: number,
  rows: number,
  columnWidth: number
): NodeSchema<BlockNodes.TableWrapper, BlockNodes.Table> {
  return RawSchemaHelpers.tableContent(columns, rows, columnWidth);
}

export function toTableRowContent(
  columns: number,
  columnWidth: number
): NodeSchema<BlockNodes.TableRow, BlockNodes.TableCell> {
  return {
    type: BlockNodes.TableRow,
    content: Array.from(Array(columns).keys()).map(() =>
      toTableCellContent(columnWidth)
    ),
  };
}

export function toTableCellContent(
  columnWidth: number
): NodeSchema<BlockNodes.TableCell, BlockNodes.Paragraph> {
  return {
    attrs: {
      colspan: 1,
      colwidth: [columnWidth],
      rowspan: 1,
    },
    type: BlockNodes.TableCell,
    content: [toParagraphContent([])],
  };
}

export function toImageContent(src: string): NodeSchema<BlockNodes.Image> {
  return RawSchemaHelpers.imageContent(src);
}

export function emptyTextContent(): RawSchema {
  return initRawSchema([toParagraphContent([])]);
}

export function getSchemaSize(
  schema: NodeSchema | MixedSchema | NodeSchema[]
): number {
  if (isArray(schema)) {
    return schema.reduce((accum, current) => accum + getSchemaSize(current), 0);
  }

  if (isString(schema)) {
    return schema.length;
  }

  return getSchemaText(schema).length || getMediaNodesFromSchema(schema).length;
}

// TODO: Fix reliance on Mention logic as part of https://app.clickup.com/t/24qbga
export function getSchemaText(
  schema: string | NodeSchema | MixedSchema | NodeSchema[]
): string {
  if (isRawInlineNodes(schema)) {
    return getTextNodesFromSchema(schema, true);
  }

  if (isArray(schema)) {
    return schema.reduce(
      (accum, current) => accum + getSchemaText(current),
      ''
    );
  }

  if (isString(schema)) {
    return schema.trim();
  }

  if (isMixedSchema(schema)) {
    schema = getMixedContent(schema);
  }

  return recursiveFindArray(schema, (schemaItem) => {
    if (RawSchemaHelpers.isListItemNode(schemaItem)) {
      return undefined;
    }
    return (
      isParagraphNode(schemaItem) || RawSchemaHelpers.isListNode(schemaItem)
    );
  })
    .map((paragraph) => getTextNodesFromSchema(paragraph))
    .join('\n')
    .trim();
}

function getTextNodesFromSchema(
  schema: object,
  restrictToInlineNodes: boolean = false
): string {
  return recursiveFindArray(schema, (schemaItem) => {
    if (restrictToInlineNodes) {
      return isString(get(schemaItem, 'text')) || isMentionNode(schemaItem);
    }

    if (RawSchemaHelpers.isListItemNode(schemaItem)) {
      return undefined;
    }
    return (
      isParagraphNode(schemaItem) ||
      isMentionNode(schemaItem) ||
      RawSchemaHelpers.isListNode(schemaItem)
    );
  })
    .map((schemaItem) => {
      if (restrictToInlineNodes) {
        if (isMentionNode(schemaItem)) {
          return schemaItem.attrs?.key || '';
        }
        const text: unknown = get(schemaItem, 'text');
        return isString(text) ? text : '';
      }

      if (RawSchemaHelpers.isListNode(schemaItem)) {
        return (schemaItem.content ?? [])
          .map((content) => content.content ?? [])
          .map(
            (content) =>
              `- ${getTextNodesFromSchema(content, restrictToInlineNodes)}`
          )
          .flat()
          .join('\n')
          .trim();
      }

      if (isParagraphNode(schemaItem)) {
        return (schemaItem.content ?? [])
          .map((content) => {
            if (isMentionNode(content)) {
              return content.attrs?.key || '';
            }
            if (RawSchemaHelpers.isHardBreak(content)) {
              return '\n';
            }
            if (RawSchemaHelpers.isTextNode(content)) {
              const text: unknown = get(content, 'text');
              return isString(text) ? text.trim() : '';
            }
            if (RawSchemaHelpers.isLinkNode(content)) {
              return getTextNodesFromSchema(content.content ?? [], true);
            }
          })
          .join(' ')
          .replace(/\s?\n\s?/, '\n');
      }
    })
    .join(' ')
    .replace('  ', ' ')
    .trim();
}

function getMediaNodesFromSchema(schema: object): object[] {
  return recursiveFindArray(schema, (schemaItem) => {
    return (
      isObject(schemaItem) &&
      isNodeSchema(schemaItem) &&
      (
        [BlockNodes.Image, BlockNodes.Video, BlockNodes.VideoEmbed] as string[]
      ).includes(schemaItem.type)
    );
  }).map((schemaItem) => schemaItem);
}

export function addToLastParagraph(
  schema: MixedSchema,
  newContent: RawInlineNodes,
  prependContent: boolean = false
): RawSchema {
  schema = cloneDeep(schema);
  if (isMixedSchema(schema)) {
    schema = getMixedContent(schema);
  }
  const paragraphIndex = findLastIndex(
    schema.content || [],
    (content) => content.type === BlockNodes.Paragraph
  );

  if (!schema.content) {
    schema.content = [];
  }

  if (paragraphIndex === -1) {
    schema.content.push(toParagraphContent(newContent));
    return schema;
  }

  if (!prependContent) {
    schema.content[paragraphIndex].content = [
      ...(schema.content[paragraphIndex].content || []),
      ...newContent,
    ];
    return schema;
  }

  schema.content[paragraphIndex].content = [
    ...newContent,
    ...(schema.content[paragraphIndex].content || []),
  ];
  return schema;
}

export function toVersionedSchema(content: RawSchema): VersionedSchema {
  return {
    content,
    migrations: {
      migrations: [],
    },
  };
}

export function initVersionedSchema(
  content?:
    | string
    | string[]
    | NodeSchema<BlockNodes>[]
    | RawInlineNodes
    | RawSchema
): VersionedSchema {
  return {
    content: isRawSchema(content) ? content : initRawSchema(content),
    migrations: {
      migrations: [],
    },
  };
}

export function serialiseRawContent(
  content:
    | string
    | string[]
    | NodeSchema<BlockNodes>[]
    | RawInlineNodes
    | RawSchema
): ISerialisedVersionedSchema {
  return serialiseSchemaContent(initVersionedSchema(content));
}

export function serialiseSchemaContent(
  schema: MixedSchema
): ISerialisedVersionedSchema {
  if (isSerialisedVersionedSchema(schema)) {
    return schema;
  }
  return {
    content: JSON.stringify(getMixedContent(schema)),
    migrations: {
      migrations: [],
    },
  };
}

export function serialiseRawSchemaContent(
  schema: RawSchema
): ISerialisedRawSchema {
  return {
    rawSchemaValue: JSON.stringify(getMixedContent(schema)),
  };
}

export function unserialiseRawSchemaContent(
  schema: ISerialisedRawSchema
): RawSchema {
  return unserialiseRawSchema(schema.rawSchemaValue);
}

export function unserialiseVersionedSchemaContent(
  schema: ISerialisedVersionedSchema
): VersionedSchema {
  return {
    content: unserialiseRawSchema(schema.content),
    migrations: schema.migrations,
  };
}

export function unserialiseMixedSchemaContent(
  schema: SerialisedMixedSchema
): MixedSchema {
  if (isSerialisedVersionedSchema(schema)) {
    return unserialiseVersionedSchemaContent(schema);
  }

  return unserialiseRawSchemaContent(schema);
}

export function unserialiseRawSchema(serialisedContent: string): RawSchema {
  try {
    const content = JSON.parse(serialisedContent) as RawSchema;
    if (!isRawSchema(content)) {
      throw new Error('Parsed Content was not RawSchema');
    }
    return content;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Editor]: Invalid serialisedContent', 'Error:', error);
    return initRawSchema();
  }
}
