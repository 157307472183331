import { ACC_SERVICE } from './providers/acc';
import { ADA_SERVICE } from './providers/ada';
import { CDBS_SERVICE } from './providers/cdbs';
import { DVA_SERVICE } from './providers/dva';
import { DVA_PROSTHETIC_SERVICE } from './providers/dva-prosthetics';
import { MOH_SERVICE } from './providers/moh';
import { type IServiceCode } from './service-item';

export const SERVICE_PROVIDER_OPTIONS = [
  ADA_SERVICE,
  CDBS_SERVICE,
  DVA_PROSTHETIC_SERVICE,
  DVA_SERVICE,
  ACC_SERVICE,
  MOH_SERVICE,
];

export const ALL_SERVICE_CODES = SERVICE_PROVIDER_OPTIONS.reduce(
  (allCodes, serviceProvider) => {
    return [
      ...allCodes,
      ...Object.values(serviceProvider.items).map((code) => ({
        ...code,
        type: serviceProvider.type,
      })),
    ];
  },
  [] as IServiceCode[]
);
