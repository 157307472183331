import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  type OnDestroy,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AutomationConfigurationService } from '@principle-theorem/ng-automations';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import {
  MOCK_ALL_RESOLVER,
  type ITemplateFormData,
} from '@principle-theorem/ng-templating';
import { SystemTemplates } from '@principle-theorem/principle-core';
import {
  type IAutomatedNotificationConfiguration,
  type ITemplateDefinition,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  filterUndefined,
  findProp,
  isReffable,
  isWithRef,
  snapshot,
  type WithRef,
  type WithRefOrUnsaved,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { TemplateResolver } from '../template-resolver.service';

@Component({
  selector: 'pr-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  contextResolver = MOCK_ALL_RESOLVER;
  template$ = new ReplaySubject<WithRefOrUnsaved<ITemplateDefinition>>(1);
  isSystemTemplate: Signal<boolean>;
  breadcrumbs: Signal<IBreadcrumb[]>;
  automationConfig: Signal<
    WithRef<IAutomatedNotificationConfiguration> | undefined
  >;
  isAutomationTemplate: Signal<boolean>;
  trackByConfig =
    TrackByFunctions.ref<WithRef<IAutomatedNotificationConfiguration>>();

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope,
    private _automationConfig: AutomationConfigurationService
  ) {
    const brand = toSignal(this._currentBrand.doc$);
    const template = toSignal(this.template$);

    this.breadcrumbs = computed(() => {
      return [
        { label: 'Settings', path: '../../../../' },
        { label: brand()?.name ?? '' },
        { label: 'Templates', path: '../' },
        { label: template()?.name ?? '' },
      ];
    });

    this.isSystemTemplate = computed(() => {
      const currentTemplate = template();
      if (!currentTemplate) {
        return false;
      }
      const uid = isReffable(currentTemplate)
        ? currentTemplate.ref.id
        : currentTemplate.unsavedDocPointer.uid;
      return SystemTemplates.isSystemDocUid(uid);
    });

    this._route.data
      .pipe(
        findProp<WithRefOrUnsaved<ITemplateDefinition>>(
          TemplateResolver.resolverKey
        ),
        filterUndefined(),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((currentTemplate) => this.template$.next(currentTemplate));

    const automationConfig$ = this.template$.pipe(
      switchMap((currentTemplate) =>
        isWithRef(currentTemplate) && currentTemplate.automatedConfigurationRef
          ? Firestore.getDoc(currentTemplate.automatedConfigurationRef)
          : of(undefined)
      )
    );

    this.automationConfig = toSignal(automationConfig$);
    this.isAutomationTemplate = computed(() => !!this.automationConfig());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async templateChange(data: ITemplateFormData): Promise<void> {
    const template = await snapshot(this.template$);
    this.template$.next({ ...template, ...data });
  }

  async save(): Promise<void> {
    const template = await snapshot(this.template$);
    await Firestore.saveDoc(template);
    this._snackBar.open('Template saved');
  }

  async editAutomation(
    config: WithRef<IAutomatedNotificationConfiguration>
  ): Promise<void> {
    const template = await snapshot(this.template$);
    await this._automationConfig.editNotification(config, template.scope);
  }
}
