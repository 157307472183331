import { Money } from '@principle-theorem/accounting';
import { Transaction } from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  TransactionType,
  type IInvoice,
  type IPractice,
  type IPurchaseTransactionExtendedData,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  isTransactionCompleteCallbackData,
  type IBaseTransactionCompleteCallbackData,
  type IPurchaseRequestParams,
  type IPurchaseTransactionCompleteCallbackData,
} from '@principle-theorem/tyro';
import { toTyroTransaction } from '../base-transaction';

export async function toPurchaseTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IPurchaseRequestParams,
  response: IBaseTransactionCompleteCallbackData
): Promise<ITransaction<IPurchaseTransactionExtendedData>> {
  const amount = isTransactionCompleteCallbackData(response)
    ? parseFloat(response.baseAmount)
    : Money.fromCents(parseFloat(request.amount));
  const base = await toTyroTransaction(
    TransactionProvider.TyroEftpos,
    invoice,
    practiceRef,
    response
  );
  return Transaction.init({
    ...base,
    amount,
    extendedData: {
      request,
      response,
    },
  });
}

export async function toPurchaseRefundTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IPurchaseRequestParams,
  response: IPurchaseTransactionCompleteCallbackData,
  initialTransaction: WithRef<ITransaction<IPurchaseTransactionExtendedData>>
): Promise<ITransaction<IPurchaseTransactionExtendedData>> {
  const base = await toTyroTransaction(
    TransactionProvider.TyroEftpos,
    invoice,
    practiceRef,
    response,
    TransactionType.Outgoing
  );
  return Transaction.init({
    ...base,
    amount: parseFloat(response.transactionAmount),
    reference: initialTransaction.reference,
    extendedData: {
      request,
      response,
    },
  });
}
