import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { type INamedDocument } from '@principle-theorem/shared';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-fee-schedule',
  templateUrl: './patient-fee-schedule.component.html',
  styleUrls: ['./patient-fee-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientFeeScheduleComponent {
  feeSchedule$ = new ReplaySubject<INamedDocument<IFeeSchedule> | undefined>(1);

  @Input()
  set feeSchedule(feeSchedule: INamedDocument<IFeeSchedule> | undefined) {
    if (feeSchedule) {
      this.feeSchedule$.next(feeSchedule);
    }
  }
}
