import { omit } from 'lodash';

export interface ITheme {
  name: string;
  class: string[];
  color: string;
  default?: boolean;
}

export const MATERIAL_THEME: ITheme = {
  name: 'Material',
  class: ['theme-material'],
  color: '#3f51b5',
};

export const PRINCIPLE_THEME: ITheme = {
  name: 'Principle',
  class: ['theme-principle'],
  color: '#f3c731',
};

export const LEVEL_UP_THEME: ITheme = {
  name: 'Level Up',
  class: ['theme-level-up'],
  color: '#7b42f5',
};

export const DEFAULT_THEMES: ITheme[] = [
  MATERIAL_THEME,
  LEVEL_UP_THEME,
  PRINCIPLE_THEME,
];

export function selectTheme(selectedTheme: ITheme): ITheme[] {
  return DEFAULT_THEMES.map((theme) =>
    selectedTheme.name === theme.name
      ? {
          ...theme,
          default: true,
        }
      : omit(theme, ['default'])
  );
}
