import { type Time24hrType } from '@principle-theorem/shared';
import { type unitOfTime } from 'moment-timezone';

export type ScheduleTime = IScheduleRange | IScheduleByDuration;

export interface IScheduleRange {
  from: Time24hrType;
  to: Time24hrType;
}

export interface IScheduleByDuration {
  duration: number;
  durationUnit: unitOfTime.Base;
  time: string;
}
