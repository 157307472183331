import { type Observable } from 'rxjs';
import {
  type IFeature,
  type Permission,
} from '@principle-theorem/feature-flags';
import { type IResourceDisplay } from '../../../models/resource-display';
import { type SidebarBadges } from './sidebar-badges';

export interface ISidebarItem {
  path: string;
  label: string;
  tooltip?: string;
  feature?: IFeature;
  permissions?: Permission[];
  display?: IResourceDisplay;
  children?: ISidebarItem[];
  badge$?: Observable<number>;
  resolveBadge?: (badges: SidebarBadges) => Observable<number>;
}

export const BRAND_SLUG = `:brand-slug`;
export const PRACTICE_SLUG = `:practice-slug`;

export interface IRouteParams {
  practiceSlug: string;
  brandSlug: string;
}

export class SidebarItem implements ISidebarItem {
  private _path: string;
  label: string;
  feature?: IFeature;
  permissions?: Permission[];
  display?: IResourceDisplay;
  children?: SidebarItem[];
  badge$?: Observable<number>;

  constructor(
    item: ISidebarItem,
    private _params: IRouteParams,
    badges: SidebarBadges
  ) {
    Object.assign(this, item);
    this.badge$ = item.resolveBadge ? item.resolveBadge(badges) : undefined;
  }

  set path(path: string) {
    this._path = path;
  }

  get path(): string {
    let path: string = Array.isArray(this._path)
      ? this._path.join('/')
      : this._path;
    path = path.replace(BRAND_SLUG, this._params.brandSlug);
    path = path.replace(PRACTICE_SLUG, this._params.practiceSlug);
    return path;
  }
}
