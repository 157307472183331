import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IItemCodeToTreatmentXSLX,
  ITEM_CODE_TO_TREATMENT_HEADERS,
} from './item-code-to-treatment-to-xlsx';

export class XLSXToItemCodeToTreatment
  implements IXSLXImport<IItemCodeToTreatmentXSLX>
{
  headers = ITEM_CODE_TO_TREATMENT_HEADERS;

  translate(row: Row): IItemCodeToTreatmentXSLX {
    return {
      code: row.getCell('code').value?.toString() ?? '',
      description: row.getCell('description').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IItemCodeToTreatmentXSLX['mapTo'],
    };
  }
}
