import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import { type IItemCodeXLSX, ITEM_CODE_HEADERS } from './item-codes-to-xlsx';

export class XLSXToItemCodes implements IXSLXImport<IItemCodeXLSX> {
  headers = ITEM_CODE_HEADERS;

  translate(row: Row): IItemCodeXLSX {
    return {
      code: row.getCell('code').value?.toString() ?? '',
      description: row.getCell('description').value?.toString() ?? '',
      associatedValue: row.getCell('associatedValue').value?.toString(),
    };
  }
}
