<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h1 class="mat-headline-4">Treatment Categories</h1>
    <pt-buttons-container>
      <button mat-flat-button color="primary" (click)="create()">
        Create Treatment Category
      </button>
    </pt-buttons-container>
  </div>

  <ng-container *ngIf="globalStore.treatmentCategories$ | async as categories">
    <mat-list *ngIf="categories.length; else noCategories">
      <mat-list-item *ngFor="let category of categories; trackBy: trackByRef">
        <div
          matListItemAvatar
          class="colour-indicator"
          [style.backgroundColor]="category.colour.value"
        ></div>
        <span matListItemTitle>{{ category.name }}</span>

        <div matListItemMeta>
          <button mat-icon-button (click)="edit(category)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(category)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
    <ng-template #noCategories>
      <pr-empty-state image="list" title="treatment categories" />
    </ng-template>
  </ng-container>
</div>
