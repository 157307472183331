<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs()" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="mx-auto flex max-w-5xl flex-col gap-4 p-4">
  <pr-content-container>
    <h2 class="text-xl font-bold">Restrictions</h2>

    <div class="flex flex-col gap-4">
      <p class="text-md !mb-0 font-bold">Date Range Restrictions</p>

      <pr-information-box>
        Restrict the date range that patients can book appointments. Leave blank
        for no restriction.
      </pr-information-box>

      <div class="flex flex-row gap-4 md:flex-row md:gap-6">
        <pr-online-booking-time-restriction-form
          label="Minimum Booking Time"
          hint="Patients can not book appointments until after this time."
          [value]="minBookingWindow()"
          (bookingWindowChange)="onMinBookingWindowChange($event)"
        />

        <pr-online-booking-time-restriction-form
          label="Maximum Booking Time"
          hint="Patients can book appointments up until to this time."
          [value]="maxBookingWindow()"
          (bookingWindowChange)="onMaxBookingWindowChange($event)"
        />
      </div>
    </div>
  </pr-content-container>

  <pr-content-container>
    <h2 class="text-xl font-bold">Staff</h2>

    <div class="flex flex-col gap-4">
      <p class="text-md !mb-0 font-bold">Order By</p>

      <pr-information-box>
        The order in which staff are displayed on the online booking page.
      </pr-information-box>

      <mat-form-field class="w-full">
        <mat-label>Order Staff</mat-label>
        <mat-select [formControl]="orderBy">
          @for (order of staffOrderOptionValues; track order) {
            <mat-option [value]="order">
              {{ order | splitCamel | titlecase }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (orderBy.value === staffOrderOptions.Custom) {
        <mat-list cdkDropList (cdkDropListDropped)="dropStaffer($event)">
          @for (staffer of staff(); track staffer.ref.path) {
            <div class="flex" cdkDrag>
              <div
                class="flex cursor-move items-center justify-center"
                cdkDragHandle
              >
                <mat-icon>drag_handle</mat-icon>
              </div>
              <mat-list-item>
                {{ staffer.user.name | titlecase }}
              </mat-list-item>
            </div>
          }
        </mat-list>
      }
    </div>
  </pr-content-container>
</div>
