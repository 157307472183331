import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AcquisitionsModule } from './acquisitions/acquisitions.module';
import { ReportingCoreModule } from './core/reporting-core.module';
import { CustomReportResolverService } from './custom-reports/custom-report-resolver.service';
import { CustomReportFiltersComponent } from './custom-reports/custom-report/custom-report-filters/custom-report-filters.component';
import { QueryFilterDisplayComponent } from './custom-reports/custom-report/custom-report-filters/query-filter-display/query-filter-display.component';
import { CustomReportFormComponent } from './custom-reports/custom-report/custom-report-form/custom-report-form.component';
import { CustomReportSaveBarComponent } from './custom-reports/custom-report/custom-report-save-bar/custom-report-save-bar.component';
import { CustomReportComponent } from './custom-reports/custom-report/custom-report.component';
import { EditCustomReportDialogComponent } from './custom-reports/custom-report/edit-custom-report-dialog/edit-custom-report-dialog.component';
import { PreSelectedFiltersComponent } from './custom-reports/custom-report/pre-selected-filters/pre-selected-filters.component';
import { RunCustomReportFormComponent } from './custom-reports/custom-report/run-custom-report-form/run-custom-report-form.component';
import { SaveCustomReportDialogComponent } from './custom-reports/custom-report/save-custom-report-dialog/save-custom-report-dialog.component';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { HasAccessToCustomReportGuard } from './custom-reports/has-access-to-custom-report.guard';
import { DailyDashboardComponent } from './daily-dashboard/daily-dashboard.component';
import { LegacyReportsModule } from './legacy-reports/legacy-reports.module';
import { PatientFlowModule } from './patient-flow/patient-flow.module';
import { PracticeIncomeComponent } from './practice-reporting/practice-income/practice-income.component';
import { PracticeOperationsComponent } from './practice-reporting/practice-operations/practice-operations.component';
import { PracticePatientsComponent } from './practice-reporting/practice-patients/practice-patients.component';
import { PracticeProductivityComponent } from './practice-reporting/practice-productivity/practice-productivity.component';
import { PracticeReportingComponent } from './practice-reporting/practice-reporting.component';
import { PractitionerReportingModule } from './practitioner-reporting/practitioner-reporting.module';
import { QueryScopeFormComponent } from './report-builder/query-scope-form/query-scope-form.component';
import { ReportBuilderAvailableChartsComponent } from './report-builder/report-builder-chart-filters/report-builder-available-charts/report-builder-available-charts.component';
import { ReportBuilderChartFiltersComponent } from './report-builder/report-builder-chart-filters/report-builder-chart-filters.component';
import { ReportBuilderChartSectionComponent } from './report-builder/report-builder-chart-filters/report-builder-chart-section/report-builder-chart-section.component';
import { ReportBuilderChartComponent } from './report-builder/report-builder-chart-filters/report-builder-chart-section/report-builder-chart/report-builder-chart.component';
import { ReportBuilderTableChartComponent } from './report-builder/report-builder-chart-filters/report-builder-chart-section/report-builder-table-chart/report-builder-table-chart.component';
import { ReportBuilderLoaderComponent } from './report-builder/report-builder-loader/report-builder-loader.component';
import { ReportBuilderQueryFormComponent } from './report-builder/report-builder-query-form/report-builder-query-form.component';
import { ReportBuilderAddChartDialogComponent } from './report-builder/report-builder-results-table/report-builder-add-chart-dialog/report-builder-add-chart-dialog.component';
import { ReportBuilderColumnSelectorDialogComponent } from './report-builder/report-builder-results-table/report-builder-column-selector-dialog/report-builder-column-selector-dialog.component';
import { ReportBuilderColumnsDialogComponent } from './report-builder/report-builder-results-table/report-builder-columns-dialog/report-builder-columns-dialog.component';
import { ReportBuilderEditGroupByDialogComponent } from './report-builder/report-builder-results-table/report-builder-edit-group-by-dialog/report-builder-edit-group-by-dialog.component';
import { ReportBuilderResolvedColumnLabelComponent } from './report-builder/report-builder-results-table/report-builder-resolved-column-label/report-builder-resolved-column-label.component';
import { ReportBuilderResultsTableComponent } from './report-builder/report-builder-results-table/report-builder-results-table.component';
import { ReportBuilderTableComponent } from './report-builder/report-builder-results-table/report-builder-table/report-builder-table.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { ReportBuilderStore } from './report-builder/report-builder.store';
import { ReportingComponentsModule } from './reporting-components/reporting-components.module';
import { ReportBuilderTableNameDialogComponent } from './report-builder/report-builder-results-table/report-builder-table-name-dialog/report-builder-table-name-dialog.component';
// import { ReportBuilderAddSummaryChartDialogComponent } from './report-builder/report-builder-results-table/report-builder-add-summary-chart-dialog/report-builder-add-summary-chart-dialog.component';
// import { ReportBuilderAddFilteredCountChartDialogComponent } from './report-builder/report-builder-results-table/report-builder-add-filtered-count-chart-dialog/report-builder-add-filtered-count-chart-dialog.component';
import { SystemReportsComponent } from './system-reports/system-reports.component';
import { ReportBuilderDataPointSelectorComponent } from './report-builder/report-builder-data-point-selector/report-builder-data-point-selector.component';
import { ReportBuilderMoveToSectionDialogComponent } from './report-builder/report-builder-move-to-section-dialog/report-builder-move-to-section-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    PatientFlowModule,
    AcquisitionsModule,
    PractitionerReportingModule,
    LegacyReportsModule,
    SystemReportsComponent,
  ],
  declarations: [
    PracticeReportingComponent,
    DailyDashboardComponent,
    PracticeIncomeComponent,
    PracticePatientsComponent,
    PracticeOperationsComponent,
    PracticeProductivityComponent,
    ReportBuilderComponent,
    ReportBuilderQueryFormComponent,
    ReportBuilderResultsTableComponent,
    ReportBuilderChartFiltersComponent,
    ReportBuilderTableComponent,
    ReportBuilderColumnsDialogComponent,
    ReportBuilderChartSectionComponent,
    ReportBuilderChartComponent,
    ReportBuilderColumnSelectorDialogComponent,
    CustomReportsComponent,
    CustomReportComponent,
    RunCustomReportFormComponent,
    CustomReportFiltersComponent,
    ReportBuilderTableNameDialogComponent,
    ReportBuilderDataPointSelectorComponent,
    ReportBuilderResolvedColumnLabelComponent,
    QueryFilterDisplayComponent,
    EditCustomReportDialogComponent,
    ReportBuilderLoaderComponent,
    ReportBuilderAddChartDialogComponent,
    ReportBuilderEditGroupByDialogComponent,
    ReportBuilderAvailableChartsComponent,
    QueryScopeFormComponent,
    PreSelectedFiltersComponent,
    CustomReportSaveBarComponent,
    CustomReportFormComponent,
    SaveCustomReportDialogComponent,
    ReportBuilderTableChartComponent,
    ReportBuilderMoveToSectionDialogComponent,
  ],
  exports: [ReportingComponentsModule, PatientFlowModule],
  providers: [
    ReportBuilderStore,
    CustomReportResolverService,
    HasAccessToCustomReportGuard,
  ],
})
export class ReportingModule {}
