import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IPatient,
  type ISubmittedFormHistory,
  PatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { startCase } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type FormComponentType,
  FormDialogComponent,
  type IFormDialogData,
} from '../form-dialog/form-dialog.component';
import { MedicalHistoryListComponent } from '../medical-history-list/medical-history-list.component';
import { PatientDetailsHistoryListComponent } from '../patient-details-history-list/patient-details-history-list.component';
import { PatientSubmittedFormsListStore } from './patient-submitted-forms-list.store';

@Component({
  selector: 'pr-patient-submitted-forms-list',
  templateUrl: './patient-submitted-forms-list.component.html',
  styleUrls: ['./patient-submitted-forms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientSubmittedFormsListComponent {
  readonly dateFormat = DATE_TIME_FORMAT;
  trackByRef = TrackByFunctions.ref<WithRef<ISubmittedFormHistory>>();
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  emptyState$: Observable<boolean>;

  @Input() showEmptyState: boolean = false;
  @Input() loadingState: boolean = true;
  @Input() hideSubmittedForms: PatientForm[] = [];

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    public store: PatientSubmittedFormsListStore,
    private _stateNav: StateBasedNavigationService,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    this.store.loadSubmittedForms(this.patient$);

    this.emptyState$ = combineLatest([
      this.store.loading$,
      this.store.submittedForms$,
    ]).pipe(
      map(
        ([loading, forms]) => this.showEmptyState && !loading && !forms.length
      )
    );
  }

  async openForm(form: WithRef<ISubmittedFormHistory>): Promise<void> {
    const patient = await snapshot(this.patient$);

    if (this._router.url.includes('/forms')) {
      await this._stateNav.brand([
        'patients',
        patient.ref.id,
        'forms',
        form.ref.id,
      ]);
      return;
    }

    const data: IFormDialogData = {
      component: this._getFormComponent(form.ref.id),
      title: this.getFormName(form.ref.id),
      patient,
    };

    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data,
    });

    this._dialog.open(FormDialogComponent, config);
  }

  getFormName(id: string): string {
    return startCase(id);
  }

  displaySubmittedForm(form: WithRef<ISubmittedFormHistory>): boolean {
    const id = form.ref.id as PatientForm;
    return !this.hideSubmittedForms.includes(id);
  }

  private _getFormComponent(id: string): FormComponentType {
    return id === String(PatientForm.MedicalHistoryForm)
      ? MedicalHistoryListComponent
      : PatientDetailsHistoryListComponent;
  }
}
