import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToConditionMappingHandler } from '../../../mappings/base-item-code-to-condition';
import {
  ICorePracticeItemCode,
  ItemCodeSourceEntity,
} from '../../source/entities/item-codes';

export class CorePracticeItemCodeToConditionMappingHandler extends BaseItemCodeToConditionMappingHandler<
  ICorePracticeItemCode,
  ItemCodeSourceEntity
> {
  sourceEntity = new ItemCodeSourceEntity();
  translateFn = (record: ICorePracticeItemCode): IBaseMigrationItemCode => ({
    id: record.id,
    itemCode: record.itemCode,
    description: record.name ?? '',
  });
}
