import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingComponent {
  brand$: Observable<WithRef<IBrand>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(currentBrand: CurrentBrandScope) {
    this.brand$ = currentBrand.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Accounting' },
      ])
    );
  }
}
