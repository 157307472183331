import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import {
  type ICanBeChartedProperty,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-single-value-chart',
    templateUrl: './single-value-chart.component.html',
    styleUrls: ['./single-value-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SingleValueChartComponent {
  config$ = new ReplaySubject<IChartConfig>(1);
  dataBuilder$ = new ReplaySubject<IDataBuilder>(1);

  @Input()
  set dataBuilder(dataBuilder: IDataBuilder) {
    if (dataBuilder) {
      this.dataBuilder$.next(dataBuilder);
    }
  }

  @Input()
  set config(config: IChartConfig) {
    if (config) {
      this.config$.next(config);
    }
  }

  @Input() chart: IChartCard;
  @Input() measure: ICanBeChartedProperty;
}
