import { initVersionedSchema } from '@principle-theorem/editor';
import { IPatientDocument } from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class PatientDocument {
  static init(
    overrides: AtLeast<IPatientDocument, 'createdBy'>
  ): IPatientDocument {
    return {
      name: '',
      content: initVersionedSchema(),
      deleted: false,
      ...overrides,
    };
  }
}
