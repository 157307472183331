<mat-toolbar color="accent">Update Appointment Duration</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="16px">
    <pr-eventable-header-template
      *ngIf="data.appointment.event"
      fxFlex
      [start]="
        data.appointment.event.from
          | momentTimezone$ : data.appointment.event.practice.ref
          | ngrxPush
      "
      [end]="endTime$ | async"
     />
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <mat-slider
      color="primary"
      [max]="max"
      [min]="min"
      [step]="step"
      [discrete]="true"
      [displayWith]="displayWith"
      #ngSlider
      ><input
        matSliderThumb
        [value]="duration$ | async"
        (change)="
          duration$.next(
            {
              source: ngSliderThumb,
              parent: ngSlider,
              value: ngSliderThumb.value
            }.value ?? undefined
          )
        "
        #ngSliderThumb="matSliderThumb"
      />
    </mat-slider>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
