<ng-container *ngIf="auth.authUser$ | async as authUser; else firebaseUi">
  <div class="flex flex-col items-center">
    <h1 class="mat-headline-4">Welcome {{ authUser?.displayName }}!</h1>

    <ng-container *ngIf="userIsVerified$ | async; else verifyEmail">
      <div class="flex flex-col items-center">
        <pt-workspace-selector [workspaceSelectors]="workspaceSelectors" />
        <button mat-stroked-button (click)="auth.logout()">Logout</button>
      </div>
    </ng-container>
    <ng-template #verifyEmail>
      <ngx-auth-firebaseui-email-confirmation
        class="layout-margin"
        signOutText="Sign Out"
        verifyEmailGoBackText="Go Back"
        [email]="authUser?.email"
        (signOut)="auth.logout()"
      />
    </ng-template>
  </div>
</ng-container>

<ng-template #firebaseUi>
  <ng-container *ngIf="resetPassword$ | async; else signIn">
    <div class="mx-auto flex max-w-sm flex-col items-center gap-4 p-10">
      <span class="mb-4 text-[20px]">Reset Password</span>
      <form
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPasswordForm.valid && resetPassword()"
        class="flex w-full flex-col items-stretch gap-4"
      >
        <mat-form-field>
          <mat-label>Email address</mat-label>
          <input matInput formControlName="email" class="lowercase" />
          <mat-icon matSuffix color="primary">email</mat-icon>
          <mat-error *ngIf="emailCtrl.hasError('required')">
            Email address is required
          </mat-error>
          <mat-error *ngIf="emailCtrl.hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="!resetPasswordForm.valid"
        >
          Send Password Reset
        </button>
      </form>
      <button
        mat-button
        color="primary"
        class="mt-4"
        (click)="resetPassword$.next(false)"
      >
        Back
      </button>
    </div>
  </ng-container>

  <ng-template #signIn>
    <img
      *ngIf="logoSrc$ | async as src"
      [ngSrc]="src"
      class="mx-auto py-3"
      width="150"
      height="70"
      priority
    />
    <ngx-auth-firebaseui-login
      #loginComponent
      class="flat"
      appearance="outline"
      titleText="Sign In"
      loginButtonText="Sign In"
      [providers]="[]"
      [registrationEnabled]="true"
      (onSuccess)="onSuccess()"
      (onError)="onError($event)"
      (onCreateAccountRequested)="onCreateAccount()"
      (onResetPasswordRequested)="this.resetPassword$.next(true)"
    />
  </ng-template>

  <div class="mx-auto flex max-w-xs flex-col items-stretch gap-2">
    <strong class="self-center">Sign in with</strong>
    <ngx-auth-firebaseui-providers
      [providers]="providers"
      [layout]="providersLayout"
      (onSuccess)="onSuccess()"
    />
  </div>
</ng-template>
