<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="reportFacade.isLoading$ | async; else showPage"
>
  <mat-spinner mode="indeterminate" />
</div>

<ng-template #showPage>
  <div
    class="error"
    *ngIf="reportFacade.errorMessage$ | async as errorMessage; else inspector"
  >
    {{ errorMessage }}
  </div>

  <ng-template #inspector>
    <pr-practitioner-income-inspector
      *ngIf="reportFacade.inspected$ | async as result; else selector"
      [result]="result"
     />

    <ng-template #selector>
      <pr-practitioner-income-selector />
    </ng-template>

    <div class="layout-margin mat-caption">
      * List of invoices
      <strong>{{ dataPointInRangeDescription$ | async }}</strong>
      within the selected date range.
    </div>
  </ng-template>
</ng-template>
