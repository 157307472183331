import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  ChartType,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';
import { BehaviorSubject, type Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { type IChartHistoryItem } from '../charting-history-selector/chart-history-list';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';

@Component({
  selector: 'pr-charting-history-list',
  templateUrl: './charting-history-list.component.html',
  styleUrls: ['./charting-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartingHistoryListComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  loaded$ = new BehaviorSubject<boolean>(false);
  selectedItem$ = new BehaviorSubject<IChartHistoryItem | undefined>(undefined);
  items$ = new ReplaySubject<IChartHistoryItem[]>(1);
  isPerioChart$: Observable<boolean>;
  @Output() chartSelected = new EventEmitter<IClinicalChart>();
  @Output() addNewChart = new EventEmitter<void>();

  constructor(private _chartState: ChartFacade) {
    this.items$
      .pipe(
        map((items) => first(items)),
        withLatestFrom(this.selectedItem$),
        filter(([_item, selected]) => !selected),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([item, _]) => this.selectChartHistory(item));

    this.isPerioChart$ = this._chartState
      .chartType$(ChartId.InAppointment)
      .pipe(map((chartType) => chartType === ChartType.Periodontal));
  }

  @Input()
  set items(items: IChartHistoryItem[]) {
    if (items) {
      this.items$.next(items);
    }
  }

  selectChartHistory(selected: IChartHistoryItem | undefined): void {
    this.selectedItem$.next(selected);
    this.chartSelected.emit(selected?.value);
    this.loaded$.next(true);
  }

  addFirstChart(): void {
    this.loaded$.next(false);
    this.addNewChart.emit();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
