import { IStatistic, IStatisticMetrics } from './base-statistics';

export interface IBrandStats extends IStatisticMetrics {
  // Aggregate Facts
  ebit: IStatistic;
  revenue: IStatistic;
  overheads: IStatistic;
  dentalDraw: IStatistic;
  revenueKPI: IStatistic;
  fraudScore: IStatistic;
  staffExpenses: IStatistic;
  consumablesExpended: IStatistic;
  capitalInvestmentCharge: IStatistic;

  // Practitioner Facts
  practitionerProductivity: IStatistic;

  // Dimensions
  yearOpened: IStatistic;
}
