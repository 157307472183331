import { rand, randNumber } from '@ngneat/falso';
import {
  Arch,
  ChartableSurface,
  CHARTABLE_SURFACES,
  IChartedRef,
  IChartedTreatment,
  IStaffer,
  IToothRef,
  ITreatmentConfiguration,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast, INamedDocument, WithRef } from '@principle-theorem/shared';
import { PricedServiceCodeGroup } from '../service-codes/ada-code-category';
import { ChartedSurface } from '../core/charted-surface';
import { ChartedTreatment } from './charted-treatment';

export function MockTreatment(
  data: AtLeast<IChartedTreatment, 'config' | 'feeSchedule'>,
  staff: INamedDocument<IStaffer>[],
  config?: WithRef<ITreatmentConfiguration>
): IChartedTreatment {
  const treatment: IChartedTreatment = ChartedTreatment.init({
    scopeRef: {
      scope: ChartableSurface.WholeMouth,
    },
    ...data,
  });
  if (!config) {
    return treatment;
  }
  treatment.serviceCodeGroups = config.serviceCodeGroups.map((group) =>
    PricedServiceCodeGroup.fromServiceCodeGroup(group, config)
  );

  const surface = rand(config.availableSurfaces);

  treatment.chartedSurfaces = [
    ChartedSurface.init({
      chartedRef: MockChartedRef(surface),
      chartedBy: rand(staff),
    }),
  ];
  return treatment;
}

export function MockToothRef(): IToothRef {
  return {
    quadrant: randomQuadrant(),
    quadrantIndex: randNumber({ min: 1, max: 8 }),
  };
}

export function MockChartedRef(
  surface?: ChartableSurface,
  toothRef?: IToothRef
): Partial<IChartedRef> {
  if (!surface) {
    surface = rand(CHARTABLE_SURFACES);
  }
  switch (surface) {
    case ChartableSurface.WholeMouth:
      return { wholeMouth: true };
    case ChartableSurface.Arch:
      return { arch: randomArch() };
    case ChartableSurface.Quadrant:
      return { quadrant: randomQuadrant() };
    case ChartableSurface.MultipleTeeth:
      const count = 3;
      const startingTooth: number = randNumber({ min: 1, max: 6 });
      const quadrant: Quadrant = randomQuadrant();
      const teeth: IToothRef[] = [];

      for (
        let quadrantIndex: number = startingTooth;
        quadrantIndex <= startingTooth + count;
        quadrantIndex++
      ) {
        teeth.push({
          quadrant,
          quadrantIndex,
        });
      }

      return { multipleTeeth: teeth };
    case ChartableSurface.WholeTooth:
      return {
        tooth: toothRef ?? MockToothRef(),
      };
    case ChartableSurface.Distal:
    case ChartableSurface.Facial:
    case ChartableSurface.Lingual:
    case ChartableSurface.Mesial:
    case ChartableSurface.Occlusal:
      return {
        tooth: { surface, ...(toothRef ?? MockToothRef()) },
      };
    default:
      return {};
  }
}

function randomQuadrant(): Quadrant {
  return rand([
    Quadrant.AdultUpperRight,
    Quadrant.AdultUpperLeft,
    Quadrant.AdultLowerLeft,
    Quadrant.AdultLowerRight,
  ]);
}

function randomArch(): Arch {
  return rand([Arch.Upper, Arch.Lower]);
}
