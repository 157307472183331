import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ISystemTemplateInteractionDialogData,
  SystemTemplateInteractionDialogComponent,
} from '../../system-template-interaction-dialog/system-template-interaction-dialog.component';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { EditorPresetsService } from '../../editor/editor-presets.service';
import {
  isDisabled$,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';
import { WithRef } from '@principle-theorem/shared';
import {
  IPractice,
  IPrincipleMention,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { IEmailFormData, SendEmailService } from '../send-email.service';
import { toMention } from '@principle-theorem/principle-core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'pr-system-email-dialog',
    templateUrl: './system-email-dialog.component.html',
    styleUrls: ['./system-email-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SystemEmailDialogComponent
  extends SystemTemplateInteractionDialogComponent
  implements OnDestroy
{
  protected override _onDestroy$ = new Subject<void>();
  emailForm = new TypedFormGroup<IEmailFormData>({
    subject: new TypedFormControl<string>('', Validators.required),
    practice: new TypedFormControl<WithRef<IPractice>>(
      undefined,
      Validators.required
    ),
    content: this.noteCtrl,
  });
  contact: IPrincipleMention;
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISystemTemplateInteractionDialogData,
    currentScope: CurrentScopeFacade,
    private _editorPresets: EditorPresetsService,
    private _sendEmailService: SendEmailService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SystemEmailDialogComponent, IEmailFormData>
  ) {
    super(data, currentScope);

    this.contact = toMention(this.data.patient, MentionResourceType.Patient);
    this._practice$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) => this.emailForm.patchValue({ practice }));
    this.isDisabled$ = isDisabled$(this.emailForm);
    this.extensions = this._editorPresets.defaultToHTMLExtensions();
  }

  override ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async send(): Promise<void> {
    if (!this.emailForm.valid) {
      return;
    }
    this.submitting$.next(true);
    const formData = this.emailForm.getRawValue();
    const error = await this._sendEmailService.send(this.contact, formData);

    if (error) {
      this.submitting$.next(false);
      this._snackBar.open(error.message);
      this.dialogRef.close();
      return;
    }

    this.submitting$.next(false);
    this._snackBar.open('Email Sent Successfully');
    this.dialogRef.close(formData);
  }
}
