import { roundToDecimals } from '@principle-theorem/accounting';
import {
  IBrand,
  ITransaction,
  InvoiceCollection,
  TransactionProvider,
  isAccountCreditExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  IDataTable,
  ISODateType,
  ITimePeriod,
  asyncForEach,
  bufferedQuery$,
  collectionGroupQuery,
  isSameRef,
  multiMergeMap,
  toISODate,
  toTimestamp,
  where,
} from '@principle-theorem/shared';
import { Brand } from '../models/brand';
import { dateRangeToBrandTimezone } from './helpers';

interface IAccountCreditFromOtherPractice {
  transactionPractice: string;
  transactionDate: ISODateType;
  transactionId: string;
  creditPracticeName: string;
  creditId: string;
  creditAmount: number;
  invoiceUrl: string;
  creditInvoiceUrl: string;
}

const headers = {
  transactionPractice: 'Transaction Practice',
  transactionDate: 'Transaction Date',
  transactionId: 'Transaction ID',
  creditPracticeName: 'Credit Practice Name',
  creditId: 'Credit ID',
  creditAmount: 'Credit Amount',
  invoiceUrl: 'Invoice URL',
  creditInvoiceUrl: 'Credit Invoice URL',
};

export class AccountCreditsUsedFromOtherPractice {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>
  ): Promise<IDataTable<IAccountCreditFromOtherPractice>[]> {
    const brand = await Firestore.getDoc(brandRef);
    const practices = await Firestore.getDocs(
      Brand.practiceCol({ ref: brandRef })
    );
    const rows: IAccountCreditFromOtherPractice[] = [];

    const brandDateRange = dateRangeToBrandTimezone(brand, dateRange);

    await asyncForEach(practices, async (practice) => {
      try {
        await bufferedQuery$(
          collectionGroupQuery<ITransaction>(
            InvoiceCollection.Transactions,
            where('createdAt', '>=', toTimestamp(brandDateRange.from)),
            where('createdAt', '<=', toTimestamp(brandDateRange.to)),
            where('practiceRef', '==', practice.ref),
            where('provider', '==', TransactionProvider.AccountCredit)
          ),
          100,
          'createdAt'
        )
          .pipe(
            multiMergeMap(100, async (transaction) => {
              if (!isAccountCreditExtendedData(transaction.extendedData)) {
                return;
              }

              await asyncForEach(
                transaction.extendedData.accountCreditsUsed,
                async (accountCreditsUsed) => {
                  const accountCredit = await Firestore.getDoc(
                    accountCreditsUsed.ref
                  );

                  if (!accountCredit.invoice) {
                    return;
                  }

                  const amount = accountCreditsUsed.amount;
                  const invoice = await Firestore.getDoc(accountCredit.invoice);

                  if (isSameRef(invoice.practice, transaction.practiceRef)) {
                    return;
                  }

                  const creditPatientRef = Firestore.getParentDocRef(
                    invoice.ref
                  );
                  const creditInvoiceUrl = `https://app.principle.dental/${brand.slug}/patients/${creditPatientRef.id}/account/invoices/${invoice.ref.id}`;

                  const parentInvoiceRef = Firestore.getParentDocRef(
                    transaction.ref
                  );
                  const patientRef =
                    Firestore.getParentDocRef(parentInvoiceRef);
                  const invoiceUrl = `https://app.principle.dental/${brand.slug}/patients/${patientRef.id}/account/invoices/${parentInvoiceRef.id}`;

                  rows.push({
                    transactionPractice: practice.name,
                    transactionDate: toISODate(
                      transaction.createdAt,
                      brand.settings.timezone
                    ),
                    transactionId: transaction.ref.id,
                    creditPracticeName: invoice.practice.name,
                    creditId: accountCredit.ref.id,
                    creditAmount: roundToDecimals(amount, 2),
                    invoiceUrl,
                    creditInvoiceUrl,
                  });
                }
              );
            })
          )
          .toPromise();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    return this._toDataTables(rows);
  }

  private _toDataTables(
    data: IAccountCreditFromOtherPractice[]
  ): IDataTable<IAccountCreditFromOtherPractice>[] {
    return [
      {
        name: 'Account Credits Used From Other Practice',
        data,
        columns: Object.entries(headers).map(([key, header]) => ({
          key,
          header,
        })),
      },
    ];
  }
}
