import { Brand, hasMergeConflicts } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IMigratedDataSummary,
  ISourceEntityHandler,
  MergeConflictDestinationEntityRecord,
  SkippedDestinationEntityRecord,
  type IBrand,
  type IContact,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPracticeMigration,
  type IReferralSourceConfiguration,
  type ISourceEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  asDocRef,
  toTimestamp,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { DestinationEntity } from '../destination-entity';

export const CONTACT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'contacts',
    label: 'Contacts',
    description: '',
  },
});

export interface IContactDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord<object>>;
  contactRef: DocumentReference<IContact | IReferralSourceConfiguration>;
}

export interface IContactErrorData {
  sourceRef: DocumentReference<ISourceEntityRecord<object>>;
}

export interface IContactMigrationData {
  sourceContactId: string | number;
  contact: IContact;
}

export interface IContactJobData<ContactRecord> {
  sourceContact: IGetRecordResponse<ContactRecord>;
  brand: WithRef<IBrand>;
}

export abstract class BaseContactDestinationEntity<
  ContactRecord extends object,
  JobData extends IContactJobData<ContactRecord>,
> extends BaseDestinationEntity<
  IContactDestinationRecord,
  JobData,
  IContactMigrationData
> {
  destinationEntity = CONTACT_DESTINATION_ENTITY;
  abstract contactSourceEntity: ISourceEntityHandler<ContactRecord[]>;

  get sourceCountComparison(): ISourceEntityHandler<ContactRecord[]> {
    return this.contactSourceEntity;
  }

  sourceCountDataAccessor(
    data: IContactJobData<ContactRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceContact.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IContactDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return from(Firestore.getDoc(record.data.contactRef)).pipe(
      map((contact) => [
        {
          label: 'Contact',
          data: contact,
        },
      ])
    );
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IContactJobData<ContactRecord>,
    migrationData: IContactMigrationData
  ): Promise<IDestinationEntityRecord> {
    const existingContactRef = await translationMap.getDestination(
      migrationData.sourceContactId.toString(),
      this.contactSourceEntity.sourceEntity.metadata.idPrefix
    );

    const contactRef = await FirestoreMigrate.upsertDoc(
      Brand.contactCol(jobData.brand),
      {
        ...migrationData.contact,
        createdAt: toTimestamp(),
      },
      existingContactRef?.id
    );

    if (!existingContactRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.sourceContactId.toString(),
        destinationIdentifier: contactRef,
        resourceType: this.contactSourceEntity.sourceEntity.metadata.idPrefix,
      });
    }

    return this._buildSuccessResponse(jobData.sourceContact, contactRef);
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IContactMigrationData
  ): Promise<IContactMigrationData | undefined> {
    const existingContactRef = await translationMap.getDestination(
      data.sourceContactId.toString(),
      this.contactSourceEntity.sourceEntity.metadata.idPrefix
    );
    if (!existingContactRef) {
      return;
    }

    try {
      const existingContact = await Firestore.getDoc(
        asDocRef<IContact>(existingContactRef)
      );
      const hasMergeConflict = hasMergeConflicts(data.contact, existingContact);
      if (hasMergeConflict) {
        return {
          ...data,
          contact: existingContact,
        };
      }
    } catch (error) {
      //
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IContactJobData<ContactRecord>,
    _migrationData: IContactMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceContact.record.uid,
      label: jobData.sourceContact.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourceContact.record.ref,
    };
  }

  getDestinationEntityRecordUid(data: IContactJobData<ContactRecord>): string {
    return data.sourceContact.record.uid;
  }

  protected _buildErrorResponse(
    contact: IGetRecordResponse<ContactRecord>,
    message?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: contact.record.uid,
      label: contact.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: contact.record.ref,
      errorMessage: message ?? 'Missing required contact properties',
      failData: {
        contactRef: contact.record.ref,
      },
    };
  }

  protected _buildSkippedResponse(
    contact: IGetRecordResponse<ContactRecord>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: contact.record.uid,
      label: contact.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: contact.record.ref,
    };
  }

  private _buildSuccessResponse(
    record: IGetRecordResponse<ContactRecord>,
    contactRef: DocumentReference<IContact | IReferralSourceConfiguration>
  ): IDestinationEntityRecord<IContactDestinationRecord> {
    return {
      uid: record.record.uid,
      label: record.record.label,
      sourceRef: record.record.ref,
      data: {
        sourceRef: record.record.ref,
        contactRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }
}
