import { Directive, HostBinding, Input, type OnDestroy } from '@angular/core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DISABLED_TOOTH_OPACITY } from '../models/charted-item';

@Directive({
    selector: '[prChartSurfaceAttrFill]',
    standalone: false
})
export class ChartSurfaceAttrFillDirective implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  fillPath$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  view$: Subject<IDentalChartViewSurface> =
    new Subject<IDentalChartViewSurface>();
  @HostBinding('attr.fill') fill?: string;
  @HostBinding('style.opacity') opacity = 1;

  constructor() {
    combineLatest([this.view$, this.fillPath$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([view, fillPath]: [IDentalChartViewSurface, string]) => {
        if (!view.texture) {
          this.fill = undefined;
          this.opacity = 1;
          return;
        }

        if (view.texture) {
          this.fill = view.texture.colour.value;
          this.opacity = view.texture.disablesSurface
            ? DISABLED_TOOTH_OPACITY
            : 1;
          return;
        }

        if (!fillPath) {
          return;
        }

        this.fill = `url(#${fillPath})`;
      });
  }

  @Input('prChartSurfaceAttrFill')
  set view(view: IDentalChartViewSurface) {
    if (!view) {
      return;
    }
    this.view$.next(view);
  }

  @Input()
  set fillPath(fillPath: string) {
    this.fillPath$.next(fillPath);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
