<button mat-stroked-button [matMenuTriggerFor]="sectionMenu">
  {{ section }}
</button>

<mat-menu
  #sectionMenu="matMenu"
  [overlapTrigger]="true"
  classList="chart-selector-menu"
>
  <ng-template matMenuContent>
    <h2>Sections</h2>

    <ng-container
      *ngFor="let sectionGroup of sectionGroups; trackBy: trackBySection"
    >
      <mat-divider />

      <button
        *ngFor="let sectionOption of sectionGroup; trackBy: trackBySection"
        mat-menu-item
        [ngClass]="{ selected: section === sectionOption }"
        (click)="select(sectionOption)"
      >
        {{ sectionOption }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
