import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  Invoice,
  Patient,
  Transaction,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IAccountCreditExtendedData,
  ICreditUsedData,
  IInvoice,
  IPractice,
  ISplitAccountCreditFormData,
  ITransaction,
  ITreatmentCategory,
  PatientRelationshipType,
  type IAccountCredit,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  DocumentReference,
  Firestore,
  INamedDocument,
  TIME_FORMAT,
  asyncForEach,
  debounceUserInput,
  isSameRef,
  shareReplayCold,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, sum, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';
import { TreatmentCategorySelectorBloc } from '../edit-account-credit-dialog/treatment-category-selector-bloc';

export interface ISplitAccountCreditDialogRequest {
  credit: WithRef<IAccountCredit>;
  practice?: WithRef<IPractice>;
}

export interface ISplitAccountCreditDialogResponse {
  splitCredit: ISplitAccountCreditFormData;
  useForTransactions: ICreditUsedData[];
}

@Component({
    selector: 'pr-split-account-credit-dialog',
    templateUrl: './split-account-credit-dialog.component.html',
    styleUrls: ['./split-account-credit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class SplitAccountCreditDialogComponent {
  readonly dateTimeFormat = `${DAY_MONTH_YEAR_FORMAT} - ${TIME_FORMAT}`;
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  isDisabled$: Observable<boolean>;
  form = new TypedFormGroup<ISplitAccountCreditFormData>({
    description: new TypedFormControl<string>(),
    practitioner: new TypedFormControl<INamedDocument<IStaffer>>(),
    treatmentCategory: new TypedFormControl<
      DocumentReference<ITreatmentCategory>
    >(),
    amount: new TypedFormControl<number>(),
    createdAt: new TypedFormControl<Moment>(),
  });
  receiveDepositsPermissions = [PatientPermissions.ReceiveDeposits];
  accountAdminPermission = [PatientPermissions.AccountInvoiceAdmin];
  treatmentCategoryBloc: TreatmentCategorySelectorBloc;
  creditsUsed$: Observable<ICreditUsedData[]>;
  mustUseAtLeast$: Observable<number>;
  usedCreditIsInvalid$: Observable<boolean>;
  selectedCreditUsed$ = new BehaviorSubject<ICreditUsedData[]>([]);
  depositPaidDate$: Observable<Moment | undefined>;
  today = moment().endOf('day');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISplitAccountCreditDialogRequest,
    public _dialogRef: MatDialogRef<
      SplitAccountCreditDialogComponent,
      ISplitAccountCreditDialogResponse
    >,
    private _globalStore: GlobalStoreService
  ) {
    this.form.patchValue({
      description: data.credit.description,
      amount: 0,
      practitioner: data.credit.reservedFor.practitioner,
      treatmentCategory: data.credit.reservedFor.treatmentCategory,
      createdAt: moment(),
    });
    this.treatmentCategoryBloc = new TreatmentCategorySelectorBloc(
      this._globalStore.treatmentCategories$,
      this.form.controls.treatmentCategory
    );

    this.depositPaidDate$ = from(
      AccountCredit.getDepositPaidDate(data.credit)
    ).pipe(
      map((depositPaidAt) =>
        depositPaidAt ? toMoment(depositPaidAt) : undefined
      )
    );
    this.creditsUsed$ = this._resolveInvoicesCreditUsedOn$(data.credit);
    const creditsUsedTotal$ = this.creditsUsed$.pipe(
      map((credits) =>
        sum(credits.map((credit) => credit.usedAccountCredit.amount))
      )
    );
    this.mustUseAtLeast$ = formControlChanges$(this.form.controls.amount).pipe(
      debounceUserInput(),
      map((amount) => amount ?? 0),
      map((amount) => {
        const mustUseAtLeast = amount - AccountCredit.remaining(data.credit);
        return mustUseAtLeast > 0 ? mustUseAtLeast : 0;
      }),
      shareReplayCold()
    );
    const assignedAmount$ = this.selectedCreditUsed$.pipe(
      map((selected) =>
        sum(selected.map((item) => item.usedAccountCredit.amount))
      )
    );
    this.usedCreditIsInvalid$ = combineLatest([
      this.mustUseAtLeast$,
      assignedAmount$,
      creditsUsedTotal$,
    ]).pipe(
      map(
        ([mustUseAtLeast, assignedAmount, creditsUsedTotal]) =>
          creditsUsedTotal > 0 && assignedAmount < mustUseAtLeast
      )
    );
    this.isDisabled$ = combineLatest([
      isDisabled$(this.form),
      this.usedCreditIsInvalid$,
    ]).pipe(
      map(
        ([isDisabled, usedCreditIsInvalid]) => isDisabled || usedCreditIsInvalid
      )
    );
  }

  async getInvoiceLink(invoice: WithRef<IInvoice>): Promise<string[]> {
    const patientRef = Invoice.patientDocRef(invoice);
    const brand = await Firestore.getDoc(Patient.brandRef({ ref: patientRef }));
    return [
      '/',
      brand.slug,
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoice.ref.id,
    ];
  }

  selectTransaction(
    event: MatCheckboxChange,
    creditUsed: ICreditUsedData
  ): void {
    const current = this.selectedCreditUsed$.value;
    const newValue = event.checked
      ? [...current, creditUsed]
      : current.filter((credit) => credit !== creditUsed);
    const cleanValue = uniqBy(newValue, (item) => item.transaction.ref.path);
    this.selectedCreditUsed$.next(cleanValue);
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    const data = this.form.getRawValue();
    const selectedCreditUsed = this.selectedCreditUsed$.value;
    this._dialogRef.close({
      splitCredit: data,
      useForTransactions: selectedCreditUsed,
    });
  }

  private _resolveInvoicesCreditUsedOn$(
    credit: WithRef<IAccountCredit>
  ): Observable<ICreditUsedData[]> {
    return from(Firestore.getDoc(AccountCredit.patientRef(credit))).pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.creditTransactions$
        )
      ),
      switchMap(async (transactions) => {
        const usedCreditData = await asyncForEach(transactions, (transaction) =>
          findCreditUsedData(credit, transaction)
        );
        return compact(usedCreditData);
      })
    );
  }
}

async function findCreditUsedData(
  credit: WithRef<IAccountCredit>,
  transaction: WithRef<ITransaction<IAccountCreditExtendedData>>
): Promise<ICreditUsedData | undefined> {
  const usedAccountCredit = transaction.extendedData?.accountCreditsUsed.find(
    (accountCreditUsed) => isSameRef(accountCreditUsed, credit)
  );
  if (!usedAccountCredit) {
    return;
  }
  const invoice = await Firestore.getDoc(
    Transaction.invoiceDocRef(transaction)
  );
  return {
    credit,
    transaction,
    invoice,
    usedAccountCredit,
  };
}
