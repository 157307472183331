import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PatientDetailsFormService } from '@principle-theorem/ng-custom-forms';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { getCustomSubmittedForm } from '@principle-theorem/principle-core';
import {
  ICustomFormData,
  IPatient,
  IPatientMedicalHistoryFormTokenData,
  IReferralSourceConfiguration,
  PatientHealthCardType,
  PatientOptionalField,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  shareReplayHot,
  snapshot,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { sanitiseCSRF } from '@principle-theorem/temporary-tokens';
import { isNull } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  PatientConfirmState,
  PatientConfirmStateBloc,
} from './patient-confirm-state';
import { PatientMedicalHistoryService } from './patient-medical-history.service';

interface IPatientMedicalHistoryComponentData {
  tokenData?: IPatientMedicalHistoryFormTokenData;
  customForm: ICustomFormData<object>;
}

@Component({
    selector: 'pr-patient-medical-history',
    templateUrl: './patient-medical-history.component.html',
    styleUrls: ['./patient-medical-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientMedicalHistoryComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  state = new PatientConfirmStateBloc();
  patientDetailsFormData$ = new BehaviorSubject<Partial<IPatient> | undefined>(
    undefined
  );
  tokenUid$: Observable<string | undefined>;
  data$: Observable<IPatientMedicalHistoryComponentData>;
  referralSources$: Observable<WithRef<IReferralSourceConfiguration>[]>;
  requiredFields$: Observable<PatientOptionalField[]>;
  healthCardTypes$: Observable<PatientHealthCardType[]>;
  customFormState$ = new ReplaySubject<object>(1);
  customFormIsValid$ = new ReplaySubject<boolean>(1);
  isDisabled$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private _medicalHistoryService: PatientMedicalHistoryService,
    private _patientDetailsFormService: PatientDetailsFormService,
    private _intercom: IntercomService,
    private _snackbar: MatSnackBar
  ) {
    this.tokenUid$ = combineLatest([
      this._route.queryParamMap.pipe(
        map((queryParams) => queryParams.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
      this._route.paramMap.pipe(
        map((params) => params.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
    ]).pipe(
      map(([queryToken, routeToken]) => queryToken ?? routeToken),
      map((token) => token && sanitiseCSRF(token)),
      take(1),
      shareReplayHot(this._onDestroy$)
    );

    const tokenData$ = this.tokenUid$.pipe(
      switchMap((tokenUid) =>
        tokenUid
          ? this._medicalHistoryService.resolvePatientMedicalHistoryData$(
              tokenUid
            )
          : of(undefined)
      ),
      withLatestFrom(this.state.isSubmitting$),
      map(([data, isSubmitting]) => {
        if (isSubmitting) {
          return data;
        }
        this._updateState(data);
        return data;
      })
    );

    const customForm$ = tokenData$.pipe(
      filterUndefined(),
      map((data) => data.customFormData)
    );

    this.data$ = combineLatest([tokenData$, customForm$]).pipe(
      map(([tokenData, customForm]) => ({ tokenData, customForm }))
    );

    this.referralSources$ = this.data$.pipe(
      map((data) => data.tokenData?.referralSources ?? [])
    );

    this.requiredFields$ = this.data$.pipe(
      map((data) => data.tokenData?.requiredFields ?? [])
    );

    this.healthCardTypes$ = this.data$.pipe(
      map((data) => data.tokenData?.healthCardTypes ?? [])
    );

    this.isDisabled$ = combineLatest([
      this.customFormIsValid$,
      this.patientDetailsFormData$,
    ]).pipe(
      map(
        ([customFormIsValid, patientDetailsFormData]) =>
          !customFormIsValid || !patientDetailsFormData
      )
    );
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const patientDetails = await snapshot(this.patientDetailsFormData$);
    const customFormState = await snapshot(this.customFormState$);

    if (!patientDetails) {
      this._snackbar.open('Missing Patient Information');
      return;
    }

    this.state.state$.next(PatientConfirmState.Submitting);
    try {
      const tokenUid = await snapshot(this.tokenUid$);
      if (!tokenUid) {
        throw new Error('Missing Token ID on form submission');
      }
      await snapshot(
        this._patientDetailsFormService.submitFormData(tokenUid, {
          data: patientDetails,
          date: toTimestamp(),
        })
      );
      const customForm = await snapshot(
        this.data$.pipe(map((componentData) => componentData.customForm))
      );
      const formSubmission = getCustomSubmittedForm<object>(
        customForm,
        customFormState
      );
      await this._medicalHistoryService.submitFormData(
        tokenUid,
        formSubmission
      );
      this.state.state$.next(PatientConfirmState.Confirmed);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.state.state$.next(PatientConfirmState.NotAvailable);
    }
  }

  private _updateState(data?: IPatientMedicalHistoryFormTokenData): void {
    if (!data) {
      this.state.state$.next(PatientConfirmState.NotAvailable);
      return;
    }
    if (data.alreadySubmitted) {
      this.state.state$.next(PatientConfirmState.AlreadySubmitted);
      return;
    }
    this.state.state$.next(PatientConfirmState.AwaitingConfirmation);
  }
}
