<mat-toolbar [color]="data.toolbarColor">{{ data.title }}</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col">
    <p *ngFor="let line of lines; trackBy: trackByLine">{{ line }}</p>
    <span *ngIf="data.note" class="mat-caption">{{ data.note }}</span>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button [mat-dialog-close]="false">
      {{ data.cancelLabel }}
    </button>
    <button
      mat-flat-button
      [mat-dialog-close]="true"
      [color]="data.submitColor"
      cdkFocusInitial
    >
      {{ data.submitLabel }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
