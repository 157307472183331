import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DialogPresets,
  InputSearchFilter,
  ProfileImageService,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IUser } from '@principle-theorem/principle-core/interfaces';
import { User } from '@principle-theorem/principle-core';
import { where, type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  all$,
  DATE_TIME_WITH_YEAR_FORMAT,
  find$,
  multiFilter,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type InviteFormData,
  InviteUserDialogComponent,
} from '../invite-user-dialog/invite-user-dialog.component';

@Component({
  selector: 'pr-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent {
  profileImage = inject(ProfileImageService);
  trackByUser = TrackByFunctions.ref<WithRef<IUser>>();
  usersFilter: InputSearchFilter<WithRef<IUser>>;
  activeUsers$: Observable<WithRef<IUser>[]>;
  disabledUsers$: Observable<WithRef<IUser>[]>;
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>();
  userCollection$ = new ReplaySubject<CollectionReference<IUser>>(1);
  emptyState$: Observable<boolean>;
  emptySearch$: Observable<boolean>;
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {
    const users$ = this.userCollection$.pipe(
      switchMap((collection) => all$(collection)),
      map((users) => sortBy(users, 'name'))
    );
    this.usersFilter = new InputSearchFilter<WithRef<IUser>>(
      users$,
      toSearchStream(this.searchCtrl),
      ['name', 'email']
    );
    this.activeUsers$ = this.usersFilter.results$.pipe(
      multiFilter((user) => user.isEnabled)
    );
    this.disabledUsers$ = this.usersFilter.results$.pipe(
      multiFilter((user) => !user.isEnabled)
    );
    this.emptyState$ = users$.pipe(map((users) => !users.length));
    this.emptySearch$ = combineLatest([
      this.usersFilter.results$,
      this.emptyState$,
    ]).pipe(map(([results, emptyState]) => !results.length && !emptyState));
  }

  @Input()
  set userCollection(userCollection: CollectionReference<IUser>) {
    if (userCollection) {
      this.userCollection$.next(userCollection);
    }
  }

  async invite(): Promise<void> {
    const user = await this._dialog
      .open<InviteUserDialogComponent, undefined, InviteFormData>(
        InviteUserDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (user) {
      await this._handleInvite(user);
    }
  }

  private async _handleInvite(user: InviteFormData): Promise<void> {
    const existingUser$: Observable<WithRef<IUser> | undefined> = find$(
      await snapshot(this.userCollection$),
      where('email', '==', user.email)
    );
    if (await snapshot(existingUser$)) {
      this._snackBar.open(`A user already exists with that email address`);
      return;
    }
    const collection = await snapshot(this.userCollection$);
    await addDoc(collection, User.init(user));
    this._snackBar.open(`Invite Sent`);
  }
}
