import {
  AtLeast,
  IXSLXExport,
  WithRef,
  XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { Column } from 'exceljs';
import { IBaseMigrationStaffer } from '../../../interfaces';
import {
  type IPractice,
  type ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import { sortBy } from 'lodash';

export interface IStafferToPracticeXSLX {
  id: string;
  name: string;
  mapTo: string;
}

export const STAFF_TO_PRACTICE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Staff Name',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class StafferToPracticeToXSLX
  implements IXSLXExport<IBaseMigrationStaffer, IStafferToPracticeXSLX>
{
  headers = STAFF_TO_PRACTICE_HEADERS;

  constructor(
    private _practices: WithRef<IPractice>[],
    private _existingMappings: ITranslationMap<IPractice>[]
  ) {}

  translate(
    records: IBaseMigrationStaffer[]
  ): Record<keyof IStafferToPracticeXSLX, XSLXCell>[] {
    const practiceOptions = sortBy(this._practices, 'name').map(
      (practice) => practice.name
    );

    return records.map((record) => {
      const existing = this._existingMappings.find(
        (mapping) => mapping.sourceIdentifier === record.id.toString()
      );
      const associatedValue = existing?.destinationIdentifier;
      const mappedPractice = associatedValue
        ? this._practices.find((practice) =>
            isSameRef(practice, associatedValue)
          )
        : undefined;

      const existingValue = mappedPractice ? mappedPractice.name : '';

      return {
        id: {
          value: record.id,
        },
        name: {
          value: record.name,
        },
        mapTo: {
          value: existingValue,
          dataValidation: {
            type: 'list',
            formulae: [`"${practiceOptions.join(',')}"`],
          },
        },
      };
    });
  }
}
