<ng-container *ngrxLet="treatmentPlan$ as treatmentPlan">
  <div class="flex flex-row place-content-between items-center gap-6">
    <div class="flex flex-col">
      <p class="mat-caption opaque">Treatment Plan</p>
      <p class="mat-subtitle-2">{{ treatmentPlan.name }}</p>
    </div>
    <div>
      <pr-treatment-plan-status
        class="w-20"
        dense
        [status]="treatmentPlan.status"
      />
    </div>
    <button *ngIf="canAddStep" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      [disabled]="canAddStep === false"
      (click)="addStep.emit()"
    >
      <mat-icon>add</mat-icon>
      Create New Step
    </button>
  </mat-menu>
</ng-container>
