import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import {
  IPrincipleReconciliationReportData,
  type ITransactionSourceSummary,
} from '@principle-theorem/reporting';
import {
  serialise,
  unserialise$,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import {
  HealthPointReconciliationReportResponse,
  ReconciliationReportResponse,
} from '@principle-theorem/tyro';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  clearSelectedSummary,
  selectSummary,
  setDateRange,
  setPractice,
} from './actions/query.actions';
import { type IReconciliatonReportState } from './reducers/main';
import { IReconciliatonQueryState } from './reducers/query.reducers';
import {
  getDateRange,
  getHealthPointReport,
  getPractice,
  getPrincipleReport,
  getReconciliationReportQuery,
  getSelectedSummary,
  getTyroReport,
} from './selectors';

@Injectable()
export class ReconciliationReportFacade {
  private _store = inject(Store<IReconciliatonReportState>);
  loading$ = new BehaviorSubject<boolean>(false);
  query$: Observable<IReconciliatonQueryState>;
  range$: Observable<ITimePeriod>;
  practice$: Observable<WithRef<IPractice> | undefined>;
  principleReport$: Observable<IPrincipleReconciliationReportData | undefined>;
  tyroReport$: Observable<ReconciliationReportResponse | undefined>;
  healthPointReport$: Observable<
    HealthPointReconciliationReportResponse | undefined
  >;
  selectedSummary$: Observable<ITransactionSourceSummary | undefined>;

  constructor() {
    this.query$ = this._store.pipe(
      select(getReconciliationReportQuery),
      unserialise$()
    );
    this.range$ = this._store.pipe(select(getDateRange), unserialise$());
    this.practice$ = this._store.pipe(select(getPractice), unserialise$());
    this.principleReport$ = this._store.pipe(
      select(getPrincipleReport),
      unserialise$()
    );
    this.tyroReport$ = this._store.pipe(select(getTyroReport), unserialise$());
    this.healthPointReport$ = this._store.pipe(
      select(getHealthPointReport),
      unserialise$()
    );
    this.selectedSummary$ = this._store.pipe(
      select(getSelectedSummary),
      unserialise$()
    );
  }

  setDateRange(range: ITimePeriod): void {
    this._store.dispatch(setDateRange(serialise({ range })));
  }

  setPractice(practice?: WithRef<IPractice>): void {
    this._store.dispatch(setPractice(serialise({ practice })));
  }

  selectSummary(summary: ITransactionSourceSummary): void {
    this._store.dispatch(selectSummary(serialise(summary)));
  }

  clearSelectedSummary(): void {
    this._store.dispatch(clearSelectedSummary());
  }
}
