import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AppointmentSummary,
  EVENT_TYPES_ICONS,
  IScheduleSummaryEventable,
  type EventIconMap,
  isAppointmentSummary,
} from '@principle-theorem/principle-core/interfaces';
import { TIME_FORMAT } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-timeline-tooltip',
  templateUrl: './timeline-tooltip.component.html',
  styleUrls: ['./timeline-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineTooltipComponent {
  readonly timeFormat = TIME_FORMAT;
  readonly eventIcons: EventIconMap = EVENT_TYPES_ICONS;
  eventable$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  eventIcon$: Observable<string>;
  title$: Observable<string>;
  appointment$: Observable<AppointmentSummary | undefined>;

  @Input()
  set eventable(eventable: IScheduleSummaryEventable) {
    if (eventable) {
      this.eventable$.next(eventable);
    }
  }

  constructor() {
    this.title$ = this.eventable$.pipe(
      map((eventable) => eventable.metadata.label)
    );
    this.eventIcon$ = this.eventable$.pipe(
      map((eventable) => this.eventIcons[eventable.event.type])
    );
    this.appointment$ = this.eventable$.pipe(
      map((eventable) =>
        isAppointmentSummary(eventable) ? eventable : undefined
      )
    );
  }
}
