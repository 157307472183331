import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type IAppointment } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-checklist-form-dialog',
  templateUrl: './checklist-form-dialog.component.html',
  styleUrls: ['./checklist-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistFormDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChecklistFormData,
    public dialogRef: MatDialogRef<ChecklistFormDialogComponent>
  ) {}
}

export interface IChecklistFormData {
  appointment: WithRef<IAppointment>;
}
