import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  ObservableDataTable,
  TrackByFunctions,
  extendSortingDataAccessor,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import { Event } from '@principle-theorem/principle-core';
import {
  type IAppointmentRequest,
  type IPatient,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { ConfirmPatientDialogComponent } from '../confirm-patient-dialog/confirm-patient-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'pr-appointment-requests-list',
  templateUrl: './appointment-requests-list.component.html',
  styleUrls: ['./appointment-requests-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentRequestsListComponent implements OnDestroy {
  trackByRequest = TrackByFunctions.ref<WithRef<IAppointmentRequest>>();
  dataTable: ObservableDataTable<WithRef<IAppointmentRequest>>;
  appointmentRequests$ = new ReplaySubject<WithRef<IAppointmentRequest>[]>(1);
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataTable.dataSource.sort = sort;
  }

  @Input()
  set appointmentRequests(appointmentRequests: WithRef<IAppointmentRequest>[]) {
    if (appointmentRequests) {
      this.appointmentRequests$.next(appointmentRequests);
    }
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _dialog: MatDialog
  ) {
    this.dataTable = new ObservableDataTable(this.appointmentRequests$);
    this.dataTable.displayColumns = [
      'appointment-date',
      'practitioner',
      'patient-name',
      'treatment',
      'actions',
    ];
    this.dataTable.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataTable.ngOnDestroy();
  }

  async cancelAppointmentRequest(
    appointment: WithRef<IAppointmentRequest>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Appointment Request',
      prompt: 'Are you sure you want to delete this request?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    this._schedulingFacade.cancelAppointmentRequest(appointment);
  }

  async confirmAppointmentRequest(
    appointment: WithRef<IAppointmentRequest>
  ): Promise<void> {
    const confirmPatient = await this._dialog
      .open(
        ConfirmPatientDialogComponent,
        DialogPresets.almostFullscreen({ data: appointment.patient })
      )
      .afterClosed()
      .toPromise<WithRef<IPatient>>();

    if (!confirmPatient) {
      return;
    }

    appointment.event = Event.addParticipants(appointment.event, [
      {
        ...toNamedDocument<IPatient>(confirmPatient),
        type: ParticipantType.Patient,
      },
    ]);

    this._schedulingFacade.selectPatient(confirmPatient);
    this._schedulingFacade.setAppointmentRequest(appointment);

    await this._router.navigate(['../../appointments/create'], {
      relativeTo: this._route,
      queryParams: {
        preserveState: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  private _sortingDataAccessor(
    data: WithRef<IAppointmentRequest>,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'appointment-date':
        return timestampSortingAccessor(data.event.from);
      case 'practitioner':
        return data.practitioner.name.toLowerCase();
      case 'patient-name':
        return data.patient.primaryContact.name.toLowerCase();
      case 'treatment':
        return data.template.step.name.toLowerCase();
      default:
        return;
    }
  }
}
