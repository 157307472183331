@if (isInline) {
  <span [class]="status" [ngClass]="{ dense: isDense }">{{
    status | titlecase
  }}</span>
} @else {
  <pr-status-label
    [label]="status"
    [color]="statusColourMap[status]"
    [dense]="isDense"
  />
}
