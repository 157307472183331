import { type IMigrationDataProvider } from '@principle-theorem/migration-runner';
import { type RawSchema } from '../schema';
import { BehaviorSubject } from 'rxjs';

export class SchemaDataProvider implements IMigrationDataProvider<RawSchema> {
  private _schema$: BehaviorSubject<RawSchema>;

  constructor(schema: RawSchema) {
    this._schema$ = new BehaviorSubject<RawSchema>(schema);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async get(): Promise<RawSchema> {
    return this._schema$.value;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async update(data: RawSchema): Promise<void> {
    this._schema$.next(data);
  }
}
