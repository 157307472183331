import { TeamMock } from '@principle-theorem/principle-core';
import { ITeamDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { BigQueryFirestoreModelMock } from './common.mock';

const team = MockGenerator.generate(TeamMock);

export class TeamDimensionMock
  extends BigQueryFirestoreModelMock
  implements ITeamDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = team.name;
}
