import { type TrackByFunction } from '@angular/core';
import { isDate, isDocRef, isTimestamp } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { compact, get } from 'lodash';
import { isMoment, type Moment } from 'moment-timezone';

type WithUniqueId =
  | {
      id: string;
    }
  | {
      uid: string;
    }
  | {
      uuid: string;
    };

export class TrackByFunctions {
  static nestedField<T>(path: string): TrackByFunction<T> {
    return (_index: number, item: T) => {
      const field: unknown = get(item, path);
      if (!field) {
        return;
      }
      return String(field);
    };
  }

  static field<T>(path: keyof T): TrackByFunction<T> {
    return (_index: number, item: T) => String(get(item, path, ''));
  }

  static ref<T>(path: string = 'ref'): TrackByFunction<T> {
    return (_index: number, item: T) => {
      const ref: unknown = get(item, path);
      if (!ref) {
        return;
      }
      if (!isDocRef(ref)) {
        return;
      }
      return ref.id;
    };
  }

  static uniqueId<T extends WithUniqueId>(): TrackByFunction<T> {
    return (_index: number, item: T) => {
      if ('id' in item) {
        return String(get(item, 'id', ''));
      }
      if ('uid' in item) {
        return String(get(item, 'uid', ''));
      }
      if ('uuid' in item) {
        return String(get(item, 'uuid', ''));
      }
      return '';
    };
  }

  static date<T>(path?: string): TrackByFunction<T> {
    return (_index: number, item: T) => {
      const date: unknown = path ? get(item, path) : item;
      if (!date) {
        return;
      }
      if (isTimestamp(date) || isMoment(date) || isDate(date)) {
        return convertDateToString(date);
      }
      return;
    };
  }

  static label<T extends { label: string }>(): TrackByFunction<T> {
    return (_index: number, item: T) => String(item.label);
  }

  static title<T extends { title: string }>(): TrackByFunction<T> {
    return (_index: number, item: T) => String(item.title);
  }

  static variable<T extends string | number>(): TrackByFunction<T> {
    return (_index: number, item: T) => String(item);
  }

  static combine<T>(...fields: string[]): TrackByFunction<T> {
    return (_index: number, item: T) => {
      compact(fields.map((field) => convertToString(item, field))).join('-');
    };
  }

  static fn<T>(trackFn: (item: T) => string | number): TrackByFunction<T> {
    return (_index: number, item: T) => trackFn(item);
  }

  static index<T>(): TrackByFunction<T> {
    return (index: number): number => {
      return index;
    };
  }

  field<T>(path: string): TrackByFunction<T> {
    return TrackByFunctions.nestedField(path);
  }

  ref<T>(path: string = 'ref'): TrackByFunction<T> {
    return TrackByFunctions.ref(path);
  }

  uniqueId<T extends WithUniqueId>(): TrackByFunction<T> {
    return TrackByFunctions.uniqueId();
  }

  date<T>(path?: string): TrackByFunction<T> {
    return TrackByFunctions.date(path);
  }

  name<T extends { name: string }>(): TrackByFunction<T> {
    return TrackByFunctions.field('name');
  }

  label<T extends { label: string }>(): TrackByFunction<T> {
    return TrackByFunctions.label();
  }

  title<T extends { title: string }>(): TrackByFunction<T> {
    return TrackByFunctions.title();
  }

  variable<T extends string | number>(): TrackByFunction<T> {
    return TrackByFunctions.variable();
  }

  combine<T>(...fields: string[]): TrackByFunction<T> {
    return TrackByFunctions.combine(...fields);
  }

  index(): TrackByFunction<number> {
    return TrackByFunctions.index();
  }
}

function convertToString<T>(item: T, path: string): string {
  const data: unknown = get(item, path);
  if (!data) {
    return '';
  }
  if (isTimestamp(data) || isMoment(data) || isDate(data)) {
    return convertDateToString(data);
  }
  if (isDocRef(data)) {
    return data.id;
  }
  return String(data);
}

function convertDateToString(date: Timestamp | Moment | Date): string {
  const item = isTimestamp(date) ? date.toDate() : date;
  return String(item);
}
