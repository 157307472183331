/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type ObservableInput,
  type ObservedValueOf,
  type OperatorFunction,
} from 'rxjs';

// TODO: Remove when RxJs improves typings in v7
// https://github.com/ReactiveX/rxjs/issues/5221
declare module 'rxjs/internal/operators/withLatestFrom' {
  export type ObservableInputTuple<T> = {
    [K in keyof T]: ObservableInput<T[K]>;
  };

  export function withLatestFrom<T, O extends unknown[]>(
    ...inputs: [...ObservableInputTuple<O>]
  ): OperatorFunction<T, [T, ...O]>;

  export function withLatestFrom<T, O extends unknown[], R>(
    ...inputs: [...ObservableInputTuple<O>, (...value: [T, ...O]) => R]
  ): OperatorFunction<T, R>;

  export function withLatestFrom<
    T,
    O2 extends ObservableInput<any>,
    O3 extends ObservableInput<any>,
    O4 extends ObservableInput<any>,
    O5 extends ObservableInput<any>,
    O6 extends ObservableInput<any>,
    O7 extends ObservableInput<any>,
    R,
  >(
    v2: O2,
    v3: O3,
    v4: O4,
    v5: O5,
    v6: O6,
    v7: O7,
    project: (
      v1: T,
      v2: ObservedValueOf<O2>,
      v3: ObservedValueOf<O3>,
      v4: ObservedValueOf<O4>,
      v5: ObservedValueOf<O5>,
      v6: ObservedValueOf<O6>,
      v7: ObservedValueOf<O7>
    ) => R
  ): OperatorFunction<T, R>;
  export function withLatestFrom<
    T,
    O2 extends ObservableInput<any>,
    O3 extends ObservableInput<any>,
    O4 extends ObservableInput<any>,
    O5 extends ObservableInput<any>,
    O6 extends ObservableInput<any>,
    O7 extends ObservableInput<any>,
  >(
    v2: O2,
    v3: O3,
    v4: O4,
    v5: O5,
    v6: O6,
    v7: O7
  ): OperatorFunction<
    T,
    [
      T,
      ObservedValueOf<O2>,
      ObservedValueOf<O3>,
      ObservedValueOf<O4>,
      ObservedValueOf<O5>,
      ObservedValueOf<O6>,
      ObservedValueOf<O7>,
    ]
  >;
}
