import { type MatPaginator } from '@angular/material/paginator';
import { type MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface IDataTable<T, D extends MatTableDataSource<T>> {
  dataSource: D;
  pageSizeOptions: number[];
  pageSize: number;
  displayColumns: string[];
}

export abstract class AbstractTable<T, D extends MatTableDataSource<T>>
  implements IDataTable<T, D>
{
  dataSource: D;
  displayColumns: string[];
  pageSizeOptions = [10, 25, 50, 100];
  pageSize = 10;

  sort: MatSort;
  paginator: MatPaginator;

  initSortAndPagination(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

export abstract class BaseTable<T> extends AbstractTable<
  T,
  MatTableDataSource<T>
> {
  override dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
}
