import { type DocumentReference } from '@principle-theorem/shared';

export interface IAssetRequirement {
  quantity: number;
  duration?: number; // In Minutes
  isFullDuration?: boolean;
  asset: DocumentReference;
}

export interface IAssetRequirements {
  instruments: IAssetRequirement[];
  equipment: IAssetRequirement[];
  consumables: IAssetRequirement[];
}
