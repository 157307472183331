@if (typed.section$ | async; as section) {
  <pr-form-builder-section-options [element]="section" />
}

@if (typed.content$ | async; as content) {
  <pr-form-builder-content-options class="flex grow" [element]="content" />
}

@if (typed.text$ | async; as text) {
  <pr-form-builder-text-options [element]="text" />
}

@if (typed.textarea$ | async; as textarea) {
  <pr-form-builder-text-options [element]="textarea" />
}

@if (typed.number$ | async; as number) {
  <pr-form-builder-text-options [element]="number" />
}

@if (typed.checkbox$ | async; as checkbox) {
  <pr-form-builder-text-options [element]="checkbox" />
}

@if (typed.date$ | async; as date) {
  <pr-form-builder-text-options [element]="date" />
}

@if (typed.dropdown$ | async; as dropdown) {
  <pr-form-builder-dropdown-options [element]="dropdown" />
}

@if (typed.signature$ | async; as signature) {
  <pr-form-builder-text-options [element]="signature" />
}
