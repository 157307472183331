import {
  IAbsoluteSchedulingRules,
  IRelativeSchedulingRules,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment, toTimestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export function toAbsoluteSchedulingRules(
  relativeRules: IRelativeSchedulingRules,
  date: moment.Moment = moment()
): IAbsoluteSchedulingRules {
  const rules: IAbsoluteSchedulingRules = {
    duration: relativeRules.duration,
  };
  if (relativeRules.minDays) {
    rules.minDate = toTimestamp(
      moment(date).add(relativeRules.minDays, 'days')
    );
  }
  if (relativeRules.maxDays) {
    rules.maxDate = toTimestamp(
      moment(date).add(relativeRules.maxDays, 'days')
    );
  }
  return rules;
}

export function dateIsWithinBounds(
  date: moment.Moment,
  rules: IAbsoluteSchedulingRules
): boolean {
  if (rules.minDate && date.isBefore(toMoment(rules.minDate), 'day')) {
    return false;
  }
  if (rules.maxDate && date.isAfter(toMoment(rules.maxDate), 'day')) {
    return false;
  }
  return true;
}
