import { CommonModule } from '@angular/common';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { APP_VERSION } from './app-version';
import { AppVersionComponent } from './app-version.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppVersionComponent],
  exports: [AppVersionComponent],
})
export class AppVersionModule {
  static forRoot(version: string): ModuleWithProviders<AppVersionModule> {
    return {
      ngModule: AppVersionModule,
      providers: [
        {
          provide: APP_VERSION,
          useValue: version,
        },
      ],
    };
  }
}
