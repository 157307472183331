import {
  createEntityAdapter,
  type EntityAdapter,
  type EntityState,
} from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type IMultiTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { loadMultiTreatmentConfigurationsSuccess } from '../../actions/charted-configurations';

export type IMultiTreatmentConfigurationState = EntityState<
  SerialisedData<WithRef<IMultiTreatmentConfiguration>>
>;

export const multiTreatmentConfigurationAdapter: EntityAdapter<
  SerialisedData<WithRef<IMultiTreatmentConfiguration>>
> = createEntityAdapter<SerialisedData<WithRef<IMultiTreatmentConfiguration>>>({
  selectId: (config) => config.ref.id,
  sortComparer: false,
});

export const initialMultiTreatmentConfiguration: IMultiTreatmentConfigurationState =
  multiTreatmentConfigurationAdapter.getInitialState();

export const multiTreatmentConfigurationReducer: ActionReducer<
  IMultiTreatmentConfigurationState,
  Action
> = createReducer(
  initialMultiTreatmentConfiguration,

  on(loadMultiTreatmentConfigurationsSuccess, (state, action) =>
    multiTreatmentConfigurationAdapter.upsertMany(action.configs, {
      ...state,
    })
  )

  // on(reset, () => initialMultiTreatmentConfiguration)
);
