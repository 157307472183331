@if (isAlpha()) {
  <div class="flex items-center gap-2">
    <mat-icon>error_outline</mat-icon>
    <span>
      This feature is in Alpha status. Our focus with alpha testing is to verify
      functionality and gather feedback. This feature may be incomplete and
      should not be relied upon for operational work.
    </span>
  </div>
}
