import { type AbstractControl, type ValidationErrors } from '@angular/forms';
import { shareReplayCold, type TypeGuardFn } from '@principle-theorem/shared';
import { isNull } from 'lodash';
import { type Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import {
  type TypedAbstractControl,
  type TypedFormArray,
  type TypedFormControl,
  type TypedFormGroup,
} from './typed-form-group';

export function canSave$(formControl: AbstractControl): Observable<boolean> {
  return formControl.statusChanges.pipe(
    startWith(undefined),
    map(() => formControl.valid && formControl.dirty),
    shareReplayCold()
  );
}

export function isDisabled$(formControl: AbstractControl): Observable<boolean> {
  return formControl.statusChanges.pipe(
    startWith(undefined),
    map(() => !formControl.valid || !formControl.dirty),
    shareReplayCold()
  );
}

export function formControlChanges$<T>(
  formControl: TypedAbstractControl<T>
): Observable<T | undefined> {
  return formControl.valueChanges.pipe(
    startWith(formControl.value),
    map((value) => (isNull(value) ? undefined : value))
  );
}

export function validFormGroupChanges$<T extends object>(
  form: TypedFormGroup<T>
): Observable<T> {
  return form.valueChanges.pipe(
    filter(() => form.valid),
    map(() => form.getRawValue())
  );
}

export function validFormControlChanges$<T>(
  form: TypedFormControl<T>
): Observable<T> {
  return form.valueChanges.pipe(filter(() => form.valid));
}

export function validFormArrayChanges$<T>(
  form: TypedFormArray<T>
): Observable<T[]> {
  return form.valueChanges.pipe(filter(() => form.valid));
}

export class FormValidators {
  static typeGuard<T>(
    guard: TypeGuardFn<T>,
    message: string
  ): (control: TypedAbstractControl<T>) => ValidationErrors | null {
    return (control: TypedAbstractControl<T>): ValidationErrors | null => {
      if (guard(control.value)) {
        // eslint-disable-next-line no-null/no-null
        return null;
      }
      return { required: message };
    };
  }
}
