import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type RawInlineNodes,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  type ISetOptionParams,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  CalendarEvent,
  Event,
  stafferToNamedDoc,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  EventType,
  type ICalendarEvent,
  type IEvent,
  type IParticipant,
  type IPatient,
  type IPractice,
  isPreBlockEvent,
  type IStaffer,
  type ITag,
  type ITreatmentCategory,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import {
  asDocRef,
  DEFAULT_INCREMENT,
  filterUndefined,
  getDoc,
  type INamedDocument,
  isSameRef,
  isTime24hrType,
  mergeDayAndTime,
  type RequireProps,
  type Time24hrType,
  type Timezone,
  TIME_FORMAT_24HR,
  to24hrTime,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface ICalendarEventFormData
  extends Pick<
      ICalendarEvent,
      'title' | 'locked' | 'notes' | 'eventTags' | 'isBlocking'
    >,
    RequireProps<Pick<IEvent, 'practice' | 'type'>, 'practice'> {
  staffSearch: string | WithRef<IStaffer>;
  participants: INamedDocument<IStaffer | IPatient>[];
  eventStartDate: moment.Moment;
  eventEndDate?: moment.Moment;
  startTime: string;
  endTime: string;
  allowedTreatmentCategories?: DocumentReference<ITreatmentCategory>[];
  isPublic?: boolean;
  colourOverride?: string;
}

const SINGLE_PARTICIPANT_TYPES = [
  EventType.Leave,
  EventType.Break,
  EventType.RosteredOn,
  EventType.PreBlock,
];
const MULTIPLE_PARTICIPANT_TYPES = [EventType.Meeting, EventType.Misc];
const SINGLE_DAY_TYPES = [
  EventType.Break,
  EventType.RosteredOn,
  EventType.Meeting,
  EventType.Misc,
  EventType.PreBlock,
];

export class CalendarEventFormGroup extends TypedFormGroup<ICalendarEventFormData> {
  constructor() {
    const now: moment.Moment = moment();
    const startTime: moment.Moment = now.clone().add(1, 'hour').minute(0);
    const endTime: moment.Moment = startTime
      .clone()
      .add(DEFAULT_INCREMENT, 'minutes');

    super({
      title: new TypedFormControl<RawInlineNodes>([], Validators.required),
      type: new TypedFormControl<EventType>(
        EventType.Meeting,
        Validators.required
      ),
      practice: new TypedFormControl<INamedDocument<IPractice>>(
        undefined,
        Validators.required
      ),
      staffSearch: new TypedFormControl<string | WithRef<IStaffer>>(''),
      participants: new TypedFormControl<INamedDocument<IStaffer | IPatient>[]>(
        [],
        Validators.required
      ),
      eventStartDate: new TypedFormControl<moment.Moment>(
        now,
        Validators.required
      ).withGuard(moment.isMoment),
      eventEndDate: new TypedFormControl<moment.Moment>().withGuard(
        moment.isMoment
      ),
      startTime: new TypedFormControl<Time24hrType>(
        to24hrTime(startTime)
      ).withGuard(isTime24hrType),
      endTime: new TypedFormControl<Time24hrType>(
        to24hrTime(endTime)
      ).withGuard(isTime24hrType),
      notes: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
      locked: new TypedFormControl<boolean>(false),
      isBlocking: new TypedFormControl<boolean>(true),
      eventTags: new TypedFormControl<INamedDocument<ITag>[]>([]),
      allowedTreatmentCategories: new TypedFormControl<
        DocumentReference<ITreatmentCategory>[]
      >([]),
      isPublic: new TypedFormControl<boolean>(false),
      colourOverride: new TypedFormControl<string>(),
    });
  }

  selectedPractice$(): Observable<INamedDocument<IPractice>> {
    return this.controls.practice.valueChanges.pipe(
      startWith(this.controls.practice.value ?? undefined),
      filterUndefined()
    );
  }

  isSelectedType$(match: EventType | EventType[]): Observable<boolean> {
    return this.valueChanges.pipe(
      startWith(this.getRawValue()),
      map((form) => {
        return Array.isArray(match)
          ? match.includes(form.type)
          : form.type === match;
      })
    );
  }

  isNotSelectedType$(match: EventType | EventType[]): Observable<boolean> {
    return this.valueChanges.pipe(
      startWith(this.getRawValue()),
      map((form) => {
        return Array.isArray(match)
          ? !match.includes(form.type)
          : form.type !== match;
      })
    );
  }

  async updateControls(
    calendarEvent: ICalendarEvent,
    options?: ISetOptionParams
  ): Promise<void> {
    this.patchValue(
      {
        title: calendarEvent.title,
        type: calendarEvent.event.type,
        practice: calendarEvent.event.practice,
        participants: calendarEvent.event.participants,
        locked: calendarEvent.locked,
        isBlocking: calendarEvent.isBlocking,
        notes: calendarEvent.notes,
        eventTags: calendarEvent.eventTags,
      },
      options
    );

    if (isPreBlockEvent(calendarEvent.event)) {
      this.patchValue(
        {
          allowedTreatmentCategories:
            calendarEvent.event.allowedTreatmentCategories,
          isPublic: calendarEvent.event.isPublic,
          colourOverride: calendarEvent.event.colourOverride,
        },
        options
      );
    }

    const timezone = await TimezoneResolver.fromEvent(calendarEvent);
    const eventStartDate = toMomentTz(calendarEvent.event.from, timezone);
    const eventEndDate = toMomentTz(calendarEvent.event.to, timezone);
    const startTime = to24hrTime(eventStartDate);
    const endTime = to24hrTime(eventEndDate);

    this.patchValue(
      {
        eventStartDate,
        eventEndDate,
        startTime,
        endTime,
      },
      options
    );

    await this._patchSingleParticipant(calendarEvent, options);
    this.markAllAsTouched();
  }

  addParticipant(staffer: WithRef<IStaffer>): void {
    const isSingleType = SINGLE_PARTICIPANT_TYPES.includes(this.value.type);
    if (isSingleType) {
      this.controls.participants.setValue([stafferToNamedDoc(staffer)]);
      return;
    }
    const foundIndex: number = this._findParticipantIndex(
      stafferToNamedDoc(staffer)
    );
    if (foundIndex !== -1) {
      return;
    }
    const participants = (this.controls.participants.value ?? []).concat(
      stafferToNamedDoc(staffer)
    );
    this.controls.participants.setValue(participants);
    this.controls.staffSearch.setValue('');
  }

  removeParticipant(staffer: WithRef<IStaffer>): void {
    const stafferDoc = stafferToNamedDoc(staffer);
    const updated = (this.controls.participants.value ?? []).filter(
      (participant) => !isSameRef(participant, stafferDoc)
    );
    this.controls.participants.setValue(updated);
  }

  async toCalendarEvent(
    creator: INamedDocument<IStaffer>
  ): Promise<ICalendarEvent> {
    const data: ICalendarEventFormData = this.getRawValue();
    const timezone = await TimezoneResolver.fromPracticeRef(data.practice.ref);
    return CalendarEvent.init({
      title: data.title,
      notes: data.notes,
      locked: data.locked,
      eventTags: data.eventTags,
      isBlocking: data.isBlocking,
      event: this._getEvent(creator, timezone),
    });
  }

  toggleParticipantsRequired(isRequired: boolean): void {
    if (isRequired) {
      this.controls.participants.setValidators([Validators.required]);
    } else {
      this.controls.participants.clearValidators();
      this.controls.participants.reset([]);
      this.controls.staffSearch.clearValidators();
      this.controls.staffSearch.reset('');
    }
    this.updateValueAndValidity();
  }

  private async _patchSingleParticipant(
    calendarEvent: ICalendarEvent,
    options?: ISetOptionParams
  ): Promise<void> {
    if (!SINGLE_PARTICIPANT_TYPES.includes(calendarEvent.event.type)) {
      return;
    }
    await asyncForEach(
      calendarEvent.event.participants,
      async (participant) => {
        const staffer = await getDoc(asDocRef<IStaffer>(participant.ref));
        this.controls.staffSearch.patchValue(staffer, options);
      }
    );
  }

  private _getEvent(
    creator: INamedDocument<IStaffer>,
    timezone: Timezone
  ): IEvent {
    const data: ICalendarEventFormData = this.getRawValue();
    creator = toNamedDocument(creator);

    const event = Event.init({
      type: data.type,
      practice: toNamedDocument(data.practice),
      from: this._getEventStart(data, timezone),
      to: this._getEventEnd(data, timezone),
      participants: this._getParticipants(data),
      organiser: creator,
      creator,
    });

    if (isPreBlockEvent(event)) {
      return {
        ...event,
        allowedTreatmentCategories: data.allowedTreatmentCategories ?? [],
        isPublic: data.isPublic ?? false,
        colourOverride: data.colourOverride,
      };
    }

    return event;
  }

  private _getParticipants(formData: ICalendarEventFormData): IParticipant[] {
    if (
      ![...MULTIPLE_PARTICIPANT_TYPES, ...SINGLE_PARTICIPANT_TYPES].includes(
        formData.type
      )
    ) {
      return [];
    }

    return formData.participants.map((participant) => {
      return { ...participant, type: ParticipantType.Staffer };
    });
  }

  private _getEventStart(
    formData: ICalendarEventFormData,
    timezone: Timezone
  ): Timestamp {
    const date = formData.eventStartDate;
    const time = moment(formData.startTime, TIME_FORMAT_24HR);
    return toTimestamp(mergeDayAndTime(date, time, timezone));
  }

  private _getEventEnd(
    formData: ICalendarEventFormData,
    timezone: Timezone
  ): Timestamp {
    const isSingleDayEvent = SINGLE_DAY_TYPES.includes(formData.type);
    const date = isSingleDayEvent
      ? formData.eventStartDate
      : formData.eventEndDate ?? formData.eventStartDate;
    const time = moment(formData.endTime, TIME_FORMAT_24HR);
    return toTimestamp(mergeDayAndTime(date, time, timezone));
  }

  private _findParticipantIndex(staffer: INamedDocument): number {
    return (this.controls.participants.value ?? []).findIndex(
      (participant: INamedDocument) => isSameRef(participant, staffer)
    );
  }
}
