import { DimensionsCommon } from '@principle-theorem/reporting/interfaces';
import { Timestamp } from '@principle-theorem/shared';
import {
  BaseMock,
  IAvroSerialisedRawInlineNodes,
  IAvroSerialisedVersionedSchema,
  ISerialisedDocumentReference,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';
import { NoteMock, InteractionMock } from '@principle-theorem/principle-core';

const mockedDocRef = MockDocRef();

export class SerialisedDocumentReferenceMock
  extends BaseMock
  implements ISerialisedDocumentReference
{
  id = mockedDocRef.id;
  referenceValue = mockedDocRef.path;
}

export class BigQueryNamedDocumentMock
  extends BaseMock
  implements DimensionsCommon.IBigQueryNamedDocument
{
  name = 'Mock Named Document';
  ref: ISerialisedDocumentReference = MockGenerator.generate(
    SerialisedDocumentReferenceMock
  );
}

export class BigQueryReffableMock
  extends BaseMock
  implements DimensionsCommon.IBigQueryReffable
{
  ref: ISerialisedDocumentReference = MockGenerator.generate(
    SerialisedDocumentReferenceMock
  );
}

export class BigQueryFirestoreModelMock
  extends BigQueryReffableMock
  implements DimensionsCommon.IBigQueryFirestoreModel
{
  createdAt = toSerialisedTimestamp(toTimestamp());
  updatedAt = toSerialisedTimestamp(toTimestamp());
  deleted = false;
}

export class AvroSerialisedRawInlineNodesMock
  extends BaseMock
  implements IAvroSerialisedRawInlineNodes
{
  rawInlineNodesValue = {
    text: 'Hello World',
    json: '{}',
  };
}

export class AvroSerialisedVersionedSchemaMock
  extends BaseMock
  implements IAvroSerialisedVersionedSchema
{
  versionedSchemaValue = {
    text: 'Hello World',
    json: '{}',
  };
}

const note = MockGenerator.generate(NoteMock);
export class BigQueryNoteMock
  extends BaseMock
  implements DimensionsCommon.IBigQueryNote
{
  uid = note.uid;
  pinned = note.pinned;
  deleted = note.deleted;
  content = MockGenerator.generate(AvroSerialisedVersionedSchemaMock);
  createdAt = toSerialisedTimestamp(toTimestamp());
  updatedAt = toSerialisedTimestamp(toTimestamp());
}

export function MockBigQueryStatusHistory<T>(
  status: T,
  timestamp: Timestamp = MockTimestamp()
): DimensionsCommon.IBigQueryStatusHistory<T> {
  return {
    status,
    updatedAt: toSerialisedTimestamp(timestamp),
  };
}

const interaction = MockGenerator.generate(InteractionMock);

export class BigQueryInteractionMock
  extends BaseMock
  implements DimensionsCommon.IBigQueryInteraction
{
  uid = interaction.uid;
  type = interaction.type;
  owner = MockGenerator.generate(BigQueryNamedDocumentMock);
  action = interaction.action;
  title = MockGenerator.generate(AvroSerialisedRawInlineNodesMock);
  createdAt = toSerialisedTimestamp(interaction.createdAt);
  content = MockGenerator.generate(AvroSerialisedVersionedSchemaMock);
}

export function MockBigQueryNamedDocument(
  name: string,
  collectionPath: string = uuid()
): DimensionsCommon.IBigQueryNamedDocument {
  return MockGenerator.generate(BigQueryNamedDocumentMock, {
    name,
    ref: MockBigQueryDocRef(name, collectionPath),
  });
}

export function MockBigQueryDocRef(
  id: string,
  collectionPath: string = uuid()
): ISerialisedDocumentReference {
  return MockGenerator.generate(SerialisedDocumentReferenceMock, {
    id,
    referenceValue: `${collectionPath}/${id}`,
  });
}
