import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  TransactionAllocation,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IInvoice,
  IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  mergeDayAndTime,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { determineNewCreatedAtDate } from '../../transaction-helpers';
import { AmendTransactionDateStore } from './amend-transaction-date.store';

export interface ITransactionEditDialogResult
  extends Pick<ITransaction, 'amount' | 'description' | 'attributedTo'> {
  dateReceived?: Moment;
}

export interface ITransactionEditDialogData
  extends Pick<ITransactionEditDialogResult, 'dateReceived'> {
  transaction: WithRef<ITransaction>;
  invoice: IInvoice;
  title: string;
  max?: number;
}

class TransactionEditDialogForm extends TypedFormGroup<ITransactionEditDialogResult> {
  constructor(data: ITransactionEditDialogData) {
    const initialAmountValue = data.transaction.amount ?? data.max;
    const initialAmount = initialAmountValue
      ? roundTo2Decimals(initialAmountValue)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);
    super({
      description: new TypedFormControl<string>(data.transaction.description),
      dateReceived: new TypedFormControl<Moment>(data.dateReceived),
      amount: new TypedFormControl<number>(initialAmount, amountValidators),
      attributedTo: new TypedFormControl<
        DocumentReference<IStaffer> | undefined
      >(data.transaction.attributedTo),
    });
  }
}

@Component({
    selector: 'pr-transaction-edit-dialog',
    templateUrl: './transaction-edit-dialog.component.html',
    styleUrls: ['./transaction-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
    standalone: false
})
export class TransactionEditDialogComponent {
  form: TransactionEditDialogForm;
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  staff: INamedDocument<IStaffer>[];
  showAttributedTo: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITransactionEditDialogData,
    private _dialogRef: MatDialogRef<
      TransactionEditDialogComponent,
      ITransactionEditDialogResult
    >,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.form = new TransactionEditDialogForm(data);
    this.amendDateStore.loadAmendDates(data.transaction);
    this.staff = Invoice.staffOnInvoice(this.data.invoice);
    this.showAttributedTo =
      this.staff.length > 1 ||
      TransactionAllocation.hasUnallocatedAmount(this.data.invoice);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          this.data.transaction,
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : undefined;

    this._dialogRef.close({
      ...this.form.value,
      dateReceived,
    });
  }
}
