import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiSortBy$,
  nameSorter,
  snapshot,
  type INamedDocument,
  type IReffable,
  type WithRef,
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiMap,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { FeeScheduleSourceEntity } from '../../source/entities/fee-schedule';
import { TranslationMapHandler } from '../../../translation-map';
import { PracticeMigration } from '../../../practice-migrations';
import { XSLXToFeeScheduleToPatientTag } from './xlsx-to-fee-schedule-to-patient-tag';
import { FeeSchedulesToPatientTagXSLX } from './fee-schedule-to-patient-tag-to-xlsx';
import { from } from 'rxjs';
import { IBaseMigrationFeeSchedule } from '../../../mappings/fee-schedules';

export const FEE_SCHEDULE_TO_PATIENT_TAG_CUSTOM_MAPPING_TYPE =
  'feeScheduleToPatientTag';

export const FEE_SCHEDULE_TO_PATIENT_TAG_MAPPING: ICustomMapping =
  CustomMapping.init({
    metadata: {
      label: 'Fee Schedule To Patient Tag',
      description:
        'Allow for mapping a fee schedule to a patient tag. This will allow for the fee schedules to be used to add tags such as DVA and CDBS.',
      type: FEE_SCHEDULE_TO_PATIENT_TAG_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.DocumentReference,
  });

export class D4WFeeScheduleToPatientTagMappingHandler extends BaseCustomMappingHandler<ITag> {
  customMapping = FEE_SCHEDULE_TO_PATIENT_TAG_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new FeeScheduleSourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => ({
        label: `${record.description}`,
        value: entity.getSourceRecordId(record).toString(),
      }));

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      Brand.patientTags$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, feeSchedules, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      feeSchedules,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, feeSchedules, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      feeSchedules,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToFeeScheduleToPatientTag()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const patientTags = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const patientTag = patientTags.find(
        (configuration) => configuration.name === mapTo
      );

      if (!patientTag) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find tag for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: patientTag.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    feeSchedules: IBaseMigrationFeeSchedule[];
    translators: FeeSchedulesToPatientTagXSLX;
  }> {
    const fileName = this.getFileName();
    const feeSchedules = await snapshot(
      from(this.getSourceOptions(migration)).pipe(
        multiMap((option) => ({ id: option.value, name: option.label }))
      )
    );
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translators = new FeeSchedulesToPatientTagXSLX(
      await snapshot(Brand.patientTags$(migration.configuration.brand)),
      await this.getRecords(translationMap)
    );
    return { fileName, feeSchedules, translators };
  }
}
