import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import {
  IChat,
  IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  isSameRef,
  patchDoc,
  snapshot,
  snapshotDefined,
} from '@principle-theorem/shared';
import { compact, differenceBy, sortBy, uniqBy } from 'lodash';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ChatsDashboardStore } from '../../pages/chats-dashboard/chats-dashboard.store';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import { toMention } from '@principle-theorem/principle-core';

export interface IEditChatMembersDialogData {
  chat: WithRef<IChat>;
}

@Component({
  selector: 'pr-edit-chat-members-dialog',
  templateUrl: './edit-chat-members-dialog.component.html',
  styleUrl: './edit-chat-members-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditChatMembersDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  searchCtrl = new TypedFormControl<string>();
  search: InputSearchFilter<WithRef<IStaffer>>;
  selected$ = new BehaviorSubject<WithRef<IStaffer>[]>([]);

  constructor(
    private _organisation: OrganisationService,
    private _chat: ChatsDashboardStore,
    private _dialogRef: MatDialogRef<EditChatMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: IEditChatMembersDialogData
  ) {
    this.search = new InputSearchFilter<WithRef<IStaffer>>(
      combineLatest([
        this._organisation.staff$,
        this._organisation.staffer$,
        this.selected$,
      ]).pipe(
        map(([staff, currentStaffer, selected]) => {
          const unselected = differenceBy(
            staff.filter(
              (staffer) => !isSameRef(staffer.ref, currentStaffer?.ref)
            ),
            selected,
            (staffer) => staffer.ref.path
          );

          return unselected;
        }),
        map((staff) => sortBy(staff, 'user.name'))
      ),
      toSearchStream(this.searchCtrl),
      ['user.name']
    );

    combineLatest([this._organisation.staff$, this._organisation.staffer$])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([staff, currentStaffer]) => {
        const participants = compact(
          this._data.chat.participants.map((participant) =>
            staff.find(
              (staffer) =>
                isSameRef(staffer, participant) &&
                !isSameRef(staffer, currentStaffer)
            )
          )
        );
        this.selected$.next(participants);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  displayFn(value: string | WithRef<IStaffer>): string {
    return typeof value === 'string' ? value : '';
  }

  addMember(staffer: WithRef<IStaffer>): void {
    this.selected$.next(
      sortBy([...this.selected$.value, staffer], 'user.name')
    );
  }

  removeMember(staffer: WithRef<IStaffer>): void {
    this.selected$.next(
      differenceBy(
        this.selected$.value,
        [staffer],
        (currentStaffer) => currentStaffer.ref.path
      )
    );
  }

  async save(): Promise<void> {
    const staff = await snapshot(this._organisation.staff$);
    const currentStaffer = await snapshotDefined(this._organisation.staffer$);
    const chat = this._data.chat;
    const participants = await snapshot(this.selected$);
    const newMemberRefs = uniqBy(
      [
        currentStaffer.ref,
        ...participants.map((participant) => participant.ref),
      ],
      (ref) => ref.path
    );

    await patchDoc(chat.ref, {
      participants: newMemberRefs,
    });

    const addedMembers = compact(
      differenceBy(
        newMemberRefs,
        chat.participants,
        (participant) => participant.path
      ).map((participant) =>
        staff.find((staffer) => isSameRef(staffer, participant))
      )
    );

    const removedMembers = compact(
      differenceBy(
        chat.participants,
        newMemberRefs,
        (participant) => participant.path
      ).map((participant) =>
        staff.find((staffer) => isSameRef(staffer, participant))
      )
    );

    if (addedMembers.length) {
      await asyncForEach(addedMembers, (participant) =>
        this._chat.addInteraction(chat.ref, [
          toMentionContent(toMention(participant, MentionResourceType.Staffer)),
          toTextContent(` added to chat by `),
          toMentionContent(
            toMention(currentStaffer, MentionResourceType.Staffer)
          ),
        ])
      );
    }

    await asyncForEach(removedMembers, (participant) =>
      this._chat.addInteraction(chat.ref, [
        toMentionContent(toMention(participant, MentionResourceType.Staffer)),
        toTextContent(` removed from chat by `),
        toMentionContent(
          toMention(currentStaffer, MentionResourceType.Staffer)
        ),
      ])
    );

    this._dialogRef.close();
  }
}
