import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  SelectionListStore,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ChartedItemTotalCalculator,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedItemSelectionSummary,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedTreatment,
  type IFeeSchedule,
  type IPatient,
  isChartedMultiStepTreatment,
  isChartedMultiStepTreatmentStep,
  isTreatmentPlan,
  type ITreatmentPlan,
  type ITreatmentPlanProposal,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  addDoc,
  deleteDoc,
  isSameRef,
  isWithRef,
  patchDoc,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, get } from 'lodash';
import {
  combineLatest,
  noop,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { AddChartable } from '../../add-chartable';
import { ChartDialogService } from '../../chart-dialog.service';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../chartable-surface-updater';
import { type IChartedSurfaceProvider } from '../../charted-surface/add-charted-surface-provider';
import { AddMultiTreatmentToProposalProvider } from '../../charted-surface/chart/add-multi-treatment-to-proposal-provider';
import { AddTreatmentToMultiTreatmentProposalProvider } from '../../charted-surface/chart/add-treatment-to-multi-treatment-proposal-provider';
import { AddTreatmentToProposalProvider } from '../../charted-surface/chart/add-treatment-to-proposal-provider';
import { type IDragDropNode, type IDropEvent } from '../../drag-drop-group';
import {
  StepDragDropGroup,
  type StepItem,
  type StepParent,
} from '../../step-drag-drop-group';
import { TreatmentStepsEditorService } from './treatment-steps-editor.service';

@Component({
    selector: 'pr-treatment-steps-editor',
    templateUrl: './treatment-steps-editor.component.html',
    styleUrls: ['./treatment-steps-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentStepsEditorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  stepDragDrop = new StepDragDropGroup();
  currentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  price$: Observable<number>;
  planDisabled$: Observable<boolean>;
  childPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  flaggedTreatments$: Observable<IChartedTreatment[]>;
  trackByPlan = TrackByFunctions.ref<WithRef<ITreatmentPlan>>();
  formCtrl = new TypedFormControl<'consolidated' | 'current'>();

  @ViewChild('flaggedTreatment', { read: ElementRef })
  flaggedTreatment: ElementRef;

  @Input() patient: WithRef<IPatient>;
  @Input() flaggedTreatments: ITreatmentPlanProposal;
  @Input() showPlanView: boolean;
  @Output() showPlan = new EventEmitter<void>();
  @Output() setFeeSchedule = new EventEmitter<WithRef<IFeeSchedule>>();

  @Input()
  set currentPlan(currentPlan: WithRef<ITreatmentPlan>) {
    if (currentPlan) {
      this.currentPlan$.next(currentPlan);
    }
  }

  constructor(
    public elementRef: ElementRef,
    private _chartFacade: ChartFacade,
    private _treatmentStepsEditor: TreatmentStepsEditorService,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _chartDialog: ChartDialogService,
    public selectionList: SelectionListStore<
      IChartedItemSelectionSummary<IChartedTreatment>
    >
  ) {
    this.price$ = this.currentPlan$.pipe(
      switchMap((plan) =>
        this._treatmentPlanFacade.getTreatmentSteps$(plan.ref)
      ),
      map((steps) => new ChartedItemTotalCalculator().multiStep({ steps }))
    );
    this.planDisabled$ = this.currentPlan$.pipe(
      map((currentPlan) => TreatmentPlan.isDisabled(currentPlan))
    );
    this.stepDragDrop.afterDrop$
      .pipe(
        concatMap((event) => this._handleStepDrag(event)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.childPlans$ = this.currentPlan$.pipe(
      switchMap((plan) => TreatmentPlan.getChildren(plan))
    );

    this.flaggedTreatments$ = this._chartFacade.chartedTreatments$(
      ChartId.InAppointment
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toElementRef(element: HTMLElement): ElementRef {
    return new ElementRef(element);
  }

  updateCurrentPlan(plan: WithRef<ITreatmentPlan>): void {
    this._treatmentPlanFacade.selectTreatmentPlan(plan.ref);
    this.showPlan.emit();
  }

  async updateChartable(data: IEditChartableData): Promise<void> {
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(
      data,
      await this._buildServiceProviders(data.plan, data.step)
    );
  }

  async addFlaggedTreatment(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<void> {
    const feeSchedule = await snapshot(
      this._chartFacade.getFeeScheduleManager().currentSchedule$
    );

    await new AddChartable([
      new AddTreatmentToProposalProvider(this._chartFacade, feeSchedule),
      new AddMultiTreatmentToProposalProvider(
        this._chartFacade,
        this._chartDialog,
        toNamedDocument(feeSchedule)
      ),
    ]).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(
        this._chartFacade.chartingAs$(ChartId.InAppointment)
      ),
    });
  }

  async selectAllFlaggedTreatments(): Promise<void> {
    this.selectionList.resetSelected();
    const flaggedTreatments = await snapshot(this.flaggedTreatments$);

    flaggedTreatments.map((item) =>
      this.selectionList.toggleSelected({ item })
    );
  }

  private async _buildServiceProviders(
    plan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>,
    step?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): Promise<IChartedSurfaceProvider[]> {
    const feeSchedule = await snapshot(
      this._chartFacade.getFeeScheduleManager().currentSchedule$
    );

    const addSurfaceProviders: IChartedSurfaceProvider[] = [
      new AddMultiTreatmentToProposalProvider(
        this._chartFacade,
        this._chartDialog,
        toNamedDocument(feeSchedule)
      ),
    ];

    if (
      plan &&
      isChartedMultiStepTreatment(plan) &&
      step &&
      isChartedMultiStepTreatmentStep(step)
    ) {
      addSurfaceProviders.push(
        new AddTreatmentToMultiTreatmentProposalProvider(
          this._chartFacade,
          plan,
          step,
          feeSchedule
        )
      );
    }

    addSurfaceProviders.push(
      new AddTreatmentToProposalProvider(this._chartFacade, feeSchedule)
    );

    return addSurfaceProviders;
  }

  private async _handleStepDrag(
    event: IDropEvent<StepParent, StepItem>
  ): Promise<void> {
    const movedFromPlan = isTreatmentPlan(event.previous.data.parent);
    const movedToPlan = isTreatmentPlan(event.current.data.parent);
    const [, nextPlan] = await this._getSourcePlans(event);

    const planStepsChangeSource = movedToPlan
      ? event.current.data.items
      : movedFromPlan
        ? event.previous.data.items
        : undefined;

    if (planStepsChangeSource && nextPlan) {
      const stepRefs = planStepsChangeSource.map(async (step) =>
        isWithRef(step)
          ? step.ref
          : addDoc(TreatmentPlan.treatmentStepCol(nextPlan), step)
      );
      const steps = await Promise.all(stepRefs);
      await patchDoc(nextPlan.ref, { steps: compact(steps) });
    }

    const isMovedToSameFlagged =
      get(event.previous.data.parent, 'uuid') ===
      get(event.current.data.parent, 'uuid');
    if (!isMovedToSameFlagged) {
      await this._updateMultiTreatment(event.previous.data);
    }
    await this._updateMultiTreatment(event.current.data);
  }

  private async _getSourcePlans(
    event: IDropEvent<StepParent, StepItem>
  ): Promise<
    [WithRef<ITreatmentPlan> | undefined, WithRef<ITreatmentPlan> | undefined]
  > {
    const previousPlan = isTreatmentPlan(event.previous.data.parent)
      ? await this._findPlan(event.previous.data.parent.ref)
      : undefined;
    const nextPlan = isTreatmentPlan(event.current.data.parent)
      ? await this._findPlan(event.current.data.parent.ref)
      : undefined;
    return [previousPlan, nextPlan];
  }

  private async _findPlan(
    search: DocumentReference<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentPlan> | undefined> {
    return snapshot(
      combineLatest([this.currentPlan$, this.childPlans$]).pipe(
        map(([current, children]) =>
          isSameRef(search, current)
            ? current
            : children.find((child) => isSameRef(child, search))
        )
      )
    );
  }

  private async _updateMultiTreatment(
    data: IDragDropNode<StepParent, StepItem>
  ): Promise<void> {
    if (!isChartedMultiStepTreatment(data.parent)) {
      return;
    }
    const steps = data.items.map((item) =>
      this._coerceToChartedMultiStepTreatment(item)
    );
    const multiTreatment = {
      ...data.parent,
      steps: await Promise.all(steps),
    };

    await this._chartFacade.updateMultiTreatment(
      ChartId.InAppointment,
      multiTreatment
    );
  }

  private async _coerceToChartedMultiStepTreatment(
    step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep
  ): Promise<IChartedMultiStepTreatmentStep> {
    if (isChartedMultiStepTreatmentStep(step)) {
      return step;
    }
    await this._treatmentStepsEditor.removeAppointmentFromStep(step);
    await deleteDoc(step.ref);
    return {
      uid: step.ref.id,
      ...step,
    };
  }
}
