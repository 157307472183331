import {
  ITranslationMap,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  isINamedDocument,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodesMappingHandler } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import {
  ADAItemSourceEntity,
  type IExactADAItem,
} from '../../source/entities/ada-item';
import { type IExactTreatment } from '../../source/entities/patient-treatments';

export class ExactItemCodeMappingHandler extends ItemCodesMappingHandler<
  IExactADAItem,
  ADAItemSourceEntity
> {
  override sourceEntity = new ADAItemSourceEntity();
  override translateFn = (record: IExactADAItem): IBaseMigrationItemCode => ({
    ...record,
  });
}

export function resolveMappedExactTreatmentConfiguration(
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[],
  sourceTreatment: IExactTreatment
): INamedDocument<ITreatmentConfiguration> | undefined {
  const mappedCode = sourceItemCodes.find(
    (itemCode) =>
      itemCode.sourceIdentifier === sourceTreatment.service_code &&
      itemCode.destinationValue ===
        ItemCodeResourceMapType.TreatmentConfiguration
  );
  if (!mappedCode?.associatedValue) {
    return;
  }

  if (isINamedDocument(mappedCode.associatedValue)) {
    return mappedCode.associatedValue as INamedDocument<ITreatmentConfiguration>;
  }
}
