import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  IRescheduleListData,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  AUTOMATION_GRACE_PERIOD,
  AutomationStatus,
  TimingDirection,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  toMoment,
  toMomentTz,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface ICustomMessage {
  message: string;
  tooltip: string;
}

@Component({
    selector: 'pr-automation-reschedule-timing',
    imports: [
        CommonModule,
        NgSharedModule,
        NgMaterialModule,
        NgPrincipleSharedModule,
    ],
    templateUrl: './automation-reschedule-timing.component.html',
    styleUrl: './automation-reschedule-timing.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationRescheduleTimingComponent {
  readonly dateFormat = DATE_TIME_FORMAT;
  automation$ = new ReplaySubject<IRescheduleListData>(1);
  status$: Observable<AutomationStatus>;
  hasAlreadyRun$: Observable<boolean>;
  isNotGoingToRun$: Observable<boolean>;
  hasFailed$: Observable<boolean>;
  cancelled$: Observable<boolean>;
  triggerDate$: Observable<moment.Moment | undefined>;
  customMessage$: Observable<ICustomMessage | undefined>;

  @Input()
  set automation(automation: IRescheduleListData) {
    this.automation$.next(automation);
  }

  constructor() {
    this.status$ = this.automation$.pipe(
      map((data) => data.changes?.status ?? data.automation.status)
    );
    this.hasAlreadyRun$ = this.status$.pipe(
      map((status) => this._hasAlreadyRun(status))
    );
    this.isNotGoingToRun$ = this.status$.pipe(
      map((status) => this._isNotGoingToRun(status))
    );
    this.hasFailed$ = this.automation$.pipe(
      map((data) => data.automation.status === AutomationStatus.Failed)
    );
    this.triggerDate$ = this.automation$.pipe(
      switchMap((automation) => this._getTriggerDate(automation))
    );
    this.customMessage$ = this.automation$.pipe(
      map((data) => this._getCustomTriggerDateMessage(data))
    );
  }

  private _getCustomTriggerDateMessage(
    data: IRescheduleListData
  ): ICustomMessage | undefined {
    if (!data.newDate) {
      return;
    }
    const gracePeriod = moment().add(AUTOMATION_GRACE_PERIOD);
    const isBeforeNow = toMoment(data.newDate).isSameOrBefore(gracePeriod);
    if (!isBeforeNow) {
      return;
    }
    if (data.automation.data.timing.direction === TimingDirection.After) {
      return {
        message: 'Will run when appointment is complete',
        tooltip:
          'This automation would normally run earlier than currently scheduled.',
      };
    }
    return {
      message: 'Will run immediately',
      tooltip:
        'This automation would normally run earlier than currently scheduled, or is scheduled to run immediately',
    };
  }

  private _hasAlreadyRun(status: AutomationStatus): boolean {
    return [
      AutomationStatus.Completed,
      AutomationStatus.Skipped,
      AutomationStatus.Failed,
    ].includes(status);
  }

  private _isNotGoingToRun(status: AutomationStatus): boolean {
    return [AutomationStatus.Disabled, AutomationStatus.Cancelled].includes(
      status
    );
  }

  private async _getTriggerDate(
    data: IRescheduleListData
  ): Promise<moment.Moment | undefined> {
    const automation = data.automation;
    const newDate = data.newDate;

    const triggerDate = newDate ?? automation.triggerDate;
    if (!triggerDate || !automation.practiceRef) {
      return;
    }
    const timezone = await TimezoneResolver.fromPracticeRef(
      automation.practiceRef
    );
    return toMomentTz(triggerDate, timezone);
  }
}
