import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { PricingRuleComponent } from './pricing-rule/pricing-rule.component';
import { PricingScopeHelpComponent } from './pricing-scope-help/pricing-scope-help.component';
import { PricingTypeHelpComponent } from './pricing-type-help/pricing-type-help.component';
import { PricingUnitTypeHelpComponent } from './pricing-unit-type-help/pricing-unit-type-help.component';

@NgModule({
  declarations: [
    PricingRuleComponent,
    PricingScopeHelpComponent,
    PricingTypeHelpComponent,
    PricingUnitTypeHelpComponent,
  ],
  exports: [
    PricingRuleComponent,
    PricingScopeHelpComponent,
    PricingTypeHelpComponent,
    PricingUnitTypeHelpComponent,
  ],
  imports: [CommonModule, NgSharedModule, ReactiveFormsModule],
})
export class ComponentsModule {}
