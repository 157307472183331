<div class="layout-margin">
  <form [formGroup]="templateForm" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
    <pr-content-editor
      [required]="true"
      [menuEnabled]="true"
      formControlName="content"
     />
  </form>
  <pt-buttons-container>
    <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
    <button mat-stroked-button [routerLink]="['print']">
      <mat-icon>print</mat-icon> Print
    </button>
  </pt-buttons-container>
</div>
