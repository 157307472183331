import { isEmpty, mapValues, omitBy } from 'lodash';

export function createBreakoutMap<T>(
  keys: string[],
  compare: (item: T, key: string) => boolean
): Record<string, BreakoutComparisonFn<T>> {
  return keys.reduce(
    (all, key) => ({
      ...all,
      [key]: (item: T) => compare(item, key),
    }),
    {}
  );
}

type BreakoutComparisonFn<T> = (item: T) => boolean;

export function breakoutMixedArray<ArrayInterface>(
  breakoutMap: Record<string, BreakoutComparisonFn<ArrayInterface>>,
  items: ArrayInterface[]
): Record<string, ArrayInterface[]> {
  const obj = mapValues(
    breakoutMap,
    (comparisonFn: BreakoutComparisonFn<ArrayInterface>) =>
      items.filter((item) => comparisonFn(item))
  );
  return omitBy<ArrayInterface[]>(obj, (value) => isEmpty(value));
}
