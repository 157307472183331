import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, toFloat, type Timezone } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_CREDIT_NOTE_LINE_ITEM_RESOURCE_TYPE =
  'patientCreditNoteLineItems';

export const PATIENT_CREDIT_NOTE_LINE_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Credit Note Line Item List',
      description: '',
      idPrefix: PATIENT_CREDIT_NOTE_LINE_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientCreditNoteLineItem {
  id: number;
  amount: number; // 1700.0000
  isVoided: boolean;
  treatmentId: number;
  creditNoteId: number;
  sourceInvoiceId: number;
  taxAmount?: number;
}

export function isCorePracticePatientCreditNoteLineItem(
  item: unknown
): item is ICorePracticePatientCreditNoteLineItem {
  return TypeGuard.interface<ICorePracticePatientCreditNoteLineItem>({
    id: isNumber,
    amount: isNumber,
    isVoided: isBoolean,
    treatmentId: isNumber,
    creditNoteId: isNumber,
    sourceInvoiceId: isNumber,
    taxAmount: TypeGuard.nilOr(isNumber),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientCreditNoteLineItemTranslations {}

export interface ICorePracticePatientCreditNoteLineItemFilters {
  treatmentId: number;
  creditNoteId: number;
  sourceInvoiceId: number;
}

const PATIENT_CREDIT_NOTE_LINE_ITEM_SOURCE_QUERY = `
SELECT
  CreditNoteLineItemId AS id,
  convert_to_decimal(Amount) AS amount,
  convert_to_boolean(IsVoided) AS is_voided,
  TreatmentId AS treatment_id,
  CreditNoteId AS credit_note_id,
  SourceInvoiceId AS source_invoice_id,
  convert_to_decimal(TaxAmount) AS tax_amount
FROM tblCreditNoteLineItem
`;

export class PatientCreditNoteLineItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientCreditNoteLineItem,
  ICorePracticePatientCreditNoteLineItemTranslations,
  ICorePracticePatientCreditNoteLineItemFilters
> {
  sourceEntity = PATIENT_CREDIT_NOTE_LINE_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_CREDIT_NOTE_LINE_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_CREDIT_NOTE_LINE_ITEM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientCreditNoteLineItem;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'amount', 'taxAmount'),
  ]);

  translate(
    _data: ICorePracticePatientCreditNoteLineItem,
    _timezone: Timezone
  ): ICorePracticePatientCreditNoteLineItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientCreditNoteLineItem): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientCreditNoteLineItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientCreditNoteLineItem,
    _timezone: Timezone
  ): ICorePracticePatientCreditNoteLineItemFilters {
    return {
      treatmentId: data.treatmentId,
      creditNoteId: data.creditNoteId,
      sourceInvoiceId: data.sourceInvoiceId,
    };
  }
}
