import {
  type IAbsoluteSchedulingRules,
  type IRelativeSchedulingRules,
} from '@principle-theorem/principle-core/interfaces';
import { type Moment } from 'moment-timezone';

export interface ISurroundingAppointments {
  previous?: ISurroundingAppointmentData<IAbsoluteSchedulingRules>;
  next?: ISurroundingAppointmentData<IRelativeSchedulingRules>;
}

export interface ISurroundingAppointmentData<
  T = IAbsoluteSchedulingRules | IRelativeSchedulingRules
> {
  appointmentDate: Moment;
  rules: T;
}
