import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import { WithRef, type DocumentReference } from '@principle-theorem/shared';
import {
  IDixelOpenPatientCommand,
  IDixelToDeviceCommand,
} from '@principle-theorem/principle-bridge-core';
import {
  IPatient,
  IBridgeDevice,
} from '@principle-theorem/principle-core/interfaces';

@Injectable()
export class IDixelFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async openPatient(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: IDixelOpenPatientCommand = {
      type: IDixelToDeviceCommand.OpenPatient,
      data: { patient },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
