<mat-menu>
  <div class="gap-search layout-padding flex flex-col">
    <div
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    >
      <div class="flex flex-col">
        @if (gapStore.nextAvailableGap$ | async; as nextAvailable) {
          <button
            mat-stroked-button
            class="mat-small mb-4"
            (click)="setToNextAvailableGap(nextAvailable)"
          >
            <div class="flex gap-4">
              <div>Next Available Gap</div>
              <div
                class="flex items-center justify-center rounded-full bg-blue-600 px-2 text-xs text-white"
              >
                {{ nextAvailable.from | moment | amDateFormat: dateFormat }}
              </div>
            </div>
          </button>
        }

        <form [formGroup]="gapSearchForm" class="flex flex-col">
          <pr-gap-date-range-selector
            class="flex-1"
            (dateRangeChange)="gapSearchForm.updateDateRange($event)"
            #dateRangeSelector
          />

          <mat-form-field
            class="flex-1"
            formGroupName="dateRange"
            appearance="outline"
          >
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [rangePicker]="rangePicker"
              [min]="gapSearchForm.minDate"
              [max]="gapSearchForm.maxDate"
            >
              <input
                matStartDate
                placeholder="Start date"
                formControlName="from"
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="to"
                (dateChange)="dateRangeSelector.clear()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="rangePicker" />
            <mat-date-range-picker #rangePicker />
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-1">
            <mat-label>Select Practitioners</mat-label>
            <mat-select
              formControlName="practitioners"
              multiple
              [compareWith]="compareStaff"
            >
              @for (
                staffer of organisation.practicePractitioners$ | async;
                track staffer.ref.id
              ) {
                <mat-option [value]="staffer">
                  <div class="flex gap-2">
                    <pr-staffer-display [staffer]="staffer" />
                  </div>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <div formGroupName="timeRange">
            <span class="mat-caption">Time Range</span>
            <div class="flex gap-1">
              <div class="half-control-container">
                <pr-date-selector
                  appearance="outline"
                  placeholder="From"
                  formControlName="from"
                  [timeFrom]="schedulingFacade.openTime$ | async"
                  [timeTo]="schedulingFacade.closeTime$ | async"
                />
              </div>
              <div class="half-control-container">
                <pr-date-selector
                  appearance="outline"
                  placeholder="To"
                  formControlName="to"
                  [timeFrom]="schedulingFacade.openTime$ | async"
                  [timeTo]="schedulingFacade.closeTime$ | async"
                />
              </div>
            </div>
          </div>

          <div formGroupName="duration">
            <span class="mat-caption">Minimum Gap Duration</span>
            <div class="flex gap-1">
              <mat-form-field
                appearance="outline"
                class="half-control-container flex-1"
              >
                <mat-label>Hours</mat-label>
                <mat-select formControlName="hours">
                  <mat-option [value]="undefined">Clear</mat-option>
                  @for (hour of gapSearchForm.hours; track hour) {
                    <mat-option [value]="hour">
                      {{ hour }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                class="half-control-container flex-1"
              >
                <mat-label>Minutes</mat-label>
                <mat-select formControlName="minutes">
                  <mat-option [value]="undefined">Clear</mat-option>
                  @for (minute of gapSearchForm.minutes; track minute) {
                    <mat-option [value]="minute">
                      {{ minute }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
    <pt-buttons-container>
      <button mat-stroked-button>Close</button>
      <button mat-flat-button color="primary" (click)="submit()">
        Search Gaps
      </button>
    </pt-buttons-container>
  </div>
</mat-menu>
