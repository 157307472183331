import {
  CommunicationDirection,
  InteractionType,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import { SourceEntity } from '../../../source/source-entity';
import {
  ISODateTimeType,
  Timestamp,
  Timezone,
  TypeGuard,
  isISODateTimeType,
  toTimestamp,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import * as moment from 'moment-timezone';
import { PATIENT_INTERACTION_DESTINATION_ENTITY } from '../../../destination/entities/patient-interactions';

export const PATIENT_COMMUNICATION_RESOURCE_TYPE = 'patientCommunication';

export const PATIENT_COMMUNICATION_SOURCE_ENTITY = SourceEntity.init({
  metadata: {
    label: 'Patient Communication List',
    description: '',
    idPrefix: PATIENT_COMMUNICATION_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IOasisPatientCommunication {
  patientId: number;
  id: number;
  type: InteractionType.Email | InteractionType.Sms;
  userNumber: number;
  userId: string;
  direction: CommunicationDirection;
  messageSubject: string;
  messageBody?: string;
  branchNumber?: number;
  createdAt: ISODateTimeType;
}

function isOasisPatientCommunication(
  data: unknown
): data is IOasisPatientCommunication {
  return TypeGuard.interface<IOasisPatientCommunication>({
    patientId: isNumber,
    id: isNumber,
    type: isString,
    userNumber: isNumber,
    userId: TypeGuard.nilOr(isString),
    direction: TypeGuard.enumValue(CommunicationDirection),
    messageSubject: isString,
    messageBody: TypeGuard.nilOr(isString),
    branchNumber: TypeGuard.nilOr(isNumber),
    createdAt: isISODateTimeType,
  })(data);
}

export interface IOasisPatientCommunicationFilters {
  patientId: number;
}

export interface IOasisPatientCommuncationTranslations {
  createdAt: Timestamp;
}

const PATIENT_COMMUNICATION_SOURCE_QUERY = `
SELECT
  convert_to_integer(PATNUMBER) AS patient_id,
  SEQNUMBER AS id,
  CASE WHEN ENTRYTYPE = 3
    THEN 'sms'
    ELSE 'email'
    END AS type,
  CASE WHEN ENTRYTYPE = 3
    THEN 'outbound'
    ELSE 'inbound'
    END AS direction,
  USERNUMBER AS user_number,
  NULLIF(USERID, '') AS user_id,
  NULLIF(DETAILS1, '') AS message_subject,
  NULLIF(NULLIF(REPLACE(DETAILS2, '��', '\r\n'), ''), 'NULL') AS message_body,
  BRANCHNUMBER AS branch_number,
  CASE WHEN strpos('/', ENTRYDATE) > 0
    THEN to_char(
      convert_to_date(ENTRYDATE, 'DD/MM/YYYY')
      +
      ENTRYTIME::TIME,
      'YYYY-MM-DD HH:MI:SS'
    )
    ELSE to_char(
      convert_to_date(ENTRYDATE, 'YYYY-MM-DD')
      +
      ENTRYTIME::TIME,
      'YYYY-MM-DD HH:MI:SS'
    )
    END AS created_at
FROM PBCOMLOG
WHERE ENTRYTYPE != 2
  AND DETAILS1 != ''
  AND convert_to_integer(PATNUMBER) IS NOT NULL
`;

export class PatientCommunicationSourceEntity extends BaseSourceEntity<
  IOasisPatientCommunication,
  IOasisPatientCommuncationTranslations,
  IOasisPatientCommunicationFilters
> {
  sourceEntity = PATIENT_COMMUNICATION_SOURCE_ENTITY;
  entityResourceType = PATIENT_COMMUNICATION_RESOURCE_TYPE;
  sourceQuery = PATIENT_COMMUNICATION_SOURCE_QUERY;
  verifySourceFn = isOasisPatientCommunication;
  allowOffsetJob = true;
  override defaultOffsetSize = 50000;

  migrationDestinations = [PATIENT_INTERACTION_DESTINATION_ENTITY.metadata.key];

  translate(
    data: IOasisPatientCommunication,
    timezone: Timezone
  ): IOasisPatientCommuncationTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
    };
  }

  getSourceRecordId(data: IOasisPatientCommunication): string {
    return data.id.toString();
  }

  getSourceLabel(data: IOasisPatientCommunication): string {
    return `${data.id} - ${data.createdAt}`;
  }

  getFilterData(
    data: IOasisPatientCommunication,
    _timezone: Timezone
  ): IOasisPatientCommunicationFilters {
    return {
      patientId: data.patientId,
    };
  }
}
