import { rand } from '@ngneat/falso';
import { AccountCreditType } from '@principle-theorem/principle-core/interfaces';
import { IAccountCreditDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import { AccountCreditMock } from '@principle-theorem/principle-core';
import {
  MOCKED_STAFF,
  stafferDimensionNamedDoc,
} from './staffer-dimension.mock';

const accountCredit = MockGenerator.generate(AccountCreditMock);

export class AccountCreditDimensionMock
  extends BigQueryFirestoreModelMock
  implements IAccountCreditDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  description = accountCredit.description;
  amount = accountCredit.amount;
  type = AccountCreditType.Deposit;
  reference = accountCredit.reference;
  invoice = MockGenerator.generate(SerialisedDocumentReferenceMock);
  depositUid = accountCredit.depositUid;
  reservedFor = {
    practitioner: stafferDimensionNamedDoc(rand(MOCKED_STAFF)),
    treatment: [MockGenerator.generate(BigQueryNamedDocumentMock)],
  };
  used = accountCredit.used;
  issued = toSerialisedTimestamp(accountCredit.issued);
}
