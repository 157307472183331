import {
  type IBrand,
  type ICandidateCalendarEvent,
  type IGap,
  type IPractice,
  type IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Brand,
  type IWaitListCandidate,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import { toTimePeriod, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { WaitListCandidateList } from './components/wait-list/wait-list-candidate-list';

export class GapShortListManager {
  gapCandidates$: Observable<WithRef<ICandidateCalendarEvent>[]>;
  waitList$: Observable<IWaitListCandidate[]>;

  constructor(
    gap$: Observable<IGap>,
    practitioner$: Observable<WithRef<IStaffer>>,
    private _brand$: Observable<WithRef<IBrand>>,
    practice$: Observable<WithRef<IPractice>>
  ) {
    const combined$: Observable<[IGap, WithRef<IPractice>, WithRef<IStaffer>]> =
      combineLatest([gap$, practice$, practitioner$]);

    this.gapCandidates$ = combined$.pipe(
      switchMap(([gap, _practice, practitioner]) =>
        this.getGapCandidates$(practitioner, gap)
      ),
      startWith([])
    );

    this.waitList$ = combined$.pipe(
      switchMap(([gap, practice, _practitioner]) => {
        const waitlistCandidateList: WaitListCandidateList =
          new WaitListCandidateList(practice);
        return waitlistCandidateList.getCandidates$(gap);
      }),
      startWith([])
    );
  }

  getGapCandidates$(
    practitioner: WithRef<IStaffer>,
    gap: IGap
  ): Observable<WithRef<ICandidateCalendarEvent>[]> {
    return this._brand$.pipe(
      switchMap((brand) => {
        const participant = {
          ...stafferToNamedDoc(practitioner),
          type: ParticipantType.Staffer,
        };
        const range = toTimePeriod(gap.event.from, gap.event.to);
        return Brand.getGapCandidates$(
          brand,
          range,
          [gap.event.practice.ref],
          [participant]
        );
      })
    );
  }
}
