<mat-list-item *ngIf="treatmentTemplate$ | async as treatmentTemplate">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <div fxFlex>
      {{ treatmentTemplate.name }}
    </div>

    <pt-buttons-container>
      <div>
        <button
          mat-icon-button
          (click)="editTemplate.emit(treatmentTemplate)"
          ptIntercomTarget="treatment-template.edit-button"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="deleteTemplate.emit(treatmentTemplate)"
          ptIntercomTarget="treatment-template.delete-button"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </pt-buttons-container>
  </div>
</mat-list-item>
