import { SterilisationRecord } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  IDestinationEntityRecord,
  ITranslationMap,
  type IDestinationEntity,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientSterilisationDestinationEntity,
  ISterilisationRecordBaseJobData,
  ISterilisationRecordMigrationData,
} from '../../../destination/entities/sterilisation-records';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { SterilisationRecordSourceEntity } from '../../source/entities/sterilisation-records';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

interface IPatientSterilisationJobData
  extends ISterilisationRecordBaseJobData<ICorePracticePatient> {
  practices: WithRef<ITranslationMap<IPractice>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientSterilisationDestinationEntity extends BasePatientSterilisationDestinationEntity<
  ICorePracticePatient,
  IPatientSterilisationJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    sterilisationRecords: new SterilisationRecordSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practices: new CorePracticePracticeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientSterilisationJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$, practices$]),
    ]).pipe(
      map(([sourcePatients, [staff, practices]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          staff,
          practices,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientSterilisationJobData
  ): Promise<
    | ISterilisationRecordMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient ${sourcePatientId}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sterilisationRecords = await asyncForEach(
      await this.sourceEntities.sterilisationRecords.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      ),
      async (record) => {
        const practiceId = record.data.data.locationId.toString();
        const practiceMap = data.practices.find(
          (searchPractice) =>
            searchPractice.sourceIdentifier === practiceId.toString()
        );

        if (!practiceMap?.destinationIdentifier) {
          throw new Error(`Can't find practice with id ${practiceId}`);
        }
        const practice = await Firestore.getDoc(
          practiceMap?.destinationIdentifier
        );

        return {
          ...SterilisationRecord.init({
            data: record.data.data.barcode,
            practice: toNamedDocument(practice),
            patient: toNamedDocument(patient),
          }),
          sourceIdentifier: record.record.uid,
          createdAt: record.data.translations.cycleDateUtc ?? toTimestamp(),
        };
      }
    );

    return {
      sterilisationRecords,
    };
  }
}
