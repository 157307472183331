import { Pipe, type PipeTransform } from '@angular/core';
import {
  TimingDirection,
  type IAutomationTiming,
} from '@principle-theorem/principle-core/interfaces';
import { formatTimeStringDisplay } from '@principle-theorem/shared';

@Pipe({
    name: 'prTimingDisplay',
    standalone: false
})
export class TimingDisplayPipe implements PipeTransform {
  transform(timing: IAutomationTiming): string {
    if (timing.direction === TimingDirection.Immediately) {
      return 'Immediately';
    }

    const timingUnit = this.getUnit(timing);
    return `${timing.amount} ${timingUnit} ${timing.direction}${
      timing.time ? ' at ' + formatTimeStringDisplay(timing.time) : ''
    }`;
  }

  getUnit(timing: IAutomationTiming): string {
    const isPlural: boolean =
      timing.unit.lastIndexOf('s') === timing.unit.length - 1;
    if (isPlural) {
      return timing.amount === 1
        ? timing.unit.substring(0, timing.unit.lastIndexOf('s'))
        : timing.unit;
    }
    return timing.amount === 1 ? timing.unit : `${timing.unit}s`;
  }
}
