import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  AreaSummary,
  AreaSummaryFactory,
  ChartedItemTotalCalculator,
  ChartedServiceExclusiveGroup,
  ChartedServiceSmartGroup,
  ClinicalChart,
  Patient,
  isSameToothRef,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ChartedItemType,
  IAppointment,
  IChartedItem,
  IChartedItemDisplay,
  IClinicalChart,
  IPatient,
  IPricedServiceCodeEntry,
  IStaffer,
  ITooth,
  ITreatmentPlan,
  PatientRelationshipType,
  ServiceCodeGroupType,
  isChartedCondition,
  isChartedTreatment,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  Timestamp,
  WithRef,
  multiSwitchMap,
  snapshot,
  sortTimestamp,
  uid,
} from '@principle-theorem/shared';
import { flatten, groupBy, uniqBy } from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ITreatmentPlanStepPair } from '../treatment-plans-consolidated/treatment-plans-consolidated.store';

type LoadToothHistory = Observable<
  [ITooth[], ITreatmentPlanStepPair[], WithRef<IClinicalChart>[]]
>;

export enum RecordType {
  Condition = 'condition',
  Treatment = 'treatment',
}

export enum TreatmentStatus {
  Complete = 'complete', // the treatment has been completed - has an assosiated appointment in a completed state
  Planned = 'planned', // the treatment has been planned - the item is part of a treatment plan
  Flagged = 'flagged', // applies to a treatment or multi-treatment that has been flagged
}

export interface IToothHistoryState {
  records: IToothHistoryRecord[];
  charts: WithRef<IClinicalChart>[];
  selectedTeeth: ITooth[];
  loading: boolean;
  filters: IToothHistoryFilters;
}

export interface IToothHistoryFilters {
  from: Timestamp | undefined;
  to: Timestamp | undefined;
  type: RecordType[];
  treatmentStatus: TreatmentStatus[];
  searchTerm: string;
}

export interface IToothHistoryRecord {
  uid: string;
  chartedAt: Timestamp;
  chartedBy: INamedDocument<IStaffer>;
  surfaces: string;
  type: RecordType;
  item: IChartedItem;
  serviceCodes: IPricedServiceCodeEntry[];
  treatmentDate?: Timestamp | AppointmentStatus.Unscheduled;
  treatedBy?: INamedDocument<IStaffer>;
  treatmentStatus?: TreatmentStatus;
  display?: IChartedItemDisplay;
  fee?: number;
  appointment?: WithRef<IAppointment>;
  treatmentPlan?: WithRef<ITreatmentPlan>;
}

const initialState: IToothHistoryState = {
  selectedTeeth: [],
  charts: [],
  records: [],
  loading: false,
  filters: {
    from: undefined,
    to: undefined,
    type: [],
    treatmentStatus: [],
    searchTerm: '',
  },
};

const SIMPLIFIED_CHARTED_ITEM_TYPE_MAP: Record<ChartedItemType, RecordType> = {
  [ChartedItemType.ChartedCondition]: RecordType.Condition,
  [ChartedItemType.ChartedTreatment]: RecordType.Treatment,
  [ChartedItemType.ChartedTreatmentGroup]: RecordType.Treatment,
  [ChartedItemType.TreatmentStep]: RecordType.Treatment,
  [ChartedItemType.Treatment]: RecordType.Treatment,
  [ChartedItemType.ChartedMultiStepTreatment]: RecordType.Treatment,
  [ChartedItemType.Generic]: RecordType.Treatment,
};

@Injectable()
export class ToothHistoryStore extends ComponentStore<IToothHistoryState> {
  private _globalStore = inject(GlobalStoreService);

  readonly charts$ = this.select((state) => state.charts);
  readonly selectedTeeth$ = this.select((state) => state.selectedTeeth);
  readonly records$ = this.select((state) => state.records);
  readonly loading$ = this.select((state) => state.loading);
  readonly filters$ = this.select((state) => state.filters);

  readonly filteredRecords$ = this.select(
    this.records$,
    this.filters$,
    (records, filters) =>
      records.filter(
        (record) =>
          this._matchesSearchTerm(record, filters.searchTerm) &&
          this._matchesStatus(record, filters.treatmentStatus) &&
          this._matchesType(record, filters.type) &&
          this._matchesDateRange(record, filters.from, filters.to)
      )
  );

  readonly setTeeth = this.updater((state, selectedTeeth: ITooth[]) => ({
    ...state,
    selectedTeeth,
  }));

  readonly setCharts = this.updater(
    (state, charts: WithRef<IClinicalChart>[]) => ({
      ...state,
      charts,
    })
  );

  readonly setRecords = this.updater(
    (state, records: IToothHistoryRecord[]) => ({
      ...state,
      records,
    })
  );

  readonly updateFilters = this.updater(
    (state, filters: Partial<IToothHistoryFilters>) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    })
  );

  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading,
  }));

  readonly loadCharts = this.effect(
    (patient$: Observable<WithRef<IPatient>>) => {
      return patient$.pipe(
        switchMap((patient) => this._resolveCharts$(patient)),
        tap((conditions) => this.setCharts(conditions)),
        catchError(() => {
          // eslint-disable-next-line no-console
          console.error('Failed To Load Charts');
          return EMPTY;
        })
      );
    }
  );

  readonly loadToothHistory = this.effect((params: LoadToothHistory) => {
    return params.pipe(
      tap(() => this.setLoading(true)),
      map((data) => this._buildToothHistory(...data)),
      multiSwitchMap((records) => this._resolveDisplay(records)),
      tap((records) => {
        this.setRecords(records);
        this.setLoading(false);
      }),
      catchError(() => {
        // eslint-disable-next-line no-console
        console.error('Failed To Build Records');
        return EMPTY;
      })
    );
  });

  constructor() {
    super(initialState);
  }

  private _buildToothHistory(
    teeth: ITooth[],
    consolidatedPlan: ITreatmentPlanStepPair[],
    charts: WithRef<IClinicalChart>[]
  ): IToothHistoryRecord[] {
    const chartedConditions = charts.flatMap(({ conditions }) => conditions);
    const chartedTreatments = charts.flatMap(({ flaggedTreatment }) => [
      ...flaggedTreatment.treatments,
      ...flaggedTreatment.multiTreatments,
    ]);

    const filteredConditions = this._filterByTooth(chartedConditions, teeth);
    const filteredTreatments = this._filterByTooth(chartedTreatments, teeth);
    const conditions = this._buildRecords(filteredConditions);
    const flaggedTreatments = this._buildRecords(filteredTreatments);
    const treatments = this._buildTreatmentRecords(teeth, consolidatedPlan);

    return [...conditions, ...flaggedTreatments, ...treatments].sort((a, b) =>
      sortTimestamp(a.chartedAt, b.chartedAt)
    );
  }

  private async _resolveDisplay(
    record: IToothHistoryRecord
  ): Promise<IToothHistoryRecord> {
    if (isChartedCondition(record.item)) {
      const config = await snapshot(
        this._globalStore.getConditionConfiguration$(record.item.config.ref)
      );

      return {
        ...record,
        display: config?.display,
      };
    }

    if (isChartedTreatment(record.item)) {
      const config = await Firestore.getDoc(record.item.config.ref);

      return {
        ...record,
        display: config?.display,
      };
    }

    return {
      ...record,
      display: undefined,
    };
  }

  private _resolveCharts$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IClinicalChart>[]> {
    return Patient.withPatientRelationships$(
      patient,
      [PatientRelationshipType.DuplicatePatient],
      (patientReffable) => ClinicalChart.all$(patientReffable)
    );
  }

  private _buildTreatmentRecords(
    teeth: ITooth[],
    consolidatedPlan: ITreatmentPlanStepPair[]
  ): IToothHistoryRecord[] {
    return consolidatedPlan.flatMap((entity) => {
      const filteredTreatments = this._filterByTooth(
        entity.treatmentStep.treatments,
        teeth
      );
      return this._buildRecords(filteredTreatments, entity);
    });
  }

  // RECORD BUILDING
  private _buildRecords(
    chartedItems: IChartedItem[],
    consolidatedPlan?: ITreatmentPlanStepPair
  ): IToothHistoryRecord[] {
    return chartedItems.flatMap((item) => {
      const groupedItems = groupBy(
        item.chartedSurfaces,
        (surface) => `${surface.chartedAt.seconds}_${surface.chartedBy.ref.id}`
      );

      return Object.values(groupedItems).map((surfaces) => ({
        uid: uid(),
        item,
        chartedAt: surfaces[0].chartedAt,
        chartedBy: surfaces[0].chartedBy,
        type: SIMPLIFIED_CHARTED_ITEM_TYPE_MAP[item.type],
        surfaces: new AreaSummaryFactory()
          .create(surfaces)
          .map(AreaSummary.asCompact)
          .join(', '),
        appointment: consolidatedPlan?.appointment,
        treatmentPlan: consolidatedPlan?.treatmentPlan,
        treatmentDate: this._getTreatmentDate(item, consolidatedPlan),
        treatedBy: this._getTreatedBy(item, consolidatedPlan),
        treatmentStatus: this._getTreatmentStatus(item, consolidatedPlan),
        fee: this._getFee(item),
        serviceCodes: this._getServiceCodes(item),
      }));
    });
  }

  private _getTreatmentDate(
    item: IChartedItem,
    consolidatedPlan?: ITreatmentPlanStepPair
  ): Timestamp | AppointmentStatus.Unscheduled | undefined {
    const appointment = consolidatedPlan?.appointment;

    if (item.resolvedAt) {
      return item.resolvedAt;
    }

    if (appointment) {
      return appointment.event?.from || AppointmentStatus.Unscheduled;
    }

    return undefined;
  }

  private _getTreatedBy(
    item: IChartedItem,
    consolidatedPlan?: ITreatmentPlanStepPair
  ): INamedDocument<IStaffer> | undefined {
    return item.resolvedBy ?? consolidatedPlan?.appointment?.practitioner;
  }

  private _getTreatmentStatus(
    item: IChartedItem,
    consolidatedPlan?: ITreatmentPlanStepPair
  ): TreatmentStatus | undefined {
    if (isChartedCondition(item)) {
      return;
    }

    if (!consolidatedPlan) {
      return TreatmentStatus.Flagged;
    }

    const appointment = consolidatedPlan.appointment;

    if (!appointment) {
      return TreatmentStatus.Planned;
    }

    const isComplete = Appointment.isComplete(appointment);
    const isCheckingOut = Appointment.isCheckingOut(appointment);

    return isComplete || isCheckingOut
      ? TreatmentStatus.Complete
      : TreatmentStatus.Planned;
  }

  private _getFee(item: IChartedItem): number | undefined {
    return !isChartedCondition(item)
      ? new ChartedItemTotalCalculator().calc(item)
      : undefined;
  }

  private _getServiceCodes(item: IChartedItem): IPricedServiceCodeEntry[] {
    if (!isChartedTreatment(item)) {
      return [];
    }

    const smartGroupItems = item.serviceCodeSmartGroups.map((group) => {
      const selected = ChartedServiceSmartGroup.getSelected(group);
      return selected ? [selected] : group.serviceCodes;
    });
    const groupItems = item.serviceCodeGroups.map((group) => {
      if (group.type !== ServiceCodeGroupType.Exclusive) {
        return [];
      }
      const selected = ChartedServiceExclusiveGroup.getSelected(group);
      return selected ? [selected] : group.serviceCodes;
    });

    return [
      ...flatten(smartGroupItems),
      ...flatten(groupItems),
      ...item.serviceCodes,
    ].filter((serviceCode) => serviceCode.quantity > 0);
  }

  private _filterByTooth(
    chartedItem: IChartedItem[],
    teeth: ITooth[]
  ): IChartedItem[] {
    return uniqBy(
      chartedItem.map((item) => ({
        ...item,
        chartedSurfaces: item.chartedSurfaces.filter((surface) =>
          teeth.some(
            (tooth) =>
              surface.chartedRef.tooth &&
              isSameToothRef(surface.chartedRef.tooth, tooth.toothRef)
          )
        ),
      })),
      'uuid'
    );
  }

  //  FILTERS
  private _matchesSearchTerm(
    record: IToothHistoryRecord,
    searchTerm: string
  ): boolean {
    if (!searchTerm) {
      return true;
    }

    const terms = [
      record.item.config.name,
      record.surfaces,
      record.serviceCodes.map(({ code }) => code).join(' '),
    ];

    return terms.some(
      (term) =>
        term && term.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  private _matchesStatus(
    record: IToothHistoryRecord,
    statusFilters: TreatmentStatus[]
  ): boolean {
    if (statusFilters.length === 0) {
      return true;
    }
    if (!record.treatmentStatus) {
      return false;
    }

    return statusFilters.includes(record.treatmentStatus);
  }

  private _matchesType(
    record: IToothHistoryRecord,
    typeFilters: RecordType[]
  ): boolean {
    if (typeFilters.length === 0) {
      return true;
    }
    if (!record.type) {
      return false;
    }

    return typeFilters.includes(record.type);
  }

  private _matchesDateRange(
    record: IToothHistoryRecord,
    from: Timestamp | undefined,
    to: Timestamp | undefined
  ): boolean {
    if (!from && !to) {
      return true;
    }

    const chartedAt = record.chartedAt;
    const afterFrom = !from || chartedAt >= from;
    const beforeTo = !to || chartedAt <= to;
    return afterFrom && beforeTo;
  }
}
