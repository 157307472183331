import { type CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type ITreatmentPlan,
  type ITreatmentStep,
  TreatmentStepStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { slice } from 'lodash';
import { DragDropGroup, type IDragDropNode } from './drag-drop-group';

export type StepParent = IChartedMultiStepTreatment | WithRef<ITreatmentPlan>;
export type StepItem = WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep;
export type IStepDragDropNode = IDragDropNode<StepParent, StepItem>;

export class StepDragDropGroup extends DragDropGroup<StepParent, StepItem> {
  private _immovables: TreatmentStepStatus[] = [
    TreatmentStepStatus.Complete,
    TreatmentStepStatus.Current,
  ];

  override drop(event: CdkDragDrop<IStepDragDropNode>): void {
    if (
      this._targetIsImmovable(event) ||
      this._targetLocationIsBeforeImmovable(event)
    ) {
      return;
    }
    super.drop(event);
  }

  private _targetLocationIsBeforeImmovable(
    event: CdkDragDrop<IStepDragDropNode>
  ): boolean {
    return slice(event.container.data.items, event.currentIndex).some(
      (followingStep) => this._immovables.includes(followingStep.status)
    );
  }

  private _targetIsImmovable(event: CdkDragDrop<IStepDragDropNode>): boolean {
    const targetItem = event.item.data as StepItem;
    return this._immovables.includes(targetItem.status);
  }
}
