import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../core/reporting-core.module';
import { ReportingComponentsModule } from '../reporting-components/reporting-components.module';
import { OutstandingInvoicesReportingModule } from './outstanding-invoices-reporting/outstanding-invoices-reporting.module';
import { PendingPaymentsModule } from './pending-payments/pending-payments.module';
import { PractitionerIncomeReportModule } from './practitioner-income/practitioner-income-report.module';
import { ReconciliationReportModule } from './reconciliation-report/reconciliation-report.module';
import { PatientsReportingModule } from './patients-reporting/patients-reporting.module';
import { AccountCreditsReportModule } from './account-credits/account-credits-report.module';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    NgPrincipleSharedModule,
    PractitionerIncomeReportModule,
    PatientsReportingModule,
    ReconciliationReportModule,
    OutstandingInvoicesReportingModule,
    PendingPaymentsModule,
    AccountCreditsReportModule,
  ],
  declarations: [],
  exports: [],
})
export class LegacyReportsModule {}
