import {
  isObject,
  type IFirestoreModel,
  type Timezone,
  DocumentReference,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IConfirmAppointmentInfo } from './appointment/patient-confirm-appointment';
import {
  type IBasePatient,
  type IPatientContactDetails,
} from './patient/patient';
import { ReportingJobType } from './reporting-job';
import { ISchedulingEventReason as ISchedulingReasonConfiguration } from './appointment/cancellation';
import { ITreatmentTemplate } from './clinical-charting/treatment/treatment-template';

export enum BrandCollection {
  Labs = 'labs',
  Staff = 'staff',
  Teams = 'teams',
  Chats = 'chats',
  PrescriptionItems = 'prescriptionItems',
  Products = 'products',
  Patients = 'patients',
  Contacts = 'contacts',
  Snippets = 'snippets',
  Practices = 'practices',
  Templates = 'templates',
  FeeSchedules = 'feeSchedules',
  CalendarEvents = 'calendarEvents',
  SchedulingAlerts = 'schedulingAlerts',
  TreatmentTemplates = 'treatmentTemplates',
  CalendarEventTags = 'calendarEventTags',
  AppointmentTags = 'appointmentTags',
  PatientTags = 'patientTags',
  PatientNoteTags = 'patientNoteTags',
  ConditionConfigurations = 'conditionConfigurations',
  TreatmentConfigurations = 'treatmentConfigurations',
  TreatmentCategories = 'treatmentCategories',
  MultiTreatmentConfigurations = 'multiTreatmentConfigurations',
  ManualTransactionTypes = 'manualTransactionTypes',
  CustomFormConfigurations = 'customFormConfigurations',
  CalendarEventSchedules = 'calendarEventSchedules',
  ReferralSources = 'referralSources',
  CancellationReasons = 'cancellationReasons',
  AutomationConfigurations = 'automationConfigurations',
  PatientPhoneNumbers = 'patientPhoneNumbers',
  SMSConversations = 'smsConversations',
  SMSMessages = 'smsMessages',
  CustomReports = 'customReports',
  ReportingJobs = 'reportingJobs',
  PatientMetadataConfigurations = 'patientMetadataConfigurations',
  PatientMetadataDisplays = 'patientMetadataDisplays',
  SterilisationMachines = 'sterilisationMachines',
  SterilisationPacks = 'sterilisationPacks',
  SterilisationCycleTypes = 'sterilisationCycleTypes',
  SterilisationEquipment = 'sterilisationEquipment',
}

export type PatientOptionalField = keyof Pick<
  IBasePatient & IPatientContactDetails,
  'referrer' | 'contactNumbers' | 'email' | 'dateOfBirth'
>;

export type AppointmentConfirmationOptionalInfo = keyof Pick<
  IConfirmAppointmentInfo,
  'treatment' | 'duration' | 'practitioner'
>;

export interface ITrackingCodes {
  googleTagManager?: string[];
  googleAnalytics4?: string[];
  pixel?: string;
  hubSpot?: string;
  crossDomainUrls?: string[];
}

export interface IOnlineBookingSettings {
  restrictToPreBlocks?: boolean;
  requestSuccessMessage?: string;
  treatmentTemplateOrder?: DocumentReference<ITreatmentTemplate>[];
  trackingCodes?: ITrackingCodes;
  redirectUrl?: string;
}

export const OPTIONAL_PATIENT_FIELDS: PatientOptionalField[] = [
  'referrer',
  'contactNumbers',
  'email',
  'dateOfBirth',
];

export const OPTIONAL_APPOINTMENT_CONFIRMATION_INFO: AppointmentConfirmationOptionalInfo[] =
  ['treatment', 'duration', 'practitioner'];

export const ADD_TO_WAITLIST_BY_DEFAULT = true;
export const RESTRICT_TO_PRE_BLOCKS_BY_DEFAULT = false;
export const DEFAULT_SUCCESS_MESSAGE = `This is NOT an appointment confirmation. We will be in contact with you soon to confirm the booking.`;

export interface IBrandSettings {
  timezone: Timezone;
  scheduling?: {
    defaultWaitlistSettingsOn?: boolean;
  };
  onlineBookings?: IOnlineBookingSettings;
  patient?: {
    requiredFields?: PatientOptionalField[];
  };
  patientDetailsForm?: {
    requiredFields?: PatientOptionalField[];
  };
  optionalDisplayInformation?: {
    appointmentConfirmationInfo?: AppointmentConfirmationOptionalInfo[];
  };
  treatmentPlanning?: {
    autoCompletePlanAfterLastStep?: boolean;
    includeCompletedTreatmentPlansInTimeline?: boolean;
  };
  availableSystemReports?: ReportingJobType[];
  displayMigratedChartFilter?: boolean;
}

export interface IBrand extends IFirestoreModel {
  name: string;
  slug: string;
  settings: IBrandSettings;
  defaultSchedulingEventReasonOrder: DocumentReference<ISchedulingReasonConfiguration>[];
  logoUrl?: string;
}

export function isBrand(data: unknown): data is IBrand {
  return (
    isObject(data) &&
    isString(data.name) &&
    isString(data.slug) &&
    isBrandSettings(data.settings)
  );
}

export function isBrandSettings(data: unknown): data is IBrandSettings {
  return isObject(data) && isString(data.timezone);
}
