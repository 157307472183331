import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pt-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent {
  @Input() padding = true;
}
