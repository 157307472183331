import {
  type IFormSchema,
  FormSchemaPropertyType,
  type IChildFlexLayoutElement,
  FormLayoutElementType,
  type IParentFlexLayout,
  type IFormLayoutElement,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, prefixLayoutItems } from '../custom-form-helpers';

export const NOT_APPLICABLE_OPTION = 'N/A';

const CONDITIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    bleeding: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Abnormal / excessive bleeding',
    },
    anaemia: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Anaemia',
    },
    arthritis: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Arthritis',
    },
    heartCondition: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Artificial heart valve / valve defect',
    },
    asthma: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Asthma',
    },
    bloodPressure: {
      type: FormSchemaPropertyType.String,
      title: 'Blood pressure',
      enum: [NOT_APPLICABLE_OPTION, 'High', 'Low'],
      default: NOT_APPLICABLE_OPTION,
      required: true,
    },
    biphosphonate: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Biphosphonate medication',
    },
    cardiacSurgery: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Cardiac surgery / pacemaker',
    },
    diabetes: {
      type: FormSchemaPropertyType.String,
      title: 'Diabetes',
      enum: [NOT_APPLICABLE_OPTION, 'Type I', 'Type II'],
      default: NOT_APPLICABLE_OPTION,
      required: true,
    },
    epilepsy: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Epilepsy',
    },
    hasGastrointestinalDisorder: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have a gastrointestinal disorder',
    },
    gastrointestinalDisorder: {
      type: FormSchemaPropertyType.String,
      title: 'Gastrointestinal disorder',
    },
    immuneDeficiency: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Immune deficiency',
    },
    hasJointReplacement: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have had a joint replacement',
    },
    replacedJoint: {
      type: FormSchemaPropertyType.String,
      title: 'Joint replaced',
    },
    hepatitis: {
      type: FormSchemaPropertyType.String,
      title: 'Hepatitis',
      enum: [NOT_APPLICABLE_OPTION, 'A', 'B', 'C', 'D'],
      default: NOT_APPLICABLE_OPTION,
      required: true,
    },
    hiv: {
      type: FormSchemaPropertyType.Boolean,
      title: 'HIV',
    },
    isSmoker: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Past or Current smoker',
    },
    amountSmoked: {
      type: FormSchemaPropertyType.String,
      title: 'Approx. amount currently smoked',
    },
    kidneyDisease: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Kidney / Liver disease',
    },
    heartBurn: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Reflux / Heart Burn',
    },
    rheumaticFever: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Rheumatic Fever',
    },
    sleepApnoea: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Snoring or Sleep apnoea',
    },
    steroidMedication: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Steroid medication',
    },
    stroke: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Stroke',
    },
    thyroidDisorder: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Thyroid disorder',
    },
    other: {
      type: FormSchemaPropertyType.String,
      title: 'Any other medical conditions or major surgeries?',
    },
    pregnancyDueDate: {
      type: FormSchemaPropertyType.String,
      title: 'Due Date',
    },
    hasCancer: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have cancer',
    },
    cancerType: {
      type: FormSchemaPropertyType.String,
      title: 'Cancer type',
    },
    cancerTreatmentChemotherapy: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have had/ will have Chemotherapy',
    },
    cancerTreatmentRadiotherapy: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have had/ will have Radiotherapy',
    },
    isPregnant: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I am pregnant',
    },
    hasPsychiatricDisorder: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I have a psychiatric disorder',
    },
    psychiatricDisorderType: {
      type: FormSchemaPropertyType.String,
      title: 'Psychiatric disorder',
    },
  },
};

export const CONDITIONS_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([
        { key: 'bleeding' },
        { key: 'anaemia' },
        { key: 'arthritis' },
      ]),
      formFlexRow([
        { key: 'heartCondition' },
        { key: 'asthma' },
        { key: 'biphosphonate' },
      ]),
      formFlexRow([
        { key: 'cardiacSurgery' },
        { key: 'epilepsy' },
        { key: 'immuneDeficiency' },
      ]),
      formFlexRow([
        { key: 'hiv' },
        { key: 'kidneyDisease' },
        { key: 'heartBurn' },
      ]),
      formFlexRow([
        { key: 'rheumaticFever' },
        { key: 'sleepApnoea' },
        { key: 'steroidMedication' },
      ]),
      formFlexRow([{ key: 'stroke' }, { key: 'thyroidDisorder' }], {
        options: { fxFlex: '33.3' },
      }),
      formFlexRow([
        { key: 'hasGastrointestinalDisorder' },
        { key: 'gastrointestinalDisorder' },
      ]),
      formFlexRow([{ key: 'isSmoker' }, { key: 'amountSmoked' }]),
      formFlexRow([{ key: 'hasJointReplacement' }, { key: 'replacedJoint' }]),
      formFlexRow([
        { key: 'hasPsychiatricDisorder' },
        { key: 'psychiatricDisorderType' },
      ]),
      formFlexRow([{ key: 'hasCancer' }, { key: 'cancerType' }]),
      formFlexRow([
        { key: 'cancerTreatmentChemotherapy' },
        { key: 'cancerTreatmentRadiotherapy' },
      ]),
      formFlexRow([
        { key: 'isPregnant' },
        { key: 'pregnancyDueDate', type: FormLayoutElementType.Date },
      ]),
      formFlexRow([{ key: 'bloodPressure' }, { key: 'diabetes' }]),
      formFlexRow([{ key: 'hepatitis' }, { key: 'other' }]),
    ],
  },
];

const ALLERGIES_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    penicillin: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Penicillin',
    },
    aspirin: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Aspirin',
    },
    codeine: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Codeine',
    },
    chlorhexidine: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Chlorhexidine',
    },
    iodine: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Iodine',
    },
    latex: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Latex',
    },
    other: {
      type: FormSchemaPropertyType.String,
      title: 'Other',
    },
  },
};

const ALLERGIES_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Div,
    items: [
      formFlexRow([{ key: 'penicillin' }, { key: 'aspirin' }]),
      formFlexRow([{ key: 'codeine' }, { key: 'chlorhexidine' }]),
      formFlexRow([{ key: 'iodine' }, { key: 'latex' }]),
      { key: 'other' },
    ],
  },
];

const EMERGENCY_CONTACT_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    name: {
      type: FormSchemaPropertyType.String,
      title: 'Name',
    },
    phone: {
      type: FormSchemaPropertyType.String,
      title: 'Phone',
    },
  },
};

const EMERGENCY_CONTACT_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Div,
    items: [formFlexRow([{ key: 'name' }, { key: 'phone' }])],
  },
];

export const GENERAL_MEDICAL_HISTORY_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    conditions: CONDITIONS_FORM_SCHEMA,
    medications: {
      type: FormSchemaPropertyType.String,
      title: 'Medications?',
    },
    allergies: ALLERGIES_FORM_SCHEMA,
    emergencyContact: EMERGENCY_CONTACT_FORM_SCHEMA,
  },
};

export const GENERAL_MEDICAL_HISTORY_FORM_LAYOUT: (
  | IParentFlexLayout
  | IFormLayoutElement
)[] = [
  {
    key: 'conditions',
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    notitle: true,
    items: prefixLayoutItems(CONDITIONS_FORM_LAYOUT, 'conditions'),
  },
  {
    key: 'medications',
    type: FormLayoutElementType.TextArea,
    options: { flex: '1 1 100%' },
    placeholder: '(Including Supplements)',
  },
  {
    key: 'allergies',
    title: 'Allergies?',
    type: FormLayoutElementType.Div,
    options: { flex: '1 1 100%' },
    items: prefixLayoutItems(ALLERGIES_FORM_LAYOUT, 'allergies'),
  },
  {
    key: 'emergencyContact',
    title: 'Emergency Contact',
    type: FormLayoutElementType.Div,
    options: { flex: '1 1 100%' },
    items: prefixLayoutItems(EMERGENCY_CONTACT_FORM_LAYOUT, 'emergencyContact'),
  },
];
