import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  ICalendarEvent,
  ICandidateCalendarEvent,
  IEvent,
  IParticipant,
  IPatient,
  IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  WithRef,
  initFirestoreModel,
  isSameRef,
  isWithRef,
  toTimestamp,
} from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';

export class CalendarEvent {
  static init(overrides: AtLeast<ICalendarEvent, 'event'>): ICalendarEvent {
    return {
      locked: false,
      isBlocking: true,
      title: [],
      notes: initVersionedSchema(),
      eventHistory: [],
      eventTags: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static includesStaffer(
    calendarEvent: ICalendarEvent,
    stafferRef: DocumentReference<IStaffer>
  ): boolean {
    return calendarEvent.event.participants.some((participant) =>
      isSameRef(participant.ref, stafferRef)
    );
  }

  static participantsByType<T extends IStaffer | IPatient>(
    event: IEvent,
    type: ParticipantType
  ): IParticipant<T>[] {
    return event.participants.filter(
      (participant): participant is IParticipant<T> => participant.type === type
    );
  }

  static replaceEvent(
    calendarEvent: ICalendarEvent,
    newEvent: IEvent
  ): Pick<ICalendarEvent, 'event' | 'eventHistory'> {
    const lastEvent =
      calendarEvent.eventHistory[calendarEvent.eventHistory.length - 1];
    const isSameEvent: boolean = lastEvent
      ? lastEvent.event === calendarEvent.event
      : false;
    if (!isSameEvent) {
      const eventHistory = [
        ...calendarEvent.eventHistory,
        {
          createdAt: toTimestamp(),
          event: calendarEvent.event,
        },
      ];
      return {
        ...calendarEvent,
        eventHistory,
        event: newEvent,
      };
    }

    return {
      ...calendarEvent,
      eventHistory: calendarEvent.eventHistory,
      event: newEvent,
    };
  }

  static hasChanged(
    currentEvent: ICalendarEvent | WithRef<ICalendarEvent> | undefined,
    newEvent: ICalendarEvent | WithRef<ICalendarEvent>
  ): boolean {
    if (!currentEvent) {
      return true;
    }

    if (
      !currentEvent.event.from.isEqual(newEvent.event.from) ||
      !currentEvent.event.to.isEqual(newEvent.event.to)
    ) {
      return true;
    }

    if (
      isWithRef<ICalendarEvent>(currentEvent) &&
      !isWithRef<ICalendarEvent>(newEvent)
    ) {
      return true;
    }

    if (
      !isWithRef<ICalendarEvent>(currentEvent) &&
      isWithRef<ICalendarEvent>(newEvent)
    ) {
      return true;
    }

    if (
      isWithRef<ICalendarEvent>(currentEvent) &&
      isWithRef<ICalendarEvent>(newEvent) &&
      !isSameRef(currentEvent, newEvent)
    ) {
      return true;
    }

    if (
      !isWithRef<ICalendarEvent>(currentEvent) &&
      !isWithRef<ICalendarEvent>(newEvent) &&
      (!isSameRef(currentEvent.scheduleRef, newEvent.scheduleRef) ||
        !currentEvent.event.from.isEqual(newEvent.event.from))
    ) {
      return true;
    }

    return false;
  }
}

export class CandidateCalendarEvent {
  static init(
    overrides: AtLeast<ICandidateCalendarEvent, 'candidate' | 'event'>
  ): ICandidateCalendarEvent {
    return {
      ...CalendarEvent.init({ event: overrides.event }),
      title: [toTextContent('Gap Candidate')],
      offerMade: false,
      ...overrides,
    };
  }
}
