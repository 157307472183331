<svg class="clinical-chart" [attr.viewBox]="viewBox$ | async">
  <svg:g
    *ngFor="let arch of arches$ | async; trackBy: trackByArch"
    class="arch-selector"
    [prChartArch]="arch"
    [prSVGTransform]="arch"
    #archChartSurface="prChartSurface"
    [ngClass]="{
      selected: archChartSurface.selected$ | async,
      disabled: archChartSurface.disabled$ | async
    }"
    prChartSurface
    [highlightDisabled]="true"
    prChartedItemsBadgeHover
    [view]="arch.display"
  />

  <svg:g
    class="mouth-selector"
    prChartMouth
    [arches]="arches$ | async"
    [element]="element$ | async"
    [view]="mouth$ | async"
    #mouthChartSurface="prChartSurface"
    [ngClass]="{
      selected: mouthChartSurface.selected$ | async,
      disabled: mouthChartSurface.disabled$ | async
    }"
    prChartSurface
    [highlightDisabled]="true"
    prChartedItemsBadgeHover
  />

  <svg:g
    *ngFor="let row of rows$ | async; trackBy: trackByRow"
    [prSVGTransform]="row"
  >
    <svg:g
      *ngFor="let quadrant of row.quadrants; trackBy: trackByQuadrant"
      [prSVGTransform]="quadrant"
    >
      <svg:g
        class="quadrant-selector"
        [prChartQuadrant]="quadrant"
        #quadrantChartSurface="prChartSurface"
        [ngClass]="{
          selected: quadrantChartSurface.selected$ | async,
          disabled: quadrantChartSurface.disabled$ | async
        }"
        prChartSurface
        [highlightDisabled]="true"
        prChartedItemsBadgeHover
        [view]="quadrant.display"
      />

      <svg:g
        *ngFor="let tooth of quadrant.teeth; trackBy: trackByTooth"
        prChartTooth
        [view]="tooth.display"
        [prSVGTransform]="tooth"
        [tooth]="tooth"
      />
    </svg:g>
  </svg:g>
</svg>
