import { Injectable } from '@angular/core';
import { type IInteractionData } from '@principle-theorem/principle-core/interfaces';
import { type AtLeast } from '@principle-theorem/shared';
import { EmailService } from './contextual-actions/interaction-actions/email.service';
import { type IInteractionAction } from './contextual-actions/interaction-actions/interaction-action';
import { NoteService } from './contextual-actions/interaction-actions/note.service';
import { PhoneService } from './contextual-actions/interaction-actions/phone.service';
import { SmsService } from './contextual-actions/interaction-actions/sms.service';

@Injectable({
  providedIn: 'root',
})
export class InteractionDialogsService {
  private _dialogData?: AtLeast<IInteractionData, 'contact'>;

  constructor(
    private _smsService: SmsService,
    private _phoneService: PhoneService,
    private _emailService: EmailService,
    private _noteService: NoteService
  ) {}

  set dialogData(data: AtLeast<IInteractionData, 'contact'>) {
    this._dialogData = data;
  }

  openPhone(): void {
    this._doAction(this._phoneService);
  }

  openSMS(): void {
    this._doAction(this._smsService);
  }

  openEmail(): void {
    this._doAction(this._emailService);
  }

  openNote(): void {
    this._doAction(this._noteService);
  }

  private _doAction(action: IInteractionAction): void {
    if (!this._dialogData) {
      return;
    }
    action.do(this._dialogData.contact, this._dialogData.resource);
  }
}
