import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  getRangeDuration,
  getTimePeriodStartEnd,
  ISO_DATE_FORMAT,
  type ITimePeriod,
  snapshot,
  type Timezone,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { DateRangePresetsHeaderComponent } from './date-range-presets-header/date-range-presets-header.component';
import { DateRangeService } from './date-range.service';
import { RangeSelectionStrategy } from './range-selection-strategy';

@Component({
  selector: 'pr-date-range-pagination',
  templateUrl: './date-range-pagination.component.html',
  styleUrls: ['./date-range-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...MOMENT_DATEPICKER_PROVIDERS,
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: RangeSelectionStrategy,
    },
  ],
})
export class DateRangePaginationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  header = DateRangePresetsHeaderComponent;
  timezone$ = new ReplaySubject<Timezone>(1);
  isToday$: Observable<boolean>;
  dateRangeForm = new TypedFormGroup<ITimePeriod>({
    from: new TypedFormControl<moment.Moment>(undefined, Validators.required),
    to: new TypedFormControl<moment.Moment>(undefined, Validators.required),
  });
  nextDayDisabled$ = new BehaviorSubject<boolean>(false);
  nextWeekDisabled$ = new BehaviorSubject<boolean>(false);
  nextMonthDisabled$ = new BehaviorSubject<boolean>(false);
  datePickerDisabled$ = new BehaviorSubject<boolean>(false);
  @Output() dateRangeChange = new EventEmitter<ITimePeriod>();

  @Input()
  set nextWeekDisabled(nextWeekDisabled: BooleanInput) {
    this.nextWeekDisabled$.next(coerceBooleanProperty(nextWeekDisabled));
  }

  @Input()
  set nextDayDisabled(nextDayDisabled: BooleanInput) {
    this.nextDayDisabled$.next(coerceBooleanProperty(nextDayDisabled));
  }

  @Input()
  set nextMonthDisabled(nextMonthDisabled: BooleanInput) {
    this.nextMonthDisabled$.next(coerceBooleanProperty(nextMonthDisabled));
  }

  @Input()
  set datePickerDisabled(datePickerDisabled: BooleanInput) {
    this.datePickerDisabled$.next(coerceBooleanProperty(datePickerDisabled));
  }

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRangeForm.setValue(dateRange, { emitEvent: false });
      this.rangeService.setRange(
        Math.round(getRangeDuration(dateRange, 'days', true).asDays())
      );
    }
  }

  @Input()
  set timezone(timezone: Timezone) {
    if (timezone) {
      this.timezone$.next(timezone);
    }
  }

  constructor(public rangeService: DateRangeService) {
    this.isToday$ = combineLatest([
      this.dateRangeForm.valueChanges,
      this.timezone$,
    ]).pipe(
      map(([value, timezone]) => {
        if (!value.from) {
          return false;
        }
        return moment
          .tz(timezone)
          .isSame(
            moment.tz(value.from.format(ISO_DATE_FORMAT), timezone),
            'day'
          );
      })
    );

    combineLatest([
      this.dateRangeForm.valueChanges.pipe(debounceTime(100)),
      this.timezone$,
    ])
      .pipe(
        filter(([range, _timezone]) => (range.from && range.to ? true : false)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([range, timezone]) =>
        this.dateRangeChange.emit(getTimePeriodStartEnd(range, timezone))
      );

    this.rangeService.selectedDayRange$
      .pipe(
        filter((range) => !range),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.dateRangeForm.reset());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  previousDay(): void {
    this._moveRangeSelection(-1);
  }

  nextDay(): void {
    this._moveRangeSelection(1);
  }

  previousWeek(): void {
    this._moveRangeSelection(-7);
  }

  nextWeek(): void {
    this._moveRangeSelection(7);
  }

  previousMonth(): void {
    this._moveRangeSelection(-1, 'month');
  }

  nextMonth(): void {
    this._moveRangeSelection(1, 'month');
  }

  async today(): Promise<void> {
    const rangeInDays = this.dateRangeForm.value.to.diff(
      this.dateRangeForm.value.from,
      'days'
    );
    const timezone = await snapshot(this.timezone$);
    this.dateRangeForm.setValue({
      from: moment.tz(timezone).startOf('day'),
      to: moment.tz(timezone).add(rangeInDays, 'days').endOf('day'),
    });
  }

  private _moveRangeSelection(
    days: number,
    unit: moment.unitOfTime.Base = 'days'
  ): void {
    const from = moment(this.dateRangeForm.value.from).add(days, unit);
    const to = moment(this.dateRangeForm.value.to).add(days, unit);
    this.dateRangeForm.setValue({ from, to });
  }
}
