import { type ICopiedFileInfo } from './storage.generic';
export * from './storage.generic';

export class CopyFile {
  constructor(
    public sourceBucket: string,
    public sourcePath: string,
    public destinationBucket: string,
    public destinationPath: string,
    _projectId: string
  ) {}

  getSourceFile(): File {
    throw new Error('Not implemented');
  }

  getDestinationFile(): File {
    throw new Error('Not implemented');
  }

  copy(): Promise<string> {
    throw new Error('Not implemented');
  }

  getFiles(): Promise<string> {
    throw new Error('Not implemented');
  }
}

export class CopyFiles extends CopyFile {
  copyAll(): Promise<ICopiedFileInfo[]> {
    throw new Error('Not implemented');
  }
}

export class StorageFile {
  constructor(
    public sourceBucket: string,
    public destinationBucket: string,
    public destinationPath: string,
    _projectId: string
  ) {}

  getSourceFile(_sourcePath: string): Promise<File | undefined> {
    throw new Error('Not implemented');
  }

  getDestinationFile(_sourcePath: string): File {
    throw new Error('Not implemented');
  }

  copy(_sourcePath: string): Promise<string | undefined> {
    throw new Error('Not implemented');
  }
}
