import {
  IInvoice,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { sortTimestamp } from '@principle-theorem/shared';
import { TransactionOperators } from '../transaction/transaction-operators';
import { Invoice } from './invoice';

export interface IInvoiceWithTransactions {
  invoice: IInvoice;
  transactions: ITransaction[];
}

interface IInvoiceWithBalance {
  invoice: IInvoice;
  balance: number;
}

export interface IInvoiceWithTransaction {
  invoice: IInvoice;
  transaction: ITransaction;
}

export function splitTransactionOverInvoices(
  baseTransaction: ITransaction,
  invoices: IInvoiceWithTransactions[]
): IInvoiceWithTransaction[] {
  return invoices
    .sort((a: IInvoiceWithTransactions, b: IInvoiceWithTransactions) =>
      sortTimestamp(a.invoice.due, b.invoice.due)
    )
    .reverse()
    .map((pair: IInvoiceWithTransactions) => ({
      invoice: pair.invoice,
      balance: Invoice.balance(pair.invoice, pair.transactions),
    }))
    .filter((pair: IInvoiceWithBalance) => pair.balance > 0)
    .reduce(splitTransactionsReducer(baseTransaction), []);
}

function splitTransactionsReducer(
  baseTransaction: ITransaction
): (
  acc: IInvoiceWithTransaction[],
  item: IInvoiceWithBalance
) => IInvoiceWithTransaction[] {
  return (acc: IInvoiceWithTransaction[], item: IInvoiceWithBalance) => {
    const pair: IInvoiceWithTransaction = {
      invoice: item.invoice,
      transaction: getTransaction(baseTransaction, acc, item),
    };
    return [...acc, pair];
  };
}

function getTransaction(
  baseTransaction: ITransaction,
  acc: IInvoiceWithTransaction[],
  item: IInvoiceWithBalance
): ITransaction {
  const remaining: number = baseTransaction.amount - fundsSpent(acc);
  const amount: number = remaining > item.balance ? item.balance : remaining;
  return { ...baseTransaction, amount };
}

function fundsSpent(items: IInvoiceWithTransaction[]): number {
  const transactions = items
    .map((pair: IInvoiceWithTransaction) => pair.transaction)
    .filter((transaction?: ITransaction) => transaction !== undefined);
  return new TransactionOperators(transactions).sum();
}
