import {
  IBrand,
  IOrganisation,
  IPatient,
  IPatientPortalToken,
  IPatientTokenData,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getDoc,
  getParentDocRef,
  IReffable,
  WithRef,
} from '@principle-theorem/shared';
import { createCSRF } from '@principle-theorem/temporary-tokens';
import * as moment from 'moment-timezone';
import { getTokenExpiryFromAppointment } from '../appointment/patient-confirm-appointment';
import { Brand } from '../brand';
import { Organisation } from '../organisation/organisation';
import { Patient } from './patient';

export async function generatePatientMedicalHistoryToken(
  org: WithRef<IOrganisation>,
  patient: WithRef<IPatient>
): Promise<string> {
  const appointment = await Patient.getNextScheduledAppointment(patient);
  const tokenExpiry = await getTokenExpiryFromAppointment(appointment);
  const tokenData: IPatientTokenData = {
    patient: patient.ref,
  };
  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    tokenExpiry,
    true
  );
}

export async function generateMedicalHistoryUrl(
  patient: WithRef<IPatient>,
  appUrl: string
): Promise<string> {
  const brandRef = getParentDocRef<IBrand>(patient.ref.path);
  const orgRef = getParentDocRef<IOrganisation>(brandRef.path);
  const token = await generatePatientMedicalHistoryToken(
    await getDoc(orgRef),
    patient
  );

  return [appUrl, 'history', token].join('/');
}

/**
 * Generates a patient forms link that expires in 2 weeks
 */
export async function generatePatientFormsToken(
  org: IReffable<IOrganisation>,
  patient: IReffable<IPatient>,
  paractice: IReffable<IPractice>
): Promise<string> {
  const tokenExpiry = moment().add(2, 'weeks');
  const tokenData: IPatientPortalToken = {
    patient: patient.ref,
    practice: paractice.ref,
  };
  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    tokenExpiry,
    true
  );
}

export async function generatePatientFormsUrl(
  patient: IReffable<IPatient>,
  practice: IReffable<IPractice>,
  appUrl: string
): Promise<string> {
  const brandRef = Patient.brandRef(patient);
  const orgRef = Brand.organisationRef({ ref: brandRef });
  const org = await Firestore.getDoc(orgRef);
  const token = await generatePatientFormsToken(org, patient, practice);
  return [appUrl, 'forms', token].join('/');
}
