import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { STAFF_DESTINATION_ENTITY } from '../../destination/entities/staff';
import { convertValueFn } from '../../../source/source-helpers';

export const STAFFER_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Staffer List',
    description: '',
    idPrefix: STAFFER_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactStaffer {
  id: string;
  title: string | null;
  first_name: string | null;
  surname: string | null;
  middle_name: string | null;
  name: string | null;
  email: string;
  is_inactive: boolean;
}

function isExactStafferListItem(item: unknown): item is IExactStaffer {
  return TypeGuard.interface<IExactStaffer>({
    id: isString,
    title: [isString, isNull],
    first_name: [isString, isNull],
    surname: [isString, isNull],
    middle_name: [isString, isNull],
    name: [isString, isNull],
    email: isString,
    is_inactive: isBoolean,
  })(item);
}

const STAFF_SOURCE_QUERY = `
SELECT
  NULLIF(id::text, '') as id,
  NULLIF(title, '') as title,
  NULLIF(firstname, '') AS first_name,
  NULLIF(surname, '') AS surname,
  NULLIF(middlename, '') AS middle_name,
  NULLIF(name, '') AS name,
  email,
  isinactive AS is_inactive
FROM
  convprovider
WHERE
  id != ''
`;

export class StafferSourceEntity extends BaseSourceEntity<IExactStaffer> {
  sourceEntity = STAFFER_SOURCE_ENTITY;
  entityResourceType = STAFFER_RESOURCE_TYPE;
  sourceQuery = STAFF_SOURCE_QUERY;
  verifySourceFn = isExactStafferListItem;

  migrationDestinations = [STAFF_DESTINATION_ENTITY.metadata.key];

  override transformDataFn = flow([
    convertValueFn((value) => String(value).replace(/\//g, '-'), 'id'),
  ]);

  translate(_staffer: IExactStaffer): Record<symbol, unknown> {
    return {};
  }

  getSourceRecordId(data: IExactStaffer): string {
    return data.id;
  }

  getSourceLabel(data: IExactStaffer): string {
    const name = data.name ?? `${data.first_name} ${data.surname}`;
    return `${data.id} ${name}`;
  }
}
