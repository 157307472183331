import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StorageModule } from '@ngx-pwa/local-storage';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { WorkspaceSelectorComponent } from './workspace-selector/workspace-selector.component';
import { WorkspaceService } from './workspace.service';
import { RegisterComponent } from './register/register.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { UserPublicIpService } from './user-public-ip.service';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgxAuthFirebaseUIModule,
    StorageModule,
    ReactiveFormsModule,
  ],
  providers: [AuthService, WorkspaceService, UserPublicIpService],
  declarations: [
    LoginComponent,
    WorkspaceSelectorComponent,
    RegisterComponent,
    SignInComponent,
  ],
  exports: [
    LoginComponent,
    WorkspaceSelectorComponent,
    RegisterComponent,
    SignInComponent,
  ],
})
export class NgAuthModule {}
