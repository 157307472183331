import {
  SourceEntityMigrationType,
  ToothNumber,
  isToothNumber,
  type ISourceEntity,
  PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_PERIODONTAL_CHART_ITEM_RESOURCE_TYPE =
  'patientPeriodontalChartItems';

export const PATIENT_PERIODONTAL_CHART_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Periodontal Chart Item List',
      description: '',
      idPrefix: PATIENT_PERIODONTAL_CHART_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum PeriodontalItemType {
  MissingTooth = 1, // 0 / 1 for value; 0 = not missing, 1 = missing
  Implant = 2, // 0 / 1 for value; 0 = not an implant, 1 = implant. This also disabled the furcation
  ProbingDepth = 4,
  Recession = 5,
  AttachmentLoss = 6, // A sum of the Probing Depth and Recession values
  Bleeding = 7,
  Suppuration = 8,
  Plaque = 9,
  Mobility = 11, // 0 / 1 / 11 for value
  Furcation = 12,
}

export const CORE_PACTICE_PRIODONTAL_TYPE_MAP: Record<
  PeriodontalItemType,
  PerioMeasurement | undefined
> = {
  [PeriodontalItemType.MissingTooth]: undefined,
  [PeriodontalItemType.Implant]: undefined,
  [PeriodontalItemType.ProbingDepth]: PerioMeasurement.Pocket,
  [PeriodontalItemType.Recession]: PerioMeasurement.Recession,
  [PeriodontalItemType.AttachmentLoss]: undefined,
  [PeriodontalItemType.Bleeding]: PerioMeasurement.Bleeding,
  [PeriodontalItemType.Suppuration]: PerioMeasurement.Suppuration,
  [PeriodontalItemType.Plaque]: undefined,
  [PeriodontalItemType.Mobility]: PerioMeasurement.Mobility,
  [PeriodontalItemType.Furcation]: PerioMeasurement.Furcation,
};

export enum PeriodontalRowType {
  Buccal = 0,
  Palatal = 1,
}

export interface ICorePracticePatientPeriodontalChartItem {
  id: number;
  periodontalChartId: number;
  patientId: number;
  toothId: number; // 2 (Appears to be a 1 - 32 index corresponding to the tooth number, starting from 18. So 15 would be 4, 21 would be 9 etc...)
  tooth: ToothNumber; // 17
  periodontalRow: PeriodontalRowType; // Perio row; Mobility and Furcation are only on row 0.
  periodontalColumn: 1 | 2 | 3; // Perio column; Mobility only has column 1 as it spans all columns. For row 0: D | F | M. For row 1: D | P | M.
  type: PeriodontalItemType; // Row type (need to review in UI)
  value: number;
}

export function isCorePracticePatientPeriodontalChartItem(
  item: unknown
): item is ICorePracticePatientPeriodontalChartItem {
  return TypeGuard.interface<ICorePracticePatientPeriodontalChartItem>({
    id: isNumber,
    periodontalChartId: isNumber,
    patientId: isNumber,
    toothId: isNumber,
    tooth: isToothNumber,
    periodontalRow: isNumber,
    periodontalColumn: isNumber,
    type: isNumber,
    value: isNumber,
  })(item);
}

export interface ICorePracticePatientPeriodontalChartItemTranslations {}

export interface ICorePracticePatientPeriodontalChartItemFilters {
  periodontalChartId: number;
  patientId: number;
}

const PATIENT_PERIODONTAL_CHART_ITEM_SOURCE_QUERY = `
SELECT
	perio_chart.patient_id,
	chart_item.*
FROM (
  SELECT
    PerioChartItemId AS id,
    PerioChartId AS periodontal_chart_id,
    ToothId AS tooth_id,
    convert_to_text(Tooth) AS tooth,
    PeriodontalRow AS periodontal_row,
    PeriodontalColumn AS periodontal_column,
    Type AS type,
    Value AS value
  FROM tblPerioChartItem
) AS chart_item
LEFT JOIN (
  SELECT
    PerioChartId AS id,
    PatientId AS patient_id
  FROM tblPerioChart
) AS perio_chart
ON chart_item.periodontal_chart_id = perio_chart.id
`;

export class PatientPeriodontalChartItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPeriodontalChartItem,
  ICorePracticePatientPeriodontalChartItemTranslations,
  ICorePracticePatientPeriodontalChartItemFilters
> {
  sourceEntity = PATIENT_PERIODONTAL_CHART_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIODONTAL_CHART_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIODONTAL_CHART_ITEM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPeriodontalChartItem;

  translate(
    _data: ICorePracticePatientPeriodontalChartItem,
    _timezone: Timezone
  ): ICorePracticePatientPeriodontalChartItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientPeriodontalChartItem): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPeriodontalChartItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPeriodontalChartItem,
    _timezone: Timezone
  ): ICorePracticePatientPeriodontalChartItemFilters {
    return {
      periodontalChartId: data.periodontalChartId,
      patientId: data.patientId,
    };
  }
}
