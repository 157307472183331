<div class="box-border flex h-full min-h-full w-fit min-w-full flex-col">
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
  </ng-template>

  <ng-container *ngrxLet="items$ as items">
    <div
      *ngIf="!items || items.length === 0; else selectChart"
      class="flex flex-col items-center justify-center"
    >
      <div
        class="charting-empty box-border flex h-full min-h-full w-full flex-col items-center justify-center gap-4"
      >
        <pr-empty-state image="list" title="clinical charts" />
        <pt-loader-button
          type="flat"
          color="primary"
          [loading]="(loaded$ | ngrxPush) === false"
          (click)="addFirstChart()"
        >
          Add First Chart
        </pt-loader-button>
      </div>
    </div>
  </ng-container>

  <ng-template #selectChart>
    <div class="charting-history-card mat-elevation-z1 flex">
      <pr-history-list
        [items]="items$ | ngrxPush"
        [selected]="selectedItem$ | ngrxPush"
        (selectedChange)="selectChartHistory($event)"
      />
      <div class="w-full p-2" *ngIf="loaded$ | async; else loading">
        <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          [selectedIndex]="selectedChartTypeIndex$ | async"
          (selectedIndexChange)="setChartType($event)"
        >
          <mat-tab [label]="chartTypeOption.Clinical | titlecase">
            <pr-chart-ui [showHistorySelector]="false" class="px-2 py-4" />
          </mat-tab>
          <mat-tab [label]="chartTypeOption.Periodontal | titlecase">
            <div class="px-2 py-4">
              <pr-chart-view-toolbar [showHistorySelector]="false">
                <pr-perio-chart-settings [immutable]="false" />
              </pr-chart-view-toolbar>
              <pr-perio-chart-ui />
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-template>
</div>
