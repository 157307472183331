import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isEnumValue,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_COMMUNICATION_RESOURCE_TYPE = 'patientCommunication';

export const PATIENT_COMMUNICATION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Communication List',
      description: '',
      idPrefix: PATIENT_COMMUNICATION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientCommunication {
  id: number;
  appointment_id: number | null;
  reminder_type_id: number;
  provider_id: number | null;
  patient_id: number;
  server_id: number | null;
  created_at: string;
  mobile_number: string;
  type: ID4WCommunicationType;
  content: string;
}

export enum ID4WCommunicationType {
  Unknown = '',
  OnlineAppointmentRevoked = 'EAPP_R',
  OnlineAppointmentConfirmation = 'EAPP_C',
  BulkMessage = 'QUE',
  PatientGeneral = 'PAT',
  AppointmentConfirmAndGeneral = 'APP',
  AppointmentReminderAndGeneral = 'APP_MESS',
  AppointmentNotification = 'APP_NOTIF',
  AppointmentScheduled = 'APP_SCHED',
  Recall = 'REC',
  PatientRegistration = 'PAT_REG',
}

export function isD4WPatientCommunication(
  item: unknown
): item is ID4WPatientCommunication {
  return TypeGuard.interface<ID4WPatientCommunication>({
    id: isNumber,
    appointment_id: [isNumber, isNull],
    reminder_type_id: isNumber,
    provider_id: [isNumber, isNull],
    patient_id: isNumber,
    server_id: [isString, isNumber, isNull],
    created_at: isString,
    mobile_number: [isString, isNull],
    type: (value): value is ID4WCommunicationType =>
      isEnumValue(ID4WCommunicationType, value),
    content: isString,
  })(item);
}

export interface ID4WPatientCommunicationTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientCommunicationFilters {
  providerId?: string;
  patientId: string;
  appointmentId?: string;
  createdAt: Timestamp;
}

const PATIENT_COMMUNICATION_SOURCE_QUERY = `
SELECT
  id,
  appoint_id AS appointment_id,
  reminder_type_id,
  provider_id,
  pat_id AS patient_id,
  server_id,
  date_time AS created_at,
  mobile_number::TEXT as mobile_number,
  tp AS type,
  reminder_text AS content
FROM patients_reminders
WHERE pat_id IS NOT NULL
`;

export class PatientCommunicationSourceEntity extends BaseSourceEntity<
  ID4WPatientCommunication,
  ID4WPatientCommunicationTranslations,
  ID4WPatientCommunicationFilters
> {
  sourceEntity = PATIENT_COMMUNICATION_SOURCE_ENTITY;
  entityResourceType = PATIENT_COMMUNICATION_RESOURCE_TYPE;
  sourceQuery = PATIENT_COMMUNICATION_SOURCE_QUERY;
  verifySourceFn = isD4WPatientCommunication;
  override dateFilterField: keyof ID4WPatientCommunicationFilters = 'createdAt';

  override transformDataFn = flow([
    (rows: ID4WPatientCommunication[]) => rows.map(cleanObjectStrings),
  ]);

  translate(
    appointment: ID4WPatientCommunication,
    timezone: Timezone
  ): ID4WPatientCommunicationTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientCommunication): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientCommunication): string {
    return `${data.id} ${data.patient_id} ${data.appointment_id || ''}`;
  }

  getFilterData(
    data: ID4WPatientCommunication,
    timezone: Timezone
  ): ID4WPatientCommunicationFilters {
    return {
      patientId: data.patient_id.toString(),
      appointmentId: data.appointment_id
        ? data.appointment_id.toString()
        : undefined,
      providerId: data.provider_id?.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
