import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { CancelAppointmentComponent } from './cancel-appointment/cancel-appointment.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { ManageAppointmentComponent } from './manage-appointment/manage-appointment.component';
import { AppointmentRequestsComponent } from './appointment-requests/appointment-requests.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    NgSharedModule,
    NgMaterialModule,
    NgInteractionsModule,
    NgPrincipleSharedModule,
  ],
  declarations: [
    CreateAppointmentComponent,
    ManageAppointmentComponent,
    CancelAppointmentComponent,
    AppointmentRequestsComponent,
  ],
  exports: [
    CreateAppointmentComponent,
    ManageAppointmentComponent,
    CancelAppointmentComponent,
    AppointmentRequestsComponent,
  ],
})
export class PagesModule {}
