<div cdkDropListGroup class="generic-drag-drop">
  <div
    class="service-code-group"
    *ngFor="let group of serviceCodeGroups; trackBy: trackByGroup"
  >
    <pr-service-group-header
      [group]="group"
      (groupChange)="emitChanges()"
      (deleted)="deleteGroup(group)"
     />

    <div
      cdkDropList
      [cdkDropListData]="group.serviceCodes"
      (cdkDropListDropped)="drop($event)"
    >
      <div
        *ngFor="let entry of group.serviceCodes; trackBy: trackByCode"
        cdkDrag
        [cdkDragData]="entry"
        cdkDragLockAxis="y"
      >
        <div class="flex items-center gap-4">
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
          <span class="flex-1">{{ entry | map: getCodeName }}</span>

          <pr-increase-decrease-input
            (decrease)="decreaseQty(entry)"
            [decreaseDisabled]="entry.quantity <= 1"
            (increase)="increaseQty(entry)"
          >
            <label>Quantity</label>
            {{ entry.quantity }}
          </pr-increase-decrease-input>

          <button mat-icon-button color="warn" (click)="deleteEntry(entry)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
