import { type ComponentLoader } from '@principle-theorem/ng-shared';
import { Editor } from '@tiptap/core';
import { type EditorMenuItemComponent } from './components/editor-menu-item.component';

/**
 * Used for creating a list of dynamically generated menu item components.
 * The schema is available to be able to get any data from the
 * registered Nodes and Marks.
 */
export type MenuButtonLoaderFn<
  Component extends EditorMenuItemComponent = EditorMenuItemComponent,
  ComponentData extends object = object
> = (editor: Editor) => ComponentLoader<Component, ComponentData>;

/**
 * Wraps the MenuButtonLoaderFn in a configure function which allows
 * non-Prosemirror data to be passed into the menu button.
 */
export type MenuButtonConfigureFn<
  Component extends EditorMenuItemComponent,
  ComponentData extends object = object,
  ConfigData = ComponentData
> = (data: ConfigData) => MenuButtonLoaderFn<Component, ComponentData>;
