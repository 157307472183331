<mat-toolbar color="accent">{{ data.title }}</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Amount</mat-label>
      <span matTextPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        formControlName="amount"
        [min]="0"
        [max]="data.max"
        [required]="true"
      />
      <mat-hint *ngIf="data.max" align="end"
        >Maximum {{ data.max | currency }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>
    <mat-form-field fxFlex *prHasPermission="[accountAdminPermission]">
      <mat-label>Date Received</mat-label>
      <input
        matInput
        formControlName="dateReceived"
        [matDatepicker]="dateReceived"
        [min]="amendDateStore.minDate$ | async"
        [max]="amendDateStore.maxDate$ | async"
        (focus)="dateReceived.open()"
        (click)="dateReceived.open()"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateReceived"
       />
      <mat-datepicker #dateReceived />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
