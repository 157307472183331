import {
  HicapsConnectExtendedDataTypeGuard,
  IHicapsConnectMedicareClaimTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralProviderActionsService } from '../../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';

export class AmendHicapsConnectMedicareTransaction
  implements
    ITransactionAction<IHicapsConnectMedicareClaimTransactionExtendedData>
{
  icon = 'edit';
  label = 'Amend';
  tooltip = 'Amend if claim amount is incorrect';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = HicapsConnectExtendedDataTypeGuard.isMedicareClaim;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(
    data: ITransactionActionsData<IHicapsConnectMedicareClaimTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.canAmend$(data);
  }

  async do(
    data: ITransactionActionsData<IHicapsConnectMedicareClaimTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.amend(
      data.invoice,
      data.latestTransaction,
      'Amend Medicare Amount'
    );
    this.inProgress$.next(false);
  }
}
