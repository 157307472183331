<p *ngIf="rules.duration > 0" class="mat-caption">
  Requested duration: <strong>{{ rules.duration }} minutes</strong>
</p>
<p class="mat-caption" *ngIf="rules.minDays || rules.maxDays">
  This can be booked

  <ng-container *ngIf="rules.minDays">
    <strong>{{ rules.minDays }}</strong> days after
  </ng-container>

  <ng-container *ngIf="rules.minDays && rules.maxDays"> and </ng-container>

  <ng-container *ngIf="rules.maxDays">
    no more than <strong>{{ rules.maxDays }}</strong> days after
  </ng-container>

  the previous step.
</p>
