import {
  rand,
  randBoolean,
  randNumber,
  randParagraph,
  randText,
} from '@ngneat/falso';
import { InteractionType } from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  IPatientInteractionDimension,
} from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  getEnumValues,
  toSerialisedTimestamp,
  toTimestamp,
  wrapWithSeed,
} from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  MockBigQueryDocRef,
  MockBigQueryNamedDocument,
} from './common.mock';
import {
  stafferDimensionNamedDoc,
  MOCKED_STAFF,
} from './staffer-dimension.mock';

export const MOCKED_INTERACTION_TAGS = wrapWithSeed('MockInteractionTags', () =>
  ['PC Note', 'Something Else'].map((name) =>
    MockBigQueryNamedDocument(name, 'interaction-tags')
  )
);

function MockInteractionTags(): DimensionsCommon.IBigQueryNamedDocument[] {
  const count = randNumber({ min: 0, max: 1 });
  const tags = new Array(count)
    .fill('')
    .map(() => rand(MOCKED_INTERACTION_TAGS));
  return uniqBy(tags, (item) => item.ref.referenceValue);
}

export class PatientInteractionDimensionMock
  extends BigQueryFirestoreModelMock
  implements IPatientInteractionDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  patientRef = MockBigQueryDocRef(uuid());
  title = randText();
  type = rand(getEnumValues(InteractionType));
  pinned = randBoolean();
  content = randParagraph();
  owner = stafferDimensionNamedDoc(rand(MOCKED_STAFF));
  preferredPractice = MockGenerator.generate(BigQueryNamedDocumentMock);
  preferredFeeSchedule = MockGenerator.generate(BigQueryNamedDocumentMock);
  tags = MockInteractionTags();
}
