import { Pipe, type PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'find$',
})
export class FindPipe implements PipeTransform {
  transform<T, U>(
    value: T,
    array$: Observable<U[]>,
    findFn?: (value: T, array: U[]) => U | undefined
  ): Observable<U | undefined> {
    return array$.pipe(
      map((array) =>
        findFn
          ? findFn(value, array)
          : array.find((arrayItem) => isEqual(arrayItem, value))
      )
    );
  }
}
