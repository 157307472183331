import {
  AccountCredit,
  Brand,
  Invoice,
  Patient,
  TransactionOperators,
  depositToLineItem,
  stafferToNamedDoc,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  AccountCreditType,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityRecord,
  IPracticeMigration,
  IStaffer,
  ITranslationMap,
  InvoiceStatus,
  InvoiceType,
  TransactionProvider,
  type IBasePatient,
  type IGetRecordResponse,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  asyncForEach,
  getError,
  isSameRef,
  sortByCreatedAt,
  snapshotCombineLatest,
  toNamedDocument,
  type DocumentReference,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  BasePatientDepositDestinationEntity,
  IDepositBuildData,
  IDepositJobData,
  IDepositMigrationData,
} from '../../../destination/entities/patient-deposits';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientPaymentAllocation,
  ICorePracticePatientPaymentAllocationFilters,
  ICorePracticePatientPaymentAllocationTranslations,
  PatientPaymentAllocationSourceEntity,
} from '../../source/entities/patient-payment-allocations';
import {
  ICorePracticePatientPrepaymentLineItem,
  ICorePracticePatientPrepaymentLineItemFilters,
  ICorePracticePatientPrepaymentLineItemTranslations,
  PatientPrepaymentLineItemSourceEntity,
} from '../../source/entities/patient-prepayment-line-items';
import {
  ICorePracticePatientPrepayment,
  ICorePracticePatientPrepaymentFilters,
  ICorePracticePatientPrepaymentTranslations,
  PatientPrepaymentSourceEntity,
} from '../../source/entities/patient-prepayments';
import {
  ICorePracticePatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticePaymentTypeMappingHandler } from '../mappings/payment-type-to-provider';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { getTransactions } from './patient-invoices';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { PRACTICE_MAPPING } from '../../../mappings/practices';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IJobData extends IDepositJobData<ICorePracticePatient> {
  paymentTypes: WithRef<ITranslationMap<object, TransactionProvider>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
}

export class PatientDepositDestinationEntity extends BasePatientDepositDestinationEntity<
  ICorePracticePatient,
  IDepositJobData<ICorePracticePatient>
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    prepayments: new PatientPrepaymentSourceEntity(),
    prepaymentLineItems: new PatientPrepaymentLineItemSourceEntity(),
    paymentAllocations: new PatientPaymentAllocationSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practices: new CorePracticePracticeMappingHandler(),
    paymentTypes: new CorePracticePaymentTypeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const paymentTypes$ =
      this.customMappings.paymentTypes.getRecords$(translationMap);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.patientSourceEntity,
        runOptions
      ),
      snapshotCombineLatest([
        practices$,
        staff$,
        practitioners$,
        paymentTypes$,
      ]),
    ]).pipe(
      map(([sourcePatients, [practices, staff, practitioners, paymentTypes]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          practices,
          paymentTypes,
          staff,
          practitioners,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IDepositMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient found');
    }

    const sourceDeposits = (
      await this.sourceEntities.prepayments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      )
    ).filter(
      (deposit) => !deposit.data.data.isVoided && !deposit.data.data.isDeleted
    );

    try {
      const deposits = await asyncForEach(sourceDeposits, async (deposit) => {
        const sourcePracticeId = deposit.data.data.locationId.toString();
        const practiceRef = await translationMap.getDestination<IPractice>(
          sourcePracticeId,
          PRACTICE_MAPPING.metadata.type
        );

        if (!practiceRef) {
          throw new Error(`No practice found for id: ${sourcePracticeId}`);
        }

        const sourceLineItems = (
          await this.sourceEntities.prepaymentLineItems.filterRecords(
            migration,
            'prepaymentId',
            deposit.data.data.id
          )
        ).filter((lineItem) => !lineItem.data.data.isVoided);

        const sourcePaymentAllocations =
          await this.sourceEntities.paymentAllocations.filterRecords(
            migration,
            'prepaymentId',
            deposit.data.data.id
          );

        const providerId = deposit.data.data.providerId.toString();
        const practitionerMap = data.staff.find(
          (staffer) => staffer.sourceIdentifier === providerId
        )?.destinationIdentifier;

        if (!practitionerMap) {
          throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
        }
        const practitioner = data.practitioners.find((searchPractitioner) =>
          isSameRef(searchPractitioner.ref, practitionerMap)
        );

        if (!practitioner) {
          throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
        }

        const depositData = await this._buildDepositData(
          migration,
          data,
          deposit,
          practiceRef,
          patientRef,
          sourceLineItems,
          sourcePaymentAllocations,
          practitioner
        );

        if (!depositData) {
          throw new Error(`Deposit ${deposit.record.uid} has no amount`);
        }

        return depositData;
      });

      return {
        patientRef,
        deposits: sortBy(deposits, 'uid'),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private async _buildDepositData(
    migration: WithRef<IPracticeMigration>,
    data: IJobData,
    deposit: IGetRecordResponse<
      ICorePracticePatientPrepayment,
      ICorePracticePatientPrepaymentTranslations,
      ICorePracticePatientPrepaymentFilters
    >,
    practiceRef: DocumentReference<IPractice>,
    patientRef: DocumentReference<IPatient>,
    sourceLineItems: IGetRecordResponse<
      ICorePracticePatientPrepaymentLineItem,
      ICorePracticePatientPrepaymentLineItemTranslations,
      ICorePracticePatientPrepaymentLineItemFilters
    >[],
    sourcePaymentAllocations: IGetRecordResponse<
      ICorePracticePatientPaymentAllocation,
      ICorePracticePatientPaymentAllocationTranslations,
      ICorePracticePatientPaymentAllocationFilters
    >[],
    practitioner: WithRef<IStaffer>
  ): Promise<IDepositBuildData | undefined> {
    const practice = await Firestore.getDoc(practiceRef);
    const primaryContact = await Patient.resolvePrimaryContact(patientRef);
    const resolvedPatient = await Firestore.getDoc(patientRef);
    const onBehalfOf = toAccountDetails(
      resolvedPatient as IBasePatient & IPatientContactDetails
    );

    const transactions = await getTransactions(
      migration,
      deposit.data.translations.prepaymentDate,
      0,
      sourcePaymentAllocations,
      practiceRef,
      InvoiceType.Invoice,
      data.paymentTypes
    );
    const amount = deposit.data.data.total;
    const used =
      deposit.data.data.total - (deposit.data.data.remainingCredit ?? 0);
    const issuedAt = deposit.data.translations.prepaymentDate;

    const invoice = {
      ...Invoice.init({
        from: toAccountDetails(practice),
        to: primaryContact
          ? {
              ...toAccountDetails(primaryContact),
              onBehalfOf,
            }
          : onBehalfOf,
        practice: toNamedDocument(practice),
        items: sourceLineItems.map((lineItem) =>
          depositToLineItem(
            lineItem.data.data.itemCodeName ?? 'Deposit',
            lineItem.data.data.amount
          )
        ),
        createdAt: issuedAt,
        issuedAt,
      }),
      reference: deposit.data.data.id.toString(),
    };

    const status = getDepositInvoiceStatus(amount);

    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    Invoice.updateStatus(invoice, status, issuedAt);

    const accountCredit = AccountCredit.init({
      type: AccountCreditType.Deposit,
      description: 'Deposit',
      amount,
      used,
      reservedFor: {
        practitioner: stafferToNamedDoc(practitioner),
      },
      practiceRef,
    });

    if (amount !== Invoice.total(invoice)) {
      throw new Error(
        `Deposit ${
          deposit.data.data.id
        } has a mismatched amount to invoice. Invoice: ${Invoice.total(
          invoice
        )}, Account Credit: ${amount}`
      );
    }

    const uid = deposit.data.data.id.toString();

    return {
      uid,
      invoice,
      transactions,
      accountCredit: {
        ...accountCredit,
        createdAt: issuedAt,
      },
    };
  }
}

function getDepositInvoiceStatus(transactionAmount: number): InvoiceStatus {
  return transactionAmount > 0 ? InvoiceStatus.Paid : InvoiceStatus.Cancelled;
}
