@if (schedulingEventSummary$ | async; as summary) {
  @if (display$ | async; as display) {
    <div class="flex flex-row rounded bg-slate-100 p-1">
      <div class="p-2">
        <mat-icon [ngClass]="display.textColour">
          {{ display.iconName }}
        </mat-icon>
      </div>

      <div class="flex flex-1 flex-row flex-wrap items-center gap-x-4 gap-y-2">
        <div class="flex w-32 flex-auto flex-col gap-1">
          <div class="truncate font-bold">
            {{ summary | map: getReasonName : this | titlecase }}
          </div>
          <div class="text-xs" [ngClass]="display.textColour">
            {{ summary | map: getActionLabel : this | titlecase }}
          </div>
        </div>

        <div class="flex w-32 flex-auto flex-col gap-1">
          <div class="truncate text-xs">
            By
            @if (summary.scheduledByName) {
              {{ summary.scheduledByName }}
            } @else {
              {{ unknown | titlecase }}
            }
          </div>
          <div class="text-xs">
            {{ summary.scheduledAt | moment | amDateFormat: dateFormat }}
            •
            {{ summary.scheduledAt | moment | amDateFormat: timeFormat }}
          </div>
        </div>

        <div class="flex w-40 flex-auto flex-col gap-1">
          <div>
            <a
              [prBrandRouterLink]="['patients', summary.patientRef.id]"
              class="font-bold"
            >
              {{ summary.patientName }}
            </a>
          </div>

          <div class="flex flex-row items-center text-xs">
            <div class="truncate">{{ summary.treatmentStepName }}</div>

            @if (summary.treatmentStepCategory) {
              <pr-treatment-category-indicator
                [category]="summary.treatmentStepCategory"
                displayOnly
              />
            }
          </div>
        </div>

        <div class="flex flex-none flex-row items-center">
          <pr-scheduling-event-snapshot
            [eventType]="
              summary.eventBefore | map: eventTypeForDay$ : this | async
            "
            [snapshot]="summary.eventBefore"
          />
          <mat-icon [ngClass]="display.textColour">arrow_forward</mat-icon>
          <pr-scheduling-event-snapshot
            [eventType]="
              summary.eventAfter | map: eventTypeForDay$ : this | async
            "
            [snapshot]="summary.eventAfter"
          />
        </div>
      </div>

      <div>
        <ng-content />
      </div>
    </div>
  }
}
