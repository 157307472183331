import {
  SerialisedMixedSchema,
  isSerialisedMixedSchema,
} from '@principle-theorem/editor';
import { TypeGuard, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { isAutomationTiming } from '../automation/automation-timing';
import { type TemplateScope } from '../templating/template-scope';
import {
  AutomatedNotificationType,
  type IAutomatedNotification,
  type IRenderedTemplate,
} from './automated-notification';

export interface ISerialisedAutomatedNotification
  extends Omit<IAutomatedNotification, 'customTemplate'> {
  customTemplate?: {
    template: SerialisedMixedSchema;
    scope?: TemplateScope;
    renderedTemplate?: IRenderedTemplate;
  };
}

export function isSerialisedAutomatedNotification(
  data: unknown
): data is ISerialisedAutomatedNotification {
  if (!isObject(data)) {
    return false;
  }

  if (
    isString(data.name) ||
    isAutomationTiming(data.timing) ||
    !TypeGuard.undefinedOr(TypeGuard.enumValue(AutomatedNotificationType))(
      data.type
    )
  ) {
    return false;
  }

  if (!('customTemplate' in data)) {
    return true;
  }

  return (
    isObject(data.customTemplate) &&
    isSerialisedMixedSchema(data.customTemplate.template)
  );
}
