import {
  ChartableSurface,
  IChartedRef,
  isChartedToothWithSurface,
} from '@principle-theorem/principle-core/interfaces';
import { CrownSurfaceHierarchy } from './crown-heirarchy';
import { SurfaceHierarchy } from './surface-hierarchy';

export class ToothSurfaceHierarchy extends SurfaceHierarchy<
  Required<Pick<IChartedRef, 'tooth'>>
> {
  override surfaces = [
    ChartableSurface.Distal,
    ChartableSurface.Facial,
    ChartableSurface.Lingual,
    ChartableSurface.Mesial,
    ChartableSurface.Occlusal,
  ];
  override parents = [new CrownSurfaceHierarchy()];

  canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is Required<Pick<IChartedRef, 'tooth'>> {
    return isChartedToothWithSurface(selectedSurface);
  }

  toChartedRef(
    currentRef: Required<Pick<IChartedRef, 'tooth'>>
  ): Required<Pick<IChartedRef, 'tooth'>> {
    return {
      tooth: currentRef.tooth,
    };
  }
}
