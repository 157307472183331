<div class="flex gap-2">
  <div class="label-wrapper flex flex-1 flex-col">
    <div class="flex items-center justify-between gap-4">
      <div class="flex min-w-0 flex-1 gap-1.5">
        <ng-container *ngIf="config$ | async as config">
          <mat-icon
            *ngIf="config.display?.icon.slug"
            class="item-icon shrink-0"
            [ngStyle]="{ color: config.display.colour.value }"
            [svgIcon]="config.display.icon.slug"
          />
          <h4
            class="item-name"
            [matTooltip]="config.name"
            [matTooltipShowDelay]="300"
            [ngClass]="{ compact: compact }"
          >
            {{ config.name }}
          </h4>
        </ng-container>
        <ng-container *ngIf="item$ | async as item">
          <mat-icon
            *ngIf="item.notes.length"
            class="note shrink-0"
            [matTooltip]="noteTooltipText$ | async"
          >
            description
          </mat-icon>
        </ng-container>
      </div>
      <div class="flex shrink-0 items-center justify-end gap-0.5">
        <ng-content select=".header-buttons" />
      </div>
    </div>
    <div class="flex flex-1 items-center gap-2">
      <ng-content select=".drag-handle" />
      <ng-content select=".checkbox" />
      <ng-content select=".attributed-to" />
      <ng-content select=".change-treatment-package" />
      <ng-container *ngIf="item$ | async as item">
        <pr-charted-items-surface-list
          *ngIf="config$ | async as config"
          [chartedSurfaces]="item.chartedSurfaces"
          [availableSurfaces]="config.availableSurfaces"
          [ngClass]="{ highlighted: highlighted }"
          [disabled]="item | map: isMultiTreatment"
          (editSurfaces)="editSurfaces.emit()"
        />
      </ng-container>
      <ng-content select=".header-info" />
      <span class="flex-1"></span>
      <span class="price">{{ price$ | async | currency }}</span>
    </div>
  </div>

  <ng-content />
</div>
