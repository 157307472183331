import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import {
  type IPricedServiceCodeEntry,
  type IServiceCode,
} from '@principle-theorem/principle-core/interfaces';
import { isNil } from 'lodash';
import { type Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceItemComponent {
  private _disableName = false;
  disableQuantity$ = new BehaviorSubject(false);
  serviceCode$: ReplaySubject<IPricedServiceCodeEntry> = new ReplaySubject(1);
  price$: Observable<number | undefined>;
  quantity$: Observable<number>;
  @Output() itemUpdate = new EventEmitter<Partial<IPricedServiceCodeEntry>>();
  @Output() addServiceCode = new EventEmitter<IServiceCode>();
  @Input() disabled = false;
  @Input() compact = false;

  @Input()
  set disableName(disableName: BooleanInput) {
    this._disableName = coerceBooleanProperty(disableName);
  }

  @Input()
  set disableQuantity(disableQuantity: BooleanInput) {
    this.disableQuantity$.next(coerceBooleanProperty(disableQuantity));
  }

  @Input()
  set serviceCode(serviceCode: IPricedServiceCodeEntry) {
    if (serviceCode) {
      this.serviceCode$.next(serviceCode);
    }
  }

  constructor() {
    this.price$ = this.serviceCode$.pipe(
      map((serviceCode) =>
        !isNil(serviceCode.priceOverride)
          ? serviceCode.priceOverride
          : serviceCode.price
      )
    );
    this.quantity$ = this.serviceCode$.pipe(
      map((serviceCode) => serviceCode.quantity)
    );
  }

  getCodeName(code: IPricedServiceCodeEntry): string | undefined {
    if (this._disableName) {
      return;
    }
    const resolvedCode = ServiceProviderHandler.resolveServiceCode(
      code.type,
      code.code
    );
    if (!resolvedCode) {
      return;
    }
    return `${resolvedCode.code} - ${resolvedCode.title}`;
  }
}
