<mat-nav-list>
  <div mat-subheader>Candidate Shortlist</div>

  <ng-template #noCandidates>
    <div class="no-candidates mat-caption">
      Add gap candidates from the waitlist below
    </div>
  </ng-template>

  <ng-container *ngIf="gapCandidates$ | async as gapCandidates">
    <ng-container *ngIf="gapCandidates.length; else noCandidates">
      <pr-gap-candidate-item
        *ngFor="let gapCandidate of gapCandidates; trackBy: trackByCandidate"
        [gap]="gap$ | async"
        [gapCandidate]="gapCandidate"
       />
    </ng-container>
  </ng-container>
</mat-nav-list>
