import {
  type CollectionReference,
  type Query,
  type Timestamp,
  type WriteBatch,
  doc,
  type QueryConstraint,
  collectionGroup,
  query,
  writeBatch as writeBatchFirestore,
  type Transaction,
  runTransaction as runTransactionFirestore,
  store,
} from './adaptor';
import { get, isString } from 'lodash';
import { isObject } from '../../common';
import { toFirestore } from '../../serialisation-provider';
import { toTimestamp } from '../../time/time';
import { isColRef } from '../firestore/collection';
import { asQueryRef } from './collection-group';
import { FirestoreScheduler } from './firestore-scheduler';
import { SystemActors } from '../interfaces';

export function collectionGroupQuery<T>(
  collectionId: string,
  ...queryConstraints: QueryConstraint[]
): Query<T> {
  return query(
    asQueryRef<T>(
      collectionGroup(store(FirestoreScheduler.appName), collectionId)
    ) as unknown as Query<T>,
    ...queryConstraints
  );
}

export function writeBatch(): WriteBatch {
  return writeBatchFirestore(store(FirestoreScheduler.appName));
}

export async function addBulk<T extends object>(
  col: CollectionReference<T>,
  data: T[]
): Promise<void> {
  const batch: WriteBatch = writeBatch();
  const timestamp: Timestamp = toTimestamp();
  data.map((item: T) => {
    const serialisedData = toFirestore(item);
    const ref = doc(col);

    if (isObject(serialisedData) && !serialisedData.createdAt) {
      batch.set(ref, {
        ref,
        ...serialisedData,
        createdAt: timestamp,
        updatedAt: timestamp,
      });
      return;
    }
    batch.set(ref, { ref, ...serialisedData, updatedAt: timestamp });
  });
  await batch.commit();
}

export async function upsertBulk<T extends object>(
  col: CollectionReference<T>,
  data: T[],
  uidPath: keyof T,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<void> {
  const batch: WriteBatch = writeBatch();
  const timestamp: Timestamp = toTimestamp();
  data.map((item: T) => {
    const serialisedData = toFirestore(item);
    const ref = doc(col, String(get(item, uidPath)));

    if (isObject(serialisedData) && !serialisedData.createdAt) {
      batch.set(ref, {
        ref,
        ...serialisedData,
        createdAt: timestamp,
        updatedAt: timestamp,
        createdBy: updatedBy,
        updatedBy,
      });
      return;
    }
    batch.set(ref, {
      ref,
      createdBy: updatedBy,
      ...serialisedData,
      updatedAt: timestamp,
      updatedBy,
    });
  });
  await batch.commit();
}

export async function upsertBulkUniqueCol<T extends object>(
  data: IBulkUploadData<T>[]
): Promise<void> {
  const batch: WriteBatch = writeBatch();
  const timestamp: Timestamp = toTimestamp();
  data.map((item) => {
    const serialisedData = toFirestore(item.data);
    const ref = item.uid
      ? doc(item.collectionRef, item.uid)
      : doc(item.collectionRef);

    if (isObject(serialisedData) && !serialisedData.createdAt) {
      batch.set(ref, {
        ref,
        ...serialisedData,
        createdAt: timestamp,
        updatedAt: timestamp,
      });
      return;
    }
    batch.set(ref, { ref, ...serialisedData, updatedAt: timestamp });
  });
  await batch.commit();
}

export interface IBulkUploadData<T> {
  collectionRef: CollectionReference<T>;
  data: T;
  uid?: string;
}

export function isBulkUploadData<T extends object = object>(
  item: unknown
): item is IBulkUploadData<T> {
  return (
    isObject(item) &&
    isString(item.uid) &&
    isColRef(item.collectionRef) &&
    isObject(item.data)
  );
}

export function runTransaction<T>(
  updateFunction: (transaction: Transaction) => Promise<T>
): Promise<T> {
  return runTransactionFirestore(
    store(FirestoreScheduler.appName),
    (transaction) => updateFunction(transaction as unknown as Transaction)
  );
}
