<div *ngIf="invoice$ | async as invoice">
  <pr-invoice-header [invoice]="invoice" />

  <div class="flex p-4">
    <div class="flex-1">
      <h2>From</h2>
      <pr-invoice-account-details [account]="invoice.from" />
    </div>
    <div class="flex flex-1 flex-col">
      <div class="flex items-center gap-2">
        <h2>To</h2>
        @if (nameHasChanged$ | async) {
          <button
            mat-icon-button
            matTooltip="Resync patient's name on the invoice"
            (click)="resyncName()"
          >
            <mat-icon color="warn">sync</mat-icon>
          </button>
        }
      </div>
      <pr-invoice-account-details [account]="invoice.to" />
    </div>
  </div>

  <div class="p-4">
    <pr-invoice-line-item-header />
    <ng-container *ngIf="groups$ | async as groups; else noGroups">
      <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
        <pr-line-item-group [lineItem]="group">
          <pr-treatment-provider
            class="mat-caption"
            [name]="group.user.name"
            [providerNumber]="group.providerNumber"
          />
          <pr-invoice-line-item-display
            *ngFor="let line of group.items; trackBy: trackByLineItem"
            [lineItem]="line"
          />
        </pr-line-item-group>
      </ng-container>
    </ng-container>

    <ng-template #noGroups>
      <ng-container
        *ngFor="
          let parent of lineItems.withChildren$ | async;
          trackBy: trackByGroup
        "
      >
        <pr-line-item-group [lineItem]="parent">
          <pr-treatment-provider
            class="mat-caption"
            *ngrxLet="parent | map: resolveProvider$ : this as provider"
            [name]="provider?.name"
            [providerNumber]="provider?.providerNumber"
          />
          <pr-invoice-line-item-display
            *ngFor="let line of parent.items; trackBy: trackByLineItem"
            [lineItem]="line"
          />
        </pr-line-item-group>
      </ng-container>
    </ng-template>

    <ng-container
      *ngFor="
        let line of lineItems.withoutChildren$ | async;
        trackBy: trackByLineItem
      "
    >
      <pr-invoice-line-item-display [lineItem]="line" />
    </ng-container>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="layout-margin">
    <pr-balance-display
      [balance]="balance$ | async"
      fxFlex="100"
      fxFlex.gt-sm="50"
    />
  </div>
</div>
