import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  type IFeeSchedule,
  type IFeeScheduleGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  serialise,
  shareReplayCold,
  unserialise$,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  loadFeeSchedules,
  selectFeeSchedule,
  type ILoadFeeSchedules,
} from '../actions';
import { type IChartState } from '../reducers/reducers';
import {
  getFeeScheduleEntities,
  getFeeScheduleGroups,
  getFeeSchedules,
  getSelectedFeeSchedule,
} from '../selectors/fee-schedule.selectors';

@Injectable()
export class FeeScheduleFacade {
  private _store = inject(Store<IChartState>);

  feeSchedules$: Observable<WithRef<IFeeSchedule>[]>;
  selectedFeeSchedule$: Observable<WithRef<IFeeSchedule> | undefined>;
  feeScheduleGroups$: Observable<IFeeScheduleGroup[]>;

  constructor() {
    this.feeSchedules$ = this._store
      .select(getFeeSchedules)
      .pipe(unserialise$(), shareReplayCold());
    this.selectedFeeSchedule$ = this._store
      .select(getSelectedFeeSchedule)
      .pipe(unserialise$(), shareReplayCold());
    this.feeScheduleGroups$ = this._store
      .select(getFeeScheduleGroups)
      .pipe(unserialise$(), shareReplayCold());
  }

  loadFeeSchedules(data: ILoadFeeSchedules): void {
    this._store.dispatch(loadFeeSchedules(serialise(data)));
  }

  selectFeeSchedule(ref: DocumentReference<IFeeSchedule>): void {
    this._store.dispatch(selectFeeSchedule(serialise({ ref })));
  }

  getFeeSchedule$(ref: DocumentReference): Observable<WithRef<IFeeSchedule>> {
    return this._store.pipe(
      select(getFeeScheduleEntities),
      unserialise$(),
      map((entities) => entities[ref.id]),
      filterUndefined()
    );
  }
}
