import {
  FeeSchedule,
  Patient,
  ReferralSource,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ContactNumberLabel,
  FailedDestinationEntityRecord,
  Gender,
  PatientStatus,
  SourceEntityRecordStatus,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedicareCard,
  type IPatient,
  type IPracticeMigration,
  type IReferralSource,
  type IReferralSourceConfiguration,
  type IStaffer,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  getDoc,
  getError,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { BasePatientDestinationEntity } from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ExactSex,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import { ExactContactMappingHandler } from '../mappings/contacts';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: `
    We have not implemented any family linking logic in this migration as yet. At most there is a head_of_family id but its not always used and, at times, users seem to
    store this data in other fields as if it were a note.

    Another thing to note is that Exact allows up to three referrals per patient row (where Principle only has one) so we are defining the order of preference as:
      1. Patient referrer (referral_patient_id)
      2. Contact Source (referrer_id)
      3. Referral Source (referral_source_id)
    `,
  },
});

export interface IPatientMigrationData {
  sourcePatientId: string;
  patient: IPatient;
  createdAt?: Timestamp;
}

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[];
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<IExactPatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  customMappings = {
    referralSources: new ExactContactMappingHandler(),
    staff: new ExactStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const referralSources$ =
      this.customMappings.referralSources.getRecords$(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(brand$, staff$, referralSources$),
        map(([sourcePatients, brand, staff, referralSources]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            brand,
            referralSources,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        errorResponseData,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const translations = data.sourcePatient.data.translations;
    const sourcePatientId =
      this.sourceEntities.patients.getSourceRecordId(patient);

    const referrer = await getReferralSource(
      patient,
      data.referralSources,
      translationMap
    );

    const preferredDentist = await getPractitioner(
      patient.preferred_practitioner_initials,
      translationMap,
      data.staff
    );
    const preferredHygienist = await getPractitioner(
      patient.preferred_hygienist_initials,
      translationMap,
      data.staff
    );
    const medicareCard = getMedicareCard(patient, translations);
    const preferredFeeSchedule = patient.preferred_fee_schedule
      ? await resolveFeeSchedule(
          translationMap,
          await FeeSchedule.getPreferredOrDefault(
            migration.configuration.organisation
          )
        )(patient.preferred_fee_schedule)
      : undefined;

    try {
      return {
        sourcePatientId,
        patient: Patient.init({
          name: getName(patient),
          email: patient.email ?? undefined,
          contactNumbers: getContactNumbers(patient),
          dateOfBirth: translations.dateOfBirth ?? undefined,
          gender: getGender(patient),
          address: getAddress(patient),
          referenceId: sourcePatientId,
          referrer,
          preferredDentist,
          preferredHygienist,
          preferredFeeSchedule,
          medicareCard,
          status: patient.is_inactive
            ? PatientStatus.Inactive
            : PatientStatus.Active,
          deleted: patient.is_deleted,
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }
}

function getName(patient: IExactPatient): string {
  return `${patient.first_name ?? ''} ${patient.last_name ?? ''}`;
}

async function getReferralSource(
  patient: IExactPatient,
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[],
  translationMap: TranslationMapHandler
): Promise<IReferralSource | undefined> {
  return patient.referral_patient_id
    ? getPatientReferralSource(patient.referral_patient_id, translationMap)
    : getReferralSourceFromMap(
        patient.referrer_id ?? patient.referral_source_id,
        referralSources
      );
}

async function getPatientReferralSource(
  id: string,
  translationMap: TranslationMapHandler
): Promise<IReferralSource | undefined> {
  const patientRef = await translationMap.getDestination<IPatient>(
    id,
    PATIENT_RESOURCE_TYPE
  );

  if (!patientRef) {
    return;
  }

  const referralPatient = await getDoc(patientRef);
  return ReferralSource.toReferrer(referralPatient);
}

async function getReferralSourceFromMap(
  id: string | null,
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[]
): Promise<IReferralSource | undefined> {
  if (!id) {
    return;
  }

  const referralSourceMap = referralSources.find(
    (referralSource) => referralSource.sourceIdentifier === id
  );
  if (!referralSourceMap?.destinationIdentifier) {
    return;
  }

  const referralSource = await getDoc(referralSourceMap.destinationIdentifier);
  return ReferralSource.toReferrer(referralSource);
}

function getGender(patient: IExactPatient): Gender {
  switch (patient.sex) {
    case ExactSex.Male:
      return Gender.Male;
    case ExactSex.Female:
      return Gender.Female;
    default:
      return Gender.NotSpecified;
  }
}

function getAddress(patient: IExactPatient): string {
  return [
    patient.home_address_1,
    patient.home_address_2,
    patient.home_address_suburb ?? patient.home_address_town_city,
    patient.home_address_postcode,
    patient.home_address_state,
  ].join(' ');
}

function getContactNumbers(patient: IExactPatient): IContactNumber[] {
  return compact([
    patient.mobile_number
      ? {
          label: ContactNumberLabel.Mobile,
          number: patient.mobile_number,
        }
      : undefined,
    patient.home_phone
      ? {
          label: ContactNumberLabel.Home,
          number: patient.home_phone,
        }
      : undefined,
    patient.work_phone
      ? {
          label: ContactNumberLabel.Work,
          number: patient.work_phone,
        }
      : undefined,
  ]);
}

async function getPractitioner(
  initials: string | null,
  translationMap: TranslationMapHandler,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer> | undefined> {
  if (!initials) {
    return;
  }
  const staffer = await resolveExactStaffer(initials, translationMap, staff);
  return staffer ? stafferToNamedDoc(staffer) : undefined;
}

function getMedicareCard(
  patient: IExactPatient,
  translations: IExactPatientTranslations
): IMedicareCard | undefined {
  if (!patient.medicare_card_number || !patient.medicare_card_position) {
    return;
  }
  return {
    number: patient.medicare_card_number,
    subNumerate: `${patient.medicare_card_position}`,
    expiryDate: translations.medicareExpiryDate,
  };
}
