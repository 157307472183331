import { titlecase } from '@principle-theorem/shared';
import { first, kebabCase } from 'lodash';

export class ReportBuilderHelpers {
  static getSectionFromPrefix(
    id: string,
    noSection?: string
  ): string | undefined {
    const segments = id.split('.');
    const section = segments.length > 1 ? first(segments) : undefined;
    const result = section ?? noSection;
    if (!result) {
      return;
    }
    return titlecase(kebabCase(result).replace(/-/g, ' '));
  }
}
