<form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column">
  <div class="form-errors" *ngIf="form.dirty && form.errors">
    <pt-alert
      *ngFor="let error of form.errors | keyvalue; trackBy: trackByError"
      [alert]="error.value"
     />
  </div>

  <mat-form-field fxFlex>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" required />
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" type="email" />
    </mat-form-field>

    <mat-form-field fxFlex hintLabel="Min {{ minPhoneLength }} characters">
      <mat-label>Phone</mat-label>
      <input matInput formControlName="phone" />
      <mat-hint align="end">
        {{ form.value?.phone?.length || 0 }}/{{ minPhoneLength }}
      </mat-hint>
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label>Address</mat-label>
    <input matInput formControlName="address" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Job Title</mat-label>
    <input matInput formControlName="jobTitle" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Mobile Number</mat-label>
    <input matInput formControlName="mobileNumber" />
  </mat-form-field>

  <button type="submit" hidden></button>
</form>
