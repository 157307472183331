import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IOptionGroup,
  OptionGroupSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ServiceProvider,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import {
  type IServiceCode,
  type IServiceCodeEntry,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, reduce2DArray } from '@principle-theorem/shared';
import { compact, sortBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'pr-add-service-code',
    templateUrl: './add-service-code.component.html',
    styleUrls: ['./add-service-code.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddServiceCodeComponent {
  trackByOption = TrackByFunctions.field<IServiceCode>('code');
  trackByGroup = TrackByFunctions.field<IOptionGroup<IServiceCode>>('name');
  search: OptionGroupSearchFilter<IServiceCode>;
  searchCtrl = new TypedFormControl<string>('');
  @Output() codeSelected = new EventEmitter<IServiceCode>();
  commonCodes$ = new BehaviorSubject<IServiceCodeEntry[]>([]);

  @Input()
  set commonCodes(commonCodes: IServiceCodeEntry[]) {
    if (commonCodes) {
      this.commonCodes$.next(commonCodes);
    }
  }

  constructor(org: OrganisationService) {
    const commonCodes$ = this.commonCodes$.pipe(
      map((commonCodes) => [
        {
          name: 'Common Codes',
          options: compact(
            commonCodes.map((commonCode) => {
              const code = ServiceProviderHandler.resolveServiceCode(
                commonCode.type,
                commonCode.code
              );
              if (!code) {
                return;
              }
              return {
                ...code,
                type: commonCode.type,
              };
            })
          ).filter((commonCode) => !commonCode.deleted),
          skipFilter: false,
        },
      ])
    );

    const serviceCodes$ = org.organisation$.pipe(
      filterUndefined(),
      map((organisation) =>
        ServiceProvider.providersByRegion(organisation.region)
      ),
      map((serviceProviders) =>
        serviceProviders.map((serviceProvider) => ({
          name: `${serviceProvider.label} Codes`,
          options: sortBy(Object.values(serviceProvider.items), 'code')
            .filter((code) => !code.deleted)
            .map((code) => ({
              ...code,
              type: serviceProvider.type,
            })),
          skipFilter: false,
        }))
      )
    );

    this.search = new OptionGroupSearchFilter<IServiceCode>(
      combineLatest([commonCodes$, serviceCodes$]).pipe(reduce2DArray()),
      this.searchCtrl.valueChanges.pipe(startWith('')),
      ['code', 'title']
    );
  }

  displayFn(code?: IServiceCode): string {
    return code ? `${code.code} - ${code.title}` : '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }
    this.codeSelected.emit(event.option.value as IServiceCode);
    this.searchCtrl.reset('');
  }
}
