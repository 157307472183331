<h1>What do you want to report on?</h1>

<form [formGroup]="queryForm" (ngSubmit)="submit()">
  <mat-form-field class="w-72">
    <mat-label>Select a data source</mat-label>
    <mat-select formControlName="dataSource" required>
      <mat-option
        *ngFor="let dataSource of dataSources; trackBy: trackByDataSource"
        [value]="dataSource"
        [matTooltip]="dataSource.description"
        matTooltipPosition="right"
        matTooltipShowDelay="300"
        >{{ dataSource.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <pr-query-scope-form
    *ngIf="queryForm.value.dataSource as dataSource"
    class="mb-4"
    [dataSource]="dataSource"
    (formChange)="setScopeRequests($event)"
  />

  <button mat-flat-button color="primary" [disabled]="submitDisabled$ | async">
    Start Exploring
  </button>
</form>
