import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAutomatedNotificationConfiguration,
  type IGeneratedTaskConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export interface ISelectAutomationTemplateRequest {
  notificationConfigs: WithRef<IAutomatedNotificationConfiguration>[];
  taskConfigs: WithRef<IGeneratedTaskConfiguration>[];
}

export interface ISelectAutomationTemplateResponse {
  config:
    | WithRef<IAutomatedNotificationConfiguration>
    | WithRef<IGeneratedTaskConfiguration>;
  task?: WithRef<IGeneratedTaskConfiguration>;
  notification?: WithRef<IAutomatedNotificationConfiguration>;
}

@Component({
  selector: 'pr-select-automation-configuration-dialog',
  templateUrl: './select-automation-configuration-dialog.component.html',
  styleUrls: ['./select-automation-configuration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAutomationConfigurationDialogComponent {
  trackByNotification =
    TrackByFunctions.ref<WithRef<IAutomatedNotificationConfiguration>>();
  trackByTask = TrackByFunctions.ref<WithRef<IGeneratedTaskConfiguration>>();
  hasNoAutomations: boolean;

  constructor(
    private _dialogRef: MatDialogRef<
      SelectAutomationConfigurationDialogComponent,
      ISelectAutomationTemplateResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: ISelectAutomationTemplateRequest
  ) {
    this.hasNoAutomations =
      !this.data.notificationConfigs.length && !this.data.taskConfigs.length;
  }

  selectNotification(
    config: WithRef<IAutomatedNotificationConfiguration>
  ): void {
    this._dialogRef.close({ config, notification: config });
  }

  selectTask(config: WithRef<IGeneratedTaskConfiguration>): void {
    this._dialogRef.close({ config, task: config });
  }
}
