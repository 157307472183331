<div
  class="dropzone"
  ptFileUploadDropzone
  (hovered)="toggleHover($event)"
  (dropped)="fileDropped($event)"
  [class.hovering]="isHovering"
>
  <input type="file" multiple #fileInput (change)="startUpload($event)" />

  <div
    class="content layout-padding"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <ng-content></ng-content>
    <p class="mat-caption">Drag and drop or</p>
    <button mat-stroked-button (click)="fileInput.click()">
      <div class="button-inner" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>cloud_upload</mat-icon>
        Choose a file...
      </div>
    </button>
  </div>
</div>
