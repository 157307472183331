import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  ContactNumberControls,
  CurrentBrandScope,
  OrganisationService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  type TypedFormArray,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  FeeSchedule,
  Staffer,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  GENDERS,
  PATIENT_STATUSES,
  isPatientDetails,
  type Gender,
  type IContactNumber,
  type IFeeSchedule,
  type IPatient,
  type IPatientDetails,
  type IPractice,
  type IStaffer,
  type ISuggestedPatientData,
  type ITag,
  type PatientOptionalField,
  type PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isPathChanged$,
  isSameRef,
  multiMap,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  reduce2DArray,
  snapshot,
  toNamedDocument,
  type CollectionReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { isEmpty, isNil, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { EditPatientFormGroup } from './edit-patient-form';

@Component({
  selector: 'pr-edit-patient-form',
  templateUrl: './edit-patient-form.component.html',
  styleUrls: ['./edit-patient-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class EditPatientFormComponent implements OnDestroy, OnInit {
  private _onDestroy$ = new Subject<void>();
  trackByPractitioner = TrackByFunctions.ref<INamedDocument<IStaffer>>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  trackByFeeSchedule = TrackByFunctions.ref<INamedDocument<IFeeSchedule>>();
  trackByGender = TrackByFunctions.variable<Gender>();
  trackByStatus = TrackByFunctions.variable<PatientStatus>();
  trackByIndex = TrackByFunctions.index();
  today: Moment = moment();
  patientForm = new EditPatientFormGroup();
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  practices$: Observable<INamedDocument<IPractice>[]>;
  feeSchedules$: Observable<INamedDocument<IFeeSchedule>[]>;
  genders: Gender[] = GENDERS;
  statuses: PatientStatus[] = PATIENT_STATUSES;
  tagCol$: Observable<CollectionReference<ITag>>;
  contactNumberControls: ContactNumberControls;
  patient$ = new BehaviorSubject<WithRef<IPatient> | undefined>(undefined);
  suggestedPatientData$ = new BehaviorSubject<
    ISuggestedPatientData | undefined
  >(undefined);
  patientSelectorCtrl = new TypedFormControl<WithRef<IPatient> | string>('');
  nameMatHint =
    'Preferred name example: Leonardo (Leo) Dicaprio. (Leo) will be removed from patient correspondence.';
  formReady$ = new BehaviorSubject(false);
  newPatientName$ = new ReplaySubject<string | undefined>(1);
  tagType = TagType.Patient;

  @Output() submitted = new EventEmitter<Partial<IPatientDetails>>();
  @Output() patientDetailsChanges = new EventEmitter<boolean>();
  @Input() tabGroup: MatTabGroup;
  @Input() emitPatientDataOnUpdate = false;

  @Input()
  set patient(patient: WithRef<IPatient> | undefined) {
    this.patient$.next(patient ?? undefined);
  }

  @Input()
  set newPatientName(name: string | undefined) {
    if (name) {
      this.newPatientName$.next(name);
    }
  }

  constructor(
    private _brandScope: CurrentBrandScope,
    private _organisation: OrganisationService,
    private _schedulingFacade: AppointmentSchedulingFacade
  ) {
    const organisation$ =
      this._organisation.organisation$.pipe(filterUndefined());
    this.contactNumberControls = new ContactNumberControls(this.contactNumbers);

    combineLatest([this.patient$, this.newPatientName$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([patient, name]) => {
        if (!patient && name) {
          this.patientForm.controls.name.setValue(name, { emitEvent: false });
          this.patientSelectorCtrl.setValue(name, { emitEvent: false });
        }
      });

    this.practitioners$ = this._brandScope.model$.pipe(
      switchMap((brand?) => {
        if (!brand) {
          return of([]);
        }
        return Staffer.practitionersByBrand$(brand);
      }),
      toINamedDocuments()
    );

    this.practices$ = this._brandScope.model$.pipe(
      switchMap((brand) => (brand ? Brand.practices$(brand) : of([]))),
      toINamedDocuments()
    );

    const organisationFeeSchedules$ = organisation$.pipe(
      switchMap((organisation) => FeeSchedule.all$(organisation))
    );

    const practiceFeeSchedules$ = this.practices$.pipe(
      multiSortBy$(nameSorter()),
      multiSwitchMap((practice) =>
        FeeSchedule.all$(practice).pipe(
          multiMap((feeSchedule) => ({
            ...feeSchedule,
            name: `${practice.name} - ${feeSchedule.name}`,
          }))
        )
      ),
      reduce2DArray()
    );

    this.feeSchedules$ = combineLatest([
      organisationFeeSchedules$,
      practiceFeeSchedules$,
    ]).pipe(reduce2DArray());

    this.tagCol$ = this._organisation.brand$.pipe(
      filterUndefined(),
      map((brand) => Brand.patientTagCol(brand))
    );

    this.patientForm.valueChanges
      .pipe(
        debounceTime(300),
        withLatestFrom(organisation$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([formData, { region }]) =>
        this.suggestedPatientData$.next({
          name: formData.name,
          dateOfBirth: formData.dateOfBirth,
          contactNumbers: formData.contactNumbers,
          region,
        })
      );

    this.patientSelectorCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((patient) => {
        if (!isNil(patient) && !isString(patient)) {
          this.selectPatient(patient);
          return;
        }
        this.patientForm.controls.name.setValue(patient);
      });

    this.patientForm.valueChanges
      .pipe(
        filter(() => this.emitPatientDataOnUpdate),
        debounceTime(300),
        withLatestFrom(this.patient$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([_, patient]) => {
        const patientDetails = this._getPatientDetails(patient);
        this.patientForm.valid && isPatientDetails(patientDetails)
          ? this.submitted.emit(patientDetails)
          : this._schedulingFacade.clearPatientDetails();
      });
  }

  ngOnInit(): void {
    this.patient$
      .pipe(isPathChanged$('ref.path'), takeUntil(this._onDestroy$))
      .subscribe((patient) => {
        this.formReady$.next(false);
        this.patientForm.reset({ emitEvent: false });
        this.patientSelectorCtrl.reset(undefined, { emitEvent: false });
        this.contactNumberControls.reset({ emitEvent: false });
        this.suggestedPatientData$.next(undefined);

        if (patient) {
          this.contactNumberControls.initContactNumberControls(patient, {
            emitEvent: false,
          });

          const address = !isEmpty(patient.metadata?.address)
            ? patient.metadata?.address
            : { address: patient.address };

          this.patientForm.patchValue(
            {
              ...patient,
              address,
              email: patient.email?.trim(),
            },
            { emitEvent: this.emitPatientDataOnUpdate }
          );
        }
        this.formReady$.next(true);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (this.patientForm.invalid || !this.patientForm.dirty) {
      return;
    }
    const patient = await snapshot(this.patient$);
    const patientDetails = this._getPatientDetails(patient);
    this.submitted.emit(patientDetails);
  }

  selectPatient(patient: WithRef<IPatient>): void {
    this._schedulingFacade.selectPatient(patient);
    this.tabGroup.selectedIndex = 0;
    this.patientSelectorCtrl.reset();
  }

  clearPatient(): void {
    this.patientSelectorCtrl.reset();
    this._schedulingFacade.clearPatient();
  }

  isSelectedNamedDocument(
    namedDocumentOption: INamedDocument,
    selectedNamedDocument?: INamedDocument
  ): boolean {
    if (!selectedNamedDocument) {
      return false;
    }
    return isSameRef(selectedNamedDocument, namedDocumentOption);
  }

  get contactNumbers(): TypedFormArray<IContactNumber> {
    return this.patientForm.controls
      .contactNumbers as TypedFormArray<IContactNumber>;
  }

  isRequired$(field: PatientOptionalField): Observable<boolean> {
    return this._organisation.brand$.pipe(
      map((brand) => {
        const requiredFields = brand?.settings.patient?.requiredFields ?? [];
        return requiredFields.includes(field);
      })
    );
  }

  private _getPatientDetails(
    patient?: WithRef<IPatient>
  ): Partial<IPatientDetails> & Partial<IPatient> {
    const data = this.patientForm.getRawValue();
    const address = data.address;
    const metadata = patient ? { ...patient.metadata, address } : { address };

    return {
      ...data,
      address: data.address?.address ?? '',
      metadata,
      preferredFeeSchedule: data.preferredFeeSchedule
        ? toNamedDocument(data.preferredFeeSchedule)
        : undefined,
    };
  }
}
