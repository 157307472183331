import {
  rand,
  randBetweenDate,
  randCity,
  randEmail,
  randLatitude,
  randLongitude,
  randPhoneNumber,
  randStreetAddress,
} from '@ngneat/falso';
import {
  IPractice,
  IRoster,
  IScheduleRange,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  DayOfWeek,
  DAYS_OF_WEEK,
  MockGenerator,
  Properties,
  slugify,
  TIMEZONES,
} from '@principle-theorem/shared';

export class PracticeMock
  extends BaseFirestoreMock
  implements Properties<IPractice>
{
  name = `${randCity()} Dental`;
  settings = {
    timezone: rand(TIMEZONES),
  };
  slug = slugify(this.name.toLowerCase());
  address = randStreetAddress();
  phone = randPhoneNumber();
  email = randEmail();
  yearOpened = randBetweenDate({ from: '1980', to: new Date() }).getFullYear();
  coordinates = {
    latitude: randLatitude(),
    longitude: randLongitude(),
  };
  schedule = MockPracticeSchedule();
  tyroSettings = {
    isEnabled: true,
    iClient: 'simulator',
    addCDBSAsPending: true,
  };
  smartpaySettings = {
    isEnabled: true,
  };
  hicapsConnectSettings = {};
  restrictions = {};
  enabledForSchedulingAggregateTrial = false;
}

function MockPracticeSchedule(): IRoster {
  const operatingDays: DayOfWeek[] = DAYS_OF_WEEK;

  const shifts: IScheduleRange[] = [
    { from: '08:00', to: '18:00' },
    { from: '07:00', to: '17:00' },
  ];

  const schedule: IRoster = {
    days: [],
  };

  operatingDays.map((day: DayOfWeek) => {
    schedule.days.push({
      dayOfWeek: day,
      shift: rand(shifts),
    });
  });

  return schedule;
}

export const PRACTICE_NAMES: string[] = ['McMahons Point', 'North Sydney'];

export const PRACTICES: IPractice[] = PRACTICE_NAMES.map((name: string) =>
  MockGenerator.generate(PracticeMock, { name })
);
