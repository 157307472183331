<ng-container *ngIf="patient$ | async as patient">
  <div class="flex items-center gap-2">
    <div class="flex-auto">
      <div class="flex items-end gap-2">
        <a
          [prBrandRouterLink]="['patients', patient.ref.id]"
          class="text-xl font-bold"
          [ngClass]="{
            'text-red-500': patient.status | map: isDisabledPatient
          }"
        >
          {{ patient?.name }}
        </a>
        <div class="leading-none">
          <pr-new-patient-icon [patient]="patient" iconSize="icon-md" />
        </div>
        <pr-patient-metadata-display-outlet
          [patient]="patient"
          [displayTarget]="metadataTargetPatientName"
        />
      </div>
      <div *ngIf="patient.status | map: isDisabledPatient">
        <strong>Status: </strong>
        <pr-patient-status [status]="patient.status" />
      </div>
      <div>
        {{ patient.gender | splitCamel | titlecase }} &bull;
        <ng-container *ngTemplateOutlet="dateOfBirth" />
      </div>
    </div>

    <button
      mat-icon-button
      class="icon-md"
      (click)="content.toggle()"
      *ngIf="expandable"
    >
      <mat-icon>
        {{ content.expanded ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </button>

    <ng-container *ngIf="actions$ | async as actions">
      <button
        mat-icon-button
        class="icon-md"
        [matMenuTriggerFor]="patientMenu"
        *ngIf="actions.length"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-container>
  </div>

  <pt-collapsible #content [expanded]="expanded$ | async">
    <ng-template ptCollapsibleContent>
      <div class="mt-2 flex gap-4">
        <div class="flex flex-auto flex-col gap-2 overflow-x-hidden">
          <div class="grid grid-cols-2">
            <div class="flex flex-col">
              <ng-container *ngTemplateOutlet="email" />
              <ng-container *ngTemplateOutlet="phoneNumber" />
            </div>
            <div
              class="max-w-[150px] pl-2"
              *ngIf="bloc.healthFundName$ | async as healthFundName"
            >
              <div class="text-xs opacity-80">Health Fund</div>
              <div
                class="truncate text-sm"
                [ptTruncateTooltip]="healthFundName"
              >
                {{ healthFundName }}
              </div>
            </div>
          </div>

          <ng-content />

          <div class="flex gap-2">
            <div class="w-1/2">
              <ng-container *ngTemplateOutlet="accountOutstanding" />
            </div>
            <div class="w-1/2">
              <ng-container *ngTemplateOutlet="accountCredit" />
            </div>
          </div>

          <ng-container *ngIf="bloc.tags$ | async as tags">
            <pr-tag-display-list
              *ngIf="tags.length"
              class="inline-block"
              [tags]="tags"
              [compact]="true"
            />
          </ng-container>

          <ng-content select="pr-appointment-tags-sidebar-list" />
          <pr-patient-metadata-display-outlet
            [patient]="patient"
            [displayTarget]="metadataTargetProfileCard"
          />
        </div>

        <pt-user-icon
          [src]="patient | profileImage$ | async"
          [name]="patient.name"
          [diameter]="100"
        />
      </div>
    </ng-template>
  </pt-collapsible>

  <ng-template #dateOfBirth>
    <ng-container *ngIf="patient.dateOfBirth; else noDateOfBirth">
      {{ patient.dateOfBirth | moment | amDateFormat: bloc.dobFormat }}
      &bull; {{ bloc.age$ | async }} years old
    </ng-container>
  </ng-template>

  <ng-template #noDateOfBirth>
    <span class="text-xs opacity-50">No date of birth</span>
  </ng-template>

  <ng-template #phoneNumber>
    <ng-container
      *ngIf="bloc.phoneNumber$ | async as phoneNumber; else noPhoneNumber"
    >
      <ng-container
        *ngIf="phoneActions$ | async as phoneActions; else phoneNoLink"
      >
        <ng-container *ngIf="phoneActions.length > 1; else phoneNoLink">
          <a
            [routerLink]="[]"
            class="truncate"
            [matMenuTriggerFor]="phoneMenu"
            >{{ phoneNumber }}</a
          >
        </ng-container>
      </ng-container>

      <ng-template #phoneNoLink>
        <span class="truncate"> {{ phoneNumber }}</span>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #noPhoneNumber>
    <span class="text-xs opacity-50">No phone number</span>
  </ng-template>

  <ng-template #email>
    <ng-container *ngIf="patient.email; else noEmail">
      <ng-container
        *ngIf="emailAction$ | async as emailAction; else emailNoLink"
      >
        <a
          *ngIf="(emailAction.isDisabled$ | async) === false; else emailNoLink"
          [routerLink]="[]"
          class="truncate"
          (click)="emailAction.run()"
          >{{ patient.email }}</a
        >
      </ng-container>

      <ng-template #emailNoLink>
        <span class="truncate">{{ patient.email }}</span>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #noEmail>
    <span class="text-xs opacity-50">No email</span>
  </ng-template>

  <ng-template #accountOutstanding>
    <div class="rounded-md bg-red-50 px-2 py-1 text-red-500">
      <div class="-mb-0.5 text-xs">Outstanding</div>
      <div
        class="text-base font-bold"
        *ngIf="(bloc.account.isLoading$ | async) === false; else accountLoader"
      >
        {{ bloc.account.outstanding$ | async | currency }}
      </div>
    </div>
  </ng-template>

  <ng-template #accountCredit>
    <div class="rounded-md bg-emerald-50 px-2 py-1 text-emerald-500">
      <div class="-mb-0.5 text-xs">Credit</div>
      <div
        class="text-base font-bold"
        *ngIf="(bloc.account.isLoading$ | async) === false; else accountLoader"
      >
        {{ bloc.account.creditRemaining$ | async | currency }}
      </div>
    </div>
  </ng-template>

  <ng-template #accountLoader>
    <mat-spinner color="primary" [diameter]="24" />
  </ng-template>

  <mat-menu #patientMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let action of actions$ | async; trackBy: trackByAction"
      [disabled]="action.isDisabled$ | async"
      (click)="action.run()"
    >
      <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
      <span>{{ action.name }}</span>
    </button>
  </mat-menu>

  <mat-menu #phoneMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let action of phoneActions$ | async; trackBy: trackByAction"
      [disabled]="action.isDisabled$ | async"
      (click)="action.run()"
    >
      <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
      <span>{{ action.name }}</span>
    </button>
  </mat-menu>
</ng-container>
