import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import {
  IBridgeDevice,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  CliniviewOpenPatientCommand,
  CliniviewToDeviceCommand,
  IDeviceCommand,
} from '@principle-theorem/principle-bridge-core';

@Injectable()
export class CliniviewFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async openPatient(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: IDeviceCommand<CliniviewOpenPatientCommand>['command'] = {
      type: CliniviewToDeviceCommand.OpenPatient,
      data: {
        patient,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
