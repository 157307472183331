import { isEqual } from 'lodash';

export class UndirectedGraphNode<T> {
  constructor(
    public value: T,
    public neighbours: T[]
  ) {}

  hasNeighbour(item: T): boolean {
    return this.neighbours.some((neighbour: T) => isEqual(neighbour, item));
  }

  addNeighbour(neighbour: T): void {
    if (this.hasNeighbour(neighbour)) {
      return;
    }
    this.neighbours.push(neighbour);
  }
}

export class UndirectedGraph<T> {
  private _nodes: UndirectedGraphNode<T>[] = [];

  constructor(items?: T[]) {
    if (items && items.length) {
      this._nodes = items.map((item: T) => this._toNode(item));
    }
  }

  get nodes(): UndirectedGraphNode<T>[] {
    return this._nodes;
  }

  addItem(item: T): UndirectedGraphNode<T> {
    let node: UndirectedGraphNode<T> | undefined = this.findByValue(item);
    if (node) {
      return node;
    }
    node = this._toNode(item);
    this._nodes.push(node);
    return node;
  }

  addNeighbours(itemA: T, itemB: T): void {
    let nodeA: UndirectedGraphNode<T> | undefined = this.findByValue(itemA);
    let nodeB: UndirectedGraphNode<T> | undefined = this.findByValue(itemB);

    if (!nodeA) {
      nodeA = this.addItem(itemA);
    }
    if (!nodeB) {
      nodeB = this.addItem(itemB);
    }
    nodeA.addNeighbour(nodeB.value);
    nodeB.addNeighbour(nodeA.value);
  }

  getNeighbours(item: T): T[] {
    const node: UndirectedGraphNode<T> | undefined = this.findByValue(item);
    if (!node) {
      return [];
    }
    return node.neighbours;
  }

  findByValue(value: T): UndirectedGraphNode<T> | undefined {
    return this._nodes.find((storedNode: UndirectedGraphNode<T>) => {
      return isEqual(storedNode.value, value);
    });
  }

  private _toNode(value: T): UndirectedGraphNode<T> {
    return new UndirectedGraphNode(value, []);
  }
}
