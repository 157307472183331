import { type IXSLXImport } from '@principle-theorem/shared';
import { type Row } from 'exceljs';
import {
  SINGLE_VALUE_HEADERS,
  type ISingleValueToXSLX,
} from './single-value-to-xlsx';

export class XSLXToSingleValue implements IXSLXImport<ISingleValueToXSLX> {
  headers = SINGLE_VALUE_HEADERS;

  translate(row: Row): ISingleValueToXSLX {
    return {
      label: row.getCell('label').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as ISingleValueToXSLX['mapTo'],
    };
  }
}
