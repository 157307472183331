import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AuthModule } from '../auth/auth.module';
import { NavigationModule } from '../navigation/navigation.module';
import { PipesModule } from '../pipes/pipes.module';
import { AddressInputComponent } from './address-input/address-input.component';
import { AppointmentTagsComponent } from './appointment-tags/appointment-tags.component';
import { AssigneeProfileSelectorComponent } from './assignee-profile-selector/assignee-profile-selector.component';
import { AutomationConfigurationStatusComponent } from './automation-configuration-status/automation-configuration-status.component';
import { BackgroundImageComponent } from './background-image/background-image.component';
import { ContactNumberInputComponent } from './contact-number-input/contact-number-input.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { DatePaginationComponent } from './date-pagination/date-pagination.component';
import { DateRangePaginationComponent } from './date-range-pagination/date-range-pagination.component';
import { DateRangePresetsHeaderComponent } from './date-range-pagination/date-range-presets-header/date-range-presets-header.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { DisplayColourBarComponent } from './display-colour-bar/display-colour-bar.component';
import { EditAccountCreditDialogComponent } from './edit-account-credit-dialog/edit-account-credit-dialog.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { EventableTagsPreviewComponent } from './eventable-tags-preview/eventable-tags-preview.component';
import { FormattedEventFieldComponent } from './formatted-event-field/formatted-event-field.component';
import { HistoryListItemComponent } from './history-list/history-list-item/history-list-item.component';
import { HistoryListComponent } from './history-list/history-list.component';
import { IncreaseDecreaseInputComponent } from './increase-decrease-input/increase-decrease-input.component';
import { InformationBoxComponent } from './information-box/information-box.component';
import { InlineTagsDisplayComponent } from './inline-tags-display/inline-tags-display.component';
import { InvoiceStatusComponent } from './invoice-status/invoice-status.component';
import { MapPreviewComponent } from './map-preview/map-preview.component';
import { MultiStaffSelectorComponent } from './multi-staff-selector/multi-staff-selector.component';
import { SelectedStafferComponent } from './multi-staff-selector/selected-staffer/selected-staffer.component';
import { StafferDisplayComponent } from './multi-staff-selector/staffer-display/staffer-display.component';
import { NewPatientIconComponent } from './new-patient-icon/new-patient-icon.component';
import { EditPatientMetadataDisplayComponent } from './patient-metadata/edit-patient-metadata-display/edit-patient-metadata-display.component';
import { PreviewPatientMetadataDisplayComponent } from './patient-metadata/edit-patient-metadata-preview/preview-patient-metadata-display.component';
import { PatientMetadataDisplayOutletComponent } from './patient-metadata/patient-metadata-display-outlet/patient-metadata-display-outlet.component';
import { PatientMetadataDisplayComponent } from './patient-metadata/patient-metadata-display/patient-metadata-display.component';
import { PatientProfileSummaryComponent } from './patient-profile-summary/patient-profile-summary.component';
import { PatientRelationshipSelectorComponent } from './patient-relationship-selector/patient-relationship-selector.component';
import { PatientSelectorItemComponent } from './patient-selector-item/patient-selector-item.component';
import { PatientSelectorComponent } from './patient-selector/patient-selector.component';
import { PatientSelectorStore } from './patient-selector/patient-selector.store';
import { PatientStatusComponent } from './patient-status/patient-status.component';
import { PhoneNumberPatientMatchesComponent } from './phone-number-patient-matches/phone-number-patient-matches.component';
import { PracticeSelectorComponent } from './practice-selector/practice-selector.component';
import { PrincipleLogoComponent } from './principle-logo/principle-logo.component';
import { ProfileCardButtonsComponent } from './profile-card/profile-card-buttons/profile-card-buttons.component';
import { ProfileCardHeaderComponent } from './profile-card/profile-card-header/profile-card-header.component';
import { ProfileCardTitleComponent } from './profile-card/profile-card-title/profile-card-title.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { RangeSelectorComponent } from './range-selector/range-selector.component';
import { ReferrerSelectorComponent } from './referrer-selector/referrer-selector.component';
import { ReferrerSelectorStore } from './referrer-selector/referrer-selector.store';
import { RelativeSchedulingSummaryComponent } from './relative-scheduling-summary/relative-scheduling-summary.component';
import { SchedulingEventDisplayComponent } from './scheduling-event/scheduling-event-display/scheduling-event-display.component';
import { SchedulingEventQueryFormComponent } from './scheduling-event/scheduling-event-query-form/scheduling-event-query-form.component';
import { SchedulingEventReasonSelectorComponent } from './scheduling-event/scheduling-event-reason-selector/scheduling-event-reason-selector.component';
import { SchedulingEventReasonTooltipComponent } from './scheduling-event/scheduling-event-reason-tooltip/scheduling-event-reason-tooltip.component';
import { SchedulingEventSnapshotComponent } from './scheduling-event/scheduling-event-snapshot/scheduling-event-snapshot.component';
import { SelectPractitionerDialogComponent } from './select-practitioner-dialog/select-practitioner-dialog.component';
import { FormSkeletonComponent } from './skeletons/form-skeleton/form-skeleton.component';
import { ListSkeletonComponent } from './skeletons/list-skeleton/list-skeleton.component';
import { TreatmentStepSkeletonComponent } from './skeletons/treatment-step-skeleton/treatment-step-skeleton.component';
import { SplitAccountCreditDialogComponent } from './split-account-credit-dialog/split-account-credit-dialog.component';
import { StaffSelectorComponent } from './staff-selector/staff-selector.component';
import { StaffSelectorStore } from './staff-selector/staff-selector.store';
import { StatusLabelComponent } from './status-label/status-label.component';
import { TagDisplayListComponent } from './tag-display-list/tag-display-list.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { TagLimitListComponent } from './tag-limit-list/tag-limit-list.component';
import { TagsInputComponent } from './tags-input/tags-input.component';
import { TocPageComponent } from './toc-page/toc-page.component';
import { TreatmentCategoryIndicatorComponent } from './treatment-category-indicator/treatment-category-indicator.component';
import { TreatmentStepCategoryIndicatorComponent } from './treatment-step-category-indicator/treatment-step-category-indicator.component';
import { HealthFundCardPreviewItemComponent } from './update-health-cards/health-fund-card-preview-item/health-fund-card-preview-item.component';
import { HealthFundCardPreviewComponent } from './update-health-cards/health-fund-card-preview/health-fund-card-preview.component';
import { UpdateHealthCardsComponent } from './update-health-cards/update-health-cards.component';
import { UpdateHealthCardsService } from './update-health-cards/update-health-cards.service';
import { WarningComponent } from './warning/warning.component';
import { CaptureMediaDialogComponent } from './capture-media-dialog/capture-media-dialog.component';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSharedModule,
    ReactiveFormsModule,
    PipesModule,
    NgMaterialModule,
    GoogleMapsModule,
    NavigationModule,
    AuthModule,
    AddressInputComponent,
    PatientMetadataDisplayComponent,
    PreviewPatientMetadataDisplayComponent,
    EditPatientMetadataDisplayComponent,
    PatientMetadataDisplayOutletComponent,
    EmptyStateComponent,
    ContentContainerComponent,
    InformationBoxComponent,
    StafferDisplayComponent,
    SchedulingEventReasonSelectorComponent,
    SchedulingEventReasonTooltipComponent,
    SchedulingEventSnapshotComponent,
    TocPageComponent,
    SchedulingEventQueryFormComponent,
    SchedulingEventDisplayComponent,
    TreatmentCategoryIndicatorComponent,
    StatusLabelComponent,
    WebcamModule,
  ],
  declarations: [
    DateSelectorComponent,
    TagsInputComponent,
    ReferrerSelectorComponent,
    InlineTagsDisplayComponent,
    PatientSelectorComponent,
    PatientRelationshipSelectorComponent,
    PrincipleLogoComponent,
    HistoryListComponent,
    HistoryListItemComponent,
    WarningComponent,
    BackgroundImageComponent,
    IncreaseDecreaseInputComponent,
    RelativeSchedulingSummaryComponent,
    DatePaginationComponent,
    PracticeSelectorComponent,
    ContactNumberInputComponent,
    MapPreviewComponent,
    ProfileCardComponent,
    ProfileCardTitleComponent,
    ProfileCardButtonsComponent,
    ProfileCardHeaderComponent,
    RangeSelectorComponent,
    PatientSelectorItemComponent,
    DisplayColourBarComponent,
    MultiStaffSelectorComponent,
    StaffSelectorComponent,
    AppointmentTagsComponent,
    SelectedStafferComponent,
    DateRangePaginationComponent,
    DateRangePresetsHeaderComponent,
    EventableTagsPreviewComponent,
    InvoiceStatusComponent,
    FormattedEventFieldComponent,
    FormSkeletonComponent,
    ListSkeletonComponent,
    TagDisplayListComponent,
    TagFilterComponent,
    AssigneeProfileSelectorComponent,
    PhoneNumberPatientMatchesComponent,
    TreatmentStepCategoryIndicatorComponent,
    TreatmentStepSkeletonComponent,
    SelectPractitionerDialogComponent,
    UpdateHealthCardsComponent,
    HealthFundCardPreviewComponent,
    HealthFundCardPreviewItemComponent,
    AutomationConfigurationStatusComponent,
    TagLimitListComponent,
    NewPatientIconComponent,
    PatientProfileSummaryComponent,
    PatientStatusComponent,
    EditAccountCreditDialogComponent,
    SplitAccountCreditDialogComponent,
    CaptureMediaDialogComponent,
  ],
  exports: [
    DateSelectorComponent,
    TagsInputComponent,
    ReferrerSelectorComponent,
    InlineTagsDisplayComponent,
    StatusLabelComponent,
    PatientSelectorComponent,
    PatientRelationshipSelectorComponent,
    PrincipleLogoComponent,
    HistoryListComponent,
    HistoryListItemComponent,
    WarningComponent,
    BackgroundImageComponent,
    IncreaseDecreaseInputComponent,
    RelativeSchedulingSummaryComponent,
    DatePaginationComponent,
    PracticeSelectorComponent,
    ContactNumberInputComponent,
    MapPreviewComponent,
    ProfileCardComponent,
    ProfileCardTitleComponent,
    ProfileCardButtonsComponent,
    ProfileCardHeaderComponent,
    RangeSelectorComponent,
    DisplayColourBarComponent,
    MultiStaffSelectorComponent,
    StaffSelectorComponent,
    AppointmentTagsComponent,
    DateRangePaginationComponent,
    EventableTagsPreviewComponent,
    EmptyStateComponent,
    InvoiceStatusComponent,
    FormattedEventFieldComponent,
    FormSkeletonComponent,
    ListSkeletonComponent,
    TagDisplayListComponent,
    TagFilterComponent,
    AssigneeProfileSelectorComponent,
    PhoneNumberPatientMatchesComponent,
    TreatmentCategoryIndicatorComponent,
    TreatmentStepCategoryIndicatorComponent,
    TreatmentStepSkeletonComponent,
    StafferDisplayComponent,
    SelectPractitionerDialogComponent,
    AutomationConfigurationStatusComponent,
    TagLimitListComponent,
    NewPatientIconComponent,
    PatientProfileSummaryComponent,
    InformationBoxComponent,
    PatientStatusComponent,
    ContentContainerComponent,
    AddressInputComponent,
    EditPatientMetadataDisplayComponent,
    PatientMetadataDisplayComponent,
    PatientMetadataDisplayOutletComponent,
    PreviewPatientMetadataDisplayComponent,
    SchedulingEventReasonSelectorComponent,
    SchedulingEventReasonTooltipComponent,
    SchedulingEventSnapshotComponent,
    TocPageComponent,
    SchedulingEventQueryFormComponent,
    SchedulingEventDisplayComponent,
    EditAccountCreditDialogComponent,
    SplitAccountCreditDialogComponent,
    CaptureMediaDialogComponent,
  ],
  providers: [
    ReferrerSelectorStore,
    StaffSelectorStore,
    PatientSelectorStore,
    UpdateHealthCardsService,
  ],
})
export class ComponentsModule {}
