import { NgModule } from '@angular/core';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { MentionModule } from '../mention/mention.module';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { ContentViewComponent } from './content-view/content-view.component';
import { EditorInputComponent } from './editor-input/editor-input.component';
import { NgxTiptapModule } from 'ngx-tiptap';

@NgModule({
  imports: [
    NgPrincipleSharedModule,
    NgEditorModule,
    MentionModule,
    NgxTiptapModule,
  ],
  declarations: [
    ContentEditorComponent,
    ContentViewComponent,
    EditorInputComponent,
  ],
  exports: [ContentEditorComponent, ContentViewComponent, EditorInputComponent],
})
export class PrincipleEditorModule {}
