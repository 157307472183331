import {
  DocumentReference,
  isDocRef,
  isINamedDocument,
  isTimestamp,
  Timestamp,
  TypeGuard,
  type INamedDocument,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import {
  IProviderData,
  IStaffer,
  ServiceTypeModality,
} from '../staffer/staffer';
import { ITransaction } from '../transaction/transaction';
import { IServiceCodeLineItem, ITreatmentLineItem } from './custom-line-items';

export const MAX_CLAIMABLE_ITEMS = 16;

export interface IHealthcareClaimGroup {
  items: IHealthcareClaimItem[];
}

export type IClaimProviderData = Omit<IProviderData, 'practiceRef'>;

export interface IHealthcareClaimProviderGroup extends IHealthcareClaimGroup {
  practitioner: INamedDocument<IStaffer>;
  providerData?: IClaimProviderData;
}

export interface IHealthcareClaimItem {
  treatmentUid: string;
  serviceCodeUid: string;
  quantity: number;
  provider: INamedDocument<IStaffer>;
}

export const isHealthcareClaimItem = TypeGuard.interface<IHealthcareClaimItem>({
  treatmentUid: isString,
  serviceCodeUid: isString,
  quantity: isNumber,
  provider: isINamedDocument,
});

export enum HealthcareClaimStatus {
  Unclaimed = 'unclaimed',
  Claimed = 'claimed',
  Cancelled = 'cancelled',
}

export interface IHealthcareClaim extends IHealthcareClaimProviderGroup {
  uid: string;
  status: HealthcareClaimStatus;
  transactions: DocumentReference<ITransaction>[];
  outdatedAt?: Timestamp;
}

export interface IClaimableItem {
  treatment: ITreatmentLineItem;
  serviceCode: IServiceCodeLineItem;
  quantity: number;
}

const isClaimProviderData = TypeGuard.interface<IClaimProviderData>({
  providerNumber: isString,
  providerModality: TypeGuard.enumValue(ServiceTypeModality),
  payeeProviderNumber: TypeGuard.undefinedOr(isString),
});

export const isHealthcareClaim = TypeGuard.interface<IHealthcareClaim>({
  uid: isString,
  status: TypeGuard.enumValue(HealthcareClaimStatus),
  transactions: TypeGuard.arrayOf(isDocRef),
  outdatedAt: TypeGuard.undefinedOr(isTimestamp),
  practitioner: isINamedDocument,
  items: TypeGuard.arrayOf(isHealthcareClaimItem),
  providerData: TypeGuard.undefinedOr(isClaimProviderData),
});
