import {
  ChartableSurface,
  IChartedItem,
  IChartedItemConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  initFirestoreModel,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { ChartedItem } from './core/charted-item';
import { ChartedItemDisplay } from './core/charted-item-display';

export class ChartedItemConfiguration {
  static init(
    overrides?: Partial<IChartedItemConfiguration>
  ): IChartedItemConfiguration {
    return {
      name: '',
      display: ChartedItemDisplay.init(),
      clinicalNotes: '',
      availableSurfaces: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static canBeChartedOn(
    config: IChartedItemConfiguration,
    surfaces: ChartableSurface[]
  ): boolean {
    if (!surfaces.length) {
      return false;
    }
    return surfaces.every((surface: ChartableSurface) => {
      return config.availableSurfaces.includes(surface);
    });
  }

  static toChartedItem(item: WithRef<IChartedItemConfiguration>): IChartedItem {
    return ChartedItem.init({
      config: toNamedDocument(item),
    });
  }
}

export type HasChartingOptions = Pick<
  IChartedItemConfiguration,
  'display' | 'availableSurfaces'
>;
