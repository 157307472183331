<div class="mx-auto mb-8 max-w-screen-xl px-4">
  <div class="px-4">
    <h2 class="pt-4 text-xl font-bold">Patient Sterilisation Records</h2>
  </div>
  <pr-sterilisation-record-list
    [records]="store.records$ | async"
    [packOptions]="packOptions$ | async"
    [omitFields]="['patient']"
  />
</div>
