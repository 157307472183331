import {
  ChartType,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { type IChartContents } from './measure-data-interfaces';

enum ChartColours {
  Purple = '#7b42f5',
  Indigo = '#3f51b5',
  DeepOrange = '#ff5722',
  Teal = '#10a5ce',
  Green = '#4caf50',
}

export interface IChartData {
  type: ChartType;
  options: IChartOptions;
}

export interface IChartPackageMap {
  [ChartType.Bar]: string[];
  [ChartType.Line]: string[];
  [ChartType.Pie]: string[];
  [ChartType.Table]: string[];
  [ChartType.Area]: string[];
  [ChartType.Combo]: string[];
  [ChartType.BasicLine]: string[];
  [ChartType.SanKey]: string[];
  [ChartType.Histogram]: string[];
  [ChartType.Scatter]: string[];
  [key: string]: string[];
}

export const PACKAGE_MAP: IChartPackageMap = {
  [ChartType.Bar]: ['bar'],
  [ChartType.Line]: ['line'],
  [ChartType.Table]: ['table'],
  [ChartType.Combo]: ['corechart'],
  [ChartType.Area]: ['corechart'],
  [ChartType.Pie]: ['corechart'],
  [ChartType.BasicLine]: ['corechart'],
  [ChartType.SanKey]: ['sankey'],
  [ChartType.Histogram]: ['corechart'],
  [ChartType.Scatter]: ['corechart'],
};

export interface IChartOptions {
  colors: (ChartColours | string)[];
  tooltip: object;
  legend?: string | object;
  chartArea?: object;
  pieHole?: number;
  seriesType?: string;
  series?: object;
  vAxis?: object;
  hAxis?: object;
  trendlines?: object;
  backgroundColor: string | google.visualization.ChartStrokeFill;
  isStacked?: boolean | 'relative' | 'percent' | 'absolute';
  title?: string;
  bars?: string;
  bar?: object;
  axes?: object;
  chart?: object;
  height?: number;
  width?: number;
}

export class ChartOptions {
  static init(overrides?: Partial<IChartOptions>): IChartOptions {
    return {
      colors: [
        ChartColours.Purple,
        ChartColours.Indigo,
        ChartColours.DeepOrange,
        ChartColours.Teal,
        ChartColours.Green,
      ],
      tooltip: {
        isHtml: true,
      },
      backgroundColor: 'none',
      ...overrides,
    };
  }
}

export interface IChartDataHandler {
  data: IChartData;
  config: IChartConfig;
  dataBuilder: IDataBuilder;
  chartContents: IChartContents;
  getDataTable(): Promise<google.visualization.DataTable>;
}

export class ChartBuilder {
  chartDataHandler: IChartDataHandler;
  chart: google.charts.BaseChart;

  constructor(chartDataHandler: IChartDataHandler) {
    this.chartDataHandler = chartDataHandler;
  }

  clone(): ChartBuilder {
    const builder = new ChartBuilder(this.chartDataHandler);
    builder.chart = this.chart;
    return builder;
  }

  get options(): IChartOptions {
    return this.chartDataHandler.data.options;
  }

  get packages(): string[] {
    return PACKAGE_MAP[this.chartDataHandler.data.type];
  }

  getChartClass(elem: Element): google.charts.BaseChart {
    switch (this.chartDataHandler.data.type) {
      case ChartType.BasicLine:
        return new google.visualization.LineChart(elem);
      case ChartType.Line:
        return new google.charts.Line(elem);
      case ChartType.Pie:
        return new google.visualization.PieChart(elem);
      case ChartType.Table:
        return new google.visualization.Table(elem);
      case ChartType.Combo:
        return new google.visualization.ComboChart(elem);
      case ChartType.Area:
        return new google.visualization.AreaChart(elem);
      case ChartType.SanKey:
        return new google.visualization.Sankey(elem);
      case ChartType.Histogram:
        return new google.visualization.Histogram(elem);
      case ChartType.Scatter:
        return new google.visualization.ScatterChart(elem);
      default:
        return new google.charts.Bar(elem);
    }
  }

  clear(): void {
    if (!this.chart) {
      return;
    }
    google.visualization.events.removeAllListeners(this.chart);
    this.chart.clearChart();
  }

  async draw(elem: Element): Promise<void> {
    this.chart = this.getChartClass(elem);
    const dataTable: google.visualization.DataTable =
      await this.chartDataHandler.getDataTable();
    if (this.chartDataHandler.data.type === ChartType.Line) {
      this.chart.draw(
        dataTable,
        google.charts.Line.convertOptions(this.options)
      );
      return;
    }
    if (this.chartDataHandler.data.type === ChartType.Bar) {
      this.chart.draw(
        dataTable,
        google.charts.Bar.convertOptions(this.options)
      );
      return;
    }
    this.chart.draw(dataTable, this.options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortPieData(rowA: any[], rowB: any[]): number {
    if (rowA[1] === rowB[1]) {
      return 0;
    }
    return rowA[1] > rowB[1] ? -1 : 1;
  }

  /**
   * Extends the current chart options with the given options.
   *
   * @param {object} [data={}]
   * @memberof ChartBuilder
   */
  addChartOptions(data: Partial<IChartOptions> = {}): ChartBuilder {
    this.chartDataHandler.data.options = ChartOptions.init({
      ...this.chartDataHandler.data.options,
      ...data,
    });
    return this;
  }
}
