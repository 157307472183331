<div *ngrxLet="appointment$ as appointment" class="item-content flex gap-4">
  <pt-user-icon
    class="-mr-3 self-center"
    [src]="practitionerImage$ | async"
    [name]="practitionerName$ | async"
    [diameter]="28"
  />

  <div class="flex flex-1 flex-col gap-0.5">
    <span class="mat-caption">Appointment</span>
    @if (appointmentDate$ | async; as appointmentDate) {
      {{ appointmentDate | moment | amDateFormat: dateFormat }}
    }
  </div>

  <div class="flex flex-col gap-0.5">
    <span class="mat-caption">Duration</span>
    <span>{{ duration$ | async }}</span>
  </div>
  <div class="flex flex-col justify-between gap-0.5">
    <span class="mat-caption">Score</span>
    <pr-appointment-score
      class="mb-1"
      [scoreType]="scoreType$ | async"
      [score]="score$ | async"
    />
  </div>
</div>
