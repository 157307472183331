import {
  type IAppointment,
  type ILabJob,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, toMoment, type WithRef } from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';

export function getNewDueDate(
  appointment: WithRef<IAppointment>,
  labJob: WithRef<ILabJob>
): moment.Moment | undefined {
  if (!appointment.event || isSameRef(appointment, labJob.appointment)) {
    return;
  }
  return toMoment(appointment.event.from).subtract(1, 'day');
}
