<div
  fxFill
  fxLayout="column"
  fxLayoutAlign="start center"
  *ngIf="chart && measure"
>
  <!-- <pr-measure-history-summary
    class="history-summary"
  ></pr-measure-history-summary> -->

  <pr-data-points-summary
    [chart]="chart.chartBuilder"
    [config]="config$ | async"
    [config]="dataBuilder$ | async"
    [measure]="measure"
    [showHistory]="false"
   />

  <p class="mat-subtitle-1 title">{{ chart.title }}</p>
</div>
