import { rand, randNumber, randSentence } from '@ngneat/falso';
import {
  IAppointment,
  IStaffer,
  ITreatmentCategory,
  ITreatmentStep,
  TREATMENT_STEP_STATUSES,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  INamedDocument,
  MockGenerator,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';
import { MockTreatment } from './charted-treatment.mock';
import { TreatmentStep } from './treatment-step';
import { TreatmentMock } from './treatment.mock';

export class TreatmentStepMock
  extends BaseFirestoreMock
  implements Properties<ITreatmentStep>
{
  uid = uuid();
  name = randSentence();
  appointment = MockDocRef<IAppointment>();
  status = rand(TREATMENT_STEP_STATUSES);
  treatments = [
    MockGenerator.generate(TreatmentMock),
    MockGenerator.generate(TreatmentMock),
  ];
  display = {
    overrideTreatmentCategory: MockDocRef<ITreatmentCategory>(),
    primaryTreatmentCategory: MockDocRef<ITreatmentCategory>(),
  };
  serviceCodes = [];
  schedulingRules = {
    minDays: randNumber({
      min: 1,
      max: 7,
    }),
    maxDays: randNumber({
      min: 14,
      max: 90,
    }),
    duration: randNumber({
      min: 15,
      max: 180,
    }),
  };
  practitionerRef = MockDocRef<IStaffer>();
}

export function MockTreatmentStep(
  data: Partial<ITreatmentStep>,
  staff: INamedDocument<IStaffer>[]
): ITreatmentStep {
  const step: ITreatmentStep = TreatmentStep.init();
  if (data.name) {
    step.name = data.name;
  }

  if (data.treatments) {
    step.treatments = data.treatments.map((treatment) =>
      MockTreatment(treatment, staff)
    );
  }

  if (data.schedulingRules) {
    return {
      ...step,
      schedulingRules: {
        ...step.schedulingRules,
        ...data.schedulingRules,
      },
    };
  }

  return step;
}
