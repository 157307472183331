<mat-toolbar color="accent">Select Bridge Device</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
    <mat-selection-list
      fxFlex
      hideSingleSelectionIndicator
      [multiple]="false"
      (selectionChange)="selectDevice($event)"
    >
      <mat-list-option
        *ngFor="let device of devices$ | async; trackBy: trackByDevice"
        [disabled]="device | map : isDisabled"
        [value]="device"
      >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>{{ device.name }}</div>
          <pr-bridge-device-status
            [status]="device.status"
            dense
           />
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
