import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  multiSwitchMap,
  type WithRef,
} from '@principle-theorem/shared';
import { differenceWith, uniq } from 'lodash';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IBrandPracticeAccess {
  brands: DocumentReference<IBrand>[];
  practices: DocumentReference<IPractice>[];
}

interface IAccessBrandOption {
  brand: WithRef<IBrand>;
  practices: WithRef<IPractice>[];
}

@Component({
  selector: 'pr-access-selector',
  templateUrl: './access-selector.component.html',
  styleUrls: ['./access-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessSelectorComponent {
  private _access$ = new BehaviorSubject<IBrandPracticeAccess>({
    brands: [],
    practices: [],
  });
  trackByAccessOption = TrackByFunctions.ref<IAccessBrandOption>('brand.ref');
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  options$: Observable<IAccessBrandOption[]>;
  brands$ = new ReplaySubject<WithRef<IBrand>[]>(1);
  @Output() accessChange = new EventEmitter<IBrandPracticeAccess>();
  @Input() disabled = false;

  constructor() {
    this.options$ = this.brands$.pipe(
      multiSwitchMap((brand) =>
        Brand.practices$(brand).pipe(
          map((practices) => ({
            brand,
            practices,
          }))
        )
      )
    );
  }

  @Input()
  set access(access: IBrandPracticeAccess) {
    if (!access) {
      return;
    }
    this._access$.next(access);
  }

  @Input()
  set brands(brands: WithRef<IBrand>[]) {
    if (brands) {
      this.brands$.next(brands);
    }
  }

  isBrandSelected(brand: WithRef<IBrand>): boolean {
    return this._access$.value.brands
      .map((ref: DocumentReference) => ref.path)
      .includes(brand.ref.path);
  }

  selectBrand(
    ref: DocumentReference<IBrand>,
    practices: WithRef<IPractice>[],
    checked: boolean
  ): void {
    const access: IBrandPracticeAccess = this._access$.value;
    if (checked) {
      access.brands = uniq([...access.brands, ref]);
    } else {
      access.brands = access.brands.filter(
        (item: DocumentReference) => item.path !== ref.path
      );
      access.practices = differenceWith(access.practices, practices, isSameRef);
    }
    this.accessChange.next(access);
  }

  isPracticeSelected(practice: WithRef<IPractice>): boolean {
    return this._access$.value.practices
      .map((ref: DocumentReference) => ref.path)
      .includes(practice.ref.path);
  }

  selectPractice(
    ref: DocumentReference<IPractice>,
    brand: WithRef<IBrand>,
    checked: boolean
  ): void {
    const access: IBrandPracticeAccess = this._access$.value;
    if (checked) {
      access.practices = uniq([...access.practices, ref]);
      access.brands = uniq([...access.brands, brand.ref]);
    } else {
      access.practices = access.practices.filter(
        (item: DocumentReference) => item.path !== ref.path
      );
    }
    this.accessChange.next(access);
  }
}
