import { Brand } from '@principle-theorem/principle-core';
import { type IReferralSourceConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type INamedDocument,
  type IReffable,
  multiFilter,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type WithRef,
  XSLXImporterExporter,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { scan } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  type ID4WPatientReferral,
  PatientReferralSourceEntity,
} from '../../source/entities/patient-referral-sources';
import { ReferralSourceToXSLX } from './referral-sources-to-xlsx';
import { XSLXToReferralSources } from './xlsx-to-referral-sources';
import { hardDeleteDoc } from '@principle-theorem/shared';

export const REFERRAL_SOURCE_CUSTOM_RESOURCE_TYPE = 'referralSource';

export const REFERRAL_SOURCES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Referral Sources',
    description: `This allows us to map D4W's referral sources to Principle's.`,
    type: REFERRAL_SOURCE_CUSTOM_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class D4WReferralSourceMappingHandler extends BaseCustomMappingHandler<IReferralSourceConfiguration> {
  customMapping = REFERRAL_SOURCES_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new PatientReferralSourceEntity();
    const records = await entity
      .getRecords$(migration, 1000)
      .pipe(
        multiFilter((record) => !!record.data.data.third_party_referrer_id),
        multiMap((record) => record.data.data),
        scan(
          (sources, newSources) => [...sources, ...newSources],
          [] as ID4WPatientReferral[]
        )
      )
      .toPromise();
    const options = uniqBy(
      records.map((source) => ({
        label: `${source.category_name ?? ''} - ${
          source.third_party_name ?? ''
        }`,
        value: source.id.toString(),
      })),
      (option) => option.value
    );

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IReferralSourceConfiguration>[]> {
    return snapshot(
      Brand.referralSources$(migration.configuration.brand).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `referral-source-mapping`;
    const referralSources = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      referralSources,
      new ReferralSourceToXSLX(
        await snapshot(Brand.referralSources$(migration.configuration.brand)),
        await snapshot(this.getRecords$(translationMap))
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToReferralSources()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await snapshot(this.getRecords$(translationMap));
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const referralSourceConfigurations =
      await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const referralSourceConfiguration = referralSourceConfigurations.find(
        (configuration) => configuration.name === mapTo
      );

      if (!referralSourceConfiguration) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: referralSourceConfiguration.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }
}
