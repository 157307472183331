<form [formGroup]="form">
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start start"
    fxLayoutGap.gt-sm="16px"
  >
    <mat-form-field fxFlex.gt-sm="50" fxFlex="100">
      <mat-label>{{ isFirst ? 'Primary ' : '' }}Contact Number</mat-label>
      <input
        [required]="isRequired$ | async"
        matInput
        formControlName="number"
      />
      <mat-hint *ngIf="isFirst">
        Default number used when contacting the patient
      </mat-hint>
    </mat-form-field>
    <div fxFlex.gt-sm="50" fxFlex="100">
      <mat-form-field fxFlex>
        <mat-label>Label</mat-label>
        <input matInput formControlName="label" [matAutocomplete]="auto" />
        <div
          matSuffix
          class="controls"
          (click)="$event.stopImmediatePropagation()"
        >
          <ng-content />
        </div>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="
            let option of filteredOptions$ | async;
            trackBy: trackByOption
          "
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
</form>
