import {
  Appointment,
  InvoiceContextBuilder,
  Patient,
  ScopeDataBuilder,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IInvoice,
  type IPatient,
  type IPrincipleMention,
  type ITemplateContextOption,
  MentionResourceType,
  TemplateScope,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  DATE_TIME_FORMAT,
  filterUndefined,
  getDoc,
  type IProvider,
  multiFilter,
  snapshot,
  sortByCreatedAt,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';

export class InvoiceMentionContextProvider
  implements IProvider<TemplateScope[], Promise<ITemplateContextOption[]>>
{
  constructor(
    private _mention: IPrincipleMention,
    private _appUrl: string
  ) {}

  canProvide(scopes: TemplateScope[]): boolean {
    const type = this._mention.resource.type;
    const isInvoiceScope = scopes.includes(TemplateScope.Invoice);
    const isInvoiceResource = type === MentionResourceType.Invoice;
    const isPatientResource = type === MentionResourceType.Patient;
    const isAppointmentResource = type === MentionResourceType.Appointment;
    return (
      isInvoiceScope &&
      (isInvoiceResource || isPatientResource || isAppointmentResource)
    );
  }

  async execute(): Promise<ITemplateContextOption[]> {
    const type = this._mention.resource.type;
    const isPatientResource = type === MentionResourceType.Patient;
    const isAppointmentResource = type === MentionResourceType.Appointment;

    if (isPatientResource) {
      const patient = await getDoc(
        asDocRef<IPatient>(this._mention.resource.ref)
      );
      return this._resolveInvoiceContextsFromPatient(patient);
    }

    if (isAppointmentResource) {
      const appointment = await getDoc(
        asDocRef<IAppointment>(this._mention.resource.ref)
      );
      const patient = await snapshot(Appointment.patient$(appointment));

      if (!appointment.invoiceRef) {
        return this._resolveInvoiceContextsFromPatient(patient);
      }
      return this._resolveInvoiceContextFromAppointment(appointment);
    }

    const invoice = await getDoc(
      asDocRef<IInvoice>(this._mention.resource.ref)
    );
    return [await this._resolveInvoiceContext(invoice)];
  }

  private async _resolveInvoiceContextFromAppointment(
    appointment: WithRef<IAppointment>
  ): Promise<ITemplateContextOption[]> {
    const invoice = await snapshot(
      Appointment.invoice$(appointment).pipe(filterUndefined())
    );

    return [await this._resolveInvoiceContext(invoice)];
  }

  private async _resolveInvoiceContextsFromPatient(
    patient: WithRef<IPatient>
  ): Promise<ITemplateContextOption[]> {
    const invoices = await snapshot(
      Patient.withPatientRelationships$(
        patient,
        [PatientRelationshipType.DuplicatePatient],
        Patient.invoices$
      ).pipe(
        multiFilter((invoice) => {
          const fromDate = moment().subtract(30, 'days');
          return toMoment(invoice.createdAt).isAfter(fromDate);
        }),
        map((results) => results.sort(sortByCreatedAt))
      )
    );

    return asyncForEach(invoices, (invoice) =>
      this._resolveInvoiceContext(invoice)
    );
  }

  private async _resolveInvoiceContext(
    invoice: WithRef<IInvoice>
  ): Promise<ITemplateContextOption> {
    const scopeData = await ScopeDataBuilder.buildInvoiceScopeData(
      invoice,
      this._appUrl
    );
    const context = new InvoiceContextBuilder(scopeData).build();

    const label = `${invoice.status.toUpperCase()} - ${
      invoice.practice.name
    } - ${toMoment(invoice.createdAt).format(DATE_TIME_FORMAT)}`;

    return {
      label: label,
      scope: TemplateScope.Invoice,
      context,
      scopeData,
    };
  }
}
