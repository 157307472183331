import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IIntegration } from '@principle-theorem/integrations';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { StripeFunctions, StripeService } from '@principle-theorem/ng-stripe';
import {
  getBaseTransaction,
  Invoice,
  Organisation,
  Transaction,
} from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IInvoice,
  type IOrganisation,
  type ITransaction,
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type WithRef,
  filterUndefined,
  getParentDocRef,
  Region,
} from '@principle-theorem/shared';
import { type ICreatePaymentIntentResponse } from '@principle-theorem/stripe';
import {
  type IStripeIntegrationData,
  StripeIntegrationStorage,
} from '@principle-theorem/stripe-integration';
import {
  type ConfirmCardPaymentData,
  type PaymentIntent,
  type Stripe,
} from '@stripe/stripe-js';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type ITransactionProvider,
  TransactionProviderType,
} from '../transaction-provider';
import {
  type IStripeFormData,
  type IStripeTransactionInput,
  StripeTransactionComponent,
} from './stripe-transaction.component';

@Injectable()
export class StripeTransactionProvider implements ITransactionProvider {
  providerId = TransactionProvider.Stripe;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$: Observable<boolean>;

  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _functions: StripeFunctions,
    private _stripe: StripeService,
    organisationService: OrganisationService,
    private _accountFunctions: AccountingFunctionsService
  ) {
    const storage = new StripeIntegrationStorage();
    this.isEnabled$ = organisationService.integrationCol$.pipe(
      filterUndefined(),
      switchMap((integrationsCol) =>
        storage.get$(storage.col(integrationsCol))
      ),
      map((data) => !!data)
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const stripe = await this._stripe.stripe;
    if (!stripe) {
      this._stripe.showStripeInitError();
      return;
    }

    const orgRef = this._resolveInvoiceOrg(invoice);
    const connectedAccount = await this._getStripeIntegration(orgRef);
    if (!connectedAccount) {
      this._snackbar.open(`Couldn't find Stripe Connected Account`);
      return;
    }

    const { amount } = await getBaseTransaction(invoice);
    const data: IStripeTransactionInput = {
      amount: amount || 0,
      invoice,
    };

    const formData = await this._dialog
      .open<
        StripeTransactionComponent,
        IStripeTransactionInput,
        IStripeFormData
      >(StripeTransactionComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();

    if (!formData) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const intent = await this._functions.createPaymentIntent(
      orgRef.id,
      formData.amount
    );
    const pending = await this._addPendingTransaction(
      invoice,
      intent.reference,
      formData
    );
    const success = await this._processPayment(
      stripe,
      intent,
      formData.token.id
    );
    await this._resolveTransaction(
      invoice,
      pending,
      success ? TransactionStatus.Complete : TransactionStatus.Failed
    );
  }

  private async _processPayment(
    stripe: Stripe,
    intent: ICreatePaymentIntentResponse,
    token: string
  ): Promise<PaymentIntent | undefined> {
    const paymentData: ConfirmCardPaymentData = {
      payment_method: {
        card: { token },
      },
    };
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      intent.clientSecret,
      paymentData
    );
    if (error || !paymentIntent) {
      const message = error?.message || `Couldn't process payment`;
      this._snackbar.open(`Transaction Failed: ${message}`);
      return;
    }
    return paymentIntent;
  }

  private async _addPendingTransaction(
    invoice: WithRef<IInvoice>,
    reference: string,
    formData: IStripeFormData
  ): Promise<ITransaction> {
    const baseTransaction = await getBaseTransaction(invoice);
    const pending: ITransaction = Transaction.init({
      ...baseTransaction,
      reference,
      type: TransactionType.Incoming,
      status: TransactionStatus.Pending,
      amount: formData.amount,
      provider: TransactionProvider.Stripe,
      practiceRef: formData.practiceRef,
    });
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      pending,
      TransactionAction.Add
    );
    return pending;
  }

  private async _resolveTransaction(
    invoice: WithRef<IInvoice>,
    pending: ITransaction,
    status: TransactionStatus
  ): Promise<ITransaction> {
    const received: ITransaction = Transaction.resolveAs(pending, status);
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      received,
      TransactionAction.Update
    );
    return received;
  }

  private _resolveInvoiceOrg(
    invoice: WithRef<IInvoice>
  ): DocumentReference<IOrganisation> {
    const practiceRef = invoice.practice.ref;
    const brandRef = getParentDocRef<IBrand>(practiceRef);
    const orgRef = getParentDocRef<IOrganisation>(brandRef);
    if (!orgRef) {
      throw new Error(`Couldn't find Organisation`);
    }
    return orgRef;
  }

  private _getStripeIntegration(
    orgRef: DocumentReference<IOrganisation>
  ): Promise<IIntegration<IStripeIntegrationData> | undefined> {
    const storage = new StripeIntegrationStorage();
    return storage.get(
      Organisation.integrationCol<IStripeIntegrationData>({
        ref: orgRef,
      })
    );
  }
}
