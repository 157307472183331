<button mat-stroked-button [matMenuTriggerFor]="viewMenu">
  {{ view }}
</button>

<mat-menu
  #viewMenu="matMenu"
  [overlapTrigger]="true"
  classList="chart-selector-menu"
>
  <ng-template matMenuContent>
    <h2>Views</h2>

    <ng-container *ngFor="let viewGroup of viewGroups; trackBy: trackByView">
      <mat-divider />

      <button
        *ngFor="let viewOption of viewGroup; trackBy: trackByView"
        mat-menu-item
        [ngClass]="{ selected: view === viewOption }"
        (click)="select(viewOption)"
      >
        {{ viewOption }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
