@if (interaction$ | async; as interaction) {
  <div
    class="m-4 flex flex-col overflow-hidden rounded"
    [ngClass]="{
      'border border-solid border-slate-200 bg-white': hasContent$ | async
    }"
  >
    <pr-interaction-summary
      class="px-2"
      [ngClass]="{ 'bg-slate-50 py-1': hasContent$ | async }"
      [pinnableTypes]="pinnableTypes$ | async"
      [interaction]="interaction"
      [compact]="isCompact$ | ngrxPush"
      [editable]="editable$ | async"
      (interactionUpdated)="update(interaction, $event)"
      (interactionDeleted)="interactionDeleted.emit()"
    />

    @if (hasContent$ | async) {
      <div class="flex flex-col border-t border-solid border-slate-200">
        @if (schedulingEvent$ | async; as schedulingEvent) {
          <pr-scheduling-event-interaction-details
            class="border-b border-solid border-slate-200 bg-slate-50 p-2"
            [schedulingEvent]="schedulingEvent"
          />
        }
        @if (tags$ | async; as tags) {
          @if (tags.length) {
            <div class="p-2">
              <pr-tag-display-list [tags]="tags" [compact]="true" />
            </div>
          }
        }
        @if (hasComments$ | async) {
          <div class="p-2">
            <pr-content-view [content]="interaction.content" />
          </div>
        }
      </div>
    }
  </div>
}
