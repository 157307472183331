@if (event$ | async; as event) {
  <div class="calendar-event-wrapper" [ngStyle]="eventStyle$ | async">
    <div class="calendar-event" (click)="selectEvent(event)">
      <div class="flex items-center gap-2">
        @if (eventTime$ | async; as eventTime) {
          <span> {{ eventTime }} </span>
        }
        @if (title$ | async; as title) {
          <span [ptTruncateTooltip]="title" class="title truncate">
            {{ title }}
          </span>
        }
      </div>
    </div>
  </div>
}
