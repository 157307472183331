import { ISoftDelete } from '@principle-theorem/shared';
import { IAddressMetadata } from '../address/address';

export type PatientMetadataValue = string | boolean | number | string[];

export type IPatientMetadata = Partial<ICustomMetadata> &
  Partial<ISystemPatientMetadata>;

interface ICustomPatientMetadata {
  [key: string]: PatientMetadataValue;
}

interface ICustomMetadata {
  custom: ICustomPatientMetadata;
}

interface ISystemPatientMetadata {
  rescheduleReasons: Record<string, PatientMetadataValue>;
  address: IAddressMetadata;
}

export enum PatientMetadataType {
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
}

export interface IPatientMetadataConfiguration extends ISoftDelete {
  key: string;
  label: string;
  description: string;
  type: PatientMetadataType;
  multiple: boolean;
  options?: string[];
  defaultValue?: PatientMetadataValue;
}

export enum PatientMetadataDisplayFormat {
  Label = 'label',
  LabelValue = 'labelValue',
  Value = 'value',
}

export enum PatientMetadataDisplayTarget {
  AppointmentSidebarBesidePatientName = 'appointmentSidebarBesidePatientName',
  AppointmentSidebarUnderTags = 'appointmentSidebarUnderTags',
  PatientProfileHeader = 'patientProfileHeader',
  PatientProfilePatientDetailsUnderTags = 'patientProfilePatientDetailsUnderTags',
}

interface IPatientMetadataDisplayTargetInfo {
  label: string;
  description: string;
  imageUrl: string;
}

export const PATIENT_METADATA_DISPLAY_TARGET_INFO: {
  [key in PatientMetadataDisplayTarget]: IPatientMetadataDisplayTargetInfo;
} = {
  [PatientMetadataDisplayTarget.AppointmentSidebarBesidePatientName]: {
    label: `Appointment sidebar - Next to patient's name`,
    description: `Shown in the sidebar next to the patient's name when scheduling, rescheduling, cancelling or during an appointment.`,
    imageUrl: `/assets/help-images/metadata-outlets/appointment-sidebar-beside-patient-name.png`,
  },
  [PatientMetadataDisplayTarget.AppointmentSidebarUnderTags]: {
    label: `Appointment sidebar - Under patient's tags`,
    description: `Shown in the sidebar under a patient's tags when scheduling, rescheduling, cancelling or during an appointment.`,
    imageUrl: `/assets/help-images/metadata-outlets/appointment-sidebar-under-tags.png`,
  },
  [PatientMetadataDisplayTarget.PatientProfileHeader]: {
    label: `Patient profile - Header`,
    description: `Shown in the header of a patient's profile. This is hidden on smaller screens.`,
    imageUrl: `/assets/help-images/metadata-outlets/patient-profile-header.png`,
  },
  [PatientMetadataDisplayTarget.PatientProfilePatientDetailsUnderTags]: {
    label: `Patient profile - Patient details under patient's tags`,
    description: `Shown in the Patient Details card on a patient's profile under the patient's tags.`,
    imageUrl: `/assets/help-images/metadata-outlets/patient-profile-patient-details-under-tags.png`,
  },
};

export interface IPatientMetadataDisplay extends ISoftDelete {
  label: string;
  metadataKey: string;
  format: PatientMetadataDisplayFormat;
  hexColour: string;
  displayTargets: PatientMetadataDisplayTarget[];
  onlyShowIfHasValue: boolean;
}

export const CANCELLATION_REASON_METADATA_CONFIGURATION: IPatientMetadataConfiguration =
  {
    key: 'cancellationReason',
    label: 'Cancellation/reschedule reason',
    description: 'The reason the appointment was cancelled/rescheduled.',
    type: PatientMetadataType.Number,
    multiple: false,
    defaultValue: 0,
    deleted: false,
  };

export const SYSTEM_METADATA_CONFIGURATIONS: IPatientMetadataConfiguration[] = [
  CANCELLATION_REASON_METADATA_CONFIGURATION,
];
