import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type IPracticeSmartpaySettings,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, snapshot, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-smartpay-configuration',
  templateUrl: './smartpay-configuration.component.html',
  styleUrls: ['./smartpay-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartpayConfigurationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form: TypedFormGroup<IPracticeSmartpaySettings>;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor() {
    this.form = new TypedFormGroup<IPracticeSmartpaySettings>({
      isEnabled: new TypedFormControl<boolean>(false),
    });
    this.practice$.pipe(takeUntil(this._onDestroy$)).subscribe((practice) => {
      this.form.patchValue(practice.smartpaySettings ?? {});
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const practice = await snapshot(this.practice$);
    const smartpaySettings = this.form.getRawValue();
    await Firestore.patchDoc(practice.ref, { smartpaySettings });
  }
}
