import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PAYMENT_TYPE_RESOURCE_TYPE = 'paymentTypes';

export const PAYMENT_TYPE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Payment Type List',
    description: '',
    idPrefix: PAYMENT_TYPE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePaymentType {
  id: number;
  name: string; // Medipass HealthPoint
  isActive: boolean;
}

export function isCorePracticePaymentType(
  item: unknown
): item is ICorePracticePaymentType {
  return TypeGuard.interface<ICorePracticePaymentType>({
    id: isNumber,
    name: isString,
    isActive: isBoolean,
  })(item);
}

export interface ICorePracticePaymentTypeTranslations {}

export interface ICorePracticePaymentTypeFilters {}

const PAYMENT_TYPE_SOURCE_QUERY = `
SELECT
  PaymentTypeId as id,
  PaymentTypeName as name,
  convert_to_boolean(IsActive) AS is_active
FROM tblPaymentType
`;

export class PaymentTypeSourceEntity extends BaseSourceEntity<
  ICorePracticePaymentType,
  ICorePracticePaymentTypeTranslations,
  ICorePracticePaymentTypeFilters
> {
  sourceEntity = PAYMENT_TYPE_SOURCE_ENTITY;
  entityResourceType = PAYMENT_TYPE_RESOURCE_TYPE;
  sourceQuery = PAYMENT_TYPE_SOURCE_QUERY;
  verifySourceFn = isCorePracticePaymentType;

  translate(
    _data: ICorePracticePaymentType,
    _timezone: Timezone
  ): ICorePracticePaymentTypeTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePaymentType): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePaymentType): string {
    return `${data.id} - ${data.name}`;
  }

  getFilterData(
    _data: ICorePracticePaymentType,
    _timezone: Timezone
  ): ICorePracticePaymentTypeFilters {
    return {};
  }
}
