import { Injectable } from '@angular/core';
import { type ICreateAction } from '@principle-theorem/ng-interactions';
import { ManageCalendarEventService } from './manage-calendar-event.service';

@Injectable()
export class CreateCalendarEventActionService implements ICreateAction {
  icon = 'add';
  name = 'New Event';

  constructor(private _calendarEvents: ManageCalendarEventService) {}

  do(): void {
    void this._calendarEvents.add();
  }
}
