<ng-container *ngIf="editable$ | async; else embed">
  <pt-drag-resize
    [disabled]="(selected$ | async) === false"
    (resizeUpdate)="updateSize($event)"
    #resize
  >
    <iframe
      class="max-w-full"
      allowfullscreen="true"
      [src]="embedUrl"
      [style.pointer-events]="'none'"
      [style.width]="width"
      [style.height]="height"
    ></iframe>
  </pt-drag-resize>
</ng-container>

<ng-template #embed>
  <iframe
    class="max-w-full"
    allowfullscreen="true"
    [src]="embedUrl"
    [style.width]="width"
    [style.height]="height"
  ></iframe>
</ng-template>
