<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Patient Reports"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <h2 class="layout-margin">Patient Reports</h2>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
  >
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
    />
    <pr-practice-selector
      subscriptSizing="dynamic"
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
    <pr-patients-report-type-selector
      (typeSelected)="reportType$.next($event)"
    />
  </div>

  <pr-patient-visits-report
    class="layout-margin"
    *ngIf="isPatientVisitReport$ | async"
    [dataBuilder]="dataBuilder"
  />

  <pr-referral-sources-report
    class="layout-margin"
    *ngIf="isReferralSourceReport$ | async"
    [dataBuilder]="dataBuilder"
  />
</div>
