import { initVersionedSchema } from '@principle-theorem/editor';
import {
  IPrescription,
  IStaffer,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  WithRef,
  initFirestoreModel,
  toTimestamp,
} from '@principle-theorem/shared';

export const PRESCRIPTION_STATUS_COLOUR_MAP = {
  [PrescriptionStatus.Draft]: 'default',
  [PrescriptionStatus.Printed]: 'success',
  [PrescriptionStatus.Sent]: 'success',
  [PrescriptionStatus.Issued]: 'primary',
  [PrescriptionStatus.Cancelled]: 'warn',
  [PrescriptionStatus.Delete]: 'delete',
};

export class Prescription {
  static init(
    overrides: AtLeast<IPrescription, 'patient' | 'prescriber' | 'practice'>,
    actionedByRef: DocumentReference<IStaffer>
  ): IPrescription {
    const firestoreModel = initFirestoreModel();
    return {
      items: [],
      status: PrescriptionStatus.Draft,
      statusHistory: [
        {
          status: PrescriptionStatus.Draft,
          updatedAt: firestoreModel.createdAt,
          actionedByRef,
        },
      ],
      notes: initVersionedSchema(),
      ...firestoreModel,
      ...overrides,
    };
  }

  static updateStatus(
    prescription: WithRef<IPrescription>,
    status: PrescriptionStatus,
    actionedByRef: DocumentReference<IStaffer>
  ): WithRef<IPrescription> {
    return {
      ...prescription,
      status,
      statusHistory: [
        ...prescription.statusHistory,
        {
          status,
          updatedAt: toTimestamp(),
          actionedByRef,
        },
      ],
    };
  }
}
