import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { CalendarComponent } from './calendar/calendar.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ScheduleHistoryComponent } from './schedule-history/schedule-history.component';
import { SchedulingEventActionsComponent } from './scheduling-event-actions/scheduling-event-actions.component';

@NgModule({
  declarations: [
    CalendarComponent,
    TimelineComponent,
    ScheduleComponent,
    ScheduleHistoryComponent,
    SchedulingEventActionsComponent,
  ],
  exports: [CalendarComponent],
  imports: [
    CommonModule,
    NgMaterialModule,
    ComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgEventableModule,
  ],
})
export class PagesModule {}
