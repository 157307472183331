<mat-toolbar color="accent">Automations</mat-toolbar>

<div fxFlex class="content">
  <div class="layout-margin">
    <pt-date-range-form
      *ngIf="dateRange$ | async as dateRange"
      [useRangePicker]="true"
      [from]="dateRange.from"
      [to]="dateRange.to"
      (changed)="dateRange$.next($event)"
     />
  </div>
  <div class="layout-margin">
    <pr-automations-list layout="table" />
  </div>
</div>

<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</pt-page-footer>
