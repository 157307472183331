import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ITransactionSourceSummary,
  type IPrincipleReconciliationReportData,
} from '@principle-theorem/reporting';
import { Observable, ReplaySubject } from 'rxjs';
import { ReconciliationReportFacade } from '../store/reconciliation-report.facade';
import { ReconciliationTransactionSummaryToCSV } from './reconciliation-transactions-summary/reconciliation-transactions-summary-to-csv';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-principle-reconciliation-report',
  templateUrl: './principle-reconciliation-report.component.html',
  styleUrls: ['./principle-reconciliation-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrincipleReconciliationReportComponent {
  report$ = new ReplaySubject<IPrincipleReconciliationReportData | undefined>(
    1
  );
  csvData$: Observable<ITransactionSourceSummary[]>;
  csvTranslator = new ReconciliationTransactionSummaryToCSV();

  constructor(public reportFacade: ReconciliationReportFacade) {
    this.csvData$ = this.report$.pipe(
      map((report) => [
        ...(report?.revenue.sources ?? []),
        ...(report?.discount.sources ?? []),
      ])
    );
  }

  @Input()
  set report(report: IPrincipleReconciliationReportData | undefined) {
    this.report$.next(report);
  }
}
