import { type WithRef } from '@principle-theorem/shared';
import { type ILab } from '@principle-theorem/principle-core/interfaces';
import { type IProfileLoader } from './profile-loader';
import { LoadLabActionService } from '@principle-theorem/ng-interactions';

export class LabLoader implements IProfileLoader {
  showInitials = false;
  name: string;

  constructor(public lab: WithRef<ILab>, public loader: LoadLabActionService) {
    this.name = lab.name;
  }

  async do(): Promise<void> {
    await this.loader.do(this.lab);
  }
}
