import { type SUPPORTED_REGIONS } from 'firebase-functions';
import { SupportedRegion } from 'firebase-functions/v2/options';

export const FUNCTIONS_REGION: (typeof SUPPORTED_REGIONS)[number] =
  'australia-southeast1';

export const FUNCTIONS_V2_REGION: SupportedRegion = 'australia-southeast1';

export function firebaseFunctionUrl(
  region: (typeof SUPPORTED_REGIONS)[number],
  projectUid: string,
  functionPath: string
): string {
  return [
    `https://${region}-${projectUid}.cloudfunctions.net`,
    functionPath,
  ].join('/');
}
