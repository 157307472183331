import { type IXSLXImport } from '@principle-theorem/shared';
import {
  IStafferToPracticeXSLX,
  STAFF_TO_PRACTICE_HEADERS,
} from './practitioner-to-practice-to-xslx';
import { Row } from 'exceljs';

export class XSLXToStafferToPractice
  implements IXSLXImport<IStafferToPracticeXSLX>
{
  headers = STAFF_TO_PRACTICE_HEADERS;

  translate(row: Row): IStafferToPracticeXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      name: row.getCell('name').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IStafferToPracticeXSLX['mapTo'],
    };
  }
}
