import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { CustomReport } from '@principle-theorem/principle-core';
import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import {
  ICustomChartSettings,
  MeasureFormatter,
  type ICustomReportChartSection,
  type ICustomReportFilter,
  CustomChartType,
} from '@principle-theorem/principle-core/interfaces';
import { type BaseMeasures } from '@principle-theorem/reporting';
import { filterUndefined, snapshot, uid } from '@principle-theorem/shared';
import { flatten } from 'lodash';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportBuilderStore } from '../report-builder.store';
import {
  IReportBuilderAddChartRequest,
  IReportBuilderAddChartResponse,
  ReportBuilderAddChartDialogComponent,
} from '../report-builder-results-table/report-builder-add-chart-dialog/report-builder-add-chart-dialog.component';
import { ReportBuilderDataSource } from '../../../models/report-builder-data-sources/report-builder-data-source';

class ViewToggle {
  constructor(public isShown = true) {}
  toggle(): void {
    this.isShown = !this.isShown;
  }
}

@Component({
  selector: 'pr-report-builder-chart-filters',
  templateUrl: './report-builder-chart-filters.component.html',
  styleUrls: ['./report-builder-chart-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderChartFiltersComponent {
  trackByChartSection = TrackByFunctions.uniqueId<ICustomReportChartSection>();
  chartSections$: Observable<ICustomReportChartSection[]>;
  showAvailableCharts$ = new BehaviorSubject<boolean>(true);
  availableChartsView = new ViewToggle();
  customReportView = new ViewToggle();
  factTable$: Observable<BaseMeasures>;
  dynamicFilters$: Observable<ICustomReportFilter[]>;
  createCustomReportsPermission =
    ReportingPermissions.ReportingCustomReportEdit;
  hasCharts$: Observable<boolean>;

  constructor(
    public store: ReportBuilderStore,
    private _dialog: BasicDialogService
  ) {
    this.chartSections$ = this.store.display$.pipe(
      map((display) => display?.chartSections ?? [])
    );
    this.factTable$ = this.store.dataSource$.pipe(
      map((dataSource) => dataSource?.factTable),
      filterUndefined()
    );
    this.dynamicFilters$ = this.store.display$.pipe(
      map((display) => display?.dynamicFilters ?? [])
    );
    this.hasCharts$ = this.chartSections$.pipe(
      map(
        (sections) =>
          flatten(sections.map((section) => section.charts)).length > 0
      )
    );
  }

  async addNewSection(): Promise<void> {
    const name = await this._dialog.prompt({
      title: 'Add New Section',
      prompt: 'Enter a name for the new section',
      submitLabel: 'Add',
    });
    if (!name) {
      return;
    }
    const current = await snapshot(this.store.display$);
    if (!current) {
      return;
    }

    this.store.setDisplay({
      ...current,
      chartSections: [
        ...current.chartSections,
        CustomReport.chartSection(name, []),
      ],
    });
  }

  async addChartAtIndex(
    section: ICustomReportChartSection,
    index: number
  ): Promise<void> {
    await this.addCustomChart(section.name, index + 1);
  }

  async addCustomChart(sectionName?: string, index?: number): Promise<void> {
    const dataSource = await snapshot(this.store.dataSource$);
    if (!dataSource) {
      return;
    }

    const filteredData = await snapshot(
      this.store.results$.pipe(map((results) => results?.filtered ?? []))
    );
    const data: IReportBuilderAddChartRequest = {
      measures: [
        ...ReportBuilderDataSource.allColumns(dataSource).map(
          (column) => column.measure
        ),
        dataSource.factTable.count,
      ],
      groupBys: dataSource?.groupByOptions ?? [],
      filteredData,
    };

    const result = await this._dialog
      .open<
        ReportBuilderAddChartDialogComponent,
        IReportBuilderAddChartRequest,
        IReportBuilderAddChartResponse
      >(
        ReportBuilderAddChartDialogComponent,
        DialogPresets.almostFullscreen({ data })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }

    await this.store.addChart(
      CustomReport.chart<ICustomChartSettings>(
        result.type,
        [
          {
            uid: uid(),
            label: result.measure.metadata.label,
            filters: result.filters,
            dataPoint: result.measure.metadata.id,
            reducer: result.reducer,
          },
        ],
        result.label,
        result.groupBy.metadata.id,
        {
          colourOverride: !!result.groupBy.groupMeasure.colourAccessor,
          plottedOverTime: [
            MeasureFormatter.Timestamp,
            MeasureFormatter.Day,
          ].includes(result.groupBy.metadata.formatter ?? MeasureFormatter.Text)
            ? true
            : false,
        },
        {
          rotateXAxisLabels:
            result.type === CustomChartType.Column ? true : false,
        }
      ),
      sectionName,
      index
    );
  }
}
