import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import { ReportBuilderStore } from './report-builder.store';

@Component({
  selector: 'pr-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderComponent {
  createCustomReportsPermission =
    ReportingPermissions.ReportingCustomReportEdit;
  breadcrumbs: IBreadcrumb[] = [
    { label: 'Reporting' },
    { label: 'Report Builder' },
  ];

  constructor(public store: ReportBuilderStore) {
    this.store.reset();
  }
}
