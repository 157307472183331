<mat-toolbar color="accent" class="min-h-[50px]">
  Preview
  @if (config$ | async; as config) {
    {{ config.name }}
  } @else {
    Form
  }
</mat-toolbar>

<div class="flex grow flex-col overflow-scroll">
  <div class="p-4">
    @if (layout$ | async; as layout) {
      <pr-preview-form-issue
        [layout]="layout"
        (layoutChange)="layoutChanges$.next($event)"
      />
    } @else {
      @if (customFormData$ | async; as customFormData) {
        <pr-custom-form [form]="customFormData" [disabled]="true" />
      } @else {
        <pr-empty-state
          image="search"
          title="Form Preview"
          [descriptionEnabled]="false"
        />
      }
    }
  </div>
</div>

<mat-dialog-actions class="!p-4">
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submit()">
      Generate Form
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
