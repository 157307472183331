import {
  type INamedDocument,
  isEnumValue,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type IPatient } from './patient';

export enum PatientRelationshipType {
  Parent = 'parent',
  Child = 'child',
  Sibling = 'sibling',
  Partner = 'partner',
  Guardian = 'guardian',
  Referred = 'referred',
  ReferredBy = 'referred by',
  Unknown = 'unknown',
  DuplicatePatient = 'duplicatePatient',
  PrimaryPatient = 'primaryPatient',
}

export const PATIENT_RELATIONSHIP_TYPES: PatientRelationshipType[] = [
  PatientRelationshipType.Parent,
  PatientRelationshipType.Child,
  PatientRelationshipType.Sibling,
  PatientRelationshipType.Partner,
  PatientRelationshipType.Guardian,
  PatientRelationshipType.Referred,
  PatientRelationshipType.ReferredBy,
  PatientRelationshipType.Unknown,
];

export interface IPatientRelationMap {
  type: PatientRelationshipType;
  inverse: PatientRelationshipType;
}

export const RELATIONSHIP_TYPE_MAP: IPatientRelationMap[] = [
  {
    type: PatientRelationshipType.Parent,
    inverse: PatientRelationshipType.Child,
  },
  {
    type: PatientRelationshipType.Child,
    inverse: PatientRelationshipType.Parent,
  },
  {
    type: PatientRelationshipType.Sibling,
    inverse: PatientRelationshipType.Sibling,
  },
  {
    type: PatientRelationshipType.Partner,
    inverse: PatientRelationshipType.Partner,
  },
  {
    type: PatientRelationshipType.Guardian,
    inverse: PatientRelationshipType.Child,
  },
  {
    type: PatientRelationshipType.Referred,
    inverse: PatientRelationshipType.ReferredBy,
  },
  {
    type: PatientRelationshipType.ReferredBy,
    inverse: PatientRelationshipType.Referred,
  },
  {
    type: PatientRelationshipType.Unknown,
    inverse: PatientRelationshipType.Unknown,
  },
  {
    type: PatientRelationshipType.DuplicatePatient,
    inverse: PatientRelationshipType.PrimaryPatient,
  },
  {
    type: PatientRelationshipType.PrimaryPatient,
    inverse: PatientRelationshipType.DuplicatePatient,
  },
];

export interface IPatientRelationship<T = IPatient> {
  patient: INamedDocument<T>;
  type: PatientRelationshipType;
}

export function isPatientRelationship(
  data: unknown
): data is IPatientRelationship {
  return (
    isObject(data) &&
    'patient' in data &&
    isINamedDocument(data.patient) &&
    'type' in data &&
    isEnumValue(PatientRelationshipType, data.type)
  );
}
