import { NgModule } from '@angular/core';
import { NgAppointmentModule } from '@principle-theorem/ng-appointment';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgGapsModule } from '@principle-theorem/ng-gaps';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgPrincipleModule } from '@principle-theorem/ng-principle';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { SchedulingAlertItemComponent } from './scheduling-alert-item/scheduling-alert-item.component';
import { AlertsHeaderComponent } from './scheduling-alerts-list/alerts-header/alerts-header.component';
import { LabJobCardComponent } from './scheduling-alerts-list/lab-job-card/lab-job-card.component';
import { SchedulingAlertsListComponent } from './scheduling-alerts-list/scheduling-alerts-list.component';
import { SchedulingAlertsComponent } from './scheduling-alerts.component';

@NgModule({
  imports: [
    NgPrincipleModule,
    NgGapsModule,
    NgSharedModule,
    NgInteractionsModule,
    NgPrincipleSharedModule,
    NgAppointmentModule,
    NgEventableModule,
  ],
  declarations: [
    SchedulingAlertsComponent,
    SchedulingAlertsListComponent,
    AlertsHeaderComponent,
    LabJobCardComponent,
    SchedulingAlertItemComponent,
  ],
})
export class SchedulingAlertsModule {}
