import { type DocumentReference } from '@principle-theorem/shared';
import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IStaffer } from '../../staffer/staffer';
import {
  ChartedItemType,
  type IChartedItem,
  isChartedItem,
} from '../core/charted-item';
import { type IFeeSchedule } from '../fees/fee-schedule/fee-schedule';
import {
  type IChartedServiceSmartGroup,
  type IPricedServiceCodeEntry,
  type IPricedServiceCodeGroup,
} from '../service-provider/providers/ada-code';
import { type ITreatmentConfiguration } from './treatment-configuration';
import { TaxStrategy } from '@principle-theorem/accounting';

export function isChartedTreatment(
  chartedItem: unknown
): chartedItem is IChartedTreatment {
  return (
    isChartedItem(chartedItem) &&
    isObject(chartedItem) &&
    isString(chartedItem.type) &&
    chartedItem.type === ChartedItemType.ChartedTreatment &&
    isINamedDocument(chartedItem.feeSchedule)
  );
}

export function isChartedTreatmentGroup(
  chartedItem: unknown
): chartedItem is IChartedTreatmentGroup {
  return (
    isChartedItem(chartedItem) &&
    chartedItem.type === ChartedItemType.ChartedTreatmentGroup
  );
}

export interface IInvoiceReference {
  invoiceRef: DocumentReference;
  lineItemUid: string;
  amount: number;
}

export interface IChartedTreatment
  extends IChartedItem<ITreatmentConfiguration> {
  type: ChartedItemType.ChartedTreatment;
  serviceCodeSmartGroups: IChartedServiceSmartGroup[];
  serviceCodeGroups: IPricedServiceCodeGroup[];
  serviceCodes: IPricedServiceCodeEntry[];
  invoices: IInvoiceReference[];
  basePrice: number;
  feeSchedule: INamedDocument<IFeeSchedule>;
  attributedTo?: INamedDocument<IStaffer>;
  isGrouped?: boolean;
  treatmentPackageId?: string;
  taxStatus?: TaxStrategy;
}

export interface IChartedTreatmentGroup
  extends IChartedItem<ITreatmentConfiguration> {
  type: ChartedItemType.ChartedTreatmentGroup;
  treatments: IChartedTreatment[];
  feeSchedule: INamedDocument<IFeeSchedule>;
  attributedTo?: INamedDocument<IStaffer>;
}
