import {
  type IChartedTreatment,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { DragDropGroup, type IDragDropNode } from './drag-drop-group';

export type TreatmentParent =
  | WithRef<ITreatmentStep>
  | ITreatmentStep
  | undefined;
export type TreatmentItem = IChartedTreatment;
export type ITreatmentDragDropNode = IDragDropNode<
  TreatmentParent,
  TreatmentItem
>;

export class TreatmentDragDropGroup extends DragDropGroup<
  TreatmentParent,
  TreatmentItem
> {}
