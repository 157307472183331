import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { queryParam$ } from '@principle-theorem/ng-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'pr-register-page',
  templateUrl: './register-page.component.html',
  styleUrl: './register-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPageComponent {
  name$: Observable<string>;
  email$: Observable<string>;

  constructor(private _route: ActivatedRoute) {
    this.name$ = queryParam$(this._route, 'name');
    this.email$ = queryParam$(this._route, 'email');
  }
}
