<ng-container *ngIf="tooth$ | async as tooth">
  <mat-menu class="pr-right-click-menu">
    <ng-template matMenuContent>
      <button
        mat-menu-item
        [matMenuTriggerFor]="rootsMenu"
        [disabled]="disabled$ | async"
      >
        Update Roots
      </button>
      <mat-divider />
      <pr-chart-surface-menu
        [view]="view$ | async"
        [chartedItems]="chartedItems$ | async"
       />
      <mat-divider />
      <button
        mat-menu-item
        [disabled]="disabled$ | async"
        (click)="removeTooth(tooth)"
      >
        Remove Tooth
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #rootsMenu="matMenu" class="pr-right-click-menu">
    <ng-template matMenuContent>
      <button
        mat-menu-item
        *ngFor="let roots of rootOptions; trackBy: trackByRoot"
        (click)="changeRoots(roots, tooth)"
        [disabled]="tooth.roots === roots"
      >
        {{ roots }}
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
