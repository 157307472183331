import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CustomFormElement } from '@principle-theorem/principle-core';
import {
  AnyCustomFormElement,
  CustomFormElementCategory,
  CustomFormElementType,
  isCustomFormElementType,
} from '@principle-theorem/principle-core/interfaces';

export type DropListEnterPredicate<DragType, ListType = DragType[]> = (
  drag: CdkDrag<DragType>,
  drop: CdkDropList<ListType>
) => boolean;

export type FormBuilderDropListEnterPredicate = DropListEnterPredicate<
  AnyCustomFormElement | CustomFormElementType
>;

export class FormBuilderDragDrop {
  static onlyAllowCategoryFn(
    category: CustomFormElementCategory
  ): FormBuilderDropListEnterPredicate {
    return (drag: CdkDrag<AnyCustomFormElement | CustomFormElementType>) => {
      const elementType = isCustomFormElementType(drag.data)
        ? drag.data
        : drag.data.type;
      const elementCategory = CustomFormElement.getCategory(elementType);
      return elementCategory === category;
    };
  }
}
