import {
  isSameToothRef,
  toothRefToLabel,
} from '@principle-theorem/principle-core';
import {
  PerioDataPoint,
  PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import type { default as HandsOnTable } from 'handsontable';
import { first, upperCase } from 'lodash';
import {
  HANDS_ON_TABLE_LICENSE,
  getNumberOfColumns,
  reduceCells,
  setColumnWidths,
} from '../handson-table-helpers';
import {
  type IPerioTable,
  type IPerioTableCell,
  type PerioMeasurementValue,
} from './perio-table';
import { DetailedSettings as NestedHeadersDetailedSettings } from 'handsontable/plugins/nestedHeaders';
import { DetailedSettings as MergeCellDetailedSettings } from 'handsontable/plugins/mergeCells';

export function perioTableToHandsOnTable(
  perioTable: IPerioTable
): HandsOnTable.GridSettings {
  const data = getData(perioTable);
  return {
    data,
    nestedHeaders: [
      getToothHeaders(perioTable),
      getColHeaders(perioTable, dcmColumnHeader),
    ],
    rowHeaders: getRowHeaders(perioTable),
    mergeCells: mergeMobilityFieldsByTooth(perioTable),
    type: 'numeric',
    readOnly: perioTable.disabled,
    height: 225,
    rowHeaderWidth: 125,
    autoRowSize: false,
    autoColumnSize: false,
    licenseKey: HANDS_ON_TABLE_LICENSE,
    colWidths: setColumnWidths(getNumberOfColumns(data), 25),
  };
}

function getRowHeaders(perioTable: IPerioTable): string[] {
  return perioTable.rows
    .map((row) => first(row))
    .map((cell, index) => (cell ? cell.metadata.measurement : `${index}`))
    .map((label) => upperCase(label));
}

export function getColHeaders(
  perioTable: IPerioTable,
  transformFn: (cell: IPerioTableCell) => string
): string[] {
  const firstRow = first(perioTable.rows) || [];
  return firstRow.map((cell) => transformFn(cell));
}

/**
 * Useful for debugging to display the dataPoint of each column
 */
export function debugColumnHeader(cell: IPerioTableCell): string {
  const tooth = `${cell.metadata.toothRef.quadrant}${cell.metadata.toothRef.quadrantIndex}`;
  return `${tooth} ${cell.metadata.dataPoint}`;
}

export function dcmColumnHeader(cell: IPerioTableCell): string {
  const PERIO_DATA_POINT_LABEL_MAP: Record<PerioDataPoint, string> = {
    [PerioDataPoint.FacialDistal]: 'D',
    [PerioDataPoint.PalatalDistal]: 'D',
    [PerioDataPoint.FacialCentral]: 'C',
    [PerioDataPoint.PalatalCentral]: 'C',
    [PerioDataPoint.FacialMesial]: 'M',
    [PerioDataPoint.PalatalMesial]: 'M',
  };
  return PERIO_DATA_POINT_LABEL_MAP[cell.metadata.dataPoint];
}

function getData(perioTable: IPerioTable): PerioMeasurementValue[][] {
  return perioTable.rows.map((row) => row.map((cell) => cell.value));
}

function getToothHeaders(
  perioTable: IPerioTable
): NestedHeadersDetailedSettings[] {
  const firstRow = first(perioTable.rows) || [];
  const incrementFn = (value: NestedHeadersDetailedSettings): number =>
    (value.colspan += 1);
  const createFn = (
    current: IPerioTableCell
  ): NestedHeadersDetailedSettings => ({
    label: toothRefToLabel(current.metadata.toothRef),
    colspan: 1,
  });
  return firstRow.reduce<NestedHeadersDetailedSettings[]>(
    reduceCells(isSameAsLastTooth, incrementFn, createFn),
    []
  );
}

export function mergeMobilityFieldsByTooth(
  perioTable: IPerioTable
): MergeCellDetailedSettings[] {
  const mobilityIndex = getMeasurementRowIndex(
    perioTable,
    PerioMeasurement.Mobility
  );
  const mobilityRow = perioTable.rows[mobilityIndex];
  const incrementFn = (value: MergeCellDetailedSettings): number =>
    (value.colspan += 1);
  const createFn = (
    _: IPerioTableCell,
    index: number
  ): MergeCellDetailedSettings => ({
    row: mobilityIndex,
    col: index,
    colspan: 1,
    rowspan: 1,
  });
  return mobilityRow.reduce<MergeCellDetailedSettings[]>(
    reduceCells(isSameAsLastTooth, incrementFn, createFn),
    []
  );
}

function isSameAsLastTooth(
  current: IPerioTableCell,
  previous?: IPerioTableCell
): boolean {
  return previous
    ? isSameToothRef(current.metadata.toothRef, previous.metadata.toothRef)
    : false;
}

function getMeasurementRowIndex(
  perioTable: IPerioTable,
  measurement: PerioMeasurement
): number {
  return perioTable.rows.findIndex(
    (row) => first(row)?.metadata.measurement === measurement
  );
}
