@if (type === 'image') {
  <section class="flex flex-col items-center justify-center">
    <img *ngIf="imagePath$ | async as imagePath" [src]="imagePath" />
    <div class="flex flex-col items-center justify-center gap-1.5">
      <h2>No {{ title | titlecase }}</h2>
      @if (isSearch$ | async) {
        <p class="mat-body">
          Sorry, no results found for
          <span class="mat-body-strong">{{ searchInputValue }}</span>
        </p>
      } @else {
        <p class="mat-body" *ngIf="descriptionEnabled">
          Any new {{ title | lowercase }} will appear here
        </p>
      }
      <ng-content />
    </div>
  </section>
} @else {
  <section class="flex flex-col items-center justify-center">
    <span class="mat-caption">No {{ title | titlecase }}</span>
  </section>
}
