import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { GeneralTrackingCodeHelpComponent } from './general-tracking-code-help/general-tracking-code-help.component';
import { GoogleAnalyticsTrackingCodeHelpComponent } from './google-analytics-tracking-code-help/google-analytics-tracking-code-help.component';
import { GoogleTagManagerTrackingCodeHelpComponent } from './google-tag-manager-tracking-code-help/google-tag-manager-tracking-code-help.component';
import { HubSpotTrackingCodeHelpComponent } from './hub-spot-tracking-code-help/hub-spot-tracking-code-help.component';
import { MetaPixelTrackingCodeHelpComponent } from './meta-pixel-tracking-code-help/meta-pixel-tracking-code-help.component';

@Injectable()
export class TrackingCodeHelpService {
  private _dialog = inject(MatDialog);

  async openGeneral(): Promise<void> {
    await this._dialog
      .open(
        GeneralTrackingCodeHelpComponent,
        DialogPresets.extraLarge({ maxHeight: '90vh' })
      )
      .afterClosed()
      .toPromise();
  }

  async openGoogleAnalytics(): Promise<void> {
    await this._dialog
      .open(
        GoogleAnalyticsTrackingCodeHelpComponent,
        DialogPresets.medium({ maxHeight: '90vh' })
      )
      .afterClosed()
      .toPromise();
  }

  async openGoogleTagManager(): Promise<void> {
    await this._dialog
      .open(
        GoogleTagManagerTrackingCodeHelpComponent,
        DialogPresets.medium({ maxHeight: '90vh' })
      )
      .afterClosed()
      .toPromise();
  }

  async openMetaPixel(): Promise<void> {
    await this._dialog
      .open(
        MetaPixelTrackingCodeHelpComponent,
        DialogPresets.medium({ maxHeight: '90vh' })
      )
      .afterClosed()
      .toPromise();
  }

  async openHubSpot(): Promise<void> {
    await this._dialog
      .open(
        HubSpotTrackingCodeHelpComponent,
        DialogPresets.medium({ maxHeight: '90vh' })
      )
      .afterClosed()
      .toPromise();
  }
}
