import { htmlToVersionedSchema } from '@principle-theorem/editor';
import {
  AnyCustomFormElement,
  AnyFormLayout,
  CustomFormContentElement,
  CustomFormElementType,
  CustomFormInputFieldElement,
  CustomFormSectionElement,
  FormLayoutElementType,
  IFormSchema,
  IFormSchemaProperty,
  IJsonSchemaForm,
} from '@principle-theorem/principle-core/interfaces';
import { PartialRequired, omitUndefined } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { compact } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  AngularJsonSchemaForm,
  EMPTY_FORM_SCHEMA,
} from './angular-json-schema-form';
import { CustomFormElementBlueprint } from './custom-form-element-blueprints';

interface IPlaceElementState {
  sections: CustomFormSectionElement[];
  sectionPointer?: CustomFormSectionElement;
  lastElement?: AnyCustomFormElement;
}

export class AJSFToCustomForm {
  static transform(
    jsonSchemaForm: IJsonSchemaForm,
    editorExtensions: AnyExtension[]
  ): CustomFormSectionElement[] {
    const rootSchema = jsonSchemaForm.schema ?? EMPTY_FORM_SCHEMA;
    const allElements = compact(jsonSchemaForm.layout).flatMap((layout) =>
      this.getElements(layout, rootSchema, editorExtensions)
    );
    return this.placeElements(allElements);
  }

  static getElements(
    layout: AnyFormLayout,
    rootSchema: IFormSchema,
    editorExtensions: AnyExtension[]
  ): AnyCustomFormElement[] {
    const childrenElements = compact(layout.items).flatMap((child) =>
      this.getElements(child, rootSchema, editorExtensions)
    );
    const parentElement = this.getElement(layout, rootSchema, editorExtensions);
    return compact([parentElement, ...childrenElements]);
  }

  static getElement(
    layout: AnyFormLayout,
    rootSchema: IFormSchema,
    editorExtensions: AnyExtension[]
  ): AnyCustomFormElement | undefined {
    const schema = AngularJsonSchemaForm.getSchemaProperty(
      rootSchema,
      layout.key
    );
    const elementType = this.getElementType(layout, schema);

    const dataKey = layout.key ?? uuid();
    const label = AngularJsonSchemaForm.getLayoutLabel(layout, schema);
    const required = AngularJsonSchemaForm.getRequired(layout, schema);
    const html = AngularJsonSchemaForm.getRawHtml(layout);

    if (!elementType && (label || html)) {
      return CustomFormElementBlueprint.createSection(
        omitUndefined({
          title: label,
          content: html
            ? htmlToVersionedSchema(html, editorExtensions)
            : undefined,
        })
      );
    }

    switch (elementType) {
      case CustomFormElementType.Content:
        // TODO: sometimes we use `mat-toolbar` in a content element as a title
        // this should really be converted into a section with a title, but its
        // hard to identify and may not be worth the effort.
        return CustomFormElementBlueprint.createContent(
          omitUndefined({
            content: htmlToVersionedSchema(html ?? '', editorExtensions),
          })
        );
      case CustomFormElementType.Text:
        return CustomFormElementBlueprint.createText(
          omitUndefined({ dataKey, label, required })
        );
      case CustomFormElementType.Textarea:
        return CustomFormElementBlueprint.createTextarea(
          omitUndefined({ dataKey, label, required })
        );
      case CustomFormElementType.Number:
        return CustomFormElementBlueprint.createNumber(
          omitUndefined({ dataKey, label, required })
        );
      case CustomFormElementType.Checkbox:
        return CustomFormElementBlueprint.createCheckbox(
          omitUndefined({ dataKey, label, required })
        );
      case CustomFormElementType.Date:
        return CustomFormElementBlueprint.createDate(
          omitUndefined({ dataKey, label, required })
        );
      case CustomFormElementType.Dropdown:
        const options = AngularJsonSchemaForm.getEnumValues(layout, schema);
        return CustomFormElementBlueprint.createDropdown(
          omitUndefined({ dataKey, label, required, options })
        );
      case CustomFormElementType.Signature:
        return CustomFormElementBlueprint.createSignature(
          omitUndefined({ dataKey, label, required })
        );
      default:
        return;
    }
  }

  static getElementType(
    layout: AnyFormLayout,
    schema?: IFormSchemaProperty | IFormSchema
  ): CustomFormElementType | undefined {
    if (layout.type === FormLayoutElementType.Help) {
      return CustomFormElementType.Content;
    }
    if (layout.type === FormLayoutElementType.Date) {
      return CustomFormElementType.Date;
    }
    if (
      layout.type === FormLayoutElementType.TextArea ||
      layout.type === FormLayoutElementType.CustomTextArea
    ) {
      return CustomFormElementType.Textarea;
    }
    if (layout.type === FormLayoutElementType.Signature) {
      return CustomFormElementType.Signature;
    }
    if (schema) {
      const inputType = AngularJsonSchemaForm.getInputType(schema, layout);
      switch (inputType) {
        case 'text':
          return CustomFormElementType.Text;
        case 'checkbox':
          return CustomFormElementType.Checkbox;
        case 'select':
          return CustomFormElementType.Dropdown;
        default:
          return undefined;
      }
    }
    return;
  }

  static placeElements(
    elements: AnyCustomFormElement[]
  ): CustomFormSectionElement[] {
    const initialState: IPlaceElementState = { sections: [] };
    const result = elements.reduce(
      (state, element) => this.placeElement(state, element),
      initialState
    );
    return result.sections;
  }

  static placeElement(
    state: IPlaceElementState,
    element: AnyCustomFormElement
  ): IPlaceElementState {
    switch (element.type) {
      case CustomFormElementType.Section:
        return this.placeSection(state, element);
      case CustomFormElementType.Content:
        return this.placeContent(state, element);
      case CustomFormElementType.Textarea:
      case CustomFormElementType.Signature:
        return this.placeInputField(state, element, 1);
      case CustomFormElementType.Text:
      case CustomFormElementType.Number:
      case CustomFormElementType.Checkbox:
      case CustomFormElementType.Date:
      case CustomFormElementType.Dropdown:
        return this.placeInputField(state, element, 2);
      default:
        return state;
    }
  }

  static placeSection(
    state: IPlaceElementState,
    element: CustomFormSectionElement
  ): IPlaceElementState {
    return {
      sections: [...state.sections, element],
      sectionPointer: element,
      lastElement: element,
    };
  }

  static placeContent(
    state: IPlaceElementState,
    element: CustomFormContentElement
  ): IPlaceElementState {
    const adjustedState = this.upsertSection(state, 1);
    CustomFormElementBlueprint.appendElementToSection(
      adjustedState.sectionPointer,
      element
    );
    return {
      ...adjustedState,
      lastElement: element,
    };
  }

  static placeInputField(
    state: IPlaceElementState,
    element: CustomFormInputFieldElement,
    numberOfColumns: number
  ): IPlaceElementState {
    const adjustedState = this.upsertSection(state, numberOfColumns);
    CustomFormElementBlueprint.appendElementToSection(
      adjustedState.sectionPointer,
      element
    );
    return {
      ...adjustedState,
      lastElement: element,
    };
  }

  static upsertSection(
    state: IPlaceElementState,
    numberOfColumns: number
  ): PartialRequired<IPlaceElementState, 'sectionPointer'> {
    if (
      state.sectionPointer &&
      state.sectionPointer.options.numberOfColumns === numberOfColumns
    ) {
      return { ...state, sectionPointer: state.sectionPointer };
    }
    const section = CustomFormElementBlueprint.createSection({
      numberOfColumns,
    });
    return {
      ...state,
      sections: [...state.sections, section],
      sectionPointer: section,
    };
  }
}
