@if (form$ | async; as form) {
  <form [formGroup]="form">
    <div class="flex gap-4">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Skip Practitioner</mat-label>
        <mat-select formControlName="skipPractitioners" multiple>
          @for (
            practitioner of availablePractitioners$ | async;
            track practitioner.ref.path
          ) {
            <mat-option [value]="practitioner.ref">
              {{ practitioner.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (selectedType$ | async; as selectedType) {
        @if (selectedType === practitionerGapReport) {
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Group Records By</mat-label>
            <mat-select formControlName="groupRecordsBy">
              <mat-option value="day">Day</mat-option>
              <mat-option value="week">Week</mat-option>
            </mat-select>
          </mat-form-field>
        }
      }
    </div>
  </form>
}
