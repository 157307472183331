<ng-container *ngrxLet="treatmentSteps$ as treatmentSteps">
  <div class="flex flex-col">
    <ng-container
      *ngFor="
        let treatmentStep of treatmentSteps;
        trackBy: trackByTreatmentStep
      "
    >
      <pr-expandable-treatment-step
        [treatmentPlan]="treatmentPlan$ | async"
        [treatmentStep]="treatmentStep"
        [currentPractitioner]="currentPractitioner$ | async"
        [patient]="patient$ | async"
        [expanded]="false"
      >
        <pr-treatment-scope-list
          [disabled]="
            disabled
              ? disabled
              : (treatmentStep
                | pairMap$: treatmentPlan$ : isStepComplete
                | async)
          "
          [treatments]="treatmentStep.treatments"
          [step]="treatmentStep"
          [plan]="treatmentPlan$ | async"
          (treatmentsChange)="updateTreatments(treatmentStep, $event)"
        />
      </pr-expandable-treatment-step>
    </ng-container>
    <ng-container *prHasPermission="treatmentPlanManagePermission">
      <div
        *ngIf="canAddStep"
        class="step-container my-2"
        [matTooltip]="stepDisplay.unscheduledHiddenTooltip()"
      >
        <a
          href="javascript:void(0);"
          class="action-link"
          (click)="createNewStep()"
        >
          <mat-icon>add</mat-icon> Create New Step
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>
