import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  type IOrganisation,
  type IRole,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-role-edit',
    templateUrl: './role-edit.component.html',
    styleUrls: ['./role-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RoleEditComponent {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  role$: Observable<WithRef<IRole>>;

  constructor(private _route: ActivatedRoute) {
    const organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );

    this.role$ = this._route.data.pipe(
      findProp<WithRef<IRole>>('role'),
      filterUndefined()
    );

    this.breadcrumbs$ = combineLatest([organisation$, this.role$]).pipe(
      map(([organisation, role]) => [
        { label: 'Organisations', path: '../../../' },
        { label: organisation.name, path: '../../' },
        { label: 'Roles', path: '../' },
        { label: role.name },
      ])
    );
  }
}
