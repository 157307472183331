<div
  class="flex h-full w-full items-center justify-between"
  *ngIf="item$ | async as item"
>
  <div class="flex items-center">
    <mat-icon
      class="charted-item-icon"
      *ngIf="item.display?.icon.slug"
      [ngStyle]="{ color: item.display.colour.value }"
      [svgIcon]="item.display.icon.slug"
    />

    <div class="flex flex-col">
      <span class="charted-item-label">{{ item.name }}</span>
      <div
        class="service-code-summary"
        *ngIf="serviceCodeSummary$ | async as serviceCodeSummary"
      >
        <small class="mat-caption">{{ serviceCodeSummary }}</small>
      </div>
    </div>
  </div>

  <div class="flex items-center">
    <div class="flex items-center justify-end">
      @if (isMultiTreatment$ | async) {
        <mat-icon
          class="multi-treatment-icon"
          matTooltip="This will create multiple treatment steps"
        >
          list
        </mat-icon>
      }
      @if (quickChartEnabled) {
        @if (isInQuickChart$ | async) {
          <button
            mat-icon-button
            (click)="removeFromQuickChart($event, item)"
            matTooltip="Remove from Quick Chart"
          >
            <mat-icon>bookmark</mat-icon>
          </button>
        } @else {
          <button
            mat-icon-button
            (click)="addToQuickChart($event, item)"
            matTooltip="Add to Quick Chart"
          >
            <mat-icon>bookmark_border</mat-icon>
          </button>
        }
      }
    </div>
  </div>
</div>
