import {
  IInvoice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, WithRef } from '@principle-theorem/shared';
import { depositToLineItem } from './custom-line-items';
import { Invoice } from './invoice';
import { TaxRate } from '@principle-theorem/accounting';

export async function balanceOverpaymentWithDeposit(
  invoice: WithRef<IInvoice>,
  staffer: WithRef<IStaffer>,
  taxRate: TaxRate
): Promise<void> {
  const transactions = await snapshot(Invoice.transactions$(invoice));
  const overPaidAmount = Invoice.getOverpaymentAmount(invoice, transactions);
  if (overPaidAmount <= 0) {
    return;
  }
  await Invoice.revertToDraft(invoice, staffer);
  Invoice.addLineItem(
    invoice,
    depositToLineItem(`Account Credit (Invoice Overpayment)`, overPaidAmount),
    taxRate
  );
  await Invoice.issueInvoice(invoice, transactions, staffer);
}
