import {
  IBrand,
  IEventTimePeriod,
  IPatient,
  IPractice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { doc$, WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  allPractitionersAvailableTimes,
  practiceStaffAvailableTimes,
  stafferAvailableTimes,
} from './brand-event-option-finder';
import { EventOptionFinder } from './event-option-finder';

type PreferredResolved = [
  WithRef<IStaffer> | undefined,
  WithRef<IPractice> | undefined
];

export class PatientEventOptionFinder extends EventOptionFinder<IPatient> {
  selectedPractitioner$: BehaviorSubject<WithRef<IStaffer> | undefined> =
    new BehaviorSubject<WithRef<IStaffer> | undefined>(undefined);
  selectedPractice$: BehaviorSubject<WithRef<IPractice> | undefined> =
    new BehaviorSubject<WithRef<IPractice> | undefined>(undefined);

  set practitioner(practitioner: WithRef<IStaffer> | undefined) {
    this.selectedPractitioner$.next(practitioner);
  }

  set practice(practice: WithRef<IPractice> | undefined) {
    this.selectedPractice$.next(practice);
  }

  getAvailableTimes$(
    brand: WithRef<IBrand>,
    duration?: moment.Duration,
    usePatientPreference: boolean = true,
    allowOverlapping: boolean = false
  ): Observable<IEventTimePeriod[]> {
    return this._getPatientPreferences$(usePatientPreference).pipe(
      switchMap(([preferredDentist, preferredPractice]) => {
        if (preferredDentist) {
          return this.currentRequest$.pipe(
            stafferAvailableTimes(
              preferredDentist,
              brand,
              preferredPractice,
              duration,
              allowOverlapping,
              this.timeIncrement
            )
          );
        }

        if (preferredPractice) {
          return this.currentRequest$.pipe(
            practiceStaffAvailableTimes(
              preferredPractice,
              brand,
              duration,
              allowOverlapping
            )
          );
        }

        return this.currentRequest$.pipe(
          allPractitionersAvailableTimes(brand, duration, allowOverlapping)
        );
      })
    );
  }

  private _getPatientPreferences$(
    usePatientPreference: boolean = true
  ): Observable<PreferredResolved> {
    return combineLatest([
      iif(
        () => usePatientPreference,
        this._getPreferredDentist$(),
        this.selectedPractitioner$.asObservable()
      ),
      iif(
        () => usePatientPreference,
        this._getPreferredPractice$(),
        this.selectedPractice$.asObservable()
      ),
    ]);
  }

  private _getPreferredDentist$(): Observable<WithRef<IStaffer> | undefined> {
    return this.model$.pipe(
      switchMap((patient) =>
        patient.preferredDentist
          ? doc$<IStaffer>(patient.preferredDentist.ref)
          : of(undefined)
      )
    );
  }

  private _getPreferredPractice$(): Observable<WithRef<IPractice> | undefined> {
    return this.model$.pipe(
      switchMap((patient) =>
        patient.preferredPractice
          ? doc$<IPractice>(patient.preferredPractice.ref)
          : of(undefined)
      )
    );
  }
}
