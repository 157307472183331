import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import {
  AccountSummaryBloc,
  GlobalStoreService,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  Event,
  OrganisationCache,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  IPatient,
  IScheduleSummaryEventable,
  UNCATEGORISED_CATEGORY,
  type IAppointment,
  type INextStage,
  type IStaffer,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  asDocRef,
  filterUndefined,
  getDoc,
  isPathChanged$,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { AppointmentManager } from 'libs/ng-appointment/store/src/lib/models/appointment-management/appointment-manager';
import { compact } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IAppointmentCardAction } from '../appointment-card/appointment-card-actions/actions/appointment-card-action-interface';
import { OpenAppointmentActionService } from '../appointment-card/appointment-card-actions/actions/open-appointment-action.service';
import { StartAppointmentActionService } from '../appointment-card/appointment-card-actions/actions/start-appointment-action.service';
import {
  IInboundChecklistData,
  InboundChecklistComponent,
} from '../scheduling/inbound-checklist/inbound-checklist.component';

@Component({
  selector: 'pr-appointment-header',
  templateUrl: './appointment-header.component.html',
  styleUrls: ['./appointment-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHeaderComponent {
  private _global = inject(GlobalStoreService);
  private _appointment$ = new BehaviorSubject<
    WithRef<IAppointment> | undefined
  >(undefined);
  readonly mediaDiameter: number = 45;
  profileImage = inject(ProfileImageService);
  trackByPractitioner = TrackByFunctions.ref<WithRef<IStaffer>>();
  eventable$ = new ReplaySubject<AppointmentSummary>(1);
  practitioners$: Observable<WithRef<IStaffer>[]>;
  category$: Observable<ITreatmentCategory>;
  practitionerProfileImages$: Observable<(string | undefined)[]>;
  patientProfileImage$: Observable<string | undefined>;
  accountSummary: AccountSummaryBloc;
  hasAppointmentAlerts$: Observable<boolean>;
  hasTags$: Observable<boolean>;
  canCheckIn$: Observable<boolean>;
  checkInRequirementsCount$: Observable<number>;
  canCheckOut$: Observable<boolean>;
  inProgress$: Observable<boolean>;
  startAppointmentVisible$: Observable<boolean>;
  openAppointmentVisible$: Observable<boolean>;
  nextStage$: Observable<INextStage | undefined>;
  patient$: Observable<WithRef<IPatient>>;
  @Input() showPractitionerIcon = false;

  @Input()
  set eventable(eventable: AppointmentSummary) {
    if (eventable) {
      this.eventable$.next(eventable);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _eventableTimeline: EventableTimelineStore,
    private _stateNav: StateBasedNavigationService,
    public startAppointment: StartAppointmentActionService,
    public openAppointment: OpenAppointmentActionService
  ) {
    this.patient$ = this.eventable$.pipe(
      isPathChanged$('metadata.patientRef.path'),
      switchMap((eventable) =>
        OrganisationCache.patients.getDoc(eventable.metadata.patientRef)
      ),
      shareReplayCold()
    );
    this.accountSummary = new AccountSummaryBloc(this.patient$);

    this.hasAppointmentAlerts$ = this.eventable$.pipe(
      map(
        (appointment) =>
          Appointment.getSchedulingConflicts(appointment).length > 0
      )
    );

    this.practitioners$ = combineLatest([
      this.eventable$.pipe(map((eventable) => Event.staff(eventable.event))),
      this._organisation.stafferMap$,
    ]).pipe(
      map(([staffParticipants, stafferMap]) => {
        return compact(
          staffParticipants.map((stafferRef) => stafferMap[stafferRef.ref.path])
        );
      })
    );

    this.hasTags$ = this.eventable$.pipe(
      map(({ metadata }) => metadata.tags.length > 0)
    );

    this.category$ = this.eventable$.pipe(
      switchMap((eventable) => {
        const displayRef = eventable.metadata.categoryRef;

        if (!displayRef) {
          return of(UNCATEGORISED_CATEGORY);
        }

        return this._global
          .getTreatmentCategory$(displayRef)
          .pipe(map((category) => category ?? UNCATEGORISED_CATEGORY));
      })
    );

    this.patientProfileImage$ = this.patient$.pipe(
      isPathChanged$('profileImageURL'),
      switchMap((patient) => this.profileImage.getUserProfileImage$(patient)),
      shareReplayCold()
    );

    this.canCheckIn$ = this.eventable$.pipe(
      map((eventable) => Appointment.canCheckIn(eventable))
    );

    this.checkInRequirementsCount$ = this.eventable$.pipe(
      switchMap((eventable) =>
        AppointmentManager.getCheckInRequirementCount$(
          { ref: eventable.metadata.patientRef },
          eventable
        )
      )
    );

    this.canCheckOut$ = this.eventable$.pipe(
      map((appointment) => Appointment.isCheckingOut(appointment))
    );

    this.inProgress$ = this.eventable$.pipe(
      map((appointment) => Appointment.inProgress(appointment))
    );

    this.nextStage$ = this.eventable$.pipe(
      map((eventable) => Appointment.nextStage(eventable))
    );

    this.startAppointmentVisible$ = this.eventable$.pipe(
      map((eventable) => this.startAppointment.isVisible(eventable))
    );

    this.openAppointmentVisible$ = this.eventable$.pipe(
      map((eventable) => this.openAppointment.isVisible(eventable))
    );
  }

  stafferProfileImage$(
    practitoner: WithRef<IStaffer>
  ): Observable<string | undefined> {
    return this._global.getStafferImage$(practitoner);
  }

  async routeToCheckOut(): Promise<void> {
    const eventable = await snapshot(this.eventable$);
    const patient = eventable.metadata.patientRef;
    const appointment = await this._getAppointment();
    await this._stateNav.brand([
      'patients',
      patient.id,
      'appointments',
      appointment.ref.id,
      'outgoing',
    ]);
  }

  async runAction(
    action: IAppointmentCardAction,
    eventable: AppointmentSummary
  ): Promise<void> {
    const appointment = await this._getAppointment();

    const updateFn = (
      oldEvent: IScheduleSummaryEventable,
      newEvent: IScheduleSummaryEventable
    ): void => this._eventableTimeline.updateEvent(oldEvent, newEvent);

    action.updateEventableSummary(eventable, updateFn);
    await action.run(appointment, { ref: eventable.metadata.patientRef });
  }

  async checkIn(eventable: AppointmentSummary): Promise<void> {
    const appointment = await this._getAppointment();
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    this.updateEventableStatus(eventable, AppointmentStatus.CheckedIn);
    await AppointmentManager.markCheckedIn(staffer, appointment);
    this._snackBar.open('Patient checked in');
  }

  async openArrival(eventable: AppointmentSummary): Promise<void> {
    const appointment = await this._getAppointment();
    const patient = await snapshot(this.patient$);
    const status = await this._dialog
      .open<
        InboundChecklistComponent,
        IInboundChecklistData,
        AppointmentStatus
      >(
        InboundChecklistComponent,
        DialogPresets.medium({
          data: { appointment, patient },
        })
      )
      .afterClosed()
      .toPromise();
    if (!status) {
      return;
    }
    this.updateEventableStatus(eventable, status);
  }

  updateEventableStatus(
    eventable: AppointmentSummary,
    status: AppointmentStatus
  ): void {
    const metadata = {
      ...eventable.metadata,
      status,
    };

    this._eventableTimeline.updateEvent(eventable, {
      ...eventable,
      metadata,
    });
  }

  private async _getAppointment(): Promise<WithRef<IAppointment>> {
    const currentAppointment = await snapshot(this._appointment$);
    if (currentAppointment) {
      return currentAppointment;
    }
    const eventable = await snapshot(this.eventable$);
    const ref = eventable.ref as DocumentReference<IAppointment>;
    const appointment = await getDoc(asDocRef<IAppointment>(ref));
    this._appointment$.next(appointment);
    return appointment;
  }
}
