import {
  createEntityAdapter,
  type EntityAdapter,
  type EntityState,
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  type ILabJob,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { LabJobPresetFilter } from '../lab-job-preset-filters';
import { LabJobSortId } from '../lab-job-sort-options';
import * as LabJobActions from './lab-jobs.actions';

export const LAB_JOBS_FEATURE_KEY = 'lab-jobs';

export interface ILabJobsState
  extends EntityState<SerialisedData<WithRef<ILabJob>>> {
  selectedId?: string;
  loaded: boolean;
  error?: string;
  statusFilter: LabJobStatus;
  presetFilter: LabJobPresetFilter;
  sortOption: LabJobSortId;
}

export interface ILabJobsPartialState {
  readonly [LAB_JOBS_FEATURE_KEY]: ILabJobsState;
}

export const labJobAdapter: EntityAdapter<SerialisedData<WithRef<ILabJob>>> =
  createEntityAdapter<SerialisedData<WithRef<ILabJob>>>({
    selectId: (labJob) => labJob.ref.id,
    sortComparer: false,
  });

export const initialState: ILabJobsState = labJobAdapter.getInitialState({
  loaded: false,
  statusFilter: LabJobStatus.Sending,
  presetFilter: LabJobPresetFilter.Active,
  sortOption: LabJobSortId.DueDate,
});

const reducer = createReducer(
  initialState,

  on(LabJobActions.loadPracticeLabJobs, (state) => ({
    ...state,
    loaded: false,
  })),

  on(LabJobActions.loadLabJobsSuccess, (state, action) =>
    labJobAdapter.setAll(action.labJobs, { ...state, loaded: true })
  ),

  on(LabJobActions.loadLabJobsForLabSuccess, (state, action) =>
    labJobAdapter.setAll(action.labJobs, { ...state, loaded: true })
  ),

  on(LabJobActions.loadLabJobsFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),

  on(
    LabJobActions.selectLabJob,
    LabJobActions.selectJobFromRoute,
    (state, action) => ({
      ...state,
      selectedId: action.id,
    })
  ),

  on(LabJobActions.clearSelectedLabJob, (state) => ({
    ...state,
    selectedId: undefined,
  })),

  on(LabJobActions.setStatusFilter, (state, action) => ({
    ...state,
    statusFilter: action.status,
  })),

  on(LabJobActions.setSortOption, (state, action) => ({
    ...state,
    sortOption: action.id,
  })),

  on(LabJobActions.setPresetFilter, (state, action) => ({
    ...state,
    presetFilter: action.preset,
  })),

  on(LabJobActions.loadLabJobFromRouteSuccess, (state, action) =>
    labJobAdapter.upsertOne(action.labJob, { ...state })
  )
);

export function labJobsReducer(
  state: ILabJobsState | undefined,
  action: Action
): ILabJobsState {
  return reducer(state, action);
}
