import { IAssetRequirement } from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class AssetRequirement {
  static init(
    overrides: AtLeast<IAssetRequirement, 'asset'>
  ): IAssetRequirement {
    const requirement = {
      duration: 0,
      quantity: 1,
      ...overrides,
    };

    if (overrides.isFullDuration) {
      requirement.duration = -1;
    }

    return requirement;
  }
}
