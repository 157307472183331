import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CanBeChartedProperty,
  FactTables,
  GreaterThanMeasureFilter,
  type ICanGroupMeasuresProperty,
  LessThanMeasureFilter,
} from '@principle-theorem/reporting';
import { BAR_CHART_OPTIONS } from '../../../models/report/charts/bar-chart';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';

@Component({
    selector: 'pr-practice-productivity',
    templateUrl: './practice-productivity.component.html',
    styleUrls: ['./practice-productivity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeProductivityComponent extends MeasureDataChartComponent {
  replacementHeaders: ITableHeaderReplacement[] = [
    {
      from: 'Owner',
      to: 'Practice',
    },
  ];
  tableData: IChartCard;
  appointmentsChart: ChartBuilder;
  chairTimeChart: ChartBuilder;
  ftaAndUtaChartBuilder: ChartBuilder;
  appointmentsBreakdownChart: ChartBuilder;
  rescheduleAndCancellationsChartBuilder: ChartBuilder;

  _redraw(): void {
    this.appointmentsChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.completed)
              .count.setLabel('Completed Appointments'),
            FactTables.appointmentEvent.duration
              .reduceBySum()
              .setLabel('Total Appointment Duration')
              .setFormatter(MeasureFormatter.Suffix /* , 'mins' */), // TODO: renable mins
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.chairTimeChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            // 'timeUsed',
            FactTables.appointmentEvent.duration.reduceBySum(),
            // 'timeUnused',
            FactTables.gapEvent.timeUnused.reduceBySum(),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.rescheduleAndCancellationsChartBuilder = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.rescheduled)
              .count.reduceByRatio(
                FactTables.appointmentEvent.scopeBy(
                  FactTables.appointmentEvent.scheduled
                ).count
              )
              .setLabel('Reschedule Rate')
              .setFormatter(MeasureFormatter.Percentage),
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.cancelled)
              .count.reduceByRatio(
                FactTables.appointmentEvent.scopeBy(
                  FactTables.appointmentEvent.scheduled
                ).count
              )
              .setLabel('Cancellation Rate')
              .setFormatter(MeasureFormatter.Percentage),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this._setFtaAndUtaChartBuilder(
      [
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment.filterBy(
              new LessThanMeasureFilter(60)
            )
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('FTAs'),
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment
              .filterBy(new LessThanMeasureFilter(720))
              .filterBy(new GreaterThanMeasureFilter(60, true))
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('UTAs'),
      ],
      FactTables.appointmentEvent.practice.name
    );

    this.appointmentsBreakdownChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.completed)
              .count.setLabel('Completed Appointments'),
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.scheduled)
              .count.setLabel('Scheduled Appointments'),
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.unscheduled)
              .count.setLabel('Unscheduled Appointments'),
            FactTables.appointmentEvent
              .scopeBy(FactTables.appointmentEvent.cancelled)
              .count.setLabel('Cancelled Appointments'),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.tableData = this._tableChartCard(
      [
        FactTables.appointmentEvent.duration
          .reduceBySum()
          .setLabel('Used Time'),
        FactTables.gapEvent.timeUnused.reduceBySum().setLabel('Unused Time'),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.completed)
          .count.setLabel('Completed Appointments'),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.scheduled)
          .count.setLabel('Scheduled Appointments'),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.unscheduled)
          .count.setLabel('Unscheduled Appointments'),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('Cancelled Appointments'),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.rescheduled)
          .count.reduceByRatio(
            FactTables.appointmentEvent.scopeBy(
              FactTables.appointmentEvent.scheduled
            ).count
          )
          .setLabel('Reschedule Rate')
          .setFormatter(MeasureFormatter.Percentage),
        FactTables.appointmentEvent
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.reduceByRatio(
            FactTables.appointmentEvent.scopeBy(
              FactTables.appointmentEvent.scheduled
            ).count
          )
          .setLabel('Cancellation Rate')
          .setFormatter(MeasureFormatter.Percentage),
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment
              .filterBy(new LessThanMeasureFilter(720))
              .filterBy(new GreaterThanMeasureFilter(60, true))
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('UTAs'),
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment.filterBy(
              new LessThanMeasureFilter(60)
            )
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('FTAs'),
      ],
      FactTables.appointmentEvent.practice.name
    );
  }

  private _setFtaAndUtaChartBuilder(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): void {
    this.ftaAndUtaChartBuilder = this.dataBuilder
      .toBarChart(
        this._chartMeasures(CustomChartType.Bar, measures, groupByDimension)
      )
      .chartBuilder.addChartOptions(BAR_CHART_OPTIONS)
      .addChartOptions({
        bars: 'horizontal',
        vAxis: { title: '' },
      });
  }
}
