import {
  ChartableSurface,
  IChartableItem,
  IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { isBoolean } from 'lodash';
import { SurfaceHierarchy } from './surface-hierarchy';

export class UnscopedHierarchy extends SurfaceHierarchy<
  Pick<IChartedRef, 'unscoped'>
> {
  override surfaces: ChartableSurface[] = [ChartableSurface.Unscoped];

  override hasCompatibleSurface(item: IChartableItem): boolean {
    return !item.availableSurfaces.length
      ? true
      : super.hasCompatibleSurface(item);
  }

  canBeChartedOn(
    surface: Partial<IChartedRef>
  ): surface is Pick<IChartedRef, 'unscoped'> {
    return isBoolean(surface.unscoped);
  }

  toChartedRef(): Pick<IChartedRef, 'unscoped'> {
    return { unscoped: true };
  }
}
