import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IGeoCoordinates } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPreviewComponent {
  readonly zoomLevel: number = 14;
  center$ = new ReplaySubject<google.maps.LatLngLiteral | undefined>(1);

  @Input()
  set coordinates(coordinates: IGeoCoordinates | undefined) {
    if (coordinates) {
      this.center$.next({
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      });
    }
  }
}
