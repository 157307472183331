import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { ManualTransactionType } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IManualTransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  deleteDoc,
  multiFilter,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  EditManualTransactionTypeDialogComponent,
  type IEditManualTransactionTypeDialogData,
} from '../edit-manual-transaction-type-dialog/edit-manual-transaction-type-dialog.component';

@Component({
  selector: 'pr-manual-transaction-types',
  templateUrl: './manual-transaction-types.component.html',
  styleUrls: ['./manual-transaction-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualTransactionTypesComponent {
  private _manualTransactionTypes$: Observable<
    WithRef<IManualTransactionType>[]
  >;
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  activeTypes$: Observable<WithRef<IManualTransactionType>[]>;
  deletedTypes$: Observable<WithRef<IManualTransactionType>[]>;
  trackByTransactionType = TrackByFunctions.ref<IManualTransactionType>();

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  constructor(private _dialog: MatDialog) {
    this._manualTransactionTypes$ = this.brand$.pipe(
      switchMap((brand) => ManualTransactionType.all$(brand, true))
    );

    this.activeTypes$ = this._manualTransactionTypes$.pipe(
      multiFilter((type) => !type.deleted)
    );
    this.deletedTypes$ = this._manualTransactionTypes$.pipe(
      multiFilter((type) => type.deleted)
    );
  }

  async addType(type?: WithRef<IManualTransactionType>): Promise<void> {
    const change = await this._getManualTransactionType(type);
    if (!change) {
      return;
    }
    const brand = await snapshot(this.brand$);
    if (type) {
      await patchDoc(type.ref, { ...change });
      return;
    }
    await addDoc(ManualTransactionType.col(brand), change);
  }

  async delete(type: WithRef<IManualTransactionType>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Manual Transaction Type',
      prompt:
        'Deleting this could affect reconciliation reporting. Are you sure?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(type.ref);
  }

  async restore(type: WithRef<IManualTransactionType>): Promise<void> {
    await patchDoc(type.ref, { deleted: false });
  }

  private async _getManualTransactionType(
    type?: WithRef<IManualTransactionType>
  ): Promise<IManualTransactionType | undefined> {
    return this._dialog
      .open<
        EditManualTransactionTypeDialogComponent,
        IEditManualTransactionTypeDialogData,
        IManualTransactionType | undefined
      >(
        EditManualTransactionTypeDialogComponent,
        DialogPresets.small({ data: { type } })
      )
      .afterClosed()
      .toPromise();
  }
}
