import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  IScheduleSummaryEventable,
  type ICandidateCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-gap-candidate-list',
    templateUrl: './gap-candidate-list.component.html',
    styleUrls: ['./gap-candidate-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapCandidateListComponent {
  trackByCandidate = TrackByFunctions.ref<WithRef<ICandidateCalendarEvent>>();
  gap$: ReplaySubject<IScheduleSummaryEventable> = new ReplaySubject(1);
  gapCandidates$: ReplaySubject<WithRef<ICandidateCalendarEvent>[]> =
    new ReplaySubject(1);

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set gapCandidates(candidates: WithRef<ICandidateCalendarEvent>[]) {
    if (candidates) {
      this.gapCandidates$.next(candidates);
    }
  }
}
