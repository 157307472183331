import { Component, Input, type OnInit } from '@angular/core';
import { asDocRef, doc$, type WithRef } from '@principle-theorem/shared';
import {
  type IAppointment,
  type IGap,
  type ILabJob,
  type ISchedulingAlert,
  SchedulingAlertTargetType,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import {
  type AppointmentCardAction,
  ConfirmAppointmentActionService,
} from '@principle-theorem/ng-appointment';

@Component({
  selector: 'pr-scheduling-alert-item',
  templateUrl: './scheduling-alert-item.component.html',
  styleUrls: ['./scheduling-alert-item.component.sass'],
})
export class SchedulingAlertItemComponent implements OnInit {
  @Input() alert: ISchedulingAlert;
  appointment$: Observable<WithRef<IAppointment>>;
  gap: IGap;
  labJob$: Observable<WithRef<ILabJob>>;
  appointmentActions: AppointmentCardAction[] = [];

  constructor(confirmAppointment: ConfirmAppointmentActionService) {
    this.appointmentActions = [confirmAppointment];
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    if (this.isAppointment() && this.alert.target) {
      this.appointment$ = doc$(asDocRef<IAppointment>(this.alert.target));
    }

    if (this.isGap() && this.alert.gap) {
      this.gap = this.alert.gap;
    }

    if (this.isLabJob() && this.alert.target) {
      this.labJob$ = doc$(asDocRef<ILabJob>(this.alert.target));
    }
  }

  isAppointment(): boolean {
    return this.alert.targetType === SchedulingAlertTargetType.Appointment;
  }

  isGap(): boolean {
    return this.alert.targetType === SchedulingAlertTargetType.Gap;
  }

  isLabJob(): boolean {
    return this.alert.targetType === SchedulingAlertTargetType.LabJob;
  }
}
