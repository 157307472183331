<div>{{ account.name }}</div>
<div>{{ account.address }}</div>
<div>{{ account.phone }}</div>
<div *ngIf="account.taxId">
  <strong>Tax ID:</strong>
  {{ account.taxId }}
</div>
<div *ngIf="account.bsb">
  <strong>BSB:</strong>
  {{ account.bsb }}
</div>
<div *ngIf="account.accNo">
  <strong>Acc No:</strong>
  {{ account.accNo }}
</div>
<div *ngIf="account.onBehalfOf">
  <strong>On Behalf Of:</strong>
  <div>{{ account.onBehalfOf.name }}</div>
  <div>{{ account.onBehalfOf.address }}</div>
</div>
