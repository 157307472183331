import { IProviderOption } from '../transaction-provider';
import { HicapsConnectEftposTransactionProvider } from '../hicaps-connect/eftpos/hicaps-connect-eftpos-transaction-provider.service';
import { HicapsConnectHealthFundTransactionProvider } from '../hicaps-connect/health-fund/hicaps-connect-health-fund-transaction-provider.service';
import { HicapsConnectMedicareTransactionProvider } from '../hicaps-connect/medicare/hicaps-connect-medicare-transaction-provider.service';

export const HICAPS_CONNECT_IMAGE_URL = '/assets/integrations/hicaps-logo.png';

export const HICAPS_CONNECT_PAYMENT_PROVIDER_OPTION: IProviderOption = {
  label: 'HICAPS',
  icon: 'credit_card',
  imageUrl: HICAPS_CONNECT_IMAGE_URL,
  provider: HicapsConnectEftposTransactionProvider,
};

export const HICAPS_CONNECT_HEALTH_FUND_PROVIDER_OPTION: IProviderOption = {
  label: 'HICAPS',
  icon: 'health_and_safety',
  imageUrl: HICAPS_CONNECT_IMAGE_URL,
  provider: HicapsConnectHealthFundTransactionProvider,
};

export const HICAPS_CONNECT_MEDICARE_PROVIDER_OPTION: IProviderOption = {
  label: 'HICAPS',
  icon: 'credit_card',
  imageUrl: HICAPS_CONNECT_IMAGE_URL,
  provider: HicapsConnectMedicareTransactionProvider,
};

export const HICAPS_CONNECT_PROVIDER_OPTIONS: IProviderOption[] = [
  HICAPS_CONNECT_PAYMENT_PROVIDER_OPTION,
  HICAPS_CONNECT_HEALTH_FUND_PROVIDER_OPTION,
  HICAPS_CONNECT_MEDICARE_PROVIDER_OPTION,
];
