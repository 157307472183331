import { type IUserWorkspace } from '@principle-theorem/shared';

export type IWorkspace = IUserWorkspace<WorkspaceType>;

export enum WorkspaceType {
  Organisation = 'organisation',
}

export function isOrganisationWorkspace(
  workspace: IUserWorkspace
): workspace is IUserWorkspace<WorkspaceType.Organisation> {
  return String(workspace.type) === String(WorkspaceType.Organisation);
}
