import {
  createEntityAdapter,
  type EntityAdapter,
  type EntityState,
} from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { loadTreatmentConfigurationsSuccess } from '../../actions/charted-configurations';

export type ITreatmentConfigurationState = EntityState<
  SerialisedData<WithRef<ITreatmentConfiguration>>
>;

export const treatmentConfigurationAdapter: EntityAdapter<
  SerialisedData<WithRef<ITreatmentConfiguration>>
> = createEntityAdapter<SerialisedData<WithRef<ITreatmentConfiguration>>>({
  selectId: (config) => config.ref.id,
  sortComparer: false,
});

export const initialTreatmentConfiguration: ITreatmentConfigurationState =
  treatmentConfigurationAdapter.getInitialState();

export const treatmentConfigurationReducer: ActionReducer<
  ITreatmentConfigurationState,
  Action
> = createReducer(
  initialTreatmentConfiguration,

  on(loadTreatmentConfigurationsSuccess, (state, action) =>
    treatmentConfigurationAdapter.upsertMany(action.configs, {
      ...state,
    })
  )

  // on(reset, () => initialTreatmentConfiguration)
);
