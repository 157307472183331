import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  type IPractitionerIncomeReportState,
  PRACTITIONER_INCOME_REPORT_FEATURE_KEY,
} from './practitioner-income.reducers';

export const getPractitionerIncomeReportState =
  createFeatureSelector<IPractitionerIncomeReportState>(
    PRACTITIONER_INCOME_REPORT_FEATURE_KEY
  );

export const getQuery = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.query
);

export const getReportType = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.reportType
);

export const getIncomeReport = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.results
);

export const getIsLoading = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.isLoading
);

export const getErrorMessage = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.errorMessage
);

export const getInspected = createSelector(
  getPractitionerIncomeReportState,
  (state) => state.inspected
);
