<div class="flex flex-col gap-4">
  @for (
    perioTable of perioChartStore.perioTables$ | async;
    track perioTable.label
  ) {
    @defer {
      <div class="flex flex-col gap-4">
        <div class="flex items-center">
          <div class="text-center font-bold" style="writing-mode: vertical-lr">
            <p>{{ perioTable.label | map: getLabel | titlecase }}</p>
          </div>
          <pr-perio-table
            [perioTable]="perioTable"
            (perioCellChange)="handleChange($event)"
            (deleteCells)="deleteCells($event)"
          />
        </div>
      </div>
    } @placeholder {
      <ngx-skeleton-loader
        animation="pulse"
        count="2"
        [theme]="{
          height: '300px',
          width: '100%',
          maxWidth: '1280px',
          marginLeft: '32px'
        }"
      />
    }
  }
</div>
