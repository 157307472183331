import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';
import { combineLatest, type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class DatePickerDisplayBloc {
  nextDayDisabled$: Observable<boolean>;
  nextWeekDisabled$: Observable<boolean>;
  nextMonthDisabled$: Observable<boolean>;
  datePickerDisabled$: Observable<boolean>;

  constructor(
    isCalendarView$: Observable<boolean>,
    unit$: Observable<CalendarUnit>
  ) {
    this.nextDayDisabled$ = this._every$([
      isCalendarView$,
      unit$.pipe(map((unit) => unit !== CalendarUnit.Day)),
    ]);
    this.nextWeekDisabled$ = this._every$([
      isCalendarView$,
      unit$.pipe(map((unit) => unit !== CalendarUnit.Week)),
    ]);
    this.nextMonthDisabled$ = this._some$([
      isCalendarView$.pipe(map((isCalendarView) => !isCalendarView)),
      this._every$([
        isCalendarView$,
        unit$.pipe(map((unit) => unit !== CalendarUnit.Month)),
      ]),
    ]);
    this.datePickerDisabled$ = of(false);
  }

  private _every$(values: Observable<boolean>[]): Observable<boolean> {
    return combineLatest(values).pipe(
      map((checks) => checks.every((check) => check))
    );
  }

  private _some$(values: Observable<boolean>[]): Observable<boolean> {
    return combineLatest(values).pipe(
      map((checks) => checks.some((check) => check))
    );
  }
}
