import {
  SourceEntityMigrationType,
  type ISourceEntity,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_MISSING_TOOTH_RESOURCE_TYPE = 'patientMissingTooth';

export const PATIENT_MISSING_TOOTH_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Missing Tooth List',
      description: '',
      idPrefix: PATIENT_MISSING_TOOTH_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientMissingTooth {
  id: number;
  patient_id: number;
  tooth_number: number | null;
  created_at: string;
  is_child: boolean;
  chart_id: number | null;
  perio_chart_id: number | null;
  treatment_id: number | null;
}

function isD4WPatientMissingToothItem(
  item: unknown
): item is ID4WPatientMissingTooth {
  return TypeGuard.interface<ID4WPatientMissingTooth>({
    id: isNumber,
    patient_id: isNumber,
    tooth_number: [isNumber, isNull],
    created_at: isString,
    is_child: isBoolean,
    chart_id: [isNumber, isNull],
    perio_chart_id: [isNumber, isNull],
    treatment_id: [isNumber, isNull],
  })(item);
}

export interface ID4WPatientMissingToothTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientMissingToothFilters {
  createdAt: Timestamp;
  patientId: string;
  chartId?: string;
  perioChartId?: string;
  treatmentId?: string;
}

const PATIENT_MISSING_TOOTH_SOURCE_QUERY = `
SELECT * FROM (
  SELECT
    id,
    patient_id,
    tooth_n AS tooth_number,
    date_time AS created_at,
    convert_to_boolean(child) AS is_child,
    charting_missing.chart_id,
    convert_to_integer(perio_charting_id) AS perio_chart_id,
    treat_id AS treatment_id
  FROM charting_missing
) AS missing_tooth
INNER JOIN (
  SELECT
    chart_id,
    convert_to_boolean(active) AS active
  FROM charting
  ) AS chart
ON chart.chart_id = missing_tooth.chart_id
WHERE patient_id IS NOT NULL
ORDER BY id
`;

export class PatientMissingToothSourceEntity extends BaseSourceEntity<
  ID4WPatientMissingTooth,
  ID4WPatientMissingToothTranslations,
  ID4WPatientMissingToothFilters
> {
  sourceEntity = PATIENT_MISSING_TOOTH_SOURCE_ENTITY;
  entityResourceType = PATIENT_MISSING_TOOTH_RESOURCE_TYPE;
  sourceQuery = PATIENT_MISSING_TOOTH_SOURCE_QUERY;
  allowOffsetJob = true;
  override defaultOffsetSize = 50000;
  verifySourceFn = isD4WPatientMissingToothItem;
  override transformDataFn = flow([]);

  translate(
    data: ID4WPatientMissingTooth,
    timezone: Timezone
  ): ID4WPatientMissingToothTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getFilterData(
    data: ID4WPatientMissingTooth,
    timezone: Timezone
  ): ID4WPatientMissingToothFilters {
    return {
      patientId: data.patient_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
      chartId: data.chart_id?.toString() || undefined,
      perioChartId: data.perio_chart_id?.toString() || undefined,
      treatmentId: data.treatment_id?.toString() || undefined,
    };
  }

  getSourceRecordId(data: ID4WPatientMissingTooth): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientMissingTooth): string {
    return `${data.id} ${data.patient_id}`;
  }
}

export function d4wMissingToothToISONotation(toothNumber: string): ToothNumber {
  const conversions: Record<string, ToothNumber> = {
    '1': '18',
    '2': '17',
    '3': '16',
    '4': '15',
    '5': '14',
    '6': '13',
    '7': '12',
    '8': '11',
    '9': '21',
    '10': '22',
    '11': '23',
    '12': '24',
    '13': '25',
    '14': '26',
    '15': '27',
    '16': '28',
    '17': '48',
    '18': '47',
    '19': '46',
    '20': '45',
    '21': '44',
    '22': '43',
    '23': '42',
    '24': '41',
    '25': '31',
    '26': '32',
    '27': '33',
    '28': '34',
    '29': '35',
    '30': '36',
    '31': '37',
    '32': '38',
  };

  try {
    return conversions[toothNumber];
  } catch (error) {
    throw new Error(`Invalid missing tooth number: ${toothNumber}`);
  }
}
