import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ImageSize, ImageStyle } from '@tiptap-pro/extension-ai';

export interface IAIImagePromptOutput {
  prompt: string;
  imageStyle: ImageStyle;
  imageSize: ImageSize;
}

export interface IAIImagePromptData {
  initialPrompt?: string;
}

@Component({
  selector: 'pt-ai-image-prompt-dialog',
  templateUrl: './ai-image-prompt-dialog.component.html',
  styleUrls: ['./ai-image-prompt-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIImagePromptDialogComponent {
  trackByOption = TrackByFunctions.variable<string>();
  form = new TypedFormGroup<IAIImagePromptOutput>({
    prompt: new TypedFormControl<string>(),
    imageStyle: new TypedFormControl<ImageStyle>(),
    imageSize: new TypedFormControl<ImageSize>(),
  });
  imageStyles: ImageStyle[] = [
    'photorealistic',
    'digital_art',
    'comic_book',
    'neon_punk',
    'isometric',
    'line_at',
    '3d_model',
  ];
  imageSizes: ImageSize[] = ['256x256', '512x512', '1024x1024'];

  constructor(
    private _dialogRef: MatDialogRef<
      AIImagePromptDialogComponent,
      IAIImagePromptOutput
    >,
    @Inject(MAT_DIALOG_DATA) data: IAIImagePromptData
  ) {
    if (data.initialPrompt) {
      this.form.patchValue({
        prompt: data.initialPrompt,
      });
    }
  }

  save(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
