import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-treatment-step-skeleton',
    templateUrl: './treatment-step-skeleton.component.html',
    styleUrls: ['./treatment-step-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentStepSkeletonComponent {}
