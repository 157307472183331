import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { TreatmentConfiguration } from '@principle-theorem/principle-core';
import {
  type IStaffer,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';

@Injectable()
export class StafferTreatmentConfigurationCollectionResolverService extends BaseResolver<
  CollectionReference<ITreatmentConfiguration>
> {
  resolve(
    route: ActivatedRouteSnapshot
  ): CollectionReference<ITreatmentConfiguration> {
    const staffer = this._findInheritedRouteData<WithRef<IStaffer>>(
      route,
      'staffer'
    );
    if (!staffer) {
      throw new Error('No Staffer found');
    }
    return TreatmentConfiguration.col(staffer);
  }
}
