import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionDirective } from './has-permission.directive';
import { HasPermissionGuard } from './has-permission.guard';
import { IsAuthorisedGuard } from './is-authorised.guard';
import { IsManagementDirective } from './is-management.directive';
import { IsManagementGuard } from './is-management.guard';
import { IsNotAuthorisedGuard } from './is-not-authorised.guard';
import { ManagementService } from './management.service';
import { IsIpRestrictedGuard } from './is-ip-restricted.guard';
import { IsTimeRestrictedGuard } from './is-time-restricted.guard';

@NgModule({
  imports: [CommonModule],
  providers: [
    ManagementService,
    IsAuthorisedGuard,
    IsManagementGuard,
    IsNotAuthorisedGuard,
    HasPermissionGuard,
    IsIpRestrictedGuard,
    IsTimeRestrictedGuard,
  ],
  declarations: [HasPermissionDirective, IsManagementDirective],
  exports: [HasPermissionDirective, IsManagementDirective],
})
export class AuthModule {}
