import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BRIDGE_DEVICE_DOWNLOAD_STATUS_MAP,
  BridgeDeviceDownloadStatus,
  IBridgeDevice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  filterUndefined,
  Firestore,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IDownloadBridgeProgressDialogData {
  deviceRef: DocumentReference<IBridgeDevice>;
}

@Component({
    selector: 'pr-download-bridge-progress-dialog',
    templateUrl: './download-bridge-progress-dialog.component.html',
    styleUrl: './download-bridge-progress-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DownloadBridgeProgressDialogComponent {
  private _bridgeDevice$: Observable<WithRef<IBridgeDevice>>;
  readonly downloadStatusMap = BRIDGE_DEVICE_DOWNLOAD_STATUS_MAP;
  downloadStatus$: Observable<BridgeDeviceDownloadStatus>;
  downloadDisabled$: Observable<boolean>;
  progress$: Observable<number>;
  progressMessage$: Observable<string>;
  showCloseButton$: Observable<boolean>;

  constructor(
    private _dialogRef: MatDialogRef<DownloadBridgeProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDownloadBridgeProgressDialogData
  ) {
    this._bridgeDevice$ = Firestore.doc$(data.deviceRef);
    this.downloadStatus$ = this._bridgeDevice$.pipe(
      map((bridgeDevice) => bridgeDevice.downloadStatus),
      filterUndefined()
    );
    this.downloadDisabled$ = this.downloadStatus$.pipe(
      map((status) => status === BridgeDeviceDownloadStatus.Failed)
    );
    this.progress$ = this.downloadStatus$.pipe(
      map((status) => this.downloadStatusMap[status].progress || 0)
    );
    this.progressMessage$ = this.downloadStatus$.pipe(
      map((status) => this.downloadStatusMap[status].message || '')
    );
    this.showCloseButton$ = this.downloadStatus$.pipe(
      map((status) =>
        [
          BridgeDeviceDownloadStatus.Ready,
          BridgeDeviceDownloadStatus.Failed,
        ].includes(status)
      )
    );
  }

  async download(): Promise<void> {
    const bridgeDevice = await snapshot(this._bridgeDevice$);
    if (!bridgeDevice.downloadUrl) {
      return;
    }

    window.open(bridgeDevice.downloadUrl);
    this._dialogRef.close();
  }
}
