import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { AITextPromptMenuComponent } from './ai-text-prompt-menu/ai-text-prompt-menu.component';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';

export const TEXT_PROMPT_DATA: IMenuButton = {
  buttonType: 'submenu',
  buttonText: 'Generate Text',
  tooltip: `Generate text from your prompt`,
  icon: 'edit_note',
  canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
  command: (editor) => editor.chain().focus().aiTextPrompt().focus().run(),
};

export function createTextPromptButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AITextPromptMenuComponent,
    data: TEXT_PROMPT_DATA,
  });
}

export function createTextPromptBlockButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AITextPromptMenuComponent,
    data: {
      ...TEXT_PROMPT_DATA,
      buttonType: 'block',
    },
  });
}
