import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TypedAbstractControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { IHealthFundCard } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-patient-details-health-fund-form',
    templateUrl: './patient-details-health-fund-form.component.html',
    styleUrl: './patient-details-health-fund-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDetailsHealthFundFormComponent {
  healthFundForm$ = new ReplaySubject<TypedFormGroup<IHealthFundCard>>(1);

  @Input()
  set form(form: TypedAbstractControl<IHealthFundCard>) {
    if (form) {
      this.healthFundForm$.next(form as TypedFormGroup<IHealthFundCard>);
    }
  }
}
