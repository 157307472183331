<ng-container *ngIf="measure && chartBuilder">
  <div
    class="drilldown-preview"
    [ngClass]="{ selected: selected, disabled: disabled }"
  >
    <div fxLayout="row">
      <div class="mat-caption">{{ measure.metadata.label }}</div>
      <ng-container *ngIf="measure.metadata.summary">
        <mat-icon [matTooltip]="measure.metadata.summary">
          help_outline
        </mat-icon>
      </ng-container>
    </div>

    <pr-data-points-summary
      [chart]="chartBuilder"
      [measure]="measure"
     />

    <div class="chart-area">
      <pr-chart [chartBuilder]="chartBuilder" />
    </div>
  </div>
</ng-container>
