import { Injectable, ElementRef } from '@angular/core';
import { shareReplayCold } from '@principle-theorem/shared';
import { EMPTY, type Observable, of } from 'rxjs';

@Injectable()
export class ScrollContainerManagerService {
  private _containers$: Record<ScrollContainer, Observable<HTMLElement>> = {
    [ScrollContainer.InAppointment]: EMPTY,
  };

  setContainer(label: ScrollContainer, element: ElementRef): void {
    this._containers$[label] = of(element.nativeElement).pipe(
      shareReplayCold()
    );
  }

  getContainer(label: ScrollContainer): Observable<HTMLElement> {
    return this._containers$[label];
  }

  removeContainer(label: ScrollContainer): void {
    this._containers$[label] = EMPTY;
  }
}

export enum ScrollContainer {
  InAppointment = 'InAppointment',
}
