import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { ChartedItemConfiguration } from '@principle-theorem/principle-core';
import {
  type IChartedItemConfiguration,
  type IConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { deleteDoc, patchDoc, type WithRef } from '@principle-theorem/shared';

@Component({
    selector: 'pr-edit-condition-configuration',
    templateUrl: './edit-condition-configuration.component.html',
    styleUrls: ['./edit-condition-configuration.component.sass'],
    standalone: false
})
export class EditConditionConfigurationComponent {
  @Input() conditionType: WithRef<IConditionConfiguration>;
  currentCondition: IChartedItemConfiguration;
  expanded = true;

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: BasicDialogService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  setCurrentCondition(condition: IChartedItemConfiguration): void {
    this.currentCondition = ChartedItemConfiguration.init({
      display: condition.display,
      clinicalNotes: condition.clinicalNotes,
      name: condition.name,
      availableSurfaces: condition.availableSurfaces,
    });
  }

  async save(): Promise<void> {
    await patchDoc(this.conditionType.ref, this.currentCondition);
    this._snackBar.open('Condition configuration saved');
  }

  async delete(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt:
        'Are you sure you want to delete this condition configuration? This will no longer be available for clinical charting and cannot be undone.',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }
    const config = this.conditionType;
    await deleteDoc(config.ref);
    this._snackBar.open('Condition Configuration Deleted');
    await this._router.navigate(['../'], {
      relativeTo: this._route,
    });
  }
}
