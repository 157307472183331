<div fxLayout="column" fxLayoutGap="8px">
  <div class="flex items-center justify-between gap-4">
    <h2>Appointment Details</h2>
    <pr-appointment-score [score]="score$ | async" />
  </div>
  <div
    *ngrxLet="selectedEvent$ as selectedEvent"
    fxLayout="column"
    fxLayoutGap="6px"
  >
    <ng-container *ngIf="selectedEvent">
      <div fxLayout="row" fxLayoutAlign="start center" class="gap-6">
        <mat-icon matTooltip="Date">calendar_today</mat-icon>
        <pr-formatted-event-field
          field="from"
          [event]="selectedEvent"
          [format]="dateFormat"
        />
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="gap-6">
        <mat-icon matTooltip="Time">schedule</mat-icon>
        <span>{{ timeDisplay$ | ngrxPush }}</span>
      </div>

      <ng-container *ngrxLet="practices$ as practices">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="gap-6"
          *ngIf="practices.length > 1"
        >
          <mat-icon matTooltip="Practice">location_on</mat-icon>
          <span>{{ (selectedPractice$ | async)?.name }}</span>
        </div>
      </ng-container>

      <div
        *ngIf="participants$ | ngrxPush as participants"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="gap-6"
      >
        <mat-icon matTooltip="Event participants">group</mat-icon>
        <div fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="12px"
            *ngFor="
              let participant of participants;
              trackBy: trackByParticipant
            "
          >
            <pt-user-icon
              [name]="participant.name"
              [src]="participant | profileImage$ | ngrxPush"
              [diameter]="30"
              [matTooltip]="participant.name"
            />
            <div>{{ participant.name }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div>
      <a
        href="javascript:void(0);"
        class="action-link"
        (click)="showAppointmentOptions.emit()"
      >
        <mat-icon>visibility</mat-icon> See more appointment options
      </a>
    </div>
  </div>
</div>
