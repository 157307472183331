import { Validators } from '@angular/forms';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type ITimePeriod,
  toTimePeriod,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';

export interface IRunCustomReportForm {
  brand: INamedDocument<IBrand>;
  practices: INamedDocument<IPractice>[];
  dateRange: ITimePeriod;
}

export class RunCustomReportForm extends TypedFormGroup<IRunCustomReportForm> {
  constructor(
    defaultBrand?: INamedDocument<IBrand>,
    defaultPractices?: INamedDocument<IPractice>[]
  ) {
    const today = toTimePeriod(moment().startOf('day'), moment().endOf('day'));
    super({
      brand: new TypedFormControl<INamedDocument<IBrand>>(
        defaultBrand,
        Validators.required
      ),
      practices: new TypedFormControl<INamedDocument<IPractice>[]>(
        defaultPractices,
        Validators.required
      ),
      dateRange: new TypedFormControl<ITimePeriod>(today, Validators.required),
    });
  }
}

export class RunReportFormBloc {
  trackByBrand = TrackByFunctions.ref<INamedDocument<IBrand>>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  brands$: Observable<INamedDocument<IBrand>[]>;
  practices$: Observable<INamedDocument<IPractice>[]>;

  constructor(private _organisation: OrganisationService) {
    this.brands$ = this._organisation.userBrands$;
    this.practices$ = this._organisation.userPractices$;
  }
}
