<ng-container *ngIf="groups$ | async as groups">
  <div class="items-container flex flex-col items-stretch gap-2">
    <pr-service-item-smart-group-item
      *ngFor="let group of groups; trackBy: trackByGroup"
      [group]="group"
      [chartedSurfaces]="chartedSurfaces"
      [disabled]="disabled"
      [compact]="compact"
      (groupChange)="groupUpdate(groups, group, $event)"
    >
      <button
        *ngIf="!disabled"
        mat-icon-button
        class="delete icon-sm opacity-50"
        color="warn"
        (click)="delete(groups, group)"
        matTooltip="Delete Smart Group"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </pr-service-item-smart-group-item>
  </div>
</ng-container>
