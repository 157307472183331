import { asDocRef, isObject } from '@principle-theorem/shared';
import { compact, isString } from 'lodash';
import { v4 as uuid } from 'uuid';
import { type IMention } from '../mention';
import { isNodeSchema, recursiveFindArray } from '../migrations/helpers';
import {
  MixedSchema,
  getMixedContent,
  isMixedSchema,
  type NodeSchema,
  type RawInlineNodes,
} from '../schema';
import { InlineNodes } from './available-extensions';

export interface IEditorMentionAttributes {
  key: string;
  path: string;
  type: string;
}

export function isMentionNode(
  item: unknown
): item is NodeSchema<InlineNodes.Mention> {
  return (
    isObject(item) &&
    isNodeSchema(item) &&
    String(item.type) === String(InlineNodes.Mention)
  );
}

export function isMentionAttributes(
  item: unknown
): item is IEditorMentionAttributes {
  return (
    isObject(item) &&
    isString(item.key) &&
    isString(item.path) &&
    isString(item.type)
  );
}

export function getMentions<Type extends string>(
  schema: NodeSchema | MixedSchema | RawInlineNodes
): IMention<Type>[] {
  if (isMixedSchema(schema)) {
    schema = getMixedContent(schema);
  }
  return compact(
    recursiveFindArray(schema, (schemaItem) => isMentionNode(schemaItem)).map(
      (schemaItem) => {
        if (
          !isMentionNode(schemaItem) ||
          !isMentionAttributes(schemaItem.attrs)
        ) {
          return;
        }
        return {
          uid: uuid(),
          key: schemaItem.attrs.key,
          resource: {
            uid: schemaItem.attrs.path,
            ref: asDocRef(schemaItem.attrs.path),
            type: schemaItem.attrs.type as Type,
          },
        };
      }
    )
  );
}
