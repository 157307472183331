import { Injectable, Injector } from '@angular/core';
import { MentionResourceType } from '@principle-theorem/principle-core/interfaces';
import { type IContextualAction } from '@principle-theorem/ng-principle-shared';
import { EmailService } from '../contextual-actions/interaction-actions/email.service';
import { PhoneService } from '../contextual-actions/interaction-actions/phone.service';
import { SmsService } from '../contextual-actions/interaction-actions/sms.service';
import { LoadContactActionService } from '../contextual-actions/routing-actions/load-contact-action.service';
import { LoadLabActionService } from '../contextual-actions/routing-actions/load-lab-action.service';
import { LoadLabJobActionService } from '../contextual-actions/routing-actions/load-lab-job-action.service';
import { LoadPatientActionService } from '../contextual-actions/routing-actions/load-patient-action.service';
import { LoadStafferActionService } from '../contextual-actions/routing-actions/load-staffer-action.service';
import { LoadTaskActionService } from '../contextual-actions/routing-actions/load-task-action.service';
import { LoadInvoiceActionService } from '../contextual-actions/routing-actions/load-invoice-action.service';
import { LoadTimelineActionService } from '../contextual-actions/routing-actions/load-timeline-action.service';

@Injectable()
export class MentionActionResolverService {
  constructor(private _inject: Injector) {}

  resolveActions(resourceType: MentionResourceType): IContextualAction[] {
    return this.getActions(resourceType);
  }

  getActions(resourceName: string | MentionResourceType): IContextualAction[] {
    switch (resourceName) {
      case MentionResourceType.Patient:
        return [
          this._inject.get(LoadPatientActionService),
          this._inject.get(SmsService),
          this._inject.get(PhoneService),
          this._inject.get(EmailService),
        ];
      case MentionResourceType.Staffer:
        return [
          this._inject.get(LoadStafferActionService),
          // TODO
          // this._inject.get(CreateTaskActionService),
        ];
      case MentionResourceType.Lab:
        return [
          this._inject.get(LoadLabActionService),
          this._inject.get(PhoneService),
        ];
      case MentionResourceType.LabJob:
        return [this._inject.get(LoadLabJobActionService)];
      case MentionResourceType.Appointment:
        return [
          this._inject.get(LoadPatientActionService),
          this._inject.get(LoadTimelineActionService),
          this._inject.get(SmsService),
          this._inject.get(PhoneService),
          this._inject.get(EmailService),
        ];
      case MentionResourceType.Task:
        return [this._inject.get(LoadTaskActionService)];
      case MentionResourceType.Candidate:
        return [
          this._inject.get(LoadPatientActionService),
          // this._inject.get(OfferAppointmentGapActionService),
          this._inject.get(SmsService),
          this._inject.get(PhoneService),
        ];
      case MentionResourceType.Contact:
        return [
          this._inject.get(LoadContactActionService),
          this._inject.get(PhoneService),
          this._inject.get(EmailService),
          this._inject.get(SmsService),
        ];
      case MentionResourceType.Invoice:
        return [this._inject.get(LoadInvoiceActionService)];
      default:
        return [];
    }
  }
}
