import { type ICustomReportColumn } from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  resolveCanBeChartedProperty,
} from '@principle-theorem/reporting';
import { type WithId } from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  ReportBuilderDataSource,
  type IReportBuilderDataSource,
} from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { toWithIds } from '../../report-builder.store';
import { TABLE_GROUP_KEY } from '../../../core/chart-builders/table-chart-builder';

export interface IResolvedColumnSection {
  name: string;
  columns: IResolvedColumnProperty[];
}

export interface IResolvedColumnProperty {
  definition: WithId<ICustomReportColumn>;
  property: CanBeChartedProperty;
}

export class ResolvedColumns {
  static getLabel(column: IResolvedColumnProperty): string {
    if (column.definition.label === TABLE_GROUP_KEY) {
      return ReportBuilderDataSource.getPropertyLabel(column.property);
    }
    return (
      column.definition.label ??
      ReportBuilderDataSource.getPropertyLabel(column.property)
    );
  }

  static toSections(
    dataSource?: IReportBuilderDataSource
  ): IResolvedColumnSection[] {
    if (!dataSource) {
      return [];
    }
    return ReportBuilderDataSource.buildColumnSections(dataSource).map(
      (section) => {
        return {
          ...section,
          columns: this.toColumns(dataSource, toWithIds(section.columns)),
        };
      }
    );
  }

  static toColumns(
    dataSource: IReportBuilderDataSource,
    columns: WithId<ICustomReportColumn>[]
  ): IResolvedColumnProperty[] {
    return compact(
      columns.map((column) => this.resolveColumn(dataSource, column))
    );
  }

  static resolveColumn(
    dataSource: IReportBuilderDataSource,
    column: WithId<ICustomReportColumn>
  ): IResolvedColumnProperty | undefined {
    const property = resolveCanBeChartedProperty(
      dataSource.factTable,
      column.id
    );
    return property ? { definition: column, property } : undefined;
  }
}
