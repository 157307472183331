<div class="flex flex-col" *ngrxLet="treatmentStep$ as treatmentStep">
  <ng-container *ngrxLet="selectedTreatment$ as selectedTreatment">
    <div class="flex items-center justify-between">
      <div class="flex w-full flex-col gap-6" class="title-group">
        <h2>Treatment</h2>
        <ng-container *ngrxLet="allPatientPlans$ as patientPlans">
          <ng-container *ngIf="patientPlans.length">
            <ng-container *ngIf="!selectPlanControl.value; else planSelected">
              <mat-hint>
                Creating New Plan or
                <a
                  href="javascript:void(0);"
                  class="action-link"
                  [matMenuTriggerFor]="planMenu"
                >
                  Select from existing plans
                </a>
              </mat-hint>
            </ng-container>
            <ng-template #planSelected>
              <mat-hint *ngIf="selectedTreatment | map: canChangePlan">
                <span *ngIf="isMostRecentPlan$ | async; else addToOtherPlan">
                  Adding as step to most recent plan:
                </span>
                <ng-template #addToOtherPlan> Adding as step to: </ng-template>
                <a
                  href="javascript:void(0);"
                  class="selected-plan"
                  [matMenuTriggerFor]="planMenu"
                >
                  <mat-icon>edit</mat-icon> {{ selectPlanControl.value?.name }}
                </a>
              </mat-hint>
            </ng-template>
          </ng-container>

          <mat-menu #planMenu="matMenu">
            <button mat-menu-item (click)="setPlan()">Create New Plan</button>
            <button
              *ngFor="
                let plan of patientPlans | map: filterPlans : this;
                trackBy: trackByPlan
              "
              mat-menu-item
              (click)="setPlan(plan)"
            >
              {{ plan.name }}
            </button>
          </mat-menu>
        </ng-container>

        <div class="flex gap-4">
          <a
            href="javascript:void(0);"
            class="action-link"
            *ngIf="selectedTreatment"
            (click)="resetSelectedTreatment()"
          >
            <mat-icon>edit</mat-icon> Change Selected Treatment
          </a>
          <a
            *ngIf="selectedTreatment | map: isTreatmentPlan"
            [prBrandRouterLink]="treatmentPlanLink$ | async"
            class="action-link"
          >
            <mat-icon>visibility</mat-icon> View Plan
          </a>
        </div>
      </div>

      <ng-container *ngIf="treatmentStep">
        <div class="actions" *ngIf="treatmentStep | map: isIncomplete">
          <pr-add-charted-treatment-button
            [connectedTo]="elementRef"
            (addChartable)="
              addChartable($event.chartable, $event.selectedSurfaces)
            "
            [inlcudeMultiTreatments]="false"
          />
        </div>
      </ng-container>
    </div>

    <div class="step-container" *ngIf="treatmentStep; else selectTreatment">
      <div class="flex gap-2">
        <div class="mat-caption step-name" class="flex-1">
          <input
            matInput
            autocomplete="off"
            type="text"
            [formControl]="stepNameCtrl"
            class="custom-input-default w-full"
            placeholder="Step Name"
          />
        </div>
        <pr-treatment-step-category-indicator
          [treatmentStepDisplay]="treatmentStep.display"
          (stepDisplayChange)="updateStepDisplay($event)"
          displayOnly
        />
      </div>
      <pr-treatment-scope-list
        [disabled]="treatmentStep | map: isComplete"
        [treatments]="treatmentStep.treatments"
        [step]="treatmentStep"
        [plan]="treatmentPlan$ | async"
        [compact]="true"
        (treatmentsChange)="updateTreatments($event)"
        (treatmentDeleted)="deleteTreatment($event)"
        (updateChartable)="updateChartable($event)"
        (categoryChanged)="updateStepDisplayOverride(treatmentStep, $event)"
      />
    </div>

    <div *ngrxLet="appointmentDetails$ as appointmentDetails">
      <div *ngrxLet="schedulingAlerts$ as alerts">
        <div
          *ngIf="alerts.length"
          class="flex items-center justify-start gap-4"
        >
          <mat-icon color="accent">warning</mat-icon>
          <h4 *ngFor="let alert of alerts; trackBy: trackByAlert" class="alert">
            {{ alert }}
          </h4>
        </div>
      </div>

      <ng-container *ngrxLet="durationWarningMessage$ as warning">
        <div
          *ngIf="warning.length"
          class="duration-warning-message flex items-center justify-start gap-2"
        >
          <mat-icon>warning</mat-icon>
          <span>{{ warning }}</span>
        </div>
      </ng-container>
    </div>

    <ng-template #selectTreatment>
      <div class="flex flex-col">
        <div class="flex flex-col">
          <span class="mat-caption opaque">
            Select From Treatment Templates
          </span>
          <ng-container *ngrxLet="filteredTemplates$ as templates">
            <mat-form-field class="flex-1">
              <input
                matInput
                [placeholder]="
                  templates.length
                    ? 'Search Templates'
                    : 'No treatments available for the selected Practitioner'
                "
                floatLabel="none"
                [formControl]="treatmentCtrl"
                [matAutocomplete]="treatmentAutoComplete"
                [disabled]="!templates.length"
              />

              <mat-autocomplete
                #treatmentAutoComplete="matAutocomplete"
                [autoActiveFirstOption]="true"
                [displayWith]="displayFn"
              >
                <ng-container
                  *ngIf="
                    treatmentTemplateSearchFilter.results$ | async as planPairs
                  "
                >
                  <mat-option
                    *ngFor="
                      let planPair of planPairs;
                      trackBy: trackByTemplatePlanPair
                    "
                    [value]="planPair"
                  >
                    {{ planPair.step.name }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>

              <button
                [disabled]="treatmentDisabled$ | async"
                type="button"
                matIconSuffix
                mat-icon-button
                (click)="clearTreatment()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="flex flex-col gap-2">
          <span
            class="mat-caption opaque"
            *ngIf="
              (unscheduledTreatmentSteps.filteredTreatmentPlans$ | async)
                ?.length
            "
          >
            Select From Existing Treatment Plans
          </span>
          <pr-treatment-plans
            #unscheduledTreatmentSteps="prTreatmentPlans"
            [patient]="patient$ | async"
            [currentPractitioner]="currentPractitioner$ | async"
            [statuses]="treatmentStatuses"
            [filterStepStatuses]="treatmentStepStatuses"
            (stepAdded)="selectStep($event)"
          />
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
