import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  nextRange,
  previousRange,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  ITimePeriod,
  serialise,
  unserialise$,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { CalendarActions } from '../actions';
import { CalendarFacade } from '../facades/calendar.facade';
import { CALENDAR_LOCAL_STORAGE } from '../local-store';

@Injectable()
export class CalendarEffects {
  private _actions$ = inject(Actions);
  private _calendarFacade = inject(CalendarFacade);
  nextRange$: Observable<Action> = createEffect(() => this._nextRange$());
  previousRange$: Observable<Action> = createEffect(() =>
    this._previousRange$()
  );
  setRange$: Observable<void> = createEffect(() => this._setRange$(), {
    dispatch: false,
  });
  setFromRoute$: Observable<void> = createEffect(() => this._setFromRoute$(), {
    dispatch: false,
  });

  private _nextRange$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(CalendarActions.nextCalendarRange),
      withLatestFrom(this._calendarFacade.unit$, this._calendarFacade.range$),
      map(([_action, unit, range]) => {
        const next = nextRange(unit, range);
        return CalendarActions.setCalendarRange(
          serialise({
            range: next,
          })
        );
      })
    );
  }

  private _previousRange$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(CalendarActions.previousCalendarRange),
      withLatestFrom(this._calendarFacade.unit$, this._calendarFacade.range$),
      map(([_action, unit, range]) => {
        const previous = previousRange(unit, range);
        return CalendarActions.setCalendarRange(
          serialise({
            range: previous,
          })
        );
      })
    );
  }

  private _setRange$(): Observable<void> {
    return this._actions$.pipe(
      ofType(CalendarActions.setCalendarRange),
      unserialise$(),
      map((action) => CALENDAR_LOCAL_STORAGE.update(formatRange(action.range)))
    );
  }

  private _setFromRoute$(): Observable<void> {
    return this._actions$.pipe(
      ofType(CalendarActions.setViewUnitAndRange),
      unserialise$(),
      map((action) => CALENDAR_LOCAL_STORAGE.update(formatRange(action.range)))
    );
  }
}

function formatRange(range: ITimePeriod): { from: string; to: string } {
  return {
    from: range.from.format(ISO_DATE_FORMAT),
    to: range.to.format(ISO_DATE_FORMAT),
  };
}
