import {
  initVersionedSchema,
  toParagraphContent,
} from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  IHasSourceIdentifier,
  ITranslationMap,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  ISODateType,
  Timestamp,
  Timezone,
  getError,
  resolveSequentially,
  sortTimestamp,
  toISODate,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { groupBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IOasisPatientNote,
  IOasisPatientNoteFilters,
  IOasisPatientNoteTranslations,
  PatientNoteSourceEntity,
} from '../../source/entities/patient-notes';
import {
  IOasisPatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { OasisItemCodeMappingHandler } from '../mappings/item-codes';
import { OasisPatientNoteCategoryToNoteMappingHandler } from '../mappings/patient-note-categories-to-notes';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IJobData extends IClinicalNoteJobData<IOasisPatient> {
  noteCategories: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<
  IOasisPatient,
  IJobData
> {
  patientSourceEntity = new PatientSourceEntity();
  override batchLimit = 1000;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientNote: new PatientNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new OasisStafferMappingHandler(),
    itemCodes: new OasisItemCodeMappingHandler(),
    patientNoteCategories: new OasisPatientNoteCategoryToNoteMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const noteCategories$ =
      this.customMappings.patientNoteCategories.getRecords$(translationMap);

    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      withLatestFrom(staff$, sourceItemCodes$, noteCategories$),
      map(([sourcePatients, staff, sourceItemCodes, noteCategories]) =>
        sourcePatients.map((sourcePatient) => ({
          staff,
          noteCategories,
          sourcePatient,
          sourceItemCodes,
          destinationEntity,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      const clinicalNotes = await this._buildClinicalNotes(migration, data);

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private async _buildClinicalNotes(
    migration: WithRef<IPracticeMigration>,
    data: IJobData
  ): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
    const timezone = migration.configuration.timezone;
    const sourcePatientId = data.sourcePatient.data.data.id;

    const sourcePatientNotes =
      await this.sourceEntities.patientNote.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const sorted = sourcePatientNotes.sort((treatmentA, treatmentB) =>
      sortTimestamp(
        this._getNoteTimestamp(treatmentA, timezone),
        this._getNoteTimestamp(treatmentB, timezone)
      )
    );

    const userGroupedNotes = groupBy(
      sorted,
      (record) => record.data.data.practitionerId ?? '0'
    );

    const clinicalNotes = await resolveSequentially(
      Object.entries(userGroupedNotes),
      async ([providerId, userNotes]) => {
        const staffer = await getClinicalNotePractitioner(
          providerId,
          data.staff
        );
        if (!staffer) {
          throw new Error(`Failed to resolve practitioner code: ${providerId}`);
        }

        const flteredNotes = userNotes.filter((note) => {
          if (!note.data.data.note.trim()) {
            return false;
          }

          const isClinicalNoteType = note.data.data.noteType === 0;
          if (isClinicalNoteType) {
            return true;
          }

          const categoryId = note.data.data.categoryId;
          if (!categoryId) {
            return;
          }

          const noteCategoryMapping = data.noteCategories.find(
            (category) => category.sourceIdentifier === categoryId.toString()
          );

          if (!noteCategoryMapping?.destinationValue) {
            throw new Error(
              `Note category mapping not found for ${note.data.data.categoryId}`
            );
          }

          if (
            noteCategoryMapping.destinationValue !==
            ItemCodeNoteType.ClinicalNote
          ) {
            return false;
          }

          return true;
        });

        const dateGroupedNotes = groupBy(flteredNotes, (note) =>
          this._getNoteDate(note, timezone)
        );

        return Object.values(dateGroupedNotes).map((notes) => {
          const content = notes
            .reduce(
              (results, note) => {
                const existing = results.find(
                  (result) => result.data.data.note === note.data.data.note
                );
                if (!existing) {
                  results.push(note);
                }
                return results;
              },
              [] as IGetRecordResponse<
                IOasisPatientNote,
                IOasisPatientNoteTranslations,
                IOasisPatientNoteFilters
              >[]
            )
            .map((note) => note.data.data.note ?? '');

          const noteDate = this._getNoteDate(notes[0], timezone);
          const noteTimestamp = this._getNoteTimestamp(notes[0], timezone);

          return {
            sourceIdentifier: `${notes[0].data.data.patientId}-${providerId}-${noteDate}`,
            ...ClinicalNote.init({
              owner: stafferToNamedDoc(staffer),
              content: initVersionedSchema(
                content
                  .map((text) => [
                    toParagraphContent(text.trim() ?? ' '),
                    toParagraphContent(' '),
                  ])
                  .flat()
              ),
              recordDate: noteDate ?? toISODate(toTimestamp()),
              createdAt: noteTimestamp,
              updatedAt: noteTimestamp,
              immutable: true,
            }),
          };
        });
      }
    );
    return clinicalNotes.flat();
  }

  private _getNoteTimestamp(
    sourceNote: IGetRecordResponse<
      IOasisPatientNote,
      IOasisPatientNoteTranslations,
      IOasisPatientNoteFilters
    >,
    timezone: Timezone
  ): Timestamp {
    const noteDate = this._getNoteDate(sourceNote, timezone);
    return noteDate
      ? toTimestamp(toMomentTz(noteDate, timezone))
      : toTimestamp();
  }

  private _getNoteDate(
    sourceNote: IGetRecordResponse<
      IOasisPatientNote,
      IOasisPatientNoteTranslations,
      IOasisPatientNoteFilters
    >,
    timezone: Timezone
  ): ISODateType | undefined {
    return toISODate(sourceNote.data.translations.createdAt, timezone);
  }
}

export async function getClinicalNotePractitioner(
  practitionerId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer>> {
  const practitionerMap = getPractitionerOrDefaultMapping(practitionerId, staff)
    ?.destinationIdentifier;

  if (!practitionerMap) {
    throw new Error(`Couldn't find practitioner with id ${practitionerId}`);
  }

  const creator = await Firestore.getDoc(practitionerMap);
  return stafferToNamedDoc(creator);
}
