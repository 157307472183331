<ng-container *ngIf="referrer$ | async as referrer">
  <ng-container *ngIf="linkableReferrer$ | async as link">
    <a [routerLink]="link">
      {{ referrer.name }}
    </a>
  </ng-container>
  <span *ngIf="defaultReferrer$ | async as default">
    {{ default }}
  </span>
</ng-container>
