import { Pipe, type PipeTransform } from '@angular/core';
import { formatTimeStringDisplay } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';

@Pipe({
  name: 'timeDisplay',
})
export class TimeDisplayPipe implements PipeTransform {
  transform(value: string | Moment): string {
    return formatTimeStringDisplay(value);
  }
}
