<mat-toolbar color="accent">
  <span fxFlex>SMS {{ data.contact.key }}</span>
</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      class="mobile-phone-container"
    >
      <mat-icon>phone_iphone</mat-icon>
      <h3>{{ mobile }}</h3>
    </div>

    <ng-container *ngIf="organisation.hasMultiplePractices$ | async">
      <pr-practice-selector
        fxFlex
        *ngIf="brand$ | async as brand"
        label="Send SMS as"
        [brands]="[brand]"
        [practice]="selectedPractice$ | async"
        (practiceSelected)="selectedPractice$.next($event)"
      />
    </ng-container>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>SMS Message</mat-label>
      <pr-content-editor
        [formControl]="noteControl"
        [menuEnabled]="false"
        placeholder="SMS Message"
        (keydown.meta.Enter)="addNote()"
        [required]="true"
        [extensions]="extensions"
        [slashMenuItems]="slashMenuItems"
      />
      <pt-character-counter
        label="SMS Segments"
        [divisor]="segmentSize"
        [formCtrl]="noteControl"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
    <pr-template-selector
      [templates]="(templates$ | async)?.templates"
      [contextOptions]="(templates$ | async)?.contextOptions"
      [resetOnSelection]="true"
      (templateSelected)="templateSelected($event)"
    />

    <pt-buttons-container padding="none">
      <button mat-stroked-button (click)="dialogRef.close()">Close</button>
      <pt-loader-button
        color="primary"
        type="flat"
        [loading]="submitting$ | async"
        [disabled]="isDisabled$ | async"
        (clicked)="addNote()"
      >
        Send
      </pt-loader-button>
    </pt-buttons-container>
  </div>
</mat-dialog-actions>
