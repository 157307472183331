import { TaxStrategy } from '@principle-theorem/accounting';
import { IProduct } from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  initFirestoreModel,
  isEnumValue,
  isObject,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';

export class Product {
  static init(overrides: AtLeast<IProduct, 'name'>): IProduct {
    return {
      cost: 0,
      taxStatus: TaxStrategy.GSTApplicable,
      ...initFirestoreModel(),
      ...overrides,
    };
  }
}

export function isProduct(item: unknown): item is IProduct {
  return (
    isObject(item) &&
    isString(item.name) &&
    isNumber(item.cost) &&
    isEnumValue(TaxStrategy, item.taxStatus)
  );
}
