import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { NgSterilisationModule } from './ng-sterilisation.module';
import { SterilisationPageComponent } from './pages/sterilisation-page/sterilisation-page.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Sterilisation Tracking' },
    component: SterilisationPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgSterilisationModule],
  exports: [RouterModule],
})
export class NgSterilisationRoutingModule {}
