import { type RawInlineNodes } from '@principle-theorem/editor';
import {
  AutomationType,
  type IAutomatedNotification,
  type IAutomation,
  type IGeneratedTask,
  isAutomatedNotification,
  isGeneratedTask,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import { type EntityModel, type Timestamp } from '@principle-theorem/shared';
import { omit } from 'lodash';

export interface IAutomationWithMetadata
  extends IAutomation<TreatmentStepAutomation> {
  appointmentSummary?: string;
  patientMention?: RawInlineNodes;
}

export type AutomationEntity = EntityModel<IAutomationWithMetadata>;

export function getAutomationEntity(
  automation: AutomationEntity
): EntityModel<IAutomation<TreatmentStepAutomation>> {
  return omit(automation, [
    'patientMention',
    'appointmentSummary',
  ]) as EntityModel<IAutomation<TreatmentStepAutomation>>;
}

export function isGeneratedTaskAutomation(
  entity: AutomationEntity
): entity is EntityModel<IAutomation<IGeneratedTask>> {
  return (
    entity.type === AutomationType.GeneratedTask && isGeneratedTask(entity.data)
  );
}

export function isAutomatedNotificationAutomation(
  entity: AutomationEntity
): entity is EntityModel<IAutomation<IAutomatedNotification>> {
  return (
    entity.type === AutomationType.AutomatedNotification &&
    isAutomatedNotification(entity.data)
  );
}

export interface IRescheduleListData {
  automation: AutomationEntity;
  autoCancelled: boolean;
  reschedule: boolean;
  newDate?: Timestamp;
  changes?: Partial<IAutomation<TreatmentStepAutomation>>;
}
