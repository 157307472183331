import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  getRefundRemaining,
  Invoice,
  RefundHelpers,
} from '@principle-theorem/principle-core';
import {
  InvoiceType,
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, snapshot, WithRef } from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { ITransactionActionsData } from '../../transaction-action';
import {
  TransactionEditDialogComponent,
  type ITransactionEditDialogData,
  type ITransactionEditDialogResult,
} from '../transaction-edit-dialog/transaction-edit-dialog.component';
import { TransactionPracticeOptionMethods } from '../transaction-practice-options';
import {
  TransactionAmountDialogComponent,
  type ITransactionAmountDialogData,
  type ITransactionAmountDialogResult,
  type ITransactionAmountFormOptions,
} from './transaction-amount-dialog.component';

@Injectable()
export class TransactionAmountDialog {
  constructor(private _dialog: MatDialog) {}

  async openForInvoice(
    title: string,
    invoice: WithRef<IInvoice>,
    options: Partial<ITransactionAmountFormOptions> = {}
  ): Promise<ITransactionAmountDialogResult | undefined> {
    const transactions = await snapshot(Invoice.transactions$(invoice));
    const balance = Invoice.balance(invoice, transactions);
    return this._open(
      TransactionPracticeOptionMethods.toInvoicePracticeOption(invoice),
      invoice,
      title,
      balance,
      options
    );
  }

  async openForRefund(
    title: string,
    data: ITransactionActionsData<unknown>,
    options: Partial<ITransactionAmountFormOptions> = {}
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this.openRefund(
      title,
      data.invoice,
      data.latestTransaction,
      options
    );
  }

  async openRefund(
    title: string,
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction>,
    options: Partial<ITransactionAmountFormOptions> = {}
  ): Promise<ITransactionAmountDialogResult | undefined> {
    const transactions = await snapshot(Invoice.transactions$(invoice));
    const transactionsMax = transaction
      ? getRefundRemaining(transaction, transactions)
      : RefundHelpers.getTotalRefundRemaining(transactions);
    const invoiceMax = Invoice.balance(invoice, transactions);
    const max =
      invoice.type === InvoiceType.CreditNote
        ? Math.abs(invoiceMax)
        : transactionsMax;

    const practice = transaction
      ? await TransactionPracticeOptionMethods.toTransactionPracticeOption(
          transaction
        )
      : TransactionPracticeOptionMethods.toInvoicePracticeOption(invoice);
    return this._open(practice, invoice, title, max, options);
  }

  openEdit(
    title: string,
    transaction: WithRef<ITransaction>,
    invoice: IInvoice,
    dateReceived?: Moment,
    max?: number
  ): Promise<ITransactionEditDialogResult | undefined> {
    const data = {
      title,
      max,
      transaction,
      invoice,
      dateReceived,
    };
    return this._dialog
      .open<
        TransactionEditDialogComponent,
        ITransactionEditDialogData,
        ITransactionEditDialogResult
      >(TransactionEditDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
  }

  private async _open(
    practice: INamedDocument<IPractice>,
    invoice: IInvoice,
    title: string,
    max?: number,
    options: Partial<ITransactionAmountFormOptions> = {}
  ): Promise<ITransactionAmountDialogResult | undefined> {
    const data = { practice, invoice, title, max, options };
    return this._dialog
      .open<
        TransactionAmountDialogComponent,
        ITransactionAmountDialogData,
        ITransactionAmountDialogResult
      >(TransactionAmountDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
  }
}
