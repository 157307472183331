import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'pr-profile-card-title',
    template: '<ng-content></ng-content>',
    standalone: false
})
export class ProfileCardTitleComponent {
  @HostBinding('class') classes = 'pr-profile-card-title';
}
