import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type AppointmentItemViewType,
  type ILabJob,
  type IPrincipleMention,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { LabJob, toMention } from '@principle-theorem/principle-core';
import { DATE_TIME_FORMAT, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { getSchemaText } from '@principle-theorem/editor';

@Component({
    selector: 'pr-appointment-lab-jobs',
    templateUrl: './appointment-lab-jobs.component.html',
    styleUrls: ['./appointment-lab-jobs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentLabJobsComponent {
  readonly dateFormat = DATE_TIME_FORMAT;
  trackByLabJob = TrackByFunctions.ref<WithRef<ILabJob>>();
  labJobs$: ReplaySubject<WithRef<ILabJob>[]> = new ReplaySubject(1);
  @Input() viewType: AppointmentItemViewType = 'sidebar';

  @Input()
  set labJobs(labJobs: WithRef<ILabJob>[]) {
    if (labJobs) {
      this.labJobs$.next(labJobs);
    }
  }

  isOverdue(labJob: ILabJob): boolean {
    return LabJob.isOverdue(labJob);
  }

  toMention(labJob: WithRef<ILabJob>): IPrincipleMention {
    return toMention(
      { name: getSchemaText(labJob.title), ref: labJob.ref },
      MentionResourceType.LabJob
    );
  }
}
