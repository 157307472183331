import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartableSurfaceResolver,
  ChartedSurface,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  CHARTABLE_SURFACES,
  type IChartedMultiStepTreatment,
  type IMultiTreatmentPackage,
  type IPricedServiceCodeEntry,
  ServiceCodeGroupType,
  type IChartedServiceExclusiveGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  multiSwitchMap,
  snapshot,
} from '@principle-theorem/shared';
import { difference, first, flatten, intersection } from 'lodash';
import { combineLatest, type Observable, Subject } from 'rxjs';
import { auditTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../dental-chart-svg/chart-entity-id';
import {
  type IMultiTreatmentSurfaceSelectorData,
  type ITreatmentAndSurface,
  type ITreatmentStepWithSurfaces,
  MultiTreatmentSelectorStore,
  ISelectedIndexes,
} from './multi-treatment-selector.store';

@Component({
  selector: 'pr-multi-treatment-surface-selector-dialog',
  templateUrl: './multi-treatment-surface-selector-dialog.component.html',
  styleUrls: ['./multi-treatment-surface-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MultiTreatmentSelectorStore,
    {
      provide: CHART_ENTITY_ID,
      useValue: ChartId.InAppointment,
    },
  ],
})
export class MultiTreatmentSurfaceSelectorDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  isStacked$: Observable<boolean>;
  trackByStep =
    TrackByFunctions.nestedField<ITreatmentStepWithSurfaces>('step.name');
  trackByTreatment = TrackByFunctions.ref<ITreatmentAndSurface>();
  trackByCode = TrackByFunctions.uniqueId<IPricedServiceCodeEntry>();
  trackByPackage = TrackByFunctions.index<IMultiTreatmentPackage>();
  chartedTreatment$: Observable<IChartedMultiStepTreatment>;
  packages$: Observable<IMultiTreatmentPackage[]>;
  price$: Observable<number>;
  steps$: Observable<ITreatmentStepWithSurfaces[]>;
  selected$: Observable<ISelectedIndexes[]>;
  allTreatmentsCharted$: Observable<boolean>;

  constructor(
    public media: MediaObserver,
    private _chartStore: ChartFacade,
    @Inject(MAT_DIALOG_DATA) public data: IMultiTreatmentSurfaceSelectorData,
    public dialogRef: MatDialogRef<MultiTreatmentSurfaceSelectorDialogComponent>,
    private _store: MultiTreatmentSelectorStore
  ) {
    this.packages$ = this._store.packages$;
    this.price$ = this._store.price$;
    this.steps$ = this._store.steps$;
    this.selected$ = this._store.selected$;
    this.allTreatmentsCharted$ = this._store.allTreatmentsCharted$;
    this._store.setMultiTreatmentConfiguration({
      chartable: data.chartable,
      selectedSurfaces: data.selectedSurfaces,
    });

    this.isStacked$ = media
      .asObservable()
      .pipe(map((change) => change[0].mqAlias === 'sm'));

    combineLatest([
      this.selected$.pipe(isChanged$()),
      this._chartStore.chartingAs$(ChartId.InAppointment),
      this._chartStore.getFeeScheduleManager().currentSchedule$,
    ])
      .pipe(
        switchMap(([selectedTreatments, chartingAs, feeSchedule]) =>
          this._chartStore.selectedSurfacesState$(ChartId.InAppointment).pipe(
            auditTime(200),
            map((surfaces) => ({
              feeSchedule,
              selectedTreatments,
              chartedSurfaces: surfaces.map((surface) =>
                ChartedSurface.init({
                  chartedRef: surface,
                  chartedBy: stafferToNamedDoc(chartingAs),
                })
              ),
            }))
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ feeSchedule, selectedTreatments, chartedSurfaces }) => {
        selectedTreatments.map((selectedTreatment) =>
          this._store.updateTreatment({
            ...selectedTreatment,
            chartedSurfaces,
            feeSchedule,
          })
        );
      });

    this.selected$
      .pipe(
        multiSwitchMap((selectedTreatment) =>
          this._store
            .getTreatmentByIndex$(
              selectedTreatment.stepIndex,
              selectedTreatment.treatmentIndex
            )
            .pipe(filterUndefined(), take(1))
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((treatment) => this._updateSurfaces(treatment));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._chartStore.setDisabledSurfaces(ChartId.InAppointment, []);
    this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
  }

  async save(): Promise<void> {
    const treatment = await this._store.getChartedMultiTreatment();
    this.dialogRef.close(treatment);
  }

  selectTreatment(
    isDisabled: boolean,
    stepIndex: number,
    treatmentIndex: number
  ): void {
    if (isDisabled) {
      return;
    }

    this._store.setSelected({
      selected: [{ stepIndex, treatmentIndex }],
      overrideSelected: true,
    });
  }

  updateMultiSelect(
    stepIndex: number,
    treatmentIndex: number,
    checked: boolean
  ): void {
    if (checked) {
      this._store.setSelected({
        selected: [{ stepIndex, treatmentIndex }],
        overrideSelected: false,
      });
      return;
    }
    this._store.removeSelected({
      stepIndex,
      treatmentIndex,
    });
  }

  async updateSelectedPackage(
    multiTreatmentPackage: IMultiTreatmentPackage
  ): Promise<void> {
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    this._store.updateSelectedPackage({
      package: multiTreatmentPackage,
      feeSchedule,
    });
  }

  isSelected$(stepIndex: number, treatmentIndex: number): Observable<boolean> {
    return this.selected$.pipe(
      map((selected) =>
        selected.some(
          (selectedTreatment) =>
            selectedTreatment.stepIndex === stepIndex &&
            selectedTreatment.treatmentIndex === treatmentIndex
        )
      )
    );
  }

  isSelectedPackage$(packageUid: string): Observable<boolean> {
    return this._store.isSelectedPackage$(packageUid);
  }

  isPackageCompleted$(packageUid: string): Observable<boolean> {
    return this._store.isPackageCompleted$(packageUid);
  }

  resetSurfaces(stepIndex: number, treatmentIndex: number): void {
    this._store.resetSurfaces({ stepIndex, treatmentIndex });
  }

  async updateServiceCode(
    uuid: string,
    serviceCode: Partial<IPricedServiceCodeEntry>,
    stepIndex: number,
    treatmentIndex: number
  ): Promise<void> {
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    this._store.updateTreatment({
      stepIndex,
      treatmentIndex,
      serviceCode: {
        uuid,
        ...serviceCode,
      },
      feeSchedule,
    });
  }

  getServiceCodes(treatment: ITreatmentAndSurface): IPricedServiceCodeEntry[] {
    const exclusiveGroups: IChartedServiceExclusiveGroup[] =
      treatment.serviceCodeGroups.filter(
        (group) => group.type === ServiceCodeGroupType.Exclusive
      );
    return flatten([
      ...treatment.pricedServiceCodes,
      ...exclusiveGroups.map((group) =>
        group.serviceCodes.filter((code) => code.code === group.selected)
      ),
    ]);
  }

  private _updateSurfaces(treatments: ITreatmentAndSurface[]): void {
    this._chartStore.setDisabledSurfaces(ChartId.InAppointment, []);

    this._chartStore.setSelectedSurfaces(
      ChartId.InAppointment,
      (first(treatments)?.chartedSurfaces ?? []).map(
        (chartedSurface) => chartedSurface.chartedRef
      )
    );

    const compatibleSurfaces = intersection(
      ...treatments.map((treatment) =>
        ChartableSurfaceResolver.getChartableSurfaces(treatment.treatment)
      )
    );

    if (
      compatibleSurfaces.includes(ChartableSurface.MultipleTeeth) &&
      !compatibleSurfaces.includes(ChartableSurface.WholeTooth)
    ) {
      compatibleSurfaces.push(ChartableSurface.WholeTooth);
    }

    this._chartStore.setDisabledSurfaces(
      ChartId.InAppointment,
      difference(CHARTABLE_SURFACES, compatibleSurfaces)
    );
  }
}
