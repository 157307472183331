import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type MatCheckboxChange } from '@angular/material/checkbox';
import { type RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import {
  type IAutomatedNotification,
  type IAutomation,
  type IGeneratedTask,
  isAutomatedNotification,
} from '@principle-theorem/principle-core/interfaces';
import { DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-automation-list-item',
  templateUrl: './automation-list-item.component.html',
  styleUrls: ['./automation-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationListItemComponent {
  checked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  automation$: ReplaySubject<
    IAutomation<IAutomatedNotification | IGeneratedTask>
  > = new ReplaySubject(1);
  title$: Observable<RawInlineNodes>;
  newDate$ = new ReplaySubject<Timestamp>(1);
  readonly dateFormat = DATE_TIME_FORMAT;
  @Input() withCheckbox = true;

  @Input()
  set automation(
    automation: IAutomation<IAutomatedNotification | IGeneratedTask>
  ) {
    if (automation) {
      this.automation$.next(automation);
    }
  }

  @Input()
  set checked(checked: boolean) {
    if (checked) {
      this.checked$.next(checked);
    }
  }

  @Input()
  set newDate(newDate: Timestamp) {
    if (newDate) {
      this.newDate$.next(newDate);
    }
  }

  @Output()
  checkedChange = new EventEmitter<MatCheckboxChange>();

  constructor() {
    this.title$ = this.automation$.pipe(
      map((automation) =>
        isAutomatedNotification(automation.data)
          ? [toTextContent(automation.data.name)]
          : automation.data.title
      )
    );
  }
}
