<div
  class="dropzone"
  ptFileUploadDropzone
  (hovered)="toggleHover($event)"
  (dropped)="fileDropped($event)"
  [class.hovering]="isHovering"
>
  <input type="file" multiple #fileInput (change)="startUpload($event)" />
  <div class="content layout-padding flex flex-col">
    <ng-container *ngrxLet="tasks$ as tasks">
      <ng-container *ngIf="!tasks.length; else uploading">
        <div class="upload-container flex items-center justify-center">
          <div class="flex flex-col items-center gap-0.5">
            <span class="mat-caption">Drag and drop or</span>
            <button mat-stroked-button (click)="fileInput.click()">
              <div
                class="button-inner"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <mat-icon>cloud_upload</mat-icon>
                Choose a file...
              </div>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-template #uploading>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="16px grid"
        >
          <div *ngFor="let task of tasks; trackBy: trackByTask">
            <mat-progress-bar
              mode="determinate"
              [value]="task.percentage$ | async"
            />

            <ng-container *ngIf="task">
              <div *ngIf="task.snapshot$ | async as snapshot">
                <button
                  mat-icon-button
                  color="primary"
                  (click)="task.upload.pause()"
                  [disabled]="(snapshot | map: isActive) === false"
                >
                  <mat-icon>pause</mat-icon>
                </button>

                <button
                  mat-icon-button
                  color="primary"
                  (click)="task.upload.cancel()"
                  [disabled]="(snapshot | map: isActive) === false"
                >
                  <mat-icon>stop</mat-icon>
                </button>

                <button
                  mat-icon-button
                  color="primary"
                  (click)="task.upload.resume()"
                  [disabled]="snapshot.state !== 'paused'"
                >
                  <mat-icon>play_arrow</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-content />
    </ng-container>
  </div>
</div>
