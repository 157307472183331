import {
  ICalendarEvent,
  ICalendarEventSchedule,
  ICalendarEventTemplate,
  ISchedule,
  ScheduleTime,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  isWithRef,
  ITimePeriod,
  TimeBucket,
  Timezone,
  WithRef,
} from '@principle-theorem/shared';
import { Schedule } from '../schedule';
import { scheduleTimeToTimePeriod } from '../schedule/schedule-time';
import { CalendarEventTemplate } from './calendar-event-template';
import { TimezoneResolver } from '../../timezone';

export class CalendarEventSchedule {
  static init(
    overrides: AtLeast<ISchedule<ICalendarEventTemplate>, 'item'>,
    scheduleTime: ScheduleTime
  ): ICalendarEventSchedule {
    return { ...Schedule.init(overrides), scheduleTime };
  }

  static async buildEvents(
    schedule: ICalendarEventSchedule | WithRef<ICalendarEventSchedule>,
    range: ITimePeriod,
    filterPeriods: ITimePeriod[] = []
  ): Promise<ICalendarEvent[]> {
    if (!schedule.item.event.practice) {
      // eslint-disable-next-line no-console
      console.error('Missing Practice from CalendarEventSchedule');
      return [];
    }
    const timezone = await TimezoneResolver.fromPracticeRef(
      schedule.item.event.practice.ref
    );
    return CalendarEventSchedule.buildEventsWithTimezone(
      schedule,
      range,
      filterPeriods,
      timezone
    );
  }

  static buildEventsWithTimezone(
    schedule: ICalendarEventSchedule | WithRef<ICalendarEventSchedule>,
    range: ITimePeriod,
    filterPeriods: ITimePeriod[] = [],
    timezone: Timezone
  ): ICalendarEvent[] {
    const forecasted = Schedule.forecast(schedule, range, timezone).map(
      (date) => scheduleTimeToTimePeriod(schedule.scheduleTime, date)
    );

    const filteredPeriods = new TimeBucket(forecasted)
      .subtract(filterPeriods)
      .get();

    return filteredPeriods.map((timePeriod) => {
      const event = CalendarEventTemplate.toCalendarEvent(
        schedule.item,
        timePeriod
      );
      if (isWithRef(schedule)) {
        event.scheduleRef = schedule.ref;
      }
      return event;
    });
  }
}
