import {
  IBrand,
  IPractice,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import { toQuery } from '@principle-theorem/shared';
import {
  CollectionReference,
  DocumentReference,
  Query,
  where,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class UserQueryBuilder {
  private _queryCol: Observable<Query<IUser>>;

  constructor(userCol$: Observable<CollectionReference<IUser>>) {
    this._queryCol = userCol$;
  }

  fromBrand(brandRef: DocumentReference<IBrand>): this {
    this._queryCol = this._queryCol.pipe(
      map((userCol) =>
        toQuery(userCol, where('brands', 'array-contains', brandRef))
      )
    );
    return this;
  }

  fromPractice(practiceRef: DocumentReference<IPractice>): this {
    this._queryCol = this._queryCol.pipe(
      map((userCol) =>
        toQuery(userCol, where('practices', 'array-contains', practiceRef))
      )
    );
    return this;
  }

  result$(): Observable<Query<IUser>> {
    return this._queryCol;
  }
}
