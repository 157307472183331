<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutGap="16px">
    <pt-search-field fxFlex>
      <input matInput placeholder="Search" [formControl]="searchCtrl" />
    </pt-search-field>

    <button mat-raised-button color="primary" (click)="createDocument()">
      Create
    </button>
  </div>

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="list"
    title="patient documents"
   />

  <ng-container *ngIf="searchFilter.results$ | async as documents">
    <mat-nav-list *ngIf="documents.length; else noSearchResults">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="loading$ | async"
       />
      <a
        mat-list-item
        [routerLink]="[document.ref.id]"
        *ngFor="let document of documents; trackBy: trackByDocument"
      >
        <span fxFlex>{{ document.name }}</span>
        <span class="mat-caption">{{
          document.createdAt | moment | amDateFormat: dateFormat
        }}</span>
      </a>
    </mat-nav-list>
  </ng-container>
</div>

<ng-template #noSearchResults>
  <pr-empty-state
    *ngIf="(emptyState$ | async) === false"
    image="search"
    title="search results"
    [searchInputValue]="searchCtrl.value"
   />
</ng-template>
