import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pr-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundImageComponent {
  url = '';

  @Input()
  @HostBinding('class.with-blur')
  withBlur = false;

  @Input()
  @HostBinding('class.with-inner-shadow')
  withInnerShadow = false;

  @Input()
  @HostBinding('class.with-gradient')
  withGradient = false;

  @Input()
  @HostBinding('class.fit-image')
  fitImage = false;

  @Input()
  set src(src: string) {
    this.url = `url("${src}")`;
  }
}
