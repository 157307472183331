import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  type IInteractionData,
  type IInteractiveResource,
  type IPrincipleMention,
  PatientContactDetailsError,
  type WithTemplateResource,
} from '@principle-theorem/principle-core/interfaces';
import { BaseInteractionService } from './base-interaction.service';

@Injectable({
  providedIn: 'root',
})
export abstract class InteractionWithTemplatesService<
  T
> extends BaseInteractionService<T> {
  constructor(dialog: MatDialog, private _snackBar: MatSnackBar) {
    super(dialog);
  }

  protected override async _getDialogData(
    contact: IPrincipleMention,
    resource: IInteractiveResource,
    templateResource?: IPrincipleMention
  ): Promise<WithTemplateResource<IInteractionData> | undefined> {
    try {
      const parentData = await super._getDialogData(contact, resource);
      if (!parentData) {
        throw new Error('Failed retrieving parent data');
      }

      return {
        ...parentData,
        templateResource: templateResource ?? contact,
      };
    } catch (error) {
      let message = 'Error retrieving template data';
      if (error instanceof PatientContactDetailsError) {
        message = `Patient is missing required contact details`;
      }
      this._snackBar.open(message, 'OK');
    }
  }
}
