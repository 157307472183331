<ng-container *ngrxLet="gap$ as gap">
  <div class="gap-item flex items-center" (click)="selectGap(gap)">
    <div class="flex flex-1 items-center gap-6">
      <pt-user-icon
        [src]="gap | map: getPractitionerImage$ : this | async"
        [name]="gap | map: getPractitionerName$ : this | async"
        [diameter]="28"
      />
      <div>
        {{ gap.event | map: getEventMoment : this | async }}
      </div>
    </div>
    <div class="flex items-center gap-2">
      <div>{{ gap | map: getGapDurationString : this }}</div>
      <div class="buttons-container">
        <button
          class="opacity-50"
          mat-icon-button
          matTooltip="Preview Gap"
          (click)="$event.stopPropagation(); previewGap(gap)"
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          [matMenuTriggerFor]="moreMenu"
          (click)="handleMoreMenuClick($event)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-menu #moreMenu="matMenu">
    @for (mode of createModes; track mode) {
      <button mat-menu-item (click)="createEventFromGap(gap, mode)">
        <span>Create {{ mode | splitCamel | titlecase }}</span>
      </button>
    }
  </mat-menu>
</ng-container>
