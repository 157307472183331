import {
  ChartableSurface,
  CHARTABLE_SURFACES,
} from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

type SurfaceFormData = Record<ChartableSurface, boolean>;

export class AvailableSurfacesFormGroup extends TypedFormGroup<SurfaceFormData> {
  constructor() {
    super({
      facial: new TypedFormControl<boolean>(false),
      lingual: new TypedFormControl<boolean>(false),
      mesial: new TypedFormControl<boolean>(false),
      distal: new TypedFormControl<boolean>(false),
      occlusal: new TypedFormControl<boolean>(false),
      wholeTooth: new TypedFormControl<boolean>(false),
      crown: new TypedFormControl<boolean>(false),
      wholeMouth: new TypedFormControl<boolean>(false),
      arch: new TypedFormControl<boolean>(false),
      quadrant: new TypedFormControl<boolean>(false),
      multipleTeeth: new TypedFormControl<boolean>(false),
      unscoped: new TypedFormControl<boolean>(false),
    });
  }
}

export function setSelectedSurfaces(
  form: AvailableSurfacesFormGroup,
  surfaces: ChartableSurface[]
): void {
  const options = { emitEvent: false };
  form.reset(
    {
      facial: false,
      lingual: false,
      mesial: false,
      distal: false,
      occlusal: false,
      wholeTooth: false,
      crown: false,
      wholeMouth: false,
      arch: false,
      quadrant: false,
      multipleTeeth: false,
      unscoped: false,
    },
    options
  );
  surfaces.map((surface: ChartableSurface) =>
    form.controls[surface].setValue(true, options)
  );
}

export function getSelectedSurfaces(
  formData: SurfaceFormData
): ChartableSurface[] {
  const selected = CHARTABLE_SURFACES.filter(
    (surface: ChartableSurface) => formData[surface]
  );
  return !selected.length ? [ChartableSurface.Unscoped] : selected;
}
