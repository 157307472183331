import { compact, isEqual, isNumber, round } from 'lodash';

export function getExpectationErrors(
  testFn: (test: ExpectationContainer) => void
): string[] {
  const test = new ExpectationContainer();
  testFn(test);
  return test.getErrors();
}

export class Expectation {
  private _hasRun = false;
  private _errorMessage?: string;

  constructor(private _receivedValue: unknown) {}

  getError(): string | undefined {
    if (!this._hasRun) {
      return 'Expectation not set';
    }
    if (this._errorMessage) {
      return this._errorMessage;
    }
    return;
  }

  toEqual(expectedValue: unknown): this {
    if (isEqual(this._receivedValue, expectedValue)) {
      return this._pass();
    }
    return this._fail(this._expected('to equal', expectedValue));
  }

  toBeGreaterThanOrEqual(expectedValue: number): this {
    if (!isNumber(this._receivedValue)) {
      return this._fail(this._expected('to be a number'));
    }
    return this._receivedValue >= expectedValue
      ? this._pass()
      : this._fail(this._expected('>=', expectedValue));
  }

  toBeLessThanOrEqual(expectedValue: number): this {
    if (!isNumber(this._receivedValue)) {
      return this._fail(this._expected('to be a number'));
    }
    return this._receivedValue <= expectedValue
      ? this._pass()
      : this._fail(this._expected('<=', expectedValue));
  }

  toBeCloseTo(expectedValue: number, precision: number = 2): this {
    if (!isNumber(this._receivedValue)) {
      return this._fail(this._expected('to be a number'));
    }
    return round(this._receivedValue, precision) <=
      round(expectedValue, precision)
      ? this._pass()
      : this._fail(this._expected('to be close to', expectedValue));
  }

  toBeDefined(): this {
    return this._receivedValue !== undefined
      ? this._pass()
      : this._fail(`Expected value to be defined`);
  }

  toPass(testFn: () => boolean, customMessage: string): this {
    return testFn() ? this._pass() : this._fail(customMessage);
  }

  private _pass(): this {
    this._hasRun = true;
    this._errorMessage = undefined;
    return this;
  }

  private _fail(errorMessage: string): this {
    this._hasRun = true;
    this._errorMessage = errorMessage;
    return this;
  }

  private _expected(comparison?: string, expectedValue?: unknown): string {
    const received = JSON.stringify(this._receivedValue);
    const expected = expectedValue ? JSON.stringify(expectedValue) : undefined;
    return compact(['Expected', received, comparison, expected]).join(' ');
  }
}

export class ExpectationContainer {
  constructor(private collections: Expectation[] = []) {}

  expect(value: unknown): Expectation {
    const expectation = new Expectation(value);
    this.collections.push(expectation);
    return expectation;
  }

  getErrors(): string[] {
    return compact(
      this.collections.map((collection) => collection.getError())
    ).flat();
  }
}
