import {
  IPracticeMigration,
  ISource,
  ISourceEntity,
  ISourceEntityHandler,
  ISourceHandler,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  firstResult$,
  snapshot,
  upsertDocByProperty,
  where,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';
import { of } from 'rxjs';

export abstract class BaseSourceHandler implements ISourceHandler {
  abstract migrationType: string;
  abstract migrationVersion: string;
  abstract source: ISource;
  abstract entityHandlers: ISourceEntityHandler[];

  get entities(): ISourceEntity[] {
    return this.entityHandlers.map((handler) => handler.sourceEntity);
  }

  canHandle(mappings: ISource): boolean {
    return isEqual(mappings.metadata, this.source.metadata);
  }

  async addEntities(migration: WithRef<IPracticeMigration>): Promise<void> {
    await asyncForEach(this.entities, async (entity) => {
      const hasEntity = await snapshot(
        firstResult$(
          PracticeMigration.sourceEntityCol({
            ref: migration.ref,
          }),
          where('uid', '==', entity.metadata.idPrefix)
        ).pipe(
          map((result) => !!result),
          catchError(() => of(undefined)),
          map((doc) => (doc ? true : false))
        )
      );

      if (hasEntity) {
        return;
      }

      return upsertDocByProperty(
        PracticeMigration.sourceEntityCol({
          ref: migration.ref,
        }),
        entity,
        'uid',
        entity.metadata.idPrefix
      );
    });
  }
}
