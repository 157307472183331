import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { surfaceFromRef } from '@principle-theorem/principle-core';
import {
  Arch,
  type IDentalChartViewSurface,
} from '@principle-theorem/principle-core/interfaces';
import {
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../chart-entity-id';
import { type IChartArch } from '../renderers/chart-arch';
import { ChartElement, type IChartElement } from '../renderers/chart-element';

interface ILoadData {
  element: IChartElement;
  view: IDentalChartViewSurface;
  isOnBottom: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[prChartMouth]',
  templateUrl: './chart-mouth.component.html',
  styleUrls: ['./chart-mouth.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMouthComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _element$: ReplaySubject<IChartElement> = new ReplaySubject(1);
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  arches$: ReplaySubject<IChartArch[]> = new ReplaySubject(1);
  mouth$: Observable<IChartElement>;
  selector$: Observable<IChartElement>;
  indicator$: Observable<IChartElement>;
  label$: Observable<IChartElement>;
  disabled$: Observable<boolean>;
  badge$: Observable<number>;
  labelText$: Observable<string> = of('Whole Mouth');
  @HostBinding('attr.transform') transform: string;

  constructor(private _chartStore: ChartFacade) {
    const load$: Observable<ILoadData> = combineLatest([
      this._element$,
      this.arches$,
      this.view$,
    ]).pipe(
      map(([element, arches, view]) => {
        return {
          element,
          view,
          isOnBottom: !this._hasTopArch(arches),
        };
      })
    );

    this.mouth$ = load$.pipe(
      map((data) => this._buildMouth(data.element, data.isOnBottom))
    );
    this.disabled$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isDisabledSurface$(
          this._chartId,
          surfaceFromRef(view.id)
        )
      )
    );
    this.selector$ = this.mouth$.pipe(
      map((mouth) => this._buildSelector(mouth))
    );
    this.indicator$ = this.mouth$.pipe(
      map((mouth) => this._buildIndicator(mouth))
    );
    this.label$ = combineLatest([this.mouth$, load$]).pipe(
      map(([mouth, data]) => this._buildLabel(mouth, data.isOnBottom))
    );
    this.badge$ = load$.pipe(
      map((data) => data.view.badge),
      startWith(0)
    );

    this.mouth$
      .pipe(
        map((mouth: IChartElement) => mouth.transform),
        takeUntil(this._onDestroy$)
      )
      .subscribe((transform: string) => {
        this.transform = transform;
      });
  }

  @Input()
  set element(element: IChartElement) {
    if (element) {
      this._element$.next(element);
    }
  }

  @Input()
  set arches(arches: IChartArch[]) {
    if (arches) {
      this.arches$.next(arches);
    }
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    if (view) {
      this.view$.next(view);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _buildMouth(
    element: IChartElement,
    isOnBottom: boolean
  ): IChartElement {
    const mouth: ChartElement = new ChartElement();
    mouth.height = 125;
    mouth.width = element.width / 3;
    mouth.xPos = element.width - mouth.width;
    if (isOnBottom) {
      mouth.yPos = element.height - mouth.height;
    }
    return mouth;
  }

  private _buildSelector(parent: IChartElement): IChartElement {
    const selector: ChartElement = new ChartElement();
    selector.width = parent.width;
    selector.height = parent.height;
    return selector.toInterface();
  }

  private _buildIndicator(parent: IChartElement): IChartElement {
    const indicator: ChartElement = new ChartElement();
    indicator.xPos = parent.width / 2;
    indicator.yPos = parent.height / 2;
    return indicator.toInterface();
  }

  private _buildLabel(
    parent: IChartElement,
    isOnBottom: boolean
  ): IChartElement {
    const label: ChartElement = new ChartElement();
    const labelPadding = 10;
    label.height = 32;
    label.xPos = parent.width - labelPadding;
    label.yPos = labelPadding;
    if (isOnBottom) {
      label.yPos = parent.height - label.height - labelPadding;
    }
    return label.toInterface();
  }

  private _hasTopArch(arches: IChartArch[]): boolean {
    return arches.some((arch) => arch.arch === Arch.Upper);
  }
}
