import { rand, randBoolean, randNumber } from '@ngneat/falso';
import {
  AppointmentAction,
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IChartConfig } from '../chart-config';
import { generateBuilderData } from '../models/base-measures';
import { FactTables } from '../models/fact-tables';
import { BooleanMeasureFilter } from '../models/measure-filters';
import { CommonMockValues, MockTimestampedFacts } from './common-mock-values';
import { toMeasureBuilderData } from '../models/measure-properties';

/**
 * Acquisition Sources
 */
export const acquisitionSources: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Acquisition Sources`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const acquisitionSourcesResults = [
  MockTimestampedFacts('acquisitionSourcesSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      isFirstAppointment: randBoolean(),
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
    };
  }),
];

/**
 * Conversion Cost Vs. Treatment Values
 * Cost Per Conversion
 * TODO: We don't have the spend per referral source so can't give this metric.
 */
export const costPerConversion: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Cost Per Conversion`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.appointmentEvent.nextAppointmentBooked.measure
          )
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const costPerConversionResults = [];

/**
 * Conversion Cost Vs. Treatment Values
 * Average Treatment Plan Value
 * TODO: Not sure how to get the treatment plan value. Currently giving appointment value.
 */
export const averageTreatmentPlanValue: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Average Treatment Plan Value`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByAverage()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const averageTreatmentPlanValueResults = [
  MockTimestampedFacts('averageTreatmentPlanValueSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * New Patients
 * TODO: This type of composite chart needs each "chart" as its own measure.
 * I'm not sure how to break this kinda of result out into multiple charts that
 * can then be combines back down into a composite.
 */
export const newPatients: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `New Patients`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const newPatientsResults = [
  MockTimestampedFacts('newPatientsSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Patient Behaviour
 * Next Visits Booked
 */
export const nextVisitsBooked: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `Next Visits Booked`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const nextVisitsBookedResults = [
  MockTimestampedFacts('nextVisitsBookedSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
      nextAppointmentBooked: true,
    };
  }),
];

/**
 * Patient Behaviour
 * Failed to Book Next Asppointment
 */
export const failedToBookNextAppointment: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Failed To Book Next Appointment`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .filterBy(
            new BooleanMeasureFilter(false),
            FactTables.appointmentEvent.nextAppointmentBooked.measure
          )
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const failedToBookNextAppointmentResults = [
  MockTimestampedFacts('failedToBookNextAppointmentSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
      nextAppointmentBooked: false,
    };
  }),
];

/**
 * Patient Behaviour
 * Cancelled Appointments
 */
export const cancelledAppointments: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Cancelled Appointments`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
};

export const cancelledAppointmentsResults = [
  MockTimestampedFacts('cancelledAppointmentsSeed', () => {
    const referrer = rand(CommonMockValues.referrers);
    return {
      referrer_ref_referenceValue: referrer.ref,
      referrer_name: referrer.name,
      event_action: AppointmentAction.Cancelled,
    };
  }),
];
