import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { PrescriptionItemDialogComponent } from '../prescription-item-dialog/prescription-item-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-prescription-list-item',
  standalone: true,
  imports: [
    CommonModule,
    PrescriptionItemDialogComponent,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './prescription-list-item.component.html',
  styleUrl: './prescription-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionListItemComponent {
  private _dialog = inject(MatDialog);
  item$ = new ReplaySubject<WithRef<IPrescriptionItem>>(1);

  @Input()
  set item(item: WithRef<IPrescriptionItem>) {
    if (item) {
      this.item$.next(item);
    }
  }

  @Output() itemDeleted = new EventEmitter<WithRef<IPrescriptionItem>>();
  @Output() itemEdit = new EventEmitter<WithRef<IPrescriptionItem>>();

  async edit(): Promise<void> {
    const item = await snapshot(this.item$);
    const change = await this._dialog
      .open<
        PrescriptionItemDialogComponent,
        WithRef<IPrescriptionItem>,
        WithRef<IPrescriptionItem>
      >(PrescriptionItemDialogComponent, DialogPresets.medium({ data: item }))
      .afterClosed()
      .toPromise();

    if (!change) {
      return;
    }
    this.itemEdit.emit(change);
  }

  async delete(): Promise<void> {
    const item = await snapshot(this.item$);
    this.itemDeleted.emit(item);
  }
}
