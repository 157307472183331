<mat-toolbar color="accent">
  <span>Tooth History</span>
</mat-toolbar>

<mat-dialog-content>
  <pr-tooth-history
    [patient]="patient()"
    [selectedTeeth]="selectedTeeth()"
    (openPlan)="openPlan($event)"
    (openInvoice)="openInvoice($event)"
  />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
