import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  matSelectOptions,
  TrackByFunctions,
  type TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IProviderData,
  isContactNumber,
  ServiceTypeModality,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  type INamedDocument,
  isSameRef,
  multiMap,
  multiSortBy$,
  nameSorter,
  toNamedDocument,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-provider-details-input',
  templateUrl: './provider-details-input.component.html',
  styleUrls: ['./provider-details-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProviderDetailsInputComponent),
      multi: true,
    },
  ],
})
export class ProviderDetailsInputComponent implements ControlValueAccessor {
  onChange: (_: unknown) => void;
  onTouched: (_: unknown) => void;
  trackByOption = TrackByFunctions.nestedField<IProviderData>('ref.path');
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  practices$: Observable<INamedDocument<IPractice>[]>;
  filteredOptions$: Observable<string[]>;
  modality = MODALITY_OPTIONS;

  @Input() form: TypedFormGroup<IProviderData>;

  constructor(private _brandScope: CurrentBrandScope) {
    this.practices$ = this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.practices$(brand)),
      multiMap((practice) => toNamedDocument(practice)),
      multiSortBy$(nameSorter())
    );
  }

  writeValue(value: IProviderData): void {
    if (isContactNumber(value)) {
      this.form.setValue(value);
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clear(): void {
    this.form.reset();
  }

  isSelectedPracticeRef(
    practiceRef: DocumentReference<IPractice>,
    selectedPracticeRef: DocumentReference<IPractice>
  ): boolean {
    return isSameRef(practiceRef, selectedPracticeRef);
  }
}

const MODALITY_OPTIONS = matSelectOptions<ServiceTypeModality>([
  { label: 'General Dentist', value: ServiceTypeModality.GeneralDentist },
  { label: 'Endodontist', value: ServiceTypeModality.Endodontist },
  { label: 'Oral Surgeon', value: ServiceTypeModality.OralSurgeon },
  { label: 'Orthodontist', value: ServiceTypeModality.Orthodontist },
  { label: 'Paedodontist', value: ServiceTypeModality.Paedodontist },
  { label: 'Periodontist', value: ServiceTypeModality.Periodontist },
  { label: 'Prosthodontist', value: ServiceTypeModality.Prosthodontist },
  { label: 'Dental Technician', value: ServiceTypeModality.DentalTechnician },
]);
