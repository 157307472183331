import { Component, Inject } from '@angular/core';
import { type ITreatmentStep } from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export type TreatmentStepRenameDialogData = Pick<ITreatmentStep, 'name'>;

@Component({
    selector: 'pr-treatment-step-rename-dialog',
    templateUrl: './treatment-step-rename-dialog.component.html',
    styleUrls: ['./treatment-step-rename-dialog.component.sass'],
    standalone: false
})
export class TreatmentStepRenameDialogComponent {
  stepFormGroup = new TypedFormGroup<TreatmentStepRenameDialogData>({
    name: new TypedFormControl(''),
  });

  constructor(
    public dialogRef: DialogRef<
      TreatmentStepRenameDialogData,
      TreatmentStepRenameDialogComponent
    >,
    @Inject(DIALOG_DATA) public data: TreatmentStepRenameDialogData
  ) {
    this.stepFormGroup.setValue(data);
  }
}
