import {
  ChartedItemScopeResolver,
  ChartedSurface,
  TreatmentStep,
  upsertTreatmentsToStep,
} from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IFeeSchedule,
  type ITreatmentCategory,
  type ITreatmentConfiguration,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, type WithRef } from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';
import { BaseAddTreatmentProvider } from '../base-add-treatment-provider';

export class AddTreatmentToFirestoreStepProvider
  extends BaseAddTreatmentProvider
  implements IChartedSurfaceProvider
{
  constructor(
    private _treatmentStep: WithRef<ITreatmentStep>,
    private _treatmentCategories: WithRef<ITreatmentCategory>[],
    private _feeSchedule: WithRef<IFeeSchedule>
  ) {
    super();
  }

  async addSurfaceToTreatment(
    event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    const scopeResolver = new ChartedItemScopeResolver();
    const surfaceScopeRefPairs = scopeResolver.reduceChartedSurfacesToScope(
      config,
      surfaces
    );

    this._treatmentStep = await upsertTreatmentsToStep(
      this._treatmentStep,
      config,
      surfaceScopeRefPairs,
      scopeResolver,
      this._feeSchedule,
      event.attributedTo
    );

    this._treatmentStep = await TreatmentStep.updateDisplayPrimaryCategory(
      this._treatmentStep,
      this._treatmentCategories
    );

    await patchDoc(this._treatmentStep.ref, {
      treatments: this._treatmentStep.treatments,
      display: this._treatmentStep.display,
    });
  }

  override async removeSurfacesFromTreatment(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    const foundTreatment = this._treatmentStep.treatments.find(
      (treatment) => treatment.uuid === item.uuid
    );
    if (!foundTreatment) {
      return;
    }

    const chartedSurfaces = ChartedSurface.filterSurfaceRefs(
      foundTreatment.chartedSurfaces,
      surfaceRefs
    );

    if (!chartedSurfaces.length) {
      this._treatmentStep = TreatmentStep.deleteTreatment(
        this._treatmentStep,
        foundTreatment.uuid
      );
      await patchDoc(this._treatmentStep.ref, {
        treatments: this._treatmentStep.treatments,
      });
      return;
    }
    this._treatmentStep = TreatmentStep.updateTreatment(this._treatmentStep, {
      ...foundTreatment,
      chartedSurfaces,
    });

    await patchDoc(this._treatmentStep.ref, {
      treatments: this._treatmentStep.treatments,
    });
  }
}
