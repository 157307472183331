<form class="layout-padding flex flex-col gap-4" [formGroup]="form">
  <mat-form-field class="flex-1">
    <mat-label>Name</mat-label>
    <input type="text" matInput [required]="true" formControlName="name" />
  </mat-form-field>
  <pt-buttons-container>
    <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </pt-buttons-container>
</form>
