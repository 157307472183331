import { Pipe, type PipeTransform } from '@angular/core';
import { type IProfile } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { ProfileImageService } from '../profile-image-manager/profile-image.service';

@Pipe({
  name: 'profileImage$',
})
export class ProfileImagePipe implements PipeTransform {
  constructor(public profileImage: ProfileImageService) {}

  transform(profile: IProfile): Observable<string | undefined> {
    return this.profileImage.getUserProfileImage$(profile);
  }
}
