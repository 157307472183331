import { BlockNodes, getActiveNode } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { Editor } from '@tiptap/core';
import { ISelectMenuData } from '../select-menu/select-menu-view';
import { ISlashMenuData, SlashMenuComponent } from './slash-menu.component';
import { BasicMenuButtonComponent } from '../basic-menu-button/basic-menu-button.component';

export function createSlashMenu(
  editor: Editor,
  menuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[]
): ISelectMenuData<SlashMenuComponent, ISlashMenuData> {
  return {
    shouldShow: (props) => {
      const isDisabledParagraph = props.editor.isActive(BlockNodes.Paragraph, {
        'data-disable-slash-menu': true,
      });
      const isParagraph = props.editor.isActive(BlockNodes.Paragraph);
      if (isDisabledParagraph || !isParagraph) {
        return false;
      }

      const marks = props.state.selection.$head.marks();
      const isDisabled = marks.some(
        (mark) => mark.type.name === 'disableSlashMenu'
      );
      if (isDisabled) {
        return false;
      }

      const paragraphNode = getActiveNode(props.state, BlockNodes.Paragraph);
      const hasManyNodes = (paragraphNode?.content.childCount ?? 0) > 1;
      const hasTrigger = paragraphNode?.textContent.startsWith('/') ?? false;
      const isMenuTrigger = !hasManyNodes && hasTrigger;
      return isMenuTrigger;
    },
    pluginKey: `slash-menu`,
    nodeType: BlockNodes.Paragraph,
    component: SlashMenuComponent,
    data: {
      editor,
      menuItems,
    },
  };
}
