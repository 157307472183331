@if (customFormData$ | async; as customFormData) {
  @if (patient$ | async; as patient) {
    <div>
      <strong class="mb-1 text-lg">Patient Details</strong>
      <div
        *ngrxLet="isMedicalHistoryForm$ as isMedicalHistoryForm"
        class="grid grid-cols-2"
      >
        <div>
          <div><strong>Name: </strong>{{ patient.name }}</div>
          <div><strong>Email: </strong>{{ patient.email }}</div>
          @if (phone$ | async; as phone) {
            <div><strong>Phone: </strong>{{ phone }}</div>
          }
          @if (isMedicalHistoryForm) {
            @if (gender$ | async; as gender) {
              <div>
                <strong>Gender: </strong>{{ gender | splitCamel | titlecase }}
              </div>
            }
            @if (address$ | async; as address) {
              <div><strong>Address: </strong>{{ address }}</div>
            }
          }
        </div>
        <div>
          @if (isMedicalHistoryForm) {
            @if (medicareCard$ | async; as medicareCard) {
              <div>
                <strong>Medicare Card</strong>
                <div class="ml-0.5">
                  <div>Card #: {{ medicareCard.number }}</div>
                  <div>IRN: {{ medicareCard.subNumerate }}</div>
                  <div>
                    Expiry:
                    {{
                      medicareCard.expiryDate
                        | moment
                        | amDateFormat: dateFormat
                    }}
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </div>
    </div>
  }
  <pr-custom-form [form]="customFormData" [disabled]="true" />
} @else {
  <div class="flex items-center justify-center">
    <mat-spinner />
  </div>
}
