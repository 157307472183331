import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ChartedItemTotalCalculator } from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemDetail,
  isChartedCondition,
} from '@principle-theorem/principle-core/interfaces';

export interface IItemsSummaryData {
  chartedItems: IChartedItemDetail[];
}

@Component({
  selector: 'pr-charted-items-hover-summary-dialog',
  templateUrl: './charted-items-hover-summary-dialog.component.html',
  styleUrls: ['./charted-items-hover-summary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedItemsHoverSummaryDialogComponent {
  trackByChartedItem = TrackByFunctions.ref<IItemsSummaryData>('config.ref');

  constructor(@Inject(DIALOG_DATA) public data: IItemsSummaryData) {}

  getPrice(item: IChartedItem): number | undefined {
    if (isChartedCondition(item)) {
      return;
    }
    const totalPrice: ChartedItemTotalCalculator =
      new ChartedItemTotalCalculator();
    return totalPrice.calc(item);
  }
}
