import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ChatBodyComponent } from './chat-body/chat-body.component';
import { ChatFooterComponent } from './chat-footer/chat-footer.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { ChatListDialogComponent } from './chat-list-dialog/chat-list-dialog.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatMenuItemComponent } from './chat-menu-item/chat-menu-item.component';
import { ChatMultiActionToolbarComponent } from './chat-multi-action-toolbar/chat-multi-action-toolbar.component';
import { ChatComponent } from './chat/chat.component';
import { CreateChatDialogComponent } from './create-chat-dialog/create-chat-dialog.component';
import { EditChatMembersDialogComponent } from './edit-chat-members-dialog/edit-chat-members-dialog.component';
import { FloatingChatComponent } from './floating-chat/floating-chat.component';
import { ReactionSelectorDialogComponent } from './reaction-selector-dialog/reaction-selector-dialog.component';
import { ChatMessageReactionsComponent } from './chat-message-reactions/chat-message-reactions.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    NgMaterialModule,
    ReactiveFormsModule,
    NgSharedModule,
    NgInteractionsModule,
    NgEditorModule,
    NgPrincipleSharedModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    ChatMenuItemComponent,
    ChatComponent,
    ChatHeaderComponent,
    ChatFooterComponent,
    ChatBodyComponent,
    ChatMultiActionToolbarComponent,
    CreateChatDialogComponent,
    ChatListComponent,
    FloatingChatComponent,
    ChatListDialogComponent,
    EditChatMembersDialogComponent,
    ReactionSelectorDialogComponent,
    ChatMessageReactionsComponent,
  ],
  exports: [
    ChatMenuItemComponent,
    ChatComponent,
    ChatMultiActionToolbarComponent,
    ChatListComponent,
    FloatingChatComponent,
    ChatListDialogComponent,
  ],
})
export class ComponentsModule {}
