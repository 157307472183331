<pr-content-container height="h-full" padding="p-0" overflow="overflow-hidden">
  <div fxLayout="column">
    <mat-progress-bar *ngIf="store.loading$ | async" mode="indeterminate" />
    <mat-table
      matSort
      matSortDisableClear
      matSortActive="charted"
      [dataSource]="table.dataTable.dataSource"
      [trackBy]="trackByUid"
      (matSortChange)="table.sortChange($event)"
    >
      <mat-header-row *matHeaderRowDef="table.dataTable.displayColumns" />
      <mat-row
        *matRowDef="let record; columns: table.dataTable.displayColumns"
      />
      <ng-container matColumnDef="charted">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="!max-w-[150px] truncate !bg-slate-50 !font-bold"
        >
          Charted
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="!max-w-[150px] truncate">
          <div>
            <p
              class="!m-0 font-medium"
              matTooltip="Date Charted"
              matTooltipShowDelay="300"
              matTooltipPosition="right"
            >
              {{ record.chartedAt | moment | amDateFormat: dateFormat }}
            </p>
            <p
              class="!m-0 text-xs text-slate-500"
              matTooltip="Charted By"
              matTooltipShowDelay="300"
              matTooltipPosition="right"
            >
              {{ record.chartedBy.ref | map: getStafferName$ : this | async }}
            </p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="surface">
        <mat-header-cell
          *matHeaderCellDef
          class="!max-w-[120px] truncate !bg-slate-50 !font-bold"
        >
          Surface
        </mat-header-cell>
        <mat-cell class="!max-w-[120px] truncate" *matCellDef="let record">
          {{ record.surfaces }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="!max-w-[120px] truncate !bg-slate-50 !font-bold"
        >
          Type
        </mat-header-cell>
        <mat-cell class="!max-w-[120px] truncate" *matCellDef="let record">
          {{ record.type | titlecase }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="treatmentStatus">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="!max-w-[160px] truncate !bg-slate-50 !font-bold"
        >
          Treatment Status
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="!max-w-[160px] truncate">
          @if (record.treatmentStatus) {
            <pr-tooth-history-status-chip [status]="record.treatmentStatus" />
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="treatmentDate">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="!max-w-[160px] truncate !bg-slate-50 !font-bold"
        >
          Treatment Date
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="!max-w-[160px] truncate">
          <div>
            @if (record.treatmentDate) {
              <p class="!m-0">
                {{ record.treatmentDate | map: getTreatmentDate : this }}
              </p>
            }
            @if (record.treatedBy) {
              <p class="!m-0 text-xs text-slate-500">
                {{ record.treatedBy.ref | map: getStafferName$ : this | async }}
              </p>
            }
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="min-w-[300px] !flex-1 truncate !bg-slate-50 !font-bold"
        >
          Name
        </mat-header-cell>
        <mat-cell
          *matCellDef="let record"
          class="min-w-[300px] flex-1 truncate"
        >
          <div
            class="flex items-center gap-2"
            [matTooltip]="record.item.config.name"
            matTooltipShowDelay="300"
          >
            @if (record.display) {
              <span class="mr-2">
                <mat-icon
                  *ngIf="record.display?.icon.slug"
                  [ngStyle]="{ color: record.display.colour.value }"
                  [svgIcon]="record.display.icon.slug"
                />
              </span>
            }
            <div>
              @if (record.treatmentPlan) {
                <a
                  [routerLink]="[]"
                  (click)="openPlan.emit(record.treatmentPlan.ref)"
                >
                  {{ record.item.config.name }}
                </a>
              } @else {
                <p class="!m-0">
                  {{ record.item.config.name }}
                </p>
              }
              <p class="!m-0 text-xs text-slate-500">
                {{ record.serviceCodes | map: displayCodes }}
              </p>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fee">
        <mat-header-cell
          *matHeaderCellDef
          class="!max-w-[120px] truncate !bg-slate-50 !font-bold"
        >
          Fee
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="!max-w-[120px] truncate">
          @if (record.fee !== undefined) {
            @if (record.appointment?.invoiceRef) {
              <a
                [routerLink]="[]"
                matTooltip="View Invoice"
                matTooltipShowDelay="300"
                (click)="openInvoice.emit(record.appointment.invoiceRef)"
              >
                {{ record.fee | currency }}
              </a>
            } @else {
              <span>{{ record.fee | currency }}</span>
            }
          }
        </mat-cell>
      </ng-container>
    </mat-table>

    @if ((store.loading$ | async) === false && emptySearch()) {
      <pr-empty-state
        class="layout-margin"
        image="list"
        title="Records Found"
      />
    }

    <mat-paginator
      #paginator
      showFirstLastButtons
      class="sticky bottom-0 z-50"
      [length]="totalRecordCount()"
      [pageSize]="table.pageSize$ | async"
      [pageSizeOptions]="table.dataTable.pageSizeOptions"
      (page)="table.updatePage($event)"
    />
  </div>
</pr-content-container>
