import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { BaseMenuComponent } from '@principle-theorem/ng-core';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IClinicalChart,
  type Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../../chart-entity-id';
import {
  AddToothDialogComponent,
  type IAddToothDialogData,
} from '../add-tooth-dialog/add-tooth-dialog.component';

@Component({
    selector: 'pr-chart-quadrant-menu',
    templateUrl: './chart-quadrant-menu.component.html',
    styleUrls: ['./chart-quadrant-menu.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prChartQuadrantMenu',
    standalone: false
})
export class ChartQuadrantMenuComponent extends BaseMenuComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  quadrant$: ReplaySubject<Quadrant> = new ReplaySubject(1);
  disabled$: Observable<boolean>;

  constructor(
    private _dialog: MatDialog,
    private _chartStore: ChartFacade
  ) {
    super();
    this.disabled$ = this._chartStore
      .canEdit$(this._chartId)
      .pipe(map((canStore) => !canStore));
  }

  @Input()
  set quadrant(quadrant: Quadrant) {
    this.quadrant$.next(quadrant);
  }

  async openAddTooth(): Promise<void> {
    const chart: IClinicalChart = await snapshot(
      this._chartStore.clinicalChartState$(this._chartId)
    );
    const quadrant: Quadrant = await snapshot(this.quadrant$);

    this._dialog.open<AddToothDialogComponent, IAddToothDialogData>(
      AddToothDialogComponent,
      DialogPresets.medium({
        data: { chart, quadrant },
      })
    );
  }
}
