import { Injectable } from '@angular/core';
import {
  BlockNodes,
  findNodePosition,
  getActiveNode,
} from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';
import { FileUploader, MIMEFileType, type IUploader } from '../uploader';
import { type IEditorImageAttributes } from './image-node/image-node.component';

@Injectable()
export class ImageCommandsService {
  async upload(
    uploader: IUploader,
    editor: Editor,
    position: number,
    fileList: FileList
  ): Promise<void> {
    await new FileUploader().upload(
      BlockNodes.ImageUploading,
      BlockNodes.Image,
      MIMEFileType.Image,
      uploader,
      editor,
      position,
      fileList
    );
  }

  setFixedSize(editor: Editor): void {
    this._updateAttrs({ fitToWidth: false }, editor);
  }

  setFloat(
    editor: Editor,
    dataFloat: IEditorImageAttributes['dataFloat']
  ): void {
    this._updateAttrs(
      { dataFloat, dataAlign: undefined, fitToWidth: false },
      editor
    );
  }

  setAlign(
    editor: Editor,
    dataAlign: IEditorImageAttributes['dataAlign']
  ): void {
    this._updateAttrs(
      { dataAlign, dataFloat: undefined, fitToWidth: false },
      editor
    );
  }

  resetLayout(editor: Editor): void {
    this._updateAttrs(
      { dataAlign: undefined, dataFloat: undefined, fitToWidth: false },
      editor
    );
  }

  fitToWidth(editor: Editor): void {
    this._updateAttrs(
      { dataAlign: undefined, dataFloat: undefined, fitToWidth: true },
      editor
    );
  }

  private _updateAttrs(
    attrs: Partial<IEditorImageAttributes>,
    editor: Editor
  ): void {
    const node = getActiveNode(
      editor.state,
      editor.state.schema.nodes[BlockNodes.Image]
    );
    if (!node) {
      return;
    }
    const pos = findNodePosition(editor.state.doc, node);
    if (!pos) {
      return;
    }

    editor.chain().updateAttributes(node.type, attrs).run();
  }
}
