import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISterilisationCycleType } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';
import { WithRef } from '@principle-theorem/shared';
import { CommonModule } from '@angular/common';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgMaterialModule } from '@principle-theorem/ng-material';

@Component({
    selector: 'pr-sterilisation-cycle-types-list',
    imports: [CommonModule, NgMaterialModule, NgPrincipleSharedModule],
    templateUrl: './sterilisation-cycle-types-list.component.html',
    styleUrl: './sterilisation-cycle-types-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationCycleTypesListComponent {
  cycleTypes$ = new ReplaySubject<WithRef<ISterilisationCycleType>[]>(1);
  @Output() delete = new EventEmitter<WithRef<ISterilisationCycleType>>();
  @Output() edit = new EventEmitter<WithRef<ISterilisationCycleType>>();

  @Input()
  set cycleTypes(cycleTypes: WithRef<ISterilisationCycleType>[]) {
    if (cycleTypes) {
      this.cycleTypes$.next(cycleTypes);
    }
  }
}
