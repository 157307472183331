@if (items$ | async; as items) {
  @if (items.length) {
    <mat-list>
      @for (item of items; track item.ref.path) {
        <pr-prescription-list-item
          [item]="item"
          (itemEdit)="itemEdit.emit($event)"
          (itemDeleted)="itemDeleted.emit($event)"
        />
      }
    </mat-list>
  } @else {
    @if (!loading) {
      <pr-empty-state image="list" title="prescription items" />
    }
  }
}
