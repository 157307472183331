import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-staff-list',
    templateUrl: './staff-list.component.html',
    styleUrls: ['./staff-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StaffListComponent {
  trackByTeam = TrackByFunctions.ref<WithRef<IStaffer>>();
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  staffFilter: InputSearchFilter<WithRef<IStaffer>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  emptyState$: Observable<boolean>;

  constructor(private _brandScope: CurrentBrandScope) {
    const brand$ = this._brandScope.doc$.pipe(filterUndefined());
    const staff$ = brand$.pipe(
      switchMap((brand) => Brand.staff$(brand)),
      map((staff) => sortBy(staff, ['user.name']))
    );
    this.breadcrumbs$ = brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Staff' },
      ])
    );

    this.staffFilter = new InputSearchFilter(
      staff$,
      toSearchStream(this.searchCtrl),
      ['user.name']
    );

    this.emptyState$ = staff$.pipe(map((staff) => !staff.length));
  }
}
