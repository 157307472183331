import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { EXACT_DESTINATION } from './destination';
import { EXACT_MAPPINGS } from './mappings';
import { EXACT_SOURCE } from './source';

export const EXACT_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'Exact',
    version: 'v1',
    supportsMultiplePractices: false,
  },
  source: EXACT_SOURCE,
  destination: EXACT_DESTINATION,
  mappings: EXACT_MAPPINGS,
};
