import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ICustomLineItem } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-line-item-group',
    templateUrl: './line-item-group.component.html',
    styleUrls: ['./line-item-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LineItemGroupComponent {
  @Input() lineItem: ICustomLineItem;
}
