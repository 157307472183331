<ng-container *ngrxLet="packages$ as packages">
  <ng-container *ngIf="packages.length">
    <button
      mat-icon-button
      [ngClass]="{ active: currentPackageId$ | async }"
      [matTooltip]="currentPackageName$ | async"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>sell</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <div *ngFor="let package of packages; trackBy: trackByPackageId">
        <button mat-menu-item (click)="packageSelected.emit(package.uid)">
          {{ package.name }}
        </button>
      </div>
    </mat-menu>
  </ng-container>
</ng-container>
