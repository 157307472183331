import { createAction, props } from '@ngrx/store';
import { type ITreatmentTemplateWithStep } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IAppointmentDetails } from '@principle-theorem/principle-core';

enum AppointmentDetailsActions {
  ValueChange = '[Appointment Details] Value Change',
  SelectTreatmentTemplate = '[Appointment Details] Select Treatment Template',
}

interface IChangeAppointmentDetails {
  appointmentDetails: Partial<IAppointmentDetails>;
}

export const appointmentDetailsChange = createAction(
  AppointmentDetailsActions.ValueChange,
  props<SerialisedData<IChangeAppointmentDetails>>()
);

interface ISelectTreatmentTemplate {
  template: ITreatmentTemplateWithStep;
}

export const selectTreatmentTemplate = createAction(
  AppointmentDetailsActions.SelectTreatmentTemplate,
  props<SerialisedData<ISelectTreatmentTemplate>>()
);
