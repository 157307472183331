import { Brand } from '@principle-theorem/principle-core';
import {
  type IPatient,
  PatientStatus,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type IReffable,
  ISO_DATE_FORMAT,
  multiFilter,
  query$,
  snapshot,
  toISODate,
  toTimestamp,
  type WithRef,
  XSLXImporterExporter,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PATIENT_RESOURCE_TYPE } from '../../source/entities/patient';
import { PatientsToXSLX } from './patients-to-xlsx';
import { XSLXToPatients } from './xlsx-to-patients';
import { where } from '@principle-theorem/shared';

export const PATIENT_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Patients',
    description: `Used for mapping patients to Principle that have been added after initial migration.`,
    type: PATIENT_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class D4WPatientMappingHandler extends BaseCustomMappingHandler<IPatient> {
  customMapping = PATIENT_MAPPING;

  override getRecords$(
    translationMap: TranslationMapHandler
  ): Observable<WithRef<ITranslationMap<IPatient>>[]> {
    return super
      .getRecords$(translationMap)
      .pipe(multiFilter((record) => !!record.sourceLabel));
  }

  async downloadMapping(
    _migration: IReffable<IPracticeMigration>
  ): Promise<void> {
    const fileName = `patient-mapping`;
    await new XSLXImporterExporter().download(
      fileName,
      [],
      new PatientsToXSLX()
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToPatients()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await asyncForEach(items, async (item) => {
      const label = `${item.patientId} - ${item.dateOfBirth ?? ''} - ${
        item.firstName
      } ${item.lastName}`;

      if (!item.dateOfBirth) {
        // eslint-disable-next-line no-console
        console.log(`No date of birth for ${label}`);
        return;
      }

      const dateOfBirth = moment(item.dateOfBirth, ISO_DATE_FORMAT);

      if (!dateOfBirth.isValid()) {
        // eslint-disable-next-line no-console
        console.log(`Invalid date of birth ${item.dateOfBirth}`);
        return;
      }

      const existingMapping = await this.getBySource(
        item.patientId,
        translationMap
      );
      if (existingMapping && existingMapping.destinationIdentifier) {
        // eslint-disable-next-line no-console
        console.log(`Patient ${label} already exists`);
        return;
      }

      const foundPatients = await snapshot(
        query$(
          Brand.patientCol(migration.configuration.brand),
          where(
            'dateOfBirth',
            '>=',
            toTimestamp(moment(dateOfBirth).startOf('day'))
          ),
          where(
            'dateOfBirth',
            '<=',
            toTimestamp(moment(dateOfBirth).endOf('day'))
          )
        )
      );

      const patientRef = foundPatients.find((patient) => {
        const isActive = patient.status === PatientStatus.Active;
        const hasLastName = patient.name
          .trim()
          .toLowerCase()
          .includes(item.lastName.trim().toLowerCase());
        const hasFirstName = patient.name
          .trim()
          .toLowerCase()
          .includes(item.firstName.trim().toLowerCase());
        const hasDateOfBirth =
          patient.dateOfBirth &&
          toISODate(patient.dateOfBirth) === item.dateOfBirth
            ? true
            : false;

        return isActive && hasFirstName && hasLastName && hasDateOfBirth;
      })?.ref;

      // eslint-disable-next-line no-console
      console.log(
        `Patient ${label} ${patientRef ? 'found' : 'not found'}; ${
          foundPatients.length
        } matches`,
        foundPatients.map((patient) => ({
          name: patient.name,
          dateOfBirth: patient.dateOfBirth
            ? toISODate(patient.dateOfBirth)
            : '',
        }))
      );

      await this.upsertRecord(
        {
          sourceIdentifier: item.patientId,
          destinationIdentifier: patientRef,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }
}
