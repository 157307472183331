import { type ILabJob } from '@principle-theorem/principle-core/interfaces';
import { LabJob } from '@principle-theorem/principle-core';

export enum LabJobPresetFilter {
  Active = 'active',
  All = 'all',
}

export interface ILabJobFilter {
  name: string;
  id: LabJobPresetFilter;
  icon: string;
  filter: (task: ILabJob) => boolean;
}

export function isActiveLabJob(labJob: ILabJob): boolean {
  if (!LabJob.complete(labJob)) {
    return true;
  }
  return false;
}

export const ACTIVE_LAB_JOBS_FILTER: ILabJobFilter = {
  name: `Active Lab Jobs`,
  id: LabJobPresetFilter.Active,
  icon: 'public',
  filter: (task: ILabJob) => isActiveLabJob(task),
};

export const ALL_LAB_JOBS_FILTER: ILabJobFilter = {
  name: 'All Lab Jobs',
  id: LabJobPresetFilter.All,
  icon: 'check_box',
  filter: () => true,
};

export const LAB_JOB_PRESET_FILTERS: ILabJobFilter[] = [
  ACTIVE_LAB_JOBS_FILTER,
  ALL_LAB_JOBS_FILTER,
];
