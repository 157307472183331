import { Validators } from '@angular/forms';
import {
  ANY_OPTION,
  type IAppointmentDetails,
  type IDefaultOption,
  isTreatmentOption,
  type TreatmentPlanStepPair,
} from '@principle-theorem/ng-appointment/store';
import {
  formControlChanges$,
  FormValidators,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  MINIMUM_DURATION,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AppointmentDetailsFormGroup extends TypedFormGroup<
  Omit<IAppointmentDetails, 'overridePlan'>
> {
  constructor() {
    super({
      practice: new TypedFormControl<WithRef<IPractice>>(
        undefined,
        Validators.required
      ),
      practitioner: new TypedFormControl<
        INamedDocument<IStaffer> | IDefaultOption
      >(ANY_OPTION, Validators.required),
      treatment: new TypedFormControl<TreatmentPlanStepPair>(undefined, [
        Validators.required,
        FormValidators.typeGuard(
          isTreatmentOption,
          'No treatment option selected'
        ),
      ]),
      duration: new TypedFormControl<number>(
        MINIMUM_DURATION,
        Validators.min(MINIMUM_DURATION)
      ),
    });
  }

  selectedTreatment$(): Observable<TreatmentPlanStepPair | undefined> {
    return formControlChanges$(this.controls.treatment).pipe(
      map((value) => (isTreatmentOption(value) ? value : undefined))
    );
  }

  errorMessage$(): Observable<string> {
    return combineLatest([
      this.controls.treatment.statusChanges,
      this.controls.duration.statusChanges,
    ]).pipe(
      map(([treatmentStatus, durationStatus]) => {
        let message = '';
        if (treatmentStatus === 'INVALID' || durationStatus === 'INVALID') {
          message = this.getErrorMessage();
        }
        return message;
      })
    );
  }

  getErrorMessage(): string {
    if (this.controls.treatment.hasError('required')) {
      return `Treatment is required`;
    }
    if (this.controls.duration.hasError('min')) {
      return `Duration must be at least ${MINIMUM_DURATION} minutes`;
    }
    return '';
  }
}
