import {
  DEFAULT_FEE_SCHEDULE_NAME,
  IFeeSchedule,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  Region,
  WithRef,
  asyncForEach,
  snapshot,
  subCollection,
  Firestore,
} from '@principle-theorem/shared';
import { DocumentReference, doc } from '@principle-theorem/shared';
import { FeeSchedule } from './fee-schedule';
import {
  DEFAULT_SCHEDULE_ID,
  defaultFeeSchedule$,
} from './fee-schedule-collection';

export class DefaultFeeScheduleCollection<T extends IReffable> {
  constructor(
    private _feeScheduleCollectionPath: string,
    private _region: Region
  ) {}

  postAdd(): (parentDocs: T[]) => Promise<void> {
    return async (parentDocs: T[]) => {
      await asyncForEach(parentDocs, (parentDoc: T) =>
        this._addDefaultFeeSchedule(parentDoc)
      );
    };
  }

  private async _addDefaultFeeSchedule(parentDoc: T): Promise<void> {
    const serviceCodeType =
      this._region === Region.Australia ? ServiceCodeType.ADA : undefined;

    const defaultSchedule = FeeSchedule.init({
      name: DEFAULT_FEE_SCHEDULE_NAME,
      inheritedFrom: await this._getInheritedDocRef(parentDoc),
      serviceCodeType,
    });

    const defaultDocRef = doc(
      subCollection<IFeeSchedule>(
        parentDoc.ref,
        this._feeScheduleCollectionPath
      ),
      DEFAULT_SCHEDULE_ID
    );

    let defaultData: WithRef<IFeeSchedule> | undefined;
    try {
      defaultData = await Firestore.getDoc(defaultDocRef);
    } catch {
      // Do nothing
    }

    await Firestore.saveDoc({
      ...defaultSchedule,
      ...defaultData,
      ref: defaultDocRef,
    });
  }

  private async _getInheritedDocRef(
    data: T
  ): Promise<DocumentReference<IFeeSchedule> | undefined> {
    try {
      const inheritingFeeScheduleRef = subCollection<IFeeSchedule>(
        Firestore.getParentDocRef(data.ref),
        this._feeScheduleCollectionPath
      );
      const defaultSchedule = await snapshot(
        defaultFeeSchedule$(inheritingFeeScheduleRef)
      );
      if (!defaultSchedule) {
        throw new Error('No Default Schedule found');
      }
      return defaultSchedule.ref;
    } catch (error) {
      return;
    }
  }
}
