<mat-toolbar color="accent">Add Organisation</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field class="w-full" required>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field class="w-full" required>
      <mat-label>Region</mat-label>
      <mat-select formControlName="region" required>
        @for (region of regions; track region) {
          <mat-option [value]="region">{{ region }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (data && data.fileNames) {
      <mat-form-field class="w-full" required>
        <mat-label>File Name</mat-label>
        <mat-select formControlName="seedFileName" required>
          @for (name of data.fileNames; track name) {
            <mat-option [value]="name">{{ name }}</mat-option>
          }
        </mat-select>
        <mat-hint>Select a file name to use as the source data.</mat-hint>
      </mat-form-field>
    }
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid && form.pristine"
      >
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
