<pt-breadcrumbs current="Tasks" class="bg-white" />

<div class="border-b border-solid border-slate-300 bg-white px-2 pt-0">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="m-auto w-full !border-none">
    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      *ngFor="let link of navLinks; trackBy: trackByLink"
      [routerLink]="link.path"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </nav>
</div>
<mat-tab-nav-panel #tabPanel>
  <div class="wrapper">
    <router-outlet />
  </div>
</mat-tab-nav-panel>
