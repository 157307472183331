import { type Routes } from '@angular/router';
import {
  REPORTING_ACCOUNT_CREDITS_FEATURE,
  REPORTING_OUTSTANDING_INVOICES_FEATURE,
  REPORTING_PATIENTS_FEATURE,
  REPORTING_PENDING_PAYMENTS_FEATURE,
  REPORTING_PRACTITIONERS_FEATURE,
  REPORTING_RECONCILIATION_FEATURE,
} from '@principle-theorem/principle-core/features';
import { AccountCreditsReportComponent } from './account-credits/account-credits-report.component';
import { OutstandingInvoicesReportingComponent } from './outstanding-invoices-reporting/outstanding-invoices-reporting.component';
import { PatientsReportingComponent } from './patients-reporting/patients-reporting.component';
import { PendingPaymentsComponent } from './pending-payments/pending-payments.component';
import { PractitionerIncomeReportComponent } from './practitioner-income/practitioner-income-report.component';
import { ReconciliationReportComponent } from './reconciliation-report/reconciliation-report.component';

export const LEGACY_REPORT_ROUTES: Routes = [
  {
    path: 'reconciliation',
    component: ReconciliationReportComponent,
    data: {
      title: 'Reconciliation',
      feature: REPORTING_RECONCILIATION_FEATURE,
    },
  },
  {
    path: 'outstanding-invoices',
    component: OutstandingInvoicesReportingComponent,
    data: {
      title: 'Outstanding Invoices',
      feature: REPORTING_OUTSTANDING_INVOICES_FEATURE,
    },
  },
  {
    path: 'practitioner-income',
    component: PractitionerIncomeReportComponent,
    data: {
      title: 'Practitioners Income Reporting',
      feature: REPORTING_PRACTITIONERS_FEATURE,
    },
  },
  {
    path: 'account-credits',
    component: AccountCreditsReportComponent,
    data: {
      title: 'Account Credits Reporting',
      feature: REPORTING_ACCOUNT_CREDITS_FEATURE,
    },
  },
  {
    path: 'pending-payments',
    component: PendingPaymentsComponent,
    data: {
      title: 'Pending Payments',
      feature: REPORTING_PENDING_PAYMENTS_FEATURE,
    },
  },
  {
    path: 'patients',
    component: PatientsReportingComponent,
    data: {
      title: 'Patients',
      feature: REPORTING_PATIENTS_FEATURE,
    },
  },
];
