import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface IFormSchema {
  type: string;
  properties: IFormSchemaProperties;
  required?: string[];
}

export function isFormSchema(data: unknown): data is IFormSchema {
  return isObject(data) && isString(data.type) && isObject(data.properties);
}

/**
 * Form Types: https://github.com/json-schema-form/angular-schema-form/blob/master/docs/index.md#form-types
 * For Defaults see: https://github.com/json-schema-form/angular-schema-form/blob/master/docs/index.md#default-form-types
 */
export enum FormSchemaPropertyType {
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
}

export interface IFormSchemaProperties {
  [key: string]: IFormSchemaProperty | IFormSchema;
}

export interface IFormSchemaProperty {
  title: string;
  type?: FormSchemaPropertyType;
  const?: boolean;
  enum?: string[];
  default?: string;
  required?: boolean;
}
