<mat-nav-list dense>
  <ng-container
    *ngFor="let aid of treatmentConfig.educationalAids; trackBy: trackByAid"
  >
    <a mat-list-item [href]="aid.url" target="_blank">
      <div class="flex">
        <span class="flex-1">{{ aid.name }}</span>
        <mat-icon>open_in_new</mat-icon>
      </div>
    </a>
  </ng-container>
</mat-nav-list>

<div *ngIf="uploadPath$ | async as uploadPath" class="layout-margin">
  <pt-file-upload-to-storage
    [path]="uploadPath"
    (uploadStarted)="startedUpload($event.task)"
  />
</div>
