import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { TransactionComponentsModule } from '../transaction-components/transaction-components.module';
import { MedipassBuilder } from './medipass-builder.service';
import { MedipassHicapsTransactionProvider } from './medipass-hicaps-transaction-provider.service';
import { MedipassMedicareTransactionProvider } from './medipass-medicare-transaction-provider.service';
import { MedipassVirtualTerminalTransactionProvider } from './medipass-virtual-terminal-transaction-provider.service';
import { MedipassProviderService } from './medipass-provider.service';
import { MedipassGapPaymentTransactionProvider } from './medipass-gap-payment-provider.service';
import { MedipassDVATransactionProvider } from './medipass-dva-transaction-provider.service';
import { MedipassExtendedDataComponent } from './medipass-extended-data/medipass-extended-data.component';

@NgModule({
  imports: [TransactionComponentsModule, NgPrincipleSharedModule],
  providers: [
    MedipassBuilder,
    MedipassProviderService,
    MedipassHicapsTransactionProvider,
    MedipassMedicareTransactionProvider,
    MedipassDVATransactionProvider,
    MedipassVirtualTerminalTransactionProvider,
    MedipassGapPaymentTransactionProvider,
  ],
  declarations: [MedipassExtendedDataComponent],
  exports: [],
})
export class MedipassTransactionModule {}
