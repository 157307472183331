import { Component } from '@angular/core';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  BooleanMeasureFilter,
  type CanBeChartedProperty,
  FactTables,
} from '@principle-theorem/reporting';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';

@Component({
    selector: 'pr-practitioner-collections',
    templateUrl: './practitioner-collections.component.html',
    styleUrls: ['./practitioner-collections.component.sass'],
    standalone: false
})
export class PractitionerCollectionsComponent extends MeasureDataChartComponent {
  collectionsChart: ChartBuilder;
  collectionsTable: IChartCard;
  replacementHeaders: ITableHeaderReplacement[] = [
    { from: 'Owner', to: 'Practitioner' },
  ];

  collectionMeasures: CanBeChartedProperty[] = [
    FactTables.invoiceEvent
      .scopeBy(FactTables.invoiceEvent.writtenOff)
      .amountRemaining.reduceBySum()
      .setLabel('Written Off Invoices Total'),
    FactTables.invoiceEvent
      .scopeBy(FactTables.invoiceEvent.overdue)
      .amountRemaining.reduceBySum()
      .setLabel('Overdue Invoices Total'),
    FactTables.paymentPlanEvent
      .scopeBy(FactTables.paymentPlanEvent.active)
      .paymentPlan.total.reduceBySum()
      .setLabel('Active Payment Plans Total'),
  ];

  collectionTableMeasures: CanBeChartedProperty[] = [
    FactTables.invoiceEvent // TODO: https://app.clickup.com/t/2x03mtk
      .scopeBy(
        FactTables.invoiceEvent.paidOnCheckout.filterBy(
          // TODO: https://app.clickup.com/t/2x03mtk
          new BooleanMeasureFilter(true)
        )
      )
      .count.reduceByRatio(
        FactTables.invoiceEvent.scopeBy(FactTables.invoiceEvent.active).count
      )
      .setLabel('Paid On Checkout Rate')
      .setFormatter(MeasureFormatter.Percentage),
    FactTables.invoiceEvent
      .scopeBy(
        FactTables.invoiceEvent.paidOnCheckout.filterBy(
          // TODO: https://app.clickup.com/t/2x03mtk
          new BooleanMeasureFilter(false)
        )
      )
      .count.reduceByRatio(FactTables.invoiceEvent.latestEvent.count)
      .setLabel('Paid After Checkout Rate')
      .setFormatter(MeasureFormatter.Percentage),
    FactTables.invoiceEvent
      .scopeBy(FactTables.invoiceEvent.writtenOff)
      .count.reduceByRatio(FactTables.invoiceEvent.latestEvent.count)
      .setLabel('Written Off Rate')
      .setFormatter(MeasureFormatter.Percentage),
    FactTables.invoiceEvent
      .scopeBy(FactTables.invoiceEvent.overdue)
      .amountRemaining.reduceByCount()
      .setLabel('Overdue Invoices')
      .setFormatter(MeasureFormatter.Number),
    FactTables.paymentPlanEvent
      .scopeBy(FactTables.paymentPlanEvent.active)
      .count.setLabel('Active Payment Plans')
      .setFormatter(MeasureFormatter.Number),
  ];

  _redraw(): void {
    this.collectionsChart = this.dataBuilder.toBarChart(
      this._chartMeasures(
        CustomChartType.Bar,
        this.collectionMeasures,
        FactTables.invoiceEvent.practitioner.name
      )
    ).chartBuilder;
    this.collectionsTable = this._tableChartCard(
      this.collectionTableMeasures,
      FactTables.invoiceEvent.practitioner.name
    );
  }
}
