import { NgIfContext } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  type EmbeddedViewRef,
  Input,
  type OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  type IPermission,
  type Permission,
} from '@principle-theorem/feature-flags';
import { User } from '@principle-theorem/principle-core';
import { isChanged$, isPathChanged$ } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { combineLatest, OperatorFunction, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';
import { ManagementService } from './management.service';
import { IManagementUser } from '@principle-theorem/principle-core/interfaces';

@Directive({
    selector: '[prHasPermission]',
    standalone: false
})
export class HasPermissionDirective implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _context = new NgIfContext();
  private _elseTemplateRef$ = new ReplaySubject<
    TemplateRef<NgIfContext> | undefined
  >(1);
  private _elseViewRef: EmbeddedViewRef<NgIfContext>;
  private _permissions$ = new ReplaySubject<string[]>(1);
  private _thenTemplateRef: TemplateRef<unknown>;

  @Input()
  set prHasPermission(permissions: (IPermission | Permission)[]) {
    if (!permissions) {
      this._permissions$.next([]);
      return;
    }
    this._permissions$.next(
      permissions.map((permission) =>
        isString(permission) ? permission : permission.value
      )
    );
  }

  @Input()
  set prHasPermissionElse(template: TemplateRef<NgIfContext>) {
    if (template) {
      this._elseTemplateRef$.next(template);
    }
  }

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<unknown>,
    private _organisation: OrganisationService,
    private _management: ManagementService,
    private _cdr: ChangeDetectorRef
  ) {
    this._thenTemplateRef = templateRef;
    this._viewContainer.clear();

    const hasPermission$ = combineLatest([
      this._permissions$.pipe(isChanged$()),
      this._organisation.userPermissions$,
      this._management.user$.pipe(isPathChanged$('ref.path')),
    ]).pipe(userHasPermissions$());

    combineLatest([
      hasPermission$,
      this._elseTemplateRef$.pipe(startWith(undefined)),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([hasPermission, elseTemplate]) => {
        this._updateView(hasPermission, elseTemplate);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _updateView(
    hasPermission: boolean,
    elseTemplateRef?: TemplateRef<NgIfContext>
  ): void {
    this._viewContainer.clear();
    if (hasPermission) {
      this._viewContainer.createEmbeddedView(this._thenTemplateRef);
      this._cdr.detectChanges();
      return;
    }
    if (!this._elseViewRef && elseTemplateRef) {
      this._elseViewRef = this._viewContainer.createEmbeddedView(
        elseTemplateRef,
        this._context
      );
    }
    this._cdr.detectChanges();
  }
}

export function userHasPermissions$(): OperatorFunction<
  [string[], string[], IManagementUser | undefined],
  boolean
> {
  return (source) => {
    return source.pipe(
      map(([requestedPermissions, userPermissions, managementUser]) => {
        if (!userPermissions) {
          return false;
        }
        if (managementUser) {
          return true;
        }
        return User.hasPermissions(requestedPermissions, userPermissions);
      })
    );
  };
}
