import {
  AppointmentStatus,
  Gender,
  MeasureFormatter,
  MentionResourceType,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBigQueryReferrer,
  IPatientDimension,
} from '@principle-theorem/reporting/interfaces';
import { ISODateType, toISODate } from '@principle-theorem/shared';
import { compact, get, isNumber, max, min } from 'lodash';
import * as moment from 'moment-timezone';
import { BigQuerySQL, GroupBy } from '../../querying';
import { BaseDimensionMeasures, latestEventId } from '../base-measures';
import { DataAccessorFactory, MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap, noFilterAccessor } from '../measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  IMeasureRef,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';
import { AppointmentDimensionMeasureFactory } from './appointment-dimension';

export class PatientDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IPatientDimension, 'status' | 'gender'>>
{
  get latestEvent(): PatientDimensionMeasures {
    const updatedAt = this.measureRef(MeasurePath.timestamp('updatedAt'));
    const measures = new PatientDimensionMeasures(this.table, this.id);
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Ref, updatedAt.attributePath)
      .get();
    return measures;
  }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Patient Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get createdAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('createdAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('createdAt'),
        label: 'Date Patient Added',
        summary: 'Date that the patient was added to Principle',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get name(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Patient Name',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }

  get address(): CanDoAllProperty {
    const propertyName = 'address';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Patient Address',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get dateOfBirth(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('dateOfBirth'));
    return MeasurePropertyFactory.timestamp(
      {
        id: 'patient.dateOfBirth',
        label: 'Patient Date Of Birth',
        summary: 'Date of birth of the patient',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get email(): CanDoAllProperty {
    const propertyName = 'email';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Patient Email',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get mobileNumber(): CanDoAllProperty {
    const propertyName = 'mobileNumber';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Patient Mobile Number',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get status(): CanDoAllProperty {
    const propertyName = 'status';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<PatientStatus>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Patient Status',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      PatientStatus.Inactive
    );
  }

  get gender(): CanDoAllProperty {
    const propertyName = 'gender';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<Gender>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Patient Gender',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      Gender.Other
    );
  }

  get hasMobileNumber(): CanDoAllProperty {
    const measure = this.measureRef('mobileNumber');
    return MeasurePropertyFactory.boolean(
      {
        id: 'patient.hasMobileNumber',
        label: 'Patient Has Mobile Number',
        summary: 'Whether the patient has a mobile number or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hasEmail(): CanDoAllProperty {
    const measure = this.measureRef('email');
    return MeasurePropertyFactory.boolean(
      {
        id: 'patient.hasEmail',
        label: 'Patient Has Email',
        summary: 'Whether the patient has an email address or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get distance(): CanBeChartedProperty {
    const patientCoordinates = this.measureRef('metadata.address.coordinates');
    const practiceCoordinates: IMeasureRef = {
      attributePath: 'practice.coordinates',
      factPropertyPath: 'practice_coordinates',
    };

    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('distance'),
        label: 'Patient Distance',
        summary: 'Distance between the patient and the practice in KMs',
        formatter: MeasureFormatter.Suffix,
        formatterValue: 'kms',
      },
      measure: {
        propertyName: patientCoordinates.attributePath,
        query: this.buildQuery()
          .attributes([
            patientCoordinates.attributePath,
            practiceCoordinates.attributePath,
          ])
          .get(),
        dataAccessor: DataAccessorFactory.distance(
          patientCoordinates.factPropertyPath,
          practiceCoordinates.factPropertyPath
        ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get age(): CanDoAllProperty {
    const measure = this.measureRef(MeasurePath.timestamp('dateOfBirth'));
    return new CanDoAllProperty({
      metadata: {
        id: this._pathWithPrefix('age'),
        label: 'Patient Age',
        summary: 'Age of the patient in years',
        formatter: MeasureFormatter.Suffix,
        formatterValue: 'years',
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) => this._buildAge(measure.factPropertyPath, fact),
        filterAccessor: (filters) => {
          const values = filters.map((filter) => filter.value).filter(isNumber);
          return getAgeFilter(
            measure.factPropertyPath,
            min(values),
            max(values)
          );
        },
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: (fact) => this._buildAge(measure.factPropertyPath, fact),
        labelAccessor: (fact) =>
          this._buildAge(measure.factPropertyPath, fact).toString(),
      },
    });
  }

  get tags(): CanDoAllProperty {
    const measure = this.measureRef('tags');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('tags'),
        label: 'Patient Tags',
        summary: 'Tags that have ben added to the patient',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'name'
    );
  }

  get ageRange(): CanDoAllProperty {
    const measure = this.measureRef(MeasurePath.timestamp('dateOfBirth'));
    return new CanDoAllProperty({
      metadata: {
        id: this._pathWithPrefix('ageRange'),
        label: 'Patient Age Range',
        summary: '',
        formatter: MeasureFormatter.Suffix,
        formatterValue: 'years',
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) => {
          const age = this._buildAge(measure.factPropertyPath, fact);
          return getAgeRangeIndex(age);
        },
        filterAccessor: (filters) => {
          const ageFilters = filters.map((filter) =>
            isNumber(filter.value)
              ? getAgeRangeToFilter(measure.factPropertyPath, filter.value)
              : undefined
          );
          return BigQuerySQL.or(compact(ageFilters));
        },
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: (fact) => {
          const age = this._buildAge(measure.factPropertyPath, fact);
          return getAgeRangeIndex(age);
        },
        labelAccessor: (fact) => {
          const age = this._buildAge(measure.factPropertyPath, fact);
          return getAgeRangeLabel(getAgeRangeIndex(age));
        },
      },
    });
  }

  get referrer(): CanDoAllProperty {
    const name = this.measureRef('referrer.name');
    const docRef = this.measureRef(MeasurePath.docRef('referrer.ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('referrer'),
        label: 'Referrer Name',
        summary: `The name of the referral source of the patient. If the patient was referred by another patient, the patient's name will be shown`,
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name,
      'None'
    );
  }

  get referrerType(): CanDoAllProperty {
    const referrerMeasure = this.measureRef('referrer');
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix('referrerType'),
        label: 'Referrer Type',
        summary:
          'The type of the referral source of the patient. If the patient was referred by another patient, the type will be "Patient"',
      },
      referrerMeasure,
      this.buildQuery().attributes([referrerMeasure.attributePath]).get(),
      'None',
      (fact: unknown) => {
        const referrer = get(
          fact,
          referrerMeasure.factPropertyPath,
          undefined
        ) as IBigQueryReferrer | undefined;

        if (!referrer) {
          return 'None';
        }

        if (referrer.type === MentionResourceType.Patient) {
          return 'Patient';
        }

        return referrer.name;
      }
    );
  }

  get patientReferrers(): CanDoAllProperty {
    const referrerMeasure = this.measureRef('referrer');
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix('patientReferrers'),
        label: 'Patient Referrers',
        summary:
          'The names of the patients who referred the patient. If they were not referred by a patient, "None" will be shown.',
      },
      referrerMeasure,
      this.buildQuery().attributes([referrerMeasure.attributePath]).get(),
      'None',
      (fact: unknown) => {
        const referrer = get(
          fact,
          referrerMeasure.factPropertyPath,
          undefined
        ) as IBigQueryReferrer | undefined;

        if (!referrer || referrer.type !== MentionResourceType.Patient) {
          return 'None';
        }

        return referrer.name;
      }
    );
  }

  get preferredDentist(): CanDoAllProperty {
    const propertyName = 'preferredDentist';
    const docRef = this.measureRef(MeasurePath.docRef(`${propertyName}.ref`));
    const name = this.measureRef(`${propertyName}.name`);
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Preferred Dentist',
        summary: 'The name of the dentist that the patient prefers to see',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name,
      'None'
    );
  }

  get preferredHygienist(): CanDoAllProperty {
    const propertyName = 'preferredHygienist';
    const docRef = this.measureRef(MeasurePath.docRef(`${propertyName}.ref`));
    const name = this.measureRef(`${propertyName}.name`);
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Preferred Hygienist',
        summary: 'The name of the hygienist that the patient prefers to see',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name,
      'None'
    );
  }

  get preferredPractice(): CanDoAllProperty {
    const propertyName = 'preferredPractice';
    const docRef = this.measureRef(MeasurePath.docRef(`${propertyName}.ref`));
    const name = this.measureRef(`${propertyName}.name`);
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Preferred Practice',
        summary: 'The name of the practice that the patient prefers to visit',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name,
      'None'
    );
  }

  get depositsPaid(): CanBeChartedProperty {
    const propertyName = 'accountSummary.creditSummary.depositsPaid';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Deposits Paid',
        summary: 'Total of all deposits ever paid by the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get creditTotal(): CanBeChartedProperty {
    const propertyName = 'accountSummary.creditSummary.creditTotal';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Account Credit Total',
        summary:
          'Total of all account credits the patient has ever amassed, regardless of how much has been used.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get creditUsed(): CanBeChartedProperty {
    const propertyName = 'accountSummary.creditSummary.creditUsed';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Account Credit Used',
        summary: 'Total of all account credits ever used by the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      0,
      (value) => value * -1
    );
  }

  get creditRemaining(): CanBeChartedProperty {
    const propertyName = 'accountSummary.creditSummary.creditRemaining';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Account Credit Remaining',
        summary: 'Total of all unused account credits available to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentsInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.treatments';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Treatments Invoiced',
        summary: 'Total of all treatments ever invoiced to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get productsInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.products';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Products Invoiced',
        summary: 'Total of all products ever invoiced to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get feesInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.fees';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Fees Invoiced',
        summary: 'Total of all fees ever invoiced to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get subtotalInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.subtotal';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Subtotal Invoiced',
        summary:
          'Subtotal of all invoices ever issued to the patient, excluding tax.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get depositsInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.deposits';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Deposits Invoiced',
        summary: 'Total of all deposits ever invoiced to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get totalInvoiced(): CanBeChartedProperty {
    const propertyName = 'accountSummary.invoiceSummary.totalInvoiced';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Invoiced',
        summary:
          'Total of all invoices ever issued to the patient, including tax.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get discountsGiven(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.discounts';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Discounts Given to Patient',
        summary: 'Total of all discounts ever given to the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      0,
      (value) => value * -1
    );
  }

  get writtenOff(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.writtenOff';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Written Off',
        summary:
          'Total of the unpaid remainder of invoices ever written off for the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      0,
      (value) => value * -1
    );
  }

  get creditsUsed(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.creditUsed';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Credits Used',
        summary: 'Total of all account credits ever used by the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      0,
      (value) => value * -1
    );
  }

  get totalReceivable(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.totalReceivable';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Receivable',
        summary:
          'Total of all of the patients invoices, minus discounts, minus written off amounts, minus account credits used.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get paymentsReceived(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.paymentsReceived';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Payments Received',
        summary:
          'Total of all of the patients payments received, excluding discounts & account credits.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get outstanding(): CanBeChartedProperty {
    const propertyName = 'accountSummary.paymentSummary.outstanding';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Outstanding',
        summary:
          'Total of all outstanding amounts from invoices for the patient',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get lastVisitTreatmentCategory(): CanDoAllProperty {
    const treatmentCategory =
      'lastVisit.treatmentPlan.treatmentStep.treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`);
    const colourMeasure = this.measureRef(`${treatmentCategory}.colour.value`);
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`)
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: 'lastVisit.treatmentCategory',
        label: 'Last Visit Treatment Category',
        summary: `The primary treatment category of the patient's last visit`,
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }

  get lastVisitPractitioner(): CanDoAllProperty {
    const name = this.measureRef('lastVisit.practitioner.name');
    const docRef = this.measureRef(
      MeasurePath.docRef('lastVisit.practitioner.ref')
    );
    return MeasurePropertyFactory.docRef(
      {
        id: 'lastVisit.practitioner',
        label: 'Last Visit Practitioner',
        summary: 'The name of the practitioner that the patient last saw',
      },
      docRef,
      this.buildQuery()
        .attributes([name.attributePath, docRef.attributePath])
        .get(),
      name
    );
  }

  get lastVisitDate(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('lastVisit.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: 'lastVisit.date',
        label: 'Last Visit Date',
        summary: 'Date that the patient last had an appointment',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get lastVisitTags(): CanDoAllProperty {
    const measure = this.measureRef('lastVisit.tags');
    return MeasurePropertyFactory.array(
      {
        id: 'lastVisit.tags',
        label: 'Last Visit Tags',
        formatter: MeasureFormatter.Text,
        summary: `The appointment tags on the patient's last visit`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'name'
    );
  }

  get lastVisitStatus(): CanDoAllProperty {
    const measure = this.measureRef('lastVisit.status');
    return MeasurePropertyFactory.enum<AppointmentStatus>(
      {
        id: 'lastVisit.status',
        label: 'Last Visit Status',
        summary: `The status of the patient's last visit`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'N/A'
    );
  }

  get nextVisitBooked(): CanDoAllProperty {
    const measure = this.measureRef('nextVisit');
    return MeasurePropertyFactory.boolean(
      {
        id: 'nextVisit.isBooked',
        label: 'Next Visit Booked',
        summary: 'Whether the patient has a next visit booked or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get nextVisitTreatmentCategory(): CanDoAllProperty {
    const treatmentCategory =
      'nextVisit.treatmentPlan.treatmentStep.treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`);
    const colourMeasure = this.measureRef(`${treatmentCategory}.colour.value`);
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`)
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: 'nextVisit.treatmentCategory',
        label: 'Next Visit Treatment Category',
        summary: `The primary treatment category of the patient's next visit`,
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }

  get nextVisitPractitioner(): CanDoAllProperty {
    const name = this.measureRef('nextVisit.practitioner.name');
    const docRef = this.measureRef(
      MeasurePath.docRef('nextVisit.practitioner.ref')
    );
    return MeasurePropertyFactory.docRef(
      {
        id: 'nextVisit.practitioner',
        label: 'Next Visit Practitioner',
        summary:
          'The name of the practitioner that the patient is booked to see',
      },
      docRef,
      this.buildQuery()
        .attributes([name.attributePath, docRef.attributePath])
        .get(),
      name
    );
  }

  get nextVisitDate(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('nextVisit.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: 'nextVisit.date',
        label: 'Next Visit Date',
        summary: `Date of the patient's next appointment`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get nextVisitTags(): CanDoAllProperty {
    const measure = this.measureRef('nextVisit.tags');
    return MeasurePropertyFactory.array(
      {
        id: 'nextVisit.tags',
        label: 'Next Visit Tags',
        summary: `The appointment tags on the patient's next visit`,
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'name'
    );
  }

  get nextVisitStatus(): CanDoAllProperty {
    const measure = this.measureRef('nextVisit.status');
    return MeasurePropertyFactory.enum<AppointmentStatus>(
      {
        id: 'nextVisit.status',
        label: 'Next Visit Status',
        summary: `The status of the patient's next visit`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'N/A'
    );
  }

  get hasMedicareCard(): CanDoAllProperty {
    const measure = this.measureRef('medicareCard.number');
    return MeasurePropertyFactory.boolean(
      {
        id: 'patient.hasMedicareCard',
        label: 'Patient Has Medicare Card',
        summary: 'Whether the patient has a Medicare Card or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hasDvaCard(): CanDoAllProperty {
    const measure = this.measureRef('dvaCard.number');
    return MeasurePropertyFactory.boolean(
      {
        id: 'patient.hasDvaCard',
        label: 'Patient Has DVA Card',
        summary: 'Whether the patient has a DVA Card or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hasHealthFundCard(): CanDoAllProperty {
    const measure = this.measureRef('healthFundCard.membershipNumber');
    return MeasurePropertyFactory.boolean(
      {
        id: 'patient.hasHealthFundCard',
        label: 'Patient Has Health Fund Card',
        summary: 'Whether the patient has a Health Fund Card or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get healthFundType(): CanDoAllProperty {
    const measure = this.measureRef('healthFundCard.fundCode');
    return MeasurePropertyFactory.string(
      {
        id: 'patient.healthFundType',
        label: 'Patient Health Fund Type',
        summary: `The type of health fund that the patients is a member of`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'No Health Fund Type'
    );
  }

  private _buildAge(propertyName: string, fact: unknown): number {
    const dateOfBirth = DataAccessorFactory.timestamp(
      propertyName,
      undefined
    )(fact);
    return dateOfBirth ? moment().diff(moment(dateOfBirth), 'years') : 0;
  }
}

export function getAgeRangeLabel(index: AgeRange): string {
  switch (index) {
    case AgeRange.Under12:
      return 'Under 12';
    case AgeRange.Between12And17:
      return 'Between 12 And 17';
    case AgeRange.Between18And24:
      return 'Between 18 And 24';
    case AgeRange.Between25And34:
      return 'Between 25 And 34';
    case AgeRange.Between35And44:
      return 'Between 35 And 44';
    case AgeRange.Between45And54:
      return 'Between 45 And 54';
    case AgeRange.Between55And64:
      return 'Between 55 And 64';
    case AgeRange.Between65And74:
      return 'Between 65 And 74';
    case AgeRange.OlderThan74:
      return 'Older Than 74';
    default:
      return '';
  }
}

export function getAgeRangeIndex(age: number): number {
  if (age < 12) {
    return AgeRange.Under12;
  }
  if (age < 18) {
    return AgeRange.Between12And17;
  }
  if (age < 25) {
    return AgeRange.Between18And24;
  }
  if (age < 35) {
    return AgeRange.Between25And34;
  }
  if (age < 45) {
    return AgeRange.Between35And44;
  }
  if (age < 55) {
    return AgeRange.Between45And54;
  }
  if (age < 65) {
    return AgeRange.Between55And64;
  }
  if (age < 75) {
    return AgeRange.Between65And74;
  }
  return AgeRange.OlderThan74;
}

export function getAgeFilter(
  path: string,
  minAge: number = 0,
  maxAge: number = 150
): string {
  return BigQuerySQL.dateRange(path, yearsAgo(maxAge), yearsAgo(minAge));
}

export function getAgeRangeToFilter(
  path: string,
  ageRange: AgeRange
): string | undefined {
  switch (ageRange) {
    case AgeRange.Under12:
      return BigQuerySQL.filter(path, '>=', yearsAgo(12));
    case AgeRange.Between12And17:
      return getAgeFilter(path, 12, 17);
    case AgeRange.Between18And24:
      return getAgeFilter(path, 17, 24);
    case AgeRange.Between25And34:
      return getAgeFilter(path, 24, 34);
    case AgeRange.Between35And44:
      return getAgeFilter(path, 34, 44);
    case AgeRange.Between45And54:
      return getAgeFilter(path, 44, 54);
    case AgeRange.Between55And64:
      return getAgeFilter(path, 54, 64);
    case AgeRange.Between65And74:
      return getAgeFilter(path, 64, 74);
    case AgeRange.OlderThan74:
      return BigQuerySQL.filter(path, '<=', yearsAgo(74));
    default:
      return undefined;
  }
}

function yearsAgo(numYears: number): ISODateType {
  return toISODate(moment().subtract(numYears, 'years'));
}

export enum AgeRange {
  Under12,
  Between12And17,
  Between18And24,
  Between25And34,
  Between35And44,
  Between45And54,
  Between55And64,
  Between65And74,
  OlderThan74,
}
