import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAndConditions,
  IConditionLogicConfiguration,
  IOrConditions,
} from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';

export const CONDITION_LABEL_MAP: { [key in ConditionLogicId]: string } = {
  [ConditionLogicId.Always]: 'Always',
  [ConditionLogicId.Never]: 'Never',
  [ConditionLogicId.PatientHasTag]: 'Patient Has Tag',
  [ConditionLogicId.PatientHasMobileNumber]: 'Patient Has Mobile Number',
  [ConditionLogicId.PatientHasEmailAddress]: 'Patient Has Email Address',
  [ConditionLogicId.PatientHasIssuedForm]: 'Patient Has Issued Form',
  [ConditionLogicId.PatientHasSubmittedForm]: 'Patient Has Submitted Form',
  [ConditionLogicId.AppointmentHasTag]: 'Appointment Has Tag',
  [ConditionLogicId.AppointmentIsStatus]: 'Appointment Is Status',
  [ConditionLogicId.AppointmentConfirmed]: 'Appointment Confirmed',
  [ConditionLogicId.IsFirstAppointment]: 'Is First Appointment',
  [ConditionLogicId.AppointmentIncludesTreatment]:
    'Appointment Includes Treatment',
  [ConditionLogicId.AppointmentExcludesTreatment]:
    'Appointment Excludes Treatment',
  [ConditionLogicId.AppointmentIsTreatmentCategory]:
    'Appointment Is Treatment Category',
  [ConditionLogicId.AppointmentIsNotTreatmentCategory]:
    'Appointment Is Not Treatment Category',
  [ConditionLogicId.AppointmentIncludesPractitioner]:
    'Appointment Includes Practitioner',
  [ConditionLogicId.AppointmentExcludesPractitioner]:
    'Appointment Excludes Practitioner',
};

export class ConditionLogic {
  static collection(
    andConditions: IAndConditions[]
  ): ConditionLogicConfigurationCollection {
    return this.or(andConditions);
  }

  static or(andConditions: IAndConditions[]): IOrConditions {
    return {
      uid: uuid(),
      or: andConditions,
    };
  }

  static and(
    conditions: IConditionLogicConfiguration<unknown>[] = []
  ): IAndConditions {
    return {
      uid: uuid(),
      and: conditions,
    };
  }

  static never(): IConditionLogicConfiguration<unknown> {
    return {
      uid: uuid(),
      conditionId: ConditionLogicId.Never,
      config: {},
    };
  }

  static always(): IConditionLogicConfiguration<unknown> {
    return {
      uid: uuid(),
      conditionId: ConditionLogicId.Always,
      config: {},
    };
  }
}
