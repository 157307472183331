import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { PatientFormStatus } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-form-status',
    templateUrl: './form-status.component.html',
    styleUrls: ['./form-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgSharedModule, NgMaterialModule]
})
export class FormStatusComponent {
  @Input() status: PatientFormStatus;

  getColour(status: PatientFormStatus): string {
    switch (status) {
      case PatientFormStatus.Confirmed:
        return 'border-emerald-500 text-emerald-500';
      case PatientFormStatus.Submitted:
        return 'border-yellow-500 text-yellow-500';
      case PatientFormStatus.Issued:
      default:
        return 'border-slate-400 text-slate-400';
    }
  }

  getTooltip(status: PatientFormStatus): string | undefined {
    switch (status) {
      case PatientFormStatus.Issued:
        return 'Issued forms have not been filled out by the patient yet';
      case PatientFormStatus.Submitted:
        return 'Submitted forms have been filled out by the patient and are awaiting confirmation from a staff member';
      case PatientFormStatus.Confirmed:
        return 'Confirmed forms have been checked by a staff member';
      default:
        return;
    }
  }
}
