<div class="flex max-h-96 w-80 flex-col gap-4 overflow-scroll p-2">
  @for (reason of reasons$ | async; track reason.ref.path) {
    <div class="flex flex-col gap-1">
      <h3
        class="!m-0 !text-sm !font-bold"
        [ngClass]="{
          'text-blue-500': reason | map: isSelectedReason$ : this | async
        }"
      >
        {{ reason.name }}
      </h3>

      <div class="text-xs">
        {{ reason | map: conditionsDisplay }}
        @if (reason | map: isDefaultReason$ : this | async) {
          <span class="text-blue-300">
            Default for conditions at time of scheduling.
          </span>
        }
      </div>

      <div class="text-xs opacity-50">
        @if (reason.description.length > 0) {
          {{ reason.description }}
        } @else {
          No description
        }
      </div>
    </div>
  }
</div>
