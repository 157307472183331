import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { BaseMenuComponent } from '@principle-theorem/ng-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IChartedItemDetail,
  type IDentalChartViewSurface,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../../chart-entity-id';

@Component({
  selector: 'pr-chart-surface-menu',
  templateUrl: './chart-surface-menu.component.html',
  styleUrls: ['./chart-surface-menu.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSurfaceMenuComponent extends BaseMenuComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  trackByChartedItem = TrackByFunctions.ref<IChartedItemDetail>('config.ref');
  chartedItems$: ReplaySubject<IChartedItemDetail[]> = new ReplaySubject(1);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  disabled$: Observable<boolean>;

  constructor(
    private _snackBar: MatSnackBar,
    private _chartState: ChartFacade
  ) {
    super();

    this.disabled$ = this._chartState
      .canEdit$(this._chartId)
      .pipe(map((canStore) => !canStore));
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    this.view$.next(view);
  }

  @Input()
  set chartedItems(chartedItems: IChartedItemDetail[]) {
    this.chartedItems$.next(chartedItems);
  }

  async removeChartedItem(itemDetail: IChartedItemDetail): Promise<void> {
    await this._chartState.removeChartedItem(
      this._chartId,
      await snapshot(this.view$),
      itemDetail
    );
    this._snackBar.open(`Removed ${itemDetail.config.name}`);
  }

  removeTooth(tooth: ITooth): void {
    this._chartState.removeTooth(this._chartId, tooth.toothRef);
    this._snackBar.open('Tooth Removed');
  }
}
