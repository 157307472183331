<ng-container *ngIf="patient$ | async as patient; else noPatient">
  <pr-patient-profile-summary
    [patient]="patient"
    [actions]="patientActions"
    [expandable]="true"
    class="m-4 block"
  >
    <div>
      <pr-patient-last-visit [patient]="patient" />

      <div
        *ngIf="nextScheduledAppointment$ | async as nextScheduledAppointment"
      >
        <strong>Next Visit: </strong>
        <pr-formatted-event-field
          *ngIf="nextScheduledAppointment.event"
          field="from"
          [event]="nextScheduledAppointment.event"
          [format]="dateFormat"
        />
      </div>

      <ng-container *ngIf="appointment$ | async as appointment">
        <div *ngIf="appointment.event; else noEvent">
          <strong>Appointment: </strong>
          <pr-formatted-event-field
            field="from"
            [event]="appointment.event"
            [format]="dateService.format.dateWithTimes"
          />
        </div>
      </ng-container>

      <ng-template #noEvent>
        <div><strong>Appointment: </strong>Unscheduled</div>
      </ng-template>

      <pr-patient-fee-schedule [feeSchedule]="patient.preferredFeeSchedule" />
    </div>

    <pr-appointment-tags-sidebar-list
      *ngIf="hasAppointmentTags$ | async"
      [appointment]="appointment$ | async"
    />
  </pr-patient-profile-summary>

  <mat-divider />

  <pr-patient-medical-alerts [patient]="patient" class="m-4 block" />
</ng-container>

<ng-template #noPatient>
  <div class="h-14"></div>
</ng-template>
