<mat-list-item *ngIf="product$ | async as product">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <div fxFlex>
      {{ product.name }}
    </div>
    <div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
        <div class="itemlist">
          {{ product.cost | currency }}
        </div>
        <div>
          <button mat-icon-button (click)="edit()">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-list-item>
