import { type ICSVExport } from '@principle-theorem/ng-shared';
import { first } from 'lodash';
import { type IPractitionerTransactionReportRecord } from '@principle-theorem/reporting';
import { PractitionerTransactionDrilldownToCSV } from '../practitioner-transactions-report-drilldown/practitioner-transactions-drilldown-to-csv';
import { roundTo2Decimals } from '@principle-theorem/accounting';

export interface IPractitionerTransactionComplexDrilldownCSV {
  transactionDate: string;
  invoiceIssuedAt: string;
  patient: string;
  invoice: string;
  transactionProvider: string;
  transactionProviderType: string;
  invoiceAmount: number;
  depositAmount: number;
  productAmount: number;
  feeAmount: number;
  treatmentAmounts: string;
  accountCreditPaymentType: string;
  accountCreditAmount: number;
  discountAmount: number;
  paymentAmount: number;
  practitionerProportionAmount: number;
}

export class PractitionerTransactionComplexDrilldownToCSV
  implements
    ICSVExport<
      IPractitionerTransactionReportRecord,
      IPractitionerTransactionComplexDrilldownCSV
    >
{
  defaultFileName = 'practitioner-transactions-complex-drilldown';
  headers = [
    'Transaction Date',
    'Invoice Issued At',
    'Patient',
    'Invoice/Transaction Reference',
    'Type',
    'Card / Manual Type',
    'Invoice Amount',
    'Deposit Amount',
    'Product Amount',
    'Fee Amount',
    'Treatment Amounts',
    'Account Credit Payment Type',
    'Account Credits',
    'Discounts',
    'Payments',
    'Practitioner Proportion',
  ];

  translate(
    records: IPractitionerTransactionReportRecord[]
  ): IPractitionerTransactionComplexDrilldownCSV[] {
    const baseDrilldown = new PractitionerTransactionDrilldownToCSV(false);
    return records.map((record) => {
      const baseData = first(baseDrilldown.translate([record]));
      if (!baseData) {
        throw new Error('Failed to translate base data');
      }
      const treatmentAmounts = record.summary.treatmentAmounts
        .map((item) => `${item.practitioner.name} (${item.amount})`)
        .join(', ');

      return {
        transactionDate: baseData.transactionDate,
        invoiceIssuedAt: baseData.invoiceIssuedAt,
        patient: baseData.patient,
        invoice: baseData.invoice,
        transactionProvider: baseData.transactionProvider,
        transactionProviderType: baseData.transactionProviderType,
        invoiceAmount: roundTo2Decimals(record.summary.invoiceAmount),
        depositAmount: roundTo2Decimals(record.summary.depositAmount),
        productAmount: roundTo2Decimals(record.summary.productAmount),
        feeAmount: roundTo2Decimals(record.summary.feeAmount),
        treatmentAmounts,
        accountCreditPaymentType: baseData.accountCreditPaymentType,
        accountCreditAmount: baseData.accountCreditAmount,
        discountAmount: baseData.discountAmount,
        paymentAmount: baseData.paymentAmount,
        practitionerProportionAmount: baseData.practitionerProportionAmount,
      };
    });
  }
}
