<div *ngrxLet="treatmentPlan$ as treatmentPlan" class="flex flex-col gap-1">
  <div class="flex flex-row items-center justify-end">
    <pt-multi-filter-group
      [compact]="true"
      tooltip="Filter by status"
      [statusFilters]="stepDisplay.treatmentStepStatusOptions"
      [activeStatuses]="stepDisplay.activeStatusFilters()"
      [displayBadge]="stepDisplay.hasActiveStatusFilters()"
      (statusFilterChange)="stepDisplay.updateUsersFilteredStatuses($event)"
    />

    <pr-treatment-step-sort-by-menu />

    <pr-staff-fee-schedule-menu
      [patient]="patient$ | async"
      [feeSchedule]="defaultSchedule$ | async"
      [plan]="treatmentPlan$ | async"
      (scheduleSelected)="changeSchedule($event, treatmentPlan)"
    />

    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item (click)="print(treatmentPlan)">
        <mat-icon>print</mat-icon>
        <span>Print Plan</span>
      </button>
      <button
        mat-menu-item
        [disabled]="duplicating$ | ngrxPush"
        (click)="duplicate(treatmentPlan)"
      >
        <mat-icon>content_copy</mat-icon>
        <span>Make a Copy</span>
      </button>
      <mat-divider />
      <button
        mat-menu-item
        *ngIf="treatmentPlan | map: canAccept"
        (click)="acceptPlan(treatmentPlan)"
      >
        <mat-icon color="primary">thumb_up</mat-icon>
        <span>Accept Plan</span>
      </button>
      <button
        mat-menu-item
        *ngIf="treatmentPlan | map: canDecline"
        (click)="declinePlan(treatmentPlan)"
      >
        <mat-icon color="warn">thumb_down</mat-icon>
        <span>Decline Plan</span>
      </button>
      <div
        *ngrxLet="treatmentPlan | map: canDelete$ as canDelete"
        matTooltip="Can't delete a plan that has treatment steps"
        [matTooltipDisabled]="canDelete"
      >
        <button
          mat-menu-item
          [disabled]="!canDelete"
          (click)="deletePlan(treatmentPlan)"
        >
          <mat-icon color="warn">delete</mat-icon>
          <span>Delete Plan</span>
        </button>
      </div>
    </mat-menu>
  </div>

  <div class="flex justify-between gap-4">
    <pr-assignee-profile-selector
      selectTooltip="Reassign Practitioner"
      [disableClear]="true"
      [selectedStaffer]="treatmentPlan.practitioner?.ref"
      [staff]="organisation.practicePractitioners$ | async"
      (stafferSelected)="patchPlanPractitioner($event)"
    />
    <div class="plan-name flex flex-1 flex-col">
      <mat-form-field class="compact no-style mb-1" subscriptSizing="dynamic">
        <mat-label>Plan Name</mat-label>
        <input
          matInput
          autocomplete="off"
          type="text"
          [formControl]="nameControl"
        />
      </mat-form-field>
      <div class="flex justify-between gap-2">
        <span
          class="mat-caption"
          *ngIf="treatmentPlan.practitioner?.name; else noPractitioner"
        >
          Assigned to {{ treatmentPlan.practitioner?.name }}
        </span>
        <div class="price my-2">
          {{ price$ | ngrxPush | currency }}
        </div>
      </div>
      <ng-template #noPractitioner>
        <span class="mat-caption">{{ treatmentPlan.type }}</span>
      </ng-template>
    </div>
  </div>
</div>
