import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ProfileImageService,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import { TimezoneResolver } from '@principle-theorem/principle-core';
import {
  IChatMessage,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  filterUndefined,
  isSameRef,
  safeCombineLatest,
  shareReplayCold,
  toMomentTz,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChatActions } from '../../chat-actions';
import { type IChatSummary } from '../../pages/chats-dashboard/chats-dashboard.store';
import { getSchemaText } from '@principle-theorem/editor';

@Component({
  selector: 'pr-chat-menu-item',
  templateUrl: './chat-menu-item.component.html',
  styleUrls: ['./chat-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMenuItemComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  profileImage = inject(ProfileImageService);
  chat$ = new ReplaySubject<IChatSummary>(1);
  staffer$: Observable<WithRef<IStaffer>>;
  participants$: Observable<DocumentReference<IStaffer>[]>;
  lastMessage$: Observable<IChatMessage | undefined>;
  lastMessageAt$: Observable<Moment | undefined>;
  chatLabel$: Observable<string>;
  actions: ChatActions;
  multiSelectEnabled$ = new BehaviorSubject<boolean>(false);
  @Input() selectionList: SelectionListStore<IChatSummary>;

  @Input()
  set chat(chat: IChatSummary) {
    if (chat) {
      this.chat$.next(chat);
    }
  }

  @Input()
  set multiSelectEnabled(isEnabled: BooleanInput) {
    this.multiSelectEnabled$.next(coerceBooleanProperty(isEnabled));
  }

  constructor(
    private _organisation: OrganisationService,
    snackBar: MatSnackBar,
    private _globalStore: GlobalStoreService
  ) {
    this.actions = new ChatActions(
      this.chat$,
      this._organisation.staffer$.pipe(filterUndefined()),
      snackBar,
      this._onDestroy$
    );

    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());

    this.participants$ = combineLatest([
      this.staffer$,
      this.chat$.pipe(map((summary) => summary.chat.participants)),
    ]).pipe(
      map(([staffer, participants]) =>
        participants.filter((participant) => !isSameRef(participant, staffer))
      ),
      shareReplayCold()
    );

    this.chatLabel$ = combineLatest([this.chat$, this.participants$]).pipe(
      switchMap(([chat, participants]) => {
        if (chat.chat.label) {
          return of(chat.chat.label);
        }

        if (!participants.length) {
          return of('Empty Group');
        }

        return safeCombineLatest(
          participants.map((participantRef) =>
            this.stafferName$(participantRef)
          )
        ).pipe(map((participantNames) => participantNames.join(', ')));
      })
    );

    this.lastMessage$ = this.chat$.pipe(
      map((chat) => chat.lastMessage),
      filterUndefined()
    );

    this.lastMessageAt$ = this.chat$.pipe(
      switchMap(async (chat) =>
        chat.chat.lastMessageAt
          ? toMomentTz(
              chat.chat.lastMessageAt,
              await TimezoneResolver.fromPracticeRef(chat.chat.practiceRef)
            )
          : undefined
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  stafferImage$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<string | undefined> {
    return this._globalStore.getStafferImage$({ ref: stafferRef });
  }

  stafferName$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<string | undefined> {
    return this._globalStore.getStafferName$({ ref: stafferRef });
  }

  getTextContent(message: IChatMessage): string {
    return getSchemaText(message.content);
  }
}
