/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Patient } from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { type Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-new-patient-icon',
    templateUrl: './new-patient-icon.component.html',
    styleUrls: ['./new-patient-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NewPatientIconComponent {
  patient$ = new ReplaySubject<IReffable<IPatient>>(1);
  appointment$ = new BehaviorSubject<
    WithRef<IAppointment> | AppointmentSummary | undefined
  >(undefined);
  isNewPatient$: Observable<boolean>;
  @Input() iconSize: 'icon-lg' | 'icon-md' | 'icon-sm' | 'icon-xs' = 'icon-lg';

  @Input()
  set patient(patient: IReffable<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment> | AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.isNewPatient$ = combineLatest([this.patient$, this.appointment$]).pipe(
      switchMap(([patient, appointment]) =>
        Patient.isNewPatient$(patient, appointment?.ref)
      )
    );
  }
}
