import { Component, Input } from '@angular/core';
import { type IChartCard } from '../../../models/report/charts/chart-card';

@Component({
    selector: 'pr-schedule-card',
    templateUrl: './schedule-card.component.html',
    styleUrls: ['./schedule-card.component.sass'],
    standalone: false
})
export class ScheduleCardComponent {
  @Input() chart: IChartCard;
}
