import { type IChartedSurface } from './charted-surface';

export interface IAreaSummary {
  label: string;
  values: IAreaSummaryValue[];
}

export interface IAreaSummaryValue {
  label: string;
  surface: IChartedSurface;
}
