import {
  DEFAULT_EXTENSIONS,
  MixedSchema,
  getMixedContent,
  initVersionedSchema,
  isMixedSchema,
} from '@principle-theorem/editor';
import { AnyExtension, Editor } from '@tiptap/core';

export function versionedSchemaToHtml(
  content: MixedSchema,
  extensions: AnyExtension[] = []
): string {
  const editor = new Editor({
    extensions: [...DEFAULT_EXTENSIONS, ...extensions],
    content: isMixedSchema(content)
      ? getMixedContent(content)
      : initVersionedSchema(content).content,
  });

  return editor.getHTML();
}
