import { ChangeDetectionStrategy, Component } from '@angular/core';
import { randBoolean, randFloat } from '@ngneat/falso';

@Component({
    selector: 'pr-measure-history-summary',
    templateUrl: './measure-history-summary.component.html',
    styleUrls: ['./measure-history-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MeasureHistorySummaryComponent {
  changeIsPositive: boolean = randBoolean();
  changePercentage: number = randFloat({ min: 0.01, max: 0.2, fraction: 4 });
}
