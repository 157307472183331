<div *ngFor="let option of options$ | async; trackBy: trackByAccessOption">
  <mat-checkbox
    [disabled]="disabled"
    [checked]="isBrandSelected(option.brand)"
    (change)="selectBrand(option.brand.ref, option.practices, $event.checked)"
  >
    {{ option.brand.name }}
  </mat-checkbox>
  <div
    *ngFor="let practice of option.practices; trackBy: trackByPractice"
    class="practices"
  >
    <mat-checkbox
      [disabled]="disabled"
      [checked]="isPracticeSelected(practice)"
      (change)="selectPractice(practice.ref, option.brand, $event.checked)"
    >
      {{ practice.name }}
    </mat-checkbox>
  </div>
</div>
