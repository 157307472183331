import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pr-history-list-item',
    templateUrl: './history-list-item.component.html',
    styleUrls: ['./history-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HistoryListItemComponent {
  @Input() first = false;
  @Input() last = false;
  @Input() selected = false;
  @Input() closed = true;
}
