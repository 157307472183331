import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IGap } from '@principle-theorem/principle-core/interfaces';
import {
  Appointment,
  DEFAULT_TIME_INCREMENT,
  type IWaitListCandidate,
} from '@principle-theorem/principle-core';
import {
  type ITimePeriod,
  TimeBucket,
  TIME_FORMAT,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-candidate-gap-time',
  templateUrl: './candidate-gap-time.component.html',
  styleUrls: ['./candidate-gap-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CandidateGapTimeComponent {
  trackByAppointmentOption = TrackByFunctions.date<ITimePeriod>('from');
  appointmentOptions$: Observable<ITimePeriod[]>;
  selectedOption: ITimePeriod;
  readonly timeFormat = TIME_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGapTimeData,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<CandidateGapTimeComponent>,
    private _timezone: TimezoneService
  ) {
    const duration: moment.Duration = moment.duration(
      Appointment.duration(this.data.candidate.appointment),
      'minutes'
    );
    this.appointmentOptions$ = this._timezone
      .resolvePracticeTimezone$(this.data.gap.event.practice.ref)
      .pipe(
        map((timezone) =>
          TimeBucket.fromEvents([this.data.gap.event])
            .exhaustTimeOptions({
              timeIncrement: DEFAULT_TIME_INCREMENT,
              duration,
            })
            .get()
            .map((timePeriod) => ({
              from: moment(timePeriod.from).tz(timezone),
              to: moment(timePeriod.to).tz(timezone),
            }))
        )
      );
  }

  save(): void {
    this._snackBar.open('Selected time updated');
    this._dialogRef.close(this.selectedOption);
  }
}

export interface IGapTimeData {
  gap: IGap;
  candidate: IWaitListCandidate;
}
