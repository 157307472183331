import { type IPracticeMigration } from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  XSLXImporterExporter,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { PracticeMigration } from '../practice-migrations';
import { TranslationMapHandler } from '../translation-map';
import { SingleValuesToXSLX } from './single-value-to-xlsx';
import { XSLXToSingleValue } from './xlsx-to-single-value';

export abstract class SingleValueMappingHandler extends BaseCustomMappingHandler<
  object,
  string
> {
  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, translator } = await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, [], translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, translator } = await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, [], translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToSingleValue()
    );

    const item = first(items);
    if (!item) {
      return;
    }

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await this.upsertRecord(
      {
        destinationValue: item.mapTo,
        sourceIdentifier: this.customMapping.metadata.type,
        sourceLabel: this.customMapping.metadata.label,
      },
      translationMap
    );
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    translator: SingleValuesToXSLX;
  }> {
    const fileName = this.getFileName();
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new SingleValuesToXSLX(
      await this.getRecords(translationMap)
    );
    return { fileName, translator };
  }
}
