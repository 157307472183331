import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import {
  ManageAppointmentQueryParams,
  ofRouteMatch,
  selectQueryParams,
  selectRouteParam,
  type IPrincipleState,
} from '@principle-theorem/ng-principle-shared';
import { cancelAction } from '@principle-theorem/ng-shared';
import { Appointment, TreatmentPlan } from '@principle-theorem/principle-core';
import {
  doc,
  filterUndefined,
  getDoc,
  serialise,
  serialise$,
  toMoment,
  unserialise$,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  AppointmentSelectorActions,
  PatientDetailsActions,
  SchedulingActions,
} from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';
import { type IAppointmentFilterOptions } from '../models/appointment-filter';
import { type IAppointmentSchedulingPartialState } from '../reducers/appointment-scheduling.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PatientsFacade } from 'libs/ng-patient/src/lib/store/facades/patients.facade';

export const APPOINTMENT_MANAGE_REGEX = /appointments\/\w+\/manage\/.+/;
export const APPOINTMENT_CREATE_REGEX = /appointments\/create/;

@Injectable()
export class AppointmentSchedulingRouterEffects {
  private _actions$ = inject(Actions);
  private _store = inject(
    Store<IAppointmentSchedulingPartialState & IPrincipleState>
  );
  private _patientFacade = inject(PatientsFacade);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  setAppointmentManageRouteData$ = createEffect(() =>
    this._setAppointmentManageRouteData$()
  );
  setRequesedAppointmentOptionsData$ = createEffect(() =>
    this._setRequestedAppointmentOptionsRouteData$()
  );
  setCreateAppointmentOptionsData$ = createEffect(() =>
    this._setCreateAppointmentOptionsRouteData$()
  );
  selectAppointment$ = createEffect(() => this._selectAppointment$());
  setSchedulingRules$ = createEffect(() => this._setSchedulingRules$());
  selectPatient$ = createEffect(() => this._selectPatient$());

  private _setAppointmentManageRouteData$(): Observable<Action> {
    return this._actions$.pipe(
      ofRouteMatch(APPOINTMENT_MANAGE_REGEX),
      tap(() => this._schedulingFacade.reset()),
      concatLatestFrom(() => [
        this._patientFacade.currentPatient$,
        this._store.pipe(select(selectRouteParam('uid'))),
      ]),
      concatMap(async ([_action, patient, appointmentUid]) => {
        if (!patient || !appointmentUid) {
          return cancelAction();
        }
        const appointment = await getDoc(
          doc(Appointment.col(patient), appointmentUid)
        );
        return SchedulingActions.setAppointmentManageRouteData(
          serialise({
            appointment,
            patient,
          })
        );
      })
    );
  }

  private _setRequestedAppointmentOptionsRouteData$(): Observable<Action> {
    return this._actions$.pipe(
      ofRouteMatch(APPOINTMENT_MANAGE_REGEX),
      withLatestFrom(
        this._schedulingFacade.brand$,
        this._store.pipe(select(selectQueryParams)),
        this._schedulingFacade.currentAppointment$
      ),
      filter(([_action, _brand, paramsMap, _appointment]) => !!paramsMap),
      map(([_action, brand, paramsMap, appointment]) => {
        try {
          return {
            appointment,
            params: ManageAppointmentQueryParams.unserialise(brand, paramsMap),
          };
        } catch (error) {
          return;
        }
      }),
      filterUndefined(),
      serialise$(),
      map((data) => SchedulingActions.setRequestedAppointmentOption(data))
    );
  }

  private _setCreateAppointmentOptionsRouteData$(): Observable<Action> {
    return this._actions$.pipe(
      ofRouteMatch(APPOINTMENT_CREATE_REGEX),
      withLatestFrom(
        this._schedulingFacade.brand$,
        this._store.pipe(select(selectQueryParams))
      ),
      filter(([_action, _brand, paramsMap]) => !!paramsMap),
      map(([_action, brand, paramsMap]) => {
        try {
          return {
            params: ManageAppointmentQueryParams.unserialise(brand, paramsMap),
          };
        } catch (error) {
          return;
        }
      }),
      filterUndefined(),
      serialise$(),
      map((data) => SchedulingActions.setRequestedAppointmentOption(data))
    );
  }

  private _selectAppointment$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setAppointmentManageRouteData),
      map(({ appointment }) =>
        SchedulingActions.setExistingAppointment({
          appointment,
          options: {
            updateSelectedSuggestion: true,
            updateAppointmentDetails: false,
          },
        })
      )
    );
  }

  private _setSchedulingRules$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setAppointmentManageRouteData),
      unserialise$(),
      concatMap(({ appointment }) =>
        combineLatest([
          Appointment.treatmentPlan$(appointment),
          Appointment.treatmentStep$(appointment),
        ])
      ),
      concatMap(([plan, step]) =>
        TreatmentPlan.getStepAbsoluteSchedulingRules$(plan, step)
      ),
      filterUndefined(),
      map((rules) => {
        const change: Partial<IAppointmentFilterOptions> = {};
        if (rules.minDate) {
          change.fromDate = toMoment(rules.minDate);
        }
        if (rules.maxDate) {
          change.toDate = toMoment(rules.maxDate);
        }

        return AppointmentSelectorActions.updateFilterOptions(
          serialise({
            change,
          })
        );
      })
    );
  }

  private _selectPatient$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setAppointmentManageRouteData),
      map(({ patient }) => PatientDetailsActions.selectPatient({ patient }))
    );
  }
}
