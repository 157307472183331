import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IDemoSpace,
  type IOrganisation,
} from '@principle-theorem/principle-core/interfaces';
import { Region, getEnumValues } from '@principle-theorem/shared';

export type OrganisationFormData = Pick<IOrganisation, 'name' | 'region'>;
export type DemoOrganisationFormData = OrganisationFormData & {
  seedFileName: 'string';
};

@Component({
    selector: 'pr-organisation-add-dialog',
    templateUrl: './organisation-add-dialog.component.html',
    styleUrls: ['./organisation-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationAddDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<
      OrganisationAddDialogComponent,
      Partial<IOrganisation> | undefined
    >
  );

  data = inject<IDemoSpace | undefined>(MAT_DIALOG_DATA);
  regions = getEnumValues(Region);
  form: TypedFormGroup<OrganisationFormData | DemoOrganisationFormData>;

  constructor() {
    this.form = new TypedFormGroup<OrganisationFormData>({
      name: new TypedFormControl<string>('', [Validators.required]),
      region: new TypedFormControl<Region>(undefined, [Validators.required]),
    });

    if (this.data && this.data.fileNames) {
      this.form.addControl(
        'seedFileName',
        new TypedFormControl<string>('', [Validators.required])
      );
    }
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
