import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Appointment, Event } from '@principle-theorem/principle-core';
import {
  IScheduleSummaryEventable,
  isAppointmentRequest,
  isCalendarEvent,
  isCalendarEventSummary,
  type IEvent,
  type IStaffer,
  isAppointmentSummary,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'pr-eventable-header',
  templateUrl: './eventable-header.component.html',
  styleUrls: ['./eventable-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventableHeaderComponent {
  eventable$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  staffer$ = new ReplaySubject<WithRef<IStaffer>>(1);
  event$: Observable<IEvent>;
  hasAlert$: Observable<boolean>;
  canEditEvent$: Observable<boolean>;
  @Output() edit = new EventEmitter<IScheduleSummaryEventable>();

  @Input()
  set eventable(eventable: IScheduleSummaryEventable) {
    this.eventable$.next(eventable);
  }

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      this.staffer$.next(staffer);
    }
  }

  constructor() {
    this.event$ = this.eventable$.pipe(
      map((eventable) => this._toEvent(eventable))
    );
    this.hasAlert$ = this.eventable$.pipe(
      map((eventable) => this._hasAlert(eventable))
    );
    this.initCanEdit();
  }

  initCanEdit(): void {
    this.canEditEvent$ = combineLatest([this.staffer$, this.eventable$]).pipe(
      map(([staffer, event]) => {
        if (!isCalendarEvent(event) || isAppointmentRequest(event)) {
          return false;
        }
        if (event.event.creator && isSameRef(event.event.creator, staffer)) {
          return true;
        }
        return !event.locked;
      }),
      startWith(false)
    );
  }

  editEvent(eventable: IScheduleSummaryEventable): void {
    this.edit.emit(eventable);
  }

  calendarEventTitle(event: IScheduleSummaryEventable): string | undefined {
    if (!isCalendarEventSummary(event)) {
      return;
    }
    return event.metadata.label;
  }

  private _hasAlert(eventable: IScheduleSummaryEventable): boolean {
    if (isAppointmentSummary(eventable)) {
      return Appointment.getSchedulingConflicts(eventable).length > 0;
    }
    return false;
  }

  private _toEvent(eventable: IScheduleSummaryEventable): IEvent {
    return Event.init(eventable.event);
  }
}
