/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { PatientActionsFactoryService } from '@principle-theorem/ng-interactions';
import {
  GlobalStoreService,
  IReasonSelectorValue,
  OrganisationService,
  ScheduleSummaryEventActionsService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  ConnectedDialogConfig,
  DYNAMIC_COMPONENT_DATA,
  DialogPresets,
  DynamicSidebarService,
  IActionButton,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  ClinicalChart,
  IAppointmentDetails,
  IWaitListDetails,
  ScheduleSummary,
  SchedulingEvent,
  SchedulingEventReason,
  Staffer,
  TimezoneResolver,
  TreatmentPlanStepPair,
  TreatmentStep,
  isPatientDetails,
  isRequiredAppointmentDetails,
  staffToNamedDocs,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  ISchedulingEventConditions,
  ISchedulingEventData,
  ISchedulingEventReason,
  SchedulingEventType,
  type IChecklistItem,
  type IEvent,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  Timezone,
  filterUndefined,
  isINamedDocument,
  listToSentence,
  shareReplayCold,
  snapshot,
  type CollectionReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isNil, isString, omitBy } from 'lodash';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { SchedulingScenarioService } from '../../../scheduling-scenario.service';
import { AppointmentAutomationsRescheduleComponent } from '../appointment-automations-reschedule/appointment-automations-reschedule.component';
import { AppointmentSelectorDialogComponent } from '../appointment-selector-dialog/appointment-selector-dialog.component';
import { AppointmentCreateSidebarService } from './appointment-create-sidebar.service';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';

export interface IAppointmentCreateSidebarData {
  saveFn: () => Promise<void>;
}

interface IFormValidationData {
  hasPatient: boolean;
  hasTreatment: boolean;
  hasRescheduleReason: boolean;
}

@Component({
    selector: 'pr-appointment-create-sidebar',
    templateUrl: './appointment-create-sidebar.component.html',
    styleUrls: ['./appointment-create-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentCreateSidebarComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _global = inject(GlobalStoreService);
  private _previousTabIndex: number = 0;
  unscheduledErrorMessage = `Appointment cannot be left unscheduled`;

  practices$: Observable<WithRef<IPractice>[]>;
  practitioner$: Observable<INamedDocument<IStaffer>>;
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  treatmentStepPair$: Observable<TreatmentPlanStepPair | undefined>;
  patientSelectorCtrl = new TypedFormControl<
    WithRef<IPatient> | string | undefined
  >(undefined);

  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;

  appointment$: Observable<WithRef<IAppointment> | undefined>;
  patient$: Observable<WithRef<IPatient> | undefined>;
  patientActions: IActionButton[];

  appointmentDetails$: Observable<IAppointmentDetails>;
  waitlistDetails$: Observable<IWaitListDetails>;
  selectedEvent$: Observable<IEvent | undefined>;

  canSave$: Observable<boolean>;
  saving$ = new BehaviorSubject<boolean>(false);
  appointmentTags = new TypedFormControl<INamedDocument<ITag>[]>();
  tagCol$: Observable<CollectionReference<ITag>>;
  automationReschedule$ =
    new ReplaySubject<AppointmentAutomationsRescheduleComponent>(1);

  isOnWaitList$: Observable<boolean>;
  hasAutomations$ = this.automationReschedule$.pipe(
    switchMap((component) => component.hasAutomations$)
  );
  hasTags$: Observable<boolean>;
  validPatientDetails$: Observable<boolean>;
  checklists$: Observable<IChecklistItem[]>;
  hasChecklistItems$: Observable<boolean>;
  newPatientName$ = new BehaviorSubject<string | undefined>(undefined);
  saveDisabledMessage$: Observable<string | undefined>;

  reasonControl = new TypedFormControl<IReasonSelectorValue | undefined>(
    undefined,
    Validators.required
  );
  timezone$: Observable<Timezone>;
  reasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  schedulingConditions$: Observable<ISchedulingEventConditions>;
  requiresReschedulingReason$: Observable<boolean>;
  tagType = TagType.Appointment;

  readonly dateFormat = DATE_FORMAT;
  readonly timeFormat = TIME_FORMAT;

  @ViewChild('mainTabGroup', { read: MatTabGroup }) mainTabGroup?: MatTabGroup;

  @ViewChild(AppointmentAutomationsRescheduleComponent, { static: false })
  set automationReschedule(
    component: AppointmentAutomationsRescheduleComponent
  ) {
    if (component) {
      this.automationReschedule$.next(component);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _snackBar: MatSnackBar,
    private _dialog: BasicDialogService,
    private _sidebar: DynamicSidebarService,
    private _chart: ChartFacade,
    private _schedulingScenario: SchedulingScenarioService,
    private _appointmentCreateSidebar: AppointmentCreateSidebarService,
    private _scheduleSummaryEvent: ScheduleSummaryEventActionsService,
    actionFactory: PatientActionsFactoryService,
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IAppointmentCreateSidebarData
  ) {
    this.openTime$ = this._schedulingFacade.openTime$;
    this.closeTime$ = this._schedulingFacade.closeTime$;
    this.appointment$ = this._schedulingFacade.currentAppointment$;
    this.patient$ = this._schedulingFacade.selectedPatient$;
    this.appointmentDetails$ = this._schedulingFacade.appointmentDetails$;
    this.waitlistDetails$ = this._schedulingFacade.waitlistDetails$;
    this.selectedEvent$ = this._schedulingFacade.selectedEvent$;
    this.practices$ = this._organisation.practices$;
    this.isOnWaitList$ = this._schedulingFacade.waitlistDetails$.pipe(
      map((waitList) => waitList.addToWaitlist)
    );
    this.hasTags$ = this._schedulingFacade.tags$.pipe(
      map((tags) => tags.length > 0)
    );
    this.hasChecklistItems$ = this._schedulingFacade.checklists$.pipe(
      map((checklists) => checklists.length > 0)
    );
    this.validPatientDetails$ = this._schedulingFacade.patientDetails$.pipe(
      map((details) => isPatientDetails(details))
    );

    this.practitioners$ = combineLatest([
      this.appointmentDetails$.pipe(map((details) => details.practice)),
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([practice, brand]) =>
        practice
          ? Staffer.practitionersByPractice$(practice)
          : Staffer.practitionersByBrand$(brand)
      ),
      staffToNamedDocs()
    );

    this._organisation.practice$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) => this.updateAppointmentDetails({ practice }));

    this.patientSelectorCtrl.valueChanges
      .pipe(
        filter(
          (patient): patient is WithRef<IPatient> =>
            !isNil(patient) && !isString(patient)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => this.selectPatient(patient));

    this.patientSelectorCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((name) => {
        if (isString(name)) {
          this.newPatientName$.next(name);
        }
      });

    this.treatmentStepPair$ = this.appointmentDetails$.pipe(
      map((details) => details.treatment),
      shareReplayCold()
    );

    this.checklists$ = this.treatmentStepPair$.pipe(
      switchMap((treatmentStep) =>
        treatmentStep
          ? TreatmentStep.getTreatmentConfigurationChecklists$(
              treatmentStep.step
            )
          : of([])
      )
    );

    this.tagCol$ = this._schedulingFacade.brand$.pipe(
      map((brand) => Brand.appointmentTagCol(brand))
    );

    this.appointmentTags.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.updateTags(value));

    this._schedulingFacade.currentAppointment$
      .pipe(
        filterUndefined(),
        map((appointment) => appointment.tags),
        takeUntil(this._onDestroy$)
      )
      .subscribe((tags) => {
        this.appointmentTags.setValue(tags, { emitEvent: false });
      });

    this.practitioner$ = this.appointmentDetails$.pipe(
      map((details) =>
        isINamedDocument<IStaffer>(details.practitioner)
          ? details.practitioner
          : undefined
      ),
      filterUndefined()
    );

    this.practitioner$
      .pipe(
        switchMap((practitioner) => this._global.getStaffer$(practitioner.ref)),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((practitioner) =>
        this._chart.setChartingAs(ChartId.InAppointment, practitioner)
      );

    this.patient$
      .pipe(
        filterUndefined(),
        switchMap((patient) =>
          ClinicalChart.getLatestChart$({
            ref: patient.ref,
          })
        ),
        map((chart) => chart ?? ClinicalChart.init()),
        takeUntil(this._onDestroy$)
      )
      .subscribe((chart) =>
        this._chart.loadChartSuccess(ChartId.InAppointment, chart)
      );

    this._appointmentCreateSidebar.triggerPatientSave$
      .pipe(
        filter((save) => save),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => void this._savePatient());

    this.timezone$ = this._schedulingFacade.brand$.pipe(
      switchMap((brand) => TimezoneResolver.fromBrandRef(brand.ref))
    );

    this.schedulingConditions$ = combineLatest([
      this.timezone$,
      this.appointment$,
      this.selectedEvent$,
    ]).pipe(
      map(([timezone, appointment, selectedEvent]) =>
        SchedulingEvent.getSchedulingConditions(
          timezone,
          appointment?.event?.from,
          selectedEvent?.from
        )
      )
    );
    this.requiresReschedulingReason$ = this.schedulingConditions$.pipe(
      map((conditions) => conditions.eventType !== SchedulingEventType.Schedule)
    );

    const reasons$ = this._schedulingFacade.brand$.pipe(
      switchMap((brand) => Brand.cancellationReasons$(brand))
    );
    this.reasons$ = combineLatest([reasons$, this.schedulingConditions$]).pipe(
      map(([reasons, conditions]) =>
        SchedulingEventReason.filterByEventType(reasons, conditions.eventType)
      )
    );

    const hasPatientDetails$ = this._schedulingFacade.patientDetails$.pipe(
      map((details) => isPatientDetails(details))
    );
    const hasAppointmentDetails$ = this.appointmentDetails$.pipe(
      map((details) => isRequiredAppointmentDetails(details))
    );
    const hasRescheduleReason$ = combineLatest([
      this.requiresReschedulingReason$,
      formControlChanges$(this.reasonControl),
    ]).pipe(
      map(([requiresReschedulingReason]) =>
        requiresReschedulingReason ? this.reasonControl.valid : true
      )
    );

    const formValidationData$ = combineLatest([
      hasPatientDetails$,
      hasAppointmentDetails$,
      hasRescheduleReason$,
    ]).pipe(
      map(([hasPatient, hasTreatment, hasRescheduleReason]) => ({
        hasPatient,
        hasTreatment,
        hasRescheduleReason,
      }))
    );

    this.saveDisabledMessage$ = formValidationData$.pipe(
      debounceTime(500),
      map(({ hasPatient, hasTreatment, hasRescheduleReason }) => {
        const missingPatient = !hasPatient ? 'patient details' : undefined;
        const missingTreatment = !hasTreatment
          ? 'treatment details'
          : undefined;
        const missingRescheduleReason = !hasRescheduleReason
          ? 'reschedule reason'
          : undefined;
        const missing = compact([
          missingPatient,
          missingTreatment,
          missingRescheduleReason,
        ]);
        return missing.length
          ? `Missing ${listToSentence(missing)}`
          : undefined;
      })
    );

    const actionPatient$ = this.patient$.pipe(filterUndefined());
    this.patientActions = [
      actionFactory.sms(actionPatient$),
      actionFactory.call(actionPatient$),
      actionFactory.email(actionPatient$),
    ];

    this.canSave$ = this._getCanSave$(
      this._schedulingFacade.savingAppointment$,
      formValidationData$
    );
  }

  ngOnDestroy(): void {
    this._schedulingFacade.reset();
    this._appointmentCreateSidebar.reset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  selectPatient(patient: WithRef<IPatient>): void {
    this._schedulingFacade.selectPatient(patient);
  }

  clearPatient(): void {
    const patientName = this.patientSelectorCtrl.value;
    this.patientSelectorCtrl.reset();
    if (isString(patientName)) {
      this.newPatientName$.next(patientName);
    }
    this._schedulingFacade.clearPatient();
  }

  updateAppointmentDetails(details: Partial<IAppointmentDetails>): void {
    details = omitBy(details, isNil);
    this._schedulingFacade.appointmentFormChange(details);
  }

  updateWaitList(details: IWaitListDetails): void {
    this._schedulingFacade.updateWaitListDetails(details);
  }

  updateChecklists(checklists: IChecklistItem[]): void {
    this._schedulingFacade.updateChecklists(checklists);
  }

  updateTags(tags: INamedDocument<ITag>[]): void {
    this._schedulingFacade.updateTags(tags);
  }

  onTabChange(event: MatTabChangeEvent): void {
    const patientTabIndex = 2;
    const currentTabIndex = event.index;

    if (
      this._previousTabIndex === patientTabIndex &&
      this._previousTabIndex !== currentTabIndex
    ) {
      this._schedulingFacade.savePatient();
      this._appointmentCreateSidebar.clearPatientDetailsChanges();
    }
    this._previousTabIndex = currentTabIndex;
  }

  async save(): Promise<void> {
    const canSave = await snapshot(this.canSave$);
    if (!canSave) {
      return;
    }
    this.saving$.next(true);
    await this._savePatient();
    await this._saveAppointment();
    await this._data.saveFn();
    this.saving$.next(false);
  }

  showAppointmentOptions(connectedTo: HTMLElement): void {
    this._dialog.connected(
      AppointmentSelectorDialogComponent,
      this._getAppointmentOptionsDialogConfig(connectedTo)
    );
  }

  createNewPatient(): void {
    if (!this.mainTabGroup) {
      return;
    }
    this.clearPatient();
    this.mainTabGroup.selectedIndex = 2;
  }

  private _openUnscheduledMessage(): void {
    this._snackBar.open(this.unscheduledErrorMessage, '', {
      duration: 5000,
    });
  }

  private _getAppointmentOptionsDialogConfig(
    connectedTo: HTMLElement
  ): ConnectedDialogConfig {
    return {
      ...DialogPresets.large(),
      connectedTo: new ElementRef(connectedTo),
      positions: [
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 100,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 100,
        },
      ],
    };
  }

  private async _savePatient(): Promise<void> {
    this._schedulingFacade.savePatient();
    await this._schedulingFacade.savingPatient$
      .pipe(
        filter((saving) => !saving),
        take(1)
      )
      .toPromise();
  }

  private async _saveAppointment(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const selectedEvent = await snapshot(this._schedulingFacade.selectedEvent$);
    const appointmentDetails = await snapshot(
      this._schedulingFacade.appointmentDetails$
    );
    const treatmentCategory = appointmentDetails.treatment
      ? TreatmentStep.defaultDisplayRef(
          appointmentDetails.treatment.step.display
        )
      : undefined;

    if (selectedEvent) {
      const isBlockedByDoubleBooking =
        await this._schedulingScenario.isBlockedByDoubleBooking(
          {
            event: selectedEvent,
            ref: appointment?.ref,
          },
          treatmentCategory
        );
      if (isBlockedByDoubleBooking) {
        return;
      }
    }

    const automationRescheduleComponent = await snapshot(
      this.automationReschedule$
    );
    await automationRescheduleComponent.update();

    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingEventData: ISchedulingEventData = {
      scheduledByPractice: practice.ref,
      reasonSetManually: false,
      schedulingConditions: await snapshot(this.schedulingConditions$),
    };

    if (!appointment) {
      this._schedulingFacade.saveNewAppointment(schedulingEventData);
      this._sidebar.close(true);
      return;
    }

    const event = await snapshot(this.selectedEvent$);
    if (!event) {
      this._openUnscheduledMessage();
      return;
    }

    const summary = await ScheduleSummary.getSummaryFromEventable({
      ...appointment,
      event,
      ref: appointment.ref,
    });
    this._scheduleSummaryEvent.setSelectedSummary(summary);

    const reasonFormData = this.reasonControl.value;

    this._schedulingFacade.rescheduleAppointment({
      ...schedulingEventData,
      reason: reasonFormData?.reason ?? undefined,
      reasonSetManually: reasonFormData?.reasonSetManually ?? false,
    });
    this._sidebar.close(true);
  }

  private _getCanSave$(
    savingAppointment$: Observable<boolean>,
    formValidationData$: Observable<IFormValidationData>
  ): Observable<boolean> {
    return combineLatest([savingAppointment$, formValidationData$]).pipe(
      map(
        ([saving, validation]) =>
          !saving &&
          validation.hasPatient &&
          validation.hasTreatment &&
          validation.hasRescheduleReason
      )
    );
  }
}
