import { rand, randWord } from '@ngneat/falso';
import { IMentionResource } from '@principle-theorem/editor';
import {
  IPrincipleMention,
  MentionResourceType,
  MENTION_RESOURCE_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMock, Properties } from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';

export class MentionMock
  extends BaseMock
  implements Properties<IPrincipleMention>
{
  get uid(): string {
    return uuid();
  }

  get key(): string {
    return randWord();
  }

  get resource(): IMentionResource<MentionResourceType> {
    return {
      uid: uuid(),
      ref: MockDocRef(),
      type: rand(MENTION_RESOURCE_TYPES),
    };
  }
}
