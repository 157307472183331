import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import { TypedFormControl } from '@principle-theorem/ng-shared';

export interface IChangeBasePriceDialogData {
  basePrice: number;
}

@Component({
  selector: 'pr-change-base-price-dialog',
  templateUrl: './change-base-price-dialog.component.html',
  styleUrls: ['./change-base-price-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeBasePriceDialogComponent {
  basePriceControl = new TypedFormControl<number>(0);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChangeBasePriceDialogData,
    private _dialogRef: MatDialogRef<
      ChangeBasePriceDialogComponent,
      IChangeBasePriceDialogData
    >
  ) {
    this.basePriceControl.setValue(data.basePrice);
  }

  submit(): void {
    this._dialogRef.close({
      basePrice: roundTo2Decimals(this.basePriceControl.value),
    });
  }
}
