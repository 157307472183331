import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PractitionerIncomeReportFacade } from '../store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../store/practitioner-income.reducers';

interface IReportType {
  label: string;
  value: PractitionerIncomeReportType;
}

@Component({
  selector: 'pr-practitioner-income-report-type-selector',
  templateUrl: './practitioner-income-report-type-selector.component.html',
  styleUrls: ['./practitioner-income-report-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerIncomeReportTypeSelectorComponent
  implements OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  trackByReportType = TrackByFunctions.field<IReportType>('value');
  reportTypes: IReportType[] = [
    {
      label: 'By Invoice Issued Date',
      value: PractitionerIncomeReportType.ByInvoiceIssuedDate,
    },
    {
      label: 'By Invoice Paid Date',
      value: PractitionerIncomeReportType.ByInvoicePaidDate,
    },
    {
      label: 'By Transaction Date',
      value: PractitionerIncomeReportType.ByTransactionDate,
    },
  ];
  reportTypeControl = new TypedFormControl<PractitionerIncomeReportType>(
    PractitionerIncomeReportType.ByTransactionDate
  );

  constructor(private _reportFacade: PractitionerIncomeReportFacade) {
    this._reportFacade.reportType$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((reportType) => this.reportTypeControl.setValue(reportType));
    validFormControlChanges$(this.reportTypeControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((reportType) => this._reportFacade.setReportType(reportType));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
