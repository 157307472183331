import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  ChartedCondition,
  ChartedSurface,
  ClinicalChart,
  ClinicalNote,
  ConditionConfiguration,
  ConditionConfigurationName,
  FeeSchedule,
  MockAllTeeth,
  Tooth,
  TreatmentConfiguration,
  TreatmentPlanProposal,
  getQuadrant,
  getQuadrantIndex,
  stafferToNamedDoc,
  type ExistingTreatmentConfigurationName,
} from '@principle-theorem/principle-core';
import {
  ITranslationMap,
  PerioMeasurement,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IChartedCondition,
  type IChartedTreatment,
  type IClinicalChart,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPerioRecord,
  type IPracticeMigration,
  type IStaffer,
  type ITooth,
  type IToothRef,
  type ITreatmentConfiguration,
  type ToothNumber,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  find$,
  getDoc$,
  getError,
  reduceToSingleArrayFn,
  snapshot,
  sortTimestamp,
  toISODate,
  toInt,
  toNamedDocument,
  toTimestamp,
  where,
  type DocumentReference,
  type IIdentifiable,
  type INamedDocument,
  type Timestamp,
  type WithRef,
  snapshotCombineLatest,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientClinicalChartDestinationEntity,
  IBaseClinicalChartJobData,
  IClinicalChartMigrationData,
  IGeneratedCharts,
  PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
} from '../../../destination/entities/patient-clinical-charts';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PRAKTIKA_CONDITION_MAP,
  PRAKTIKA_CONDITION_PRINCIPLE_MAP,
  PraktikaCondition,
} from '../../source/entities/lib/conditions';
import {
  PraktikaToothType,
  type IPraktikaPatientClinicalChart,
} from '../../source/entities/lib/patient-clinical-chart';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
} from '../../source/entities/patient';
import { PatientClinicalNoteSourceEntity } from '../../source/entities/patient-clinical-notes';
import {
  IPraktikaToothDentition,
  PatientToothDentitionSourceEntity,
} from '../../source/entities/patient-dentitions';
import { PATIENT_PERIODONTAL_RESOURCE_TYPE } from '../../source/entities/patient-periodontal-chart';
import {
  PatientPeriodontalDataSourceEntity,
  type IPraktikaPerioToothData,
  type IPraktikaPerioToothDataFilters,
  type IPraktikaPerioToothDataTranslations,
} from '../../source/entities/patient-periodontal-chart-data';
import {
  PatientToothConditionSourceEntity,
  type IPraktikaToothCondition,
  type IPraktikaToothConditionTranslations,
} from '../../source/entities/patient-tooth-conditions';
import { PraktikaItemCodeMappingHandler } from '../mappings/item-code';
import { PraktikaItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import {
  getChartedRefs,
  getChartedTreatments,
} from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { TaxRate, getTaxRateByRegion } from '@principle-theorem/accounting';

export const PATIENT_CLINICAL_CHART_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
      label: 'Patient Clinical Charts',
      description: `The conversion will be done as follows:
        Each Periodontal Chart will be added as a separate clinical chart.

        A "latest" chart will be added to include all:
          - Dentition Records
          - Clinical Notes
          - Conditions
          - Flagged Treatments

        This acts as the baseline chart going forward in Principle. All of the most latest information will be on this chart. The dates for when notes, conditions, and treatments were added will be mirrored.

        Some things to be aware of:

        If no practitioner is available for the following, the preferred practitioner for the patient has been used:
          - Conditions
          - Flagged Treatments
          - Clinical Notes of Conditions and Flagged Treatments

        The author is correct of all normal Clinical Notes.
      `,
    },
  }
);

interface IPerioChartData {
  id: string;
  date: Timestamp;
  patientId: string;
  practitionerId: string;
  data: IGetRecordResponse<
    IPraktikaPerioToothData,
    IPraktikaPerioToothDataTranslations
  >[];
}

export interface IClinicalChartJobData
  extends IBaseClinicalChartJobData<IPraktikaPatient> {
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  taxRate: TaxRate;
}

export class PatientClinicalChartDestinationEntity extends BasePatientClinicalChartDestinationEntity<
  IPraktikaPatient,
  IClinicalChartJobData
> {
  periodontalResourceType = PATIENT_PERIODONTAL_RESOURCE_TYPE;
  destinationEntity = PATIENT_CLINICAL_CHART_DESTINATION_ENTITY;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    clinicalNotes: new PatientClinicalNoteSourceEntity(),
    periodontalChart: new PatientPeriodontalDataSourceEntity(),
    toothConditions: new PatientToothConditionSourceEntity(),
    patientDentitions: new PatientToothDentitionSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
    itemCodeToTreatment: new PraktikaItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IClinicalChartJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        defaultTreatmentConfiguration$,
        treatmentConfigurations$,
        sourceItemCodes$,
        treatmentConfigurationMappings$,
        taxRate$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            staff,
            sourcePatient,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<
    | IClinicalChartMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const providerId =
      data.sourcePatient.data.data.patient_preferredproviderid?.toString();

    const practitionerMap = getPractitionerOrDefaultMapping(
      providerId,
      data.staff
    )?.destinationIdentifier;

    if (!practitionerMap) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve practitioner ${
          data.sourcePatient.data.data.patient_preferredproviderid ?? ''
        }`
      );
    }

    try {
      const charts = await this._buildClinicalChartData(
        migration,
        translationMap,
        practitionerMap,
        data.sourcePatient.data.data.patient_id.toString(),
        data
      );

      return {
        sourcePatientId,
        patientRef,
        charts,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private async _buildClinicalChartData(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    practitionerRef: DocumentReference<IStaffer>,
    patientUid: string,
    data: IClinicalChartJobData
  ): Promise<IGeneratedCharts> {
    const practitioner = await Firestore.getDoc(practitionerRef);

    const sourcePerioChartItems =
      await this.sourceEntities.periodontalChart.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const perioClinicalCharts = await buildPerioCharts(
      sourcePerioChartItems,
      data.staff
    );

    const notes = await this.sourceEntities.clinicalNotes.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const conditions = await this.sourceEntities.toothConditions.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const dentitions =
      await this.sourceEntities.patientDentitions.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const clinicalNoteDates = notes.map(
      (note) => note.data.translations.dateCreated
    );

    const clinicalCharts = await buildClinicalCharts(
      practitioner,
      migration,
      translationMap,
      conditions,
      data,
      procedures,
      clinicalNoteDates,
      dentitions.map((dentition) => dentition.data.data)
    );

    return {
      clinicalCharts: compact(clinicalCharts),
      perioCharts: compact(perioClinicalCharts),
    };
  }
}

async function buildPerioCharts(
  sourcePerioChartItems: IGetRecordResponse<
    IPraktikaPerioToothData,
    IPraktikaPerioToothDataTranslations,
    IPraktikaPerioToothDataFilters
  >[],
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<(IClinicalChart & IIdentifiable)[]> {
  const perioCharts: IPerioChartData[] = [];

  sourcePerioChartItems.map((sourcePerioChartItem) => {
    const perioChartFound = perioCharts.find(
      (perioChart) =>
        perioChart.id === sourcePerioChartItem.data.data.exam_id.toString()
    );
    if (perioChartFound) {
      perioChartFound.data.push(sourcePerioChartItem);
      return;
    }
    perioCharts.push({
      id: sourcePerioChartItem.data.data.exam_id.toString(),
      date: sourcePerioChartItem.data.translations.date,
      patientId: sourcePerioChartItem.record.filters.patientId,
      practitionerId: sourcePerioChartItem.record.filters.stafferId,
      data: [sourcePerioChartItem],
    });
  });

  return asyncForEach(perioCharts, async (perioChart) => {
    const createdAt = perioChart.date;
    const perioRecords: IPerioRecord[] = compact(
      perioChart.data.map((perioChartData) =>
        buildPerioRecord(perioChartData.data.data)
      )
    );

    const createdBy = await getPerioPractitioner(
      perioChart.practitionerId,
      staff
    );

    return {
      ...ClinicalChart.init({
        perioRecords,
        teeth: buildTeeth([]),
        createdAt,
        createdBy,
        immutable: true,
      }),
      uid: perioChart.id,
    };
  });
}

async function getPerioPractitioner(
  practitionerId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer>> {
  const creatorMap = staff.find(
    (staffer) => staffer.sourceIdentifier === practitionerId
  );

  if (!creatorMap || !creatorMap.destinationIdentifier) {
    throw new Error(`Couldn't find staffer with id ${practitionerId}`);
  }

  const creator = await Firestore.getDoc(creatorMap.destinationIdentifier);
  return stafferToNamedDoc(creator);
}

async function buildClinicalCharts(
  practitioner: WithRef<IStaffer>,
  migration: WithRef<IPracticeMigration>,
  translationMap: TranslationMapHandler,
  sourceConditions: IGetRecordResponse<
    IPraktikaToothCondition,
    IPraktikaToothConditionTranslations
  >[],
  data: IClinicalChartJobData,
  procedures: IGetRecordResponse<
    IPraktikaAppointmentProcedure,
    IPraktikaAppointmentProcedureTranslations,
    IPraktikaAppointmentProcedureFilters
  >[],
  clinicalNoteDates: Timestamp[],
  dentitions: IPraktikaToothDentition[]
): Promise<(IClinicalChart & IIdentifiable)[]> {
  const brand = await snapshot(PracticeMigration.brand$(migration));
  const conditions = [];
  conditions.push(
    ...[
      ...(await PraktikaToothBuilders.buildMissingTeeth(
        dentitions,
        practitioner,
        brand
      )),
      ...(await buildConditions(sourceConditions, practitioner, brand)),
    ]
  );

  const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
    migration.configuration.organisation
  );
  const treatments = await getChartedTreatments(
    procedures
      .filter((procedure) => procedure.record.filters.isFlagged)
      .map((procedure) => procedure.data.data),
    practitioner,
    resolveFeeSchedule(translationMap, defaultFeeSchedule),
    data,
    migration.configuration.timezone
  );
  const flaggedTreatment = TreatmentPlanProposal.init({
    treatments,
  });
  const createdAt = determineCreatedAt(
    conditions,
    treatments,
    clinicalNoteDates
  );

  return [
    {
      uid: data.sourcePatient.record.uid,
      ...ClinicalChart.init({
        conditions,
        teeth: buildTeeth([]),
        flaggedTreatment,
        createdAt,
        createdBy: stafferToNamedDoc(practitioner),
      }),
    },
  ];
}

function determineCreatedAt(
  conditions: IChartedCondition[],
  treatments: IChartedTreatment[],
  clinicalNoteDates: Timestamp[]
): Timestamp | undefined {
  const timestamps = [
    ...conditions
      .map((condition) =>
        condition.chartedSurfaces.map(
          (chartedSurface) => chartedSurface.chartedAt
        )
      )
      .reduce(reduceToSingleArrayFn, []),
    ...treatments
      .map((treatment) =>
        treatment.chartedSurfaces.map(
          (chartedSurface) => chartedSurface.chartedAt
        )
      )
      .reduce(reduceToSingleArrayFn, []),
    ...clinicalNoteDates.map((clinicalNoteDate) => clinicalNoteDate),
  ];

  return first(timestamps.sort(sortTimestamp));
}

async function buildConditions(
  conditions: IGetRecordResponse<
    IPraktikaToothCondition,
    IPraktikaToothConditionTranslations
  >[],
  practitioner: WithRef<IStaffer>,
  brand: WithRef<IBrand>
): Promise<IChartedCondition[]> {
  return asyncForEach(conditions, async (conditionRecord) => {
    const condition = conditionRecord.data.data;
    const examDate = conditionRecord.data.translations.createdDate;
    const resolvedCondition =
      PRAKTIKA_CONDITION_PRINCIPLE_MAP[
        toInt(condition.type_id) as PraktikaCondition
      ];

    const principleCondition = await snapshot(
      find$(
        ConditionConfiguration.col(brand),
        where(
          'name',
          '==',
          resolvedCondition ?? ConditionConfigurationName.Other
        )
      )
    );

    if (!principleCondition) {
      throw new Error(
        `No condition could be resolved for type Id: ${condition.type_id}, "${
          PRAKTIKA_CONDITION_MAP[condition.type_id]
        }" using resolved condition: ${resolvedCondition}`
      );
    }

    return ChartedCondition.init({
      config: toNamedDocument(principleCondition),
      chartedSurfaces: getChartedRefs({
        toothNumber: condition.tooth_number.toString() as ToothNumber,
        surfaces: condition.surfaces,
      }).map((chartedRef) =>
        ChartedSurface.init({
          chartedBy: stafferToNamedDoc(practitioner),
          chartedAt: examDate,
          chartedRef,
        })
      ),
      notes: [
        ClinicalNote.init({
          owner: stafferToNamedDoc(practitioner),
          content: initVersionedSchema(
            PRAKTIKA_CONDITION_MAP[condition.type_id]
          ),
          createdAt: examDate,
          recordDate: toISODate(examDate),
        }),
      ],
    });
  });
}

export class PraktikaToothBuilders {
  static async buildMissingTeeth(
    dentitions: IPraktikaToothDentition[],
    practitioner: WithRef<IStaffer>,
    brand: WithRef<IBrand>
  ): Promise<IChartedCondition[]> {
    return buildConditionFromTeeth(
      dentitions,
      practitioner,
      brand,
      PraktikaToothType.Missing,
      ConditionConfigurationName.MissingTooth
    );
  }

  static async buildImplantTeeth(
    dentitions: IPraktikaToothDentition[],
    practitioner: WithRef<IStaffer>,
    brand: WithRef<IBrand>
  ): Promise<IChartedCondition[]> {
    return buildConditionFromTeeth(
      dentitions,
      practitioner,
      brand,
      PraktikaToothType.Implant,
      ConditionConfigurationName.ImplantCrown
    );
  }

  static async buildPonticTeeth(
    dentitions: IPraktikaToothDentition[],
    practitioner: WithRef<IStaffer>,
    brand: WithRef<IBrand>
  ): Promise<IChartedCondition[]> {
    return buildConditionFromTeeth(
      dentitions,
      practitioner,
      brand,
      PraktikaToothType.Pontic,
      ConditionConfigurationName.BridgePontic
    );
  }

  static async buildDentureTeeth(
    dentitions: IPraktikaToothDentition[],
    practitioner: WithRef<IStaffer>,
    brand: WithRef<IBrand>
  ): Promise<IChartedCondition[]> {
    return buildConditionFromTeeth(
      dentitions,
      practitioner,
      brand,
      PraktikaToothType.Denture,
      ConditionConfigurationName.Denture
    );
  }

  static async buildImplantDentureTeeth(
    dentitions: IPraktikaToothDentition[],
    practitioner: WithRef<IStaffer>,
    brand: WithRef<IBrand>
  ): Promise<IChartedCondition[]> {
    return buildConditionFromTeeth(
      dentitions,
      practitioner,
      brand,
      PraktikaToothType.ImplantDenture,
      ConditionConfigurationName.Denture
    );
  }
}

async function buildConditionFromTeeth(
  dentitions: IPraktikaToothDentition[],
  practitioner: WithRef<IStaffer>,
  brand: WithRef<IBrand>,
  toothType: PraktikaToothType,
  configurationName:
    | ConditionConfigurationName
    | ExistingTreatmentConfigurationName
): Promise<IChartedCondition[]> {
  const missingToothCondition = await snapshot(
    find$(
      ConditionConfiguration.col(brand),
      where('name', '==', configurationName)
    )
  );

  if (!missingToothCondition) {
    throw new Error(`Missing tooth condition is missing`);
  }

  return compact(
    dentitions.map((tooth) => {
      if (tooth.type_id === toothType) {
        return {
          ...ChartedCondition.fromConfig(missingToothCondition),
          chartedSurfaces: getChartedRefs({
            toothNumber: tooth.tooth_number.toString() as ToothNumber,
          }).map((chartedRef) =>
            ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              chartedAt: toTimestamp(),
              chartedRef,
            })
          ),
        };
      }
    })
  );
}

function buildToothRef(toothNumber: ToothNumber | null): IToothRef | undefined {
  const quadrant = toothNumber ? getQuadrant(toothNumber) : undefined;
  const quadrantIndex = toothNumber ? getQuadrantIndex(toothNumber) : undefined;

  if (!quadrant || !quadrantIndex) {
    return;
  }
  return {
    quadrant,
    quadrantIndex,
  };
}

function buildTeeth(dentition: IPraktikaPatientClinicalChart[]): ITooth[] {
  if (!dentition.length) {
    return MockAllTeeth();
  }

  return compact(
    dentition.map((tooth) => {
      const toothRef = buildToothRef(
        tooth.toothNumber.toString() as ToothNumber
      );
      if (!toothRef) {
        return;
      }

      return Tooth.init({
        toothRef,
      });
    })
  );
}

function buildPerioRecord(
  toothData: IPraktikaPerioToothData
): IPerioRecord | undefined {
  const toothRef = buildToothRef(
    toothData.tooth_number.toString() as ToothNumber
  );
  if (!toothRef) {
    return;
  }
  return {
    toothRef,
    data: {
      [PerioMeasurement.Mobility]: toothData.mobility ?? undefined,
      [PerioMeasurement.Recession]: {
        facialMesial: toothData.rec_b_m ?? undefined,
        facialCentral: toothData.rec_b_c ?? undefined,
        facialDistal: toothData.rec_b_d ?? undefined,
        palatalMesial: toothData.rec_l_m ?? undefined,
        palatalCentral: toothData.rec_l_c ?? undefined,
        palatalDistal: toothData.rec_l_d ?? undefined,
      },
      [PerioMeasurement.Pocket]: {
        facialMesial: toothData.prd_b_m ?? undefined,
        facialCentral: toothData.prd_b_c ?? undefined,
        facialDistal: toothData.prd_b_d ?? undefined,
        palatalMesial: toothData.prd_l_m ?? undefined,
        palatalCentral: toothData.prd_l_c ?? undefined,
        palatalDistal: toothData.prd_l_d ?? undefined,
      },
      [PerioMeasurement.Bleeding]: {
        facialMesial: toothData.bld_b_m ?? undefined,
        facialCentral: toothData.bld_b_c ?? undefined,
        facialDistal: toothData.bld_b_d ?? undefined,
        palatalMesial: toothData.bld_l_d ?? undefined,
        palatalCentral: toothData.bld_l_c ?? undefined,
        palatalDistal: toothData.bld_l_m ?? undefined,
      },
      [PerioMeasurement.Furcation]: {
        facialMesial: toothData.fur_b_m ?? undefined,
        facialCentral: toothData.fur_b_c ?? undefined,
        facialDistal: toothData.fur_b_d ?? undefined,
        palatalMesial: toothData.fur_l_m ?? undefined,
        palatalCentral: toothData.fur_l_c ?? undefined,
        palatalDistal: toothData.fur_l_d ?? undefined,
      },
      [PerioMeasurement.Suppuration]: {
        facialMesial: toothData.is_sup_l_m ? 1 : 0,
        facialCentral: toothData.is_sup_l_c ? 1 : 0,
        facialDistal: toothData.is_sup_l_d ? 1 : 0,
        palatalMesial: toothData.is_sup_b_m ? 1 : 0,
        palatalCentral: toothData.is_sup_b_c ? 1 : 0,
        palatalDistal: toothData.is_sup_b_d ? 1 : 0,
      },
    },
  };
}
