import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

type ImageLabel =
  | 'appointment'
  | 'broken'
  | 'conversation'
  | 'list'
  | 'message'
  | 'notification'
  | 'search'
  | 'task';

export const IMAGE_MAP: Record<ImageLabel, string> = {
  appointment: '/assets/empty-states/empty-appointments.png',
  broken: '/assets/empty-states/empty-broken.png',
  conversation: '/assets/empty-states/no-conversation-selected.png',
  list: '/assets/empty-states/empty-list.png',
  message: '/assets/empty-states/empty-messages.png',
  notification: '/assets/empty-states/empty-notification.png',
  search: '/assets/empty-states/empty-search.png',
  task: '/assets/empty-states/empty-task.png',
};

@Component({
  selector: 'pr-empty-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  image$ = new ReplaySubject<ImageLabel>(1);
  isSearch$ = new BehaviorSubject<boolean>(false);
  imagePath$: Observable<string>;
  @Input() title: string;
  @Input() type: 'image' | 'text' = 'image';
  @Input() searchInputValue: string;
  @Input() descriptionEnabled = true;

  @Input()
  set image(image: ImageLabel) {
    if (image) {
      this.image$.next(image);
    }
    if (image === 'search') {
      this.isSearch$.next(true);
    }
  }

  constructor() {
    this.imagePath$ = this.image$.pipe(map((img) => IMAGE_MAP[img]));
  }
}
