import { randBoolean, randNumber } from '@ngneat/falso';
import { PATIENTS } from '@principle-theorem/principle-core';
import { type IPatientStats } from '@principle-theorem/reporting';
import * as moment from 'moment-timezone';
import { PatientStatistics } from './patient-statistics';
import { StatisticsMocker } from './statistics-mocker';

export class PatientStatisticsMocker extends StatisticsMocker<
  IPatientStats,
  PatientStatistics
> {
  constructor() {
    super(PATIENTS);
  }

  mockStatistic(
    name: string,
    date: moment.Moment,
    dates: moment.Moment[]
  ): PatientStatistics {
    const metrics = new PatientStatistics();
    metrics.name = name;
    metrics.date = moment(date);
    this._mockStatistics(metrics.stats, metrics.date, dates);
    return metrics;
  }

  private _mockStatistics(
    stats: IPatientStats,
    date: moment.Moment,
    dates: moment.Moment[]
  ): void {
    stats.totalNumberOfAppointments.metric = randNumber({ max: 100 });
    stats.totalNumberOfCancelledAppointments.metric = randNumber({ max: 50 });
    stats.totalNumberOfRebookedAppointments.metric = randNumber({ max: 50 });
    stats.totalValueOfTreatmentsApplied.metric = randNumber({ max: 300 });
    stats.totalPatientSpend.metric = randNumber({
      min: 800,
      max: 18000,
    });
    stats.incompleteTreatments.metric = randNumber({ max: 50 });

    // Demographics
    stats.age.metric = randNumber({ min: 12, max: 100 });
    stats.distance.metric = randNumber({ max: 80 });

    // Gender
    const isMale: boolean = randBoolean();
    stats.isMale.metric = this._setOnce(isMale ? 1 : 0, date, dates);
    stats.isFemale.metric = this._setOnce(!isMale ? 1 : 0, date, dates);

    // New / Existing
    const isNew: boolean = Math.random() > 0.9;
    stats.isNew.metric = this._setOnce(isNew ? 1 : 0, date, dates);
    stats.isExisting.metric = this._setOnce(!isNew ? 1 : 0, date, dates);
  }
}
