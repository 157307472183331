<mat-list fxFill>
  <mat-list-item
    class="mat-mdc-list-item-interactive"
    *ngFor="let item of checklist$ | async; trackBy: trackByItem"
    (click)="updateSelected(item)"
  >
    <div fxFlex="80">
      <mat-checkbox color="primary" [checked]="item.done">
        <div class="title" fxFlex="70" fxLayout="column">
          <span class="title">{{ item.title }}</span>
        </div>
      </mat-checkbox>
    </div>
    <ng-container *ptFeatureEnabled="'appointments.checklists.actions'">
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="end center"
        *ngIf="item.action !== 'none'"
      >
        <button mat-icon-button (click)="doAction($event, item)">
          <mat-icon>{{ item.action }}</mat-icon>
        </button>
      </div>
    </ng-container>
  </mat-list-item>
</mat-list>
