import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SMSComponent } from '../../sms/sms.component';
import { InteractionWithTemplatesService } from './interaction-with-templates.service';

@Injectable()
export class SmsService extends InteractionWithTemplatesService<SMSComponent> {
  override icon = 'sms';
  override name = 'SMS';
  override dialogComponent: ComponentType<SMSComponent> = SMSComponent;
}
