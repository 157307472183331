import { type UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import {
  type IEntityAction,
  getErrorAction,
  getSelectAction,
} from '@principle-theorem/ng-shared';
import {
  type IChartedTreatment,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

export enum TreatmentStepActions {
  LoadTreatmentSteps = '[TreatmentSteps] Load',
  LoadTreatmentStepsSuccess = '[TreatmentSteps] Load Success',
  LoadTreatmentStepsError = '[TreatmentSteps] Load Error',
  LoadTreatmentStepSuccess = '[TreatmentSteps] Load Treatment Step Success',
  UpdateTreatmentStep = '[TreatmentSteps] Update Treatment Step',
  SelectTreatmentStep = '[TreatmentSteps] Select Treatment Step',
  ClearSelectedTreatmentStep = '[TreatmentSteps] Clear Selected Step',
  AddTreatment = '[TreatmentSteps] Add Treatment',
  UpdateTreatment = '[TreatmentSteps] Update Treatment',
}

export const loadTreatmentSteps = createAction(
  TreatmentStepActions.LoadTreatmentSteps
);

interface ILoadTreatmentStepsSuccess {
  treatmentSteps: WithRef<ITreatmentStep>[];
}

export const loadTreatmentStepsSuccess = createAction(
  TreatmentStepActions.LoadTreatmentStepsSuccess,
  props<SerialisedData<ILoadTreatmentStepsSuccess>>()
);

export const loadTreatmentStepsError = getErrorAction(
  TreatmentStepActions.LoadTreatmentStepsError
);

export interface ILoadTreatmentStepSuccess {
  step: WithRef<ITreatmentStep>;
}

export const loadTreatmentStepSuccess = createAction(
  TreatmentStepActions.LoadTreatmentStepSuccess,
  props<SerialisedData<ILoadTreatmentStepSuccess>>()
);

export interface IUpdateTreatmentStep {
  update: UpdateStr<WithRef<ITreatmentStep>>;
}

export const updateTreatmentStep = createAction(
  TreatmentStepActions.UpdateTreatmentStep,
  props<SerialisedData<IUpdateTreatmentStep>>()
);

export interface IChartTreatmentAdd extends IEntityAction {
  treatment: IChartedTreatment;
}

export const addTreatment = createAction(
  TreatmentStepActions.AddTreatment,
  props<SerialisedData<IChartTreatmentAdd>>()
);

export interface IChartTreatmentUpdate extends IEntityAction {
  treatment: AtLeast<IChartedTreatment, 'uuid'>;
}

export const updateTreatment = createAction(
  TreatmentStepActions.UpdateTreatment,
  props<SerialisedData<IChartTreatmentUpdate>>()
);

export const selectTreatmentStep = getSelectAction(
  TreatmentStepActions.SelectTreatmentStep
);

export const clearTreatmentStep = createAction(
  TreatmentStepActions.ClearSelectedTreatmentStep
);
