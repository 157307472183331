import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentPatientScope(route),
  deps: [Router],
})
export class CurrentPatientScope extends BaseCurrentFirestoreModel<
  WithRef<IPatient>
> {
  protected _propertyAccessor(data: Data): WithRef<IPatient> | undefined {
    if (data.patient) {
      return data.patient as WithRef<IPatient>;
    }
  }
}
