import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerNavigatedAction,
  type RouterNavigatedPayload,
  type SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import {
  type ITimePeriod,
  serialise$,
  type Timezone,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { CalendarActions } from '../actions';
import { CalendarFacade } from '../facades/calendar.facade';
import { CALENDAR_LOCAL_STORAGE } from '../local-store';
import { type ICalendarParams } from '../models/calendar-params';
import {
  CALENDAR_UNITS,
  CalendarUnit,
  CalendarView,
} from '@principle-theorem/principle-core/interfaces';

interface IUnitAndRange {
  unit: CalendarUnit;
  range: ITimePeriod;
}

@Injectable()
export class CalendarRouterEffects {
  private _actions$ = inject(Actions);
  private _calendarFacade = inject(CalendarFacade);
  private _timezoneService = inject(TimezoneService);
  handleRoute$: Observable<Action> = createEffect(() =>
    this._handleRouteParams$()
  );

  private _handleRouteParams$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(
        ({ payload }) =>
          payload.event.url.includes('schedule') ||
          payload.event.urlAfterRedirects.includes('schedule')
      ),
      withLatestFrom(
        this._calendarFacade.calendarParams$,
        this._timezoneService.currentPractice.timezone$
      ),
      map(([{ payload }, params, timezone]) => {
        const view = this._getView(payload);
        const result =
          view === CalendarView.Calendar
            ? this._getCalendarUnitAndRange(params, timezone)
            : this._getTimelineUnitAndRange(params, timezone);
        return { view, ...result };
      }),
      serialise$(),
      map((data) => CalendarActions.setViewUnitAndRange(data))
    );
  }

  private _getCalendarUnitAndRange(
    params: Partial<ICalendarParams>,
    timezone: Timezone
  ): IUnitAndRange {
    const unit =
      CALENDAR_UNITS.find((search) => search === params.calendarUnit) ??
      CalendarUnit.Month;
    return {
      unit,
      range: this._getRangeFromQueryParams(params, timezone),
    };
  }

  private _getTimelineUnitAndRange(
    params: Partial<ICalendarParams>,
    timezone: Timezone
  ): IUnitAndRange {
    const range = this._getRangeFromQueryParams(params, timezone);
    return {
      range,
      unit: this._inferUnitFromRange(range),
    };
  }

  private _getRangeFromQueryParams(
    params: Partial<ICalendarParams>,
    timezone: Timezone
  ): ITimePeriod {
    const localVal = CALENDAR_LOCAL_STORAGE.value;
    const rawFrom = params.from
      ? moment.tz(params.from, timezone)
      : localVal?.from
        ? moment.tz(localVal.from, timezone)
        : moment.tz(timezone);
    const rawTo = params.to
      ? moment.tz(params.to, timezone)
      : localVal?.to
        ? moment.tz(localVal.to, timezone)
        : moment.tz(timezone);

    const from = rawFrom.startOf('day');
    const to = rawTo.endOf('day');
    return {
      from,
      to: to.isBefore(from) ? moment(from).tz(timezone).endOf('day') : to,
    };
  }

  private _inferUnitFromRange(range: ITimePeriod): CalendarUnit {
    const numDays = range.to.diff(range.from, 'day') + 1;
    if (numDays > 4) {
      return CalendarUnit.Month;
    }
    if (numDays > 1) {
      return CalendarUnit.Week;
    }
    return CalendarUnit.Day;
  }

  private _getView(
    payload: RouterNavigatedPayload<SerializedRouterStateSnapshot>
  ): CalendarView {
    if (payloadUrlIncludes(payload, 'calendar')) {
      return CalendarView.Calendar;
    }
    if (payloadUrlIncludes(payload, 'timeline')) {
      return CalendarView.Timeline;
    }
    return CalendarView.Dashboard;
  }
}

function payloadUrlIncludes(
  payload: RouterNavigatedPayload<SerializedRouterStateSnapshot>,
  match: string
): boolean {
  return (
    payload.event.url.includes(match) ||
    payload.event.urlAfterRedirects.includes(match)
  );
}
