import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  ITreatmentCategory,
  ITypesenseWaitListWithRef,
  UNCATEGORISED_CATEGORY,
} from '@principle-theorem/principle-core/interfaces';
import { asDocRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GapAppointmentTooltipComponent } from '../../gap-appointment-tooltip/gap-appointment-tooltip.component';

@Component({
  selector: 'pr-gap-shortlist-item-container',
  templateUrl: './gap-shortlist-item-container.component.html',
  styleUrls: ['./gap-shortlist-item-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GapAppointmentTooltipComponent, NgMaterialModule, NgSharedModule],
})
export class GapShortlistItemContainerComponent {
  treatmentCategory$: Observable<ITreatmentCategory>;
  appointment$ = new ReplaySubject<ITypesenseWaitListWithRef>(1);
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
    },
    // TODO: Fallback tooltip position is not working in a mat drawer
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  @Input()
  set appointment(appointment: ITypesenseWaitListWithRef) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {
    this.treatmentCategory$ = this.appointment$.pipe(
      map((appointment) =>
        appointment.treatmentCategoryRef
          ? asDocRef<ITreatmentCategory>(appointment.treatmentCategoryRef)
          : undefined
      ),
      switchMap((displayRef) =>
        displayRef
          ? this._globalStore.getTreatmentCategory$(displayRef)
          : of(undefined)
      ),
      map((treatmentCategory) => treatmentCategory ?? UNCATEGORISED_CATEGORY)
    );
  }
}
