import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  ICustomChartSettings,
  MeasureFormatter,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  IMeasureBuilderData,
} from '@principle-theorem/reporting';
import { trim } from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ReportBuilderStore } from '../../report-builder.store';
import { ReportBuilderManageChartBloc } from '../report-builder-add-chart-dialog/report-builder-manage-chart-bloc';
import { ComparableValue } from 'crossfilter2';

export interface IReportBuilderColumnSelectorDialogData {
  filteredData: object[];
  existing?: ICustomChartSettings;
  excludeDataPointFormatters: MeasureFormatter[];
}

export interface IReportBuildColumnSelectorFormData
  extends Omit<IMeasureBuilderData, 'uid'> {
  reducer: MeasureReducer;
  filters: ComparableValue[];
  label?: string;
}

@Component({
  selector: 'pr-report-builder-column-selector-dialog',
  templateUrl: './report-builder-column-selector-dialog.component.html',
  styleUrls: ['./report-builder-column-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderColumnSelectorDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  isEdit = false;
  columnForm = new TypedFormGroup<IReportBuildColumnSelectorFormData>({
    label: new TypedFormControl(),
    measure: new TypedFormControl(undefined, Validators.required),
    filters: new TypedFormControl([]),
    reducer: new TypedFormControl(MeasureReducer.Sum),
  });
  bloc: ReportBuilderManageChartBloc;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IReportBuilderColumnSelectorDialogData,
    private _store: ReportBuilderStore,
    public _dialogRef: MatDialogRef<
      ReportBuilderColumnSelectorDialogComponent,
      IReportBuildColumnSelectorFormData | undefined
    >
  ) {
    this.bloc = new ReportBuilderManageChartBloc(
      this._store,
      this.data.filteredData,
      this._onDestroy$,
      this.columnForm.controls,
      data.excludeDataPointFormatters
    );

    combineLatest([
      formControlChanges$(this.columnForm.controls.measure),
      formControlChanges$(this.columnForm.controls.reducer),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([measure, reducer]) => {
        const measureLabel = measure?.metadata.label;
        if (!measureLabel) {
          return;
        }

        let label = '';

        switch (reducer) {
          case MeasureReducer.Count:
            label = measureLabel;
            break;
          case MeasureReducer.Average:
            label = `Average ${measureLabel}`;
            break;
          case MeasureReducer.Sum:
            if (measureLabel.includes('Total')) {
              label = `${measureLabel}`;
              break;
            }
            label = `Total ${measureLabel}`;
            break;
          default:
            return;
        }

        this.columnForm.controls.label.setValue(label, { emitEvent: false });
      });

    if (data.existing) {
      this.isEdit = true;
      this.bloc.resolvedDataPointSections$
        .pipe(take(1), takeUntil(this._onDestroy$))
        .subscribe((sections) => {
          const measures = sections
            .flatMap((section) => section.columns)
            .map((column) => column.property);
          const existingMeasure = measures.find(
            (measure) => measure.metadata.id === data.existing?.dataPoint
          );

          this.columnForm.patchValue({
            measure: existingMeasure,
            label: data.existing?.label,
            reducer: data.existing?.reducer,
            filters: data.existing?.filters,
          });

          this.bloc.dataPointSearchCtrl.setValue(
            existingMeasure?.metadata.label ?? ''
          );
          this.columnForm.markAsDirty();
          this.columnForm.markAsTouched();
          this.columnForm.updateValueAndValidity();
        });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    const formValue = this.columnForm.getRawValue();
    this._dialogRef.close({
      ...formValue,
      label: formValue.label ? trim(formValue.label) : undefined,
    });
  }

  compareFn(
    columnA: CanBeChartedProperty,
    columnB: CanBeChartedProperty
  ): boolean {
    if (!columnA || !columnB) {
      return false;
    }
    return columnA.metadata.id === columnB.metadata.id;
  }
}
