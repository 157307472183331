import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import {
  sortByTreatmentPlanStatus,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  type ITreatmentPlanWithPatient,
  TreatmentPlanStatus,
  TREATMENT_PLAN_STATUS_ORDER_MAP,
} from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT, multiSort } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-treatment-plan-table',
  templateUrl: './treatment-plan-table.component.html',
  styleUrls: ['./treatment-plan-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanTableComponent implements OnDestroy {
  private _treatmentPlans$ = new ReplaySubject<ITreatmentPlanWithPatient[]>(1);
  readonly dateFormat = HISTORY_DATE_FORMAT;
  dataSource: ObservableDataSource<ITreatmentPlanWithPatient>;
  @Input() displayedColumns: string[] = [
    'plan.createdAt',
    'plan.status',
    'patient.name',
    'plan.name',
    'progress',
    'price',
    'actions',
  ];

  @Input()
  set treatmentPlans(plans: ITreatmentPlanWithPatient[]) {
    if (plans) {
      this._treatmentPlans$.next(plans);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor() {
    this.dataSource = new ObservableDataSource<ITreatmentPlanWithPatient>(
      this._treatmentPlans$.pipe(
        multiSort((a, b) =>
          sortByTreatmentPlanStatus(a.plan.status, b.plan.status)
        )
      )
    );
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  canBeDeclined(pair: ITreatmentPlanWithPatient): boolean {
    return TreatmentPlan.canDecline(pair.plan);
  }

  canBeAccepted(pair: ITreatmentPlanWithPatient): boolean {
    return TreatmentPlan.canAccept(pair.plan);
  }

  canBeCompleted$(pair: ITreatmentPlanWithPatient): Observable<boolean> {
    return TreatmentPlan.canComplete$(pair.plan);
  }

  progress$(pair: ITreatmentPlanWithPatient): Observable<number> {
    return TreatmentPlan.progress$(pair.plan);
  }

  price$(pair: ITreatmentPlanWithPatient): Observable<number> {
    return TreatmentPlan.price$(pair.plan);
  }

  isComplete(pair: ITreatmentPlanWithPatient): boolean {
    return TreatmentPlan.isComplete(pair.plan);
  }

  async acceptPlan(pair: ITreatmentPlanWithPatient): Promise<void> {
    await TreatmentPlan.patchPlanStatus(
      pair.plan,
      TreatmentPlanStatus.Accepted
    );
  }

  async declinePlan(pair: ITreatmentPlanWithPatient): Promise<void> {
    await TreatmentPlan.patchPlanStatus(
      pair.plan,
      TreatmentPlanStatus.Declined
    );
  }

  async completePlan(pair: ITreatmentPlanWithPatient): Promise<void> {
    await TreatmentPlan.patchPlanStatus(
      pair.plan,
      TreatmentPlanStatus.Completed
    );
  }

  async reactivatePlan(pair: ITreatmentPlanWithPatient): Promise<void> {
    await TreatmentPlan.patchPlanStatus(
      pair.plan,
      TreatmentPlanStatus.InProgress
    );
  }

  private _sortingDataAccessor(
    data: ITreatmentPlanWithPatient,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'plan.createdAt':
        return timestampSortingAccessor(data.plan.createdAt);
      case 'plan.status':
        return TREATMENT_PLAN_STATUS_ORDER_MAP[data.plan.status];
      default:
        return;
    }
  }
}
