<button
  class="opacity-80"
  mat-icon-button
  matTooltip="Device Settings"
  [matMenuTriggerFor]="moreMenu"
  [disabled]="disabled$ | async"
>
  <mat-icon>settings</mat-icon>
</button>

<mat-menu class="view-settings" #moreMenu="matMenu">
  <form
    *ngrxLet="bridgeVersion$ as version"
    [formGroup]="twainDeviceSettingsForm"
    class="min-w-[250px]"
  >
    <h3 class="settings-heading">TWAIN Device Settings</h3>

    <pt-settings-menu-slide-toggle-item
      label="VistaSoft Connect"
      formControlName="vistaSoftConnectEnabled"
      tooltip="Enables VistaSoft Connect compatibility.
      Requires Principle Bridge version 1.167.0 or higher"
      [disabled]="!!version && version < minimumVistaSoftVersion"
    />

    <pt-settings-menu-slide-toggle-item
      label="Acquire Multiple Images"
      formControlName="multiImageEnabled"
      tooltip="Allows multiple images to be acquired and uploaded at once.
      Requires Principle Bridge version 1.168.0 or higher"
      [disabled]="!!version && version < minimumMultiImageVersion"
    />
  </form>

  <mat-divider />

  <div class="flex items-center justify-between px-4">
    <span>Delete Device</span>
    <button mat-icon-button (click)="updateDevice({ deleted: true })">
      <mat-icon color="warn" class="opacity-80">delete</mat-icon>
    </button>
  </div>
</mat-menu>
