import { BaseFirestoreMock, Properties } from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';
import { MockGenerator, INamedDocument } from '@principle-theorem/shared';
import {
  IGap,
  IEvent,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { EventMock } from '../event/event.mock';

export class GapMock extends BaseFirestoreMock implements Properties<IGap> {
  get event(): IEvent {
    return MockGenerator.generate(EventMock);
  }

  get practitioner(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }
}
