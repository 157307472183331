@if (data(); as info) {
  <h3 class="!m-0 !font-bold">Location</h3>

  @if (info.practiceCoordinates) {
    <pr-map-preview
      class="overflow-hidden rounded"
      locked="true"
      height="200px"
      [coordinates]="info.practiceCoordinates"
    />
  }

  <div class="flex flex-row gap-4">
    <div class="flex grow flex-col gap-1">
      <div>{{ info.practice }}</div>
      <div class="text-xs opacity-50">{{ info.practiceAddress }}</div>
    </div>
    <a
      mat-icon-button
      matTooltip="Open in Maps"
      target="_blank"
      [href]="info.practiceAddress | map: openMapsLink : this"
    >
      <mat-icon>near_me</mat-icon>
    </a>
  </div>
}
