export function roundTo2Decimals(value: number): number {
  return Math.round(value * 100) / 100;
}

export function roundToDecimals(
  value: number,
  numberOfDecimals: number
): number {
  let multiplier = 10;
  for (let i = 1; i < numberOfDecimals; i++) {
    multiplier *= 10;
  }
  return Math.round(value * multiplier) / multiplier;
}
