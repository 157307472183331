import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IRole } from '../auth/role';
import { type IBrand } from '../brand';
import { type IPractice } from '../practice/practice';

export interface IUserMetadata {
  firstSignedInAt?: Timestamp;
  lastSignInTime?: Timestamp;
  lastActiveAt?: Timestamp;
}

export interface IUser extends IUserMetadata {
  name: string;
  phone: string;
  email: string;
  profileImageURL?: string;
  roles: DocumentReference<IRole>[];
  practices: DocumentReference<IPractice>[];
  brands: DocumentReference<IBrand>[];
  isEnabled: boolean;
}
