<pt-dynamic-sidebar-header>
  <div fxLayout="row" fxLayoutAlign="end center">
    <pr-appointment-quick-actions
      [appointment]="appointment$ | ngrxPush"
      [patient]="patient$ | ngrxPush"
    />
  </div>
</pt-dynamic-sidebar-header>
<mat-tab-group class="sticky" mat-align-tabs="center">
  <mat-tab label="Appointment">
    <ng-template matTabContent>
      <div
        class="flex flex-col gap-4 p-4"
        *ngIf="appointment$ | ngrxPush as appointment"
      >
        <pr-appointment-alerts
          [appointment]="appointment"
          [patient]="patient$ | ngrxPush"
        />

        <pr-content-container>
          <h2>Patient</h2>
          <pr-patient-profile-summary
            [patient]="patient$ | ngrxPush"
            class="block"
          />
          <pr-patient-medical-alerts
            class="mt-2 block"
            [patient]="patient$ | ngrxPush"
            [expandable]="false"
          />
        </pr-content-container>

        <pr-content-container>
          <h2>Appointment Details</h2>
          <pr-event-details-sidebar
            [appointment]="appointment"
            [event]="event$ | ngrxPush"
          />
          <pr-appointment-tags-sidebar-list [appointment]="appointment" />
        </pr-content-container>

        <pr-content-container>
          <pr-appointment-treatment-sidebar
            [appointment]="appointment"
            [treatmentPlan]="treatmentPlan$ | ngrxPush"
          />
        </pr-content-container>

        <pr-content-container>
          <pr-event-participants-display [event]="event$ | ngrxPush" />
        </pr-content-container>

        <pr-content-container>
          <pr-appointment-all-notes-sidebar
            [appointment]="appointment"
            [patient]="patient$ | ngrxPush"
          />
        </pr-content-container>

        <pr-content-container>
          <pr-appointment-automations-summary-sidebar
            [automations]="automations$ | ngrxPush"
            (openAutomations)="openAutomations()"
          />
        </pr-content-container>

        <pr-content-container>
          <pr-appointment-tags [appointment]="appointment" />
        </pr-content-container>

        @if (checklistItems$ | ngrxPush; as checklistItems) {
          @if (checklistItems.length) {
            <pr-content-container>
              <pr-appointment-checklist-status-sidebar
                [checklistItems]="checklistItems"
              />
            </pr-content-container>
          }
        }

        @if (appointment.invoiceRef) {
          <pr-content-container>
            <pr-appointment-invoice [appointment]="appointment" />
          </pr-content-container>
        }

        @if (labJobs$ | ngrxPush; as labJobs) {
          @if (labJobs.length) {
            <pr-content-container>
              <pr-appointment-lab-jobs [labJobs]="labJobs" />
            </pr-content-container>
          }
        }
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Treatments">
    <ng-template matTabContent>
      <div class="p-4">
        <pr-content-container>
          <pr-treatment-plans
            [patient]="patient$ | ngrxPush"
            [currentPractitioner]="currentPractitioner$ | ngrxPush"
            [statuses]="treatmentStatuses"
            [disabled]="true"
          >
            <h2>Treatment Plans</h2>
          </pr-treatment-plans>
        </pr-content-container>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
