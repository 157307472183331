import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PerioChartFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { BehaviorSubject } from 'rxjs';
import { PerioChartSettingsDialogComponent } from '../perio-chart-settings-dialog/perio-chart-settings-dialog.component';

@Component({
  selector: 'pr-perio-chart-settings',
  templateUrl: './perio-chart-settings.component.html',
  styleUrl: './perio-chart-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
})
export class PerioChartSettingsComponent {
  private _dialog = inject(MatDialog);
  private _perioChartFacade = inject(PerioChartFacade);
  immutable$ = new BehaviorSubject<boolean>(false);

  @Input()
  set immutable(immutable: BooleanInput) {
    this.immutable$.next(coerceBooleanProperty(immutable));
  }

  openPerioSettings(): void {
    this._dialog.open(PerioChartSettingsDialogComponent, DialogPresets.large());
  }

  async deletePerioChartData(): Promise<void> {
    const data = confirmationDialogData({
      title: `Delete Perio Chart Data`,
      prompt: `Are you sure you want to delete all chart data?`,
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    this._perioChartFacade.deletePerioValues();
  }
}
