import { Money } from '@principle-theorem/accounting';
import {
  Brand,
  ClinicalChart,
  Patient,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  IPricedServiceCodeEntry,
  IStaffer,
  ITreatmentPlan,
  ITreatmentStep,
  PatientStatus,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  AnyDataTable,
  DocumentReference,
  Firestore,
  INamedDocument,
  IReffable,
  ITimePeriod,
  Timestamp,
  WithRef,
  asyncForEach,
  bufferedQuery$,
  multiConcatMap,
  multiFilter,
  snapshot,
  toISODate,
  toMoment,
} from '@principle-theorem/shared';
import { compact, first, groupBy, startCase, sum, toPairs, uniq } from 'lodash';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';

interface IItemCodeData {
  itemCode: IPricedServiceCodeEntry;
  chartedBy?: INamedDocument<IStaffer>;
  chartedAt?: Timestamp;
  createdAt?: Timestamp;
  source: string;
  status: string;
}

interface IPatientItemCodesData {
  metadata: IPatientMetadata;
  itemCodes: IItemCodeData[];
}

interface IPatientMetadata {
  ref: string;
  name: string;
  patientUrl: string;
  age?: number;
  mobile: string;
  practice?: string;
  healthFund?: string;
  lastVisit?: Timestamp;
  accountCreditRemaining: number;
}

interface IRow {
  name: string;
  patientUrl: string;
  age?: number;
  mobile: string;
  practice?: string;
  healthFund?: string;
  lastVisit?: string;
  accountCreditRemaining: number;
  practitioner: string;
  source: string;
  status: string;
  chartedAt: string;
  itemCode: string;
  price: number;
}

interface ISummaryRow {
  name: string;
  patientUrl: string;
  age?: number;
  mobile: string;
  practice?: string;
  healthFund?: string;
  lastVisit?: string;
  accountCreditRemaining: number;
  practitioner: string;
  itemCodes: string;
  outstandingTreatment: number;
}

/**
 * Incomplete treatment by item code
 * Gets a list of all item codes from treatments that haven't been completed.
 */
export class IncompleteTreatmentByItemCode {
  async run(
    brandRef: DocumentReference<IBrand>,
    dateRange: ITimePeriod,
    appUrl: string
  ): Promise<AnyDataTable[]> {
    const brand = await Firestore.getDoc(brandRef);

    const data = await this._getActivePatients$(brand)
      .pipe(
        multiConcatMap((patient) =>
          this._getIncompleteItemCodes(brand, patient, appUrl, dateRange)
        ),
        multiFilter((patientData) => !!patientData),
        scan(
          (acc: IPatientItemCodesData[], patientData) => [
            ...acc,
            ...compact(patientData),
          ],
          []
        )
      )
      .toPromise();

    const rows = this._toRows(data);
    const summary = this._toSummary(data);
    return this._toDataTables(rows, summary);
  }

  private _getActivePatients$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IPatient>[]> {
    return bufferedQuery$(Brand.patientCol(brand), 100, 'ref').pipe(
      multiFilter((patient) => patient.status === PatientStatus.Active)
    );
  }

  private async _getIncompleteItemCodes(
    brand: WithRef<IBrand>,
    patient: WithRef<IPatient>,
    appUrl: string,
    dateRange: ITimePeriod
  ): Promise<IPatientItemCodesData | undefined> {
    const treatmentPlanItemCodes =
      await this._itemCodesFromTreatmentSteps(patient);
    const flaggedTreatmentItemCodes =
      await this._itemCodesFromFlaggedTreatment(patient);

    const itemCodes = this._filterToReleventItemCodes(
      [treatmentPlanItemCodes, flaggedTreatmentItemCodes].flat(),
      dateRange
    );
    if (!itemCodes.length) {
      return;
    }
    const patientMetadata = await this._getPatientMetadata(
      brand,
      patient,
      appUrl
    );
    return {
      metadata: patientMetadata,
      itemCodes,
    };
  }

  private _filterToReleventItemCodes(
    itemCodes: IItemCodeData[],
    dateRange: ITimePeriod
  ): IItemCodeData[] {
    return itemCodes
      .filter((data) => data.itemCode.quantity > 0)
      .filter(
        (data) =>
          data.chartedAt &&
          toMoment(data.chartedAt).isBetween(
            dateRange.from,
            dateRange.to,
            'second',
            '[]'
          )
      );
  }

  private async _itemCodesFromTreatmentSteps(
    patient: WithRef<IPatient>
  ): Promise<IItemCodeData[]> {
    const treatmentPlans = await snapshot(TreatmentPlan.all$(patient));
    const activeTreatmentPlans = treatmentPlans
      .filter((treatmentPlan) => !treatmentPlan.deleted)
      .filter((treatmentPlan) =>
        [
          TreatmentPlanStatus.Draft,
          TreatmentPlanStatus.Offered,
          TreatmentPlanStatus.Accepted,
          TreatmentPlanStatus.InProgress,
        ].includes(treatmentPlan.status)
      );

    const allSteps = await asyncForEach(
      activeTreatmentPlans,
      async (treatmentPlan) => {
        const steps = await snapshot(
          TreatmentPlan.treatmentSteps$(treatmentPlan)
        );
        const activeSteps = steps.filter(
          (step) =>
            !step.appointment &&
            !step.deleted &&
            step.status !== TreatmentStepStatus.Complete
        );
        return activeSteps.flatMap((treatmentStep) =>
          this._itemCodesFromTreatmentStep(treatmentPlan, treatmentStep)
        );
      }
    );
    return allSteps.flat();
  }

  private _itemCodesFromTreatmentStep(
    treatmentPlan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): IItemCodeData[] {
    return treatmentStep.treatments.flatMap((treatment) => {
      const surface = first(treatment.chartedSurfaces);
      return treatment.serviceCodes.map((itemCode) => ({
        itemCode,
        chartedAt: surface?.chartedAt ?? treatmentStep.createdAt,
        chartedBy: surface?.chartedBy,
        source: treatmentPlan.name,
        status: startCase(treatmentPlan.status),
        createdAt: treatmentStep.createdAt,
      }));
    });
  }

  private async _itemCodesFromFlaggedTreatment(
    patient: WithRef<IPatient>
  ): Promise<IItemCodeData[]> {
    const chart = await snapshot(ClinicalChart.getLatestChart$(patient));
    if (!chart) {
      return [];
    }

    const treatments = [
      chart.flaggedTreatment.multiTreatments.flatMap((multiTreatment) =>
        multiTreatment.steps.flatMap((step) => step.treatments)
      ),
      chart.flaggedTreatment.treatments,
    ].flat();

    return treatments.flatMap((treatment) => {
      const surface = first(treatment.chartedSurfaces);
      return treatment.serviceCodes.map((itemCode) => ({
        itemCode,
        chartedAt: surface?.chartedAt,
        chartedBy: surface?.chartedBy,
        createdAt: surface?.chartedAt,
        source: 'Flagged Treatment',
        status: 'Flagged',
      }));
    });
  }

  private async _getPatientMetadata(
    brand: WithRef<IBrand>,
    patient: WithRef<IPatient>,
    appUrl: string
  ): Promise<IPatientMetadata> {
    const lastAppointment = await Patient.lastCompletedAppointment(patient);
    const mobileNumber =
      Patient.getMobileNumber(patient)?.replace(/ /g, '') ?? '';
    const patientUrl = [appUrl, brand.slug, 'patients', patient.ref.id].join(
      '/'
    );
    return {
      ref: patient.ref.path,
      name: patient.name,
      patientUrl,
      age: Patient.age(patient),
      mobile: mobileNumber,
      practice: patient.preferredPractice?.name,
      healthFund: patient.healthFundCard?.fundCode?.trim(),
      lastVisit: lastAppointment?.event?.from,
      accountCreditRemaining:
        patient.accountSummary.creditSummary.creditRemaining,
    };
  }

  private _toRows(data: IPatientItemCodesData[]): IRow[] {
    return data.flatMap((patientData) =>
      patientData.itemCodes.map((itemCode) => ({
        name: patientData.metadata.name,
        patientUrl: patientData.metadata.patientUrl,
        age: patientData.metadata.age,
        mobile: patientData.metadata.mobile,
        practice: patientData.metadata.practice,
        healthFund: patientData.metadata.healthFund,
        lastVisit: patientData.metadata.lastVisit
          ? toISODate(patientData.metadata.lastVisit)
          : '',
        accountCreditRemaining: patientData.metadata.accountCreditRemaining,
        practitioner: itemCode.chartedBy?.name ?? '',
        source: itemCode.source,
        status: itemCode.status,
        chartedAt: itemCode.chartedAt ? toISODate(itemCode.chartedAt) : '',
        itemCode: `${itemCode.itemCode.code}`,
        price: itemCode.itemCode.priceOverride ?? itemCode.itemCode.price,
      }))
    );
  }

  private _toSummary(data: IPatientItemCodesData[]): ISummaryRow[] {
    return data.map((patientData) => {
      const practitioner = uniq(
        compact(
          patientData.itemCodes.map((itemCode) => itemCode.chartedBy?.name)
        )
      ).join(', ');

      const outstandingTreatment = Money.sum(
        patientData.itemCodes.map((code) =>
          Money.from(code.itemCode.price).multiply(code.itemCode.quantity)
        )
      );

      return {
        name: patientData.metadata.name,
        patientUrl: patientData.metadata.patientUrl,
        age: patientData.metadata.age,
        mobile: patientData.metadata.mobile,
        practice: patientData.metadata.practice,
        healthFund: patientData.metadata.healthFund,
        lastVisit: patientData.metadata.lastVisit
          ? toISODate(patientData.metadata.lastVisit)
          : '',
        accountCreditRemaining: patientData.metadata.accountCreditRemaining,
        practitioner,
        itemCodes: this._summariseItemCodes(patientData.itemCodes),
        outstandingTreatment: Money.amount(outstandingTreatment),
      };
    });
  }

  private _summariseItemCodes(itemCodes: IItemCodeData[]): string {
    const groupedItemCodes = toPairs(
      groupBy(itemCodes, (itemCode) => itemCode.itemCode.code)
    );
    return groupedItemCodes
      .map(([itemCode, groupedCodes]) => {
        const quantity = sum(
          groupedCodes.map((item) => item.itemCode.quantity)
        );
        return `${itemCode} x${quantity}`;
      })
      .join(', ');
  }

  private _toDataTables(
    rows: IRow[],
    summaryRows: ISummaryRow[]
  ): AnyDataTable[] {
    return [
      {
        name: 'Summary',
        data: summaryRows,
        columns: [
          { key: 'name', header: 'Name' },
          { key: 'patientUrl', header: 'Patient Url' },
          { key: 'age', header: 'Age' },
          { key: 'mobile', header: 'Mobile' },
          { key: 'practice', header: 'Practice' },
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'healthFund', header: 'Health Fund' },
          { key: 'lastVisit', header: 'Last Visit' },
          {
            key: 'accountCreditRemaining',
            header: 'Account Credit Remaining',
          },
          { key: 'itemCodes', header: 'Item Codes' },
          { key: 'outstandingTreatment', header: 'Outstanding Treatment' },
        ],
      },
      {
        name: 'Data',
        data: rows,
        columns: [
          { key: 'name', header: 'Name' },
          { key: 'patientUrl', header: 'Patient Url' },
          { key: 'age', header: 'Age' },
          { key: 'mobile', header: 'Mobile' },
          { key: 'practice', header: 'Practice' },
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'source', header: 'Source' },
          { key: 'status', header: 'Status' },
          { key: 'chartedAt', header: 'Created At' },
          { key: 'itemCode', header: 'Item Code' },
          { key: 'price', header: 'Price' },
          { key: 'healthFund', header: 'Health Fund' },
          { key: 'lastVisit', header: 'Last Visit' },
          { key: 'accountCreditRemaining', header: 'Account Credit Remaining' },
        ],
      },
    ];
  }
}
