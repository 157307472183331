import { ClientPosition } from '../models/svg-helpers';
import { ChartElement } from '../renderers/chart-element';
import { type IChartTooth } from '../renderers/chart-tooth';
import { ChartToothSurfaceTexture } from '../renderers/chart-tooth-surface-texture';
import { ChartableSurface } from '@principle-theorem/principle-core/interfaces';
import {
  isUpperQuadrant,
  isRightQuadrant,
} from '@principle-theorem/principle-core';

const ICON_RADIUS = 23;

export class ToothSurfaceIndicatorsFactory {
  defaultMid: ClientPosition = new ClientPosition(100.5, 93);

  constructor(private _tooth: IChartTooth) {}

  create(surface: ChartableSurface): ChartElement {
    const indicator: ChartElement = new ChartElement();
    this._setPosition(indicator, this._getPosition(surface));
    return indicator;
  }

  private _setPosition(
    indicator: ChartElement,
    position: ClientPosition
  ): void {
    indicator.xPos = position.x;
    indicator.yPos = position.y;
    indicator.yFlipped = isUpperQuadrant(this._tooth.tooth.toothRef.quadrant);
    indicator.xFlipped = isRightQuadrant(this._tooth.tooth.toothRef.quadrant);
  }

  private _getPosition(surface: ChartableSurface): ClientPosition {
    const mid: ClientPosition = this.defaultMid;
    const centreOffset = 25;
    const iconSpacing = 16;
    const delta: number = ICON_RADIUS * 2 + iconSpacing + centreOffset;
    switch (surface) {
      case ChartableSurface.Occlusal:
        return mid;
      case ChartableSurface.Mesial:
        return new ClientPosition(mid.x - delta, mid.y);
      case ChartableSurface.Lingual:
        return new ClientPosition(mid.x, mid.y - delta);
      case ChartableSurface.Facial:
        return new ClientPosition(mid.x, mid.y + delta);
      case ChartableSurface.Distal:
        return new ClientPosition(mid.x + delta, mid.y);
      default:
        return new ClientPosition(0, 0);
    }
  }
}

export class ToothSurfaceItemsIndicatorFactory {
  defaultMid: ClientPosition = new ClientPosition(70, 63);

  constructor(private _tooth: IChartTooth) {}

  create(surface: ChartableSurface): ChartToothSurfaceTexture {
    const indicator: ChartToothSurfaceTexture = new ChartToothSurfaceTexture(
      this._tooth
    );
    this._setPosition(indicator, this._getPosition(indicator, surface));
    return indicator;
  }

  private _setPosition(
    indicator: ChartToothSurfaceTexture,
    position: ClientPosition
  ): void {
    indicator.xPos = position.x;
    indicator.yPos = position.y;
    indicator.yFlipped = isUpperQuadrant(this._tooth.tooth.toothRef.quadrant);
    indicator.xFlipped = isRightQuadrant(this._tooth.tooth.toothRef.quadrant);
  }

  private _getPosition(
    indicator: ChartToothSurfaceTexture,
    surface: ChartableSurface
  ): ClientPosition {
    const mid: ClientPosition = this.defaultMid;
    const iconSpacing = 0;
    const delta: number = indicator.width + iconSpacing;
    switch (surface) {
      case ChartableSurface.Occlusal:
        return mid;
      case ChartableSurface.Mesial:
        return new ClientPosition(mid.x - delta, mid.y);
      case ChartableSurface.Lingual:
        return new ClientPosition(mid.x, mid.y - delta);
      case ChartableSurface.Facial:
        return new ClientPosition(mid.x, mid.y + delta);
      case ChartableSurface.Distal:
        return new ClientPosition(mid.x + delta, mid.y);
      default:
        return new ClientPosition(0, 0);
    }
  }
}
