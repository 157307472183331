import { getInheritedPropertyDescriptors } from '@principle-theorem/shared';
import { intersection, isFunction, keys, memoize, toPairs } from 'lodash';
import {
  BaseFactMeasures,
  BaseMeasures,
  FactTableInterface,
} from './base-measures';
import { AccountCreditEventFactMeasures } from './facts/account-credit-event-fact';
import { AppointmentEventFactMeasures } from './facts/appointment-event-fact';
import { AppointmentServiceCodeFactMeasures } from './facts/appointment-service-code-fact';
import { AppointmentTreatmentFactMeasures } from './facts/appointment-treatment-fact';
import { GapEventFactMeasures } from './facts/gap-event-fact';
import { GapFilledFactMeasures } from './facts/gap-filled-fact';
import { InvoiceEventFactMeasures } from './facts/invoice-event-fact';
import { LabJobEventFactMeasures } from './facts/lab-job-event-fact';
import { LabJobReceivedFactMeasures } from './facts/lab-job-received-fact';
import { PatientEventFactMeasures } from './facts/patient-event-fact';
import { PatientInteractionEventFactMeasures } from './facts/patient-interaction-event-fact';
import { PaymentPlanEventFactMeasures } from './facts/payment-plan-event-fact';
import { ScheduleSummaryEventFactMeasures } from './facts/schedule-summary-event-fact';
import { SchedulingEventFactMeasures } from './facts/scheduling-event-fact';
import { TaskCompletedFactMeasures } from './facts/task-completed-fact';
import { TaskEventFactMeasures } from './facts/task-event-fact';
import { TransactionEventFactMeasures } from './facts/transaction-event-fact';
import { TreatmentPlanCompletedFactMeasures } from './facts/treatment-plan-completed-fact';
import { TreatmentPlanEventFactMeasures } from './facts/treatment-plan-event-fact';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanGroupMeasuresProperty,
  ICanQueryByTimestampProperty,
  ReportingProperty,
  isCanBeChartedProperty,
  isCanGroupMeasuresProperty,
  isCanQueryByTimestampProperty,
} from './measure-properties';

const ROOT_FACT_TABLES = [
  new AppointmentEventFactMeasures(),
  new GapEventFactMeasures(),
  new GapFilledFactMeasures(),
  new InvoiceEventFactMeasures(),
  new LabJobEventFactMeasures(),
  new ScheduleSummaryEventFactMeasures(),
  new AccountCreditEventFactMeasures(),
  new LabJobReceivedFactMeasures(),
  new PatientEventFactMeasures(),
  new PatientInteractionEventFactMeasures(),
  new PaymentPlanEventFactMeasures(),
  new TaskCompletedFactMeasures(),
  new TaskEventFactMeasures(),
  new TreatmentPlanCompletedFactMeasures(),
  new TreatmentPlanEventFactMeasures(),
  new TransactionEventFactMeasures(),
  new AppointmentServiceCodeFactMeasures(),
  new AppointmentTreatmentFactMeasures(),
  new SchedulingEventFactMeasures(),
];

export const FACT_TABLES = ROOT_FACT_TABLES.reduce(
  (all: FactTableInterface<unknown>[], factTable) => [
    ...all,
    factTable,
    ...getNestedFactTables(factTable),
  ],
  []
);

export function resolveFactTable(
  id: string
): FactTableInterface<unknown> | undefined {
  return FACT_TABLES.find((factTable) => factTable.id === id);
}

function getNestedFactTables(
  factTable: FactTableInterface<unknown>,
  depthLimit = 1,
  depth = 0
): FactTableInterface<unknown>[] {
  return getKeyValues(factTable)
    .map(([_key, value]) => value)
    .filter(
      (value): value is BaseFactMeasures => value instanceof BaseFactMeasures
    )
    .reduce((all: FactTableInterface<unknown>[], nestedFactTable) => {
      const nestedFactTables =
        depth < depthLimit
          ? getNestedFactTables(nestedFactTable, depthLimit, depth + 1)
          : [];
      return [...all, nestedFactTable, ...nestedFactTables];
    }, []);
}

export class FactTables {
  static get appointmentEvent(): AppointmentEventFactMeasures {
    return new AppointmentEventFactMeasures();
  }

  static get appointmentServiceCodeEvent(): AppointmentServiceCodeFactMeasures {
    return new AppointmentServiceCodeFactMeasures();
  }

  static get appointmentTreatmentEvent(): AppointmentTreatmentFactMeasures {
    return new AppointmentTreatmentFactMeasures();
  }

  static get gapEvent(): GapEventFactMeasures {
    return new GapEventFactMeasures();
  }

  static get gapFilled(): GapFilledFactMeasures {
    return new GapFilledFactMeasures();
  }

  static get invoiceEvent(): InvoiceEventFactMeasures {
    return new InvoiceEventFactMeasures();
  }

  static get labJobEvent(): LabJobEventFactMeasures {
    return new LabJobEventFactMeasures();
  }

  static get scheduleSummaryEvent(): ScheduleSummaryEventFactMeasures {
    return new ScheduleSummaryEventFactMeasures();
  }

  static get accountCreditEvent(): AccountCreditEventFactMeasures {
    return new AccountCreditEventFactMeasures();
  }

  static get labJobReceived(): LabJobReceivedFactMeasures {
    return new LabJobReceivedFactMeasures();
  }

  static get patientEvent(): PatientEventFactMeasures {
    return new PatientEventFactMeasures();
  }

  static get patientInteractionEvent(): PatientInteractionEventFactMeasures {
    return new PatientInteractionEventFactMeasures();
  }

  static get paymentPlanEvent(): PaymentPlanEventFactMeasures {
    return new PaymentPlanEventFactMeasures();
  }

  static get taskCompleted(): TaskCompletedFactMeasures {
    return new TaskCompletedFactMeasures();
  }

  static get taskEvent(): TaskEventFactMeasures {
    return new TaskEventFactMeasures();
  }

  static get treatmentPlanCompleted(): TreatmentPlanCompletedFactMeasures {
    return new TreatmentPlanCompletedFactMeasures();
  }

  static get treatmentPlanEvent(): TreatmentPlanEventFactMeasures {
    return new TreatmentPlanEventFactMeasures();
  }

  static get transactionEvent(): TransactionEventFactMeasures {
    return new TransactionEventFactMeasures();
  }

  static get schedulingEvent(): SchedulingEventFactMeasures {
    return new SchedulingEventFactMeasures();
  }
}

export class KeyOverlapError extends Error {
  constructor(factTableId: string, overlappingKeys: string[]) {
    const keyList = overlappingKeys.join(', ');
    super(`Fact table (${factTableId}) has duplicate keys: ${keyList}`);
  }
}

export function getFactTablePropertyMap(
  factTable: BaseMeasures
): Record<string, ReportingProperty> {
  return getKeyValues(factTable)
    .map(([_key, value]) => value)
    .reduce((acc: Record<string, ReportingProperty>, property) => {
      const newProperties = getPropertyMap(property);
      const overlappingKeys = intersection(keys(acc), keys(newProperties));
      if (overlappingKeys.length > 0) {
        // eslint-disable-next-line no-console
        console.error('overlappingKeys', acc, newProperties, overlappingKeys);
      }
      return { ...acc, ...newProperties };
    }, {});
}

function getPropertyMap(property: unknown): Record<string, ReportingProperty> {
  if (property instanceof BaseMeasures) {
    return getBaseMeasuresPropertyMap(property);
  }
  return {};
}

function getBaseMeasuresPropertyMap(
  baseMeasures: BaseMeasures
): Record<string, ReportingProperty> {
  return getKeyValues(baseMeasures)
    .map(([_key, value]) => value)
    .filter(isResolvableProperty)
    .reduce((acc: Record<string, ReportingProperty>, property) => {
      if (acc[property.metadata.id]) {
        throw new KeyOverlapError(baseMeasures.id, [property.metadata.id]);
      }
      return { ...acc, [property.metadata.id]: property };
    }, {});
}

function isResolvableProperty(
  property: unknown
): property is CanBeChartedProperty | CanGroupMeasuresProperty {
  return (
    property instanceof CanBeChartedProperty ||
    property instanceof CanGroupMeasuresProperty
  );
}

export function resolveReportingProperty(
  factTable: BaseMeasures,
  id: string
): ReportingProperty | undefined {
  const getMapFn = memoize(
    getFactTablePropertyMap,
    (factMeasure) => factMeasure.id
  );
  const propertyMap = getMapFn(factTable);
  const result = propertyMap[id];
  if (!result) {
    // eslint-disable-next-line no-console
    console.warn(`Can't resolve property ${id} on ${factTable.id}`);
    return;
  }
  return result;
}

export function resolveCanGroupMeasuresProperty(
  factTable: BaseMeasures,
  id: string
): CanGroupMeasuresProperty | undefined {
  const property = resolveReportingProperty(factTable, id);
  if (!isCanGroupMeasuresProperty(property)) {
    return;
  }
  return property;
}

export function resolveCanBeChartedProperty(
  factTable: BaseMeasures,
  id: string
): CanBeChartedProperty | undefined {
  const property = resolveReportingProperty(factTable, id);
  if (!isCanBeChartedProperty(property)) {
    return;
  }
  return property;
}

export function resolveCanQueryByTimestampProperty(
  factTable: BaseMeasures,
  targetTimestamp: string
): (CanDoAllProperty & ICanQueryByTimestampProperty) | undefined {
  const timestampProperty = resolveReportingProperty(
    factTable,
    targetTimestamp
  );
  if (!isCanQueryByTimestampProperty(timestampProperty)) {
    return;
  }
  return timestampProperty as CanDoAllProperty & ICanQueryByTimestampProperty;
}

function getKeyValues<T extends object>(
  item: T
): [key: keyof T, value: unknown][] {
  const itemAll = {
    ...item,
    ...accessGetters(item),
  };
  return toPairs(itemAll).map(([key, value]) => [key as keyof T, value]);
}

function accessGetters<T extends object>(item: T): Partial<T> {
  return toPairs(getInheritedPropertyDescriptors(item))
    .filter(([key, descriptor]) => {
      if (key === '__proto__') {
        return false;
      }
      return descriptor && isFunction(descriptor.get);
    })
    .reduce((acc: Partial<T>, [key]) => {
      try {
        const value = item[key as keyof T];
        return { ...acc, [key]: value };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error calling getter ${key}`, error);
        return acc;
      }
    }, {});
}
