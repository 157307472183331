<div fxLayout="column" fxLayoutGap="16px">
  <ng-container *ngIf="conflicts$ | async as conflicts">
    <div *ngIf="conflicts.length">
      <h3 class="mat-headline-6">Scheduling Conflicts</h3>
      <div *ngFor="let conflict of conflicts; trackBy: trackByReason">
        {{ conflict.reason }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tags$ | async as tags">
    <div *ngIf="tags.length">
      <h3 class="mat-headline-6">Tags</h3>
      <pr-inline-tags-display [tags]="tags" />
    </div>
  </ng-container>
</div>
