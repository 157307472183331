import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import {
  IsIpRestrictedGuard,
  IsTimeRestrictedGuard,
} from '@principle-theorem/ng-principle-shared';
import { InfrastructureTestsComponent } from './infrastructure-tests.component';
import { TestPagesModule } from './test-pages.module';

const sharedSettingsGuards = [IsIpRestrictedGuard, IsTimeRestrictedGuard];

const routes: Routes = [
  {
    path: '',
    canActivate: sharedSettingsGuards,
    component: InfrastructureTestsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TestPagesModule],
  exports: [RouterModule],
})
export class TestPagesRoutingModule {}
