<mat-toolbar color="accent">Edit Treatment Step Details</mat-toolbar>

<form [formGroup]="form">
  <mat-dialog-content>
    <div fxLayout="column">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" [required]="true" formControlName="name" />
      </mat-form-field>

      <div formGroupName="schedulingRules" fxLayout="column" fxLayoutGap="16px">
        <mat-form-field floatLabel="always">
          <mat-label>Duration</mat-label>
          <input
            type="number"
            matInput
            min="0"
            [placeholder]="stepDuration$ | async"
            formControlName="duration"
          />
          <span matTextSuffix>mins</span>
        </mat-form-field>
        <mat-slide-toggle
          *ngIf="stepIndex > 0"
          [checked]="showAdvanced"
          (change)="updateDates($event)"
        >
          Advanced
        </mat-slide-toggle>
        <div *ngIf="showAdvanced">
          <div class="description">
            <p>
              Here you can specify if the step needs to be booked a certain time
              after the previous step. A step can be booked after the previous
              in several ways:
            </p>
            <ul class="list-inside list-disc">
              <li>At least X days after</li>
              <li>At most X days after</li>
              <li>Or both</li>
            </ul>
          </div>
          <div class="flex items-center justify-between gap-4">
            <div class="flex-1">
              Schedule more than X days after previous step
            </div>
            <mat-form-field class="basis-1/4">
              <mat-label>Min Days</mat-label>
              <input
                matInput
                type="number"
                min="0"
                maxlength="3"
                formControlName="minDays"
              />
              <button
                mat-icon-button
                aria-label="Clear"
                matIconSuffix
                (click)="clearMinDays()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="flex items-center justify-between gap-4">
            <div class="flex-1">
              Schedule less than X days after previous step
            </div>
            <mat-form-field class="basis-1/4">
              <mat-label>Max Days</mat-label>
              <input
                matInput
                type="number"
                min="0"
                maxlength="3"
                formControlName="maxDays"
              />
              <button
                mat-icon-button
                aria-label="Clear"
                matIconSuffix
                (click)="clearMaxDays()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="save()">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
