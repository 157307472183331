import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeGuard',
})
export class TypeGuardPipe implements PipeTransform {
  transform<T, R extends T, S>(
    value: T,
    typeGuardFn: (value: T) => value is R,
    thisArg?: S
  ): R | undefined {
    if (thisArg) {
      typeGuardFn = typeGuardFn.bind(thisArg);
    }
    if (!typeGuardFn(value)) {
      return;
    }
    return value;
  }
}
