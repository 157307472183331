import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  type IOutstandingInvoicesReportState,
  OUTSTANDING_INVOICES_REPORT_FEATURE_KEY,
} from './outstanding-invoices.reducers';

export const getOverdueInvoicesReportState =
  createFeatureSelector<IOutstandingInvoicesReportState>(
    OUTSTANDING_INVOICES_REPORT_FEATURE_KEY
  );

export const getQuery = createSelector(
  getOverdueInvoicesReportState,
  (state) => state.query
);

export const getOutstandingInvoicesReport = createSelector(
  getOverdueInvoicesReportState,
  (state) => state.results
);

export const getIsLoading = createSelector(
  getOverdueInvoicesReportState,
  (state) => state.isLoading
);

export const getErrorMessage = createSelector(
  getOverdueInvoicesReportState,
  (state) => state.errorMessage
);
