<div fxLayout="column" class="page-container">
  <div
    class="schedule-header bg-white"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <pr-date-range-pagination
      [nextDayDisabled]="datePickerDisplayBloc.nextDayDisabled$ | ngrxPush"
      [nextWeekDisabled]="datePickerDisplayBloc.nextWeekDisabled$ | ngrxPush"
      [nextMonthDisabled]="datePickerDisplayBloc.nextMonthDisabled$ | ngrxPush"
      [datePickerDisabled]="
        datePickerDisplayBloc.datePickerDisabled$ | ngrxPush
      "
      [dateRange]="range$ | ngrxPush"
      (dateRangeChange)="dateRangeChange($event)"
      [timezone]="timezone$ | async"
    />

    <pr-calendar-range-header
      fxFlex
      [isCalendarView]="isCalendarView$ | ngrxPush"
      [unit]="unit$ | ngrxPush"
      [range]="range$ | ngrxPush"
    />

    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        *ngFor="let link of links; trackBy: trackByLink"
        [routerLink]="link.path"
        routerLinkActive
        queryParamsHandling="preserve"
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ link.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel />
  </div>

  <router-outlet />
</div>
