<mat-toolbar color="accent">Split Account Credit</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content class="bg-slate-100">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <div>
          <h3 class="!m-0 !font-bold text-black">New Account Credit</h3>
          <p>
            The original credit will be reduced by this amount and a new credit
            will be created. The original invoice will be updated to reflect the
            change in any deposits taken.
          </p>
        </div>

        <div class="flex flex-col gap-4 md:flex-row">
          <mat-form-field class="flex md:w-1/2" subscriptSizing="dynamic">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>

          <mat-form-field class="flex md:w-1/2" subscriptSizing="dynamic">
            <mat-label>Amount</mat-label>
            <input
              matInput
              formControlName="amount"
              [min]="0.01"
              [max]="data.credit.amount"
              type="number"
              step="0.01"
            />
            <span matTextPrefix>$&nbsp;</span>
            <span matTextSuffix
              >&sol;&nbsp;{{ data.credit.amount | currency }}</span
            >
          </mat-form-field>
        </div>

        <div class="flex flex-col gap-4 md:flex-row">
          <pr-staff-selector
            class="flex md:w-1/2"
            subscriptSizing="dynamic"
            formControlName="practitioner"
            [practice]="data.practice"
            label="Reserve for Practitioner"
            [permissions]="receiveDepositsPermissions"
            [showNone]="true"
          />

          <mat-form-field
            class="flex md:w-1/2"
            subscriptSizing="dynamic"
            floatLabel="always"
          >
            <mat-label>Treatment Category</mat-label>
            <mat-select
              formControlName="treatmentCategory"
              [compareWith]="treatmentCategoryBloc.compareFn"
              placeholder="None"
            >
              <mat-select-trigger>
                @if (
                  treatmentCategoryBloc.selected$ | async;
                  as treatmentCategory
                ) {
                  {{ treatmentCategory.name }}
                  <div
                    class="inline-block h-3 w-3 rounded-full"
                    [style.backgroundColor]="treatmentCategory.colour.value"
                  ></div>
                }
              </mat-select-trigger>
              <mat-option>None</mat-option>
              @for (
                treatmentCategory of treatmentCategoryBloc.options$ | async;
                track treatmentCategory.ref.path
              ) {
                <mat-option [value]="treatmentCategory.ref">
                  {{ treatmentCategory.name }}
                  <div
                    class="inline-block h-3 w-3 rounded-full"
                    [style.backgroundColor]="treatmentCategory.colour.value"
                  ></div>
                </mat-option>
              }
            </mat-select>
            <button
              type="button"
              matIconSuffix
              mat-icon-button
              [disabled]="treatmentCategoryBloc.resetDisabled$ | async"
              (click)="treatmentCategoryBloc.clear($event)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div
          class="flex flex-col gap-4 md:flex-row"
          *prHasPermission="accountAdminPermission"
        >
          <mat-form-field class="flex md:w-1/2">
            <mat-label>Date Created</mat-label>
            <input
              matInput
              formControlName="createdAt"
              [matDatepicker]="createdAtPicker"
              [min]="depositPaidDate$ | async"
              [max]="today"
              (focus)="createdAtPicker.open()"
              (click)="createdAtPicker.open()"
            />
            @if (depositPaidDate$ | async; as depositPaidDate) {
              <mat-hint>
                Deposit paid at
                {{ depositPaidDate | moment | amDateFormat: dateFormat }}
              </mat-hint>
            }
            <mat-datepicker-toggle matIconSuffix [for]="createdAtPicker" />
            <mat-datepicker #createdAtPicker />
          </mat-form-field>
          <div class="flex md:w-1/2"></div>
        </div>
      </div>

      @if (creditsUsed$ | async; as creditsUsed) {
        @if (creditsUsed.length) {
          <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
            <div>
              <h3 class="!m-0 !font-bold text-black">
                Account Credit has been used
              </h3>
              <div>
                The original Account Credit has been used for the following
                transactions. Select the transactions you want to use the new
                Account Credit for instead.
              </div>
              @if (mustUseAtLeast$ | async; as mustUseAtLeast) {
                <div
                  [ngClass]="{ 'text-red-500': usedCreditIsInvalid$ | async }"
                >
                  You must use at least {{ mustUseAtLeast | currency }} of the
                  new Account Credit.
                </div>
              }
            </div>

            <div class="flex flex-col gap-1">
              <div
                class="flex flex-row gap-4 border-b border-solid border-slate-300 pb-1 text-xs opacity-70"
              >
                <div class="flex grow">Invoice & Transaction</div>
                <div class="w-32">Date</div>
                <div class="w-20">Credit Used</div>
                <div class="w-12"></div>
              </div>
              @for (
                creditUsed of creditsUsed;
                track creditUsed.transaction.ref.id
              ) {
                <div class="flex flex-row items-center gap-4">
                  <div class="flex grow flex-col">
                    <a
                      [routerLink]="
                        creditUsed.invoice | map: getInvoiceLink : this | async
                      "
                      target="_blank"
                      class="truncate text-sm"
                    >
                      {{ creditUsed.invoice.reference }}
                    </a>
                    <div class="truncate text-xs opacity-50">
                      {{ creditUsed.transaction.reference }}
                    </div>
                  </div>
                  <div class="w-32 text-xs opacity-50">
                    {{
                      creditUsed.transaction.createdAt
                        | moment
                        | amDateFormat: dateTimeFormat
                    }}
                  </div>
                  <div class="w-20 text-sm">
                    {{ creditUsed.usedAccountCredit.amount | currency }}
                  </div>
                  <div class="w-12">
                    <mat-checkbox
                      (change)="selectTransaction($event, creditUsed)"
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        }
      }
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="bg-slate-100">
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="isDisabled$ | async"
      >
        Split
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
