import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartedConfigurationFacade } from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { surfaceFromRef, TOOTH_GRAPH } from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  type IChartedCondition,
  type IChartedRef,
  type IToothRef,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { type UndirectedGraph } from '@principle-theorem/shared';
import { intersection, intersectionWith, isEqual } from 'lodash';
import { combineLatest, type Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedSurfaceCollection } from '../dental-chart-svg/chart-surface/selected-surface-collection';

@Component({
    selector: 'pr-chart-treatment-suggestions',
    templateUrl: './chart-treatment-suggestions.component.html',
    styleUrls: ['./chart-treatment-suggestions.component.sass'],
    standalone: false
})
export class ChartTreatmentSuggestionsComponent {
  private _chartedConditions$: Subject<IChartedCondition[]> = new Subject();
  private _treatments$: Observable<ITreatmentConfiguration[]>;
  private _graph: UndirectedGraph<IToothRef> = TOOTH_GRAPH;
  trackByTreatment = TrackByFunctions.field<ITreatmentConfiguration>('name');
  suggestedTreatments$: Observable<ITreatmentConfiguration[]>;
  @Input() selectedSurfaces: Partial<IChartedRef>[] = [];
  @Output()
  chartableSelected = new EventEmitter<ITreatmentConfiguration>();

  @Input()
  set chartedConditions(conditions: IChartedCondition[]) {
    this._chartedConditions$.next(conditions);
  }

  constructor(private _configurationStore: ChartedConfigurationFacade) {
    this._treatments$ = this._configurationStore.treatmentConfigurations$;
    this.suggestedTreatments$ = combineLatest([
      this._treatments$,
      this._chartedConditions$,
    ]).pipe(
      map(([treatments, chartedConditions]) =>
        treatments
          .filter((treatmentConfiguration) => {
            return this._canTreatChartedConditions(
              treatmentConfiguration,
              chartedConditions
            );
          })
          .filter((treatmentConfig) => {
            return this._canBeChartedOnSelectedSurfaces(treatmentConfig);
          })
      )
    );
  }

  addTreatment(treatment: ITreatmentConfiguration): void {
    this.chartableSelected.emit(treatment);
  }

  private _canTreatChartedConditions(
    configuration: ITreatmentConfiguration,
    conditions: IChartedCondition[]
  ): boolean {
    return configuration.conditions.some((conditionRef: DocumentReference) => {
      return conditions
        .map(
          (chartedCondition: IChartedCondition) =>
            chartedCondition.config.ref.path
        )
        .includes(conditionRef.path);
    });
  }

  private _canBeChartedOnSelectedSurfaces(
    configuration: ITreatmentConfiguration
  ): boolean {
    const selectedSurfaces: ChartableSurface[] = this.selectedSurfaces.map(
      (surface: Partial<IChartedRef>) => surfaceFromRef(surface)
    );

    if (
      configuration.availableSurfaces.includes(ChartableSurface.MultipleTeeth)
    ) {
      return this._multipleAreSelected();
    }
    return (
      intersection(configuration.availableSurfaces, selectedSurfaces).length !==
      0
    );
  }

  private _multipleAreSelected(): boolean {
    if (!this.selectedSurfaces.length) {
      return false;
    }

    const collection: SelectedSurfaceCollection = new SelectedSurfaceCollection(
      this.selectedSurfaces
    );
    return collection
      .getTeeth()
      .some((tooth: IToothRef, _: number, teeth: IToothRef[]) => {
        const neighbours: IToothRef[] = this._graph.getNeighbours(tooth);
        return intersectionWith(neighbours, teeth, isEqual).length >= 1;
      });
  }
}
