<mat-toolbar color="accent">
  <span>Prescription</span>
</mat-toolbar>

<mat-dialog-content>
  @if (
    prescription.prescriptions().length && !prescription.selectedPrescription()
  ) {
    <h2 class="text-xl font-bold">Continue editing a draft prescription?</h2>
    <pr-content-container margin="mb-8" padding="p-0">
      <div class="flex h-full flex-col">
        <pr-patient-prescriptions-list
          [prescriptions]="prescription.prescriptions()"
          (prescriptionSelected)="prescription.selected$.next($event)"
        />
      </div>
    </pr-content-container>
  }

  @if (!prescription.selectedPrescription()) {
    <h2 class="text-xl font-bold">Create a new prescription?</h2>
    <pr-content-container>
      <div class="flex flex-col gap-4">
        <pr-practitioner-selector
          label="Create As"
          class="w-full"
          [staffer]="prescribingAs()"
          [staff]="organisation.prescribers$ | async"
          (stafferSelected)="prescribingAs.set($event)"
        />
        <div [matTooltip]="feedbackMessage()">
          <button
            class="w-full"
            [disabled]="!prescribingAs()"
            mat-stroked-button
            (click)="create()"
          >
            <mat-icon>add</mat-icon>
            Create Prescription
          </button>
        </div>
      </div>
    </pr-content-container>
  }

  @if (prescription.selectedPrescription()) {
    <pr-patient-prescription-form
      (deleteChange)="delete($event)"
      [compact]="true"
    />
  }
</mat-dialog-content>
