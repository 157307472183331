<div>
  <div
    class="search-bar"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="16px"
  >
    <pt-search-field fxFlex>
      <input
        matInput
        placeholder="Search by title, lab, patient, or status"
        #search
        (keyup)="searchLabJobs(search.value)"
      />
      <button mat-icon-button fxHide.gt-xs fxShow (click)="addNewLabJob()">
        <mat-icon class="add-lab-job-icon">add</mat-icon>
      </button>
    </pt-search-field>
    <button
      mat-raised-button
      fxHide
      fxShow.gt-xs
      color="primary"
      (click)="addNewLabJob()"
    >
      Create Lab Job
    </button>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="end center"
    fxLayoutGap="16px"
  >
    <mat-button-toggle-group
      class="filter-presets"
      (change)="updatePresetFilter($event.value)"
      [value]="presetFilter$ | async"
    >
      <mat-button-toggle
        *ngFor="let filter of filters; trackBy: trackByFilter"
        [value]="filter.id"
      >
        {{ filter.name }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div fxFlex fxHide fxShow.gt-xs></div>

    <mat-button-toggle-group
      (change)="updateStatusFilter($event.value)"
      [value]="statusFilter$ | async"
    >
      <mat-button-toggle value="sending">Sending</mat-button-toggle>
      <mat-button-toggle value="sent">Sent</mat-button-toggle>
      <mat-button-toggle value="received">Received</mat-button-toggle>
    </mat-button-toggle-group>

    <div
      class="lab-jobs-filter-container"
      fxLayout="row"
      fxLayoutAlign.gt-sm="end center"
      fxLayoutGap="16px"
    >
      <div class="mat-body-2">
        <mat-form-field subscriptSizing="dynamic" class="w-36">
          <mat-label>Sort By</mat-label>
          <mat-select
            [formControl]="sortControl"
            #sort="matSort"
            matSort
            [matSortActive]="activeSort$ | async"
            [matSortDirection]="activeSortDirection$ | async"
            (matSortChange)="sortChange($event)"
          >
            <mat-option
              *ngFor="let option of sortOptions; trackBy: trackByOption"
              [value]="option"
            >
              <span mat-sort-header="{{ option.id }}" [disableClear]="true">
                {{ option.label }}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div class="lab-job-list-container mat-elevation-z1">
  <mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="center stretch">
      <div class="checkbox">
        <mat-checkbox
          color="primary"
          [indeterminate]="selectionList.isSomeSelected$ | async"
          [checked]="selectionList.isAllSelected$ | async"
          (change)="selectionList.toggleAllSelected()"
        />
      </div>

      <ng-container *ngIf="(selectionList.isNoneSelected$ | async) === false">
        <mat-divider [vertical]="true" />

        <div fxFlex>
          <button
            mat-icon-button
            [matMenuTriggerFor]="statusMenu"
            matTooltip="Change Status"
            matTooltipShowDelay="500"
          >
            <mat-icon>low_priority</mat-icon>
          </button>

          <mat-menu #statusMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let status of statuses; trackBy: trackByStatus"
              (click)="updateStatus(status)"
            >
              {{ status | titlecase }}
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </mat-toolbar>
  <div class="lab-job-list">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="dataSource.loading$ | async"
    />

    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="search.value"
    />

    <pr-empty-state
      *ngIf="emptyState$ | async"
      image="list"
      [title]="'lab jobs ' + (statusFilter$ | async)"
    />

    <pr-lab-job-list-display
      [sort]="sort"
      [dataSource]="dataSource"
      [selectionList]="selectionList"
      [(selectedItem)]="selectedLabJob"
      (selectedItemChange)="editLabJob($event)"
    />
  </div>
</div>
