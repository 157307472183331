import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TreatmentPlanFacade } from '@principle-theorem/ng-clinical-charting/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Patient, TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IStaffer,
  isTreatmentPlan,
  type ITreatmentPlan,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  PatientRelationshipType,
  ITreatmentPlanWithBookableStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getEnumValues,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TreatmentStepsEditorService } from '../treatment-steps-editor/treatment-steps-editor.service';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';

@Component({
  selector: 'pr-treatment-plans',
  templateUrl: './treatment-plans.component.html',
  styleUrls: ['./treatment-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'prTreatmentPlans',
})
export class TreatmentPlansComponent {
  patient$ = new ReplaySubject<WithRef<IPatient> | undefined>(1);
  currentPractitioner$ = new ReplaySubject<WithRef<IStaffer>>(1);
  filteredTreatmentPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  trackByTreatmentPlan = TrackByFunctions.ref<WithRef<ITreatmentPlan>>();
  statuses$ = new BehaviorSubject<TreatmentPlanStatus[]>(
    getEnumValues(TreatmentPlanStatus)
  );
  filterStepStatuses$ = new BehaviorSubject<TreatmentStepStatus[] | undefined>(
    getEnumValues(TreatmentStepStatus)
  );
  @Output() stepAdded = new EventEmitter<ITreatmentPlanWithBookableStep>();
  @Input() disabled = false;

  @Input()
  set currentPractitioner(currentPractitioner: WithRef<IStaffer>) {
    if (currentPractitioner) {
      this.currentPractitioner$.next(currentPractitioner);
    }
  }

  @Input()
  set statuses(statuses: TreatmentPlanStatus[]) {
    if (statuses) {
      this.statuses$.next(statuses);
    }
  }

  @Input()
  set filterStepStatuses(filterStepStatuses: TreatmentStepStatus[]) {
    if (filterStepStatuses) {
      this.filterStepStatuses$.next(filterStepStatuses);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    this.patient$.next(patient);
  }

  constructor(
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _editorService: TreatmentStepsEditorService,
    private _currentScope: CurrentScopeFacade,
    public stepDisplay: TreatmentStepsDisplayService
  ) {
    this.filteredTreatmentPlans$ = combineLatest([
      this.patient$.pipe(
        switchMap((patient) =>
          patient
            ? Patient.withPatientRelationships$(
                patient,
                [PatientRelationshipType.DuplicatePatient],
                TreatmentPlan.all$
              )
            : of([])
        ),
        switchMap(async (plans) => {
          const activePlans = await TreatmentPlan.sortPlansByMostRecentStep(
            plans.filter(TreatmentPlan.canSchedule)
          );
          const inactivePlans = await TreatmentPlan.sortPlansByMostRecentStep(
            plans.filter((plan) => !TreatmentPlan.canSchedule(plan))
          );
          return [...activePlans, ...inactivePlans];
        })
      ),
      this.statuses$,
    ]).pipe(
      map(([plans, statuses]) =>
        plans.filter((plan) => statuses.includes(plan.status))
      )
    );
  }

  canAddStep(plan: WithRef<ITreatmentPlan>): boolean {
    return isTreatmentPlan(plan);
  }

  async addBookableStep(plan: WithRef<ITreatmentPlan>): Promise<void> {
    const stepRef = await this._treatmentPlanFacade.addStep(plan);
    const patient = await snapshot(this.patient$);
    const practitioner = await snapshot(this.currentPractitioner$);
    const practice = await snapshot(this._currentScope.currentPractice$);
    const step = await Firestore.getDoc(stepRef);

    if (!patient || !practice) {
      return;
    }

    const appointment = await this._editorService.generateAppointmentForStep(
      patient,
      practitioner,
      practice,
      plan,
      step
    );

    this.stepAdded.emit({
      plan,
      step: {
        ...step,
        appointment: appointment.ref,
      },
    });
  }
}
