import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import {
  type IAppointment,
  type IInteraction,
  type IInteractionV2,
  type IPatient,
  isInteractionV2,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-appointment-sidebar',
  templateUrl: './appointment-sidebar.component.html',
  styleUrls: ['./appointment-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSidebarComponent {
  interactions$ = new BehaviorSubject<IInteractionV2[]>([]);
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  hasNotifications$ = new ReplaySubject<boolean>(1);

  @Output() interactionsChange = new EventEmitter<IInteractionV2[]>();
  @Input() patient?: WithRef<IPatient>;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set interactions(interactions: IInteractionV2[]) {
    if (interactions) {
      this.interactions$.next(interactions);
    }
  }

  async addInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this.interactionsChange.emit([...interactions, interaction]);
  }

  async updateInteraction(
    updatedInteraction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(updatedInteraction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this.interactionsChange.emit(
      interactions.map((interaction) =>
        interaction.uid === updatedInteraction.uid
          ? updatedInteraction
          : interaction
      )
    );
  }

  async deleteInteraction(
    deletedInteraction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(deletedInteraction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this.interactionsChange.emit(
      interactions.filter(
        (interaction) => interaction.uid !== deletedInteraction.uid
      )
    );
  }
}
