import {
  ChartableSurfaceResolver,
  ChartedItemScopeResolver,
  ChartedItemTotalCalculator,
  ChartedMultiStepTreatment,
  ChartedSurface,
  ChartedTreatment,
  ChartedTreatmentUpdater,
  type FeeScheduleManager,
  type ISurfaceScopeRefPair,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentStep,
  upsertTreatmentsToStep,
  PricedServiceCodeGroup,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  ServiceCodeGroupType,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type IMultiTreatmentConfiguration,
  type IMultiTreatmentPackage,
  type IPricedServiceCodeEntry,
  type IStaffer,
  type ITreatmentConfiguration,
  type ITreatmentStep,
  type ITreatmentStepConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getDoc,
  type INamedDocument,
  reduceToSingleArrayFn,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { first, flatten, isEqual, sumBy } from 'lodash';
import {
  type ITreatmentAndSurface,
  type ITreatmentStepWithSurfaces,
} from './multi-treatment-selector.store';
import { v4 as uuid } from 'uuid';
import { type Observable } from 'rxjs';

export class MultiTreatmentBuilder {
  constructor(
    private _feeScheduleManager: FeeScheduleManager,
    private _chartingAs$: Observable<WithRef<IStaffer>>
  ) {}

  applyPackage(
    treatmentPackage: IMultiTreatmentPackage,
    stepsWithSurfaces: ITreatmentStepWithSurfaces[],
    feeSchedule: WithRef<IFeeSchedule>
  ): ITreatmentStepWithSurfaces[] {
    const calc = new ChartedItemTotalCalculator();

    return stepsWithSurfaces.map((step, index) => {
      const packageStep = treatmentPackage.steps[index];
      if (!packageStep) {
        return step;
      }

      const treatments = step.treatments.map((treatment, treatmentIndex) => {
        const packageTreatment = packageStep.treatments[treatmentIndex];
        if (!packageTreatment) {
          return treatment;
        }

        const pricedServiceCodes = treatment.pricedServiceCodes.map(
          (pricedServiceCode) => {
            const packageCode = packageTreatment.priceOverrides.find(
              (code) =>
                code.code === pricedServiceCode.code &&
                code.type === pricedServiceCode.type
            );

            if (!packageCode) {
              return pricedServiceCode;
            }

            return {
              ...pricedServiceCode,
              priceOverride: packageCode.price,
            };
          }
        );

        const chartedTreatment = ChartedTreatment.init({
          config: treatment.treatment,
          feeSchedule: feeSchedule,
          scopeRef: {
            scope: ChartableSurface.Unscoped,
          },
          serviceCodes: pricedServiceCodes,
        });

        const treatmentPrice = calc.treatment(chartedTreatment);
        return {
          ...treatment,
          pricedServiceCodes,
          price: treatment.disabled
            ? treatmentPrice
            : treatmentPrice * treatment.chartedSurfaces.length,
        };
      });

      return {
        ...step,
        treatments,
        price: sumBy(treatments, 'price'),
      };
    });
  }

  async getChartedMultiTreatment(
    configuration: WithRef<IMultiTreatmentConfiguration>,
    stepsWithSurfaces: ITreatmentStepWithSurfaces[],
    feeSchedule: WithRef<IFeeSchedule>,
    selectedPackage?: IMultiTreatmentPackage
  ): Promise<IChartedMultiStepTreatment> {
    const multiTreatment = await ChartedMultiStepTreatment.fromConfig(
      configuration,
      feeSchedule,
      {
        scope: ChartableSurface.WholeMouth,
      }
    );
    const steps = await asyncForEach(stepsWithSurfaces, (step) =>
      this.stepToChartedStep(step, feeSchedule)
    );

    const combinedSurfaces = steps
      .map((step) =>
        step.treatments
          .map((treatment) => treatment.chartedSurfaces)
          .reduce(reduceToSingleArrayFn, [])
      )
      .reduce(reduceToSingleArrayFn, []);

    return {
      ...multiTreatment,
      steps,
      chartedSurfaces: combinedSurfaces,
      treatmentPackageId: selectedPackage?.uid,
    };
  }

  async initialiseTreatmentStepWithSurfaces(
    step: ITreatmentStepConfiguration,
    selectedSurfaces: Partial<IChartedRef>[],
    feeSchedule: WithRef<IFeeSchedule>
  ): Promise<ITreatmentStepWithSurfaces> {
    const treatments = await asyncForEach(step.treatments, async (treatment) =>
      this._getTreatmentAndSurface(
        await getDoc(treatment.ref),
        treatment.quantity,
        feeSchedule,
        selectedSurfaces
      )
    );
    return {
      step,
      treatments,
      price: sumBy(treatments, 'price'),
    };
  }

  async stepToChartedStep(
    step: ITreatmentStepWithSurfaces,
    feeSchedule: WithRef<IFeeSchedule>
  ): Promise<IChartedMultiStepTreatmentStep> {
    const treatmentStep = TreatmentStep.init({
      name: step.step.name,
      schedulingRules: step.step.schedulingRules,
    });
    const combinedTreatments: IChartedTreatment[] = [];
    await asyncForEach(step.treatments, async (treatment) => {
      const resolver = new ChartedItemScopeResolver();
      let surfaceScopeRefPairs = resolver.reduceChartedSurfacesToScope(
        treatment.treatment,
        treatment.chartedSurfaces
      );

      if (surfaceScopeRefPairs.length) {
        const chartedTreatments: IChartedTreatment[] =
          await this.getChartedTreatments(
            treatmentStep,
            treatment,
            surfaceScopeRefPairs,
            feeSchedule
          );
        combinedTreatments.push(...chartedTreatments);
        return;
      }

      surfaceScopeRefPairs = [
        {
          surfaces: [],
          scopeRef: {
            scope: resolver.determineScope(
              treatment.treatment,
              treatment.chartedSurfaces
            ),
          },
        },
      ];

      for (let index = 0; index < treatment.quantity; index++) {
        const chartedTreatments: IChartedTreatment[] =
          await this.getChartedTreatments(
            treatmentStep,
            treatment,
            surfaceScopeRefPairs,
            feeSchedule
          );
        combinedTreatments.push(...chartedTreatments);
      }
    });

    const synced = await ChartedTreatmentUpdater.syncPricingRules(
      combinedTreatments
    );
    return { ...treatmentStep, treatments: synced, uid: uuid() };
  }

  async getChartedTreatments(
    treatmentStep: ITreatmentStep,
    treatment: ITreatmentAndSurface,
    surfaceScopeRefPairs: ISurfaceScopeRefPair[],
    feeSchedule: WithRef<IFeeSchedule>
  ): Promise<IChartedTreatment[]> {
    const resolver = new ChartedItemScopeResolver();

    const { treatments } = await upsertTreatmentsToStep(
      treatmentStep,
      treatment.treatment,
      surfaceScopeRefPairs,
      resolver,
      feeSchedule
    );

    return treatments.map((chartedTreatment) => ({
      ...chartedTreatment,
      serviceCodes: this._addChartedSurfacesToServiceCodes(
        PricedServiceCodeEntry.duplicateCodes(treatment.pricedServiceCodes),
        chartedTreatment.chartedSurfaces
      ),
      serviceCodeGroups: treatment.serviceCodeGroups.map((group) => ({
        ...group,
        serviceCodes: this._addChartedSurfacesToServiceCodes(
          PricedServiceCodeEntry.duplicateCodes(group.serviceCodes),
          chartedTreatment.chartedSurfaces
        ),
      })),
    }));
  }

  private async _getTreatmentAndSurface(
    treatment: WithRef<ITreatmentConfiguration>,
    quantity: number,
    feeSchedule: WithRef<IFeeSchedule>,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<ITreatmentAndSurface> {
    const calc = new ChartedItemTotalCalculator();
    const requiredCodes = TreatmentConfiguration.getCombinedServiceCodes(
      treatment,
      [ServiceCodeGroupType.Required]
    );
    const serviceCodes = await asyncForEach(requiredCodes, (serviceCode) =>
      PricedServiceCodeEntry.applyFeeSchedule(
        serviceCode,
        requiredCodes,
        this._feeScheduleManager,
        false
      )
    );
    const serviceCodeGroups = TreatmentConfiguration.getServiceGroups(
      treatment,
      [ServiceCodeGroupType.Exclusive]
    );
    const groupCodes = flatten(
      serviceCodeGroups.map((group) => group.serviceCodes)
    );
    const pricedServiceCodeGroups = await asyncForEach(
      serviceCodeGroups,
      (serviceCodeGroup) => {
        return PricedServiceCodeGroup.applyFeeSchedule(
          serviceCodeGroup,
          groupCodes,
          this._feeScheduleManager,
          true,
          false
        );
      }
    );

    let disabled = false;
    const compatibleSurfaces =
      ChartableSurfaceResolver.getChartableSurfaces(treatment);

    if (
      isEqual(compatibleSurfaces, [ChartableSurface.Unscoped]) ||
      isEqual(compatibleSurfaces, [ChartableSurface.WholeMouth]) ||
      !compatibleSurfaces.length
    ) {
      disabled = true;
    }

    const chartingAs = await snapshot(this._chartingAs$);
    const chartedSurfaces: IChartedSurface[] = this._getChartedSurfaces(
      compatibleSurfaces,
      stafferToNamedDoc(chartingAs),
      treatment,
      selectedSurfaces
    );

    const chartedTreatments = await ChartedTreatmentUpdater.syncPricingRules([
      ChartedTreatment.init({
        config: treatment,
        feeSchedule,
        scopeRef: {
          scope: ChartableSurface.Unscoped,
        },
        serviceCodes,
        serviceCodeGroups: pricedServiceCodeGroups,
      }),
    ]);

    const chartedTreatment = first(chartedTreatments);
    const treatmentWithSurfaces: ITreatmentAndSurface = {
      treatment,
      quantity,
      pricedServiceCodes: chartedTreatment
        ? chartedTreatment.serviceCodes
        : serviceCodes,
      serviceCodeGroups: chartedTreatment
        ? chartedTreatment.serviceCodeGroups
        : pricedServiceCodeGroups,
      price: !disabled
        ? 0
        : chartedTreatment
        ? calc.treatment(chartedTreatment)
        : 0,
      chartedSurfaces,
      disabled,
    };

    return treatmentWithSurfaces;
  }

  private _getChartedSurfaces(
    compatibleSurfaces: ChartableSurface[],
    chartedBy: INamedDocument<IStaffer>,
    treatment: WithRef<ITreatmentConfiguration>,
    selectedSurfaces: Partial<IChartedRef>[]
  ): IChartedSurface[] {
    if (isEqual(compatibleSurfaces, [ChartableSurface.WholeMouth])) {
      return [
        ChartedSurface.init({
          chartedRef: {
            wholeMouth: true,
          },
          chartedBy,
        }),
      ];
    }

    if (
      isEqual(compatibleSurfaces, [ChartableSurface.Unscoped]) ||
      !compatibleSurfaces.length
    ) {
      return [
        ChartedSurface.init({
          chartedRef: {
            unscoped: true,
          },
          chartedBy,
        }),
      ];
    }

    if (!selectedSurfaces.length) {
      return [];
    }

    const chartedRefs = ChartableSurfaceResolver.getChartedRefs(
      treatment,
      selectedSurfaces
    );
    return chartedRefs.map((chartedRef) =>
      ChartedSurface.init({ chartedRef, chartedBy })
    );
  }

  private _addChartedSurfacesToServiceCodes(
    serviceCodes: IPricedServiceCodeEntry[],
    chartedSurfaces: IChartedSurface[]
  ): IPricedServiceCodeEntry[] {
    return serviceCodes.map((serviceCode) => ({
      ...serviceCode,
      chartedSurfaces,
    }));
  }
}
