/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNil, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_DEPOSIT_DESTINATION_ENTITY } from '../../../destination/entities/patient-deposits';
import { PATIENT_INTERACTION_DESTINATION_ENTITY } from '../../../destination/entities/patient-interactions';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_CLINICAL_CHART_DESTINATION_ENTITY } from '../../destination/entities/patient-clinical-charts';
import { PATIENT_FILE_DESTINATION_ENTITY } from '../../destination/entities/patient-files';
import { PATIENT_RELATIONSHIP_DESTINATION_ENTITY } from '../../destination/entities/patient-relationships';
import { PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY } from '../../destination/entities/patient-treatment-plan';
import { PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY } from '../../destination/entities/patient-treatment-plan-proposal';
import { PATIENT_DESTINATION_ENTITY } from '../../destination/entities/patients';

export const PATIENT_RESOURCE_TYPE = 'patient';

export const PATIENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient List',
    description: '',
    idPrefix: 'patient',
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPraktikaPatientTranslations {
  dob?: Timestamp;
  dateJoined: Timestamp;
}

export interface IPraktikaPatientFilters {
  dateJoined: Timestamp;
}

export enum PraktikaPatientStatusLabel {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Current = 'CURRENT',
  Suspended = 'SUSPENDED',
  Deceased = 'DECEASED',
}

export enum PraktikaPatientStatus {
  Current = 1,
  Guarantor = 2,
  Deceased = 3,
  Suspended = 4,
  Inactive = 5,
  Deleted = 6,
}

export interface IPraktikaPatient {
  patient_id: number | string;
  patient_title: string | null;
  patient_firstname: string;
  patient_lastname: string;
  patient_preferredname: string | null;
  patient_gender: 'F' | 'M' | 'O' | null;
  patient_dob: string | null; // '25/07/1972'
  patient_phone_home: string;
  patient_phone_mobile: string;
  patient_phone_work: string;
  patient_statusid: PraktikaPatientStatus;
  patient_notes: string | null;
  patient_defaultfeescheduleid: number;
  patient_medicalhistorydate: string | null;
  patient_nonrecall: false;
  patient_datejoined: string; // '2013-08-17'
  patient_email_personal: string;
  patient_healthfund_name: string | null;
  patient_healthfund_membernumber: string | null;
  patient_medicare_number: string | null;
  patient_medicare_subnumerate: string | null;
  patient_preferredproviderid: number | null;
  patient_address_street: string | null;
  patient_address_suburb: string | null;
  patient_address_state: string | null;
  patient_address_postcode: string | number | null;

  // These need to be refactored for use elsewhere
  // patient_perioexamids: number[];
  // patient_dentition: IPraktikaPatientClinicalChart[];
  // patient_treatmentplan: IPraktikaPatientTreatmentPlan[];
  // patient_depositids: number[];

  // These have queries
  // patient_appointmentids: Record<
  //   PraktikaAppointmentId,
  //   PraktikaAppointmentStatus
  // >;
  // patient_images: IPraktikaPatientImage[];
  // patient_quotes: IPraktikaPatientQuote[];
  // patient_communication: IPraktikaPatientCommunication[];

  // These aren't required
  // patient_healthfund_membershipnumber: string | null;
  // patient_medicare_expirydate: string | null;
  // patient_guarantors: IPraktikaPatientRelationship[];
  // patient_fullname: string;
  // patient_shortname: string;
  // patient_birthdate: string | null; // '1972-07-25'
  // patient_photofileid: number;
  // patient_alerts: IPatientAlerts;
  // patient_medicalhistory: Record<string, IPatientMedicalHistoryQuestion>;
  // patient_allergyhistory: Record<string, IPatientMedicalHistoryAllergy>;
  // patient_number: number;
  // patient_referrals: unknown[];
  // patient_allergysummary: string;
  // patient_medicalsummary: string;
  // patient_recall: IPraktikaPatientFollowUp;
  // patient_balances: IPraktikaPatientBalance;
}

export function isPraktikaPatient(item: unknown): item is IPraktikaPatient {
  return TypeGuard.interface<IPraktikaPatient>({
    patient_id: TypeGuard.isOneOf(isNumber, isString),
    patient_title: TypeGuard.nilOr(isString),
    patient_firstname: isString,
    patient_lastname: isString,
    patient_preferredname: TypeGuard.nilOr(isString),
    patient_gender: TypeGuard.nilOr(
      (data: unknown): data is 'F' | 'M' | 'O' =>
        isString(data) && ['F', 'M', 'O'].includes(data)
    ),
    patient_dob: TypeGuard.nilOr(isString),
    patient_phone_home: isString,
    patient_phone_mobile: isString,
    patient_phone_work: isString,
    patient_statusid: TypeGuard.enumValue(PraktikaPatientStatus),
    patient_notes: TypeGuard.nilOr(isString),
    patient_defaultfeescheduleid: isNumber,
    patient_medicalhistorydate: TypeGuard.nilOr(isString),
    patient_nonrecall: isBoolean,
    patient_datejoined: isString,
    patient_email_personal: isString,
    patient_healthfund_name: TypeGuard.nilOr(isString),
    patient_healthfund_membernumber: TypeGuard.nilOr(isString),
    patient_medicare_number: TypeGuard.nilOr(isString),
    patient_medicare_subnumerate: TypeGuard.nilOr(isString),
    patient_preferredproviderid: TypeGuard.nilOr(isNumber),
    patient_address_street: TypeGuard.nilOr(isString),
    patient_address_suburb: TypeGuard.nilOr(isString),
    patient_address_state: TypeGuard.nilOr(isString),
    patient_address_postcode: [isNil, isNumber, isString],

    // isString(item.patient_fullname) &&
    // isString(item.patient_shortname) &&
    // (isString(item.patient_birthdate) || isNull(item.patient_birthdate)) && // '1972-07-25'
    // isNumber(item.patient_photofileid) &&
    // isPraktikaPatientAlert(item.patient_alerts) &&
    // isNumber(item.patient_number) &&
    // (isString(item.patient_healthfund_membershipnumber) ||
    //   isNull(item.patient_healthfund_membershipnumber)) &&
    // (isString(item.patient_medicare_expirydate) ||
    //   isNull(item.patient_medicare_expirydate)) &&
    // isArray(item.patient_referrals) &&
    // TypeGuard.arrayOf(isPraktikaPatientRelationship)(item.patient_guarantors) &&
    // isString(item.patient_allergysummary) &&
    // isString(item.patient_medicalsummary) &&
    // isObject(item.patient_appointmentids) &&
    // Object.keys(item.patient_appointmentids).every(
    //   (key) => isString(key) && isNumber(get(item.patient_appointmentids, key))
    // ) &&
    // isPraktikaPatientFollowUp(item.patient_recall) &&
    // isPraktikaPatientBalance(item.patient_balances) &&
    // TypeGuard.arrayOf(isPraktikaCommunicationRecord)(item.patient_communication) &&
    // TypeGuard.arrayOf(isPraktikaClinicalChart)(item.patient_dentition) &&
    // TypeGuard.arrayOf(isPraktikaPatientQuote)(item.patient_quotes) &&
    // TypeGuard.arrayOf(
    //   item.patient_treatmentplan,
    //   isPraktikaPatientTreatmentPlan
    // ) &&
    // TypeGuard.arrayOf(isPraktikaPatientImage)(item.patient_images)
  })(item);
}

// export interface IPatientAlerts {
//   isNewPatient: boolean;
//   isBadPatient: boolean;
//   hasHighMedicalAlert: boolean;
//   dateUpdated: string; // '2021-01-19 01:32:38'
//   ftaTotal: string;
//   cancellationTotal: string;
// }

// export function isPraktikaPatientAlert(item: unknown): item is IPatientAlerts {
//   return (
//     isObject(item) &&
//     isBoolean(item.isNewPatient) &&
//     isBoolean(item.isBadPatient) &&
//     isBoolean(item.hasHighMedicalAlert) &&
//     isString(item.dateUpdated) && // '2021-01-19 01:32:38'
//     isString(item.ftaTotal) &&
//     isString(item.cancellationTotal)
//   );
// }

// export interface IPraktikaPatientBalance {
//   current: string;
//   '30_days': string;
//   '60_days': string;
//   '90_days': string;
//   '120_plus_days': string;
//   total: string;
//   deposits: string;
//   total_paid_by_patient: string | null;
//   total_paid_by_healthfunds: string | null;
//   total_received: string;
// }

// export function isPraktikaPatientBalance(
//   item: unknown
// ): item is IPraktikaPatientBalance {
//   return (
//     isObject(item) &&
//     isString(item.current) &&
//     isString(item['30_days']) &&
//     isString(item['60_days']) &&
//     isString(item['90_days']) &&
//     isString(item['120_plus_days']) &&
//     isString(item.total) &&
//     isString(item.deposits) &&
//     (isString(item.total_paid_by_patient) ||
//       isNull(item.total_paid_by_patient)) &&
//     (isString(item.total_paid_by_healthfunds) ||
//       isNull(item.total_paid_by_healthfunds)) &&
//     isString(item.total_received)
//   );
// }

// export interface IPraktikaPatientFollowUp {
//   recallId: number;
//   recallType: number;
//   recallDate: string; // '2019-08-17'
//   recallStatus: number;
//   contactMethod: number;
//   note: string;
//   createdOn: string | null; // '2019-02-12 13:07:09'
//   createdByUserId: number;
//   createdByName: string;
//   groupName: string | null; // 'Recalls on 01/08/2019'
// }

// export function isPraktikaPatientFollowUp(
//   item: unknown
// ): item is IPraktikaPatientFollowUp {
//   return (
//     isObject(item) &&
//     isNumber(item.recallId) &&
//     isNumber(item.recallType) &&
//     isString(item.recallDate) && // '2019-08-17'
//     isNumber(item.recallStatus) &&
//     isNumber(item.contactMethod) &&
//     isString(item.note) &&
//     (isString(item.createdOn) || isNull(item.createdOn)) && // '2019-02-12 13:07:09'
//     isNumber(item.createdByUserId) &&
//     isString(item.createdByName) &&
//     (isString(item.groupName) || isNull(item.groupName))
//   ); // 'Recalls on 01/08/2019'
// }

// export interface IPatientMedicalHistoryQuestion {
//   label: string;
//   bvalue: boolean | null;
//   svalue: string;
// }

// export interface IPatientMedicalHistoryTypedQuestion
//   extends IPatientMedicalHistoryQuestion {
//   stype: 'TEXT' | 'BOOLEAN_TEXT';
// }

// export interface IPatientMedicalHistoryAllergy
//   extends IPatientMedicalHistoryQuestion {
//   group: string;
// }

const PATIENT_SOURCE_QUERY = `
SELECT * FROM (
  SELECT
    iPatientNumber as patient_id,
    vchTitle as patient_title,
    vchFirstName as patient_firstname,
    vchLastName as patient_lastname,
    vchPreferredName as patient_preferredname,
    dtDOB as patient_dob,
    NULLIF(vchGender, '') as patient_gender,
    vchHealthFund as patient_healthfund_name,
    vchHealthFundMemberNumber as patient_healthfund_membernumber,
    vchNotes as patient_notes,
    iPreferredProviderId as patient_preferredproviderid,
    iDefaultFeeScheduleId as patient_defaultfeescheduleid,
    iStatusId as patient_statusid,
    bNonRecall as patient_nonrecall,
    vchMedicareNumber as patient_medicare_number,
    vchMedicareSubNumerate::TEXT as patient_medicare_subnumerate,
    dtMedicalHistoryUpdated as patient_medicalhistorydate,
    dtJoinedPractice as patient_datejoined
  FROM patients
  ) AS patients
INNER JOIN (
  SELECT
    iPatientNumber as patient_id,
    vchHomePhone as patient_phone_home,
    vchMobile as patient_phone_mobile,
    vchWorkPhone as patient_phone_work,
    vchEmail as patient_email_personal,
    vchHomeStreetAddress as patient_address_street,
    vchHomeSuburb as patient_address_suburb,
    vchHomeState as patient_address_state,
    vchHomePostCode as patient_address_postcode
  FROM patient_contacts
) AS patient_contacts
ON patients.patient_id = patient_contacts.patient_id
ORDER BY patients.patient_id DESC
`;

export class PatientSourceEntity extends BaseSourceEntity<
  IPraktikaPatient,
  IPraktikaPatientTranslations,
  IPraktikaPatientFilters
> {
  sourceEntity = PATIENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaPatient;
  override transformDataFn = flow([]);

  migrationDestinations = [
    PATIENT_CLINICAL_CHART_DESTINATION_ENTITY.metadata.key,
    PATIENT_DEPOSIT_DESTINATION_ENTITY.metadata.key,
    PATIENT_FILE_DESTINATION_ENTITY.metadata.key,
    PATIENT_INTERACTION_DESTINATION_ENTITY.metadata.key,
    PATIENT_RELATIONSHIP_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY.metadata.key,
    PATIENT_DESTINATION_ENTITY.metadata.key,
  ];

  translate(
    patient: IPraktikaPatient,
    timezone: Timezone
  ): IPraktikaPatientTranslations {
    return {
      dob: patient.patient_dob
        ? toTimestamp(
            moment
              .tz(patient.patient_dob, ISO_DATE_FORMAT, timezone)
              .startOf('day')
          )
        : undefined,
      dateJoined: toTimestamp(
        moment
          .tz(patient.patient_datejoined, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      ),
    };
  }

  getFilterData(
    patient: IPraktikaPatient,
    timezone: Timezone
  ): IPraktikaPatientFilters {
    return {
      dateJoined: toTimestamp(
        moment
          .tz(patient.patient_datejoined, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaPatient): string | number {
    return data.patient_id;
  }

  getSourceLabel(data: IPraktikaPatient): string {
    return `${data.patient_firstname} ${data.patient_lastname}`;
  }
}
