<mat-toolbar color="accent">Add New Condition</mat-toolbar>
<mat-stepper orientation="vertical" class="content flex-1">
  <mat-step>
    <ng-template matStepLabel>General Configuration</ng-template>
    <form [formGroup]="form" class="flex flex-col items-stretch justify-around">
      <mat-form-field class="flex-1">
        <mat-label>Name</mat-label>
        <input
          type="text"
          matInput
          [required]="true"
          placeholder="Condition Name"
          formControlName="name"
          autofocus
        />
      </mat-form-field>
    </form>
    <button mat-flat-button matStepperNext>Next</button>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Charting Options</ng-template>
    <div class="flex gap-4">
      <div class="grow">
        <pr-charting-options
          [chartingConfiguration]="conditionConfig"
          (selectedDisplayOptions)="updateDisplayOptions($event)"
         />
      </div>

      <div class="grow">
        <pr-available-surfaces
          [surfaces]="conditionConfig.availableSurfaces"
          (selectedSurfaces)="updateSelectedSurfaces($event)"
         />
      </div>
    </div>
    <button mat-stroked-button matStepperPrevious>Back</button>
  </mat-step>
</mat-stepper>
<pt-buttons-container padding="all-round">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!isValid" (click)="add()">
    Add
  </button>
</pt-buttons-container>
