import { IPatientInteractionEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PatientInteractionDimensionMeasures } from '../dimensions/patient-interaction-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class PatientInteractionEventFactMeasures
  extends BaseFactMeasures
  implements
    MeasureTransformMap<ComparableProperties<IPatientInteractionEventFact>>
{
  id = 'patientInteractionEvent';
  readonly table = BigQueryTable.PatientInteractionEvent;
  readonly name = 'PatientInteractions';
  scopes = [new BrandQueryScopeConfig(), new DateRangeQueryScopeConfig()];

  get interaction(): PatientInteractionDimensionMeasures {
    return new PatientInteractionDimensionMeasures(
      BigQueryTable.PatientInteractions,
      'interaction',
      {
        sourceJoinKey: MeasurePath.docRef('interaction.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get latestEvent(): PatientInteractionEventFactMeasures {
    const measures = new PatientInteractionEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Interaction)
      .filterSoftDeleted(measures.patient.measureRef('deleted'))
      .get();
    return measures;
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }
}
