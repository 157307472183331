import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Event,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import { isPathChanged$, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-duration-warning-sidebar',
    templateUrl: './appointment-duration-warning-sidebar.component.html',
    styleUrls: ['./appointment-duration-warning-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentDurationWarningSidebarComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  requestedMins$: Observable<number>;
  difference$: Observable<number>;
  absDifference$: Observable<number>;

  constructor() {
    const eventDuration$ = this.appointment$.pipe(
      map((appointment) =>
        isEventable(appointment) ? Event.duration(appointment.event) : 0
      )
    );

    const step$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) =>
        TreatmentPlan.treatmentStepForAppointment$(appointment)
      )
    );

    this.requestedMins$ = combineLatest([
      step$.pipe(
        switchMap((step) => (step ? TreatmentStep.getDuration$(step) : of(0)))
      ),
      eventDuration$,
    ]).pipe(
      map(([stepDuration, eventDuration]) =>
        stepDuration > 0 ? stepDuration : eventDuration
      )
    );

    this.difference$ = combineLatest([
      this.requestedMins$,
      eventDuration$,
    ]).pipe(
      map(([requestedMins, eventDuration]) => requestedMins - eventDuration)
    );

    this.absDifference$ = this.difference$.pipe(
      map((difference) => Math.abs(difference))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }
}
