import { isBoolean, isNumber, isString } from 'lodash';
import { isTimestamp } from '../timestamp';
import { type Query, type Timestamp } from './adaptor';

export type ComparableValue = string | boolean | number | Timestamp;

export function isComparableValue(item: unknown): item is ComparableValue {
  return (
    isString(item) || isBoolean(item) || isNumber(item) || isTimestamp(item)
  );
}

export interface IQueryBuilder<D, R> {
  getQuery(data: D): Query<R>;
}
