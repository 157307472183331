@if (cycleTypes$ | async; as cycleTypes) {
  @if (!cycleTypes.length) {
    <pr-empty-state image="list" title="cycle types configured" />
  } @else {
    <mat-list>
      @for (cycleType of cycleTypes; track cycleType.ref.path) {
        <div class="flex items-center justify-between">
          <div>
            <div class="flex items-center gap-3">
              <p class="mat-headline-6 !m-0">
                {{ cycleType.name }}
              </p>
              <mat-chip-listbox class="mt-1" [selectable]="false">
                <mat-chip
                  *ngIf="cycleType.isTestType"
                  color="primary"
                  highlighted
                >
                  TEST
                </mat-chip>
              </mat-chip-listbox>
            </div>
            <p class="mat-caption !m-0">
              {{ cycleType.description }}
            </p>
          </div>

          <div class="flex items-center justify-end gap-4">
            <div>
              <button mat-icon-button (click)="edit.emit(cycleType)">
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="delete.emit(cycleType)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      }
    </mat-list>
  }
}
