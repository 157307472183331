<div fxFill fxLayout="column">
  <pr-lab-job-interactions
    fxFlex
    fxFill
    [practice]="practice$ | async"
    [labJob]="labJob$ | async"
    (statusUpdate)="onStatusUpdate()"
    (cancelled)="dialogRef.close()"
   />
</div>
