<ng-container *ngIf="dayData$ | async as dayData">
  <div fxFlex class="max-w-full">
    <div>
      <a
        class="day-link"
        [ngClass]="{ today: dayData.isToday }"
        [routerLink]="[]"
        [queryParams]="dayData.queryParams"
        queryParamsHandling="merge"
      >
        {{ dayData.dayOfMonth }}
      </a>
      <span *ngIf="dayData.firstOfMonth" class="first-day">
        {{ dayData.firstOfMonth }}
      </span>
    </div>

    <ng-container *ngIf="events$ | async as events">
      <div
        class="all-events"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="3px"
      >
        <pr-calendar-event
          *ngFor="let event of events; let i = index; trackBy: trackByEvent"
          [index]="i"
          [event]="event"
          [day]="dayData.day"
          fxFlex
        />
      </div>
    </ng-container>
  </div>
</ng-container>
