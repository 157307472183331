import { AccountCreditTransferTransactionProvider } from '../account-credit-transfer/account-credit-transfer-transaction-provider.service';
import { AccountCreditTransactionProvider } from '../account-credit/account-credit-transaction-provider.service';
import { CashTransactionProvider } from '../cash/cash-transaction-provider.service';
import { DiscountTransactionProvider } from '../discount/discount-transaction-provider.service';
import { ManualTransactionProvider } from '../manual/manual-transaction-provider.service';
import { SmartpayCardPurchaseTransactionProvider } from '../smartpay/smartpay-card-purchase-transaction-provider.service';
import { SmartpayQRPurchaseTransactionProvider } from '../smartpay/smartpay-qr-purchase-transaction-provider.service';
import { StripeTransactionProvider } from '../stripe/stripe-transaction-provider.service';
import { IProviderOption } from '../transaction-provider';
import { HICAPS_CONNECT_PROVIDER_OPTIONS } from './hicaps-connect-transaction-provider-options';
import { MEDIPASS_PROVIDER_OPTIONS } from './medipass-transaction-provider-options';
import { TYRO_PROVIDER_OPTIONS } from './tyro-transaction-provider-options';

export const CASH_PROVIDER_OPTION: IProviderOption = {
  label: 'Cash',
  icon: 'payments',
  provider: CashTransactionProvider,
};

export const MANUAL_PROVIDER_OPTION: IProviderOption = {
  label: 'Manual',
  icon: 'edit',
  provider: ManualTransactionProvider,
};

export const DISCOUNT_PROVIDER_OPTION: IProviderOption = {
  label: 'Discount',
  icon: 'money_off',
  provider: DiscountTransactionProvider,
};

export const ACCOUNT_CREDIT_PROVIDER_OPTION: IProviderOption = {
  label: 'Account Credit',
  icon: 'local_atm',
  provider: AccountCreditTransactionProvider,
};

export const ACCOUNT_CREDIT_TRANSFER_PROVIDER_OPTION: IProviderOption = {
  label: 'Account Credit Transfer',
  icon: 'sync_alt',
  provider: AccountCreditTransferTransactionProvider,
};

export const STRIPE_PROVIDER_OPTION: IProviderOption = {
  label: 'Stripe',
  icon: 'credit_card',
  provider: StripeTransactionProvider,
};

export const SMARTPAY_CARD_PROVIDER_OPTION: IProviderOption = {
  label: 'Smartpay Card',
  icon: 'credit_card',
  provider: SmartpayCardPurchaseTransactionProvider,
};

export const SMARTPAY_QR_PROVIDER_OPTION: IProviderOption = {
  label: 'Smartpay QR',
  icon: 'qr_code_scanner',
  provider: SmartpayQRPurchaseTransactionProvider,
};

export const ALL_PROVIDER_OPTIONS: IProviderOption[] = [
  CASH_PROVIDER_OPTION,
  DISCOUNT_PROVIDER_OPTION,
  MANUAL_PROVIDER_OPTION,
  STRIPE_PROVIDER_OPTION,
  SMARTPAY_CARD_PROVIDER_OPTION,
  SMARTPAY_QR_PROVIDER_OPTION,
  ACCOUNT_CREDIT_PROVIDER_OPTION,
  ACCOUNT_CREDIT_TRANSFER_PROVIDER_OPTION,
  ...TYRO_PROVIDER_OPTIONS,
  ...MEDIPASS_PROVIDER_OPTIONS,
  ...HICAPS_CONNECT_PROVIDER_OPTIONS,
];
