import {
  type CustomFormDataResolverConfigMap,
  type ICustomFormConfiguration,
  type IFormSchema,
  type IRootFormLayoutElement,
} from '@principle-theorem/principle-core/interfaces';
import { formSubForm, formToolbar } from '../../custom-form-helpers';
import {
  PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_LAYOUT,
  PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_SCHEMA,
} from './paediatric-birth-and-early-childhood';
import {
  PAEDIATRIC_DENTAL_BACKGROUND_FORM_LAYOUT,
  PAEDIATRIC_DENTAL_BACKGROUND_FORM_SCHEMA,
} from './paediatric-dental-background';
import {
  PAEDIATRIC_MEDICAL_BACKGROUND_FORM_LAYOUT,
  PAEDIATRIC_MEDICAL_BACKGROUND_FORM_SCHEMA,
} from './paediatric-medical-background';
import {
  PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_LAYOUT,
  PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_SCHEMA,
} from './paediatric-terms-and-conditions';

export const PAEDIATRIC_MEDICAL_HISTORY_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    medicalHistory: PAEDIATRIC_MEDICAL_BACKGROUND_FORM_SCHEMA,
    birthAndEarlyChildhood: PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_SCHEMA,
    dentalHistory: PAEDIATRIC_DENTAL_BACKGROUND_FORM_SCHEMA,
    termsAndConditions: PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_SCHEMA,
  },
};

export const PAEDIATRIC_MEDICAL_HISTORY_FORM_LAYOUT: IRootFormLayoutElement[] =
  [
    formToolbar('Medical Background'),
    formSubForm('medicalHistory', PAEDIATRIC_MEDICAL_BACKGROUND_FORM_LAYOUT),
    formToolbar('Birth & Early Childhood'),
    formSubForm(
      'birthAndEarlyChildhood',
      PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_LAYOUT
    ),
    formToolbar('Dental Background'),
    formSubForm('dentalHistory', PAEDIATRIC_DENTAL_BACKGROUND_FORM_LAYOUT),
    formToolbar('Consent & Signature'),
    formSubForm(
      'termsAndConditions',
      PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_LAYOUT
    ),
  ];

export const PAEDIATRIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG: CustomFormDataResolverConfigMap =
  {
    conditions: [
      { path: 'medicalHistory.conditions.anxiety' },
      { path: 'medicalHistory.conditions.attention' },
      { path: 'medicalHistory.conditions.asthma' },
      { path: 'medicalHistory.conditions.autism' },
      { path: 'medicalHistory.conditions.bleeding' },
      { path: 'medicalHistory.conditions.cancer' },
      { path: 'medicalHistory.conditions.cleftPalate' },
      { path: 'medicalHistory.conditions.craniofacialAbnormality' },
      { path: 'medicalHistory.conditions.developmentalDelay' },
      { path: 'medicalHistory.conditions.diabetes' },
      { path: 'medicalHistory.conditions.gastrointestinalIssues' },
      { path: 'medicalHistory.conditions.heartIssues' },
      { path: 'medicalHistory.conditions.immuneSystemIssues' },
      { path: 'medicalHistory.conditions.kidneyLiverIssues' },
      { path: 'medicalHistory.conditions.physicalDisability' },
      { path: 'medicalHistory.conditions.prematurity' },
      { path: 'medicalHistory.conditions.sightOrSpeechIssues' },
      { path: 'medicalHistory.conditions.seizures' },
      { path: 'medicalHistory.conditions.other' },
      { path: 'medicalHistory.conditions.details', mapTitle: '' },
      { path: 'medicalHistory.medications', mapTitle: 'Medications' },
    ],
    allergies: [
      {
        path: 'medicalHistory.allergies.allergies',
        mapTitle: '',
      },
      {
        path: 'medicalHistory.allergies.familyHistory',
        mapTitle: 'Family History',
      },
      {
        path: 'medicalHistory.allergies.intolerances',
        mapTitle: 'Intolerances',
      },
    ],
    history: [
      { path: 'medicalHistory.other.immunisations', mapTitle: 'Immunisations' },
      { path: 'medicalHistory.other.surgeries', mapTitle: 'Surgeries' },
      { path: 'medicalHistory.other.specialists', mapTitle: 'Specialists' },
      {
        path: 'medicalHistory.other.alliedHealthProfessionals',
        mapTitle: 'Allied Health',
      },
    ],
  };

export const PAEDIATRIC_MEDICAL_HISTORY_FORM_CONFIG: ICustomFormConfiguration =
  {
    name: 'Paediatric Medical History Form',
    schema: PAEDIATRIC_MEDICAL_HISTORY_FORM_SCHEMA,
    layout: PAEDIATRIC_MEDICAL_HISTORY_FORM_LAYOUT,
    dataResolverConfig: PAEDIATRIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG,
  };
