import { ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, noop } from 'rxjs';
import { finalize, repeat, takeUntil, tap } from 'rxjs/operators';

export class MouseHoverBloc {
  hover$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _element: ElementRef<HTMLElement>,
    onDestroy$: Observable<void>
  ) {
    const enter$ = fromEvent<MouseEvent>(
      this._element.nativeElement,
      'mouseenter'
    );

    const out$ = fromEvent<MouseEvent>(
      this._element.nativeElement,
      'mouseleave'
    );

    const hover$ = enter$.pipe(
      tap(() => this.hover$.next(true)),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(out$),
      finalize(() => this.hover$.next(false)),
      repeat()
    );

    hover$.pipe(takeUntil(onDestroy$)).subscribe(noop);
  }
}
