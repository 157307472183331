import { AnyExtension, Node, mergeAttributes } from '@tiptap/core';
import { Table, TableOptions } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { BlockNodes, NodeGroup } from '../available-extensions';

export function createTableExtension(
  options?: Partial<TableOptions>
): AnyExtension[] {
  return [...createAdditionalTableExtensions(options)];
}

function createAdditionalTableExtensions(
  options?: Partial<TableOptions>
): AnyExtension[] {
  return [
    tableWrapper.configure(),
    Table.extend({
      content: `${BlockNodes.TableRow}+`,
    }).configure({
      allowTableNodeSelection: true,
      ...options,
    }),
    TableHeader.extend({
      name: BlockNodes.TableHeader,
    }).configure(),
    TableRow.extend({
      name: BlockNodes.TableRow,
      content: `(${BlockNodes.TableCell} | ${BlockNodes.TableHeader})*`,
    }).configure(),
    TableCell.extend({
      name: BlockNodes.TableCell,
      renderHTML({ HTMLAttributes }) {
        const attrs = mergeAttributes(
          this.options.HTMLAttributes,
          HTMLAttributes
        );

        if (attrs.colwidth) {
          attrs.style = `width: ${attrs.colwidth}px`;
        }

        return ['td', attrs, 0];
      },
    }).configure(),
  ];
}

const tableWrapper = Node.create({
  name: BlockNodes.TableWrapper,
  group: NodeGroup.Block,
  content: `${BlockNodes.Table}{1}`,
  metadata: {
    tableRole: 'wrapper',
  },
  isolating: true,
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0];
  },
});
