/* eslint-disable no-null/no-null */
import {
  ChartableSurface,
  SourceEntityMigrationType,
  isToothNumber,
  type ISourceEntity,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  ISO_DATE_FORMAT,
  Timezone,
  TypeGuard,
} from '@principle-theorem/shared';
import { compact, flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import {
  exactToothConverter,
  toothRangeConverter,
  toothSurfacesConverter,
} from '../../util/tooth';

export const PATIENT_TREATMENT_RESOURCE_TYPE = 'patientTreatment';

export const PATIENT_TREATMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Treatments List',
      description: '',
      idPrefix: PATIENT_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export enum ExactTreatmentType {
  Historic = 'historic', // already done
  Planned = 'planned', // yet to be completed
  Base = 'base', // existing stuff
}

export interface IExactTreatmentQueryResult {
  treatment_id: string;
  patient_id: string;
  appointment_ids: string | null;
  treatment_type: ExactTreatmentType;
  planned_date: string;
  completed_date: string;
  service_code: string;
  service_description: string;
  provider_code: string;
  tooth: string | null;
  tooth_surfaces: string;
  tooth_range: string | null;
  treatment_notes: string | null;
  total_amount: string;
  treatment_plan_id: string | null;
  treatment_description: string | null;
  treatment_plan_planned_date: string | null;
  visit_id: string | null; // this ID is the same as treatment_id but groups treatments together
  visit_sequence: string | null;
  visit_duration_in_minutes: string | null;
  payment_plan_id: string | null;
  is_charged: boolean;
  is_completed: boolean;
}

export interface IExactTreatment
  extends Omit<
    IExactTreatmentQueryResult,
    'tooth' | 'tooth_range' | 'tooth_surfaces' | 'total_amount'
  > {
  tooth: ToothNumber | null;
  tooth_range: ToothNumber[] | null;
  tooth_surfaces: ChartableSurface[];
  total_amount: number;
}

export function isExactTreatment(item: unknown): item is IExactTreatment {
  return TypeGuard.interface<IExactTreatment>({
    treatment_id: isString,
    patient_id: isString,
    appointment_ids: [isString, isNull],
    treatment_type: TypeGuard.enumValue(ExactTreatmentType),
    planned_date: isString,
    completed_date: isString,
    service_code: isString,
    service_description: isString,
    provider_code: isString,
    tooth: [isToothNumber, isNull],
    tooth_range: [TypeGuard.arrayOf(isToothNumber), isNull],
    tooth_surfaces: TypeGuard.arrayOf(TypeGuard.enumValue(ChartableSurface)),
    treatment_notes: [isString, isNull],
    total_amount: isNumber,
    treatment_plan_id: [isString, isNull],
    treatment_description: [isString, isNull],
    treatment_plan_planned_date: [isString, isNull],
    visit_id: [isString, isNull],
    visit_sequence: [isString, isNull],
    visit_duration_in_minutes: [isString, isNull],
    payment_plan_id: [isString, isNull],
    is_charged: isBoolean,
    is_completed: isBoolean,
  })(item);
}

export interface IExactTreatmentFilters {
  patientId: string;
  treatmentPlanId?: string;
}

export interface IExactTreatmentTranslations {
  plannedDate?: ISODateType;
  completedDate?: ISODateType;
}

const TREATMENT_SOURCE_QUERY = `
SELECT
  patientid::TEXT AS patient_id,
  sourceid::TEXT AS treatment_id,
  NULLIF(appointmentids::TEXT, '') AS appointment_ids,
  LOWER(treatmenttype) AS treatment_type,
  planneddate AS planned_date,
  completeddate AS completed_date,
  NULLIF(servicecode, '') AS service_code,
  servicedescription AS service_description,
  providercode::TEXT AS provider_code,
  NULLIF(tooth, '') AS tooth,
  toothsurfaces AS tooth_surfaces,
  NULLIF(toothrange, '') AS tooth_range,
  NULLIF(notes, '') AS treatment_notes,
  NULLIF(totalamount::TEXT, '') AS total_amount,
  NULLIF(convtreatmentplan_treatmentplan_id::TEXT, '') AS treatment_plan_id,
  NULLIF(convtreatmentplan_treatmentplan_description, '') AS treatment_description,
  NULLIF(convtreatmentplan_treatmentplan_planneddate, '') AS treatment_plan_planned_date,
  NULLIF(treatmentplanvisit_visit_id::TEXT, '') AS visit_id,
  NULLIF(treatmentplanvisit_visit_sequence::TEXT, '') AS visit_sequence,
  NULLIF(treatmentplanvisit_visit_durationinminutes, '') AS visit_duration_in_minutes,
  NULLIF(convpaymentplan_paymentplan_sourceid, '') AS payment_plan_id,
  convert_to_boolean(ischarged) AS is_charged,
  iscompleted AS is_completed
FROM
  convtreatment
ORDER BY
  patient_id, treatment_id
`;

export class PatientTreatmentSourceEntity extends BaseSourceEntity<
  IExactTreatment,
  IExactTreatmentTranslations,
  IExactTreatmentFilters
> {
  sourceEntity = PATIENT_TREATMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_RESOURCE_TYPE;
  sourceQuery = TREATMENT_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isExactTreatment;
  override defaultOffsetSize = 50000;
  override transformDataFn = flow([transformTreatmentResults]);

  translate(
    treatment: IExactTreatment,
    timezone: Timezone
  ): IExactTreatmentTranslations {
    const completedDate = moment.tz(
      treatment.completed_date,
      EXACT_DATE_TIME_FORMAT,
      timezone
    );
    const planned = moment.tz(
      treatment.planned_date,
      EXACT_DATE_TIME_FORMAT,
      timezone
    );
    return {
      plannedDate:
        planned.year() === 1 ? undefined : planned.format(ISO_DATE_FORMAT),
      completedDate:
        completedDate.year() === 1
          ? undefined
          : completedDate.format(ISO_DATE_FORMAT),
    };
  }

  getSourceRecordId(data: IExactTreatment): string {
    return data.treatment_id;
  }

  getSourceLabel(data: IExactTreatment): string {
    return `${data.treatment_id} ${data.patient_id}`;
  }

  getFilterData(data: IExactTreatment): IExactTreatmentFilters {
    return {
      patientId: data.patient_id,
      treatmentPlanId: data.treatment_plan_id ?? undefined,
    };
  }
}

function transformTreatmentResults(
  rows: IExactTreatmentQueryResult[]
): IExactTreatment[] {
  return compact(
    rows.map((treatment) => {
      if (!treatment.patient_id || !treatment.treatment_id) {
        // eslint-disable-next-line no-console
        console.error('Missing patient_id or treatment_id', treatment);
        return;
      }

      return {
        ...treatment,
        treatment_notes:
          treatment.treatment_notes?.replace(/<br>/g, '\n') ?? null,
        service_code: treatment.service_code
          ? treatment.service_code.replace(/\//g, ' ')
          : '',
        patient_id: convertExactId(treatment.patient_id),
        treatment_id: convertExactId(treatment.treatment_id),
        total_amount: parseFloat(treatment.total_amount ?? '0'),
        visit_id: treatment.visit_id
          ? convertExactId(treatment.visit_id)
          : null,
        tooth: treatment.tooth
          ? exactToothConverter(treatment.tooth) ?? null
          : null,
        tooth_range: treatment.tooth_range
          ? toothRangeConverter(treatment.tooth_range)
          : null,
        tooth_surfaces: toothSurfacesConverter(treatment.tooth_surfaces) ?? [],
      };
    })
  );
}
