import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import { IFilterOption } from '@principle-theorem/ng-shared';
import {
  PlanStepPairStatus,
  SortStepOptions,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import { ITreatmentPlanStepPair } from '../treatment-plans-consolidated/treatment-plans-consolidated.store';

@Injectable({
  providedIn: 'root',
})
export class TreatmentStepsDisplayService {
  private _stafferSettings = inject(StafferSettingsStoreService);

  treatmentStepStatusOptions = this._getTreatmentStepStatusOptions();
  treatmentStepSortOptions = getEnumValues(SortStepOptions);

  activeStatusFilters = toSignal(
    this._stafferSettings.treatmentPlan$.pipe(map((setting) => setting.filters))
  );
  activeSortOption = toSignal(
    this._stafferSettings.treatmentPlan$.pipe(map((setting) => setting.sortBy))
  );
  hasActiveStatusFilters = computed(() =>
    this._hasActiveStatusFilters(this.activeStatusFilters())
  );
  isUnscheduledHidden = computed(() => {
    const filters = this.activeStatusFilters();
    return !filters || !filters.includes(PlanStepPairStatus.Unscheduled);
  });
  unscheduledHiddenTooltip = computed(() => {
    return this.isUnscheduledHidden()
      ? 'Creating a new step will enable the Unscheduled status filter'
      : '';
  });

  updateUsersFilteredStatuses(filters: PlanStepPairStatus[]): void {
    this._stafferSettings.updateStafferSettings({
      treatmentPlan: {
        filters,
      },
    });
  }

  updateUserSortPreference(sortBy?: SortStepOptions): void {
    this._stafferSettings.updateStafferSettings({
      treatmentPlan: {
        sortBy,
      },
    });
  }

  enableUnscheduledFilter(): void {
    const filters = this.activeStatusFilters();
    if (!filters || !filters.includes(PlanStepPairStatus.Unscheduled)) {
      this.updateUsersFilteredStatuses([
        ...(filters || []),
        PlanStepPairStatus.Unscheduled,
      ]);
    }
  }

  private _hasActiveStatusFilters(
    userFilters: PlanStepPairStatus[] | undefined
  ): boolean {
    if (!userFilters) {
      return false;
    }
    return userFilters.length < this.treatmentStepStatusOptions.length;
  }

  private _getTreatmentStepStatusOptions(): IFilterOption<
    PlanStepPairStatus,
    ITreatmentPlanStepPair
  >[] {
    return getEnumValues(PlanStepPairStatus).map((status) => ({
      id: status,
      label: status,
      filter: (step) => step.status === status,
    }));
  }
}
