<ng-container *ngIf="treatmentCategory$ | async as category">
  <ng-container *ngIf="displayOnly$ | async; else actionable">
    <div class="button-container">
      <div
        class="display-colour button"
        [matTooltip]="'Treatment Category: ' + category.name"
        [style.backgroundColor]="category.colour.value"
      ></div>
    </div>
  </ng-container>

  <ng-template #actionable>
    <div class="button-container">
      <div
        [matMenuTriggerFor]="menu"
        class="display-colour button"
        [matTooltip]="'Treatment Category: ' + category.name"
        [style.backgroundColor]="category.colour.value"
      ></div>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="categoryChanged.emit(category)">
        <mat-icon
          [style.backgroundColor]="category.colour.value"
          class="display-colour"
        />
        <span>Set As Default Colour</span>
      </button>
    </mat-menu>
  </ng-template>
</ng-container>
