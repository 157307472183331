<mat-drawer-container fxFlex [hasBackdrop]="false">
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
  <mat-drawer
    #drawer
    [ngClass]="{ collapsed: collapsed$ | ngrxPush }"
    class="event-sidebar"
    [mode]="mode"
    [position]="position"
    [autoFocus]="autoFocus"
    [disableClose]="false"
  >
    <button
      matTooltip="Close / ESC"
      mat-icon-button
      class="close-button"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>

    <button
      *ngIf="(collapsed$ | ngrxPush) === false; else expandButton"
      matTooltip="Collapse"
      mat-icon-button
      class="collapse-button"
      (click)="collapse()"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>

    <ng-template #expandButton>
      <button
        matTooltip="Expand"
        mat-icon-button
        class="collapse-button"
        (click)="expand()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
    </ng-template>

    <pt-dynamic-component
      fxFill
      *ngIf="componentDefinition$ | ngrxPush as definition"
      [definition]="definition"
    />
  </mat-drawer>
</mat-drawer-container>
