import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-page-splash',
    templateUrl: './page-splash.component.html',
    styleUrls: ['./page-splash.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageSplashComponent {}
