import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type INote,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-patient-notes',
    templateUrl: './appointment-patient-notes.component.html',
    styleUrls: ['./appointment-patient-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentPatientNotesComponent {
  trackByNote = TrackByFunctions.uniqueId<INote>();
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  notes$: Observable<INote[]>;

  constructor() {
    this.notes$ = this.patient$.pipe(
      map((patient) => patient.notes.filter((note) => note.pinned))
    );
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
