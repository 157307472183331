<form [formGroup]="queryForm" class="flex flex-auto flex-row gap-2">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Events that</mat-label>
    <mat-select formControlName="byDate" required>
      @for (byDateOption of byDateOptions; track byDateOption.value) {
        <mat-option [value]="byDateOption.value">
          {{ byDateOption.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Event Type</mat-label>
    <mat-select formControlName="eventTypes" required multiple>
      @for (eventType of eventTypes; track eventType.value) {
        <mat-option [value]="eventType.value">
          {{ eventType.label | titlecase }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    class="flex-auto"
  >
    <mat-label>Filter</mat-label>
    <input matInput formControlName="filterValue" />
  </mat-form-field>
</form>
