import { initVersionedSchema } from '@principle-theorem/editor';
import {
  Brand,
  Note,
  Patient,
  ReferralSource,
  ServiceProviderHandler,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  Gender,
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  PatientStatus,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IContactNumber,
  type IDVACard,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IHealthFundCard,
  type IMedicareCard,
  type INote,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IReferralSource,
  type IReferralSourceConfiguration,
  type IStaffer,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getDoc,
  getError,
  isSameRef,
  multiMap,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import * as he from 'he';
import { compact, first, sortBy, startCase } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  IPatientJobData,
  IPatientMigrationData,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../../mappings/fee-schedules';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import { FeeScheduleSourceEntity } from '../../source/entities/fee-schedule';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientHealthFundSourceEntity,
  type ID4WPatientHealthFund,
} from '../../source/entities/patient-health-fund';
import {
  PatientTreatmentNoteSourceEntity,
  type ID4WPatientTreatmentNote,
  type ID4WPatientTreatmentNoteTranslations,
} from '../../source/entities/patient-treatment-note';
import { D4WFeeScheduleToPatientTagMappingHandler } from '../mappings/fee-schedule-to-patient-tag';
import { D4WItemCodeToNoteMappingHandler } from '../mappings/item-code-to-note';
import { D4WPatientStatusMappingHandler } from '../mappings/patient-statues';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WReferralSourceMappingHandler } from '../mappings/referral-sources';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { ExistingPatientDestinationEntity } from './existing-patients';
import { FeeScheduleDestinationEntity } from './fee-schedules';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: '',
  },
});

export interface ID4WPatientJobData extends IPatientJobData<ID4WPatient> {
  sourcePatient: IGetRecordResponse<
    ID4WPatient,
    ID4WPatientTranslations,
    ID4WPatientFilters
  >;
  practices: WithRef<ITranslationMap<IPractice>>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
  tags: INamedDocument<ITag>[];
  feeScheduleToPatientTags: WithRef<ITranslationMap<ITag>>[];
  statuses: WithRef<ITranslationMap<object, PatientStatus>>[];
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[];
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<ID4WPatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override filters = [
    new PracticeIdFilter<ID4WPatientJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<ID4WPatientJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientHealthFunds: new PatientHealthFundSourceEntity(),
    treatmentNotes: new PatientTreatmentNoteSourceEntity(),
  };

  override destinationEntities = {
    feeSchedules: new FeeScheduleDestinationEntity(),
    existingPatients: new ExistingPatientDestinationEntity(),
  };

  customMappings = {
    itemCodes: new D4WItemCodeToNoteMappingHandler(),
    staff: new D4WStafferMappingHandler(),
    practices: new D4WPracticeMappingHandler(),
    feeScheduleToPatientTags: new D4WFeeScheduleToPatientTagMappingHandler(),
    statuses: new D4WPatientStatusMappingHandler(),
    referralSources: new D4WReferralSourceMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<ID4WPatientJobData[]> {
    const staff$ = this.customMappings.staff.getRecords$(translationMap);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const statuses$ = this.customMappings.statuses.getRecords$(translationMap);
    const tags$ = brand$.pipe(
      switchMap((brand) => Brand.patientTags$(brand)),
      multiMap(toNamedDocument)
    );
    const feeScheduleToPatientTags$ =
      this.customMappings.feeScheduleToPatientTags.getRecords$(translationMap);
    const referralSources$ =
      this.customMappings.referralSources.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        brand$,
        practices$,
        sourceItemCodes$,
        tags$,
        feeScheduleToPatientTags$,
        statuses$,
        referralSources$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            brand,
            practices,
            sourceItemCodes,
            tags,
            feeScheduleToPatientTags,
            statuses,
            referralSources,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            brand,
            practices,
            sourceItemCodes,
            tags,
            feeScheduleToPatientTags,
            statuses,
            referralSources,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: ID4WPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const translations = data.sourcePatient.data.translations;
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(patient)
      .toString();

    const treatmentNotes =
      await this.sourceEntities.treatmentNotes.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const healthFunds = (
      await this.sourceEntities.patientHealthFunds.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      )
    ).map((healthFund) => healthFund.data.data);

    const referrer = await getReferralSource(
      patient,
      data.referralSources,
      translationMap
    );

    try {
      return {
        sourcePatientId,
        createdAt: translations.first_presented_date ?? undefined,
        patient: Patient.init({
          name: getName(patient),
          email: patient.email ?? '',
          contactNumbers: getPhone(patient),
          address: getAddress(patient),
          dateOfBirth: patient.dob ?? undefined,
          gender: getGender(patient),
          status: getStatus(patient),
          notes: compact([
            buildPatientCommentNote(patient),
            ...buildSocialNotesFromTreatments(treatmentNotes, data),
          ]),
          tags: this._getPatientTags(data, patient),
          referenceId: sourcePatientId,
          medicareCard: getMedicareDetails(patient),
          healthFundCard: getHealthFundDetails(healthFunds),
          dvaCard: getDVADetails(healthFunds),
          referrer,
          preferredPractice: patient.practice_id
            ? await getPreferredPractice(
                patient.practice_id.toString(),
                data.practices
              )
            : undefined,
          preferredDentist: patient.provider_id
            ? await getPreferredProvider(
                patient.provider_id.toString(),
                data.staff
              )
            : undefined,
          preferredFeeSchedule: patient.practice_fee_level_id
            ? await getDefaultFeeSchedule(
                patient.practice_fee_level_id,
                translationMap
              )
            : undefined,
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _getPatientTags(
    data: ID4WPatientJobData,
    patient: ID4WPatient
  ): INamedDocument<ITag>[] {
    const preferredFeeSchedule = patient.practice_fee_level_id?.toString();
    if (!preferredFeeSchedule) {
      return [];
    }

    const tagMapping = data.feeScheduleToPatientTags.find(
      (mapping) => mapping.sourceIdentifier === preferredFeeSchedule
    );

    if (!tagMapping?.destinationIdentifier) {
      return [];
    }

    const foundTag = data.tags.find((tag) =>
      isSameRef(tag, tagMapping.destinationIdentifier)
    );

    if (!foundTag) {
      return [];
    }

    return [foundTag];
  }
}

function buildPatientCommentNote(patient: ID4WPatient): INote | undefined {
  const patientComments = patient.marketing_details.trim();
  if (!patientComments) {
    return;
  }

  return Note.init({
    content: initVersionedSchema(patient.marketing_details.trim()),
  });
}

function getMedicareDetails(patient: ID4WPatient): IMedicareCard | undefined {
  if (!patient.medicare_id) {
    return;
  }

  return {
    number: patient.medicare_id,
    subNumerate: '?',
  };
}

function getHealthFundDetails(
  healthFunds: ID4WPatientHealthFund[]
): IHealthFundCard | undefined {
  const patientHealthFund = first(
    sortBy(healthFunds, (healthFund) => healthFund.is_default).filter(
      (healthFund) => !healthFund.is_dva
    )
  );
  if (!patientHealthFund) {
    return;
  }

  return {
    membershipNumber: patientHealthFund.membership_number,
    memberNumber: patientHealthFund.member_number
      ? patientHealthFund.member_number.toString()
      : '',
    fundCode: patientHealthFund.fund_code,
  };
}

function getDVADetails(
  healthFunds: ID4WPatientHealthFund[]
): IDVACard | undefined {
  const patientHealthFund = first(
    sortBy(healthFunds, (healthFund) => healthFund.is_default).filter(
      (healthFund) => healthFund.is_dva
    )
  );
  if (!patientHealthFund || !patientHealthFund.membership_number) {
    return;
  }

  return {
    number: patientHealthFund.membership_number,
    expiryDate: toTimestamp(moment(patientHealthFund.expiry_date)),
  };
}

export function getGender(patient: ID4WPatient): Gender {
  switch (patient.patient_sex) {
    case 'F':
      return Gender.Female;
    case 'M':
      return Gender.Male;
    default:
      return Gender.NotSpecified;
  }
}

export function getStatus(_patient: ID4WPatient): PatientStatus {
  return PatientStatus.Active;
}

export function getAddress(patient: ID4WPatient): string {
  const addressFields = compact([
    patient.address_1?.trim(),
    startCase(patient.suburb?.trim().toLowerCase()),
    patient.state?.trim(),
    patient.postcode?.toString().trim(),
  ]);

  if (!addressFields.length) {
    return '';
  }

  return addressFields.join(', ');
}

export function getPhone(patient: ID4WPatient): IContactNumber[] {
  const d4wContactNumbers = compact([
    patient.mobile ? { label: 'mobile', number: patient.mobile } : undefined,
    patient.phone_home
      ? { label: 'home', number: patient.phone_home }
      : undefined,
    patient.phone_work
      ? { label: 'work', number: patient.phone_work }
      : undefined,
  ]);
  return d4wContactNumbers.filter((contactNumbers) => contactNumbers.number);
}

export function getName(patient: ID4WPatient): string {
  const preferredName = patient.preferred_name?.trim() ?? '';

  if (!preferredName || preferredName === patient.firstname) {
    return he.decode(`${patient.firstname} ${patient.surname}`.trim());
  }
  return he.decode(
    `${patient.firstname} (${preferredName}) ${patient.surname}`.trim()
  );
}

async function getPreferredProvider(
  preferredProviderId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer> | undefined> {
  const practitionerMap = staff.find(
    (staffer) => staffer.sourceIdentifier === preferredProviderId
  );

  if (!practitionerMap?.destinationIdentifier) {
    return;
  }

  return stafferToNamedDoc(await getDoc(practitionerMap.destinationIdentifier));
}

async function getPreferredPractice(
  preferredPracticeId: string,
  practices: WithRef<ITranslationMap<IPractice>>[]
): Promise<INamedDocument<IPractice> | undefined> {
  const practitionerMap = practices.find(
    (practice) => practice.sourceIdentifier === preferredPracticeId
  );

  if (!practitionerMap?.destinationIdentifier) {
    return;
  }

  return toNamedDocument(await getDoc(practitionerMap.destinationIdentifier));
}

async function getDefaultFeeSchedule(
  defaultFeeScheduleId: number,
  translationMap: TranslationMapHandler
): Promise<INamedDocument<IFeeSchedule> | undefined> {
  const entity = new FeeScheduleSourceEntity();
  const feeScheduleMap = await translationMap.getDestination<IFeeSchedule>(
    entity.getSourceRecordId({ id: defaultFeeScheduleId }).toString(),
    FEE_SCHEDULE_RESOURCE_TYPE
  );

  if (!feeScheduleMap) {
    return;
  }

  return toNamedDocument(await Firestore.getDoc(feeScheduleMap));
}

function buildSocialNotesFromTreatments(
  notes: IGetRecordResponse<
    ID4WPatientTreatmentNote,
    ID4WPatientTreatmentNoteTranslations
  >[],
  data: ID4WPatientJobData
): INote[] {
  return compact(
    notes.map((note) => {
      const procedureCode = note.data.data.item_code;
      const code = ServiceProviderHandler.findServiceCode(procedureCode);

      if (code) {
        return;
      }

      const mappedCode = data.sourceItemCodes.find(
        (sourceItemCode) =>
          sourceItemCode.sourceIdentifier === procedureCode &&
          !!sourceItemCode.destinationValue
      );

      if (
        !mappedCode ||
        mappedCode.destinationValue !== ItemCodeNoteType.PatientSocialNote
      ) {
        return;
      }

      return Note.init({
        content: initVersionedSchema(note.data.data.content),
        createdAt: note.data.translations.createdAt,
      });
    })
  );
}

async function getReferralSource(
  patient: ID4WPatient,
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[],
  translationMap: TranslationMapHandler
): Promise<IReferralSource | undefined> {
  if (!patient.source_code) {
    return;
  }

  if (patient.patient_referrer_id) {
    const patientRef = await translationMap.getDestination<IPatient>(
      patient.patient_referrer_id.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return;
    }

    const referralPatient = await getDoc(patientRef);
    return ReferralSource.toReferrer(referralPatient);
  }

  if (patient.refsource_id) {
    const referralSourceMap = referralSources.find(
      (referralSource) =>
        referralSource.sourceIdentifier === patient.refsource_id?.toString()
    );
    if (!referralSourceMap?.destinationIdentifier) {
      return;
    }

    const referralSource = await getDoc(
      referralSourceMap.destinationIdentifier
    );
    return ReferralSource.toReferrer(referralSource);
  }
}
