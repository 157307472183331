import { Injectable } from '@angular/core';
import { Money } from '@principle-theorem/accounting';
import { MedicareValidation } from '@principle-theorem/principle-core';
import {
  IPatientClaimDialogResponse,
  type IADACodeClaim,
  type IAppointment,
  type IBulkBillDialogResponse,
  type IHealthcareClaim,
  type IInvoice,
  type IMedicareCard,
  type IReferralFormData,
} from '@principle-theorem/principle-core/interfaces';
import { prefixCharacters, type WithRef } from '@principle-theorem/shared';
import {
  bulkBillPayload,
  fullyPaidPayload,
  partlyPaidPayload,
  toEasyclaimDate,
  type IBulkBillEasyclaimRequestParams,
  type IEasyclaimBulkBillServiceAttrs,
  type IEasyclaimFullyPaidServiceAttrs,
  type IEasyclaimPartPaidServiceAttrs,
  type IEasyclaimPatientAttrs,
  type IEasyclaimRequestParams,
  type IEasyclaimServicingProviderAttrs,
  type IEasyclaimServicingVoucherAttrs,
  type IReferralDataAttrs,
} from '@principle-theorem/tyro';
import { trimStart } from 'lodash';
import {
  filterClaimableItems,
  getServiceItems,
} from '../../transaction-components/medicare-components/select-claim-items/select-claim-items.component';
import { TransactionProviderError } from '../../transaction-provider';
import { type IntegrationKeyFormData } from '../select-tyro-terminal/select-tyro-terminal.component';

@Injectable()
export class EasyclaimBuilder {
  buildEasyclaimBulkBillClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    dialogData: IBulkBillDialogResponse,
    terminalData: IntegrationKeyFormData,
    appointment?: WithRef<IAppointment>
  ): IBulkBillEasyclaimRequestParams {
    return {
      ...terminalData,
      payload: this._buildBulkBillPayload(
        invoice,
        claim,
        dialogData,
        appointment
      ),
      rightsAssigned: false,
    };
  }

  buildEasyclaimPartPaidClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    dialogData: IBulkBillDialogResponse,
    terminalData: IntegrationKeyFormData
  ): IEasyclaimRequestParams {
    return {
      ...terminalData,
      payload: this._buildPartPaidPayload(invoice, claim, dialogData),
    };
  }

  buildEasyclaimFullyPaidClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    dialogData: IPatientClaimDialogResponse,
    terminalData: IntegrationKeyFormData
  ): IEasyclaimRequestParams {
    return {
      ...terminalData,
      payload: this._buildFullyPaidPayload(invoice, claim, dialogData),
    };
  }

  private _buildBulkBillPayload(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    result: IBulkBillDialogResponse,
    appointment?: WithRef<IAppointment>
  ): string {
    const services = filterClaimableItems(result.itemClaims).map(
      (itemClaim, index) =>
        this._toBulkBillServiceItem(invoice, itemClaim, index, appointment)
    );
    return bulkBillPayload(
      this._getVoucherAttrs(invoice, result.referralData),
      services,
      this._getPatientAttrs(result.medicareCard),
      this._getServicingProviderAttrs(claim),
      this._getReferral(result.referralData)
    );
  }

  private _buildPartPaidPayload(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    result: IBulkBillDialogResponse
  ): string {
    // TODO: Take into account what has already been paid on the invoice.
    const services = filterClaimableItems(result.itemClaims).map(
      (itemClaim, index) =>
        this._toPartPaidServiceItem(invoice, itemClaim, index)
    );

    return partlyPaidPayload(
      this._getVoucherAttrs(invoice, result.referralData),
      services,
      this._getPatientAttrs(result.medicareCard),
      this._getServicingProviderAttrs(claim),
      this._getReferral(result.referralData)
    );
  }

  private _buildFullyPaidPayload(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    result: IPatientClaimDialogResponse
  ): string {
    const services = filterClaimableItems(getServiceItems(invoice, claim)).map(
      (itemClaim, index) =>
        this._toFullyPaidServiceItem(invoice, itemClaim, index)
    );
    return fullyPaidPayload(
      this._getVoucherAttrs(invoice, result.referralData),
      services,
      this._getPatientAttrs(result.medicareCard),
      this._getServicingProviderAttrs(claim),
      this._getReferral(result.referralData)
    );
  }

  private _getPatientAttrs(
    medicareCard: IMedicareCard
  ): IEasyclaimPatientAttrs {
    if (
      !MedicareValidation.isValidMemberNumber(medicareCard.number) ||
      !MedicareValidation.isValidMemberNumber(medicareCard.subNumerate)
    ) {
      throw new TransactionProviderError('Invalid Medicare Card Details');
    }
    return {
      memberNum: medicareCard.number,
      memberRefNum: trimStart(medicareCard.subNumerate, '0'),
    };
  }

  private _getVoucherAttrs(
    _invoice: WithRef<IInvoice>,
    referralData: IReferralFormData
  ): IEasyclaimServicingVoucherAttrs {
    const voucher: IEasyclaimServicingVoucherAttrs = {
      voucherId: '01',
      serviceTypeCde: 'S',
    };
    if (!referralData.hasReferral) {
      voucher.referralOverrideTypeCde =
        referralData.noReferral.referralOverrideTypeCde;
    }
    return voucher;
  }

  private _getServicingProviderAttrs(
    claim: IHealthcareClaim
  ): IEasyclaimServicingProviderAttrs {
    if (!claim.providerData) {
      throw new TransactionProviderError(`Missing Provider Data`);
    }
    return {
      providerNum: claim.providerData.providerNumber,
    };
  }

  private _toBulkBillServiceItem(
    invoice: WithRef<IInvoice>,
    item: IADACodeClaim,
    index: number,
    appointment?: WithRef<IAppointment>
  ): IEasyclaimBulkBillServiceAttrs {
    return {
      mbsItemNum: item.lineItem.code,
      dateOfService: toEasyclaimDate(
        appointment?.event?.from ?? invoice.createdAt
      ),
      serviceId: prefixCharacters('0', 4)(index + 1),
    };
  }

  private _toFullyPaidServiceItem(
    invoice: WithRef<IInvoice>,
    item: IADACodeClaim,
    index: number
  ): IEasyclaimFullyPaidServiceAttrs {
    return {
      ...this._toBulkBillServiceItem(invoice, item, index),
      chargeAmount: `${Money.toCents(item.lineItem.amount)}`,
    };
  }

  private _toPartPaidServiceItem(
    invoice: WithRef<IInvoice>,
    item: IADACodeClaim,
    index: number
  ): IEasyclaimPartPaidServiceAttrs {
    return {
      ...this._toFullyPaidServiceItem(invoice, item, index),
      patientContribAmt: `${Money.toCents(item.amount)}`,
    };
  }

  private _getReferral(
    referralData: IReferralFormData
  ): IReferralDataAttrs | undefined {
    if (!referralData.hasReferral) {
      return;
    }
    if (!referralData.referral.dateOfIssue) {
      throw new TransactionProviderError(`Missing Referral Date`);
    }
    return {
      referralAttrs: {
        dateOfIssue: toEasyclaimDate(referralData.referral.dateOfIssue),
        periodTypeCde: referralData.referral.periodTypeCde,
      },
      providerAttrs: {
        providerNum: referralData.referral.providerNum,
      },
    };
  }
}
