import { InjectionToken } from '@angular/core';
import { TypesenseCluster } from '@principle-theorem/principle-core/interfaces';
import { ITypesenseConfig } from '@principle-theorem/typesense';

export interface INgPrincipleSharedConfig {
  projectId: string;
  podium: {
    clientId: string;
  };
  typesense: Record<TypesenseCluster, ITypesenseConfig>;
}

export const NG_PRINCIPLE_SHARED_CONFIG: InjectionToken<INgPrincipleSharedConfig> =
  new InjectionToken('INgPrincipleSharedConfig');
