import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {
  GlobalStoreService,
  StatusLabelComponent,
} from '@principle-theorem/ng-principle-shared';
import {
  ObservableDataSource,
  PipesModule,
  extendSortingDataAccessor,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import { PRESCRIPTION_STATUS_COLOUR_MAP } from '@principle-theorem/principle-core';
import {
  IPrescription,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT, WithRef } from '@principle-theorem/shared';
import { MomentModule } from 'ngx-moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Component({
    selector: 'pr-patient-prescriptions-list',
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        PipesModule,
        MomentModule,
        StatusLabelComponent,
    ],
    templateUrl: './patient-prescriptions-list.html',
    styleUrl: './patient-prescriptions-list.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientPrescriptionsListComponent {
  private _onDestroy$ = new Subject<void>();
  private _global = inject(GlobalStoreService);
  private _prescriptions$ = new ReplaySubject<WithRef<IPrescription>[]>(1);
  readonly dateFormat = HISTORY_DATE_FORMAT;
  dataSource: ObservableDataSource<WithRef<IPrescription>>;
  displayedColumns: string[] = ['createdAt', 'prescriber', 'items', 'status'];

  @Output() prescriptionSelected = new EventEmitter<WithRef<IPrescription>>();

  @Input()
  set prescriptions(prescriptions: WithRef<IPrescription>[]) {
    if (prescriptions) {
      this._prescriptions$.next(prescriptions);
    }
  }

  @ViewChild(MatPaginator)
  set tablePaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor() {
    this.dataSource = new ObservableDataSource(this._prescriptions$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getPrescriberDisplayName$(
    prescription: WithRef<IPrescription>
  ): Observable<string> {
    return this._global.getStafferName$({ ref: prescription.prescriber.ref });
  }

  getMedicationNames(prescription: WithRef<IPrescription>): string {
    return prescription.items.map((item) => item.medicationName).join(', ');
  }

  statusColor(status: PrescriptionStatus): string {
    return PRESCRIPTION_STATUS_COLOUR_MAP[status];
  }

  private _sortingDataAccessor(
    data: WithRef<IPrescription>,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'createdAt':
        return timestampSortingAccessor(data.createdAt);
      default:
        return;
    }
  }
}
