import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { CreateConfiguration } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { TreatmentConfiguration } from '@principle-theorem/principle-core';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITreatmentFormData extends Pick<ITreatmentConfiguration, 'name'> {}

@Component({
    selector: 'pr-create-treatment-configuration',
    templateUrl: './create-treatment-configuration.component.html',
    styleUrls: ['./create-treatment-configuration.component.sass'],
    standalone: false
})
export class CreateTreatmentConfigurationComponent extends CreateConfiguration<
  CreateTreatmentConfigurationComponent,
  ITreatmentFormData,
  ITreatmentConfiguration
> {
  form: TypedFormGroup<ITreatmentFormData> =
    new TypedFormGroup<ITreatmentFormData>({
      name: new TypedFormControl('', Validators.required),
    });

  protected _transformData(
    formData: ITreatmentFormData
  ): ITreatmentConfiguration {
    return TreatmentConfiguration.init(formData);
  }
}
