import {
  HicapsConnect,
  IPMSHicapsConnectConfig,
} from '@principle-theorem/hicaps-connect';
import {
  HicapsConnectResponseCodes,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  IHicapsConnectTerminal,
  IInvoice,
  TransactionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  WithRef,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import { mapValues } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export type SubstringMap<T extends string> = Record<
  T,
  [number, number | undefined]
>;

export class HicapsConnectHelpers {
  static buildBaseMessage(
    config: IPMSHicapsConnectConfig,
    terminal: WithRef<IHicapsConnectTerminal>
  ): HicapsConnect.BaseMessage {
    return {
      ServerUrl: terminal.terminalId,
      SoftwareVendorName: config.PmsName,
    };
  }

  static buildBaseRequest(
    config: IPMSHicapsConnectConfig,
    terminal: WithRef<IHicapsConnectTerminal>
  ): HicapsConnect.BaseRequest {
    return {
      ...this.buildBaseMessage(config, terminal),
      PmsKey: config.PmsKey,
    };
  }

  static getStatus(approvalCode: string): TransactionStatus {
    return HicapsConnectResponseCodes.isClaimSuccessful(approvalCode)
      ? TransactionStatus.Complete
      : TransactionStatus.Failed;
  }

  static getTransactionDate(
    transactionDate: string,
    responseDate: string
  ): Timestamp {
    const errorDate = moment('0001-01-01T00:00:00');
    const date = moment(transactionDate);
    if (date.isSame(errorDate)) {
      return toTimestamp(moment(responseDate));
    }
    return toTimestamp(moment(transactionDate));
  }

  static objectFromSubstrings<T extends string>(
    value: string,
    splitMap: SubstringMap<T>
  ): Record<T, string> {
    return mapValues(splitMap, ([start, end]) => value.substring(start, end));
  }

  static async getServiceDate(
    invoice: IInvoice,
    serviceDate?: Timestamp
  ): Promise<Moment> {
    const useServiceDate = serviceDate ?? invoice.createdAt;
    const timezone = await TimezoneResolver.fromPracticeRef(
      invoice.practice.ref
    );

    return toMomentTz(useServiceDate, timezone);
  }
}
