import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  VersionedSchema,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import {
  ISchedulingEventDisplay,
  Interaction,
  SchedulingEvent,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  InteractionType,
  isInteractionV2,
  type IInteraction,
  type IInteractionV2,
  type ITag,
  type WithContext,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  SystemActors,
  TIME_FORMAT,
  deleteDoc,
  isWithRef,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-interaction-timeline-event',
    templateUrl: './interaction-timeline-event.component.html',
    styleUrls: ['./interaction-timeline-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionTimelineEventComponent {
  interaction$ = new ReplaySubject<WithContext<IInteractionV2>>(1);
  isEditable$: Observable<boolean>;
  title$: Observable<RawInlineNodes>;
  tags$: Observable<WithRef<ITag>[]>;
  showCreatedAt$: Observable<boolean>;
  pinnableTypes = [InteractionType.Note, InteractionType.Call];
  @Input() fullDate = false;

  readonly dateFormat = TIME_FORMAT;
  readonly fullDateFormat = HISTORY_DATE_TIME_FORMAT;

  hasComments$: Observable<boolean>;
  schedulingEvent$: Observable<WithRef<ISchedulingEvent> | undefined>;
  schedulingEventDisplay$: Observable<ISchedulingEventDisplay | undefined>;
  ownerMention$: Observable<VersionedSchema | undefined>;
  unknown = SystemActors.Unknown;

  constructor() {
    this.title$ = this.interaction$.pipe(
      map((interaction) => Interaction.getTitle(interaction))
    );
    this.tags$ = this.interaction$.pipe(
      switchMap((interaction) => Interaction.tags$(interaction))
    );
    this.isEditable$ = this.interaction$.pipe(
      map(
        (interaction) =>
          isWithRef(interaction) &&
          !!interaction.owner &&
          [InteractionType.Note, InteractionType.Call].includes(
            interaction.originalType ?? interaction.type
          )
      )
    );
    this.showCreatedAt$ = this.interaction$.pipe(
      map((interaction) => interaction.type !== InteractionType.ClinicalNote)
    );

    this.hasComments$ = this.interaction$.pipe(
      map((interaction) => Interaction.hasContent(interaction))
    );

    this.schedulingEvent$ = this.interaction$.pipe(
      switchMap((interaction) => Interaction.getSchedulingEvent$(interaction))
    );
    this.schedulingEventDisplay$ = this.schedulingEvent$.pipe(
      map((schedulingEvent) =>
        schedulingEvent
          ? SchedulingEvent.getDisplay(schedulingEvent.eventType)
          : undefined
      )
    );
    this.ownerMention$ = this.schedulingEvent$.pipe(
      switchMap(async (schedulingEvent) =>
        schedulingEvent
          ? SchedulingEvent.resolveStafferMention(schedulingEvent)
          : undefined
      )
    );
  }

  @Input()
  set interaction(interaction: WithContext<IInteractionV2>) {
    if (interaction) {
      this.interaction$.next(interaction);
    }
  }

  getInteractionTypeIcon(interaction: IInteractionV2): string {
    return Interaction.getInteractionTypeIcon(interaction);
  }

  getInteractionTypeColourClass(interaction: IInteractionV2): string {
    return Interaction.getInteractionTypeColourClass(interaction);
  }

  getActionLabel(schedulingEvent: WithRef<ISchedulingEvent>): string {
    return SchedulingEvent.getActionlabel(schedulingEvent);
  }

  async updateInteraction(
    interaction: IInteraction | WithRef<WithContext<IInteractionV2>>,
    changes: Partial<IInteraction | WithRef<IInteractionV2>>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await Interaction.amend({ ...interaction, ...changes });
  }

  async deleteInteraction(
    interaction: IInteraction | WithRef<WithContext<IInteractionV2>>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await deleteDoc(interaction.ref);
  }
}
