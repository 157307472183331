<div class="flex flex-col gap-0.5">
  <div
    *ngIf="isNotConfirmed$ | async"
    class="bg-warn-500 badge"
    matTooltip="Appointment not confirmed"
    [matTooltipDisabled]="!tooltipsEnabled"
  >
    C
  </div>
  @if (showLabJobBadge$ | async) {
    @if (labJobBadgeState$ | async; as state) {
      <div
        class="badge"
        [ngClass]="{
          'bg-success-500': state === 'received',
          'bg-warn-500': state === 'overdue',
          'bg-amber-500': state === 'sentOrSending'
        }"
        [matTooltipDisabled]="!tooltipsEnabled"
        [matTooltip]="labJobTooltip$ | async"
      >
        L
      </div>
    }
  }
  <div
    *ngIf="hasArrived$ | async"
    class="bg-success-500 badge"
    matTooltip="Patient has arrived"
    [matTooltipDisabled]="!tooltipsEnabled"
  >
    A
  </div>
</div>
