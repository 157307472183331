import { isEnumValue } from '@principle-theorem/shared';

export enum Quadrant {
  AdultUpperRight = 1,
  AdultUpperLeft = 2,
  AdultLowerLeft = 3,
  AdultLowerRight = 4,
  DeciduousUpperRight = 5,
  DeciduousUpperLeft = 6,
  DeciduousLowerLeft = 7,
  DeciduousLowerRight = 8,
}

export const QUADRANTS: Quadrant[] = [
  Quadrant.AdultUpperRight,
  Quadrant.AdultUpperLeft,
  Quadrant.AdultLowerLeft,
  Quadrant.AdultLowerRight,
  Quadrant.DeciduousUpperRight,
  Quadrant.DeciduousUpperLeft,
  Quadrant.DeciduousLowerLeft,
  Quadrant.DeciduousLowerRight,
];

export enum Arch {
  Upper = 1,
  Lower = 2,
}

export function isQuadrant(item: unknown): item is Quadrant {
  return isEnumValue(Quadrant, item);
}

export function isArch(item: unknown): item is Arch {
  return isEnumValue(Arch, item);
}
