import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NgSharedModule } from '../../ng-shared.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [NgSharedModule, ReactiveFormsModule, PipesModule, MomentModule],
  exports: [NgSharedModule, ReactiveFormsModule, PipesModule, MomentModule],
})
export class StorybookCommonModule {}
