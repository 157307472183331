import {
  type RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  InteractionType,
  type IRecurringTaskConfiguration,
  isStaffer,
  type IStaffer,
  type ITask,
  type ITeam,
  MentionResourceType,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  InteractionProperty,
  RecurrencePattern,
  stafferToNamedDoc,
  Task,
  toMention,
  WorkflowItemUpdater,
} from '@principle-theorem/principle-core';
import {
  isSameRef,
  saveDoc,
  snapshot,
  TIME_FORMAT,
  toMoment,
  toNamedDocument,
  toTimestamp,
  toTimeString,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type ITaskFormData } from './components/task-form/task-form.formgroup';
import { type TaskManager } from './task-manager';

export class TaskUpdater extends WorkflowItemUpdater<WithRef<ITask>> {
  updateTitle(title: RawInlineNodes): this {
    if (this._item.title === title) {
      return this;
    }
    this._item.title = title;
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated title to `),
      ...title,
    ]);
    this._item.mentionRefs = Task.getAllMentionRefs(this._item);
    return this;
  }
  /**
   * Update task priority & add note
   */
  updatePriority(priority: TaskPriority): this {
    if (this._item.priority === priority) {
      return this;
    }
    this._item.priority = priority;
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated priority to `),
      new InteractionProperty(priority).toNodeSchema(),
    ]);
    return this;
  }

  /**
   * Update task due time & add note
   */
  updateDueTime(dueTime?: string): this {
    const interactionType: InteractionType = InteractionType.TaskEditDue;
    if (!this._item.dueDate) {
      return this;
    }

    if (!dueTime) {
      if (!Task.dueEndOfDay(this._item)) {
        this._item.dueDate = toTimestamp(
          // TODO: Build with timezone - CU-251edxw
          toMoment(this._item.dueDate).endOf('day')
        );
        this._transaction.add(interactionType, [
          toTextContent(`removed due time`),
        ]);
      }
      return this;
    }

    const dueDate: moment.Moment = toMoment(this._item.dueDate);
    if (toTimeString(dueTime) === dueDate.format(TIME_FORMAT)) {
      return this;
    }

    this._item.dueDate = toTimestamp(this._setTimeOnDate(dueDate, dueTime));
    this._transaction.add(interactionType, [
      toTextContent(`updated due time to `),
      new InteractionProperty(toTimeString(dueTime)).toNodeSchema(),
    ]);
    return this;
  }

  /**
   * Update task assignee & add note
   */
  updateAssignee(assignee?: WithRef<IStaffer> | WithRef<ITeam>): this {
    const noAssignee = !assignee && this._hasNoAssignee();
    const interactionType: InteractionType = InteractionType.TaskAssign;
    const sameUser: boolean = isStaffer(assignee)
      ? this._isSameUser(assignee)
      : false;
    const sameTeam: boolean = !isStaffer(assignee)
      ? this._isSameTeam(assignee)
      : false;
    if (noAssignee || sameUser || sameTeam) {
      return this;
    }

    if (!assignee) {
      this._transaction.add(interactionType, [
        toTextContent(`unassigned task`),
      ]);
      return this;
    }

    if (isStaffer(assignee)) {
      this._item.assignedUser = stafferToNamedDoc(assignee);
      this._item.assignedTeam = undefined;
      this._transaction.add(interactionType, [
        toTextContent(`assigned task to `),
        toMentionContent(toMention(assignee, MentionResourceType.Staffer)),
      ]);
      return this;
    }

    this._item.assignedUser = undefined;
    this._item.assignedTeam = toNamedDocument(assignee);
    this._transaction.add(interactionType, [
      toTextContent(`assigned task to `),
      toMentionContent(toMention(assignee, MentionResourceType.Staffer)),
    ]);
    return this;
  }

  /**
   * Update task's recurrence pattern
   */
  updateRecurrence(
    taskManager: TaskManager,
    data: ITaskFormData,
    recurringTaskConfig?: WithRef<IRecurringTaskConfiguration>
  ): this {
    const interactionType: InteractionType = InteractionType.TaskEdit;
    if (!data.recurrencePattern && !recurringTaskConfig) {
      return this;
    }

    if (!data.recurrencePattern) {
      this._transaction.add(interactionType, [
        toTextContent(`removed as recurring task`),
      ]);
      this._transaction.onCommit(async () => {
        if (!recurringTaskConfig) {
          return;
        }
        this._item.recurrenceConfiguration = undefined;
        await taskManager.deleteRecurringTaskConfiguration(recurringTaskConfig);
      });
      return this;
    }

    if (!recurringTaskConfig) {
      this._transaction.add(interactionType, [
        toTextContent(`changed to recurring task`),
      ]);
      this._transaction.onCommit(async () => {
        const practice = await snapshot(Task.practice$(this._item));
        const recurringRef = await taskManager.addConfiguration(
          practice,
          data,
          this._item
        );
        this._item.recurrenceConfiguration = recurringRef;
      });
      return this;
    }

    const updateRequired = !RecurrencePattern.matches(
      data.recurrencePattern,
      recurringTaskConfig.recurrencePattern
    );
    if (!updateRequired) {
      return this;
    }

    recurringTaskConfig.recurrencePattern = data.recurrencePattern;
    this._transaction.add(interactionType, [
      toTextContent(`updated recurring task settings`),
    ]);
    this._transaction.onCommit(async () => {
      await saveDoc(recurringTaskConfig);
    });
    return this;
  }

  /**
   * Helper method will set the time on a moment object where time is a string
   * @param date moment.Moment
   * @param time string
   */
  private _setTimeOnDate(date: moment.Moment, time: string): moment.Moment {
    const timeAsMoment: moment.Moment = moment(time, TIME_FORMAT);
    return date.hour(timeAsMoment.hour()).minute(timeAsMoment.minute());
  }

  private _isSameUser(user?: WithRef<IStaffer>): boolean {
    if (!user || !this._item.assignedUser) {
      return false;
    }
    return isSameRef(this._item.assignedUser, user);
  }

  private _isSameTeam(team?: WithRef<ITeam>): boolean {
    if (!team || !this._item.assignedTeam) {
      return false;
    }
    return isSameRef(team, this._item.assignedTeam);
  }

  private _hasNoAssignee(): boolean {
    return !this._item.assignedTeam && !this._item.assignedUser;
  }
}
