import { type StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStep } from '@angular/material/stepper';
import {
  AppointmentAutomationsRescheduleComponent,
  WaitlistFormGroup,
  type AppointmentDetailsComponent,
} from '@principle-theorem/ng-appointment';
import {
  getWaitListDetailsFromAppointment,
  type AppointmentSuggestionEntity,
  type IAppointmentDetails,
  type IWaitListDetails,
} from '@principle-theorem/ng-appointment/store';
import {
  DateService,
  IReasonSelectorValue,
  OrganisationService,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  AppointmentSuggestion,
  Brand,
  DEFAULT_TIME_INCREMENT,
  Event,
  EventTimePeriod,
  Practice,
  SchedulingEvent,
  SchedulingEventReason,
  Staffer,
  TreatmentStep,
  getPlanWithBookableStep,
  isSameTimeStamp,
  staffToNamedDocs,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEventConditions,
  ISchedulingEventData,
  ISchedulingEventReason,
  isEventable,
  type IAppointmentSuggestion,
  type IBrand,
  type IEvent,
  type IPractice,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  Firestore,
  TIME_FORMAT,
  TimeBucket,
  filterUndefined,
  isINamedDocument,
  snapshot,
  toEntityModel,
  toEntityModels$,
  toMomentTz,
  toTimePeriod,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
  Timezone,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GapManager, type IMoveAppointmentData } from '../../gap-manager';

@Component({
  selector: 'pr-move-appointment',
  templateUrl: './move-appointment.component.html',
  styleUrls: ['./move-appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class MoveAppointmentComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _gapManager = new GapManager();
  private _appointmentDetailsComponent$ =
    new ReplaySubject<AppointmentDetailsComponent>(1);
  private _timezone$: Observable<Timezone>;

  practices$: Observable<WithRef<IPractice>[]>;
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  appointmentDetails$ = new ReplaySubject<IAppointmentDetails>(1);
  waitlistDetails$ = new ReplaySubject<IWaitListDetails>(1);
  automationReschedule$ =
    new ReplaySubject<AppointmentAutomationsRescheduleComponent>(1);
  waitlistForm: WaitlistFormGroup = new WaitlistFormGroup();
  appointmentOptions$: Observable<AppointmentSuggestionEntity[]>;
  @ViewChild('appointmentSelectStep', { read: MatStep, static: true })
  _appointmentSelectSet: MatStep;
  appointmentSuggestion$ = new ReplaySubject<AppointmentSuggestionEntity>(1);
  newEvent$: Observable<IEvent>;
  treatmentStep$: Observable<WithRef<ITreatmentStep>>;
  completed$: Observable<boolean>;
  selectedId$ = new BehaviorSubject<string | undefined>(undefined);
  openTime$: Observable<moment.Moment>;
  closeTime$: Observable<moment.Moment>;

  reasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  schedulingConditions$: Observable<ISchedulingEventConditions>;
  reasonControl = new TypedFormControl<IReasonSelectorValue>(
    undefined,
    Validators.required
  );

  @ViewChild('appointmentDetails', { static: true })
  set appointmentDetailsComponent(
    appointmentDetails: AppointmentDetailsComponent
  ) {
    if (appointmentDetails) {
      this._appointmentDetailsComponent$.next(appointmentDetails);
    }
  }

  @ViewChild(AppointmentAutomationsRescheduleComponent, { static: false })
  set automationReschedule(
    component: AppointmentAutomationsRescheduleComponent
  ) {
    if (component) {
      this.automationReschedule$.next(component);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMoveAppointmentData,
    public dialogRef: MatDialogRef<MoveAppointmentComponent>,
    public dateService: DateService,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _timezone: TimezoneService
  ) {
    if (isEventable(this.data.appointment)) {
      const appointmentSuggestion = toEntityModel(
        AppointmentSuggestion.init({
          event: this.data.appointment.event,
          practitioner: this.data.appointment.practitioner,
          score: 1,
        })
      );
      this.appointmentSuggestion$.next(appointmentSuggestion);
    }

    const practice$ = Firestore.doc$(this.data.gap.event.practice.ref);
    this.openTime$ = practice$.pipe(
      map((practice) => Practice.openTime(practice))
    );
    this.closeTime$ = practice$.pipe(
      map((practice) => Practice.closeTime(practice))
    );

    this.loadAppointmentOptions();
    this._gapManager.copyGapEventToAppointment(
      this.data.gap,
      this.data.gapCandidate.candidate,
      this.data.appointment,
      this.data.patient
    );

    this.practices$ = this._organisation.practices$;

    this.completed$ = this.appointmentSuggestion$.pipe(map((event) => !!event));

    this.practitioners$ = combineLatest([
      this.appointmentDetails$.pipe(map((details) => details.practice)),
      this._organisation.brand$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([practice, brand]) =>
        practice
          ? Staffer.practitionersByPractice$(practice)
          : Staffer.practitionersByBrand$(brand)
      ),
      staffToNamedDocs()
    );

    this._appointmentDetailsComponent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((component) => {
        component.form.disable();
        component.form.controls.duration.enable();
      });

    this.waitlistDetails$.next(
      getWaitListDetailsFromAppointment(this.data.appointment)
    );

    this.treatmentStep$ = Appointment.treatmentStep$(this.data.appointment);
    this.newEvent$ = this.appointmentSuggestion$.pipe(
      map((suggestion) => suggestion.event)
    );

    this._timezone$ = this._timezone.resolvePracticeTimezone$(
      this.data.appointment.practice.ref
    );

    this.schedulingConditions$ = combineLatest([
      this._timezone$,
      this.appointmentSuggestion$,
    ]).pipe(
      map(([timezone, appointmentSuggestion]) =>
        SchedulingEvent.getSchedulingConditions(
          timezone,
          this.data.appointment?.event?.from,
          appointmentSuggestion?.event.from,
          undefined,
          true
        )
      )
    );
    const reasons$ = this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.cancellationReasons$(brand))
    );
    this.reasons$ = combineLatest([reasons$, this.schedulingConditions$]).pipe(
      map(([reasons, conditions]) =>
        SchedulingEventReason.filterByEventType(reasons, conditions.eventType)
      )
    );
  }

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async load(): Promise<void> {
    const treatment = await getPlanWithBookableStep(this.data.appointment);
    const practice = await snapshot(
      Appointment.practice$(this.data.appointment)
    );
    this.appointmentDetails$.next({
      practitioner: this.data.appointment.practitioner,
      duration: this.getDuration(),
      practice,
      treatment,
    });
  }

  waitlistChange(change: IWaitListDetails): void {
    this.waitlistForm.patchValue(change);
  }

  getDuration(): number {
    const gapDuration = Event.duration(this.data.gap.event);
    const appointmentDuration = Appointment.duration(this.data.appointment);
    return gapDuration > appointmentDuration
      ? appointmentDuration
      : gapDuration;
  }

  async submit(): Promise<void> {
    const appointmentSuggestion = await snapshot(this.appointmentSuggestion$);
    if (!appointmentSuggestion) {
      return;
    }

    const appointmentDetails = await snapshot(this.appointmentDetails$);
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );

    const automationRescheduleComponent = await snapshot(
      this.automationReschedule$
    );
    await automationRescheduleComponent.update();

    const reasonFormData =
      this.reasonControl.getRawValue() as IReasonSelectorValue;
    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingEventData: ISchedulingEventData = {
      scheduledByPractice: practice.ref,
      reason: reasonFormData.reason,
      reasonSetManually: reasonFormData.reasonSetManually,
      schedulingConditions: await snapshot(this.schedulingConditions$),
    };

    await this._gapManager.approveCandidate(
      staffer,
      this.data,
      {
        ...appointmentDetails,
        waitListItem: { ...this.waitlistForm.getRawValue() },
        event: appointmentSuggestion.event,
      },
      schedulingEventData
    );

    const timezone = await snapshot(
      this._timezone.resolvePracticeTimezone$(
        this.data.appointment.practice.ref
      )
    );

    this._snackBar.open(
      `Appointment rescheduled to ` +
        `${toMomentTz(appointmentSuggestion.event.from, timezone).format(
          DATE_FORMAT
        )} @ ` +
        `${toMomentTz(appointmentSuggestion.event.from, timezone).format(
          TIME_FORMAT
        )}`
    );

    this.dialogRef.close();
  }

  loadAppointmentOptions(): void {
    const duration: moment.Duration = moment.duration(
      this.getDuration(),
      'minutes'
    );

    this.appointmentOptions$ = this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) =>
        !isINamedDocument(this.data.gap.event.practice)
          ? of([])
          : allEventTimes$(
              DEFAULT_TIME_INCREMENT,
              this.data.gap.event,
              duration,
              this.data.gap.event.practice,
              this.data.gap.practitioner,
              brand,
              TreatmentStep.defaultDisplayRef(
                this.data.appointment.treatmentPlan.treatmentStep.display
              )
            )
      ),
      toEntityModels$(),
      tap((options) => this.setSuggestionFromOptions(options))
    );
  }

  setSuggestionFromOptions(options: AppointmentSuggestionEntity[]): void {
    if (options.length === 1) {
      this.appointmentSuggestion$.next(options[0]);
      return;
    }

    const foundSuggestion = options.find((suggestion) => {
      if (
        isSameTimeStamp(
          suggestion.event.from,
          this.data.gapCandidate.candidate.offerTimeFrom
        ) &&
        isSameTimeStamp(
          suggestion.event.to,
          this.data.gapCandidate.candidate.offerTimeTo
        )
      ) {
        return true;
      }
    });

    if (!foundSuggestion) {
      return;
    }
    this.appointmentSuggestion$.next(foundSuggestion);
  }

  handleStepChange(event: StepperSelectionEvent): void {
    if (event.selectedStep === this._appointmentSelectSet) {
      void this.loadAppointmentOptions();
    }
  }
}

export function allEventTimes$(
  timeIncrement: moment.Duration,
  event: IEvent,
  duration: moment.Duration,
  practice: INamedDocument<IPractice>,
  staffer: INamedDocument<IStaffer>,
  brand: WithRef<IBrand>,
  treatmentCategoryRef?: DocumentReference<ITreatmentCategory>
): Observable<IAppointmentSuggestion[]> {
  const options = TimeBucket.fromEvents([event])
    .exhaustTimeOptions({
      timeIncrement,
      duration,
    })
    .get()
    .map((period) =>
      EventTimePeriod.init({
        ...period,
        practice,
        staffer,
      })
    );

  return Brand.findCalendarEventsIntersectingRange$(
    brand,
    toTimePeriod(event.from, event.to)
  ).pipe(
    map((calendarEvents) =>
      AppointmentSuggestion.convertOptionsToSuggestions(
        staffer,
        options,
        [],
        event,
        calendarEvents,
        {
          distance: false,
          duration: false,
        },
        compact([treatmentCategoryRef])
      )
    )
  );
}
