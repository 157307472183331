<table mat-table [dataSource]="dataSource" matSort class="w-full">
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
    <td mat-cell *matCellDef="let data">
      {{ data.createdAt | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="prescriber">
    <th mat-header-cell *matHeaderCellDef>Prescribed By</th>
    <td mat-cell *matCellDef="let data">
      {{ data | map: getPrescriberDisplayName$: this | async }}
    </td>
  </ng-container>

  <ng-container matColumnDef="items">
    <th mat-header-cell *matHeaderCellDef>Items</th>
    <td mat-cell *matCellDef="let data">
      @for (item of data.items; track item.medicationName ) {
      <div>{{ item.medicationName }}</div>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let data">
      <pr-status-label
        class="w-20 align-middle"
        [dense]="true"
        [label]="data.status"
        [color]="data.status | map: statusColor"
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="cursor-pointer hover:bg-gray-100"
    (click)="prescriptionSelected.emit(row)"
  ></tr>
</table>
