import { isObject, Region, TypeGuard } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { SmsIntegrationOption } from '../interaction/sms-integration';

export enum OrganisationStatus {
  Active = 'active',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Suspended = 'suspended',
}

export interface IOrganisationIntegrationOptions {
  smsProvider: SmsIntegrationOption;
}

export interface IOrganisation {
  name: string;
  slug: string;
  status: OrganisationStatus;
  region: Region;
  integrations?: Partial<IOrganisationIntegrationOptions>;
  subscription?: IOrganisationSubscription;
}

export const isOrganisation = TypeGuard.interface<IOrganisation>({
  name: isString,
  slug: isString,
  status: TypeGuard.enumValue(OrganisationStatus),
  region: TypeGuard.enumValue(Region),
  integrations: TypeGuard.nilOr(isObject),
  subscription: TypeGuard.nilOr(isObject),
});

export interface IOrganisationSubscription {
  subscriptionId: string;
  customerId: string;
}

export const ORGANISATION_STATUS_MAP: {
  [key in OrganisationStatus]: string;
} = {
  [OrganisationStatus.Pending]: 'default',
  [OrganisationStatus.Active]: 'primary',
  [OrganisationStatus.Cancelled]: 'default',
  [OrganisationStatus.Suspended]: 'warn',
};

export enum OrganisationCollection {
  Users = 'users',
  Brands = 'brands',
  FeeSchedules = 'feeSchedules',
  Roles = 'roles',
  Integrations = 'integrations',
  TemporaryTokens = 'temporaryTokens',
}

export enum CollectionGroup {
  CalendarEvents = 'calendarEvents',
  Appointments = 'appointments',
  TreatmentPlans = 'treatmentPlans',
  Templates = 'templates',
  LabJobs = 'labJobs',
  TemporaryTokens = 'temporaryTokens',
  Invoices = 'invoices',
  Transactions = 'transactions',
  FeeSchedules = 'feeSchedules',
  ConditionConfigurations = 'conditionConfigurations',
  TreatmentConfigurations = 'treatmentConfigurations',
  MultiTreatmentConfigurations = 'multiTreatmentConfigurations',
  BridgeDevices = 'bridgeDevices',
  AutomationConfigurations = 'automationConfigurations',
  AccountCredits = 'credits',
}
