import {
  HealthcareClaimStatus,
  IHealthcareClaim,
  IHealthcareClaimItem,
  IStaffer,
  ITransaction,
  ServiceTypeModality,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';

export class HealthcareClaimMock
  extends BaseMock
  implements Properties<IHealthcareClaim>
{
  uid = uuid();
  practitioner = MockNamedDocument<IStaffer>('staffer');
  providerData = {
    providerNumber: '1234',
    providerModality: ServiceTypeModality.GeneralDentist,
  };
  status = HealthcareClaimStatus.Claimed;
  items: IHealthcareClaimItem[] = [
    MockGenerator.generate(HealthcareClaimItemMock),
  ];
  transactions = [MockDocRef<ITransaction>()];
  outdatedAt = MockTimestamp();
}

export class HealthcareClaimItemMock
  extends BaseMock
  implements Properties<IHealthcareClaimItem>
{
  treatmentUid = uuid();
  serviceCodeUid = uuid();
  quantity = 1;
  provider = MockNamedDocument<IStaffer>();
}
