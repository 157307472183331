<div
  *ngIf="loader$ | async as loader"
  class="message-panel"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="8px"
>
  <h2>{{ loader.heading }}</h2>
  <mat-progress-bar
    [mode]="loader.mode"
    [value]="loader.progress"
   />
  <p class="mat-caption">{{ loader.message }}</p>
</div>

<ng-container *ngIf="store.hasData$ | async">
  <ng-content></ng-content>
</ng-container>

<ng-container *ngIf="store.hasNoData$ | async">
  <pr-empty-state
    image="search"
    title="Data Found"
    [searchInputValue]="searchValue$ | async"
   />
</ng-container>

<div
  *ngIf="store.isError$ | async"
  class="message-panel"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <mat-icon>report_problem</mat-icon>
  <h2>Oops! There was a problem fetching your data.</h2>
  <p class="mat-caption">Please contact support if this issue persists.</p>
</div>
