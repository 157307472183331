<mat-menu class="pr-right-click-menu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      [disabled]="disabled$ | async"
      (click)="openAddTooth()"
    >
      Add Tooth
    </button>
  </ng-template>
</mat-menu>
