import { randBoolean, randNumber } from '@ngneat/falso';
import { MockTimeUntilAppointment } from '@principle-theorem/principle-core';
import { ILabJobReceivedFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { LabDimensionMock } from '../dimensions/lab-dimension.mock';
import { LabJobDimensionMock } from '../dimensions/lab-job-dimension.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class LabJobReceivedFactMock
  extends BaseMock
  implements ILabJobReceivedFact
{
  labRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  labJobRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  appointmentRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  lab = MockGenerator.generate(LabDimensionMock);
  labJob = MockGenerator.generate(LabJobDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  appointment = MockGenerator.generate(AppointmentDimensionMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  isOverdue = randBoolean();
  overdueBy = randNumber({
    min: 1,
    max: 60,
  });
  requiredForAppointment = randBoolean();
  timeUntilAppointment = MockTimeUntilAppointment();
}
