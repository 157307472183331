import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { PrescriptionListItemComponent } from '../prescription-list-item/prescription-list-item.component';
import { ReplaySubject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'pr-prescription-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    PrescriptionListItemComponent,
    EmptyStateComponent,
  ],
  templateUrl: './prescription-list.component.html',
  styleUrl: './prescription-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionListComponent {
  items$ = new ReplaySubject<WithRef<IPrescriptionItem>[]>(1);

  @Input()
  set items(items: WithRef<IPrescriptionItem>[]) {
    if (items) {
      this.items$.next(items);
    }
  }

  @Input({ transform: coerceBooleanProperty }) loading = false;
  @Output() itemDeleted = new EventEmitter<WithRef<IPrescriptionItem>>();
  @Output() itemEdit = new EventEmitter<WithRef<IPrescriptionItem>>();
}
