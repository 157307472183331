import {
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceSyncErrorData,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  getError,
  type WithRef,
  Timezone,
} from '@principle-theorem/shared';
import { compact, isString } from 'lodash';
import { EMPTY, from, of, type Observable } from 'rxjs';
import { catchError, expand } from 'rxjs/operators';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { CopyFiles, getBucketStoragePath } from '../../../storage';
import { PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY } from '../../destination/entities/patient-profile-photos';

export const PATIENT_PROFILE_PHOTO_RESOURCE_TYPE = 'patientProfilePhoto';

export const PATIENT_PROFILE_PHOTO_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Profile Photo List',
      description: '',
      idPrefix: PATIENT_PROFILE_PHOTO_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientProfilePhoto {
  patientId: string;
  fileName: string;
  folderPath: string;
}

export interface ID4WPatientProfilePhotoFilters {
  patientId: string;
}

export function isD4WPatientProfilePhoto(
  item: unknown
): item is ID4WPatientProfilePhoto {
  return TypeGuard.interface<ID4WPatientProfilePhoto>({
    patientId: isString,
    fileName: isString,
    folderPath: isString,
  })(item);
}

export class PatientProfilePhotoSourceEntity extends BaseSourceEntity<
  ID4WPatientProfilePhoto,
  unknown,
  ID4WPatientProfilePhotoFilters
> {
  sourceEntity = PATIENT_PROFILE_PHOTO_SOURCE_ENTITY;
  entityResourceType = PATIENT_PROFILE_PHOTO_RESOURCE_TYPE;
  sourceQuery = '';
  verifySourceFn = isD4WPatientProfilePhoto;
  override defaultOffsetSize = 1000000;
  isSingleRun = true;

  migrationDestinations = [
    PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY.metadata.key,
  ];

  override getFromSource$(
    migration: WithRef<IPracticeMigration>
  ): Observable<ID4WPatientProfilePhoto[] | ISourceSyncErrorData> {
    return from(
      this.getPatientProfileImages(
        migration.configuration.projectId,
        getBucketStoragePath(migration.source),
        `profile-photos/`
      )
    ).pipe(
      expand(() => EMPTY),
      catchError((error) =>
        of({
          resumeData: {},
          errorMessage: getError(error),
        })
      )
    );
  }

  translate(): object {
    return {};
  }

  getSourceRecordId(data: ID4WPatientProfilePhoto): string {
    return data.patientId;
  }

  getSourceLabel(data: ID4WPatientProfilePhoto): string {
    return data.patientId;
  }

  getFilterData(
    data: ID4WPatientProfilePhoto,
    _timezone: Timezone
  ): ID4WPatientProfilePhotoFilters {
    return {
      patientId: data.patientId,
    };
  }

  async getPatientProfileImages(
    projectId: string,
    bucketName: string,
    bucketPath: string
  ): Promise<ID4WPatientProfilePhoto[]> {
    const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
      .storage;

    const [files] = await storage.bucket(bucketName).getFiles({
      prefix: bucketPath,
    });

    const missingFields: string[] = [];

    return compact(
      files.map((file) => {
        const folderPath = file.name.split('/');

        const fileSummary: ID4WPatientProfilePhoto = {
          patientId: '',
          fileName: '',
          folderPath: '',
        };

        const patientIdMatch = new RegExp(/([0-9]+).*$/).exec(folderPath[1]);
        fileSummary.patientId = patientIdMatch?.[1] ?? '';

        fileSummary.folderPath = folderPath.slice(0, 1).join('/') ?? '';
        fileSummary.fileName = folderPath.slice(1).join('_');

        if (!fileSummary.patientId || !fileSummary.fileName) {
          missingFields.push(file.name);
        }

        return fileSummary;
      })
    );
  }
}
