import { Injectable, OnDestroy } from '@angular/core';
import { ContactNumberFormGroup } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IBasePatient,
  IDVACard,
  IHealthFundCard,
  IMedicareCard,
  IPatient,
  isDVACard,
  isHealthFundCard,
  isMedicareCard,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  WithRef,
  isISODateType,
  isObject,
  isTimestamp,
  snapshot,
} from '@principle-theorem/shared';
import { get, isEmpty, isEqual, isNil, mapValues } from 'lodash';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  IPatientDetailsFormData,
  PatientDetailsFormDataGroup,
} from '../patient-details-form/patient-details-form-group';

export interface ICompareFormFieldState {
  hasPatientChanges: boolean;
  tooltipMessage: string;
  hintMessage: string;
}

export type BasePatientField = Omit<
  IPatientDetailsFormData,
  | 'contactNumbers'
  | 'healthFundCard'
  | 'medicareCard'
  | 'dvaCard'
  | 'referrer'
  | 'showHealthFundCard'
  | 'showMedicareCard'
  | 'showDVACard'
>;

export type PatientHealthCardField = Pick<
  IBasePatient,
  'healthFundCard' | 'medicareCard' | 'dvaCard'
>;

@Injectable()
export class PatientDetailsFormComparisonService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  private _patientDetails$ = new ReplaySubject<IPatient>(1);
  private _form: PatientDetailsFormDataGroup;
  disabled$ = new BehaviorSubject<boolean>(false);

  init(
    patient$: Observable<WithRef<IPatient>>,
    patientDetails$: Observable<IPatient>,
    form: PatientDetailsFormDataGroup
  ): void {
    patient$.pipe(takeUntil(this._onDestroy$)).subscribe(this._patient$);
    patientDetails$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(this._patientDetails$);
    this._form = form;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getBasePatientFieldState$(
    fieldName: keyof BasePatientField,
    subFieldName?: string
  ): Observable<ICompareFormFieldState | undefined> {
    const formCtrl = this._form.controls[fieldName] as TypedFormControl<
      IPatientDetailsFormData[keyof BasePatientField]
    >;

    return combineLatest([
      this._patient$.pipe(map((patient) => get(patient, fieldName))),
      formCtrl.valueChanges.pipe(startWith(formCtrl.value)),
      this._patientDetails$.pipe(map((patient) => get(patient, fieldName))),
      this.disabled$,
    ]).pipe(
      map(([savedPatientValue, formValue, submittedValue, isDisabled]) => {
        const effectiveFormValue =
          subFieldName && isObject(formValue)
            ? formValue[subFieldName]
            : formValue;
        return isDisabled || isEqual(submittedValue, savedPatientValue)
          ? undefined
          : this._getState(
              savedPatientValue,
              effectiveFormValue,
              submittedValue
            );
      })
    );
  }

  getContactNumberFieldState$(
    formGroup: ContactNumberFormGroup
  ): Observable<ICompareFormFieldState | undefined> {
    return combineLatest([
      this._patient$.pipe(map((patient) => patient.contactNumbers)),
      formGroup.valueChanges.pipe(startWith(formGroup.value)),
      this._patientDetails$.pipe(
        map((patient) => get(patient, 'contactNumbers'))
      ),
      this.disabled$,
    ]).pipe(
      map(([savedValue, formValue, submittedValue, isDisabled]) => {
        if (!formValue?.label || isDisabled) {
          return;
        }
        const savedNumber = savedValue?.find((contactNumber) =>
          isEqual(contactNumber.label, formValue.label)
        );
        const submittedNumber = submittedValue?.find((contactNumber) =>
          isEqual(contactNumber.label, formValue.label)
        );
        if (!savedNumber || !submittedNumber) {
          return;
        }
        return this._getState(
          savedNumber.number,
          formValue.number,
          submittedNumber.number
        );
      })
    );
  }

  getHealthCardFieldState$(
    formGroup: TypedFormGroup<IHealthFundCard | IMedicareCard | IDVACard>,
    fieldName: keyof PatientHealthCardField
  ): Observable<ICompareFormFieldState | undefined> {
    return combineLatest([
      this._patient$.pipe(map((patient) => patient[fieldName])),
      formGroup.valueChanges.pipe(startWith(formGroup.value)),
      this._patientDetails$.pipe(map((patient) => patient[fieldName])),
      this.disabled$,
    ]).pipe(
      map(([savedValue, formValue, submittedValue, isDisabled]) =>
        this._getFormGroupState(
          savedValue,
          formValue,
          submittedValue,
          isDisabled
        )
      )
    );
  }

  async revertChange(
    fieldName: keyof BasePatientField,
    state: ICompareFormFieldState
  ): Promise<void> {
    const formCtrl = this._form.controls[fieldName];

    if (state.hasPatientChanges) {
      const patient = await snapshot(this._patient$);
      !patient[fieldName]
        ? formCtrl.reset()
        : formCtrl.setValue(patient[fieldName]);
      return;
    }

    const submittedDetails = await snapshot(this._patientDetails$);
    !submittedDetails[fieldName]
      ? formCtrl.reset()
      : formCtrl.setValue(submittedDetails[fieldName]);
  }

  async revertAddressChange(state: ICompareFormFieldState): Promise<void> {
    const formCtrl = this._form.controls['address'];

    if (state.hasPatientChanges) {
      const patient = await snapshot(this._patient$);
      const metadata = patient.metadata?.address;

      if (!isEmpty(metadata)) {
        formCtrl.setValue(metadata);
        return;
      }

      if (patient.address) {
        const address = { address: patient.address };
        formCtrl.setValue(address);
        return;
      }

      formCtrl.reset();
      return;
    }

    const submittedDetails = await snapshot(this._patientDetails$);
    const submittedAddress = submittedDetails.metadata?.address;
    submittedAddress ? formCtrl.setValue(submittedAddress) : formCtrl.reset();
  }

  async revertContactNumberChange(
    formGroup: ContactNumberFormGroup,
    state: ICompareFormFieldState
  ): Promise<void> {
    const label = formGroup.value.label;
    if (!label) {
      return;
    }

    if (state.hasPatientChanges) {
      const patient = await snapshot(this._patient$);
      const found = patient.contactNumbers?.find(
        (contactNumber) => contactNumber.label === label
      );
      if (!found) {
        return;
      }
      formGroup.setValue(found);
      return;
    }

    const submittedDetails = await snapshot(this._patientDetails$);
    const found = submittedDetails.contactNumbers?.find(
      (contactNumber) => contactNumber.label === label
    );
    if (!found) {
      return;
    }
    formGroup.setValue(found);
  }

  async revertHealthCardChange(
    formGroup: TypedFormGroup<IHealthFundCard | IMedicareCard | IDVACard>,
    state: ICompareFormFieldState | undefined,
    fieldName: keyof PatientHealthCardField
  ): Promise<void> {
    if (!state) {
      return;
    }

    if (state.hasPatientChanges) {
      const details = await snapshot(
        this._patient$.pipe(map((patient) => patient[fieldName]))
      );
      if (!details) {
        formGroup.reset();
        return;
      }
      formGroup.patchValue(details);
      return;
    }

    const submittedDetails = await snapshot(
      this._patientDetails$.pipe(map((patient) => patient[fieldName]))
    );
    if (!submittedDetails) {
      return;
    }
    formGroup.patchValue(submittedDetails);
  }

  private _getState<T>(
    savedValue: T,
    formValue: T,
    submittedValue: T
  ): ICompareFormFieldState {
    const hasPatientChanges =
      isEqual(this._normalise(submittedValue), this._normalise(formValue)) &&
      !isEqual(this._normalise(submittedValue), savedValue);

    const tooltipMessage = this._getButtonTooltip(hasPatientChanges);
    const hintMessage = this._getHintMessage(
      hasPatientChanges,
      savedValue,
      submittedValue
    );

    return {
      tooltipMessage,
      hintMessage,
      hasPatientChanges,
    };
  }

  private _getFormGroupState<T>(
    savedValue: T | undefined,
    formValue: T,
    submittedValue: T | undefined,
    isDisabled: boolean
  ): ICompareFormFieldState | undefined {
    if (
      isEqual(this._normalise(savedValue), this._normalise(submittedValue)) ||
      isDisabled
    ) {
      return;
    }
    return this._getState(savedValue, formValue, submittedValue);
  }

  private _getButtonTooltip(hasPatientChanges: boolean): string {
    return hasPatientChanges
      ? 'Revert to current patient details'
      : 'Revert to change from submitted form';
  }

  private _getHintMessage<T>(
    hasPatientChanges: boolean,
    savedPatientValue: T,
    submittedValue: T
  ): string {
    return hasPatientChanges
      ? `Current patient details value: ${this._formattedValue(
          savedPatientValue
        )}`
      : `Submitted form value: ${this._formattedValue(submittedValue)}`;
  }

  private _formattedValue(value: unknown): string {
    if (!value) {
      return 'None';
    }

    if (isHealthFundCard(value)) {
      const required = `Membership Number: ${
        value.membershipNumber ?? 'None'
      }; Member Number: ${value.memberNumber ?? 'None'}`;

      return value.fundCode
        ? `Health Fund Name: ${value.fundCode}; ${required}`
        : required;
    }

    if (isMedicareCard(value)) {
      return `Card Number: ${
        value.number.length ? value.number : 'None'
      }; Member Number: ${
        value.subNumerate.length ? value.subNumerate : 'None'
      }`;
    }

    if (isDVACard(value)) {
      return `Card Number: ${value.number.length ? value.number : 'None'}`;
    }

    if (isISODateType(value)) {
      return moment(value).format(DAY_MONTH_YEAR_FORMAT);
    }

    return value as string;
  }

  private _normalise<T>(value: T | undefined | null): T | undefined {
    if (isTimestamp(value)) {
      return value;
    }
    if (isObject(value)) {
      if (Object.values(value).every(isEmpty)) {
        return;
      }
      return mapValues(value, this._normalise) as T;
    }
    return isNil(value) || isEmpty(value) ? undefined : value;
  }
}
