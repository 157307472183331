import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pr-checklist-item-status',
    templateUrl: './checklist-item-status.component.html',
    styleUrls: ['./checklist-item-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChecklistItemStatusComponent {
  @Input() isDone?: boolean;

  get statusColor(): string {
    return this.isDone ? 'primary' : 'default';
  }

  get statusLabel(): string {
    return this.isDone ? 'completed' : 'pending';
  }
}
