<div
  class="practitioner-claims"
  *ngFor="
    let group of practitionerClaims$ | async;
    trackBy: trackByPractitioner
  "
>
  <div class="header">
    <pt-user-icon
      [src]="group.practitioner | map : stafferImage$ : this | async"
      [name]="group.practitioner.user.name"
      [diameter]="45"
    />
    <div>
      <div>
        <strong>{{ group.practitioner.user.name }}</strong>
      </div>
      <div
        *ngIf="
          group.providerData?.providerNumber as providerNumber;
          else noProviderNumber
        "
        class="mat-caption"
      >
        {{ providerNumber }}
      </div>

      <ng-template #noProviderNumber>
        <mat-error>Missing Provider Number</mat-error>
      </ng-template>
    </div>
  </div>

  <div class="claimed">
    <pr-healthcare-claim
      *ngFor="let claim of group.claims; trackBy: trackByClaim"
      [invoice]="invoice$ | async"
      [claim]="claim"
    />
  </div>

  <div class="unclaimed py-1 pl-2" *ngIf="group.unclaimedCount">
    <div class="status"><strong>UNCLAIMED</strong></div>
    <div class="flex-auto"></div>
    <div class="count">
      <pr-healthcare-claim-items-summary [items]="group.unclaimedItems" />
    </div>

    <div class="price-actions w-1/4">
      <pr-healthcare-claim-selector
        matTooltip="Provider number must be set to process a claim"
        [matTooltipDisabled]="!!group.providerData?.providerNumber"
        [invoice]="invoice$ | async"
        [disabled]="!group.providerData?.providerNumber"
        (providerSelected)="claim(group, $event)"
      >
        {{ group.unnclaimedAmount | currency }} available
      </pr-healthcare-claim-selector>
    </div>
  </div>
</div>
