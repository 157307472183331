<ng-container *ngIf="invoice$ | async as invoice">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
    ptElementTarget="invoice-status-indicator"
  >
    <ng-container *ngIf="invoice.deleted; else status">
      <div class="status" color="warn">DELETED</div>
    </ng-container>
    <ng-template #status>
      <div class="status">{{ invoice.status | uppercase }}</div>
    </ng-template>
    @if (invoice.type === invoiceType.CreditNote) {
      <h1 class="mat-headline-4">Credit Note</h1>
    } @else {
      <h1 class="mat-headline-4">Tax Invoice</h1>
    }
  </div>

  <ng-template #tbd>TBD</ng-template>

  <div class="layout-margin">
    <div>
      <strong>Invoice No:</strong>
      <a [routerLink]="link$ | async" ptElementTarget="invoice-link">
        {{ invoice.reference }}
      </a>
    </div>
    <div>
      <strong>Issued Date:</strong>&nbsp;
      <ng-container *ngIf="invoice.issuedAt; else tbd">
        {{ invoice.issuedAt | moment | amDateFormat: dateFormat }}
      </ng-container>
    </div>
    <div>
      <strong>Due Date:</strong>&nbsp;
      <ng-container
        *ngIf="invoice.due && (isDraft$ | async) === false; else tbd"
      >
        {{ invoice.due | moment | amDateFormat: dateFormat }}
      </ng-container>
    </div>

    <div>
      <div *ngIf="treatmentDate$ | async as treatmentDate">
        <strong>Treatment Date:</strong>
        {{ treatmentDate | moment | amDateFormat: dateFormat }}
      </div>
      <strong>Paid Date:</strong>&nbsp;
      <ng-container *ngIf="isPaid$ | ngrxPush; else tbd">
        <ng-container *ngIf="invoice.paidAt; else unknown">
          {{ invoice.paidAt | moment | amDateFormat: dateFormat }}
        </ng-container>
        <ng-template #unknown>Unknown</ng-template>
      </ng-container>
    </div>
    <div *ngIf="cancelledDate$ | async as cancelledDate">
      <strong>Cancelled Date:</strong>
      {{ cancelledDate | moment | amDateFormat: dateFormat }}
    </div>
    <div *ngIf="replacedInvoice$ | async as replacedInvoice">
      <strong>Amended Invoice:</strong>
      <a [routerLink]="replacedInvoiceLink$ | async">
        {{ replacedInvoice.reference }}
      </a>
    </div>
  </div>
</ng-container>
