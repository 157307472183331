import { Injector } from '@angular/core';
import { wrapAngularExtension } from '@principle-theorem/ng-prosemirror';
import { AnyExtension } from '@tiptap/core';
import { ImageNodeComponent } from './image/image-node/image-node.component';
import { ImageUploadingComponent } from './image/image-uploading/image-uploading.component';
import { createMentionExtension } from './mention/mention';
import { createMentionAutocompleteExtension } from './mention/mention-autocomplete';
import { type IMentionAutocompleteNodeConfig } from './mention/mention-autocomplete-node/mention-autocomplete-node.component';
import { type IMentionNodeConfig } from './mention/mention-node/mention-node.component';
import { createSnippetAutocompleteExtension } from './snippet/snippet-autocomplete';
import { type ISnippetAutocompleteNodeConfig } from './snippet/snippet-autocomplete-node/snippet-autocomplete-node.component';
import { createVideoEmbedPasteRules } from './video/paste-rules';
import { VideoEmbedNodeComponent } from './video/video-embed-node/video-embed-node.component';
import { VideoNodeComponent } from './video/video-node/video-node.component';
import { VideoUploadingComponent } from './video/video-uploading/video-uploading.component';
import { TableOfContentsNodeComponent } from './table-of-contents/table-of-contents-node.component';

export interface IAngularEditorExtensions {
  tableOfContents: (injector: Injector) => AnyExtension;
  image: (injector: Injector) => AnyExtension;
  imageUploading: (injector: Injector) => AnyExtension;
  video: (injector: Injector) => AnyExtension;
  videoUploading: (injector: Injector) => AnyExtension;
  videoEmbed: (injector: Injector) => AnyExtension;
  mention: (injector: Injector, config: IMentionNodeConfig) => AnyExtension;
  mentionAutocomplete: (
    injector: Injector,
    config: IMentionAutocompleteNodeConfig
  ) => AnyExtension;
  snippetAutocomplete: (
    injector: Injector,
    config: ISnippetAutocompleteNodeConfig
  ) => AnyExtension;
}

export const angularEditorExtensions: IAngularEditorExtensions = {
  tableOfContents: wrapAngularExtension(TableOfContentsNodeComponent),
  image: wrapAngularExtension(ImageNodeComponent),
  imageUploading: wrapAngularExtension(ImageUploadingComponent),
  video: wrapAngularExtension(VideoNodeComponent),
  videoUploading: wrapAngularExtension(VideoUploadingComponent),
  videoEmbed: wrapAngularExtension(
    VideoEmbedNodeComponent,
    createVideoEmbedPasteRules()
  ),
  mention: createMentionExtension,
  mentionAutocomplete: createMentionAutocompleteExtension,
  snippetAutocomplete: createSnippetAutocompleteExtension,
};
