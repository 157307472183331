import {
  getRefundRemaining,
  Invoice,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  isDepositLineItem,
  IStaffer,
  isTreatmentLineItem,
  ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import { isSameRef, WithRef } from '@principle-theorem/shared';
import { sum, uniqWith } from 'lodash';
import {
  IPractitionerIncomeSummary,
  PractitionerIncomeSummary,
} from './practitioner-income-summary';

export function createRecordSummary(
  invoice: WithRef<IInvoice>,
  transactions: WithRef<ITransaction>[],
  practitioner: DocumentReference<IStaffer>
): IPractitionerIncomeSummary {
  const discountsAppliedAmount = sum(
    new TransactionOperators(transactions)
      .byProvider(TransactionProvider.Discount)
      .incoming()
      .completed()
      .result()
      .map((discount) => getRefundRemaining(discount, transactions))
  );

  const creditsAppliedAmount = sum(
    new TransactionOperators(transactions)
      .byProvider(TransactionProvider.AccountCredit)
      .incoming()
      .completed()
      .result()
      .map((credit) => getRefundRemaining(credit, transactions))
  );

  const deposits = invoice.items.filter(isDepositLineItem);
  const depositsTakenAmount = sum(
    deposits
      .filter(
        (deposit) =>
          deposit.attributedTo && isSameRef(deposit.attributedTo, practitioner)
      )
      .map((deposit) => deposit.amount)
  );

  const allTreatments = invoice.items.filter(isTreatmentLineItem);
  const practitionerTreatments = allTreatments.filter((treatment) =>
    isSameRef(treatment.treatmentRef.attributedTo, practitioner)
  );
  const treatmentsInvoicedAmount = sum(
    practitionerTreatments.map((treatment) => treatment.amount)
  );
  const practitionerCount = uniqWith(
    allTreatments.map((treatment) => treatment.treatmentRef.attributedTo),
    isSameRef
  ).length;

  const totalInvoicedAmount = Invoice.total(invoice);

  const receivableInvoiceAmount =
    treatmentsInvoicedAmount - discountsAppliedAmount;
  const invoicedCommissionAmount = receivableInvoiceAmount;
  const receiptedCommissionAmount =
    treatmentsInvoicedAmount + depositsTakenAmount - discountsAppliedAmount;

  /**
   * If treatments billed is less than total billed there was something else on the invoice.
   * - Discounts do not count as income recieved.
   * - Credits count as income recieved
   */
  const hasMultiplePractitioners = practitionerCount > 1;
  const hasNonTreatment = receiptedCommissionAmount !== receivableInvoiceAmount;

  const discountsAppliedWarning =
    discountsAppliedAmount > 0 && (hasMultiplePractitioners || hasNonTreatment);
  const creditsAppliedWarning =
    creditsAppliedAmount > 0 && (hasMultiplePractitioners || hasNonTreatment);
  const invoicedCommissionWarning = discountsAppliedWarning;
  const receiptedCommissionWarning =
    discountsAppliedWarning || creditsAppliedWarning;

  return PractitionerIncomeSummary.init({
    totalInvoicedAmount,
    treatmentsInvoicedAmount,
    depositsTakenAmount,
    discountsAppliedAmount,
    discountsAppliedWarning,
    creditsAppliedAmount,
    creditsAppliedWarning,
    invoicedCommissionAmount,
    invoicedCommissionWarning,
    receiptedCommissionAmount,
    receiptedCommissionWarning,
  });
}
