import { isSameToothRef } from '@principle-theorem/principle-core';
import {
  type IPerioRecord,
  type IPerioTableCell,
} from '@principle-theorem/principle-core/interfaces';
import { cloneDeep, set } from 'lodash';

export function upsertPerioValues(
  records: IPerioRecord[],
  events: IPerioTableCell[]
): IPerioRecord[] {
  return events.reduce((updatedRecords, event) => {
    const existingRecord = updatedRecords.find((record) =>
      isSameToothRef(record.toothRef, event.metadata.toothRef)
    );

    const oldData = { ...existingRecord?.data };

    const upsertValue: IPerioRecord = {
      toothRef: event.metadata.toothRef,
      data: set(cloneDeep(oldData), event.metadata.dataPath, event.value),
    };

    const filteredRecords = updatedRecords.filter(
      (record) => !isSameToothRef(record.toothRef, upsertValue.toothRef)
    );

    return [...filteredRecords, upsertValue];
  }, records);
}
