import {
  IPatient,
  IPatientPrescriptionDetails,
  IPractice,
  IPracticePrescriptionDetails,
  IPrescriberPrescriptionDetails,
  IPrescription,
  IPrescriptionItem,
  IPrintPrescriptionSettings,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  MockGenerator,
  Timestamp,
  Timezone,
  WithRef,
  toMoment,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockWithRef } from '@principle-theorem/testing';
import { jsPDF } from 'jspdf';
import { NameHelpers } from '../patient/name-helpers';
import { PatientMock } from '../patient/patient.mock';
import { PractitionerMock } from '../staffer/practitioner.mock';
import { PrescriptionMock } from './prescription.mock';

type PatientDetails = Required<Omit<IPatientPrescriptionDetails, 'ref'>>;
type PrescriberDetails = Required<Omit<IPrescriberPrescriptionDetails, 'ref'>>;
type PracticeDetails = Required<Omit<IPracticePrescriptionDetails, 'ref'>>;

interface IPrintOptions {
  fontStyle: 'normal' | 'bold';
  maxWidth?: number;
}

interface ICoordinate {
  x: number[];
  y: number;
}

export class PrintPrescription {
  private _coordinates: { [key: string]: ICoordinate } = {
    practice: { x: [6, 110], y: 6 },
    prescriberName: { x: [27, 130], y: 138 },
    qualifications: { x: [27, 130], y: 141.5 },
    prescriberNumber: { x: [22.5, 127], y: 24.5 },
    dentistCheckbox: { x: [33.5], y: 150 },
    patient: { x: [23, 129], y: 57 },
    medicare: { x: [31.5, 137], y: 33 },
    dvaNumber: { x: [24, 129], y: 40 },
    safetyNet: { x: [24.5, 129], y: 49 },
    concessional: { x: [65.5, 170.5], y: 49 },
    pbs: { x: [10, 115], y: 78 },
    rpbs: { x: [30, 135], y: 78 },
    brandSub: { x: [45.5, 150.5], y: 78 },
    prescribedDate: { x: [11.5, 116], y: 73 },
    medication: { x: [27, 131.5], y: 85 },
  };

  readonly linespacing = 3.4;

  async print(
    prescription: WithRef<IPrescription>
  ): Promise<WithRef<IPrescription> | string> {
    const practice = await Firestore.getDoc(prescription.practice.ref);
    const staffer = await Firestore.getDoc(prescription.prescriber.ref);
    const patient = await Firestore.getDoc(prescription.patient.ref);
    const timezone = practice.settings.timezone;
    const offset = practice.settings.print?.prescriptionMarginOffset;

    if (!patient.address || !staffer.prescriberNumber) {
      return this._checkPrescriptionRequirements(
        patient.address,
        staffer.prescriberNumber
      );
    }

    if (offset) {
      this._updateCoords(offset);
    }

    const prescribedAt = prescription.prescribedAt ?? toTimestamp();
    const prescriberData = this._getPrescriberData(staffer);
    const patientData = this._getPatientData(patient);
    const practiceData = this._getPracticeData(practice);
    const doc = this._createDocument();
    this._printPrescriberDetails(doc, prescriberData);
    this._printPatientDetails(doc, patientData);
    this._printPracticeDetails(doc, practiceData);
    this._printPrescriptionDetails(doc, prescription, timezone, prescribedAt);
    this._printMedicationDetails(doc, prescription.items);

    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');

    return {
      ...prescription,
      prescribedAt,
      patient: { ...prescription.patient, ...patientData },
      prescriber: { ...prescription.prescriber, ...prescriberData },
      practice: { ...prescription.practice, ...practiceData },
    };
  }

  printTestPrescription(practice: WithRef<IPractice>): void {
    const timezone = practice.settings.timezone;
    const offset = practice.settings.print?.prescriptionMarginOffset;

    if (offset) {
      this._updateCoords(offset);
    }

    const patient = MockWithRef(MockGenerator.generate(PatientMock));
    const staffer = MockWithRef(MockGenerator.generate(PractitionerMock));
    const prescription = MockWithRef(MockGenerator.generate(PrescriptionMock));
    const doc = this._createDocument();
    this._addWatermark(doc, 'TEST PRESCRIPTION', 45);
    this._printPrescriberDetails(doc, this._getPrescriberData(staffer));
    this._printPatientDetails(doc, this._getPatientData(patient));
    this._printPracticeDetails(doc, this._getPracticeData(practice));
    this._printPrescriptionDetails(doc, prescription, timezone, toTimestamp());
    this._printMedicationDetails(doc, prescription.items);
    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
  }

  private _printPrescriberDetails(doc: jsPDF, data: PrescriberDetails): void {
    const {
      prescriberName: name,
      prescriberNumber: number,
      qualifications,
      dentistCheckbox,
    } = this._coordinates;
    this._printTextInColumns(doc, data.number, number.x, number.y);
    this._printTextInColumns(doc, data.name, name.x, name.y, {
      fontStyle: 'bold',
    });
    this._printTextInColumns(
      doc,
      data.qualifications,
      qualifications.x,
      qualifications.y
    );
    this._printTextInColumns(doc, 'X', dentistCheckbox.x, dentistCheckbox.y);
  }

  private _getPrescriberData(staffer: WithRef<IStaffer>): PrescriberDetails {
    return {
      name: staffer.user.name,
      number: staffer.prescriberNumber as string,
      qualifications: staffer.qualifications ?? '',
    };
  }

  private _printPracticeDetails(doc: jsPDF, data: PracticeDetails): void {
    const maxWidth = 100;
    const xPos = this._coordinates.practice.x;
    let currentYPos = this._coordinates.practice.y;

    this._printTextInColumns(doc, data.name, xPos, currentYPos, {
      maxWidth,
      fontStyle: 'bold',
    });

    currentYPos += this.linespacing;

    this._splitTextToLines(doc, data.address, maxWidth).forEach((line) => {
      this._printTextInColumns(doc, line, xPos, currentYPos);
      currentYPos += this.linespacing;
    });

    this._printTextInColumns(doc, `Ph: ${data.phone}`, xPos, currentYPos);
  }

  private _getPracticeData(practice: WithRef<IPractice>): PracticeDetails {
    return {
      name: practice.name,
      address: practice.address,
      phone: practice.phone,
    };
  }

  private _printPatientDetails(doc: jsPDF, data: PatientDetails): void {
    const maxWidth = 76;
    const xPos = this._coordinates.patient.x;
    let currentYPos = this._coordinates.patient.y;

    this._printTextInColumns(
      doc,
      data.medicareNumber,
      this._coordinates.medicare.x,
      this._coordinates.medicare.y,
      { fontStyle: 'bold' }
    );

    this._printTextInColumns(
      doc,
      data.dvaNumber ? `DVA Card Number: ${data.dvaNumber}` : '',
      this._coordinates.dvaNumber.x,
      this._coordinates.dvaNumber.y
    );

    this._printTextInColumns(doc, data.name, xPos, currentYPos, {
      maxWidth,
      fontStyle: 'bold',
    });
    currentYPos += this.linespacing;

    this._splitTextToLines(doc, data.address, maxWidth).forEach((line) => {
      this._printTextInColumns(doc, line, xPos, currentYPos);
      currentYPos += this.linespacing;
    });

    this._printTextInColumns(
      doc,
      data.dateOfBirth ? `D.O.B: ${data.dateOfBirth}` : '',
      xPos,
      currentYPos
    );
  }

  private _getPatientData(patient: WithRef<IPatient>): PatientDetails {
    const medicareCard = patient.medicareCard;
    const dvaCard = patient.dvaCard;
    const DOB = patient.dateOfBirth;

    return {
      name: NameHelpers.fullName(patient.name),
      address: patient.address as string,
      dateOfBirth: DOB ? toMoment(DOB).format(DAY_MONTH_YEAR_FORMAT) : '',
      medicareNumber: medicareCard ? medicareCard.number : '',
      dvaNumber: dvaCard ? dvaCard.number : '',
    };
  }

  private _printMedicationDetails(
    doc: jsPDF,
    items: IPrescriptionItem[]
  ): void {
    const maxWidth = 73;
    const xPos = this._coordinates.medication.x;
    let currentYPos = this._coordinates.medication.y;

    items.forEach((item) => {
      const { display, instructions, info } = this._getItemPrintData(item);

      this._splitTextToLines(doc, item.medicationName, maxWidth, true).forEach(
        (line) => {
          this._printTextInColumns(doc, line, xPos, currentYPos, {
            maxWidth,
            fontStyle: 'bold',
          });
          currentYPos += this.linespacing;
        }
      );

      this._printTextInColumns(doc, display, xPos, currentYPos);

      if (item.instructions || item.safetyInformation) {
        currentYPos += this.linespacing;
      }

      if (item.instructions) {
        this._splitTextToLines(doc, instructions, maxWidth).forEach((line) => {
          this._printTextInColumns(doc, line, xPos, currentYPos);
          currentYPos += this.linespacing;
        });
      }

      if (item.safetyInformation) {
        this._splitTextToLines(doc, info, maxWidth).forEach((line) => {
          this._printTextInColumns(doc, line, xPos, currentYPos);
          currentYPos += this.linespacing;
        });
      }

      currentYPos += this.linespacing;
    });
  }

  private _getItemPrintData(item: IPrescriptionItem): {
    display: string;
    instructions: string;
    info: string;
  } {
    const quantity = item.quantity ? `Quantity: ${item.quantity}` : '0';
    const repeatXTimes = item.repeat ? item.repeat : 0;
    const repeat = repeatXTimes > 1 ? `Repeats` : `Repeat`;
    const display = `${quantity} ${repeat}: ${repeatXTimes}`;
    const instructions = item.instructions ?? '';
    const info = item.safetyInformation
      ? `Comment: ${item.safetyInformation}`
      : '';
    return { display, instructions, info };
  }

  private _printPrescriptionDetails(
    doc: jsPDF,
    prescription: WithRef<IPrescription>,
    timezone: Timezone,
    prescribedAt: Timestamp
  ): void {
    const safetyNet = prescription.pbsSafetyNet ? 'X' : '';
    const concessional = prescription.concessionalOrDependentRPBS ? 'X' : '';
    const pbs = prescription.pbs ? 'X' : '';
    const rpbs = prescription.rpbs ? 'X' : '';
    const brandSub = prescription.brandSubstitutionNotPermitted ? 'X' : '';
    const prescribedDate = toMomentTz(prescribedAt, timezone).format(
      DAY_MONTH_YEAR_FORMAT
    );

    doc.setFontSize(10);
    this._printTextInColumns(
      doc,
      safetyNet,
      this._coordinates.safetyNet.x,
      this._coordinates.safetyNet.y,
      { fontStyle: 'bold' }
    );
    this._printTextInColumns(
      doc,
      concessional,
      this._coordinates.concessional.x,
      this._coordinates.concessional.y,
      { fontStyle: 'bold' }
    );
    this._printTextInColumns(
      doc,
      pbs,
      this._coordinates.pbs.x,
      this._coordinates.pbs.y,
      {
        fontStyle: 'bold',
      }
    );
    this._printTextInColumns(
      doc,
      rpbs,
      this._coordinates.rpbs.x,
      this._coordinates.rpbs.y,
      {
        fontStyle: 'bold',
      }
    );
    this._printTextInColumns(
      doc,
      brandSub,
      this._coordinates.brandSub.x,
      this._coordinates.brandSub.y,
      { fontStyle: 'bold' }
    );
    doc.setFontSize(8);
    this._printTextInColumns(
      doc,
      prescribedDate,
      this._coordinates.prescribedDate.x,
      this._coordinates.prescribedDate.y
    );
  }

  private _updateCoords(offset: IPrintPrescriptionSettings): void {
    Object.keys(this._coordinates).forEach((key) => {
      this._coordinates[key].x = this._coordinates[key].x.map(
        (xValue) => xValue + offset.horizontalOffset
      );
      this._coordinates[key].y += offset.verticalOffset;
    });
  }

  private _createDocument(): jsPDF {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
    doc.setFontSize(8);
    return doc;
  }

  private _splitTextToLines(
    doc: jsPDF,
    text: string,
    maxWidth: number,
    isBold: boolean = false
  ): string[] {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    if (isBold) {
      doc.setFont('helvetica', 'bold');
    }

    words.forEach((word) => {
      const line = currentLine + word + ' ';
      const width = doc.getTextWidth(line);
      if (width > maxWidth) {
        lines.push(currentLine.trim());
        currentLine = word + ' ';
      } else {
        currentLine = line;
      }
    });

    lines.push(currentLine.trim());
    doc.setFont('helvetica', 'normal');

    return lines;
  }

  private _printTextInColumns(
    doc: jsPDF,
    text: string,
    xPositions: number[],
    yPos: number,
    options: IPrintOptions = { fontStyle: 'normal' }
  ): void {
    const { fontStyle = 'normal', ...restOptions } = options;
    doc.setFont('helvetica', fontStyle);
    xPositions.forEach((xPos) => doc.text(text, xPos, yPos, restOptions));
  }

  private _checkPrescriptionRequirements(
    address?: string,
    prescriberNumber?: string
  ): string {
    const missingFields = [];
    if (!address) {
      missingFields.push('patient address');
    }
    if (!prescriberNumber) {
      missingFields.push('prescriber number');
    }
    if (missingFields.length > 0) {
      const missingString = missingFields.join(' and ');
      return `Cannot print prescription: missing ${missingString}. Please update and try again.`;
    }
    return '';
  }

  private _addWatermark(doc: jsPDF, text: string, angle: number): void {
    const scale = 0.5;
    const watermarkImage = this._createWatermarkImage(text, angle, scale);

    if (watermarkImage) {
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const imageSize = 200;

      doc.addImage(
        watermarkImage,
        'PNG',
        (pageWidth - imageSize) / 2,
        (pageHeight - imageSize * 1.5) / 2,
        imageSize,
        imageSize
      );
    }
  }

  private _createWatermarkImage(
    text: string,
    angle: number,
    scale: number
  ): string {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      return '';
    }

    canvas.width = 2000 * scale;
    canvas.height = 2000 * scale;
    context.font = `${200 * scale}px Helvetica`;
    context.fillStyle = 'rgba(244, 67, 54, 0.2)';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((angle * Math.PI) / -180);
    context.fillText(text, 0, 0);

    return canvas.toDataURL('image/png');
  }
}
