import {
  Appointment,
  Candidate,
  Event,
  type IWaitListCandidate,
  Practice,
  WaitListCandidate,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ICandidate,
  type IGap,
  type IPractice,
  isEventable,
  type SortReturnValue,
  WaitListItemState,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiFilter,
  multiSort,
  multiSwitchMap,
  toMoment,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export class WaitListCandidateList {
  constructor(private _practice: WithRef<IPractice>) {}

  getCandidates$(gap: IGap): Observable<IWaitListCandidate[]> {
    return Practice.waitlistedAppointments$(
      this._practice,
      toMoment(gap.event.from)
    ).pipe(
      multiFilter((appointment) => this._appointmentFitsGap(gap, appointment)),
      multiSwitchMap((appointment) =>
        this._appointmentToCandidate(gap, appointment)
      ),
      multiSort((candidateA, candidateB) =>
        this._sortCandidates(
          gap,
          candidateA.appointment,
          candidateB.appointment
        )
      )
    );
  }

  private _appointmentFitsGap(
    gap: IGap,
    appointment: WithRef<IAppointment>
  ): boolean {
    if (Appointment.duration(appointment) === 0) {
      return false;
    }
    if (!isEventable(appointment)) {
      return true;
    }
    return toMoment(gap.event.from).isBefore(toMoment(appointment.event.from));
  }

  private async _appointmentToCandidate(
    gap: IGap,
    appointment: WithRef<IAppointment>
  ): Promise<IWaitListCandidate> {
    return WaitListCandidate.init({
      candidate: await this._createCandidate(gap, appointment),
      appointment,
    });
  }

  private async _createCandidate(
    gap: IGap,
    appointment: WithRef<IAppointment>
  ): Promise<ICandidate> {
    let duration: number = Event.duration(gap.event);
    const appointmentDuration: number = Appointment.duration(appointment);
    if (appointmentDuration < duration) {
      duration = appointmentDuration;
    }
    const offerTimeFrom = gap.event.from;
    const offerTimeTo = toTimestamp(
      toMoment(gap.event.from).add(duration, 'minutes')
    );
    const patient = await Appointment.patient(appointment);
    return Candidate.init({
      state: WaitListItemState.Available,
      appointment: appointment.ref,
      patient: toNamedDocument(patient),
      offerTimeFrom,
      offerTimeTo,
    });
  }

  private _sortCandidates(
    gap: IGap,
    appointmentA: WithRef<IAppointment>,
    appointmentB: WithRef<IAppointment>
  ): SortReturnValue {
    const scoreA: number = WaitListCandidate.getMatchScore(
      appointmentA,
      gap.event
    );
    const scoreB: number = WaitListCandidate.getMatchScore(
      appointmentB,
      gap.event
    );
    return scoreA < scoreB ? 1 : -1;
  }
}
