import { IPracticeDimension } from '@principle-theorem/reporting/interfaces';
import { BigQueryFirestoreModelMock, MockBigQueryDocRef } from './common.mock';
import { PracticeMock } from '@principle-theorem/principle-core';
import { MockGenerator } from '@principle-theorem/shared';
import { toSerialisedTimestamp, toTimestamp } from '@principle-theorem/shared';

const practice = MockGenerator.generate(PracticeMock);

export class PracticeDimensionMock
  extends BigQueryFirestoreModelMock
  implements IPracticeDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = practice.name;
  slug = practice.slug;
  address = practice.address;
  phone = practice.phone;
  email = practice.email;
  coordinates = practice.coordinates;
  settings = practice.settings;
  tyroSettings = practice.tyroSettings;
  schedule = practice.schedule;
  yearOpened = practice.yearOpened;
}

export const MOCKED_PRACTICES = [`Golden Dental`, `Silver Smiles`].map((name) =>
  MockGenerator.generate(PracticeDimensionMock, {
    ref: MockBigQueryDocRef(name, 'practices'),
    name,
  })
);
