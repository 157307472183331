import { type IDestination } from '@principle-theorem/principle-core/interfaces';
import { BaseDestinationHandler } from '../destination/base-destination-handler';
import { ContactDestinationEntity } from '../exact/destination/entities/contact';
import { PatientDestinationEntity } from '../exact/destination/entities/patient';
import { PatientAppointmentDestinationEntity } from '../exact/destination/entities/patient-appointments';
import { PatientClinicalChartDestinationEntity } from '../exact/destination/entities/patient-clinical-charts';
import { PatientClinicalNoteDestinationEntity } from '../exact/destination/entities/patient-clinical-notes';
import { PatientInteractionsDestinationEntity } from '../exact/destination/entities/patient-interactions';
import { PatientTreatmentPlanDestinationEntity } from '../exact/destination/entities/patient-treatment-plans';
import { StafferDestinationEntity } from '../exact/destination/entities/staff';
import { PatientDocumentDestinationEntity } from './destination/entities/patient-documents';
import { PatientInvoicesDestinationEntity } from './destination/entities/patient-invoices';

export const DENTRIX_DESTINATION: IDestination = {
  metadata: {
    name: 'Principle Firestore',
    description: 'Main Principle Migration',
  },
  configuration: [],
};

export class DentrixDestinationHandler extends BaseDestinationHandler {
  migrationType = 'Dentrix';
  migrationVersion = 'v1';
  destination = DENTRIX_DESTINATION;

  entityHandlers = [
    new PatientDestinationEntity(),
    new StafferDestinationEntity(),
    new ContactDestinationEntity(),
    new PatientInteractionsDestinationEntity(),
    new PatientAppointmentDestinationEntity(),
    new PatientTreatmentPlanDestinationEntity(),
    new PatientClinicalNoteDestinationEntity(),
    new PatientClinicalChartDestinationEntity(),
    new PatientInvoicesDestinationEntity(),
    new PatientDocumentDestinationEntity(),
  ];
}
