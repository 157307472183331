<div class="flex h-full w-full flex-row overflow-hidden">
  <div class="hidden w-60 lg:block" [ngClass]="sidebarClass">
    <div class="flex flex-col gap-2 p-2">
      <pt-table-of-contents container=".toc-page" />
    </div>
  </div>

  <div class="flex flex-auto">
    <div #mainPage class="toc-page h-full w-full overflow-scroll">
      <div class="mx-auto w-full max-w-7xl">
        <ng-content />
      </div>
    </div>
  </div>
</div>
