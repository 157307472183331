import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IChartedTreatment,
  type IPatient,
  type IProduct,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  isINamedDocument,
  isObject,
  type WithRef,
} from '@principle-theorem/shared';
import { get } from 'lodash';
import { type Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const NEW_DEPOSIT = 'Deposit';
export const CANCELLATION_FEE = 'Cancellation Fee';

export class LineItemsSearch {
  products$: Observable<WithRef<IProduct>[]>;
  treatmentWithPlans$: Observable<ITreatmentWithPlan[]> = of([]);

  constructor(
    public patient$: Observable<WithRef<IPatient>>,
    brand$: Observable<WithRef<IBrand>>
  ) {
    this.products$ = brand$.pipe(switchMap((brand) => Brand.products$(brand)));
  }
}

export interface ITreatmentWithPlan {
  treatmentPlan: WithRef<ITreatmentPlan>;
  treatment: IChartedTreatment;
}

export function isTreatmentWithPlan(item: unknown): item is ITreatmentWithPlan {
  if (!isObject(item)) {
    return false;
  }
  const hasPlan: boolean = isINamedDocument(get(item, 'treatmentPlan'));
  const hasTreatment: boolean = get(item, 'treatment') !== undefined;
  return hasPlan && hasTreatment;
}
