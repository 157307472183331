import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HicapsApi,
  HicapsConnectMethod,
  IPMSHicapsConnectConfig,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import {
  BridgeCommandsService,
  BridgeDeviceSelectorService,
} from '@principle-theorem/ng-principle-bridge-cloud';
import {
  BasicDialogService,
  DialogPresets,
  openBlankTab,
} from '@principle-theorem/ng-shared';
import { HICAPS_CONNECT_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import {
  HicapsConnectApiAdaptor,
  HicapsConnectProcessHandler,
  HicapsConnectProcessStatus,
  HicapsConnectResponseCodes,
  IHicapsConnectApiHooks,
  IHicapsConnectProcess,
  Practice,
} from '@principle-theorem/principle-core';
import {
  BridgeDeviceStatus,
  IBridgeDevice,
  IHicapsConnectTerminal,
  IPractice,
  IPracticeHicapsConnectSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  IReffable,
  WithRef,
  addDoc,
  asyncForEach,
  deleteDoc,
  firstResult,
  getDocs,
  patchDoc,
  snapshot,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import {
  INgPaymentsConfig,
  NG_PAYMENTS_CONFIG,
} from '../../../ng-payments-config';
import {
  HicapsConnectSelectTerminalDialogComponent,
  IHicapsSelectTerminalRequest,
  IHicapsSelectTerminalResponse,
} from './hicaps-connect-select-terminal-dialog/hicaps-connect-select-terminal-dialog.component';

interface ITerminalBridgeDevicePair {
  terminal: WithRef<IHicapsConnectTerminal>;
  bridgeDevice: WithRef<IBridgeDevice>;
}

@Injectable()
export class HicapsConnectService {
  constructor(
    @Inject(NG_PAYMENTS_CONFIG) private _config: INgPaymentsConfig,
    private _basicDialog: BasicDialogService,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService,
    private _featureFlags: FeatureFlagsService,
    private _bridgeCommands: BridgeCommandsService
  ) {}

  getDeviceAPI(bridgeDeviceRef: DocumentReference<IBridgeDevice>): HicapsApi {
    const hooks: IHicapsConnectApiHooks = {
      afterProcessCreate: (hicapsProcess) =>
        this._afterProcessCreate(hicapsProcess),
    };
    const adaptor = new HicapsConnectApiAdaptor(
      bridgeDeviceRef,
      this._featureFlags.features,
      hooks
    );
    return new HicapsApi(adaptor);
  }

  getConfig(): IPMSHicapsConnectConfig {
    return this._config.hicapsConnect;
  }

  async getPracticeSettings(
    practiceRef: DocumentReference<IPractice>
  ): Promise<IPracticeHicapsConnectSettings | undefined> {
    const practice = await Firestore.getDoc(practiceRef);
    return practice.hicapsConnectSettings;
  }

  async syncTerminalsForDevice(practice: IReffable<IPractice>): Promise<void> {
    const deviceRef = await this._bridgeDeviceSelector.selectDevice(
      HICAPS_CONNECT_INTEGRATION
    );
    if (!deviceRef) {
      return;
    }
    const api = this.getDeviceAPI(deviceRef);
    const response = await snapshot(api.getTerminalList());
    const terminalIds = Array.isArray(response.data) ? response.data : [];

    await asyncForEach(terminalIds, (terminalId) =>
      this._updateTerminalForDevice(practice, deviceRef, terminalId)
    );
    this._snackbar.open('Terminals synced');
  }

  async testTerminal(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const api = this.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(api.sendTerminalTest(terminal.terminalId));

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      await this._basicDialog.alert({
        title: 'Terminal Test Failed',
        prompt: `${response.message}`,
      });
      return;
    }
    if (
      !HicapsConnectResponseCodes.isClaimSuccessful(response.data.ResponseCode)
    ) {
      await this._basicDialog.alert({
        title: 'Terminal Test Failed',
        prompt: `${response.data.ResponseText}`,
        submitLabel: 'Close',
        submitColor: 'accent',
      });
      return;
    }
    await this._basicDialog.alert({
      title: 'Terminal Test Successful',
      prompt: `Terminal ${terminal.name} is working correctly`,
      submitLabel: 'Close',
      submitColor: 'accent',
    });
  }

  async editTerminal(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const name = await this._basicDialog.prompt({
      title: 'Edit Terminal',
      prompt: 'Enter a name for the terminal',
      defaultValue: terminal.name,
      submitLabel: 'Save',
    });
    if (!name) {
      return;
    }
    await patchDoc(terminal.ref, { name });
  }

  async deleteTerminal(
    terminal: WithRef<IHicapsConnectTerminal>
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm({
      title: 'Delete Terminal',
      prompt: `Are you sure you want to delete ${terminal.name}`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    if (!confirmed) {
      return;
    }
    await deleteDoc(terminal.ref);
  }

  async selectTerminal(
    practice: IReffable<IPractice>
  ): Promise<WithRef<IHicapsConnectTerminal> | undefined> {
    const terminalDevicePairs =
      await this._getTerminalBridgeDevicePairs(practice);
    const defaultTerminal = this._getDefaultTerminal(terminalDevicePairs);
    if (defaultTerminal) {
      return defaultTerminal;
    }
    const terminals = terminalDevicePairs.map((pair) => pair.terminal);

    const response = await this._dialog
      .open<
        HicapsConnectSelectTerminalDialogComponent,
        IHicapsSelectTerminalRequest,
        IHicapsSelectTerminalResponse | undefined
      >(
        HicapsConnectSelectTerminalDialogComponent,
        DialogPresets.medium({ data: { terminals } })
      )
      .afterClosed()
      .toPromise();

    return response?.terminal;
  }

  async viewLogs(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const api = this.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(api.principleHicapsLogsRead());
    if (!response.data) {
      this._snackbar.open(`Unable to load logs for ${terminal.name}`);
      return;
    }
    openBlankTab(`Principle Logs: ${terminal.name}`, response.data);
  }

  private async _getTerminalBridgeDevicePairs(
    practice: IReffable<IPractice>
  ): Promise<ITerminalBridgeDevicePair[]> {
    const terminals = await getDocs(
      undeletedQuery(Practice.hicapsConnectTerminalCol(practice))
    );
    const pairs = await asyncForEach(terminals, async (terminal) => {
      const bridgeDevice = await Firestore.getDoc(terminal.bridgeDevice);
      return { terminal, bridgeDevice };
    });
    return pairs.filter((pair) => !pair.bridgeDevice.deleted);
  }

  private _getDefaultTerminal(
    pairs: ITerminalBridgeDevicePair[]
  ): WithRef<IHicapsConnectTerminal> | undefined {
    const firstPair = first(pairs);
    if (!firstPair || pairs.length > 1) {
      return;
    }
    if (firstPair.bridgeDevice.status !== BridgeDeviceStatus.Active) {
      return;
    }
    return firstPair.terminal;
  }

  private async _updateTerminalForDevice(
    practice: IReffable<IPractice>,
    bridgeDevice: DocumentReference<IBridgeDevice>,
    terminalId: string
  ): Promise<DocumentReference<IHicapsConnectTerminal>> {
    const terminalCol = Practice.hicapsConnectTerminalCol(practice);
    const existing = await firstResult(
      undeletedQuery(terminalCol),
      where('terminalId', '==', terminalId)
    );

    if (existing) {
      await patchDoc(existing.ref, {
        terminalId,
        bridgeDevice,
        lastActive: toTimestamp(),
      });
      return existing.ref;
    }

    return addDoc(terminalCol, {
      terminalId,
      bridgeDevice,
      lastActive: toTimestamp(),
      name: terminalId,
      deleted: false,
    });
  }

  private async _afterProcessCreate<T extends HicapsConnectMethod>(
    hicapsProcess: WithRef<IHicapsConnectProcess<T>>
  ): Promise<void> {
    if (hicapsProcess.status !== HicapsConnectProcessStatus.Pending) {
      return;
    }
    const deviceCommand =
      HicapsConnectProcessHandler.toDeviceCommand(hicapsProcess);
    await this._bridgeCommands.sendCommand(
      deviceCommand.command,
      deviceCommand.deviceRef
    );
    await patchDoc(hicapsProcess.ref, {
      status: HicapsConnectProcessStatus.InProgress,
    });
  }
}
