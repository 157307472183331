import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentPatientScope,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { Patient, Prescription } from '@principle-theorem/principle-core';
import {
  IPatient,
  IPractice,
  IPrescription,
  IStaffer,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  addDoc,
  filterUndefined,
  getDoc$,
  isSameRef,
} from '@principle-theorem/shared';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface IPrescriptionState {
  prescriptions: WithRef<IPrescription>[];
  selectedPrescription: WithRef<IPrescription> | undefined;
  loading: boolean;
}

type LoadPrescriptonData = { uid: string; patient: WithRef<IPatient> };
export type DeletePrescriptonData = {
  prescription: WithRef<IPrescription>;
  actionedByRef: DocumentReference<IStaffer>;
};

@Injectable()
export class PrescriptionService {
  private _snackBar = inject(MatSnackBar);
  private _currentPatient = inject(CurrentPatientScope);
  private _organisation = inject(OrganisationService);
  private _patient = toSignal(this._getPatient(this._currentPatient));
  private _practice = toSignal(this._getPractice(this._organisation));

  // state
  private _state = signal<IPrescriptionState>({
    prescriptions: [],
    selectedPrescription: undefined,
    loading: false,
  });

  // selectors
  loading = computed(() => this._state().loading);
  prescriptions = computed(() => this._state().prescriptions);
  selectedPrescription = computed(() => this._state().selectedPrescription);

  // actions
  loading$ = new Subject<boolean>();
  loadAll$ = new Subject<PrescriptionStatus | undefined>();
  load$ = new Subject<LoadPrescriptonData>();
  add$ = new Subject<IPrescription>();
  edit$ = new Subject<WithRef<IPrescription>>();
  delete$ = new Subject<DeletePrescriptonData>();
  selected$ = new Subject<WithRef<IPrescription>>();

  constructor(private _stateNav: StateBasedNavigationService) {
    // reducers
    this.add$
      .pipe(
        takeUntilDestroyed(),
        switchMap((prescription) => this._addPrescription(prescription))
      )
      .subscribe((prescription) => {
        this._addPrescriptionToState(prescription);
      });

    this.edit$
      .pipe(
        takeUntilDestroyed(),
        switchMap((prescription) => this._editPrescription(prescription))
      )
      .subscribe((prescription) => this._editPrescriptionInState(prescription));

    this.delete$
      .pipe(
        takeUntilDestroyed(),
        switchMap((data) =>
          this._deletePrescription(data.prescription, data.actionedByRef)
        )
      )
      .subscribe((prescription) => {
        if (prescription) {
          this._deletePrescriptionFromState(prescription);
        }
      });

    this.loadAll$
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.loading$.next(true)),
        switchMap((status) => this._loadPrescriptions(status))
      )
      .subscribe((prescriptions) =>
        this._state.update((state) => ({
          ...state,
          prescriptions,
          loading: false,
        }))
      );

    this.load$
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.loading$.next(true)),
        switchMap((data: LoadPrescriptonData) => this._loadPrescription(data))
      )
      .subscribe((prescription) =>
        this._state.update((state) => ({
          ...state,
          selectedPrescription: prescription,
          loading: false,
        }))
      );

    this.loading$
      .pipe(takeUntilDestroyed())
      .subscribe((loading) =>
        this._state.update((state) => ({ ...state, loading }))
      );

    this.selected$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedPrescription) =>
        this._state.update((state) => ({ ...state, selectedPrescription }))
      );
  }

  create(prescriber: WithRef<IStaffer>, actionedBy: WithRef<IStaffer>): void {
    const practice = this._practice() as WithRef<IPractice>;
    const patient = this._patient() as WithRef<IPatient>;
    const prescription = Prescription.init(
      {
        patient: { ref: patient.ref },
        prescriber: { ref: prescriber.ref },
        practice: { ref: practice.ref },
      },
      actionedBy.ref
    );
    this.add$.next(prescription);
  }

  async navigate(prescription?: WithRef<IPrescription>): Promise<void> {
    const patient = this._patient() as WithRef<IPatient>;
    const route = prescription
      ? ['patients', patient.ref.id, 'prescriptions', prescription.ref.id]
      : ['patients', patient.ref.id, 'prescriptions'];

    await this._stateNav.brand(route);
  }

  private _addPrescriptionToState(prescription: WithRef<IPrescription>): void {
    this._state.update((state) => ({
      ...state,
      selectedPrescription: prescription,
      prescriptions: [...state.prescriptions, prescription],
    }));
  }

  private _editPrescriptionInState(
    editPrescription: WithRef<IPrescription>
  ): void {
    this._state.update((state) => ({
      ...state,
      selectedPrescription: editPrescription,
      prescriptions: state.prescriptions.map((prescription) =>
        isSameRef(prescription, editPrescription)
          ? editPrescription
          : prescription
      ),
    }));
  }

  private _deletePrescriptionFromState(
    deletePrescription: WithRef<IPrescription>
  ): void {
    this._state.update((state) => ({
      ...state,
      selectedPrescription: undefined,
      prescriptions: state.prescriptions.filter(
        (prescription) => !isSameRef(prescription, deletePrescription)
      ),
    }));
  }

  private async _addPrescription(
    prescription: IPrescription
  ): Promise<WithRef<IPrescription>> {
    const patient = this._patient() as WithRef<IPatient>;
    const ref = await addDoc(Patient.prescriptionCol(patient), prescription);
    this._snackBar.open('Prescription added');
    return { ...prescription, ref };
  }

  private async _editPrescription(
    prescription: WithRef<IPrescription>
  ): Promise<WithRef<IPrescription>> {
    await Firestore.patchDoc(prescription.ref, prescription);
    this._snackBar.open('Prescription updated');
    return prescription;
  }

  private async _deletePrescription(
    prescription: WithRef<IPrescription>,
    actionedByRef: DocumentReference<IStaffer>
  ): Promise<WithRef<IPrescription> | undefined> {
    await Firestore.patchDoc(prescription.ref, {
      ...Prescription.updateStatus(
        prescription,
        PrescriptionStatus.Delete,
        actionedByRef
      ),
      deleted: true,
    });
    this._snackBar.open('Prescription deleted');
    return prescription;
  }

  private _loadPrescriptions(
    status?: PrescriptionStatus
  ): Observable<WithRef<IPrescription>[]> {
    return this._currentPatient.doc$.pipe(
      filterUndefined(),
      switchMap((patient) => Patient.prescriptions$(patient, status))
    );
  }

  private _loadPrescription(
    data: LoadPrescriptonData
  ): Observable<WithRef<IPrescription>> {
    return getDoc$(Patient.prescriptionCol(data.patient), data.uid);
  }

  private _getPatient(
    scope: CurrentPatientScope
  ): Observable<WithRef<IPatient>> {
    return scope.doc$.pipe(filterUndefined());
  }

  private _getPractice(
    organisation: OrganisationService
  ): Observable<WithRef<IPractice>> {
    return organisation.practice$.pipe(filterUndefined());
  }
}
