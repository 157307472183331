import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ITransactionAction,
  ITransactionActionsData,
  TransactionActionHelpers,
} from '@principle-theorem/ng-payments';
import { snapshot } from '@principle-theorem/shared';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-transaction-action-menu-button',
  templateUrl: './transaction-action-menu-button.component.html',
  styleUrls: ['./transaction-action-menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionActionMenuButtonComponent {
  action$ = new ReplaySubject<ITransactionAction<unknown>>(1);
  data$ = new ReplaySubject<ITransactionActionsData<unknown>>(1);
  label$: Observable<string>;
  info$: Observable<string[]>;
  isDisabled$: Observable<boolean>;
  showAction$: Observable<boolean>;

  @Input()
  set action(action: ITransactionAction<unknown>) {
    if (action) {
      this.action$.next(action);
    }
  }

  @Input()
  set data(actionsData: ITransactionActionsData<unknown>) {
    if (actionsData) {
      this.data$.next(actionsData);
    }
  }

  constructor() {
    const inputs$ = combineLatest([this.data$, this.action$]);
    this.label$ = inputs$.pipe(
      switchMap(([data, action]) =>
        action.label$ ? action.label$(data) : of(action.label)
      )
    );
    this.info$ = inputs$.pipe(
      switchMap(([data, action]) =>
        TransactionActionHelpers.getInfo$(action, data)
      )
    );
    this.showAction$ = inputs$.pipe(
      switchMap(([data, action]) => action.canDo$(data))
    );
    this.isDisabled$ = combineLatest([
      this.action$.pipe(switchMap((action) => action.inProgress$)),
      this.showAction$,
    ]).pipe(map(([inProgress, canDo]) => inProgress || !canDo));
  }

  async doAction(): Promise<void> {
    const isDisabled = await snapshot(this.isDisabled$);
    if (isDisabled) {
      return;
    }
    const action = await snapshot(this.action$);
    const data = await snapshot(this.data$);
    await action.do(data);
  }
}
