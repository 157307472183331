<mat-toolbar color="accent">
  {{ data.providerName }} - Claim Estimate
</mat-toolbar>

<mat-dialog-content>
  <div class="layout-padding" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="end">
      <strong class="amount">Benefit</strong>
      <strong class="amount">Gap</strong>
    </div>
    <div *ngFor="let item of data.items; trackBy: trackByCode">
      <div>{{ item.description }}</div>
      <span fxFlex></span>
      <div class="amount">{{ item.benefit | currency }}</div>
      <div class="amount">{{ item.gap | currency }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <strong>Total</strong>
      <span fxFlex></span>
      <strong class="amount">{{ data.totalBenefit | currency }}</strong>
      <strong class="amount">{{ data.totalGap | currency }}</strong>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
