import { createReducer, on } from '@ngrx/store';
import { upsertPerioValues as upsertPerioValueFn } from '@principle-theorem/ng-perio-charting';
import { upsertEntity } from '@principle-theorem/ng-shared';
import {
  ChartedMultiStepTreatment,
  ClinicalChart,
  isSameToothRef,
} from '@principle-theorem/principle-core';
import { serialise, unserialise } from '@principle-theorem/shared';
import { isEqual, uniqWith, xorWith } from 'lodash';
import {
  deselectSurface,
  selectSurface,
  setChartConfig,
  setChartTeeth,
  setChartingAs,
  setDisabledSurfaces,
  setSelectedSurfaces,
} from '../../actions';
import { setChartedItemFilters } from '../../actions/chart-context';
import {
  setChartSection,
  setChartType,
  setChartView,
  setIsStacked,
} from '../../actions/chart-view';
import {
  forceAllowInteractivity,
  loadChartSuccess,
  removeChart,
  resetChart,
  setChart,
  updateChartedSurfaces,
} from '../../actions/load-chart';
import { deletePerioValues, upsertPerioValue } from '../../actions/perio-table';
import {
  addQuickChartingItem,
  removeQuickChartingItem,
} from '../../actions/quick-charting';
import {
  addCondition,
  removeCondition,
  updateCondition,
} from '../../actions/selected-conditions';
import {
  addMultiTreatment,
  addTreatment,
  removeMultiTreatment,
  removeTreatment,
  updateMultiTreatment,
  updatePlanProposal,
  updateTreatment,
  updateTreatments,
} from '../../actions/selected-treatments';
import {
  addTooth,
  removeTooth,
  updateToothRoots,
} from '../../actions/tooth-change';
import {
  INITIAL_CHART,
  chartAdapter,
  initialChartContextState,
} from './chart-context-state';

export const activeChartsReducer = createReducer(
  chartAdapter.getInitialState(),
  on(
    setChartType,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      chartType: action.chartType,
    }))
  ),

  on(
    setChartSection,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      chartSection: action.section,
    }))
  ),

  on(
    setChartView,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      chartView: action.view,
    }))
  ),

  on(
    setIsStacked,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      isStacked: action.stackedRows,
    }))
  ),

  on(
    setChartedItemFilters,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      filters: action.filters,
    }))
  ),

  on(
    setChartingAs,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      chartingAs: action.staffer,
    }))
  ),

  on(
    addQuickChartingItem,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      if (!state.chartingAs) {
        return state;
      }

      return {
        ...state,
        chartingAs: {
          ...state.chartingAs,
          quickChartingConfiguration: {
            ...state.chartingAs.quickChartingConfiguration,
            [action.itemType]: [
              ...state.chartingAs.quickChartingConfiguration[action.itemType],
              action.quickChartingItem,
            ],
          },
        },
      };
    })
  ),

  on(
    removeQuickChartingItem,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      if (!state.chartingAs) {
        return state;
      }
      const filteredValue = state.chartingAs.quickChartingConfiguration[
        action.itemType
      ].filter(
        (quickChartingItem) =>
          quickChartingItem.ref.id !== action.quickChartingItem.ref.id
      );
      return {
        ...state,
        chartingAs: {
          ...state.chartingAs,
          quickChartingConfiguration: {
            ...state.chartingAs.quickChartingConfiguration,
            [action.itemType]: filteredValue,
          },
        },
      };
    })
  ),

  on(
    loadChartSuccess,
    setChart,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      chart: action.chart,
    }))
  ),

  on(
    resetChart,
    upsertEntity(
      chartAdapter,
      initialChartContextState,
      () => initialChartContextState
    )
  ),

  on(
    removeChart,
    upsertEntity(chartAdapter, initialChartContextState, (state) => ({
      ...state,
      chart: INITIAL_CHART,
    }))
  ),

  on(
    addTooth,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const teeth = [...state.chart.teeth, action.tooth];
      return {
        ...state,
        chart: {
          ...state.chart,
          teeth,
        },
      };
    })
  ),

  on(
    removeTooth,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const teeth = state.chart.teeth.filter(
        (tooth) => !isSameToothRef(tooth.toothRef, action.toothRef)
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          teeth,
        },
      };
    })
  ),

  on(
    updateToothRoots,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const { teeth } = ClinicalChart.changeRoots(
        unserialise(state.chart),
        action.roots,
        action.toothRef
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          teeth: serialise(teeth),
        },
      };
    })
  ),

  on(
    updatePlanProposal,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: action.proposal,
        },
      };
    })
  ),

  on(
    addCondition,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const conditions = [...state.chart.conditions, action.condition];
      return {
        ...state,
        chart: {
          ...state.chart,
          conditions,
        },
      };
    })
  ),

  on(
    updateCondition,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const conditions = state.chart.conditions.map((currentCondition) => {
        if (currentCondition.uuid !== action.condition.uuid) {
          return currentCondition;
        }
        return {
          ...currentCondition,
          ...action.condition,
        };
      });
      return {
        ...state,
        chart: {
          ...state.chart,
          conditions,
        },
      };
    })
  ),

  on(
    removeCondition,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const conditions = state.chart.conditions.filter((currentCondition) => {
        return currentCondition.uuid !== action.uuid;
      });
      return {
        ...state,
        chart: {
          ...state.chart,
          conditions,
        },
      };
    })
  ),

  on(
    addTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const treatments = [
        ...state.chart.flaggedTreatment.treatments,
        action.treatment,
      ];
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            treatments,
          },
        },
      };
    })
  ),

  on(
    updateTreatments,
    upsertEntity(
      chartAdapter,
      initialChartContextState,
      (state, { treatments }) => ({
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            treatments,
          },
        },
      })
    )
  ),

  on(
    updateTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const treatments = state.chart.flaggedTreatment.treatments.map(
        (currentTreatment) => {
          if (currentTreatment.uuid !== action.treatment.uuid) {
            return currentTreatment;
          }
          return {
            ...currentTreatment,
            ...action.treatment,
          };
        }
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            treatments,
          },
        },
      };
    })
  ),

  on(
    addMultiTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const multiTreatments = [
        ...state.chart.flaggedTreatment.multiTreatments,
        action.multiTreatment,
      ];
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            multiTreatments,
          },
        },
      };
    })
  ),

  on(
    updateMultiTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const multiTreatments = state.chart.flaggedTreatment.multiTreatments.map(
        (treatment) => {
          if (treatment.uuid !== action.multiTreatment.uuid) {
            return treatment;
          }
          return {
            ...treatment,
            ...action.multiTreatment,
          };
        }
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            multiTreatments,
          },
        },
      };
    })
  ),

  on(
    removeTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const multiTreatments = state.chart.flaggedTreatment.multiTreatments
        .map((multiTreatment) =>
          ChartedMultiStepTreatment.deleteTreatment(
            unserialise(multiTreatment),
            action.uuid
          )
        )
        .map((multiTreatment) => serialise(multiTreatment));
      const treatments = state.chart.flaggedTreatment.treatments.filter(
        (currentTreatment) => currentTreatment.uuid !== action.uuid
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            multiTreatments,
            treatments,
          },
        },
      };
    })
  ),

  on(
    removeMultiTreatment,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const multiTreatments =
        state.chart.flaggedTreatment.multiTreatments.filter(
          (currentTreatment) => currentTreatment.uuid !== action.uuid
        );
      return {
        ...state,
        chart: {
          ...state.chart,
          flaggedTreatment: {
            ...state.chart.flaggedTreatment,
            multiTreatments,
          },
        },
      };
    })
  ),

  on(
    updateChartedSurfaces,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      return {
        ...state,
        chart: {
          ...state.chart,
          chartedSurfaces: action.chartedSurfaces,
        },
      };
    })
  ),

  on(
    upsertPerioValue,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const perioRecords = upsertPerioValueFn(
        state.chart.perioRecords || [],
        action.changes
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          perioRecords,
        },
      };
    })
  ),

  on(
    deletePerioValues,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const perioRecords = action.cells
        ? upsertPerioValueFn(state.chart.perioRecords || [], action.cells)
        : [];

      return {
        ...state,
        chart: {
          ...state.chart,
          perioRecords,
        },
      };
    })
  ),

  on(
    setChartTeeth,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      renderConfig: {
        ...state.renderConfig,
        teeth: action.teeth,
      },
    }))
  ),

  on(
    setDisabledSurfaces,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      renderConfig: {
        ...state.renderConfig,
        disabled: action.disabled,
      },
    }))
  ),

  on(
    selectSurface,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const selected = uniqWith(
        [...state.renderConfig.selected, action.surface],
        isEqual
      );
      return {
        ...state,
        renderConfig: {
          ...state.renderConfig,
          selected,
        },
      };
    })
  ),

  on(
    deselectSurface,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => {
      const selected = xorWith(
        state.renderConfig.selected,
        [action.surface],
        isEqual
      );
      return {
        ...state,
        renderConfig: {
          ...state.renderConfig,
          selected,
        },
      };
    })
  ),

  on(
    setSelectedSurfaces,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      renderConfig: {
        ...state.renderConfig,
        selected: action.selected,
      },
    }))
  ),

  on(
    setChartConfig,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      renderConfig: action.config,
    }))
  ),

  on(
    forceAllowInteractivity,
    upsertEntity(chartAdapter, initialChartContextState, (state, action) => ({
      ...state,
      forceAllowInteractivity: action.forceAllowInteractivity,
    }))
  )
);
