import { createAction, props } from '@ngrx/store';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import {
  type ChartItemDisplayType,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface ISetChartingAs extends IEntityAction<ChartId> {
  staffer: WithRef<IStaffer>;
}

export const setChartingAs = createAction(
  ChartActionTypes.SetChartingAs,
  props<SerialisedData<ISetChartingAs>>()
);

export interface ISetChartedItemFilters extends IEntityAction<ChartId> {
  filters: ChartItemDisplayType[];
}

export const setChartedItemFilters = createAction(
  ChartActionTypes.SetChartedItemFilters,
  props<SerialisedData<ISetChartedItemFilters>>()
);
