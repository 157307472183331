import {
  Arch,
  type IChartedRef,
  type IChartedTooth,
  type IDentalChartView,
  type IDentalChartViewSurface,
  type IDentalChartViewTooth,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { compact, get } from 'lodash';
import { type IChartRenderView } from './chart-svg-layout-renderer';

export function getDentalViewForChartedSurface(
  item: Partial<IChartedRef>,
  view: IDentalChartView
): IDentalChartViewSurface | undefined {
  if (item.unscoped || item.multipleTeeth) {
    return;
  }
  if (item.wholeMouth) {
    return view.mouth;
  }
  if (item.arch) {
    return item.arch === Arch.Upper ? view.mouth.upper : view.mouth.lower;
  }
  if (item.quadrant) {
    return getDentalViewForQuadrant(item.quadrant, view);
  }
  if (item.tooth) {
    return getDentalViewForTooth(item.tooth, view);
  }
}

export function getDentalViewForQuadrant(
  quadrant: Quadrant,
  view: IDentalChartView
): IDentalChartViewSurface | undefined {
  switch (quadrant) {
    case Quadrant.AdultUpperLeft:
      return view.mouth.upper?.left?.adult;
    case Quadrant.AdultUpperRight:
      return view.mouth.upper?.right?.adult;
    case Quadrant.AdultLowerLeft:
      return view.mouth.lower?.left?.adult;
    case Quadrant.AdultLowerRight:
      return view.mouth.lower?.right?.adult;
    case Quadrant.DeciduousUpperLeft:
      return view.mouth.upper?.left?.deciduous;
    case Quadrant.DeciduousUpperRight:
      return view.mouth.upper?.right?.deciduous;
    case Quadrant.DeciduousLowerLeft:
      return view.mouth.lower?.left?.deciduous;
    case Quadrant.DeciduousLowerRight:
      return view.mouth.lower?.right?.deciduous;
    default:
      return;
  }
}

export function getDentalViewForTooth(
  tooth: IChartedTooth,
  view: IDentalChartView
): IDentalChartViewSurface | undefined {
  return [
    ...(get(
      view,
      'mouth.upper.left.adult.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.upper.left.deciduous.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.upper.right.adult.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.upper.right.deciduous.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.lower.left.adult.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.lower.left.deciduous.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.lower.right.adult.teeth',
      []
    ) as IDentalChartViewTooth[]),
    ...(get(
      view,
      'mouth.lower.right.deciduous.teeth',
      []
    ) as IDentalChartViewTooth[]),
  ].find((viewTooth: IDentalChartViewTooth) => {
    return (
      viewTooth.id.tooth &&
      tooth &&
      viewTooth.id.tooth.quadrant === tooth.quadrant &&
      viewTooth.id.tooth.quadrantIndex === tooth.quadrantIndex
    );
  });
}

export function getRenderViewSurfaces(
  view: IChartRenderView
): IDentalChartViewSurface[] {
  return compact([
    get(view, 'mouth', undefined),
    ...view.arches.map((arch) => arch.display),
    ...view.rows
      .map((row) =>
        row.quadrants
          .map((quadrant) => [
            quadrant.display,
            ...quadrant.teeth
              .map((tooth) => [
                tooth.display,
                tooth.display.distal,
                tooth.display.facial,
                tooth.display.lingual,
                tooth.display.mesial,
                tooth.display.occlusal,
              ])
              .reduce((surfaces, surface) => [...surfaces, ...surface], []),
          ])
          .reduce((surfaces, surface) => [...surfaces, ...surface], [])
      )
      .reduce((surfaces, surface) => [...surfaces, ...surface], []),
  ]);
}
