import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MixedSchema } from '@principle-theorem/editor';
import { DateService } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  SelectionListStore,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { LabJob } from '@principle-theorem/principle-core';
import {
  LAB_JOB_STATUSES,
  type ILabJob,
  type LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { deleteDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-lab-job-item',
  templateUrl: './lab-job-item.component.html',
  styleUrls: ['./lab-job-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobItemComponent {
  statuses: LabJobStatus[] = LAB_JOB_STATUSES;
  lastInteractionSummary$: Observable<MixedSchema | undefined>;
  isLate$: Observable<boolean>;
  isComplete$: Observable<boolean>;
  hasConflict$: Observable<boolean>;
  selectEnabled$ = new BehaviorSubject<boolean>(true);
  item$ = new ReplaySubject<WithRef<ILabJob>>(1);
  @Input() selectionList: SelectionListStore<WithRef<ILabJob>>;
  @Input() selectedItem: WithRef<ILabJob>;
  @Output() selectedItemChange = new EventEmitter<WithRef<ILabJob>>();

  @Input()
  set selectDisabled(selectDisabled: BooleanInput) {
    this.selectEnabled$.next(!coerceBooleanProperty(selectDisabled));
  }

  @Input()
  set item(item: WithRef<ILabJob>) {
    this.item$.next(item);
  }

  constructor(
    public dateService: DateService,
    private _dialog: MatDialog
  ) {
    this.lastInteractionSummary$ = this.item$.pipe(
      map((item) => LabJob.lastInteractionSummary(item))
    );

    this.isLate$ = this.item$.pipe(map((item) => LabJob.late(item)));
    this.isComplete$ = this.item$.pipe(map((item) => LabJob.complete(item)));
    this.hasConflict$ = this.item$.pipe(
      switchMap((item) => LabJob.hasConflict(item))
    );
  }

  async selectItem(): Promise<void> {
    const item = await snapshot(this.item$);
    this.selectedItemChange.emit(item);
  }

  async deleteLabJob(item: WithRef<ILabJob>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Lab Job',
      prompt: 'Are you sure you want to delete this lab job?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirm = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirm) {
      return;
    }

    await deleteDoc(item.ref);
  }
}
