import { ofType } from '@ngrx/effects';
import {
  getRouterSelectors,
  routerNavigatedAction,
  type RouterReducerState,
} from '@ngrx/router-store';
import { Action, createFeatureSelector } from '@ngrx/store';
import { type Observable, type OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import { type IMinimalRouterStateSnapshot } from '../basic-router-state-serialiser';

export const getRouterState =
  createFeatureSelector<RouterReducerState<IMinimalRouterStateSnapshot>>(
    'router'
  );

export const {
  selectCurrentRoute,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = getRouterSelectors(getRouterState);

export function ofRouteMatch(
  routeMatch: RegExp
): OperatorFunction<Action, Action> {
  return (source$: Observable<Action>) =>
    source$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => routeMatch.test(payload.event.url))
    );
}
