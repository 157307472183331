import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { sharedTransactionsDataSourceProperties } from './transactions-report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.transactionEvent.latestEvent;

const dataSource = sharedTransactionsDataSourceProperties(
  factTable.practitionerPayments
);

export const PRACTITIONER_INCOME_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    ...sharedTransactionsDataSourceProperties(factTable.practitionerPayments),
    id: ReportBuilderDataSourceId.PractitionerIncome,
    name: 'Practitioner Income',
    description: `One result per practitioner for each transaction within Principle. This details the practitioner's allocated portion of each transaction. This includes information about the practice, patient, and invoice associated with each result.`,
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(factTable.transaction.createdAt),
        ReportBuilderGroups.toOption(factTable.practitionerName),
        ReportBuilderGroups.toOption(factTable.practice.name),
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.transaction(factTable.transaction),
      ReportBuilderGroups.invoice(factTable.invoice),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: ReportBuilderColumns.mergeColumnGroups([
      ReportBuilderColumns.toColumnGroup('Common', [
        factTable.practitionerName,
        factTable.practitionerPayments,
      ]),
      ...dataSource.availableColumns,
      ReportBuilderColumns.toColumnGroup(
        'Transaction',
        ReportBuilderColumns.transaction(factTable.transaction)
      ),
    ]),
    defaultColumns: [
      ...dataSource.defaultColumns,
      ...ReportBuilderColumns.toReferences([
        factTable.practitionerName,
        factTable.practitionerPayments,
        factTable.transaction.createdAt,
        factTable.transaction.status,
        factTable.transaction.type,
        factTable.transaction.provider,
      ]),
    ],
    availableCharts: [
      ...dataSource.availableCharts,
      ReportBuilderChartFilters.predefined(
        'overview.practitioner',
        factTable.practitionerName,
        ChartFilters.rowChart('Practitioner', factTable.practitionerName, [
          toMeasureBuilderData(factTable.practitionerPayments),
        ])
      ),
    ],
  };
