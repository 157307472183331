import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { ChartedTreatmentUpdater } from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
  type IEditTreatmentProvider,
  type TreatmentUpdateFn,
} from '../add-charted-surface-provider';
import { BaseAddTreatmentProvider } from '../base-add-treatment-provider';

export class AddTreatmentToProposalProvider
  extends BaseAddTreatmentProvider
  implements IChartedSurfaceProvider, IEditTreatmentProvider
{
  constructor(
    private _chartStore: ChartFacade,
    private _feeSchedule: WithRef<IFeeSchedule>
  ) {
    super();
  }

  async addSurfaceToTreatment(
    event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    await this._chartStore.addTreatment(
      ChartId.InAppointment,
      config,
      surfaces,
      this._feeSchedule,
      event.attributedTo
    );
  }

  async removeSurfacesFromTreatment(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    await this._chartStore.removeChartedSurfaces(
      ChartId.InAppointment,
      surfaceRefs,
      item
    );
  }

  async addTreatments(treatments: IChartedTreatment[]): Promise<void> {
    const newTreatments =
      await ChartedTreatmentUpdater.syncPricingRules(treatments);
    const allChartedTreatments = await snapshot(
      this._chartStore.chartedTreatments$(ChartId.InAppointment)
    );
    this._chartStore.updateTreatments(ChartId.InAppointment, [
      ...allChartedTreatments,
      ...newTreatments,
    ]);
  }

  async removeTreatments(treatments: IChartedTreatment[]): Promise<void> {
    const allChartedTreatments = await snapshot(
      this._chartStore.chartedTreatments$(ChartId.InAppointment)
    );
    const updatedTreatments = allChartedTreatments.filter(
      (current) =>
        !treatments.some((treatment) => treatment.uuid === current.uuid)
    );
    this._chartStore.updateTreatments(ChartId.InAppointment, updatedTreatments);
  }

  async updateTreatments(
    treatments: IChartedTreatment[],
    stepTreatments: IChartedTreatment[],
    updateFn: TreatmentUpdateFn
  ): Promise<void> {
    const allChartedTreatments = await snapshot(
      this._chartStore.chartedTreatments$(ChartId.InAppointment)
    );

    const updatedTreatments = await asyncForEach(
      allChartedTreatments,
      async (chartedTreatment) => {
        const matchingTreatment = treatments.find(
          (treatment) => treatment.uuid === chartedTreatment.uuid
        );
        return matchingTreatment
          ? updateFn(chartedTreatment, stepTreatments)
          : chartedTreatment;
      }
    );

    this._chartStore.updateTreatments(ChartId.InAppointment, updatedTreatments);
  }
}
