import { recursiveReplace } from '@principle-theorem/editor';
import {
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  Brand,
  CustomFormConfiguration,
} from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  FormSchemaPropertyType,
  ICustomFormDataResolverConfig,
  ICustomMapping,
  ICustomMappingSourceOption,
  IFormSchema,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  isArray,
  isObject,
  multiSwitchMap,
  snapshot,
} from '@principle-theorem/shared';
import { cloneDeep, sortBy } from 'lodash';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PatientAllergyOptionsSourceEntity } from '../../source/entities/patient-allergy-options';
import {
  AllergyToMedicalHistoryToXSLX,
  IMedicalHistoryOption,
} from './allergy-to-medical-history-to-xlsx';
import { XSLXToAllergyToMedicalHistory } from './xlsx-to-allergy-to-medical-history';

export const ALLERGY_CUSTOM_MAPPING_TYPE = 'medicalAllergyMapping';

export interface ICustomFormDataResolverConfigExtended
  extends ICustomFormDataResolverConfig {
  type: FormSchemaPropertyType;
  associatedValues?: string[];
}

export const ALLERGY_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Allergy Mapping',
    description: `Used to map Core Practice allergies to Principle medical history.`,
    type: ALLERGY_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticeAllergyMappingHandler extends BaseCustomMappingHandler<
  object,
  string
> {
  customMapping = ALLERGY_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const allergyOptions = new PatientAllergyOptionsSourceEntity();
    const records = await allergyOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.description,
        })),
      'value'
    );
  }

  async getSelectionListOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
        switchMap((config) =>
          config ? CustomFormConfiguration.getContent$(config) : of(undefined)
        ),
        map((content) => {
          const schema =
            content?.jsonSchemaForm.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
          return [
            {
              value: '',
              description: 'Omit',
              hasAssociatedValue: false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: '',
            },
            ...getValidMedicalHistoryFormOptions(schema).map((option) => ({
              value: option.path,
              description: option.mapTitle ?? '',
              hasAssociatedValue: option.associatedValues ? true : false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: 'Select an option',
            })),
          ];
        })
      )
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: string
  ): Observable<{ name: string }[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
      switchMap((config) =>
        config ? CustomFormConfiguration.getContent$(config) : of(undefined)
      ),
      map((content) => {
        const schema =
          content?.jsonSchemaForm.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
        const selectedOption = getValidMedicalHistoryFormOptions(schema).find(
          (option) => option.path === destinationValue
        );
        return (selectedOption?.associatedValues ?? []).map((name) => ({
          name,
        }));
      })
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      referralSources,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      referralSources,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAllergyToMedicalHistory()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const medicalHistoryOptions =
      await this._getMedicalHistoryOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const matchingOption = medicalHistoryOptions.find(
        (option) => option.label === mapTo
      );

      if (!matchingOption) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find matching option for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          associatedValue: matchingOption.associatedValue,
          destinationValue: matchingOption.destinationValue,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    referralSources: ICustomMappingSourceOption[];
    translators: AllergyToMedicalHistoryToXSLX;
  }> {
    const fileName = this.getFileName();
    const referralSources = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const medicalHistoryOptions =
      await this._getMedicalHistoryOptions(migration);
    const translators = new AllergyToMedicalHistoryToXSLX(
      medicalHistoryOptions,
      await this.getRecords(translationMap)
    );
    return { fileName, referralSources, translators };
  }

  private async _getMedicalHistoryOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<IMedicalHistoryOption[]> {
    return snapshot(
      from(this.getSelectionListOptions(migration)).pipe(
        multiSwitchMap((option) => {
          if (!option.hasAssociatedValue) {
            return of([
              {
                destinationValue: option.value,
                label: option.description,
              },
            ]);
          }

          return this.getAssociatedValueOptions$(migration, option.value).pipe(
            map((associatedValues) => {
              return associatedValues.map((associatedValue) => ({
                destinationValue: option.value,
                associatedValue: associatedValue.name,
                label: `${option.description} - ${associatedValue.name}`,
              }));
            })
          );
        }),
        map((options) => options.flat())
      )
    );
  }
}

export function getValidMedicalHistoryFormOptions(
  schema: IFormSchema
): ICustomFormDataResolverConfigExtended[] {
  const options: ICustomFormDataResolverConfigExtended[] = [];
  recursiveReplace(cloneDeep(schema), (option, _parents, key) => {
    if (isObject(option.properties) && 'properties' in option) {
      return option.properties;
    }

    if (isObject(option) && 'type' in option && 'title' in option) {
      const type = option.type as FormSchemaPropertyType;
      const associatedValues =
        'enum' in option && isArray(option.enum)
          ? option.enum.map((value) => String(value))
          : undefined;
      if (
        [
          FormSchemaPropertyType.Boolean,
          FormSchemaPropertyType.String,
        ].includes(type)
      ) {
        options.push({
          path: key ?? '',
          mapTitle: String(option.title),
          type,
          associatedValues,
        });
      }
    }

    return option;
  });

  return sortBy(options, 'mapTitle');
}
