<div class="flex flex-col gap-2 p-4">
  <h3 class="!m-0 !font-bold">Google Analytics 4 (GA4)</h3>

  <p class="!m-0">
    A Google tag ID is a series of letters and numbers that usually starts with
    <code
      class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
      >G-</code
    >. You can find it by following the
    <a
      href="https://support.google.com/analytics/answer/9539598?hl=en"
      target="_blank"
      >Official Documentation</a
    >
    or the steps below:
  </p>

  <ol class="list-inside list-decimal">
    <li>
      In
      <a href="https://tagmanager.google.com/" target="_blank">Tag Manager</a>,
      click <strong>Admin</strong>.
    </li>
    <li>Navigate to the <strong>Google Tags</strong> tab.</li>
    <li>
      Near the top right of the window, find your Tag IDs, formatted as
      <code
        class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
        >G-XXXXXXXXXX</code
      >. This is your ID.
    </li>
  </ol>

  <div>
    <img
      src="/assets/help-images/tracking-codes/google-tag-manager-google-tags.png"
      alt="Google Analytics 4 - Tracking Code"
    />
  </div>
</div>
