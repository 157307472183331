import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pr-increase-decrease-input',
  templateUrl: './increase-decrease-input.component.html',
  styleUrls: ['./increase-decrease-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncreaseDecreaseInputComponent {
  @Input() decreaseDisabled = false;
  @Input() increaseDisabled = false;
  @Output() increase: EventEmitter<void> = new EventEmitter<void>();
  @Output() decrease: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  @HostBinding('class.slim')
  slim = false;
}
