import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  type Timezone,
  Timestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_MEDICAL_HISTORY_RESOURCE_TYPE = 'patientMedicalHistories';

export const PATIENT_MEDICAL_HISTORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Medical History List',
      description: '',
      idPrefix: PATIENT_MEDICAL_HISTORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export const CORE_PRACTICE_PATIENT_MEDICAL_HISTORY_MAPPING: Record<
  keyof Pick<
    ICorePracticePatientHistory,
    | 'details'
    | 'treatConditionOther'
    | 'medicalHistory'
    | 'medicalConditionsOther'
    | 'allergyOther'
  >,
  string
> = {
  details: 'dentalHistory.reason',
  treatConditionOther: 'dentalHistory.more',
  medicalHistory: 'medicalHistory.conditions.other',
  medicalConditionsOther: 'medicalHistory.conditions.other',
  allergyOther: 'medicalHistory.allergies.other',
};

export interface ICorePracticePatientHistory {
  id: number;
  dateAttend: string; // 2021-12-02 00:54:47.870
  details?: string; // "jammed food between teeth"
  treatmentHistory?: string; // "PATIENT HAS BEEN KNOWN TO EXCESSIVLY BLEED"
  treatConditionOther?: string; // "jaw click/hurt, bite lips/cheek often,"
  medicalHistory?: string;
  // "Medications:
  // - Hiprix (blood infection )
  // - Noidip (blood pressure)
  // - Endep (Nevers )
  // - Mobic -  (Arthritis)
  // - Somac (gastro upset )"
  medicalConditionsOther?: string; // "anaemia , diabetes"
  allergyOther?: string; // "Crustaceans, kiwifruit, tomatoes"
  isDeleted: boolean;
  patientId: number;
}

export function isCorePracticePatientMedicalHistory(
  item: unknown
): item is ICorePracticePatientHistory {
  return TypeGuard.interface<ICorePracticePatientHistory>({
    id: isNumber,
    dateAttend: isString,
    details: TypeGuard.nilOr(isString),
    treatmentHistory: TypeGuard.nilOr(isString),
    treatConditionOther: TypeGuard.nilOr(isString),
    medicalHistory: TypeGuard.nilOr(isString),
    medicalConditionsOther: TypeGuard.nilOr(isString),
    allergyOther: TypeGuard.nilOr(isString),
    isDeleted: isBoolean,
    patientId: isNumber,
  })(item);
}

export interface ICorePracticePatientHistoryTranslations {
  dateAttend: Timestamp;
}

export interface ICorePracticePatientHistoryFilters {
  patientId: number;
}

const PATIENT_MEDICAL_HISTORY_SOURCE_QUERY = `
SELECT
  PatientHistoryId AS id,
  DateAttend AS date_attend,
  Details AS details,
  TreatHistory AS treatment_history,
  TreatCondOther AS treat_condition_other,
  MedicalHistory AS medical_history,
  MedCondOther AS medical_conditions_other,
  AllergyOther AS allergy_other,
  convert_to_boolean(IsDeleted) AS is_deleted,
  PatientId AS patient_id
FROM tblPatientHistory
`;

export class PatientMedicalHistorySourceEntity extends BaseSourceEntity<
  ICorePracticePatientHistory,
  ICorePracticePatientHistoryTranslations,
  ICorePracticePatientHistoryFilters
> {
  sourceEntity = PATIENT_MEDICAL_HISTORY_SOURCE_ENTITY;
  entityResourceType = PATIENT_MEDICAL_HISTORY_RESOURCE_TYPE;
  sourceQuery = PATIENT_MEDICAL_HISTORY_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientMedicalHistory;

  translate(
    data: ICorePracticePatientHistory,
    _timezone: Timezone
  ): ICorePracticePatientHistoryTranslations {
    return {
      dateAttend: toTimestamp(moment.utc(data.dateAttend)),
    };
  }

  getSourceRecordId(data: ICorePracticePatientHistory): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientHistory): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientHistory,
    _timezone: Timezone
  ): ICorePracticePatientHistoryFilters {
    return {
      patientId: data.patientId,
    };
  }
}
