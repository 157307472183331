import { getSchemaText, initVersionedSchema } from '@principle-theorem/editor';
import {
  ClinicalNoteCollection,
  IClinicalNote,
  IPatient,
  IStaffer,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  DocumentArchive,
  DocumentReference,
  Firestore,
  IReffable,
  ISODateType,
  WithRef,
  addDoc,
  all$,
  isSameRef,
  multiSort,
  query$,
  subCollection,
  toISODate,
  toTimestamp,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference, where } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { stafferToNamedDoc } from '../../common';
import { first } from 'lodash';
import * as moment from 'moment-timezone';

export class ClinicalNote {
  static init(
    overrides: AtLeast<IClinicalNote, 'owner' | 'recordDate'>
  ): IClinicalNote {
    return {
      uid: uuid(),
      content: initVersionedSchema(),
      immutable: false,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      deleted: false,
      ...overrides,
    };
  }

  static col(patient: IReffable<IPatient>): CollectionReference<IClinicalNote> {
    return subCollection<IClinicalNote>(
      patient.ref,
      PatientCollection.ClinicalNotes
    );
  }

  static archiveCol(
    clinicalNote: WithRef<IClinicalNote>
  ): CollectionReference<ArchivedDocument<IClinicalNote>> {
    return subCollection<ArchivedDocument<IClinicalNote>>(
      clinicalNote.ref,
      ClinicalNoteCollection.ClinicalNoteHistory
    );
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IClinicalNote>[]> {
    return query$(undeletedQuery(ClinicalNote.col(patient)));
  }

  static history$(
    clinicalNote: WithRef<IClinicalNote>
  ): Observable<ArchivedDocument<WithRef<IClinicalNote>>[]> {
    return all$(ClinicalNote.archiveCol(clinicalNote)).pipe(
      multiSort((a, b) => DocumentArchive.sortByArchivedAt(a, b))
    );
  }

  static filterEmpty(
    clinicalNotes: WithRef<IClinicalNote>[]
  ): WithRef<IClinicalNote>[] {
    return clinicalNotes.filter((note) => {
      return getSchemaText(note.content).trim().length > 0;
    });
  }

  static getEditableNote$(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    recordDate: ISODateType
  ): Observable<WithRef<IClinicalNote> | undefined> {
    return query$(
      undeletedQuery(ClinicalNote.col(patient)),
      where('recordDate', '==', recordDate)
    ).pipe(
      map((notes) => notes.find((note) => isSameRef(note.owner, staffer))),
      tap((note) => {
        if (!note) {
          void ClinicalNote.create(patient, staffer, recordDate);
        }
      })
    );
  }

  static async create(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    recordDate: ISODateType
  ): Promise<WithRef<IClinicalNote>> {
    const noteRef = await addDoc(
      ClinicalNote.col(patient),
      ClinicalNote.init({
        owner: stafferToNamedDoc(staffer),
        recordDate,
      })
    );
    return Firestore.getDoc(noteRef);
  }

  static async archive(
    clinicalNote: WithRef<IClinicalNote>,
    archivedBy: DocumentReference<IStaffer>
  ): Promise<void> {
    await DocumentArchive.snapshotToArchive(
      await Firestore.getDoc(clinicalNote.ref),
      ClinicalNote.archiveCol(clinicalNote),
      undefined,
      archivedBy
    );
  }

  static canArchive(
    clinicalNote: WithRef<IClinicalNote>,
    history: ArchivedDocument<IClinicalNote>[]
  ): boolean {
    if (!getSchemaText(clinicalNote.content).length) {
      return false;
    }
    const sortedHistory = history.sort((aNote, bNote) =>
      DocumentArchive.sortByArchivedAt(aNote, bNote)
    );
    const lastArchivedAt = first(sortedHistory)?.archivedAt;
    const today = toISODate(moment());

    if (!lastArchivedAt) {
      return clinicalNote.recordDate < today;
    }
    return toISODate(lastArchivedAt) < today;
  }
}
