import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IReferralSource,
  isPatientReferrer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  getDoc,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type IReferralSourcesGrouping,
  type IReferralSourcesReportRecord,
  ReferralSourcesReportStore,
} from '../referral-sources-report.store';
import { ReferralSourcesDrilldownToCSV } from './referral-sources-drilldown-to-csv';

@Component({
    selector: 'pr-referral-sources-report-drilldown',
    templateUrl: './referral-sources-report-drilldown.component.html',
    styleUrls: ['./referral-sources-report-drilldown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReferralSourcesReportDrilldownComponent implements OnDestroy {
  result$ = new ReplaySubject<IReferralSourcesGrouping>(1);
  treatmentTotal$ = new ReplaySubject<IReferralSourcesGrouping>(1);
  dataSource: ObservableDataSource<IReferralSourcesReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns = [
    'appointmentDate',
    'patientName',
    'mobileNumber',
    'referredBy',
    'referrerMobileNumber',
    'treatmentAmount',
  ];
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  csvTranslator = new ReferralSourcesDrilldownToCSV();

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set result(result: IReferralSourcesGrouping) {
    if (result) {
      this.result$.next(result);
    }
  }

  constructor(public store: ReferralSourcesReportStore) {
    this.dataSource = new ObservableDataSource(
      this.result$.pipe(map((result) => result.records))
    );

    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  getMobileNumber(patient: WithRef<IPatient>): Promise<string | undefined> {
    return Patient.getMobileNumber(patient);
  }

  async getReferrerMobileNumber(
    referrer: IReferralSource
  ): Promise<string | undefined> {
    if (isPatientReferrer(referrer)) {
      const patientReferrer = await getDoc(referrer.ref);
      return Patient.getMobileNumber(patientReferrer);
    }
  }

  private _sortingDataAccessor(
    data: IReferralSourcesReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'patientName':
        return data.patient.name.toLowerCase();
      case 'appointmentDate':
        return timestampSortingAccessor(data.appointment.event.from);
      default:
        return;
    }
  }
}
