<pr-tag-display-list
  *ngIf="bloc.shownTags$ | async as shownTags"
  class="inline-block"
  [tags]="shownTags"
  [compact]="true"
/>

<ng-container *ngIf="bloc.hiddenTagCount$ | async as hiddenTagCount">
  <div
    class="mx-2 inline-block cursor-default rounded-full px-2 py-px text-xs text-blue-500 outline outline-1 outline-blue-500"
    cdkOverlayOrigin
    #overlayTarget="cdkOverlayOrigin"
  >
    +{{ hiddenTagCount }}
  </div>

  <pt-custom-tooltip [target]="overlayTarget">
    <ng-template ptCustomTooltipContent>
      <div class="px-1 py-px">
        <pr-tag-display-list
          [tags]="bloc.hiddenTags$ | async"
          [compact]="true"
        />
      </div>
    </ng-template>
  </pt-custom-tooltip>
</ng-container>
