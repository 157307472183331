import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  convertWaitListDetailsToItem,
  convertWaitListItemToWaitListDetails,
  type IWaitListDetails,
} from '@principle-theorem/ng-appointment/store';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';
import {
  Appointment,
  OrganisationCache,
  Practice,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  patchDoc,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { type Moment as MomentMoment } from 'moment';
import { type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { WaitlistFormGroup } from '../waitlist-configuration/waitlist-configuration.formgroup';

@Component({
  selector: 'pr-waitlist-configuration-dialog',
  templateUrl: './waitlist-configuration-dialog.component.html',
  styleUrls: ['./waitlist-configuration-dialog.component.scss'],
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitlistConfigurationDialogComponent {
  today = moment() as MomentMoment;
  waitlistFormGroup = new WaitlistFormGroup();
  patient$: Observable<WithRef<IPatient>>;
  appointment$: Observable<WithRef<IAppointment>>;
  waitlistDetails$: Observable<Partial<IWaitListDetails>>;
  hasWaitlistSettings$: Observable<boolean>;
  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWaitListConfigurationData,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<WaitlistConfigurationDialogComponent>
  ) {
    this.appointment$ = doc$(data.appointment.ref).pipe(
      startWith(data.appointment)
    );
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient(appointment))
    );
    this.hasWaitlistSettings$ = this.appointment$.pipe(
      map((appointment) => !!appointment.waitListItem)
    );
    this.waitlistDetails$ = this.appointment$.pipe(
      map((appointment) => this._buildWaitListDetails(appointment))
    );

    const practice$ = OrganisationCache.practices.doc$(
      data.appointment.practice.ref
    );
    this.openTime$ = practice$.pipe(
      map((practice) => Practice.openTime(practice))
    );
    this.closeTime$ = practice$.pipe(
      map((practice) => Practice.closeTime(practice))
    );
  }

  async addToWaitlist(): Promise<void> {
    await this._updateWaitlistSettings();
  }

  async removeFromWaitlist(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    appointment.waitListItem = undefined;
    await saveDoc(appointment);
  }

  async save(): Promise<void> {
    await this._updateWaitlistSettings();
    this._snackBar.open('Waitlist settings updated');
    this._dialogRef.close();
  }

  private _buildWaitListDetails(
    appointment: WithRef<IAppointment>
  ): Partial<IWaitListDetails> {
    return appointment.waitListItem
      ? convertWaitListItemToWaitListDetails(appointment.waitListItem)
      : {};
  }

  private async _updateWaitlistSettings(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const timezone = await TimezoneResolver.fromEvent(appointment);
    const waitListItem = convertWaitListDetailsToItem(
      this.waitlistFormGroup.getRawValue(),
      timezone
    );
    await patchDoc(appointment.ref, { waitListItem });
  }
}

export interface IWaitListConfigurationData {
  appointment: WithRef<IAppointment>;
}
