import { type Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isString } from 'lodash';
import { type INamedDocument, type WithRef } from '@principle-theorem/shared';
import { type IMedia } from '@principle-theorem/principle-core/interfaces';

export class MediaSearch {
  results$: Observable<WithRef<IMedia>[]>;

  constructor(
    media$: Observable<WithRef<IMedia>[]>,
    search$: Observable<string | WithRef<IMedia>>
  ) {
    this.results$ = combineLatest([media$, search$.pipe(startWith(''))]).pipe(
      map(([media, search]) => this._filter(media, search))
    );
  }

  private _filter(
    media: WithRef<IMedia>[],
    search: string | WithRef<IMedia>
  ): WithRef<IMedia>[] {
    const searchValue: string = this._getSearchValue(search);
    return media.filter((item) => {
      const searchWithin: string = this._buildSearchWithin(item);
      return searchWithin.includes(searchValue);
    });
  }

  private _getSearchValue(search: string | WithRef<IMedia>): string {
    return isString(search) ? search.toLowerCase() : '';
  }

  private _buildSearchWithin(media: WithRef<IMedia>): string {
    const tags: string = media.tags
      .map((namedDoc: INamedDocument) => namedDoc.name)
      .join(' ');
    const searchString = `${media.name} ${tags}`;
    return searchString.toLowerCase();
  }
}
