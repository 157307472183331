import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  initVersionedSchema,
  type MixedSchema,
} from '@principle-theorem/editor';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type IPatientDocument } from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  filterUndefined,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CurrentPatientDocumentScope } from './current-patient-document-scope';

@Component({
    selector: 'pr-patient-document',
    templateUrl: './patient-document.component.html',
    styleUrls: ['./patient-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDocumentComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  patientDocument$: Observable<WithRef<IPatientDocument>>;
  templateForm = new TypedFormGroup<Pick<IPatientDocument, 'content' | 'name'>>(
    {
      name: new TypedFormControl<string>('', Validators.required),
      content: new TypedFormControl<MixedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
    }
  );

  constructor(
    patientDocumentScope: CurrentPatientDocumentScope,
    private _dialog: MatDialog,
    private _location: Location
  ) {
    this.patientDocument$ = patientDocumentScope.doc$.pipe(filterUndefined());

    this.patientDocument$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((patientDocument) =>
        this.templateForm.patchValue(patientDocument, { emitEvent: false })
      );

    validFormGroupChanges$(this.templateForm)
      .pipe(
        debounceTime(500),
        withLatestFrom(this.patientDocument$),
        takeUntil(this._onDestroy$)
      )
      // TODO: https://app.clickup.com/t/3mu7g4
      .subscribe(([value, doc]) => void saveDoc({ ...doc, ...value }));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async delete(): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Patient Document',
      prompt: 'Are you sure you want to delete this patient document?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    const patientDocument = await snapshot(this.patientDocument$);
    await deleteDoc(patientDocument.ref);
    this._location.back();
  }
}
