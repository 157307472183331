import { BaseMeasures } from '../models/base-measures';
import { IReportingQuery } from '../querying';

export interface IQueryScopeBuilderContext {
  factTable: BaseMeasures;
}

export interface IQueryScopeConfig<T> {
  id: string;
  required: boolean; // TODO: Implement this, it isn't currently used
  isValid(request: T): request is T;
  buildQuery(request: T, context?: IQueryScopeBuilderContext): BuiltQueryScope;
}

export type BuiltQueryScope = Required<
  Pick<IReportingQuery, 'attributes' | 'filters'>
>;
