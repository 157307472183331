import { Validators } from '@angular/forms';
import {
  TypedFormGroup,
  TypedFormControl,
  TypedFormArray,
} from '@principle-theorem/ng-shared';
import {
  ISterilisationPackContent,
  ISterilisationEquipment,
  ISterilisationPack,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, ONLY_NUMBERS } from '@principle-theorem/shared';

export class SterilisationPackContentFormGroup extends TypedFormGroup<ISterilisationPackContent> {
  constructor() {
    super({
      item: new TypedFormControl<INamedDocument<ISterilisationEquipment>>(
        undefined
      ),
      quantity: new TypedFormControl<number>(
        1,
        Validators.pattern(ONLY_NUMBERS)
      ),
    });
  }
}

export class SterilisationPackContentControls {
  constructor(private _formArray: TypedFormArray<ISterilisationPackContent>) {}

  addControl(index: number): void {
    this._formArray.insert(index + 1, new SterilisationPackContentFormGroup());
  }

  removeControl(index: number): void {
    if (!index) {
      return;
    }
    this._formArray.removeAt(index);
  }

  reset(): void {
    this._formArray.clear();
    this.addControl(0);
  }

  initContentControls(pack: ISterilisationPack): void {
    this.reset();
    pack.content.map((_, index) =>
      index ? this.addControl(index) : undefined
    );
  }
}
