import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { RecurringTaskConfigurationComponent } from './recurring-task-configuration/recurring-task-configuration.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskTabsComponent } from './task-tabs/task-tabs.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgMaterialModule,
    RouterModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],
  declarations: [
    TaskTabsComponent,
    RecurringTaskConfigurationComponent,
    TaskListComponent,
  ],
  exports: [
    TaskTabsComponent,
    RecurringTaskConfigurationComponent,
    TaskListComponent,
  ],
})
export class TaskPagesModule {}
