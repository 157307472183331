import type { JSONContent, Predicate } from '@tiptap/core';
import {
  Node,
  findParentNodeClosestToPos,
  mergeAttributes,
} from '@tiptap/core';
import type { NodeType, Node as ProseMirrorNode } from '@tiptap/pm/model';

import { NodeSelection } from '@tiptap/pm/state';
import { isNil } from 'lodash';
import { COLUMN } from './column';
import { ColumnSelection } from './column-selection';
import { buildColumn, buildColumnBlock, buildColumns } from './utils';
import { BlockNodes } from '../../available-extensions';

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    columnBlock: {
      setColumns: (columns: number) => ReturnType;
      unsetColumns: () => ReturnType;
    };
  }
}

export interface IColumnBlockOptions {
  nestedColumns: boolean;
  columnType: Node;
}

export const COLUMN_BLOCK = Node.create<IColumnBlockOptions>({
  name: BlockNodes.ColumnBlock,
  group: 'block',
  content: 'column{2,}',
  isolating: true,
  selectable: true,

  addOptions() {
    return {
      nestedColumns: false,
      columnType: COLUMN,
    };
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, { class: 'column-block' });
    return ['div', attrs, 0];
  },

  addCommands() {
    return {
      unsetColumns:
        () =>
        ({ tr, dispatch }) => {
          try {
            if (!dispatch) {
              return false;
            }

            // find the first ancestor
            const pos = tr.selection.$from;
            const where: Predicate = (node) => {
              if (!this.options.nestedColumns && node.type === this.type) {
                return true;
              }
              return node.type === this.type;
            };
            const firstAncestor = findParentNodeClosestToPos(pos, where);
            if (firstAncestor === undefined) {
              return false;
            }

            // find the content inside of all the columns
            let nodes: ProseMirrorNode[] = [];
            firstAncestor.node.descendants((node, _, parent) => {
              if (parent?.type.name === COLUMN.name) {
                nodes.push(node);
              }
            });
            nodes = nodes.reverse().filter((node) => node.content.size > 0);

            // resolve the position of the first ancestor
            const resolvedPos = tr.doc.resolve(firstAncestor.pos);
            const sel = new NodeSelection(resolvedPos);

            // insert the content inside of all the columns and remove the column layout
            tr = tr.setSelection(sel);
            nodes.forEach((node) => (tr = tr.insert(firstAncestor.pos, node)));
            tr = tr.deleteSelection();
            dispatch(tr);
            return true;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          return false;
        },
      setColumns:
        (numberOfColumns: number, keepContent = false) =>
        ({ tr, dispatch }) => {
          try {
            const { doc, selection } = tr;
            if (!dispatch) {
              return false;
            }

            const sel = new ColumnSelection(selection);
            sel.expandSelection(doc);

            const { openStart, openEnd } = sel.content();
            if (openStart !== openEnd) {
              return false;
            }

            // create columns and put old content in the first column
            let columnBlock;
            if (keepContent) {
              const content = sel.content().toJSON() as Partial<JSONContent>;
              const firstColumn = buildColumn(content);
              const otherColumns = buildColumns(numberOfColumns - 1);
              columnBlock = buildColumnBlock({
                content: [firstColumn, ...otherColumns],
              });
            } else {
              const columns = buildColumns(numberOfColumns);
              columnBlock = buildColumnBlock({ content: columns });
            }
            const newNode = doc.type.schema.nodeFromJSON(columnBlock);
            if (isNil(newNode)) {
              return false;
            }

            const parent = sel.$anchor.parent.type;

            if (
              !canAcceptColumnBlockChild(
                parent,
                this.type,
                this.options.nestedColumns
              )
            ) {
              // eslint-disable-next-line no-console
              console.warn('content not allowed');
              return false;
            }

            tr = tr.setSelection(sel);
            tr = tr.replaceSelectionWith(newNode, false);
            dispatch(tr);
            return true;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          return false;
        },
    };
  },
});

function canAcceptColumnBlockChild(
  par: NodeType,
  type: NodeType,
  nestedColumns: boolean
): boolean {
  if (!par.contentMatch.matchType(type)) {
    return false;
  }

  if (!nestedColumns && par.name === COLUMN.name) {
    return false;
  }

  return true;
}
