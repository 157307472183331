import { ChartedSurface } from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IFeeSchedule,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';
import { BaseAddTreatmentProvider } from '../base-add-treatment-provider';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';

export class AddTreatmentToTreatmentTemplateProvider
  extends BaseAddTreatmentProvider
  implements IChartedSurfaceProvider
{
  constructor(
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _feeSchedule: WithRef<IFeeSchedule>
  ) {
    super();
  }

  async addSurfaceToTreatment(
    event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    await this._schedulingFacade.addTreatmentToStep(
      config,
      surfaces,
      this._feeSchedule,
      event.attributedTo
    );
  }

  override async removeSurfacesFromTreatment(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    const treatmentPlanPair = await snapshot(
      this._schedulingFacade.appointmentDetails$.pipe(
        map((appointment) => appointment.treatment)
      )
    );
    if (!treatmentPlanPair?.step.treatments) {
      return;
    }

    const foundTreatment = treatmentPlanPair.step.treatments.find(
      (treatment) => treatment.uuid === item.uuid
    );
    if (!foundTreatment) {
      return;
    }

    const chartedSurfaces = ChartedSurface.filterSurfaceRefs(
      foundTreatment.chartedSurfaces,
      surfaceRefs
    );
    if (!chartedSurfaces.length) {
      return this._schedulingFacade.removeTreatmentFromStep(
        foundTreatment.uuid
      );
    }

    const treatments = treatmentPlanPair.step.treatments.map((treatment) => {
      if (treatment.uuid !== item.uuid) {
        return treatment;
      }
      return { ...treatment, chartedSurfaces };
    });

    await this._schedulingFacade.updateTreatmentStep({
      treatments,
    });
  }
}
