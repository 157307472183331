import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChartedConfigurationFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { ConditionConfiguration } from '@principle-theorem/principle-core';
import {
  type ChartableSurface,
  type IChartedItemDisplay,
  type IConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  type IAddChartedItem,
  toAddChartedItemData,
} from '../add-charted-item';

@Component({
    selector: 'pr-add-charted-condition',
    templateUrl: './add-charted-condition.component.html',
    styleUrls: ['./add-charted-condition.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddChartedConditionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  conditionConfig: IConditionConfiguration;
  form = new TypedFormGroup<Pick<IConditionConfiguration, 'name'>>({
    name: new TypedFormControl('', Validators.required),
  });

  constructor(
    private _configurationStore: ChartedConfigurationFacade,
    private _dialogRef: MatDialogRef<AddChartedConditionComponent>,
    @Inject(MAT_DIALOG_DATA) data: IAddChartedItem
  ) {
    this.conditionConfig = ConditionConfiguration.init(
      toAddChartedItemData(data)
    );

    this.form.patchValue(this.conditionConfig);
    validFormGroupChanges$(this.form)
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((formData) => this.updateGeneralConfig(formData));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get isValid(): boolean {
    return this.conditionConfig.name ? true : false;
  }

  updateDisplayOptions(display: IChartedItemDisplay): void {
    this.conditionConfig = { ...this.conditionConfig, display };
  }

  updateSelectedSurfaces(availableSurfaces: ChartableSurface[]): void {
    this.conditionConfig = { ...this.conditionConfig, availableSurfaces };
  }

  updateGeneralConfig(generalConfig: Partial<IConditionConfiguration>): void {
    this.conditionConfig = { ...this.conditionConfig, ...generalConfig };
  }

  add(): void {
    if (!this.isValid) {
      return;
    }
    this._configurationStore.addAndChartConditionConfiguration(
      ChartId.InAppointment,
      this.conditionConfig
    );
    this._dialogRef.close();
  }
}
