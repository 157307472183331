import { type Timestamp } from '@principle-theorem/shared';
import { type AutomationEntity } from './automation-entity';

export interface IAutomationListData {
  automation: AutomationEntity;
  isSelected: boolean;
  newDate?: Timestamp;
}

export interface IRescheduleAutomationListData
  extends Omit<IAutomationListData, 'isSelected'> {
  reschedule: boolean;
}
