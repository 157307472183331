import { Validators } from '@angular/forms';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  IPrescriptionItem,
  PrescriptionFormData,
  PrescriptionItemFormData,
} from '@principle-theorem/principle-core/interfaces';
import { PrescriptionItemFormGroup } from '../prescription-item-form/prescription-item-form';

export class PrescriptionFormGroup extends TypedFormGroup<PrescriptionFormData> {
  constructor() {
    super({
      items: new TypedFormArray<PrescriptionItemFormData>([]),
      notes: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
      pbsSafetyNet: new TypedFormControl<boolean>(),
      concessionalOrDependentRPBS: new TypedFormControl<boolean>(),
      pbs: new TypedFormControl<boolean>(),
      rpbs: new TypedFormControl<boolean>(),
      brandSubstitutionNotPermitted: new TypedFormControl<boolean>(),
    });
  }

  get items(): TypedFormArray<PrescriptionItemFormData> {
    return this.controls.items as TypedFormArray<PrescriptionItemFormData>;
  }

  addItem(item?: IPrescriptionItem): void {
    this.items.push(
      new PrescriptionItemFormGroup(item, {
        quantity: [Validators.required],
      })
    );
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }
}
