import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import {
  HicapsConnectResponseCodes,
  Practice,
} from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  type IHealthcareClaim,
  type IInvoice,
  type IPatient,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isString, sum } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IClaimEstimateProvider,
  IClaimEstimateSummary,
} from '../../claim-estimate/claim-estimate-provider';
import { ClaimEstimateSummaryDialogService } from '../../transaction-components/claim-estimate-summary-dialog/claim-estimate-summary-dialog.service';
import { HealthFundMemberNumberDialogService } from '../../transaction-components/health-fund-member-number-dialog/health-fund-member-number-dialog.service';
import { TransactionProviderType } from '../../transaction-provider';
import { HICAPS_CONNECT_IMAGE_URL } from '../../transaction-provider-options/hicaps-connect-transaction-provider-options';
import { HicapsConnectService } from '../hicaps-connect.service';
import { HicapsConnectHealthFundRequestBuilder } from './hicaps-connect-health-fund-request-builder';
import { HicapsConnectHealthFundResponseTransformer } from './hicaps-connect-health-fund-response-transformer';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { HICAPS_CONNECT_FEATURE } from '@principle-theorem/principle-core/features';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

@Injectable({ providedIn: 'root' })
export class HicapsConnectHealthFundClaimEstimateProvider
  implements IClaimEstimateProvider
{
  providerId = TransactionProvider.HicapsConnectHealthFund;
  providerType = TransactionProviderType.HealthFund;
  isEnabled$: Observable<boolean>;
  label = 'Hicaps';
  icon = 'health_and_safety';
  imageUrl = HICAPS_CONNECT_IMAGE_URL;

  constructor(
    private _hicapsConnect: HicapsConnectService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _snackbar: MatSnackBar,
    private _memberNumberDialog: HealthFundMemberNumberDialogService,
    private _claimEstimateSummary: ClaimEstimateSummaryDialogService,
    private _basicDialog: BasicDialogService,
    private _featureFlags: FeatureFlagsService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map(
        (practice) =>
          this._featureFlags.isFeatureEnabled(HICAPS_CONNECT_FEATURE) &&
          Practice.isHicapsConnectEnabled(practice)
      )
    );
  }

  async capture(
    invoice: IInvoice,
    claim: IHealthcareClaim,
    patient: WithRef<IPatient>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._memberNumberDialog.open(invoice, patient);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice.practice);
    if (!terminal) {
      return;
    }

    const serviceDate = await HicapsConnectHelpers.getServiceDate(invoice);
    const data = HicapsConnectHealthFundRequestBuilder.buildClaimQuote(
      this._hicapsConnect.getConfig(),
      patient,
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
    if (!data) {
      return;
    }
    const { request, extendedData } = data;
    this._snackbar.open('Sending request to terminal');

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(
      api.sendQuoteRequest(request, extendedData)
    );

    const claimEstimate = this._toClaimEstimateSummary(response);

    if (!claimEstimate) {
      const errorMessage =
        !isString(response.data) &&
        response.data?.ResponseText &&
        response.data.ResponseCode
          ? `${response.data.ResponseCode} - ${response.data?.ResponseText}`
          : undefined;
      await this._basicDialog.alert({
        title: 'Error processing quote request',
        prompt: compact([
          'An error occurred while sending the quote request.',
          errorMessage,
        ]),
        submitLabel: 'Close',
      });
      return;
    }

    await this._claimEstimateSummary.open(claimEstimate);
  }

  private _toClaimEstimateSummary(
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendQuoteRequest>
  ): IClaimEstimateSummary | undefined {
    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return;
    }
    if (
      !HicapsConnectResponseCodes.isClaimSuccessful(response.data.ResponseCode)
    ) {
      return;
    }

    const claimResponseItems = response.data.ClaimDetails.map((item) =>
      HicapsConnectHealthFundResponseTransformer.claimResponseItem(item)
    );

    const items = claimResponseItems.map((item) => ({
      code: item.itemNumber,
      description:
        HicapsConnectHealthFundResponseTransformer.getItemDescription(item),
      gap: roundTo2Decimals(item.itemClaimAmount - item.itemBenefitAmount),
      benefit: roundTo2Decimals(item.itemBenefitAmount),
    }));

    return {
      providerName: 'Hicaps',
      items,
      totalBenefit: roundTo2Decimals(sum(items.map((item) => item.benefit))),
      totalGap: roundTo2Decimals(sum(items.map((item) => item.gap))),
    };
  }
}
