import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  isDisabled$,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  HEX_COLOUR_REGEX,
  randomHexColour,
  type WithRef,
} from '@principle-theorem/shared';

export type TagFrom = Pick<ITag, 'name' | 'hexColour'>;

export interface IEditTagDialogData {
  title: string;
  tag?: WithRef<ITag>;
}

@Component({
  selector: 'pr-edit-tag-dialog',
  templateUrl: './edit-tag-dialog.component.html',
  styleUrls: ['./edit-tag-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTagDialogComponent {
  title = 'Add Tag';
  tagForm: TypedFormGroup<TagFrom> = new TypedFormGroup<TagFrom>({
    name: new TypedFormControl('', Validators.required),
    hexColour: new TypedFormControl(randomHexColour(), [
      Validators.required,
      Validators.pattern(HEX_COLOUR_REGEX),
    ]),
  });
  isDisabled$ = isDisabled$(this.tagForm);

  constructor(
    private _dialogRef: MatDialogRef<EditTagDialogComponent, TagFrom>,
    @Inject(MAT_DIALOG_DATA) data: IEditTagDialogData
  ) {
    if (!data) {
      return;
    }
    this.title = data.title;
    if (data.tag) {
      this.tagForm.patchValue(data.tag);
    }
  }

  submit(): void {
    this._dialogRef.close(this.tagForm.getRawValue());
  }
}
