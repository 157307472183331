import { type IProfileLoader } from './profile-loader';
import { type IContact } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { LoadContactActionService } from '@principle-theorem/ng-interactions';

export class ContactLoader implements IProfileLoader {
  showInitials = false;
  name: string;
  details: string;
  phone: string;

  constructor(
    public contact: WithRef<IContact>,
    public loader: LoadContactActionService
  ) {
    this.name = contact.name;
    this.details = contact.address ?? '';
    this.phone = contact.phone ?? '';
  }

  async do(): Promise<void> {
    await this.loader.do(this.contact);
  }
}
