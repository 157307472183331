import {
  IDestinationEntityHandler,
  SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
  ISourceEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  bufferedQuery$,
  errorNil,
  find$,
  where,
  type CollectionReference,
  type IReffable,
  type Timestamp,
  type WithRef,
  DocumentReference,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { type Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';
import { type TranslationMapHandler } from '../translation-map';
import { DestinationEntity } from './destination-entity';
import { type IArraySorter } from './destination-entity-record';
import {
  type IDestinationJobFilter,
  type IDestinationJobFilterHandler,
} from './destination-job-filter';

export abstract class BaseDestinationEntity<
  SuccessRecordData extends object,
  InitialJobData extends object,
  BuiltMigrationData extends object,
> implements
    IDestinationEntityHandler<
      SuccessRecordData,
      InitialJobData,
      BuiltMigrationData
    >
{
  filters: IDestinationJobFilterHandler<InitialJobData>[] = [];
  sorters: IArraySorter[] = [];

  abstract destinationEntity: IDestinationEntity;

  abstract getDestinationEntityRecordUid(data: InitialJobData): string;

  abstract sourceCountDataAccessor(
    data: InitialJobData
  ): DocumentReference<ISourceEntityRecord>;

  abstract buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    from?: Timestamp,
    to?: Timestamp
  ): Observable<InitialJobData[]>;

  abstract buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: InitialJobData
  ):
    | Promise<
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      >
    | (
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      );

  abstract hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: BuiltMigrationData
  ): Promise<BuiltMigrationData | undefined> | (BuiltMigrationData | undefined);

  abstract buildMergeConflictRecord(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord;

  abstract runJob(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): Promise<IDestinationEntityRecord>;

  canMigrateByDateRange = false;
  canMigrateByIdRange = false;
  sourceEntities = {};
  destinationEntities = {};

  canHandle(destination: IDestinationEntity): boolean {
    return isEqual(
      destination.metadata.key,
      this.destinationEntity.metadata.key
    );
  }

  getEntity$(
    migration: IReffable<IPracticeMigration>
  ): Observable<IDestinationEntity | undefined> {
    return find$(
      PracticeMigration.destinationEntityCol(migration),
      where('metadata.key', '==', this.destinationEntity.metadata.key)
    );
  }

  getCollection$(
    migration: IReffable<IPracticeMigration>
  ): Observable<
    CollectionReference<IDestinationEntityRecord<SuccessRecordData>>
  > {
    return PracticeMigration.findDestinationEntity$(
      migration,
      this.destinationEntity
    ).pipe(
      take(1),
      errorNil(
        `Could not find destination entity ${this.destinationEntity.metadata.label}`
      ),
      map((destinationEntity) =>
        DestinationEntity.recordCol({
          ref: destinationEntity.ref,
        })
      )
    );
  }

  getRecords$(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number = 50,
    initialRecord?: WithRef<IDestinationEntityRecord<SuccessRecordData>>
  ): Observable<WithRef<IDestinationEntityRecord<SuccessRecordData>>[]> {
    return this.getCollection$(migration).pipe(
      concatMap((collection) =>
        bufferedQuery$(collection, bufferSize, 'ref', 'asc', initialRecord)
      )
    );
  }

  shouldSkipRecord(
    jobData: InitialJobData,
    filters: IDestinationJobFilter[]
  ): boolean {
    return filters.some((filter) => {
      const handler = this.filters.find((filterHandler) =>
        filterHandler.canHandle(filter)
      );
      if (!handler) {
        throw new Error(`No filter handler for ${filter.key}`);
      }

      return !handler.matches(jobData, filter);
    });
  }
}
