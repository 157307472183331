import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../transaction-providers/transaction-action';

@Component({
    selector: 'pr-transaction-menu-actions',
    templateUrl: './transaction-menu-actions.component.html',
    styleUrls: ['./transaction-menu-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionMenuActionsComponent {
  data$ = new ReplaySubject<ITransactionActionsData<unknown>>(1);
  actions$ = new ReplaySubject<ITransactionAction<unknown>[]>(1);
  trackByAction = TrackByFunctions.label<ITransactionAction<unknown>>();

  @Input()
  set data(actionsData: ITransactionActionsData<unknown>) {
    if (actionsData) {
      this.data$.next(actionsData);
    }
  }

  @Input()
  set actions(actions: ITransactionAction<unknown>[]) {
    if (actions) {
      this.actions$.next(actions);
    }
  }
}
