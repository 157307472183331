@if (canConfirm$ | async) {
  <button
    mat-flat-button
    color="primary"
    [disabled]="confirmDisabled$ | async"
    (click)="confirm()"
  >
    @if (store.isPatientDetails$ | async) {
      Confirm & Update Patient
    } @else {
      Confirm Checked
    }
  </button>
}
