import { CustomChartType } from '@principle-theorem/principle-core/interfaces';
import { IChartBuilderData } from './models/measure-properties';

export interface IChartConfig {
  labels: IChartLabel;
  type: CustomChartType;
  builderData: IChartBuilderData;
  summary?: string;
}

export interface IChartLabel {
  title: string;
  verticalAxis?: string;
  horizontalAxis?: string;
}

export enum ChartType {
  Row = 'Row',
  RowStacked = 'RowStacked',
  RowGrouped = 'RowGrouped',
  SingleValue = 'SingleValue',
  Pie = 'Pie',
  // ...
  Bar = 'Bar',
  Area = 'Area',
  Line = 'Line',
  Combo = 'Combo',
  Table = 'Table',
  SanKey = 'SanKey',
  Scatter = 'scatter',
  Forecast = 'Forecast',
  BasicLine = 'basicline',
  Histogram = 'Histogram',
}
