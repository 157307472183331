<ng-container *ngIf="balance$ | async as balance">
  <div class="line flex gap-4">
    <div class="flex-1">Subtotal</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.subtotal | currency }}</span>
    </div>
  </div>

  <div class="line flex gap-4">
    <div class="flex-1">Tax</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.tax | currency }}</span>
    </div>
  </div>

  <div class="line flex gap-4">
    <div class="flex-1">Total</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.total | currency }}</span>
    </div>
  </div>

  <div class="line flex gap-4">
    <div class="flex-1">Discounts</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.discounts | currency }}</span>
    </div>
  </div>

  <div class="line flex gap-4">
    <div class="flex-1">Paid to Date</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.paidToDate | currency }}</span>
    </div>
  </div>

  <div class="line balance flex items-center gap-4">
    <div class="flex-1">Balance</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.balance | currency }}</span>
    </div>
  </div>
</ng-container>
