import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  ButtonsContainerComponent,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ClinicalChart } from '@principle-theorem/principle-core';
import {
  type IClinicalChart,
  type ITooth,
  type IToothRef,
  type Quadrant,
  QUADRANTS,
  ToothType,
  TOOTH_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { ROOT_OPTIONS } from '../../models/chart-tooth-surfaces-svg-map';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';

export interface IAddToothDialogData {
  chart: IClinicalChart;
  quadrant?: Quadrant;
  tooth?: ITooth;
  toothRef?: IToothRef;
  editMode?: boolean;
}

@Component({
  selector: 'pr-add-tooth-dialog',
  templateUrl: './add-tooth-dialog.component.html',
  styleUrls: ['./add-tooth-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatToolbarModule,
    ButtonsContainerComponent,
  ],
})
export class AddToothDialogComponent implements OnInit {
  trackByQuadrant = TrackByFunctions.variable<Quadrant>();
  trackByToothType = TrackByFunctions.variable<ToothType>();
  trackByRoot = TrackByFunctions.variable<number>();
  toothTypes: ToothType[] = TOOTH_TYPES;
  rootOptions: number[] = ROOT_OPTIONS;
  quadrants: Quadrant[] = QUADRANTS;
  form: TypedFormGroup<ITooth> = new TypedFormGroup<ITooth>({
    type: new TypedFormControl<ToothType>(
      ToothType.Incisor,
      Validators.required
    ),
    roots: new TypedFormControl<number>(1, Validators.required),
    toothRef: new TypedFormGroup<IToothRef>({
      quadrant: new TypedFormControl<Quadrant>(undefined, Validators.required),
      quadrantIndex: new TypedFormControl<number>(
        undefined,
        Validators.required
      ),
    }),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAddToothDialogData,
    private _dialogRef: MatDialogRef<AddToothDialogComponent>,
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade
  ) {
    if (this.data.tooth) {
      this.form.patchValue({ ...this.data.tooth });
      return;
    }

    if (this.data.toothRef) {
      this.form.patchValue({ toothRef: this.data.toothRef });
      return;
    }

    if (this.data.quadrant) {
      this.form.patchValue({
        toothRef: {
          quadrant: this.data.quadrant,
          quadrantIndex: ClinicalChart.getNextToothIndex(
            this.data.chart,
            this.data.quadrant
          ),
        },
      });
      return;
    }
  }

  ngOnInit(): void {
    if (this.data.editMode) {
      this.form.controls.type.disable();
      this.form.controls.toothRef.disable();
    }
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const tooth = this.form.getRawValue();

    if (this.data.editMode) {
      this._chartStore.updateToothRoots(
        ChartId.InAppointment,
        tooth.toothRef,
        tooth.roots
      );
      this._snackBar.open('Tooth Updated');
      this._dialogRef.close();
      return;
    }

    if (
      ClinicalChart.hasToothIndex(
        this.data.chart,
        tooth.toothRef.quadrant,
        tooth.toothRef.quadrantIndex
      )
    ) {
      this._snackBar.open('This tooth already exists');
      return;
    }

    this._chartStore.addTooth(ChartId.InAppointment, tooth);
    this._snackBar.open('Tooth Added');
    this._dialogRef.close();
  }
}
