import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { MultiTreatmentPackage } from '@principle-theorem/principle-core';
import {
  type IMultiTreatmentConfiguration,
  type IMultiTreatmentPackage,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type MultiTreatmentPackageForm } from './edit-multi-treatment-package/edit-multi-treatment-package.component';

@Component({
  selector: 'pr-multi-treatment-packages',
  templateUrl: './multi-treatment-packages.component.html',
  styleUrls: ['./multi-treatment-packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiTreatmentPackagesComponent {
  multiTreatmentConfig$ = new ReplaySubject<
    WithRef<IMultiTreatmentConfiguration>
  >(1);
  trackByPackage = TrackByFunctions.uniqueId<IMultiTreatmentPackage>();
  packages$: Observable<IMultiTreatmentPackage[]>;

  @Input()
  set multiTreatmentConfig(
    multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>
  ) {
    if (multiTreatmentConfig) {
      this.multiTreatmentConfig$.next(multiTreatmentConfig);
    }
  }

  constructor(private _snackBar: MatSnackBar) {
    this.packages$ = this.multiTreatmentConfig$.pipe(
      map((multiTreatmentConfig) => multiTreatmentConfig.packages)
    );
  }

  async addPackage(): Promise<void> {
    const multiTreatmentConfig = await snapshot(this.multiTreatmentConfig$);
    await patchDoc(multiTreatmentConfig.ref, {
      packages: [
        ...multiTreatmentConfig.packages,
        MultiTreatmentPackage.init(),
      ],
    });
    this._snackBar.open('Package added');
  }

  async deletePackage(packageUid: string): Promise<void> {
    const multiTreatmentConfig = await snapshot(this.multiTreatmentConfig$);
    await patchDoc(multiTreatmentConfig.ref, {
      packages: multiTreatmentConfig.packages.filter(
        (treatmentPackage) => treatmentPackage.uid !== packageUid
      ),
    });
    this._snackBar.open('Package removed');
  }

  async updatePackage(
    packageUid: string,
    packageData: MultiTreatmentPackageForm
  ): Promise<void> {
    const multiTreatmentConfig = await snapshot(this.multiTreatmentConfig$);
    await patchDoc(multiTreatmentConfig.ref, {
      packages: multiTreatmentConfig.packages.map((treatmentPackage) => {
        if (treatmentPackage.uid !== packageUid) {
          return treatmentPackage;
        }
        return {
          ...treatmentPackage,
          ...packageData,
        };
      }),
    });
    this._snackBar.open('Package updated');
  }
}
