import {
  ITranslationMap,
  type ICustomMapping,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentData,
  addDoc,
  asColRef,
  find$,
  patchDoc,
  query$,
  snapshot,
  where,
  type CollectionReference,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

export class TranslationMapHandler {
  constructor(
    private _translationMapCol: CollectionReference<ITranslationMap>
  ) {}

  findCustomMapping$(
    resourceMap: ICustomMapping
  ): Observable<WithRef<ITranslationMap> | undefined> {
    return find$(
      this._translationMapCol,
      where('type', '==', resourceMap.metadata.type)
    );
  }

  async add(translationMap: ITranslationMap): Promise<void> {
    await addDoc(this._translationMapCol, translationMap);
  }

  async upsert(translationMap: ITranslationMap): Promise<void> {
    await snapshot(
      find$(
        this._translationMapCol,
        where('sourceIdentifier', '==', translationMap.sourceIdentifier),
        where('resourceType', '==', translationMap.resourceType)
      ).pipe(
        concatMap((foundTranslationMap) => {
          if (!foundTranslationMap) {
            return this.add(translationMap);
          }
          return patchDoc(foundTranslationMap.ref, translationMap);
        })
      )
    );
  }

  getByType$<T extends object = object, R = unknown>(
    resourceType: string
  ): Observable<WithRef<ITranslationMap<T, R>>[]> {
    return query$(
      asColRef<ITranslationMap<T, R>>(this._translationMapCol),
      where('resourceType', '==', resourceType)
    );
  }

  getSource(
    destinationIdentifier: DocumentReference,
    resourceType: string
  ): Promise<string | undefined> {
    return snapshot(
      find$(
        this._translationMapCol,
        where('destinationIdentifier', '==', destinationIdentifier),
        where('resourceType', '==', resourceType)
      ).pipe(map((translationMap) => translationMap?.sourceIdentifier))
    );
  }

  async getDestination<T extends object = object, R = unknown>(
    sourceIdentifier: string,
    resourceType: string
  ): Promise<DocumentReference<T> | undefined> {
    const translationMap = await this.getBySource<T, R>(
      sourceIdentifier,
      resourceType
    );
    return translationMap?.destinationIdentifier;
  }

  getBySource<T extends object = object, R = unknown>(
    sourceIdentifier: string,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined> {
    return snapshot(
      find$(
        asColRef<ITranslationMap<T, R>>(this._translationMapCol),
        where('sourceIdentifier', '==', sourceIdentifier),
        where('resourceType', '==', resourceType)
      )
    );
  }

  getByDestination<T extends object = DocumentData, R = unknown>(
    destinationIdentifier: DocumentReference<T>,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined> {
    return snapshot(
      find$(
        asColRef<ITranslationMap<T, R>>(this._translationMapCol),
        where('destinationIdentifier', '==', destinationIdentifier),
        where('resourceType', '==', resourceType)
      )
    );
  }
}
