import { Tag } from '@principle-theorem/principle-core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  getDoc$,
  randomHexColour,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type CollectionReference } from '@principle-theorem/shared';

export class RandomTagFactory {
  constructor(private _collection: CollectionReference<ITag>) {}

  async create(name: string): Promise<WithRef<ITag>> {
    const newTag: ITag = this._randomNewTag(name);
    const docRef = await addDoc(this._collection, newTag);
    return snapshot(getDoc$(this._collection, docRef.id));
  }

  private _randomNewTag(name: string): ITag {
    return Tag.init({
      name,
      hexColour: randomHexColour(),
    });
  }
}
