<mat-toolbar color="accent">{{ data.title }} </mat-toolbar>

<mat-dialog-content>
  <pr-contact-form
    #contactForm
    [contact]="data.contact"
    (submitted)="submit($event)"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="contactForm.form.invalid"
      (click)="submit(contactForm.form.value)"
    >
      {{ data.submitLabel }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
