/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isEnumValue,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNil, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';

export const PATIENT_CLINICAL_NOTE_RESOURCE_TYPE = 'patientClincalNote';

export const PATIENT_CLINICAL_NOTE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Clinical Note List',
      description: '',
      idPrefix: PATIENT_CLINICAL_NOTE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

// export interface IPraktikaClinicalNotes {
//   appointment_id?: string;
//   patient_id: string;
//   patient_clinicalnotes: IPraktikaClinicalNote[];
//   patient_appointment_date: string | null;
//   patient_appointment_procedures: IPraktikaAppointmentProcedure[];
//   user_templates_macros: Record<string, IPraktikaTemplateMacro[]>;
//   user_templates_categories: IPraktikaUserTemplateCategories[];
//   user_templates_clinicalnotes: IPraktikaClinicalNoteTemplate[];
// }

// export interface IPraktikaClinicalNoteTemplate {
//   id: string;
//   oldid: string | null;
//   author: string;
//   type: PraktikaClinicalNoteType;
//   categoryid: string; // Ties to IPraktikaUserTemplateCategories
//   name: string;
//   text: string;
//   bOtherRead: boolean;
//   bOtherWrite: boolean;
//   bOwner: boolean;
// }

export enum PraktikaClinicalNoteType {
  Personal = 1,
  General = 2,
  Clinical = 3,
  Appointment = 4,
  Image = 5,
  Sms = 6,
  PhoneCall = 7,
  File = 8,
  Recall = 9,
  UserTemplate = 10,
  CommsEvent = 11,
  Email = 12,
}

export interface IPraktikaClinicalNote {
  id: string | number;
  prev_id: string | number | null;
  author_id: number | null;
  author_name: string;
  type: PraktikaClinicalNoteType;
  teeth: string | null; // '#24'
  text: string;
  appointment_id: number | null;
  patient_id: number;
  date_created: string; // '2018-12-10 19:07:18'
  date_deleted: string | null; // '2018-12-10 19:07:18'

  // date: string; // '2018-12-10 19:26:26'
  // draft: boolean;
  // editable: boolean;
  // oldid: string | null;
  // history: Omit<
  //   IPraktikaClinicalNote,
  //   'history' | 'dateCreated' | 'oldid'
  // >[];
}

function isPraktikaClinicalNote(item: unknown): item is IPraktikaClinicalNote {
  return (
    isObject(item) &&
    (isString(item.id) || isNumber(item.id)) &&
    isNumber(item.patient_id) &&
    isString(item.text) &&
    isEnumValue(PraktikaClinicalNoteType, item.type) &&
    (isString(item.teeth) || isNull(item.teeth)) &&
    (isNumber(item.appointment_id) || isNull(item.appointment_id)) &&
    (isString(item.date_created) || isNil(item.date_created)) &&
    (isString(item.prev_id) ||
      isNumber(item.prev_id) ||
      isNull(item.prev_id)) &&
    TypeGuard.nilOr(isNumber)(item.author_id) &&
    isString(item.author_name) &&
    isString(item.date_deleted)

    // (isString(item.oldid) || isNil(item.oldid)) &&
    // isString(item.date) &&
    // isBoolean(item.draft) &&
    // isBoolean(item.editable) &&
    // (isNil(item.history) ||
    //   TypeGuard.arrayOf(isPraktikaClinicalNote)(item.history))
  );
}

// export interface IPraktikaCombinedClinicalNoteAndTreatment {
//   patient_id: string;
//   appointment_id?: string;
//   treatments: IPraktikaAppointmentProcedure[];
//   note?: IPraktikaClinicalNote;
//   history: IPraktikaClinicalNote[];
// }

// function isPraktikaClinicalNoteAndTreatment(
//   item: unknown
// ): item is IPraktikaCombinedClinicalNoteAndTreatment {
//   return (
//     isObject(item) &&
//     isString(item.patient_id) &&
//     (isString(item.appointment_id) || isNil(item.appointment_id)) &&
//     (isPraktikaClinicalNote(item.note) || isNil(item.note)) &&
//     TypeGuard.arrayOf(isPraktikaAppointmentProcedure)(item.treatments) &&
//     TypeGuard.arrayOf(isPraktikaClinicalNote)(item.history)
//   );
// }

// export interface IPraktikaTemplateMacro {
//   label: string;
//   macro: string;
// }

// export interface IPraktikaUserTemplateCategories {
//   id: string | number;
//   oldid?: string | null;
//   name: string;
//   colour?: string | null; // hex colour
//   bDeleted?: boolean;
// }

export interface IPraktikaClinicalNoteTranslations {
  dateCreated: Timestamp;
  dateDeleted?: Timestamp;
}

export interface IPraktikaClinicalNoteFilters {
  dateCreated: Timestamp;
  patientId: string;
  appointmentId?: string;
}

const PATIENT_CLINICAL_NOTE_SOURCE_QUERY = `
SELECT
  iPatientNumber as patient_id,
  iNoteId as id,
  iNoteTypeId as type,
  vchNote as text,
  vchRelatedTeeth as teeth,
  iAppointmentId as appointment_id,
  dtCreated as date_created,
  iCreatedByStaffId as author_id,
  vchStaffName as author_name,
  dtDeleted as date_deleted,
  iPrevNoteVersionId as prev_id
FROM patient_clinical_notes
ORDER BY id DESC
`;

export class PatientClinicalNoteSourceEntity extends BaseSourceEntity<
  IPraktikaClinicalNote,
  IPraktikaClinicalNoteTranslations,
  IPraktikaClinicalNoteFilters
> {
  sourceEntity = PATIENT_CLINICAL_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_CLINICAL_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_CLINICAL_NOTE_SOURCE_QUERY;
  verifySourceFn = isPraktikaClinicalNote;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaClinicalNoteFilters = 'dateCreated';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    clinicalNote: IPraktikaClinicalNote,
    timezone: Timezone
  ): IPraktikaClinicalNoteTranslations {
    return {
      dateCreated: toTimestamp(
        moment.tz(clinicalNote.date_created, ISO_DATE_TIME_FORMAT, timezone)
      ),
      dateDeleted: clinicalNote.date_deleted
        ? toTimestamp(
            moment.tz(clinicalNote.date_deleted, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
    };
  }

  getSourceRecordId(data: IPraktikaClinicalNote): string {
    return data.id.toString();
  }

  getSourceLabel(data: IPraktikaClinicalNote): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaClinicalNote,
    timezone: Timezone
  ): IPraktikaClinicalNoteFilters {
    return {
      dateCreated: toTimestamp(
        moment.tz(data.date_created, ISO_DATE_TIME_FORMAT, timezone)
      ),
      patientId: data.patient_id.toString(),
      appointmentId: data.appointment_id?.toString(),
    };
  }
}
