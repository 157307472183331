import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  ManualTransactionType,
  RefundHelpers,
  Transaction,
  invoiceBalance,
  toINamedDocuments,
  withTransactions$,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IInvoice,
  type IManualExtendedData,
  type IManualTransactionType,
  type IPractice,
  type ITransaction,
  InvoiceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  mergeDayAndTime,
  snapshot,
  toTimestamp,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Observable, Subject, of } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AmendTransactionDateStore } from '../transaction-components/transaction-edit-dialog/amend-transaction-date.store';
import {
  TransactionPracticeOptionMethods,
  TransactionPracticeOptions,
} from '../transaction-components/transaction-practice-options';
import { determineNewCreatedAtDate } from '../transaction-helpers';

interface ITransactionFormData
  extends Pick<
    ITransaction,
    'type' | 'status' | 'amount' | 'to' | 'from' | 'practiceRef'
  > {
  description: string;
  transactionType: INamedDocument<IManualTransactionType>;
  dateReceived?: Moment;
}

class ManualTransactionForm extends TypedFormGroup<ITransactionFormData> {
  constructor() {
    super({
      type: new TypedFormControl<TransactionType>(TransactionType.Incoming),
      status: new TypedFormControl<TransactionStatus>(
        TransactionStatus.Complete
      ),
      from: new TypedFormControl<string>(),
      to: new TypedFormControl<string>(),
      amount: new TypedFormControl<number>(),
      description: new TypedFormControl<string>(),
      transactionType: new TypedFormControl<
        INamedDocument<IManualTransactionType>
      >(),
      dateReceived: new TypedFormControl<Moment>(moment()),
      practiceRef: new TypedFormControl<DocumentReference<IPractice>>(),
    });
  }
}

export interface IManualTransactionData {
  invoice: WithRef<IInvoice>;
  transaction: Partial<ITransactionFormData>;
  isRefund?: boolean;
  disableAmount?: boolean;
}

@Component({
  selector: 'pr-manual-transaction',
  templateUrl: './manual-transaction.component.html',
  styleUrls: ['./manual-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
})
export class ManualTransactionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByStatus = TrackByFunctions.variable<TransactionStatus>();
  trackByType = TrackByFunctions.variable<TransactionType>();
  trackByTransactionType = TrackByFunctions.ref<IManualTransactionType>();
  form = new ManualTransactionForm();
  types: TransactionType[] = [
    TransactionType.Incoming,
    TransactionType.Outgoing,
  ];
  statuses: TransactionStatus[] = [
    TransactionStatus.Pending,
    TransactionStatus.Complete,
  ];
  maxAmount$: Observable<number>;
  transactionTypes$: Observable<INamedDocument<IManualTransactionType>[]>;
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  practiceOptions: TransactionPracticeOptions;
  isOutgoing: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<
      ManualTransactionComponent,
      ITransaction<IManualExtendedData>
    >,
    @Inject(MAT_DIALOG_DATA) private _data: IManualTransactionData,
    private _currentScope: CurrentScopeFacade,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.transactionTypes$ = this._currentScope.currentBrand$.pipe(
      filterUndefined(),
      switchMap((brand) => ManualTransactionType.all$(brand)),
      toINamedDocuments()
    );

    this.form.patchValue(this._data.transaction);

    if (this._data.disableAmount) {
      this.form.controls.amount.disable();
    }

    this.isOutgoing = this._isOutgoing();
    this.form.controls.type.setValue(
      this.isOutgoing ? TransactionType.Outgoing : TransactionType.Incoming
    );

    this.maxAmount$ = this._getMaxAmount$(this._data);
    this.maxAmount$.pipe(takeUntil(this._onDestroy$)).subscribe((maxAmount) => {
      const amountControl = this.form.controls.amount;
      const currentValue = amountControl.value ?? maxAmount;
      if (currentValue > maxAmount) {
        amountControl.setValue(maxAmount);
      }
      amountControl.setValidators([
        Validators.required,
        Validators.max(maxAmount),
      ]);
    });

    this.practiceOptions = new TransactionPracticeOptions(
      this._currentScope.currentPractice$,
      TransactionPracticeOptionMethods.toInvoicePracticeOption(
        this._data.invoice
      ),
      true
    );
    this.practiceOptions.initialValue$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.form.controls.practiceRef.setValue(practice.ref)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const value = this.form.getRawValue();
    if (value.amount <= 0) {
      return;
    }

    const dateReceived = value.dateReceived
      ? await determineNewCreatedAtDate(
          {
            createdAt: toTimestamp(),
            practiceRef: this._data.invoice.practice.ref,
          },
          mergeDayAndTime(value.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : moment();

    const transaction = Transaction.init({
      ...omit(value, 'description', 'transactionType', 'dateReceived'),
      ...Transaction.internalReference(TransactionProvider.Manual),
      description: value.description ?? undefined,
      extendedData: {
        transactionType: value.transactionType,
      },
      practiceRef: value.practiceRef,
      createdAt: toTimestamp(dateReceived),
    });
    this._dialogRef.close(transaction);
  }

  private _getMaxAmount$(data: IManualTransactionData): Observable<number> {
    return this.form.controls.type.valueChanges.pipe(
      startWith(this.form.controls.type.value),
      switchMap((type) => {
        if (type === TransactionType.Incoming) {
          return invoiceBalance(withTransactions$(data.invoice));
        }
        if (type === TransactionType.Outgoing) {
          if (data.invoice.type === InvoiceType.CreditNote) {
            return invoiceBalance(withTransactions$(data.invoice));
          }
          return Invoice.transactions$(data.invoice).pipe(
            map((transactions) =>
              RefundHelpers.getTotalRefundRemaining(transactions)
            )
          );
        }
        return of(0);
      })
    );
  }

  private _isOutgoing(): boolean {
    return (
      this._data.isRefund || this._data.invoice.status === InvoiceStatus.Paid
    );
  }
}
