import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { VistaSoftFeatureService } from './vistasoft-feature.service';
import { VistaSoftOpenPatientComponent } from './vistasoft-open-patient/vistasoft-open-patient.component';

@NgModule({
  declarations: [VistaSoftOpenPatientComponent],
  imports: [NgSharedModule],
  providers: [VistaSoftFeatureService],
})
export class VistaSoftModule {}
