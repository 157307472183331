import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDrawer, type MatDrawerMode } from '@angular/material/sidenav';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { type ComponentLoader } from '../dynamic-component/dynamic-component.component';
import {
  DynamicSidebarService,
  type SidebarCloseCallbackFn,
} from './dynamic-sidebar.service';

export interface ISidebarComponentData {
  saveFn?: () => Promise<void> | void;
}

@Component({
  selector: 'pt-dynamic-sidebar',
  templateUrl: './dynamic-sidebar.component.html',
  styleUrls: ['./dynamic-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicSidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _closeFn?: SidebarCloseCallbackFn;
  collapsed$ = new BehaviorSubject<boolean>(false);
  mode: MatDrawerMode = 'over';
  position: 'start' | 'end' = 'end';
  autoFocus = 'true';
  componentDefinition$ = new BehaviorSubject<
    ComponentLoader<unknown, ISidebarComponentData> | undefined
  >(undefined);
  @ViewChild(MatDrawer, { static: true }) drawer?: MatDrawer;

  constructor(public sidebarService: DynamicSidebarService) {
    this.sidebarService.open$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((config) => {
        this._closeFn = config.close;
        this.componentDefinition$.next({
          component: config.component,
          data: config.config?.data,
        });
        void this.drawer?.open();
        this.collapsed$.next(false);
      });

    this.sidebarService.isOpen$
      .pipe(
        filter((opened) => !opened),
        takeUntil(this._onDestroy$)
      )
      .subscribe((_config) => {
        this.componentDefinition$.next(undefined);
        void this.drawer?.close();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async close(): Promise<void> {
    if (this._closeFn) {
      await this._closeFn();
      this._closeFn = undefined;
    }
    this.componentDefinition$.next(undefined);
    void this.drawer?.close();
  }

  collapse(): void {
    this.collapsed$.next(true);
  }

  expand(): void {
    this.collapsed$.next(false);
  }
}
