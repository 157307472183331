<div
  class="flex"
  [style.float]="dataFloat$ | ngrxPush"
  [ngClass]="{
    'justify-start': (dataAlign$ | ngrxPush) === 'left',
    'justify-center': (dataAlign$ | ngrxPush) === 'center',
    'justify-end': (dataAlign$ | ngrxPush) === 'right'
  }"
>
  <pt-drag-resize
    *ngIf="(editable$ | async) && (canResize$ | async); else image"
    class="max-w-full"
    [disabled]="(selected$ | async) === false"
    [loading]="loading$ | async"
    (resizeUpdate)="updateSize($event)"
    (resizeEnd)="resizeEnd()"
    #resize
  >
    <div
      class="max-w-full cursor-pointer"
      [style.width]="renderWidth$ | ngrxPush"
      [style.height]="renderHeight$ | ngrxPush"
      [ngClass]="{ 'fit-to-width': fitToWidth$ | ngrxPush }"
    >
      <img
        *ngIf="imageUrl$ | async as url"
        priority
        fill
        class="!relative"
        [attr.storage-path]="storagePath"
        [ngSrc]="url"
        [alt]="alt || ''"
        [title]="title || ''"
        (load)="loading$.next(false)"
      />
    </div>
  </pt-drag-resize>
</div>

<ng-template #image>
  <div
    class="cursor-pointer"
    [style.width]="renderWidth$ | ngrxPush"
    [style.height]="renderHeight$ | ngrxPush"
    [ngClass]="{
      'fit-to-width': fitToWidth$ | ngrxPush,
      'cursor-zoom-in': (editable$ | ngrxPush) === false
    }"
  >
    <img
      *ngIf="imageUrl$ | async as imageUrl"
      priority
      fill
      class="!relative"
      [attr.storage-path]="storagePath"
      [ngSrc]="imageUrl"
      [alt]="alt || ''"
      [title]="title || ''"
      (click)="openImage()"
      (load)="loading$.next(false)"
    />
  </div>
</ng-template>
