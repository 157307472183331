@if (automation$ | async; as automation) {
  <pr-automation-icon [automation]="automation" />

  <div class="flex shrink grow overflow-hidden">
    <pr-automation-label [automation]="automation" />
  </div>

  @if (assignment$ | async; as assignment) {
    <pr-icon-chip
      [icon]="assignment.isForTeam ? 'groups' : 'person'"
      matTooltip="Task Assignee"
    >
      {{ assignment.assignee }}
    </pr-icon-chip>
  }

  <pr-icon-chip icon="schedule" matTooltip="Automation Timing">
    {{ automation.timing | prTimingDisplay }}
  </pr-icon-chip>

  @if (automation.isActive) {
    <pr-icon-chip icon="check_circle" iconClass="text-primary-500">
      Enabled
    </pr-icon-chip>
  } @else {
    <pr-icon-chip icon="check_circle" iconClass="text-slate-300">
      Disabled
    </pr-icon-chip>
  }

  <ng-content />
}
