import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { MultiTreatmentConfiguration } from '@principle-theorem/principle-core';
import { type IMultiTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, getDoc$ } from '@principle-theorem/shared';
import { merge, type Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable()
export class MultiTreatmentConfigResolverService extends AbstractResolver<IMultiTreatmentConfiguration> {
  constructor(
    router: Router,
    private _organisation: OrganisationService,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    super(router);
  }

  getItemById(uid: string): Observable<IMultiTreatmentConfiguration> {
    const brandMultiTreatment$ = this._currentScopeFacade.currentBrand$.pipe(
      filterUndefined(),
      switchMap((brand) =>
        getDoc$(MultiTreatmentConfiguration.col(brand), uid)
      ),
      catchError(() => of(undefined)),
      filterUndefined()
    );

    const stafferMultiTreatment$ = this._organisation.staffer$.pipe(
      filterUndefined(),
      switchMap((staffer) =>
        getDoc$(MultiTreatmentConfiguration.col(staffer), uid)
      ),
      catchError(() => of(undefined)),
      filterUndefined()
    );

    return merge(brandMultiTreatment$, stafferMultiTreatment$).pipe(take(1));
  }
}
