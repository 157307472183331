<ng-container *ngIf="transaction$ | ngrxPush as transaction">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div
      *ngIf="status$ | async as status"
      class="status"
      [ngClass]="status.status"
    >
      {{ status.status | uppercase }}
    </div>

    <div class="provider-id mat-caption">
      <div class="provider">
        {{ transaction.provider | splitCamel | titlecase }}
        <ng-container *ngIf="isRefund$ | async">Refund</ng-container>
      </div>
      <div class="id">{{ transaction.reference }}</div>
    </div>

    <div class="date mat-caption" fxFlex>
      {{ transaction.createdAt | moment | amDateFormat: dateFormat }}

      <ng-container *ngIf="(readonly$ | async) === false">
        <button
          *ngIf="hasHistory$ | async"
          mat-icon-button
          matTooltip="View transaction history"
          (click)="action.viewHistory(transaction)"
        >
          <mat-icon>history</mat-icon>
        </button>
      </ng-container>
    </div>

    <ng-content />

    <div class="price-actions" fxLayout="row" fxLayoutAlign="end center">
      <span class="amount" [class]="{ outgoing: isOutgoing$ | async }">
        <ng-container *ngIf="isOutgoing$ | async">-</ng-container>
        {{ transaction.amount | currency }}
      </span>

      <ng-content select=".actions" />

      <div class="actions" *ngIf="(readonly$ | async) === false">
        <ng-container *ngIf="actions$ | async as actions">
          <button
            mat-icon-button
            *ngIf="actions.length"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu" yPosition="above" class="no-padding">
            <ng-template matMenuContent>
              <pr-transaction-menu-actions
                [data]="actionsData$ | async"
                [actions]="actions"
              />
            </ng-template>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="extended-data-container" *ngIf="hasExtendedData$ | async">
    <div class="extended-data-inner">
      <span class="description mat-caption" *ngIf="transaction.description">
        {{ transaction.description }}
      </span>
      <pt-dynamic-component
        *ngIf="extendedDataComponent$ | async as extendedDataComponent"
        [definition]="extendedDataComponent"
      />
    </div>
  </div>
</ng-container>
