import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixedSchema, initVersionedSchema } from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type INotable,
  type INote,
} from '@principle-theorem/principle-core/interfaces';
import {
  patchDoc,
  toMoment,
  toTimestamp,
  type IReffable,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import * as moment from 'moment-timezone';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type FormData = Pick<INote, 'content'>;

@Component({
  selector: 'pr-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditNoteComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  form = new TypedFormGroup<FormData>({
    content: new TypedFormControl<MixedSchema>(
      initVersionedSchema(),
      Validators.required
    ),
  });
  editMode = false;
  note$ = new ReplaySubject<INote>(1);

  content$ = new ReplaySubject<MixedSchema>(1);
  @HostBinding('class.touched') touched = false;
  @HostBinding('class.pinned-note') pinned = false;

  @ViewChild('editNote', { read: ElementRef })
  set editNote(editNote: ElementRef<HTMLElement> | undefined) {
    if (editNote) {
      setTimeout(() => editNote.nativeElement.focus());
    }
  }

  @Input() resource: INotable & IReffable;

  @Input()
  set note(note: INote) {
    if (!note) {
      return;
    }
    this.note$.next(note);
    this.pinned = note.pinned;
    this.content$.next(note.content);
  }

  constructor(private _snackBar: MatSnackBar) {
    this.note$.pipe(takeUntil(this._onDestroy$)).subscribe((note) => {
      this._updateFormData(note);
      if (toMoment(note.createdAt).isAfter(moment().subtract(1, 'seconds'))) {
        this.touch();
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  reset(note: INote): void {
    this._updateFormData(note);
    this.editMode = false;
  }

  edit(): void {
    this.editMode = true;
  }

  async togglePin(updatedNote: INote): Promise<void> {
    this.resource.notes = this.resource.notes.map((note) => {
      if (updatedNote.uid !== note.uid) {
        return note;
      }
      note.pinned = !note.pinned;
      return note;
    });
    await this._save({
      notes: this.resource.notes,
      ref: this.resource.ref,
    });
    const message: string = updatedNote.pinned
      ? 'Note pinned'
      : 'Note unpinned';
    this._snackBar.open(message);
  }

  async save(updatedNote: INote): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    updatedNote = {
      ...updatedNote,
      ...this.form.getRawValue(),
      updatedAt: toTimestamp(),
    };

    this.resource.notes = this.resource.notes.map((note) => {
      if (updatedNote.uid !== note.uid) {
        return note;
      }
      return updatedNote;
    });
    await this._save({
      notes: this.resource.notes,
      ref: this.resource.ref,
    });
    this.reset(updatedNote);
    this._snackBar.open('Note updated');
  }

  async delete(note: INote): Promise<void> {
    const index: number = this.resource.notes.indexOf(note);
    this.resource.notes.splice(index, 1);
    await patchDoc(this.resource.ref, {
      notes: this.resource.notes,
    });
    this._snackBar.open('Note deleted');
  }

  touch(): void {
    this.touched = true;
    setTimeout(() => {
      this.touched = false;
    }, 1000);
  }

  private _updateFormData(note: INote): void {
    this.form.patchValue(note);
  }

  private async _save(changes: INotable & IReffable): Promise<void> {
    this.touch();
    await patchDoc(changes.ref, omit(changes, 'ref'));
  }
}
