import {
  ChartableSurface,
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  TypeGuard,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
  ISODateType,
  toISODate,
} from '@principle-theorem/shared';
import {
  flow,
  groupBy,
  isBoolean,
  isNil,
  isNull,
  isNumber,
  isString,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { D4WPatientTreatmentPlanStatus } from './patient-chart';

export const PATIENT_TREATMENT_RESOURCE_TYPE = 'patientTreatment';

export const PATIENT_TREATMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Treatment List',
      description: '',
      idPrefix: PATIENT_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export const TOOTH_SURFACES = ['M', 'O', 'I', 'D', 'La', 'B', 'L', 'P'];

export enum D4WToothSurface {
  Occlusal = 'O',
  Buccal = 'B',
  Lingual = 'L',
  Mesial = 'M',
  Distal = 'D',
  Labial = 'La',
  Palatal = 'P',
  Incisal = 'I',
}

export const TOOTH_SURFACES_MAP: Partial<
  Record<D4WToothSurface, ChartableSurface>
> = {
  M: ChartableSurface.Mesial,
  O: ChartableSurface.Occlusal,
  I: ChartableSurface.Occlusal,
  D: ChartableSurface.Distal,
  B: ChartableSurface.Facial,
  La: ChartableSurface.Facial,
  L: ChartableSurface.Lingual,
  P: ChartableSurface.Lingual,
};

export function convertToToothSurfaces(item: string): D4WToothSurface[] {
  if (!item) {
    return [];
  }
  const surfaces = item.match(/([A-Z]?[^A-Z]*)/g)?.slice(0, -1) ?? [];
  const allValidSurfaces = surfaces.every((surface) =>
    TOOTH_SURFACES.includes(surface)
  );
  if (!allValidSurfaces) {
    throw new Error(`Invalid tooth surfaces: ${surfaces.join(', ')}`);
  }
  return surfaces as D4WToothSurface[];
}

export interface ID4WPatientTreatment {
  id: number;
  date: string | null;
  times: number;
  tooth_ref: string | number | null;
  tooth_surface: D4WToothSurface[];
  is_baby_tooth: boolean;
  fee: string;
  rebate_amount: string | null;
  provider_id: number;
  account_id: number | null;
  patient_id: number;
  practice_id: number;

  /**
   * Part paid represented as 'H'. A partial paid will be tracked against all of the treatments from the "account_payment_plan" table
   */
  paid_status: 'Y' | 'N' | 'H';
  item_id: number;
  item_code: string;
  item_code_description: string;

  chart_id: number | null;
  chart_layer: number | null;
  plan_created_at: string | null;
  plan_name?: string | null;
  plan_provider_id: number | null;
  plan_active: boolean | null;
  plan_status_id?: D4WPatientTreatmentPlanStatus | null;

  /**
   * Denotes the treatment as deleted
   */
  ref_number: string | number | null;

  /**
   * D represents a deleted treatment
   */
  ref_status: 'D' | null;
  treat_area_id: number | null;
  treat_zones_list: string | null;
  plan_date: string | null;
  plan_times: number;

  /**
   * Proposed fee for treatment
   */
  plan_fee: string;
  time_estimate: string | number | null;
  time_allocation: number | null;
  plan_stage_no: string | number | null;
  plan_visit_no: string | number | null;
  lab_work_id: number | null;

  payment_plans: ID4WPatientTreatmentPaymentPlanItem[];
}

export function isD4WPatientTreatment(
  item: unknown
): item is ID4WPatientTreatment {
  return TypeGuard.interface<ID4WPatientTreatment>({
    id: isNumber,
    date: [isString, isNull],
    times: isNumber,
    tooth_ref: [isString, isNumber, isNull],
    tooth_surface: TypeGuard.arrayOf(TypeGuard.enumValue(D4WToothSurface)),
    is_baby_tooth: isBoolean,
    fee: isString,
    rebate_amount: [isString, isNull],
    provider_id: isNumber,
    account_id: [isNumber, isNull],
    patient_id: isNumber,
    practice_id: isNumber,
    item_id: isNumber,
    item_code: isString,
    item_code_description: isString,
    paid_status: (status): status is 'Y' | 'N' | 'H' =>
      ['Y', 'N', 'H'].includes(String(status)),
    chart_id: [isNumber, isNull],
    chart_layer: [isNumber, isNull],
    plan_created_at: [isString, isNull],
    plan_name: TypeGuard.nilOr(isString),
    plan_provider_id: [isNumber, isNull],
    plan_active: [isBoolean, isNull],
    plan_status_id: [TypeGuard.enumValue(D4WPatientTreatmentPlanStatus), isNil],
    ref_number: [isString, isNumber, isNull],
    ref_status: [isString, isNull],
    treat_area_id: [isNumber, isNull],
    treat_zones_list: [isString, isNull],
    plan_date: [isString, isNull],
    plan_times: isNumber,
    plan_fee: isString,
    time_estimate: [isString, isNumber, isNull],
    time_allocation: [isNumber, isNull],
    plan_stage_no: [isString, isNumber, isNull],
    plan_visit_no: [isString, isNumber, isNull],
    lab_work_id: [isNumber, isNull],
    payment_plans: TypeGuard.arrayOf(isD4WPatientTreatmentPaymentPlanItem),
  })(item);
}

export interface ID4WPatientTreatmentPaymentPlanItem {
  payment_plan_id: number | null;
  payment_plan_item_id: number | null;
  treatment_amount_paid: string | null;
  fee_level_id: number | null;
}

export function isD4WPatientTreatmentPaymentPlanItem(
  item: unknown
): item is ID4WPatientTreatmentPaymentPlanItem {
  return (
    isObject(item) &&
    (isString(item.treatment_amount_paid) ||
      isNull(item.treatment_amount_paid)) &&
    (isNumber(item.payment_plan_id) || isNull(item.payment_plan_id)) &&
    (isNumber(item.payment_plan_item_id) ||
      isNull(item.payment_plan_item_id)) &&
    (isNumber(item.fee_level_id) || isNull(item.fee_level_id))
  );
}

export interface ID4WPatientTreatmentQuery
  extends Omit<ID4WPatientTreatment, 'tooth_surface'>,
    ID4WPatientTreatmentPaymentPlanItem {
  tooth_surface: string | null;
}

export interface ID4WPatientTreatmentTranslations {
  date?: ISODateType;
  planDate?: Timestamp;
  planCreatedAt?: Timestamp;
}

export interface ID4WPatientTreatmentFilters {
  providerId: string;
  patientId: string;
  practiceId: string;
  itemId: string;
  chartId?: string;
  accountId?: string;
  date?: ISODateType;
  planDate?: Timestamp;
  planCreatedAt?: Timestamp;
}

const PATIENT_TREATMENT_QUERY = `SELECT
  chart.plan_created_at,
  chart.plan_name,
  chart.plan_provider_id,
  chart.plan_active,
  chart.plan_status_id,
  item_codes.item_code AS item_code,
  item_codes.description AS item_code_description,
  treatment.*,
  account.id AS account_id,
  payment_plan.id AS payment_plan_id,
  payment_plan_item.id AS payment_plan_item_id,
  payment_plan_item.fee_level_id AS fee_level_id,
  payment_plan_item.treatment_amount_paid
FROM (
  SELECT
    treat_id AS id,
    pay_or_not AS paid_status,
    fee,
    rebate AS rebate_amount,
    provider_id,
    account_id,
    patient_id,
    practice_id,
    item_id,
    chart_id,
    treat_date as date,
    times,
    tooth AS tooth_ref,
    NULLIF(surface, '') AS tooth_surface,
    convert_to_boolean(baby) AS is_baby_tooth,
    chart_layer,
    ref_number,
    NULLIF(ref_status, '') AS ref_status,
    treat_area_id,
    NULLIF(treat_zones_list, '') AS treat_zones_list,
    NULLIF(plan_date, '') AS plan_date,
    plan_times,
    plan_fee,
    time_estimate,
    convert_to_integer(time_allocation) AS time_allocation,
    plan_stage_no,
    plan_visit_no,
    convert_to_integer(lab_work_id) AS lab_work_id
  FROM treat
  WHERE
    ref_status != 'D'
  ORDER BY treat_id
  ${OFFSET_PLACEHOLDER}
) AS treatment
LEFT JOIN (
  SELECT
    id,
    CONCAT(date_created, ' ',time_created) AS created_at,
    total,
    number_of_instalments AS number_of_installments,
    over_period_of,
    period_type,
    rebate,
    amount_paid AS invoice_amount_paid,
    convert_to_integer(claim_no) as claim_id
  FROM patients_accounts
  WHERE
    ref_status != 'D'
) AS account
ON account.id = treatment.account_id
LEFT JOIN (
  SELECT
    plan_items_id AS id,
    account_payment_plan_id AS payment_plan_id,
    treat_id AS treatment_id,
    fee_level_id,
    amount AS treatment_amount_paid
  FROM account_payment_plan_items
) AS payment_plan_item
ON treatment.id = payment_plan_item.treatment_id
LEFT JOIN (
  SELECT
    account_payment_plan_id AS id,
    patient_account_id AS account_id
  FROM account_payment_plan
  WHERE ref_status != 'D'
) AS payment_plan
ON payment_plan_item.payment_plan_id = payment_plan.id
INNER JOIN (
  SELECT
    item_id,
    item AS item_code,
    description
    FROM procedures
) AS item_codes
ON treatment.item_id = item_codes.item_id
LEFT JOIN (
  SELECT
    chart_id,
    chart_date_time as plan_created_at,
    tr_plan_label AS plan_name,
    provider_id AS plan_provider_id,
    NULLIF(ref_status, '') AS ref_status,
    convert_to_boolean(active) AS plan_active,
    status_id AS plan_status_id,
    changed_status_date AS status_updated_at
  FROM charting
) AS chart
ON chart.chart_id = treatment.chart_id
ORDER BY treatment.id, treatment.patient_id, treatment.chart_id
`;

const PATIENT_TREATMENT_QUERY_V2 = `SELECT
  chart.plan_created_at,
  chart.plan_provider_id,
  chart.plan_active,
  item_codes.item_code AS item_code,
  item_codes.description AS item_code_description,
  treatment.*,
  account.id AS account_id,
  payment_plan.id AS payment_plan_id,
  payment_plan_item.id AS payment_plan_item_id,
  payment_plan_item.fee_level_id AS fee_level_id,
  payment_plan_item.treatment_amount_paid
FROM (
  SELECT
    treat_id AS id,
    pay_or_not AS paid_status,
    fee,
    rebate AS rebate_amount,
    provider_id,
    account_id,
    patient_id,
    practice_id,
    item_id,
    chart_id,
    treat_date as date,
    times,
    tooth AS tooth_ref,
    NULLIF(surface, '') AS tooth_surface,
    convert_to_boolean(baby) AS is_baby_tooth,
    chart_layer,
    ref_number,
    NULLIF(ref_status, '') AS ref_status,
    treat_area_id,
    NULLIF(treat_zones_list, '') AS treat_zones_list,
    NULLIF(plan_date, '') AS plan_date,
    plan_times,
    plan_fee,
    time_estimate,
    convert_to_integer(time_allocation) AS time_allocation,
    plan_stage_no,
    plan_visit_no,
    convert_to_integer(lab_work_id) AS lab_work_id
  FROM treat
  WHERE
    ref_status != 'D'
  ORDER BY treat_id
  ${OFFSET_PLACEHOLDER}
) AS treatment
LEFT JOIN (
  SELECT
    id,
    CONCAT(date_created, ' ',time_created) AS created_at,
    total,
    number_of_instalments AS number_of_installments,
    over_period_of,
    period_type,
    rebate,
    amount_paid AS invoice_amount_paid,
    convert_to_integer(claim_no) as claim_id
  FROM patients_accounts
  WHERE
    ref_status != 'D'
) AS account
ON account.id = treatment.account_id
LEFT JOIN (
  SELECT
    plan_items_id AS id,
    account_payment_plan_id AS payment_plan_id,
    treat_id AS treatment_id,
    fee_level_id,
    amount AS treatment_amount_paid
  FROM account_payment_plan_items
) AS payment_plan_item
ON treatment.id = payment_plan_item.treatment_id
LEFT JOIN (
  SELECT
    account_payment_plan_id AS id,
    patient_account_id AS account_id
  FROM account_payment_plan
  WHERE ref_status != 'D'
) AS payment_plan
ON payment_plan_item.payment_plan_id = payment_plan.id
INNER JOIN (
  SELECT
    item_id,
    item AS item_code,
    description
    FROM procedures
) AS item_codes
ON treatment.item_id = item_codes.item_id
LEFT JOIN (
  SELECT
    chart_id,
    chart_date_time as plan_created_at,
    provider_id AS plan_provider_id,
    NULLIF(ref_status, '') AS ref_status,
    convert_to_boolean(active) AS plan_active
  FROM charting
) AS chart
ON chart.chart_id = treatment.chart_id
ORDER BY treatment.id, treatment.patient_id, treatment.chart_id
`;

const PATIENT_TREATMENT_ESTIMATE_QUERY = `SELECT
  treatment.id
FROM (
  SELECT
    treat_id AS id,
    item_id
  FROM treat
  WHERE
    ref_status != 'D'
) AS treatment
INNER JOIN (
  SELECT
    item_id
    FROM procedures
) AS item_codes
ON treatment.item_id = item_codes.item_id
`;

export class PatientTreatmentSourceEntity extends BaseSourceEntity<
  ID4WPatientTreatment,
  ID4WPatientTreatmentTranslations,
  ID4WPatientTreatmentFilters
> {
  sourceEntity = PATIENT_TREATMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_RESOURCE_TYPE;
  sourceQuery = [PATIENT_TREATMENT_QUERY, PATIENT_TREATMENT_QUERY_V2];
  allowOffsetJob = true;
  verifySourceFn = isD4WPatientTreatment;
  override defaultOffsetSize = 50000;
  override dateFilterField: keyof ID4WPatientTreatmentFilters = 'date';
  override transformDataFn = flow([transformPatientTreatmentResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      PATIENT_TREATMENT_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (item) => `${item.id}`)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WPatientTreatment,
    timezone: Timezone
  ): ID4WPatientTreatmentTranslations {
    return {
      date: data.date
        ? toISODate(moment.tz(data.date, ISO_DATE_FORMAT, timezone))
        : undefined,
      planDate: data.plan_date
        ? toTimestamp(moment.tz(data.plan_date, ISO_DATE_FORMAT, timezone))
        : undefined,
      planCreatedAt: data.plan_created_at
        ? toTimestamp(
            moment.tz(data.plan_created_at, ISO_DATE_FORMAT, timezone)
          )
        : undefined,
    };
  }

  getSourceRecordId(data: ID4WPatientTreatment): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientTreatment): string {
    return `${data.id} ${data.patient_id} ${data.chart_id || ''}`;
  }

  getFilterData(
    data: ID4WPatientTreatment,
    timezone: Timezone
  ): ID4WPatientTreatmentFilters {
    return {
      patientId: data.patient_id.toString(),
      practiceId: data.practice_id.toString(),
      providerId: data.provider_id.toString(),
      itemId: data.item_id.toString(),
      chartId: data.chart_id ? data.chart_id.toString() : undefined,
      accountId: data.account_id ? data.account_id.toString() : undefined,
      date: data.date
        ? toISODate(moment.tz(data.date, ISO_DATE_FORMAT, timezone))
        : undefined,
      planDate: data.plan_date
        ? toTimestamp(moment.tz(data.plan_date, ISO_DATE_FORMAT, timezone))
        : undefined,
      planCreatedAt: data.plan_created_at
        ? toTimestamp(
            moment.tz(data.plan_created_at, ISO_DATE_FORMAT, timezone)
          )
        : undefined,
    };
  }
}

function transformPatientTreatmentResults(
  rows: ID4WPatientTreatmentQuery[]
): ID4WPatientTreatment[] {
  const treatmentGroups = groupBy(rows, (item) => `${item.id}`);

  return Object.values(treatmentGroups)
    .map((treatmentGroup) => {
      const treatment = treatmentGroup[0];
      return {
        ...treatment,
        payment_plans: treatmentGroup.map((treatmentItem) => ({
          payment_plan_id: treatmentItem.payment_plan_id,
          payment_plan_item_id: treatmentItem.payment_plan_item_id,
          treatment_amount_paid: treatmentItem.treatment_amount_paid,
          fee_level_id: treatmentItem.fee_level_id,
        })),
      };
    })
    .map((row) => {
      try {
        if (row.tooth_surface) {
          return {
            ...row,
            tooth_surface: convertToToothSurfaces(row.tooth_surface),
          };
        }
      } catch (error) {
        //
      }
      return {
        ...row,
        tooth_surface: [],
      };
    })
    .map(cleanObjectStrings);
}
