import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';
import { type WithRef } from '@principle-theorem/shared';
import { type ITreatmentPlan } from '@principle-theorem/principle-core/interfaces';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentTreatmentPlanScope(route),
  deps: [Router],
})
export class CurrentTreatmentPlanScope extends BaseCurrentFirestoreModel<
  WithRef<ITreatmentPlan>
> {
  protected _propertyAccessor(data: Data): WithRef<ITreatmentPlan> | undefined {
    if (data.treatmentPlan) {
      return data.treatmentPlan as WithRef<ITreatmentPlan>;
    }
  }
}
