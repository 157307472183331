/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';

export interface IPraktikaToothDentition {
  patient_id: number;
  id: number;
  tooth_number: number;
  type_id: PraktikaToothType;
  created_date: string; // '2020-12-16 14:26:04'
}

export enum PraktikaToothType {
  Permanent = 1,
  Primary = 2,
  Missing = 3,
  Implant = 4,
  Pontic = 5,
  Denture = 6,
  ImplantDenture = 7,
  Supernumerary = 8,
}

export function isPraktikaToothDentition(
  item: unknown
): item is IPraktikaToothDentition {
  return TypeGuard.interface<IPraktikaToothDentition>({
    patient_id: isNumber,
    id: isNumber,
    type_id: TypeGuard.enumValue(PraktikaToothType),
    tooth_number: isNumber,
    created_date: isString,
  })(item);
}

export const PATIENT_TOOTH_DENTITION_RESOURCE_TYPE = 'patientToothDentition';

export const PATIENT_TOOTH_DENTITION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Tooth Dentition List',
      description: '',
      idPrefix: PATIENT_TOOTH_DENTITION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaToothDentitionFilters {
  patientId: string;
  createdDate: Timestamp;
}

export interface IPraktikaToothDentitionTranslations {
  createdDate: Timestamp;
}

const PATIENT_TOOTH_DENTITION_SOURCE_QUERY = `
  SELECT
    iPatientNumber as patient_id,
    iToothId as id,
    iToothNumber as tooth_number,
    iToothTypeId as type_id,
    dtCreated as created_date
  FROM patient_dentitions
  ORDER BY id
`;

export class PatientToothDentitionSourceEntity extends BaseSourceEntity<
  IPraktikaToothDentition,
  IPraktikaToothDentitionTranslations,
  IPraktikaToothDentitionFilters
> {
  sourceEntity = PATIENT_TOOTH_DENTITION_SOURCE_ENTITY;
  entityResourceType = PATIENT_TOOTH_DENTITION_RESOURCE_TYPE;
  sourceQuery = PATIENT_TOOTH_DENTITION_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isPraktikaToothDentition;
  override transformDataFn = flow([]);
  override defaultOffsetSize = 20000;
  override dateFilterField: keyof IPraktikaToothDentitionFilters =
    'createdDate';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    adjustment: IPraktikaToothDentition,
    timezone: Timezone
  ): IPraktikaToothDentitionTranslations {
    return {
      createdDate: toTimestamp(
        moment.tz(adjustment.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaToothDentition): string {
    return data.id.toString();
  }

  getSourceLabel(data: IPraktikaToothDentition): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaToothDentition,
    timezone: Timezone
  ): IPraktikaToothDentitionFilters {
    return {
      patientId: data.patient_id.toString(),
      createdDate: toTimestamp(
        moment.tz(data.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
