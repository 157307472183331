<div
  class="flex items-center justify-end gap-2"
  *ngIf="pricingRule$ | async as pricingRule"
>
  <small class="unit-type">
    Pricing Rule:
    <ng-container *ngIf="pricingRule.unitType; else noUnit"
      >({{ pricingRule.unitType | splitCamel | titlecase }})</ng-container
    >
    <ng-template #noUnit>(Per Unit)</ng-template>
  </small>
  <mat-button-toggle-group
    *ngIf="pricingRule.type | map: shouldShowPricingRules"
    [hideSingleSelectionIndicator]="true"
    (change)="updateSelected(pricingRule, $event.value)"
  >
    <mat-button-toggle
      class="rule-item"
      *ngFor="let ruleItem of pricingRule.ruleItems; trackBy: trackByRuleItems"
      [value]="ruleItem.uid"
      [checked]="ruleItem.selected"
      [disabled]="pricingRule.type | map: isAutoSelected"
      tabindex="-1"
    >
      <div class="unit-row-label flex items-center gap-2">
        <div>
          {{ ruleItem.startUnit }}
          <ng-container
            *ngIf="ruleItem.endUnit && ruleItem.endUnit !== ruleItem.startUnit"
            >to {{ ruleItem.endUnit }}</ng-container
          >
          <ng-container *ngIf="!ruleItem.endUnit"> & above</ng-container>
        </div>
      </div>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
