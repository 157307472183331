@if (!manualInput()) {
  <mat-form-field class="w-full">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      #addressInput
      type="text"
      [placeholder]="placeholder"
      [formControl]="addressCtrl"
    />

    @if (addressIsVerified() && addressCtrl.value) {
      <mat-hint>
        <span class="text-success-500">
          <mat-icon class="align-text-top">check_circle</mat-icon>
          <span>Address verified</span>
        </span>
      </mat-hint>
    }

    @if (!addressIsVerified() && addressCtrl.value) {
      <mat-hint>
        <span
          [ngClass]="
            addressCtrl.hasError('invalidAddress')
              ? 'text-warn-500'
              : 'text-orange-500'
          "
        >
          <mat-icon class="align-text-top">error</mat-icon>
          <span>Address not verified, select address or enter manually</span>
        </span>
      </mat-hint>
    }

    <mat-hint align="end" (click)="toggleManualInput()">
      <span class="text-primary-500 hover:text-primary-700 cursor-pointer">
        Manually Enter Address
      </span>
    </mat-hint>
  </mat-form-field>
} @else {
  <form [formGroup]="manualAddressForm" class="grid grid-cols-4 gap-2">
    <mat-form-field class="col-span-4 w-full">
      <mat-label>Street Address</mat-label>
      <input
        matInput
        type="text"
        placeholder="Street Address"
        formControlName="streetName"
      />
      <mat-hint (click)="clearForm()">
        <span class="text-primary-500 hover:text-primary-700 cursor-pointer">
          <mat-icon class="align-text-top">clear</mat-icon> Clear Address
        </span>
      </mat-hint>
      <mat-hint align="end" (click)="toggleManualInput()">
        <span class="text-primary-500 hover:text-primary-700 cursor-pointer">
          Autocomplete Address
        </span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="col-span-4 w-full">
      <mat-label>Apartment, suite, unit, etc.</mat-label>
      <input
        matInput
        type="text"
        placeholder="Apartment, suite, unit, etc."
        formControlName="subpremise"
      />
    </mat-form-field>

    <mat-form-field class="col-span-2 w-full">
      <mat-label>City</mat-label>
      <input matInput type="text" placeholder="City" formControlName="city" />
    </mat-form-field>

    <mat-form-field class="col-span-2 w-full">
      <mat-label>State/Province/Region</mat-label>
      <input
        matInput
        type="text"
        placeholder="State/Province/Region"
        formControlName="state"
        [matAutocomplete]="states"
      />
      <mat-autocomplete #states="matAutocomplete">
        @for (state of filteredStates(); track state) {
          <mat-option [value]="state">{{ state }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="col-span-2 w-full">
      <mat-label>Postal Code/ZIP Code</mat-label>
      <input
        matInput
        type="text"
        placeholder="Postal Code/ZIP Code"
        formControlName="postalCode"
      />
    </mat-form-field>

    <mat-form-field class="col-span-2 w-full">
      <mat-label>Country</mat-label>
      <input
        matInput
        type="text"
        placeholder="Country"
        formControlName="country"
        [matAutocomplete]="countries"
      />
      <mat-autocomplete #countries="matAutocomplete">
        @for (country of filteredCountries(); track country) {
          <mat-option [value]="country">{{ country }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
}
