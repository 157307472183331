import {
  AtLeast,
  type INamedDocument,
  type IXSLXExport,
  type XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { type Column } from 'exceljs';
import { type IExactAppointmentCategory } from '../../source/entities/appointment-category';
import {
  ITranslationMap,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';

export enum AppointmentCategoryResourceType {
  TreatmentCategory = 'Treatment Category',
  AppointmentTag = 'Appointment Tag',
}

export type AppointmentCategoryDestination = ITreatmentCategory | ITag;

export interface IAppointmentToTreatmentCategoryXSLX {
  label: string;
  mapTo: string;
}

export const APPOINTMENT_TO_TREATMENT_CATEGORY_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'label',
    header: 'Label',
  },
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class AppointmentToTreatmentCategoryToXSLX
  implements
    IXSLXExport<IExactAppointmentCategory, IAppointmentToTreatmentCategoryXSLX>
{
  headers = APPOINTMENT_TO_TREATMENT_CATEGORY_HEADERS;

  constructor(
    private _existingMappings: ITranslationMap<AppointmentCategoryDestination>[],
    private _treatmentCategories: INamedDocument<ITreatmentCategory>[],
    private _appointmentTags: INamedDocument<ITag>[]
  ) {}

  translate(
    records: IExactAppointmentCategory[]
  ): Record<keyof IAppointmentToTreatmentCategoryXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );
      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const mappedCategory = destinationIdentifier
        ? this._treatmentCategories.find((category) =>
            isSameRef(category.ref, destinationIdentifier)
          )
        : undefined;

      const options = [
        ...this._treatmentCategories,
        ...this._appointmentTags,
      ].map((category) => category.name);

      return {
        label: { value: record.name },
        mapTo: {
          value: mappedCategory?.name,
          dataValidation: {
            type: 'list',
            formulae: [`"${options.join(',')}"`],
          },
        },
      };
    });
  }
}
