import {
  Arch,
  ChartableSurface,
  IChartedRef,
  isArch,
  isChartedToothWithSurface,
  isQuadrant,
  isToothSurface,
  ITooth,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { isEqual } from 'lodash';

type SelectedSurfaceChartedRefFields = Partial<{
  area: Quadrant | Arch;
  surface: ChartableSurface;
  tooth: ITooth;
  teeth: ITooth[];
}>;

export function surfaceFromRef(ref: Partial<IChartedRef>): ChartableSurface {
  if (ref.unscoped) {
    return ChartableSurface.Unscoped;
  }
  if (ref.wholeMouth) {
    return ChartableSurface.WholeMouth;
  }
  if (ref.arch) {
    return ChartableSurface.Arch;
  }
  if (ref.quadrant) {
    return ChartableSurface.Quadrant;
  }
  if (ref.multipleTeeth) {
    return ChartableSurface.MultipleTeeth;
  }
  if (ref.tooth && ref.tooth.surface) {
    return ref.tooth.surface;
  }
  return ChartableSurface.WholeTooth;
}

export function toChartedRef(
  selectedSurface: SelectedSurfaceChartedRefFields
): Partial<IChartedRef> {
  const { surface, area, tooth, teeth } = selectedSurface;
  if (surface === ChartableSurface.WholeMouth) {
    return { wholeMouth: true };
  }
  if (surface === ChartableSurface.Unscoped) {
    return { unscoped: true };
  }
  if (surface === ChartableSurface.Arch && isArch(area)) {
    return { arch: area };
  }
  if (surface === ChartableSurface.Quadrant && isQuadrant(area)) {
    return { quadrant: area };
  }
  if (surface === ChartableSurface.MultipleTeeth && teeth) {
    return {
      multipleTeeth: teeth.map((multiTooth) => multiTooth.toothRef),
    };
  }
  if (surface === ChartableSurface.WholeTooth && tooth) {
    return { tooth: tooth.toothRef };
  }
  if (
    surface &&
    (isToothSurface(surface) || surface === ChartableSurface.Crown) &&
    tooth
  ) {
    return {
      tooth: { ...tooth.toothRef, surface },
    };
  }
  return {};
}

export function isWithinSurfaces(
  search: Partial<IChartedRef>,
  surfaces: Partial<IChartedRef>[]
): boolean {
  return surfaces.some((surface) => isSameChartedRef(search, surface));
}

export function toCrownChartedRef(
  chartedRef: Required<Pick<IChartedRef, 'tooth'>>
): Required<Pick<IChartedRef, 'tooth'>> {
  return {
    tooth: { ...chartedRef.tooth, surface: ChartableSurface.Crown },
  };
}

export function isSameChartedRef(
  surfaceA: Partial<IChartedRef>,
  surfaceB: Partial<IChartedRef>
): boolean {
  if (
    !isChartedToothWithSurface(surfaceA) ||
    !isChartedToothWithSurface(surfaceB)
  ) {
    return isEqual(surfaceA, surfaceB);
  }

  return (
    isEqual(toCrownChartedRef(surfaceA), surfaceB) ||
    isEqual(surfaceA, toCrownChartedRef(surfaceB)) ||
    isEqual(surfaceA, surfaceB)
  );
}
