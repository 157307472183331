import {
  ChartableSurfaceResolver,
  ChartedSurface,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IAddChartedSurfaceProvider,
  type IChartSurfaceEvent,
  type IRemoveChartedSurfaceProvider,
} from './add-charted-surface-provider';

export class ChartedSurfaceEventHandler {
  constructor(
    private _chartingAs: WithRef<IStaffer>,
    private _chartingProviders: IAddChartedSurfaceProvider[]
  ) {}

  async addChartable(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[] = []
  ): Promise<void> {
    const compatibleSurfaceRefs: Partial<IChartedRef>[] =
      ChartableSurfaceResolver.getChartedRefs(chartable, selectedSurfaces);

    const chartedSurfaces: IChartedSurface[] = compatibleSurfaceRefs.map(
      (chartedRef: Partial<IChartedRef>) => {
        return this._createChartedSurface(chartedRef, this._chartingAs);
      }
    );

    await this._add({
      config: chartable,
      surfaces: chartedSurfaces,
    });
  }

  private _createChartedSurface(
    chartedRef: Partial<IChartedRef>,
    chartedBy: WithRef<IStaffer>
  ): IChartedSurface {
    return ChartedSurface.init({
      chartedRef,
      chartedBy: stafferToNamedDoc(chartedBy),
    });
  }

  private async _add(event: IChartSurfaceEvent): Promise<void> {
    const chartingProvider = this._chartingProviders.find((provider) =>
      provider.canProvide(event.config)
    );
    if (!chartingProvider) {
      // eslint-disable-next-line no-console
      console.error('chartingProvider not found', event);
      return;
    }
    await chartingProvider.addChartedItem(event, event.config);
  }
}

export class ChartedSurfaceRemovalHandler {
  constructor(private _chartingProviders: IRemoveChartedSurfaceProvider[]) {}

  async removeSurfaces(
    chartable: AnyChartedItemConfiguration,
    surfaces: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    const chartingProvider = this._chartingProviders.find((provider) =>
      provider.canProvide(chartable)
    );
    if (!chartingProvider) {
      // eslint-disable-next-line no-console
      console.error('chartingProvider not found', chartable);
      return;
    }

    await chartingProvider.removeSurfacesFromChartedItem(surfaces, item);
  }
}
