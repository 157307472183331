import { createAction, props } from '@ngrx/store';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import {
  type ChartSection,
  type ChartType,
  type ChartView,
  type IChartDataRenderConfig,
  type IDentalChartViewSurface,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface ISetChartTeeth extends IEntityAction<ChartId> {
  teeth: ITooth[];
}

export const setChartTeeth = createAction(
  ChartActionTypes.SetChartTeeth,
  props<SerialisedData<ISetChartTeeth>>()
);

export interface ISetChartViewSurfaces extends IEntityAction<ChartId> {
  surfaces: IDentalChartViewSurface[];
}

export interface ISetChartSection extends IEntityAction<ChartId> {
  section: ChartSection;
}

export const setChartSection = createAction(
  ChartActionTypes.SetChartSection,
  props<SerialisedData<ISetChartSection>>()
);

export interface ISetStackedRows extends IEntityAction<ChartId> {
  stackedRows: boolean;
}

export const setIsStacked = createAction(
  ChartActionTypes.SetIsStacked,
  props<SerialisedData<ISetStackedRows>>()
);

export interface ISetChartConfig extends IEntityAction<ChartId> {
  config: IChartDataRenderConfig;
}

export const setChartConfig = createAction(
  ChartActionTypes.SetChartConfig,
  props<SerialisedData<ISetChartConfig>>()
);

export interface ISetChartType extends IEntityAction<ChartId> {
  chartType: ChartType;
}

export const setChartType = createAction(
  ChartActionTypes.SetChartType,
  props<SerialisedData<ISetChartType>>()
);

export interface ISetChartView extends IEntityAction<ChartId> {
  view: ChartView;
}

export const setChartView = createAction(
  ChartActionTypes.SetChartView,
  props<SerialisedData<ISetChartView>>()
);
