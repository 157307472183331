import {
  ChartedItemType,
  ServiceCodeGroupType,
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type IInvoiceReference,
  type IPricedServiceCodeEntry,
  type IScopeRef,
  type IStaffer,
  type ITreatmentConfiguration,
  type ITreatmentPackage,
} from '@principle-theorem/principle-core/interfaces';
import {
  reduceToSingleArrayFn,
  type DocumentReference,
} from '@principle-theorem/shared';
import {
  type AtLeast,
  type INamedDocument,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flatten } from 'lodash';
import { v4 as uuid } from 'uuid';
import { PricedServiceCodeGroup } from '../service-codes/ada-code-category';
import { ChartedServiceSmartGroup } from '../service-codes/charted-service-smart-group';
import { PricedServiceCodeEntry } from '../service-codes/service-code';
import { ChartedItem } from '../core/charted-item';
import { type FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { TreatmentConfiguration } from './treatment-configuration';
import { ChartedServiceExclusiveGroup } from '../service-codes/charted-service-exclusive-group';
import { FeeSchedule } from '../fees/fee-schedule/fee-schedule';

export class ChartedTreatment extends ChartedItem {
  static override init(
    overrides: AtLeast<IChartedTreatment, 'config' | 'feeSchedule' | 'scopeRef'>
  ): IChartedTreatment {
    return {
      uuid: uuid(),
      type: ChartedItemType.ChartedTreatment,
      chartedSurfaces: [],
      notes: [],
      serviceCodeSmartGroups: [],
      serviceCodeGroups: [],
      serviceCodes: [],
      invoices: [],
      basePrice: 0,
      ...overrides,
    };
  }

  static override allChartedSurfaces(
    treatment: IChartedTreatment
  ): IChartedSurface[] {
    return [
      ...treatment.chartedSurfaces,
      ...treatment.serviceCodes
        .map((code) => code.chartedSurfaces)
        .reduce((allSurfaces: IChartedSurface[], surfaces) => {
          return [...allSurfaces, ...surfaces];
        }, []),
    ];
  }

  static includesInvoice(
    treatment: IChartedTreatment,
    ref: DocumentReference
  ): boolean {
    return treatment.invoices
      .map((invoice) => invoice.invoiceRef)
      .includes(ref);
  }

  static async applyFeeSchedule(
    treatment: IChartedTreatment,
    stepTreatments: IChartedTreatment[],
    manager: FeeScheduleManager,
    convertServiceCodes: boolean,
    changingFeeSchedule = false,
    overrideBasePrice = false
  ): Promise<IChartedTreatment> {
    const feeSchedule = await snapshot(manager.currentSchedule$);
    treatment.feeSchedule = toNamedDocument(feeSchedule);
    treatment.basePrice = overrideBasePrice
      ? treatment.basePrice
      : await manager.getFeeByTreatment(treatment.config.ref);

    const treatmentServiceCodes =
      PricedServiceCodeEntry.expandComplexPricedCodes(treatment.serviceCodes);

    const stepServiceCodes = flatten(
      stepTreatments.map((stepTreatment) =>
        stepTreatment.uuid === treatment.uuid
          ? treatmentServiceCodes
          : stepTreatment.serviceCodes
      )
    );

    const serviceCodes = await Promise.all(
      treatmentServiceCodes.map((serviceCode) =>
        PricedServiceCodeEntry.applyFeeSchedule(
          serviceCode,
          stepServiceCodes,
          manager,
          convertServiceCodes,
          changingFeeSchedule
        )
      )
    );

    const stepGroupCodes = flatten(
      stepTreatments
        .map((stepTreatment) =>
          stepTreatment.serviceCodeGroups.map(
            (serviceCodeGroup) => serviceCodeGroup.serviceCodes
          )
        )
        .reduce(reduceToSingleArrayFn, [])
    );

    const serviceCodeGroups = await Promise.all(
      treatment.serviceCodeGroups.map(async (adaGroup) =>
        PricedServiceCodeGroup.applyFeeSchedule(
          adaGroup,
          stepGroupCodes,
          manager,
          convertServiceCodes,
          changingFeeSchedule
        )
      )
    );

    const stepTreatmentSmartCodes = flatten(
      stepTreatments.map((stepTreatment) =>
        flatten(
          stepTreatment.serviceCodeSmartGroups.map(
            (smartGroup) => smartGroup.serviceCodes
          )
        )
      )
    );
    const stepSmartGroupServiceCodes =
      PricedServiceCodeEntry.expandComplexPricedCodes(stepTreatmentSmartCodes);

    const serviceCodeSmartGroups = await Promise.all(
      treatment.serviceCodeSmartGroups.map(async (smartGroup) =>
        ChartedServiceSmartGroup.applyFeeSchedule(
          smartGroup,
          stepSmartGroupServiceCodes,
          manager,
          convertServiceCodes,
          changingFeeSchedule
        )
      )
    );
    return {
      ...treatment,
      serviceCodes,
      serviceCodeGroups,
      serviceCodeSmartGroups,
    };
  }

  static applyPackagePricing(
    treatment: IChartedTreatment,
    treatmentPackage: ITreatmentPackage
  ): IChartedTreatment {
    const applyPriceFn = (
      serviceCode: IPricedServiceCodeEntry
    ): IPricedServiceCodeEntry => {
      const packageCode = treatmentPackage.priceOverrides.find(
        (code) =>
          code.code === serviceCode.code && code.type === serviceCode.type
      );
      if (!packageCode) {
        return serviceCode;
      }
      return {
        ...serviceCode,
        priceOverride: packageCode.price,
      };
    };

    const serviceCodes = treatment.serviceCodes.map(applyPriceFn);
    const serviceCodeGroups = treatment.serviceCodeGroups.map((group) => ({
      ...group,
      serviceCodes: group.serviceCodes.map(applyPriceFn),
    }));
    const serviceCodeSmartGroups = treatment.serviceCodeSmartGroups.map(
      (group) => ({
        ...group,
        serviceCodes: group.serviceCodes.map(applyPriceFn),
      })
    );

    return {
      ...treatment,
      serviceCodes,
      serviceCodeGroups,
      serviceCodeSmartGroups,
      treatmentPackageId: treatmentPackage.uid,
    };
  }

  static allADACodes(treatment: IChartedTreatment): IPricedServiceCodeEntry[] {
    return [
      ...treatment.serviceCodes,
      ...compact(
        treatment.serviceCodeSmartGroups.map((smartGroup) =>
          ChartedServiceSmartGroup.getSelected(smartGroup)
        )
      ),
      ...compact(
        treatment.serviceCodeGroups
          .filter((group) => group.type === ServiceCodeGroupType.Exclusive)
          .map((group) => ChartedServiceExclusiveGroup.getSelected(group))
      ),
    ];
  }

  static getInvoice(
    treatment: IChartedTreatment,
    ref: DocumentReference
  ): IInvoiceReference | undefined {
    return treatment.invoices.find(
      (invoice) => invoice.invoiceRef.path === ref.path
    );
  }

  static fromConfig(
    config: WithRef<ITreatmentConfiguration>,
    feeSchedule: WithRef<IFeeSchedule>,
    scopeRef: IScopeRef,
    attributedTo?: INamedDocument<IStaffer>
  ): IChartedTreatment {
    const exclusiveServiceCodeGroups = TreatmentConfiguration.getServiceGroups(
      config,
      [ServiceCodeGroupType.Exclusive]
    );
    const requiredServiceCodes =
      TreatmentConfiguration.getCombinedServiceCodes(config);
    const serviceCodeSmartGroups =
      TreatmentConfiguration.getSmartGroups(config);

    return ChartedTreatment.init({
      config: toNamedDocument(config),
      serviceCodeGroups: exclusiveServiceCodeGroups,
      serviceCodeSmartGroups,
      serviceCodes: requiredServiceCodes,
      basePrice: FeeSchedule.getTreatmentFee(feeSchedule, config.ref),
      taxStatus: FeeSchedule.getTreatmentTaxStrategy(feeSchedule, config.ref),
      feeSchedule: toNamedDocument(feeSchedule),
      attributedTo,
      scopeRef,
    });
  }

  static async applyPricing(
    treatment: IChartedTreatment,
    stepTreatments: IChartedTreatment[],
    feeScheduleManager: FeeScheduleManager,
    changingFeeSchedule = false,
    treatmentPackage?: ITreatmentPackage
  ): Promise<IChartedTreatment> {
    const updatedTreatment = await ChartedTreatment.applyFeeSchedule(
      treatment,
      stepTreatments,
      feeScheduleManager,
      changingFeeSchedule
    );
    return treatmentPackage
      ? ChartedTreatment.applyPackagePricing(updatedTreatment, treatmentPackage)
      : updatedTreatment;
  }
}
