import { Money, roundTo2Decimals } from '@principle-theorem/accounting';
import {
  AccountCredit,
  Invoice,
  Patient,
  Transaction,
  TransactionOperators,
  depositToLineItem,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  AccountCreditType,
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type FailedDestinationEntityRecord,
  type IBasePatient,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type IPracticeMigration,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  sortByCreatedAt,
  toNamedDocument,
  type DocumentReference,
  type IIdentifiable,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientDepositDestinationEntity,
  IDepositBuildData,
  IDepositJobData,
  IDepositMigrationData,
} from '../../../destination/entities/patient-deposits';
import { getConfigurationItem } from '../../../source/source';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PraktikaTransactionEffect,
  PraktikaTransationType,
} from '../../source/entities/appointment-invoice-payment';
import {
  IPraktikaPatient,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointment';
import {
  PatientDepositAdjustmentSourceEntity,
  type IPraktikaDepositAdjustment,
  type IPraktikaDepositAdjustmentTranslations,
} from '../../source/entities/patient-deposit-adjustments';
import {
  PatientDepositPaymentSourceEntity,
  type IPraktikaDepositPayment,
  type IPraktikaDepositPaymentTranslations,
} from '../../source/entities/patient-deposit-payments';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

interface IDepositData {
  id: string;
  number: string;
  date: Timestamp;
  patientId: string;
  payments: IGetRecordResponse<
    IPraktikaDepositPayment,
    IPraktikaDepositPaymentTranslations
  >[];
  adjustments: IGetRecordResponse<
    IPraktikaDepositAdjustment,
    IPraktikaDepositAdjustmentTranslations
  >[];
}

export class PatientDepositDestinationEntity extends BasePatientDepositDestinationEntity<
  IPraktikaPatient,
  IDepositJobData<IPraktikaPatient>
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    depositPayments: new PatientDepositPaymentSourceEntity(),
    depositAdjustments: new PatientDepositAdjustmentSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practices: new PraktikaPracticeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IDepositJobData<IPraktikaPatient>[]> {
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    return this.patientSourceEntity
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(practices$),
        map(([sourcePatients, practices]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            practices,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IDepositJobData<IPraktikaPatient>
  ): Promise<
    | IDepositMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();

    const payments = await this.sourceEntities.depositPayments.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const adjustments =
      await this.sourceEntities.depositAdjustments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const deposits: IDepositData[] = [];

    payments.map((payment) => {
      const depositFound = deposits.find(
        (deposit) => deposit.id === payment.data.data.deposit_id.toString()
      );
      if (depositFound) {
        if (
          depositFound.date.seconds >
          payment.data.translations.effectiveDate.seconds
        ) {
          depositFound.date = payment.data.translations.effectiveDate;
        }
        depositFound.payments.push(payment);
        return;
      }
      deposits.push({
        id: payment.data.data.deposit_id.toString(),
        number: payment.data.data.deposit_number.toString(),
        date: payment.data.translations.effectiveDate,
        patientId: sourcePatientId,
        adjustments: [],
        payments: [payment],
      });
    });

    adjustments.map((adjustment) => {
      const depositFound = deposits.find(
        (deposit) => deposit.id === adjustment.data.data.deposit_id.toString()
      );
      if (depositFound) {
        if (
          depositFound.date.seconds >
          adjustment.data.translations.effectiveDate.seconds
        ) {
          depositFound.date = adjustment.data.translations.effectiveDate;
        }
        depositFound.adjustments.push(adjustment);
        return;
      }
      deposits.push({
        id: adjustment.data.data.deposit_id.toString(),
        number: adjustment.data.data.deposit_number.toString(),
        date: adjustment.data.translations.effectiveDate,
        patientId: sourcePatientId,
        adjustments: [adjustment],
        payments: [],
      });
    });

    const patientRef = await translationMap.getDestination<IPatient>(
      data.sourcePatient.data.data.patient_id.toString(),
      PATIENT_RESOURCE_TYPE
    );

    const practiceMap = await this.customMappings.practices.getBySource(
      getConfigurationItem(migration.source, 'practice id', ''),
      translationMap
    );

    if (!patientRef || !practiceMap?.destinationIdentifier) {
      const message: string[] = [];
      if (!patientRef) {
        message.push('No patient');
      }
      if (!practiceMap?.destinationIdentifier) {
        message.push('No practice has been mapped');
      }

      return this._buildErrorResponse(data.sourcePatient, message.join('; '));
    }

    const builtDeposits = await asyncForEach(deposits, async (deposit) => {
      if (!practiceMap.destinationIdentifier) {
        throw new Error('No practice has been mapped');
      }
      const depositData = await this._buildDepositData(
        deposit,
        practiceMap.destinationIdentifier,
        patientRef
      );

      if (!depositData) {
        throw new Error(`Deposit ${deposit.id} has no amount`);
      }

      return depositData;
    });

    return {
      patientRef,
      deposits: builtDeposits,
    };
  }

  private async _buildDepositData(
    deposit: IDepositData,
    practiceRef: DocumentReference<IPractice>,
    patientRef: DocumentReference<IPatient>
  ): Promise<IDepositBuildData | undefined> {
    const practice = await Firestore.getDoc(practiceRef);
    const primaryContact = await Patient.resolvePrimaryContact(patientRef);
    const resolvedPatient = await Firestore.getDoc(patientRef);
    const onBehalfOf = toAccountDetails(
      resolvedPatient as IBasePatient & IPatientContactDetails
    );

    const transactions = getTransactions(deposit.payments, practiceRef);
    const used = Invoice.balance(
      { items: [] },
      getAdjustments(deposit.adjustments, practiceRef)
    );

    const amount = Math.abs(Invoice.balance({ items: [] }, transactions));

    const invoice = Invoice.init({
      from: toAccountDetails(practice),
      to: primaryContact
        ? {
            ...toAccountDetails(primaryContact),
            onBehalfOf,
          }
        : onBehalfOf,
      practice: toNamedDocument(practice),
      items: [depositToLineItem('Deposit', amount)],
      createdAt: deposit.date,
    });

    const status = getDepositInvoiceStatus(amount);

    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    const issuedAt = lastTransaction?.createdAt ?? deposit.date;
    invoice.issuedAt = issuedAt;
    invoice.reference = deposit.number;
    Invoice.updateStatus(invoice, status, issuedAt);

    const accountCredit = {
      createdAt: issuedAt,
      ...AccountCredit.init({
        type: AccountCreditType.Deposit,
        description: 'Deposit',
        amount,
        used: roundTo2Decimals(used),
        practiceRef: practice.ref,
      }),
    };

    return {
      uid: deposit.id,
      invoice,
      transactions,
      accountCredit,
    };
  }
}

function getDepositInvoiceStatus(transactionAmount: number): InvoiceStatus {
  return transactionAmount > 0 ? InvoiceStatus.Paid : InvoiceStatus.Cancelled;
}

function getTransactions(
  depositPayments: IGetRecordResponse<
    IPraktikaDepositPayment,
    IPraktikaDepositPaymentTranslations
  >[],
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return depositPayments.map((paymentRecord) => {
    const payment = paymentRecord.data.data;
    const uid = payment.id.toString();
    return {
      ...Transaction.init({
        ...Transaction.internalReference(TransactionProvider.Manual, uid),
        type:
          payment.effect === PraktikaTransactionEffect.Cr
            ? TransactionType.Incoming
            : TransactionType.Outgoing,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(Money.fromCents(payment.amount)),
        extendedData: payment,
        createdAt: paymentRecord.data.translations.effectiveDate,
        practiceRef,
      }),
    };
  });
}

function getAdjustments(
  depositAdjustments: IGetRecordResponse<
    IPraktikaDepositAdjustment,
    IPraktikaDepositAdjustmentTranslations
  >[],
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return depositAdjustments
    .filter(
      (adjustment) =>
        adjustment.data.data.type_id ===
        PraktikaTransationType.TransferFromDepositDr
    )
    .map((adjustmentRecord) => {
      const adjustment = adjustmentRecord.data.data;
      const uid = adjustment.id.toString();
      // TODO: Filter out the use of deposits
      return Transaction.init({
        ...Transaction.internalReference(TransactionProvider.Discount, uid),
        type: TransactionType.Outgoing,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(Money.fromCents(adjustment.amount)),
        extendedData: adjustment,
        createdAt: adjustmentRecord.data.translations.effectiveDate,
        practiceRef,
      });
    });
}
