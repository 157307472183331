import {
  ConditionConfigurationName,
  ExistingTreatmentConfigurationName,
} from '@principle-theorem/principle-core';

export enum PraktikaCondition {
  Caries = 1,
  Chip = 2,
  Crack = 3,
  Lesion = 4,
  Drifted = 5,
  RotatedCw = 6,
  RotatedAcw = 7,
  Impacted = 8,
  Overhang = 9,
  SubErupted = 10,
  SupraErupted = 11,
  Unerupted = 12,
  RootStump = 13,
  Tipped = 14,
  Facet = 15,
  CrownMetallic = 16,
  Veneer = 17,
  Denture = 18,
  Bridge = 19,
  Implant = 20,
  Metallic = 21,
  Composite = 22,
  Other = 23,
  Watch = 24,
  DriftedDistal = 25,
  DriftedMesial = 26,
  TippedDistal = 27,
  TippedMesial = 28,
  RootFracture = 29,
  NoGap = 30,
  Rct = 31,
  FissureSeal = 32,
  CrownPfm = 33,
  CrownCeramic = 34,
  Post = 35,
  Provisional = 36,
  CrownZirconia = 37,
  OnlayComposite = 38,
  OnlayMetallic = 39,
  OnlayZirconia = 40,
  CrownProvisional = 41,
  CrownComposite = 42,
  CrownSteel = 43,
  OnlayCeramic = 44,
  HypoplasiaGrade1 = 45,
  HypoplasiaGrade2 = 46,
  HypoplasiaGrade3 = 47,
  Abrasion = 48,
  Pegged = 49,
  Hypomineralisation = 50,
  Attrition = 51,
  DentureOverlay = 52,
  DentureClaspMesial = 53,
  DentureClaspDistal = 54,
  DentureClaspNylon = 55,
  DentureRestDistal = 56,
  DentureRestMesial = 57,
  Band = 58,
  OrthoToothWire = 59,
  LesionWhiteSpot = 63,
  PartiallyErupted = 73,
}

export const PRAKTIKA_CONDITION_MAP: Record<PraktikaCondition, string> = {
  [PraktikaCondition.Caries]: 'Caries',
  [PraktikaCondition.Chip]: 'Chip',
  [PraktikaCondition.Crack]: 'Crack',
  [PraktikaCondition.Lesion]: 'Lesion, Abscess',
  [PraktikaCondition.Drifted]: 'Drifted Tooth',
  [PraktikaCondition.RotatedCw]: 'Rotated Clockwise',
  [PraktikaCondition.RotatedAcw]: 'Rotated Anti-clockwise',
  [PraktikaCondition.Impacted]: 'Impacted Tooth',
  [PraktikaCondition.Overhang]: 'Overhang',
  [PraktikaCondition.SubErupted]: 'Sub-erupted Tooth',
  [PraktikaCondition.SupraErupted]: 'Supra-erupted Tooth',
  [PraktikaCondition.Unerupted]: 'Unerupted Tooth',
  [PraktikaCondition.RootStump]: 'Root Stump',
  [PraktikaCondition.Tipped]: 'Tipped Tooth',
  [PraktikaCondition.Facet]: 'Facet',
  [PraktikaCondition.CrownMetallic]: 'Crown Metallic',
  [PraktikaCondition.Veneer]: 'Veneer',
  [PraktikaCondition.Denture]: 'Denture',
  [PraktikaCondition.Bridge]: 'Bridge',
  [PraktikaCondition.Implant]: 'Implant',
  [PraktikaCondition.Metallic]: 'Filling Metallic',
  [PraktikaCondition.Composite]: 'Filling Composite',
  [PraktikaCondition.Other]: 'Other',
  [PraktikaCondition.Watch]: 'Watch',
  [PraktikaCondition.DriftedDistal]: 'Drifted Distal',
  [PraktikaCondition.DriftedMesial]: 'Drifted Mesial',
  [PraktikaCondition.TippedDistal]: 'Tipped Distal',
  [PraktikaCondition.TippedMesial]: 'Tipped Mesial',
  [PraktikaCondition.RootFracture]: 'Root Fracture',
  [PraktikaCondition.NoGap]: 'No Gap',
  [PraktikaCondition.Rct]: 'RCT',
  [PraktikaCondition.FissureSeal]: 'Fissure Seal',
  [PraktikaCondition.CrownPfm]: 'Crown PFM',
  [PraktikaCondition.CrownCeramic]: 'Crown Ceramic',
  [PraktikaCondition.Post]: 'Post',
  [PraktikaCondition.Provisional]: 'Filling Provisional',
  [PraktikaCondition.CrownZirconia]: 'Crown Zirconia',
  [PraktikaCondition.OnlayComposite]: 'Onlay/Inlay Composite',
  [PraktikaCondition.OnlayMetallic]: 'Onlay/Inlay Metallic',
  [PraktikaCondition.OnlayZirconia]: 'Onlay/Inlay Zirconia',
  [PraktikaCondition.CrownProvisional]: 'Crown Provisional',
  [PraktikaCondition.CrownComposite]: 'Crown Composite',
  [PraktikaCondition.CrownSteel]: 'Crown Steel',
  [PraktikaCondition.OnlayCeramic]: 'Onlay/Inlay Ceramic',
  [PraktikaCondition.HypoplasiaGrade1]: 'Hypoplasia (grade 1)',
  [PraktikaCondition.HypoplasiaGrade2]: 'Hypoplasia (grade 2)',
  [PraktikaCondition.HypoplasiaGrade3]: 'Hypoplasia (grade 3)',
  [PraktikaCondition.Abrasion]: 'Abrasion',
  [PraktikaCondition.Pegged]: 'Pegged Tooth',
  [PraktikaCondition.Hypomineralisation]: 'Hypomineralisation',
  [PraktikaCondition.Attrition]: 'Attrition',
  [PraktikaCondition.DentureOverlay]: 'Denture Overlay',
  [PraktikaCondition.DentureClaspMesial]: 'Retainer - Denture Clasp (M)',
  [PraktikaCondition.DentureClaspDistal]: 'Retainer - Denture Clasp (D)',
  [PraktikaCondition.DentureClaspNylon]: 'Retainer - Denture Clasp (Nylon)',
  [PraktikaCondition.DentureRestDistal]: 'Denture Rest (D)',
  [PraktikaCondition.DentureRestMesial]: 'Denture Rest (M)',
  [PraktikaCondition.Band]: 'Ortho Tooth Band',
  [PraktikaCondition.OrthoToothWire]: 'Ortho Tooth Wire',
  [PraktikaCondition.PartiallyErupted]: 'Partially Erupted',
  [PraktikaCondition.LesionWhiteSpot]: 'Lesion White Spot',
};

export const PRAKTIKA_CONDITION_PRINCIPLE_MAP: Record<
  PraktikaCondition,
  ExistingTreatmentConfigurationName | ConditionConfigurationName | undefined
> = {
  [PraktikaCondition.Caries]: ConditionConfigurationName.Caries,
  [PraktikaCondition.Chip]: ConditionConfigurationName.ChippedBrokenTooth,
  [PraktikaCondition.Crack]: ConditionConfigurationName.Crack,
  [PraktikaCondition.Lesion]: undefined,
  [PraktikaCondition.Drifted]: ConditionConfigurationName.DriftedRotatedTooth,
  [PraktikaCondition.RotatedCw]: ConditionConfigurationName.DriftedRotatedTooth,
  [PraktikaCondition.RotatedAcw]:
    ConditionConfigurationName.DriftedRotatedTooth,
  [PraktikaCondition.Impacted]: ConditionConfigurationName.ImpactedTooth,
  [PraktikaCondition.Overhang]: undefined,
  [PraktikaCondition.SubErupted]:
    ConditionConfigurationName.PartiallyEruptedTooth,
  [PraktikaCondition.SupraErupted]: ConditionConfigurationName.OverEruptedTooth,
  [PraktikaCondition.Unerupted]: ConditionConfigurationName.UneruptedTooth,
  [PraktikaCondition.RootStump]: undefined,
  [PraktikaCondition.Tipped]: undefined,
  [PraktikaCondition.Facet]: undefined,
  [PraktikaCondition.CrownMetallic]: ConditionConfigurationName.CrownMetallic,
  [PraktikaCondition.Veneer]: ExistingTreatmentConfigurationName.Veneer,
  [PraktikaCondition.Denture]: ConditionConfigurationName.Denture,
  [PraktikaCondition.Bridge]: ExistingTreatmentConfigurationName.CrownAndBridge,
  [PraktikaCondition.Implant]: ExistingTreatmentConfigurationName.Implant,
  [PraktikaCondition.Metallic]: ConditionConfigurationName.AmalgamFilling,
  [PraktikaCondition.Composite]: ConditionConfigurationName.CompositeFilling,
  [PraktikaCondition.Other]: ConditionConfigurationName.Other,
  [PraktikaCondition.Watch]: ConditionConfigurationName.WatchReviewRequired,
  [PraktikaCondition.DriftedDistal]:
    ConditionConfigurationName.DriftedRotatedTooth,
  [PraktikaCondition.DriftedMesial]:
    ConditionConfigurationName.DriftedRotatedTooth,
  [PraktikaCondition.TippedDistal]: undefined,
  [PraktikaCondition.TippedMesial]: undefined,
  [PraktikaCondition.RootFracture]: ConditionConfigurationName.RootFracture,
  [PraktikaCondition.NoGap]: undefined,
  [PraktikaCondition.Rct]: ConditionConfigurationName.RootCanalTreatment,
  [PraktikaCondition.FissureSeal]: ConditionConfigurationName.FissureSealant,
  [PraktikaCondition.CrownPfm]: undefined,
  [PraktikaCondition.CrownCeramic]: undefined,
  [PraktikaCondition.Post]: undefined,
  [PraktikaCondition.Provisional]: undefined,
  [PraktikaCondition.CrownZirconia]: undefined,
  [PraktikaCondition.OnlayComposite]: undefined,
  [PraktikaCondition.OnlayMetallic]: undefined,
  [PraktikaCondition.OnlayZirconia]: undefined,
  [PraktikaCondition.CrownProvisional]: undefined,
  [PraktikaCondition.CrownComposite]: undefined,
  [PraktikaCondition.CrownSteel]: undefined,
  [PraktikaCondition.OnlayCeramic]: undefined,
  [PraktikaCondition.HypoplasiaGrade1]: undefined,
  [PraktikaCondition.HypoplasiaGrade2]: undefined,
  [PraktikaCondition.HypoplasiaGrade3]: undefined,
  [PraktikaCondition.Abrasion]: undefined,
  [PraktikaCondition.Pegged]: undefined,
  [PraktikaCondition.Hypomineralisation]: undefined,
  [PraktikaCondition.Attrition]: ConditionConfigurationName.Attrition,
  [PraktikaCondition.DentureOverlay]: undefined,
  [PraktikaCondition.DentureClaspMesial]: undefined,
  [PraktikaCondition.DentureClaspDistal]: undefined,
  [PraktikaCondition.DentureClaspNylon]: undefined,
  [PraktikaCondition.DentureRestDistal]: undefined,
  [PraktikaCondition.DentureRestMesial]: undefined,
  [PraktikaCondition.Band]: undefined,
  [PraktikaCondition.OrthoToothWire]:
    ConditionConfigurationName.FixedOrthoRetainerBondedWire,
  [PraktikaCondition.PartiallyErupted]:
    ConditionConfigurationName.PartiallyEruptedTooth,
  [PraktikaCondition.LesionWhiteSpot]: undefined,
};
