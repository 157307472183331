<div class="pr-profile-card">
  <div *ngIf="hasActions" class="actions">
    <button mat-icon-button [matMenuTriggerFor]="profileMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #profileMenu="matMenu">
      <ng-content select="pr-profile-card-buttons"></ng-content>
    </mat-menu>
  </div>

  <div fxLayout="row">
    <div class="profile-card-icon">
      <ng-content select="pt-user-icon"></ng-content>
      <ng-content select="pt-profile-image-manager"></ng-content>
    </div>

    <div fxFlex class="layout-padding header-content">
      <div *ngIf="title">
        <ng-content select="pr-profile-card-title"></ng-content>
      </div>
      <ng-content *ngIf="header" select="pr-profile-card-header"></ng-content>
    </div>
  </div>

  <ng-content></ng-content>
</div>
