/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  ISO_DATE_FORMAT,
  Timestamp,
  Timezone,
  TypeGuard,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE } from '../../../destination/entities/consts';
import { PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE } from '../../../destination/entities/patient-treatment-plans';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_CLINICAL_CHART_DESTINATION_ENTITY } from '../../destination/entities/patient-clinical-charts';
import { PATIENT_DOCUMENTS_RESOURCE_TYPE } from '../../destination/entities/patient-documents';
import { PATIENT_INVOICE_DESTINATION_ENTITY } from '../../destination/entities/patient-invoices';
import { PATIENT_XRAYS_RESOURCE_TYPE } from '../../destination/entities/patient-xrays';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import { PATIENT_INTERACTION_RESOURCE_TYPE } from '../../../destination/entities/patient-interactions';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { EXISTING_PATIENT_DESTINATION_ENTITY } from '../../../destination/entities/existing-patient';

export const PATIENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient List',
    description: `
    "previousname" is mostly being used to store random patient info like Mum or dads name.
    "alsoknownas" is often used to store the medicare card number and position.
    "occupation" is also used to store the medicare card number and position.
    All 3 can be expected to store any of that kind of data.
    Also, the patient data is missing any "created_date" so we can't use that to filter.
    `,
    idPrefix: PATIENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum ExactSex {
  Male = 'Male',
  Female = 'Female',
}

export interface IExactPatient {
  patient_id: string;
  is_deleted: boolean;
  head_of_family_code: string | null;
  initials: string | null;
  first_name: string | null;
  last_name: string | null;
  second_name: string | null;
  title: string | null;
  sex: ExactSex | null;
  date_of_birth: string | null;
  medicare_card_number: string | null;
  medicare_card_position: string | null;
  medicare_expiry_date: string | null;
  home_address_1: string | null;
  home_address_2: string | null;
  home_address_suburb: string | null;
  home_address_town_city: string | null;
  home_address_postcode: number | null;
  home_address_state: string | null;
  home_phone: string | null;
  mobile_number: string | null;
  work_phone: string | null;
  email: string | null;
  contact_preference: string | null;
  preferred_practitioner_initials: string | null;
  preferred_hygienist_initials: string | null;
  referrer_id: string | null;
  referral_source_id: string | null;
  family_id: string | null;
  is_head_of_family: boolean;
  first_visit_datetime: string | null;
  last_visit_datetime: string | null;
  patient_note: string | null;
  healthfund_code: string | null;
  healthfund_patient_id: string | null;
  preferred_fee_schedule: string | null;
  referral_patient_id: string | null;
  location_id: string | null;
  is_inactive: boolean | null;
}

function isExactPatient(item: unknown): item is IExactPatient {
  return TypeGuard.interface<IExactPatient>({
    patient_id: isString,
    is_deleted: isBoolean,
    head_of_family_code: [isString, isNull],
    initials: [isString, isNull],
    first_name: [isString, isNull],
    last_name: [isString, isNull],
    second_name: [isString, isNull],
    title: [isString, isNull],
    sex: [TypeGuard.enumValue(ExactSex), isNull],
    date_of_birth: [isString, isNull],
    medicare_card_number: [isString, isNull],
    medicare_card_position: [isString, isNull],
    medicare_expiry_date: [isString, isNull],
    home_address_1: [isString, isNull],
    home_address_2: [isString, isNull],
    home_address_suburb: [isString, isNull],
    home_address_town_city: [isString, isNull],
    home_address_postcode: [isNumber, isNull],
    home_address_state: [isString, isNull],
    home_phone: [isString, isNull],
    mobile_number: [isString, isNull],
    work_phone: [isString, isNull],
    email: [isString, isNull],
    contact_preference: [isString, isNull],
    preferred_practitioner_initials: [isString, isNull],
    preferred_hygienist_initials: [isString, isNull],
    referrer_id: [isString, isNull],
    referral_source_id: [isString, isNull],
    family_id: [isString, isNull],
    is_head_of_family: isBoolean,
    first_visit_datetime: [isString, isNull],
    last_visit_datetime: [isString, isNull],
    patient_note: [isString, isNull],
    healthfund_code: [isString, isNull],
    healthfund_patient_id: [isString, isNull],
    preferred_fee_schedule: [isString, isNull],
    referral_patient_id: [isString, isNull],
    location_id: [isString, isNull],
    is_inactive: [isBoolean, isNull],
  })(item);
}

export interface IExactPatientTranslations {
  dateOfBirth?: ISODateType;
  firstVisitDatetime?: Timestamp;
  lastVisitDatetime?: Timestamp;
  medicareExpiryDate?: Timestamp;
}

const PATIENT_SOURCE_QUERY = `
SELECT
  patient_details.*,
  patient_email.*
FROM (
  SELECT
    patientid::TEXT AS patient_id,
    NULLIF(headoffamilycode, '') AS head_of_family_code,
    NULLIF(initials, '') AS initials,
    NULLIF(firstname, '') AS first_name,
    NULLIF(lastname, '') AS last_name,
    NULLIF(secondname, '') AS second_name,
    NULLIF(title, '') AS title,
    NULLIF(sex, '') AS sex,
    NULLIF(dateofbirth, '') AS date_of_birth,
    NULLIF(medicarecardnumber::TEXT, '') AS medicare_card_number,
    NULLIF(medicareirn::TEXT, '') AS medicare_card_position,
    NULLIF(medicareexpirydate, '') AS medicare_expiry_date,
    NULLIF(convaddress_homeaddress_address1, '') AS home_address_1,
    NULLIF(convaddress_homeaddress_address2, '') AS home_address_2,
    NULLIF(convaddress_homeaddress_suburb, '') AS home_address_suburb,
    NULLIF(convaddress_homeaddress_towncity, '') AS home_address_town_city,
    convert_to_integer(convaddress_homeaddress_postcodezipcode) AS home_address_postcode,
    NULLIF(convaddress_homeaddress_areastateprovince, '') AS home_address_state,
    NULLIF(homephone, '') AS home_phone,
    NULLIF(mobile, '') AS mobile_number,
    NULLIF(workphone, '') AS work_phone,
    contactpreference AS contact_preference,
    NULLIF(dentist::TEXT, '') AS preferred_practitioner_initials,
    NULLIF(hygienist, '') AS preferred_hygienist_initials,
    NULLIF(referrerid, '') AS referrer_id,
    NULLIF(referrerotherid, '') AS referral_source_id,
    NULLIF(familyid::TEXT, '') AS family_id,
    isheadoffamily AS is_head_of_family,
    firstvisitdatetime AS first_visit_datetime,
    lastvisitdatetime AS last_visit_datetime,
    NULLIF(patientnote, '') AS patient_note,
    NULLIF(healthfundcode, '') AS healthfund_code,
    NULLIF(healthfundpatientid::TEXT, '') AS healthfund_patient_id,
    NULLIF(payor, '') AS preferred_fee_schedule,
    NULLIF(referralpatient, '') AS referral_patient_id,
    NULLIF(locationid::TEXT, '') AS location_id,
    isinactive AS is_inactive
  FROM
    convpatient
) AS patient_details
LEFT JOIN (
  SELECT
    patientid::TEXT,
    emailaddress AS email
  FROM
    convpatientemail
) AS patient_email
ON patient_details.patient_id::TEXT = patient_email.patientid::TEXT
WHERE patient_details.first_name != '' OR patient_details.last_name != ''
`;

export class PatientSourceEntity extends BaseSourceEntity<
  IExactPatient,
  IExactPatientTranslations
> {
  sourceEntity = PATIENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_SOURCE_QUERY;
  verifySourceFn = isExactPatient;
  override transformDataFn = flow([transformPatientResults]);

  migrationDestinations = [
    PATIENT_CLINICAL_CHART_DESTINATION_ENTITY.metadata.key,
    PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE,
    PATIENT_DOCUMENTS_RESOURCE_TYPE,
    PATIENT_INTERACTION_RESOURCE_TYPE,
    PATIENT_INVOICE_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
    PATIENT_XRAYS_RESOURCE_TYPE,
    PATIENT_RESOURCE_TYPE,
    PATIENT_APPOINTMENT_RESOURCE_TYPE,
    EXISTING_PATIENT_DESTINATION_ENTITY.metadata.key,
  ];

  translate(
    patient: IExactPatient,
    timezone: Timezone
  ): IExactPatientTranslations {
    return {
      dateOfBirth: patient.date_of_birth
        ? moment
            .tz(patient.date_of_birth, EXACT_DATE_TIME_FORMAT, timezone)
            .format(ISO_DATE_FORMAT)
        : undefined,
      firstVisitDatetime: patient.first_visit_datetime
        ? toTimestamp(
            moment.tz(
              patient.first_visit_datetime,
              EXACT_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
      lastVisitDatetime: patient.last_visit_datetime
        ? toTimestamp(
            moment.tz(
              patient.last_visit_datetime,
              EXACT_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
      medicareExpiryDate: patient.medicare_expiry_date
        ? toTimestamp(
            moment.tz(
              patient.medicare_expiry_date,
              EXACT_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
    };
  }

  getFilterData(_patient: IExactPatient): Record<string, unknown> {
    return {};
  }

  getSourceRecordId(data: IExactPatient): string {
    return data.patient_id;
  }

  getSourceLabel(data: IExactPatient): string {
    return `${data.patient_id} ${data.first_name ?? ''} ${
      data.last_name ?? ''
    }`;
  }
}

function transformPatientResults(rows: IExactPatient[]): IExactPatient[] {
  return rows.map((exactPatient) => ({
    ...exactPatient,
    is_deleted: exactPatient.last_name?.indexOf('*^Deleted') !== -1,
    patient_id: convertExactId(exactPatient.patient_id),
    referral_patient_id: exactPatient.referral_patient_id
      ? convertExactId(exactPatient.referral_patient_id)
      : null,
    referrer_id: exactPatient.referrer_id
      ? convertExactId(exactPatient.referrer_id)
      : null,
    referral_source_id: exactPatient.referral_source_id
      ? convertExactId(exactPatient.referral_source_id)
      : null,
    family_id: exactPatient.family_id
      ? convertExactId(exactPatient.family_id)
      : null,
  }));
}
