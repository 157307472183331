<div fxFill fxLayout="column">
  <mat-toolbar color="accent">Create Healthcare Claim</mat-toolbar>
  <div class="header">
    <pt-user-icon
      [src]="stafferImage$ | async"
      [name]="data.practitioner.user.name"
      [diameter]="32"
    ></pt-user-icon>
    <div fxFlex>
      <div>
        <strong>{{ data.practitioner.user.name }}</strong>
      </div>
      <div class="mat-caption" *ngIf="providerData; else missingProviderData">
        {{ providerData.providerNumber }}
      </div>
      <ng-template #missingProviderData>
        <div class="mat-caption mat-error">Missing Provider Number</div>
      </ng-template>
    </div>
    <div>
      <span *ngIf="selected$ | async as selected">
        {{ selected.length }} selected
      </span>
      <mat-checkbox
        class="!mr-0"
        [checked]="hasAllSelected$ | async"
        (change)="toggleAll($event.checked)"
      />
    </div>
  </div>

  <div fxFlex class="content">
    <mat-selection-list
      dense
      [compareWith]="compareItemsFn"
      (selectionChange)="updateSelected($event)"
    >
      <mat-list-option
        *ngFor="let option of options; trackBy: trackByItem"
        [value]="option"
        [selected]="option | map : isSelected$ : this | async"
      >
        <div class="flex items-center">
          <div class="flex min-w-0 grow flex-col">
            <span class="mat-caption"
              >{{ option.item.treatment.description }}:
            </span>
            <span class="truncate">{{
              option.item.serviceCode.description
            }}</span>
          </div>
          <div>
            <div>{{ option.item.serviceCode.amount | currency }}</div>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <pt-buttons-container>
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isDisabled$ | async"
      (click)="submit()"
    >
      {{ data.submitLabel }}
    </button>
  </pt-buttons-container>
</div>
