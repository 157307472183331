import {
  AutomatedNotificationType,
  AutomationType,
  getAutomatedNotificationFromConfig,
  IAutomatedNotification,
  IAutomatedNotificationConfiguration,
  IAutomation,
  IAutomationConfiguration,
  IBrand,
  IEvent,
  IRawTemplate,
  isAutomatedNotificationConfiguration,
  ITemplateDefinition,
  ITemplateScopeData,
  TemplateScope,
  TemplateType,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Firestore } from '@principle-theorem/shared';
import {
  AtLeast,
  getDoc,
  INamedTypeDocument,
  initFirestoreModel,
  WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { DefaultDocumentTemplate } from '../../templating/template-defaults';
import { TemplateDefinition } from '../../templating/template-definition';
import { Automation } from '../automation/automation';
import { AutomationTiming } from '../automation/automation-timing';

export class AutomatedNotification {
  static init(
    overrides: AtLeast<IAutomatedNotification, 'timing'>
  ): IAutomatedNotification {
    return {
      name: '',
      subject: '',
      type: AutomatedNotificationType.EMAIL,
      ...overrides,
    };
  }

  static getTemplateType(type?: AutomatedNotificationType): TemplateType {
    switch (type) {
      case AutomatedNotificationType.EMAIL:
        return TemplateType.Html;
      default:
        return TemplateType.PlainText;
    }
  }

  static async getTemplateScope(
    notification: IAutomatedNotification,
    configRef?: DocumentReference<IAutomationConfiguration>
  ): Promise<TemplateScope> {
    if (notification.customTemplate) {
      return notification.customTemplate.scope ?? TemplateScope.Appointment;
    }
    if (configRef) {
      const config = await Firestore.getDoc(configRef);
      if (isAutomatedNotificationConfiguration(config)) {
        const parent = await Firestore.getDoc(config.templateRef);
        return parent.scope;
      }
    }
    return TemplateScope.Appointment;
  }

  static async toAutomation(
    notification: IAutomatedNotification,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    configRef?: DocumentReference<IAutomationConfiguration>
  ): Promise<IAutomation<IAutomatedNotification>> {
    return Automation.init({
      type: AutomationType.AutomatedNotification,
      data: notification,
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        notification.timing
      ),
      brandRef,
      configRef,
    });
  }

  static async generateFromConfig(
    config: WithRef<IAutomatedNotificationConfiguration>,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>
  ): Promise<IAutomation<IAutomatedNotification>> {
    return Automation.init({
      type: AutomationType.AutomatedNotification,
      data: getAutomatedNotificationFromConfig(config),
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        config.timing
      ),
      brandRef,
      practiceRef: event.practice.ref,
      configRef: config.ref,
    });
  }

  static async resolveRawTemplate<T extends ITemplateScopeData>(
    automation: IAutomation<IAutomatedNotification>,
    scopeData: T,
    preResolvedConfig?: WithRef<IAutomationConfiguration>
  ): Promise<IRawTemplate | undefined> {
    const notification = automation.data;

    if (notification.customTemplate) {
      return {
        type: this.getTemplateType(notification.type),
        scope: notification.customTemplate.scope ?? TemplateScope.None,
        content: notification.customTemplate.content,
        renderedTemplate: notification.customTemplate.renderedTemplate,
      };
    }

    if (automation.configRef) {
      const config = preResolvedConfig ?? (await getDoc(automation.configRef));
      if (!isAutomatedNotificationConfiguration(config)) {
        // eslint-disable-next-line no-console
        console.warn(
          `ConfigRef is not a notification: ${automation.configRef.path}`
        );
        return;
      }
      const template = await getDoc(config.templateRef);
      return TemplateDefinition.resolveRawTemplate(template, scopeData);
    }

    // eslint-disable-next-line no-console
    console.warn(`No resolvable template`);
  }
}

export enum DefaultAutomationConfiguration {
  TwoDaySMSConfirmation = '2-day SMS Confirmation',
}

interface IAutomatedNotificationSeedDefinition {
  templateName: DefaultDocumentTemplate;
  config: AtLeast<
    IAutomatedNotificationConfiguration,
    'name' | 'subject' | 'type' | 'timing'
  >;
}

const notifications: IAutomatedNotificationSeedDefinition[] = [
  {
    templateName: DefaultDocumentTemplate.TwoDaySMSConfirmation,
    config: {
      type: AutomatedNotificationType.SMS,
      name: '2-day SMS Confirmation',
      subject: '',
      timing: {
        amount: 2,
        unit: TimingUnit.Days,
        direction: TimingDirection.Before,
      },
    },
  },
];

export function getDefaultAutomatedNotificationConfigurations(
  documentTemplates: WithRef<ITemplateDefinition>[]
): IAutomatedNotificationConfiguration[] {
  const automations = notifications.map(
    (item): IAutomatedNotificationConfiguration | undefined => {
      const documentTemplate = documentTemplates.find(
        (template) => template.name === String(item.templateName)
      );
      if (!documentTemplate) {
        // eslint-disable-next-line no-console
        console.error(`Could not find document template: ${item.templateName}`);
        return;
      }
      return {
        ...initFirestoreModel(),
        treatmentRefs: [],
        isActive: true,
        templateRef: documentTemplate.ref,
        ...item.config,
      };
    }
  );

  return compact(automations);
}
