import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  TimezoneResolver,
  Transaction,
  TransactionOperators,
  isDiscountTransaction,
  isManualTransaction,
} from '@principle-theorem/principle-core';
import {
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IDiscountExtendedData,
  type IInvoice,
  type IManualExtendedData,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  isWithRef,
  snapshot,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { omit, sortBy } from 'lodash';
import { type Moment } from 'moment-timezone';
import { map } from 'rxjs/operators';
import {
  ITransactionAmendmentHistoryDialogData,
  TransactionAmendmentHistoryDialogComponent,
} from '../transaction-amendment-history-dialog/transaction-amendment-history-dialog.component';
import {
  AmendDiscountTransactionDialogComponent,
  type IAmendDiscountTransactionDialogData,
  type IAmendDiscountTransactionDialogResult,
} from './discount/amend-discount-transaction-dialog/amend-discount-transaction-dialog.component';
import {
  AmendManualTransactionDialogComponent,
  type IAmendManualTransactionDialogData,
  type IAmendManualTransactionDialogResult,
} from './manual/amend-manual-transaction-dialog/amend-manual-transaction-dialog.component';
import { TransactionAmountDialog } from './transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { TransactionPracticeOptionMethods } from './transaction-components/transaction-practice-options';

interface IManualTransactionData
  extends Pick<ITransaction<IManualExtendedData>, 'amount' | 'extendedData'> {
  dateReceived?: Moment;
}

interface IDiscountTransactionData
  extends Pick<ITransaction<IDiscountExtendedData>, 'amount' | 'extendedData'> {
  dateReceived?: Moment;
}

interface IBasicTransactionData extends Pick<ITransaction, 'amount'> {
  dateReceived?: Moment;
}

@Injectable()
export class GeneralProviderActionsService {
  constructor(
    private _accountFunctions: AccountingFunctionsService,
    private _amountDialog: TransactionAmountDialog,
    private _basicDialog: BasicDialogService,
    private _dialog: MatDialog
  ) {}

  async viewHistory(transaction: WithRef<ITransaction>): Promise<void> {
    const data: ITransactionAmendmentHistoryDialogData = { transaction };
    await this._dialog
      .open(
        TransactionAmendmentHistoryDialogComponent,
        DialogPresets.large({ data })
      )
      .afterClosed()
      .toPromise();
  }

  async amend(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>,
    dialogTitle: string,
    allowZeroDollarAmount = false
  ): Promise<void> {
    const timezone = await TimezoneResolver.fromPracticeRef(
      transaction.practiceRef
    );
    const result = await this._getDialogData(
      dialogTitle,
      transaction,
      toMomentTz(transaction.createdAt, timezone),
      allowZeroDollarAmount
    );
    if (!result) {
      return;
    }
    const amendTransaction = Transaction.init({
      ...transaction,
      ...omit(result, 'dateReceived'),
      createdAt: result.dateReceived
        ? toTimestamp(result.dateReceived)
        : transaction.createdAt,
      updatedAt: toTimestamp(),
    });
    const claim = Invoice.findHealthcareClaimForTransaction(
      invoice,
      transaction
    );
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      amendTransaction,
      TransactionAction.Update,
      claim
    );
  }

  async approve(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>,
    dialogTitle: string
  ): Promise<void> {
    const result = await this._getDialogData(dialogTitle, transaction);
    if (!result) {
      return;
    }
    const claim = Invoice.findHealthcareClaimForTransaction(
      invoice,
      transaction
    );
    const approveTransaction = Transaction.init({
      ...transaction,
      ...omit(result, 'dateReceived'),
      type: TransactionType.Incoming,
      status: TransactionStatus.Complete,
      createdAt: toTimestamp(result.dateReceived),
      updatedAt: toTimestamp(),
    });
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      approveTransaction,
      TransactionAction.Approve,
      claim
    );
  }

  async cancel(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>,
    dialogTitle: string
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm({
      prompt: 'Are you sure you want to cancel this transaction?',
      title: dialogTitle,
      submitLabel: 'Yes',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }
    const claim = Invoice.findHealthcareClaimForTransaction(
      invoice,
      transaction
    );
    const cancelTransaction = Transaction.init({
      ...transaction,
      type: TransactionType.Incoming,
      status: TransactionStatus.Failed,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
    });
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      cancelTransaction,
      TransactionAction.Cancel,
      claim
    );
  }

  async delete(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>,
    dialogTitle: string = 'Delete Transaction'
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm({
      prompt: 'Are you sure you want to delete this transaction?',
      title: dialogTitle,
      submitLabel: 'Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });
    if (!confirmed) {
      return;
    }
    const claim = Invoice.findHealthcareClaimForTransaction(
      invoice,
      transaction
    );
    const cancelTransaction = Transaction.init({
      ...transaction,
      deleted: true,
      updatedAt: toTimestamp(),
    });
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      cancelTransaction,
      TransactionAction.Delete,
      claim
    );
  }

  private async _getDialogData(
    dialogTitle: string,
    transaction: WithRef<ITransaction>,
    dateReceived?: Moment,
    allowZeroDollarAmount = false
  ): Promise<IBasicTransactionData | IManualTransactionData | undefined> {
    if (
      isWithRef<ITransaction<IManualExtendedData>>(transaction) &&
      isManualTransaction(transaction)
    ) {
      return this._getManualDialogData(
        dialogTitle,
        transaction,
        dateReceived,
        allowZeroDollarAmount
      );
    }
    if (
      isWithRef<ITransaction<IDiscountExtendedData>>(transaction) &&
      isDiscountTransaction(transaction)
    ) {
      return this._getDiscountDialogData(
        dialogTitle,
        transaction,
        dateReceived
      );
    }
    return this._getBasicDialogData(dialogTitle, transaction, dateReceived);
  }

  private async _getManualDialogData(
    dialogTitle: string,
    transaction: WithRef<ITransaction<IManualExtendedData>>,
    dateReceived?: Moment,
    allowZeroDollarAmount = false
  ): Promise<IManualTransactionData | undefined> {
    const data: IAmendManualTransactionDialogData = {
      practice:
        await TransactionPracticeOptionMethods.toTransactionPracticeOption(
          transaction
        ),
      title: dialogTitle,
      options: { initialAmount: transaction.amount },
      transaction,
      dateReceived,
    };
    const result = await this._dialog
      .open<
        AmendManualTransactionDialogComponent,
        IAmendManualTransactionDialogData,
        IAmendManualTransactionDialogResult
      >(AmendManualTransactionDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!result || result.amount < 0) {
      return;
    }
    if (!allowZeroDollarAmount && result.amount === 0) {
      return;
    }
    return result;
  }

  private async _getDiscountDialogData(
    dialogTitle: string,
    transaction: WithRef<ITransaction<IDiscountExtendedData>>,
    dateReceived?: Moment
  ): Promise<IDiscountTransactionData | undefined> {
    const invoice = await Transaction.getInvoice(transaction);
    const invoicePractitioners = sortBy(
      Invoice.getPractitionerProportionsOnInvoice(invoice),
      (practitioner) => practitioner.practitioner.name
    );

    const activeDiscounts = await snapshot(
      Invoice.transactions$(invoice).pipe(
        map((transactions) =>
          new TransactionOperators(transactions)
            .byProvider(TransactionProvider.Discount)
            .transactionGuard(
              (data): data is WithRef<ITransaction<IDiscountExtendedData>> =>
                isWithRef(data) && isDiscountTransaction(data)
            )
            .result()
        )
      )
    );

    const data: IAmendDiscountTransactionDialogData = {
      practice:
        await TransactionPracticeOptionMethods.toTransactionPracticeOption(
          transaction
        ),
      title: dialogTitle,
      options: { initialAmount: transaction.amount },
      transaction,
      dateReceived,
      invoicePractitioners,
      activeDiscounts,
    };
    const result = await this._dialog
      .open<
        AmendDiscountTransactionDialogComponent,
        IAmendDiscountTransactionDialogData,
        IAmendDiscountTransactionDialogResult
      >(AmendDiscountTransactionDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
    if (!result || result.amount <= 0) {
      return;
    }
    return result;
  }

  private async _getBasicDialogData(
    dialogTitle: string,
    transaction: WithRef<ITransaction>,
    dateReceived?: Moment
  ): Promise<IBasicTransactionData | undefined> {
    const result = await this._amountDialog.openEdit(
      dialogTitle,
      transaction,
      dateReceived,
      undefined
    );
    if (!result || result.amount <= 0) {
      return;
    }
    return result;
  }
}
