<ng-container *ngIf="checklistItems$ | async as options; else noChecklistItems">
  <ng-container
    *ngFor="let option of options.groups; trackBy: options.trackByGroup"
  >
    <small class="mat-caption">{{ option.group }}</small>
    <div class="flex flex-col gap-1">
      <div
        *ngFor="let item of option.items; trackBy: options.trackByOption"
        class="flex flex-row items-center justify-between gap-2"
      >
        <div class="min-w-0 grow truncate" [ptTruncateTooltip]="item.label">
          {{ item.label }}
        </div>
        <div class="flex w-16 shrink-0 text-right">
          <pr-checklist-item-status [isDone]="item.value.done" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noChecklistItems>
  <div class="text-xs opacity-50">
    There are no checklist items for this appointment
  </div>
</ng-template>
