import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { type MatSort } from '@angular/material/sort';
import { type MatTableDataSource } from '@angular/material/table';
import { type IReffable } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ICheckedChangeEvent<T> {
  checked: boolean;
  item: T;
}
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseItemList<T extends IReffable> {
  protected _onDestroy$ = new Subject<void>();
  @Input() sort: MatSort;
  @Input() selectedItem: T;
  @Output() selectedItemChange: EventEmitter<T> = new EventEmitter<T>();

  /**
   * Update the item list data whenever the data source is updated.
   *
   * @abstract
   * @param {T[]} [items]
   * @memberof BaseItemList
   */
  abstract updateItems(items: T[]): void;

  @Input()
  set dataSource(dataSource: MatTableDataSource<T>) {
    dataSource
      .connect()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((data: T[]) => this.updateItems(data));
  }

  selectItem(item: T): void {
    this.selectedItemChange.emit(item);
  }

  isItemSelected(item: T): boolean {
    return this.selectedItem === item;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
