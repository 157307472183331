import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { type WithRef } from '@principle-theorem/shared';
import { type ILabJob } from '@principle-theorem/principle-core/interfaces';
import {
  SelectionListStore,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { BaseItemList } from '@principle-theorem/ng-principle-shared';
import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

@Component({
    selector: 'pr-lab-job-list-display',
    templateUrl: './lab-job-list-display.component.html',
    styleUrls: ['./lab-job-list-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LabJobListDisplayComponent extends BaseItemList<WithRef<ILabJob>> {
  trackByLabJob = TrackByFunctions.ref<WithRef<ILabJob>>();
  labJobs$: ReplaySubject<WithRef<ILabJob>[]> = new ReplaySubject(1);
  deletedLabJobs$: ReplaySubject<WithRef<ILabJob>[]> = new ReplaySubject(1);
  multiSelectDisabled$ = new BehaviorSubject<boolean>(false);
  @Input() selectionList: SelectionListStore<WithRef<ILabJob>>;

  @Input()
  set multiSelectDisabled(disableMultiSelect: BooleanInput) {
    this.multiSelectDisabled$.next(coerceBooleanProperty(disableMultiSelect));
  }

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  updateItems(labJobs: WithRef<ILabJob>[]): void {
    this.labJobs$.next(labJobs.filter((job) => !job.deleted));
    this.deletedLabJobs$.next(labJobs.filter((job) => job.deleted));
    this._cdr.markForCheck();
  }
}
