import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AutomationsFacade } from '@principle-theorem/ng-automations';
import {
  CurrentBrandScope,
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
  selectQueryParam,
  type IPrincipleState,
} from '@principle-theorem/ng-principle-shared';
import {
  AppointmentSuggestion,
  Event,
  FeeSchedule,
  Practice,
  TimezoneResolver,
  TreatmentStep,
  buildEventTimePeriodFromEvent$,
  getPlanWithBookableStep,
  stafferToNamedDoc,
  stafferToParticipant,
} from '@principle-theorem/principle-core';
import {
  ADD_TO_WAITLIST_BY_DEFAULT,
  type IAppointment,
  type IAppointmentRequest,
  type IOrganisation,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentPlanPairFromTemplate,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_24HR,
  asDocRef,
  filterUndefined,
  getDoc,
  getRangeDuration,
  guardFilter,
  serialise$,
  snapshot,
  toMoment,
  toMomentTz,
  toTimePeriod,
  toTimestamp,
  unserialise$,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { first, omit } from 'lodash';
import { from, of, type Observable } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AppointmentDetailsActions,
  AppointmentRequestActions,
  AppointmentSelectorActions,
  SchedulingActions,
  WaitListActions,
} from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';
import {
  initAppointmentDetails,
  isDefaultOption,
  isRequiredAppointmentDetails,
  type IAppointmentDetails,
  type TreatmentPlanStepPair,
} from '../models';
import { AppointmentCreator } from '../models/appointment-management/appointment-creator';
import { AppointmentManager } from '../models/appointment-management/appointment-manager';
import { AppointmentTreatmentPlanAssociator } from '../models/appointment-treatment-plan-associator';
import {
  getWaitListDetailsFromAppointment,
  initWaitListDetails,
} from '../models/waitlist-details';
import { type IAppointmentSchedulingPartialState } from '../reducers/appointment-scheduling.reducer';

@Injectable()
export class AppointmentSchedulingEffects {
  private _actions$ = inject(Actions);
  private _store = inject(
    Store<IAppointmentSchedulingPartialState & IPrincipleState>
  );
  private _organisation = inject(OrganisationService);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  private _automationsFacade = inject(AutomationsFacade);
  private _brandScope = inject(CurrentBrandScope);
  private _currentScope = inject(CurrentScopeFacade);
  private _snackBar = inject(MatSnackBar);
  private _globalStore = inject(GlobalStoreService);
  saveNewAppointment$ = createEffect(() => this._saveNewAppointment$());
  saveNewAppointmentSuccess$ = createEffect(
    () => this._saveNewAppointmentSuccess$(),
    { dispatch: false }
  );
  saveAppointmentFailure$ = createEffect(
    () => this._saveAppointmentFailure$(),
    { dispatch: false }
  );
  setAppointmentDetails$ = createEffect(() => this._setAppointmentDetails$());
  selectTreatmentTemplate$ = createEffect(() =>
    this._selectTreatmentTemplate$()
  );
  setAppointmentRequest$ = createEffect(() => this._setAppointmentRequest$(), {
    dispatch: false,
  });
  setWaitlistDetails$ = createEffect(() => this._setWaitlistDetails$());
  setTags$ = createEffect(() => this._setTags$());
  rescheduleAppointment$ = createEffect(() => this._rescheduleAppointment$());
  setDetailsFromGap$ = createEffect(() => this._setDetailsFromGap$());
  setFilterOptionsFromGap$ = createEffect(() =>
    this._setFilterOptionsFromGap$()
  );
  setRequestedOption$ = createEffect(() => this._setRequestedOption$(), {
    dispatch: false,
  });
  setWaitlistDefaults$ = createEffect(() => this._setWaitlistDefaults$());

  private _saveNewAppointment$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.saveNewAppointment),
      unserialise$(),
      withLatestFrom(
        this._organisation.staffer$.pipe(filterUndefined()),
        this._schedulingFacade.selectedPatient$.pipe(filterUndefined()),
        this._schedulingFacade.appointmentDetails$.pipe(
          guardFilter(isRequiredAppointmentDetails)
        ),
        this._schedulingFacade.interactions$,
        this._schedulingFacade.waitlistDetails$,
        this._schedulingFacade.selectedEvent$,
        this._automationsFacade.newAutomations$,
        this._schedulingFacade.tags$,
        this._schedulingFacade.checklists$,
        this._schedulingFacade.appointmentRequest$
      ),
      concatMap(
        ([
          action,
          staffer,
          patient,
          appointmentDetails,
          interactions,
          waitlistDetails,
          event,
          automations,
          tags,
          checklists,
          appointmentRequest,
        ]) =>
          from(
            AppointmentCreator.add(
              staffer,
              patient,
              appointmentDetails,
              action.schedulingEventData,
              waitlistDetails,
              interactions,
              automations,
              checklists,
              tags,
              event,
              appointmentRequest
            )
          ).pipe(
            serialise$(),
            map((appointment) =>
              SchedulingActions.saveNewAppointmentSuccess({ appointment })
            ),
            catchError((error) =>
              of(
                SchedulingActions.saveAppointmentFailure({
                  error: String(error),
                })
              )
            )
          )
      )
    );
  }

  private _saveAppointmentFailure$(): Observable<void> {
    return this._actions$.pipe(
      ofType(SchedulingActions.saveAppointmentFailure),
      map((action) => void this._snackBar.open(action.error))
    );
  }

  private _saveNewAppointmentSuccess$(): Observable<void> {
    return this._actions$.pipe(
      ofType(
        SchedulingActions.saveNewAppointmentSuccess,
        SchedulingActions.rescheduleAppointmentSuccess
      ),
      map((action) => action.appointment),
      unserialise$(),
      concatLatestFrom((appointment) =>
        from(TimezoneResolver.fromEvent(appointment))
      ),
      map(([appointment, timezone]) => {
        let message = `Unscheduled Appointment Created`;
        if (appointment.event) {
          const date = toMomentTz(appointment.event.from, timezone).format(
            DATE_FORMAT
          );
          const time = toMomentTz(appointment.event.from, timezone).format(
            TIME_FORMAT
          );
          message = `Appointment booked for ${date} @ ${time}`;
        }
        this._snackBar.open(message);
      })
    );
  }

  private _setAppointmentDetails$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setExistingAppointment),
      unserialise$(),
      concatMap(({ appointment }) =>
        appointment ? this._getAppointmentDetails(appointment) : of(undefined)
      ),
      concatLatestFrom(() =>
        this._store.pipe(
          select(selectQueryParam('preserveState')),
          map((preserveState) => coerceBooleanProperty(preserveState))
        )
      ),
      map(([appointmentDetails, preserveState]) => {
        const details = appointmentDetails
          ? appointmentDetails
          : initAppointmentDetails({
              practice: undefined,
              treatment: undefined,
              duration: undefined,
            });
        return preserveState
          ? omit(details, ['practitioner', 'duration', 'practice'])
          : details;
      }),
      serialise$(),
      map((appointmentDetails) =>
        AppointmentDetailsActions.appointmentDetailsChange({
          appointmentDetails,
        })
      )
    );
  }

  private _selectTreatmentTemplate$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AppointmentDetailsActions.selectTreatmentTemplate),
      unserialise$(),
      concatMap((action) =>
        this._getTreatmentStepFromTemplate$(action.template)
      ),
      serialise$(),
      map((treatment) =>
        AppointmentDetailsActions.appointmentDetailsChange({
          appointmentDetails: {
            treatment,
          },
        })
      )
    );
  }

  private _setAppointmentRequest$(): Observable<void> {
    return this._actions$.pipe(
      ofType(AppointmentRequestActions.setAppointmentRequest),
      unserialise$(),
      concatMap(({ appointmentRequest }) =>
        this._getTreatmentStepFromTemplate$(appointmentRequest.template).pipe(
          map((treatment) => ({ request: appointmentRequest, treatment }))
        )
      ),
      concatMap(async ({ request, treatment }) => {
        const appointmentRequest = request as WithRef<IAppointmentRequest>;
        const practice: INamedDocument<IPractice> = appointmentRequest.practice;
        const brand = await getDoc(Practice.brandDoc(practice));
        const practitioner = await getDoc<IStaffer>(
          appointmentRequest.practitioner.ref
        );

        const timePeriod = await snapshot(
          buildEventTimePeriodFromEvent$(
            brand,
            practice,
            practitioner,
            toTimePeriod(
              appointmentRequest.event.from,
              appointmentRequest.event.to,
              await TimezoneResolver.fromPracticeRef(practice.ref)
            )
          )
        );

        const suggestion =
          AppointmentSuggestion.fromEventTimePeriod(timePeriod);
        suggestion.score = AppointmentSuggestion.getMatchScore(
          suggestion,
          appointmentRequest.event,
          timePeriod,
          {
            duration: true,
            overlap: true,
          }
        );

        this._schedulingFacade.addAppointmentSuggestion(suggestion);
        this._schedulingFacade.appointmentFormChange({
          practitioner: appointmentRequest.practitioner,
          treatment,
          duration: getRangeDuration(
            toTimePeriod(
              appointmentRequest.event.from,
              appointmentRequest.event.to
            )
          ).asMinutes(),
        });
      })
    );
  }

  private async _getAppointmentDetails(
    appointment: WithRef<IAppointment>
  ): Promise<IAppointmentDetails> {
    const treatment = await getPlanWithBookableStep(appointment);
    const practice = await getDoc(appointment.practice.ref);
    const duration = appointment.event
      ? Event.duration(appointment.event)
      : await snapshot(TreatmentStep.getDuration$(treatment.step));
    return {
      practitioner: appointment.practitioner,
      treatment,
      duration,
      practice,
    };
  }

  private _setWaitlistDetails$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setExistingAppointment),
      unserialise$(),
      map(({ appointment }) =>
        appointment ? getWaitListDetailsFromAppointment(appointment) : undefined
      ),
      serialise$(),
      map((waitlistDetails) =>
        waitlistDetails
          ? WaitListActions.setWaitListDetails({ waitlistDetails })
          : WaitListActions.reset()
      )
    );
  }

  private _rescheduleAppointment$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.rescheduleAppointment),
      unserialise$(),
      withLatestFrom(
        this._schedulingFacade.currentAppointment$,
        this._schedulingFacade.appointmentDetails$,
        this._schedulingFacade.selectedEvent$,
        this._organisation.staffer$.pipe(filterUndefined()),
        this._schedulingFacade.selectedPatient$.pipe(filterUndefined()),
        this._schedulingFacade.tags$,
        this._schedulingFacade.waitlistDetails$,
        this._automationsFacade.newAutomations$
      ),
      concatMap(
        ([
          action,
          appointment,
          appointmentDetails,
          event,
          staffer,
          patient,
          tags,
          waitListItem,
          automations,
        ]) => {
          if (!appointment || !event) {
            throw new Error('Appointment Reschedule - Missing required data');
          }
          appointment.tags = tags;
          return AppointmentManager.move(staffer, patient, appointment, {
            event,
            waitListItem,
            automations,
            treatmentStep: appointmentDetails.treatment?.step,
            schedulingEventData: action.schedulingEventData,
          });
        }
      ),
      concatMap((appointmentRef) => getDoc(appointmentRef)),
      serialise$(),
      map((updated) =>
        SchedulingActions.rescheduleAppointmentSuccess({
          appointment: updated,
        })
      ),
      catchError((error, caught) => {
        // eslint-disable-next-line no-console
        console.error(error, caught);
        SchedulingActions.saveAppointmentFailure({
          error: String(error),
        });
        return caught;
      })
    );
  }

  private _setDetailsFromGap$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setAppointmentDetailsFromGap),
      unserialise$(),
      exhaustMap(async ({ gap }) => ({
        practice: await getDoc(asDocRef<IPractice>(gap.event.practice.ref)),
        practitioner: gap.practitioner,
      })),
      serialise$(),
      map((appointmentDetails) =>
        AppointmentDetailsActions.appointmentDetailsChange({
          appointmentDetails,
        })
      )
    );
  }

  private _setFilterOptionsFromGap$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setAppointmentDetailsFromGap),
      unserialise$(),
      map(({ gap }) => {
        const fromDate = toMoment(gap.event.from);
        const toDate = toMoment(gap.event.to);
        return {
          fromDate,
          toDate,
          fromTime: fromDate.format(TIME_FORMAT_24HR),
          toTime: toDate.format(TIME_FORMAT_24HR),
        };
      }),
      serialise$(),
      map((change) =>
        AppointmentSelectorActions.updateFilterOptions({ change })
      )
    );
  }

  private _setTags$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setExistingAppointment),
      map(({ appointment }) => (appointment ? appointment.tags : [])),
      map((tags) => SchedulingActions.updateAppointmentTags({ tags }))
    );
  }

  private _setRequestedOption$(): Observable<void> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setRequestedAppointmentOption),
      unserialise$(),
      concatMap(async ({ params, appointment }) => {
        const rawFrom = params.from;
        const rawTo = params.to;
        if (!rawFrom || !rawTo || !params.staffer) {
          return;
        }

        const brand = await snapshot(
          this._brandScope.doc$.pipe(filterUndefined())
        );
        const practice = await getDoc(asDocRef<IPractice>(params.practice));
        const staffer = await getDoc(asDocRef<IStaffer>(params.staffer));
        const practitioner = stafferToNamedDoc(staffer);

        const range = {
          from: toMomentTz(rawFrom, practice.settings.timezone),
          to: toMomentTz(rawTo, practice.settings.timezone),
        };

        this._schedulingFacade.appointmentFormChange({
          practice,
          practitioner,
          duration: getRangeDuration(range).asMinutes(),
        });

        const event = Event.init({
          practice,
          from: toTimestamp(rawFrom),
          to: toTimestamp(rawTo),
          participants: [stafferToParticipant(practitioner)],
          organiser: practitioner,
          creator: await snapshot(
            this._organisation.staffer$.pipe(
              filterUndefined(),
              map(stafferToNamedDoc)
            )
          ),
        });

        const timePeriod = await snapshot(
          buildEventTimePeriodFromEvent$(brand, practice, staffer, range)
        );

        const suggestion =
          AppointmentSuggestion.fromEventTimePeriod(timePeriod);
        suggestion.score = AppointmentSuggestion.getMatchScore(
          suggestion,
          appointment?.event ?? event,
          timePeriod,
          {
            duration: true,
            overlap: true,
          }
        );

        this._schedulingFacade.addAppointmentSuggestion(suggestion);
      })
    );
  }

  private _setWaitlistDefaults$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.resetSchedulingState, WaitListActions.reset),
      withLatestFrom(this._currentScope.currentBrand$.pipe(filterUndefined())),
      map(([_, brand]) => {
        const defaultWaitlistSettingsOn =
          brand.settings.scheduling?.defaultWaitlistSettingsOn ??
          ADD_TO_WAITLIST_BY_DEFAULT;
        return initWaitListDetails({
          addToWaitlist: defaultWaitlistSettingsOn,
        });
      }),
      serialise$(),
      map((waitlistDetails) =>
        WaitListActions.setWaitListDetails({
          waitlistDetails,
        })
      )
    );
  }

  private _getTreatmentStepFromTemplate$(
    templateWithStep: ITreatmentTemplateWithStep
  ): Observable<TreatmentPlanStepPair> {
    return of(templateWithStep).pipe(
      withLatestFrom(
        this._schedulingFacade.appointmentDetails$,
        this._schedulingFacade.selectedPatient$,
        this._organisation.organisation$.pipe(filterUndefined()),
        this._globalStore.treatmentCategories$
      ),
      concatMap(
        async ([
          template,
          details,
          patient,
          organisation,
          treatmentCategories,
        ]) =>
          convertTemplateToPlanPair(
            template,
            details,
            patient,
            organisation,
            treatmentCategories
          )
      )
    );
  }
}

export async function convertTemplateToPlanPair(
  template: ITreatmentTemplateWithStep,
  details: IAppointmentDetails,
  patient: WithRef<IPatient> | undefined,
  organisation: WithRef<IOrganisation>,
  treatmentCategories: WithRef<ITreatmentCategory>[]
): Promise<ITreatmentPlanPairFromTemplate> {
  const feeSchedule = await FeeSchedule.getPreferredOrDefault(
    organisation,
    patient?.preferredFeeSchedule
  );

  const practitioner = getPractitionerFromDetailsOrTemplate(details, template);

  const { plan, steps } =
    await AppointmentTreatmentPlanAssociator.getTreatmentPlanStepsPair(
      template,
      feeSchedule,
      treatmentCategories,
      practitioner
    );

  // TODO: CU-2tyu87g Allow treatment tempaltes with multiple steps to be
  // added when booking an appointment.
  return { plan, step: first(steps) } as ITreatmentPlanPairFromTemplate;
}

function getPractitionerFromDetailsOrTemplate(
  details: IAppointmentDetails,
  template: ITreatmentTemplateWithStep
): INamedDocument<IStaffer> | undefined {
  const fromDetails = isDefaultOption(details.practitioner)
    ? undefined
    : details.practitioner;

  return fromDetails ?? template.plan.practitioner;
}
