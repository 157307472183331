import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  getBaseTransaction,
  Invoice,
  RefundHelpers,
  Transaction,
} from '@principle-theorem/principle-core';
import {
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IInvoice,
  type IManualExtendedData,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Region, WithRef } from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefundDepositPromptService } from '../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { refundAvailableInfo } from '../transaction-helpers';
import {
  IRefundTransactionProvider,
  ITransactionProvider,
  TransactionProviderType,
} from '../transaction-provider';
import {
  ManualTransactionComponent,
  type IManualTransactionData,
} from './manual-transaction.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountingFunctionsService } from 'libs/ng-principle-accounting/src/lib/accounting-functions.service';

@Injectable()
export class ManualTransactionProvider
  implements ITransactionProvider, IRefundTransactionProvider
{
  providerId = TransactionProvider.Manual;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$ = of(true);

  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _amountDialog: TransactionAmountDialog,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService
  ) {}

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const data: IManualTransactionData = {
      invoice,
      transaction: await getBaseTransaction(invoice),
      isRefund: false,
    };
    const transaction = await this._dialog
      .open<ManualTransactionComponent, IManualTransactionData, ITransaction>(
        ManualTransactionComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
    if (!transaction) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction<IManualExtendedData>>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const result = await this._amountDialog.openForRefund('Manual Refund', {
      invoice,
      transaction,
      latestTransaction: transaction,
    });
    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const refundTransaction = Transaction.init({
      ...(await getBaseTransaction(invoice, TransactionType.Outgoing)),
      ...Transaction.internalReference(TransactionProvider.Manual),
      extendedData: transaction.extendedData,
      reference: transaction.reference,
      status: TransactionStatus.Complete,
      amount: result.amount,
      practiceRef: result.practiceRef,
    });
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Refund
    );
  }

  canRefund$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return RefundHelpers.isRefundable$(invoice);
  }

  refundInfo$(invoice: WithRef<IInvoice>): Observable<string[]> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        RefundHelpers.getTotalRefundRemaining(transactions)
      ),
      map((refundRemaining) => [refundAvailableInfo(refundRemaining)])
    );
  }

  async refund(
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction>,
    disableAmount: boolean = false
  ): Promise<DocumentReference<ITransaction> | undefined> {
    if (transaction) {
      return this.refundTransaction(
        invoice,
        transaction as WithRef<ITransaction<IManualExtendedData>>
      );
    }
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const data: IManualTransactionData = {
      invoice,
      transaction: await getBaseTransaction(invoice, TransactionType.Outgoing),
      isRefund: true,
      disableAmount,
    };
    const refundTransaction = await this._dialog
      .open<ManualTransactionComponent, IManualTransactionData, ITransaction>(
        ManualTransactionComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
    if (!refundTransaction) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Add
    );
  }
}
