import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { APP_ROUTES } from './app-routes';
import { NgClinicalFeesModule } from '@principle-theorem/ng-clinical-fees';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { PracticeResolverService } from '../layout/practice-resolver.service';
import { BrandResolverService } from '../layout/brand-resolver.service';

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
    NgClinicalFeesModule,
    NgPatientModule,
  ],
  providers: [PracticeResolverService, BrandResolverService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
