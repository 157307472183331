<ng-container *ngIf="organisation$ | async as organisation">
  <pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
  <div class="border-b border-solid border-slate-300"></div>

  <div class="wrapper">
    <div fxLayout="column" fxLayoutGap="16px" class="layout-margin">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="16px"
      >
        <div fxFlex class="info mat-body-1">
          <p>Workspace: {{ organisation.slug }}</p>
          <p>Region: {{ organisation.region }}</p>
          <p>
            Joined:
            {{ organisation.createdAt | moment | amDateFormat: dateFormat }}
          </p>
        </div>
        <div class="flex items-center gap-4">
          <pr-organisation-status [status]="organisation.status" />

          <pr-organisation-actions [organisation]="organisation" />
        </div>
      </div>

      <h2 class="mat-headline-6">Links</h2>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['./users']">Users</a>
        <a mat-list-item [routerLink]="['./roles']">Roles</a>
      </mat-nav-list>

      <h2 class="mat-headline-6">Stripe Subscription Details</h2>
      <form [formGroup]="form" fxLayout="column">
        <div fxFlex="row" fxLayoutGap="16px">
          <mat-form-field fxFlex>
            <mat-label>Customer Id</mat-label>
            <input matInput formControlName="customerId" />
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Subscription Id</mat-label>
            <input matInput formControlName="subscriptionId" />
          </mat-form-field>
        </div>
        <pt-buttons-container padding="none">
          <button
            mat-flat-button
            color="primary"
            [disabled]="isDisabled$ | async"
            (click)="updateSubscriptionDetails()"
          >
            Save
          </button>
        </pt-buttons-container>
      </form>
    </div>
  </div>
</ng-container>
