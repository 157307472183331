<form [formGroup]="form" class="mb-4">
  <div formArrayName="relationships">
    <pr-patient-relationship-selector
      *ngFor="let control of relationships.controls; trackBy: trackByIndex"
      [form]="control"
     />
  </div>
</form>
<button mat-flat-button color="primary" (click)="addControl()">
  Add Relationship
</button>
