<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs()" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="mx-auto flex max-w-5xl flex-col gap-4 p-4">
  <pr-content-container>
    <div class="flex items-center justify-between gap-4">
      <h2 class="text-xl font-bold">Print Prescription Settings</h2>
      <button mat-stroked-button (click)="printTest()">Print Test</button>
    </div>
    <p class="text-md font-bold">Print Margin Offset</p>
    <pr-information-box>
      To ensure the prescription prints correctly, you may need to adjust the
      margin offset. This setting will move the prescription content
      horizontally and vertically on the page. The adjustment is in millimeters
      (mm) and can be positive or negative.
    </pr-information-box>

    <div class="mt-4 flex flex-col gap-4">
      @for (
        practice of organisation.practices$ | async;
        track practice.ref.path
      ) {
        <pr-prescription-print-settings [practice]="practice" />
      }
    </div>
  </pr-content-container>

  <pr-content-container>
    <div class="flex items-center justify-between gap-4">
      <h2 class="text-xl font-bold">Prescription Items</h2>
      <pr-prescription-add-item (itemAdd)="prescription.add$.next($event)" />
    </div>

    <pr-prescription-list
      [items]="prescription.items()"
      [loading]="prescription.loading()"
      (itemEdit)="prescription.edit$.next($event)"
      (itemDeleted)="confirmDelete($event)"
    />
  </pr-content-container>
</div>
