import { Pipe, type PipeTransform } from '@angular/core';
import { toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  transform(value?: Timestamp | moment.Moment): Date {
    return value ? toMoment(value).toDate() : new Date();
  }
}
