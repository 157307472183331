<div fxLayout="row" fxLayoutAlign="start center">
  <div class="mat-caption">{{ bloc.count$ | async }} Items</div>
  <mat-icon
    class="info-icon"
    #claimItemsOrigin="cdkOverlayOrigin"
    cdkOverlayOrigin
    >info_outline</mat-icon
  >
</div>

<pt-custom-tooltip [target]="claimItemsOrigin">
  <div class="claim-items-tooltip">
    <h3>Claim Items</h3>
    <div
      *ngFor="let item of bloc.items$ | async; trackBy: trackByClaimItem"
      class="mat-caption"
    >
      <ng-container *ngIf="item.resolved as resolved; else unknownItem">
        <strong>{{ resolved.treatment.description }}:</strong>
        {{ resolved.serviceCode.description }} x{{ resolved.quantity }}
      </ng-container>

      <ng-template #unknownItem>
        <strong>Unknown</strong> x{{ item.claimItem.quantity }}
      </ng-template>
    </div>
  </div>
</pt-custom-tooltip>
