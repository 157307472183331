import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import {
  ChatType,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  snapshot,
} from '@principle-theorem/shared';
import { differenceBy, sortBy } from 'lodash';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface ICreateChatFormData {
  chatType: ChatType;
  participants: DocumentReference<IStaffer>[];
}

@Component({
  selector: 'pr-create-chat-dialog',
  templateUrl: './create-chat-dialog.component.html',
  styleUrl: './create-chat-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChatDialogComponent {
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  searchCtrl = new TypedFormControl<string>();
  search: InputSearchFilter<WithRef<IStaffer>>;
  selected$ = new BehaviorSubject<WithRef<IStaffer>[]>([]);
  chatType = ChatType;
  isDisabled$: Observable<boolean>;

  constructor(
    private _organisation: OrganisationService,
    private _dialogRef: MatDialogRef<
      CreateChatDialogComponent,
      ICreateChatFormData
    >
  ) {
    const staff$ = this._organisation.staff$;

    this.search = new InputSearchFilter<WithRef<IStaffer>>(
      combineLatest([staff$, this.selected$]).pipe(
        map(([staff, selected]) =>
          differenceBy(staff, selected, (staffer) => staffer.ref.path)
        ),
        map((staff) => sortBy(staff, 'user.name'))
      ),
      toSearchStream(this.searchCtrl),
      ['user.name']
    );

    this.isDisabled$ = combineLatest([this.selected$]).pipe(
      map(([selected]) => {
        if (!selected.length) {
          return true;
        }
        return false;
      }),
      startWith(true)
    );
  }

  displayFn(value: string | WithRef<IStaffer>): string {
    return typeof value === 'string' ? value : '';
  }

  addMember(staffer: WithRef<IStaffer>): void {
    this.selected$.next(
      sortBy([...this.selected$.value, staffer], 'user.name')
    );
  }

  removeMember(staffer: WithRef<IStaffer>): void {
    this.selected$.next(
      differenceBy(
        this.selected$.value,
        [staffer],
        (currentStaffer) => currentStaffer.ref.path
      )
    );
  }

  async createChat(chatType: ChatType): Promise<void> {
    const participants = await snapshot(this.selected$);
    this._dialogRef.close({
      chatType,
      participants: participants.map((staffer) => staffer.ref),
    });
  }
}
