import { type SortDirection } from '@angular/material/sort';
import { type Params } from '@angular/router';
import {
  DEFAULT_TIME_RANGES,
  type ITimeRange,
} from '@principle-theorem/ng-principle-shared';
import { stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  isStaffer,
  type IStaffer,
  type ITeam,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  shareReplayCold,
  slugify,
  type WithRef,
} from '@principle-theorem/shared';
import { camelCase, compact } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskPresetFilter } from './task-preset-options';
import {
  TASK_DUE_DATE_SORT,
  TASK_SORT_OPTIONS,
  type ITaskSortOption,
} from './task-sort-options';

export enum TaskStatusFilter {
  Active = 'active',
  Completed = 'completed',
  Deleted = 'deleted',
}

export class TaskFilter {
  preset$: Observable<string>;
  status$: Observable<TaskStatusFilter>;
  dateRanges: ITimeRange[] = DEFAULT_TIME_RANGES;
  assignees$: Observable<(WithRef<IStaffer> | WithRef<ITeam>)[]>;
  owners$: Observable<WithRef<IStaffer>[]>;

  filteredAssignee$: Observable<WithRef<IStaffer> | WithRef<ITeam> | undefined>;
  filteredOwner$: Observable<WithRef<IStaffer> | undefined>;
  filteredRange$: Observable<ITimeRange | undefined>;
  activeSort$: Observable<string>;
  activeSortDirection$: Observable<SortDirection>;
  filtersActive$: Observable<boolean>;

  constructor(
    private _staff$: Observable<WithRef<IStaffer>[]>,
    private _teams$: Observable<WithRef<ITeam>[]>,
    private _params$: Observable<Params>
  ) {
    this.preset$ = this._params$.pipe(
      findProp<TaskPresetFilter>('preset'),
      map((name) => (name ? name : camelCase(TaskPresetFilter.MyTasks))),
      shareReplayCold()
    );

    this.status$ = this._params$.pipe(
      findProp<TaskStatusFilter>('status'),
      map((status) => (status ? status : TaskStatusFilter.Active)),
      shareReplayCold()
    );

    this.assignees$ = combineLatest([this._staff$, this._teams$]).pipe(
      map(([staff, teams]) => [...staff, ...teams]),
      shareReplayCold()
    );

    this.owners$ = this._staff$.pipe(shareReplayCold());

    this.filteredOwner$ = this._params$.pipe(
      findProp<string>('owner'),
      filterUndefined(),
      switchMap((name: string) =>
        this.owners$.pipe(
          map((staff) =>
            staff.find((staffer) => slugify(staffer.user.name) === name)
          )
        )
      ),
      shareReplayCold()
    );

    this.filteredAssignee$ = this._params$.pipe(
      findProp<string>('assignee'),
      filterUndefined(),
      switchMap((name: string) =>
        this.assignees$.pipe(
          map((assignees) =>
            assignees.find(
              (assignee) =>
                isStaffer(assignee) &&
                slugify(stafferToNamedDoc(assignee).name) === name
            )
          )
        )
      ),
      shareReplayCold()
    );

    this.filteredRange$ = this._params$.pipe(
      findProp<string>('dateRange'),
      map((title) =>
        this.dateRanges.find((range: ITimeRange) =>
          title && slugify(range.title) === title ? range.fromTo : undefined
        )
      ),
      shareReplayCold()
    );

    this.activeSort$ = this._params$.pipe(
      findProp<string>('sort'),
      map((sort) => (sort ? camelCase(sort) : TASK_DUE_DATE_SORT.id)),
      shareReplayCold()
    );

    this.activeSortDirection$ = this.activeSort$.pipe(
      map((sortId: string) => {
        const sortHandler: ITaskSortOption | undefined = TASK_SORT_OPTIONS.find(
          (sortOption) => sortOption.id === sortId
        );
        if (!sortHandler) {
          return 'asc';
        }
        return sortHandler.direction;
      }),
      shareReplayCold()
    );

    this.filtersActive$ = combineLatest([
      this.filteredOwner$,
      this.filteredAssignee$,
      this.filteredRange$,
    ]).pipe(map((args) => compact(args).length > 0));
  }
}
