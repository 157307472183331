@if (store.isPatientDetails$ | async) {
  <pr-patient-details-form-display
    [patient]="store.patient$ | async"
    [patientForm]="store.form$ | async"
    [disabled]="store.readonly$ | async"
    (patientDetailsChanged)="patientDetailsChange($event)"
  />
} @else {
  @if (customFormData$ | async; as customFormData) {
    <pr-custom-form
      [form]="customFormData"
      [disabled]="store.readonly$ | async"
      [hideSubmit]="true"
    />
  } @else {
    <div class="p-4">
      <pr-empty-state image="list" title="Submitted Details" />
    </div>
  }
}
