import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { GeocodeService } from '@principle-theorem/ng-principle-shared';
import {
  type IContact,
  type IGeoCoordinates,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { toMention } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, of, ReplaySubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactCardComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  contact$ = new ReplaySubject<WithRef<IContact>>(1);
  coordinates$: Observable<IGeoCoordinates | undefined>;

  constructor(
    private _geocodeService: GeocodeService,
    public dialogService: InteractionDialogsService
  ) {
    this.coordinates$ = this.contact$.pipe(
      switchMap((contact) =>
        contact.address
          ? this._geocodeService.geocodeAddress$(contact.address)
          : of(undefined)
      )
    );

    this.contact$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((contact) => {
        this.dialogService.dialogData = {
          contact: toMention(contact, MentionResourceType.Contact),
        };
      });
  }

  @Input()
  set contact(contact: WithRef<IContact>) {
    if (contact) {
      this.contact$.next(contact);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
