<mat-button-toggle-group
  class="filter-presets"
  [value]="activeType$ | async"
  (change)="typeFilterSelect.emit($event.value)"
>
  <mat-button-toggle
    *ngFor="let filter of typeFilters; trackBy: trackByFilter"
    [value]="filter.id"
  >
    <pr-automation-filter-badge [filterItem]="filter">
      <span>{{ filter.label | titlecase }}</span>
    </pr-automation-filter-badge>
  </mat-button-toggle>
</mat-button-toggle-group>
