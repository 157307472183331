<div class="heading">
  <div class="flex items-center justify-stretch gap-4">
    <ng-container *ngIf="config$ | async as config">
      <mat-icon
        *ngIf="config.display?.icon?.slug"
        [ngStyle]="{ color: config.display.colour.value }"
        [svgIcon]="config.display.icon.slug"
       />

      <div class="flex-1">
        <div class="flex items-center gap-1.5">
          <h3>{{ config.name }}</h3>
          <ng-container *ngIf="condition$ | async as condition">
            <mat-icon
              class="note"
              *ngIf="condition.notes.length"
              [matTooltip]="noteTooltipText$ | async"
            >
              description
            </mat-icon>
          </ng-container>
        </div>
        <div>
          <pr-charted-items-surface-list
            *ngIf="condition$ | async as condition"
            [chartedSurfaces]="condition.chartedSurfaces"
            [availableSurfaces]="config.availableSurfaces"
            [ngClass]="{ highlighted: highlighted }"
            (editSurfaces)="editSurfaces(condition)"
           />
        </div>
      </div>
    </ng-container>

    <div class="buttons flex items-stretch justify-end">
      <button mat-icon-button (click)="collapsible.toggle()">
        <mat-icon>
          {{ collapsible.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>

      <ng-container *ngIf="!disabled">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-template matMenuContent>
            <ng-container *ngIf="condition$ | async as condition">
              <button
                mat-menu-item
                color="warn"
                (click)="addTreatment(condition)"
              >
                <mat-icon>healing</mat-icon>
                Treat
              </button>

              <ng-container *ngIf="canEdit$ | async">
                <button mat-menu-item (click)="openNoteDialog(condition)">
                  <mat-icon>note_add</mat-icon>
                  Add Note
                </button>

                <button
                  mat-menu-item
                  [disabled]="disabled"
                  (click)="editSurfaces(condition)"
                >
                  <mat-icon>select_all</mat-icon>
                  Add/Change Surfaces
                </button>

                <button
                  mat-menu-item
                  *ngIf="(condition | map: isResolved) === false"
                  (click)="resolve(condition)"
                >
                  <mat-icon>done</mat-icon>
                  Resolve
                </button>

                <button mat-menu-item color="warn" (click)="delete(condition)">
                  <mat-icon>delete</mat-icon>
                  Delete
                </button>
              </ng-container>
            </ng-container>
          </ng-template>
        </mat-menu>
      </ng-container>
    </div>
  </div>
</div>

<pt-collapsible #collapsible="ptCollapsible">
  <div class="content">
    <ng-template ptCollapsibleContent>
      <ng-content></ng-content>
    </ng-template>
  </div>
</pt-collapsible>
