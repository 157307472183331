import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type ICustomMapping,
  type IPractice,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  multiSortBy$,
  nameSorter,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { PracticeMigration } from '../practice-migrations';
import { TranslationMapHandler } from '../translation-map';
import { PracticesToXSLX } from './practice-to-xlsx';
import { XSLXToPractice } from './xlsx-to-practice';

export interface IBaseMigrationPractice {
  id: number;
  name: string;
}

export const PRACTICE_RESOURCE_TYPE = 'practice';

export const PRACTICE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Practices ',
    description: 'DocumentReference paths for practices',
    type: PRACTICE_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Practice Id',
    destinationIdentifier: 'Principle Practice',
  },
});

export abstract class PracticeMappingHandler extends BaseCustomMappingHandler<IPractice> {
  customMapping = PRACTICE_MAPPING;

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IPractice>[]> {
    return snapshot(
      Brand.practices$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, sourcePractices, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      sourcePractices,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, sourcePractices, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      sourcePractices,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToPractice()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const sourceOptions = [
      {
        name: '1',
        label: '1',
      },
    ];
    const practices = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (option) => option.label === item.label
      );
      if (!matchingOption) {
        return;
      }

      const practice = practices.find(
        (sourcePractice) => sourcePractice.name === item.mapTo
      );
      if (!practice) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find practice for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: practice.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: matchingOption.label,
          sourceLabel: matchingOption.label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    sourcePractices: IBaseMigrationPractice[];
    translator: PracticesToXSLX;
  }> {
    const fileName = this.getFileName();
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const sourcePractices = [
      {
        id: 1,
        name: '1',
        label: '1',
      },
    ];
    const translator = new PracticesToXSLX(
      await this.getDestinationOptions(migration),
      await this.getRecords(translationMap)
    );
    return { fileName, sourcePractices, translator };
  }
}
