<mat-toolbar color="accent">{{ isEdit ? 'Edit' : 'Add' }} Column</mat-toolbar>

<mat-dialog-content fxLayout="column">
  <form [formGroup]="columnForm" fxLayout="column" (ngSubmit)="submit()">
    <div class="flex flex-col gap-4">
      <pr-information-box class="mb-2">
        Select what data you'd like to see on the chart. This might be a value
        such as a dollar value ($), time value (mins or hrs), or counting the
        number of times something is in the report.
      </pr-information-box>

      <pr-report-builder-data-point-selector [bloc]="bloc" label="Data Point" />

      @if (bloc.filterOptions$ | async; as filterOptions) {
        @if (filterOptions.length) {
          <div class="flex flex-col">
            <mat-form-field class="flex-1" subscriptSizing="dynamic">
              <mat-label>
                Filter the data point's values to only a select few of it's
                options
              </mat-label>
              <mat-select formControlName="filters" multiple>
                @for (option of filterOptions; track option) {
                  <mat-option [value]="option">
                    {{ option | map: bloc.convertBoolean : bloc }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div
              class="px-4 py-1 text-[10.5px] leading-4 text-[rgba(0,0,0,.54)]"
            >
              Data points such as categories, tags, patient demographics,
              "Yes/No" values can be filtered down to get a count just for some
              of the values.
            </div>
          </div>
        }
      }

      <mat-form-field class="flex-1">
        <mat-label> How do you want the data to be added together? </mat-label>
        <mat-select formControlName="reducer">
          @for (option of bloc.reducerOptions$ | async; track option) {
            <mat-option [value]="option">
              {{ option | map: bloc.convertReducer : bloc }}
            </mat-option>
          }
        </mat-select>
        @if (columnForm.controls.reducer.disabled) {
          <mat-hint>
            This data point can only be counted the number of times it is in the
            report.
          </mat-hint>
        }
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Custom Label</mat-label>
        <input matInput formControlName="label" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="submit()">
      {{ isEdit ? 'Save' : 'Add' }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
