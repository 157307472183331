import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-query-filter-display',
  templateUrl: './query-filter-display.component.html',
  styleUrls: ['./query-filter-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryFilterDisplayComponent {
  @Input() label: string;
  @Input() values: string;
}
