<div *ngIf="practices$ | async as practices" class="flex flex-col gap-2">
  <button
    class="w-full"
    *ngFor="let practice of practices; trackBy: trackByPractice"
    (click)="loadPractice(practice)"
    mat-stroked-button
  >
    {{ practice.name }}
  </button>
</div>
