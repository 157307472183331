import {
  Arch,
  ChartableSurface,
  IChartedRef,
  IChartedSurface,
  IStaffer,
  IToothRef,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  INamedDocument,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import { differenceWith } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isUpperQuadrant } from './quadrant';
import { isSameChartedRef } from './selected-surface';
import { isSameToothRef } from './tooth';

export class ChartedSurface {
  static init(
    overrides: AtLeast<IChartedSurface, 'chartedBy'>
  ): IChartedSurface {
    return {
      uuid: uuid(),
      chartedAt: toTimestamp(),
      chartedRef: {},
      ...overrides,
    };
  }

  static isOnTooth(surface: IChartedSurface, toothRef: IToothRef): boolean {
    if (!surface.chartedRef.tooth) {
      return false;
    }
    return isSameToothRef(surface.chartedRef.tooth, toothRef);
  }

  static isOneOfTeeth(surface: IChartedSurface, toothRef: IToothRef): boolean {
    if (!surface.chartedRef.multipleTeeth) {
      return false;
    }
    return surface.chartedRef.multipleTeeth.some((tooth: IToothRef) => {
      return isSameToothRef(tooth, toothRef);
    });
  }

  static isSameToothSurface(
    chartedSurface: IChartedSurface,
    surface?: ChartableSurface
  ): boolean {
    if (!chartedSurface.chartedRef.tooth) {
      return false;
    }
    return surface === chartedSurface.chartedRef.tooth.surface;
  }

  static isToothSurface(
    chartedSurface: IChartedSurface,
    surface?: ChartableSurface
  ): boolean {
    if (!chartedSurface.chartedRef.tooth) {
      return false;
    }
    if (
      surface === ChartableSurface.Crown ||
      surface === ChartableSurface.WholeTooth
    ) {
      return true;
    }
    return surface === chartedSurface.chartedRef.tooth.surface;
  }

  static isOnSurface(
    chartedSurface: IChartedSurface,
    surface?: ChartableSurface
  ): boolean {
    const surfaceKey: keyof IChartedRef = surface as keyof IChartedRef;
    if (chartedSurface.chartedRef[surfaceKey]) {
      return true;
    }
    return this.isSameToothSurface(chartedSurface, surface);
  }

  static isOnASelectedSurface(
    surface: IChartedSurface,
    chartedRefs: Partial<IChartedRef>[]
  ): boolean {
    return chartedRefs.some((selectedRef: Partial<IChartedRef>) => {
      return this.isSameChartedRef(surface, selectedRef);
    });
  }

  static isSameChartedRef(
    surface: IChartedSurface,
    other: Partial<IChartedRef>
  ): boolean {
    return isSameChartedRef(surface.chartedRef, other);
  }

  static isResolved(surface: IChartedSurface): boolean {
    return surface.resolvedAt ? true : false;
  }

  static asWholeTooth(surface: IChartedSurface): IToothRef | undefined {
    if (!surface.chartedRef.tooth) {
      return;
    }

    return {
      quadrant: surface.chartedRef.tooth.quadrant,
      quadrantIndex: surface.chartedRef.tooth.quadrantIndex,
    };
  }

  static asArch(surface: IChartedSurface): Arch | undefined {
    if (surface.chartedRef.arch) {
      return surface.chartedRef.arch;
    }
    if (surface.chartedRef.quadrant || surface.chartedRef.tooth) {
      const quadrant: Quadrant | undefined = surface.chartedRef.tooth
        ? surface.chartedRef.tooth.quadrant
        : surface.chartedRef.quadrant;
      if (!quadrant) {
        return;
      }
      if (isUpperQuadrant(quadrant)) {
        return Arch.Upper;
      }
      return Arch.Lower;
    }
    return undefined;
  }

  static asQuadrant(surface: IChartedSurface): Quadrant | undefined {
    return surface.chartedRef.tooth
      ? surface.chartedRef.tooth.quadrant
      : surface.chartedRef.quadrant;
  }

  static resolve(
    surface: IChartedSurface,
    resolveBy?: INamedDocument<IStaffer>
  ): void {
    if (this.isResolved(surface)) {
      return;
    }
    surface.resolvedAt = toTimestamp();
    if (resolveBy) {
      surface.resolvedBy = toNamedDocument(resolveBy);
    }
  }

  static filterSurfaceRefs(
    surfaces: IChartedSurface[],
    surfaceRefsToFilterOut: Partial<IChartedRef>[]
  ): IChartedSurface[] {
    return differenceWith(
      surfaces,
      surfaceRefsToFilterOut,
      (surface, surfaceRef) => isSameChartedRef(surface.chartedRef, surfaceRef)
    );
  }
}
