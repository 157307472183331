import { Injectable } from '@angular/core';
import { Money } from '@principle-theorem/accounting';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type IPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import {
  type IPurchaseRequestParams,
  type IRefundRequestParams,
} from '@principle-theorem/tyro';
import { ITransactionActionsData } from '../../transaction-action';
import { type ITransactionAmountDialogResult } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { TransactionAmountDialog } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { findActiveHealthPointClaim } from '../health-point/health-point-transaction';
import { type IntegrationKeyFormData } from '../select-tyro-terminal/select-tyro-terminal.component';

@Injectable()
export class PurchaseBuilder {
  constructor(private _amountDialog: TransactionAmountDialog) {}

  async buildPurchase(
    invoice: WithRef<IInvoice>,
    formData: ITransactionAmountDialogResult,
    terminalData: IntegrationKeyFormData
  ): Promise<IPurchaseRequestParams> {
    const request: IPurchaseRequestParams = {
      ...terminalData,
      amount: `${Money.toCents(formData.amount)}`,
      integratedReceipt: false,
    };
    const transactions = await snapshot(Invoice.transactions$(invoice));
    const claim = findActiveHealthPointClaim(transactions);
    if (claim && claim.extendedData) {
      request.healthpointTransactionId =
        claim.extendedData.response.transactionId;
    }
    return request;
  }

  buildRefund(
    formData: ITransactionAmountDialogResult,
    terminalData: IntegrationKeyFormData
  ): IRefundRequestParams {
    return {
      ...terminalData,
      amount: `${Money.toCents(formData.amount)}`,
      integratedReceipt: false,
    };
  }

  async openRefundForm(
    actionsData: ITransactionActionsData<IPurchaseTransactionExtendedData>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForRefund(
      'Tyro - EFTPOS Refund',
      actionsData
    );
  }

  async openPurchaseForm(
    invoice: WithRef<IInvoice>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForInvoice('Tyro - EFTPOS Payment', invoice);
  }
}
