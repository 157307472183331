import { isWithRef, TypeGuard, type WithRef } from '@principle-theorem/shared';
import { isTreatmentPlan, type ITreatmentPlan } from './treatment-plan';
import { isTreatmentStep, type ITreatmentStep } from './treatment-step';
import {
  isTreatmentStepFromTemplate,
  type ITreatmentStepFromTemplate,
} from './treatment-step-from-template';

export interface ITreatmentPlanWithBookableStep {
  plan: WithRef<ITreatmentPlan>;
  step: WithRef<ITreatmentStep>;
}

export interface ITreatmentTemplateWithStep {
  plan: ITreatmentPlanFromTemplate;
  step: ITreatmentStepFromTemplate;
}

export interface ITreatmentPlanPairFromTemplate {
  plan: ITreatmentPlanFromTemplate;
  step: ITreatmentStep & ITreatmentStepFromTemplate;
}

export interface ITreatmentPlanFromTemplate extends ITreatmentPlan {
  isTemplate: boolean;
}

export function isTreatmentPlanFromTemplate(
  item: unknown
): item is ITreatmentPlanFromTemplate {
  return isTreatmentPlan(item) && 'isTemplate' in item;
}

export function isTreatmentTemplateWithStep(
  item: unknown
): item is ITreatmentTemplateWithStep {
  return TypeGuard.interface<ITreatmentTemplateWithStep>({
    plan: isTreatmentPlanFromTemplate,
    step: isTreatmentStepFromTemplate,
  })(item);
}

export function isTreatmentPlanWithBookableStep(
  item: unknown
): item is ITreatmentPlanWithBookableStep {
  return TypeGuard.interface<ITreatmentPlanWithBookableStep>({
    plan: TypeGuard.merge(isTreatmentPlan, isWithRef),
    step: TypeGuard.merge(isTreatmentStep, isWithRef),
  })(item);
}

export function isTreatmentPlanPairFromTemplate(
  item: unknown
): item is ITreatmentPlanPairFromTemplate {
  return TypeGuard.interface<ITreatmentPlanPairFromTemplate>({
    plan: isTreatmentPlanFromTemplate,
    step: TypeGuard.merge(isTreatmentStep, isTreatmentStepFromTemplate),
  })(item);
}
