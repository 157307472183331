import { IntegrationType } from '@principle-theorem/integrations';
import {
  AppointmentContextBuilder,
  PatientContextBuilder,
  PatientMock,
  PracticeMock,
  PractitionerMock,
  ScopeContextProvider,
  ScopeContextResolver,
  TreatmentPlanContextBuilder,
  TreatmentPlanMock,
  type IContextResolver,
  AppointmentMock,
  EventMock,
  GapContextBuilder,
  InvoiceContextBuilder,
  InvoiceMock,
  MockCandidateCalendarEvent,
} from '@principle-theorem/principle-core';
import {
  ServiceTypeModality,
  TemplateScope,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_TIME_FORMAT,
  MockGenerator,
  Region,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef, MockWithRef } from '@principle-theorem/testing';
import * as moment from 'moment-timezone';

const mockPatient = MockWithRef(
  MockGenerator.generate(PatientMock, {
    name: 'Allan Sutherland',
    email: 'allan.sutherland@gmail.com',
    address: '11 York St, Berrydale, NSW 2535',
    dateOfBirth: '1980-01-16',
  })
);
const mockPractice = MockWithRef(
  MockGenerator.generate(PracticeMock, {
    name: 'North Sydney Smiles',
    email: 'info@northsydneysmiles.au',
    phone: '02 1234 5678',
  })
);
const mockAppointment = MockWithRef(
  MockGenerator.generate(AppointmentMock, {
    event: MockGenerator.generate(EventMock, {
      from: toTimestamp(
        moment
          .tz('10:00:00', ISO_TIME_FORMAT, mockPractice.settings.timezone)
          .add(1, 'week')
      ),
      to: toTimestamp(
        moment
          .tz('12:00:00', ISO_TIME_FORMAT, mockPractice.settings.timezone)
          .add(1, 'week')
      ),
    }),
    treatmentPlan: {
      name: 'Crown - PFM / Veneered',
      ref: MockDocRef<ITreatmentPlan>(),
      treatmentStep: {
        name: 'Crown Preparation',
        duration: 120,
        ref: MockDocRef<ITreatmentStep>(),
        display: {},
      },
    },
  })
);
const mockPractitioner = MockGenerator.generate(PractitionerMock, {
  providerDetails: [
    {
      practiceRef: mockPractice.ref,
      providerNumber: '3222411A',
      providerModality: ServiceTypeModality.GeneralDentist,
    },
  ],
});
const mockGapCandidate = MockCandidateCalendarEvent();
const mockInvoice = MockGenerator.generate(InvoiceMock);
const mockViewInvoiceLink = 'https://app.principle.dental/invoice/9vbv20';
const mockGapOfferLink = 'https://app.principle.dental/reschedule/9sd823';
const mockMedicalHistoryLink = 'https://app.principle.dental/history/29cg82';
const mockPatientFormsLink = 'https://app.principle.dental/history/65xa16';
const mockConfirmationLink = 'https://app.principle.dental/confirm/a9g2jj';
const mockReferrer = {
  name: 'Melanie Green',
  email: 'melanie.green@gmail.com',
  address: '742 Evergreen Terrace, Springfield, NSW',
  jobTitle: 'Solicitor',
};
const mockTreatmentPlan = MockGenerator.generate(TreatmentPlanMock);

export const MOCK_PATIENT_RESOLVER = new ScopeContextProvider(
  TemplateScope.Patient,
  new PatientContextBuilder({
    patient: mockPatient,
    patientForms: [],
    medicalHistoryLink: mockMedicalHistoryLink,
    patientFormsLink: mockPatientFormsLink,
    referrer: mockReferrer,
    smsIntegration: IntegrationType.Twilio,
    region: Region.Australia,
  })
);

export const MOCK_APPOINTMENT_RESOLVER = new ScopeContextProvider(
  TemplateScope.Appointment,
  new AppointmentContextBuilder({
    appointment: mockAppointment,
    practitioner: mockPractitioner,
    practice: mockPractice,
    patient: mockPatient,
    patientForms: [],
    smsIntegration: IntegrationType.Twilio,
    confirmLink: mockConfirmationLink,
    medicalHistoryLink: mockMedicalHistoryLink,
    patientFormsLink: mockPatientFormsLink,
    referrer: mockReferrer,
    region: Region.Australia,
  })
);

export const MOCK_INVOICE_RESOLVER = new ScopeContextProvider(
  TemplateScope.Invoice,
  new InvoiceContextBuilder({
    invoice: mockInvoice,
    invoiceLink: mockViewInvoiceLink,
    patient: mockPatient,
    patientForms: [],
    medicalHistoryLink: mockMedicalHistoryLink,
    patientFormsLink: mockPatientFormsLink,
    smsIntegration: IntegrationType.Twilio,
    region: Region.Australia,
  })
);

export const MOCK_GAP_RESOLVER = new ScopeContextProvider(
  TemplateScope.GapOffer,
  new GapContextBuilder({
    gapCandidate: mockGapCandidate,
    gapOfferConfirmUrl: mockGapOfferLink,
    patient: mockPatient,
    medicalHistoryLink: mockMedicalHistoryLink,
    referrer: mockReferrer,
    appointment: mockAppointment,
    practitioner: mockPractitioner,
    practice: mockPractice,
    confirmLink: mockConfirmationLink,
    region: Region.Australia,
    patientForms: [],
    patientFormsLink: mockPatientFormsLink,
  })
);

export const MOCK_TREATMENT_PLAN_RESOLVER = new ScopeContextProvider(
  TemplateScope.TreatmentPlan,
  new TreatmentPlanContextBuilder({
    treatmentPlan: mockTreatmentPlan,
    patient: mockPatient,
    patientForms: [],
    medicalHistoryLink: mockMedicalHistoryLink,
    patientFormsLink: mockPatientFormsLink,
    smsIntegration: IntegrationType.Twilio,
    region: Region.Australia,
  })
);

export const MOCK_EMPTY_CONTEXT_PROVIDER = new ScopeContextProvider(
  TemplateScope.None,
  {
    build: () => ({}),
  }
);

export const MOCK_ALL_RESOLVER: IContextResolver = new ScopeContextResolver([
  MOCK_PATIENT_RESOLVER,
  MOCK_APPOINTMENT_RESOLVER,
  MOCK_INVOICE_RESOLVER,
  MOCK_GAP_RESOLVER,
  MOCK_TREATMENT_PLAN_RESOLVER,
  MOCK_EMPTY_CONTEXT_PROVIDER,
]);
