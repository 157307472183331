import { NgModule } from '@angular/core';
import { PractitionerSelectorComponent } from '@principle-theorem/ng-clinical-charting';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { NgPrincipleBridgeCloudModule } from '@principle-theorem/ng-principle-bridge-cloud';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { TransactionComponentsModule } from '../transaction-components/transaction-components.module';
import { EditHicapsConnectTerminalDialogComponent } from './edit-hicaps-connect-terminal-dialog/edit-hicaps-connect-terminal-dialog.component';
import { HicapsConnectEftposTransactionProvider } from './eftpos/hicaps-connect-eftpos-transaction-provider.service';
import { HicapsConnectHealthFundTransactionProvider } from './health-fund/hicaps-connect-health-fund-transaction-provider.service';
import { HicapsConnectExtendedDataComponent } from './hicaps-connect-extended-data/hicaps-connect-extended-data.component';
import { HicapsConnectReceiptComponent } from './hicaps-connect-receipt/hicaps-connect-receipt.component';
import { HicapsConnectSelectTerminalDialogComponent } from './hicaps-connect-select-terminal-dialog/hicaps-connect-select-terminal-dialog.component';
import { HicapsConnectService } from './hicaps-connect.service';
import { HicapsConnectMedicareTransactionProvider } from './medicare/hicaps-connect-medicare-transaction-provider.service';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPatientModule,
    TransactionComponentsModule,
    NgPrincipleBridgeCloudModule,
    PractitionerSelectorComponent,
  ],
  providers: [
    HicapsConnectService,
    HicapsConnectEftposTransactionProvider,
    HicapsConnectHealthFundTransactionProvider,
    HicapsConnectMedicareTransactionProvider,
  ],
  declarations: [
    HicapsConnectExtendedDataComponent,
    HicapsConnectSelectTerminalDialogComponent,
    HicapsConnectReceiptComponent,
    EditHicapsConnectTerminalDialogComponent,
  ],
  exports: [HicapsConnectReceiptComponent],
})
export class HicapsConnectTransactionModule {}
