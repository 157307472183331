import { BehaviorSubject, type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export enum PatientConfirmState {
  Loading = 'loading',
  AwaitingConfirmation = 'awaitingConfirmation',
  AlreadySubmitted = 'alreadySubmitted',
  NotAvailable = 'notAvailable',
  Submitting = 'submitting',
  Confirmed = 'confirmed',
}

export class PatientConfirmStateBloc {
  state$ = new BehaviorSubject<PatientConfirmState>(
    PatientConfirmState.Loading
  );
  isLoading$ = this.state$.pipe(isState(PatientConfirmState.Loading));
  isAwaitingConfirmation$ = this.state$.pipe(
    isState(PatientConfirmState.AwaitingConfirmation)
  );
  isAlreadySubmitted$ = this.state$.pipe(
    isState(PatientConfirmState.AlreadySubmitted)
  );
  isNotAvailable$ = this.state$.pipe(isState(PatientConfirmState.NotAvailable));
  isSubmitting$ = this.state$.pipe(isState(PatientConfirmState.Submitting));
  isConfirmed$ = this.state$.pipe(isState(PatientConfirmState.Confirmed));
}

function isState(
  match: PatientConfirmState
): OperatorFunction<PatientConfirmState, boolean> {
  return map((state) => state === match);
}
