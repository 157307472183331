<div fxFill fxLayout="column" fxLayoutAlign="center center">
  <ng-template #loading>
    <mat-spinner />
  </ng-template>

  <pr-task-interactions
    *ngIf="task$ | async as task; else loading"
    fxFlex
    fxFill
    [task]="task"
    (closeTrigger)="onComplete()"
   />
</div>
