import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { Subject } from 'rxjs';
import { PatientPortalFunctionsService } from '../../patient-portal-functions.service';
import { PatientPortalHeaderComponent } from '../../components/patient-portal-header/patient-portal-header.component';
import { PatientPortalLoaderComponent } from '../../components/patient-portal-loader/patient-portal-loader.component';
import { PatientPortalSplashComponent } from '../../components/patient-portal-splash/patient-portal-splash.component';
import { PatientPortalUI } from '../../lib/patient-portal-ui';
import { PatientFormsListComponent } from '../../components/patient-forms-list/patient-forms-list.component';
import { PatientFormsStore } from './patient-forms-store.service';

@Component({
    selector: 'pr-patient-forms-page',
    templateUrl: './patient-forms-page.component.html',
    styleUrl: './patient-forms-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        PatientPortalHeaderComponent,
        PatientPortalLoaderComponent,
        PatientPortalSplashComponent,
        PatientFormsListComponent,
    ],
    providers: [PatientPortalFunctionsService, PatientFormsStore]
})
export class PatientFormsPageComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _intercom = inject(IntercomService);
  private _route = inject(ActivatedRoute);
  store = inject(PatientFormsStore);

  constructor() {
    this.store.loadTokenUid(PatientPortalUI.getTokenUid$(this._route));
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
