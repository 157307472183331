import { toMoment, type WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export interface INotification<
  NotificationType extends string = string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Resources extends Record<string, any> = Record<string, any>
> {
  type: NotificationType;
  viewed: boolean;
  resources: Resources;
}

export class Notification {
  static init<
    NotificationType extends string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Resources extends Record<string, any> = Record<string, any>
  >(
    type: NotificationType,
    resources: Resources
  ): INotification<NotificationType, Resources> {
    return {
      viewed: false,
      type,
      resources,
    };
  }

  static distance(notification: WithRef<INotification>): string {
    const distance: string = moment().to(toMoment(notification.createdAt));
    return distance.charAt(0).toUpperCase() + distance.slice(1);
  }
}
