import {
  type IBrand,
  type IInvoice,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  SystemActors,
  WithRef,
  asyncForEach,
  bufferedQuery$,
  multiConcatMap,
  undeletedQuery,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { Invoice } from '../models/invoice/invoice';
import {
  buildAccountSummary,
  getInvoicePaymentSummary,
} from '../models/patient/build-account-summary';
import { Patient } from '../models/patient/patient';
import { AccountCredit } from '../models/account-credit/account-credit';
import { Brand } from '../models/brand';
import { ILogger } from '@principle-theorem/developer-tools';

export class BuildAccountAggregates {
  constructor(private _logger: ILogger) {}

  async run(
    brandRef: DocumentReference<IBrand>,
    dryRun: boolean
  ): Promise<void> {
    await this._getPatients$(brandRef)
      .pipe(multiConcatMap((patient) => this.updatePatient(patient, dryRun)))
      .toPromise();
  }

  async updateInvoice(
    invoice: WithRef<IInvoice>,
    dryRun: boolean
  ): Promise<WithRef<IInvoice>> {
    try {
      const allTransactions = await Firestore.getDocs(
        Invoice.transactionCol(invoice)
      );
      const summary = getInvoicePaymentSummary(invoice, allTransactions);
      if (!dryRun) {
        await Firestore.patchDoc(
          invoice.ref,
          { summary },
          undefined,
          undefined,
          SystemActors.Migration
        );
      }
      return { ...invoice, summary };
    } catch (error) {
      return invoice;
    }
  }

  async updatePatient(
    patient: WithRef<IPatient>,
    dryRun: boolean
  ): Promise<void> {
    const invoices = await Firestore.getDocs(
      undeletedQuery(Patient.invoiceCol(patient))
    );

    await asyncForEach(invoices, (invoice) =>
      this.updateInvoice(invoice, dryRun)
    );

    const credits = await Firestore.getDocs(
      undeletedQuery(AccountCredit.col(patient))
    );
    const accountSummary = await buildAccountSummary(invoices, credits);

    if (!dryRun) {
      this._logger.info(
        `Updating account summary for patient ${patient.ref.path}`
      );
      await Firestore.patchDoc(
        patient.ref,
        { accountSummary },
        undefined,
        undefined,
        SystemActors.Migration
      );
    }
  }

  private _getPatients$(
    brandRef: DocumentReference<IBrand>
  ): Observable<WithRef<IPatient>[]> {
    return bufferedQuery$(Brand.patientCol({ ref: brandRef }), 200, 'ref');
  }
}
