import { type INamedDocument } from '@principle-theorem/shared';
import { type IRole } from '../auth/role';
import { type IStaffer } from '../staffer/staffer';

export interface IRestrictAccessStaffer extends INamedDocument<IStaffer> {
  type: 'staffer';
}

export interface IRestrictAccessRole extends INamedDocument<IRole> {
  type: 'role';
}

export type RestrictAccessEntity = IRestrictAccessStaffer | IRestrictAccessRole;
