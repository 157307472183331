<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Outstanding Invoices"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <h2 class="layout-margin">Outstanding Invoices Report</h2>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
  >
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
    />
    <pr-practice-selector
      subscriptSizing="dynamic"
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
  </div>

  <pr-outstanding-invoices-report [dataBuilder]="dataBuilder" />
</div>
