<ng-container *ngIf="transaction$ | ngrxPush as transaction">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div
      *ngIf="status$ | async as status"
      class="status"
      [ngClass]="status.status"
    >
      {{ status.status | uppercase }}
    </div>

    <div class="provider-id mat-caption flex flex-col items-start">
      <div class="provider">
        {{ transaction.provider | splitCamel | titlecase }}
        <ng-container *ngIf="isRefund$ | async">Refund</ng-container>
      </div>
      <div class="id w-full text-xs text-slate-400">
        {{ transaction.reference }}
      </div>

      @if (showTransactionAllocation$ | async) {
        <pr-transaction-allocation-display
          class="print:hidden"
          [invoice]="invoice$ | async"
          [transaction]="transaction"
        />
      }
    </div>

    <div class="date mat-caption" fxFlex>
      {{ transaction.createdAt | moment | amDateFormat: dateFormat }}

      <ng-container *ngIf="(readonly$ | async) === false">
        <button
          *ngIf="hasHistory$ | async"
          mat-icon-button
          matTooltip="View transaction history"
          (click)="action.viewHistory(transaction)"
        >
          <mat-icon>history</mat-icon>
        </button>
      </ng-container>
    </div>

    <ng-content />

    <div class="price-actions" fxLayout="row" fxLayoutAlign="end center">
      <span class="amount" [class]="{ outgoing: isOutgoing$ | async }">
        <ng-container *ngIf="isOutgoing$ | async">-</ng-container>
        {{ transaction.amount | currency }}
      </span>

      <ng-content select=".actions" />
      @if ((readonly$ | async) === false) {
        <div class="actions">
          @if (actions$ | async; as actions) {
            @if (actions.length) {
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu" yPosition="above" class="no-padding">
                <ng-template matMenuContent>
                  <pr-transaction-menu-actions
                    [data]="actionsData$ | async"
                    [actions]="actions"
                  />
                </ng-template>
              </mat-menu>
            }
          }
        </div>
      }
    </div>
  </div>

  @if (hasExtendedData$ | async) {
    <div class="extended-data-container">
      <div
        class="extended-data-inner rounded bg-slate-200/50 px-2 text-slate-500"
      >
        @if (transaction.description) {
          <span class="description mat-caption">
            {{ transaction.description }}
          </span>
        }
        @if (extendedDataComponent$ | async; as extendedDataComponent) {
          <pt-dynamic-component [definition]="extendedDataComponent" />
        }
      </div>
    </div>
  }
</ng-container>
