import { rand, randCompanyName, randNumber } from '@ngneat/falso';
import {
  IPractice,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';

export class MockTransaction {
  static mockTransaction(overrides?: Partial<ITransaction>): ITransaction {
    return {
      uid: uuid(),
      provider: rand([
        TransactionProvider.Stripe,
        TransactionProvider.AccountCredit,
        TransactionProvider.Cash,
        TransactionProvider.Manual,
      ]),
      reference: uuid(),
      type: rand([TransactionType.Incoming, TransactionType.Outgoing]),
      status: rand([TransactionStatus.Pending, TransactionStatus.Complete]),
      from: randCompanyName(),
      to: randCompanyName(),
      amount: randNumber({ max: 9999 }),
      practiceRef: MockDocRef<IPractice>(),
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      deleted: false,
      ...overrides,
    };
  }
}
