import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { AnyCustomFormElement } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';
import { TypeNarrowedCustomFormElement } from '../lib/type-narrowed-custom-form-element';
import { FormBuilderContentOptionsComponent } from './form-builder-content-options/form-builder-content-options.component';
import { FormBuilderDropdownOptionsComponent } from './form-builder-dropdown-options/form-builder-dropdown-options.component';
import { FormBuilderSectionOptionsComponent } from './form-builder-section-options/form-builder-section-options.component';
import { FormBuilderTextOptionsComponent } from './form-builder-text-options/form-builder-text-options.component';

@Component({
  selector: 'pr-form-builder-element-options',
  templateUrl: './form-builder-element-options.component.html',
  styleUrl: './form-builder-element-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgMaterialModule,
    FormBuilderSectionOptionsComponent,
    FormBuilderContentOptionsComponent,
    FormBuilderTextOptionsComponent,
    FormBuilderDropdownOptionsComponent,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-4' },
})
export class FormBuilderElementOptionsComponent {
  private _element$ = new ReplaySubject<AnyCustomFormElement>(1);
  typed = new TypeNarrowedCustomFormElement(this._element$);

  @Input()
  set element(element: AnyCustomFormElement) {
    this._element$.next(element);
  }
}
