<mat-list-item
  disableRipple
  *ngIf="candidate$ | async as candidate"
  [ngClass]="{ unavailable: isUnavailable$ | async }"
  (click)="openCandidateDetails()"
>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-icon>account_circle</mat-icon>

    <div fxFlex>
      <div>
        {{ candidate.patient.name }} - {{ offeredTimeslot$ | async }}
        <ng-container *ngIf="isUnavailable$ | async">
          - Unavailable</ng-container
        >
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <button
        mat-stroked-button
        *ngIf="(isUnavailable$ | async) === false"
        matTooltipShowDelay="500"
        matTooltip="Mark candidate unavailable for gap"
        [disabled]="isApproved$ | async"
        (click)="updateAvailability($event)"
      >
        Not Available
      </button>

      <button
        *ngIf="(isUnavailable$ | async) === false"
        mat-stroked-button
        matTooltipShowDelay="500"
        matTooltip="Fill gap with candidate appointment"
        (click)="openMoveAppointment($event)"
      >
        Approve
      </button>

      <pr-mention-actions
        [moreButton]="true"
        [resource]="mention$ | async"
        [interactiveResource]="interactiveResource$ | async"
        (click)="$event.stopPropagation()"
       />
    </div>
  </div>
</mat-list-item>
