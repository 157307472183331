import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import {
  DataPointValue,
  ICanGroupMeasuresProperty,
  IChartConfig,
} from '@principle-theorem/reporting';
import { WithId } from '@principle-theorem/shared';
import { Grouping, NaturallyOrderedValue } from 'crossfilter2';
import * as dc from 'dc';
import { compact, get, zipWith } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDCTransformResult } from '../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  ChartBuildHelpers,
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  IDCChartEvent,
  ITableBuilder,
} from './dc-chart-builders/dc-chart-builder';

export interface ITableChartBuilderConfig extends IBaseChartBuilderConfig {}

export const TABLE_GROUP_KEY = 'group';

export const DEFAULT_TABLE_CHART_BUILDER_CONFIG: ITableChartBuilderConfig = {
  ...DEFAULT_CHART_BUILDER_CONFIG,
};

export class TableChartBuilder implements ITableBuilder {
  constructor(
    _config: ITableChartBuilderConfig = DEFAULT_TABLE_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ):
    | ObservableDataSource<{ [key: string]: NaturallyOrderedValue }>
    | undefined {
    if (!config.builderData.groupByDimension || !results.length) {
      return;
    }

    const group = config.builderData.groupByDimension;
    const rows$ = new BehaviorSubject(this._buildRows(results, config, group));

    if (eventListener$) {
      const chart = dc
        .dataTable(D3_CHART_ELEM_SELECTOR)
        .dimension(results[0].dimension);
      dc.registerChart(chart);

      chart.on('preRedraw', () =>
        rows$.next(this._buildRows(results, config, group))
      );
      chart.on('renderlet', () =>
        rows$.next(this._buildRows(results, config, group))
      );
    }

    return new ObservableDataSource(rows$);
  }

  getColumns(
    _results: IDCTransformResult[],
    config: IChartConfig
  ): WithId<{ id: string; label: string }>[] {
    return config.builderData.measures.map((measure) => ({
      id: measure.measure.metadata.id,
      uid: measure.uid,
      label: measure.label ?? measure.measure.metadata.label,
    }));
  }

  private _buildRows(
    results: IDCTransformResult[],
    config: IChartConfig,
    _group: ICanGroupMeasuresProperty
  ): {
    [key: string]: NaturallyOrderedValue;
  }[] {
    const firstResult = results[0];

    const groupColumn = compact(
      [firstResult].map((result) => {
        return (result.group?.all() ?? []).map((data) => ({
          [TABLE_GROUP_KEY]: String(
            get(data, 'key.label', get(data, 'key', ''))
          ),
        }));
      })
    );

    const measureColumns = results.map((result) => {
      const measure = config.builderData.measures.find(
        (builderMeasure) => builderMeasure.uid === result.measureUid
      );
      const key = measure?.label ?? result.measure.metadata.label;
      const filteredResult = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        measure
      );

      let groupedData: readonly Grouping<
        NaturallyOrderedValue,
        NaturallyOrderedValue
      >[] = [];

      if (measure?.filters?.length) {
        groupedData = filteredResult.group?.all() ?? [];
      } else {
        groupedData = result.group?.all() ?? [];
      }

      return groupedData.map((data) => ({
        [key]: data.value.valueOf() as DataPointValue,
      }));
    });

    const rows = zipWith(
      ...compact([...groupColumn, ...measureColumns]),
      (...combinedRows) => {
        return combinedRows.reduce(
          (acc, row) => ({
            ...acc,
            ...row,
          }),
          {} as { [key: string]: NaturallyOrderedValue }
        );
      }
    );
    return rows;
  }
}
