import { TreatmentStepsFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  ChartedSurface,
  ChartedTreatmentUpdater,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type ITreatmentConfiguration,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { asyncForEach, type WithRef } from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
  type IEditTreatmentProvider,
  type TreatmentUpdateFn,
} from '../add-charted-surface-provider';
import { BaseAddTreatmentProvider } from '../base-add-treatment-provider';

export class AddTreatmentToStoreStepProvider
  extends BaseAddTreatmentProvider
  implements IChartedSurfaceProvider, IEditTreatmentProvider
{
  constructor(
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _treatmentStep: WithRef<ITreatmentStep>,
    private _feeSchedule: WithRef<IFeeSchedule>
  ) {
    super();
  }

  async addSurfaceToTreatment(
    event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    await this._treatmentStepsFacade.addTreatmentToStep(
      config,
      surfaces,
      this._treatmentStep,
      this._feeSchedule,
      event.attributedTo
    );
  }

  override async removeSurfacesFromTreatment(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    const foundTreatment = this._treatmentStep.treatments.find(
      (treatment) => treatment.uuid === item.uuid
    );
    if (!foundTreatment) {
      return;
    }

    const chartedSurfaces = ChartedSurface.filterSurfaceRefs(
      foundTreatment.chartedSurfaces,
      surfaceRefs
    );
    if (!chartedSurfaces.length) {
      await this._treatmentStepsFacade.removeTreatmentFromStep(
        foundTreatment.uuid,
        this._treatmentStep.ref
      );
      return;
    }

    const update = { uuid: item.uuid, chartedSurfaces };
    await this._treatmentStepsFacade.updateTreatmentFromStep(
      update,
      this._treatmentStep.ref
    );
  }

  async addTreatments(treatments: IChartedTreatment[]): Promise<void> {
    const combinedTreatments = [
      ...this._treatmentStep.treatments,
      ...treatments,
    ];
    const newTreatments =
      await ChartedTreatmentUpdater.syncPricingRules(combinedTreatments);
    this._treatmentStepsFacade.updateTreatmentStep(this._treatmentStep.ref.id, {
      treatments: newTreatments,
    });
  }

  removeTreatments(treatments: IChartedTreatment[]): void {
    const treatmentStep = treatments.reduce((step, treatment) => {
      return TreatmentStep.removeTreatment(step, treatment);
    }, this._treatmentStep);

    this._treatmentStepsFacade.updateTreatmentStep(this._treatmentStep.ref.id, {
      treatments: treatmentStep.treatments,
    });
  }

  async updateTreatments(
    selectedTreatments: IChartedTreatment[],
    stepTreatments: IChartedTreatment[],
    updateFn: TreatmentUpdateFn
  ): Promise<void> {
    const treatments = await asyncForEach(
      this._treatmentStep.treatments,
      async (chartedTreatment) => {
        const matchingTreatment = selectedTreatments.find(
          (treatment) => treatment.uuid === chartedTreatment.uuid
        );
        return matchingTreatment
          ? updateFn(chartedTreatment, stepTreatments)
          : chartedTreatment;
      }
    );

    this._treatmentStepsFacade.updateTreatmentStep(this._treatmentStep.ref.id, {
      treatments,
    });
  }
}
