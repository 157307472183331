import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  ChartItemDisplayType,
  CHART_ITEM_DISPLAY_TYPES,
  IBrand,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, filterUndefined } from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject, noop } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../dental-chart-svg/chart-entity-id';
import { ChartFacade } from '@principle-theorem/ng-clinical-charting/store';

interface IChartFilterGroup {
  label: string;
  displayTypes: ChartItemDisplayType[];
}

@Component({
    selector: 'pr-chart-filters-selector',
    templateUrl: './chart-filters-selector.component.html',
    styleUrls: ['./chart-filters-selector.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartFiltersSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _chartId = inject(CHART_ENTITY_ID);
  baseFilterGroups = [
    {
      label: 'Conditions',
      displayTypes: [
        ChartItemDisplayType.CurrentCondition,
        ChartItemDisplayType.ResolvedCondition,
      ],
    },
    {
      label: 'Treatments',
      displayTypes: [
        ChartItemDisplayType.ProposedTreatment,
        ChartItemDisplayType.ExistingTreatment,
        ChartItemDisplayType.CompletedTreatment,
      ],
    },
  ];

  filterGroups$: Observable<IChartFilterGroup[]>;
  filters$ = new BehaviorSubject<ChartItemDisplayType[]>(
    CHART_ITEM_DISPLAY_TYPES
  );

  @Output() selectionChange = new EventEmitter<ChartItemDisplayType[]>();

  constructor(
    private _organisation: OrganisationService,
    private _chartStore: ChartFacade
  ) {
    this._chartStore
      .chartContextFilters$(this._chartId)
      .pipe(
        take(1),
        map((selectedFilters) => this.filters$.next(selectedFilters)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.filterGroups$ = this._organisation.brand$.pipe(
      filterUndefined(),
      map((brand) => this._getFilterGroups(brand))
    );
  }

  select(filter: ChartItemDisplayType, event: MouseEvent): void {
    event.stopPropagation();
    const currentFilters = this.filters$.value;
    const index = currentFilters.indexOf(filter);
    if (index < 0) {
      this.filters$.next([...currentFilters, filter]);
    } else {
      this.filters$.next(
        currentFilters.filter((current) => current !== filter)
      );
    }
    this.selectionChange.emit(this.filters$.value);
  }

  isSelected$(filter: ChartItemDisplayType): Observable<boolean> {
    return this.filters$.pipe(map((filters) => filters.includes(filter)));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getFilterGroups(brand: WithRef<IBrand>): IChartFilterGroup[] {
    if (!brand.settings.displayMigratedChartFilter) {
      return this.baseFilterGroups;
    }

    return this.baseFilterGroups.map((group) =>
      group.label === 'Treatments'
        ? {
            ...group,
            displayTypes: [
              ...group.displayTypes,
              ChartItemDisplayType.MigratedCompletedTreatment,
            ],
          }
        : group
    );
  }
}
