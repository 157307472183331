import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Patient,
  TreatmentPlan,
  TreatmentStep,
  TreatmentTemplate,
  type IDefaultOption,
  isDefaultOption,
  type TreatmentPlanStepPair,
  ANY_OPTION,
  type IAppointmentDetails,
} from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IPractice,
  type IStaffer,
  isTreatmentPlanPairFromTemplate,
  type ITreatmentPlan,
  type ITreatmentPlanPairFromTemplate,
  type ITreatmentPlanWithBookableStep,
  type ITreatmentStep,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isChanged$,
  isRefChanged$,
  isSameRef,
  MINIMUM_DURATION,
  multiSwitchMap,
  shareReplayCold,
  STEP_SIZE,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isEqual, sortBy } from 'lodash';
import {
  combineLatest,
  type Observable,
  type OperatorFunction,
  ReplaySubject,
  Subject,
  BehaviorSubject,
} from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { AppointmentDetailsFormGroup } from './appointment-details.formgroup';

@Component({
    selector: 'pr-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentDetailsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  trackByPractitioner = TrackByFunctions.ref<INamedDocument<IStaffer>>();
  trackByTemplatePlanPair =
    TrackByFunctions.nestedField<ITreatmentPlanPairFromTemplate>('step.name');
  trackByPlanPair =
    TrackByFunctions.nestedField<ITreatmentPlanWithBookableStep>('step.name');
  form: AppointmentDetailsFormGroup = new AppointmentDetailsFormGroup();
  errorMessage$: Observable<string> = this.form.errorMessage$();
  durationWarningMessage$: Observable<string>;
  minimumDuration: number = MINIMUM_DURATION;
  stepSize: number = STEP_SIZE;
  practices$: ReplaySubject<WithRef<IPractice>[]> = new ReplaySubject(1);
  practitioners$: ReplaySubject<INamedDocument<IStaffer>[]> = new ReplaySubject(
    1
  );
  planTemplates$: ReplaySubject<ITreatmentPlanPairFromTemplate[]> =
    new ReplaySubject(1);
  treatmentTemplateSearchFilter: InputSearchFilter<
    ITreatmentPlanPairFromTemplate | undefined
  >;
  treatmentPlanSearchFilter: InputSearchFilter<
    TreatmentPlanStepPair | undefined
  >;
  anyPractice: IDefaultOption = ANY_OPTION;
  anyPractitioner: IDefaultOption = ANY_OPTION;
  durationPlaceholder$: Observable<string>;
  isTreatmentTemplate$: Observable<boolean>;
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  patientPlans$: Observable<ITreatmentPlanWithBookableStep[]>;
  allPatientPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  hasActivePlans$ = new BehaviorSubject<boolean>(false);
  treatmentDisabled$ = this.form.controls.treatment.statusChanges.pipe(
    map((status) => status === 'DISABLED'),
    startWith(this.form.controls.treatment.status)
  );
  filteredPractitioners$: Observable<INamedDocument<IStaffer>[]>;
  appointmentDetails$ = new ReplaySubject<Partial<IAppointmentDetails>>(1);
  selectedTreatment$: Observable<TreatmentPlanStepPair | undefined>;
  overridePlanControl = new TypedFormControl<WithRef<ITreatmentPlan>>();
  selectedOverridePlan$: Observable<WithRef<ITreatmentPlan> | undefined>;
  trackByPlan = TrackByFunctions.ref<WithRef<ITreatmentPlan>>();

  @Output()
  appointmentDetailsChange = new EventEmitter<Partial<IAppointmentDetails>>();

  @Input()
  set appointmentDetails(details: Partial<IAppointmentDetails>) {
    if (details) {
      this.appointmentDetails$.next(details);
    }
  }

  @Input()
  set planTemplates(planTemplates: ITreatmentPlanPairFromTemplate[]) {
    if (planTemplates) {
      this.planTemplates$.next(planTemplates);
    }
  }

  @Input()
  set practices(practices: WithRef<IPractice>[]) {
    if (practices) {
      this.practices$.next(practices);
      practices.length === 1
        ? this.form.controls.practice.disable({ emitEvent: false })
        : this.form.controls.practice.enable({ emitEvent: false });
    }
  }

  @Input()
  set practitioners(practitioners: INamedDocument<IStaffer>[]) {
    if (practitioners) {
      this.practitioners$.next(practitioners);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _appointmentSchedulingFacade: AppointmentSchedulingFacade
  ) {
    this.patientPlans$ = this.patient$.pipe(
      this._getPatientPlans(),
      startWith([])
    );
    this.durationWarningMessage$ =
      this._appointmentSchedulingFacade.durationWarningMessage$;
    this.durationPlaceholder$ =
      this._appointmentSchedulingFacade.durationPlaceholder$.asObservable();
    this.selectedTreatment$ =
      this._appointmentSchedulingFacade.appointmentDetails$.pipe(
        map((details) => details.treatment)
      );

    this.allPatientPlans$ = this.patient$.pipe(
      isRefChanged$(),
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          TreatmentPlan.all$
        ).pipe(take(1))
      ),
      switchMap((plans) => TreatmentPlan.sortPlansByMostRecentStep(plans))
    );

    this.allPatientPlans$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((plans) => {
        this.hasActivePlans$.next(
          plans.some((plan) => TreatmentPlan.canSchedule(plan))
        );

        if (!plans.length) {
          this.overridePlanControl.reset();
          return;
        }

        const firstActivePlan = plans.find((plan) =>
          TreatmentPlan.canSchedule(plan)
        );
        this.overridePlanControl.setValue(firstActivePlan ?? plans[0]);
      });

    this.selectedOverridePlan$ = this.overridePlanControl.valueChanges;

    this.appointmentDetails$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((details) =>
        this.form.patchValue(details, { emitEvent: false })
      );

    const filteredTemplates$ = combineLatest([
      this.planTemplates$.pipe(
        map((plans) =>
          plans.filter((plan): plan is ITreatmentPlanPairFromTemplate =>
            isTreatmentPlanPairFromTemplate(plan)
          )
        ),
        map((plans) => sortBy(plans, 'plan.name'))
      ),
      this.appointmentDetails$,
    ]).pipe(
      map(([templates, { practice, practitioner }]) =>
        templates.filter((template) =>
          treatmentTemplateHasImplementor(template, practice, practitioner)
        )
      ),
      shareReplayCold()
    );

    this.filteredPractitioners$ = combineLatest([
      this.form.selectedTreatment$(),
      this.practitioners$,
    ]).pipe(
      map(([treatment, practitioners]) => {
        if (!isTreatmentPlanPairFromTemplate(treatment)) {
          return practitioners;
        }
        return practitioners.filter((practitioner) =>
          TreatmentTemplate.hasImplementor(
            treatment.step.template,
            practitioner
          )
        );
      })
    );

    const treatmentSearchStream = toSearchStream(this.form.controls.treatment);

    this.treatmentTemplateSearchFilter = new InputSearchFilter(
      filteredTemplates$,
      treatmentSearchStream.pipe(
        map((stream) => stream as ITreatmentPlanPairFromTemplate | undefined)
      ),
      ['plan.name', 'step.name']
    );

    this.treatmentPlanSearchFilter = new InputSearchFilter(
      this.patientPlans$,
      treatmentSearchStream,
      ['plan.name', 'step.name']
    );

    this.isTreatmentTemplate$ = this.form.controls.treatment.valueChanges.pipe(
      map((treatment) =>
        treatment ? isTreatmentPlanPairFromTemplate(treatment) : false
      )
    );

    this._getPreferredPractitioner$()
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practitioner) => {
        if (!isDefaultOption(this.form.controls.practitioner.value)) {
          return;
        }
        this.form.controls.practitioner.setValue(practitioner);
      });

    this._appointmentSchedulingFacade.selectedTreatmentDuration$
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((duration) =>
        this.appointmentDetailsChange.emit({
          duration,
        })
      );

    this.form.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe(() =>
        this.appointmentDetailsChange.emit(this.form.getRawValue())
      );

    this.overridePlanControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((overridePlan) =>
        this.appointmentDetailsChange.emit({
          overridePlan: overridePlan ?? undefined,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearTreatment(): void {
    this.form.controls.treatment.reset();
  }

  setPlan(plan?: WithRef<ITreatmentPlan>): void {
    plan
      ? this.overridePlanControl.setValue(plan)
      : this.overridePlanControl.reset();
  }

  isMostRecentPlan(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    return this.allPatientPlans$.pipe(
      map((patientPlans) => this.filterPlans(patientPlans)),
      map((plans) => isSameRef(plan, plans[0]))
    );
  }

  filterPlans(plans: WithRef<ITreatmentPlan>[]): WithRef<ITreatmentPlan>[] {
    const filterBy = this.hasActivePlans$.value
      ? TreatmentPlan.canSchedule
      : TreatmentPlan.isComplete;
    return plans.filter((plan) => filterBy(plan));
  }

  displayFn(value: string | TreatmentPlanStepPair): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (isTreatmentPlanPairFromTemplate(value)) {
      return value.plan.name;
    }
    return value.step.name;
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    try {
      return isSameRef(namedDocument, selectedNamedDocument);
    } catch (error) {
      return isEqual(namedDocument, selectedNamedDocument);
    }
  }

  getStepDuration(step: WithRef<ITreatmentStep>): Observable<number> {
    return TreatmentStep.getDuration$(step);
  }

  private _getPreferredPractitioner$(): Observable<
    INamedDocument<IStaffer> | IDefaultOption
  > {
    return combineLatest([
      this.practitioners$.pipe(isChanged$()),
      this.patient$.pipe(map((patient) => patient.preferredDentist)),
    ]).pipe(
      map(([practitioners, preferredPractitioner]) => {
        if (!preferredPractitioner) {
          return this.anyPractitioner;
        }
        return practitioners.some((practitioner) =>
          isSameRef(practitioner.ref, preferredPractitioner.ref)
        )
          ? preferredPractitioner
          : this.anyPractitioner;
      })
    );
  }

  private _getPatientPlans(): OperatorFunction<
    WithRef<IPatient>,
    ITreatmentPlanWithBookableStep[]
  > {
    return (source) =>
      source.pipe(
        switchMap((patient) =>
          Patient.withPatientRelationships$(
            patient,
            [PatientRelationshipType.DuplicatePatient],
            TreatmentPlan.all$
          )
        ),
        multiSwitchMap(async (plan) => {
          const step = await TreatmentPlan.nextBookableTreatmentStep(plan);
          if (!step) {
            return;
          }
          return {
            plan,
            step,
          };
        }),
        map(compact)
      );
  }
}

function treatmentTemplateHasImplementor(
  template: ITreatmentPlanPairFromTemplate,
  practice?: WithRef<IPractice>,
  practitioner?: INamedDocument<IStaffer> | IDefaultOption
): boolean {
  if ((!practice && !practitioner) || isDefaultOption(practitioner)) {
    return true;
  }

  if (practice && practitioner) {
    return (
      TreatmentTemplate.practiceIsEnabled(template.step.template, practice) &&
      TreatmentTemplate.hasImplementor(template.step.template, practitioner)
    );
  }

  if (practitioner) {
    return TreatmentTemplate.hasImplementor(
      template.step.template,
      practitioner
    );
  }

  if (practice) {
    return TreatmentTemplate.practiceIsEnabled(
      template.step.template,
      practice
    );
  }
  return false;
}
