import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { ProductListComponent } from './product-list/product-list.component';
import { UpsertProductDialogComponent } from './upsert-product-dialog/upsert-product-dialog.component';

@NgModule({
  declarations: [
    ProductListComponent,
    ProductListItemComponent,
    UpsertProductDialogComponent,
  ],
  imports: [
    NgSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    NgPrincipleAccountingModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
  ],
  exports: [ProductListComponent, ProductListItemComponent],
})
export class ProductsModule {}
