import { type DocumentReference } from '@principle-theorem/shared';
import {
  type IFirestoreModel,
  type IReffable,
} from '@principle-theorem/shared';
import { type IBrand } from '../../../brand';
import { type IOrganisation } from '../../../organisation/organisation';
import { type IPractice } from '../../../practice/practice';
import { type IStaffer } from '../../../staffer/staffer';
import {
  type IScopedServiceCode,
  type ServiceCodeType,
} from '../../service-provider/service-item';
import { type IPricingRule } from '../pricing-rules/pricing-rule-provider';
import { ITreatmentConfiguration } from '../../treatment/treatment-configuration';
import { TaxStrategy } from '@principle-theorem/accounting';

export interface IServiceCodeFee {
  serviceCode: IScopedServiceCode;
  pricingRule: IPricingRule;
}

export interface ITreatmentFee {
  treatment: DocumentReference<ITreatmentConfiguration>;
  price: number;
  taxStrategy: TaxStrategy;
}

export interface IFeeSchedule extends IFirestoreModel {
  name: string;
  serviceCodeType?: ServiceCodeType;
  serviceCodes: IServiceCodeFee[];
  treatmentFees?: ITreatmentFee[];
  inheritedFrom?: DocumentReference<IFeeSchedule>;
}

export type HasFeeSchedules =
  | IReffable<IStaffer>
  | IReffable<IPractice>
  | IReffable<IBrand>
  | IReffable<IOrganisation>;

export enum FeeScheduleType {
  Staffer = 'staffer',
  Practice = 'practice',
  Brand = 'brand',
  Organisation = 'organisation',
}

export const DEFAULT_FEE_SCHEDULE_NAME = 'Default';
