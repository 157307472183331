import { getEnumValues } from '../common';
import { compact } from 'lodash';
import { type Timestamp } from '../firebase/firestore/adaptor';
import { ISODateType, Timezone } from './timezone';
import { DAY_OF_WEEK_FORMAT } from './date-time-formatting';
import { toMomentTz } from './time';
import * as moment from 'moment-timezone';

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum ISODayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const DAYS_OF_WEEK: DayOfWeek[] = getEnumValues(DayOfWeek);
export const WEEKEND_DAYS: DayOfWeek[] = [DayOfWeek.Saturday, DayOfWeek.Sunday];
export const WEEK_DAYS: DayOfWeek[] = DAYS_OF_WEEK.filter(
  (day) => !WEEKEND_DAYS.includes(day)
);

const tempDaysOfWeek = getEnumValues(DayOfWeek);
tempDaysOfWeek.unshift(...compact([tempDaysOfWeek.pop()]));
export const CALENDAR_DAYS_OF_WEEK: DayOfWeek[] = tempDaysOfWeek;

export function toDayOfWeek(
  date: moment.Moment | Timestamp | Date | ISODateType,
  timezone: Timezone
): DayOfWeek {
  return toMomentTz(date, timezone)
    .format(DAY_OF_WEEK_FORMAT)
    .toLowerCase() as DayOfWeek;
}
