<div class="flex">
  <mat-form-field class="flex-1">
    <mat-label>Confirm Treatment Steps</mat-label>
    <mat-select [formControl]="selectedCtrl">
      <mat-option
        *ngFor="let option of options$ | async; trackBy: trackByOption"
        [value]="option"
      >
        {{ option.name }}
        <ng-container *ngIf="option.treatmentStep.duration">
          {{ option.treatmentStep.duration }}mins -
        </ng-container>
        {{ option.treatmentStep.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
