<ng-container *ngIf="groupedServiceCodes$ | async as serviceCodes">
  <div class="items-container flex flex-col gap-1">
    <div
      *ngFor="let serviceCode of serviceCodes; trackBy: trackByCode"
      class="flex flex-col gap-1"
    >
      <pr-service-item
        [serviceCode]="serviceCode"
        [compact]="compact"
        [disabled]="disabled"
        (itemUpdate)="itemUpdate(serviceCodes, serviceCode, $event)"
        (addServiceCode)="addServiceCode(serviceCodes, $event)"
      >
        <div class="action-container flex">
          <button
            *ngIf="!disabled"
            mat-icon-button
            class="icon-sm opacity-50"
            [disabled]="serviceCode.priceOverride | map : resetPriceDisabled"
            (click)="resetPrice(serviceCodes, serviceCode)"
            matTooltip="Reset to Fee Schedule price"
            tabindex="-1"
          >
            <mat-icon>restart_alt</mat-icon>
          </button>
          <button
            *ngIf="!disabled"
            class="delete icon-sm opacity-50"
            mat-icon-button
            color="warn"
            (click)="delete(serviceCodes, serviceCode)"
            matTooltip="Delete Service Code"
            tabindex="-1"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </pr-service-item>
      <pr-service-pricing-rule-item-select
        *ngIf="serviceCode.pricingRule.type !== 'flat'"
        [disabled]="disabled"
        [pricingRule]="serviceCode.pricingRule"
        (resetPrice)="resetPrice(serviceCodes, serviceCode)"
        (updateSelectedRuleItem)="
          updateSelectedRuleItem(serviceCodes, serviceCode, $event)
        "
      />
    </div>

    <pr-add-service-code
      *ngIf="!disabled && !searchDisabled"
      class="flex"
      [commonCodes]="commonCodes$ | async"
      (codeSelected)="addServiceCode(serviceCodes, $event)"
    />
  </div>
</ng-container>
