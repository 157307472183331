<button mat-icon-button [matMenuTriggerFor]="actionsMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item>
    <ng-container *ngIf="(isPending$ | async) || (isCancelled$ | async)">
      <button mat-menu-item (click)="markActive()">Set As Active</button>
    </ng-container>
    <ng-container *ngIf="isActive$ | async">
      <button mat-menu-item (click)="markCancelled()">Set As Cancelled</button>
    </ng-container>
    <ng-container *ngIf="isActive$ | async">
      <button mat-menu-item (click)="markPending()">Set As Pending</button>
    </ng-container>
  </button>
</mat-menu>
