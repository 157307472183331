import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  TreatmentPlanFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartedTreatmentUpdater,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  TreatmentStepStatus,
  type IChartedTreatment,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  getEnumValues,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type Observable,
  ReplaySubject,
  BehaviorSubject,
  combineLatest,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StepDragDropGroup } from '../../step-drag-drop-group';

@Component({
  selector: 'pr-treatment-plan-steps',
  templateUrl: './treatment-plan-steps.component.html',
  styleUrls: ['./treatment-plan-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanStepsComponent {
  trackByTreatmentStep = TrackByFunctions.ref<WithRef<ITreatmentStep>>();
  treatmentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  treatmentSteps$: Observable<WithRef<ITreatmentStep>[]>;
  @Input() stepDragDrop = new StepDragDropGroup();
  @Input() disabled = false;
  @Input() canAddStep = false;
  @Output() addStep = new EventEmitter<void>();
  currentPractitioner$ = new ReplaySubject<WithRef<IStaffer>>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  treatmentPlanManagePermission = [PatientPermissions.TreatmentPlanManage];
  filterStepStatuses$ = new BehaviorSubject<TreatmentStepStatus[] | undefined>(
    getEnumValues(TreatmentStepStatus)
  );

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set currentPractitioner(currentPractitioner: WithRef<IStaffer>) {
    if (currentPractitioner) {
      this.currentPractitioner$.next(currentPractitioner);
    }
  }

  @Input()
  set treatmentPlan(treatmentPlan: WithRef<ITreatmentPlan>) {
    if (treatmentPlan) {
      this.treatmentPlan$.next(treatmentPlan);
    }
  }

  @Input()
  set filterStepStatuses(filterStepStatuses: TreatmentStepStatus[]) {
    if (filterStepStatuses) {
      this.filterStepStatuses$.next(filterStepStatuses);
    }
  }

  constructor(
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _treatmentStepsFacade: TreatmentStepsFacade
  ) {
    this.treatmentSteps$ = combineLatest([
      this.treatmentPlan$,
      this.filterStepStatuses$,
    ]).pipe(
      switchMap(([treatmentPlan, stepStatuses]) =>
        TreatmentPlan.orderedSteps$(treatmentPlan).pipe(
          map((steps) =>
            steps.filter((step) =>
              stepStatuses && stepStatuses.length
                ? stepStatuses.includes(step.status)
                : true
            )
          )
        )
      )
    );
  }

  isStepComplete(step: ITreatmentStep, plan: ITreatmentPlan): boolean {
    return TreatmentStep.isComplete(step) || TreatmentPlan.isComplete(plan);
  }

  async deleteStep(step: WithRef<ITreatmentStep>): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    this._treatmentPlanFacade.removeStep(plan, step);
  }

  updateStep(step: WithRef<ITreatmentStep>): void {
    this._treatmentStepsFacade.updateTreatmentStep(step.ref.id, step);
  }

  async updateTreatments(
    step: WithRef<ITreatmentStep>,
    treatments: IChartedTreatment[]
  ): Promise<void> {
    await patchDoc(step.ref, {
      treatments: await ChartedTreatmentUpdater.syncPricingRules(treatments),
    });
  }
}
