import {
  IExpectedSourceRecordSize,
  IPracticeMigration,
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateTimeType,
  ISODateType,
  ISOTimeType,
  Timestamp,
  TypeGuard,
  WithRef,
  isISODateTimeType,
  isISODateType,
  isISOTimeType,
  toISODate,
  toTimestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_APPOINTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisPatientAppointment {
  id: string;
  patientId: number;
  practitionerId: number;
  appointmentPractitionerId: number;
  treatmentStepId?: number;
  date: ISODateType;
  time: ISOTimeType;
  from: ISODateTimeType;
  performedAt?: ISODateTimeType;
  duration: number;
  createdAt: ISODateTimeType;
  createdBy: string;
  categoryId: number;
  categoryCode: string;
  referenceId?: number;
  isConfirmed: boolean;
  amount?: number;
  updatedAt: ISODateTimeType;
  treatmentSummary?: string;
  notes?: string;
}

export function isOasisPatientAppointment(
  item: unknown
): item is IOasisPatientAppointment {
  return TypeGuard.interface<IOasisPatientAppointment>({
    id: isString,
    patientId: isNumber,
    practitionerId: isNumber,
    appointmentPractitionerId: isNumber,
    treatmentStepId: TypeGuard.nilOr(isNumber),
    date: isISODateType,
    time: isISOTimeType,
    from: isISODateTimeType,
    performedAt: TypeGuard.nilOr(isISODateTimeType),
    duration: isNumber,
    createdAt: isISODateTimeType,
    createdBy: isString,
    categoryId: isNumber,
    categoryCode: isString,
    referenceId: TypeGuard.nilOr(isNumber),
    isConfirmed: isBoolean,
    amount: TypeGuard.nilOr(isNumber),
    updatedAt: isISODateTimeType,
    treatmentSummary: TypeGuard.nilOr(isString),
    notes: TypeGuard.nilOr(isString),
  })(item);
}

export interface IOasisPatientAppointmentTranslations {
  createdAt: Timestamp;
  from: Timestamp;
  to: Timestamp;
}

export interface IOasisPatientAppointmentFilters {
  practitionerId?: number;
  patientId: number;
  from: Timestamp;
  date: ISODateType;
}

const PATIENT_APPOINTMENT_SOURCE_QUERY = `
SELECT room.performed_at, appointment.* FROM (
	SELECT
		SKEY AS id,
		convert_to_integer(PATNUMBER) AS patient_id,
		DRNUMBER AS practitioner_id,
		NULLIF(REFNUMBER, 0) as treatment_step_id,
		REGEXP_REPLACE(SKEY,'([0-9]{4})([0-9]{2})([0-9]{2})(.*)','\\1-\\2-\\3') AS date,
    convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{9})([0-9]{7})(.*)','\\2')) AS appointment_practitioner_id,
		to_char(
      NULLIF(CONCAT(
			  REGEXP_REPLACE(SKEY,'([0-9]{16})([0-9]{2})(.*)','\\2'),
			  ':',
			  RPAD((convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{18})([0-9]{2})(.*)','\\2'))* 5 - 5)::TEXT, 2, '0')
		  ), '')::TIME,
      'HH24:MI:SS'
    ) AS time,
    to_char(
      convert_to_date(REGEXP_REPLACE(SKEY,'([0-9]{4})([0-9]{2})([0-9]{2})(.*)','\\1-\\2-\\3'), 'YYYY-MM-DD')
      +
      NULLIF(CONCAT(
        REGEXP_REPLACE(SKEY,'([0-9]{16})([0-9]{2})(.*)','\\2'),
        ':',
        RPAD((convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{18})([0-9]{2})(.*)','\\2'))* 5 - 5)::TEXT, 2, '0')
      ), '')::TIME,
      'YYYY-MM-DD HH24:MI:SS'
    ) AS from,
		TIMEUSED * 5 AS duration,
    CASE
      WHEN strpos(ENTRYDATE, '/') > 0
        THEN to_char(
          convert_to_date(ENTRYDATE, 'DD/MM/YYYY')
          +
          ENTRYTIME::TIME,
          'YYYY-MM-DD HH:MI:SS'
        )
      ELSE to_char(
        convert_to_date(ENTRYDATE, 'YYYY-MM-DD')
        +
        ENTRYTIME::TIME,
        'YYYY-MM-DD HH:MI:SS'
      )
		END AS created_at,
		ENTRYUSER AS created_by,
		COLORNUMBER AS category_id,
		SORTCODE AS category_code,
		NULLIF(convert_to_integer(AMOUNT), 0) AS amount,
		CASE WHEN CONFIRMEDCODE = 'C'
			THEN TRUE
			ELSE FALSE
		  END AS is_confirmed,
    CASE
      WHEN strpos(LASTUPDATE, '/') > 0
        THEN to_char(
          convert_to_timestamp(LASTUPDATE, 'DD/MM/YYYY HH:MI:SS AM'),
          'YYYY-MM-DD HH:MI:SS'
        )
      ELSE to_char(
        convert_to_timestamp(LASTUPDATE, 'YYYY-MM-DD HH24:MI:SS'),
        'YYYY-MM-DD HH:MI:SS'
      )
		END AS updated_at,
		details AS treatment_summary,
		NULLIF(REPLACE(NOTES, '��', '\r\n'), '') AS notes
	FROM PAAPPLNS
	WHERE
		SKEY NOT LIKE '9999%'
		AND NULLIF(convert_to_integer(PATNUMBER), 0) IS NOT NULL
    AND to_char(
      NULLIF(
        CONCAT(
          REGEXP_REPLACE(SKEY,'([0-9]{16})([0-9]{2})(.*)','\\2'),
          ':',
          RPAD((convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{18})([0-9]{2})(.*)','\\2'))* 5 - 5)::TEXT, 2, '0')
        ),
        ''
      )::TIME,
      'HH24:MI:SS'
    ) >= '06:00:00'
  ORDER BY SKEY
  ${OFFSET_PLACEHOLDER}
) AS appointment
LEFT JOIN (
	SELECT
		SKEY::TEXT AS id,
		convert_to_integer(PATNUMBER) AS patient_id,
		DRNUMBER AS practitioner_id,
		to_char(
      convert_to_date(REGEXP_REPLACE(SKEY::TEXT,'([0-9]{4})([0-9]{2})([0-9]{2})(.*)','\\1-\\2-\\3'), 'YYYY-MM-DD')
      +
      NULLIF(APPTIME, '')::TIME
      ,
      'YYYY-MM-DD HH:MI:SS'
  ) AS performed_at
	FROM PAWTROOS
) AS room
ON
	room.patient_id = appointment.patient_id
	AND room.practitioner_id = appointment.practitioner_id
	AND room.performed_at = appointment.from
`;

const PATIENT_APPOINTMENT_ESTIMATE_QUERY = `
SELECT
	SKEY AS id
FROM PAAPPLNS
WHERE
	SKEY NOT LIKE '9999%'
	AND NULLIF(convert_to_integer(PATNUMBER), 0) IS NOT NULL
  AND to_char(
    NULLIF(
      CONCAT(
        REGEXP_REPLACE(SKEY,'([0-9]{16})([0-9]{2})(.*)','\\2'),
        ':',
        RPAD((convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{18})([0-9]{2})(.*)','\\2'))* 5 - 5)::TEXT, 2, '0')
      ),
      ''
    )::TIME,
    'HH24:MI:SS'
  ) >= '06:00:00'
ORDER BY SKEY
`;

export class PatientAppointmentSourceEntity extends BaseSourceEntity<
  IOasisPatientAppointment,
  IOasisPatientAppointmentTranslations,
  IOasisPatientAppointmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_SOURCE_QUERY;
  verifySourceFn = isOasisPatientAppointment;
  allowOffsetJob = true;
  override defaultOffsetSize = 25000;

  translate(
    data: IOasisPatientAppointment,
    timezone: Timezone
  ): IOasisPatientAppointmentTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
      from: toTimestamp(moment.tz(data.from, timezone)),
      to: toTimestamp(
        moment.tz(data.from, timezone).clone().add(data.duration, 'minutes')
      ),
    };
  }

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: string }>(
      migration,
      PATIENT_APPOINTMENT_ESTIMATE_QUERY
    );

    return {
      expectedSize: response.rows.length,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  getSourceRecordId(data: IOasisPatientAppointment): string {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientAppointment): string {
    return data.id.toString();
  }

  getFilterData(
    data: IOasisPatientAppointment,
    timezone: Timezone
  ): IOasisPatientAppointmentFilters {
    return {
      practitionerId: data.practitionerId,
      patientId: data.patientId,
      from: toTimestamp(moment.tz(data.from, timezone)),
      date: toISODate(moment.tz(data.from, timezone)),
    };
  }
}
