import {
  type Arch,
  type IDentalChartViewArch,
  type IDentalChartViewSurface,
} from '@principle-theorem/principle-core/interfaces';
import { ChartElement, type IChartElement } from './chart-element';

export interface IChartArch extends IChartElement {
  arch: Arch;
  display: IDentalChartViewArch;
}

export class ChartArch extends ChartElement {
  constructor(public arch: Arch, public display: IDentalChartViewSurface) {
    super();
    this.height = 125;
  }

  override toInterface(): IChartArch {
    return {
      ...super.toInterface(),
      arch: this.arch,
      display: this.display,
    };
  }
}
