<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding mx-auto max-w-screen-2xl">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    @for (link of navLinks; track $index) {
      <a
        mat-tab-link
        [routerLink]="link.path"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ link.label }}
      </a>
    }
  </nav>

  <mat-tab-nav-panel #tabPanel>
    <div class="layout-margin">
      <pr-sterilisation-table-filters>
        <pt-buttons-container>
          <span
            matTooltip="No machines configured"
            *ngrxLet="store.canAddSteriCycle$ as canAddSteriCycle"
            [matTooltipDisabled]="canAddSteriCycle"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="addSteriCycle()"
              [disabled]="!canAddSteriCycle"
            >
              Add Cycle
            </button>
          </span>

          <button mat-flat-button color="primary" (click)="addSteriRecord()">
            Add Record
          </button>
        </pt-buttons-container>
      </pr-sterilisation-table-filters>
    </div>

    <router-outlet />
  </mat-tab-nav-panel>
</div>
