import {
  DestinationEntityRecordStatus,
  IArraySorter,
  IDestinationEntityHandler,
  IDestinationEntityJobRunOptions,
  IGetRecordResponse,
  ISourceEntityHandler,
  ISourceEntityRecord,
  SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  asDocRef,
  bufferedQuery$,
  errorNil,
  filterUndefined,
  find$,
  multiConcatMap,
  query$,
  toQuery,
  where,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { iif, type Observable } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';
import { buildFilterMigratedQuery } from '../source/source-entity-record';
import { type TranslationMapHandler } from '../translation-map';
import { DestinationEntity } from './destination-entity';
import {
  type IDestinationJobFilter,
  type IDestinationJobFilterHandler,
} from './destination-job-filter';

export abstract class BaseDestinationEntity<
  SuccessRecordData extends object,
  InitialJobData extends object,
  BuiltMigrationData extends object,
  MigratedWithErrorsData extends object = object,
> implements
    IDestinationEntityHandler<
      SuccessRecordData,
      InitialJobData,
      BuiltMigrationData
    >
{
  filters: IDestinationJobFilterHandler<InitialJobData>[] = [];
  sorters: IArraySorter[] = [];
  batchLimit = 5000;

  abstract destinationEntity: IDestinationEntity;

  abstract getDestinationEntityRecordUid(data: InitialJobData): string;

  abstract sourceCountDataAccessor(
    data: InitialJobData
  ): DocumentReference<ISourceEntityRecord>;

  abstract buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<InitialJobData[]>;

  abstract buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: InitialJobData
  ):
    | Promise<
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      >
    | (
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      );

  abstract hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: BuiltMigrationData
  ): Promise<BuiltMigrationData | undefined> | (BuiltMigrationData | undefined);

  abstract buildMergeConflictRecord(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord;

  abstract runJob(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): Promise<IDestinationEntityRecord>;

  canMigrateByDateRange = false;
  canMigrateByIdRange = false;
  sourceEntities = {};
  destinationEntities = {};

  canHandle(destination: IDestinationEntity): boolean {
    return isEqual(
      destination.metadata.key,
      this.destinationEntity.metadata.key
    );
  }

  buildSourceRecordQuery$<
    In extends object = object,
    Translations = unknown,
    Filters extends object = object,
  >(
    migration: IReffable<IPracticeMigration>,
    sourceEntity: ISourceEntityHandler<In[], Translations, Filters>,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IGetRecordResponse<In, Translations, Filters>[]> {
    const sourceRecords$ = sourceEntity.getRecords$(
      migration,
      1000,
      this.batchLimit,
      buildFilterMigratedQuery(
        !!runOptions.skipMigrated,
        !!runOptions.retryMigrated,
        this.destinationEntity
      )
    );

    const failedRecords$ = this.getFailedSourceEntities$<Filters>(
      migration,
      1000,
      this.batchLimit
    ).pipe(
      multiConcatMap((source) => sourceEntity.combineRecordWithData$(source))
    );

    return iif(() => !!runOptions.retryFailed, failedRecords$, sourceRecords$);
  }

  getFailedSourceEntities$<Filters extends object = object>(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number = 100,
    limit?: number
  ): Observable<WithRef<ISourceEntityRecord<Filters>>[]> {
    return PracticeMigration.findDestinationEntity$(
      migration,
      this.destinationEntity
    ).pipe(
      filterUndefined(),
      switchMap((destinationEntity) =>
        bufferedQuery$(
          toQuery(
            DestinationEntity.recordCol(destinationEntity),
            where('status', '==', DestinationEntityRecordStatus.Failed)
          ),
          bufferSize,
          'ref',
          undefined,
          undefined,
          limit
        )
      ),
      multiConcatMap((destination) =>
        Firestore.getDoc(
          asDocRef<ISourceEntityRecord<Filters>>(destination.sourceRef)
        )
      )
    );
  }

  getEntity$(
    migration: IReffable<IPracticeMigration>
  ): Observable<IDestinationEntity | undefined> {
    return find$(
      PracticeMigration.destinationEntityCol(migration),
      where('metadata.key', '==', this.destinationEntity.metadata.key)
    );
  }

  getCollection$(
    migration: IReffable<IPracticeMigration>
  ): Observable<
    CollectionReference<IDestinationEntityRecord<SuccessRecordData>>
  > {
    return PracticeMigration.findDestinationEntity$(
      migration,
      this.destinationEntity
    ).pipe(
      take(1),
      errorNil(
        `Could not find destination entity ${this.destinationEntity.metadata.label}`
      ),
      map((destinationEntity) =>
        DestinationEntity.recordCol({
          ref: destinationEntity.ref,
        })
      )
    );
  }

  getRecords$(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number = 50,
    initialRecord?: WithRef<IDestinationEntityRecord<SuccessRecordData>>
  ): Observable<WithRef<IDestinationEntityRecord<SuccessRecordData>>[]> {
    return this.getCollection$(migration).pipe(
      concatMap((collection) =>
        bufferedQuery$(collection, bufferSize, 'ref', 'asc', initialRecord)
      )
    );
  }

  shouldSkipRecord(
    jobData: InitialJobData,
    filters: IDestinationJobFilter[]
  ): boolean {
    return filters.some((filter) => {
      const handler = this.filters.find((filterHandler) =>
        filterHandler.canHandle(filter)
      );
      if (!handler) {
        throw new Error(`No filter handler for ${filter.key}`);
      }

      return !handler.matches(jobData, filter);
    });
  }

  getMigrationsWithErrors$(
    migration: WithRef<IPracticeMigration>
  ): Observable<WithRef<MigratedWithErrorsData>[]> {
    return this.getCollection$(migration).pipe(
      switchMap((collection) =>
        query$<MigratedWithErrorsData>(
          collection as CollectionReference<MigratedWithErrorsData>,
          where(
            'status',
            '==',
            DestinationEntityRecordStatus.MigratedWithErrors
          )
        )
      )
    );
  }
}
