import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  CurrentScopeFacade,
  GapStoreService,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { EventableQueries } from '@principle-theorem/principle-core';
import {
  CalendarUnit,
  CalendarView,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, toTimePeriod } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-gaps',
    templateUrl: './gaps.component.html',
    styleUrls: ['./gaps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  constructor(
    public gapStore: GapStoreService,
    private _currentScope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _globalStore: GlobalStoreService
  ) {
    const practice$ =
      this._currentScope.currentPractice$.pipe(filterUndefined());

    const timePeriod$ = practice$.pipe(
      map((practice) =>
        toTimePeriod(
          moment.tz(practice.settings.timezone).startOf('day'),
          moment.tz(practice.settings.timezone).add(4, 'days').endOf('day')
        )
      )
    );
    const scheduleSummaryEventables$ = combineLatest([
      timePeriod$,
      practice$,
      this._organisation.practicePractitioners$,
      this._globalStore.rosterSchedules$,
    ]).pipe(
      switchMap(([timePeriod, practice, practitioners, rosterSchedules]) =>
        EventableQueries.getScheduleSummaryEventablesWithFallback$(
          timePeriod,
          practice,
          practitioners,
          rosterSchedules,
          [],
          CalendarUnit.Day,
          CalendarView.Timeline
        )
      )
    );

    combineLatest([scheduleSummaryEventables$, practice$, timePeriod$, of([])])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((sources) => this.gapStore.setGaps(sources));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
