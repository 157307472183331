<div
  #tooltipOrigin="cdkOverlayOrigin"
  class="timeline-event"
  cdkOverlayOrigin
  [ngStyle]="eventStyle$ | ngrxPush"
>
  <pr-timeline-pending-gap-badge
    *ngIf="pendingGap$ | async as pendingGap"
    class="absolute right-3 top-0"
    size="small"
    [pendingGap]="pendingGap"
  />

  <div class="timeline-event-inset">
    <div class="flex gap-1">
      <div class="grow">
        <span>
          {{ eventLabel$ | ngrxPush }}
        </span>

        <div class="display-body">
          <pr-eventable-tags-preview
            *ngIf="hasTags$ | async"
            [eventable]="event$ | async"
            [dense]="true"
          />
          <div
            *ngFor="
              let pinnedNote of pinnedNotes$ | async;
              last as isLast;
              trackBy: trackByPinnedNote
            "
            class="pinned-notes"
          >
            {{ pinnedNote }}
            <ng-container *ngIf="!isLast">;&nbsp;</ng-container>
          </div>
        </div>
      </div>
      <pr-timeline-appointment-notifications
        *ngIf="appointment$ | async as appointment"
        [appointment]="appointment"
      />
      <div *ngIf="isPublicPreBlock$ | async">
        <mat-icon>public</mat-icon>
      </div>
    </div>
    <ng-container *ngrxLet="disabled$ as disabled">
      <ng-container *ngrxLet="tooltipDelay$ as tooltipDelay">
        <pt-custom-tooltip
          *ngIf="!disabled || tooltipDelay === 'disabled'"
          [target]="tooltipOrigin"
          [positions]="positions$ | ngrxPush"
          [tooltipDelay]="tooltipDelay$ | ngrxPush"
        >
          <ng-template ptCustomTooltipContent>
            <pr-timeline-tooltip
              [eventable]="event$ | ngrxPush"
              [pendingGap]="pendingGap$ | ngrxPush"
            />
          </ng-template>
        </pt-custom-tooltip>
      </ng-container>
    </ng-container>
  </div>
</div>
