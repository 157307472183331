<mat-form-field appearance="outline" class="flex-1" [formGroup]="form">
  <mat-label>Select Date Range</mat-label>
  <mat-select formControlName="range" class="flex-1">
    <mat-option *ngIf="form.controls.range.value" [value]="undefined">
      Clear
    </mat-option>
    @for (dateRange of gapDateRanges; track dateRange.title) {
      <mat-option
        class="date-range-item flex items-center"
        (click)="setDateRange(dateRange)"
      >
        <div>{{ dateRange.title }}</div>
      </mat-option>
    }
  </mat-select>
</mat-form-field>
