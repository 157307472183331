<pr-timeline-appointment-tooltip
  *ngIf="appointment$ | ngrxPush as appointment; else genericEventable"
  [appointment]="appointment"
 />

<ng-template #genericEventable>
  <div class="layout-padding">
    <pr-generic-timeline-tooltip
      [eventable]="eventable$ | ngrxPush"
     />
  </div>
</ng-template>
