import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { type WithRef } from '@principle-theorem/shared';
import { TagSearch } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { Tag } from '@principle-theorem/principle-core';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  EditTagDialogComponent,
  type IEditTagDialogData,
} from '../edit-tag-dialog/edit-tag-dialog.component';

@Component({
  selector: 'pr-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {
  private _tags$ = new BehaviorSubject<WithRef<ITag>[]>([]);
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  searchCtrl = new TypedFormControl<string>();
  tagSearch: TagSearch;
  emptyState$: Observable<boolean>;
  emptySearch$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);
  @Output() tagAdded: EventEmitter<ITag> = new EventEmitter<ITag>();

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (tags) {
      this._tags$.next(tags.filter((tag) => !tag.deleted));
      this.loading$.next(false);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.tagSearch = new TagSearch(
      this._tags$,
      this.searchCtrl.valueChanges.pipe(startWith(''))
    );
    this.emptyState$ = combineLatest([this._tags$, this.loading$]).pipe(
      map(([tags, loading]) => !tags.length && !loading)
    );
    this.emptySearch$ = combineLatest([
      this.tagSearch.results$,
      this._tags$,
      this.loading$,
    ]).pipe(
      map(
        ([results, tags, loading]) =>
          !results.length && tags.length > 0 && !loading
      )
    );
  }

  async add(): Promise<void> {
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: { title: 'Add Tag' },
    };

    const result: Partial<ITag> | undefined = await this._dialog
      .open<
        EditTagDialogComponent,
        IEditTagDialogData,
        Partial<ITag> | undefined
      >(EditTagDialogComponent, DialogPresets.small<IEditTagDialogData>(config))
      .afterClosed()
      .toPromise();

    if (result) {
      const tag: ITag = Tag.init(result);
      this.tagAdded.next(tag);
    }
  }
}
