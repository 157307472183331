import {
  APPOINTMENTS_DASHBOARD_FEATURE,
  APPOINTMENTS_TIMELINE_FEATURE,
  APPOINTMENT_REQUESTS_FEATURE,
  AppointmentPermissions,
  BRANDS_FEATURE,
  CALENDAR_FEATURE,
  CHATS_FEATURE,
  CONTACTS_FEATURE,
  CONVERSATIONS_FEATURE,
  FOLLOW_UPS_FEATURE,
  GAPS_FEATURE,
  LABS_FEATURE,
  LAB_JOBS_FEATURE,
  MANAGEMENT_FEATURE,
  PATIENTS_FEATURE,
  REPORTING_ACCOUNT_CREDITS_FEATURE,
  REPORTING_OUTSTANDING_INVOICES_FEATURE,
  REPORTING_PATIENTS_FEATURE,
  REPORTING_PENDING_PAYMENTS_FEATURE,
  REPORTING_PRACTITIONERS_FEATURE,
  REPORTING_RECONCILIATION_FEATURE,
  REPORTING_REPORT_BUILDER_FEATURE,
  ReportingPermissions,
  SCHEDULING_ALERTS_FEATURE,
  STAFF_FEATURE,
  STERILISATION_FEATURE,
  TASKS_FEATURE,
} from '@principle-theorem/principle-core/features';
import {
  RESOURCE_DISPLAY_MAP,
  ResourceType,
  type IResourceDisplay,
} from '../../../models/resource-display';
import { type SidebarBadges } from './sidebar-badges';
import { BRAND_SLUG, PRACTICE_SLUG, type ISidebarItem } from './sidebar-item';

const DASHBOARD_DISPLAY: IResourceDisplay = { icon: 'dashboard' };

export const MIGRATION_SIDEBAR_CONFIG: ISidebarItem[] = [
  {
    label: 'Map Migration Data',
    path: `/${BRAND_SLUG}/migration/custom-mappings`,
    display: {
      icon: 'route',
    },
  },
  {
    label: 'Post-migration Tasks',
    path: `/${BRAND_SLUG}/migration/post-migration-tasks`,
    display: {
      icon: 'cleaning_services',
    },
  },
];

export const SCHEDULING_SIDEBAR_CONFIG: ISidebarItem[] = [
  {
    label: 'Timeline',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/schedule/timeline`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Calendar],
    feature: APPOINTMENTS_TIMELINE_FEATURE,
  },
  {
    label: 'Receptionist Dashboard',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/schedule/dashboard`,
    display: DASHBOARD_DISPLAY,
    feature: APPOINTMENTS_DASHBOARD_FEATURE,
  },
  {
    label: 'Practitioner Dashboard',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/dashboard/practitioner`,
    display: DASHBOARD_DISPLAY,
    feature: APPOINTMENTS_DASHBOARD_FEATURE,
    permissions: [AppointmentPermissions.AppointmentConduct],
  },
  {
    label: 'Appointment Gaps',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/gaps`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Gap],
    feature: GAPS_FEATURE,
  },
  {
    label: 'Appointment Requests',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/appointment-requests`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.AppointmentRequest],
    feature: APPOINTMENT_REQUESTS_FEATURE,
    resolveBadge: (badges: SidebarBadges) =>
      badges.getAppointmentRequestsBadge(),
  },
  {
    label: 'Scheduling Alerts',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/scheduling-alerts`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.SchedulingAlert],
    feature: SCHEDULING_ALERTS_FEATURE,
  },
  {
    label: 'Follow Ups',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/follow-ups`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.FollowUp],
    feature: FOLLOW_UPS_FEATURE,
    resolveBadge: (badges: SidebarBadges) => badges.getFollowUpsBadge(),
  },
];

export const OPERATIONS_SIDEBAR_CONFIG: ISidebarItem[] = [
  {
    label: 'Patients',
    path: `/${BRAND_SLUG}/patients`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Patient],
    feature: PATIENTS_FEATURE,
  },
  {
    label: 'Staff',
    path: `/${BRAND_SLUG}/staff`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Staffer],
    feature: STAFF_FEATURE,
  },
  {
    label: 'Tasks',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/tasks`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Task],
    feature: TASKS_FEATURE,
    resolveBadge: (badges: SidebarBadges) => badges.getTasksBadge(),
  },
  {
    label: 'Lab Jobs',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/lab-jobs`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.LabJob],
    feature: LAB_JOBS_FEATURE,
  },
  {
    label: 'Labs',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/labs`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Lab],
    feature: LABS_FEATURE,
  },
  {
    label: 'Contacts',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/contacts`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Contact],
    feature: CONTACTS_FEATURE,
  },
  {
    label: 'Calendar',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/schedule/calendar`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Calendar],
    feature: CALENDAR_FEATURE,
  },
  {
    label: 'Sterilisation',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/sterilisation`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Sterilisation],
    feature: STERILISATION_FEATURE,
  },
  {
    label: 'Conversations',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/conversations`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Conversations],
    feature: CONVERSATIONS_FEATURE,
    resolveBadge: (badges: SidebarBadges) => badges.getConversationsBadge(),
  },
  {
    label: 'Team Chats',
    path: `/${BRAND_SLUG}/${PRACTICE_SLUG}/chats`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Chats],
    feature: CHATS_FEATURE,
    resolveBadge: (badges: SidebarBadges) => badges.getChatsBadge(),
  },
  {
    label: 'Automations',
    path: `/${BRAND_SLUG}/automations`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Automation],
    feature: BRANDS_FEATURE,
  },
  {
    label: 'Settings',
    path: '/settings',
    display: { icon: 'settings' },
  },
];

export const MANAGEMENT_SIDEBAR_CONFIG: ISidebarItem[] = [
  {
    label: 'Management',
    path: '',
    feature: MANAGEMENT_FEATURE,
    children: [
      {
        label: 'Organisations',
        path: '/management/organisations',
        display: { icon: 'business' },
      },
      {
        label: 'Migrations',
        path: '/management/migrations',
        display: { icon: 'integration_instructions' },
      },
      {
        label: 'Tools',
        path: '/management/tools',
        display: { icon: 'handyman' },
      },
    ],
  },
];

export const REPORTING_SIDEBAR_CONFIG: ISidebarItem[] = [
  {
    label: 'Report Builder',
    path: `/reporting/report-builder`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportBuilder],
    feature: REPORTING_REPORT_BUILDER_FEATURE,
    permissions: [ReportingPermissions.ReportingReportBuilder],
  },
  {
    label: 'Custom Reports',
    path: `/reporting/custom-reports`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.Report],
    feature: REPORTING_REPORT_BUILDER_FEATURE,
    permissions: [
      ReportingPermissions.ReportingCustomReportView,
      ReportingPermissions.ReportingCustomReportEdit,
    ],
  },

  /**
   * Legacy Reporting Pages
   */
  {
    label: 'Account Credits',
    path: `/reporting/legacy/account-credits`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_ACCOUNT_CREDITS_FEATURE,
  },
  {
    label: 'Practitioner Income',
    path: `/reporting/legacy/practitioner-income`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_PRACTITIONERS_FEATURE,
  },
  {
    label: 'Patients',
    path: `/reporting/legacy/patients`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_PATIENTS_FEATURE,
  },
  {
    label: 'Reconciliation',
    path: `/reporting/legacy/reconciliation`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_RECONCILIATION_FEATURE,
  },
  {
    label: 'Pending Payments',
    path: `/reporting/legacy/pending-payments`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_PENDING_PAYMENTS_FEATURE,
  },
  {
    label: 'Outstanding Invoices',
    path: `/reporting/legacy/outstanding-invoices`,
    display: RESOURCE_DISPLAY_MAP[ResourceType.ReportLegacy],
    feature: REPORTING_OUTSTANDING_INVOICES_FEATURE,
  },
];
