import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceSyncErrorData,
} from '@principle-theorem/principle-core/interfaces';
import {
  getError,
  isObject,
  toTimestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import { EMPTY, from, of, type Observable } from 'rxjs';
import { catchError, expand } from 'rxjs/operators';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';

export const APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE = 'appointmentStatusType';

export const APPOINTMENT_STATUS_TYPE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Status Type List',
      description: '',
      idPrefix: APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WAppointmentStatusType {
  id: number;
  abbreviation: string;
  description: string | null;
}

export function isD4WAppointmentStatusType(
  item: unknown
): item is ID4WAppointmentStatusType {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.abbreviation) &&
    (isString(item.description) || isNull(item.description))
  );
}

export class AppointmentStatusTypeSourceEntity extends BaseSourceEntity<ID4WAppointmentStatusType> {
  sourceEntity = APPOINTMENT_STATUS_TYPE_SOURCE_ENTITY;
  entityResourceType = APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE;
  sourceQuery = `SELECT
    column_type_status_id AS id,
    column_type_status_abbreviatio AS abbreviation,
    convert_empty_string_to_null(column_type_status_description) AS description
  FROM a_slot_column_status_types`;
  verifySourceFn = isD4WAppointmentStatusType;
  override defaultOffsetSize = 1000;

  override getFromSource$(
    migration: WithRef<IPracticeMigration>
  ): Observable<ID4WAppointmentStatusType[] | ISourceSyncErrorData> {
    let offset = 0;

    return from(this._get(offset, this.defaultOffsetSize, migration)).pipe(
      expand((data) => {
        offset += this.defaultOffsetSize;
        if (!data.length) {
          return EMPTY;
        }
        return this._get(offset, this.defaultOffsetSize, migration);
      }),
      catchError((error) =>
        of({
          resumeData: {},
          errorMessage: getError(error),
        })
      )
    );
  }

  translate(
    _appointment: ID4WAppointmentStatusType,
    _timezone: Timezone
  ): unknown {
    return {};
  }

  getSourceRecordId(data: ID4WAppointmentStatusType): number {
    return data.id;
  }

  getSourceLabel(data: ID4WAppointmentStatusType): string {
    return `${data.id} (${data.abbreviation}) ${data.description ?? ''}`;
  }

  getFilterData(_data: ID4WAppointmentStatusType, _timezone: Timezone): object {
    return {};
  }

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    try {
      const response = await runQuery<{ count: number }>(
        migration,
        `SELECT COUNT(*) FROM (
          ${this.sourceQuery} WHERE convert_to_boolean(is_active) = TRUE
        ) src`
      );
      return {
        expectedSize: response.rows[0].count,
        expectedSizeCalculatedAt: toTimestamp(),
      };
    } catch (error) {
      const response = await runQuery<{ count: number }>(
        migration,
        `SELECT COUNT(*) FROM (${this.sourceQuery}) src`
      );
      return {
        expectedSize: response.rows[0].count,
        expectedSizeCalculatedAt: toTimestamp(),
      };
    }
  }

  private async _get(
    queryOffset: number,
    queryLimit: number,
    migration: WithRef<IPracticeMigration>
  ): Promise<ID4WAppointmentStatusType[]> {
    const offsetStatement = `LIMIT ${queryLimit} OFFSET ${queryOffset}`;

    try {
      const response = await runQuery<ID4WAppointmentStatusType>(
        migration,
        `${this.sourceQuery} WHERE convert_to_boolean(is_active) = TRUE ${offsetStatement}`
      );
      return flow([])(response.rows) as ID4WAppointmentStatusType[];
    } catch (error) {
      const response = await runQuery<ID4WAppointmentStatusType>(
        migration,
        `${this.sourceQuery} ${offsetStatement}`
      );
      return flow([])(response.rows) as ID4WAppointmentStatusType[];
    }
  }
}
