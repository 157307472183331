import {
  TaxRate,
  TaxStrategy,
  getTaxRateByRegion,
  roundTo2Decimals,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  FeeSchedule,
  PricedServiceCodeEntry,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
  determineTaxFromStrategy,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type IAppointment,
  type IChartedSurface,
  type IChartedTreatment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentConfiguration,
  type ITreatmentPlan,
  type ITreatmentStep,
  AppointmentStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ISO_DATE_FORMAT,
  Timestamp,
  Timezone,
  asyncForEach,
  getDoc$,
  getError,
  multiFilter,
  omitByKeys,
  reduceToSingleArray,
  reduceToSingleArrayFn,
  resolveSequentially,
  safeCombineLatest,
  sortByCreatedAt,
  toISODate,
  toInt,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  updateDoc,
  type INamedDocument,
  type ISODateType,
  type WithRef,
  Firestore,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { DestinationEntityRecord } from '../../../destination/destination-entity-record';
import {
  ITreatmentPlanSuccessData,
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import {
  AppointmentSourceEntity,
  type IExactAppointment,
  type IExactAppointmentFilters,
  type IExactAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  ExactTreatmentType,
  PatientTreatmentSourceEntity,
  type IExactTreatment,
  type IExactTreatmentFilters,
  type IExactTreatmentTranslations,
} from '../../source/entities/patient-treatments';
import {
  exactPatientIdIsWithinRange,
  getResolvedTreatmentData,
} from '../../util/helpers';
import { getExactChartedRefs } from '../../util/tooth';
import { ExactFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { ExactItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';
import {
  EXACT_MIGRATED_APPOINTMENT_PLAN_NAME,
  PatientAppointmentDestinationEntity,
} from './patient-appointments';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';

const EXACT_DEFAULT_PLAN_NAME = 'Migrated Exact Treatment Plan';

export const PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      label: 'Patient Treatment Plans',
      description: `This migrates the treatments for each patient. Exact does have a loose structure for treatment plans, so we are going to migrate in similar fashion.
      - Treatments with a plan id will be grouped into a treatment plan using the Id (as they do in Exact) - a description is also used for naming if present
      - Treatments with a visit_id will be grouped into steps under the plans and the visit_sequence will determine the order of the steps
      - Treatments without a plan id will be placed in a default plan with one step for all completed and one for incomplete

      Treatments all have a type in Exact:
      - 'Historic' treatments will be considered completed
      - 'Base' treatments are considered existing so should be treated like conditions?
      - 'Planned' are considered incomplete IF they have no appointment_id or the appointment is not complete
      `,
    },
  }
);

export interface IPatientTreatmentPlanJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
  feeSchedules: WithRef<ITranslationMap<IFeeSchedule>>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  taxRate: TaxRate;
}

interface IStepWithIdentifiers extends ITreatmentStep, IHasSourceIdentifier {
  createdAt: Timestamp;
  date?: ISODateType;
}

export interface ITreatmentPlanStepPair {
  plan: ITreatmentPlan & IHasSourceIdentifier;
  steps: IStepWithIdentifiers[];
}

export interface IPatientTreatmentPlanMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  planPairs: ITreatmentPlanStepPair[];
}

export class PatientTreatmentPlanDestinationEntity extends BaseDestinationEntity<
  ITreatmentPlanSuccessData,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY;
  sourceCountComparison = new PatientSourceEntity();
  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    appointments: new AppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    patientAppointments: new PatientAppointmentDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    feeSchedules: new ExactFeeScheduleMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    itemCodeToTreatment: new ExactItemCodeToTreatmentMappingHandler(),
  };

  override filters = [
    new PatientIdFilter<IPatientTreatmentPlanJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IPatientTreatmentPlanJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientTreatmentPlanJobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.doc$(record.data.sourceRef),
      safeCombineLatest(
        record.data.treatmentPlanRefs.map((treatmentPlanRef) =>
          Firestore.doc$(treatmentPlanRef)
        )
      ),
      safeCombineLatest(
        record.data.treatmentStepRefs.map((treatmentStepRef) =>
          Firestore.doc$(treatmentStepRef)
        )
      ),
    ]).pipe(
      map(([sourceRecord, treatmentPlans, treatmentSteps]) => [
        {
          label: 'Source Record',
          data: sourceRecord,
        },
        ...treatmentPlans.map((treatmentPlan) => ({
          label: `Treatment Plan - ${treatmentPlan.name}`,
          data: treatmentPlan,
        })),
        ...treatmentSteps.map((treatmentStep) => ({
          label: `Treatment Step - ${treatmentStep.name}`,
          data: treatmentStep,
        })),
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientTreatmentPlanJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const feeSchedules$ =
      this.customMappings.feeSchedules.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) =>
          exactPatientIdIsWithinRange(
            patient.data.data.patient_id,
            fromId,
            toId
          )
        ),
        withLatestFrom(
          staff$,
          defaultTreatmentConfiguration$,
          treatmentConfigurationMappings$,
          sourceItemCodes$,
          treatmentCategories$,
          feeSchedules$,
          taxRate$
        ),
        map(
          ([
            sourcePatients,
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurationMappings,
            sourceItemCodes,
            treatmentCategories,
            feeSchedules,
            taxRate,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              staff,
              defaultTreatmentConfiguration,
              treatmentConfigurationMappings,
              sourceItemCodes,
              treatmentCategories,
              feeSchedules,
              taxRate,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanJobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const treatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'patientId',
      sourcePatientId,
      undefined,
      undefined,
      (record) => {
        const hasServiceCode = resolveMappedCode(
          data.sourceItemCodes,
          record.data.data.service_code,
          record.data.data.service_code
        );
        return (
          !!hasServiceCode &&
          record.data.data.treatment_type !== ExactTreatmentType.Base
        );
      }
    );

    const appointments = await this.sourceEntities.appointments.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    try {
      const planPairs = await this._buildTreatmentPlanData(
        data,
        translationMap,
        treatments,
        migration,
        sourcePatientId,
        appointments
      );
      return {
        sourcePatientId,
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanMigrationData
  ): Promise<IPatientTreatmentPlanMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        data.planPairs,
        async ({ plan, steps }) => {
          const planRef = await translationMap.getDestination(
            plan.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await Firestore.getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = DestinationEntityRecord.hasMergeConflicts(
            plan,
            {
              ...existingPlan,
              steps: [],
            }
          );

          const existingSteps: IStepWithIdentifiers[] = [];
          const stepMergeConflicts = await asyncForEach(steps, async (step) => {
            const stepRef = await translationMap.getDestination(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

            if (!stepRef) {
              return false;
            }

            const existingStep = await Firestore.getDoc(
              stepRef as DocumentReference<
                ITreatmentStep & { sourceIdentifier: string }
              >
            );
            existingSteps.push({
              ...omitByKeys(existingStep, ['ref']),
              sourceIdentifier: step.sourceIdentifier,
              createdAt: step.createdAt,
            });

            return DestinationEntityRecord.hasMergeConflicts(
              omitByKeys(step, ['sourceIdentifier']),
              omitByKeys(existingStep, ['sourceIdentifier']),
              ['chartedAt'],
              [
                {
                  key: 'chartedSurfaces',
                  sortByPath: [
                    'resolvedAt.seconds',
                    'chartedRef.wholeMouth',
                    'chartedRef.tooth.quadrant',
                    'chartedRef.tooth.quadrantIndex',
                    'chartedRef.tooth.surface',
                  ],
                },
                {
                  key: 'treatments',
                  sortByPath: [
                    'type',
                    'config.ref.id',
                    'scopeRef',
                    'price',
                    'attributedTo.ref.id',
                    'adaCodes[0].code.name',
                    'chartedSurfaces[0].resolvedAt.seconds',
                    'chartedSurfaces[0].chartedRef.wholeMouth',
                    'chartedSurfaces[0].chartedRef.tooth.quadrant',
                    'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                    'chartedSurfaces[0].chartedRef.tooth.surface',
                  ],
                },
              ]
            );
          });

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;
          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              plan: {
                ...omitByKeys(existingPlan, ['ref']),
                steps: [],
                sourceIdentifier: plan.sourceIdentifier,
              },
              steps: sortBy(existingSteps, 'name'),
            };
          }
        }
      );

      const conflictPlanPairs = compact(mergeConflictPlanPairs);
      if (!conflictPlanPairs.length) {
        return;
      }

      return {
        ...data,
        planPairs: conflictPlanPairs,
      };
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    _migrationData: IPatientTreatmentPlanMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    migrationData: IPatientTreatmentPlanMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const { planRefs, stepRefs } = await this._upsertTreatmentPlans(
        migrationData,
        translationMap
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        planRefs,
        stepRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, String(error));
    }
  }

  private async _buildTreatmentPlanData(
    data: IPatientTreatmentPlanJobData,
    translationMap: TranslationMapHandler,
    sourceTreatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    sourceAppointments: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations,
      IExactAppointmentFilters
    >[]
  ): Promise<ITreatmentPlanStepPair[]> {
    const planGroups = groupBy(
      sourceTreatments,
      (sourceTreatment) =>
        sourceTreatment.data.data.treatment_plan_id ?? 'default-plan'
    );

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const availableAppointments = compact(
      await asyncForEach(sourceAppointments, async (sourceAppointment) => {
        const appointmentRef = await translationMap.getDestination<
          WithRef<IAppointment>
        >(
          this.sourceEntities.appointments.getSourceRecordId(
            sourceAppointment.data.data
          ),
          this.destinationEntities.patientAppointments.destinationEntity
            .metadata.key
        );
        if (!appointmentRef) {
          return;
        }
        return Firestore.getDoc(appointmentRef);
      })
    );

    const allPlans = await asyncForEach(
      Object.values(planGroups),
      async (treatments) => {
        const lastVisitSequence = Math.max(
          ...treatments.map((treatment) =>
            treatment.data.data.visit_sequence
              ? toInt(treatment.data.data.visit_sequence)
              : 0
          )
        );
        treatments = sortBy(
          treatments,
          (step) => step.data.data.visit_sequence ?? lastVisitSequence + 1
        );
        const planId = treatments[0].data.data.treatment_plan_id;
        const defaultPlanName = planId
          ? `Treatment Plan ${planId}`
          : EXACT_DEFAULT_PLAN_NAME;
        const planName =
          treatments[0].data.data.treatment_description ?? defaultPlanName;
        const createdAt = treatments[0].data.translations.plannedDate
          ? toTimestamp(
              toMomentTz(
                treatments[0].data.translations.plannedDate,
                migration.configuration.timezone
              )
            )
          : undefined;

        const practitioner = await resolveExactStaffer(
          treatments[0].data.data.provider_code,
          translationMap,
          data.staff
        );

        const plan = {
          ...TreatmentPlan.init({
            name: planName.trim(),
            practitioner: practitioner
              ? stafferToNamedDoc(practitioner)
              : undefined,
            createdAt,
          }),
          sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
        };

        const steps = await this._buildTreatmentStepData(
          treatments,
          translationMap,
          data,
          migration,
          sourcePatientId,
          defaultFeeSchedule,
          availableAppointments
        );

        if (!steps.length) {
          return;
        }

        const earliestStepDate = first(steps.sort(sortByCreatedAt))?.createdAt;
        if (earliestStepDate) {
          plan.createdAt = earliestStepDate;
        }

        const status = this._determinePlanStatus(steps, plan.createdAt);
        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    return compact(allPlans);
  }

  private async _buildTreatmentStepData(
    treatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanJobData,
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    defaultFeeSchedule: INamedDocument<IFeeSchedule>,
    availableAppointments: WithRef<WithRef<IAppointment>>[]
  ): Promise<IStepWithIdentifiers[]> {
    const lastVisitSequence = Math.max(
      ...treatments.map((treatment) =>
        treatment.data.data.visit_sequence
          ? toInt(treatment.data.data.visit_sequence)
          : 0
      )
    );
    const stepGroups = groupBy(
      treatments,
      (treatment) => treatment.data.data.visit_sequence ?? lastVisitSequence + 1
    );

    return asyncForEach(
      Object.entries(stepGroups),
      async ([key, stepGroup]) => {
        const sourceTreatments = stepGroup.map(
          (response) => response.data.data
        );
        const stepId = stepGroup[0].data.data.visit_id;
        const stepName = `Treatment Step ${key}`;
        const chartedTreatments = await this._getChartedTreatments(
          sourceTreatments,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          translationMap,
          migration.configuration.timezone
        );

        const date = stepGroup[0].data.translations.completedDate
          ? stepGroup[0].data.translations.completedDate
          : stepGroup[0].data.translations.plannedDate;

        const createdAt = date
          ? toTimestamp(toMomentTz(date, migration.configuration.timezone))
          : toTimestamp();

        const step = {
          ...TreatmentStep.init({
            name: stepName,
            treatments: chartedTreatments,
          }),
          createdAt,
        };

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        const stepDate = toMomentTz(
          createdAt,
          migration.configuration.timezone
        ).format(ISO_DATE_FORMAT);

        const matchingAppointmentIndex = availableAppointments.findIndex(
          (appointment) => {
            if (
              !appointment.event ||
              stepGroup[0].data.data.treatment_type ===
                ExactTreatmentType.Planned
            ) {
              return;
            }

            const appointmentDate = toISODate(
              toMomentTz(
                appointment.event.from,
                migration.configuration.timezone
              )
            );
            return stepDate === appointmentDate;
          }
        );

        const matchingAppointment =
          availableAppointments[matchingAppointmentIndex];
        if (matchingAppointment) {
          availableAppointments.splice(matchingAppointmentIndex, 1);
          step.status =
            matchingAppointment.status === AppointmentStatus.Complete
              ? TreatmentStepStatus.Complete
              : TreatmentStepStatus.Incomplete;
          step.appointment = matchingAppointment.ref;
        }

        return {
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
          createdAt,
          date: stepDate,
          sourceIdentifier: `${sourcePatientId}-step-${stepId ?? key}`,
        };
      }
    );
  }

  private _determinePlanStatus(
    steps: IStepWithIdentifiers[],
    planCreatedAt: Timestamp
  ): TreatmentPlanStatus {
    const allComplete = steps.every(
      (step) => step.status === TreatmentStepStatus.Complete
    );
    if (allComplete && steps.length) {
      return TreatmentPlanStatus.Completed;
    }

    const planAgeInMonths = moment().diff(toMoment(planCreatedAt), 'months');

    const someComplete = steps.some(
      (step) => step.status === TreatmentStepStatus.Complete
    );
    if (someComplete) {
      return TreatmentPlanStatus.InProgress;
    }

    const allIncomplete = steps.every(
      (step) => step.status === TreatmentStepStatus.Incomplete
    );
    if (allIncomplete && planAgeInMonths > 12) {
      return TreatmentPlanStatus.Declined;
    }

    return TreatmentPlanStatus.Draft;
  }

  private async _getChartedTreatments(
    sourceTreatments: IExactTreatment[],
    getPreferredOrDefaultRef: (
      feeSheduleId?: string
    ) => Promise<INamedDocument<IFeeSchedule>>,
    data: IPatientTreatmentPlanJobData,
    translationMap: TranslationMapHandler,
    timezone: Timezone
  ): Promise<IChartedTreatment[]> {
    const treatments: IChartedTreatment[] = [];
    await asyncForEach(sourceTreatments, async (sourceTreatment) => {
      const practitioner = await resolveExactStaffer(
        sourceTreatment.provider_code,
        translationMap,
        data.staff
      );
      if (!practitioner) {
        throw new Error(
          `Couldn't resolve practitioner for treatment ${sourceTreatment.provider_code}`
        );
      }

      const code = resolveMappedCode(
        data.sourceItemCodes,
        sourceTreatment.service_code,
        sourceTreatment.service_code
      );
      if (!code) {
        // eslint-disable-next-line no-console
        console.log(
          `Couldn't resolve code for treatment ${sourceTreatment.service_code}`
        );
        return;
      }

      const mappedTreatment = data.treatmentConfigurationMappings.find(
        (mapping) => mapping.sourceIdentifier === sourceTreatment.service_code
      );
      const treatmentConfiguration = mappedTreatment?.destinationIdentifier
        ? await Firestore.getDoc(mappedTreatment?.destinationIdentifier)
        : data.defaultTreatmentConfiguration;

      const sourceFeeScheduleId = sourceTreatment.payment_plan_id ?? undefined;
      const feeSchedule = await getPreferredOrDefaultRef(sourceFeeScheduleId);
      const taxStatus = TaxStrategy.GSTFree;
      const price = roundTo2Decimals(sourceTreatment.total_amount);
      const chartedSurfaces = this._getChartedSurfaces(
        sourceTreatment,
        practitioner,
        timezone
      );

      const serviceCode = PricedServiceCodeEntry.init({
        code: code.code,
        type: code.type,
        taxStatus,
        price,
        tax: determineTaxFromStrategy(data.taxRate, {
          taxStatus: taxStatus,
          amount: price,
        }),
        chartedSurfaces,
      });

      const scopeResolver = new ChartedItemScopeResolver();
      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        serviceCode.chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      const resolvedTreatmentData = getResolvedTreatmentData(
        sourceTreatment,
        practitioner,
        timezone
      );

      treatments.push(
        ChartedTreatment.init({
          uuid: sourceTreatment.treatment_id,
          config: toNamedDocument(treatmentConfiguration),
          feeSchedule,
          serviceCodes: [serviceCode],
          chartedSurfaces: serviceCode.chartedSurfaces,
          attributedTo: stafferToNamedDoc(practitioner),
          scopeRef,
          resolvedAt: resolvedTreatmentData?.resolvedAt,
          resolvedBy: resolvedTreatmentData?.resolvedBy,
        })
      );
    });

    return treatments;
  }

  private _getChartedSurfaces(
    sourceTreatment: IExactTreatment,
    practitioner: WithRef<IStaffer>,
    timezone: Timezone
  ): IChartedSurface[] {
    if (!sourceTreatment.tooth_range && !sourceTreatment.tooth) {
      return [];
    }

    const teeth =
      sourceTreatment.tooth_range ?? compact([sourceTreatment.tooth]);
    return teeth
      .map((tooth) =>
        getExactChartedRefs(tooth, sourceTreatment.tooth_surfaces).map(
          (chartedRef) => {
            const resolvedTreatmentData = getResolvedTreatmentData(
              sourceTreatment,
              practitioner,
              timezone
            );

            return ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              resolvedAt: resolvedTreatmentData?.resolvedAt,
              resolvedBy: resolvedTreatmentData?.resolvedBy,
              chartedRef,
            });
          }
        )
      )
      .reduce(reduceToSingleArrayFn, []);
  }

  private async _upsertTreatmentPlans(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<{
    planRefs: DocumentReference<ITreatmentPlan>[];
    stepRefs: DocumentReference<ITreatmentStep>[];
  }> {
    const results = await asyncForEach(
      migrationData.planPairs,
      async ({ plan, steps }) => {
        const planDestinationRef = await translationMap.getDestination(
          plan.sourceIdentifier,
          PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
        );

        const planRef = await FirestoreMigrate.upsertDoc(
          TreatmentPlan.col({ ref: migrationData.patientRef }),
          plan,
          planDestinationRef?.id
        );

        if (!planDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: plan.sourceIdentifier,
            destinationIdentifier: planRef,
            resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
          });
        }

        const resolvedPlan = await Firestore.getDoc(planRef);
        const stepRefs = await resolveSequentially(steps, async (step) => {
          const stepDestinationRef = await translationMap.getDestination(
            step.sourceIdentifier,
            PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
          );
          const stepRef = await FirestoreMigrate.upsertDoc(
            TreatmentPlan.treatmentStepCol({
              ref: planRef,
            }),
            step,
            stepDestinationRef?.id
          );

          if (!stepDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: step.sourceIdentifier,
              destinationIdentifier: stepRef,
              resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
            });
          }

          if (step.appointment) {
            await this._associateAppointmentWithStep(
              step.appointment,
              resolvedPlan,
              stepRef
            );
          }

          return stepRef;
        });

        await FirestoreMigrate.patchDoc(planRef, {
          steps: stepRefs,
        });

        return { planRefs: [planRef], stepRefs };
      }
    );

    return {
      planRefs: reduceToSingleArray(results.map((result) => result.planRefs)),
      stepRefs: reduceToSingleArray(results.map((result) => result.stepRefs)),
    };
  }

  private async _associateAppointmentWithStep(
    appointmentRef: DocumentReference<IAppointment>,
    resolvedPlan: WithRef<ITreatmentPlan>,
    stepRef: DocumentReference<ITreatmentStep>
  ): Promise<void> {
    const appointment = await Firestore.getDoc(appointmentRef);
    const oldPlanSummary = appointment.treatmentPlan;
    const oldStep = await Firestore.getDoc(oldPlanSummary.treatmentStep.ref);

    if (oldPlanSummary.name === EXACT_MIGRATED_APPOINTMENT_PLAN_NAME) {
      await FirestoreMigrate.saveDoc(omitByKeys(oldStep, ['appointment']));
      await FirestoreMigrate.deleteDoc(oldStep.ref);
    } else {
      await FirestoreMigrate.patchDoc(stepRef, {
        deleted: false,
      });
    }

    await updateDoc(appointment.ref, {
      treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
        resolvedPlan,
        await Firestore.getDoc(stepRef)
      ),
    });

    await FirestoreMigrate.patchDoc(stepRef, {
      appointment: appointmentRef,
    });
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for treatment plan',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    treatmentPlanRefs: DocumentReference<ITreatmentPlan>[],
    treatmentStepRefs: DocumentReference<ITreatmentStep>[]
  ): IDestinationEntityRecord<ITreatmentPlanSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        treatmentPlanRefs,
        treatmentStepRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }
}
