<ng-container *ngrxLet="selectionList.selected$ as selected">
  <ng-container *ngIf="groups$ | async as options">
    <ng-container *ngIf="showGrid$ | async; else listView">
      <div *ngFor="let group of options.groups; trackBy: options.trackByGroup">
        <ng-container *ngIf="group.items.length">
          <div class="mat-headline-6 grid-heading" *ngIf="group.group">
            {{ group.group }}
          </div>
          <mat-grid-list
            [cols]="columnSize$ | async"
            rowHeight="4:3"
            gutterSize="4px"
          >
            <mat-grid-tile
              *ngFor="let item of group.items; trackBy: options.trackByOption"
            >
              <div
                fxFill
                class="selector-item"
                [class.selected]="
                  item.value
                    | map: selectionList.isSelected$ : selectionList
                    | async
                "
                (click)="
                  openMedia.emit({
                    media: item.value,
                    orderedMedia: groupsToOrderedMedia(options.groups)
                  })
                "
              >
                @defer (on viewport) {
                  <div class="tile">
                    <pr-media-tile fxFill [media]="item.value" />
                  </div>
                } @placeholder (minimum 150ms) {
                  <ngx-skeleton-loader animation="pulse" count="5" />
                }

                <div class="overlay" [class.shown]="selected.length">
                  <button mat-icon-button (click)="toggle(item.value, $event)">
                    <mat-icon>
                      {{
                        (item.value
                        | map: selectionList.isSelected$ : selectionList
                        | async)
                          ? 'check_circle'
                          : 'radio_button_unchecked'
                      }}
                    </mat-icon>
                  </button>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #listView>
      <div *ngFor="let group of options.groups; trackBy: options.trackByGroup">
        <div class="mat-headline-6 list-heading" *ngIf="group.group">
          {{ group.group }}
        </div>
        <mat-list>
          <mat-list-item
            *ngFor="let item of group.items; trackBy: options.trackByOption"
            class="mat-mdc-list-item-interactive"
          >
            @defer (on viewport) {
              <div class="media-item flex items-center">
                <mat-checkbox
                  (change)="toggle(item.value)"
                  [checked]="
                    item.value
                      | map: selectionList.isSelected$ : selectionList
                      | async
                  "
                />
                <pr-media-list-item
                  class="h-full w-full"
                  [media]="item.value"
                  [size]="(patientMediaSettings$ | async)?.size"
                  (click)="
                    openMedia.emit({
                      media: item.value,
                      orderedMedia: groupsToOrderedMedia(options.groups)
                    })
                  "
                />
              </div>
            } @placeholder (minimum 150ms) {
              <ngx-skeleton-loader animation="pulse" count="5" />
            }
          </mat-list-item>
        </mat-list>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
