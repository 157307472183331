import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { OASIS_DESTINATION } from './destination';
import { OASIS_MAPPINGS } from './mappings';
import { OASIS_SOURCE } from './source';

export const OASIS_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'OASiS',
    version: 'v1',
    supportsMultiplePractices: false,
    description: ``,
  },
  source: OASIS_SOURCE,
  destination: OASIS_DESTINATION,
  mappings: OASIS_MAPPINGS,
};
