import { type Timestamp } from '@principle-theorem/shared';

export interface IRelativeSchedulingRules {
  minDays?: number;
  maxDays?: number;
  duration: number;
}

export interface IAbsoluteSchedulingRules {
  minDate?: Timestamp;
  maxDate?: Timestamp;
  duration: number;
}
