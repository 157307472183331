import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  AutomationsFacade,
  isAutomatedNotificationAutomation,
  isGeneratedTaskAutomation,
  type AutomationEntity,
  type IAutomationListData,
} from '@principle-theorem/ng-automations';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  IAppointment,
  TemplateScope,
  type IAppointmentScopeData,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { isWithRef, snapshot, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, Subject, type Observable } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

interface ITypeGroupedAutomations {
  tasks: IAutomationListData[];
  notifications: IAutomationListData[];
}

@Component({
  selector: 'pr-appointment-automations',
  templateUrl: './appointment-automations.component.html',
  styleUrls: ['./appointment-automations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentAutomationsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _appointment$: Observable<WithRef<IAppointment> | undefined>;
  automationGroups$: Observable<ITypeGroupedAutomations>;
  treatmentStep$ = new ReplaySubject<ITreatmentStep | WithRef<ITreatmentStep>>(
    1
  );

  @Input()
  set treatmentStep(step: ITreatmentStep | WithRef<ITreatmentStep>) {
    if (step) {
      this.treatmentStep$.next(step);
    }
  }

  constructor(private _automationsFacade: AutomationsFacade) {
    this.treatmentStep$
      .pipe(
        filter((step): step is WithRef<ITreatmentStep> => isWithRef(step)),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (treatmentStep) =>
          void this._automationsFacade.loadTreatmentStepAutomations(
            treatmentStep
          )
      );

    this._appointment$ = this.treatmentStep$.pipe(
      switchMap((treatmentStep) => TreatmentStep.appointment$(treatmentStep))
    );

    this.automationGroups$ = this._toAutomationGroups$();
  }

  ngOnDestroy(): void {
    this._automationsFacade.resetState();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addTask(): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    if (!appointment?.event) {
      return;
    }
    const treatmentStep = await snapshot(this.treatmentStep$);
    const triggerAfterDate = appointment
      ? Appointment.getCompletedAt(appointment)
      : undefined;

    this._automationsFacade.addTask(
      appointment.event,
      isWithRef(treatmentStep) ? treatmentStep.ref : undefined,
      triggerAfterDate
    );
  }

  async addNotification(): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    if (!appointment?.event) {
      return;
    }
    const treatmentStep = await snapshot(this.treatmentStep$);
    const triggerAfterDate = appointment
      ? Appointment.getCompletedAt(appointment)
      : undefined;
    // TODO: https://app.clickup.com/t/860qzurhu
    const scopeData: IAppointmentScopeData = {} as IAppointmentScopeData;
    this._automationsFacade.addNotification(
      TemplateScope.Appointment,
      scopeData,
      appointment.event,
      isWithRef(treatmentStep) ? treatmentStep.ref : undefined,
      triggerAfterDate
    );
  }

  async addFromTemplate(): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    if (!appointment?.event) {
      return;
    }
    const treatmentStep = await snapshot(this.treatmentStep$);
    this._automationsFacade.addFromTemplate(
      TemplateScope.Appointment,
      appointment.event,
      isWithRef(treatmentStep) ? treatmentStep.ref : undefined
    );
  }

  async edit(automation: AutomationEntity): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    if (!appointment?.event) {
      return;
    }
    this._automationsFacade.editAutomation(automation, appointment.event);
  }

  cancel(automation: AutomationEntity): void {
    this._automationsFacade.cancelOne(automation);
  }

  async restore(automation: AutomationEntity): Promise<void> {
    await this._automationsFacade.restoreAutomation(automation);
  }

  resend(automation: AutomationEntity): void {
    this._automationsFacade.resend(automation);
  }

  private _toAutomationGroups$(): Observable<ITypeGroupedAutomations> {
    return this._automationsFacade.automations$.pipe(
      map((automations) => ({
        tasks: automations
          .filter(isGeneratedTaskAutomation)
          .map((automation) => ({
            automation,
            isSelected: false,
          })),
        notifications: automations
          .filter(isAutomatedNotificationAutomation)
          .map((automation) => ({
            automation,
            isSelected: false,
          })),
      }))
    );
  }
}
