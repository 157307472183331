import {
  DocumentReference,
  TypeGuard,
  isDocRef,
  isObject,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { IPractice, ISmartpayTerminal } from '../../practice/practice';

export interface ISmartpayTerminalPairRequest {
  pairingCode: string;
  practiceRef: DocumentReference<IPractice>;
  terminal: Pick<ISmartpayTerminal, 'name' | 'uid'>;
}

export function isPairTerminalRequest(
  data: unknown
): data is ISmartpayTerminalPairRequest {
  return TypeGuard.interface<ISmartpayTerminalPairRequest>({
    pairingCode: isString,
    practiceRef: isDocRef,
    terminal: isObject,
  })(data);
}

export enum SmartpayPurchaseRequestType {
  CardPurchase = 'Card.Purchase',
  CardRefund = 'Card.Refund',
  QRPurchase = 'QR.Merchant.Purchase',
  QRRefund = 'QR.Refund',
}

export interface ISmartpayPurchaseRequestParams {
  providerId: string;
  providerName: string;
  practiceRef: DocumentReference<IPractice>;
  amount: number;
  requestType: SmartpayPurchaseRequestType;
  terminal: Pick<ISmartpayTerminal, 'name' | 'uid'>;
}

export function isSmartpayPurchaseRequestParams(
  data: unknown
): data is ISmartpayPurchaseRequestParams {
  return TypeGuard.interface<ISmartpayPurchaseRequestParams>({
    providerId: isString,
    providerName: isString,
    practiceRef: isDocRef,
    amount: isNumber,
    requestType: TypeGuard.enumValue(SmartpayPurchaseRequestType),
    terminal: isObject,
  })(data);
}

export interface ISmartpayTransactionRequest {
  POSRegisterID: string;
  POSRegisterName: string;
  POSBusinessName: string;
  POSVendorName: string;
  TransactionMode: 'ASYNC';
  TransactionType: SmartpayPurchaseRequestType;
  PosNotificationCallbackUrl: string;
  AmountTotal: string;
  SaleData?: string;
}

export interface ISmartpaySaleData {
  version: '1.0.0';
  saleData: {
    saleId: string;
    invoiceNumber: string;
    createdAt: string;
    totalAmount: string;
    userId: string;
    userName: string;
    customerId: string;
    customerName: string;
  };
}

export enum SmartpayTransactionResult {
  Accepted = 'OK-ACCEPTED',
  Declined = 'OK-DECLINED',
  Unavailable = 'OK-UNAVAILABLE',
  Delayed = 'OK-DELAYED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  InterfaceError = 'FAILED-INTERFACE',
}

export enum SmartpayTerminalResult {
  Ok = 'OK',
  Cancelled = 'CANCELLED',
  DelayedTransaction = 'DELAYED-TRANSACTION',
  Failed = 'FAILED',
  InterfaceError = 'FAILED-INTERFACE',
}

export interface ISmartpayTransactionWebhookResponse {
  TransactionID: string; // 'f363c7de-102c-4d80-a902-ed37413ca599';
  TransactionResult: SmartpayTransactionResult;
}

export function isSmartpayTransactionWebhookResponse(
  data: unknown
): data is ISmartpayTransactionWebhookResponse {
  return TypeGuard.interface<ISmartpayTransactionWebhookResponse>({
    TransactionID: isString,
    TransactionResult: TypeGuard.enumValue(SmartpayTransactionResult),
  })(data);
}

export interface ISmartpayTransactionPendingResponse {
  transactionId: string; // 'f363c7de-102c-4d80-a902-ed37413ca599';
  transactionTimeStamp: string; // '201809182353193193';
  merchantId: string; // '8dac4049-20b3-473f-b3a3-2682667ece33';
  deviceId: string; // '7070714000';
  transactionStatus: 'PENDING';
  data: {
    PosVendor: string; // 'Smartpay';
    PosRegisterID: string; // '9444ae07-dc63-e49c-33e3-59a7c108cc81';
    PosBusinessName: string; // "John Doe's Coffee Shop";
    AmountTotal: string; // '500';
    DeviceID: string; // '7070714000';
    Function: string; // 'Card.Purchase';
    Merchant: string; // "1";
    Company: string; // "Smartpay";
    RequestId: string; // "f363c7de-102c-4d80-a902-ed37413ca599";
    TransactionResult: SmartpayTransactionResult;
    Result: SmartpayTerminalResult;
    ResultText: string; // "Transaction takes longer than usual";
  };
}

export interface ISmartpayTransactionCompletedResponse {
  transactionId: string; // 'f363c7de-102c-4d80-a902-ed37413ca599';
  transactionTimeStamp: string; // '201809182353193193';
  merchantId: string; // '8dac4049-20b3-473f-b3a3-2682667ece33';
  deviceId: string; // '7070714000';
  transactionStatus: 'COMPLETED';
  data: {
    PosVendor: string; // 'Smartpay';
    PosRegisterID: string; // '9444ae07-dc63-e49c-33e3-59a7c108cc81';
    PosBusinessName: string; // "John Doe's Coffee Shop";
    AmountTotal: string; // '500';
    DeviceID: string; // '7070714000';
    Function: string; // 'Card.Purchase';
    Merchant: string; // "1";
    Company: string; // "Smartpay";
    RequestId: string; // "f363c7de-102c-4d80-a902-ed37413ca599";
    TransactionResult: SmartpayTransactionResult;
    Result: SmartpayTerminalResult;
    ResultText: string; // "Transaction takes longer than usual";
    AuthId: string; // "PIN147";
    AcquirerRef: string; // "000013";
    TerminalRef: string; // "79707014";
    CardPan: string; // "....0138";
    CardType: string; // "EMV TEST CARD";
    AccountType: string; // "CREDIT";
    AmountSurcharge: string; // "15";
    AmountTip: string; // "100";
    Timestamp: string; // "20180919115520";
    Receipt: string; // "         PAYMARK PKMS         \n        TEST TERMINAL         \n        182 Wairau Rd         \n\n*-----------EFTPOS-----------*\nTERMINAL              79707014\nTIME             19SEP18 11:55\nTRAN 000013             CREDIT\nEMV TEST CARD\nCARD                  ....0138\nVisa Debit\nRID: A000000003\nPIX: 1010\nTC : E0567EF3160C55CF\nTVR: 08C0048000\nATC: 04DE\nTSI: E800\nAUTH PIN147\nREF NO            000147      \nPURCHASE              NZ$ 5.00\nSURCHARGE             NZ$ 0.15\nTIP                   NZ$ 1.00\nTOTAL                 NZ$ 6.15\n\n           ACCEPTED           \n******DUPLICATE  RECEIPT******\n*----------------------------*\n\n";
    RequestTimestamp: string; // "201809182353196949";
    ResponseTimestamp: string; // "201809182355302736";
  };
}

export interface ISmartpayPurchaseTransactionExtendedData {
  request: ISmartpayPurchaseRequestParams;
  response:
    | ISmartpayTransactionPendingResponse
    | ISmartpayTransactionCompletedResponse;
}

export function isSmartpayTransactionPendingResponse(
  data: unknown
): data is ISmartpayTransactionPendingResponse {
  return (
    isObject(data) &&
    isString(data.transactionId) &&
    isString(data.transactionTimeStamp) &&
    isString(data.merchantId) &&
    isString(data.deviceId) &&
    data.transactionStatus === 'PENDING' &&
    isObject(data.data)
  );
}

export function isSmartpayTransactionCompleteResponse(
  data: unknown
): data is ISmartpayTransactionCompletedResponse {
  return (
    isObject(data) &&
    isString(data.transactionId) &&
    isString(data.transactionTimeStamp) &&
    isString(data.merchantId) &&
    isString(data.deviceId) &&
    data.transactionStatus === 'COMPLETED' &&
    isObject(data.data)
  );
}

export function isSmartpayTransactionResponse(
  data: unknown
): data is
  | ISmartpayTransactionPendingResponse
  | ISmartpayTransactionCompletedResponse {
  return (
    isSmartpayTransactionPendingResponse(data) ||
    isSmartpayTransactionCompleteResponse(data)
  );
}

export function isSmartpayPurchaseTransactionExtendedData(
  data: unknown
): data is ISmartpayPurchaseTransactionExtendedData {
  return (
    isObject(data) &&
    isSmartpayPurchaseRequestParams(data.request) &&
    isSmartpayTransactionResponse(data.response)
  );
}
