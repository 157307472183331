import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  CurrentScopeFacade,
  NgPrincipleSharedModule,
} from '@principle-theorem/ng-principle-shared';
import {
  FileManagerService,
  type IMediaPath,
  NgSharedModule,
  TrackByFunctions,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  Staffer,
  staffToNamedDocs,
} from '@principle-theorem/principle-core';
import {
  type IPractitionerFTAReportParams,
  type IPractitionerGapsJobParams,
  type IStaffer,
  ReportingJobFileType,
  ReportingJobStatus,
  ReportingJobType,
  type IBrand,
  type IReportingJob,
  type ReportingJob,
  REPORTING_JOBS_DISPLAY,
  IReportingJobDisplay,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  filterUndefined,
  snapshot,
  toTimestampRange,
  type ITimePeriod,
  type WithRef,
  type INamedDocument,
  addDoc,
  sortTimestamp,
  multiSort,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Subject, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SystemReportParameterFormComponent } from '../system-report-parameter-form/system-report-parameter-form.component';
import { sortBy } from 'lodash';

export const PUBLIC_REPORTS = [
  ReportingJobType.AppointmentsWithoutClinicalNote,
  ReportingJobType.OnlineBookings,
];

export const REPORTING_JOB_STATUS_MAP: {
  [key in ReportingJobStatus]: string;
} = {
  [ReportingJobStatus.Pending]: 'default',
  [ReportingJobStatus.InProgress]: 'accent',
  [ReportingJobStatus.Complete]: 'primary',
  [ReportingJobStatus.Failed]: 'warn',
};

@Component({
  selector: 'pr-system-reports',
  standalone: true,
  imports: [
    CommonModule,
    NgPrincipleSharedModule,
    NgSharedModule,
    SystemReportParameterFormComponent,
  ],
  templateUrl: './system-reports.component.html',
  styleUrl: './system-reports.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemReportsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByReportName = TrackByFunctions.variable<ReportingJobType>();
  reportCtrl = new TypedFormControl<IReportingJobDisplay>();
  fileType = new TypedFormControl<ReportingJobFileType>(
    ReportingJobFileType.Csv
  );
  from: moment.Moment = moment().startOf('day').subtract({ weeks: 1 });
  to: moment.Moment = moment().endOf('day');
  dateFormat = DATE_FORMAT;
  statusColourMap = REPORTING_JOB_STATUS_MAP;
  brand$: Observable<WithRef<IBrand>>;
  availableSystemReports$: Observable<IReportingJobDisplay[]>;
  existingJobs$: Observable<WithRef<IReportingJob>[]>;
  filteredReportJobs$: Observable<WithRef<IReportingJob>[]>;
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  reportParams$ = new ReplaySubject<
    IPractitionerFTAReportParams | IPractitionerGapsJobParams | undefined
  >(1);

  constructor(
    scope: CurrentScopeFacade,
    private _fileManager: FileManagerService
  ) {
    this.brand$ = scope.currentBrand$.pipe(filterUndefined());
    this.availableSystemReports$ = this.brand$.pipe(
      map((brand) => {
        const brandReports = brand.settings.availableSystemReports?.length
          ? brand.settings.availableSystemReports
          : [];

        const options = [...PUBLIC_REPORTS, ...brandReports];
        return sortBy(
          REPORTING_JOBS_DISPLAY.filter((jobDisplay) =>
            options.includes(jobDisplay.type)
          ),
          'name'
        );
      })
    );

    this.availableSystemReports$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((reports) => {
        if (reports.length) {
          this.reportCtrl.setValue(reports[0]);
        }
      });

    this.existingJobs$ = this.brand$.pipe(
      switchMap((brand) => Brand.reportingJobs$(brand))
    );

    this.practitioners$ = this.brand$.pipe(
      switchMap((brand) => Staffer.practitionersByBrand$(brand)),
      staffToNamedDocs()
    );

    this.filteredReportJobs$ = formControlChanges$(this.reportCtrl).pipe(
      switchMap((reportingJob) =>
        this.existingJobs$.pipe(
          map((jobs) =>
            jobs.filter((job) =>
              reportingJob ? job.type === reportingJob.type : true
            )
          )
        )
      ),
      multiSort((reportA, reportB) =>
        sortTimestamp(reportA.createdAt, reportB.createdAt)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateParams(
    change: IPractitionerFTAReportParams | IPractitionerGapsJobParams
  ): void {
    this.reportParams$.next(change);
  }

  transformReportName(report: ReportingJobType): string {
    return report.split('.')[1].split('-').join(' ');
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.from = dateRange.from;
    this.to = dateRange.to;
  }

  getTooltip(job: WithRef<IReportingJob>): string {
    switch (job.status) {
      case ReportingJobStatus.InProgress:
        return 'Report in progress';
      case ReportingJobStatus.Failed:
        return 'Report failed';
      default:
        return '';
    }
  }

  isNotReadyForDownload(job: WithRef<IReportingJob>): boolean {
    return job.status !== ReportingJobStatus.Complete || !job.file;
  }

  async runJob(): Promise<void> {
    const brand = await snapshot(this.brand$);
    const params = await snapshot(this.reportParams$);
    const jobRequest: ReportingJob = {
      type: this.reportCtrl.value.type,
      requestParams: {
        range: {
          ...toTimestampRange({ from: this.from, to: this.to }),
        },
        brandRef: brand.ref,
        fileType: this.fileType.value,
        ...params,
      },
      status: ReportingJobStatus.Pending,
      deleted: false,
    };

    await addDoc(Brand.reportingJobCol(brand), jobRequest);
  }

  async download(job: WithRef<IReportingJob>): Promise<void> {
    if (!job.file) {
      return;
    }

    await this._fileManager.downloadFile(job.file as IMediaPath);
  }
}
