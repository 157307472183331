import * as fromPatients from './patients.reducer';
import * as fromInvoices from './invoices.reducers';
import { Action, combineReducers } from '@ngrx/store';
import { type IPatientModuleState } from './states';

export function reducers(
  state: IPatientModuleState | undefined,
  action: Action
): IPatientModuleState {
  return combineReducers({
    patients: fromPatients.reducer,
    invoices: fromInvoices.reducer,
  })(state, action);
}
