import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  NgSharedModule,
  IMatSelectOption,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  IPatientMetadataDisplay,
  PATIENT_METADATA_DISPLAY_TARGET_INFO,
  PatientMetadataDisplayFormat,
  PatientMetadataDisplayTarget,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, randomHexColour } from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PreviewPatientMetadataDisplayComponent } from '../edit-patient-metadata-preview/preview-patient-metadata-display.component';
import { PatientMetadataOutletHelpComponent } from '../patient-metadata-outlet-help/patient-metadata-outlet-help.component';
import { toPairs } from 'lodash';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

export type EditPatientMetadataDisplayFormData = Omit<
  IPatientMetadataDisplay,
  'metadataKey' | 'deleted'
>;

const FORMAT_OPTIONS: IMatSelectOption<PatientMetadataDisplayFormat>[] = [
  { label: 'Label: Value', value: PatientMetadataDisplayFormat.LabelValue },
  { label: 'Label', value: PatientMetadataDisplayFormat.Label },
  { label: 'Value', value: PatientMetadataDisplayFormat.Value },
];

export const DISPLAY_TARGET_OPTIONS: IMatSelectOption<PatientMetadataDisplayTarget>[] =
  toPairs(PATIENT_METADATA_DISPLAY_TARGET_INFO).map(([value, info]) => ({
    value: value as PatientMetadataDisplayTarget,
    label: info.label,
  }));

@Component({
    selector: 'pr-edit-patient-metadata-display',
    exportAs: 'prEditPatientMetadataDisplay',
    templateUrl: './edit-patient-metadata-display.component.html',
    styleUrls: ['./edit-patient-metadata-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgMaterialModule,
        NgSharedModule,
        PreviewPatientMetadataDisplayComponent,
        PatientMetadataOutletHelpComponent,
    ]
})
export class EditPatientMetadataDisplayComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new TypedFormGroup<EditPatientMetadataDisplayFormData>({
    label: new TypedFormControl<string>('Label', Validators.required),
    format: new TypedFormControl<PatientMetadataDisplayFormat>(
      PatientMetadataDisplayFormat.LabelValue,
      Validators.required
    ),
    hexColour: new TypedFormControl<string>(
      randomHexColour(),
      Validators.required
    ),
    displayTargets: new TypedFormControl<PatientMetadataDisplayTarget[]>([]),
    onlyShowIfHasValue: new TypedFormControl<boolean>(
      true,
      Validators.required
    ),
  });
  formatOptions = FORMAT_OPTIONS;
  displayTargetOptions = DISPLAY_TARGET_OPTIONS;
  previewDisplay$: Observable<IPatientMetadataDisplay>;
  @Input() previewValue?: string;
  @Output()
  valueChanges = new EventEmitter<EditPatientMetadataDisplayFormData>();
  hideFormat$ = new BehaviorSubject<boolean>(false);
  hideHasValueSelector$ = new BehaviorSubject<boolean>(false);

  @Input()
  set hideFormat(hideFormat: BooleanInput) {
    this.hideFormat$.next(coerceBooleanProperty(hideFormat));
  }

  @Input()
  set hideHasValueSelector(hideHasValueSelector: BooleanInput) {
    this.hideHasValueSelector$.next(
      coerceBooleanProperty(hideHasValueSelector)
    );
  }

  @Input()
  set value(value: Partial<EditPatientMetadataDisplayFormData>) {
    if (value) {
      this.form.patchValue(value);
    }
  }

  constructor() {
    const formData$ = formControlChanges$<EditPatientMetadataDisplayFormData>(
      this.form
    );

    formData$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((formData) => this.valueChanges.emit(formData));

    this.previewDisplay$ = formData$.pipe(
      filterUndefined(),
      map((formData) => this._toPreviewDisplay(formData))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _toPreviewDisplay(
    formData: EditPatientMetadataDisplayFormData
  ): IPatientMetadataDisplay {
    return {
      metadataKey: 'preview',
      deleted: false,
      ...formData,
    };
  }
}
