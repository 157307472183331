import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { sanatiseHealthCardNumber } from '@principle-theorem/principle-core';
import { ONLY_NUMBERS } from '@principle-theorem/shared';

export interface IHealthFundMemberNumberDialogData {
  memberNumber: string;
}

@Component({
  selector: 'pr-health-fund-member-number-dialog',
  templateUrl: './health-fund-member-number-dialog.component.html',
  styleUrls: ['./health-fund-member-number-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthFundMemberNumberDialogComponent {
  form = new TypedFormGroup<IHealthFundMemberNumberDialogData>({
    memberNumber: new TypedFormControl<string>(undefined, [
      Validators.required,
      Validators.maxLength(2),
      Validators.pattern(ONLY_NUMBERS),
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) patient: IPatient,
    private _dialogRef: MatDialogRef<
      HealthFundMemberNumberDialogComponent,
      IHealthFundMemberNumberDialogData
    >
  ) {
    if (patient.healthFundCard) {
      this.form.patchValue({
        memberNumber: sanatiseHealthCardNumber(
          patient.healthFundCard.memberNumber
        ),
      });
    }
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    const value = this.form.value;

    this._dialogRef.close(value);
  }
}
