import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';
import {
  buildMomentsRange,
  getDayDuration,
  getHoursInDay,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-week-view',
    templateUrl: './week-view.component.html',
    styleUrls: ['./week-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WeekViewComponent {
  private _range$ = new ReplaySubject<ITimePeriod>(1);
  private _unit$ = new ReplaySubject<CalendarUnit>(1);
  trackByHour = TrackByFunctions.variable<string>();
  trackByDay = TrackByFunctions.date<Moment>();
  hours: string[] = getHoursInDay();
  days$: Observable<Moment[]>;

  @Input()
  set range(range: ITimePeriod) {
    if (!range) {
      return;
    }
    this._range$.next(range);
  }

  @Input()
  set unit(unit: CalendarUnit) {
    if (!unit) {
      return;
    }
    this._unit$.next(unit);
  }

  constructor() {
    this.days$ = combineLatest([this._range$, this._unit$]).pipe(
      map(([range, unit]) => {
        if (unit === CalendarUnit.Day) {
          return buildMomentsRange(
            {
              from: range.from.clone(),
              to: range.from.clone().endOf('day'),
            },
            getDayDuration()
          );
        }
        return buildMomentsRange(
          {
            from: range.from.clone().startOf('week').day(0),
            to: range.from.clone().endOf('week').day(6),
          },
          getDayDuration()
        );
      })
    );
  }
}
