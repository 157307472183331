import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import {
  IBridgeDevice,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, DocumentReference } from '@principle-theorem/shared';
import {
  VistaSoftOpenPatientCommand,
  VistaSoftToDeviceCommand,
} from '@principle-theorem/principle-bridge-core';

@Injectable()
export class VistaSoftFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async openPatient(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: VistaSoftOpenPatientCommand = {
      type: VistaSoftToDeviceCommand.OpenVistaSoft,
      data: {
        patient,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
