import {
  EventType,
  IAppointmentSuggestion,
  IBrand,
  ICalendarEventSchedule,
  IEvent,
  IEventable,
  IEventTimePeriod,
  IPractice,
  isPreBlockEvent,
  IStaffer,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  isSameRef,
  ITimePeriod,
  snapshot,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import * as moment from 'moment-timezone';
import { Duration } from 'moment-timezone';
import { AppointmentSuggestion } from '../appointment/appointment-suggestion';
import { EventableQueries } from '../eventable-queries';
import { Roster } from '../staffer/roster';
import { Staffer } from '../staffer/staffer';
import { buildStafferAvailableTimes } from './brand-event-option-finder';
import { CalendarEvent } from './calendar-event';
import { Event } from './event';

export interface IAppointmentSuggestionResults {
  practitioner: WithRef<IStaffer>;
  suggestions: IAppointmentSuggestion[];
}

export interface IStafferDurationMap {
  staffer: WithRef<IStaffer>;
  increment: Duration;
  duration?: Duration;
  treatmentCategories: DocumentReference<ITreatmentCategory>[];
  rosters: WithRef<ICalendarEventSchedule>[];
}

function eventPeriodToSuggestion(
  staffer: WithRef<IStaffer>,
  eventPeriod: IEventTimePeriod,
  preferredEvent: IEvent,
  scoreMin: number,
  treatmentCategories: DocumentReference<ITreatmentCategory>[]
): IAppointmentSuggestion | undefined {
  const suggestions = AppointmentSuggestion.convertOptionsToSuggestions(
    staffer,
    [eventPeriod],
    [],
    preferredEvent,
    [],
    undefined,
    treatmentCategories
  )
    .map((suggestion) => ({
      ...suggestion,
      event: {
        ...suggestion.event,
        type: EventType.AppointmentRequest,
      },
    }))
    .filter((suggestion) => suggestion.score >= scoreMin);

  return first(suggestions);
}

function toAppointmentSuggestions(
  eventPeriods: IEventTimePeriod[],
  staffMap: IStafferDurationMap[],
  scoreMin: number,
  treatmentCategories: DocumentReference<ITreatmentCategory>[],
  practice: WithRef<IPractice>
): IAppointmentSuggestion[] {
  const now = moment.tz(practice.settings.timezone);

  return compact(
    eventPeriods.map((eventPeriod) => {
      const preferredEvent = Event.init({
        from: toTimestamp(now.clone()),
        to: toTimestamp(now.clone().add('60', 'minutes')),
        practice: eventPeriod.practice,
      });
      const foundMap = staffMap.find((stafferMap) =>
        isSameRef(stafferMap.staffer, eventPeriod.staffer)
      );

      if (!foundMap) {
        return;
      }

      preferredEvent.to = toTimestamp(now.clone().add(foundMap.duration));

      return eventPeriodToSuggestion(
        foundMap.staffer,
        eventPeriod,
        preferredEvent,
        scoreMin,
        treatmentCategories
      );
    })
  );
}

export async function buildStaffOnlineBookingTimes(
  stafferMap: IStafferDurationMap,
  timePeriod: ITimePeriod,
  practice: WithRef<IPractice>,
  brand: WithRef<IBrand>,
  restrictToPreBlocks: boolean
): Promise<IAppointmentSuggestionResults> {
  if (!stafferMap.duration || stafferMap.duration.asMinutes() === 0) {
    return {
      practitioner: stafferMap.staffer,
      suggestions: [],
    };
  }

  const availableTimes = await getAvailableTimes(
    stafferMap,
    timePeriod,
    practice,
    brand,
    restrictToPreBlocks
  );

  const suggestions = toAppointmentSuggestions(
    availableTimes,
    [stafferMap],
    1,
    stafferMap.treatmentCategories,
    practice
  );

  return {
    practitioner: stafferMap.staffer,
    suggestions,
  };
}

async function getAvailableTimes(
  stafferMap: IStafferDurationMap,
  timePeriod: ITimePeriod,
  practice: WithRef<IPractice>,
  brand: WithRef<IBrand>,
  restrictToPreBlocks: boolean
): Promise<IEventTimePeriod[]> {
  const summaryEventables = await snapshot(
    EventableQueries.getScheduleSummaryEventablesWithFallback$(
      timePeriod,
      practice,
      [stafferMap.staffer],
      { [stafferMap.staffer.ref.id]: stafferMap.rosters },
      [EventType.GapCandidate, EventType.Gap]
    )
  );

  const forcastEventables = await snapshot(
    Staffer.buildEventsFromForecast$(
      [stafferMap.staffer],
      timePeriod,
      [],
      practice
    )
  );

  const eventables = [...summaryEventables, ...forcastEventables];
  const stafferEventables = {
    preBlocks: getPreBlockEventables(eventables),
    usedTimes: getUsedTimes(eventables, stafferMap.treatmentCategories),
  };

  if (restrictToPreBlocks) {
    if (!stafferEventables.preBlocks.length) {
      return [];
    }

    return buildStafferAvailableTimes(
      stafferEventables.preBlocks,
      stafferEventables.usedTimes,
      stafferMap.increment,
      practice,
      stafferMap.staffer,
      stafferMap.duration
    );
  }

  const rosteredTimes = await snapshot(
    Roster.rosteredTimes$(stafferMap.staffer, timePeriod, brand, practice)
  );

  return buildStafferAvailableTimes(
    rosteredTimes,
    stafferEventables.usedTimes,
    stafferMap.increment,
    practice,
    stafferMap.staffer,
    stafferMap.duration
  );
}

function getUsedTimes(
  eventables: IEventable[],
  treatmentCategories: DocumentReference<ITreatmentCategory>[]
): IEventable[] {
  return eventables
    .filter((eventable) => {
      if (!isPreBlockEvent(eventable.event)) {
        return true;
      }

      const preBlock = eventable.event;

      if (!preBlock.isPublic) {
        return true;
      }

      if (!preBlock.allowedTreatmentCategories.length) {
        return false;
      }

      if (!treatmentCategories.length) {
        return true;
      }

      return preBlock.allowedTreatmentCategories.every((category) =>
        treatmentCategories.every(
          (treatmentCategory) => !isSameRef(category, treatmentCategory)
        )
      );
    })
    .map((eventable) =>
      CalendarEvent.init({
        event: eventable.event,
        isBlocking: true,
      })
    );
}

function getPreBlockEventables(eventables: IEventable[]): ITimePeriod[] {
  return eventables
    .filter(
      (eventable) =>
        isPreBlockEvent(eventable.event) && eventable.event.isPublic
    )
    .map((eventable) => Event.toTimePeriod(eventable.event));
}
