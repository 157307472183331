import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-team-create-dialog',
  templateUrl: './team-create-dialog.component.html',
  styleUrls: ['./team-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamCreateDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<
      TeamCreateDialogComponent,
      Partial<ITeam> | undefined
    >
  ) {}

  submit(value: Partial<ITeam>): void {
    this._dialogRef.close(value);
  }
}
