import {
  ChartableSurface,
  IMultiTreatmentConfiguration,
  IMultiTreatmentPackage,
  IScopedServiceCode,
  ITreatmentConfiguration,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  asyncForEach,
  getDoc,
  isSameRef,
  WithRef,
} from '@principle-theorem/shared';
import { MockWithRef } from '@principle-theorem/testing';
import { compact, sum } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ChartedTreatment } from './charted-treatment';
import { TreatmentConfiguration } from './treatment-configuration';
import { MockFeeSchedule } from '../fees/fee-schedule/fee-schedule.mock';

export class MultiTreatmentPackage {
  static init(
    overrides: Partial<IMultiTreatmentPackage> = {}
  ): IMultiTreatmentPackage {
    return {
      uid: uuid(),
      name: 'New Package',
      description: '',
      isDefault: false,
      steps: [],
      ...overrides,
    };
  }

  static async total(
    multiTreatmentConfig: IMultiTreatmentConfiguration,
    multiTreatmentPackage: Pick<IMultiTreatmentPackage, 'steps'>
  ): Promise<number> {
    const stepTotals = await asyncForEach(
      multiTreatmentPackage.steps,
      async (step) => {
        const treatmentTotals = await asyncForEach(
          step.treatments,
          async (treatment) => {
            const treatmentQuantity = this.resolveTreatmentQuantity(
              multiTreatmentConfig,
              step.uid,
              treatment.treatmentRef
            );
            const treatmentConfig = await getDoc(treatment.treatmentRef);
            const serviceCodes = treatment.priceOverrides.map(
              (priceOverride) => ({
                ...priceOverride,
                quantity: this.resolveServiceCodeQuantity(
                  treatmentConfig,
                  priceOverride
                ),
              })
            );
            const serviceCodePrice = serviceCodes.reduce(
              (total, serviceCode) =>
                total + serviceCode.quantity * (serviceCode.price ?? 0),
              0
            );

            return treatmentQuantity * serviceCodePrice;
          }
        );
        return sum(treatmentTotals);
      }
    );

    return sum(stepTotals);
  }

  static async mergeWithConfig(
    config: IMultiTreatmentConfiguration,
    currentPackage: IMultiTreatmentPackage
  ): Promise<IMultiTreatmentPackage> {
    const steps = await asyncForEach(config.steps, async (step) => {
      const treatmentConfigs = await asyncForEach(
        step.treatments,
        async (treatment) => {
          const treatmentConfig = await getDoc(treatment.ref);
          return {
            ...treatment,
            config: treatmentConfig,
          };
        }
      );

      return {
        ...step,
        treatments: treatmentConfigs,
      };
    });

    const updatedPackage: IMultiTreatmentPackage = {
      ...currentPackage,
      steps: compact(
        steps.map((step) => {
          const existingStep = currentPackage.steps.find(
            (currentStep) => step.uid === currentStep.uid
          );
          return {
            uid: step.uid,
            name: step.name,
            treatments: step.treatments.map((treatment) => {
              const priceOverrides =
                existingStep?.treatments.find((existingTreatment) =>
                  isSameRef(existingTreatment.treatmentRef, treatment)
                )?.priceOverrides ?? [];

              const chartedTreatment = ChartedTreatment.fromConfig(
                treatment.config,
                MockWithRef(MockFeeSchedule()),
                {
                  scope: ChartableSurface.Unscoped,
                }
              );
              return {
                name: treatment.name,
                quantity: treatment.quantity,
                treatmentRef: treatment.ref,
                priceOverrides: chartedTreatment.serviceCodes.map(
                  (serviceCode) => {
                    const priceOverride = priceOverrides.find(
                      (price) =>
                        price.code === serviceCode.code &&
                        price.type === serviceCode.type
                    );
                    return {
                      code: serviceCode.code,
                      type: serviceCode.type,
                      price: priceOverride?.price ?? undefined,
                      quantity: serviceCode.quantity,
                    };
                  }
                ),
              };
            }),
          };
        })
      ),
    };

    return updatedPackage;
  }

  static resolveServiceCodeQuantity(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    scopedCode: IScopedServiceCode
  ): number {
    const serviceCodes = TreatmentConfiguration.getCombinedServiceCodes(
      treatmentConfig,
      [ServiceCodeGroupType.Required, ServiceCodeGroupType.Exclusive]
    );
    const foundCode = serviceCodes.find(
      (serviceCode) =>
        serviceCode.code === scopedCode.code &&
        serviceCode.type === scopedCode.type
    );
    return foundCode?.quantity ?? 0;
  }

  static resolveTreatmentQuantity(
    multiTreatmentConfig: IMultiTreatmentConfiguration,
    stepUid: string,
    treatmentRef: DocumentReference<ITreatmentConfiguration>
  ): number {
    const step = multiTreatmentConfig.steps.find(
      (searchStep) => searchStep.uid === stepUid
    );

    if (!step) {
      return 0;
    }

    const foundCode = step.treatments.find((treatment) =>
      isSameRef(treatment, treatmentRef)
    );

    return foundCode?.quantity ?? 0;
  }
}
