import { ChartOptions, type IChartData } from './chart-builder';
import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { SanKeyDataTransformer } from './data-transformers/google-charts/san-key-data-transformer';

export class SanKeyDiagram extends BaseChart {
  override dataTransformer = new SanKeyDataTransformer();
  data: IChartData = {
    type: ChartType.SanKey,
    options: ChartOptions.init(),
  };
}
