<div class="flex items-center gap-4">
  <button
    mat-flat-button
    [disabled]="disabled || isCancelled()"
    (click)="actionChange.emit(prescriptionStatus.Printed)"
  >
    Print
  </button>
  <button
    mat-flat-button
    [disabled]="disabled || !isDraft()"
    (click)="actionChange.emit(prescriptionStatus.Issued)"
    matTooltip="Issuing will make this visable in the checkout for reception to print."
  >
    Issue
  </button>

  <ng-content />

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item [disabled]="hasBeenPrescribed()" (click)="delete()">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>

  <button
    mat-menu-item
    [disabled]="!isIssued() && !isDraft()"
    (click)="actionChange.emit(prescriptionStatus.Cancelled)"
  >
    <mat-icon>cancel</mat-icon>
    <span>Cancel</span>
  </button>
</mat-menu>
