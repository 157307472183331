import {
  CustomChartType,
  type ICustomReportChart,
} from '@principle-theorem/principle-core/interfaces';
import { IChartConfig, getMeasureQueries } from '@principle-theorem/reporting';
import { type Crossfilter } from 'crossfilter2';
import {
  ReportBuilderDataSource,
  type IReportBuilderDataSource,
} from '../../../../../models/report-builder-data-sources/report-builder-data-source';
import {
  DCDataTransformer,
  type IDCTransformResult,
} from '../../../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  IDCChartBuilder,
  ITableBuilder,
} from '../../../../core/chart-builders/dc-chart-builders/dc-chart-builder';
import {
  ITableDisplay,
  buildDCChartDisplay,
  buildTableDisplay,
  type IDCChartDisplay,
} from '../../../../core/dynamic-dc-chart/dc-chart-display';

export interface IChartDisplay {
  chart: ICustomReportChart;
  chartDisplay?: IDCChartDisplay<unknown>;
  tableDisplay?: ITableDisplay;
}

export function getChartDisplay(
  dataSource: IReportBuilderDataSource,
  chart: ICustomReportChart,
  ndx?: Crossfilter<unknown>
): IChartDisplay | undefined {
  const filterResult = ReportBuilderDataSource.buildChart(dataSource, chart);
  if (!filterResult) {
    // eslint-disable-next-line no-console
    console.warn(`No filter found for chart ${chart.uid}`);
    return;
  }

  if (
    chart.type === CustomChartType.Table &&
    'getColumns' in filterResult.builder
  ) {
    const tableDisplay = getTableDisplay(
      filterResult.builder,
      filterResult.config,
      ndx
    );
    if (!tableDisplay) {
      // eslint-disable-next-line no-console
      console.warn(`No table display found for chart ${chart.uid}`);
      return;
    }
    return {
      chart,
      tableDisplay,
    };
  }

  if (!('getColumns' in filterResult.builder)) {
    const chartDisplay = getDcChartDisplay(
      filterResult.builder,
      filterResult.config,
      ndx
    );
    if (!chartDisplay) {
      // eslint-disable-next-line no-console
      console.warn(`No chart display found for chart ${chart.uid}`);
      return;
    }
    return {
      chart,
      chartDisplay,
    };
  }
}

export function getDcChartDisplay(
  builder: IDCChartBuilder<unknown>,
  config: IChartConfig,
  ndx?: Crossfilter<unknown>
): IDCChartDisplay<unknown> | undefined {
  if (!ndx) {
    return;
  }
  const transformer = new DCDataTransformer();
  const results: IDCTransformResult[] = getMeasureQueries(config).map(
    ({ measure }) => {
      const dimension = transformer.getDimension(ndx, config, measure.measure);
      const group = transformer.getGroup(measure.measure, dimension);
      return {
        measureUid: measure.uid,
        measure: measure.measure,
        factTable: ndx,
        dimension,
        group,
      };
    }
  );
  return buildDCChartDisplay(builder, config, results);
}

export function getTableDisplay(
  builder: ITableBuilder,
  config: IChartConfig,
  ndx?: Crossfilter<unknown>
): ITableDisplay | undefined {
  if (!ndx) {
    return;
  }
  const transformer = new DCDataTransformer();
  const results: IDCTransformResult[] = getMeasureQueries(config).map(
    ({ measure }) => {
      const dimension = transformer.getDimension(ndx, config, measure.measure);
      const group = transformer.getGroup(measure.measure, dimension);
      return {
        measureUid: measure.uid,
        measure: measure.measure,
        factTable: ndx,
        dimension,
        group,
      };
    }
  );
  return buildTableDisplay(builder, config, results);
}
