import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '../track-by';

@Component({
  selector: 'pt-action-list-button',
  templateUrl: './action-list-button.component.html',
  styleUrls: ['./action-list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListButtonComponent {
  trackByInfo = TrackByFunctions.variable<string>();
  @Input() isDisabled: boolean = false;
  @Input() imageUrl?: string;
  @Input() imageClass?: string = 'h-[30px] w-[90px]';
  @Input() icon: string;
  @Input() label: string;
  @Input() info: string[] = [];
  @Input() tooltip?: string;

  /**
   * This will intercept clicks and prevent any parent (click) event from firing
   */
  handleClick(event: Event): void {
    if (this.isDisabled) {
      event.stopPropagation();
    }
  }
}
