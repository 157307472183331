<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="!m-0 !font-bold">Notifications</h2>
  <ng-content />
</div>

<ng-container *ngIf="noConfigs$ | async; else configMaps">
  <pr-empty-state image="list" title="notifications" />
</ng-container>

<ng-template #configMaps>
  <div
    *ngFor="let configMap of practiceConfigMaps$ | async; trackBy: trackByMap"
    class="config-map"
  >
    @if (configMap.configurations.length) {
      @if (configMap.configurations.length) {
        <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
          <h3 class="!m-0 !font-bold">
            {{ configMap.practice ? configMap.practice.name : 'All Practices' }}
          </h3>
          <pr-automated-notification-configuration-list-item
            *ngFor="
              let notification of configMap.configurations;
              trackBy: trackByNotification
            "
            [notification]="notification"
            class="notification"
          >
            <ng-container *ngIf="!notification.deleted; else deletedActions">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="edit(notification)">
                  <mat-icon>edit</mat-icon>Edit
                </button>
                <button mat-menu-item (click)="openAutomations(notification)">
                  <mat-icon>smart_toy</mat-icon>View Automations
                </button>
                <button
                  mat-menu-item
                  *ngIf="!notification.isActive"
                  (click)="setActive(notification, true)"
                >
                  <mat-icon>check_circle</mat-icon>
                  Enable
                </button>
                <button
                  mat-menu-item
                  *ngIf="notification.isActive"
                  (click)="setActive(notification, false)"
                >
                  <mat-icon>highlight_off</mat-icon>
                  Disable
                </button>
                <mat-divider />
                <button
                  mat-menu-item
                  (click)="delete(notification)"
                  color="warn"
                >
                  <mat-icon color="warn">delete</mat-icon>
                  Delete
                </button>
              </mat-menu>
            </ng-container>

            <ng-template #deletedActions>
              <button mat-icon-button (click)="restore(notification)">
                <mat-icon>undo</mat-icon>
              </button>
            </ng-template>
          </pr-automated-notification-configuration-list-item>
        </div>
      }
    }
  </div>
</ng-template>
