<ng-container *ngIf="!multiSelect; else addStaffer">
  <ng-container
    *ngIf="selectedStaffer$ | async as selectedStaffer; else addStaffer"
  >
    <div [ngClass]="{ disabled: disabled }" class="staffer-container">
      <pt-user-icon
        [src]="stafferImage$ | async"
        [name]="stafferName$ | async"
        [diameter]="32"
      />

      <ng-container *ngIf="!disableClear; else clearDisabled">
        <div
          *ngIf="disabled === false"
          class="menu-button flex flex-col items-center justify-center"
          [matTooltip]="clearTooltip"
          [matTooltipDisabled]="disableTooltip"
        >
          <button mat-icon-button (click)="clearAssignee()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-template #clearDisabled>
        <div
          class="menu-button flex flex-col items-center justify-center"
          [matTooltip]="selectTooltip"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>person</mat-icon>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-template #addStaffer>
  <div
    [ngClass]="{ disabled: disabled }"
    class="add-staffer-button flex flex-col items-center justify-center"
    [matMenuTriggerFor]="menu"
    [matTooltip]="selectTooltip"
  >
    <mat-icon>person_add</mat-icon>
  </div>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <mat-selection-list
      #staffList
      hideSingleSelectionIndicator
      [multiple]="false"
      (selectionChange)="selectStaffer(staffList)"
    >
      <mat-list-option *ngIf="multiSelect" (click)="clearAssignee()">
        <div class="flex items-center gap-4">
          <mat-icon color="warn">close</mat-icon>
          <span>Clear Selected Providers</span>
        </div>
      </mat-list-option>
      <mat-list-option
        *ngFor="let staffer of staff$ | async; trackBy: trackByStaffer"
        [value]="staffer"
      >
        <pr-staffer-display [staffer]="staffer" />
      </mat-list-option>
    </mat-selection-list>
  </ng-template>
</mat-menu>
