import {
  Component,
  EventEmitter,
  type OnDestroy,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  type MatListOption,
  type MatSelectionListChange,
} from '@angular/material/list';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type NotificationType,
  NOTIFICATION_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, type Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-notification-type-filter',
    templateUrl: './notification-type-filter.component.html',
    styleUrls: ['./notification-type-filter.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationTypeFilterComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByType = TrackByFunctions.variable<NotificationType>();
  filters$: Observable<NotificationType[]>;
  selectedFilters$: BehaviorSubject<NotificationType[]> = new BehaviorSubject<
    NotificationType[]
  >([]);
  @Output() selectionChange: EventEmitter<NotificationType[]> =
    new EventEmitter<NotificationType[]>();

  constructor() {
    this.filters$ = of(NOTIFICATION_TYPES.sort());
    this.selectedFilters$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tags: NotificationType[]) => this.selectionChange.next(tags));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateSelected(event: MatSelectionListChange): void {
    this.selectedFilters$.next(
      event.source.selectedOptions.selected.map(
        (option: MatListOption) => option.value as NotificationType
      )
    );
  }
}
