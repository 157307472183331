import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import {
  type CanBeChartedProperty,
  FactTables,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.transactionEvent.latestEvent;

export type SharedDataSourceProperties = Pick<
  IReportBuilderDataSource,
  | 'factTable'
  | 'queryableTimestamps'
  | 'availableCharts'
  | 'availableColumns'
  | 'defaultColumns'
>;

export function sharedTransactionsDataSourceProperties(
  sumBy: CanBeChartedProperty
): SharedDataSourceProperties {
  const measure = [toMeasureBuilderData(sumBy)];
  return {
    factTable,
    queryableTimestamps: [
      { property: factTable.transaction.createdAt },
      { property: factTable.invoice.createdAt },
      { property: factTable.invoice.issuedAt },
      { property: factTable.invoice.paidAt },
    ],
    availableColumns: [
      ReportBuilderColumns.toColumnGroup('Invoice', [
        ...ReportBuilderColumns.invoice(factTable.invoice),
        factTable.invoiceLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Patient', [
        ...ReportBuilderColumns.patient(factTable.patient),
        factTable.patientLink,
      ]),
    ],
    defaultColumns: ReportBuilderColumns.toReferences([
      factTable.patient.name,
      factTable.invoice.status,
    ]),
    availableCharts: [
      ReportBuilderChartFilters.predefined(
        'overview.totalPayments',
        factTable.transaction.createdAt,
        ChartFilters.columnChartOverTime(
          'Total Payments',
          factTable.transaction.createdAt,
          measure
        )
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practice',
        factTable.practice.name,
        ChartFilters.pieChart('Practice', factTable.practice.name, measure)
      ),
      ...ReportBuilderChartFilters.patient(factTable.patient, sumBy),
      ...ReportBuilderChartFilters.invoice(factTable.invoice, sumBy),
      ReportBuilderChartFilters.predefined(
        'transaction.type',
        factTable.transaction.type,
        ChartFilters.rowChart('Type', factTable.transaction.type, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'transaction.status',
        factTable.transaction.status,
        ChartFilters.rowChart('Status', factTable.transaction.status, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'transaction.provider',
        factTable.transaction.provider,
        ChartFilters.columnChart(
          'Provider',
          factTable.transaction.provider,
          measure,
          { rotateXAxisLabels: true }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'transaction.providerSubType',
        factTable.transaction.providerSubType,
        ChartFilters.rowChart(
          'Provider SubType',
          factTable.transaction.providerSubType,
          measure
        )
      ),
      ReportBuilderChartFilters.predefined(
        'transaction.amount',
        factTable.transaction.createdAt,
        ChartFilters.columnChartOverTime(
          'Amount',
          factTable.transaction.createdAt,
          [toMeasureBuilderData(factTable.transaction.amount)]
        )
      ),
    ],
  };
}

const dataSource = sharedTransactionsDataSourceProperties(
  factTable.transaction.amount
);

export const TRANSACTIONS_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    ...dataSource,
    id: ReportBuilderDataSourceId.Transactions,
    name: 'Transactions',
    description:
      'One result per transaction within Principle. This includes information about the practice, patient, and invoice associated with each transaction.',
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(factTable.transaction.createdAt),
        ReportBuilderGroups.toOption(factTable.practice.name),
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.transaction(factTable.transaction),
      ReportBuilderGroups.invoice(factTable.invoice),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: ReportBuilderColumns.mergeColumnGroups([
      ReportBuilderColumns.toColumnGroup('Transaction', [
        ...ReportBuilderColumns.transaction(factTable.transaction),
        factTable.practice.name,
      ]),
      ...dataSource.availableColumns,
    ]),
    defaultColumns: [
      ...dataSource.defaultColumns,
      ...ReportBuilderColumns.toReferences([
        factTable.transaction.createdAt,
        factTable.transaction.status,
        factTable.transaction.type,
        factTable.transaction.provider,
        factTable.transaction.amount,
      ]),
    ],
  };
