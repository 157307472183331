import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { shareReplayCold, type IAttachment } from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

export interface IUploaderOptions {
  imageMaxSize: number;
  imageAllowedTypes: string[];
}

export interface IUploadProgress {
  attachment$: Observable<IAttachment>;
  isUploadComplete$: Observable<boolean>;
  progress$: Observable<number | undefined>;
}

export interface IUploader {
  upload: (event: File) => IUploadProgress;
}

export class NgFireMediaUploader implements IUploader {
  options: IUploaderOptions = {
    imageMaxSize: 1024 * 1024 * 1024, // 1GB
    imageAllowedTypes: [
      'jpeg',
      'jpg',
      'png',
      'svg+xml',
      'gif',
      'webp',
      'webm',
      'mp4',
      'quicktime',
      'wmv',
    ],
  };

  constructor(
    protected _storage: Storage,
    protected _storagePath$: Observable<string>,
    options?: IUploaderOptions
  ) {
    if (options) {
      this.options = options;
    }
  }

  upload(event: File | Blob): IUploadProgress {
    const file: File | undefined = this._getFileFromEvent(event);
    if (!file) {
      throw new Error(`No file given`);
    }

    this._validateUpload(file);

    const unixTimestamp: number = moment().unix();
    const path$: Observable<string> = this._storagePath$.pipe(
      map((storagePath) => `${storagePath}/${unixTimestamp}_${file.name}`),
      shareReplayCold()
    );

    const task$ = path$.pipe(
      map((path) => uploadBytesResumable(ref(this._storage, path), file)),
      shareReplayCold()
    );

    const link$: Observable<string> = task$.pipe(
      switchMap((snapshot) => getDownloadURL(snapshot.snapshot.ref)),
      filter((url): url is string => isString(url)),
      shareReplayCold()
    );

    const uploadSuccess$ = new Subject<boolean>();

    const progress$ = task$.pipe(
      switchMap(
        (task) =>
          new Observable<number>((observer) => {
            task.on(
              'state_changed',
              (snapshot) => {
                const percentage =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                observer.next(percentage);
              },
              (error) => {
                uploadSuccess$.next(false);
                observer.error(error);
              },
              () => {
                uploadSuccess$.next(true);
                observer.complete();
              }
            );
          })
      ),
      shareReplayCold()
    );

    const isUploadComplete$ = combineLatest([progress$, uploadSuccess$]).pipe(
      map(([progress, uploadSuccess]) => progress === 100 && uploadSuccess)
    );

    return {
      progress$,
      isUploadComplete$,
      attachment$: combineLatest([path$, link$]).pipe(
        map(([path, link]) => ({
          path,
          link,
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          deleted: false,
        }))
      ),
    };
  }

  private _validateUpload(file: File): void {
    if (file.size > this.options.imageMaxSize) {
      throw new Error(`File size too large`);
    }
    const extension: string = this._getExtension(file);
    if (!this.options.imageAllowedTypes.includes(extension)) {
      throw new Error(`Extension type not allowed`);
    }
  }

  private _getFileFromEvent(event: File | Blob): File | undefined {
    if (event instanceof File) {
      return event;
    }
    const blob: Blob | undefined = event;
    return blob ? this._blobToFile(blob) : undefined;
  }

  private _blobToFile(blob: Blob, filename?: string): File {
    if (!filename) {
      const extension: string = this._getExtension(blob);
      filename = `${uuid()}.${extension}`;
    }
    return new File([blob], filename, {
      type: blob.type,
    });
  }

  private _getExtension(file: File | Blob): string {
    return file.type.split('/').reverse()[0];
  }
}
