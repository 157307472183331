import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type IRecurrencePattern } from '@principle-theorem/shared';

@Component({
  selector: 'pr-recurrence-dialog',
  templateUrl: './recurrence-dialog.component.html',
  styleUrls: ['./recurrence-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurrenceDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
      RecurrenceDialogComponent,
      IRecurrencePattern
    >,
    @Inject(MAT_DIALOG_DATA)
    public recurrencePattern: Partial<IRecurrencePattern>
  ) {}

  submitted($event: IRecurrencePattern): void {
    this.dialogRef.close($event);
  }
}
