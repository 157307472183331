import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const INSURANCE_OPTION_RESOURCE_TYPE = 'insuranceOptions';

export const INSURANCE_OPTION_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Insurance Option List',
    description: '',
    idPrefix: INSURANCE_OPTION_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticeInsuranceOption {
  id: number;
  name: string; // "Child Dental Benefit Scheme (CDBS)"
}

export function isCorePracticeInsuranceOption(
  item: unknown
): item is ICorePracticeInsuranceOption {
  return TypeGuard.interface<ICorePracticeInsuranceOption>({
    id: isNumber,
    name: isString,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeInsuranceTranslationsOption {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeInsuranceFiltersOption {}

const INSURANCE_OPTION_SOURCE_QUERY = `
SELECT
  InsuranceId AS id,
  Name AS name
FROM tblInsurance
WHERE name IS NOT NULL
`;

export class InsuranceOptionSourceEntity extends BaseSourceEntity<
  ICorePracticeInsuranceOption,
  ICorePracticeInsuranceTranslationsOption,
  ICorePracticeInsuranceFiltersOption
> {
  sourceEntity = INSURANCE_OPTION_SOURCE_ENTITY;
  entityResourceType = INSURANCE_OPTION_RESOURCE_TYPE;
  sourceQuery = INSURANCE_OPTION_SOURCE_QUERY;
  verifySourceFn = isCorePracticeInsuranceOption;

  translate(
    _data: ICorePracticeInsuranceOption,
    _timezone: Timezone
  ): ICorePracticeInsuranceTranslationsOption {
    return {};
  }

  getSourceRecordId(data: ICorePracticeInsuranceOption): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeInsuranceOption): string {
    return `${data.id} - ${data.name}`;
  }

  getFilterData(
    _data: ICorePracticeInsuranceOption,
    _timezone: Timezone
  ): ICorePracticeInsuranceFiltersOption {
    return {};
  }
}
