<div fxLayout="row" fxLayoutAlign="start end" *ngIf="note$ | async as note">
  <div fxFlex class="mat-caption">
    {{ note.updatedAt | moment | amTimeAgo }}
  </div>

  <div fxLayout="row">
    <div *ngIf="editMode">
      <button
        mat-icon-button
        (click)="reset(note)"
        matTooltip="Undo Changes"
        matTooltipShowDelay="500"
      >
        <mat-icon>undo</mat-icon>
      </button>

      <button
        mat-icon-button
        matTooltip="Save Changes"
        matTooltipShowDelay="500"
        [disabled]="!form.valid"
        (click)="save(note)"
      >
        <mat-icon>save</mat-icon>
      </button>
    </div>

    <button mat-icon-button class="pinned-icon" (click)="togglePin(note)">
      <mat-icon *ngIf="note.pinned" matTooltip="Unpin note">bookmark</mat-icon>
      <mat-icon *ngIf="!note.pinned" matTooltip="Pin note"
        >bookmark_border</mat-icon
      >
    </button>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon> Edit
      </button>
      <button mat-menu-item color="warn" (click)="delete(note)">
        <mat-icon>delete</mat-icon> Delete
      </button>
    </mat-menu>
  </div>
</div>

<ng-container *ngIf="editMode; else render">
  <form [formGroup]="form" novalidate (ngSubmit)="save(note)">
    <pr-content-editor
      #editNote
      class="mat-body-2"
      formControlName="content"
      [menuEnabled]="false"
      (keydown.meta.Enter)="save(note)"
     />
  </form>
</ng-container>

<ng-template #render>
  <pr-content-view [content]="content$ | async" />
</ng-template>
