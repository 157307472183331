<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  fxLayout="column"
  fxLayoutGap="16px"
>
  <div fxLayout="column" fxLayoutAlign="start center" >
    <mat-form-field fxFill>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>

    <mat-form-field fxFill>
      <span matTextPrefix>&#36;&nbsp;</span>
      <mat-label>Cost</mat-label>
      <input matInput type="number" formControlName="cost" [required]="true" />
    </mat-form-field>
  </div>

  <pt-buttons-container>
    <ng-content></ng-content>
  </pt-buttons-container>
</form>
