import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IEvent } from '@principle-theorem/principle-core/interfaces';
import { TIME_FORMAT } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-event-time',
    templateUrl: './event-time.component.html',
    styleUrls: ['./event-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventTimeComponent {
  event$: ReplaySubject<IEvent> = new ReplaySubject(1);
  readonly timeFormat = TIME_FORMAT;

  @Input()
  set event(event: IEvent) {
    if (event) {
      this.event$.next(event);
    }
  }
}
