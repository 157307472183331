<div fxFill>
  <form [formGroup]="paymentPlanForm">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field fxFlex>
        <mat-label>Payment Plan</mat-label>
        <mat-select
          placeholder="Select a Plan"
          formControlName="selectedPlan"
          (selectionChange)="setPlanTotal()"
          [(value)]="selectedPlan"
        >
          <mat-option
            *ngFor="let plan of paymentPlans; trackBy: trackByPlan"
            [value]="plan"
          >
            {{ plan.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Payment Frequency</mat-label>
        <mat-select
          formControlName="frequency"
          [(value)]="selectedPlan.paymentFrequency"
        >
          <mat-option
            *ngFor="let freq of frequencies; trackBy: trackByFrequency"
            [value]="freq"
          >
            {{ freq }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field fxFlex>
        <mat-label>Starting Date</mat-label>
        <input
          matInput
          formControlName="startingDate"
          [matDatepicker]="startDatePicker"
          (click)="startDatePicker.open()"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="startDatePicker"
         />
        <mat-datepicker #startDatePicker />
      </mat-form-field>
    </div>

    <h3>Repayment Amount: {{ repaymentAmount | currency }}</h3>
    <button mat-stroked-button>Print</button>
  </form>
</div>
