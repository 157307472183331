import {
  type AfterViewInit,
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  BooleanMeasureFilter,
  type CanBeChartedProperty,
  FactTables,
  generateBuilderData,
  type ICanGroupMeasuresProperty,
  type IChartConfig,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import {
  errorNil,
  type ITimePeriod,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { type IChartCard } from '../../models/report/charts/chart-card';
import { DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import { type ITableHeaderReplacement } from '../reporting-components/table-chart/table-chart.component';
import { DrilldownChartComponent } from './drilldown-chart/drilldown-chart.component';

@Component({
  selector: 'pr-patient-flow',
  templateUrl: './patient-flow.component.html',
  styleUrls: ['./patient-flow.component.sass'],
})
export class PatientFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy$: Subject<void> = new Subject();
  trackByMeasure =
    TrackByFunctions.nestedField<CanBeChartedProperty>('metadata.label');
  from: moment.Moment = moment().subtract({ months: 1 });
  to: moment.Moment = moment();
  dataBuilder: DateRangeDataBuilder;
  measures: CanBeChartedProperty[] = [
    FactTables.appointmentEvent.newPatient.reduceByCount(),
    FactTables.appointmentEvent.existingPatient.reduceByCount(),
    FactTables.appointmentEvent.appointment.status
      .reduceByCount()
      .setLabel('Total Patients Seen'),
    FactTables.appointmentEvent.duration
      .reduceByAverage()
      .setLabel('Average Duration'),
    FactTables.appointmentEvent.waitTime
      .reduceByAverage()
      .setLabel('Average Wait Time'),
    FactTables.appointmentEvent.nextAppointmentBooked
      .filterBy(new BooleanMeasureFilter(false))
      .reduceByCount()
      .setLabel('Patients Leaving Without Appointment')
      .setFormatter(MeasureFormatter.Number),
    FactTables.appointmentEvent
      .scopeBy(
        FactTables.appointmentEvent.isFirstAppointment.filterBy(
          new BooleanMeasureFilter(true)
        )
      )
      .scopeBy(
        FactTables.appointmentEvent.nextAppointmentBooked.filterBy(
          new BooleanMeasureFilter(false)
        )
      )
      .count.setLabel('Bounce Rate')
      .setFormatter(MeasureFormatter.Percentage),
    FactTables.appointmentEvent.treatmentCost
      .reduceByAverage()
      .setLabel('Average Patient Spend'),
    FactTables.appointmentEvent
      .scopeBy(
        FactTables.appointmentEvent.nextAppointmentBooked.filterBy(
          new BooleanMeasureFilter(true)
        )
      )
      .count.reduceByRatio(FactTables.appointmentEvent.count)
      .setLabel('Rebooking Rate')
      .setFormatter(MeasureFormatter.Percentage),
    FactTables.appointmentEvent.nextAppointmentBooked
      .filterBy(new BooleanMeasureFilter(true))
      .reduceByCount()
      .setLabel('Patients Leaving With Appointment')
      .setFormatter(MeasureFormatter.Number),
  ];
  @ViewChild(DrilldownChartComponent, { static: true })
  drilldown: DrilldownChartComponent;
  tableChartCard: IChartCard;
  replacementHeaders: ITableHeaderReplacement[];
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];

  constructor(
    private _organisation: OrganisationService,
    private _api: StorageResponseAPI
  ) {}

  ngOnInit(): void {
    this.dataBuilder = new DateRangeDataBuilder(
      this._api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this._organisation.userPractices$
    );
    this.dataBuilder.dateChange
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._redraw());
    this._redraw();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.selectMetric(this.measures[0]);
  }

  selectMetric(measure: CanBeChartedProperty): void {
    this.drilldown.select(measure);
  }

  isSelected(measure: CanBeChartedProperty): boolean {
    return this.drilldown.selected.includes(measure);
  }

  updateDateRange(dateRange: ITimePeriod): void {
    if (!this.dataBuilder) {
      return;
    }
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }

  private _redraw(): void {
    this._setTableChartCard(
      this.measures,
      FactTables.appointmentEvent.brand.name
    );
  }

  private _setTableChartCard(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): void {
    const config: IChartConfig = {
      type: CustomChartType.Table,
      builderData: generateBuilderData({
        measures: measures.map((measure) => toMeasureBuilderData(measure)),
        groupByDimension,
      }),
      labels: {
        title: '',
      },
    };

    this.replacementHeaders = [
      {
        from: 'Owner',
        to: 'Practitioner',
      },
    ];

    this.tableChartCard = this.dataBuilder.toLineChart(config);
  }
}
