import { MedipassDVATransactionProvider } from '../medipass/medipass-dva-transaction-provider.service';
import { MedipassGapPaymentTransactionProvider } from '../medipass/medipass-gap-payment-provider.service';
import { MedipassHicapsTransactionProvider } from '../medipass/medipass-hicaps-transaction-provider.service';
import { MedipassMedicareTransactionProvider } from '../medipass/medipass-medicare-transaction-provider.service';
import { MedipassVirtualTerminalTransactionProvider } from '../medipass/medipass-virtual-terminal-transaction-provider.service';
import { IProviderOption } from '../transaction-provider';

export const MEDIPASS_IMAGE_URL = '/assets/integrations/medipass-rect.png';

export const MEDIPASS_HICAPS_PROVIDER_OPTION: IProviderOption = {
  label: 'Medipass',
  icon: 'health_and_safety',
  imageUrl: MEDIPASS_IMAGE_URL,
  provider: MedipassHicapsTransactionProvider,
};

export const MEDIPASS_MEDICARE_PROVIDER_OPTION: IProviderOption = {
  label: 'Medipass',
  icon: 'local_hospital',
  imageUrl: MEDIPASS_IMAGE_URL,
  provider: MedipassMedicareTransactionProvider,
};

export const MEDIPASS_DVA_PROVIDER_OPTION: IProviderOption = {
  label: 'Medipass',
  icon: 'military_tech',
  imageUrl: MEDIPASS_IMAGE_URL,
  provider: MedipassDVATransactionProvider,
};

export const MEDIPASS_VIRTUAL_TERMINAL_PROVIDER_OPTION: IProviderOption = {
  label: 'Medipass',
  icon: 'credit_card',
  imageUrl: MEDIPASS_IMAGE_URL,
  provider: MedipassVirtualTerminalTransactionProvider,
};

export const MEDIPASS_GAP_PAYMENT_PROVIDER_OPTION: IProviderOption = {
  label: 'Medipass Gap Payment',
  icon: 'local_hospital',
  imageUrl: MEDIPASS_IMAGE_URL,
  provider: MedipassGapPaymentTransactionProvider,
};

export const MEDIPASS_PROVIDER_OPTIONS: IProviderOption[] = [
  MEDIPASS_HICAPS_PROVIDER_OPTION,
  MEDIPASS_MEDICARE_PROVIDER_OPTION,
  MEDIPASS_VIRTUAL_TERMINAL_PROVIDER_OPTION,
  MEDIPASS_GAP_PAYMENT_PROVIDER_OPTION,
  MEDIPASS_DVA_PROVIDER_OPTION,
];
