<div
  class="event-info"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <mat-icon class="status-icon">
    {{ scheduled ? 'event_available' : 'event_busy' }}
  </mat-icon>

  <div fxFlex fxLayout="column">
    <span class="mat-caption">Appointment</span>
    <span>
      <ng-template #notScheduled>
        {{ required ? 'Must be Scheduled' : 'Ready for Scheduling' }}
      </ng-template>
      <pr-formatted-event-field
        *ngIf="appointment.event; else notScheduled"
        field="from"
        [event]="appointment.event"
        [format]="dateFormat"
      />
    </span>
  </div>

  <button
    mat-icon-button
    *ngIf="showDelete"
    [disabled]="deleteDisabled"
    (click)="deleted.emit()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
