import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  type IEvent,
  isEvent,
  type IWaitListUrgency,
  type WaitListUrgency,
  WAIT_LIST_URGENCIES,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$, toInt, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Duration, type Moment } from 'moment-timezone';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WaitlistFormGroup } from './waitlist-configuration.formgroup';
import { type IWaitListDetails } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-waitlist-configuration',
  templateUrl: './waitlist-configuration.component.html',
  styleUrls: ['./waitlist-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  standalone: false,
})
export class WaitlistConfigurationComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByUrgency = TrackByFunctions.field<IWaitListUrgency>('name');
  levels: IWaitListUrgency[] = WAIT_LIST_URGENCIES;
  openTime$ = new ReplaySubject<Moment>(1);
  closeTime$ = new ReplaySubject<Moment>(1);
  timeInterval: Duration = moment.duration(30, 'minutes');
  hideToggle$ = new BehaviorSubject<boolean>(false);
  form = new WaitlistFormGroup();
  now = moment();
  @Output() waitListChange = new EventEmitter<IWaitListDetails>();

  @Input()
  set hideToggle(hideToggle: boolean) {
    this.hideToggle$.next(hideToggle);
  }

  constructor() {
    validFormGroupChanges$(this.form)
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((value) => this.waitListChange.emit(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set waitlistDetails(details: Partial<IWaitListDetails>) {
    if (details && this.form.pristine) {
      this.form.patchValue(details, { emitEvent: false });
    }
  }

  @Input()
  set event(event: IEvent) {
    if (event && isEvent(event)) {
      this.form.controls.dateTo.setValue(toMoment(event.from), {
        emitEvent: false,
      });
    }
  }

  @Input()
  set openTime(time: Moment) {
    if (time) {
      this.openTime$.next(time);
    }
  }

  @Input()
  set closeTime(time: Moment) {
    if (time) {
      this.closeTime$.next(time);
    }
  }

  isSelectedUrgency(
    urgency: number,
    selectedUrgency: WaitListUrgency
  ): boolean {
    return urgency === toInt(selectedUrgency);
  }
}
