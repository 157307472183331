import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { surfaceFromRef } from '@principle-theorem/principle-core';
import {
  Arch,
  type IDentalChartViewSurface,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../chart-entity-id';
import { type IChartArch } from '../renderers/chart-arch';
import { ChartElement, type IChartElement } from '../renderers/chart-element';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[prChartArch]',
    templateUrl: './chart-arch.component.html',
    styleUrls: ['./chart-arch.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartArchComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  arch$: ReplaySubject<IChartArch> = new ReplaySubject(1);
  selector$: Observable<IChartElement>;
  indicator$: Observable<IChartElement>;
  label$: Observable<IChartElement>;
  disabled$: Observable<boolean>;
  badge$: Observable<number>;
  labelText$: Observable<string>;

  constructor(private _chartStore: ChartFacade) {
    this.disabled$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isDisabledSurface$(
          this._chartId,
          surfaceFromRef(view.id)
        )
      )
    );
    this.selector$ = this.arch$.pipe(
      map((arch: IChartArch) => this._buildSelector(arch))
    );
    this.indicator$ = this.arch$.pipe(
      map((arch: IChartArch) => this._buildIndicator(arch))
    );
    this.label$ = this.arch$.pipe(
      map((arch: IChartArch) => this._buildLabel(arch))
    );
    this.badge$ = this.view$.pipe(
      map((view: IDentalChartViewSurface) => view.badge),
      startWith(0)
    );
    this.labelText$ = this.arch$.pipe(
      map(
        (arch: IChartArch) => `${this._isUpper(arch) ? 'Upper' : 'Lower'}  Arch`
      ),
      startWith('')
    );
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    this.view$.next(view);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('prChartArch')
  set arch(arch: IChartArch) {
    this.arch$.next(arch);
  }

  private _isUpper(arch: IChartArch): boolean {
    return arch.arch === Arch.Upper;
  }

  private _buildSelector(parent: IChartArch): IChartElement {
    const selector: ChartElement = new ChartElement();
    selector.width = parent.width;
    selector.height = parent.height;
    return selector.toInterface();
  }

  private _buildLabel(parent: IChartArch): IChartElement {
    const padding = 10;
    const textSize: number = padding + 20;
    const label: ChartElement = new ChartElement();
    label.xPos = padding;
    label.yPos = this._isUpper(parent) ? textSize : parent.height - textSize;
    return label.toInterface();
  }

  private _buildIndicator(parent: IChartArch): IChartElement {
    const indicator: ChartElement = new ChartElement();
    indicator.xPos = parent.width / 2;
    indicator.yPos = parent.height / 2;
    return indicator.toInterface();
  }
}
