import {
  isEnumValue,
  isObject,
  type DocumentReference,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IAppointment } from '../../appointment/appointment';
import { AnyAutomation } from '../../automation/automations';
import { type IRelativeSchedulingRules } from '../../scheduling-rules';
import { type IStaffer } from '../../staffer/staffer';
import { type ITreatmentCategory } from '../../treatment-category';
import { type IPricedServiceCodeEntry } from '../service-provider/providers/ada-code';
import { type IChartedTreatment } from './charted-treatment';

export interface IMultiStepTreatment {
  name: string;
  steps: ITreatmentStep[];
}

export interface ITreatmentStep extends ISoftDelete {
  name: string;
  appointment?: DocumentReference<IAppointment>;
  status: TreatmentStepStatus;
  serviceCodes: IPricedServiceCodeEntry[];
  treatments: IChartedTreatment[];
  schedulingRules: IRelativeSchedulingRules;
  display: ITreatmentStepDisplay;
  practitionerRef?: DocumentReference<IStaffer>;
}

export interface ITreatmentStepDisplay {
  overrideTreatmentCategory?: DocumentReference<ITreatmentCategory>;
  primaryTreatmentCategory?: DocumentReference<ITreatmentCategory>;
}

export enum TreatmentStepStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
  Current = 'current',
}

export const TREATMENT_STEP_STATUSES: TreatmentStepStatus[] = [
  TreatmentStepStatus.Complete,
  TreatmentStepStatus.Current,
  TreatmentStepStatus.Incomplete,
];

export const TREATMENT_STEP_STATUS_COLOUR_MAP: {
  [key in TreatmentStepStatus]: string;
} = {
  [TreatmentStepStatus.Incomplete]: 'default',
  [TreatmentStepStatus.Current]: 'primary',
  [TreatmentStepStatus.Complete]: 'accent',
};

export function isTreatmentStep(data: unknown): data is ITreatmentStep {
  return (
    isObject(data) &&
    isString(data.name) &&
    'status' in data &&
    isEnumValue(TreatmentStepStatus, data.status) &&
    'schedulingRules' in data
  );
}

export enum TreatmentStepCollection {
  Automations = 'automations',
}

/**
 * @deprecated Use AnyAutomation instead
 */
export type TreatmentStepAutomation = AnyAutomation;
