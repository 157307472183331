<ng-container *ngIf="tags$ | ngrxPush as tags">
  <ng-container *ngIf="tags.length">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon *ngIf="!compact" class="tag-icon" matTooltip="Tags"
        >sell</mat-icon
      >
      <mat-chip-listbox [selectable]="false">
        <mat-chip
          *ngFor="let tag of tags; trackBy: trackByTag"
          [style.--mdc-chip-label-text-color]="tag | tagTextColour"
          [style.background-color]="tag.hexColour"
          disableRipple
        >
          {{ tag.name }}
        </mat-chip>
      </mat-chip-listbox>
    </div>
  </ng-container>
</ng-container>
