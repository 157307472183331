import { IMenuButton } from '@principle-theorem/editor';
import {
  ISubmenuButton,
  MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { startCase } from 'lodash';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { AIMenuButtonFilters } from '../ai-menu-button-filters';

export function createAdjustToneButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  ISubmenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu',
      compact: false,
      buttonText: 'Adjust Tone',
      tooltip: `Adjusts the tone of voice`,
      icon: 'mic',
      command: (editor) =>
        editor.chain().focus().aiAdjustTone('').focus().run(),
      isDisabledFn: (editor) =>
        AIMenuButtonFilters.disableWithoutTextSelection(editor),
      submenuItems: createToneSubmenuButtons(),
    },
  });
}

const tones = [
  'business',
  'casual',
  'conversational',
  'creative',
  'formal',
  'friendly',
  'informative',
  'objective',
];

export function createToneSubmenuButtons(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
>[] {
  return tones.map((tone) => () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      textOnly: true,
      buttonText: startCase(tone),
      tooltip: tone,
      icon: '',
      command: (editor) =>
        editor.chain().focus().aiAdjustTone(tone).focus().run(),
    },
  }));
}
