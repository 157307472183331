import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ICustomFormData,
  IPatient,
  IPatientForm,
  PatientFormStatus,
} from '@principle-theorem/principle-core/interfaces';
import { PatientFormStore } from '../patient-form.store';
import { PatientForm } from '@principle-theorem/principle-core';
import { WithRef } from '@principle-theorem/shared';
import { PatientDetailsFormDisplayComponent } from '../../patient-details-form-display/patient-details-form-display.component';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-form-content',
    templateUrl: './patient-form-content.component.html',
    styleUrl: './patient-form-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientFormContentComponent {
  readonly store = inject(PatientFormStore);
  customFormData$: Observable<ICustomFormData<object> | undefined>;

  @ViewChild(PatientDetailsFormDisplayComponent)
  patientDetailsFormDisplay?: PatientDetailsFormDisplayComponent;

  constructor() {
    this.customFormData$ = this.store.form$.pipe(
      switchMap((form) =>
        form ? this._getCustomFormData(form) : of(undefined)
      )
    );
  }

  patientDetailsChange(change: Partial<IPatient>): void {
    const patientUpdates = this.patientDetailsFormDisplay?.isValid
      ? change
      : {};
    this.store.setPatientDetailsUpdates(patientUpdates);
  }

  private async _getCustomFormData(
    form: WithRef<IPatientForm>
  ): Promise<ICustomFormData<object> | undefined> {
    if (form.status !== PatientFormStatus.Issued) {
      return PatientForm.getCustomFormData(form, true);
    }

    const config = await PatientForm.getCustomFormConfig(form);
    return PatientForm.getCustomFormData(
      form,
      config?.prefillFromPreviousSubmission
    );
  }
}
