<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Patient Flow"
 />

<div class="layout-padding">
  <div fxLayout="row" fxLayoutAlign="start center" class="layout-margin">
    <h2 fxFlex>Select Date Range</h2>
    <pt-date-range-form
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
     />
  </div>

  <div class="layout-margin mat-elevation-z1">
    <pr-drilldown-chart [dataBuilder]="dataBuilder" [measures]="measures" />
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="start stretch"
  >
    <div
      fxFlex
      class="layout-margin table-previews"
      fxLayout="row wrap"
      fxLayoutAlign="center start"
      fxLayoutAlign.gt-xs="start start"
    >
      <ng-container *ngFor="let measure of measures; trackBy: trackByMeasure">
        <pr-drilldown-preview
          [dataBuilder]="dataBuilder"
          [measure]="measure"
          [selected]="isSelected(measure)"
          (click)="selectMetric(measure)"
         />
      </ng-container>
    </div>
  </div>

  <pr-patient-demographics *ngIf="dataBuilder" [dataBuilder]="dataBuilder" />

  <div class="layout-margin">
    <pr-table-chart
      fxFlex
      [chart]="tableChartCard"
      [replacementHeaders]="replacementHeaders"
     />
  </div>
</div>
