import {
  FeeSchedule,
  Patient,
  ReferralSource,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ContactNumberLabel,
  FailedDestinationEntityRecord,
  Gender,
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  PatientStatus,
  SourceEntityRecordStatus,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedicareCard,
  type IPatient,
  type IPracticeMigration,
  type IReferralSource,
  type IReferralSourceConfiguration,
  type IStaffer,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  getDoc,
  getError,
  snapshotCombineLatest,
  type INamedDocument,
  type WithRef,
  toNamedDocument,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ExactSex,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import { ExactContactMappingHandler } from '../mappings/contact-to-referral-source';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import {
  StaffToPracticeMapping,
  resolveExactStaffLocation,
} from '../mappings/practitioner-to-practice-mapping';
import { ExistingPatientDestinationEntity } from './existing-patients';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: `
    We have not implemented any family linking logic in this migration as yet. At most there is a head_of_family id but its not always used and, at times, users seem to
    store this data in other fields as if it were a note.

    Another thing to note is that Exact allows up to three referrals per patient row (where Principle only has one) so we are defining the order of preference as:
      1. Patient referrer (referral_patient_id)
      2. Contact Source (referrer_id)
      3. Referral Source (referral_source_id)
    `,
  },
});

export interface IPatientMigrationData {
  sourcePatientId: string;
  patient: IPatient;
  createdAt?: Timestamp;
}

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[];
  staffToPractice: WithRef<ITranslationMap<IPractice>>[];
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<IExactPatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();
  override batchLimit = 100;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    existingPatients: new ExistingPatientDestinationEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  customMappings = {
    referralSources: new ExactContactMappingHandler(),
    staff: new ExactStafferMappingHandler(),
    staffToPractice: new StaffToPracticeMapping(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const referralSources$ =
      this.customMappings.referralSources.getRecords$(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const staffToPractice$ =
      this.customMappings.staffToPractice.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        brand$,
        staff$,
        referralSources$,
        staffToPractice$,
      ]),
    ]).pipe(
      map(
        ([sourcePatients, [brand, staff, referralSources, staffToPractice]]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            brand,
            referralSources,
            staffToPractice,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const translations = data.sourcePatient.data.translations;
    const sourcePatientId =
      this.sourceEntities.patients.getSourceRecordId(patient);

    const referrer = await getReferralSource(
      patient,
      data.referralSources,
      translationMap
    );

    const preferredDentist = await getPractitioner(
      patient.preferred_practitioner_initials,
      translationMap,
      data.staff
    );
    const preferredHygienist = await getPractitioner(
      patient.preferred_hygienist_initials,
      translationMap,
      data.staff
    );
    const medicareCard = getMedicareCard(patient, translations);
    const preferredFeeSchedule = patient.preferred_fee_schedule
      ? await resolveFeeSchedule(
          translationMap,
          await FeeSchedule.getPreferredOrDefault(
            migration.configuration.organisation
          )
        )(patient.preferred_fee_schedule)
      : undefined;
    const preferredPractice = await getStaffPractice(
      patient.preferred_practitioner_initials ??
        patient.preferred_hygienist_initials,
      translationMap,
      data.staffToPractice,
      migration.configuration.practices
    );

    try {
      return {
        sourcePatientId,
        patient: Patient.init({
          name: getName(patient),
          email: patient.email ?? undefined,
          contactNumbers: getContactNumbers(patient),
          dateOfBirth: translations.dateOfBirth ?? undefined,
          gender: getGender(patient),
          address: getAddress(patient),
          referenceId: sourcePatientId,
          referrer,
          preferredDentist,
          preferredHygienist,
          preferredFeeSchedule,
          preferredPractice,
          medicareCard,
          status: patient.is_inactive
            ? PatientStatus.Inactive
            : PatientStatus.Active,
          deleted: patient.is_deleted,
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }
}

export function getName(patient: IExactPatient): string {
  return `${patient.first_name ?? ''} ${patient.last_name ?? ''}`;
}

async function getReferralSource(
  patient: IExactPatient,
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[],
  translationMap: TranslationMapHandler
): Promise<IReferralSource | undefined> {
  return patient.referral_patient_id
    ? getPatientReferralSource(patient.referral_patient_id, translationMap)
    : getReferralSourceFromMap(
        patient.referrer_id ?? patient.referral_source_id,
        referralSources
      );
}

async function getPatientReferralSource(
  id: string,
  translationMap: TranslationMapHandler
): Promise<IReferralSource | undefined> {
  const patientRef = await translationMap.getDestination<IPatient>(
    id,
    PATIENT_RESOURCE_TYPE
  );

  if (!patientRef) {
    return;
  }

  const referralPatient = await getDoc(patientRef);
  return ReferralSource.toReferrer(referralPatient);
}

async function getReferralSourceFromMap(
  id: string | null,
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[]
): Promise<IReferralSource | undefined> {
  if (!id) {
    return;
  }

  const referralSourceMap = referralSources.find(
    (referralSource) => referralSource.sourceIdentifier === id
  );
  if (!referralSourceMap?.destinationIdentifier) {
    return;
  }

  const referralSource = await getDoc(referralSourceMap.destinationIdentifier);
  return ReferralSource.toReferrer(referralSource);
}

export function getGender(patient: IExactPatient): Gender {
  switch (patient.sex) {
    case ExactSex.Male:
      return Gender.Male;
    case ExactSex.Female:
      return Gender.Female;
    default:
      return Gender.NotSpecified;
  }
}

export function getAddress(patient: IExactPatient): string {
  const addressFields = compact([
    patient.home_address_1?.trim(),
    patient.home_address_2?.trim(),
    patient.home_address_suburb?.trim() ??
      patient.home_address_town_city?.trim(),
    patient.home_address_postcode?.toString().trim(),
    patient.home_address_state?.trim(),
  ]);

  if (!addressFields.length) {
    return '';
  }

  return addressFields.join(', ');
}

export function getContactNumbers(patient: IExactPatient): IContactNumber[] {
  return compact([
    patient.mobile_number
      ? {
          label: ContactNumberLabel.Mobile,
          number: patient.mobile_number,
        }
      : undefined,
    patient.home_phone
      ? {
          label: ContactNumberLabel.Home,
          number: patient.home_phone,
        }
      : undefined,
    patient.work_phone
      ? {
          label: ContactNumberLabel.Work,
          number: patient.work_phone,
        }
      : undefined,
  ]);
}

async function getPractitioner(
  initials: string | null,
  translationMap: TranslationMapHandler,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer> | undefined> {
  if (!initials) {
    return;
  }
  const staffer = await resolveExactStaffer(initials, translationMap, staff);
  return staffer ? stafferToNamedDoc(staffer) : undefined;
}

async function getStaffPractice(
  initials: string | null,
  translationMap: TranslationMapHandler,
  staffToPractice: WithRef<ITranslationMap<IPractice>>[],
  migrationPractices: INamedDocument<IPractice>[]
): Promise<INamedDocument<IPractice> | undefined> {
  if (!initials) {
    return;
  }
  const practice = await resolveExactStaffLocation(
    initials,
    translationMap,
    staffToPractice,
    migrationPractices
  );
  return practice ? toNamedDocument(practice) : undefined;
}

function getMedicareCard(
  patient: IExactPatient,
  translations: IExactPatientTranslations
): IMedicareCard | undefined {
  if (!patient.medicare_card_number || !patient.medicare_card_position) {
    return;
  }
  return {
    number: patient.medicare_card_number,
    subNumerate: `${patient.medicare_card_position}`,
    expiryDate: translations.medicareExpiryDate,
  };
}
