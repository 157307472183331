import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ChartedServiceExclusiveGroup,
  ServiceCodeEntry,
  getServiceCodeNumberString,
} from '@principle-theorem/principle-core';
import {
  IChartedServiceExclusiveGroup,
  IChartedSurface,
  IPricedServiceCodeEntry,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$ } from '@principle-theorem/shared';
import { omit } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-service-item-exclusive-group-item',
  templateUrl: './service-item-exclusive-group-item.component.html',
  styleUrls: ['./service-item-exclusive-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceItemExclusiveGroupItemComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _chartedSurfaces$ = new BehaviorSubject<IChartedSurface[]>([]);
  trackByCode = TrackByFunctions.ref<IPricedServiceCodeEntry>('code.ref');
  group$ = new ReplaySubject<IChartedServiceExclusiveGroup>(1);
  selectedCode = new TypedFormControl<IPricedServiceCodeEntry | undefined>();
  @Input() compact = false;
  @Output() groupChange = new EventEmitter<
    Partial<IChartedServiceExclusiveGroup>
  >();

  disabled$ = new ReplaySubject<boolean>(1);

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor() {
    this.disabled$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((disabled) =>
        disabled ? this.selectedCode.disable() : this.selectedCode.enable()
      );

    this.selectedCode.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((selected?: IPricedServiceCodeEntry) => {
        this.groupChange.emit({
          selected: selected ? Number(selected.code) : undefined,
        });
      });

    this.group$
      .pipe(
        isChanged$(),
        map((group) => ChartedServiceExclusiveGroup.getSelected(group)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((group) => {
        this.selectedCode.setValue(group, {
          emitEvent: false,
        });
      });
  }

  @Input()
  set group(group: IChartedServiceExclusiveGroup) {
    if (group) {
      this.group$.next(group);
    }
  }

  @Input()
  set chartedSurfaces(chartedSurfaces: IChartedSurface[]) {
    this._chartedSurfaces$.next(chartedSurfaces);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  resetPrice(
    group: IChartedServiceExclusiveGroup,
    selected: IPricedServiceCodeEntry
  ): void {
    this.groupChange.next({
      ...group,
      serviceCodes: group.serviceCodes.map((item) => {
        if (item.uuid !== selected.uuid) {
          return item;
        }
        return omit(item, 'priceOverride');
      }),
    });
  }

  itemUpdate(
    group: IChartedServiceExclusiveGroup,
    current: IPricedServiceCodeEntry,
    changes: Partial<IPricedServiceCodeEntry>
  ): void {
    group.serviceCodes = group.serviceCodes.map((code) => {
      if (code.uuid !== current.uuid) {
        return code;
      }
      return {
        ...code,
        ...changes,
      };
    });
    this.groupChange.next(group);
  }

  compareWithFn(
    aCode?: IPricedServiceCodeEntry,
    bCode?: IPricedServiceCodeEntry
  ): boolean {
    return aCode && bCode
      ? getServiceCodeNumberString(aCode.code) ===
          getServiceCodeNumberString(bCode.code)
      : false;
  }

  getCodeName(entry: IPricedServiceCodeEntry): string | undefined {
    return ServiceCodeEntry.getName(entry);
  }
}
