import { compact, isArray, isNil, isUndefined } from 'lodash';
import { isEnumValue, isObject } from './common';
import { type TypeGuardFn } from './utility-types';

export class TypeGuard {
  static merge<T, K>(
    tGuard: TypeGuardFn<T>,
    kGuard: TypeGuardFn<K>
  ): TypeGuardFn<T & K> {
    return (data): data is T & K => tGuard(data) && kGuard(data);
  }

  static interface<T extends object>(
    typeGuardMap: Record<
      keyof T,
      TypeGuardFn<unknown> | TypeGuardFn<unknown>[]
    >,
    logError: boolean = false
  ): TypeGuardFn<T> {
    return (data): data is T =>
      isObject(data) &&
      Object.entries<TypeGuardFn<unknown> | TypeGuardFn<unknown>[]>(
        typeGuardMap
      ).every(([key, guardFns]) => {
        if (!isArray(guardFns)) {
          const passed = guardFns(data[key]);
          if (!passed && logError) {
            // eslint-disable-next-line no-console
            console.error(
              `TypeGuard.interface failed for key: ${key}, value: ${String(
                data[key]
              )}`
            );
          }

          return passed;
        }

        const passed = guardFns.some((guardFn) => guardFn(data[key]));
        if (!passed && logError) {
          // eslint-disable-next-line no-console
          console.error(
            `TypeGuard.interface failed for key: ${key}, value: ${String(
              data[key]
            )}`
          );
        }

        return passed;
      });
  }

  static arrayOf<T>(guardFn: TypeGuardFn<T>): TypeGuardFn<T[]> {
    return (data): data is T[] =>
      isArray(data) && data.every((item) => guardFn(item));
  }

  static nilOr<T>(guardFn: TypeGuardFn<T>): TypeGuardFn<T | undefined | null> {
    return (data): data is T | undefined | null =>
      isNil(data) ? true : guardFn(data);
  }

  static undefinedOr<T>(guardFn: TypeGuardFn<T>): TypeGuardFn<T | undefined> {
    return (data): data is T | undefined =>
      isUndefined(data) ? true : guardFn(data);
  }

  static enumValue<T>(enumType: T): (data: unknown) => data is T[keyof T] {
    return (data): data is T[keyof T] => isEnumValue(enumType, data);
  }

  static isOneOf<P, Q, R>(
    guardFn1: TypeGuardFn<P>,
    guardFn2: TypeGuardFn<Q>,
    guardFn3?: TypeGuardFn<R>
  ): TypeGuardFn<P | Q> {
    return (data): data is P | Q =>
      compact([guardFn1, guardFn2, guardFn3]).some((guardFn) => guardFn(data));
  }

  static noGuard<T>(): TypeGuardFn<T> {
    return (data): data is T => (data ? true : true);
  }
}

/**
 * @deprecated Use TypeGuard.arrayOf instead
 */
export function isArrayOfGuard<T>(
  data: unknown,
  guardFn: TypeGuardFn<T>
): data is T[] {
  return isArray(data) && data.every((item) => guardFn(item));
}
