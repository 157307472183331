import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  cancelAction,
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  toSerialisedEntityModel$,
} from '@principle-theorem/ng-shared';
import { AutomatedNotification } from '@principle-theorem/principle-core';
import {
  AutomationStatus,
  type IAppointmentScopeData,
  type IAutomation,
  type IPractice,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  serialise$,
  type DocumentReference,
  toEntityModel,
} from '@principle-theorem/shared';
import {
  filterUndefined,
  isReffable,
  patchDoc,
  type Reffable,
  unserialise$,
} from '@principle-theorem/shared';
import {
  combineLatest,
  from,
  type Observable,
  of,
  type OperatorFunction,
} from 'rxjs';
import {
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AutomationDialogService } from '../../services/automation-dialog.service';
import { AutomationActions, AutomationListActions } from '../actions';
import { AutomationsFacade } from '../facades/automations.facade';
import { type AutomationEntity } from '../models';
import { IAutomatedNotificationDialogData } from '../../components/notification-dialog/notification-dialog.component';

@Injectable()
export class AutomationsListEffects {
  private _actions$ = inject(Actions);
  private _automationsFacade = inject(AutomationsFacade);
  addTask$: Observable<Action> = createEffect(() => this._addTask$());
  editTask$: Observable<Action> = createEffect(() => this._editTask$());
  addNotification$: Observable<Action> = createEffect(() =>
    this._addNotification$()
  );
  editNotification$: Observable<Action> = createEffect(() =>
    this._editNotification$()
  );
  addFromTemplate$: Observable<Action> = createEffect(() =>
    this._addFromTemplate$()
  );
  confirmCancellation$: Observable<Action> = createEffect(() =>
    this._confirmCancellation$()
  );
  cancelSelected$: Observable<Action> = createEffect(() =>
    this._cancelSelected$()
  );

  constructor(
    private _dialog: MatDialog,
    private _automationDialogService: AutomationDialogService,
    private _snackBar: MatSnackBar
  ) {}

  private _addTask$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.addGeneratedTask),
      unserialise$(),
      mergeMap(({ event, stepRef, triggerAfterDate }) =>
        from(
          this._automationDialogService.openTaskAdd(event, triggerAfterDate)
        ).pipe(
          filterUndefined(),
          map((automation) => ({
            automation: toEntityModel(automation),
            stepRef,
          })),
          serialise$()
        )
      ),
      map(({ automation, stepRef }) => {
        if (!automation) {
          return cancelAction();
        }
        return AutomationActions.addAutomation({
          automation,
          stepRef,
        });
      })
    );
  }

  private _editTask$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.editGeneratedTask),
      unserialise$(),
      switchMap(
        ({
          id,
          generatedTask,
          event,
          triggerDate,
          triggerAfterDate,
          skipEffect,
        }) =>
          from(
            this._automationDialogService.openTaskEdit(
              generatedTask,
              event,
              triggerDate,
              triggerAfterDate
            )
          ).pipe(filterUndefined(), this._dispatchUpdate(id, skipEffect))
      )
    );
  }

  private _addNotification$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.addNotification),
      unserialise$(),
      mergeMap(({ scope, scopeData, event, stepRef, triggerAfterDate }) => {
        const dialogData: IAutomatedNotificationDialogData = {
          scope,
          scopeData,
          useRelativeTime: true,
        };
        return from(
          this._automationDialogService.openNotificationDialog(
            dialogData,
            event,
            triggerAfterDate
          )
        ).pipe(
          filterUndefined(),
          map((automation) => ({
            automation: toEntityModel(automation),
            stepRef,
          })),
          serialise$()
        );
      }),
      map(({ automation, stepRef }) => {
        if (!automation) {
          return cancelAction();
        }
        return AutomationActions.addAutomation({
          automation,
          stepRef,
        });
      })
    );
  }

  private _addFromTemplate$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.addFromTemplate),
      unserialise$(),
      mergeMap((data) =>
        this._automationDialogService
          .openFromTemplateDialog(
            data.event.practice.ref as DocumentReference<IPractice>,
            data.event
          )
          .pipe(
            filterUndefined(),
            map((automation) => ({
              automation: toEntityModel(automation),
              stepRef: data.stepRef,
            })),
            serialise$()
          )
      ),
      map(({ automation, stepRef }) => {
        if (!automation) {
          return cancelAction();
        }
        return AutomationActions.addAutomation({
          automation,
          stepRef,
        });
      })
    );
  }

  private _editNotification$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.editNotification),
      unserialise$(),
      concatMap(
        ({
          id,
          notification,
          event,
          triggerDate,
          triggerAfterDate,
          skipEffect,
          useRelativeTime,
          configRef,
        }) => {
          // TODO: Render based on actual scope data. https://app.clickup.com/t/860qzurhu
          const scopeData: IAppointmentScopeData = {} as IAppointmentScopeData;
          return from(
            AutomatedNotification.getTemplateScope(notification)
          ).pipe(
            switchMap((scope) => {
              const dialogData: IAutomatedNotificationDialogData = {
                scope,
                scopeData,
                notification,
                triggerDate,
                useRelativeTime,
                configRef,
              };
              return from(
                this._automationDialogService.openNotificationDialog(
                  dialogData,
                  event,
                  triggerAfterDate
                )
              ).pipe(filterUndefined(), this._dispatchUpdate(id, skipEffect));
            })
          );
        }
      )
    );
  }

  private _confirmCancellation$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.cancelSelected),
      withLatestFrom(
        this._automationsFacade.selectedAutomations$.pipe(
          map((selected) =>
            selected.filter(
              (automation) => automation.status !== AutomationStatus.Cancelled
            )
          )
        )
      ),
      switchMap(([_, cancellable]) => {
        if (!cancellable.length) {
          this._snackBar.open('All selected automations previously cancelled');
          return of(cancelAction());
        }
        const data: IConfirmationDialogData = confirmationDialogData({
          title: 'Cancel Automations',
          prompt: `Are you sure you want to cancel selected automations?`,
        });
        return this._dialog
          .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
            ConfirmDialogComponent,
            DialogPresets.small({ data })
          )
          .afterClosed()
          .pipe(
            map((confirmed) => {
              if (!confirmed) {
                return cancelAction();
              }
              const ids = cancellable.map((automation) => automation.uid);
              return AutomationListActions.cancelSelectedConfirmed({ ids });
            })
          );
      })
    );
  }

  private _cancelSelected$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationListActions.cancelSelectedConfirmed),
      withLatestFrom(this._automationsFacade.selectedAutomations$),
      concatMap(([{ ids }, automations]) => {
        const updates = automations
          .filter(
            (automation): automation is Reffable<AutomationEntity> =>
              isReffable(automation) && ids.includes(automation.uid)
          )
          .map((automation) => patchDoc(automation.ref, automation));
        return combineLatest(updates);
      }),
      map(() => AutomationListActions.unselectAll()),
      tap(() => this._snackBar.open('Automations Cancelled'))
    );
  }

  private _dispatchUpdate(
    id: string,
    skipEffect: boolean = false
  ): OperatorFunction<IAutomation<TreatmentStepAutomation>, Action> {
    return (source$) => {
      return source$.pipe(
        toSerialisedEntityModel$(),
        map((automation) => {
          if (!automation) {
            return cancelAction();
          }
          const changes = {
            data: automation.data,
            triggerDate: automation.triggerDate,
            status: automation.status,
          };
          const update = {
            id,
            changes,
          };
          return AutomationActions.updateAutomation({ update, skipEffect });
        })
      );
    };
  }
}
