<ng-container *ngrxLet="filteredTreatmentPlans$ as treatmentPlans">
  <div fxLayout="column" fxLayoutGap="16px">
    <div
      class="treatment-plan-container"
      *ngFor="
        let treatmentPlan of treatmentPlans;
        trackBy: trackByTreatmentPlan
      "
    >
      <pr-treatment-plan-header
        [treatmentPlan]="treatmentPlan"
        [canAddStep]="treatmentPlan | map: canAddStep"
        (addStep)="addBookableStep(treatmentPlan)"
      />
      <pr-treatment-plan-steps
        [treatmentPlan]="treatmentPlan"
        [currentPractitioner]="currentPractitioner$ | async"
        [patient]="patient$ | async"
        [canAddStep]="treatmentPlan | map: canAddStep"
        [filterStepStatuses]="filterStepStatuses$ | async"
        (addStep)="addBookableStep(treatmentPlan)"
      />
    </div>
  </div>
</ng-container>
