import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flow, groupBy, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { PATIENT_DEPOSIT_RESOURCE_TYPE } from '../../../destination/entities/patient-deposits';

export const PATIENT_DEPOSIT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Deposit List',
    description: '',
    idPrefix: PATIENT_DEPOSIT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientDeposit {
  original_total_payment_id: number;
  patient_id: number;
  deposit_amount_total: string;
  deposit_used_total: string;
  provider_id: number;
  practice_id: number;
  created_at: string;
  allocations: ID4WPatientDepositAllocation[];
}

export function isD4WPatientDeposit(item: unknown): item is ID4WPatientDeposit {
  return (
    isObject(item) &&
    isNumber(item.original_total_payment_id) &&
    isNumber(item.patient_id) &&
    isNumber(item.provider_id) &&
    isString(item.created_at) &&
    isString(item.deposit_amount_total) &&
    isString(item.deposit_used_total) &&
    TypeGuard.arrayOf(isD4WPatientDepositAllocation)(item.allocations)
  );
}

export interface ID4WPatientDepositAllocation {
  payment_id: number | null;
  amount: string | null;
}

export function isD4WPatientDepositAllocation(
  item: unknown
): item is ID4WPatientDepositAllocation {
  return TypeGuard.interface<ID4WPatientDepositAllocation>({
    payment_id: TypeGuard.nilOr(isNumber),
    amount: TypeGuard.nilOr(isString),
  })(item);
}

export interface ID4WPatientDepositResult
  extends Omit<ID4WPatientDeposit, 'allocations'> {
  deposit_used_payment_id: number | null;
  deposit_used_amount: string;
}

export interface ID4WPatientDepositTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientDepositFilters {
  createdAt: Timestamp;
  providerId: string;
  practiceId: string;
  patientId: string;
  paymentIds: string[];
}

const PATIENT_DEPOSIT_SOURCE_QUERY = `
SELECT
  total_payment.tot_payment_created_at as created_at,
  total_payment.practice_id as practice_id,
  deposit.tot_payment_id_from AS original_total_payment_id,
  deposit.patient_id AS patient_id,
  deposit.amount AS deposit_amount_total,
  deposit.allocated AS deposit_used_total,
  deposit.provider_id AS provider_id,
  deposit_details.payment_id AS deposit_used_payment_id,
  deposit_details.amount AS deposit_used_amount
FROM (
  SELECT * FROM payment_deposit_to
  WHERE ref_status != 'D'
  ORDER BY tot_payment_id_from
  ${OFFSET_PLACEHOLDER}
) AS deposit
INNER JOIN (
  SELECT
    CONCAT(date, ' ',time_created) AS tot_payment_created_at,
    *
  FROM tot_payment
) AS total_payment
ON deposit.tot_payment_id_from = total_payment.tot_paym_id
LEFT JOIN (
  SELECT * FROM payment_deposit_to_details
) AS deposit_details
ON deposit.tot_payment_id_from = deposit_details.tot_payment_id_from
`;

const PATIENT_DEPOSIT_SOURCE_ESTIMATE_QUERY = `
SELECT
  deposit.tot_payment_id_from AS original_total_payment_id
FROM (
  SELECT
    *
  FROM payment_deposit_to
  WHERE ref_status != 'D'
) AS deposit
INNER JOIN (
  SELECT
    tot_paym_id
  FROM tot_payment
) AS total_payment
ON deposit.tot_payment_id_from = total_payment.tot_paym_id
`;

export class PatientDepositSourceEntity extends BaseSourceEntity<
  ID4WPatientDeposit,
  ID4WPatientDepositTranslations,
  ID4WPatientDepositFilters
> {
  sourceEntity = PATIENT_DEPOSIT_SOURCE_ENTITY;
  entityResourceType = PATIENT_DEPOSIT_RESOURCE_TYPE;
  sourceQuery = PATIENT_DEPOSIT_SOURCE_QUERY;
  verifySourceFn = isD4WPatientDeposit;
  override defaultOffsetSize = 0;
  override dateFilterField: keyof ID4WPatientDepositFilters = 'createdAt';
  override transformDataFn = flow([transformDepositResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ original_total_payment_id: number }>(
      migration,
      PATIENT_DEPOSIT_SOURCE_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (deposit) => deposit.original_total_payment_id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WPatientDeposit,
    timezone: Timezone
  ): ID4WPatientDepositTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientDeposit): number {
    return data.original_total_payment_id;
  }

  getSourceLabel(data: ID4WPatientDeposit): string {
    return `${data.original_total_payment_id}`;
  }

  getFilterData(
    data: ID4WPatientDeposit,
    timezone: Timezone
  ): ID4WPatientDepositFilters {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
      providerId: data.provider_id.toString(),
      practiceId: data.practice_id.toString(),
      patientId: data.patient_id.toString(),
      paymentIds: compact(
        data.allocations.map((allocation) => allocation.payment_id?.toString())
      ),
    };
  }
}

function transformDepositResults(
  rows: ID4WPatientDepositResult[]
): ID4WPatientDeposit[] {
  const depositGroups = groupBy(
    rows,
    (deposit) => deposit.original_total_payment_id
  );

  return Object.values(depositGroups).map((depositGroup) => ({
    created_at: depositGroup[0].created_at,
    original_total_payment_id: depositGroup[0].original_total_payment_id,
    patient_id: depositGroup[0].patient_id,
    deposit_amount_total: depositGroup[0].deposit_amount_total,
    deposit_used_total: depositGroup[0].deposit_used_total,
    provider_id: depositGroup[0].provider_id,
    practice_id: depositGroup[0].practice_id,
    allocations: depositGroup.map((allocation) => ({
      payment_id: allocation.deposit_used_payment_id,
      amount: allocation.deposit_used_amount,
    })),
  }));
}
