import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IBreadcrumb,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  filterUndefined,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentTeamService } from '../current-team.service';

@Component({
  selector: 'pr-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditComponent {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  team$: Observable<WithRef<ITeam>>;

  constructor(
    private _currentTeam: CurrentTeamService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope,
    private _dialog: MatDialog
  ) {
    this.team$ = this._currentTeam.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this.team$,
    ]).pipe(
      map(([brand, team]) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Teams', path: '../' },
        { label: team.name },
      ])
    );
  }

  async submit(data: Partial<ITeam>): Promise<void> {
    const team: WithRef<ITeam> = await this._currentTeam.toPromise();
    await patchDoc(team.ref, data);
    this._snackBar.open('Team Updated');
  }

  async delete(): Promise<void> {
    const team: WithRef<ITeam> = await this._currentTeam.toPromise();
    const data = confirmationDialogData({
      title: 'Delete Team',
      prompt: `Are you sure you want to delete the ${team.name} team?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    await deleteDoc(team.ref);
    this._snackBar.open(`${team.name} Team Deleted`);
    await this._router.navigate(['../'], { relativeTo: this._route });
  }
}
