import { NgModule } from '@angular/core';
import { MultiTreatmentConfigListComponent } from './multi-treatment-config-list/multi-treatment-config-list.component';
import { MultiTreatmentConfigEditComponent } from './multi-treatment-config-edit/multi-treatment-config-edit.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MultiTreatmentConfigResolverService } from './multi-treatment-config-resolver.service';
import { ComponentsModule } from '../../components/components.module';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';

@NgModule({
  imports: [NgSharedModule, ComponentsModule, NgFeatureFlagsModule],
  declarations: [
    MultiTreatmentConfigListComponent,
    MultiTreatmentConfigEditComponent,
  ],
  providers: [MultiTreatmentConfigResolverService],
})
export class MultiTreatmentConfigModule {}
