<mat-toolbar color="accent">Health Fund Member Number</mat-toolbar>
<form [formGroup]="form">
  <mat-dialog-content>
    <mat-form-field class="w-full">
      <mat-label>Member Number</mat-label>
      <input matInput formControlName="memberNumber" maxlength="2" required />
      <mat-hint align="end">
        {{ form.value.memberNumber?.length || 0 }}/2
      </mat-hint>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
