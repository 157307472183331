import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { TranslateForPlatformPipe } from './translate-for-platform.pipe';

@NgModule({
  imports: [CommonModule, NgMaterialModule],
  declarations: [TranslateForPlatformPipe],
  exports: [TranslateForPlatformPipe],
})
export class NgProsemirrorModule {}
