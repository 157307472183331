import { type IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodeToNoteMappingHandler } from '../../../mappings/item-code-to-notes';
import {
  ADAItemSourceEntity,
  type ID4WADAItem,
} from '../../source/entities/ada-item';

export class D4WItemCodeToNoteMappingHandler extends ItemCodeToNoteMappingHandler<
  ID4WADAItem,
  ADAItemSourceEntity
> {
  override sourceEntity = new ADAItemSourceEntity();
  override translateFn = (record: ID4WADAItem): IBaseMigrationItemCode => ({
    ...record,
  });
}
