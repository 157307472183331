import { rand } from '@ngneat/falso';
import { initVersionedSchema } from '@principle-theorem/editor';
import {
  IClinicalNote,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { ClinicalNote } from './clinical-note';

export function MockClinicalNote(
  owner: INamedDocument<IStaffer>
): IClinicalNote {
  const content = initVersionedSchema(
    rand([
      `Continue discussion with patient regarding invisalign for their complex case.`,
      `Continue Boyz n the Hood movie at 45 mins.`,
    ])
  );
  return ClinicalNote.init({
    immutable: true,
    content,
    owner,
    recordDate: toISODate(toTimestamp()),
  });
}
