import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogData,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { SterilisationRecord } from '@principle-theorem/principle-core';
import {
  ISterilisationRecord,
  SterilisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentArchive,
  Firestore,
  WithRef,
  snapshot,
  snapshotDefined,
} from '@principle-theorem/shared';
import {
  EditSterilisationRecordDialogComponent,
  IEditSterilisationRecordData,
  IEditSterilisationRecordReturnData,
} from '../edit-sterilisation-record-dialog/edit-sterilisation-record-dialog.component';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SterilisationRecordHistoryDialogComponent,
  ISterilisationRecordHistoryDialogData,
} from '../sterilisation-record-history-dialog/sterilisation-record-history-dialog.component';
import { SterilisationStore } from '../../stores/sterilisation.store';

@Injectable()
export class SterilisationRecordActionsService {
  private _dialog = inject(MatDialog);
  private _org = inject(OrganisationService);
  private _snackBar = inject(MatSnackBar);
  private _store = inject(SterilisationStore);

  async markAsPassed(record: WithRef<ISterilisationRecord>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Mark Sterilisation Record as Passed',
      prompt: 'Are you sure you want to mark this record as passed?',
      submitLabel: 'Mark as Passed',
    });
    if (!(await this._getConfirmation(data))) {
      return;
    }

    await Firestore.patchDoc(record.ref, {
      status: SterilisationStatus.Passed,
    });
    this._snackBar.open('Record Marked as Passed');
  }

  async markAsFailed(record: WithRef<ISterilisationRecord>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Mark Sterilisation Record as Failed',
      prompt: 'Are you sure you want to mark this record as failed?',
      submitLabel: 'Mark as Failed',
    });
    if (!(await this._getConfirmation(data))) {
      return;
    }

    await Firestore.patchDoc(record.ref, {
      status: SterilisationStatus.Failed,
    });
    this._snackBar.open('Record Marked as Failed');
  }

  async edit(
    record: WithRef<ISterilisationRecord>
  ): Promise<IEditSterilisationRecordReturnData | undefined> {
    const updatedRecord = await this._dialog
      .open<
        EditSterilisationRecordDialogComponent,
        IEditSterilisationRecordData,
        IEditSterilisationRecordReturnData
      >(
        EditSterilisationRecordDialogComponent,
        DialogPresets.small({ data: { record } })
      )
      .afterClosed()
      .toPromise();

    if (!updatedRecord) {
      return;
    }

    await DocumentArchive.snapshotToArchive(
      record,
      SterilisationRecord.archiveCol(record),
      undefined,
      (await snapshotDefined(this._org.staffer$)).ref
    );

    await Firestore.patchDoc(record.ref, updatedRecord);
    this._snackBar.open('Record Updated');
    return updatedRecord;
  }

  async delete(record: WithRef<ISterilisationRecord>): Promise<void> {
    await this._store.deleteRecord(record);
    this._snackBar.open('Record Deleted');
  }

  async viewEditHistory(record: WithRef<ISterilisationRecord>): Promise<void> {
    const history = await snapshot(SterilisationRecord.editHistory$(record));
    if (!history.length) {
      return;
    }

    this._dialog.open<
      SterilisationRecordHistoryDialogComponent,
      ISterilisationRecordHistoryDialogData
    >(
      SterilisationRecordHistoryDialogComponent,
      DialogPresets.almostFullscreen({ data: { history } })
    );
  }

  private async _getConfirmation(
    data: IConfirmationDialogData
  ): Promise<boolean | undefined> {
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
