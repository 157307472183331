import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { ACCOUNT_CREDITS_REPORT_BUILDER_DATA_SOURCE } from './account-credits-report-builder-data-source';
import { APPOINTMENT_SERVICE_CODES_REPORT_BUILDER_DATA_SOURCE } from './appointment-service-codes-report-builder-data-source';
import { APPOINTMENT_TREATMENTS_REPORT_BUILDER_DATA_SOURCE } from './appointment-treatments-report-builder-data-source';
import { APPOINTMENTS_REPORT_BUILDER_DATA_SOURCE } from './appointments-report-builder-data-source';
import { INVOICES_REPORT_BUILDER_DATA_SOURCE } from './invoices-report-builder-data-source';
import { PATIENT_INTERACTIONS_REPORT_BUILDER_DATA_SOURCE } from './patient-interactions-report-builder-data-source';
import { PATIENTS_REPORT_BUILDER_DATA_SOURCE } from './patients-report-builder-data-source';
import { PRACTITIONER_INCOME_REPORT_BUILDER_DATA_SOURCE } from './practitioner-income-report-builder-data-source';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { SCHEDULING_EVENTS_BUILDER_DATA_SOURCE } from './scheduling-events-report-builder-data-source';
import { TRANSACTIONS_REPORT_BUILDER_DATA_SOURCE } from './transactions-report-builder-data-source';

export const REPORT_BUILDER_DATA_SOURCES: IReportBuilderDataSource[] = [
  ACCOUNT_CREDITS_REPORT_BUILDER_DATA_SOURCE,
  APPOINTMENTS_REPORT_BUILDER_DATA_SOURCE,
  APPOINTMENT_SERVICE_CODES_REPORT_BUILDER_DATA_SOURCE,
  APPOINTMENT_TREATMENTS_REPORT_BUILDER_DATA_SOURCE,
  INVOICES_REPORT_BUILDER_DATA_SOURCE,
  PATIENTS_REPORT_BUILDER_DATA_SOURCE,
  PATIENT_INTERACTIONS_REPORT_BUILDER_DATA_SOURCE,
  PRACTITIONER_INCOME_REPORT_BUILDER_DATA_SOURCE,
  TRANSACTIONS_REPORT_BUILDER_DATA_SOURCE,
  SCHEDULING_EVENTS_BUILDER_DATA_SOURCE,
];

export function resolveDataSource(
  id?: ReportBuilderDataSourceId
): IReportBuilderDataSource | undefined {
  if (!id) {
    return;
  }
  const result = REPORT_BUILDER_DATA_SOURCES.find((source) => source.id === id);
  if (!result) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't resolve datasource: ${id}`);
  }
  return result;
}
