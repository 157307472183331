<div class="timeline-heading">
  <div fxLayout="row" *ngrxLet="date$ as date">
    <div fxFlex class="mat-headline-4">
      {{ date | amDateFormat: headlineFormat }}
    </div>
    <pr-date-pagination [date]="date" (dateChange)="dateChange($event)" />
  </div>
  <pr-event-type-selector
    [availableEventTypes]="availableEventTypes"
    [(selected)]="filters"
  />
</div>

<mat-accordion class="popout no-content-padding timeline-content">
  <mat-expansion-panel
    [hideToggle]="true"
    *ngFor="
      let eventable of eventsFilter.results$ | async;
      trackBy: trackBySummary
    "
  >
    <mat-expansion-panel-header
      prEnableExpansionPanelHeaderActions
      collapsedHeight="auto"
      expandedHeight="auto"
    >
      <ng-container *ngIf="eventable | map: isAppointmentSummary as summary">
        <pr-appointment-header fxFlex [eventable]="summary">
          {{ summary.metadata.treatmentPlanName }} -
          {{ summary.metadata.treatmentStepName }}
          <ng-content />
        </pr-appointment-header>
      </ng-container>

      <ng-container *ngIf="eventable | map: isGapSummary as summary">
        <pr-gap-header
          fxFlex
          [gap]="summary"
          (click)="gapStore.displayGapOnTimeline(eventable)"
        >
          <ng-content />
        </pr-gap-header>
      </ng-container>

      <ng-container
        *ngIf="eventable | map: isDefaultEventableSummary as summary"
      >
        <pr-eventable-header
          fxFlex
          [eventable]="eventable"
          [staffer]="staffer$ | ngrxPush"
          (edit)="editCalendarEvent($event)"
        />
      </ng-container>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <mat-divider />

      <pr-appointment-card
        *ngIf="eventable | map: isAppointmentSummary as summary"
        [appointment]="summary | map: summaryToAppointment | async"
        [actions]="actions"
      />

      <pr-eventable-card
        *ngIf="eventable | map: isDefaultEventableSummary"
        [eventable]="eventable"
      />
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
