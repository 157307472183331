import {
  getSchemaText,
  initRawSchema,
  RawSchema,
  RawSchemaHelpers,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import { IReffable, isSameRef } from '@principle-theorem/shared';
import {
  INotification,
  INotificationContextRenderer,
} from '@principle-theorem/notifications';
import { stafferIsOfRole } from './notification-helpers';
import {
  isTaskResource,
  ITaskMentionedUserResources,
  MentionResourceType,
  NotificationType,
} from '@principle-theorem/principle-core/interfaces';
import { toMention } from '../../mention/mention';

export class TaskMentionedUserContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      ITaskMentionedUserResources
    >
{
  canRender(notification: INotification): boolean {
    return notification.type === String(NotificationType.TaskMentionedUser);
  }

  isResource(data: unknown): data is ITaskMentionedUserResources {
    return isTaskResource(data);
  }

  render(
    data: ITaskMentionedUserResources,
    currentStaffer?: IReffable
  ): RawSchema | undefined {
    if (!isSameRef(currentStaffer, data.mention.resource.ref)) {
      return;
    }

    const isActor: boolean = stafferIsOfRole(currentStaffer, data.interactor);
    const taskMention = toMention(
      { name: getSchemaText(data.task.title), ref: data.task.ref },
      MentionResourceType.Task
    );

    if (isActor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`You were mentioned in task `),
          toMentionContent(taskMention),
        ]),
        RawSchemaHelpers.blockquoteContent(data.comment),
      ]);
    }

    if (data.interactor) {
      const interactorMention = toMention(
        data.interactor,
        MentionResourceType.Staffer
      );
      return initRawSchema([
        toParagraphContent([
          toMentionContent(interactorMention),
          toTextContent(` mentioned you in task `),
          toMentionContent(taskMention),
        ]),
        RawSchemaHelpers.blockquoteContent(data.comment),
      ]);
    }

    return initRawSchema([
      toParagraphContent([
        toTextContent(`System mentioned you in task `),
        toMentionContent(taskMention),
      ]),
      RawSchemaHelpers.blockquoteContent(data.comment),
    ]);
  }
}
