import { initVersionedSchema } from '@principle-theorem/editor';
import {
  IAutomatedNotificationConfiguration,
  IBrand,
  IRawTemplate,
  isAutomatedNotificationConfiguration,
  ITemplateDefinition,
  ITemplateScopeData,
  TemplateScope,
  TemplateType,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  AtLeast,
  Firestore,
  initFirestoreModel,
  isSameRef,
  multiFilter,
  WithRef,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ConditionHandler,
  getScopedConditions,
  resolveConditionImplementations,
} from '../condition-logic/condition-handler';
import { AutomationConfiguration } from '../models/automation/automation-configuration';

/**
 * Dictates a template, how it is display and what data is required.
 */
export class TemplateDefinition {
  static init(
    overrides: AtLeast<ITemplateDefinition, 'ownerScope'>
  ): ITemplateDefinition {
    return {
      name: '',
      content: initVersionedSchema(),
      type: TemplateType.PlainText,
      scope: TemplateScope.None,
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static async resolveRawTemplate<T extends ITemplateScopeData>(
    template: ITemplateDefinition,
    scopeData: T
  ): Promise<IRawTemplate> {
    const type = template.type;
    const scope = template.scope;

    const handler = new ConditionHandler(
      resolveConditionImplementations(getScopedConditions(template.scope))
    );

    const activeVariant = (
      await asyncForEach(template.variants ?? [], async (variant) => ({
        variant,
        passes: await handler.passes(variant.conditions, scopeData),
      }))
    ).find(({ passes }) => passes)?.variant;
    if (activeVariant) {
      return {
        type,
        scope,
        content: activeVariant.content,
        renderedTemplate: activeVariant.renderedTemplate,
      };
    }

    return {
      type,
      scope,
      content: template.content,
      renderedTemplate: template.renderedTemplate,
    };
  }

  static getAutomationsUsingTemplate$(
    template: WithRef<ITemplateDefinition>
  ): Observable<WithRef<IAutomatedNotificationConfiguration>[]> {
    const brandRef = Firestore.getParentDocRef<IBrand>(template.ref);
    return AutomationConfiguration.all$({
      ref: brandRef,
    }).pipe(
      map((configs) =>
        configs.filter(
          (config): config is WithRef<IAutomatedNotificationConfiguration> =>
            isAutomatedNotificationConfiguration(config)
        )
      ),
      multiFilter((config) => isSameRef(config.templateRef, template.ref))
    );
  }
}
