import { Component } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';
import { FactTables } from '@principle-theorem/reporting';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { IncomeMeasure } from '../../../models/report/income-measure';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';

@Component({
  selector: 'pr-practice-income',
  templateUrl: './practice-income.component.html',
  styleUrls: ['./practice-income.component.sass'],
})
export class PracticeIncomeComponent extends MeasureDataChartComponent {
  trackByPractice = TrackByFunctions.variable<string>();
  replacementHeaders: ITableHeaderReplacement[] = [
    {
      from: 'Owner',
      to: 'Practice',
    },
  ];
  tableData: IChartCard;
  incomePieChart: ChartBuilder;
  forecastChart: IChartCard;
  incomeVsExpensesChart: ChartBuilder;
  potentialIncomeChart: ChartBuilder;

  practiceNames: string[] = ['All Practices'];
  forecastCharts: IChartCard[] = [];
  selectedForecast: IChartCard;
  selectedForecastName: string = this.practiceNames[0];

  _redraw(): void {
    this.incomePieChart = this.dataBuilder
      .toPieChart(
        this._chartMeasures(
          CustomChartType.Pie,
          [
            FactTables.invoiceEvent.invoice.total // TODO: https://app.clickup.com/t/2x03mtk
              .reduceBySum()
              .setLabel('Gross Income'),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        legend: {
          position: 'right',
        },
      });

    this.potentialIncomeChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.invoiceEvent.invoice.total // TODO: https://app.clickup.com/t/2x03mtk
              .reduceBySum()
              .setLabel('Gross Income'),
            FactTables.appointmentEvent.hourlyRate
              .reduceByMultiplier(FactTables.gapEvent.timeUnused)
              .setLabel('Lost Income'),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({ isStacked: true });

    this.tableData = this._tableChartCard(
      [
        FactTables.invoiceEvent.invoice.total // TODO: https://app.clickup.com/t/2x03mtk
          .reduceBySum()
          .setLabel('Gross Income'),
        // TODO: Work out what this is
        // 'netIncome',
        // TODO: Consolidate reporting values from different data sources
        // 'totalExpenses',
        // dentalDraw,
        // TODO: Integrate with Xero
        // 'staffExpenses',
        FactTables.labJobReceived.labJob.cost
          .reduceBySum()
          .setLabel('Lab Expenses'),
        // TODO: Integrate with Xero
        // 'consumables',
        // 'facilityCosts',
      ],
      FactTables.appointmentEvent.practice.name
    );

    this.incomeVsExpensesChart = this._incomeMetricBarChart(
      [
        new IncomeMeasure(
          FactTables.invoiceEvent.invoice.total.reduceBySum() // TODO: https://app.clickup.com/t/2x03mtk
        ),
        // dentalDraw
        // new IncomeMeasure(
        //   FactTables.appointmentCompleted.dentalDraw.reduceBySum(),
        //   true
        // ),
        // TODO: Integrate with Xero
        // new IncomeMetric('staffExpenses', true),
        new IncomeMeasure(
          FactTables.labJobReceived.labJob.cost.reduceBySum(),
          true
        ),
        // TODO: Integrate with Xero
        // new IncomeMetric('consumables', true),
        // new IncomeMetric('facilityCosts', true),
      ],
      FactTables.appointmentEvent.practice.name
    );

    // TODO: Find a way to build this from the same way normal measures are charted
    // this._buildIncomeForecasts();
  }

  // private _buildIncomeForecasts(): void {
  //   let selectedChartIndex: number = 0;
  //   if (this.forecastCharts.length) {
  //     selectedChartIndex = this.practiceNames.indexOf(this.selectedForecastName);
  //     this.forecastCharts = [];
  //   }

  //   this.addToForecastCharts(this.practiceNames[0], this.dataBuilder);

  //   this.dataBuilder.mocker.names.map((name: string) => {
  //     let replacementData: IStatistics[] = this._splitData(name, this.dataBuilder);
  //     let measures: MetricData = this.dataBuilder;
  //     measures.data = replacementData;
  //     if (!this.practiceNames.includes(name)) {
  //       this.practiceNames.push(name);
  //     }
  //     this.addToForecastCharts(name, measures);
  //   });

  //   this.selectedForecast = this.forecastCharts[selectedChartIndex];
  // }

  // private _splitData(name: string, measures: MetricData): IStatistics[] {
  //   return measures.data.filter((stat: IStatistics): boolean => stat.name === name);
  // }
}
