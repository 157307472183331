import { isMixedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  isObject,
  isTimestamp,
  type Timestamp,
} from '@principle-theorem/shared';
import { isBoolean } from 'lodash';

export interface IFollowUp {
  uid: string;
  createFollowUp: boolean;
  followUpDate?: Timestamp;
  noFollowUpReason: MixedSchema;
  createdAt: Timestamp;
}

export function isFollowUp(item: unknown): item is IFollowUp {
  return (
    isObject(item) &&
    'createFollowUp' in item &&
    isBoolean(item.createFollowUp) &&
    'noFollowUpReason' in item &&
    isMixedSchema(item.noFollowUpReason) &&
    'createdAt' in item &&
    isTimestamp(item.createdAt)
  );
}
