export interface INode<T> {
  value: T;
  next?: INode<T>;
  previous?: INode<T>;
}

export class LinkedList<T> {
  private _head?: INode<T>;
  private _tail?: INode<T>;

  constructor(values: T[]) {
    if (values.length) {
      values.map((value: T) => this.append(value));
    }
  }

  get head(): INode<T> | undefined {
    return this._head;
  }

  get tail(): INode<T> | undefined {
    return this._tail;
  }

  isEmpty(): boolean {
    return !this.head;
  }

  append(value: T): LinkedList<T> {
    const node: INode<T> = this._toNode(value);
    if (this.isEmpty()) {
      this._head = node;
      this._tail = node;
      return this;
    }

    node.previous = this.tail;
    if (this._tail) {
      this._tail.next = node;
      this._tail = node;
    }
    return this;
  }

  *items(): IterableIterator<INode<T>> {
    let node: INode<T> | undefined = this.head;
    while (node) {
      yield node;
      node = node.next;
    }
  }

  iterate(iterator: (value: INode<T>) => void): void {
    let node: INode<T> | undefined = this.head;
    while (node) {
      iterator(node);
      node = node.next;
    }
  }

  find(comparator: (value: T) => boolean): INode<T> | undefined {
    if (this.isEmpty()) {
      return;
    }

    let node: INode<T> | undefined = this.head;
    while (node) {
      if (comparator(node.value)) {
        return node;
      }
      node = node.next;
    }
  }

  toArray(): T[] {
    const result: T[] = [];
    this.iterate((item: INode<T>) => result.push(item.value));
    return result;
  }

  private _toNode(value: T): INode<T> {
    return { value };
  }
}
