<div fxLayout="row" fxFill>
  <pr-history-list
    [items]="historyItems$ | ngrxPush"
    [selected]="selectedHistoryItem$ | ngrxPush"
    (selectedChange)="selectedInteraction$.next($event.value)"
   />
  <div fxFlex>
    <pr-interaction compact [interaction]="selectedInteraction$ | ngrxPush" />
  </div>
</div>
