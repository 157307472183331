import { CommonModule } from '@angular/common';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { CardInputComponent } from './card-input/card-input.component';
import { ManageStripeIntegrationComponent } from './manage-stripe-integration/manage-stripe-integration.component';
import { StripeConnectButtonComponent } from './stripe-connect-button/stripe-connect-button.component';
import { StripeFunctions } from './stripe-functions.service';
import { type IStripeConfig, NG_STRIPE_CONFIG } from './stripe-config';
import { StripeService } from './stripe.service';

@NgModule({
  imports: [CommonModule, NgMaterialModule],
  declarations: [
    CardInputComponent,
    ManageStripeIntegrationComponent,
    StripeConnectButtonComponent,
  ],
  providers: [StripeFunctions, StripeService],
  exports: [CardInputComponent, ManageStripeIntegrationComponent],
})
export class NgStripeModule {
  static forRoot(config: IStripeConfig): ModuleWithProviders<NgStripeModule> {
    return {
      ngModule: NgStripeModule,
      providers: [
        {
          provide: NG_STRIPE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
