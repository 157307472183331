<ng-container *ngIf="resource$ | async as resource">
  <div class="add-note" [ngClass]="{ 'add-note-bottom': addNoteBottom }">
    <pr-add-note
      [resource]="resource"
      (noteAdded)="addNote($event, resource)"
     />
  </div>

  <div
    fxFlex
    #scrollMe
    *ngIf="notes$ | async as notes"
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <ng-container *ngIf="notes.length; else noNotes">
      <pr-edit-note
        *ngFor="let note of notes; trackBy: trackByNote"
        [resource]="resource"
        [note]="note"
       />
    </ng-container>
    <ng-container *ngIf="pinnedNotes$ | async as pinnedNotes">
      <div *ngIf="pinnedNotes.length">
        <mat-divider />
        <h3 class="pinned-header">Pinned Notes</h3>
        <pr-edit-note
          *ngFor="let note of pinnedNotes; trackBy: trackByNote"
          [resource]="resource"
          [note]="note"
         />
      </div>
    </ng-container>
    <ng-template #noNotes>
      <pr-empty-state image="list" title="notes" />
    </ng-template>
  </div>
</ng-container>
