import { Component, Input } from '@angular/core';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';

@Component({
  selector: 'pr-chart-tab',
  templateUrl: './chart-tab.component.html',
  styleUrls: ['./chart-tab.component.sass'],
})
export class ChartTabComponent {
  @Input() chartBuilder: ChartBuilder;
  @Input() title: string;
  @Input() value: string;
  @Input() unit: string;
  @Input() summary: string;
}
