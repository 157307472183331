import { initVersionedSchema } from '@principle-theorem/editor';
import {
  AutomatedNotificationType,
  AutomationType,
  EMPTY_RENDERED_TEMPLATE,
  IAutomatedNotification,
  IAutomation,
  IBrand,
  IEvent,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { INamedTypeDocument, Timezone } from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { Automation } from '../automation/automation';
import { AutomatedNotification } from './automated-notification';
import { AutomationTiming } from '../automation/automation-timing';

export function MockAutomatedNotifications(): IAutomatedNotification[] {
  return MOCK_AUTOMATED_NOTIFICATION.map(
    (notification: IAutomatedNotification): IAutomatedNotification => {
      return AutomatedNotification.init(notification);
    }
  );
}

export function MockNotificationAutomations(
  creator: INamedTypeDocument,
  event: IEvent,
  timezone: Timezone = Timezone.AustraliaSydney
): IAutomation<IAutomatedNotification>[] {
  return MockAutomatedNotifications().map((notification) =>
    Automation.init({
      type: AutomationType.AutomatedNotification,
      data: notification,
      creator,
      triggerDate: AutomationTiming.getTriggerDateFromEvent(
        event,
        notification.timing,
        timezone
      ),
      brandRef: MockDocRef<IBrand>(),
    })
  );
}

export const MOCK_AUTOMATED_NOTIFICATION: IAutomatedNotification[] = [
  {
    name: 'Send Patient Appointment Reminder',
    subject: 'Appointment Reminder',
    customTemplate: {
      content: initVersionedSchema(
        'Hello Patient, make sure you show up to your appointment'
      ),
      renderedTemplate: EMPTY_RENDERED_TEMPLATE,
    },
    type: AutomatedNotificationType.EMAIL,
    timing: {
      direction: TimingDirection.Before,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    name: 'Send Patient Feedback',
    subject: 'Dental Collective Feedback Form',
    customTemplate: {
      content: initVersionedSchema(
        'Hello Patient, please fill in our feedback form'
      ),
      renderedTemplate: EMPTY_RENDERED_TEMPLATE,
    },
    type: AutomatedNotificationType.EMAIL,
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    name: 'Send Marketing Email',
    subject: 'Visit the Dental Collective Website Today',
    customTemplate: {
      content: initVersionedSchema('Hello Patient, please visit our website'),
      renderedTemplate: EMPTY_RENDERED_TEMPLATE,
    },
    type: AutomatedNotificationType.EMAIL,
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    name: 'Send Patient Rating',
    subject: '',
    customTemplate: {
      content: initVersionedSchema('Hello Patient, please rate Dr. Dentist.'),
      renderedTemplate: EMPTY_RENDERED_TEMPLATE,
    },
    type: AutomatedNotificationType.SMS,
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
      amount: 2,
    },
  },
];
