import {
  Directive,
  Output,
  EventEmitter,
  type OnInit,
  type OnDestroy,
  ElementRef,
} from '@angular/core';
import {
  ChartKeybindInteraction,
  ChartKeybind,
  isMultiEvent,
} from './chart-interaction';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

@Directive({
  selector: '[prChartKeybindInteractions]',
})
export class ChartKeybindInteractionsDirective implements OnInit, OnDestroy {
  private _element: SVGSVGElement;
  private _chartHotkeys: Hotkey[] = [];
  @Output()
  chartKeybindInteraction = new EventEmitter<ChartKeybindInteraction>();

  constructor(
    private _hotkeys: HotkeysService,
    elementRef: ElementRef<SVGSVGElement>
  ) {
    this._element = elementRef.nativeElement;
    this._chartHotkeys = [
      this._upKeybind(),
      this._downKeybind(),
      this._rightKeybind(),
      this._leftKeybind(),
    ];
  }

  ngOnInit(): void {
    this._hotkeys.add(this._chartHotkeys);
  }

  ngOnDestroy(): void {
    this._hotkeys.remove(this._chartHotkeys);
  }

  private _upKeybind(): Hotkey {
    return this._createHotkey(
      this._withMultiCombos(['up', 'w']),
      ChartKeybind.Up,
      'Select next surface upwards',
      (interaction: ChartKeybindInteraction) =>
        this.chartKeybindInteraction.emit(interaction)
    );
  }

  private _downKeybind(): Hotkey {
    return this._createHotkey(
      this._withMultiCombos(['down', 's']),
      ChartKeybind.Down,
      'Select next surface downwards',
      (interaction: ChartKeybindInteraction) =>
        this.chartKeybindInteraction.emit(interaction)
    );
  }

  private _rightKeybind(): Hotkey {
    return this._createHotkey(
      this._withMultiCombos(['right', 'd']),
      ChartKeybind.Right,
      'Select next surface to the right',
      (interaction: ChartKeybindInteraction) =>
        this.chartKeybindInteraction.emit(interaction)
    );
  }

  private _leftKeybind(): Hotkey {
    return this._createHotkey(
      this._withMultiCombos(['left', 'a']),
      ChartKeybind.Left,
      'Select next surface to the left',
      (interaction: ChartKeybindInteraction) =>
        this.chartKeybindInteraction.emit(interaction)
    );
  }

  private _createHotkey(
    triggers: string[],
    keybind: ChartKeybind,
    description: string,
    handle: (interaction: ChartKeybindInteraction) => void
  ): Hotkey {
    return new Hotkey(
      triggers,
      (event: KeyboardEvent): boolean => {
        const interaction: ChartKeybindInteraction =
          new ChartKeybindInteraction(
            keybind,
            isMultiEvent(event),
            this._element
          );
        handle(interaction);
        return false;
      },
      undefined,
      description
    );
  }

  private _withMultiCombos(keybinds: string[]): string[] {
    return keybinds.reduce((acc: string[], keybind: string) => {
      return acc.concat(this._getMultiCombos(keybind));
    }, []);
  }

  private _getMultiCombos(keybind: string): string[] {
    return [keybind, `alt+${keybind}`, `meta+${keybind}`, `shift+${keybind}`];
  }
}
