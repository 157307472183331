import {
  isObject,
  type INamedDocument,
  type ISODateType,
  type WithRef,
  Region,
} from '@principle-theorem/shared';
import { IAddressMetadata } from '../address/address';
import { type IAppointmentSuggestion } from '../appointment/appointment-suggestion';
import { IBrand } from '../brand';
import { type ITreatmentTemplateWithStep } from '../clinical-charting/treatment/treatment-plan-from-template';
import { type ITreatmentTemplate } from '../clinical-charting/treatment/treatment-template';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type IContactNumber } from './patient';
import { type PatientRelationshipType } from './patient-relationship';

export interface IOnlineBookingData {
  practice: WithRef<IPractice>;
  templates: WithRef<ITreatmentTemplate>[];
  brand: WithRef<IBrand>;
}

export interface IGetBookingSuggestionsRequest {
  practice: INamedDocument<IPractice>;
  template: WithRef<ITreatmentTemplate>;
  dateRange: ISODateType[];
  practitioner?: INamedDocument<IStaffer>;
}

export interface IHasPracticeId {
  practiceId: string;
}

export function isHasPracticeId(data: unknown): data is IHasPracticeId {
  return isObject(data) && 'practiceId' in data;
}

export function isGetBookingSuggestionRequest(
  data: unknown
): data is IGetBookingSuggestionsRequest {
  return (
    isObject(data) &&
    'practice' in data &&
    'template' in data &&
    'dateRange' in data
  );
}

export interface IPatientFormData {
  relationshipType: PatientRelationshipType;
  name: string;
  dateOfBirth: ISODateType;
}

export interface IPrimaryContactFormData
  extends Omit<IPatientFormData, 'relationshipType'> {
  mobileNumber: string;
  email: string;
  address?: IAddressMetadata;
}

export interface ISuggestedPatientData {
  region: Region;
  name?: string;
  dateOfBirth?: ISODateType;
  contactNumbers?: IContactNumber[];
  mobileNumber?: string;
}

export interface IPatientData {
  primaryContact: IPrimaryContactFormData;
  patient?: IPatientFormData;
}

export interface IBookingRequest {
  patientData: IPatientData;
  suggestion: IAppointmentSuggestion;
  template: ITreatmentTemplateWithStep;
  conversionUid?: string;
}

export function isBookingRequest(data: unknown): data is IBookingRequest {
  return isObject(data) && 'patientData' in data && 'suggestion' in data;
}
