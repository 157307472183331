import { Injectable } from '@angular/core';
import {
  MixedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  Interaction,
  Practice,
  SMSIntegration,
  findMobileNumber,
  getPatientContactDetails,
  stafferToNamedDoc,
  toMention,
  type IInteractionError,
} from '@principle-theorem/principle-core';
import {
  CommunicationDirection,
  IInteractionV2,
  IPrincipleMention,
  InteractionType,
  MentionResourceType,
  SMSDeliveryStatus,
  type IPatient,
  type IPractice,
  type IStaffer,
  type SMSConversation,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

@Injectable()
export class SendSMSService {
  constructor(private _organisation: OrganisationService) {}

  async sendMessage(
    body: string,
    patient: WithRef<IPatient>,
    authorRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    conversationRef?: DocumentReference<SMSConversation>
  ): Promise<IInteractionError | undefined> {
    const details = await getPatientContactDetails(patient);
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );
    const patientPhoneNumber = findMobileNumber(
      details?.contactNumbers,
      organisation.region,
      organisation.integrations?.smsProvider
    );

    if (!patientPhoneNumber) {
      return { message: 'Could not resolve mobile number' };
    }

    const practicePhoneNumber = await SMSIntegration.resolvePracticeNumber(
      practiceRef,
      organisation
    );

    if (!practicePhoneNumber) {
      return { message: `Couldn't resolve practice's phone number` };
    }

    const brandRef = Practice.brandDoc({ ref: practiceRef });
    await addDoc(Brand.smsMessageCol({ ref: brandRef }), {
      readBy: [authorRef],
      body,
      status: SMSDeliveryStatus.Pending,
      patientRef: patient.ref,
      authorRef,
      practiceRef,
      conversationRef,
      direction: CommunicationDirection.Outbound,
      practicePhoneNumber,
      patientPhoneNumber,
      deleted: false,
    });
  }

  getSMSMessage(
    staffer: WithRef<IStaffer>,
    contact: IPrincipleMention,
    content: MixedSchema
  ): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.Sms,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent SMS to `),
        toMentionContent(contact),
      ],
      owner: stafferToNamedDoc(staffer),
      content,
    });
  }
}
