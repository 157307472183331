import {
  getTaxRateByRegion,
  roundTo2Decimals,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  determineTaxFromStrategy,
  FeeSchedule,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  IChartedTreatment,
  IDestinationEntityJobRunOptions,
  IFeeSchedule,
  IPractice,
  isToothNumber,
  ITranslationMap,
  ITreatmentCategory,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  Firestore,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  resolveSequentially,
  snapshotCombineLatest,
  sortByCreatedAt,
  sortTimestamp,
  Timezone,
  toISODate,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  determinePlanStatus,
  getChartedRefs,
  getResolvedAt,
  getResolvedBy,
} from '../../../d4w/destination/entities/patient-treatment-plans';
import { DestinationEntity } from '../../../destination/destination-entity';
import { feeScheduleResolverFn } from '../../../destination/entities/fee-schedules';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  IStepWithIdentifiers,
  ITreatmentPlanStepPair,
  PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { PRACTICE_MAPPING } from '../../../mappings/practices';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointments';
import { PatientChartedItemSourceEntity } from '../../source/entities/patient-charted-items';
import {
  ICorePracticePatientTreatment,
  ICorePracticePatientTreatmentFilters,
  ICorePracticePatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatments';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticeItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { CorePracticeItemCodeMappingHandler } from '../mappings/item-codes';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

const patientTreatmentPlanDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
    `This migrates the appointment procedures for each patient's appointment and creates a single step on a single plan for each appointment.`
  );

type PatientTreatmentPlanJobData = IPatientTreatmentPlanJobData<
  ICorePracticePatient,
  ICorePracticePatientTranslations,
  ICorePracticePatientFilters
> & {
  practices: WithRef<ITranslationMap<IPractice>>[];
};

export class PatientTreatmentPlanDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  ICorePracticePatient,
  PatientTreatmentPlanJobData
> {
  override destinationEntity = patientTreatmentPlanDestinationEntity;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    chartedItems: new PatientChartedItemSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practice: new CorePracticePracticeMappingHandler(),
    itemCodes: new CorePracticeItemCodeMappingHandler(),
    itemCodeToTreatment: new CorePracticeItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<PatientTreatmentPlanJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords(translationMap);
    const practices$ = this.customMappings.practice.getRecords(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        practitioners$,
        defaultTreatmentConfiguration$,
        treatmentConfigurationMappings$,
        sourceItemCodes$,
        practices$,
        treatmentCategories$,
        taxRate$,
        treatmentConfigurations$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurationMappings,
            sourceItemCodes,
            practices,
            treatmentCategories,
            taxRate,
            treatmentConfigurations,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurationMappings,
            sourceItemCodes,
            practices,
            treatmentCategories,
            taxRate,
            treatmentConfigurations,
          }))
      )
    );
  }

  // The chartId seems to be the treatment plan itself where the planVisit is the order of the planned treatments. It's not necessarily the case that treatment is performed in this order, but it would dicate how it's displayed in Core Practice.

  // Plan date is purely the correlating `chartedAt` value and `resolvedAt` is dictated by the `completeDate`.

  // We also want to filter out all treatments that are `isVoided` or `isDeleted`.

  // `isPaid` has no correlation to `completeDate` as this is the accounting aspect.

  // `fee` is the true value that will be charted on the invoice, and `planFee` seems to be the quote amount that was given to the patient originally. This is the same for the quantity.

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: PatientTreatmentPlanJobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    try {
      const treatments = (
        await this.sourceEntities.treatments.filterRecords(
          migration,
          'patientId',
          sourcePatientId
        )
      ).filter(
        (treatment) =>
          !treatment.data.data.isVoided && !treatment.data.data.isDeleted
      );

      const planPairs =
        await CorePracticeTreatmentPlanDataBuilder.buildTreatmentPlanData(
          data,
          translationMap,
          treatments,
          migration,
          sourcePatientId
        );

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }
}

export class CorePracticeTreatmentPlanDataBuilder {
  static async buildTreatmentPlanData(
    data: PatientTreatmentPlanJobData,
    translationMap: TranslationMapHandler,
    sourceTreatments: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: number
  ): Promise<ITreatmentPlanStepPair[]> {
    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const treatmentPlansGroups = groupBy(
      sourceTreatments,
      (treatment) => treatment.data.data.chartId ?? 0
    );

    const plans = await asyncForEach(
      Object.values(treatmentPlansGroups),
      async (treatments) => {
        const planId = treatments[0].data.data.chartId?.toString() ?? '';
        const planCreatedAt = this.determinePlanCreatedAt(treatments);

        const practice = await this.resolvePractice(
          data.practices,
          treatments,
          translationMap
        );

        const planPractitioner = this.determinePlanPractitioner(
          treatments,
          data.staff,
          data.practitioners
        );

        const plan = this.buildPlan(
          practice,
          planId,
          planCreatedAt,
          planPractitioner,
          sourcePatientId
        );

        const filteredTreatments = treatments.filter((treatment) =>
          data.practices.some(
            (practiceOption) =>
              practiceOption.sourceIdentifier ===
              treatment.record.filters.locationId.toString()
          )
        );

        const pendingSteps: IStepWithIdentifiers[] = [];
        const completedSteps: IStepWithIdentifiers[] = [];

        await resolveSequentially(filteredTreatments, async (treatment) => {
          const chartedTreatments = await this.getChartedTreatments(
            [treatment.data.data],
            feeScheduleResolverFn(translationMap, defaultFeeSchedule),
            data,
            migration.configuration.timezone
          );

          if (!chartedTreatments.length) {
            return;
          }

          const sourceIdentifier = this.getStepIdentifier(
            sourcePatientId,
            migration.configuration.timezone,
            treatment
          );

          if (!sourceIdentifier) {
            throw new Error(
              `No source identifier found for treatment: ${treatment.record.uid}`
            );
          }

          const existingCompletedStep = completedSteps.find(
            (step) => step.sourceIdentifier === sourceIdentifier
          );

          if (treatment.data.translations.completeDate) {
            if (existingCompletedStep) {
              existingCompletedStep.treatments.push(...chartedTreatments);
              return;
            }

            const completedStep = await this.buildCompletedStep(
              treatment.data.translations.completeDate,
              treatment,
              migration,
              chartedTreatments,
              data.treatmentCategories,
              sourcePatientId
            );

            completedSteps.push(completedStep);
            return;
          }

          if (!treatment.data.translations.planDate) {
            return;
          }

          if (existingCompletedStep) {
            existingCompletedStep.treatments.push(...chartedTreatments);
            return;
          }

          const pendingStep: IStepWithIdentifiers = await this.buildPendingStep(
            treatment.data.translations.planDate,
            treatment,
            migration,
            chartedTreatments,
            data,
            sourceIdentifier
          );

          const existingPendingStep = pendingSteps.find(
            (step) => step.sourceIdentifier === sourceIdentifier
          );

          if (existingPendingStep) {
            existingPendingStep.treatments.push(...pendingStep.treatments);
            return;
          }

          pendingSteps.push(pendingStep);
        });

        const steps = [
          ...sortBy(completedSteps, (step) => step.date),
          ...sortBy(pendingSteps, (step) => step.name),
        ];

        if (!planCreatedAt) {
          const earliestStepDate = steps.sort(sortByCreatedAt).pop()?.createdAt;
          if (earliestStepDate) {
            plan.createdAt = earliestStepDate;
          }
        }

        const status = determinePlanStatus(steps, plan.createdAt);

        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    return compact(
      sortBy(plans, 'sourceIdentifier').reduce((mergedPlans, plan) => {
        const planMatchFn = (mergedPlan: ITreatmentPlanStepPair): boolean =>
          mergedPlan.plan.name === plan.plan.name;

        const existingPlan = mergedPlans.find(planMatchFn);
        if (!existingPlan) {
          return [...mergedPlans, plan];
        }

        const mergedSteps = sortBy(
          [...existingPlan.steps, ...plan.steps],
          'sourceIdentifier'
        ).reduce((steps, step) => {
          const stepMatchFn = (mergedStep: IStepWithIdentifiers): boolean =>
            mergedStep.name === step.name &&
            mergedStep.status === TreatmentStepStatus.Complete &&
            step.status === TreatmentStepStatus.Complete;

          const existingStep = steps.find(stepMatchFn);

          if (!existingStep) {
            return [...steps, step];
          }

          return steps.map((filterStep) => {
            if (!stepMatchFn(filterStep)) {
              return filterStep;
            }

            return {
              ...filterStep,
              treatments: [...filterStep.treatments, ...step.treatments],
            };
          });
        }, [] as IStepWithIdentifiers[]);

        const mergedPlanStatus = determinePlanStatus(
          mergedSteps,
          existingPlan.plan.createdAt
        );

        const mergedPlan = {
          ...existingPlan,
          steps: mergedSteps,
          plan: {
            ...existingPlan.plan,
            status: mergedPlanStatus,
          },
        };

        return [
          ...mergedPlans.filter((filterPlan) => !planMatchFn(filterPlan)),
          mergedPlan,
        ];
      }, [] as ITreatmentPlanStepPair[])
    );
  }

  static async buildPendingStep(
    planDate: Timestamp,
    treatment: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >,
    migration: WithRef<IPracticeMigration>,
    chartedTreatments: IChartedTreatment[],
    data: PatientTreatmentPlanJobData,
    sourceIdentifier: string
  ): Promise<IStepWithIdentifiers> {
    const date = toISODate(planDate, migration.configuration.timezone);
    const stepNumber = treatment.data.data.planVisit ?? 0;

    const step = TreatmentStep.init({
      name: `Pending Step ${stepNumber}`,
      treatments: chartedTreatments,
      status: TreatmentStepStatus.Incomplete,
    });

    const treatmentCategory = await CalculateTreatmentWeight.getPrimaryCategory(
      step,
      data.treatmentCategories
    );

    const createdAt = treatment.data.translations.planDate ?? toTimestamp();

    const pendingStep: IStepWithIdentifiers = {
      ...step,
      display: {
        ...step.display,
        primaryTreatmentCategory: treatmentCategory?.ref,
      },
      createdAt,
      date,
      sourceIdentifier,
    };
    return pendingStep;
  }

  static async buildCompletedStep(
    completeDate: Timestamp,
    treatment: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >,
    migration: WithRef<IPracticeMigration>,
    chartedTreatments: IChartedTreatment[],
    treatmentCategories: WithRef<ITreatmentCategory>[],
    sourcePatientId: number
  ): Promise<IStepWithIdentifiers> {
    const sourceIdentifier = this.getStepIdentifier(
      sourcePatientId,
      migration.configuration.timezone,
      treatment
    );

    if (!sourceIdentifier) {
      throw new Error(
        `No source identifier found for treatment: ${treatment.record.uid}`
      );
    }

    const completedDate = toISODate(
      completeDate,
      migration.configuration.timezone
    );

    const step = TreatmentStep.init({
      name: `Completed ${toMomentTz(
        completedDate,
        migration.configuration.timezone
      ).format(HISTORY_DATE_FORMAT)}`,
      treatments: chartedTreatments,
      status: TreatmentStepStatus.Complete,
    });
    const treatmentCategory = await CalculateTreatmentWeight.getPrimaryCategory(
      step,
      treatmentCategories
    );

    const createdAt = completeDate ?? toTimestamp();
    const completedStep: IStepWithIdentifiers = {
      ...step,
      display: {
        ...step.display,
        primaryTreatmentCategory: treatmentCategory?.ref,
      },
      createdAt,
      date: completedDate,
      sourceIdentifier,
    };
    return completedStep;
  }

  static buildPlan(
    practice: WithRef<IPractice>,
    planId: string,
    planCreatedAt: Timestamp | undefined,
    planPractitioner: INamedDocument<IStaffer> | undefined,
    sourcePatientId: number
  ): ITreatmentPlanStepPair['plan'] {
    return {
      ...TreatmentPlan.init({
        name: this.buildPlanName(practice, planId, planCreatedAt),
        practitioner: planPractitioner
          ? stafferToNamedDoc(planPractitioner)
          : undefined,
        createdAt: planCreatedAt ?? toTimestamp(),
      }),
      sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
    };
  }

  static determinePlanPractitioner(
    treatments: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >[],
    staff: WithRef<ITranslationMap<IStaffer>>[],
    practitioners: WithRef<IStaffer>[]
  ): INamedDocument<IStaffer> | undefined {
    const practitioner = getPractitionerOrDefault(
      treatments[0].data.data.providerId ?? undefined,
      staff,
      practitioners
    );

    if (!practitioner) {
      return;
    }

    return stafferToNamedDoc(practitioner);
  }

  static determinePlanCreatedAt(
    treatments: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >[]
  ): Timestamp | undefined {
    return first(
      compact(
        [...treatments].map(
          (treatment) => treatment.data.translations.completeDate
        )
      )
        .sort(sortTimestamp)
        .reverse()
    );
  }

  static async resolvePractice(
    practices: WithRef<ITranslationMap<IPractice>>[],
    treatments: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >[],
    translationMap: TranslationMapHandler
  ): Promise<WithRef<IPractice>> {
    const sourcePractice = practices.find(
      (practice) =>
        practice.sourceIdentifier ===
        treatments[0].data.data.locationId.toString()
    );

    if (!sourcePractice) {
      throw new Error(
        `No practice found for id: ${treatments[0].data.data.locationId}`
      );
    }

    const practiceRef = await translationMap.getDestination<IPractice>(
      sourcePractice.sourceIdentifier,
      PRACTICE_MAPPING.metadata.type
    );

    if (!practiceRef) {
      throw new Error(
        `No practice found for id: ${sourcePractice.sourceIdentifier}`
      );
    }

    return Firestore.getDoc(practiceRef);
  }

  static buildPlanName(
    practice: WithRef<IPractice>,
    planId: string,
    planCreatedAt?: Timestamp
  ): string {
    const planDateFormatted = planCreatedAt
      ? toMomentTz(planCreatedAt, practice.settings.timezone).format(
          HISTORY_DATE_FORMAT
        )
      : undefined;

    return planDateFormatted
      ? `Treatment Plan - ${planDateFormatted}`.trim()
      : planId
        ? `Treatment Plan - ${planId}`.trim()
        : `Misc Treatment Plan`;
  }

  static getPlanStatus(
    stepStatuses: TreatmentStepStatus[]
  ): TreatmentPlanStatus {
    if (!stepStatuses.length) {
      return TreatmentPlanStatus.Draft;
    }

    return stepStatuses.filter(
      (stepStatus) => stepStatus === TreatmentStepStatus.Complete
    ).length
      ? TreatmentPlanStatus.InProgress
      : TreatmentPlanStatus.Completed;
  }

  static async getChartedTreatments(
    sourceTreatments: ICorePracticePatientTreatment[],
    getPreferredOrDefaultRef: (
      feeSheduleId?: string
    ) => Promise<INamedDocument<IFeeSchedule>>,
    data: PatientTreatmentPlanJobData,
    timezone: Timezone
  ): Promise<IChartedTreatment[]> {
    const treatments: IChartedTreatment[] = [];

    await asyncForEach(sourceTreatments, async (sourceTreatment) => {
      const treatmentProviderId = sourceTreatment.providerId.toString();
      const practitioner = getPractitionerOrDefault(
        treatmentProviderId,
        data.staff,
        data.practitioners
      );

      if (!practitioner) {
        throw new Error(
          `Couldn't resolve practitioner for treatment ${treatmentProviderId}`
        );
      }

      if (!sourceTreatment.itemCode) {
        return;
      }
      const procedureCode = sourceTreatment.itemCode;
      const code = resolveMappedCode(
        data.sourceItemCodes,
        sourceTreatment.itemId.toString(),
        procedureCode
      );
      if (!code) {
        return;
      }

      const itemCodeId = sourceTreatment.itemId.toString();
      const mappedTreatment = data.treatmentConfigurationMappings.find(
        (mapping) => mapping.sourceIdentifier === itemCodeId
      );
      const treatmentConfiguration = mappedTreatment?.destinationIdentifier
        ? await Firestore.getDoc(mappedTreatment?.destinationIdentifier)
        : data.defaultTreatmentConfiguration;

      const feeSchedule = await getPreferredOrDefaultRef();
      const taxStatus = TaxStrategy.GSTFree;
      const price = roundTo2Decimals(sourceTreatment.fee);

      const toothNumber = sourceTreatment.tooth
        ? String(sourceTreatment.tooth).trim()
        : undefined;
      if (!isToothNumber(toothNumber) && toothNumber) {
        // eslint-disable-next-line no-console
        console.info(`${toothNumber} isn't a valid tooth number`);
      }

      const serviceCode = PricedServiceCodeEntry.init({
        code: code.code,
        type: code.type,
        taxStatus,
        price,
        quantity: sourceTreatment.quantity,
        tax: determineTaxFromStrategy(data.taxRate, {
          taxStatus: taxStatus,
          amount: price,
        }),
        chartedSurfaces: isToothNumber(toothNumber)
          ? getChartedRefs(toothNumber, sourceTreatment.surface).map(
              (chartedRef) =>
                ChartedSurface.init({
                  chartedBy: stafferToNamedDoc(practitioner),
                  resolvedBy: getResolvedBy(
                    sourceTreatment.completeDate,
                    practitioner
                  ),
                  resolvedAt: getResolvedAt(
                    sourceTreatment.completeDate,
                    timezone
                  ),
                  chartedRef,
                })
            )
          : [],
      });

      const scopeResolver = new ChartedItemScopeResolver();
      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        serviceCode.chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      treatments.push(
        ChartedTreatment.init({
          uuid: sourceTreatment.id.toString(),
          config: toNamedDocument(treatmentConfiguration),
          feeSchedule,
          serviceCodes: [serviceCode],
          chartedSurfaces: serviceCode.chartedSurfaces,
          attributedTo: stafferToNamedDoc(practitioner),
          scopeRef,
          resolvedBy: getResolvedBy(sourceTreatment.completeDate, practitioner),
          resolvedAt: getResolvedAt(sourceTreatment.completeDate, timezone),
        })
      );
    });

    return treatments;
  }

  static getStepIdentifier(
    sourcePatientId: number,
    timezone: Timezone,
    treatment: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >
  ): string | undefined {
    const planId = treatment.data.data.chartId?.toString() ?? '';
    const completedDate = treatment?.data.translations.completeDate
      ? toISODate(treatment.data.translations.completeDate, timezone)
      : undefined;

    if (completedDate) {
      return `${sourcePatientId}-${planId}-completed-${completedDate}`;
    }

    if (!treatment.data.translations.planDate) {
      return;
    }

    const planDate = toISODate(treatment.data.translations.planDate, timezone);
    const stepNumber = treatment.data.data.planVisit ?? 0;
    return `${sourcePatientId}-${planId}-pending-${planDate}-${stepNumber}`;
  }
}
