import {
  type IAppointmentSuggestion,
  type IEvent,
  type ITreatmentPlanWithBookableStep,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  type ITimestampRange,
  reduceToSingleArrayFn,
  TimeBucket,
  TIME_FORMAT,
  TIME_FORMAT_24HR,
  toMoment,
  toTimestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  combineLatest,
  type MonoTypeOperatorFunction,
  type Observable,
  type OperatorFunction,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IAppointmentFilterOptions } from './appointment-filter';
import { type AppointmentSuggestionEntity } from './appointment-suggestion';
import {
  addRulesConflicts,
  getSurroundingAppointments$,
} from './scheduling-rules';

export function compareScore(
  a: IAppointmentSuggestion,
  b: IAppointmentSuggestion
): number {
  return a.score < b.score ? 1 : -1;
}

export function filterSuggestions$(
  timeFilters$: Observable<IAppointmentFilterOptions>,
  closeTime$: Observable<moment.Moment>
): MonoTypeOperatorFunction<AppointmentSuggestionEntity[]> {
  return (suggestions$) =>
    combineLatest([suggestions$, timeFilters$, closeTime$]).pipe(
      map(([suggestions, timeFilters, closeTime]) => {
        if (!timeFilters.fromTime) {
          return suggestions;
        }
        return filterSuggestionsByTime(
          suggestions,
          moment(timeFilters.fromTime, TIME_FORMAT_24HR),
          timeFilters.toTime
            ? moment(timeFilters.toTime, TIME_FORMAT_24HR)
            : moment(closeTime, TIME_FORMAT)
        );
      })
    );
}

export function filterSuggestionsByTime(
  suggestions: AppointmentSuggestionEntity[],
  from: moment.Moment,
  to: moment.Moment
): AppointmentSuggestionEntity[] {
  const events: IEvent[] = suggestions.map((suggestion) => suggestion.event);
  return TimeBucket.fromEvents(events)
    .betweenTime(from, to)
    .get()
    .map((period) =>
      suggestions.filter((suggestion) =>
        toMoment(suggestion.event.from).isSame(period.from)
      )
    )
    .reduce(reduceToSingleArrayFn, []);
}

export function addSuggestionSchedulingConflicts$(
  treatment$: Observable<
    ITreatmentPlanWithBookableStep | ITreatmentTemplateWithStep
  >
): OperatorFunction<IAppointmentSuggestion[], IAppointmentSuggestion[]> {
  const surroundingAppointments$ = treatment$.pipe(
    switchMap((planPair) => getSurroundingAppointments$(planPair))
  );
  return (suggestions$: Observable<IAppointmentSuggestion[]>) =>
    combineLatest([surroundingAppointments$, suggestions$]).pipe(
      map(([surroundingAppointments, suggestions]) =>
        addRulesConflicts(suggestions, surroundingAppointments)
      )
    );
}

export const getPreferredEvent$: OperatorFunction<number, ITimestampRange> =
  map((durationInMins: number) => {
    const from: moment.Moment = moment();
    const to: moment.Moment = moment(from).add(durationInMins, 'minutes');
    return {
      from: toTimestamp(from),
      to: toTimestamp(to),
    };
  });
