import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  AssociatedPlanCreator,
  FeeSchedule,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IChartedMultiStepTreatment,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asyncForEach,
  filterUndefined,
  serialise,
  snapshot,
  unserialise$,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TreatmentPlanActions } from '../../actions';
import { ChartId } from '../../reducers/active-charts/chart-context-state';
import { type IChartState } from '../../reducers/reducers';
import {
  getSelectedTreatmentPlan,
  getTreatmentPlanById,
  getTreatmentPlans,
} from '../../selectors/treatment-plans/treatment-plans.selectors';
import {
  getTreatmentStepById,
  getTreatmentStepsByPlanId,
} from '../../selectors/treatment-plans/treatment-steps.selectors';
import { ChartFacade } from '../chart.facade';

@Injectable()
export class TreatmentPlanFacade {
  private _store = inject(Store<IChartState>);
  treatmentPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  selectedTreatmentPlan$: Observable<WithRef<ITreatmentPlan> | undefined>;

  constructor(
    private _chartState: ChartFacade,
    private _org: OrganisationService
  ) {
    this.treatmentPlans$ = this._store.pipe(
      select(getTreatmentPlans),
      unserialise$()
    );
    this.selectedTreatmentPlan$ = this._store.pipe(
      select(getSelectedTreatmentPlan),
      unserialise$()
    );
  }

  selectTreatmentPlan(ref: DocumentReference<ITreatmentPlan>): void {
    this._store.dispatch(
      TreatmentPlanActions.selectTreatmentPlan({ id: ref.id })
    );
  }

  loadTreatmentPlans(patient: WithRef<IPatient>): void {
    this._store.dispatch(
      TreatmentPlanActions.loadTreatmentPlans(
        serialise({
          patient,
        })
      )
    );
  }

  getTreatmentPlan$(
    treatmentPlanRef: DocumentReference<ITreatmentPlan>
  ): Observable<WithRef<ITreatmentPlan> | undefined> {
    return this._store.pipe(
      select(getTreatmentPlanById(treatmentPlanRef.id)),
      unserialise$()
    );
  }

  getTreatmentStep$(
    treatmentStepRef: DocumentReference<ITreatmentStep>
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    return this._store.pipe(
      select(getTreatmentStepById(treatmentStepRef.id)),
      unserialise$()
    );
  }

  getTreatmentSteps$(
    treatmentPlanRef: DocumentReference<ITreatmentPlan>
  ): Observable<WithRef<ITreatmentStep>[]> {
    return this._store.pipe(
      select(getTreatmentStepsByPlanId(treatmentPlanRef.id)),
      unserialise$()
    );
  }

  findPlanByStep$(
    stepRef: DocumentReference<ITreatmentStep>
  ): Observable<WithRef<ITreatmentPlan> | undefined> {
    return this.treatmentPlans$.pipe(
      map((treatmentPlans) =>
        treatmentPlans.find((plan) =>
          TreatmentPlan.treatmentStepByRef(plan, stepRef)
        )
      )
    );
  }

  async generateTreatmentPlan(
    patient: WithRef<IPatient>,
    practitioner?: INamedDocument<IStaffer>
  ): Promise<void> {
    const associatedPlanCreator = new AssociatedPlanCreator(patient);
    const currentPlan: WithRef<ITreatmentPlan> | undefined = await snapshot(
      this.selectedTreatmentPlan$
    );
    if (!currentPlan) {
      return;
    }

    let newPlan = await associatedPlanCreator.createChildPlan(currentPlan);
    if (!newPlan) {
      const org = await snapshot(
        this._org.organisation$.pipe(filterUndefined())
      );
      const feeSchedule = await FeeSchedule.getPreferredOrDefault(
        org,
        patient.preferredFeeSchedule
      );
      newPlan = await associatedPlanCreator.createNewPlan(
        practitioner,
        feeSchedule
      );
      this.selectTreatmentPlan(newPlan.ref);
    }

    const treatmentPlan = serialise(newPlan);
    this._store.dispatch(
      TreatmentPlanActions.addTreatmentPlan({ treatmentPlan })
    );
    this.selectTreatmentPlan(newPlan.ref);
  }

  updateTreatmentPlan(
    planRef: DocumentReference<ITreatmentPlan>,
    changes: Partial<ITreatmentPlan>
  ): void {
    this._store.dispatch(
      TreatmentPlanActions.updateTreatmentPlan(
        serialise({
          planRef,
          changes,
        })
      )
    );
  }

  async acceptTreatment(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    const treatmentPlan = await snapshot(this.selectedTreatmentPlan$);
    const planRef = treatmentPlan?.ref;
    if (!planRef) {
      return;
    }
    this._chartState.removeMultiTreatment(
      ChartId.InAppointment,
      multiTreatment.uuid
    );

    const newStepRefs = await asyncForEach(multiTreatment.steps, (step) =>
      addDoc(TreatmentPlan.treatmentStepCol({ ref: planRef }), step)
    );
    this.addSteps(planRef, newStepRefs);
  }

  addSteps(
    planRef: DocumentReference<ITreatmentPlan>,
    stepRefs: DocumentReference<ITreatmentStep>[],
    index?: number
  ): void {
    this._store.dispatch(
      TreatmentPlanActions.addTreatmentSteps(
        serialise({ planRef, stepRefs, index })
      )
    );
  }

  async addStep(
    plan: WithRef<ITreatmentPlan>
  ): Promise<DocumentReference<ITreatmentStep>> {
    const stepRef = await addDoc(
      TreatmentPlan.treatmentStepCol(plan),
      TreatmentStep.init()
    );
    this._store.dispatch(
      TreatmentPlanActions.addTreatmentSteps(
        serialise({ planRef: plan.ref, stepRefs: [stepRef] })
      )
    );
    return stepRef;
  }

  removeStep(
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): void {
    this._store.dispatch(
      TreatmentPlanActions.removeTreatmentStep(
        serialise({ planRef: plan.ref, ref: step.ref })
      )
    );
  }
}
