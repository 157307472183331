<div
  (click)="$event.stopPropagation()"
  class="flex items-center justify-between gap-2"
>
  <span>
    {{ label }}
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info</mat-icon>
  </span>
  <mat-button-toggle-group
    [formControl]="formCtrl"
    [disabled]="disabled$ | async"
  >
    @for (option of options$ | async; track $index) {
      <mat-button-toggle [value]="option | map: getValue">
        @if (isIconOption$ | async) {
          <mat-icon>{{ option | map: asString : this }}</mat-icon>
        } @else {
          {{ option | map: asString | splitCamel | titlecase }}
        }
      </mat-button-toggle>
    }
  </mat-button-toggle-group>
</div>
