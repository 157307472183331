import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum PracticePermissions {
  PracticeManage = 'practices.manage',
  PracticeConfigure = 'practices.configure',
  TaskManage = 'tasks.manage',
  SteriliationManage = 'sterilisation.manage',
  LabJobManage = 'lab-jobs.manage',
  ConversationManage = 'communication.manage',
  ChatManage = 'chat.manage',
}

export const LAB_JOBS_FEATURE: IFeature = {
  label: 'lab-jobs',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PracticePermissions.LabJobManage,
      description: `User can view, interact with, and manage lab jobs`,
    },
  ],
};

export const TASKS_FEATURE: IFeature = {
  label: 'tasks',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PracticePermissions.TaskManage,
      description: `User can view, interact with, and manage tasks`,
    },
  ],
};

export const STERILISATION_FEATURE: IFeature = {
  label: 'sterilisation',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PracticePermissions.SteriliationManage,
      description: `User can view and add sterilisaton records`,
    },
  ],
};

export const CONVERSATIONS_FEATURE: IFeature = {
  label: 'conversations',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PracticePermissions.ConversationManage,
      description: `User can view, reply to, and close conversations`,
    },
  ],
};

export const CHATS_FEATURE: IFeature = {
  label: 'chats',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PracticePermissions.ChatManage,
      description: `User can create, view, reply to, and archive chats`,
    },
  ],
};

export const PRACTICES_FEATURE: IFeature = {
  label: 'practices',
  visibility: FeatureVisibility.Beta,
  features: [
    LAB_JOBS_FEATURE,
    TASKS_FEATURE,
    STERILISATION_FEATURE,
    CONVERSATIONS_FEATURE,
    CHATS_FEATURE,
  ],
  permissions: [
    {
      value: PracticePermissions.PracticeManage,
      description: `User can add and remove practices`,
    },
    {
      value: PracticePermissions.PracticeConfigure,
      description: `User can modify ALL settings for the practice`,
    },
  ],
};
