import {
  IAppointmentScopeData,
  IAppointmentTemplateContext,
  ICandidateCalendarEvent,
  IPatientTemplateContext,
  IPracticeTemplateContext,
  ITemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

export interface IGapScopeData extends IAppointmentScopeData {
  gapCandidate: WithRef<ICandidateCalendarEvent>;
  gapOfferConfirmUrl: string;
}

export interface IGapTemplateContext
  extends ITemplateContext,
    IAppointmentTemplateContext,
    IPatientTemplateContext,
    IPracticeTemplateContext {
  gapOfferConfirmUrl: string;
  gapOfferStartDate: string;
}
