import {
  type AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  type OnInit,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { type IChartedItemConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  type IChartedItemDisplay,
  type IChartedItemIcon,
} from '@principle-theorem/principle-core/interfaces';
import {
  formControlChanges$,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  CHARTING_COLOURS,
  filterUndefined,
  type IDisplayColour,
} from '@principle-theorem/shared';
import { type Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CHARTING_ICONS } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-charting-options',
  templateUrl: './charting-options.component.html',
  styleUrls: ['./charting-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartingOptionsComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  trackByColour = TrackByFunctions.field<IDisplayColour>('name');
  trackByIcon = TrackByFunctions.field<IChartedItemIcon>('name');
  @Input() chartingConfiguration: IChartedItemConfiguration;
  @Output() selectedDisplayOptions = new EventEmitter<IChartedItemDisplay>();
  colours: IDisplayColour[] = CHARTING_COLOURS;
  icons: IChartedItemIcon[] = CHARTING_ICONS;
  filteredIcons$: Observable<IChartedItemIcon[]>;

  form: TypedFormGroup<IChartedItemDisplay> =
    new TypedFormGroup<IChartedItemDisplay>({
      colour: new TypedFormControl<IDisplayColour>(
        undefined,
        Validators.required
      ),
      icon: new TypedFormControl<IChartedItemIcon>(
        undefined,
        Validators.required
      ),
      hasFill: new TypedFormControl<boolean>(false, Validators.required),
      disablesSurface: new TypedFormControl<boolean>(
        false,
        Validators.required
      ),
      hideWhenResolved: new TypedFormControl<boolean>({
        value: false,
        disabled: false,
      }),
    });

  ngOnInit(): void {
    const iconsFilter = new InputSearchFilter<IChartedItemIcon>(
      of(this.icons),
      formControlChanges$(this.form.controls.icon).pipe(filterUndefined()),
      ['name']
    );
    this.filteredIcons$ = iconsFilter.results$;
    this._loadForm();
  }

  ngAfterContentInit(): void {
    this.form.controls.colour.statusChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        if (this.form.controls.colour.valid) {
          this.emitChanges();
        }
      });
    this.form.controls.icon.statusChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        if (this.form.controls.icon.valid) {
          this.emitChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  emitChanges(): void {
    const form: IChartedItemDisplay = this.form.getRawValue();
    this.selectedDisplayOptions.emit(form);
  }

  displayFn(item?: IDisplayColour | IChartedItemIcon): string {
    return item ? `${item.name}` : '';
  }

  private _loadForm(): void {
    if (this.chartingConfiguration.display) {
      this.form.patchValue(this.chartingConfiguration.display);
    }
    const formData = this.form.getRawValue();
    if (!formData.colour) {
      this.form.controls.colour.setValue(this.colours[0]);
    }
    if (!formData.icon) {
      this.form.controls.icon.setValue(this.icons[0]);
    }
    this.emitChanges();
  }
}
