import {
  AccountCreditType,
  IAccountCredit,
  IInvoice,
  IPractice,
  IStaffer,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  BaseFirestoreMock,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';

export class AccountCreditMock
  extends BaseFirestoreMock
  implements Properties<IAccountCredit>
{
  description = 'Mocked Account Credit from Deposit';
  amount = 100;
  type = AccountCreditType.Deposit;
  reference = 'ExternalReference:12345';
  invoice = MockDocRef<IInvoice>();
  depositUid = uuid();
  reservedFor = {
    practitioner: MockNamedDocument<IStaffer>(),
    treatment: [
      MockNamedDocument(), // TODO: Feels like these should ahve a type but idk what the base typing is
    ],
    treatmentCategory: MockDocRef<ITreatmentCategory>(),
  };
  used = 0;
  issued = MockTimestamp();
  practiceRef = MockDocRef<IPractice>();
  amendmentOf = MockDocRef<ArchivedDocument<IAccountCredit>>();
}
