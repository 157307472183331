import { getTaxRateByRegion } from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  FeeSchedule,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  IDestinationEntityJobRunOptions,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  asyncForEach,
  getDoc$,
  multiSwitchMap,
  snapshotCombineLatest,
  toMomentTz,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  ITreatmentPlanStepPair,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointment';
import { PraktikaItemCodeMappingHandler } from '../mappings/item-code';
import { PraktikaItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { getChartedTreatments } from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY =
  DestinationEntity.init({
    metadata: {
      key: 'patientTreatmentPlanProposals',
      label: 'Patient Treatment Plan Proposals',
      description: `This migrates the quote procedures for each patient.`,
    },
  });

export const PATIENT_TREATMENT_PLAN_PROPOSAL_CUSTOM_MAPPING_TYPE =
  'patientTreatmentPlanProposal';

export const PATIENT_TREATMENT_STEP_PROPOSAL_CUSTOM_MAPPING_TYPE =
  'patientTreatmentStepProposal';

interface IQuoteData {
  id: string;
  date: Timestamp;
  patientId: string;
  procedures: IGetRecordResponse<
    IPraktikaAppointmentProcedure,
    IPraktikaAppointmentProcedureTranslations,
    IPraktikaAppointmentProcedureFilters
  >[];
}

type JobData = IPatientTreatmentPlanJobData<
  IPraktikaPatient,
  IPraktikaPatientTranslations,
  IPraktikaPatientFilters
>;

export class PatientTreatmentPlanProposalDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  IPraktikaPatient,
  JobData
> {
  override destinationEntity =
    PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
    itemCodeToTreatment: new PraktikaItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<JobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = snapshotCombineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.getDoc(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        defaultTreatmentConfiguration$,
        treatmentConfigurations$,
        sourceItemCodes$,
        treatmentConfigurationMappings$,
        practitioners$,
        treatmentCategories$,
        taxRate$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            practitioners,
            treatmentCategories,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            practitioners,
            treatmentCategories,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      throw new Error(`Couldn't resolve patient`);
    }

    const practitionerRef = getPractitionerOrDefaultMapping(
      data.sourcePatient.data.data.patient_preferredproviderid?.toString(),
      data.staff
    )?.destinationIdentifier;

    const practitioner = practitionerRef
      ? await Firestore.getDoc(practitionerRef)
      : undefined;

    if (!practitioner) {
      throw new Error(`Couldn't resolve practitioner`);
    }

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const quotes: IQuoteData[] = [];

    procedures
      .filter((procedure) => procedure.record.filters.isPlanProposal)
      .map((procedure) => {
        const quoteId = procedure.data.data.quote_id?.toString();
        if (!quoteId) {
          return;
        }
        const quoteFound = quotes.find(
          (quote) => quote.id === quoteId.toString()
        );
        if (quoteFound) {
          if (
            quoteFound.date.seconds >
            procedure.data.translations.createdDate.seconds
          ) {
            quoteFound.date = procedure.data.translations.createdDate;
          }
          quoteFound.procedures.push(procedure);
          return;
        }
        quotes.push({
          id: quoteId,
          date: procedure.data.translations.createdDate,
          patientId: data.sourcePatient.data.data.patient_id.toString(),
          procedures: [procedure],
        });
      });

    const planPairs = await asyncForEach(compact(quotes), async (quote) => {
      return this._buildTreatmentPlanData(
        translationMap,
        data,
        quote,
        practitioner,
        migration
      );
    });

    return {
      sourcePatientId,
      patientRef,
      planPairs,
    };
  }

  private async _buildTreatmentPlanData(
    translationMap: TranslationMapHandler,
    data: JobData,
    quote: IQuoteData,
    practitioner: WithRef<IStaffer>,
    migration: WithRef<IPracticeMigration>
  ): Promise<ITreatmentPlanStepPair> {
    const createdAt = toMomentTz(quote.date, migration.configuration.timezone);
    const formattedDate = createdAt.format(HISTORY_DATE_FORMAT);
    const planName = `Quote ${quote.id} - ${formattedDate}`;
    const status = createdAt.isBefore(moment().subtract(6, 'months'))
      ? TreatmentPlanStatus.Declined
      : TreatmentPlanStatus.Offered;
    const plan = {
      ...TreatmentPlan.init({
        name: planName,
        status,
      }),
      sourceIdentifier: quote.id,
      createdAt: quote.date,
    };

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );
    const treatments = await getChartedTreatments(
      quote.procedures.map((procedure) => procedure.data.data),
      practitioner,
      resolveFeeSchedule(translationMap, defaultFeeSchedule),
      data,
      migration.configuration.timezone
    );

    const step = {
      ...TreatmentStep.init({
        name: 'Treatments',
        status: TreatmentStepStatus.Incomplete,
        treatments,
      }),
      sourceIdentifier: quote.id,
      createdAt: quote.date,
    };

    return {
      plan,
      steps: [step],
    };
  }
}
