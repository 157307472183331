<div class="flex flex-col gap-4 p-4">
  <h1 *ngIf="!editMode">Run this Report</h1>

  <pr-query-scope-form
    #queryScopeForm="prQueryScopeForm"
    [lockQueryableTimestamp]="queryableTimestamp$ | async"
    [dataSource]="dataSource$ | async"
    [dateRange]="dateRange$ | async"
    (formChange)="formData$.next($event)"
    (dateRangeChange)="dateRangeChange.emit($event)"
  />

  <div *ngIf="!editMode" class="flex gap-2">
    <button
      mat-flat-button
      color="primary"
      [disabled]="queryScopeForm.rootForm.invalid"
      (click)="submit()"
    >
      Run Report
    </button>
  </div>
</div>
