<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Daily Dashboard"
 />

<div class="layout-padding">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
  >
    <pr-date-pagination
      [isColoured]="true"
      [date]="date$ | async"
      (dateChange)="updateRange($event)"
     />
    <h1 fxFlex>{{ date$.value | moment | amDateFormat: dateFormat }}</h1>
  </div>

  <div class="big-dick-dynamic-chart">
    <pt-dynamic-component [definition]="testChart" />
    <pt-dynamic-component [definition]="patientFlow" />
  </div>

  <!-- <div
    class="layout-margin"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="30px"
  >
    <pr-single-value-chart
      fxFlex
      [chart]="totalRevenueChart"
      [measure]="totalRevenueMeasure"
    >
    </pr-single-value-chart>

    <pr-single-value-chart
      fxFlex
      [chart]="newPatientsChart"
      [measure]="newPatientsMeasure"
    >
    </pr-single-value-chart>

    <pr-single-value-chart
      fxFlex
      [chart]="totalNewAppointmentsChart"
      [measure]="totalNewAppointmentsMeasure"
    >
    </pr-single-value-chart>

    <pr-single-value-chart
      fxFlex
      [chart]="totalCancellationsChart"
      [measure]="totalCancellationsMeasure"
    >
    </pr-single-value-chart>

    <pr-single-value-chart
      fxFlex
      [chart]="totalReschedulesChart"
      [measure]="totalReschedulesMeasure"
    >
    </pr-single-value-chart>
  </div> -->

  <div class="layout-margin" fxLayout="row">
    <!-- <pr-dc-chart-display
      fxFlex
      [chart]="timeSpent$ | async"
    ></pr-dc-chart-display> -->
  </div>

  <div
    class="layout-margin"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="30px"
  >
    <!-- <pr-dc-chart-display
      fxFlex
      [chart]="patientDistribution$ | async"
    ></pr-dc-chart-display>
    <pr-dc-chart-display
      fxFlex
      [chart]="revenueDistribution$ | async"
    ></pr-dc-chart-display> -->
  </div>

  <div
    class="layout-margin"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="30px"
  >
    <!-- <div class="chart" fxFlex>
      <pr-basic-chart [chart]="patientFlowChart"></pr-basic-chart>
    </div> -->
    <!-- <pr-dc-chart-display
      fxFlex
      [chart]="cancellations$ | async"
    ></pr-dc-chart-display> -->
  </div>

  <!-- <div class="layout-margin" *ngIf="tableChart">
    <pr-table-chart
      fxFlex
      [chart]="tableChart"
      [replacementHeaders]="replacementHeaders"
    ></pr-table-chart>
  </div> -->
</div>
