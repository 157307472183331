@if (item$ | async; as item) {
  <mat-list-item>
    <div class="flex w-full items-center justify-between gap-4">
      <div class="h-full flex-1">
        {{ item.medicationName }}
      </div>

      <div class="flex items-center justify-end gap-4">
        <div>
          <button mat-icon-button (click)="edit()">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-list-item>
}
