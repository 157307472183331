<h3>
  {{ name }}
  <ng-container *ngIf="!disableTooltip">
    <mat-icon cdkOverlayOrigin #overlayTarget="cdkOverlayOrigin">info</mat-icon>

    <pt-custom-tooltip
      [target]="overlayTarget"
      [positions]="positions"
      [disabled]="disableTooltip"
    >
      <ng-template ptCustomTooltipContent>
        <ng-content />
      </ng-template>
    </pt-custom-tooltip>
  </ng-container>
</h3>
<div
  class="amount"
  *ngIf="!isLoading; else loader"
  [ngClass]="{ positive: amount$ | async | map: isPositive }"
>
  {{ amount$ | async | currency }}
</div>

<ng-template #loader>
  <mat-spinner [diameter]="36" />
</ng-template>
