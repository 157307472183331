import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IMedia,
  IPatient,
  IPracticeMigration,
  ISourceEntityRecord,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  asyncForEach,
  getError,
  toTimestamp,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, flatMap, last } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { CopyFile, StorageFile, getBucketStoragePath } from '../../../storage';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IExactPatient,
  IExactPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  IExactPatientExternalLink,
  IExactPatientExternalLinkFilters,
  PatientExternalLinksSourceEntity,
} from '../../source/entities/patient-external-links';
import {
  IPatientDocumentJobData,
  IPatientFileDestinationRecord,
} from './patient-documents';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

const imageAllowedTypes = ['jpeg', 'jpg', 'png'];
const xrayExtensions = ['dic', 'dcm'];
const allowedExtensions = [...imageAllowedTypes, ...xrayExtensions];

export const PATIENT_XRAYS_RESOURCE_TYPE = 'patientXrays';

export const PATIENT_XRAYS_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_XRAYS_RESOURCE_TYPE,
    label: 'Patient X-Rays',
    description: 'Patient X-Rays',
  },
});

export interface IPatientXrayMigrationdata {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  documents: IGetRecordResponse<
    IExactPatientExternalLink,
    object,
    IExactPatientExternalLinkFilters
  >[];
}

export class PatientXraysDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientDocumentJobData,
  IPatientXrayMigrationdata
> {
  destinationEntity = PATIENT_XRAYS_DESTINATION_ENTITY;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientXrays: new PatientExternalLinksSourceEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientDocumentJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IPatientDocumentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientDocumentJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientDocumentJobData[]> {
    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      map((sourcePatients) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          destinationEntity,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientDocumentJobData
  ): Promise<
    | IPatientXrayMigrationdata
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const documents = await this.sourceEntities.patientXrays.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    return {
      sourcePatientId,
      patientRef,
      documents,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    migrationData: IPatientXrayMigrationdata
  ): Promise<IDestinationEntityRecord> {
    try {
      const [fileRefs, failedFiles] = await this.addMediaToPatient(
        migration,
        translationMapHandler,
        migrationData
      );
      if (failedFiles.length) {
        this._buildErrorResponse(jobData.sourcePatient, failedFiles.join(', '));
      }
      return this._buildSuccessResponse(jobData.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  async addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    migrationData: IPatientXrayMigrationdata
  ): Promise<[DocumentReference<IMedia>[], string[]]> {
    const failedFiles: string[] = [];
    const fileRefs = await asyncForEach(
      migrationData.documents,
      async (document) => {
        const folderPath = `xrays/${document.data.data.external_link_identifier}`;
        const copyFile = new CopyFile(
          getBucketStoragePath(migration.source),
          `${folderPath}/`,
          migration.configuration.destinationBucket,
          Patient.storagePath({ ref: migrationData.patientRef }),
          migration.configuration.projectId
        );
        const sourceFiles = await copyFile.getFiles();

        const copyStorage = this.getCopyStorage(
          getBucketStoragePath(migration.source),
          migrationData.patientRef,
          migration.configuration.projectId,
          migration.configuration.destinationBucket
        );

        const filteredFiles = sourceFiles.filter((sourceFile) => {
          const extension = last(sourceFile.name.split('.')) ?? '';
          return allowedExtensions.includes(extension.toLowerCase());
        });

        return asyncForEach(filteredFiles, async (sourceFile) => {
          const destinationFilePath = await copyStorage.copy(sourceFile.name);

          if (!destinationFilePath) {
            failedFiles.push(sourceFile.name);
            return;
          }

          const documentDestinationRef = await translationMap.getDestination(
            sourceFile.name,
            PATIENT_XRAYS_RESOURCE_TYPE
          );

          const mediaRef = await FirestoreMigrate.upsertDoc(
            Patient.mediaCol({ ref: migrationData.patientRef }),
            {
              ...Media.init({
                name: sourceFile.name,
                path: destinationFilePath,
              }),
              createdAt: toTimestamp(),
            },
            documentDestinationRef?.id
          );

          if (!documentDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: sourceFile.name,
              destinationIdentifier: mediaRef,
              resourceType: PATIENT_XRAYS_RESOURCE_TYPE,
            });
          }

          return mediaRef;
        });
      }
    );

    return [compact(flatMap(fileRefs)), failedFiles];
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientXrayMigrationdata
  ): IPatientXrayMigrationdata | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    _migrationData: IPatientXrayMigrationdata
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  getCopyStorage(
    sourceBucket: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): StorageFile {
    return new StorageFile(
      sourceBucket,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
