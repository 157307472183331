import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const CONTACTS_RESOURCE_TYPE = 'contacts';

export const CONTACTS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Contact List',
    description: '',
    idPrefix: CONTACTS_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticeContact {
  id: number;
  name: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  mobile?: string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  notes?: string;
}

export function isCorePracticeContact(
  item: unknown
): item is ICorePracticeContact {
  return TypeGuard.interface<ICorePracticeContact>({
    id: isNumber,
    name: isString,
    firstName: TypeGuard.nilOr(isString),
    lastName: TypeGuard.nilOr(isString),
    email: TypeGuard.nilOr(isString),
    telephone: TypeGuard.nilOr(isString),
    mobile: TypeGuard.nilOr(isString),
    addressLine1: TypeGuard.nilOr(isString),
    addressLine2: TypeGuard.nilOr(isString),
    suburb: TypeGuard.nilOr(isString),
    postcode: TypeGuard.nilOr(isString),
    state: TypeGuard.nilOr(isString),
    country: TypeGuard.nilOr(isString),
    notes: TypeGuard.nilOr(isString),
  })(item);
}

export interface ICorePracticeContactTranslations {}

export interface ICorePracticeContactFilters {}

const CONTACTS_SOURCE_QUERY = `
SELECT
  ContactId AS id,
  Name AS name,
  FirstName AS first_name,
  LastName AS last_name,
  Email AS email,
  convert_to_text(Telephone) AS telephone,
  convert_to_text(Mobile) AS mobile,
  AddressLine1 AS address_line_1,
  AddressLine2 AS address_line_2,
  Suburb AS suburb,
  convert_to_text(Postcode) AS postcode,
  State AS state,
  Country AS country,
  Notes AS notes
FROM tblContact
`;

export class ContactSourceEntity extends BaseSourceEntity<
  ICorePracticeContact,
  ICorePracticeContactTranslations,
  ICorePracticeContactFilters
> {
  sourceEntity = CONTACTS_SOURCE_ENTITY;
  entityResourceType = CONTACTS_RESOURCE_TYPE;
  sourceQuery = CONTACTS_SOURCE_QUERY;
  verifySourceFn = isCorePracticeContact;

  translate(
    _data: ICorePracticeContact,
    _timezone: Timezone
  ): ICorePracticeContactTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeContact): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeContact): string {
    return `${data.id} - ${data.firstName} ${data.lastName}`;
  }

  getFilterData(
    _data: ICorePracticeContact,
    _timezone: Timezone
  ): ICorePracticeContactFilters {
    return {};
  }
}
