import {
  type IAbsoluteSchedulingRules,
  type IAppointmentSuggestion,
  type IEvent,
  isEventable,
  isTreatmentTemplateWithStep,
  type ITreatmentPlan,
  type ITreatmentPlanWithBookableStep,
  type ITreatmentStep,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment, type WithRef } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type ISurroundingAppointmentData,
  type ISurroundingAppointments,
} from './surrounding-appointments';
import { Appointment } from '../appointment/appointment';
import {
  dateIsWithinBounds,
  toAbsoluteSchedulingRules,
} from '../scheduling-rules';
import { EventMatcher } from '../event/event-matcher';
import { TreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';

export type SurroundingAppointments =
  | ISurroundingAppointmentData<IAbsoluteSchedulingRules>
  | undefined;

export function getSurroundingAppointments$(
  planPair:
    | ITreatmentPlanWithBookableStep
    | ITreatmentTemplateWithStep
    | undefined
): Observable<ISurroundingAppointments> {
  if (!planPair || isTreatmentTemplateWithStep(planPair)) {
    return of({});
  }
  return combineLatest([
    getPreviousStepRules$(planPair.plan, planPair.step),
    getNextStepRules$(planPair.plan, planPair.step),
  ]).pipe(map(([previous, next]) => ({ previous, next })));
}

export function getSchedulingAlerts$(
  planPair: ITreatmentPlanWithBookableStep | ITreatmentTemplateWithStep,
  fromDate: Timestamp
): Observable<string[]> {
  return getSurroundingAppointments$(planPair).pipe(
    map((surroundingDates) => {
      const alerts: string[] = [];
      const eventDate: Moment = toMoment(fromDate);
      let isViolated: boolean = violatesPreviousSchedulingRules(
        surroundingDates,
        eventDate
      );

      if (isViolated) {
        alerts.push(
          'This event time violates the preceding appointment scheduling rules'
        );
      }

      isViolated = violatesNextSchedulingRules(surroundingDates, eventDate);
      if (isViolated) {
        alerts.push(
          'This event time violates the next appointment scheduling rules'
        );
      }
      return alerts;
    })
  );
}

export function violatesPreviousSchedulingRules(
  surroundingDates: ISurroundingAppointments,
  eventDate: Moment
): boolean {
  if (!surroundingDates.previous) {
    return false;
  }
  return !dateIsWithinBounds(eventDate, surroundingDates.previous.rules);
}

export function violatesNextSchedulingRules(
  surroundingDates: ISurroundingAppointments,
  eventDate: Moment
): boolean {
  if (!surroundingDates.next) {
    return false;
  }

  const nextAbsoluteRules: IAbsoluteSchedulingRules = toAbsoluteSchedulingRules(
    surroundingDates.next.rules,
    eventDate
  );

  return !dateIsWithinBounds(
    surroundingDates.next.appointmentDate,
    nextAbsoluteRules
  );
}

export function addRulesConflicts(
  suggestions: IAppointmentSuggestion[],
  surroundingDates: ISurroundingAppointments
): IAppointmentSuggestion[] {
  return suggestions.map((suggestion) => {
    const eventDate: Moment = toMoment(suggestion.event.from);
    const violatesPrevious: boolean = violatesPreviousSchedulingRules(
      surroundingDates,
      eventDate
    );
    const violatesNext: boolean = violatesNextSchedulingRules(
      surroundingDates,
      eventDate
    );

    if (!violatesPrevious && !violatesNext) {
      return suggestion;
    }

    suggestion.score = 0;

    if (violatesPrevious) {
      suggestion.schedulingConflicts.push({
        reason:
          'This event time violates the preceding appointment scheduling rules',
        blocking: false,
      });
    }

    if (violatesNext) {
      suggestion.schedulingConflicts.push({
        reason:
          'This event time violates the next appointment scheduling rules',
        blocking: false,
      });
    }
    return suggestion;
  });
}

export function findExistingEventInSuggestions(
  event: IEvent,
  suggestions: IAppointmentSuggestion[]
): IAppointmentSuggestion | undefined {
  return suggestions.find((suggestion) =>
    new EventMatcher({ event: suggestion.event }, { event }).isSame()
  );
}

export function getPreviousStepRules$(
  plan: WithRef<ITreatmentPlan>,
  step: WithRef<ITreatmentStep>
): Observable<SurroundingAppointments> {
  return TreatmentPlan.getPreviousStep$(plan, step).pipe(
    switchMap((previousStep) => {
      if (!previousStep) {
        return of(undefined);
      }
      return combineLatest([
        TreatmentPlan.getStepAbsoluteSchedulingRules$(plan, step),
        TreatmentStep.appointment$(previousStep),
      ]).pipe(
        map(([rules, previousAppointment]) => {
          if (
            previousAppointment &&
            Appointment.isScheduled(previousAppointment) &&
            rules &&
            isEventable(previousAppointment)
          ) {
            return {
              appointmentDate: toMoment(previousAppointment.event.to),
              rules,
            };
          }
        })
      );
    })
  );
}

export function getNextStepRules$(
  plan: WithRef<ITreatmentPlan>,
  step: WithRef<ITreatmentStep>
): Observable<SurroundingAppointments | undefined> {
  return TreatmentPlan.getNextStep$(plan, step).pipe(
    switchMap((nextStep) => {
      if (!nextStep) {
        return of(undefined);
      }
      return TreatmentStep.appointment$(nextStep).pipe(
        map((nextAppointment) => {
          if (
            nextAppointment &&
            Appointment.isScheduled(nextAppointment) &&
            isEventable(nextAppointment)
          ) {
            return {
              appointmentDate: toMoment(nextAppointment.event.from),
              rules: nextStep.schedulingRules,
            };
          }
        })
      );
    })
  );
}
