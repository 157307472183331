import { Injectable } from '@angular/core';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  CurrentScopeFacade,
  UpdateHealthCardsService,
} from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { TyroService } from '@principle-theorem/ng-tyro';
import {
  Invoice,
  OrganisationCache,
  Practice,
} from '@principle-theorem/principle-core';
import {
  TransactionAction,
  TransactionProvider,
  type IHealthPointTransactionExtendedData,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Region,
  WithRef,
  guardFilter,
  snapshot,
} from '@principle-theorem/shared';
import {
  isHealthPointTransactionCompleteCallbackData,
  isHealthPointTransactionErrorCallback,
  isHealthPointTransactionSuccessBaseCallbackData,
  isNotStarted,
  isTyroResponse,
} from '@principle-theorem/tyro';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../../transaction-provider';
import { TYRO_ERROR_DIALOG_DATA } from '../base-transaction';
import { SelectTyroTerminalService } from '../select-tyro-terminal/select-tyro-terminal.service';
import { HealthPointBuilder } from './health-point-builder.service';
import {
  toHealthPointCancelTransaction,
  toHealthPointTransaction,
} from './health-point-transaction';

@Injectable()
export class TyroHealthPointTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.TyroHealthPoint;
  providerType = TransactionProviderType.HealthFund;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _tyro: TyroService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _healthPointBuilder: HealthPointBuilder,
    private _selectTerminal: SelectTyroTerminalService,
    private _basicDialog: BasicDialogService,
    private _accountFunctions: AccountingFunctionsService,
    private _updateHealthCards: UpdateHealthCardsService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map((practice) => Practice.isTyroEnabled(practice))
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canClaim = Invoice.canAddTransactions(invoice);
    return of(canClaim);
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData =
      await this._healthPointBuilder.openHealthPointForm(invoice);
    if (!formData) {
      return;
    }
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = await this._healthPointBuilder.buildHealthPointClaim(
      invoice,
      claim,
      formData,
      terminal.terminalData
    );
    const response = await snapshot(
      this._tyro
        .initiateHealthPointClaim$(request)
        .pipe(guardFilter(isTyroResponse))
    );
    if (isNotStarted(response) || !response) {
      return;
    }
    if (isHealthPointTransactionErrorCallback(response)) {
      await this._basicDialog.alert({
        ...TYRO_ERROR_DIALOG_DATA,
        title: 'Notice',
        prompt: [
          `Error Code: ${response.healthpointErrorCode}`,
          `Error Description: ${response.healthpointErrorDescription}`,
          `Ref Tag: ${response.healthpointRefTag}`,
        ],
      });
      return;
    }
    if (!isHealthPointTransactionCompleteCallbackData(response)) {
      // eslint-disable-next-line no-console
      console.error('Invaid data for healthpoint response', response);
      await this._basicDialog.alert(TYRO_ERROR_DIALOG_DATA);
      return;
    }
    const transaction = await toHealthPointTransaction(
      invoice,
      terminal.practiceRef,
      claim,
      request,
      response
    );

    const result = await this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add,
      claim
    );

    if (!result) {
      return;
    }
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    await this._updateHealthCards.open(
      this._updateHealthCards.factory(patient).fromTransaction(transaction)
    );

    return result;
  }

  async cancel(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction<IHealthPointTransactionExtendedData>>,
    explicitClaim?: IHealthcareClaim
  ): Promise<void> {
    const claim =
      explicitClaim ??
      Invoice.findHealthcareClaimForTransaction(invoice, transaction);
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = this._healthPointBuilder.buildHealthPointClaimCancel(
      transaction,
      terminal.terminalData
    );
    if (!request) {
      return;
    }
    const response = await snapshot(
      this._tyro
        .cancelHealthPointClaim$(request)
        .pipe(guardFilter(isHealthPointTransactionSuccessBaseCallbackData))
    );
    if (!response) {
      return;
    }

    const cancelledTransaction = await toHealthPointCancelTransaction(
      invoice,
      terminal.practiceRef,
      claim,
      request,
      response,
      transaction
    );
    await this._accountFunctions.addTransactionToInvoice(
      invoice,
      cancelledTransaction,
      TransactionAction.Cancel,
      claim
    );
  }
}
