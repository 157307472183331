import { type WithRef } from '@principle-theorem/shared';
import { type IChartedItemConfiguration } from './charted-item-configuration';
import { type IConditionConfiguration } from './conditions/condition-configuration';
import { type IMultiTreatmentConfiguration } from './treatment/multi-treatment-configuration';
import { type ITreatmentConfiguration } from './treatment/treatment-configuration';
import { type ITreatmentPlan } from './treatment/treatment-plan';
import { type ITreatmentStep } from './treatment/treatment-step';
import { type IChartedItem } from './core/charted-item';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
} from './treatment/charted-multi-step-treatment';

export type AnyChartedItemConfiguration =
  | WithRef<IChartedItemConfiguration>
  | WithRef<IConditionConfiguration>
  | WithRef<ITreatmentConfiguration>
  | WithRef<IMultiTreatmentConfiguration>;

export interface IChartedItemSelectionSummary<
  T = IChartedItem<AnyChartedItemConfiguration>
> {
  item: T;
  plan?: WithRef<ITreatmentPlan> | IChartedMultiStepTreatment;
  step?: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep;
}
