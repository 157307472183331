import { type IFirestoreModel } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type ITreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { type IStatusHistory } from '../common';
import { type IInvoice } from '../invoice/invoice';

export enum PaymentPlanAction {
  Draft = 'draft',
  Active = 'issued',
  Cancelled = 'cancelled',
  Complete = 'paid',
}

export enum PaymentPlanStatus {
  Draft = 'draft',
  Active = 'issued',
  Cancelled = 'cancelled',
  Complete = 'paid',
}

export enum PaymentFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
}

export interface IPaymentSchedule {
  amount: number;
  date: Timestamp;
}

export interface IPaymentPlan extends IFirestoreModel {
  name: string;
  durationLength: number;
  durationType: string;
  invoices: DocumentReference<IInvoice>[];
  treatmentPlan?: DocumentReference<ITreatmentPlan>;
  status: PaymentPlanStatus;
  statusHistory: IStatusHistory<PaymentPlanStatus>[];
  paymentFrequency: PaymentFrequency;
  paymentSchedule: IPaymentSchedule[];
  total: number;
  completedAt?: Timestamp;
}

export enum PaymentPlanCollection {
  Transactions = 'transactions',
  Payments = 'payments',
}
