<mat-toolbar color="accent">Download Report Settings</mat-toolbar>

<form [formGroup]="form">
  <mat-dialog-content>
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>File Name</mat-label>
        <input matInput formControlName="fileName" />
      </mat-form-field>

      <mat-checkbox formControlName="includeHeaders">
        Include Headers
      </mat-checkbox>

      <mat-checkbox formControlName="includeTotals">
        Include Totals
      </mat-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container>
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="dialogRef.close(form.value)"
      >
        Download CSV
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
