import {
  type IFeeSchedule,
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { sortBy } from 'lodash';
import {
  type INamedDocument,
  type IReffable,
  type WithRef,
  multiMap,
  snapshot,
  reduce2DArray,
  multiSortBy$,
  nameSorter,
  toNamedDocument,
  asDocRef,
  XSLXImporterExporter,
  asyncForEach,
  Firestore,
} from '@principle-theorem/shared';
import { combineLatest } from 'rxjs';
import {
  FeeSchedule,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import { FeeSchedulesToXSLX } from './fee-schedule-to-xlsx';
import { XSLXToFeeSchedule } from './xlsx-to-fee-schedule';
import { BaseSourceEntity } from '../source/base-source-entity';
import { CustomMapping } from '../custom-mapping';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { TranslationMapHandler } from '../translation-map';
import { PracticeMigration } from '../practice-migrations';

export interface IBaseMigrationFeeSchedule {
  name: string;
}

export const FEE_SCHEDULE_RESOURCE_TYPE = 'feeSchedule';

export const FEE_SCHEDULES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Fee Schedules',
    description:
      'Even though we have a migration for fee schedules, we can override the mapping in cases where fee schedules have already been created in Principle.',
    type: FEE_SCHEDULE_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export abstract class FeeScheduleMappingHandler<
  FeeSchedule extends object,
  SourceEntity extends BaseSourceEntity<FeeSchedule>,
> extends BaseCustomMappingHandler<IFeeSchedule> {
  customMapping = FEE_SCHEDULES_MAPPING;
  abstract sourceEntity: SourceEntity;
  abstract translateFn: (record: FeeSchedule) => IBaseMigrationFeeSchedule;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const feeSchedule = this.translateFn(record);
        return {
          label: `${feeSchedule.name}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IFeeSchedule>[]> {
    return snapshot(
      combineLatest([
        FeeSchedule.all$(migration.configuration.organisation).pipe(
          multiMap((feeSchedule) => ({
            ...feeSchedule,
            name: `Workspace - ${feeSchedule.name}`,
          })),
          toINamedDocuments()
        ),
        ...migration.configuration.practices.map((practice) =>
          FeeSchedule.all$(practice).pipe(
            multiMap((feeSchedule) => ({
              ...feeSchedule,
              name: `${practice.name} - ${feeSchedule.name}`,
            })),
            toINamedDocuments()
          )
        ),
      ]).pipe(reduce2DArray(), multiSortBy$(nameSorter()))
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `fee-schedules`;
    const feeSchedules = await this._getSourceSchedules(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      feeSchedules,
      new FeeSchedulesToXSLX(
        await this.getDestinationOptions(migration),
        await snapshot(this.getRecords$(translationMap))
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToFeeSchedule()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const sourceOptions = await this.getSourceOptions(migration);
    const feeSchedules = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (option) => option.label === item.label
      );
      if (!matchingOption) {
        return;
      }

      const feeSchedule = feeSchedules.find(
        (schedule) => schedule.name === item.mapTo
      );
      if (!feeSchedule) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: feeSchedule.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: matchingOption.label,
          sourceLabel: matchingOption.label,
        },
        translationMap
      );
    });
  }

  private async _getSourceSchedules(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBaseMigrationFeeSchedule[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records.map((record) => this.translateFn(record.data.data)),
      'name'
    );
  }
}

export type FeeScheduleResolverFn = (
  id?: string
) => Promise<INamedDocument<IFeeSchedule>>;

export function resolveFeeSchedule(
  translationMap: TranslationMapHandler,
  defaultFeeSchedule: INamedDocument<IFeeSchedule>
): FeeScheduleResolverFn {
  return async (id?: string) => {
    if (!id) {
      return defaultFeeSchedule;
    }
    const resolved = await translationMap.getBySource(
      id,
      FEE_SCHEDULE_RESOURCE_TYPE
    );
    if (!resolved || !resolved.destinationIdentifier) {
      return defaultFeeSchedule;
    }
    return toNamedDocument(
      await Firestore.getDoc(
        asDocRef<IFeeSchedule>(resolved.destinationIdentifier)
      )
    );
  };
}
