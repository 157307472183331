<mat-toolbar color="accent">
  <ng-container *ngIf="update; else add">Update</ng-container>
  <ng-template #add>Add</ng-template>
  Product
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>

    <mat-form-field fxFlex>
      <span matTextPrefix>$ &nbsp;</span>
      <mat-label>Price</mat-label>
      <input
        type="number"
        matInput
        [required]="true"
        min="0"
        formControlName="cost"
      />
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Tax Status</mat-label>
      <mat-select formControlName="taxStatus">
        <mat-option
          *ngFor="
            let taxStatusOption of taxStatusOptions;
            trackBy: trackByTaxStatus
          "
          [value]="taxStatusOption"
        >
          {{ taxStatusOption | splitCamel | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
