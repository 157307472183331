import { createAction, props } from '@ngrx/store';
import {
  type IConditionConfiguration,
  type IMultiTreatmentConfiguration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IBrand,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface ILoadChartableConfiguration {
  brand: WithRef<IBrand>;
  staffer: WithRef<IStaffer>;
}

export const loadConditionConfigurations = createAction(
  ChartActionTypes.LoadConditionConfigurations,
  props<SerialisedData<ILoadChartableConfiguration>>()
);

export const loadTreatmentConfigurations = createAction(
  ChartActionTypes.LoadTreatmentConfigurations,
  props<SerialisedData<ILoadChartableConfiguration>>()
);

export const loadMultiTreatmentConfigurations = createAction(
  ChartActionTypes.LoadMultiTreatmentConfigurations,
  props<SerialisedData<ILoadChartableConfiguration>>()
);

export interface ILoadConditionConfigurationsSuccess {
  configs: WithRef<IConditionConfiguration>[];
}

export const loadConditionConfigurationsSuccess = createAction(
  ChartActionTypes.LoadConditionConfigurationsSuccess,
  props<SerialisedData<ILoadConditionConfigurationsSuccess>>()
);

export interface ILoadTreatmentConfigurationsSuccess {
  configs: WithRef<ITreatmentConfiguration>[];
}

export const loadTreatmentConfigurationsSuccess = createAction(
  ChartActionTypes.LoadTreatmentConfigurationsSuccess,
  props<SerialisedData<ILoadTreatmentConfigurationsSuccess>>()
);

export interface ILoadMultiTreatmentConfigurationsSuccess {
  configs: WithRef<IMultiTreatmentConfiguration>[];
}

export const loadMultiTreatmentConfigurationsSuccess = createAction(
  ChartActionTypes.LoadMultiTreatmentConfigurationsSuccess,
  props<SerialisedData<ILoadMultiTreatmentConfigurationsSuccess>>()
);

export interface IAddConditionConfiguration {
  entity: WithRef<IStaffer> | WithRef<IBrand>;
  config: IConditionConfiguration;
}

export const addConditionConfiguration = createAction(
  ChartActionTypes.AddConditionConfiguration,
  props<SerialisedData<IAddConditionConfiguration>>()
);

export interface IAddAndChartConditionConfiguration {
  id: ChartId;
  config: IConditionConfiguration;
}

export const addAndChartConditionConfiguration = createAction(
  ChartActionTypes.AddAndChartConditionConfiguration,
  props<SerialisedData<IAddAndChartConditionConfiguration>>()
);

export interface IAddTreatmentConfiguration {
  entity: WithRef<IStaffer> | WithRef<IBrand>;
  config: ITreatmentConfiguration;
}

export const addTreatmentConfiguration = createAction(
  ChartActionTypes.AddTreatmentConfiguration,
  props<SerialisedData<IAddTreatmentConfiguration>>()
);

export interface IAddAndChartTreatmentConfiguration {
  id: ChartId;
  config: ITreatmentConfiguration;
}

export const addAndChartTreatmentConfiguration = createAction(
  ChartActionTypes.AddAndChartTreatmentConfiguration,
  props<SerialisedData<IAddAndChartTreatmentConfiguration>>()
);

export interface IAddMultiTreatmentConfiguration {
  entity: WithRef<IStaffer> | WithRef<IBrand>;
  config: IConditionConfiguration;
}

export const addMultiTreatmentConfiguration = createAction(
  ChartActionTypes.AddMultiTreatmentConfiguration,
  props<SerialisedData<IAddMultiTreatmentConfiguration>>()
);
