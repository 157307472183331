import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IEventable } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-eventable-card',
    templateUrl: './eventable-card.component.html',
    styleUrls: ['./eventable-card.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventableCardComponent {
  eventable$ = new ReplaySubject<IEventable>(1);

  @Input()
  set eventable(eventable: IEventable) {
    if (eventable) {
      this.eventable$.next(eventable);
    }
  }
}
