import {
  SourceEntityMigrationType,
  type ISourceEntity,
  ChartableSurface,
  ToothNumber,
  IPerioData,
  IPerioRecord,
  PerioDataPoint,
  PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  type Timezone,
  ISODateType,
  isISODateType,
  toInt,
  isObject,
} from '@principle-theorem/shared';
import {
  compact,
  flow,
  isFinite,
  isNumber,
  isString,
  upperFirst,
} from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { buildToothRef } from '../../../exact/util/tooth';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
} from '../../../source/source-helpers';

export const PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE =
  'patientPeriodontalCharts';

export const PATIENT_PERIODONTAL_CHART_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Periodontal Charts',
      description: '',
      idPrefix: PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisPatientPeriodontalChart {
  patientId: number;
  chartNumber: number;
  date: ISODateType;
  comments?: string;
  teeth: IOasisPerioProbeResult[];
}

export interface IOasisPatientPeriodontalChartResult
  extends Omit<IOasisPatientPeriodontalChart, 'teeth'> {
  tooth01?: string;
  tooth02?: string;
  tooth03?: string;
  tooth04?: string;
  tooth05?: string;
  tooth06?: string;
  tooth07?: string;
  tooth08?: string;
  tooth09?: string;
  tooth10?: string;
  tooth11?: string;
  tooth12?: string;
  tooth13?: string;
  tooth14?: string;
  tooth15?: string;
  tooth16?: string;
  tooth17?: string;
  tooth18?: string;
  tooth19?: string;
  tooth20?: string;
  tooth21?: string;
  tooth22?: string;
  tooth23?: string;
  tooth24?: string;
  tooth25?: string;
  tooth26?: string;
  tooth27?: string;
  tooth28?: string;
  tooth29?: string;
  tooth30?: string;
  tooth31?: string;
  tooth32?: string;
  tooth33?: string;
  tooth34?: string;
  tooth35?: string;
  tooth36?: string;
  tooth37?: string;
  tooth38?: string;
  tooth39?: string;
  tooth40?: string;
  tooth41?: string;
  tooth42?: string;
  tooth43?: string;
  tooth44?: string;
  tooth45?: string;
  tooth46?: string;
  tooth47?: string;
  tooth48?: string;
  tooth49?: string;
  tooth50?: string;
  tooth51?: string;
  tooth52?: string;
  tooth53?: string;
  tooth54?: string;
  tooth55?: string;
  tooth56?: string;
  tooth57?: string;
  tooth58?: string;
  tooth59?: string;
  tooth60?: string;
  tooth61?: string;
  tooth62?: string;
  tooth63?: string;
  tooth64?: string;
}

export function isOasisPatientPeriodontalChart(
  item: unknown
): item is IOasisPatientPeriodontalChart {
  return TypeGuard.interface<IOasisPatientPeriodontalChart>({
    patientId: isNumber,
    chartNumber: isNumber,
    date: isISODateType,
    comments: TypeGuard.nilOr(isString),
    teeth: TypeGuard.arrayOf(isObject),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisPatientPeriodontalChartTranslations {}

export interface IOasisPatientPeriodontalChartFilters {
  patientId: number;
}

const PATIENT_PERIODONTAL_CHART_SOURCE_QUERY = `
SELECT
	convert_to_integer(REGEXP_REPLACE(SKEY,'([0-9]{6})(.*)','\\1')) AS patient_id,
	convert_to_integer(REGEXP_REPLACE(SKEY,'[0-9]{6}.{1}([0-9]{3})(.*)','\\1')) AS chart_number,
  CASE
    WHEN strpos(DATE, '/') > 0
      THEN to_char(
        convert_to_date(DATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE DATE
  END AS date,
  NULLIF(COMMENTS, '') AS comments,
  TOOTH01 AS tooth_01,
  TOOTH02 AS tooth_02,
  TOOTH03 AS tooth_03,
  TOOTH04 AS tooth_04,
  TOOTH05 AS tooth_05,
  TOOTH06 AS tooth_06,
  TOOTH07 AS tooth_07,
  TOOTH08 AS tooth_08,
  TOOTH09 AS tooth_09,
  TOOTH10 AS tooth_10,
  TOOTH11 AS tooth_11,
  TOOTH12 AS tooth_12,
  TOOTH13 AS tooth_13,
  TOOTH14 AS tooth_14,
  TOOTH15 AS tooth_15,
  TOOTH16 AS tooth_16,
  TOOTH17 AS tooth_17,
  TOOTH18 AS tooth_18,
  TOOTH19 AS tooth_19,
  TOOTH20 AS tooth_20,
  TOOTH21 AS tooth_21,
  TOOTH22 AS tooth_22,
  TOOTH23 AS tooth_23,
  TOOTH24 AS tooth_24,
  TOOTH25 AS tooth_25,
  TOOTH26 AS tooth_26,
  TOOTH27 AS tooth_27,
  TOOTH28 AS tooth_28,
  TOOTH29 AS tooth_29,
  TOOTH30 AS tooth_30,
  TOOTH31 AS tooth_31,
  TOOTH32 AS tooth_32,
  TOOTH33 AS tooth_33,
  TOOTH34 AS tooth_34,
  TOOTH35 AS tooth_35,
  TOOTH36 AS tooth_36,
  TOOTH37 AS tooth_37,
  TOOTH38 AS tooth_38,
  TOOTH39 AS tooth_39,
  TOOTH40 AS tooth_40,
  TOOTH41 AS tooth_41,
  TOOTH42 AS tooth_42,
  TOOTH43 AS tooth_43,
  TOOTH44 AS tooth_44,
  TOOTH45 AS tooth_45,
  TOOTH46 AS tooth_46,
  TOOTH47 AS tooth_47,
  TOOTH48 AS tooth_48,
  TOOTH49 AS tooth_49,
  TOOTH50 AS tooth_50,
  TOOTH51 AS tooth_51,
  TOOTH52 AS tooth_52,
  TOOTH53 AS tooth_53,
  TOOTH54 AS tooth_54,
  TOOTH55 AS tooth_55,
  TOOTH56 AS tooth_56,
  TOOTH57 AS tooth_57,
  TOOTH58 AS tooth_58,
  TOOTH59 AS tooth_59,
  TOOTH60 AS tooth_60,
  TOOTH61 AS tooth_61,
  TOOTH62 AS tooth_62,
  TOOTH63 AS tooth_63,
  TOOTH64 AS tooth_64
FROM
	PTCHARTS
WHERE
	SKEY LIKE '%P%'
`;

export class PatientPeriodontalChartSourceEntity extends BaseSourceEntity<
  IOasisPatientPeriodontalChart,
  IOasisPatientPeriodontalChartTranslations,
  IOasisPatientPeriodontalChartFilters
> {
  sourceEntity = PATIENT_PERIODONTAL_CHART_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIODONTAL_CHART_SOURCE_QUERY;
  verifySourceFn = isOasisPatientPeriodontalChart;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    transformPeriodDataResults,
  ]);

  translate(
    _data: IOasisPatientPeriodontalChart,
    _timezone: Timezone
  ): IOasisPatientPeriodontalChartTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisPatientPeriodontalChart): string {
    return `${data.patientId}-${data.chartNumber}`;
  }

  getSourceLabel(data: IOasisPatientPeriodontalChart): string {
    return `${data.patientId}-${data.chartNumber}`;
  }

  getFilterData(
    data: IOasisPatientPeriodontalChart,
    _timezone: Timezone
  ): IOasisPatientPeriodontalChartFilters {
    return {
      patientId: data.patientId,
    };
  }
}

export function transformPeriodDataResults(
  data: IOasisPatientPeriodontalChartResult[]
): IOasisPatientPeriodontalChart[] {
  return data.map((chart) => {
    const teeth = compact(
      Object.entries(chart).map(([key, value]) => {
        if (key.startsWith('tooth')) {
          return transformPerioString(
            value as string,
            oasisPerioToothLabelConverter(key)
          );
        }
      })
    );
    return {
      date: chart.date,
      patientId: chart.patientId,
      chartNumber: chart.chartNumber,
      comments: chart.comments,
      teeth,
    };
  });
}

export interface IOasisPerioProbeResult extends IPerioRecord {
  isMissing: boolean;
}

export function transformPerioString(
  perioString: string,
  toothNumber: ToothNumber
): IOasisPerioProbeResult | undefined {
  const toothRef = buildToothRef(toothNumber);
  if (!toothRef) {
    return;
  }

  const perioStringLength = perioString.length;
  if (perioStringLength <= 1) {
    return {
      toothRef,
      isMissing: perioString === '1',
      data: {},
    };
  }

  if (perioStringLength === 100) {
    perioString = `  ${perioString}`;
  }
  if (perioStringLength === 98) {
    perioString = `    ${perioString}`;
  }
  if (perioStringLength === 96) {
    perioString = `      ${perioString}`;
  }
  if (perioString.length !== 102) {
    // eslint-disable-next-line no-console
    console.error(`Perio string length: ${perioStringLength}`, perioString);
    return;
  }

  const perioArray = perioString.split('');
  const perioData: Partial<IPerioData> = {};

  const missingCheck = perioArray.splice(-12);
  const isMissing = missingCheck[missingCheck.length - 1] === '1';

  let index = 0;
  while (perioArray.length > 0) {
    const slice = perioArray.slice(0, 6);

    const surface = oasisPerioDataArrayIndexToToothSide(index);
    const positionSequence = toothNumberToPerioPositionSequence(toothNumber);
    const perioMeasurement =
      oasisPerioDataArrayPositionToPerioMeasurement(index);

    if (!perioMeasurement) {
      perioArray.splice(0, 6);
      index++;
      continue;
    }

    if (perioMeasurement === PerioMeasurement.Mobility) {
      const stringValue = perioArray.splice(0, 6).join('').trim();
      const mobility = toInt(stringValue === '*' ? '1' : stringValue);
      if (isFinite(mobility)) {
        perioData[perioMeasurement] = mobility;
      }
      index++;
      continue;
    }

    positionSequence.map((position) => {
      const perioDataPoint = surfaceToPerioDataPoint(surface, position);
      const stringValue = `${slice.shift()}${slice.shift()}`.trim();
      const value = toInt(stringValue === '*' ? '1' : stringValue);
      if (!isFinite(value)) {
        return;
      }
      const measurement = perioData[perioMeasurement] || {};
      measurement[perioDataPoint] = value;
      perioData[perioMeasurement] = measurement;
    });

    perioArray.splice(0, 6);
    index++;
  }

  return {
    toothRef,
    isMissing,
    data: perioData,
  };
}

type OasisPerioToothLabel =
  | 'tooth01'
  | 'tooth02'
  | 'tooth03'
  | 'tooth04'
  | 'tooth05'
  | 'tooth06'
  | 'tooth07'
  | 'tooth08'
  | 'tooth09'
  | 'tooth10'
  | 'tooth11'
  | 'tooth12'
  | 'tooth13'
  | 'tooth14'
  | 'tooth15'
  | 'tooth16'
  | 'tooth17'
  | 'tooth18'
  | 'tooth19'
  | 'tooth20'
  | 'tooth21'
  | 'tooth22'
  | 'tooth23'
  | 'tooth24'
  | 'tooth25'
  | 'tooth26'
  | 'tooth27'
  | 'tooth28'
  | 'tooth29'
  | 'tooth30'
  | 'tooth31'
  | 'tooth32';

function oasisPerioToothLabelConverter(tooth: string): ToothNumber {
  const conversions: Record<OasisPerioToothLabel, ToothNumber> = {
    tooth01: '18',
    tooth02: '17',
    tooth03: '16',
    tooth04: '15',
    tooth05: '14',
    tooth06: '13',
    tooth07: '12',
    tooth08: '11',
    tooth09: '21',
    tooth10: '22',
    tooth11: '23',
    tooth12: '24',
    tooth13: '25',
    tooth14: '26',
    tooth15: '27',
    tooth16: '28',
    tooth17: '38',
    tooth18: '37',
    tooth19: '36',
    tooth20: '35',
    tooth21: '34',
    tooth22: '33',
    tooth23: '32',
    tooth24: '31',
    tooth25: '41',
    tooth26: '42',
    tooth27: '43',
    tooth28: '44',
    tooth29: '45',
    tooth30: '46',
    tooth31: '47',
    tooth32: '48',
  };

  try {
    return conversions[tooth as OasisPerioToothLabel];
  } catch (error) {
    throw new Error(`Invalid Oasis tooth label: ${tooth}`);
  }
}

function oasisPerioDataArrayIndexToToothSide(
  index: number
): ChartableSurface.Facial | ChartableSurface.Lingual {
  return index <= 6 ? ChartableSurface.Facial : ChartableSurface.Lingual;
}

enum PerioPosition {
  Central = 'central',
  Mesial = 'mesial',
  Distal = 'distal',
}

function toothNumberToPerioPositionSequence(
  tooth: ToothNumber
): PerioPosition[] {
  const toothNumber = toInt(tooth);
  if (
    (toothNumber >= 11 && toothNumber <= 18) ||
    (toothNumber >= 41 && toothNumber <= 48)
  ) {
    return [PerioPosition.Distal, PerioPosition.Central, PerioPosition.Mesial];
  }
  return [PerioPosition.Mesial, PerioPosition.Central, PerioPosition.Distal];
}

function surfaceToPerioDataPoint(
  surface: ChartableSurface,
  position: 'mesial' | 'central' | 'distal'
): PerioDataPoint {
  return surface === ChartableSurface.Facial
    ? (`facial${upperFirst(position)}` as PerioDataPoint)
    : (`palatal${upperFirst(position)}` as PerioDataPoint);
}

// TODO: we need the plaque here
export enum OasisPerioMeasurement {
  Recession = 'recession',
  Pocket = 'pocket',
  Bleeding = 'bleeding',
  Mobility = 'mobility',
  Furcation = 'furcation',
  Suppuration = 'suppuration',
  Plaque = 'plaque',
}

// TODO: use the oasis measurements enum from up there
function oasisPerioDataArrayPositionToPerioMeasurement(
  index: number
): PerioMeasurement | undefined {
  if (index > 14) {
    // eslint-disable-next-line no-console
    console.error(`Invalid index for perio data array ${index}`);
    return;
  }
  const oasisMeasurements = [
    PerioMeasurement.Recession,
    PerioMeasurement.Pocket,
    PerioMeasurement.Bleeding,
    PerioMeasurement.Mobility,
    PerioMeasurement.Furcation,
    PerioMeasurement.Suppuration,
  ];
  const reverseOrder = [...oasisMeasurements].reverse();
  // position 6 and 8 are plaque in oasis
  // position 7 is empty
  const fullOrder = [
    ...oasisMeasurements,
    undefined,
    undefined,
    undefined,
    ...reverseOrder,
  ];
  return fullOrder[index] || undefined;
}
