import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IResolvedClaimItem } from '@principle-theorem/principle-core';
import {
  IProviderData,
  IStaffer,
  type IHealthcareClaim,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { ITransactionProvider } from '../transaction-providers/transaction-provider';
import { BuildClaimDialogService } from './build-claim-dialog/build-claim-dialog.service';

interface IPractitionerClaimSection {
  practitioner: WithRef<IStaffer>;
  providerData?: IProviderData;
  claims: IHealthcareClaim[];
  unclaimedItems: IResolvedClaimItem[];
  unclaimedCount: number;
  unnclaimedAmount: number;
}

@Component({
  selector: 'pr-healthcare-claims-list',
  templateUrl: './healthcare-claims-list.component.html',
  styleUrls: ['./healthcare-claims-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthcareClaimsListComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  practitionerClaims$ = new ReplaySubject<IPractitionerClaimSection[]>(1);
  trackByPractitioner =
    TrackByFunctions.ref<IPractitionerClaimSection>('practitioner.ref');
  trackByClaim = TrackByFunctions.ref<IHealthcareClaim>();

  constructor(
    private _globalStore: GlobalStoreService,
    private _buildClaimDialog: BuildClaimDialogService
  ) {}

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set practitionerClaims(practitionerClaims: IPractitionerClaimSection[]) {
    if (practitionerClaims) {
      this.practitionerClaims$.next(practitionerClaims);
    }
  }

  async claim(
    section: IPractitionerClaimSection,
    provider: ITransactionProvider
  ): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    const claim = await this._buildClaimDialog.open(
      invoice,
      section.practitioner,
      section.unclaimedItems
    );
    if (!claim) {
      return;
    }
    await provider.capture(invoice, claim);
  }

  stafferImage$(staffer: WithRef<IStaffer>): Observable<string | undefined> {
    return this._globalStore.getStafferImage$(staffer);
  }
}
