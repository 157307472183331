import { Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JSONSchema, StorageMap } from '@ngx-pwa/local-storage';
import {
  BasicDialogService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  IChat,
  IChatMessage,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  toMoment,
  uid,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Subject, noop, of, timer } from 'rxjs';
import {
  concatMap,
  filter,
  switchMap,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import { FloatingChatComponent } from './components/floating-chat/floating-chat.component';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import { NOTIFICATION_SOUNDS } from '@principle-theorem/ng-notifications';

export const DEFAULT_CHAT_SOUND = '/assets/sounds/notification.mp3';

const playSoundKey = 'chatPlaySound';
const lockKey = 'chatSoundLock';

const lockSchema: JSONSchema = {
  type: 'object',
  properties: {
    key: { type: 'string' },
    expiresUnix: { type: 'number' },
  },
};

interface INotificationLockKey {
  key: string;
  expiresUnix: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChatNotificationsService implements OnDestroy {
  private _initialised = false;
  private _onDestroy$ = new Subject<void>();
  private _openChatRefs: MatDialogRef<unknown>[] = [];
  private _lockKey = uid();

  constructor(
    private _dialog: BasicDialogService,
    private _storage: StorageMap,
    private _staffer: StafferSettingsStoreService
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  init(): void {
    if (this._initialised) {
      return;
    }

    this._initialised = true;

    timer(0, 30000)
      .pipe(
        switchMap(() =>
          this._storage.watch<INotificationLockKey>(lockKey, lockSchema)
        ),
        switchMap((currentLockHolder) =>
          this._handleLockAcquisition(currentLockHolder)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this._staffer.chats$
      .pipe(
        switchMap((chatSettings) =>
          timer(0, 2000).pipe(
            switchMap(() =>
              this._storage.get<number>(playSoundKey, { type: 'number' })
            ),
            filter(
              (playSoundUnix) =>
                !!playSoundUnix &&
                moment
                  .unix(playSoundUnix)
                  .isAfter(moment().subtract(20, 'seconds'))
            ),
            throttleTime(5000),
            concatMap(() =>
              this._playSound(chatSettings.notificationSoundOverride)
            )
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this._handleLockfileRelease();
  }

  async triggerNotificationMessage(
    message: WithRef<IChatMessage>
  ): Promise<void> {
    const messageCreatedUnix = toMoment(message.createdAt).unix();
    const playSoundUnix = await this._storage
      .get<number>(playSoundKey, { type: 'number' })
      .toPromise();

    if (playSoundUnix && messageCreatedUnix === playSoundUnix) {
      return;
    }

    await this._storage
      .set(playSoundKey, messageCreatedUnix, { type: 'number' })
      .toPromise();
  }

  showPopupChat(chatRef: DocumentReference<IChat>): void {
    this._openChatRefs.map((ref) => {
      try {
        ref.close();
      } catch (error) {
        // Do nothing
      }
    });

    this._openChatRefs = [];

    const dialogRef = this._dialog.open<
      FloatingChatComponent,
      DocumentReference<IChat>
    >(
      FloatingChatComponent,
      DialogPresets.flex({
        width: '368px',
        position: {
          bottom: '20px',
          right: '20px',
        },
        data: chatRef,
        hasBackdrop: false,
      })
    );

    this._openChatRefs.push(dialogRef);
  }

  private async _playSound(
    notificationSoundOverride: string = DEFAULT_CHAT_SOUND
  ): Promise<void> {
    const confirmLock = await this._storage
      .get<INotificationLockKey>(lockKey, lockSchema)
      .toPromise();
    if (confirmLock?.key !== this._lockKey) {
      return;
    }

    const selectedSound = NOTIFICATION_SOUNDS.find(
      (notificationSound) =>
        notificationSound.value === notificationSoundOverride
    );

    const audio = new Audio(`/assets/sounds/${selectedSound?.value}`);
    audio.volume = 0.75 * (selectedSound?.volume ?? 1);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    audio.addEventListener('canplaythrough', async () => {
      try {
        await audio.play();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error playing sound ${selectedSound?.value}`, error);
      }
    });

    await this._storage.delete(playSoundKey).toPromise();
  }

  private _handleLockfileRelease(): void {
    window.addEventListener(
      'beforeunload',
      () =>
        void this._storage
          .get(lockKey)
          .pipe(
            concatMap((currentLockHolder) => {
              if (currentLockHolder !== this._lockKey) {
                return of(undefined);
              }
              return this._storage.delete(lockKey);
            })
          )
          .toPromise()
    );
  }

  private async _handleLockAcquisition(
    currentLockHolder: INotificationLockKey | undefined
  ): Promise<void> {
    const hasActiveLock = currentLockHolder?.key === this._lockKey;

    const gracePeriodExpired =
      currentLockHolder &&
      moment
        .unix(currentLockHolder.expiresUnix)
        .isBefore(moment().subtract(30, 'seconds'));

    const shouldRenewLock =
      hasActiveLock &&
      moment
        .unix(currentLockHolder.expiresUnix)
        .isBefore(moment().add(30, 'seconds'));

    const data: INotificationLockKey = {
      key: this._lockKey,
      expiresUnix: moment().add(2, 'minutes').unix(),
    };

    if (!currentLockHolder || gracePeriodExpired || shouldRenewLock) {
      await this._storage.set(lockKey, data, lockSchema).toPromise();
    }
  }
}
