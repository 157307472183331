import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IStaffer,
  type ITeam,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import {
  type INamedDocument,
  isSameRef,
  saveDoc,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectedItemsBloc } from '../../../../../core/selected-items-bloc';

@Component({
  selector: 'pr-team-staffer-assignment',
  templateUrl: './team-staffer-assignment.component.html',
  styleUrls: ['./team-staffer-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamStafferAssignmentComponent {
  private _team$: ReplaySubject<WithRef<ITeam>> = new ReplaySubject(1);
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  trackByTeam = TrackByFunctions.ref<INamedDocument<ITeam>>();
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>();
  staff: SelectedItemsBloc<WithRef<IStaffer>>;
  unselectedSearch: InputSearchFilter<WithRef<IStaffer>>;
  selected$: Observable<WithRef<IStaffer>[]>;

  @Input()
  set team(team: WithRef<ITeam>) {
    if (team) {
      this._team$.next(team);
    }
  }

  constructor(private _brandScope: CurrentBrandScope) {
    const staff$ = this._brandScope
      .asObservable()
      .pipe(switchMap((brand?) => (brand ? Brand.staff$(brand) : of([]))));

    this.staff = new SelectedItemsBloc<WithRef<IStaffer>>(
      staff$,
      this._getSelected$(staff$),
      (stafferA, stafferB) => isSameRef(stafferA, stafferB)
    );

    this.selected$ = this.staff.selected$.pipe(
      map((staff) => sortBy(staff, 'user.name'))
    );

    this.unselectedSearch = new InputSearchFilter<WithRef<IStaffer>>(
      this.staff.unselected$.pipe(map((staff) => sortBy(staff, 'user.name'))),
      toSearchStream(this.searchCtrl),
      ['user.name']
    );
  }

  displayFn(value: string | WithRef<IStaffer>): string {
    return typeof value === 'string' ? value : '';
  }

  async unassign(staffer: WithRef<IStaffer>): Promise<void> {
    const team: WithRef<ITeam> = await snapshot(this._team$);
    staffer.teams = staffer.teams.filter(
      (namedDoc: INamedDocument) => namedDoc.ref.path !== team.ref.path
    );
    await saveDoc(staffer);
  }

  async assign(staffer: WithRef<IStaffer>): Promise<void> {
    const team: WithRef<ITeam> = await snapshot(this._team$);
    staffer.teams.push(toNamedDocument(team));
    staffer.teams = uniqBy(
      staffer.teams,
      (doc: INamedDocument) => doc.ref.path
    );
    await saveDoc(staffer);
  }

  private _getSelected$(
    staff$: Observable<WithRef<IStaffer>[]>
  ): Observable<WithRef<IStaffer>[]> {
    return combineLatest([staff$, this._team$]).pipe(
      map(([staff, team]) => staff.filter((staffer) => isInTeam(staffer, team)))
    );
  }
}

export function isInTeam(
  staffer: WithRef<IStaffer>,
  team: WithRef<ITeam>
): boolean {
  return staffer.teams
    .map((namedDoc: INamedDocument) => namedDoc.ref.path)
    .includes(team.ref.path);
}
