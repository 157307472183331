import { Component } from '@angular/core';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';
import {
  CustomChartType,
  LabJobAction,
  TaskAction,
} from '@principle-theorem/principle-core/interfaces';
import {
  FactTables,
  ValueEqualsMeasureFilter,
} from '@principle-theorem/reporting';

@Component({
  selector: 'pr-practice-operations',
  templateUrl: './practice-operations.component.html',
  styleUrls: ['./practice-operations.component.sass'],
})
export class PracticeOperationsComponent extends MeasureDataChartComponent {
  replacementHeaders: ITableHeaderReplacement[] = [
    {
      from: 'Owner',
      to: 'Practice',
    },
  ];
  tableData: IChartCard;
  staffTimeChart: ChartBuilder;
  operationsChart: ChartBuilder;
  fullTimeEquivalentChart: ChartBuilder;
  fullTimeEquivalentRatioChart: ChartBuilder;

  _redraw(): void {
    this.staffTimeChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            // TODO: Integrate rostering into system
            // 'staffOnAnnualLeave',
            // 'staffOnSickLeave',
            // 'staffOnRosteredDaysOff',
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.operationsChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.labJobEvent.latestEvent.event.action
              .filterBy(new ValueEqualsMeasureFilter(LabJobAction.Received))
              .reduceByCount()
              .setLabel('Completed Lab Jobs'),
            FactTables.taskEvent.latestEvent.event.action
              .filterBy(new ValueEqualsMeasureFilter(TaskAction.Completed))
              .reduceByCount()
              .setLabel('Completed Tasks'),
          ],
          FactTables.labJobEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.fullTimeEquivalentChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            // TODO: Integrate rostering and chair tracking into system
            // 'chairHoursAvailable',
            // 'staffHoursAvailable',
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.fullTimeEquivalentRatioChart = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            // TODO: Integrate rostering and chair tracking into system
            // 'fullTimeEquivalentRatio',
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        bars: 'horizontal',
      });

    this.tableData = this._tableChartCard(
      [
        // TODO: Integrate rostering and chair tracking into system
        // 'numberOfStaff',
        // 'numberOfChairs',
        // 'chairHoursAvailable',
        // 'staffHoursAvailable',
        // 'fullTimeEquivalentRatio',
      ],
      FactTables.appointmentEvent.practice.name
    );
  }
}
