import {
  ILabJob,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { CanBeChartedProperty } from '../measure-properties';
import {
  CanDoAllProperty,
  CanGroupMeasuresProperty,
} from '../measure-properties';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasureTransformMap } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { DataAccessorFactory, MeasurePath } from '../data-accessor-factory';

export class LabJobDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<ILabJob, 'status' | 'cost'>>
{
  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Lab Job Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get status(): CanDoAllProperty {
    const measure = this.measureRef('status');
    return MeasurePropertyFactory.enum<LabJobStatus>(
      {
        id: this._pathWithPrefix('status'),
        label: 'Lab Job Status',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      LabJobStatus.Sending
    );
  }

  get cost(): CanBeChartedProperty {
    const propertyName = 'cost';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Lab Job Cost',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get type(): CanGroupMeasuresProperty {
    const propertyName = 'type';
    const queryMeasure = this.measureRef(propertyName);
    const labelMeasure = this.measureRef('type.name');
    return new CanGroupMeasuresProperty({
      metadata: {
        id: this._pathWithPrefix(propertyName),
        label: 'Lab Job Type',
        summary: '',
      },
      groupMeasure: {
        queryAttributes: [queryMeasure.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          labelMeasure.factPropertyPath,
          ''
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          labelMeasure.factPropertyPath,
          ''
        ),
      },
    });
  }
}
