import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';
import {
  isPraktikaPeriodontalChart,
  type IPraktikaPeriodontalChart,
} from './patient-periodontal-chart';

export const PATIENT_PERIODONTAL_DATA_RESOURCE_TYPE = 'patientPeriodontalData';

export const PATIENT_PERIODONTAL_DATA_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Periodontal Data List',
      description: '',
      idPrefix: PATIENT_PERIODONTAL_DATA_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaPerioToothData extends IPraktikaPeriodontalChart {
  id: number;
  tooth_number: number; // number version of ToothNumber
  exam_id: number;
  furcation: number | null;
  mobility: number | null;
  notes: string | null;
  prognosis: string | null;
  prd_b_d: number | null;
  prd_b_c: number | null;
  prd_b_m: number | null;
  rec_b_d: number | null;
  rec_b_c: number | null;
  rec_b_m: number | null;
  is_bld_b_d: boolean | null;
  is_bld_b_c: boolean | null;
  is_bld_b_m: boolean | null;
  bld_b_d: number | null;
  bld_b_c: number | null;
  bld_b_m: number | null;
  is_sup_b_d: boolean | null;
  is_sup_b_c: boolean | null;
  is_sup_b_m: boolean | null;
  fur_b_d: number | null;
  fur_b_c: number | null;
  fur_b_m: number | null;
  plq_b: number | null;
  prd_l_d: number | null;
  prd_l_c: number | null;
  prd_l_m: number | null;
  rec_l_d: number | null;
  rec_l_c: number | null;
  rec_l_m: number | null;
  is_bld_l_d: boolean | null;
  is_bld_l_c: boolean | null;
  is_bld_l_m: boolean | null;
  bld_l_d: number | null;
  bld_l_c: number | null;
  bld_l_m: number | null;
  is_sup_l_d: boolean | null;
  is_sup_l_c: boolean | null;
  is_sup_l_m: boolean | null;
  fur_l_d: number | null;
  fur_l_c: number | null;
  fur_l_m: number | null;
  plq_l: number | null;
  date_created: string;
  date_updated: string | null;

  // typeId: number;
  // conditions: IPraktikaToothCondition[];
}

function isPerioToothData(item: unknown): item is IPraktikaPerioToothData {
  const isToothData = TypeGuard.interface<
    Omit<IPraktikaPerioToothData, keyof IPraktikaPeriodontalChart>
  >({
    id: isNumber,
    exam_id: isNumber,
    tooth_number: isNumber,
    furcation: TypeGuard.nilOr(isNumber),
    mobility: TypeGuard.nilOr(isNumber),
    notes: TypeGuard.nilOr(isString),
    prognosis: TypeGuard.nilOr(isString),
    prd_b_d: TypeGuard.nilOr(isNumber),
    prd_b_c: TypeGuard.nilOr(isNumber),
    prd_b_m: TypeGuard.nilOr(isNumber),
    rec_b_d: TypeGuard.nilOr(isNumber),
    rec_b_c: TypeGuard.nilOr(isNumber),
    rec_b_m: TypeGuard.nilOr(isNumber),
    is_bld_b_d: TypeGuard.nilOr(isBoolean),
    is_bld_b_c: TypeGuard.nilOr(isBoolean),
    is_bld_b_m: TypeGuard.nilOr(isBoolean),
    bld_b_d: TypeGuard.nilOr(isNumber),
    bld_b_c: TypeGuard.nilOr(isNumber),
    bld_b_m: TypeGuard.nilOr(isNumber),
    is_sup_b_d: TypeGuard.nilOr(isBoolean),
    is_sup_b_c: TypeGuard.nilOr(isBoolean),
    is_sup_b_m: TypeGuard.nilOr(isBoolean),
    fur_b_d: TypeGuard.nilOr(isNumber),
    fur_b_c: TypeGuard.nilOr(isNumber),
    fur_b_m: TypeGuard.nilOr(isNumber),
    plq_b: TypeGuard.nilOr(isNumber),
    prd_l_d: TypeGuard.nilOr(isNumber),
    prd_l_c: TypeGuard.nilOr(isNumber),
    prd_l_m: TypeGuard.nilOr(isNumber),
    rec_l_d: TypeGuard.nilOr(isNumber),
    rec_l_c: TypeGuard.nilOr(isNumber),
    rec_l_m: TypeGuard.nilOr(isNumber),
    is_bld_l_d: TypeGuard.nilOr(isBoolean),
    is_bld_l_c: TypeGuard.nilOr(isBoolean),
    is_bld_l_m: TypeGuard.nilOr(isBoolean),
    bld_l_d: TypeGuard.nilOr(isNumber),
    bld_l_c: TypeGuard.nilOr(isNumber),
    bld_l_m: TypeGuard.nilOr(isNumber),
    is_sup_l_d: TypeGuard.nilOr(isBoolean),
    is_sup_l_c: TypeGuard.nilOr(isBoolean),
    is_sup_l_m: TypeGuard.nilOr(isBoolean),
    fur_l_d: TypeGuard.nilOr(isNumber),
    fur_l_c: TypeGuard.nilOr(isNumber),
    fur_l_m: TypeGuard.nilOr(isNumber),
    plq_l: TypeGuard.nilOr(isNumber),
  });
  return (
    isObject(item) && isPraktikaPeriodontalChart(item) && isToothData(item)
  );
}

export interface IPraktikaPerioToothDataFilters {
  examId: string;
  patientId: string;
  stafferId: string;
  date: Timestamp;
}

export interface IPraktikaPerioToothDataTranslations {
  date: Timestamp;
}

const PATIENT_PERIODONTAL_DATA_SOURCE_QUERY = `
SELECT * FROM (SELECT
  iPerioExamId as exam_id,
  iPerioToothDataId as id,
  -- iToothId as tooth_id,
  iToothNumber as tooth_number,
  vchNote as notes,
  iProbingDepth_Buccal_Distal AS prd_b_d,
  iProbingDepth_Buccal_Central AS prd_b_c,
  iProbingDepth_Buccal_Mesial AS prd_b_m,
  iProbingDepth_Lingual_Distal AS prd_l_d,
  iProbingDepth_Lingual_Central AS prd_l_c,
  iProbingDepth_Lingual_Mesial AS prd_l_m,
  iRecession_Buccal_Distal AS rec_b_d,
  iRecession_Buccal_Central AS rec_b_c,
  iRecession_Buccal_Mesial AS rec_b_m,
  iRecession_Lingual_Distal AS rec_l_d,
  iRecession_Lingual_Central AS rec_l_c,
  iRecession_Lingual_Mesial AS rec_l_m,
  bBleeding_Buccal_Distal AS is_bld_b_d,
  bBleeding_Buccal_Central AS is_bld_b_c,
  bBleeding_Buccal_Mesial AS is_bld_b_m,
  iBleeding_Buccal_Distal AS bld_b_d,
  iBleeding_Buccal_Central AS bld_b_c,
  iBleeding_Buccal_Mesial AS bld_b_m,
  bBleeding_Lingual_Distal AS is_bld_l_d,
  bBleeding_Lingual_Central AS is_bld_l_c,
  bBleeding_Lingual_Mesial AS is_bld_l_m,
  iBleeding_Lingual_Distal AS bld_l_d,
  iBleeding_Lingual_Central AS bld_l_c,
  iBleeding_Lingual_Mesial AS bld_l_m,
  bSuppuration_Buccal_Distal AS is_sup_b_d,
  bSuppuration_Buccal_Central AS is_sup_b_c,
  bSuppuration_Buccal_Mesial AS is_sup_b_m,
  bSuppuration_Lingual_Distal AS is_sup_l_d,
  bSuppuration_Lingual_Central AS is_sup_l_c,
  bSuppuration_Lingual_Mesial AS is_sup_l_m,
  iPlaqueLevel_Buccal AS plq_b,
  iPlaqueLevel_Lingual AS plq_l,
  iFurcationLevel AS furcation,
  iFurcation_Buccal_Distal AS fur_b_d,
  iFurcation_Buccal_Central AS fur_b_c,
  iFurcation_Buccal_Mesial AS fur_b_m,
  iFurcation_Lingual_Distal AS fur_l_d,
  iFurcation_Lingual_Central AS fur_l_c,
  iFurcation_Lingual_Mesial AS fur_l_m,
  iMobilityLevel AS mobility,
  vchPrognosis as prognosis,
  dtcreated as date_created,
  dtupdated as date_updated
FROM patient_perio_exam_data) AS data
INNER JOIN (
  SELECT
    iPatientNumber as perioexam_patientid,
    iPerioExamId as perioexam_id,
    iProviderId as perioexam_providerid,
    dtExamDate as perioexam_date,
    vchExamNote as perioexam_notes,
    vchPSR_sextant1 as perioexam_psrsextant1,
    vchPSR_sextant2 as perioexam_psrsextant2,
    vchPSR_sextant3 as perioexam_psrsextant3,
    vchPSR_sextant4 as perioexam_psrsextant4,
    vchPSR_sextant5 as perioexam_psrsextant5,
    vchPSR_sextant6 as perioexam_psrsextant6,
    NULLIF(vchCPITN_sextant1, '') as perioexam_cptinsextant1,
    NULLIF(vchCPITN_sextant2, '') as perioexam_cptinsextant2,
    NULLIF(vchCPITN_sextant3, '') as perioexam_cptinsextant3,
    NULLIF(vchCPITN_sextant4, '') as perioexam_cptinsextant4,
    NULLIF(vchCPITN_sextant5, '') as perioexam_cptinsextant5,
    NULLIF(vchCPITN_sextant6, '') as perioexam_cptinsextant6,
    iAlveolarBoneLoss as perioexam_boneloss,
    bSystemicFactors as perioexam_systemicfactors,
    dtcreated as date_created,
    dtupdated as date_updated
  FROM patient_perio_exams) AS exam ON data.exam_id = exam.perioexam_id
ORDER BY data.id DESC
`;

export class PatientPeriodontalDataSourceEntity extends BaseSourceEntity<
  IPraktikaPerioToothData,
  IPraktikaPerioToothDataTranslations,
  IPraktikaPerioToothDataFilters
> {
  sourceEntity = PATIENT_PERIODONTAL_DATA_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIODONTAL_DATA_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIODONTAL_DATA_SOURCE_QUERY;
  verifySourceFn = isPerioToothData;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaPerioToothDataFilters = 'date';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    chart: IPraktikaPerioToothData,
    timezone: Timezone
  ): IPraktikaPerioToothDataTranslations {
    return {
      date: toTimestamp(
        moment.tz(chart.date_created, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaPerioToothData): number {
    return data.id;
  }

  getSourceLabel(data: IPraktikaPerioToothData): string {
    return `${data.exam_id} - ${data.id}`;
  }

  getFilterData(
    data: IPraktikaPerioToothData,
    timezone: Timezone
  ): IPraktikaPerioToothDataFilters {
    return {
      examId: data.exam_id.toString(),
      patientId: data.perioexam_patientid.toString(),
      stafferId: data.perioexam_providerid.toString(),
      date: toTimestamp(
        moment.tz(data.date_created, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
