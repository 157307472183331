<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding" fxLayout="column" fxLayoutGap="32px">
  <pr-report-builder-query-form />
  <pr-report-builder-loader>
    <div fxLayout="column" fxLayoutGap="32px">
      <pr-report-builder-chart-filters />
      <pr-report-builder-results-table>
        <p>
          The table below contains rows within the selected date range and are
          filtered by interacting with the explore section above.
        </p>
      </pr-report-builder-results-table>
    </div>
  </pr-report-builder-loader>
</div>
