<mat-toolbar *ngrxLet="patient$ | async as patient" color="accent">
  Follow Up - {{ patient.name }}
</mat-toolbar>
<div class="flex flex-1 overflow-hidden">
  <div class="flex-1">
    <pr-interactions
      editable
      [enableScroll]="true"
      [interactions]="interactions$ | async"
      (interactionAdded)="addInteraction($event)"
      (interactionUpdated)="updateInteraction($event)"
      (interactionDeleted)="deleteInteraction($event)"
    />
  </div>

  <div
    class="sidebar flex flex-col justify-between"
    *ngrxLet="appointment$ as appointment"
  >
    <div>
      <a
        mat-button
        [prBrandRouterLink]="patientUrl$ | async"
        (click)="dialogRef.close()"
      >
        <mat-icon>person</mat-icon>
        <span>Patient Profile</span>
      </a>
      @for (action of patientActions; track action.name) {
        <button
          mat-button
          [disabled]="action.isDisabled$ | async"
          (click)="action.run()"
        >
          <mat-icon>{{ action.icon }}</mat-icon>
          <span>{{ action.name }}</span>
        </button>
      }
    </div>

    <div class="mb-2">
      <mat-divider />
      <button
        mat-button
        (click)="followUpService.reschedule(appointment); dialogRef.close()"
      >
        <mat-icon>keyboard_tab</mat-icon>
        <span>Reschedule</span>
      </button>
      <button mat-button (click)="followUpService.postpone(appointment)">
        <mat-icon>watch_later</mat-icon>
        <span>Postpone</span>
      </button>
      <button
        mat-button
        color="warn"
        (click)="followUpService.cancel(appointment); dialogRef.close()"
      >
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
    </div>
  </div>
</div>
