import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { VixWinOpenPatientComponent } from './vixwin-open-patient/vixwin-open-patient.component';
import { VixWinFeatureService } from './vixwin-feature.service';

@NgModule({
  declarations: [VixWinOpenPatientComponent],
  imports: [NgSharedModule],
  providers: [VixWinFeatureService],
})
export class VixWinModule {}
