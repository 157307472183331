import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { EmailComponent } from '../../email/email.component';
import { InteractionWithTemplatesService } from './interaction-with-templates.service';

@Injectable()
export class EmailService extends InteractionWithTemplatesService<EmailComponent> {
  override icon = 'email';
  override name = 'Email';
  override dialogComponent: ComponentType<EmailComponent> = EmailComponent;
}
