<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Account Credits"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="p-4">
  <h2 class="!m-4">Account Credits</h2>
  <div class="m-4 flex flex-row flex-wrap gap-4">
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
    />
    <pr-practice-selector
      subscriptSizing="dynamic"
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
    <button
      mat-flat-button
      color="primary"
      (click)="runReport()"
      [disabled]="(store.loading$ | async) === true"
    >
      Run
    </button>
  </div>

  <div class="p-4">
    @if (store.loading$ | async) {
      <div class="flex flex-row items-center justify-center">
        <mat-spinner mode="indeterminate" />
      </div>
    } @else {
      <pr-account-credits-report-table
        class="flex"
        [records]="store.records$ | async"
        [dateRange]="dataBuilder.dateChange | async"
      />
    }
  </div>
</div>
