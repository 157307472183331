<ng-container *ngIf="item$ | async as item">
  <div *ngIf="groupedTreatment" class="treatment-card-group"></div>
  <div class="treatment-card">
    <div class="flex items-center">
      <div class="header-wrapper flex-1">
        <pr-charted-item-header
          [item]="item"
          [highlighted]="hasSelectedSurface$ | async"
          [compact]="compact"
          [config]="config$ | async"
          [plan]="plan$ | async"
          (updateChartable)="updateChartable.emit($event)"
          (editSurfaces)="editSurfaces()"
        >
          <ng-container ngProjectAs=".header-info">
            <ng-content select=".header-info"></ng-content>
          </ng-container>

          <ng-container ngProjectAs=".drag-handle">
            <ng-content select=".drag-handle"></ng-content>
          </ng-container>

          <ng-container ngProjectAs=".checkbox">
            <ng-content select=".checkbox"></ng-content>
          </ng-container>

          <ng-container ngProjectAs=".attributed-to">
            <ng-content select=".attributed-to"></ng-content>
          </ng-container>

          <ng-container ngProjectAs=".header-buttons">
            <ng-content select="pr-treatment-category-indicator"></ng-content>
            <ng-content select=".change-treatment-package"></ng-content>
            <ng-content select=".multi-step-buttons"></ng-content>
            <ng-content select=".change-fee-schedule"></ng-content>
            <ng-container ngProjectAs=".toggle-content">
              <button
                mat-icon-button
                class="icon-sm"
                aria-label="Toggle treatment"
                (click)="content.toggle()"
                [matTooltip]="
                  content.expanded ? 'Hide Treatment' : 'Show Treatment'
                "
              >
                <mat-icon>
                  {{ content.expanded ? 'expand_less' : 'expand_more' }}
                </mat-icon>
              </button>
            </ng-container>

            <ng-container *ngIf="!disabled">
              <button
                mat-icon-button
                class="icon-sm"
                [matMenuTriggerFor]="menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <ng-template matMenuContent>
                  <button mat-menu-item (click)="openNoteDialog(item)">
                    <mat-icon>note_add</mat-icon>
                    Add Note
                  </button>

                  <button
                    mat-menu-item
                    [disabled]="editDisabled$ | async"
                    (click)="editSurfaces()"
                  >
                    <mat-icon>select_all</mat-icon>
                    Add/Change Surfaces
                  </button>

                  <ng-content select=".menu-buttons"></ng-content>

                  <button
                    mat-menu-item
                    *ngIf="!disabled"
                    (click)="itemDelete.emit(item)"
                  >
                    <mat-icon color="warn">delete</mat-icon>
                    Delete Treatment
                  </button>
                </ng-template>
              </mat-menu>
            </ng-container>
          </ng-container>
        </pr-charted-item-header>
      </div>
    </div>

    <pt-collapsible #content>
      <ng-template ptCollapsibleContent>
        <mat-divider />
        <div class="content-container">
          <ng-content></ng-content>
        </div>
      </ng-template>
    </pt-collapsible>
  </div>
</ng-container>
