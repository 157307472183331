import type * as moment from 'moment-timezone';
import {
  MockActivePatientsStatistic,
  MockTotalNumberOfAppointmentsStatistic,
  MockTotalNumberOfCancelledAppointmentsStatistic,
  MockCompletedAppointmentsStatistic,
  MockCompletedLabJobsStatistic,
  MockCompletedTasksStatistic,
  MockGrossIncomeStatistic,
  MockInactivePatientsStatistic,
  MockLabFeesStatistic,
  MockNetIncomeStatistic,
  MockNewPatientsStatistic,
  MockPatientsInAppointmentStatistic,
  MockRevenueFromCompletedAppointmentsStatistic,
  MockRevenueFromProductsSoldStatistic,
  MockRevenueKPIStatistic,
  MockScheduledAppointmentsStatistic,
  MockStaffOnAnnualLeaveStatistic,
  MockStaffOnRosteredDaysOffStatistic,
  MockStaffOnSickLeaveStatistic,
  MockUnscheduledAppointmentsStatistic,
  MockEmptyChairStatistic,
  MockDentalDrawStatistic,
  MockTotalExpensesStatistic,
  MockConsumablesExpendedStatistic,
  MockFacilityCostStatistic,
  MockReschedulingRateStatistic,
  MockCancellationRateStatistic,
  MockConversionRateStatistic,
  MockAverageAppointmentDuration,
  MockStaffExpensesStatistic,
  MockOverdueInvoicesCount,
  MockOverdueInvoicesAmount,
  MockPaymentPlansCount,
  MockPaymentPlansAmount,
  MockPaidOnCheckoutRate,
  MockPaidAfterCheckoutRate,
  MockNeverPaidRate,
  MockTreatmentPlanAcceptanceRateStatistic,
  MockFtasStatistic,
  MockUtasStatistic,
  MockTimeUsedStatistic,
  MockTimeUnusedStatistic,
  MockLostIncomeStatistic,
  MockAveragePatientSpend,
  MockPatientsSeenStatistic,
  MockAveragePatientWaitTime,
  MockExistingPatientsStatistic,
  MockNumberOfChairs,
  MockNumberOfStaff,
  MockChairHoursAvailable,
  MockStaffHoursAvailable,
  MockFullTimeEquivalentRatio,
  MockWrittenOffInvoicesAmount,
} from './base-statistics';
import {
  type IStatistics,
  type IPracticeStats,
} from '@principle-theorem/reporting';

export class PracticeStatistics implements IStatistics<IPracticeStats> {
  name = '';
  date: moment.Moment;
  stats: IPracticeStats = {
    grossIncome: MockGrossIncomeStatistic(),
    netIncome: MockNetIncomeStatistic(),
    lostIncome: MockLostIncomeStatistic(),
    appointments: MockTotalNumberOfAppointmentsStatistic(),
    completedAppointments: MockCompletedAppointmentsStatistic(),
    scheduledAppointments: MockScheduledAppointmentsStatistic(),
    unscheduledAppointments: MockUnscheduledAppointmentsStatistic(),
    revenueFromCompletedAppointments:
      MockRevenueFromCompletedAppointmentsStatistic(),
    cancelledAppointments: MockTotalNumberOfCancelledAppointmentsStatistic(),
    labExpenses: MockLabFeesStatistic(),
    staffExpenses: MockStaffExpensesStatistic(),
    consumables: MockConsumablesExpendedStatistic(),
    facilityCosts: MockFacilityCostStatistic(),
    completedLabJobs: MockCompletedLabJobsStatistic(),
    completedTasks: MockCompletedTasksStatistic(),
    revenueFromProductsSold: MockRevenueFromProductsSoldStatistic(),
    patientsInAppointment: MockPatientsInAppointmentStatistic(),
    patientsSeen: MockPatientsSeenStatistic(),
    newPatients: MockNewPatientsStatistic(),
    existingPatients: MockExistingPatientsStatistic(),
    activePatients: MockActivePatientsStatistic(),
    inactivePatients: MockInactivePatientsStatistic(),
    staffOnAnnualLeave: MockStaffOnAnnualLeaveStatistic(),
    staffOnSickLeave: MockStaffOnSickLeaveStatistic(),
    staffOnRosteredDaysOff: MockStaffOnRosteredDaysOffStatistic(),
    revenueKPI: MockRevenueKPIStatistic(),
    emptyChairTime: MockEmptyChairStatistic(),
    dentalDraw: MockDentalDrawStatistic(),
    totalExpenses: MockTotalExpensesStatistic(),
    rescheduleRate: MockReschedulingRateStatistic(),
    cancellationRate: MockCancellationRateStatistic(),
    conversionRate: MockConversionRateStatistic(),
    caseAcceptanceRate: MockTreatmentPlanAcceptanceRateStatistic(),
    appointmentDuration: MockAverageAppointmentDuration(),
    overdueInvoicesCount: MockOverdueInvoicesCount(),
    overdueInvoicesTotal: MockOverdueInvoicesAmount(),
    writtenOffInvoicesTotal: MockWrittenOffInvoicesAmount(),
    paymentPlansCount: MockPaymentPlansCount(),
    paymentPlansTotal: MockPaymentPlansAmount(),
    paidOnCheckoutRate: MockPaidOnCheckoutRate(),
    paidAfterCheckoutRate: MockPaidAfterCheckoutRate(),
    neverPaidRate: MockNeverPaidRate(),
    averagePatientSpend: MockAveragePatientSpend(),
    averagePatientWaitTime: MockAveragePatientWaitTime(),
    ftas: MockFtasStatistic(),
    utas: MockUtasStatistic(),
    timeUsed: MockTimeUsedStatistic(),
    timeUnused: MockTimeUnusedStatistic(),
    numberOfChairs: MockNumberOfChairs(),
    numberOfStaff: MockNumberOfStaff(),
    chairHoursAvailable: MockChairHoursAvailable(),
    staffHoursAvailable: MockStaffHoursAvailable(),
    fullTimeEquivalentRatio: MockFullTimeEquivalentRatio(),
  };
}
