import {
  Brand,
  Organisation,
  Staffer,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IBrand,
  ICustomMappingHandler,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IMigratedDataSummary,
  IOrganisation,
  IPracticeMigration,
  IProviderData,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  IUser,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  Timestamp,
  WithRef,
  asDocRef,
  toTimestamp,
} from '@principle-theorem/shared';
import { Observable, combineLatest, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { PracticeMigration } from '../../practice-migrations';
import { buildSkipMigratedQuery } from '../../source/source-entity-record';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { FirestoreMigrate } from '../destination';
import { DestinationEntityRecord } from '../destination-entity-record';

export const USER_CUSTOM_MAPPING_TYPE = 'user';

export const STAFFER_RESOURCE_TYPE = 'staffer';

export interface IStafferJobData<T extends object> {
  stafferRecord: IGetRecordResponse<T>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  organisation: WithRef<IOrganisation>;
  brand: WithRef<IBrand>;
}

export interface IStafferMigrationData {
  sourceStafferId: string;
  user: IUser;
  stafferDetails: Pick<IProviderData, 'providerNumber' | 'providerModality'>;
}

export interface IStafferDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  userRef: DocumentReference<IUser>;
  stafferRef: DocumentReference<IStaffer>;
}

export abstract class BaseStafferDestinationEntity<
  StafferRecord extends object,
> extends BaseDestinationEntity<
  IStafferDestinationRecord,
  IStafferJobData<StafferRecord>,
  IStafferMigrationData
> {
  abstract stafferSourceEntity: ISourceEntityHandler<StafferRecord[]>;
  abstract stafferCustomMapping: ICustomMappingHandler<IStaffer>;
  abstract staffToUserCustomMapping: ICustomMappingHandler<IUser>;

  get sourceCountComparison(): ISourceEntityHandler<StafferRecord[]> {
    return this.stafferSourceEntity;
  }

  sourceCountDataAccessor(
    data: IStafferJobData<StafferRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.stafferRecord.record.ref;
  }

  getDestinationEntityRecordUid(data: IStafferJobData<StafferRecord>): string {
    return data.stafferRecord.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp
  ): Observable<IStafferJobData<StafferRecord>[]> {
    const staff$ = this.stafferCustomMapping.getRecords$(translationMapHandler);
    const organisation$ = PracticeMigration.organisation$(migration);
    const brand$ = PracticeMigration.brand$(migration);

    return this.stafferSourceEntity
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(organisation$, brand$, staff$),
        map(([staffRecords, organisation, brand, staff]) =>
          staffRecords.map((stafferRecord) => ({
            stafferRecord,
            organisation,
            brand,
            staff,
          }))
        )
      );
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IStafferDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.doc$(record.data.userRef),
      Firestore.doc$(record.data.stafferRef),
    ]).pipe(
      map(([user, staffer]) => [
        {
          label: 'User',
          data: user,
        },
        {
          label: 'Staffer',
          data: staffer,
        },
      ])
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IStafferMigrationData
  ): Promise<IStafferMigrationData | undefined> {
    const existingUserRef = await translationMap.getDestination(
      data.sourceStafferId,
      USER_CUSTOM_MAPPING_TYPE
    );

    if (!existingUserRef) {
      return;
    }

    const existingStafferRef = await translationMap.getDestination(
      data.sourceStafferId,
      this.stafferSourceEntity.sourceEntity.metadata.idPrefix
    );

    if (!existingStafferRef) {
      return;
    }

    try {
      const existingUser = await Firestore.getDoc(
        asDocRef<IUser>(existingUserRef)
      );
      const existingStaffer = await Firestore.getDoc(
        asDocRef<IStaffer>(existingStafferRef)
      );

      const hasMergeConflict =
        DestinationEntityRecord.hasMergeConflicts(data.user, existingUser) ||
        DestinationEntityRecord.hasMergeConflicts(
          data.stafferDetails,
          existingStaffer
        );

      if (hasMergeConflict) {
        return {
          ...data,
          user: existingUser,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IStafferJobData<StafferRecord>,
    _migrationData: IStafferMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.stafferRecord.record.uid,
      label: jobData.stafferRecord.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IStafferJobData<StafferRecord>,
    migrationData: IStafferMigrationData
  ): Promise<IDestinationEntityRecord> {
    const stafferCustomMapOverride = jobData.staff.find(
      (staffer) =>
        staffer.sourceIdentifier ===
        this.stafferSourceEntity
          .getSourceRecordId(jobData.stafferRecord.data.data)
          .toString()
    );

    if (stafferCustomMapOverride?.destinationIdentifier) {
      const stafferRef = stafferCustomMapOverride?.destinationIdentifier;
      await translationMapHandler.upsert({
        sourceIdentifier: migrationData.sourceStafferId,
        destinationIdentifier: stafferRef,
        resourceType: this.stafferSourceEntity.sourceEntity.metadata.idPrefix,
      });
      return this._buildSkippedResponse(
        jobData.stafferRecord,
        migrationData.user
      );
    }

    const staffToUserMapOverride =
      await this.staffToUserCustomMapping.getBySource(
        this.stafferSourceEntity
          .getSourceRecordId(jobData.stafferRecord.data.data)
          .toString(),
        translationMapHandler
      );

    let userRef: DocumentReference<IUser> | undefined =
      staffToUserMapOverride?.destinationIdentifier;

    if (userRef) {
      await translationMapHandler.upsert({
        sourceIdentifier: this.stafferSourceEntity
          .getSourceRecordId(jobData.stafferRecord.data.data)
          .toString(),
        destinationIdentifier: userRef,
        resourceType: USER_CUSTOM_MAPPING_TYPE,
      });
    }

    if (!userRef) {
      userRef = await this._upsertUser(
        translationMapHandler,
        jobData.organisation,
        migrationData
      );
    }

    if (!userRef) {
      throw new Error('Failed to create user');
    }

    const stafferRef = await this._upsertStaffer(
      jobData.brand,
      userRef,
      translationMapHandler,
      migrationData,
      migration
    );

    return this._buildSuccessResponse(
      {
        name: migrationData.user.name,
        ref: stafferRef,
      },
      jobData.stafferRecord,
      userRef
    );
  }

  protected _buildErrorResponse(
    staffer: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: staffer.uid,
      label: staffer.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? `Can't resolve staffer data`,
      failData: {
        stafferRef: staffer.ref,
      },
    };
  }

  protected _buildSuccessResponse(
    staffer: INamedDocument<IStaffer>,
    record: IGetRecordResponse<StafferRecord>,
    userRef: DocumentReference<IUser>
  ): IDestinationEntityRecord<IStafferDestinationRecord> {
    return {
      uid: record.record.uid,
      label: staffer.name,
      data: {
        sourceRef: record.record.ref,
        userRef,
        stafferRef: staffer.ref,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildSkippedResponse(
    record: IGetRecordResponse<StafferRecord>,
    user: IUser
  ): IDestinationEntityRecord {
    return {
      uid: record.record.uid,
      label: user.name.trim(),
      status: DestinationEntityRecordStatus.Skipped,
    };
  }

  private async _upsertStaffer(
    brand: WithRef<IBrand>,
    userRef: DocumentReference<IUser>,
    translationMap: TranslationMapHandler,
    migrationData: IStafferMigrationData,
    migration: WithRef<IPracticeMigration>
  ): Promise<DocumentReference<IStaffer>> {
    const existingStafferRef = await translationMap.getDestination(
      migrationData.sourceStafferId,
      this.stafferSourceEntity.sourceEntity.metadata.idPrefix
    );

    const stafferRef = await FirestoreMigrate.upsertDoc(
      Brand.stafferCol(brand),
      Staffer.init({
        user: {
          name: migrationData.user.name,
          ref: userRef,
        },
        providerDetails: migration.configuration.practices.map((practice) => ({
          practiceRef: practice.ref,
          providerNumber: migrationData.stafferDetails.providerNumber,
          providerModality: migrationData.stafferDetails.providerModality,
        })),
      }),
      existingStafferRef?.id
    );

    await this.stafferCustomMapping?.upsertRecord(
      {
        sourceIdentifier: migrationData.sourceStafferId,
        destinationIdentifier: stafferRef,
      },
      translationMap
    );

    if (!existingStafferRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.sourceStafferId,
        sourceLabel: migrationData.user.name,
        destinationIdentifier: stafferRef,
        resourceType: this.stafferSourceEntity.sourceEntity.metadata.idPrefix,
      });
    }
    return stafferRef;
  }

  private async _upsertUser(
    translationMap: TranslationMapHandler,
    organisation: WithRef<IOrganisation>,
    migrationData: IStafferMigrationData
  ): Promise<DocumentReference<IUser> | undefined> {
    const userDestinationRef = await translationMap.getDestination(
      migrationData.sourceStafferId,
      USER_CUSTOM_MAPPING_TYPE
    );
    const userRef = await FirestoreMigrate.upsertDoc(
      Organisation.userCol(organisation),
      migrationData.user,
      userDestinationRef?.id
    );

    await translationMap.upsert({
      sourceIdentifier: migrationData.sourceStafferId,
      destinationIdentifier: userRef,
      resourceType: USER_CUSTOM_MAPPING_TYPE,
    });

    return userRef;
  }
}
