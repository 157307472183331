<mat-toolbar color="accent" class="min-h-[50px]">
  {{ data?.task ? 'Edit' : 'Add' }} Task
</mat-toolbar>

<div class="flex flex-col overflow-scroll bg-slate-100">
  <form [formGroup]="form" class="flex flex-col gap-4 p-4">
    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Settings</h3>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Title</mat-label>
        <pr-editor-input cdkFocusInitial formControlName="title" />
      </mat-form-field>
    </div>

    <h2 class="!m-0 !mt-4 font-bold">When do you want this to run?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Timing</h3>

      @if (data?.useRelativeTime) {
        <pr-automation-timing-form
          [value]="form.controls.timing.value"
          (formChange)="timingChange($event)"
        />
      } @else {
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Trigger Date</mat-label>
            <input
              matInput
              formControlName="triggerDate"
              required
              [matDatepicker]="triggerDate"
              (focus)="triggerDate.open()"
              (click)="triggerDate.open()"
            />
            <mat-datepicker-toggle matIconSuffix [for]="triggerDate" />
            <mat-datepicker #triggerDate />
            <button
              mat-icon-button
              matIconSuffix
              aria-label="Clear"
              *ngIf="form.controls.triggerDate.value"
              type="button"
              (click)="clearTriggerDate($event)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <pr-date-selector
            placeholder="Trigger Time"
            [required]="true"
            [interval]="timeInterval"
            formControlName="triggerTime"
            subscriptSizing="dynamic"
          />
        </div>
      }
    </div>

    @if (availableConditions.length) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <div class="flex flex-row items-center gap-2">
          <h3 class="!m-0 !font-bold">Automation Conditions</h3>
          <mat-icon
            class="icon-sm opacity-50"
            [matTooltip]="automationConditionsHelp"
            >info</mat-icon
          >
        </div>
        <pr-conditional-logic-form
          [availableConditions]="availableConditions"
          [defaultCondition]="defaultCondition"
          [conditions]="form.value.requiredConditions"
          (conditionsUpdated)="requiredConditionsChange($event)"
        />
      </div>
    }

    <h2 class="!m-0 !mt-4 font-bold">What do you want to happen?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Task Configuration</h3>
      <div class="flex flex-row">
        <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
          <mat-label>Assignee</mat-label>
          <mat-select formControlName="assignee" [compareWith]="compareFn">
            <mat-optgroup *ngIf="teams$ | async as teams" label="Teams">
              <mat-option
                *ngFor="let team of teams; trackBy: trackByTeam"
                [value]="team"
              >
                {{ team.name }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup *ngIf="staff$ | async as staff" label="Staff">
              <mat-option
                *ngFor="let staffer of staff; trackBy: trackByStaff"
                [value]="staffer"
              >
                {{ staffer.user.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
          <mat-label>Priority</mat-label>
          <mat-select formControlName="priority" required>
            <mat-option
              *ngFor="let priority of taskPriorities; trackBy: trackByPriority"
              [value]="priority"
            >
              {{ priority }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="data?.practices"
          class="flex flex-auto"
          subscriptSizing="dynamic"
        >
          <mat-label>Practice</mat-label>
          <mat-select formControlName="practiceRef">
            <mat-option
              *ngFor="let practice of data?.practices; trackBy: trackByPractice"
              [value]="practice.ref"
            >
              {{ practice.name }}
            </mat-option>
          </mat-select>
          <button
            mat-icon-button
            matIconSuffix
            (click)="
              $event.stopImmediatePropagation();
              form.controls.practiceRef.reset(undefined)
            "
            *ngIf="form.controls.practiceRef.value"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Comments</mat-label>
        <pr-content-editor
          [menuEnabled]="false"
          formControlName="comment"
          placeholder="Comments"
        />
      </mat-form-field>
    </div>

    <pr-automation-treatments *ngIf="taskConfig" [config]="taskConfig" />
  </form>
</div>

<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="save()">
      Save
    </button>
  </pt-buttons-container>
</pt-page-footer>
