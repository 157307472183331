import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  type IMedipassBaseExtendedData,
  isMedipassClaimExtendedData,
  isMedipassPaymentExtendedData,
} from '@principle-theorem/medipass';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { type ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';

@Component({
    selector: 'pr-medipass-extended-data',
    templateUrl: './medipass-extended-data.component.html',
    styleUrls: ['./medipass-extended-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MedipassExtendedDataComponent {
  description?: string;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<IMedipassBaseExtendedData>
  ) {
    this.description = this._getDescription(data.transaction.extendedData);
  }

  private _getDescription(data?: IMedipassBaseExtendedData): string {
    if (!data) {
      return '';
    }
    if (isMedipassClaimExtendedData(data)) {
      return data.claim.statusDescription ?? '';
    }
    if (isMedipassPaymentExtendedData(data)) {
      return data.payment.statusDescription;
    }
    return '';
  }
}
