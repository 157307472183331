import { isObject, type ISoftDelete } from '@principle-theorem/shared';
import { type ChartableSurface } from './core/chartable-surface';
import { type IChartedItemDisplay } from './core/charted-item-display';

export interface IChartedItemConfiguration extends ISoftDelete {
  name: string;
  clinicalNotes: string;
  display: IChartedItemDisplay;
  availableSurfaces: ChartableSurface[];
}

export function isChartedItemConfiguration(
  item: unknown
): item is IChartedItemConfiguration {
  return (
    isObject(item) &&
    'name' in item &&
    typeof item.name === 'string' &&
    'display' in item &&
    'availableSurfaces' in item &&
    Array.isArray(item.availableSurfaces)
  );
}

export interface IRankedChartedItem extends Partial<IChartedItemConfiguration> {
  name: string;
  weight: number;
}

export type HasChartingOptions = Pick<
  IChartedItemConfiguration,
  'display' | 'availableSurfaces'
>;
