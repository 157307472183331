import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  DYNAMIC_COMPONENT_DATA,
  ISidebarComponentData,
} from '@principle-theorem/ng-shared';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  ChecklistType,
  type IAppointment,
  type IAutomation,
  type IChecklistItem,
  type IEvent,
  type ILabJob,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
  TreatmentPlanStatus,
  type TreatmentStepAutomation,
  AppointmentSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  Firestore,
  isPathChanged$,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentAutomationsDialogComponent } from '../scheduling/appointment-automations-dialog/appointment-automations-dialog.component';

export interface IAppointmentSidebarData extends ISidebarComponentData {
  appointment: AppointmentSummary;
}

@Component({
  selector: 'pr-appointment-view-sidebar',
  templateUrl: './appointment-view-sidebar.component.html',
  styleUrls: ['./appointment-view-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentViewSidebarComponent {
  private _global = inject(GlobalStoreService);
  treatmentStatuses = [
    TreatmentPlanStatus.Draft,
    TreatmentPlanStatus.Offered,
    TreatmentPlanStatus.Accepted,
    TreatmentPlanStatus.InProgress,
    TreatmentPlanStatus.Completed,
  ];
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  currentPractitioner$: Observable<WithRef<IStaffer>>;
  treatmentPlan$: Observable<WithRef<ITreatmentPlan>>;
  event$: Observable<IEvent>;
  labJobs$: Observable<WithRef<ILabJob>[]>;
  automations$: Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]>;
  checklistItems$: Observable<IChecklistItem[]>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA) data: IAppointmentSidebarData,
    private _dialog: MatDialog
  ) {
    if (!data.appointment.ref) {
      return;
    }
    this.appointment$ = Firestore.doc$(data.appointment.ref).pipe(
      shareReplayCold()
    );
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient$(appointment)),
      shareReplayCold()
    );
    this.currentPractitioner$ = this.appointment$.pipe(
      switchMap((appointment) =>
        this._global.getStaffer$(appointment.practitioner.ref)
      ),
      filterUndefined()
    );
    this.event$ = this.appointment$.pipe(
      map((appointment) => appointment.event),
      filterUndefined()
    );
    this.treatmentPlan$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentPlan$(appointment))
    );
    this.automations$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentStep(appointment)),
      switchMap((step) => TreatmentStep.automations$(step))
    );
    this.checklistItems$ = this.appointment$.pipe(
      switchMap((appointment) =>
        Appointment.checklistItems$(appointment, ChecklistType.Pre)
      )
    );
    this.labJobs$ = this._getLabJobs$();
  }

  async openAutomations(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const config = DialogPresets.medium({
      data: {
        appointment,
      },
    });
    this._dialog.open(AppointmentAutomationsDialogComponent, config);
  }

  private _getLabJobs$(): Observable<WithRef<ILabJob>[]> {
    return this.appointment$.pipe(
      switchMap((appointment) => Appointment.labJobs$(appointment)),
      shareReplayCold()
    );
  }
}
