import { rand } from '@ngneat/falso';
import {
  IChartedTreatment,
  IFeeSchedule,
  IFollowUp,
  IMultiTreatmentConfiguration,
  IStaffer,
  IStatusHistory,
  ITreatmentConfiguration,
  ITreatmentPlan,
  ITreatmentStep,
  ITreatmentTemplate,
  TREATMENT_PLAN_STATUSES,
  TreatmentConfigurationName,
  TreatmentPlanStatus,
  TreatmentPlanType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  BaseFirestoreMock,
  INamedDocument,
  MockGenerator,
  MockTimestamp,
  Properties,
  WithRef,
  getEnumValues,
  toNamedDocument,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { DocumentReference } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { MockTreatment } from './charted-treatment.mock';
import { MockTreatmentStep } from './treatment-step.mock';
import { MultiTreatmentConfigurationName } from './multi-treatment-configuration-seed-data';
import { FollowUpMock } from '../../appointment/follow-up.mock';
import { TreatmentPlan } from './treatment-plan';

export class TreatmentPlanMock
  extends BaseFirestoreMock
  implements Properties<ITreatmentPlan>
{
  get name(): string {
    return rand(getEnumValues(MultiTreatmentConfigurationName));
  }

  get steps(): DocumentReference<ITreatmentStep>[] {
    return [
      MockDocRef<ITreatmentStep>(),
      MockDocRef<ITreatmentStep>(),
      MockDocRef<ITreatmentStep>(),
    ];
  }

  get practitioner(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get status(): TreatmentPlanStatus {
    return rand(TREATMENT_PLAN_STATUSES);
  }

  get statusHistory(): IStatusHistory<TreatmentPlanStatus>[] {
    return [
      {
        status: TreatmentPlanStatus.Draft,
        updatedAt: MockTimestamp(),
      },
      {
        status: TreatmentPlanStatus.Accepted,
        updatedAt: MockTimestamp(),
      },
      {
        status: TreatmentPlanStatus.InProgress,
        updatedAt: MockTimestamp(),
      },
    ];
  }

  get followUpHistory(): IFollowUp[] {
    return [MockGenerator.generate(FollowUpMock)];
  }

  get type(): TreatmentPlanType {
    return TreatmentPlanType.PractitionerProposed;
  }

  get children(): INamedDocument<ITreatmentPlan>[] {
    return [];
  }

  get parent(): INamedDocument<ITreatmentPlan> {
    return MockNamedDocument<ITreatmentPlan>();
  }

  get config(): DocumentReference<IMultiTreatmentConfiguration> {
    return MockDocRef<IMultiTreatmentConfiguration>();
  }

  get template(): DocumentReference<ITreatmentTemplate> {
    return MockDocRef<ITreatmentTemplate>();
  }

  get feeSchedule(): INamedDocument<IFeeSchedule> {
    return MockNamedDocument<IFeeSchedule>();
  }
}

const DEFAULT_DURATION = 15;

export function MockTreatmentPlan(
  data: AtLeast<ITreatmentPlan, 'practitioner'>
): ITreatmentPlan {
  const plan: ITreatmentPlan = TreatmentPlan.init(data);

  if (data.status) {
    TreatmentPlan.updateStatus(plan, data.status);
  }

  return plan;
}

function MockStatus(): TreatmentPlanStatus {
  return TreatmentPlanStatus.InProgress;
}

export function getMockTreatmentStep(
  name: string,
  treatmentNames: TreatmentConfigurationName[],
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[],
  feeSchedule: INamedDocument<IFeeSchedule>,
  staff: INamedDocument<IStaffer>[]
): ITreatmentStep {
  const filteredConfigs: WithRef<ITreatmentConfiguration>[] = compact(
    treatmentNames.map((treatmentName) =>
      treatmentConfigurations.find(
        (config) => config.name === String(treatmentName)
      )
    )
  );

  const treatments: IChartedTreatment[] = filteredConfigs.map(
    (treatmentConfig) =>
      MockTreatment(
        {
          feeSchedule,
          config: toNamedDocument(treatmentConfig),
        },
        staff,
        treatmentConfig
      )
  );

  let duration: number = filteredConfigs.reduce(
    (total: number, config): number => total + config.duration,
    0
  );

  if (duration === 0) {
    duration = DEFAULT_DURATION;
  }

  return MockTreatmentStep(
    {
      name,
      treatments,
      schedulingRules: {
        duration,
      },
    },
    staff
  );
}

export function MockTreatmentSteps(
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[],
  feeSchedule: INamedDocument<IFeeSchedule>,
  staff: INamedDocument<IStaffer>[],
  planName?: string
): ITreatmentStep[] {
  if (planName && planName === 'Invisalign') {
    return [
      getMockTreatmentStep(
        'Invisalign Stage 1',
        [TreatmentConfigurationName.InvisalignWorkUp],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 2',
        [TreatmentConfigurationName.InvisalignBondingOfAttachments],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 3',
        [TreatmentConfigurationName.InvisalignMidTreatmentReview],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 4',
        [TreatmentConfigurationName.InvisalignMidTreatmentReview],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 5',
        [TreatmentConfigurationName.InvisalignEndOfTreatment],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
    ];
  }
  return rand([
    [
      getMockTreatmentStep(
        'Restorative Stage 1',
        [
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Restorative Stage 2',
        [
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 1',
        [TreatmentConfigurationName.InvisalignWorkUp],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 2',
        [TreatmentConfigurationName.InvisalignBondingOfAttachments],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 3',
        [TreatmentConfigurationName.InvisalignMidTreatmentReview],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 4',
        [TreatmentConfigurationName.InvisalignMidTreatmentReview],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Invisalign Stage 5',
        [TreatmentConfigurationName.InvisalignEndOfTreatment],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 1',
        [TreatmentConfigurationName.ImplantImpression],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 2',
        [TreatmentConfigurationName.ImplantSurgeryFixturePlacement],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 3',
        [TreatmentConfigurationName.ImplantReviewAfterFixturePlacement],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Occlusal Splint Stage 1',
        [TreatmentConfigurationName.SplintImpressionScan],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Occlusal Splint Stage 2',
        [TreatmentConfigurationName.SplintIssue],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
    ],
    [
      getMockTreatmentStep(
        'Restoration Quadrant 1',
        [
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.PeriodicExamAndClean,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Restoration Quadrant 2',
        [
          TreatmentConfigurationName.CerecFullCrown,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.PeriodicExamAndClean,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Restoration Quadrant 3',
        [
          TreatmentConfigurationName.CerecFullCrown,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.PeriodicExamAndClean,
          TreatmentConfigurationName.PeriodicExamAndClean,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Restoration Quadrant 4',
        [
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Front Teeth',
        [
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
          TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
        ],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 1',
        [TreatmentConfigurationName.ImplantImpression],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 2',
        [TreatmentConfigurationName.ImplantSurgeryFixturePlacement],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Implant Stage 3',
        [TreatmentConfigurationName.ImplantReviewAfterFixturePlacement],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Occlusal Splint Stage 1',
        [TreatmentConfigurationName.SplintImpressionScan],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Occlusal Splint Stage 2',
        [TreatmentConfigurationName.SplintIssue],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Whitening Stage 1',
        [TreatmentConfigurationName.TeethWhiteningImpressions],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
      getMockTreatmentStep(
        'Whitening Stage 2',
        [TreatmentConfigurationName.TeethWhiteningIssue],
        treatmentConfigurations,
        feeSchedule,
        staff
      ),
    ],
  ]);
}

export function MockTreatmentPlans(
  practitioner: INamedDocument<IStaffer>
): ITreatmentPlan[] {
  return [
    {
      practitioner,
      status: MockStatus(),
      followUpHistory: [],
      statusHistory: [],
    },
    {
      name: 'Invisalign',
      practitioner,
      status: MockStatus(),
      followUpHistory: [],
      statusHistory: [],
    },
    {
      practitioner,
      status: MockStatus(),
      followUpHistory: [],
      statusHistory: [],
    },
  ].map((treatmentPlan) => MockTreatmentPlan(treatmentPlan));
}
