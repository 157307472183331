import { Injector } from '@angular/core';
import { wrapAngularExtension } from '@principle-theorem/ng-prosemirror';
import { AnyExtension } from '@tiptap/core';
import {
  MentionAutocompleteNodeComponent,
  type IMentionAutocompleteNodeConfig,
} from './mention-autocomplete-node/mention-autocomplete-node.component';
import { createMentionKeymap } from './mention-keymap';

export function createMentionAutocompleteExtension(
  injector: Injector,
  config: IMentionAutocompleteNodeConfig
): AnyExtension {
  return wrapAngularExtension(
    MentionAutocompleteNodeComponent,
    [createMentionKeymap()],
    config
  )(injector);
}
