import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../../core/reporting-core.module';
import { ReportingComponentsModule } from '../../reporting-components/reporting-components.module';
import { AccountCreditsReportTableComponent } from './account-credits-report-table/account-credits-report-table.component';
import { AccountCreditsReportComponent } from './account-credits-report.component';
import { AccountCreditsReportStore } from './account-credits-report.store';

@NgModule({
  imports: [
    RouterModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPatientModule,
  ],
  declarations: [
    AccountCreditsReportComponent,
    AccountCreditsReportTableComponent,
  ],
  exports: [AccountCreditsReportTableComponent],
  providers: [AccountCreditsReportStore],
})
export class AccountCreditsReportModule {}
