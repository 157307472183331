import { TaxStrategy, roundTo2Decimals } from '@principle-theorem/accounting';
import {
  Invoice,
  Transaction,
  TransactionOperators,
  stafferToNamedDoc,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  IServiceCodeLineItem,
  IStaffer,
  ITranslationMap,
  ITreatmentBasePriceLineItem,
  ITreatmentLineItem,
  ITreatmentRef,
  InvoiceLineItemType,
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IBasePatient,
  type ICustomLineItem,
  type IGetRecordResponse,
  type IInvoice,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  asyncForEach,
  getDoc,
  sortByCreatedAt,
  sortTimestampAsc,
  toISODate,
  toNamedDocument,
  type IIdentifiable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, flatten, groupBy, remove, sumBy } from 'lodash';
import { resolveMappedCode } from '../../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../../mappings/item-codes-to-xlsx';
import { TranslationMapHandler } from '../../../../translation-map';
import {
  ExactTransactionType,
  type IExactTransaction,
  type IExactTransactionFilters,
  type IExactTransactionTranslations,
} from '../../../source/entities/patient-transactions';
import {
  type IExactTreatment,
  type IExactTreatmentFilters,
  type IExactTreatmentTranslations,
} from '../../../source/entities/patient-treatments';
import { exactToothConverter } from '../../../util/tooth';
import { resolveExactStaffLocation } from '../../mappings/practitioner-to-practice-mapping';
import { resolveExactStaffer } from '../../mappings/staff';
import { PATIENT_RESOURCE_TYPE } from '../../../../destination/entities/patient';

interface ITransactionByPlanMap {
  [planId: string]: {
    transactions: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[];
    treatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[];
    transactionAmount: number;
    treatmentsAmount: number;
  };
}

interface IPatientAccountSummary {
  nonTreatmentTransactions: IGetRecordResponse<
    IExactTransaction,
    IExactTransactionTranslations,
    IExactTransactionFilters
  >[];
  nonTreatmentCharges: IGetRecordResponse<
    IExactTransaction,
    IExactTransactionTranslations,
    IExactTransactionFilters
  >[];
  treatmentTransactions: ITransactionByPlanMap;
  totalCharged: number;
  totalPaid: number;
  totalOwing: number;
  allPayments: IGetRecordResponse<
    IExactTransaction,
    IExactTransactionTranslations,
    IExactTransactionFilters
  >[];
  allTransactions: (IIdentifiable & ITransaction)[];
  allCharges: IGetRecordResponse<
    IExactTransaction,
    IExactTransactionTranslations,
    IExactTransactionFilters
  >[];
}

export function isExactPayment(transaction: IExactTransaction): boolean {
  const isChargeType =
    transaction.type === ExactTransactionType.Invoice ||
    transaction.type === ExactTransactionType.TransferTo ||
    transaction.type === ExactTransactionType.Procedure ||
    (transaction.type === ExactTransactionType.TransferFrom &&
      transaction.amount > 0);
  return !isChargeType && transaction.amount < 0;
}

interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: (IIdentifiable & ITransaction)[];
}

export class ExactInvoiceBuilder {
  accountSummary: IPatientAccountSummary = {
    nonTreatmentTransactions: [],
    nonTreatmentCharges: [],
    treatmentTransactions: {},
    totalCharged: 0,
    totalPaid: 0,
    totalOwing: 0,
    allPayments: [],
    allTransactions: [],
    allCharges: [],
  };

  constructor(
    public sourceTransactions: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[],
    public patientTreatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    public patientBalance: number,
    public staff: WithRef<ITranslationMap<IStaffer, unknown>>[],
    public sourceItemCodes: WithRef<
      ITranslationMap<object, ItemCodeResourceMapType>
    >[],
    public staffToPractice: WithRef<ITranslationMap<IPractice>>[]
  ) {}

  buildAccountSummary(practiceRef: DocumentReference<IPractice>): void {
    const nonTreatmentTransactions = this.sourceTransactions.filter(
      (transaction) => !transaction.data.data.treatmentId
    );

    const nonTreatmentCharges = nonTreatmentTransactions.filter(
      (transaction) => !isExactPayment(transaction.data.data)
    );

    const allPayments = this.sourceTransactions.filter((transaction) =>
      isExactPayment(transaction.data.data)
    );
    const allTransactions = this.getTransactions(allPayments, practiceRef);
    const allCharges = this.sourceTransactions.filter(
      (transaction) => !isExactPayment(transaction.data.data)
    );

    const treatmentTransactions: ITransactionByPlanMap = {};

    this.sourceTransactions.map((transaction) => {
      const planId = transaction.data.data.treatmentId;
      if (!planId) {
        return;
      }
      const treatments = this.patientTreatments.filter(
        (patientTreatment) =>
          patientTreatment.data.data.treatment_plan_id === planId
      );

      const treatmentsAmount = treatments.reduce(
        (total, treatment) => total + treatment.data.data.total_amount,
        0
      );
      const transactionAmount = transaction.data.data.amount;
      const existingPlan = treatmentTransactions[planId];

      if (existingPlan) {
        existingPlan.transactions = [
          ...(existingPlan.transactions ?? []),
          transaction,
        ];
        const amount = sumBy(
          existingPlan.transactions,
          (record) => record.data.data.amount
        );
        existingPlan.transactionAmount = amount;
      } else {
        treatmentTransactions[planId] = {
          transactions: [transaction],
          treatments,
          transactionAmount,
          treatmentsAmount,
        };
      }
    });

    const totalCharged = this.sourceTransactions
      .filter((transaction) => !isExactPayment(transaction.data.data))
      .reduce((total, transaction) => total + transaction.data.data.amount, 0);
    const totalPaid = this.sourceTransactions
      .filter((transaction) => isExactPayment(transaction.data.data))
      .reduce((total, transaction) => total + transaction.data.data.amount, 0);

    this.accountSummary = {
      totalCharged: roundTo2Decimals(totalCharged),
      totalPaid: roundTo2Decimals(totalPaid),
      totalOwing: roundTo2Decimals(this.patientBalance),
      nonTreatmentTransactions,
      nonTreatmentCharges,
      treatmentTransactions,
      allPayments,
      allCharges,
      allTransactions,
    };
  }

  validateAccountSummary(): void {
    const balance = roundTo2Decimals(
      this.accountSummary.totalCharged - Math.abs(this.accountSummary.totalPaid)
    );
    if (balance !== this.accountSummary.totalOwing) {
      throw new Error(
        `Patient balance mismatch: ${balance} !== ${this.accountSummary.totalOwing}`
      );
    }
  }

  async buildInvoices(
    patient: IBasePatient & IPatientContactDetails,
    translationMap: TranslationMapHandler,
    migrationPractices: INamedDocument<IPractice>[]
  ): Promise<IInvoiceBuildData[]> {
    const treatmentInvoices = await this.buildTreatmentInvoices(
      patient,
      translationMap,
      migrationPractices
    );
    const nonTreatmentInvoices = await this.buildNonTreatmentInvoices(
      patient,
      translationMap,
      migrationPractices
    );
    const invoiceBuildData = [
      ...nonTreatmentInvoices,
      ...treatmentInvoices,
    ].sort((nonTreatmentInvoice, treatmentInvoice) =>
      sortTimestampAsc(
        nonTreatmentInvoice.invoice.createdAt,
        treatmentInvoice.invoice.createdAt
      )
    );
    let transactions = this.accountSummary.allTransactions.sort(
      (transactionA, transactionB) =>
        sortTimestampAsc(transactionA.createdAt, transactionB.createdAt)
    );

    const results: IInvoiceBuildData[] = [];
    invoiceBuildData.map((invoiceData, index) => {
      if (invoiceData.transactions.length) {
        const balance = Invoice.balance(
          invoiceData.invoice,
          invoiceData.transactions
        );
        if (balance <= 0) {
          results.push(invoiceData);
          return;
        }
      }

      const [transactionsForInvoice, remainingTransactions] =
        getTransactionsForBalance(
          transactions,
          Invoice.balance(invoiceData.invoice, invoiceData.transactions)
        );
      transactions = remainingTransactions;

      const isLastInvoice = index === invoiceBuildData.length - 1;
      if (isLastInvoice && transactions.length > 0) {
        transactionsForInvoice.push(...transactions);
        const deposits: ICustomLineItem[] = transactions.map((payment) => ({
          uid: payment.uid,
          type: InvoiceLineItemType.Deposit,
          description: payment.description ?? '',
          amount: payment.amount,
          tax: 0,
          taxStatus: TaxStrategy.GSTApplicable,
          quantity: 1,
        }));
        invoiceData.invoice.items.push(...deposits);
      }

      results.push({
        invoice: invoiceData.invoice,
        transactions: [...invoiceData.transactions, ...transactionsForInvoice],
      });
    });

    return results.map((invoiceData) => {
      const status = this.getInvoiceStatus(
        Invoice.balance(invoiceData.invoice, invoiceData.transactions)
      );
      const lastTransaction = new TransactionOperators(invoiceData.transactions)
        .completed()
        .sort(sortByCreatedAt)
        .reverse()
        .last();

      if (status === InvoiceStatus.Paid && lastTransaction) {
        invoiceData.invoice.paidAt = lastTransaction.createdAt;
      }
      Invoice.updateStatus(
        invoiceData.invoice,
        status,
        invoiceData.invoice.createdAt
      );

      return invoiceData;
    });
  }

  getInvoiceStatus(amountRemaining: number): InvoiceStatus {
    if (amountRemaining <= 0) {
      return InvoiceStatus.Paid;
    }

    if (amountRemaining > 0) {
      return InvoiceStatus.Issued;
    }

    return InvoiceStatus.Draft;
  }

  async buildTreatmentInvoices(
    patient: IBasePatient & IPatientContactDetails,
    translationMap: TranslationMapHandler,
    migrationPractices: INamedDocument<IPractice>[]
  ): Promise<IInvoiceBuildData[]> {
    const treatmentInvoices = await asyncForEach(
      Object.values(this.accountSummary.treatmentTransactions),
      (summary) => {
        const charges = summary.transactions.filter(
          (transaction) => !isExactPayment(transaction.data.data)
        );
        const groupedByDateCharges = groupBy(charges, (charge) =>
          toISODate(charge.data.translations.date)
        );

        return asyncForEach(
          Object.values(groupedByDateCharges),
          async (groupedCharges) => {
            const sourceInvoice = groupedCharges[0];
            const practice = await resolveExactStaffLocation(
              sourceInvoice.data.data.user_code ?? '',
              translationMap,
              this.staffToPractice,
              migrationPractices
            );
            if (!practice) {
              throw new Error(
                `Failed to resolve practice for user: ${sourceInvoice.data.data.user_code}`
              );
            }

            const invoice = Invoice.init({
              due: sourceInvoice.data.translations.date,
              practice: toNamedDocument(practice),
              from: toAccountDetails(practice),
              to: toAccountDetails(patient),
              reference: sourceInvoice.data.data.reference ?? '',
              issuedAt: sourceInvoice.data.translations.date,
              createdAt: sourceInvoice.data.translations.date,
            });

            const treatmentsCompletedOnDate = summary.treatments.filter(
              (treatment) =>
                treatment.data.data.completed_date ===
                sourceInvoice.data.data.date
            );

            if (!treatmentsCompletedOnDate.length) {
              // eslint-disable-next-line no-console
              console.error(
                `No treatments found for sourceInvoice: ${sourceInvoice.data.data.treatmentId} and date: ${sourceInvoice.data.data.date}`
              );
            }

            const nonTreatmentLineItems = remove(
              this.accountSummary.nonTreatmentCharges,
              (transaction) =>
                transaction.data.data.date === sourceInvoice.data.data.date
            );
            const nonTreatmentItems = this.buildNonTreatmentLineItems(
              nonTreatmentLineItems
            );

            const lineItems: ITreatmentLineItem[] = [];
            let transactions: (IIdentifiable & ITransaction)[] = [];

            await asyncForEach(groupedCharges, async (charge) => {
              const adjustmentsForCharge = remove(
                this.accountSummary.allTransactions,
                (transaction) =>
                  transaction.reference === charge.data.data.reference &&
                  transaction.provider === TransactionProvider.Discount
              );
              const paymentsOnDate = remove(
                this.accountSummary.allTransactions,
                (transaction) =>
                  transaction.createdAt.isEqual(charge.data.translations.date)
              );
              transactions.push(...adjustmentsForCharge, ...paymentsOnDate);

              const staffer = await resolveExactStaffer(
                charge.data.data.user_code ?? '',
                translationMap,
                this.staff
              );
              if (!staffer) {
                throw new Error(
                  `Failed to resolve staffer: ${charge.data.data.user_code}`
                );
              }

              const treatmentRef = {
                treatmentUuid: charge.data.data.id,
                attributedTo: stafferToNamedDoc(staffer),
              };

              const filteredTreatments = treatmentsCompletedOnDate.filter(
                (treatment) =>
                  treatment.data.data.provider_code ===
                  charge.data.data.user_code
              );

              lineItems.push({
                uid: charge.data.data.id,
                type: InvoiceLineItemType.Treatment,
                treatmentRef,
                items: [
                  this.getBasePriceItem(charge, treatmentRef),
                  ...this.getServiceCodeItems(filteredTreatments),
                ],
                description: charge.data.data.description,
                amount: charge.data.data.amount,
                tax: 0,
                taxStatus: TaxStrategy.GSTApplicable,
                quantity: 1,
              });
            });

            const isOnBehalfOf = treatmentsCompletedOnDate.find(
              (treatment) =>
                treatment.data.data.patient_id !== patient.referenceId
            );
            if (isOnBehalfOf) {
              const fromRef = await translationMap.getDestination<IPatient>(
                isOnBehalfOf.data.data.patient_id,
                PATIENT_RESOURCE_TYPE
              );

              if (fromRef) {
                const childPatient = await getDoc(fromRef);
                const onBehalfOf = toAccountDetails(
                  childPatient as IBasePatient & IPatientContactDetails
                );
                invoice.to = {
                  ...invoice.to,
                  onBehalfOf,
                };
              }
            }

            const items = [...lineItems, ...nonTreatmentItems];
            const invoiceData = {
              ...invoice,
              items,
              uid: sourceInvoice.data.data.id,
            };
            const balance = Invoice.balance(invoiceData, transactions);
            if (balance < 0) {
              const [transactionsForInvoice, remainingTransactions] =
                getTransactionsForBalance(transactions, balance);
              transactions = transactionsForInvoice;
              this.accountSummary.allTransactions.push(
                ...remainingTransactions
              );
            }
            return {
              invoice: {
                ...invoice,
                items,
                uid: sourceInvoice.data.data.id,
              },
              transactions,
            };
          }
        );
      }
    );

    return flatten(treatmentInvoices);
  }

  getBasePriceItem(
    charge: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >,
    treatmentRef: ITreatmentRef
  ): ITreatmentBasePriceLineItem {
    return {
      uid: charge.data.data.id,
      type: InvoiceLineItemType.TreatmentBasePrice,
      treatmentRef,
      description: 'Base Price',
      amount: charge.data.data.amount,
      tax: 0,
      taxStatus: TaxStrategy.GSTApplicable,
      quantity: 1,
    };
  }

  getServiceCodeItems(
    treatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[]
  ): IServiceCodeLineItem[] {
    const items = treatments.map((treatment) => {
      const code = resolveMappedCode(
        this.sourceItemCodes,
        treatment.data.data.service_code,
        treatment.data.data.service_code
      );
      if (!code) {
        return;
      }
      return {
        uid: treatment.data.data.treatment_id,
        type: InvoiceLineItemType.ServiceCode,
        code: code.code.toString(),
        toothId: treatment.data.data.tooth
          ? exactToothConverter(treatment.data.data.tooth)
          : undefined,
        description: `${code.code.toString()} -  ${
          treatment.data.data.service_description
        }`,
        amount: 0,
        tax: 0,
        taxStatus: TaxStrategy.GSTApplicable,
        quantity: 1,
      };
    });
    return compact(items);
  }

  async buildNonTreatmentInvoices(
    patient: IBasePatient & IPatientContactDetails,
    translationMap: TranslationMapHandler,
    migrationPractices: INamedDocument<IPractice>[]
  ): Promise<IInvoiceBuildData[]> {
    const charges = this.accountSummary.nonTreatmentCharges;
    if (!charges.length) {
      return [];
    }

    const groupedByDateCharges = groupBy(charges, (charge) =>
      toISODate(charge.data.translations.date)
    );
    return asyncForEach(
      Object.values(groupedByDateCharges),
      async (groupedCharges) => {
        const sourceInvoice = groupedCharges[0];
        const practice = await resolveExactStaffLocation(
          sourceInvoice.data.data.user_code ?? '',
          translationMap,
          this.staffToPractice,
          migrationPractices
        );
        if (!practice) {
          throw new Error(
            `Failed to resolve practice for user: ${sourceInvoice.data.data.user_code}`
          );
        }

        const invoice = Invoice.init({
          due: sourceInvoice.data.translations.date,
          practice: toNamedDocument(practice),
          from: toAccountDetails(practice),
          to: toAccountDetails(patient),
          reference: sourceInvoice.data.data.reference ?? '',
          issuedAt: sourceInvoice.data.translations.date,
          createdAt: sourceInvoice.data.translations.date,
        });

        let transactions: (IIdentifiable & ITransaction)[] = [];
        groupedCharges.map((charge) => {
          const adjustmentsForCharge = remove(
            this.accountSummary.allTransactions,
            (transaction) =>
              transaction.reference === charge.data.data.reference &&
              transaction.provider === TransactionProvider.Discount
          );
          const paymentsOnDate = remove(
            this.accountSummary.allTransactions,
            (transaction) =>
              transaction.createdAt.isEqual(charge.data.translations.date)
          );
          transactions.push(...adjustmentsForCharge, ...paymentsOnDate);
        });

        const items = this.buildNonTreatmentLineItems(groupedCharges);
        const invoiceData = {
          ...invoice,
          items,
          uid: sourceInvoice.data.data.id,
        };
        const balance = Invoice.balance(invoiceData, transactions);
        if (balance < 0) {
          const [transactionsForInvoice, remainingTransactions] =
            getTransactionsForBalance(transactions, balance);
          transactions = transactionsForInvoice;
          this.accountSummary.allTransactions.push(...remainingTransactions);
        }
        return {
          invoice: {
            ...invoice,
            items: items,
            uid: sourceInvoice.data.data.id,
          },
          transactions,
        };
      }
    );
  }

  getTransactions(
    sourcePayments: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[],
    practiceRef: DocumentReference<IPractice>
  ): (IIdentifiable & ITransaction)[] {
    return sourcePayments.map((sourcePayment) => {
      const payment = sourcePayment.data.data;
      const type =
        payment.amount < 0
          ? TransactionType.Incoming
          : TransactionType.Outgoing;
      const uid = compact([
        payment.id,
        payment.reference ?? payment.patient_id,
      ]).join('-');
      const createdAt = sourcePayment.data.translations.date;
      const provider = determineTransactionProvider(payment);

      const transaction = {
        reference: payment.reference ?? uid,
        type,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(payment.amount),
        extendedData: payment,
        createdAt,
        practiceRef,
        description: payment.description,
      };

      return {
        ...Transaction.init({
          ...Transaction.internalReference(provider, uid),
          ...transaction,
        }),
      };
    });
  }

  buildNonTreatmentLineItems(
    charges: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[]
  ): ICustomLineItem[] {
    return charges.map((transaction) => ({
      uid: transaction.data.data.id,
      type: InvoiceLineItemType.Fee,
      description: transaction.data.data.description,
      amount: transaction.data.data.amount,
      tax: 0,
      taxStatus: TaxStrategy.GSTApplicable,
      quantity: 1,
    }));
  }
}

function determineTransactionProvider(
  transaction: IExactTransaction
): TransactionProvider {
  if (
    transaction.type === ExactTransactionType.Adjustment ||
    transaction.type === ExactTransactionType.Invoice ||
    transaction.type === ExactTransactionType.WriteOff
  ) {
    return TransactionProvider.Discount;
  }

  if (transaction.paymentType?.includes(TransactionProvider.Cash)) {
    return TransactionProvider.Cash;
  }

  return TransactionProvider.Manual;
}

export function getTransactionsForBalance(
  transactions: (IIdentifiable & ITransaction)[],
  balance: number
): [(IIdentifiable & ITransaction)[], (IIdentifiable & ITransaction)[]] {
  const result: (IIdentifiable & ITransaction)[] = [];
  const additionalTransactions: (IIdentifiable & ITransaction)[] = [];
  let remainingBalance = balance;
  let finalIndex = 0;

  if (balance <= 0) {
    return [result, transactions];
  }

  transactions.map((transaction, index) => {
    if (remainingBalance <= 0) {
      return;
    }

    const transactionAmount = transaction.amount;
    if (transactionAmount < remainingBalance) {
      result.push(transaction);
      remainingBalance -= transactionAmount;
      finalIndex = index;
      return;
    }
    if (transactionAmount > remainingBalance) {
      const balanceTransactions = splitTransaction(
        transaction,
        remainingBalance
      );
      result.push(...compact([first(balanceTransactions)]));
      finalIndex = index;
      additionalTransactions.push(...balanceTransactions.slice(1));
      remainingBalance = 0;
      return;
    }
    if (transactionAmount === remainingBalance) {
      result.push(transaction);
      remainingBalance = 0;
      finalIndex = index;
      return;
    }
  });
  transactions.splice(0, finalIndex + 1, ...additionalTransactions);
  return [result, transactions];
}

export function splitTransaction(
  transaction: IIdentifiable & ITransaction,
  requiredAmount: number
): (IIdentifiable & ITransaction)[] {
  const transactions: (IIdentifiable & ITransaction)[] = [];
  const transactionRemainder = transaction.amount - requiredAmount;

  if (transactionRemainder > 0) {
    transactions.push({
      ...transaction,
      amount: requiredAmount,
      reference: `${transaction.reference}-${transactions.length + 1}`,
      uid: `${transaction.uid}-${transactions.length + 1}`,
    });
    transactions.push({
      ...transaction,
      amount: transactionRemainder,
      reference: `${transaction.reference}-${transactions.length + 1}`,
      uid: `${transaction.uid}-${transactions.length + 1}`,
    });
    return transactions;
  }

  return [transaction];
}
