<pt-breadcrumbs [breadcrumbs]="breadcrumbs" class="bg-white" />
<div class="border-b border-solid border-slate-300 bg-white"></div>

<div class="mx-auto max-w-[1000px] p-4">
  <div
    class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
  >
    @for (group of settingsGroups(); track group.label) {
      <div
        class="flex flex-col gap-0.5 rounded-lg border border-solid border-slate-200 bg-white p-4"
      >
        <div class="m-0 mb-2 text-xl font-bold">{{ group.label }}</div>
        @for (navLink of group.children; track navLink.label) {
          <a [routerLink]="navLink.path" class="text-sm">
            {{ navLink.label }}
          </a>
        }
      </div>
    }
  </div>
</div>
