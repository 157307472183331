import { randFloat, randNumber } from '@ngneat/falso';
import { type IReferrerStats } from '@principle-theorem/reporting';
import * as moment from 'moment-timezone';
import { ReferrerStatistics } from './referrer-statistics';
import { StatisticsMocker } from './statistics-mocker';

export class ReferrerStatisticsMocker extends StatisticsMocker<
  IReferrerStats,
  ReferrerStatistics
> {
  constructor() {
    super(['Organic Search', 'Paid Search', 'Direct', 'Referred', 'Social']);
  }

  mockStatistic(name: string, date: moment.Moment): ReferrerStatistics {
    const metrics: ReferrerStatistics = new ReferrerStatistics();
    metrics.date = moment(date);
    metrics.name = name;
    this._mockStatistics(metrics.stats);
    return metrics;
  }

  private _mockStatistics(stats: IReferrerStats): void {
    const newPatients: number = randNumber({ min: 4, max: 8 });
    const activePatients: number = randNumber({ min: 15, max: 30 });
    const hoursAvailable = 8;
    const timeUsed: number = randNumber({
      min: hoursAvailable / 2,
      max: hoursAvailable,
    });
    const hourlyRate: number = randNumber({ min: 300, max: 1200 });
    const totalRevenue: number = timeUsed * hourlyRate;
    const advertisingCosts: number = randNumber({ min: 20, max: 200 });
    const caseAcceptanceRate: number = randFloat({
      min: 0.15,
      max: 0.9,
      fraction: 2,
    });
    const costPerConversion: number = advertisingCosts / activePatients;
    const meanTreatmentPlanValue: number = totalRevenue / activePatients;
    const cancellations: number = randNumber({ max: 1 });
    const noNextAppointment: number = randNumber({ max: 2 });
    const nextVisitsBooked: number =
      newPatients - cancellations - noNextAppointment;

    stats.newPatients.metric = newPatients;
    stats.activePatients.metric = activePatients;
    stats.inactivePatients.metric = randNumber({ max: 2 });
    stats.caseAcceptanceRate.metric = caseAcceptanceRate;
    stats.totalRevenue.metric = totalRevenue;
    stats.cancellations.metric = cancellations;
    stats.nextVisitsBooked.metric = nextVisitsBooked;
    stats.noNextAppointment.metric = noNextAppointment;
    stats.advertisingCosts.metric = advertisingCosts;
    stats.costPerConversion.metric = costPerConversion;
    stats.meanTreatmentPlanValue.metric = meanTreatmentPlanValue;
  }
}
