import {
  DynamicFormType,
  IDynamicFormNumber,
  IDynamicFormSelect,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';
import { isEqual } from 'lodash';

export class DynamicForm {
  static select<T>(
    overrides: AtLeast<IDynamicFormSelect<T>, 'label' | 'options'>
  ): IDynamicFormSelect<T> {
    return {
      type: DynamicFormType.Select,
      compareWith: isEqual,
      isMultiple: false,
      ...overrides,
    };
  }

  static number<T>(
    overrides: AtLeast<IDynamicFormNumber<T>, 'label'>
  ): IDynamicFormNumber<T> {
    return {
      type: DynamicFormType.Number,
      isMultiple: false,
      ...overrides,
    };
  }
}
