import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MixedSchema, getSchemaSize } from '@principle-theorem/editor';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { PipesModule } from '@principle-theorem/ng-shared';
import {
  IResolvedTemplateWithName,
  ITemplateDefinitionWithName,
  ITemplateVariant,
  SystemTemplate,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';

@Component({
    selector: 'pr-system-template-selectors',
    templateUrl: './system-template-selectors.component.html',
    styleUrl: './system-template-selectors.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, PipesModule, ReactiveFormsModule, NgMaterialModule]
})
export class SystemTemplateSelectorsComponent
  implements AfterViewInit, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  templateList = signal<ITemplateDefinitionWithName[]>([]);
  systemTemplates = SystemTemplate;

  @ViewChildren('selectRef') selectedTemplates: QueryList<MatSelect>;
  @Output() templateChange = new EventEmitter<IResolvedTemplateWithName[]>();

  @Input()
  set templates(templates: ITemplateDefinitionWithName[]) {
    if (templates) {
      this.templateList.set(templates);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.templateList().forEach(({ template, templateDoc }, index) => {
      if (this.templateHasContent(templateDoc.content)) {
        this.selectedTemplates.toArray()[index].value = {
          template,
          content: templateDoc.content,
        };
      }
    });
    this.templateChanges();
  }

  templateChanges(): void {
    this.templateChange.emit(
      this.selectedTemplates.map(
        (select) => select.value as IResolvedTemplateWithName
      )
    );
  }

  getTemplateName(template: SystemTemplate): string {
    return template.replace('print', '');
  }

  getVariantName(variant: ITemplateVariant, index: number): string {
    return variant.name?.length ? variant.name : `Option ${index + 1}`;
  }

  getTemplateHint(template: SystemTemplate | undefined): string | undefined {
    switch (template) {
      case SystemTemplate.PrintHeader:
        return 'at the top of every page';
      case SystemTemplate.PrintFooter:
        return 'at the bottom of every page';
      case SystemTemplate.PrintTreatmentPlanHeader:
      case SystemTemplate.PrintInvoiceHeader:
        return 'once at the top of the first page';
      case SystemTemplate.PrintTreatmentPlanFooter:
      case SystemTemplate.PrintInvoiceFooter:
        return 'once at the bottom of the last page';
      default:
        return undefined;
    }
  }

  templateHasContent(content: MixedSchema): boolean {
    return !!getSchemaSize(content);
  }

  compareFn(
    a: ITemplateDefinitionWithName,
    b: ITemplateDefinitionWithName
  ): boolean {
    return a.template === b.template;
  }
}
