import {
  type IPractice,
  type IStaffer,
  isTreatmentPlan,
  isTreatmentPlanPairFromTemplate,
  isTreatmentPlanWithBookableStep,
  type ITreatmentPlan,
  type ITreatmentPlanPairFromTemplate,
  type ITreatmentPlanWithBookableStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isINamedDocument,
  isWithRef,
  TypeGuard,
  type WithRef,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import {
  ANY_OPTION,
  type IDefaultOption,
  isDefaultOption,
} from './default-option';

export type TreatmentPlanStepPair =
  | ITreatmentPlanWithBookableStep
  | ITreatmentPlanPairFromTemplate;

export interface IAppointmentDetails {
  practice?: WithRef<IPractice>;
  practitioner: INamedDocument<IStaffer> | IDefaultOption;
  treatment?: TreatmentPlanStepPair;
  duration?: number;
  overridePlan?: WithRef<ITreatmentPlan>;
}

export type CreateAppointmentDetails = IAppointmentDetails &
  Required<Omit<IAppointmentDetails, 'overridePlan'>>;

export function initAppointmentDetails(
  overrides?: Partial<IAppointmentDetails>
): IAppointmentDetails {
  return {
    practitioner: ANY_OPTION,
    ...overrides,
  };
}

export function isTreatmentOption(
  data: unknown
): data is TreatmentPlanStepPair {
  return (
    isTreatmentPlanWithBookableStep(data) ||
    isTreatmentPlanPairFromTemplate(data)
  );
}

export function isRequiredAppointmentDetails(
  details: unknown
): details is CreateAppointmentDetails {
  return TypeGuard.interface<CreateAppointmentDetails>({
    practitioner: TypeGuard.isOneOf(isINamedDocument, isDefaultOption),
    practice: isWithRef,
    treatment: isTreatmentOption,
    duration: isNumber,
    overridePlan: TypeGuard.nilOr(
      TypeGuard.undefinedOr(TypeGuard.merge(isWithRef, isTreatmentPlan))
    ),
  })(details);
}
