<ng-container *ngIf="item$ | async as item">
  <mat-list-item
    class="mat-mdc-list-item-interactive lab-job-list-item"
    [ngClass]="{
      completed: isComplete$ | async,
      selected: item === selectedItem
    }"
    (click)="selectItem()"
  >
    <div
      matListItemAvatar
      class="checkbox"
      (click)="$event.stopPropagation()"
      *ngIf="selectEnabled$ | async"
    >
      <mat-checkbox
        color="primary"
        [checked]="
          item | map: selectionList.isSelected$ : selectionList | async
        "
        (change)="selectionList.toggleSelected(item)"
      />
    </div>

    <div matListItemTitle>
      <pr-content-text class="title truncate" [content]="item.title" />
    </div>

    <div matListItemLine>
      <pr-content-text
        *ngIf="lastInteractionSummary$ | async as lastInteractionSummary"
        [content]="lastInteractionSummary"
        class="target truncate"
      />
    </div>

    <div matListItemMeta>
      <div fxFlex fxLayout="row" fxLayoutAlign="end center">
        <div *ngIf="hasConflict$ | async" matTooltip="Overdue for Appointment">
          <button mat-icon-button>
            <mat-icon color="warn">warning</mat-icon>
          </button>
        </div>

        <div fxLayout="column" fxLayoutAlign="center end">
          <span
            *ngIf="item.dueDate"
            class="due-date"
            [ngClass]="{ overdue: isLate$ | async }"
            >{{
              item.dueDate | moment | amCalendar: dateService.format.calendar
            }}</span
          >
        </div>

        <pr-lab-job-status-workflow [labJob]="item" />

        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteLabJob(item)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-list-item>
</ng-container>
