import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChartFacade,
  TreatmentPlanFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  AssociatedPlanCreator,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../../chartable-surface-updater';
import { AddTreatmentToStoreStepProvider } from '../../../charted-surface/store-treatment-plan/add-treatment-to-store-step-provider';
import { StepDragDropGroup } from '../../../step-drag-drop-group';

@Component({
  selector: 'pr-treatment-plan-editor',
  templateUrl: './treatment-plan-editor.component.html',
  styleUrls: ['./treatment-plan-editor.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanEditorComponent {
  treatmentPlan$: ReplaySubject<WithRef<ITreatmentPlan>> = new ReplaySubject(1);
  treatmentSteps$: Observable<WithRef<ITreatmentStep>[]>;

  @Input() stepDragDrop = new StepDragDropGroup();
  @Input() disabled = false;

  @Input()
  set plan(plan: WithRef<ITreatmentPlan>) {
    if (plan) {
      this.treatmentPlan$.next(plan);
    }
  }

  constructor(
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _chartStore: ChartFacade
  ) {
    this.treatmentSteps$ = this.treatmentPlan$.pipe(
      switchMap((plan) =>
        this._treatmentPlanFacade
          .getTreatmentSteps$(plan.ref)
          .pipe(switchMap((steps) => TreatmentPlan.orderSteps$(plan, steps)))
      ),
      filterUndefined()
    );
  }

  async deleteStep(step: WithRef<ITreatmentStep>): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    this._treatmentPlanFacade.removeStep(plan, step);
  }

  async addStep(): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    await this._treatmentPlanFacade.addStep(plan);
  }

  updateStep(step: WithRef<ITreatmentStep>): void {
    this._treatmentStepsFacade.updateTreatmentStep(step.ref.id, step);
  }

  async generateChildPlan(): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    const patient = await snapshot(TreatmentPlan.patient$(plan));
    const associatedPlanCreator = new AssociatedPlanCreator(patient);
    await associatedPlanCreator.getPlanFromChildren(plan);
  }

  async updateChartable(
    data: IEditChartableData & { step: WithRef<ITreatmentStep> }
  ): Promise<void> {
    const { step, ...withoutStep } = data;
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(withoutStep, [
      new AddTreatmentToStoreStepProvider(
        this._treatmentStepsFacade,
        step,
        feeSchedule
      ),
    ]);
  }
}
