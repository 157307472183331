import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { BridgeDeviceSelectorDialogComponent } from './bridge-device-selector-dialog/bridge-device-selector-dialog.component';
import { BridgeDeviceStatusComponent } from './bridge-device-status/bridge-device-status.component';
import { IntegrationsModule } from './integrations/integrations.module';
import { BridgeDeviceSelectorService } from './bridge-device-selector-dialog/bridge-device-selector.service';

@NgModule({
  imports: [
    NgSharedModule,
    ReactiveFormsModule,
    IntegrationsModule,
    NgPrincipleSharedModule,
  ],
  declarations: [
    BridgeDeviceStatusComponent,
    BridgeDeviceSelectorDialogComponent,
  ],
  exports: [
    BridgeDeviceStatusComponent,
    BridgeDeviceSelectorDialogComponent,
    IntegrationsModule,
  ],
  providers: [BridgeDeviceSelectorService],
})
export class ComponentsModule {}
