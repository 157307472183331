<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="triggerDate"
  matSortDirection="desc"
  fxFlex
  recycleRows
  [trackBy]="trackByData"
  *ngIf="layout === 'table'; else listView"
>
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let data">
      <pr-automation-icon [automation]="data.automation.data" />
    </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let data">
      <div
        *ngIf="multiSelect"
        class="checkbox"
        (click)="$event.stopPropagation()"
      >
        <mat-checkbox
          color="primary"
          [checked]="data.isSelected"
          (change)="toggle($event, data.automation)"
        />
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let data">
      <pr-automation-status [status]="data.automation.status" />
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let data">
      {{ data.automation.type | map: getTypeLabel }}
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let data">
      <pr-content-text [content]="data.automation | map: getTitle" />
    </td>
  </ng-container>

  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef>Patient</th>
    <td mat-cell *matCellDef="let data">
      <ng-container *ngIf="data.automation.patientMention">
        <pr-content-view [content]="data.automation.patientMention" />
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="appointment">
    <th mat-header-cell *matHeaderCellDef>Appointment</th>
    <td mat-cell *matCellDef="let data">
      <span *ngIf="data.automation.appointmentSummary">
        {{ data.automation.appointmentSummary }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef>Created By</th>
    <td mat-cell *matCellDef="let data" class="created-by">
      {{ data.automation.creator.name }}
      <mat-icon
        [matTooltip]="data.automation.creator.type | splitCamel | titlecase"
        >info</mat-icon
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
    <td mat-cell *matCellDef="let data">
      {{ data.automation.createdAt | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="triggerDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trigger Date</th>
    <td mat-cell *matCellDef="let data">
      <span *ngIf="data.automation.triggerDate; else noDate">
        <ng-container
          *ngIf="
            data.automation.triggerDate
              | momentTimezone$: data.automation.practiceRef
              | ngrxPush as triggerDate
          "
        >
          {{ triggerDate | amDateFormat: dateFormat }}
        </ng-container>
      </span>
      <ng-template #noDate>No Trigger Date</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="more">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let data">
      <pr-automation-list-item-menu
        [automation]="data.automation"
        (editAutomation)="editAutomation.next($event)"
        (cancelAutomation)="cancelAutomation.next($event)"
        (restoreAutomation)="restoreAutomation.next($event)"
        (resendAutomation)="resendAutomation.next($event)"
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<ng-template #listView>
  <ng-container *ngIf="listData$ | async as listData">
    <div
      *ngIf="listData.length; else noAutomations"
      class="flex flex-col gap-2"
    >
      <pr-automation-list-item
        *ngFor="let data of listData; trackBy: trackByData"
        [automation]="data.automation"
        [withCheckbox]="multiSelect"
        [checked]="data.isSelected"
        [newDate]="data.newDate"
        (checkedChange)="toggle($event, data.automation)"
      >
        <pr-automation-list-item-menu
          [automation]="data.automation"
          (editAutomation)="editAutomation.next($event)"
          (cancelAutomation)="cancelAutomation.next($event)"
          (restoreAutomation)="restoreAutomation.next($event)"
          (resendAutomation)="resendAutomation.next($event)"
        />
      </pr-automation-list-item>
    </div>
    <ng-template #noAutomations>
      <div fxLayoutAlign="center stretch">
        <small class="layout-margin">No Automations</small>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
