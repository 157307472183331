<div class="my-4 flex flex-col gap-4">
  <pr-tyro-configuration [practice]="practice$ | async" />

  <div>
    <h3 class="!m-0 !font-bold">Terminals</h3>
    <pr-practice-tyro-terminals [practice]="practice$ | async" />
  </div>

  <div>
    <button mat-stroked-button (click)="openTyroLogs()">View Logs</button>
  </div>
</div>
