import { Component, EventEmitter, Input, Output } from '@angular/core';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAsset,
  type IAssetRequirement,
} from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-treatment-asset-list',
    templateUrl: './treatment-asset-list.component.html',
    styleUrls: ['./treatment-asset-list.component.scss'],
    standalone: false
})
export class TreatmentAssetListComponent {
  trackByRequirement =
    TrackByFunctions.nestedField<IAssetRequirement>('asset.id');
  @Input() title = '';
  @Input() treatmentConfig: WithRef<ITreatmentConfiguration>;
  @Input() requirements: IAssetRequirement[] = [];
  @Input() assets: WithRef<IAsset>[] = [];
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  findAsset(doc: DocumentReference): WithRef<IAsset> | undefined {
    return this.assets.find((asset) => asset.ref.path === doc.path);
  }

  handleSave(): void {
    this.save.emit();
  }
}
