<ng-container *ngrxLet="summaries$ as summaries">
  <ng-container *ngIf="!disabled; else basicView">
    <ng-container *prHasPermission="manageTreatmentPlan; else basicView">
      <ng-container *ngIf="summaries.length; else setSurfaces">
        <ng-container *ngIf="canEditSurfaces$ | async; else wholeMouthOnly">
          <span
            class="summary mat-caption link"
            matTooltip="Add/Change Surfaces"
            matTooltipShowDelay="500"
            (click)="editSurfaces.emit()"
            *ngFor="
              let summary of summaries;
              let lastSummary = last;
              trackBy: trackBySummary
            "
          >
            {{ summary | splitCamel }}
            <ng-container *ngIf="!lastSummary">, </ng-container>
          </span>
        </ng-container>
        <ng-template #wholeMouthOnly>
          <span class="summary mat-caption">Whole Mouth</span>
        </ng-template>
      </ng-container>
      <ng-template #setSurfaces>
        <span
          *ngIf="canSetSurfaces$ | async"
          class="summary mat-caption link"
          (click)="editSurfaces.emit()"
        >
          Set Surfaces
        </span>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #basicView>
    <span
      class="summary mat-caption"
      *ngFor="
        let summary of summaries;
        let lastSummary = last;
        trackBy: trackBySummary
      "
    >
      {{ summary | splitCamel }}
      <ng-container *ngIf="!lastSummary">, </ng-container>
    </span>
  </ng-template>
</ng-container>
