<pt-buttons-container>
  <button mat-flat-button color="primary" (click)="addTask()">Add Task</button>
  <button mat-flat-button color="primary" (click)="addNotification()">
    Add Notification
  </button>
</pt-buttons-container>
<ng-container *ngIf="automationGroups$ | async as automationGroups">
  <ng-container
    *ngIf="
      automationGroups.notifications.length || automationGroups.tasks.length;
      else noAutomations
    "
  >
    <ng-container *ngIf="automationGroups.tasks.length">
      <h3>Tasks</h3>
      <ng-container
        [ngTemplateOutlet]="automationListTemplate"
        [ngTemplateOutletContext]="{ automations: automationGroups.tasks }"
      />
    </ng-container>
    <ng-container *ngIf="automationGroups.notifications.length">
      <h3>Notifications</h3>
      <ng-container
        [ngTemplateOutlet]="automationListTemplate"
        [ngTemplateOutletContext]="{
          automations: automationGroups.notifications
        }"
      />
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #automationListTemplate let-automations="automations">
  <pr-automation-reschedule-list
    [automations]="automations"
    [patient]="patient$ | async"
    (editAutomation)="edit($event)"
    (cancelAutomation)="cancel($event)"
    (setAutomationPending)="setPending($event)"
  />
</ng-template>

<ng-template #noAutomations>
  <div fxLayoutAlign="center stretch">
    <h2 class="no-automations mat-headline-5">No Automations</h2>
  </div>
</ng-template>
