import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IMatSelectGroupOptions,
  TrackByFunctions,
  matSelectGroupOptions,
} from '@principle-theorem/ng-shared';
import {
  ChecklistType,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { sortBy } from 'lodash';
import { ReplaySubject } from 'rxjs';

const typeLabelMap: Record<ChecklistType, string> = {
  [ChecklistType.Pre]: 'Pre-appointment',
  [ChecklistType.Post]: 'Post-appointment',
};

@Component({
    selector: 'pr-appointment-checklist-items',
    templateUrl: './appointment-checklist-items.component.html',
    styleUrls: ['./appointment-checklist-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentChecklistItemsComponent {
  trackByItem = TrackByFunctions.title<IChecklistItem>();
  checklistItems$ = new ReplaySubject<
    IMatSelectGroupOptions<string, IChecklistItem> | undefined
  >(1);

  @Input()
  set checklistItems(checklistItems: IChecklistItem[]) {
    if (!checklistItems?.length) {
      return;
    }

    const options = Object.entries(typeLabelMap)
      .map(([key, label]) => ({
        group: label,
        items: sortBy(
          checklistItems
            .filter(
              (checklistItem) => String(checklistItem.type) === String(key)
            )
            .map((checklistItem) => ({
              label: checklistItem.title,
              value: checklistItem,
            })),
          'label'
        ),
      }))
      .filter((option) => option.items.length > 0);
    this.checklistItems$.next(matSelectGroupOptions(options));
  }

  getTypeLabel(checklistType: ChecklistType): string {
    return typeLabelMap[checklistType];
  }
}
