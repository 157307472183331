<div fxLayout="row" fxLayoutGap="16px">
  <pt-search-field icon="none" fxFlex>
    <input matInput [formControl]="searchCtrl" placeholder="Filter" #search />
  </pt-search-field>
  <button mat-raised-button color="primary" (click)="add()">Create Tag</button>
</div>

<pr-empty-state
  *ngIf="emptyState$ | async"
  image="list"
  title="tags"
 />

<div class="tag-list">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading$ | async"
   />
  <ng-container *ngIf="tagSearch.results$ | async as tags">
    <ng-container *ngIf="tags.length">
      <pr-tag-list-item
        *ngFor="let tag of tags; trackBy: trackByTag"
        [tag]="tag"
       />
    </ng-container>
  </ng-container>
  <pr-empty-state
    *ngIf="emptySearch$ | async"
    image="search"
    title="search results"
    [searchInputValue]="search.value"
   />
</div>
