<mat-toolbar color="accent">
  <h2>Select Columns</h2>
</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <pr-report-builder-data-point-selector
      subscriptSizing="dynamic"
      label="Add a column"
      [bloc]="bloc"
      (dataPointSelected)="addColumn($event)"
    />

    <div
      class="drag-boundary flex flex-col overflow-scroll rounded border border-solid border-slate-200"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      @for (column of columns; track column.definition.id) {
        <div
          class="flex flex-row items-center gap-2 p-2"
          cdkDrag
          cdkDragLockAxis="y"
          cdkDragBoundary=".drag-boundary"
        >
          <mat-icon cdkDragHandle class="cursor-move">drag_handle</mat-icon>
          <div class="flex grow flex-col leading-tight">
            <div class="flex flex-row items-end gap-1">
              <pr-report-builder-resolved-column-label
                class="block"
                [column]="column"
              />
              @if (column.property.metadata.summary) {
                <mat-icon
                  class="icon-sm opacity-50"
                  [matTooltip]="column.property.metadata.summary"
                  matTooltipPosition="right"
                  matTooltipShowDelay="300"
                >
                  info_outline
                </mat-icon>
              }
            </div>
            <div class="text-xs opacity-50">
              Format:
              {{
                column.property.metadata.formatter ?? 'Text'
                  | splitCamel
                  | titlecase
              }}
            </div>
          </div>
          <div>
            <button mat-flat-button color="warn" (click)="removeColumn(column)">
              Remove
            </button>
          </div>
        </div>
      }
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
