<ng-container *ngIf="auth.authUser$ | async as authUser; else firebaseui">
  <div fxLayout="column" fxLayoutAlign="start center">
    <h1 class="mat-headline-4">Welcome {{ authUser?.displayName }}!</h1>

    <ng-container *ngIf="userIsVerified$ | async; else verifyEmail">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
        <pt-workspace-selector [workspaceSelectors]="workspaceSelectors" />
        <button mat-stroked-button (click)="auth.logout()">Logout</button>
      </div>
    </ng-container>

    <ng-template #verifyEmail>
      <ngx-auth-firebaseui-email-confirmation
        class="layout-margin"
        signOutText="Sign Out"
        verifyEmailGoBackText="Go Back"
        [email]="authUser?.email"
        (signOut)="auth.logout()"
      />
    </ng-template>
  </div>
</ng-container>

<ng-template #firebaseui>
  <ngx-auth-firebaseui
    class="flat"
    appearance="outline"
    signInCardTitleText="Sign in"
    resetPasswordTabText="Enter email to reset password"
    resetPasswordErrorRequiredText="Please enter your e-mail address"
    verifyEmailGoBackText="Go Back"
    signOutText="Sign Out"
    [providers]="[]"
    [guestEnabled]="false"
    [registrationEnabled]="true"
    (onSuccess)="onSuccess()"
    (onError)="onError($event)"
    (selectedTabChange)="updateSelectedTab($event)"
  />

  <div
    *ngIf="tabIndex < 2"
    class="mt-4"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <div *ngFor="let provider of providers; trackBy: trackByProvider">
      <div class="flex items-center justify-center gap-2">
        <ng-container *ngIf="tabIndex === 0">
          <span>Sign in with</span>
        </ng-container>
        <ng-container *ngIf="tabIndex === 1">
          <span>Register with</span>
        </ng-container>
        <ngx-auth-firebaseui-providers
          [providers]="[provider]"
          [theme]="providersTheme"
          [layout]="providersLayout"
          (onSuccess)="onSuccess()"
          (onError)="onError($event)"
        />
      </div>
    </div>
  </div>
</ng-template>
