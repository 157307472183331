import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  CurrentScopeFacade,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef, firstResult$ } from '@principle-theorem/shared';
import { where } from '@principle-theorem/shared';
import { type Observable, from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class PracticeResolverService extends BaseResolver<
  WithRef<IPractice> | undefined
> {
  constructor(
    protected _router: Router,
    private _organisation: OrganisationService,
    private _stateNav: StateBasedNavigationService,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IPractice> | undefined> {
    const slug: string = route.paramMap.get('practice-slug') || '';

    return this._organisation.brand$.pipe(
      switchMap((brand: WithRef<IBrand> | undefined) => {
        if (!brand) {
          return of(undefined);
        }
        return firstResult$(
          Brand.practiceCol(brand),
          where('slug', '==', slug)
        );
      }),
      switchMap((foundPractice) => {
        if (!foundPractice) {
          return from(this._stateNav.brand()).pipe(map(() => undefined));
        }
        this._currentScopeFacade.setPracticeFromResolver(foundPractice);
        return of(foundPractice);
      }),
      take(1)
    );
  }
}
