import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { BehaviorSubject, type Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { isNull, kebabCase, toArray } from 'lodash';
import { PatientViewInvoiceService } from './patient-view-invoice.service';
import {
  ITransaction,
  type IBalance,
  type IInvoice,
  type IInvoiceItemGroupWithProvider,
  type IInvoiceWithProviderDetails,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, toMoment, type WithRef } from '@principle-theorem/shared';
import { jsPDF } from 'jspdf';
import { sanitiseCSRF } from '@principle-theorem/temporary-tokens';

@Component({
  selector: 'pr-patient-view-invoice',
  templateUrl: './patient-view-invoice.component.html',
  styleUrls: ['./patient-view-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientViewInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('invoiceDisplay') invoiceDisplay: ElementRef<HTMLElement>;
  tokenUid$: Observable<string | undefined>;
  data$: Observable<IInvoiceWithProviderDetails | undefined>;
  invoice$: Observable<WithRef<IInvoice> | undefined>;
  balance$: Observable<IBalance | undefined>;
  transactions$: Observable<WithRef<ITransaction<unknown>>[] | undefined>;
  groups$: Observable<IInvoiceItemGroupWithProvider[] | undefined>;
  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private _route: ActivatedRoute,
    private _patientInvoiceService: PatientViewInvoiceService,
    private _intercom: IntercomService
  ) {
    this.tokenUid$ = combineLatest([
      this._route.queryParamMap.pipe(
        map((queryParams) => queryParams.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
      this._route.paramMap.pipe(
        map((params) => params.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
    ]).pipe(
      map(([queryToken, routeToken]) => queryToken ?? routeToken),
      map((token) => token && sanitiseCSRF(token))
    );

    this.data$ = this.tokenUid$.pipe(
      switchMap((tokenUid) =>
        tokenUid
          ? this._patientInvoiceService.resolveInvoice$(tokenUid)
          : of(undefined)
      ),
      tap(() => this.isLoading$.next(false))
    );

    this.invoice$ = this.data$.pipe(map((data) => data?.invoice));
    this.groups$ = this.data$.pipe(map((data) => data?.groups));
    this.balance$ = this.data$.pipe(map((data) => data?.balance));
    this.transactions$ = this.data$.pipe(map((data) => data?.transactions));
  }

  printInvoice(): void {
    window.print();
  }

  async downloadInvoice(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    if (!invoice) {
      return;
    }
    const pdf = new jsPDF('p', 'pt', 'A4');

    const filename = `${kebabCase(invoice.practice.name)}-invoice-${toMoment(
      invoice.createdAt
    ).format('DD-MM-YY')}.pdf`;
    const content = this.invoiceDisplay.nativeElement;
    content.style.width = '540px';
    content.style.fontSize = '80%';

    toArray(content.getElementsByClassName('tooltip'))
      .map((element) => element as HTMLElement)
      .map((tooltip) => (tooltip.style.display = 'none'));

    await pdf.html(content, {
      callback: (doc) => {
        doc.save(filename);
      },
      margin: [30, 20, 30, 20],
      autoPaging: 'text',
      filename,
    });

    content.style.width = 'auto';
    content.style.fontSize = '100%';
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
  }
}
