import {
  SourceEntityMigrationType,
  type ISourceEntity,
  type IExpectedSourceRecordSize,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, groupBy, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { runQuery } from '../../../source/connection';

export const PATIENT_TREATMENT_NOTE_RESOURCE_TYPE = 'patientTreatmentNote';

export const PATIENT_TREATMENT_NOTE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Treatment Note List',
      description: '',
      idPrefix: PATIENT_TREATMENT_NOTE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientTreatmentNote {
  id: number;
  created_at: string;
  treatment_id: number;
  patient_id: number;
  practice_id: number;
  provider_id: number;
  chart_id: number | null;
  item_id: number;
  item_code: string;
  content: string;
  tooth_ref: string | number | null;
  tooth_surface: string | null;
}

export function isD4WPatientTreatmentNote(
  item: unknown
): item is ID4WPatientTreatmentNote {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.treatment_id) &&
    isNumber(item.patient_id) &&
    isNumber(item.practice_id) &&
    isNumber(item.provider_id) &&
    (isNumber(item.chart_id) || isNull(item.chart_id)) &&
    isNumber(item.item_id) &&
    isString(item.item_code) &&
    isString(item.created_at) &&
    isString(item.content) &&
    (isString(item.tooth_ref) ||
      isNumber(item.tooth_ref) ||
      isNull(item.tooth_ref)) &&
    (isString(item.tooth_surface) || isNull(item.tooth_surface))
  );
}

export interface ID4WPatientTreatmentNoteTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientTreatmentNoteFilters {
  createdAt: Timestamp;
  treatmentId: string;
  patientId: string;
  practiceId: string;
  chartId?: string;
  userId?: string;
}

const PATIENT_TREATMENT_NOTE_SOURCE_QUERY = `
SELECT * FROM (
  SELECT
    DISTINCT ON (treat_id)
    treat_notes_log_id AS id,
    id_treat AS treat_id,
    id_treat AS treatment_id,
    datetime1 AS created_at
  FROM
    treat_notes_log
  WHERE ref_status != 'D'
  ORDER BY id_treat ASC, treat_notes_log_id DESC
  ${OFFSET_PLACEHOLDER}
) AS created_table
JOIN (
  SELECT
    DISTINCT ON (notes.treatment_id) id,
    *,
    notes_log.treat_id AS root_treat_id
  FROM (
    SELECT
      treat_id,
      treat_id AS treatment_id,
      is_plan
    FROM treat_notes
    WHERE ref_status != 'D'
    ORDER BY is_plan
  ) AS notes
  INNER JOIN (
    SELECT
      DISTINCT ON (id_treat)
      id_treat AS treat_id,
      treat_notes_log_id AS id,
      datetime1 AS updated_at,
      notes AS content
    FROM treat_notes_log
    WHERE ref_status != 'D'
    ORDER BY id_treat, treat_notes_log_id DESC
  ) AS notes_log
  ON notes.treat_id = notes_log.treat_id
  INNER JOIN (
    SELECT
      treat_id,
      treat_id AS treatment_id,
      patient_id,
      practice_id,
      provider_id,
      chart_id,
      item_id AS original_item_id,
      item_id,
      tooth AS tooth_ref,
      NULLIF(surface, '') AS tooth_surface
    FROM treat
  ) AS treatment
  ON notes.treat_id = treatment.treat_id
  INNER JOIN (
    SELECT
      item_id AS original_item_id,
      item_id,
      item AS item_code
    FROM procedures
  ) AS item_codes
  ON treatment.original_item_id = item_codes.original_item_id
  ) AS whole_table
ON created_table.treat_id = whole_table.root_treat_id
  `;

export class PatientTreatmentNoteSourceEntity extends BaseSourceEntity<
  ID4WPatientTreatmentNote,
  ID4WPatientTreatmentNoteTranslations,
  ID4WPatientTreatmentNoteFilters
> {
  sourceEntity = PATIENT_TREATMENT_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_TREATMENT_NOTE_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isD4WPatientTreatmentNote;
  override defaultOffsetSize = 10000;
  override dateFilterField: keyof ID4WPatientTreatmentNoteFilters = 'createdAt';

  override transformDataFn = flow([
    (rows: ID4WPatientTreatmentNote[]) => rows.map(cleanObjectStrings),
  ]);

  translate(
    data: ID4WPatientTreatmentNote,
    timezone: Timezone
  ): ID4WPatientTreatmentNoteTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      PATIENT_TREATMENT_NOTE_SOURCE_QUERY.replace(OFFSET_PLACEHOLDER, '')
    );
    const expectedSize = Object.values(
      groupBy(response.rows, (treatmentNote) => treatmentNote.id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  getSourceRecordId(data: ID4WPatientTreatmentNote): number {
    return data.treatment_id;
  }

  getSourceLabel(data: ID4WPatientTreatmentNote): string {
    return `${data.treatment_id}`;
  }

  getFilterData(
    data: ID4WPatientTreatmentNote,
    timezone: Timezone
  ): ID4WPatientTreatmentNoteFilters {
    return {
      treatmentId: data.treatment_id.toString(),
      patientId: data.patient_id.toString(),
      practiceId: data.practice_id.toString(),
      chartId: data.chart_id?.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
