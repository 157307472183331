import {
  randCompanyName,
  randEmail,
  randPhoneNumber,
  randStreetAddress,
} from '@ngneat/falso';
import {
  IContact,
  IContactable,
} from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

export class Contactable {
  static init(overrides?: Partial<IContactable>): IContactable {
    return {
      name: '',
      email: '',
      address: '',
      phone: '',
      notes: [],
      interactions: [],
      ...overrides,
    };
  }
}

export class Contact {
  static init(overrides?: Partial<IContact>): IContact {
    return {
      ...Contactable.init(),
      mobileNumber: '',
      ...initFirestoreModel(),
      ...overrides,
    };
  }
}

export function MockContact(name?: string): IContact {
  return Contact.init({
    name: name ? name : randCompanyName(),
    email: randEmail(),
    phone: randPhoneNumber(),
    address: randStreetAddress(),
  });
}

export const CONTACTS: IContact[] = ['ACME Inc.', 'Titanium Craniums'].map(
  (company: string) => MockContact(company)
);
