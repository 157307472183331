<pt-buttons-container>
  <pt-button-with-menu
    color="primary"
    type="flat"
    label="Add From Template"
    (buttonClick)="addFromTemplate()"
  >
    <button mat-menu-item (click)="addTask()">Add Task</button>
    <button mat-menu-item (click)="addNotification()">Add Notification</button>
    <button mat-menu-item (click)="addFormIssue()">Add Form Issue</button>
  </pt-button-with-menu>
</pt-buttons-container>

@if (hasAutomations$ | async) {
  <pr-automation-list-display
    [automations]="automations$ | async"
    (editAutomation)="edit($event)"
    (cancelAutomation)="cancel($event)"
    (restoreAutomation)="restore($event)"
    (resendAutomation)="resend($event)"
  />
} @else {
  <div fxLayoutAlign="center stretch">
    <h2 class="no-automations mat-headline-5">No Automations</h2>
  </div>
}
