import { type IStatistic, MetricFormatter } from '@principle-theorem/reporting';

export function MockNewPatientsStatistic(): IStatistic {
  return {
    label: 'New Patients',
    summary: 'Number of new patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockLostIncomeStatistic(): IStatistic {
  return {
    label: 'Lost Income',
    summary: 'Potential income lost due to hourly rate and gaps/unused time',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockGrossIncomeStatistic(): IStatistic {
  return {
    label: 'Gross Income',
    summary: 'Total income recieved (total takings)',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockNetIncomeStatistic(): IStatistic {
  return {
    label: 'Net Income',
    summary: 'Calculated by subtracting total expenses from total revenue',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockCompletedAppointmentsStatistic(): IStatistic {
  return {
    label: 'Completed Appointments',
    summary: 'Number of completed appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockScheduledAppointmentsStatistic(): IStatistic {
  return {
    label: 'Scheduled Appointments',
    summary: 'Number of scheduled appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockUnscheduledAppointmentsStatistic(): IStatistic {
  return {
    label: 'Unscheduled Appointments',
    summary: 'Number of unscheduled appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockRevenueFromCompletedAppointmentsStatistic(): IStatistic {
  return {
    label: 'Revenue From Completed Appointments',
    summary: 'Total money recieved from completed appointments',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockCompletedLabJobsStatistic(): IStatistic {
  return {
    label: 'Completed Lab Jobs',
    summary: 'Number of completed lab jobs',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockCompletedTasksStatistic(): IStatistic {
  return {
    label: 'Completed Tasks',
    summary: 'Number of completed tasks',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockRevenueFromProductsSoldStatistic(): IStatistic {
  return {
    label: 'Revenue from Products Sold',
    summary: 'Total income from products sold',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockPatientsInAppointmentStatistic(): IStatistic {
  return {
    label: 'Patients in Appointment',
    summary: 'Number of patients currently in an appointment',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockActivePatientsStatistic(): IStatistic {
  return {
    label: 'Active Patients',
    summary: 'Number of patients who have been seen in the past 2 years',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockInactivePatientsStatistic(): IStatistic {
  return {
    label: 'Inactive Patients',
    summary: 'Number of patients that have not been seen for 2 years',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockStaffOnAnnualLeaveStatistic(): IStatistic {
  return {
    label: 'Staff on Annual Leave',
    summary: 'Number of staff on annual leave today',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockStaffOnSickLeaveStatistic(): IStatistic {
  return {
    label: 'Staff on Sick Leave',
    summary: 'Number of staff on sick leave today',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockStaffOnRosteredDaysOffStatistic(): IStatistic {
  return {
    label: 'Staff on Rostered Days Off',
    summary: 'Number of staff that have a registered day off today',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockRevenueKPIStatistic(): IStatistic {
  return {
    label: 'Revenue KPI',
    summary:
      'Key performance index for revenue. Sometimes referred to as a budget, target or goal',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockProductivityStatistic(): IStatistic {
  return {
    label: 'Productivity',
    summary: `Index of productivity or effectiveness`,
    metric: 0,
    formatter: MetricFormatter.AverageCurrency,
  };
}

export function MockTimeUsedStatistic(): IStatistic {
  return {
    label: 'Time Used (hrs)',
    summary: 'Hours used in appointments or meetings',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTimeUnusedStatistic(): IStatistic {
  return {
    label: 'Time Unused (hrs)',
    summary: 'Hours unused including gaps',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTimeAvailableStatistic(): IStatistic {
  return {
    label: 'Time Available (hrs)',
    summary: 'Hours rostered to work',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockNumberOfChairs(): IStatistic {
  return {
    label: 'Number of Chairs',
    summary: 'Number of chairs in the brand',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockNumberOfStaff(): IStatistic {
  return {
    label: 'Number of Staff',
    summary: 'Number of staff in the brand',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockChairHoursAvailable(): IStatistic {
  return {
    label: 'Number of Chair Hours Available',
    summary: 'Number of chair hours available',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockStaffHoursAvailable(): IStatistic {
  return {
    label: 'Number of Staff Hours Available',
    summary: 'Number of staff hours available',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockFullTimeEquivalentRatio(): IStatistic {
  return {
    label: 'Ratio of Available Chair Hours to Staff Hours',
    summary: 'Ratio of available chair hours to staff hours',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockTreatmentsAppliedStatistic(): IStatistic {
  return {
    label: 'Treatments Applied',
    summary: 'Total number of treatments applied',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPatientsSeenStatistic(): IStatistic {
  return {
    label: 'Patients Seen',
    summary: 'Total number of patients seen',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockHourlyRateStatistic(): IStatistic {
  return {
    label: 'Average Hourly Rate',
    summary: 'Dollars per hour based on time in appointments',
    metric: 0,
    formatter: MetricFormatter.AverageCurrency,
  };
}

export function MockEbitStatistic(): IStatistic {
  return {
    label: 'Ebit',
    summary: 'Brand revenue minus expenses and operating costs',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockDentalDrawStatistic(): IStatistic {
  return {
    label: 'Dental Draw',
    summary: 'Income paid to practitioner',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockStaffExpensesStatistic(): IStatistic {
  return {
    label: 'Staff Expenses',
    summary: 'Wages paid to staff',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockDentalDrawPercentStatistic(): IStatistic {
  return {
    label: 'Dental Draw %',
    summary: 'Dental draw as percent of gross income',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockFacilityCostStatistic(): IStatistic {
  return {
    label: 'Facility Cost',
    summary: 'Total cost of facility expenses',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockConsumablesExpendedStatistic(): IStatistic {
  return {
    label: 'Consumables Expended',
    summary: 'Total cost of consumables',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockOverheadsStatistic(): IStatistic {
  return {
    label: 'Overhead',
    summary: 'Total cost of all overheads',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockCapitalInvestmentChargeStatistic(): IStatistic {
  return {
    label: 'Capital Investment Charge',
    summary: 'Dollars invested for the purpose of furthering the brand',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTotalNumberOfAppointmentsStatistic(): IStatistic {
  return {
    label: 'Total Number of Appointments',
    summary: 'Total number of appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalNumberOfCancelledAppointmentsStatistic(): IStatistic {
  return {
    label: 'Cancelled Appointments',
    summary: 'Total number of cancelled appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalNumberOfRebookedAppointmentsStatistic(): IStatistic {
  return {
    label: 'Rebooked Appointments',
    summary: 'Total number of rebooked appiintments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalTreatmentsAppliedStatistic(): IStatistic {
  return {
    label: 'Total Treatments Applied',
    summary: 'Total number of treatments applied',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTotalValueOfTreatmentsAppliedStatistic(): IStatistic {
  return {
    label: 'Total Value of Treatments Applied',
    summary: 'Total revenue of treatments applied',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockIncompleteTreatmentsStatistic(): IStatistic {
  return {
    label: 'Incomplete Treatments',
    summary: 'Total treatments pending completion',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockCostPerConversionStatistic(): IStatistic {
  return {
    label: 'Cost Per Conversion',
    summary: 'Cost per patient booked converted',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockMeanTreatmentPlanValueStatistic(): IStatistic {
  return {
    label: 'Average Treatment Plan Value',
    summary: 'Average value of treatment plans',
    metric: 0,
    formatter: MetricFormatter.AverageCurrency,
  };
}

export function MockEmptyChairStatistic(): IStatistic {
  return {
    label: 'Empty Chair (hrs)',
    summary: 'Total hours a chair is empty for',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockNextVisitsBookedStatistic(): IStatistic {
  return {
    label: 'Next Visits Booked',
    summary: 'Patients who have a scheduled booking',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockExistingPatientsStatistic(): IStatistic {
  return {
    label: 'Returning Patients Seen',
    summary: 'Number of returning patients seen',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAverageAppointmentDuration(): IStatistic {
  return {
    label: 'Average Appointment Duration (mins)',
    summary: 'Average appointment duration in mins',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockAveragePatientWaitTime(): IStatistic {
  return {
    label: 'Average Patient Wait Time (mins)',
    summary: 'Hrs spent in waiting room',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockPatientRebookingRate(): IStatistic {
  return {
    label: 'Patient Rebooking Rate',
    summary: 'Percentage of patients leaving with a scheduled appointment',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockPatientsLeavingWithScheduledAppointment(): IStatistic {
  return {
    label: 'Patients Leaving With Scheduled Appointment',
    summary:
      'Total number of patients that book their next appointment before leaving',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAveragePatientSpend(): IStatistic {
  return {
    label: 'Average Patient Spend',
    summary: 'Average amount a patient spends over the given period',
    metric: 0,
    formatter: MetricFormatter.AverageCurrency,
  };
}

export function MockTotalPatientSpend(): IStatistic {
  return {
    label: 'Total Patient Spend',
    summary: 'Total amount a patient spends over their lifetime',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockPatientsLeavingWithoutAppointment(): IStatistic {
  return {
    label: 'Patients Leaving without Appointment',
    summary:
      'Number of patients that leave without booking their next appointment',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockNoNextAppointmentStatistic(): IStatistic {
  return {
    label: 'Failed To Book Next Appointment',
    summary:
      'Number of treatment plans that have not got their next treatment booked',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalExpensesStatistic(): IStatistic {
  return {
    label: 'Total Expenses',
    summary: 'Total cost of all expenses',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockLabFeesStatistic(): IStatistic {
  return {
    label: 'Lab Fees',
    summary: 'Total cost of lab fees',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockGapsStatistic(): IStatistic {
  return {
    label: 'Gaps',
    summary: 'Number of gaps',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockGapsDurationStatistic(): IStatistic {
  return {
    label: 'Gaps Duration (hrs)',
    summary: 'Total duration of all gaps',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAppointmentsDurationStatistic(): IStatistic {
  return {
    label: 'Appointments Duration (hrs)',
    summary: 'Total duration of all appointments',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAverageGapDurationStatistic(): IStatistic {
  return {
    label: 'Average Gap Duration (mins)',
    summary: 'Average duration of all gaps',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockLostProductivityStatistic(): IStatistic {
  return {
    label: 'Lost Productivity',
    summary: 'Average dollars per hour lost due to unused time',
    metric: 0,
    formatter: MetricFormatter.AverageCurrency,
  };
}

export function MockFtasStatistic(): IStatistic {
  return {
    label: 'FTAs',
    summary: 'Number of Failure to Attends',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockUtasStatistic(): IStatistic {
  return {
    label: 'UTAs',
    summary: 'Number of Unable to Attends',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPresentedTreatmentPlansStatistic(): IStatistic {
  return {
    label: 'Presented Treatment Plans ',
    summary: 'Number of treatment plans given to patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAcceptedTreatmentPlansStatistic(): IStatistic {
  return {
    label: 'Accepted Treatment Plans',
    summary: 'Number of treatment plans accepted by patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockRejectedTreatmentPlansStatistic(): IStatistic {
  return {
    label: 'Unaccepted Treatment Plans',
    summary: 'Number of treatment plans not accepted by patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockFraudScoreStatistic(): IStatistic {
  return {
    label: 'Fraud Probability Score',
    summary: 'The probability of a Brand committing fraud',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentPlanAcceptanceRateStatistic(): IStatistic {
  return {
    label: 'Treatment Plan Acceptance Rate',
    summary: 'Percent of Treatment Plans Accepted',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockTreatmentsDiagnosticStatistic(): IStatistic {
  return {
    label: 'Diagnostic Services',
    summary: 'Number of diagnostic services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsPreventiveStatistic(): IStatistic {
  return {
    label: 'Preventive, Prophylactic and Bleaching Services',
    summary:
      'Number of preventive, prophylactic and bleaching services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsPeriodonticsStatistic(): IStatistic {
  return {
    label: 'Periodontics',
    summary: 'Number of periodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsOralSurgeryStatistic(): IStatistic {
  return {
    label: 'Oral Surgery',
    summary: 'Number of oral surgery services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsEndodonticsStatistic(): IStatistic {
  return {
    label: 'Endodontics',
    summary: 'Number of endodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsRestorativeStatistic(): IStatistic {
  return {
    label: 'Restorative Services',
    summary: 'Number of restorative services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsProsthodonticsStatistic(): IStatistic {
  return {
    label: 'Prosthodontics',
    summary: 'Number of prosthodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsOrthodonticsStatistic(): IStatistic {
  return {
    label: 'Orthodontics',
    summary: 'Number of orthodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTreatmentsOtherStatistic(): IStatistic {
  return {
    label: 'General & Miscellaneous Services',
    summary: 'Number of general & miscellaneous services performed',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockAgeStatistic(): IStatistic {
  return {
    label: 'Age',
    summary: `Person's age`,
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockAgeRangeStatistic(): IStatistic {
  return {
    label: 'Age Range',
    summary: `Person's age range`,
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockDistanceStatistic(): IStatistic {
  return {
    label: 'Distance (kms)',
    summary: 'Distance from brand',
    metric: 0,
    formatter: MetricFormatter.AverageNumber,
  };
}

export function MockTreatmentsDiagnosticIncome(): IStatistic {
  return {
    label: 'Diagnostic Treatment Income',
    summary: 'Income from diagnostic services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsPreventiveIncome(): IStatistic {
  return {
    label: 'Preventitive Treatment Income',
    summary: 'Income from preventitive services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsPeriodonticsIncome(): IStatistic {
  return {
    label: 'Periodontic Treatment Income',
    summary: 'Income from periodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsOralSurgeryIncome(): IStatistic {
  return {
    label: 'Oral Surgery Treatment Income',
    summary: 'Income from oral surgery services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsEndodonticsIncome(): IStatistic {
  return {
    label: 'Endodontics Treatment Income',
    summary: 'Income from endodontics services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsRestorativeIncome(): IStatistic {
  return {
    label: 'Restorative Treatment Income',
    summary: 'Income from restorative services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsProsthodonticsIncome(): IStatistic {
  return {
    label: 'Prosthodontics Treatment Income',
    summary: 'Income from prosthodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsOrthodonticsIncome(): IStatistic {
  return {
    label: 'Orthodontics Treatment Income',
    summary: 'Income from orthodontic services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockTreatmentsOtherIncome(): IStatistic {
  return {
    label: 'Other Treatment Income',
    summary: 'Income from other services performed',
    metric: 0,
    formatter: MetricFormatter.Currency,
  };
}

export function MockReschedulingRateStatistic(): IStatistic {
  return {
    label: 'Rescheduling Rate',
    summary: 'The average rate(%) at which appointments are being rescheduled',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockCancellationRateStatistic(): IStatistic {
  return {
    label: 'Cancellation Rate',
    summary: 'The average rate(%) of cancellations',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockConversionRateStatistic(): IStatistic {
  return {
    label: 'Conversion Rate',
    summary: '% of general enquiries converting to appointment',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockMalePatientsStatistics(): IStatistic {
  return {
    label: 'Male Patients',
    summary: 'Number of male patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockFemalePatientsStatistics(): IStatistic {
  return {
    label: 'Female Patients',
    summary: 'Number of female patients',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPatientBounceRate(): IStatistic {
  return {
    label: 'Patient Bounce Rate',
    summary: 'Percentage of patients leaving without a scheduled appointment',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockOverdueInvoicesCount(): IStatistic {
  return {
    label: 'Overdue Invoices',
    summary: 'Total number of overdue invoices',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockOverdueInvoicesAmount(): IStatistic {
  return {
    label: 'Overdue Invoices Amount',
    summary: 'Total amount owing from overdue invoices',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockWrittenOffInvoicesAmount(): IStatistic {
  return {
    label: 'Written-off Invoices Amount',
    summary: 'Total amount of invoices written-off',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPaymentPlansCount(): IStatistic {
  return {
    label: 'Active Payment Plans',
    summary: 'Total number of active payment plans',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPaymentPlansAmount(): IStatistic {
  return {
    label: 'Active Payment Plans Amount',
    summary: 'Total amount owing of active payment plans',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockPaidOnCheckoutRate(): IStatistic {
  return {
    label: 'Paid on Checkout',
    summary: 'Rate of invoices paid on checkout',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockPaidAfterCheckoutRate(): IStatistic {
  return {
    label: 'Paid After Checkout',
    summary: 'Rate of invoices paid after checkout date',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockNeverPaidRate(): IStatistic {
  return {
    label: 'Never Paid',
    summary: 'Rate of invoices that have been written-off',
    metric: 0,
    formatter: MetricFormatter.Percentage,
  };
}

export function MockTotalReschedules(): IStatistic {
  return {
    label: 'Total Appointments Rescheduled',
    summary: '',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalNewAppointments(): IStatistic {
  return {
    label: 'Total New Appointments Added',
    summary: '',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalValueOfTreatmentsUnaccepted(): IStatistic {
  return {
    label: 'Total Value of Unaccepted Treatments',
    summary: '',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockTotalValueOfTreatmentsAccepted(): IStatistic {
  return {
    label: 'Total Value of Accepted Treatments',
    summary: '',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}

export function MockYearOpenedStatistic(): IStatistic {
  return {
    label: 'Year Opened',
    summary: '',
    metric: 0,
    formatter: MetricFormatter.Number,
  };
}
