<mat-chip-listbox
  *ngIf="tagsList$ | async as tags"
  [ngClass]="{ dense: dense }"
>
  <mat-chip
    *ngFor="let tag of tags; trackBy: trackByTag"
    [style.--mdc-chip-label-text-color]="tag | tagTextColour"
    [style.background-color]="tag.hexColour"
  >
    {{ tag.name }}
  </mat-chip>

  <ng-container *ngIf="dense">
    <mat-chip *ngIf="extraTagCount$ | async as extraTagCount"
      >+{{ extraTagCount }}</mat-chip
    >
  </ng-container>
</mat-chip-listbox>
