import {
  IAppointment,
  ITreatmentPlanWithBookableStep,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '../../appointment/appointment';

export async function getPlanWithBookableStep(
  appointment: IAppointment
): Promise<ITreatmentPlanWithBookableStep> {
  const plan = await Appointment.treatmentPlan(appointment);
  const step = await Appointment.treatmentStep(appointment);
  return {
    plan,
    step,
  };
}
