@if (patient$ | async; as patient) {
  @if (showHealthCards$ | async) {
    <mat-card class="no-padding">
      <mat-tab-group mat-stretch-tabs>
        @if (showHealthCard$('medicare') | async) {
          <mat-tab label="Medicare">
            <pr-medicare-card-display
              [medicareCard]="patient.medicareCard"
              (edit)="editMedicareCard()"
              (remove)="updatePatient({ medicareCard: undefined })"
            />
          </mat-tab>
        }

        @if (showHealthCard$('dva') | async) {
          <mat-tab label="DVA">
            <pr-dva-card-display
              [dvaCard]="patient.dvaCard"
              (edit)="editDVACard()"
              (remove)="updatePatient({ dvaCard: undefined })"
            />
          </mat-tab>
        }

        @if (showHealthCard$('healthFund') | async) {
          <mat-tab label="Health Fund">
            <pr-healthfund-card-display
              [healthFundCard]="patient.healthFundCard"
              (edit)="editHealthFundCard()"
              (remove)="updatePatient({ healthFundCard: undefined })"
            />
          </mat-tab>
        }

        @if (showHealthCard$('nhi') | async) {
          <mat-tab label="NHI">
            <div class="p-4">
              <pr-national-health-index-number-form
                [nationalHealthIndexNumber]="patient.nationalHealthIndexNumber"
                (nationalHealthIndexNumberChange)="
                  updatePatient({ nationalHealthIndexNumber: $event })
                "
              />
            </div>
          </mat-tab>
        }

        @if (showHealthCard$('healthInsurance') | async) {
          <mat-tab label="Health Insurance">
            <div class="p-4">
              <pr-health-insurance-details-form
                [healthInsuranceDetails]="patient.healthInsuranceDetails"
                (healthInsuranceDetailsChange)="
                  updatePatient({ healthInsuranceDetails: $event })
                "
              />
            </div>
          </mat-tab>
        }
      </mat-tab-group>
    </mat-card>
  }
}
