import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { ContactTimelineComponent } from './contact-tabs/contact-timeline/contact-timeline.component';
import { SubContactListComponent } from './contact-tabs/sub-contact-list/sub-contact-list.component';
import { ContactProfileComponent } from './contact-tabs/contact-profile/contact-profile.component';
import { ContactTabsComponent } from './contact-tabs/contact-tabs.component';
import { ContactResolverService } from './contact-resolver.service';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactPagesModule } from './contact-pages.module';

const routes: Routes = [
  {
    path: '',
    component: ContactListComponent,
  },
  {
    path: ':uid',
    resolve: { contact: ContactResolverService },
    children: [
      {
        path: '',
        component: ContactTabsComponent,
        children: [
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full',
          },
          {
            path: 'profile',
            component: ContactProfileComponent,
          },
          {
            path: 'members',
            component: SubContactListComponent,
          },
          {
            path: 'history',
            component: ContactTimelineComponent,
            data: { titleSuffix: 'History' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ContactPagesModule],
  exports: [RouterModule],
})
export class ContactPagesRoutingModule {}
