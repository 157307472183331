import {
  MixedSchema,
  initVersionedSchema,
  toLinkContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  CollectionGroup,
  SystemTemplate,
  ITemplateDefinition,
  TemplateScope,
  TemplateType,
  IBrand,
  ITemplateDefinitionWithName,
  IResolvedTemplateWithName,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  doc,
  doc$,
  docOrDefault$,
  DocumentReference,
  getEnumValues,
  getParentDocRef,
  safeCombineLatest,
  snapshot,
  subCollection,
  WithRef,
  WithRefOrUnsaved,
} from '@principle-theorem/shared';
import { findKey, values } from 'lodash';
import { Observable } from 'rxjs';
import { TemplateDefinition } from './template-definition';

export interface ISystemTemplateWithUid {
  uid: string;
  template: ITemplateDefinition;
}

export const SYSTEM_TEMPLATES_IDS: Record<SystemTemplate, string> = {
  [SystemTemplate.PrintHeader]: 'print-header',
  [SystemTemplate.PrintFooter]: 'print-footer',
  [SystemTemplate.PrintInvoiceHeader]: 'print-invoice-header',
  [SystemTemplate.PrintInvoiceFooter]: 'print-invoice-footer',
  [SystemTemplate.PrintTreatmentPlanHeader]: 'print-treatment-plan-header',
  [SystemTemplate.PrintTreatmentPlanFooter]: 'print-treatment-plan-footer',
  [SystemTemplate.InvoiceSMS]: 'invoice-sms',
  [SystemTemplate.InvoiceEmail]: 'invoice-email',
};

export const DEFAULT_SEND_INVOICE_SMS_CONTENT = initVersionedSchema([
  toParagraphContent([toTextContent('Hi {{ patientFirstName }},')]),
  toParagraphContent([
    toTextContent(
      'Please follow the link to view your invoice from {{ invoiceCreatedAt }} at {{ invoicePracticeName }}:'
    ),
  ]),
  toParagraphContent([toTextContent('{{ invoiceLink }}')]),
]);

export const DEFAULT_SEND_INVOICE_EMAIL_CONTENT = initVersionedSchema([
  toParagraphContent([toTextContent('Hi {{ patientFirstName }},')]),
  toParagraphContent([
    toTextContent(
      'Please follow the link to view your invoice from {{ invoiceCreatedAt }} at {{ invoicePracticeName }}:'
    ),
  ]),
  toParagraphContent([toLinkContent('View Invoice', '{{ invoiceLink }}')]),
]);

export const SYSTEM_TEMPLATES_DEFAULTS: Record<
  SystemTemplate,
  (ownerScope: DocumentReference) => ITemplateDefinition
> = {
  [SystemTemplate.PrintHeader]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Header',
      type: TemplateType.Html,
      scope: TemplateScope.None,
      ownerScope,
    }),
  [SystemTemplate.PrintFooter]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Footer',
      type: TemplateType.Html,
      scope: TemplateScope.None,
      ownerScope,
    }),
  [SystemTemplate.PrintInvoiceHeader]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Invoice Header',
      type: TemplateType.Html,
      scope: TemplateScope.Invoice,
      ownerScope,
    }),
  [SystemTemplate.PrintInvoiceFooter]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Invoice Footer',
      type: TemplateType.Html,
      scope: TemplateScope.Invoice,
      ownerScope,
    }),
  [SystemTemplate.PrintTreatmentPlanHeader]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Treatment Plan Header',
      type: TemplateType.Html,
      scope: TemplateScope.TreatmentPlan,
      ownerScope,
    }),
  [SystemTemplate.PrintTreatmentPlanFooter]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Print Treatment Plan Footer',
      type: TemplateType.Html,
      scope: TemplateScope.TreatmentPlan,
      ownerScope,
    }),
  [SystemTemplate.InvoiceSMS]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Send Invoice as SMS',
      type: TemplateType.PlainText,
      scope: TemplateScope.Invoice,
      content: DEFAULT_SEND_INVOICE_SMS_CONTENT,
      ownerScope,
    }),
  [SystemTemplate.InvoiceEmail]: (ownerScope) =>
    TemplateDefinition.init({
      name: 'Send Invoice as Email',
      type: TemplateType.Html,
      scope: TemplateScope.Invoice,
      content: DEFAULT_SEND_INVOICE_EMAIL_CONTENT,
      ownerScope,
    }),
};

export class SystemTemplates {
  /**
   * Generic Functions
   */
  static isSystemDocUid(uid?: string): boolean {
    return uid ? values(SYSTEM_TEMPLATES_IDS).includes(uid) : false;
  }

  static getKeyFromUid(id: string): SystemTemplate | undefined {
    return findKey(SYSTEM_TEMPLATES_IDS, (item) => item === id) as
      | SystemTemplate
      | undefined;
  }

  static getUidFromKey(key: SystemTemplate): string {
    return SYSTEM_TEMPLATES_IDS[key];
  }

  static getResolverFromKey(
    key: SystemTemplate
  ): (ownerScope: DocumentReference) => ITemplateDefinition {
    return SYSTEM_TEMPLATES_DEFAULTS[key];
  }

  /**
   * System Template Specific
   */
  static getDefaultTemplate(
    systemTemplate: SystemTemplate,
    ownerScope: DocumentReference
  ): ITemplateDefinition {
    const resolver = SystemTemplates.getResolverFromKey(systemTemplate);
    return resolver(ownerScope);
  }

  static getInvoiceTemplatesWithUid(
    ownerScope: DocumentReference
  ): ISystemTemplateWithUid[] {
    return [SystemTemplate.InvoiceSMS, SystemTemplate.InvoiceEmail].map(
      (templateType) => {
        return {
          uid: SYSTEM_TEMPLATES_IDS[templateType],
          template: this.getDefaultTemplate(templateType, ownerScope),
        };
      }
    );
  }

  static docRef(
    systemTemplate: SystemTemplate,
    ownerScope: DocumentReference
  ): DocumentReference<ITemplateDefinition> {
    return doc(
      subCollection<ITemplateDefinition>(ownerScope, CollectionGroup.Templates),
      SystemTemplates.getUidFromKey(systemTemplate)
    );
  }

  static resolveSystemTemplate$(
    systemTemplate: SystemTemplate,
    ownerScope: DocumentReference
  ): Observable<WithRefOrUnsaved<ITemplateDefinition>> {
    const ref = SystemTemplates.docRef(systemTemplate, ownerScope);
    return SystemTemplates.getSystemTemplate$(ref);
  }

  static resolveSystemTemplates$(
    ownerScope: DocumentReference
  ): Observable<WithRefOrUnsaved<ITemplateDefinition>[]> {
    return safeCombineLatest(
      getEnumValues(SystemTemplate).map((template) =>
        SystemTemplates.resolveSystemTemplate$(template, ownerScope)
      )
    );
  }

  static getSystemTemplate$(
    ref: DocumentReference<ITemplateDefinition>
  ): Observable<WithRefOrUnsaved<ITemplateDefinition>> {
    const template = SystemTemplates.getKeyFromUid(ref.id);
    if (!template) {
      return doc$(ref);
    }
    const defaultValue = SystemTemplates.getDefaultTemplate(
      template,
      getParentDocRef(ref)
    );
    return docOrDefault$(ref, defaultValue);
  }

  static async getSystemTemplate(
    template: SystemTemplate,
    brand: WithRef<IBrand>
  ): Promise<WithRefOrUnsaved<ITemplateDefinition>> {
    const templateRef = SystemTemplates.docRef(template, brand.ref);
    return snapshot(SystemTemplates.getSystemTemplate$(templateRef));
  }

  static async resolveSystemTemplates(
    brand: WithRef<IBrand>,
    templates: SystemTemplate[]
  ): Promise<ITemplateDefinitionWithName[]> {
    return asyncForEach(templates, async (template) => {
      const templateDoc = await SystemTemplates.getSystemTemplate(
        template,
        brand
      );
      return { templateDoc, template };
    });
  }

  static hasTemplateVariants(
    templates: ITemplateDefinitionWithName[]
  ): boolean {
    return templates.some((template) => template.templateDoc.variants?.length);
  }

  static findTemplate(
    templates: IResolvedTemplateWithName[],
    template: SystemTemplate
  ): MixedSchema | undefined {
    return templates.find(
      (printTemplate) => printTemplate.template === template
    )?.content;
  }
}
