/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { FeeScheduleSourceEntity } from './fee-schedule';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_REFERRAL_RESOURCE_TYPE = 'patientReferralSource';

export const PATIENT_REFERRAL_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Referral List',
    description: '',
    idPrefix: PATIENT_REFERRAL_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientReferral {
  id: number;
  type_code: string;
  patient_referrer_id: number | null;
  staff_referrer_id: number | null;
  third_party_referrer_id: string | number | null;
  third_party_name: string | null;
  category_name: string | null;
}

export function isD4WReferralSource(
  item: unknown
): item is ID4WPatientReferral {
  return TypeGuard.interface<ID4WPatientReferral>({
    id: isNumber,
    type_code: isString,
    patient_referrer_id: [isNumber, isNull],
    staff_referrer_id: [isNumber, isNull],
    third_party_referrer_id: [isString, isNumber, isNull],
    third_party_name: [isString, isNull],
    category_name: [isString, isNull],
  })(item);
}

const PATIENT_REFERRAL_SOURCE_QUERY = `
SELECT
  source.*,
  third_party.third_party_name,
  category.category_name
FROM (
  SELECT
    refsource_id AS id,
    reftype_code AS type_code,
    patient_id AS patient_referrer_id,
    convert_to_integer(staff_id) AS staff_referrer_id,
    th_party_id AS third_party_referrer_id
  FROM patients_referral_sources
) AS source
LEFT JOIN (
    SELECT
    third_party_id,
    name AS third_party_name,
    refcategory_id
    FROM third_parties
) AS third_party
ON convert_to_text(source.third_party_referrer_id) = convert_to_text(third_party.third_party_id)
LEFT JOIN (
    SELECT
    refcategory_id,
    refcategory_name AS category_name
    FROM referral_sources_categories
) AS category
ON convert_to_text(third_party.refcategory_id) = convert_to_text(category.refcategory_id)
WHERE source.id IS NOT NULL
`;

export class PatientReferralSourceEntity extends BaseSourceEntity<ID4WPatientReferral> {
  sourceEntity = PATIENT_REFERRAL_SOURCE_ENTITY;
  entityResourceType = PATIENT_REFERRAL_RESOURCE_TYPE;
  sourceQuery = PATIENT_REFERRAL_SOURCE_QUERY;
  verifySourceFn = isD4WReferralSource;

  override requiredEntities = {
    feeSchedule: new FeeScheduleSourceEntity(),
  };

  override transformDataFn = flow([
    (rows: ID4WPatientReferral[]) => rows.map(cleanObjectStrings),
  ]);

  translate(): object {
    return {};
  }

  getSourceRecordId(data: ID4WPatientReferral): string | number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientReferral): string {
    return `${data.id} ${data.type_code}`;
  }
}
