<header *ngIf="expanded && label">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <h3>{{ label }}</h3>
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info_outline</mat-icon>
  </div>

  <ng-container *ngIf="matMenu">
    <button
      mat-icon-button
      class="menu-trigger"
      [matMenuTriggerFor]="matMenu"
      [matTooltip]="matMenuTooltip"
      [matTooltipDisabled]="!matMenuTooltip"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </ng-container>
</header>

<div class="links">
  <ng-container *ngFor="let link of links; trackBy: trackByLink">
    <ng-container *prHasPermission="link.feature?.permissions">
      <a
        *ptFeatureEnabled="link.feature?.label"
        [routerLink]="{routePrefix, link} | map: getRoutePath"
        routerLinkActive="selected"
        [routerLinkActiveOptions]="{ exact: false }"
        [ptIntercomTarget]="link | map : getIntercomTarget : this"
      >
        <pr-sidebar-link
          *prHasPermission="link.permissions"
          [feature]="link.feature"
          [label]="link.label"
          [icon]="link.display?.icon"
          [badge]="link.badge$ | async"
          [expanded]="expanded"
          matRipple
          (click)="closeSidebarIfMobile()"
        />
      </a>
    </ng-container>
  </ng-container>
</div>
