import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  type IContactable,
  type IContactDetails,
} from '@principle-theorem/principle-core/interfaces';

export interface IContactFormData {
  title: string;
  submitLabel: string;
  contact?: IContactable;
}

@Component({
    selector: 'pr-contact-form-dialog',
    templateUrl: './contact-form-dialog.component.html',
    styleUrls: ['./contact-form-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactFormDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IContactFormData,
    private _dialogRef: MatDialogRef<ContactFormDialogComponent>
  ) {}

  submit(data: Partial<IContactDetails>): void {
    this._dialogRef.close(data);
  }
}
