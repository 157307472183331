<mat-form-field subscriptSizing="dynamic">
  <mat-label>Select Chart</mat-label>
  <mat-select [formControl]="chartCtrl" [compareWith]="compareFn">
    <mat-option
      *ngFor="let item of items$ | async; trackBy: trackByItem"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
