import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  Brand,
  DEFAULT_SCHEDULE_SUMMARY_PERIOD,
  Event,
  Gap,
  GapFilterBuilder,
  Practice,
  buildGapEvents,
  isSameEvent,
  isSameTimePeriod,
  stafferToParticipant,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  ICandidateCalendarEvent,
  IGapDuration,
  IGapTimeRange,
  IPractice,
  IScheduleSummary,
  IScheduleSummaryEventable,
  IStaffer,
  isGapEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  ISO_DATE_FORMAT,
  ITimePeriod,
  WithRef,
  asyncForEach,
  filterUndefined,
  isChanged$,
  isObject,
  isRefChanged$,
  isSameRef,
  multiSwitchMap,
  orderBy,
  query,
  snapshot,
  snapshotDefined,
  toISODate,
  toMoment,
  toTimePeriod,
  where,
} from '@principle-theorem/shared';
import { chunk, compact, first, isArray, isNil } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, from } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { StateBasedNavigationService } from '../navigation/state-based-navigation.service';
import { OrganisationService } from '../organisation.service';
import { GlobalStoreService } from '../store/global-store.service';

interface IGapsState {
  gaps: IScheduleSummaryEventable[];
  gapCandidates: WithRef<ICandidateCalendarEvent>[];
  allGapCandidates: WithRef<ICandidateCalendarEvent>[];
  pendingGaps: IScheduleSummaryEventable[];
  filteredGaps: IScheduleSummaryEventable[];
  loading: boolean;
  displayGapsOnTimeline: boolean;
  displayInSideBar: boolean;
  displayTab: GapsTabIndex;
  dateRange: ITimePeriod;
  selectedGap: IScheduleSummaryEventable | undefined;
  searchOutOfRange: boolean;
  selectedStaff: WithRef<IStaffer>[];
  searchResults: IScheduleSummaryEventable[];
  defaultSearchRange?: ITimePeriod;
  searchFilters?: IGapSearch;
  practice?: WithRef<IPractice>;
}

export interface IGapDayPair {
  day: ISODateType;
  gaps: IScheduleSummaryEventable[];
}

export interface IGapSearch {
  dateRange?: ITimePeriod;
  timeRange?: IGapTimeRange;
  duration?: IGapDuration;
  practitioners?: WithRef<IStaffer>[];
}

interface ICandidateGapsPair {
  gaps: IScheduleSummaryEventable[];
  candidate: WithRef<ICandidateCalendarEvent>;
}

export enum GapsTabIndex {
  Gaps = 0,
  PendingGaps = 1,
}

export type LoadGaps = [
  IScheduleSummaryEventable[],
  WithRef<IPractice>,
  ITimePeriod,
  WithRef<IStaffer>[],
];

export type LoadGapCandidates = [
  WithRef<IBrand>,
  WithRef<IPractice>,
  WithRef<IStaffer>[],
];

const initialDateRange = toTimePeriod(
  moment().startOf('day'),
  moment().endOf('day')
);

const initialState: IGapsState = {
  gaps: [],
  gapCandidates: [],
  allGapCandidates: [],
  pendingGaps: [],
  filteredGaps: [],
  searchResults: [],
  selectedStaff: [],
  loading: true,
  displayGapsOnTimeline: false,
  displayInSideBar: false,
  displayTab: GapsTabIndex.Gaps,
  searchOutOfRange: false,
  dateRange: initialDateRange,
  selectedGap: undefined,
  defaultSearchRange: undefined,
  searchFilters: undefined,
  practice: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class GapStoreService extends ComponentStore<IGapsState> {
  private _global = inject(GlobalStoreService);
  readonly gaps$ = this.select((store) => store.gaps);
  readonly gapCandidates$ = this.select((store) => store.gapCandidates);
  readonly allGapCandidates$ = this.select((store) => store.allGapCandidates);
  readonly pendingGaps$ = this.select((store) => store.pendingGaps);
  readonly filteredGaps$ = this.select((store) => store.filteredGaps);
  readonly searchResults$ = this.select((store) => store.searchResults);
  readonly practice$ = this.select((store) => store.practice);
  readonly loading$ = this.select((store) => store.loading);
  readonly displayGapsOnTimeline$ = this.select(
    (store) => store.displayGapsOnTimeline
  );
  readonly displayInSideBar$ = this.select((store) => store.displayInSideBar);
  readonly displayTab$ = this.select((store) => store.displayTab);
  readonly dateRange$ = this.select((store) => store.dateRange);
  readonly selectedGap$ = this.select((store) => store.selectedGap);
  readonly searchFilters$ = this.select((store) => store.searchFilters);
  readonly searchOutOfRange$ = this.select((store) => store.searchOutOfRange);
  readonly selectedStaff$ = this.select((store) => store.selectedStaff);
  readonly defaultSearchRange$ = this.select(
    (store) => store.defaultSearchRange
  );
  readonly hasActiveFilters$: Observable<boolean>;

  readonly setGaps = this.effect((sources$: Observable<LoadGaps>) =>
    sources$.pipe(
      map(([summaries, practice, dateRange, selectedStaff]) => ({
        gaps: this._filterGapsBySelectedStaff(summaries, selectedStaff),
        practice,
        dateRange,
        selectedStaff,
      })),
      tap(({ gaps, practice, dateRange, selectedStaff }) =>
        this.patchState({
          gaps,
          practice,
          dateRange,
          selectedStaff,
          loading: false,
        })
      ),
      withLatestFrom(this.searchFilters$),
      concatMap(async ([{ dateRange, gaps }, searchFilters]) => {
        const filteredGaps = await this._filterGapsBySearchData(gaps, {
          dateRange,
          ...searchFilters,
        });
        this.setFilteredGaps(filteredGaps);
      })
    )
  );

  readonly loadAllGapCandidates = this.effect(
    (sources$: Observable<LoadGapCandidates>) =>
      sources$.pipe(
        switchMap(([brand, practice, staff]) =>
          this._getGapCandidates$([brand, practice, staff])
        ),
        tap((allGapCandidates) => this.patchState({ allGapCandidates }))
      )
  );

  readonly filterGapCandidates = this.effect(() =>
    combineLatest([
      this.allGapCandidates$,
      this.pendingGaps$,
      this.selectedGap$,
    ]).pipe(
      map(([allCandidates, pendingGaps, selectedGap]) =>
        this._filterCandidatesBySelectedGap(
          allCandidates,
          pendingGaps,
          selectedGap
        )
      ),
      tap((gapCandidates) => this.patchState({ gapCandidates }))
    )
  );

  readonly loadPendingGaps = this.effect(
    (sources$: Observable<WithRef<ICandidateCalendarEvent>[]>) =>
      sources$.pipe(
        multiSwitchMap((candidate) =>
          from(Gap.scheduleSummaryFromGapCandidate(candidate)).pipe(
            filterUndefined(),
            switchMap((scheduleSummary) =>
              this._getCandidateGapsPair(candidate, scheduleSummary)
            )
          )
        ),
        map(this._reduceCandidateGapsPairs),
        tap((pendingGaps) => this.patchState({ pendingGaps }))
      )
  );

  readonly setSearchOutOfRange = this.effect(
    (sources$: Observable<[ITimePeriod, WithRef<IPractice>]>) =>
      sources$.pipe(
        tap(([dateRange, practice]) => {
          const defaultSearchRange =
            Practice.defaultScheduleSummaryDateRange(practice);
          const isAfter = dateRange.from.isAfter(defaultSearchRange.to);
          const isBefore = dateRange.to.isBefore(defaultSearchRange.from);
          this.patchState({
            defaultSearchRange,
            searchOutOfRange: isBefore || isAfter,
          });
        })
      )
  );

  readonly selectPendingGap = this.effect(
    (
      sources$: Observable<
        [IScheduleSummaryEventable, IScheduleSummaryEventable[]]
      >
    ) =>
      sources$.pipe(
        tap(([selectedPendingGap, gaps]) =>
          this.setSelectedGap(
            gaps.find((gap) => isSameEvent(gap.event, selectedPendingGap.event))
          )
        )
      )
  );

  readonly nextAvailableGap$ = combineLatest([
    this.gaps$,
    this.searchFilters$.pipe(map((filters) => filters?.practitioners ?? [])),
    this.selectedStaff$.pipe(isChanged$(isSameRef)),
  ]).pipe(
    switchMap(([gaps, practitioners, selectedStaff]) => {
      const staff = practitioners.length ? practitioners : selectedStaff;
      if (!staff.length) {
        return this._getFirstGap(gaps);
      }
      return this._findFirstGapForPractitioners(gaps, staff);
    }),
    map((summary) => summary?.event),
    filterUndefined(),
    distinctUntilChanged(isSameEvent)
  );

  readonly setFilteredGaps = this.updater(
    (state, filteredGaps: IScheduleSummaryEventable[]) => ({
      ...state,
      filteredGaps,
    })
  );

  readonly setSearchResults = this.updater(
    (state, searchResults: IScheduleSummaryEventable[]) => ({
      ...state,
      searchResults,
    })
  );

  readonly setPractice = this.updater(
    (state, practice: WithRef<IPractice>) => ({
      ...state,
      practice,
    })
  );

  readonly setSelectedGap = this.updater(
    (state, gap: IScheduleSummaryEventable | undefined) => {
      return {
        ...state,
        selectedGap: gap,
      };
    }
  );

  readonly clearSelectedGap = this.updater((state) => ({
    ...state,
    selectedGap: undefined,
  }));

  readonly setSearchFilters = this.updater(
    (state, searchFilters?: IGapSearch) => ({ ...state, searchFilters })
  );

  readonly displayInSideBar = this.updater(
    (state, displayInSideBar: boolean) => ({
      ...state,
      displayInSideBar,
    })
  );

  readonly setDisplayTab = this.updater((state, displayTab: GapsTabIndex) => ({
    ...state,
    displayTab,
  }));

  readonly displayGapsOnTimeline = this.updater(
    (state, displayGaps: boolean) => ({
      ...state,
      displayGapsOnTimeline: displayGaps,
    })
  );

  constructor(
    private _organisation: OrganisationService,
    private _stateNav: StateBasedNavigationService
  ) {
    super(initialState);
    this.loadAllGapCandidates(
      combineLatest([
        this._organisation.brand$.pipe(filterUndefined(), isRefChanged$()),
        this._organisation.practice$.pipe(filterUndefined(), isRefChanged$()),
        this._organisation.brandPractitioners$.pipe(isChanged$(isSameRef)),
      ])
    );
    this.loadPendingGaps(this.allGapCandidates$.pipe(isChanged$(isSameRef)));
    this.filterGapCandidates();
    this.setSearchOutOfRange(
      combineLatest([this.dateRange$, this.practice$.pipe(filterUndefined())])
    );

    this.selectPendingGap(
      combineLatest([
        this.selectedGap$.pipe(
          filterUndefined(),
          filter((gap) => !!gap.metadata.candidates?.length),
          distinctUntilKeyChanged('uid')
        ),
        this.gaps$,
      ])
    );

    this.hasActiveFilters$ = this.searchFilters$.pipe(
      map((searchFilters) => this._hasActiveFilters(searchFilters))
    );
  }

  displayGaps(display: boolean, tab: GapsTabIndex = GapsTabIndex.Gaps): void {
    this.displayGapsOnTimeline(display);
    this.displayInSideBar(display);
    this.setDisplayTab(tab);
  }

  async cleanUp(): Promise<void> {
    this.displayGapsOnTimeline(false);
    this.clearSelectedGap();
    this.displayInSideBar(false);
    await this.clearFilters();
  }

  async clearFilters(): Promise<void> {
    const currentSearchFilters = await snapshot(this.searchFilters$);
    this.setSearchResults([]);
    this.setSearchFilters(undefined);

    if (!currentSearchFilters) {
      return;
    }

    const timelineDateRange = await snapshot(this.dateRange$);
    const { dateRange, ...otherFilters } = currentSearchFilters;
    if (
      dateRange &&
      !this._hasActiveFilters(otherFilters) &&
      isSameTimePeriod(dateRange, timelineDateRange)
    ) {
      return;
    }

    await this.filterGaps({ dateRange: timelineDateRange });
  }

  async filterGaps(searchData: IGapSearch): Promise<void> {
    const gaps = await snapshot(this.gaps$);
    const searchResults = await snapshot(this.searchResults$);
    const searchDataWithDateRange = {
      ...searchData,
      dateRange: searchData.dateRange ?? (await snapshot(this.dateRange$)),
    };

    const filteredGaps = await this._filterGapsBySearchData(
      gaps,
      searchDataWithDateRange
    );
    this.setFilteredGaps(filteredGaps);

    const hasActiveFilters = await snapshot(this.hasActiveFilters$);
    this.setSearchResults(
      hasActiveFilters
        ? await this._filterGapsBySearchData(
            searchResults,
            searchDataWithDateRange
          )
        : []
    );
  }

  async displayGapOnTimeline(
    gap: IScheduleSummaryEventable,
    displayInSideBar: boolean = true
  ): Promise<void> {
    if (!isGapEventType(gap.event.type)) {
      return;
    }

    this.setSelectedGap(undefined);
    this.setSelectedGap(gap);
    this.displayInSideBar(displayInSideBar);
    this.displayGapsOnTimeline(true);
    this.setDisplayTab(GapsTabIndex.Gaps);

    await this._stateNav.practice(['schedule', 'timeline'], {
      queryParams: {
        from: toMoment(gap.event.from).format(ISO_DATE_FORMAT),
        to: toMoment(gap.event.to).format(ISO_DATE_FORMAT),
      },
      queryParamsHandling: 'merge',
    });
  }

  async getSearchResults(gapSearch: IGapSearch): Promise<void> {
    const results = await this._searchGapsFromScheduleSummaries(gapSearch);
    this.setSearchResults(results);
    this.setSearchFilters(gapSearch);
    await this.filterGaps(gapSearch);
  }

  private _hasActiveFilters(gapSearch?: IGapSearch): boolean {
    if (!gapSearch) {
      return false;
    }
    return Object.values(gapSearch).some((searchFilter) => {
      if (isArray(searchFilter)) {
        return searchFilter.length > 0;
      }
      return isObject(searchFilter)
        ? Object.values(searchFilter).some((subFilter) => !isNil(subFilter))
        : !isNil(searchFilter);
    });
  }

  private async _findFirstGapForPractitioners(
    gaps: IScheduleSummaryEventable<object>[],
    practitioners: WithRef<IStaffer>[]
  ): Promise<IScheduleSummaryEventable | undefined> {
    if (!gaps.length) {
      return this._getFirstGapEvent(practitioners);
    }
    return gaps.find((gap) =>
      practitioners.some((practitioner) =>
        Event.staff(gap.event).some((staff) => isSameRef(staff, practitioner))
      )
    );
  }

  private async _filterGapsBySearchData(
    gaps: IScheduleSummaryEventable[],
    searchData: IGapSearch
  ): Promise<IScheduleSummaryEventable[]> {
    const practice = await snapshot(this.practice$.pipe(filterUndefined()));
    const timezone = practice.settings.timezone;
    const { timeRange, duration, practitioners, dateRange } = searchData;
    return new GapFilterBuilder(gaps)
      .byDateRange(timezone, dateRange)
      .byPractitioners(practitioners)
      .byTimeRange(timezone, timeRange)
      .byDuration(timezone, duration)
      .build();
  }

  private async _getFirstGap(
    gaps: IScheduleSummaryEventable[]
  ): Promise<IScheduleSummaryEventable | undefined> {
    if (gaps.length) {
      return first(gaps);
    }
    return this._getFirstGapEvent();
  }

  private async _searchGapsFromScheduleSummaries(
    search: IGapSearch
  ): Promise<IScheduleSummaryEventable[]> {
    const practice = await snapshotDefined(this.practice$);
    const summaries = await this._getScheduleSummaries(
      practice,
      search.practitioners,
      search.dateRange
    );
    const gaps = await asyncForEach(summaries, (summary) =>
      buildGapEvents(summary, practice, search.practitioners)
    );
    return gaps.flat();
  }

  private async _getScheduleSummaries(
    practice: WithRef<IPractice>,
    practitioners: WithRef<IStaffer>[] = [],
    dateRange?: ITimePeriod
  ): Promise<IScheduleSummary[]> {
    if (practitioners.length < 20) {
      const summaries = await this._queryScheduleSummaries(
        practice,
        practitioners,
        dateRange
      );
      return summaries.filter((summary) => !!summary.gaps.length);
    }

    const groups = chunk(practitioners, 20);
    const allSummaries = (
      await asyncForEach(groups, (practitionerGroup) =>
        this._queryScheduleSummaries(practice, practitionerGroup, dateRange)
      )
    ).flat();
    return allSummaries.filter((summary) => !!summary.gaps.length);
  }

  private async _queryScheduleSummaries(
    practice: WithRef<IPractice>,
    practitioners: WithRef<IStaffer>[] = [],
    dateRange?: ITimePeriod
  ): Promise<IScheduleSummary[]> {
    const timezone = practice.settings.timezone;
    const queryConstraints = compact([
      where(
        'day',
        '>=',
        dateRange
          ? toISODate(dateRange.from, timezone)
          : toISODate(moment().tz(timezone))
      ),
      where(
        'day',
        '<=',
        dateRange
          ? toISODate(dateRange.to, timezone)
          : toISODate(
              moment()
                .tz(practice.settings.timezone)
                .add(DEFAULT_SCHEDULE_SUMMARY_PERIOD, 'months')
            )
      ),
      where('practice', '==', practice.ref),
      practitioners.length
        ? where(
            'staffer',
            'in',
            practitioners.map((staffer) => staffer.ref)
          )
        : undefined,
      orderBy('day', 'asc'),
    ]);

    return query(
      Practice.scheduleSummaryCol({
        ref: practice.ref,
      }),
      ...queryConstraints
    );
  }

  private async _getFirstGapEvent(
    practitioners: WithRef<IStaffer>[] = []
  ): Promise<IScheduleSummaryEventable | undefined> {
    const practice = await snapshotDefined(this.practice$);
    const firstSummary = first(
      await this._getScheduleSummaries(practice, practitioners)
    );
    if (!firstSummary) {
      return;
    }
    return first(await buildGapEvents(firstSummary, practice, practitioners));
  }

  private _getGapCandidates$([
    brand,
    practice,
    staff,
  ]: LoadGapCandidates): Observable<WithRef<ICandidateCalendarEvent>[]> {
    const dateRange = toTimePeriod(
      moment(),
      moment().add(DEFAULT_SCHEDULE_SUMMARY_PERIOD, 'months'),
      practice.settings.timezone
    );
    const participants = staff.map((staffer) => stafferToParticipant(staffer));

    return Brand.getGapCandidates$(
      brand,
      dateRange,
      [practice.ref],
      participants
    );
  }

  private async _getCandidateGapsPair(
    candidate: WithRef<ICandidateCalendarEvent>,
    scheduleSummary: WithRef<IScheduleSummary>
  ): Promise<ICandidateGapsPair> {
    const staffer = await snapshot(
      this._global.getStaffer$(Event.staff(candidate.event)[0].ref)
    );
    const practice = await snapshot(this.practice$.pipe(filterUndefined()));
    const gaps = Gap.filterCandidateGaps(
      await buildGapEvents(scheduleSummary, practice, [staffer]),
      candidate,
      practice.settings.timezone
    );

    return { candidate, gaps };
  }

  private _reduceCandidateGapsPairs(
    pairs: ICandidateGapsPair[]
  ): IScheduleSummaryEventable[] {
    return pairs.reduce((acc, { gaps, candidate }) => {
      gaps.map((gap) => {
        const existingGap = acc.find((storedGap) =>
          isSameEvent(storedGap.event, gap.event)
        );

        if (existingGap) {
          existingGap.metadata.candidates = [
            ...(existingGap.metadata.candidates ?? []),
            candidate,
          ];
        } else {
          acc.push({
            ...gap,
            metadata: { ...gap.metadata, candidates: [candidate] },
          });
        }
      });

      return acc;
    }, [] as IScheduleSummaryEventable[]);
  }

  private _filterGapsBySelectedStaff(
    summaries: IScheduleSummaryEventable[],
    selectedStaff: WithRef<IStaffer>[]
  ): IScheduleSummaryEventable[] {
    return summaries
      .filter(({ event }) => isGapEventType(event.type))
      .filter(
        ({ event }) =>
          !selectedStaff.length ||
          selectedStaff.some((staffer) =>
            event.participantRefs.some((ref) => isSameRef(ref, staffer))
          )
      );
  }

  private _filterCandidatesBySelectedGap(
    allCandidates: WithRef<ICandidateCalendarEvent>[],
    pendingGaps: IScheduleSummaryEventable[],
    selectedGap?: IScheduleSummaryEventable
  ): WithRef<ICandidateCalendarEvent>[] {
    if (!selectedGap) {
      return [];
    }

    const pendingGap = Gap.findPendingGapFromEvent(
      selectedGap.event,
      pendingGaps
    );
    if (!pendingGap?.metadata.candidates.length) {
      return [];
    }

    return allCandidates.filter((candidate) =>
      pendingGap.metadata.candidates.some((pendingGapCandidate) =>
        isSameRef(candidate, pendingGapCandidate)
      )
    );
  }
}
