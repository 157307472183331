import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  type OnDestroy,
} from '@angular/core';
import { getSchemaText, type RawSchema } from '@principle-theorem/editor';
import {
  type INotification,
  Notification,
  renderNotification,
} from '@principle-theorem/notifications';
import {
  BlueColourShading,
  filterUndefined,
  type IReffable,
  NotImplementedError,
  patchDoc,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  type INotificationConfig,
  NG_NOTIFICATION_CONFIG,
} from '../notification-config';
import { AnyExtension } from '@tiptap/core';

@Component({
  selector: 'pt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  content$: Observable<RawSchema>;
  extensions$: ReplaySubject<AnyExtension[]> = new ReplaySubject(1);
  distance$: Observable<string>;
  sliverColour$: Observable<string>;
  sliverTooltip$: Observable<string>;

  @HostBinding('style.display') display = 'block';
  @Input() canDisable = false;

  currentUser$: ReplaySubject<IReffable> = new ReplaySubject(1);
  preview$: ReplaySubject<boolean> = new ReplaySubject(1);
  notification$: ReplaySubject<WithRef<INotification>> = new ReplaySubject(1);

  @Input()
  set currentUser(currentUser: IReffable) {
    if (currentUser) {
      this.currentUser$.next(currentUser);
    }
  }

  @Input()
  set preview(preview: boolean) {
    if (preview) {
      this.preview$.next(preview);
    }
  }

  @Input()
  set extensions(extensions: AnyExtension[]) {
    if (extensions) {
      this.extensions$.next(extensions);
    }
  }

  @Input()
  set notification(notification: WithRef<INotification>) {
    if (notification) {
      this.notification$.next(notification);
    }
  }

  constructor(
    @Inject(NG_NOTIFICATION_CONFIG) private _config: INotificationConfig
  ) {
    this.content$ = combineLatest([this.notification$, this.currentUser$]).pipe(
      map(([notification, currentUser]) => {
        return renderNotification(
          notification,
          this._config.providers,
          currentUser
        );
      }),
      filterUndefined(),
      shareReplayCold()
    );

    this.distance$ = this.notification$.pipe(
      map((notification) => Notification.distance(notification))
    );
    this.sliverColour$ = this.notification$.pipe(
      map((notification) =>
        notification.viewed ? '' : new BlueColourShading().shades.a200
      )
    );
    this.sliverTooltip$ = this.notification$.pipe(
      map((notification) => (notification.viewed ? '' : 'Unread'))
    );

    this.content$
      .pipe(
        map((content) => !!getSchemaText(content)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((hasContent) => {
        if (hasContent) {
          this.display = 'block';
          return;
        }
        this.display = 'none';
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async markRead(notification: WithRef<INotification>): Promise<void> {
    await patchDoc(notification.ref, {
      viewed: true,
    });
  }

  disableNotification(_notification: WithRef<INotification>): void {
    throw new NotImplementedError('disableNotification not implemented');
  }
}
