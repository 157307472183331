import { Patient, stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  IDVACard,
  IDestinationEntityJobRunOptions,
  IFeeSchedule,
  IHealthFundCard,
  IPractice,
  ITranslationMap,
  PatientStatus,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedicareCard,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  ISO_DATE_FORMAT,
  getError,
  snapshotCombineLatest,
  toISODate,
  toMoment,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as he from 'he';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  IPatientMigrationData,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../../mappings/fee-schedules';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  FeeScheduleSourceEntity,
  IOasisFeeSchedule,
} from '../../source/entities/fee-schedule';
import {
  PatientSourceEntity,
  type IOasisPatient,
  type IOasisPatientFilters,
  type IOasisPatientTranslations,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { OasisPracticeMappingHandler } from '../mappings/practices';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { StafferDestinationEntity } from './staff';
import { ExistingPatientDestinationEntity } from './existing-patients';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: '',
  },
});

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<
    IOasisPatient,
    IOasisPatientTranslations,
    IOasisPatientFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  sourceFeeSchedules: IGetRecordResponse<IOasisFeeSchedule>[];
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<IOasisPatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    feeSchedules: new FeeScheduleSourceEntity(),
  };

  override destinationEntities = {
    staff: new StafferDestinationEntity(),
    existingPatients: new ExistingPatientDestinationEntity(),
  };

  customMappings = {
    practices: new OasisPracticeMappingHandler(),
    staff: new OasisStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practices$ = this.customMappings.practices.getRecords$(
      translationMapHandler
    );
    const brand$ = PracticeMigration.brand$(migration);
    const sourceFeeSchedules$ =
      this.sourceEntities.feeSchedules.getRecords$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$, brand$, practices$, sourceFeeSchedules$]),
    ]).pipe(
      map(([patientRecords, [staff, brand, practices, sourceFeeSchedules]]) =>
        patientRecords.map((sourcePatient) => ({
          sourcePatient,
          staff,
          brand,
          practices,
          sourceFeeSchedules,
        }))
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const sourcePatientId =
      this.sourceEntities.patients.getSourceRecordId(patient);

    try {
      return {
        sourcePatientId: sourcePatientId.toString(),
        createdAt: data.sourcePatient.data.translations.createdAt
          ? toTimestamp(
              toMoment(data.sourcePatient.data.translations.createdAt)
            )
          : toTimestamp(),
        patient: Patient.init({
          name: getName(patient),
          email: patient.email,
          contactNumbers: getPhone(patient),
          address: getAddress(patient),
          dateOfBirth: patient.dateOfBirth
            ? toISODate(moment.utc(patient.dateOfBirth, ISO_DATE_FORMAT))
            : undefined,
          gender: patient.gender,
          status: getStatus(patient),
          medicareCard: getMedicareCard(patient),
          referenceId: sourcePatientId.toString(),
          healthFundCard: getHealthcareCard(patient),
          dvaCard: getDVACard(patient),
          preferredPractice: patient.practiceId
            ? await getPreferredPractice(
                patient.practiceId.toString(),
                data.practices
              )
            : undefined,
          preferredDentist: patient.practitionerId
            ? await getPreferredProvider(
                patient.practitionerId.toString(),
                data.staff
              )
            : undefined,
          preferredHygienist: patient.hygienistId
            ? await getPreferredProvider(
                patient.hygienistId.toString(),
                data.staff
              )
            : undefined,
          preferredFeeSchedule: await getPrefferedFeeSchedule(
            patient,
            translationMap,
            data.sourceFeeSchedules
          ),
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }
}

function getStatus(patient: IOasisPatient): PatientStatus {
  if (patient.isInactive) {
    return PatientStatus.Inactive;
  }
  return PatientStatus.Active;
}

function getAddress(patient: IOasisPatient): string {
  const addressFields = compact([
    patient.addressLine1?.trim(),
    patient.addressLine2?.trim(),
    patient.suburb?.trim(),
    patient.postCode?.toString().trim(),
  ]);

  if (!addressFields.length) {
    return '';
  }

  return addressFields.join(', ');
}

export function getPhone(patient: IOasisPatient): IContactNumber[] {
  const contactNumbers: IContactNumber[] = [
    { label: 'mobile', number: patient.mobile ?? '' },
    { label: 'home', number: patient.homePhone ?? '' },
    { label: 'work', number: patient.workPhone ?? '' },
  ];
  return contactNumbers.filter((contactNumber) => !!contactNumber.number);
}

export function getName(patient: IOasisPatient): string {
  const preferredName = patient.nickname?.trim() ?? '';

  if (!preferredName || preferredName === patient.firstName) {
    return he.decode(`${patient.firstName} ${patient.lastName}`.trim());
  }
  return he.decode(
    `${patient.firstName} (${preferredName}) ${patient.lastName}`.trim()
  );
}

function getMedicareCard(patient: IOasisPatient): IMedicareCard {
  return {
    number: patient.medicareNumber ?? '',
    subNumerate: patient.medicareSubNumerate ?? '',
  };
}

function getHealthcareCard(patient: IOasisPatient): IHealthFundCard {
  return {
    fundCode: patient.healthFundName ?? '',
    membershipNumber: '',
    memberNumber: '',
  };
}

function getDVACard(patient: IOasisPatient): IDVACard {
  return {
    number: patient.dvaCardNumber ?? '',
  };
}

async function getPrefferedFeeSchedule(
  patient: IOasisPatient,
  translationMap: TranslationMapHandler,
  sourceFeeSchedules: IGetRecordResponse<IOasisFeeSchedule>[]
): Promise<INamedDocument<IFeeSchedule> | undefined> {
  const feeScheduleAlias = patient.preferredFeeSchedule;
  if (!feeScheduleAlias) {
    return;
  }

  const entity = new FeeScheduleSourceEntity();
  const preferredFeeSchedule = sourceFeeSchedules.find((feeSchedule) =>
    feeSchedule.data.data.preferredFeeScheduleCodes.includes(feeScheduleAlias)
  );

  if (!preferredFeeSchedule) {
    return;
  }

  const feeScheduleMap = await translationMap.getDestination<IFeeSchedule>(
    entity.getSourceRecordId(preferredFeeSchedule.data.data).toString(),
    FEE_SCHEDULE_RESOURCE_TYPE
  );

  if (!feeScheduleMap) {
    return;
  }

  return toNamedDocument(await Firestore.getDoc(feeScheduleMap));
}

async function getPreferredProvider(
  preferredProviderId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer> | undefined> {
  const practitionerMap = staff.find(
    (staffer) => staffer.sourceIdentifier === preferredProviderId
  );

  if (!practitionerMap?.destinationIdentifier) {
    return;
  }

  return stafferToNamedDoc(
    await Firestore.getDoc(practitionerMap.destinationIdentifier)
  );
}

async function getPreferredPractice(
  preferredPracticeId: string,
  practices: WithRef<ITranslationMap<IPractice>>[]
): Promise<INamedDocument<IPractice> | undefined> {
  const practitionerMap = practices.find(
    (practice) => practice.sourceIdentifier === preferredPracticeId
  );

  if (!practitionerMap?.destinationIdentifier) {
    return;
  }

  return toNamedDocument(
    await Firestore.getDoc(practitionerMap.destinationIdentifier)
  );
}
