import {
  IAppointment,
  IBrand,
  IEvent,
  IEventable,
  IPractice,
  isAppointment,
  isEvent,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, ITimePeriod, Timezone } from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export class TimezoneResolver {
  static cachedTimezones: Record<string, Timezone> = {};

  static async fromEvent(
    data: IEvent | IEventable | IAppointment
  ): Promise<Timezone> {
    if (isEvent(data)) {
      return getTimezone(data.practice.ref);
    }

    if (isAppointment(data)) {
      if (data.event) {
        return getTimezone(data.event.practice.ref);
      }
      return getTimezone(data.practice.ref);
    }

    return getTimezone(data.event.practice.ref);
  }

  static async fromPracticeRef(
    ref: DocumentReference<IPractice>
  ): Promise<Timezone> {
    return getTimezone(ref);
  }

  static async fromBrandRef(ref: DocumentReference<IBrand>): Promise<Timezone> {
    return getTimezone(ref);
  }

  static fromMoment(value: Moment | ITimePeriod): Timezone | undefined {
    if (moment.isMoment(value)) {
      return value.tz() as Timezone;
    }

    return value.from.tz() as Timezone;
  }
}

async function getTimezone(
  ref: DocumentReference<IPractice | IBrand>
): Promise<Timezone> {
  const cachePath = ref.path;
  const cachedTimezone = TimezoneResolver.cachedTimezones[cachePath];
  if (cachedTimezone) {
    return cachedTimezone;
  }
  const timezoneDoc = await getDoc(ref);
  const timezone = timezoneDoc.settings.timezone;
  TimezoneResolver.cachedTimezones[cachePath] = timezone;
  return timezone;
}
