import { Injectable } from '@angular/core';
import { type ILoggerMetadataResolver } from '@principle-theorem/ng-shared';
import { OrganisationService } from './organisation.service';
import * as LogRocket from 'logrocket';
import { resolveMetadataDocPath } from '@principle-theorem/ng-shared';

interface IPrincipleLoggerMetadata {
  logRocketSessionUrl?: string;
  organisation?: string;
  brand?: string;
  practice?: string;
  user?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrincipleLoggerMetadataResolverService
  implements ILoggerMetadataResolver<IPrincipleLoggerMetadata>
{
  constructor(private _organisation: OrganisationService) {}

  async resolveMetadata(): Promise<IPrincipleLoggerMetadata> {
    return {
      logRocketSessionUrl: LogRocket.sessionURL || undefined,
      organisation: await resolveMetadataDocPath(
        this._organisation.organisation$
      ),
      brand: await resolveMetadataDocPath(this._organisation.brand$),
      practice: await resolveMetadataDocPath(this._organisation.practice$),
      user: await resolveMetadataDocPath(this._organisation.user$),
    };
  }
}
