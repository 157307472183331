import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
} from '@angular/router';
import { type Observable } from 'rxjs';
import { findInheritedRouteData } from './routing';

export abstract class BaseResolver<T> {
  abstract resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<T> | Promise<T> | T;

  protected _findInheritedRouteData<R>(
    route: ActivatedRouteSnapshot,
    key: string
  ): R | undefined {
    return findInheritedRouteData<R>(route, key);
  }
}
