import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IInvoice } from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IResolvedTransactionOption,
  TransactionProviderError,
  hasGetInfo,
  isRefundTransactionProvider,
} from '../transaction-providers/transaction-provider';
import { TransactionProviders } from '../transaction-providers/transaction-providers.service';

@Component({
    selector: 'pr-add-transaction-menu-button',
    templateUrl: './add-transaction-menu-button.component.html',
    styleUrls: ['./add-transaction-menu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddTransactionMenuButtonComponent {
  private _adding$ = new BehaviorSubject<boolean>(false);
  private _canDo$: Observable<boolean>;
  trackByInfo = TrackByFunctions.variable<string>();
  refund$ = new BehaviorSubject<boolean>(false);
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  option$ = new ReplaySubject<IResolvedTransactionOption>(1);
  info$: Observable<string[]>;
  isDisabled$: Observable<boolean>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set option(option: IResolvedTransactionOption) {
    if (option) {
      this.option$.next(option);
    }
  }

  @Input()
  set refund(refund: boolean) {
    this.refund$.next(coerceBooleanProperty(refund));
  }

  constructor(public transactions: TransactionProviders) {
    this._canDo$ = combineLatest([
      this.invoice$,
      this.option$,
      this.refund$,
    ]).pipe(
      switchMap(([invoice, option, isRefund]) =>
        this._getCanDo$(invoice, option, isRefund)
      )
    );
    this.info$ = combineLatest([
      this.invoice$,
      this.option$,
      this.refund$,
    ]).pipe(
      switchMap(([invoice, option, isRefund]) =>
        this._getInfo$(invoice, option, isRefund)
      )
    );
    this.isDisabled$ = combineLatest([this._canDo$, this._adding$]).pipe(
      map(([canCapture, adding]) => !canCapture || adding)
    );
  }

  async capture(): Promise<void> {
    const isDisabled = await snapshot(this.isDisabled$);
    this._adding$.next(true);
    if (isDisabled) {
      this._adding$.next(false);
      return;
    }
    const option = await snapshot(this.option$);
    const invoice = await snapshot(this.invoice$);
    const isRefund = await snapshot(this.refund$);
    if (isRefund) {
      await this.transactions.refund(option.provider, invoice);
      this._adding$.next(false);
      return;
    }
    await this.transactions.capture(option.provider, invoice);
    this._adding$.next(false);
  }

  private _getCanDo$(
    invoice: WithRef<IInvoice>,
    option: IResolvedTransactionOption,
    isRefund: boolean
  ): Observable<boolean> {
    if (!isRefund) {
      return option.provider.canCapture$(invoice);
    }
    if (!isRefundTransactionProvider(option.provider)) {
      throw new TransactionProviderError(
        `Provider does not support refunds: ${option.provider.providerId}`
      );
    }
    return option.provider.canRefund$(invoice);
  }

  private _getInfo$(
    invoice: WithRef<IInvoice>,
    option: IResolvedTransactionOption,
    isRefund: boolean
  ): Observable<string[]> {
    if (!isRefund) {
      return hasGetInfo(option.provider)
        ? option.provider.getInfo$(invoice).pipe(map((info) => [info]))
        : of([]);
    }
    if (!isRefundTransactionProvider(option.provider)) {
      throw new Error(
        `Provider does not support refunds: ${option.provider.providerId}`
      );
    }
    return option.provider.refundInfo$(invoice);
  }
}
