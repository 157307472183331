import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  TreatmentStepStatus,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-plan-header',
  templateUrl: './treatment-plan-header.component.html',
  styleUrls: ['./treatment-plan-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanHeaderComponent {
  treatmentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  incompleteSteps$: Observable<string>;
  @Input() canAddStep = false;
  @Output() addStep = new EventEmitter<void>();

  @Input()
  set treatmentPlan(treatmentPlan: WithRef<ITreatmentPlan>) {
    if (treatmentPlan) {
      this.treatmentPlan$.next(treatmentPlan);
    }
  }

  constructor() {
    this.incompleteSteps$ = this.treatmentPlan$.pipe(
      switchMap((treatmentPlan) => TreatmentPlan.orderedSteps$(treatmentPlan)),
      map((steps) => {
        const incompleteSteps = steps.filter(
          (step) => step.status === TreatmentStepStatus.Incomplete
        );
        return `${incompleteSteps.length}/${steps.length}`;
      })
    );
  }
}
