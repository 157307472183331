import {
  IServiceCodeEntry,
  IServiceCodeGroup,
  ServiceCode,
  ServiceCodeGroupType,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';
import { ServiceProviderHandler } from './service-provider';

export class ServiceCodeGroupBuilder {
  constructor(private _type: ServiceCodeType) {}

  code(code: ServiceCode, quantity: number = 1): IServiceCodeEntry {
    const found = ServiceProviderHandler.resolveServiceCode(this._type, code);
    if (!found) {
      throw new Error(`Service Code not found ${code}`);
    }
    return {
      type: this._type,
      code: found.code,
      quantity,
    };
  }

  group(
    type: ServiceCodeGroupType,
    serviceCodes: IServiceCodeEntry[]
  ): IServiceCodeGroup {
    return { uid: uuid(), type, serviceCodes };
  }

  default(...codes: IServiceCodeEntry[]): IServiceCodeGroup {
    return this.required(...codes);
  }

  required(...codes: IServiceCodeEntry[]): IServiceCodeGroup {
    return this.group(ServiceCodeGroupType.Required, codes);
  }

  exclusive(...codes: IServiceCodeEntry[]): IServiceCodeGroup {
    return this.group(ServiceCodeGroupType.Exclusive, codes);
  }

  optional(...codes: IServiceCodeEntry[]): IServiceCodeGroup {
    return this.group(ServiceCodeGroupType.Optional, codes);
  }
}
