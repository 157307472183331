import { Injectable, type OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  type Data,
  type Event,
  Router,
} from '@angular/router';
import { snapshot } from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { map, skipWhile, takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class BaseCurrentModel<T> implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  model$: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(
    undefined
  );

  constructor(protected _router: Router) {
    const model: T | undefined = this.findModel(
      this._router.routerState.snapshot.root
    );

    this.model$.next(model);

    this._router.events
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((event: Event) => {
        if (!(event instanceof ActivationEnd)) {
          return;
        }

        const eventModel: T | undefined = this.findModel(event.snapshot);
        this.model$.next(eventModel);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  asObservable$(): Observable<T | undefined> {
    return this.model$;
  }

  /**
   * Returns current model as Observable, skipping undefined values
   *
   * @deprecated use asObservable$ instead
   */
  asObservable(): Observable<T> {
    return this.model$.pipe(
      skipWhile((model: T | undefined) => model === undefined),
      map((model: T | undefined) => model as unknown as T)
    );
  }

  toPromise(): Promise<T> {
    return snapshot(this.asObservable());
  }

  findModel(routeSnapshot: ActivatedRouteSnapshot): T | undefined {
    return (
      this._findInChildren(routeSnapshot) || this._findInParent(routeSnapshot)
    );
  }

  protected abstract _propertyAccessor(data: Data): T | undefined;

  private _findInChildren(
    routeSnapshot: ActivatedRouteSnapshot
  ): T | undefined {
    const model: T | undefined = this._propertyAccessor(routeSnapshot.data);

    if (model) {
      return model;
    }

    if (routeSnapshot.children.length) {
      const models: (T | undefined)[] = routeSnapshot.children.map(
        (childSnapshot: ActivatedRouteSnapshot) => {
          return this._findInChildren(childSnapshot);
        }
      );

      return first(compact(models));
    }
  }

  private _findInParent(routeSnapshot: ActivatedRouteSnapshot): T | undefined {
    const model: T | undefined = this._propertyAccessor(routeSnapshot.data);

    if (model) {
      return model;
    }

    if (routeSnapshot.parent) {
      return this._findInParent(routeSnapshot.parent);
    }
  }
}
