<mat-toolbar color="accent">Select Claim Estimate Provider</mat-toolbar>
<mat-dialog-content>
  <div>
    <ng-container *ngFor="let provider of providers; trackBy: trackByProvider">
      <pt-action-list-button
        *ngIf="provider.isEnabled$ | async"
        [imageUrl]="provider.imageUrl"
        [icon]="provider.icon"
        [label]="provider.label"
        [mat-dialog-close]="provider"
      />
    </ng-container>
  </div>

  <button type="button" mat-stroked-button class="w-full" mat-dialog-close>
    Cancel
  </button>
</mat-dialog-content>
