<mat-toolbar color="accent">{{ title }}</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="m-4 flex flex-col gap-4">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Available when</mat-label>
        <mat-select formControlName="eventTypes" multiple required>
          @for (eventType of eventTypes; track eventType.value) {
            <mat-option [value]="eventType.value">
              {{ eventType.label }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Use when less than</mat-label>
        <input
          matInput
          type="number"
          min="0"
          formControlName="hrsBeforeAppointment"
          placeholder="x"
        />

        <span matTextSuffix>hours before appointment</span>

        <mat-hint align="end">
          Leave this blank to apply the default based on its order in the list
        </mat-hint>
      </mat-form-field>

      <div class="flex flex-col">
        <mat-checkbox formControlName="moveToSameDayDefault">
          Use as default reason when rescheduling appointment on the same day
        </mat-checkbox>
        <mat-checkbox formControlName="fillGapDefault">
          Use as default reason when filling a gap
        </mat-checkbox>
      </div>

      <h3 class="!m-0 text-base !font-bold">Display Options</h3>
      <pr-edit-patient-metadata-display
        #displayForm="prEditPatientMetadataDisplay"
        hideFormat
        hideHasValueSelector
        [value]="metadataDisplayFormValue$ | async"
        (valueChanges)="metadataDisplayFormValue$.next($event)"
      />
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid || displayForm.form.invalid"
      >
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
