<div fxLayout="column" class="timeline-container">
  <div class="top-line" [ngClass]="{ 'first-or-last': first }"></div>
  <div class="circle h-3.5 w-3.5 rounded-full" [ngClass]="{ 'filled-in': selected }">
    <mat-icon class="!flex !items-center !justify-center" *ngIf="selected">star</mat-icon>
  </div>
  <div fxFlex class="bottom-line" [ngClass]="{ 'first-or-last': last }"></div>
</div>

<div
  fxFlex
  [fxHide]="closed"
  [fxHide.gt-sm]="false"
  class="text-section mat-caption"
>
  <ng-content></ng-content>
</div>
