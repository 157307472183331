import * as moment from 'moment-timezone';
import { type ITimePeriod } from './time-bucket/interfaces';

export function durationToHumanisedTime(time: moment.Duration): string {
  if (time.asMonths() >= 1 && time.asMonths() < 12 && time.months()) {
    const months =
      time.asMonths() % 1 === 0 ? time.asMonths() : time.asMonths().toFixed(1);
    const monthLabel = months === 1 ? 'month' : 'months';
    return `${months} ${monthLabel}`;
  }

  if (time.asDays() >= 7 && time.asDays() < 30 && time.days()) {
    const weeks = time.weeks() < 2 ? 'week' : 'weeks';
    const daysInWeek = time.days() % 7;
    const days = daysInWeek === 1 ? 'day' : 'days';
    if (daysInWeek) {
      return `${time.weeks()} ${weeks} ${Math.ceil(daysInWeek)} ${days}`;
    }
    return `${time.weeks()} ${weeks}`;
  }

  if (time.asHours() < 24 && time.hours()) {
    const hours = time.hours() < 2 ? 'hour' : 'hours';
    const minutes = time.minutes() === 1 ? 'minute' : 'minutes';
    if (time.minutes()) {
      return `${time.hours()} ${hours} ${time.minutes()} ${minutes}`;
    }
    return `${time.hours()} ${hours}`;
  }

  if (time.asMinutes() < 60 && time.minutes()) {
    const minutes = time.minutes() === 1 ? 'minute' : 'minutes';
    return `${time.minutes()} ${minutes}`;
  }

  return time.humanize();
}

export function timePeriodToHumanisedTime(range: ITimePeriod): string {
  return durationToHumanisedTime(moment.duration(range.to.diff(range.from)));
}
