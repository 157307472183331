import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CalendarFacade } from '@principle-theorem/ng-calendar/store';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ISO_DATE_FORMAT,
  snapshot,
  type ITimePeriod,
  type Timezone,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { Subject, type Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { DatePickerDisplayBloc } from './date-picker-display-bloc';
import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';

interface ILink {
  label: string;
  path: string;
}

@Component({
  selector: 'pr-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByLink = TrackByFunctions.label<ILink>();
  links: ILink[] = [
    { label: 'Dashboard', path: 'dashboard' },
    { label: 'Timeline', path: 'timeline' },
    { label: 'Calendar', path: 'calendar' },
    { label: 'History', path: 'history' },
  ];
  datePickerDisplayBloc: DatePickerDisplayBloc;
  day$: Observable<Moment>;
  unit$: Observable<CalendarUnit>;
  open$: Observable<boolean>;
  range$: Observable<ITimePeriod>;
  isCalendarView$: Observable<boolean>;
  timezone$: Observable<Timezone>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _calendarFacade: CalendarFacade,
    private _timezone: TimezoneService
  ) {
    this.unit$ = this._calendarFacade.unit$;
    this.range$ = this._calendarFacade.range$;
    this.day$ = this._calendarFacade.range$.pipe(map((range) => range.from));
    this.isCalendarView$ = this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url),
      startWith(this._router.url),
      map((url) => url.includes('/calendar'))
    );
    this.timezone$ = this._timezone.currentPractice.timezone$;

    this.datePickerDisplayBloc = new DatePickerDisplayBloc(
      this.isCalendarView$,
      this.unit$
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async dateRangeChange(range: ITimePeriod): Promise<void> {
    const currentRange = await snapshot(this.range$);

    if (
      currentRange.from.isSame(range.from) &&
      currentRange.to.isSame(range.to)
    ) {
      return;
    }

    if (!range.from.isValid()) {
      range.from = currentRange.from;
    }

    if (!range.to.isValid()) {
      range.to = currentRange.to;
    }

    await this._router.navigate([], {
      relativeTo: this._route,
      queryParamsHandling: 'merge',
      queryParams: {
        from: range.from.format(ISO_DATE_FORMAT),
        to: range.to.format(ISO_DATE_FORMAT),
      },
    });
  }
}
