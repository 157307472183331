import { type OptionFinderQuery } from '../event/event-option-finder';
import * as moment from 'moment-timezone';

export const DEFAULT_FROM_DATE: moment.Moment = moment().startOf('day');

export interface IAppointmentFilterOptions {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  fromTime?: string;
  toTime?: string;
}

export function getDefaultFilterOptions(
  isOnlineBookings = false
): IAppointmentFilterOptions {
  const defaultDayRange = isOnlineBookings ? 4 : 14;
  return {
    fromDate: DEFAULT_FROM_DATE,
    toDate: moment().add(defaultDayRange, 'days').startOf('day'),
  };
}

export function getFilterOptionsRangeSubject(
  options: IAppointmentFilterOptions
): OptionFinderQuery {
  return [
    moment(options.fromDate).startOf('day'),
    moment(options.toDate).endOf('day'),
  ];
}
