import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITemplateContext } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

interface IKeyValue {
  key: string;
  value: string;
}

@Component({
    selector: 'pr-context-preview',
    templateUrl: './context-preview.component.html',
    styleUrls: ['./context-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContextPreviewComponent {
  context$ = new ReplaySubject<IKeyValue[]>(1);
  trackByContext = TrackByFunctions.field<IKeyValue>('key');

  @Input()
  set context(context: ITemplateContext) {
    if (context) {
      this.context$.next(
        Object.entries(context).map(([key, value]) => ({
          key,
          value: String(value),
        }))
      );
    }
  }
}
