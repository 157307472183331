<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Acquisitions"
 />

<div class="layout-padding">
  <div fxLayout="row" fxLayoutAlign="start center" class="layout-margin">
    <h2 fxFlex>Select Date Range</h2>
    <pt-date-range-form [from]="from" [to]="to" (changed)="updateDates($event)" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <div class="chart" fxFlex fxLayout="column">
      <pr-basic-chart fxFlex [chart]="channelsChart" />
      <p class="mat-caption">
        Based on new patient appointments completed within the selected date
        range.
      </p>
    </div>

    <!-- <div class="chart" fxFlex>
      <pr-basic-chart fxFlex [chart]="costPerConversionChart"></pr-basic-chart>
    </div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <div class="chart" fxFlex fxLayout="column">
      <pr-basic-chart fxFlex [chart]="patientsChart" />
      <p class="mat-caption">
        Based on appointments completed within the selected date range.
      </p>
    </div>

    <div class="chart" fxFlex fxLayout="column" fxLayoutAlign="start center">
      <h2 class="mat-subheading">Total New Patients</h2>
      <pr-data-points-summary
        fxFlex
        *ngIf="patientsTotal"
        [chart]="patientsTotal.chart.chartBuilder"
        [measure]="patientsTotal.measure"
        [size]="162"
        [showHistory]="false"
       />
    </div>

    <!-- <div class="chart" fxFlex>
      <pr-basic-chart fxFlex [chart]="conversionsChart"></pr-basic-chart>
    </div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <div class="chart" fxFlex fxLayout="column">
      <pr-basic-chart fxFlex [chart]="bookedOnlineChart" />
      <p class="mat-caption">
        Based on all appointments completed within the selected date range.
      </p>
    </div>

    <div class="chart" fxFlex fxLayout="column" fxLayoutAlign="start center">
      <h2 class="mat-subheading">Total Booked Online</h2>
      <pr-data-points-summary
        fxFlex
        *ngIf="bookedOnlineTotal"
        [chart]="bookedOnlineTotal.chart.chartBuilder"
        [measure]="bookedOnlineTotal.measure"
        [size]="162"
        [showHistory]="false"
       />
    </div>

    <!-- <div class="chart" fxFlex> -->
    <!-- <pr-single-value-chart
        [chart]="bookedOnlineChart"
        [measure]=""
      ></pr-single-value-chart> -->
    <!-- </div> -->
  </div>

  <!-- <div class="layout-margin table-breakdowns" fxLayout="row">
    <pr-table-chart
      fxFlex
      [chart]="tableData"
      [replacementHeaders]="replacementHeaders"
    ></pr-table-chart>
  </div> -->
</div>
