<div class="layout-margin">
  <h2>Debt Collections</h2>
  <pr-chart [chartBuilder]="collectionsChart" />
</div>

<div class="layout-margin">
  <pr-table-chart
    [chart]="collectionsTable"
    [replacementHeaders]="replacementHeaders"
   />
</div>
