import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  HicapsConnect,
  HicapsConnectExtended,
  IPMSHicapsConnectConfig,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import {
  NameHelpers,
  resolveClaimItems,
  toClaimableItems,
  toSingleClaimItems,
} from '@principle-theorem/principle-core';
import {
  IClaimableItem,
  IHealthcareClaim,
  IHicapsConnectHealthFundTransactionExtendedData,
  IHicapsConnectTerminal,
  IInvoice,
  IPatient,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { IHealthFundMemberNumberDialogData } from '../../transaction-components/health-fund-member-number-dialog/health-fund-member-number-dialog.component';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

interface IClaimData {
  request: HicapsConnect.ClaimRequest;
  extendedData: HicapsConnectExtended.SendClaimRequest;
}

interface IClaimCancelData {
  request: HicapsConnect.ClaimCancelRequest;
  extendedData: HicapsConnectExtended.SendClaimCancelRequest;
}

interface IClaimQuoteData {
  request: HicapsConnect.QuoteRequest;
  extendedData: HicapsConnectExtended.SendClaimRequest;
}

export class HicapsConnectHealthFundRequestBuilder {
  static buildClaim(
    config: IPMSHicapsConnectConfig,
    patient: WithRef<IPatient>,
    invoice: IInvoice,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IHealthFundMemberNumberDialogData,
    serviceDate: Moment
  ): IClaimData | undefined {
    if (!claim.providerData) {
      return;
    }

    const claimItems = toSingleClaimItems(
      toClaimableItems(resolveClaimItems(invoice, claim))
    );

    const patientId = formData.memberNumber;

    const request: HicapsConnect.ClaimRequest = {
      ...HicapsConnectHelpers.buildBaseRequest(config, terminal),
      ProviderNumberId: claim.providerData.providerNumber,
      MembershipId: patientId,
      PrintReceiptOnTerminal: true,
      TransactionAmount: 0,
    };

    const extendedData: HicapsConnectExtended.SendClaimRequest = {
      addClaimLine: this.addClaimLines(claimItems, serviceDate, patientId),
      addPatientName: this.addPatientNames(patient, patientId),
    };

    return { request, extendedData };
  }

  static buildClaimCancel(
    config: IPMSHicapsConnectConfig,
    patient: WithRef<IPatient>,
    _invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IHealthFundMemberNumberDialogData,
    transaction: WithRef<
      ITransaction<IHicapsConnectHealthFundTransactionExtendedData>
    >
  ): IClaimCancelData | undefined {
    if (!claim.providerData) {
      return;
    }

    const response = transaction.extendedData?.response;
    const isSuccess = PrincipleHicapsConnect.isSuccessResponse();
    if (!response || !isSuccess(response) || !response.data.RrnNumber) {
      return;
    }

    const patientId = formData.memberNumber;

    const request: HicapsConnect.ClaimCancelRequest = {
      ...HicapsConnectHelpers.buildBaseRequest(config, terminal),
      ProviderNumberId: claim.providerData.providerNumber,
      TransactionAmount: roundTo2Decimals(transaction.amount),
      RrnNumber: response.data.RrnNumber,
      PrintReceiptOnTerminal: true,
    };

    const extendedData: HicapsConnectExtended.SendClaimCancelRequest = {
      addPatientName: this.addPatientNames(patient, patientId),
    };

    return { request, extendedData };
  }

  static buildClaimQuote(
    config: IPMSHicapsConnectConfig,
    patient: WithRef<IPatient>,
    invoice: IInvoice,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IHealthFundMemberNumberDialogData,
    serviceDate: Moment
  ): IClaimQuoteData | undefined {
    return this.buildClaim(
      config,
      patient,
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
  }

  static addPatientNames(
    patient: IPatient,
    patientId: string
  ): HicapsConnectExtended.AddPatientName[] {
    return [
      {
        patientId,
        patientName: NameHelpers.fullName(patient.name),
      },
    ];
  }

  static addClaimLines(
    items: IClaimableItem[],
    serviceDate: Moment,
    patientId: string
  ): HicapsConnectExtended.AddClaimLine[] {
    return items.map(
      (item): HicapsConnectExtended.AddClaimLine =>
        this.toAddClaimLine(item, serviceDate, patientId)
    );
  }

  static toAddClaimLine(
    item: IClaimableItem,
    serviceDate: Moment,
    patientId: string
  ): HicapsConnectExtended.AddClaimLine {
    return {
      patientId,
      itemNumber: item.serviceCode.code,
      bodyPart: item.serviceCode.toothId ?? '',
      serviceDate: serviceDate.format(`YYYY-MM-DDTHH:mm:ss.SSS`),
      fee: roundTo2Decimals(item.serviceCode.amount),
    };
  }
}
