import {
  IAppointment,
  IChat,
  IInteractionData,
  IInteractionV2,
  IInteractiveResource,
  IInvoice,
  IPatient,
  IPrincipleMention,
  isAppointment,
  isBaseInvoice,
  isChat,
  isInteractable,
  isPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  isWithRef,
  patchDoc,
  WithRef,
} from '@principle-theorem/shared';
import { Appointment } from '../appointment/appointment';
import { Invoice } from '../invoice/invoice';
import { fromMention } from '../mention/mention';
import { Patient } from '../patient/patient';
import { Chat } from './chat';

export async function addInteractions(
  data: IInteractionData,
  interaction: IInteractionV2
): Promise<void> {
  await addContactInteraction(data.contact, interaction);
  if (data.resource) {
    await addResourceInteraction(data.resource, interaction);
  }
}

async function addResourceInteraction(
  resource: IInteractiveResource,
  interaction: IInteractionV2
): Promise<void> {
  if (resource.add) {
    await resource.add(interaction);
  }
}

async function addContactInteraction(
  contact: IPrincipleMention,
  interaction: IInteractionV2
): Promise<void> {
  const item = await fromMention(contact);
  if (isPatient(item)) {
    const patient = item as WithRef<IPatient>;
    await addDoc(Patient.historyCol(patient), interaction);
    return;
  }
  if (isAppointment(item)) {
    const appointment = item as WithRef<IAppointment>;
    await addDoc(Appointment.interactionCol(appointment), interaction);
    return;
  }
  if (isChat(item)) {
    const chat = item as WithRef<IChat>;
    await addDoc(Chat.interactionCol(chat), interaction);
    return;
  }
  if (isWithRef(item) && isBaseInvoice(item)) {
    return Invoice.addInteraction(item as WithRef<IInvoice>, interaction);
  }
  if (isInteractable(item)) {
    await patchDoc(item.ref, {
      interactions: [...item.interactions, interaction],
    });
  }
}

export interface IInteractionError {
  message: string;
}
