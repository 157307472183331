import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import {
  ITypesensePatient,
  Organisation,
  PrincipleTypesenseCollection,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  IOrganisation,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ITypesenseConfig, Typesense } from '@principle-theorem/typesense';
import { compact, isString } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';

export interface IPatientBlockFilters {
  statuses?: PatientStatus[];
  showDuplicate: boolean;
  showDeleted: boolean;
  dateOfBirthMatch?: string;
}

export class TypesensePatientListBloc {
  results$: Observable<SearchResponse<ITypesensePatient>>;

  constructor(
    private _config: ITypesenseConfig,
    private _typesense: TypesenseSearchService,
    queryBy: string | string[],
    organisation$: Observable<WithRef<IOrganisation>>,
    brand$: Observable<WithRef<IBrand>>,
    search$: Observable<string>,
    filters$: Observable<IPatientBlockFilters>,
    page$ = of(1),
    numberOfResults$ = of(50),
    sortBy?: string | string[],
    extraOptions?: Partial<SearchParams>
  ) {
    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      filters$.pipe(
        map((filters) =>
          compact([
            statusesFilter(filters.statuses),
            duplicateFilter(filters.showDuplicate),
            deletedFilter(filters.showDeleted),
            dateOfBirthFilter(filters.dateOfBirthMatch),
          ])
        )
      ),
      page$,
      numberOfResults$,
    ]).pipe(
      map(([search, filters, page, numberOfResults]) => ({
        q: search,
        query_by: isString(queryBy) ? queryBy : queryBy.join(','),
        per_page: numberOfResults,
        page,
        filter_by: filters.join(' && '),
        sort_by: sortBy ?? undefined,
        ...extraOptions,
      }))
    );

    this.results$ = combineLatest([organisation$, brand$]).pipe(
      switchMap(([organisation, brand]) =>
        this._typesense.query$<ITypesensePatient>(
          Organisation.integrationCol(organisation),
          Typesense.getScopedCollectionName(
            [organisation.ref, brand.ref],
            PrincipleTypesenseCollection.Patients
          ),
          searchParams$,
          this._config
        )
      )
    );
  }
}

function statusesFilter(statuses?: PatientStatus[]): string | undefined {
  return statuses?.length ? `status:[${statuses.join(',')}]` : undefined;
}

function deletedFilter(showDeleted: boolean): string | undefined {
  return showDeleted ? `deleted:=true` : `deleted:=false`;
}

function duplicateFilter(showDuplicate: boolean): string | undefined {
  return showDuplicate ? `isDuplicate:=true` : `isDuplicate:!=true`;
}

function dateOfBirthFilter(
  dateOfBirth: string | undefined
): string | undefined {
  return dateOfBirth ? `dateOfBirth:=${dateOfBirth}` : undefined;
}
