import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  ManualTransactionType,
  TransactionAllocation,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IStaffer,
  type IManualExtendedData,
  type IManualTransactionType,
  type ITransaction,
  IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  mergeDayAndTime,
  snapshot,
  type INamedDocument,
  type WithRef,
  DocumentReference,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { type ITransactionAmountDialogData } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { AmendTransactionDateStore } from '../../transaction-components/transaction-edit-dialog/amend-transaction-date.store';
import { determineNewCreatedAtDate } from '../../transaction-helpers';

export interface IAmendManualTransactionDialogData
  extends ITransactionAmountDialogData {
  transaction: WithRef<ITransaction<IManualExtendedData>>;
  dateReceived?: Moment;
  attributedTo?: DocumentReference<IStaffer>;
  invoice: IInvoice;
}

export interface IAmendManualTransactionDialogResult {
  amount: number;
  description: string;
  extendedData: IManualExtendedData;
  dateReceived?: Moment;
  attributedTo?: DocumentReference<IStaffer>;
}

class ManualExtendedDataFormGroup extends TypedFormGroup<
  Pick<IManualExtendedData, 'transactionType'>
> {
  constructor(extendedData?: IManualExtendedData) {
    super({
      transactionType: new TypedFormControl<
        INamedDocument<IManualTransactionType>
      >(extendedData?.transactionType),
    });
  }
}

class AmendManualTransactionFormGroup extends TypedFormGroup<IAmendManualTransactionDialogResult> {
  constructor(data: IAmendManualTransactionDialogData) {
    const initialAmountValue = data.options.initialAmount ?? data.max;
    const roundedAmount = initialAmountValue
      ? roundTo2Decimals(initialAmountValue)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);

    super({
      description: new TypedFormControl<string>(
        data.transaction.description ?? ''
      ),
      dateReceived: new TypedFormControl<Moment>(data.dateReceived),
      amount: new TypedFormControl<number>(roundedAmount, amountValidators),
      extendedData: new ManualExtendedDataFormGroup(
        data.transaction.extendedData ?? {}
      ),
      attributedTo: new TypedFormControl<
        DocumentReference<IStaffer> | undefined
      >(data.attributedTo),
    });
  }
}

@Component({
    selector: 'pr-amend-manual-transaction-dialog',
    templateUrl: './amend-manual-transaction-dialog.component.html',
    styleUrls: ['./amend-manual-transaction-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
    standalone: false
})
export class AmendManualTransactionDialogComponent {
  trackByTransactionType =
    TrackByFunctions.ref<INamedDocument<IManualTransactionType>>();
  transactionTypes$: Observable<INamedDocument<IManualTransactionType>[]>;
  form: AmendManualTransactionFormGroup;
  timeInterval = moment.duration(30, 'minutes');
  timeControl = new TypedFormControl<moment.Moment>().withGuard(
    moment.isMoment
  );
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  staff: INamedDocument<IStaffer>[];
  showAttributedTo: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAmendManualTransactionDialogData,
    private _dialogRef: MatDialogRef<
      AmendManualTransactionDialogComponent,
      IAmendManualTransactionDialogResult
    >,
    private _organisation: OrganisationService,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.form = new AmendManualTransactionFormGroup(data);
    this.staff = Invoice.staffOnInvoice(this.data.invoice);
    this.showAttributedTo =
      this.staff.length > 1 ||
      TransactionAllocation.hasUnallocatedAmount(this.data.invoice);
    this.transactionTypes$ = this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => ManualTransactionType.all$(brand)),
      toINamedDocuments()
    );
    this.amendDateStore.loadAmendDates(data.transaction);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          this.data.transaction,
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : undefined;

    this._dialogRef.close({
      ...this.form.value,
      dateReceived,
    });
  }

  compareTransactionTypeFn(
    value: INamedDocument<IManualTransactionType>,
    transactionType: INamedDocument<IManualTransactionType>
  ): boolean {
    if (!value || !transactionType) {
      return false;
    }
    return isSameRef(value, transactionType);
  }
}
