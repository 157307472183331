import { keymap } from '@tiptap/pm/keymap';
import { Fragment, type Node } from '@tiptap/pm/model';
import { Plugin, type EditorState } from '@tiptap/pm/state';
import { InlineNodes } from '../../available-extensions';
import {
  DispatchFn,
  ExtensionRegisterReturnFn,
  labelPlugin,
} from '../../../core/extension';
import {
  findNodeRange,
  findNodesOfType,
  getActiveNode,
  nodeTypeIsActive,
} from '../../../core/helpers';

export const LINK_KEYMAP = 'Mod-k';

export function createLinkKeymap(): ExtensionRegisterReturnFn<Plugin> {
  return () =>
    labelPlugin(
      `${InlineNodes.Link}-keymap`,
      keymap({
        [LINK_KEYMAP]: (state: EditorState, dispatch?: DispatchFn) => {
          if (!dispatch) {
            return false;
          }
          const type = state.schema.nodes[InlineNodes.Link];
          if (nodeTypeIsActive(state, type)) {
            const node = getActiveNode(state, type);
            if (!node) {
              return false;
            }
            removeLink(state, dispatch, node);
            return false;
          }

          const transaction = state.tr;

          const textNodes: Node[] = findNodesOfType(
            transaction.selection.content().content,
            state.schema.nodes[InlineNodes.Text]
          );

          dispatch(
            transaction.replaceSelectionWith(
              type.create(undefined, Fragment.fromArray(textNodes))
            )
          );
          return true;
        },
      })
    );
}

export function removeLink(
  state: EditorState,
  dispatch: DispatchFn,
  node: Node
): void {
  const nodePosition = findNodeRange(state.doc, node);
  if (!nodePosition) {
    return;
  }

  const childNodes: Fragment = node.content.cut(0, node.content.size);

  dispatch(
    state.tr.replaceWith(
      nodePosition.$from.before(),
      nodePosition.$to.after(),
      childNodes
    )
  );
}
