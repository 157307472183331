import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  TimingDirection,
  TimingUnit,
  type IAutomationTiming,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  type Time24hrType,
  isTime24hrType,
  DayOfWeek,
  DAYS_OF_WEEK,
} from '@principle-theorem/shared';

export class TimingFormGroup extends TypedFormGroup<IAutomationTiming> {
  constructor() {
    super({
      direction: new TypedFormControl<TimingDirection>(TimingDirection.Before),
      amount: new TypedFormControl<number>(1, Validators.min(1)),
      unit: new TypedFormControl<TimingUnit>(TimingUnit.Days),
      time: new TypedFormControl<Time24hrType | undefined>(undefined).withGuard(
        TypeGuard.undefinedOr(isTime24hrType)
      ),
      daysOfWeek: new TypedFormControl<DayOfWeek[]>(DAYS_OF_WEEK),
    });
  }
}
