import {
  serialiseSchemaContent,
  unserialiseMixedSchemaContent,
} from '@principle-theorem/editor';
import {
  IPatientDocument,
  ISerialisedPatientDocument,
  isPatientDocument,
  isSerialisedPatientDocument,
} from '@principle-theorem/principle-core/interfaces';
import { ITransformer, TransformEvent } from '@principle-theorem/shared';

export class PatientDocumentSerialiser
  implements ITransformer<IPatientDocument, ISerialisedPatientDocument>
{
  events = [TransformEvent.ToFirestore];

  guard(item: unknown): item is IPatientDocument {
    return isPatientDocument(item);
  }

  transform(item: IPatientDocument): ISerialisedPatientDocument {
    return {
      ...item,
      content: serialiseSchemaContent(item.content),
    };
  }
}

export class PatientDocumentUnserialiser
  implements ITransformer<ISerialisedPatientDocument, IPatientDocument>
{
  events = [TransformEvent.FromFirestore];

  guard(item: unknown): item is ISerialisedPatientDocument {
    return isSerialisedPatientDocument(item);
  }

  transform(item: ISerialisedPatientDocument): IPatientDocument {
    return {
      ...item,
      content: unserialiseMixedSchemaContent(item.content),
    };
  }
}
