import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IChecklistItem } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-appointment-checklist-status-sidebar',
  templateUrl: './appointment-checklist-status-sidebar.component.html',
  styleUrls: ['./appointment-checklist-status-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentChecklistStatusSidebarComponent {
  trackByItem = TrackByFunctions.title<IChecklistItem>();
  checklistItems$: ReplaySubject<IChecklistItem[]> = new ReplaySubject(1);

  @Input()
  set checklistItems(checklistItems: IChecklistItem[]) {
    if (checklistItems) {
      this.checklistItems$.next(checklistItems);
    }
  }
}
