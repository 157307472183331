import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ILabJobType } from '@principle-theorem/principle-core/interfaces';
import { LabJobType } from '@principle-theorem/principle-core';

export class LabJobTypeForm extends TypedFormGroup<
  Pick<ILabJobType, 'name' | 'cost'>
> {
  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      cost: new TypedFormControl<number>(0, Validators.required),
    });
  }

  toLabJobType(): ILabJobType {
    return LabJobType.init(this.getRawValue());
  }
}

@Component({
  selector: 'pr-lab-job-type-form',
  templateUrl: './lab-job-type-form.component.html',
  styleUrls: ['./lab-job-type-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobTypeFormComponent {
  private _labJobType?: ILabJobType;
  form: LabJobTypeForm = new LabJobTypeForm();
  @Output()
  submitted: EventEmitter<ILabJobType> = new EventEmitter<ILabJobType>();

  @Input()
  set labJobType(labJobType: ILabJobType | undefined) {
    if (!labJobType) {
      return;
    }
    this._labJobType = labJobType;
    this.form.patchValue(labJobType);
  }

  get labJobType(): ILabJobType | undefined {
    return this._labJobType;
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    const labJobType = this.form.toLabJobType();
    this.submitted.emit(labJobType);
  }
}
