<ng-container [ngSwitch]="type">
  <button
    class="flex-1"
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    [disabled]="disabled$ | async"
    [color]="color$ | async"
  >
    <ng-container *ngTemplateOutlet="buttonContent" />
  </button>
  <button
    class="flex-1"
    *ngSwitchCase="'flat'"
    mat-flat-button
    [disabled]="disabled$ | async"
    [color]="color$ | async"
  >
    <ng-container *ngTemplateOutlet="buttonContent" />
  </button>
  <button
    class="flex-1"
    *ngSwitchCase="'raised'"
    mat-raised-button
    [disabled]="disabled$ | async"
    [color]="color$ | async"
  >
    <ng-container *ngTemplateOutlet="buttonContent" />
  </button>
  <button
    class="flex-1"
    *ngSwitchDefault
    mat-button
    [disabled]="disabled$ | async"
    [color]="color$ | async"
  >
    <ng-container *ngTemplateOutlet="buttonContent" />
  </button>
</ng-container>

<ng-template #buttonContent>
  <div
    class="flex items-center"
    [ngClass]="{ 'justify-center': !cta, 'justify-left': cta }"
  >
    <mat-icon
      class="loader"
      [@loaderAnimation]="(loading$ | async) ? 'show' : 'hide'"
    >
      <mat-spinner color="accent" diameter="20" />
    </mat-icon>
    <span><ng-content></ng-content></span>
  </div>
</ng-template>
