import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import {
  MatFormFieldAppearance,
  SubscriptSizing,
} from '@angular/material/form-field';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import {
  MINIMUM_DURATION,
  TIME_FORMAT_24HR,
  formatTimeStringDisplay,
  isTime24hrType,
  to24hrTime,
  type Time24hrType,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Duration, type Moment } from 'moment-timezone';
import { BehaviorSubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateSelectorComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class DateSelectorComponent implements ControlValueAccessor, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _changed$: Subject<Time24hrType> = new Subject();
  private _touched$: Subject<void> = new Subject();
  private _dayStart = to24hrTime(moment().startOf('day'));
  private _dayEnd = to24hrTime(moment().endOf('day'));
  trackByTime = TrackByFunctions.variable<Time24hrType>();
  timeFrom$ = new BehaviorSubject<Time24hrType>(this._dayStart);
  timeTo$ = new BehaviorSubject<Time24hrType>(this._dayEnd);
  interval$: BehaviorSubject<moment.Duration> =
    new BehaviorSubject<moment.Duration>(
      moment.duration(MINIMUM_DURATION, 'minutes')
    );

  times$: Observable<string[]>;
  searchFilter: InputSearchFilter<string>;
  timeControl = new TypedFormControl<Time24hrType>();

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() placeholder: string;
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() required = false;

  @Input()
  set interval(interval: moment.Duration) {
    this.interval$.next(interval);
  }

  @Input()
  set timeFrom(time: Moment | Date | Time24hrType) {
    const timeFrom = time ?? this._dayStart;
    this.timeFrom$.next(to24hrTime(timeFrom));
  }

  @Input()
  set timeTo(time: Moment | Date | Time24hrType) {
    const timeTo = time ?? this._dayEnd;
    this.timeTo$.next(to24hrTime(timeTo));
  }

  constructor() {
    this.times$ = combineLatest([
      this.timeFrom$,
      this.timeTo$,
      this.interval$,
    ]).pipe(
      map(([start, end, interval]) => this._buildTimes(start, end, interval))
    );

    this.searchFilter = new InputSearchFilter<string>(
      this.times$,
      toSearchStream(this.timeControl),
      (option) => option
    );

    this.timeControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((time) => {
        this._changed$.next(time ? to24hrTime(time) : undefined);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  blur(): void {
    const value = this.timeControl.value;
    if (!isTime24hrType(value)) {
      this.timeControl.reset(undefined, { emitEvent: false });
      return;
    }
  }

  setValue(time?: Moment | Date | Time24hrType): void {
    if (!time) {
      this.timeControl.reset();
    }
    this._changed$.next(time ? to24hrTime(time) : undefined);
  }

  writeValue(value?: Moment | Date | Time24hrType): void {
    if (!value) {
      return;
    }
    this.timeControl.setValue(to24hrTime(value), {
      emitEvent: false,
    });
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeControl.disable();
      return;
    }
    this.timeControl.enable();
  }

  displayFn(value?: Time24hrType): string {
    if (!value) {
      return '';
    }
    return formatTimeStringDisplay(to24hrTime(value));
  }

  private _buildTimes(
    start: string,
    end: string,
    interval: Duration
  ): Time24hrType[] {
    const times: Time24hrType[] = [];
    const referenceTime = moment(start, TIME_FORMAT_24HR);

    while (referenceTime.isSameOrBefore(moment(end, TIME_FORMAT_24HR))) {
      times.push(to24hrTime(referenceTime));
      referenceTime.add(interval);
    }
    return times;
  }
}
