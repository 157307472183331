import { NgModule } from '@angular/core';
import { BrandRouterLinkDirective } from './brand-router-link.directive';
import { PracticeRouterLinkDirective } from './practice-router-link.directive';
import { StateBasedNavigationService } from './state-based-navigation.service';
import { TimelineNavigationService } from './timeline-navigation-service';

@NgModule({
  declarations: [BrandRouterLinkDirective, PracticeRouterLinkDirective],
  imports: [],
  exports: [BrandRouterLinkDirective, PracticeRouterLinkDirective],
  providers: [StateBasedNavigationService, TimelineNavigationService],
})
export class NavigationModule {}
