import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  TypeGuard,
  toTimestamp,
  type Timezone,
  toFloat,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_INVOICE_RESOURCE_TYPE = 'patientInvoices';

export const PATIENT_INVOICE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Invoice List',
    description: '',
    idPrefix: PATIENT_INVOICE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePatientInvoice {
  id: number;
  invoiceNo: number;
  invoiceDate: string; // 2024-04-08 06:17:35.810
  dueDate: string; // 2024-05-08 06:17:35.810
  note?: string;
  total: number; // 240.0000
  paid: number; // 99.0000
  discount?: number; // 141.0000
  isPaid: boolean;
  isBadDebt: boolean;
  isVoided: boolean;
  isDeleted: boolean;
  payeeId: number;
  patientId: number;
  totalTax?: number; // .0000
  isTaxInclusive: boolean;
}

export function isCorePracticePatientInvoice(
  item: unknown
): item is ICorePracticePatientInvoice {
  return TypeGuard.interface<ICorePracticePatientInvoice>({
    id: isNumber,
    invoiceNo: isNumber,
    invoiceDate: isString,
    dueDate: isString,
    note: TypeGuard.nilOr(isString),
    total: isNumber,
    paid: isNumber,
    discount: TypeGuard.nilOr(isNumber),
    isPaid: isBoolean,
    isBadDebt: isBoolean,
    isVoided: isBoolean,
    isDeleted: isBoolean,
    payeeId: isNumber,
    patientId: isNumber,
    totalTax: TypeGuard.nilOr(isNumber),
    isTaxInclusive: isBoolean,
  })(item);
}

export interface ICorePracticePatientInvoiceTranslations {
  invoiceDate: Timestamp;
  dueDate: Timestamp;
}

export interface ICorePracticePatientInvoiceFilters {
  payeeId: number;
  patientId: number;
}

const PATIENT_INVOICE_SOURCE_QUERY = `
SELECT
  InvoiceId AS id,
  InvoiceNo AS invoice_no,
  InvoiceDate AS invoice_date,
  DueDate AS due_date,
  Note AS note,
  convert_to_decimal(Total) AS total,
  convert_to_decimal(Paid) AS paid,
  convert_to_decimal(Discount) AS discount,
  convert_to_boolean(IsPaid) AS is_paid,
  convert_to_boolean(IsBadDebt) AS is_bad_debt,
  convert_to_boolean(IsVoided) AS is_voided,
  convert_to_boolean(IsDeleted) AS is_deleted,
  PayeeId AS payee_id,
  PatientId AS patient_id,
  convert_to_decimal(TotalTax) AS total_tax,
  convert_to_boolean(IsTaxInclusive) AS is_tax_inclusive
FROM tblInvoice
`;

export class PatientInvoiceSourceEntity extends BaseSourceEntity<
  ICorePracticePatientInvoice,
  ICorePracticePatientInvoiceTranslations,
  ICorePracticePatientInvoiceFilters
> {
  sourceEntity = PATIENT_INVOICE_SOURCE_ENTITY;
  entityResourceType = PATIENT_INVOICE_RESOURCE_TYPE;
  sourceQuery = PATIENT_INVOICE_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientInvoice;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'total', 'paid', 'discount', 'totalTax'),
  ]);

  translate(
    data: ICorePracticePatientInvoice,
    _timezone: Timezone
  ): ICorePracticePatientInvoiceTranslations {
    return {
      invoiceDate: toTimestamp(
        moment.utc(data.invoiceDate, ISO_DATE_TIME_FORMAT)
      ),
      dueDate: toTimestamp(moment.utc(data.dueDate, ISO_DATE_TIME_FORMAT)),
    };
  }

  getSourceRecordId(data: ICorePracticePatientInvoice): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientInvoice): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientInvoice,
    _timezone: Timezone
  ): ICorePracticePatientInvoiceFilters {
    return {
      payeeId: data.payeeId,
      patientId: data.patientId,
    };
  }
}
