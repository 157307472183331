import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { SearchFieldComponent } from './search-field.component';

@NgModule({
  imports: [CommonModule, NgMaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [SearchFieldComponent],
  exports: [SearchFieldComponent],
})
export class SearchFieldModule {}
