import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IEvent } from '../../event/event';

export interface IAppointmentResources<T> {
  brand: INamedDocument;
  practice: INamedDocument;
  patient: INamedDocument;
  practitioner: INamedDocument;
  interactor?: INamedDocument;
  appointment: {
    event: T;
    ref: DocumentReference;
  };
}

export type IBookedAppointmentResources = IAppointmentResources<IEvent>;
export type ICancelledAppointmentResources = IAppointmentResources<IEvent>;
export type IRescheduledAppointmentResources = IAppointmentResources<{
  before?: IEvent;
  after: IEvent;
}>;

export function isAppointmentResource<T>(
  data: unknown
): data is IAppointmentResources<T> {
  return (
    isObject(data) &&
    isINamedDocument(data.brand) &&
    isINamedDocument(data.practice) &&
    isINamedDocument(data.patient) &&
    isINamedDocument(data.practitioner) &&
    'appointment' in data &&
    isObject(data.appointment) &&
    'event' in data.appointment
  );
}
