import {
  CustomMappingOption,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getEnumValues,
  type IReffable,
  snapshot,
  type WithRef,
  XSLXImporterExporter,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  type ID4WPatientStatus,
  PatientStatusSourceEntity,
} from '../../source/entities/patient-status';
import { PatientStatusesToXSLX } from './patient-statuses-to-xlsx';
import { XSLXToPatientStatuses } from './xlsx-to-patient-statuses';
import { of } from 'rxjs';

export const PATIENT_STATUS_CUSTOM_MAPPING_TYPE = 'patientStatusMapping';

export const PATIENT_STATUS_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Patient Statuses',
    description: `Used for mapping patient statuses to Principle.`,
    type: PATIENT_STATUS_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class D4WPatientStatusMappingHandler extends BaseCustomMappingHandler<
  object,
  PatientStatus
> {
  customMapping = PATIENT_STATUS_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const patientStatusOptions = new PatientStatusSourceEntity();
    const records = await patientStatusOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.name,
          value: patientStatusOptions.getSourceRecordId(record).toString(),
        })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of(
        getEnumValues(PatientStatus)
          .sort()
          .map((status) => ({
            value: status,
            description: status,
            hasAssociatedValue: false,
          }))
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, records, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, records, translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, records, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, records, translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToPatientStatuses()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      if (!matchingOption) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;
      const mapTo = item.mapTo;

      if (!mapTo) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find status for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationValue: mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    records: ID4WPatientStatus[];
    translator: PatientStatusesToXSLX;
  }> {
    const fileName = this.getFileName();
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const records = await this._getPatientStatusOptions(migration);
    const translator = new PatientStatusesToXSLX(
      await this.getRecords(translationMap),
      defaultMappings
    );
    return { fileName, records, translator };
  }

  private async _getPatientStatusOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ID4WPatientStatus[]> {
    const patientStatusOptions = new PatientStatusSourceEntity();
    const records = await patientStatusOptions
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      records.map((record) => record.data.data),
      'name'
    );
  }
}

const defaultMappings: { [key: string]: PatientStatus } = {
  Casual: PatientStatus.Active,
  ['Casual Visitor']: PatientStatus.Active,
  Inactive: PatientStatus.Inactive,
  ['Inactive Deceased']: PatientStatus.Deceased,
  ['Inactive Transferred']: PatientStatus.Inactive,
  Regular: PatientStatus.Active,
};
