import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import { Brand, Staffer } from '@principle-theorem/principle-core';
import {
  GENDERS,
  Gender,
  IStafferOnlineProfile,
  type IPractice,
  type IProviderData,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IProfile,
  filterUndefined,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ProviderDetailsControls,
  ProviderDetailsFormGroup,
} from '../../../../../components/provider-details-input/provider-details-form';
import { CurrentStafferService } from '../current-staffer.service';

type IStafferFormData = Pick<
  IStaffer,
  | 'providerDetails'
  | 'providerCommission'
  | 'prescriberNumber'
  | 'qualifications'
  | 'onlineProfile'
>;

type IStafferOnlineProfileFormData = Omit<
  IStafferOnlineProfile,
  'profileImageURL'
>;

@Component({
    selector: 'pr-staffer-edit',
    templateUrl: './staffer-edit.component.html',
    styleUrls: ['./staffer-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StafferEditComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByIndex = TrackByFunctions.index();
  breadcrumbs$: Observable<IBreadcrumb[]>;
  staffer$: Observable<WithRef<IStaffer>>;
  profile$: Observable<IProfile>;
  storagePath$: Observable<string>;
  practices$: Observable<WithRef<IPractice>[]>;
  providerDetailControls: ProviderDetailsControls;
  genders: Gender[] = GENDERS;
  form = new TypedFormGroup<IStafferFormData>({
    providerDetails: new TypedFormArray<IProviderData>([
      new ProviderDetailsFormGroup(),
    ]),
    prescriberNumber: new TypedFormControl<string>(''),
    qualifications: new TypedFormControl<string>(''),
    providerCommission: new TypedFormControl<number>(0),
    onlineProfile: new TypedFormGroup<IStafferOnlineProfileFormData>({
      name: new TypedFormControl<string>(''),
      gender: new TypedFormControl<Gender>(),
      title: new TypedFormControl<string>(''),
      bio: new TypedFormControl<string>(''),
    }),
  });

  constructor(
    private _currentStaffer: CurrentStafferService,
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope
  ) {
    this.providerDetailControls = new ProviderDetailsControls(
      this.providerDetails
    );
    this.staffer$ = this._currentStaffer.doc$.pipe(filterUndefined());
    this.storagePath$ = this.staffer$.pipe(map((staffer) => staffer.ref.path));
    this.profile$ = this.staffer$.pipe(
      map((staffer) => ({
        name: Staffer.onlineProfileName(staffer),
        profileImageURL: Staffer.onlineProfileImageURL(staffer),
      }))
    );

    const brand$ = this._currentBrand.doc$.pipe(filterUndefined());

    this.practices$ = brand$.pipe(
      switchMap((brand) => Brand.practices$(brand)),
      shareReplayCold()
    );

    this.staffer$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((staffer) => {
        this.providerDetailControls.initProviderDetailsControls(staffer, {
          emitEvent: false,
        });
        this.form.patchValue(staffer);
      });

    this.breadcrumbs$ = combineLatest([brand$, this.staffer$]).pipe(
      map(([brand, staffer]) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Staff', path: '../' },
        { label: staffer.user.name },
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get providerDetails(): TypedFormArray<IProviderData> {
    return this.form.controls.providerDetails as TypedFormArray<IProviderData>;
  }

  async submit(): Promise<void> {
    const staffer = await snapshot(this.staffer$);
    if (this.form.invalid || !staffer) {
      return;
    }
    await Firestore.patchDoc(staffer.ref, this.form.getRawValue());
    this._snackBar.open('Staffer updated');
  }

  async updateImageUrl(storagePath: string): Promise<void> {
    const staffer = await snapshot(this.staffer$);
    await Firestore.patchDoc(staffer.ref, {
      onlineProfile: { ...staffer.onlineProfile, profileImageURL: storagePath },
    });
  }
}
