import { type IContact } from '../../contact/contact';
import { type ITemplateContext } from '../templating';
import { type IBaseTemplateContext } from './base-context';
import { type IPatientScopeData } from './patient-context';

export interface IReferralScopeData extends IPatientScopeData {
  contact: IContact;
}

export interface IReferralTemplateContext
  extends ITemplateContext,
    IBaseTemplateContext {
  referralProviderFullName: string;
  referralProviderFirstName: string;
  referralClinicName: string;
  referralClinicAddress: string;
}
