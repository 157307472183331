import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Appointment, ChecklistItem } from '@principle-theorem/principle-core';
import {
  ChecklistItemAction,
  ChecklistType,
  type IAppointment,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-outbound-checklist',
    templateUrl: './outbound-checklist.component.html',
    styleUrls: ['./outbound-checklist.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prOutboundChecklist',
    standalone: false
})
export class OutboundChecklistComponent {
  trackByItem = TrackByFunctions.ref<WithRef<IChecklistItem>>();
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  checklist$: Observable<WithRef<IChecklistItem>[]>;
  allComplete$: Observable<boolean>;

  constructor() {
    this.checklist$ = this.appointment$.pipe(
      switchMap((appointment) =>
        Appointment.checklistItems$(appointment, ChecklistType.Post)
      )
    );
    this.allComplete$ = this.checklist$.pipe(
      map((checklist) => checklist.every((item) => item.done))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  async updateSelected(item: WithRef<IChecklistItem>): Promise<void> {
    await patchDoc(item.ref, { done: !item.done });
  }

  doAction($event: Event, item: IChecklistItem): void {
    $event.stopPropagation();
    switch (item.action) {
      case ChecklistItemAction.Print:
        ChecklistItem.print(item);
        break;
      default:
        break;
    }
  }
}
