<div class="layout-margin" fxLayout="row" fxLayoutAlign="space-between center">
  <h1>
    <ng-container *ngrxLet="patient$ as patient">
      {{ patient.name }}:
    </ng-container>
    Update Medical History
  </h1>
  <button
    *ngIf="latestForm$ | async"
    mat-stroked-button
    (click)="clearForm$.next()"
  >
    Clear Form
  </button>
</div>

<div
  *ngIf="submitting$ | async; else medicalHistoryForm"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <mat-spinner />
  <p class="mat-body-2">Saving Medical History</p>
</div>

<ng-template #medicalHistoryForm>
  <pr-custom-form
    [form]="form$ | async"
    (submitted)="submit($event)"
   />
</ng-template>
