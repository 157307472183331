import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppointmentRequestEffects } from './effects/appointment-request.effects';
import { AppointmentSchedulingRouterEffects } from './effects/appointment-scheduling-router.effects';
import { AppointmentSchedulingEffects } from './effects/appointment-scheduling.effects';
import { AppointmentSuggestionsEffects } from './effects/appointment-suggestions.effects';
import { CancelAppointmentEffects } from './effects/cancel-appointment.effects';
import { PatientDetailsEffects } from './effects/patient-details.effects';
import { AppointmentSchedulingFacade } from './facades/appointment-scheduling.facade';
import {
  APPOINTMENT_SCHEDULING_MODULE_FEATURE_KEY,
  reducers,
} from './reducers/reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(APPOINTMENT_SCHEDULING_MODULE_FEATURE_KEY, reducers),
    EffectsModule.forFeature([
      AppointmentSchedulingEffects,
      PatientDetailsEffects,
      AppointmentSuggestionsEffects,
      CancelAppointmentEffects,
      AppointmentRequestEffects,
      AppointmentSchedulingRouterEffects,
    ]),
  ],
  providers: [AppointmentSchedulingFacade],
})
export class NgAppointmentStoreModule {}
