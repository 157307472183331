import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import {
  Organisation,
  PrincipleTypesenseCollection,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  IOrganisation,
  IPractice,
  IStaffer,
  ITypesenseWaitList,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  DocumentReference,
  ISODateType,
  ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { ITypesenseConfig, Typesense } from '@principle-theorem/typesense';
import { compact } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';
import { IWaitListFilters } from '../gaps/wait-list.store';
import { Moment } from 'moment-timezone';

export class TypesenseWaitListBloc {
  results$: Observable<SearchResponse<ITypesenseWaitList>>;

  constructor(
    private _config: ITypesenseConfig,
    private _typesense: TypesenseSearchService,
    organisation$: Observable<WithRef<IOrganisation>>,
    brand$: Observable<WithRef<IBrand>>,
    search$: Observable<string>,
    filters$: Observable<IWaitListFilters>,
    page$: Observable<number> = of(1),
    numberOfResults$: Observable<number> = of(25),
    sortBy$: Observable<string | string[]>
  ) {
    const pagination$ = combineLatest([page$, numberOfResults$]).pipe(
      map(([page, numberOfResults]) => ({ page, numberOfResults }))
    );

    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      filters$.pipe(
        map((filters) =>
          compact([
            dateRangeFilter(filters.dateRange),
            urgenciesFilter(filters.urgencies),
            shortListFilter(filters.inShortList),
            practiceRefFilter(filters.practiceRef),
            practitionersFilter(filters.practitioners),
            daysFilter(filters.days),
            availableDatesFilter(filters.availableDates),
            minimumDateFilter(filters.minimumDate),
          ])
        )
      ),
      pagination$,
      sortBy$,
    ]).pipe(
      map(([search, filters, pagination, sortBy]) => ({
        q: search,
        query_by: [
          'patientName',
          'searchPatientNames',
          'practitionerName',
          'appointmentDurationHuman',
          'treatmentCategoryName',
        ].join(','),
        per_page: pagination.numberOfResults,
        page: pagination.page,
        filter_by: filters.join(' && '),
        sort_by: sortBy,
      }))
    );

    this.results$ = combineLatest([organisation$, brand$]).pipe(
      switchMap(([organisation, brand]) =>
        this._typesense.query$<ITypesenseWaitList>(
          Organisation.integrationCol(organisation),
          Typesense.getScopedCollectionName(
            [organisation.ref, brand.ref],
            PrincipleTypesenseCollection.WaitLists
          ),
          searchParams$,
          this._config,
          {
            cacheSearchResultsForSeconds: 0,
          }
        )
      )
    );
  }
}

function shortListFilter(inShortList?: boolean): string | undefined {
  return inShortList ? `inShortList:=${inShortList}` : undefined;
}

function practiceRefFilter(
  practiceRef?: DocumentReference<IPractice>
): string | undefined {
  return practiceRef ? `practiceRef:=${practiceRef.path}` : undefined;
}

function practitionersFilter(
  practitioners?: DocumentReference<IStaffer>[]
): string | undefined {
  const practitionerRefs = practitioners
    ?.map((practitioner) => practitioner.path)
    .join(',');
  return practitioners ? `practitionerRef:=[${practitionerRefs}]` : undefined;
}

function urgenciesFilter(urgencies?: WaitListUrgency[]): string | undefined {
  return urgencies?.length ? `urgency:[${urgencies.join(',')}]` : undefined;
}

function daysFilter(days?: DayOfWeek[]): string | undefined {
  return days?.length ? `days:[${days.join(',')}]` : undefined;
}

function minimumDateFilter(minimumDate?: Moment): string | undefined {
  return minimumDate ? `appointmentFrom:>=${minimumDate.unix()}` : undefined;
}

function availableDatesFilter(
  availableDates?: ISODateType[]
): string | undefined {
  return availableDates?.length
    ? `availableDates:[${availableDates.join(',')}]`
    : undefined;
}

function dateRangeFilter(dateRange?: ITimePeriod): string | undefined {
  if (!dateRange) {
    return;
  }

  return `dateFrom:>=${dateRange.to.unix()} && dateTo:<=${dateRange.from.unix()}`;
}
