<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  class="notification"
  *ngIf="notification$ | async as notification"
>
  <pr-automation-configuration-status [automation]="notification" />

  <div fxFlex>
    <h3>{{ notification.name }}</h3>
    <p>
      {{ notification.type }} -
      <span class="mat-caption">{{
        notification.timing | prTimingDisplay
      }}</span>
    </p>
  </div>
  <ng-content></ng-content>
</div>
