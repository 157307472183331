import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MixedSchema } from '@principle-theorem/editor';
import { MOCK_ALL_RESOLVER } from '@principle-theorem/ng-templating';
import { TemplateDefinition } from '@principle-theorem/principle-core';
import {
  type IAutomatedNotificationConfiguration,
  type ITemplateContext,
  type ITemplateDefinition,
  type ITemplateScopeData,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AutomatedNotificationFormHelpers } from '../notification-dialog-form';

@Component({
    selector: 'pr-automated-notification-config-preview',
    templateUrl: './automated-notification-config-preview.component.html',
    styleUrls: ['./automated-notification-config-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomatedNotificationConfigPreviewComponent {
  config$ = new ReplaySubject<WithRef<IAutomatedNotificationConfiguration>>(1);
  scopeData$ = new ReplaySubject<ITemplateScopeData>(1);
  context$: Observable<ITemplateContext>;
  template$: Observable<WithRef<ITemplateDefinition>>;
  preview$: Observable<MixedSchema>;
  formHelpers = AutomatedNotificationFormHelpers;

  @Input()
  set config(config: WithRef<IAutomatedNotificationConfiguration>) {
    if (config) {
      this.config$.next(config);
    }
  }

  @Input()
  set scopeData(scopeData: ITemplateScopeData) {
    if (scopeData) {
      this.scopeData$.next(scopeData);
    }
  }

  constructor() {
    this.template$ = this.config$.pipe(
      switchMap((config) => getDoc(config.templateRef))
    );

    // TODO: Render based on actual scope data. https://app.clickup.com/t/860qzurhu
    this.context$ = this.template$.pipe(
      switchMap(async (template) => MOCK_ALL_RESOLVER.resolve([template.scope]))
    );

    const rawTemplate$ = combineLatest([this.template$, this.scopeData$]).pipe(
      switchMap(([template, scopeData]) =>
        TemplateDefinition.resolveRawTemplate(template, scopeData)
      )
    );
    this.preview$ = rawTemplate$.pipe(
      map((rawTemplate) => rawTemplate.content)
    );
  }
}
