import {
  IBrand,
  IBridgeDevice,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';

export interface IDeviceCommand<
  T extends IBaseCommand<string, object> = IBaseCommand<string, object>,
> {
  deviceRef: DocumentReference<IBridgeDevice>;
  practiceRef: DocumentReference<IPractice>;
  brandRef: DocumentReference<IBrand>;
  command: T;
}

export interface IBaseCommand<Type extends string, T extends object> {
  type: Type;
  data: T;
}

export interface IBridgeIntegration {
  label: string;
  description: string;
}
