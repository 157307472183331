import { Money } from '@principle-theorem/accounting';
import {
  HicapsConnect,
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import {
  MedicareValidation,
  Transaction,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  IHicapsConnectMedicareClaimTransactionExtendedData,
  IInvoice,
  IPractice,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  getDoc,
  toInt,
  toTimestamp,
} from '@principle-theorem/shared';
import { sum, trim } from 'lodash';
import * as moment from 'moment-timezone';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export interface IMedicareClaimItemResponse {
  itemNumber: string;
  feeAmount: number;
  dateOfService: string;
  itemOverrideCode: string;
  LSPN: string;
  equipmentIdentificationCde: string;
  selfDeemedCde: string;
  patientContributionAmount: number;
  spcId: string;
  benefitAmount: number;
  explanationCode: string;
  benefitAssignedAmount: number;
  scheduleFeeAmount: number;
}

export class HicapsConnectMedicareTransactionBuilder {
  static async toMedicareTransaction(
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    request: HicapsConnect.MedicareClaimRequest,
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareClaimRequest>
  ): Promise<ITransaction<IHicapsConnectMedicareClaimTransactionExtendedData>> {
    const base = await getBaseTransaction(invoice, TransactionType.Incoming);

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return Transaction.init({
        ...base,
        createdAt: toTimestamp(moment(response.timestamp)),
        reference: response.processUid,
        status: TransactionStatus.Failed,
        provider: TransactionProvider.HicapsConnectMedicare,
        practiceRef,
        amount: request.TransactionAmount,
        extendedData: {
          request,
          response,
        },
      });
    }

    const practice = await getDoc(practiceRef);
    const status = MedicareValidation.coerceTransactionStatus(
      HicapsConnectHelpers.getStatus(response.data.ResponseCode),
      practice?.hicapsConnectSettings?.addCDBSAsPending ?? false
    );

    const amount =
      status === TransactionStatus.Failed
        ? request.TransactionAmount
        : this.getBenefitAmountFromMedicareItems(
            response.data.MedicareClaimDetails
          );

    return Transaction.init({
      ...base,
      createdAt: HicapsConnectHelpers.getTransactionDate(
        response.data.TransactionDate,
        response.timestamp
      ),
      reference: response.processUid,
      status,
      provider: TransactionProvider.HicapsConnectMedicare,
      practiceRef,
      amount,
      extendedData: {
        request,
        response,
      },
    });
  }

  static getBenefitAmountFromMedicareItems(
    medicareClaimItems: string[]
  ): number {
    const items = medicareClaimItems.map((medicareClaimItem) =>
      this.getMedicareClaimItemResponse(medicareClaimItem)
    );
    const benefits = items.map((item) => item.benefitAmount);
    return sum(benefits);
  }

  static getMedicareClaimItemResponse(
    itemStr: string
  ): IMedicareClaimItemResponse {
    const item = HicapsConnectHelpers.objectFromSubstrings(itemStr, {
      itemNumber: [0, 6],
      feeAmount: [6, 12],
      dateOfService: [12, 20],
      itemOverrideCode: [20, 22],
      LSPN: [22, 28],
      equipmentIdentificationCde: [28, 33],
      selfDeemedCde: [33, 35],
      patientContributionAmount: [35, 41],
      spcId: [41, 45],
      benefitAmount: [45, 51],
      explanationCode: [51, 55],
      benefitAssignedAmount: [55, 61],
      scheduleFeeAmount: [61, 67],
    });
    return {
      ...item,
      itemNumber: trim(item.itemNumber),
      feeAmount: Money.fromCents(toInt(item.feeAmount)),
      patientContributionAmount: Money.fromCents(
        toInt(item.patientContributionAmount)
      ),
      benefitAmount: Money.fromCents(toInt(item.benefitAmount)),
      benefitAssignedAmount: Money.fromCents(toInt(item.benefitAssignedAmount)),
      scheduleFeeAmount: Money.fromCents(toInt(item.scheduleFeeAmount)),
    };
  }
}
