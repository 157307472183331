<div
  *ngrxLet="treatmentPlan$ as treatmentPlan"
  class="flex justify-between gap-4"
>
  <pr-assignee-profile-selector
    selectTooltip="Reassign Practitioner"
    [disableClear]="true"
    [selectedStaffer]="treatmentPlan.practitioner?.ref"
    [staff]="organisation.practicePractitioners$ | async"
    (stafferSelected)="patchPlanPractitioner($event)"
  />
  <div class="plan-name flex flex-1 flex-col">
    <mat-form-field class="compact no-style mb-1" subscriptSizing="dynamic">
      <mat-label>Plan Name</mat-label>
      <input
        matInput
        autocomplete="off"
        type="text"
        [formControl]="nameControl"
      />
    </mat-form-field>
    <span
      class="mat-caption"
      *ngIf="treatmentPlan.practitioner?.name; else noPractitioner"
      >Assigned to {{ treatmentPlan.practitioner?.name }}</span
    >
    <ng-template #noPractitioner>
      <span class="mat-caption">{{ treatmentPlan.type }}</span>
    </ng-template>
  </div>

  <div class="flex flex-col gap-1">
    <div class="flex flex-row items-center justify-end gap-2">
      <pr-staff-fee-schedule-menu
        [patient]="patient$ | async"
        [feeSchedule]="defaultSchedule$ | async"
        [plan]="treatmentPlan$ | async"
        (scheduleSelected)="changeSchedule($event, treatmentPlan)"
      />

      <button mat-icon-button [matMenuTriggerFor]="moreMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <div>
      <span class="price">{{ price$ | ngrxPush | currency }}</span>
    </div>
  </div>

  <mat-menu #moreMenu="matMenu">
    <button mat-menu-item (click)="print(treatmentPlan)">
      <mat-icon>print</mat-icon>
      <span>Print Plan</span>
    </button>
    <button
      mat-menu-item
      [disabled]="duplicating$ | ngrxPush"
      (click)="duplicate(treatmentPlan)"
    >
      <mat-icon>content_copy</mat-icon>
      <span>Make a Copy</span>
    </button>
    <mat-divider />
    <button
      mat-menu-item
      *ngIf="treatmentPlan | map: canAccept"
      (click)="acceptPlan(treatmentPlan)"
    >
      <mat-icon color="primary">thumb_up</mat-icon>
      <span>Accept Plan</span>
    </button>
    <button
      mat-menu-item
      *ngIf="treatmentPlan | map: canDecline"
      (click)="declinePlan(treatmentPlan)"
    >
      <mat-icon color="warn">thumb_down</mat-icon>
      <span>Decline Plan</span>
    </button>
    <div
      *ngrxLet="treatmentPlan | map: canDelete$ as canDelete"
      matTooltip="Can't delete a plan that has treatment steps"
      [matTooltipDisabled]="canDelete"
    >
      <button
        mat-menu-item
        [disabled]="!canDelete"
        (click)="deletePlan(treatmentPlan)"
      >
        <mat-icon color="warn">delete</mat-icon>
        <span>Delete Plan</span>
      </button>
    </div>
  </mat-menu>
</div>
