import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type ITemplateDefinition,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { getDoc, type WithRef } from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TemplateCollectionResolver } from './templating/template-collection-resolver';

@Injectable()
export class BrandTemplateCollectionResolver extends TemplateCollectionResolver {
  private _scope: CurrentBrandScope;

  constructor(router: Router) {
    super();
    this._scope = new CurrentBrandScope(router);
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<CollectionReference<ITemplateDefinition> | undefined> {
    const model: WithRef<IBrand> | undefined = this._scope.findModel(route);
    if (!model) {
      return of(undefined);
    }
    return from(getDoc(model.ref)).pipe(
      map((brand) => Brand.templateCol(brand)),
      take(1)
    );
  }
}
