import { randNumber } from '@ngneat/falso';
import {
  IPerioData,
  IPerioDataPoints,
  IPerioRecord,
  IPerioTable,
  IPerioTableCell,
  IToothRef,
  PerioDataPoint,
  PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import { MockAdultTeeth, MockAllTeeth } from './core/tooth.mock';
import { MockToothRef } from './treatment/charted-treatment.mock';

export function MockMouthPerioRecords(): IPerioRecord[] {
  return MockAllTeeth()
    .map((tooth) => tooth.toothRef)
    .map((toothRef) => MockPerioRecord(toothRef));
}

export function MockAdultPerioRecords(): IPerioRecord[] {
  return MockAdultTeeth()
    .map((tooth) => tooth.toothRef)
    .map((toothRef) => MockPerioRecord(toothRef));
}

export function MockPerioRecord(toothRef: IToothRef): IPerioRecord {
  return { toothRef, data: MockPerioData() };
}

export function MockPerioData(): IPerioData {
  return {
    mobility: randNumber({ min: 0, max: 3 }),
    pocket: MockPerioDataPoint(),
    recession: MockPerioDataPoint(),
    CAL: MockPerioDataPoint(),
    MGJ: MockPerioDataPoint(),
    bleeding: MockPerioDataPoint(),
    plaque: MockPerioDataPoint(),
    furcation: MockPerioDataPoint(),
    suppuration: MockPerioDataPoint(),
  };
}

export function MockPerioDataPoint(): IPerioDataPoints {
  return {
    facialMesial: randNumber({ min: 1, max: 8 }),
    facialCentral: randNumber({ min: 1, max: 8 }),
    facialDistal: randNumber({ min: 1, max: 8 }),
    palatalMesial: randNumber({ min: 1, max: 8 }),
    palatalCentral: randNumber({ min: 1, max: 8 }),
    palatalDistal: randNumber({ min: 1, max: 8 }),
  };
}

export function MockPerioCell(
  toothRef: IToothRef = MockToothRef(),
  navigationIndex: number = 0,
  readOnly: boolean = false,
  measurement: PerioMeasurement = PerioMeasurement.Pocket,
  dataPoint: PerioDataPoint = PerioDataPoint.FacialMesial
): IPerioTableCell {
  return {
    metadata: {
      toothRef,
      dataPath: '',
      measurement,
      dataPoint,
    },
    value: randNumber({ min: 1, max: 8 }),
    navigationIndex,
    readOnly,
  };
}

export function MockPerioTable(
  label: string = 'Mock Perio Table',
  row?: { cells: IPerioTableCell[]; indexAt: number }
): IPerioTable {
  const rows = [
    [
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        1,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        2,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        3,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialDistal
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        4,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        5,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        6,
        false,
        PerioMeasurement.Pocket,
        PerioDataPoint.FacialDistal
      ),
    ],
    [
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        7,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        8,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        9,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalDistal
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        10,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        11,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        12,
        false,
        PerioMeasurement.Recession,
        PerioDataPoint.PalatalDistal
      ),
    ],
    [
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        13,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        14,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        15,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialDistal
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        16,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        17,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        18,
        false,
        PerioMeasurement.CAL,
        PerioDataPoint.FacialDistal
      ),
    ],
    [
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        19,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        20,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        21,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalDistal
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        22,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        23,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalCentral
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        24,
        false,
        PerioMeasurement.MGJ,
        PerioDataPoint.PalatalDistal
      ),
    ],
    [
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 8 },
        25,
        false,
        PerioMeasurement.Mobility,
        PerioDataPoint.FacialMesial
      ),
      MockPerioCell(
        { quadrant: 1, quadrantIndex: 7 },
        26,
        false,
        PerioMeasurement.Mobility,
        PerioDataPoint.FacialMesial
      ),
    ],
  ];

  if (row) {
    rows[row.indexAt] = row.cells;
  }

  return {
    label,
    rows,
    disabled: false,
    missingTeeth: [],
    implants: [],
  };
}
