import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgContactsModule } from '@principle-theorem/ng-contacts';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { LabContactsComponent } from './lab-contacts/lab-contacts.component';
import { LabCreateComponent } from './lab-create/lab-create.component';
import { LabJobTypeFormDialogComponent } from './lab-job-types/lab-job-type-form-dialog/lab-job-type-form-dialog.component';
import { LabJobTypeFormComponent } from './lab-job-types/lab-job-type-form/lab-job-type-form.component';
import { LabJobTypesListComponent } from './lab-job-types/lab-job-types-list/lab-job-types-list.component';
import { UpdateLabJobTypeComponent } from './lab-job-types/update-lab-job-type/update-lab-job-type.component';
import { LabListComponent } from './lab-list/lab-list.component';
import { LabCardComponent } from './lab-profile/lab-card/lab-card.component';
import { LabProfileComponent } from './lab-profile/lab-profile.component';
import { LabResolverService } from './lab-resolver.service';
import { LabTabsComponent } from './lab-tabs/lab-tabs.component';
import { LabTimelineComponent } from './lab-timeline/lab-timeline.component';

@NgModule({
  imports: [
    ComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgMaterialModule,
    RouterModule,
    NgContactsModule,
  ],
  declarations: [
    LabCreateComponent,
    LabListComponent,
    LabProfileComponent,
    LabCardComponent,
    LabJobTypesListComponent,
    LabJobTypeFormComponent,
    UpdateLabJobTypeComponent,
    LabTabsComponent,
    LabJobTypeFormDialogComponent,
    LabContactsComponent,
    LabTimelineComponent,
  ],
  providers: [LabResolverService],
})
export class LabPagesModule {}
