import { addDoc, asColRef, getDocs } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { orderBy, uniqBy } from 'lodash';
import {
  type IMigrationEntry,
  type IMigrationSnapshot,
  type IMigrationStorage,
} from '../interfaces';

export class FirebaseStorage implements IMigrationStorage {
  storagePath: string = 'migrations';

  async add(migration: IMigrationEntry): Promise<void> {
    await addDoc(asColRef<IMigrationEntry>(this.storagePath), migration);
  }

  async snapshot(): Promise<IMigrationSnapshot> {
    const history: IMigrationSnapshot = await this.history();
    const migrations: IMigrationEntry[] = uniqBy(
      orderBy(
        history.migrations,
        ['performedAt.seconds', 'performedAt.nanoseconds'],
        ['desc', 'desc']
      ),
      'migration.uid'
    );

    return {
      migrations,
      lastMigrationRun: this._getLastMigrationRun(migrations),
    };
  }

  async history(): Promise<IMigrationSnapshot> {
    const migrations = await getDocs(
      asColRef<IMigrationEntry>(this.storagePath)
    );

    return {
      migrations,
      lastMigrationRun: this._getLastMigrationRun(migrations),
    };
  }

  private _getLastMigrationRun(
    migrations: IMigrationEntry[]
  ): Timestamp | undefined {
    return migrations.reduce(
      (lastRun: Timestamp | undefined, migration: IMigrationEntry) => {
        return lastRun && lastRun.seconds > migration.performedAt.seconds
          ? lastRun
          : migration.performedAt;
      },
      undefined
    );
  }
}
