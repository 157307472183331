import { type IXSLXImport } from '@principle-theorem/shared';
import { type Row } from 'exceljs';
import {
  FEE_SCHEDULE_HEADERS,
  type IFeeScheduleToXSLX,
} from './fee-schedule-to-xlsx';

export class XSLXToFeeSchedule implements IXSLXImport<IFeeScheduleToXSLX> {
  headers = FEE_SCHEDULE_HEADERS;

  translate(row: Row): IFeeScheduleToXSLX {
    return {
      label: row.getCell('label').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IFeeScheduleToXSLX['mapTo'],
    };
  }
}
