import type {
  IMedipassClaim,
  IMedipassPayment,
  IMedipassWebhookData,
  ITransactionResult,
} from '@medipass/partner-sdk';
import { isObject } from '@principle-theorem/shared';
import { isArray, isNumber, isString } from 'lodash';

export function isPayment(data: unknown): data is IMedipassPayment {
  return (
    isObject(data) &&
    isString(data.paymentId) &&
    isString(data.paymentType) &&
    isString(data.status) &&
    isNumber(data.amount)
  );
}

export function isClaim(data: unknown): data is IMedipassClaim {
  return (
    isObject(data) &&
    isObject(data.healthFund) &&
    isString(data.healthFund.code) &&
    isString(data.healthFund.displayName) &&
    isString(data.claimId) &&
    isString(data.status) &&
    isNumber(data.amountExpectedBenefit) &&
    isNumber(data.amountActualBenefit)
  );
}

export function isTransaction(data: unknown): data is ITransactionResult {
  return (
    isObject(data) &&
    isString(data.invoiceReference) &&
    isNumber(data.amountCharged) &&
    isArray(data.claims) &&
    isArray(data.payments)
  );
}

export function isMedipassWebhookData(
  data: unknown
): data is IMedipassWebhookData {
  return (
    isObject(data) &&
    isString(data.transactionId) &&
    isString(data.event) &&
    isTransaction(data.transaction)
  );
}
