import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type ProgressBarMode } from '@angular/material/progress-bar';
import { DATE_FORMAT, toMoment } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFunnyLoadingMessages$ } from './funny-loader';
import {
  type IReportBuilderState,
  ReportBuilderStore,
} from '../report-builder.store';

interface ILoaderDisplay {
  heading: string;
  message: string;
  mode: ProgressBarMode;
  progress?: number;
}

@Component({
    selector: 'pr-report-builder-loader',
    templateUrl: './report-builder-loader.component.html',
    styleUrls: ['./report-builder-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReportBuilderLoaderComponent {
  loader$: Observable<ILoaderDisplay | undefined>;
  searchValue$: Observable<string>;

  constructor(public store: ReportBuilderStore) {
    this.searchValue$ = this.store.state$.pipe(
      map((state) => {
        const noDate = 'unknown';
        const dateRange = state.query?.scopeRequests.dateRange;
        const from = dateRange
          ? toMoment(dateRange.from).format(DATE_FORMAT)
          : noDate;
        const to = dateRange
          ? toMoment(dateRange.to).format(DATE_FORMAT)
          : noDate;
        return `${from} - ${to}.`;
      })
    );

    this.loader$ = combineLatest([
      this.store.state$,
      getFunnyLoadingMessages$(),
    ]).pipe(map(([state, message]) => this._getLoader(state, message)));
  }

  private _getLoader(
    state: IReportBuilderState<unknown>,
    message: string
  ): ILoaderDisplay | undefined {
    if (!state.loading) {
      return;
    }
    if (state.progress === undefined) {
      return { heading: 'Building data', mode: 'query', message };
    }
    if (state.progress === 1) {
      return { heading: 'Formatting data', mode: 'indeterminate', message };
    }
    return {
      heading: 'Downloading data',
      mode: 'determinate',
      progress: state.progress,
      message,
    };
  }
}
