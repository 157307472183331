import {
  AnyAutomation,
  AutomationCreator,
  CollectionGroup,
  IAutomatedFormIssue,
  IAutomatedNotification,
  IAutomation,
  IBrand,
  IChartedServiceSmartGroup,
  IEvent,
  IGeneratedTask,
  IPricedServiceCodeEntry,
  IPricedServiceCodeGroup,
  IServiceCode,
  IServiceCodeGroup,
  IServiceSmartGroup,
  IStaffer,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ITreatmentConfigurationRef,
  OrganisationCollection,
  ServiceCodeGroupType,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  INamedTypeDocument,
  IReffable,
  Timestamp,
  WithRef,
  all$,
  asDocRef,
  asyncForEach,
  doc$,
  findParentDocPath,
  getDoc,
  isSameRef,
  subCollection,
  toNamedDocument,
  undeletedQuery,
} from '@principle-theorem/shared';
import {
  compact,
  first,
  groupBy,
  keys,
  mapValues,
  sortBy,
  uniqWith,
  values,
  zipWith,
} from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutomationConfiguration } from '../../automation/automation-configuration';
import { AutomatedFormIssue } from '../../custom-forms/automated-form-issue';
import { AutomatedNotification } from '../../notification/automated-notification';
import { GeneratedTask } from '../../task/generated-task';
import { ChartedItemConfiguration } from '../charted-item-configuration';
import {
  PricedServiceCodeGroup,
  ServiceCodeGroup,
} from '../service-codes/ada-code-category';
import { ChartedServiceSmartGroup } from '../service-codes/charted-service-smart-group';
import { PricedServiceCodeEntry } from '../service-codes/service-code';
import { ServiceProviderHandler } from '../service-codes/service-provider';

export class TreatmentConfiguration {
  static init(
    overrides?: Partial<ITreatmentConfiguration>
  ): ITreatmentConfiguration {
    return {
      ...ChartedItemConfiguration.init(),
      duration: 0,
      description: '',
      conditions: [],
      checklists: [],
      educationalAids: [],
      packages: [],
      assetRequirements: {
        instruments: [],
        equipment: [],
        consumables: [],
      },
      serviceCodeGroups: [],
      serviceCodeSmartGroups: [],
      automatedNotificationRefs: [],
      generatedTaskRefs: [],
      automatedFormIssueRefs: [],
      ...overrides,
    };
  }

  static getBrandRef(
    config: IReffable<ITreatmentConfiguration>
  ): DocumentReference<IBrand> {
    const brandRefPath = findParentDocPath(
      config.ref.path,
      OrganisationCollection.Brands
    );
    if (!brandRefPath) {
      throw new Error('Brand not found');
    }
    return asDocRef<IBrand>(brandRefPath);
  }

  static col(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): CollectionReference<ITreatmentConfiguration> {
    return subCollection<ITreatmentConfiguration>(
      parent.ref,
      CollectionGroup.TreatmentConfigurations
    );
  }

  static all$(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): Observable<WithRef<ITreatmentConfiguration>[]> {
    return all$(undeletedQuery(this.col(parent))).pipe(
      map((category) => sortBy(category, ['name']))
    );
  }

  static getCombinedServiceCodes(
    config: WithRef<ITreatmentConfiguration>,
    groupTypes: ServiceCodeGroupType[] = [ServiceCodeGroupType.Required]
  ): IPricedServiceCodeEntry[] {
    const serviceCodeGroups = TreatmentConfiguration.getServiceGroups(
      config,
      groupTypes
    );
    return serviceCodeGroups
      .reduce(
        (codes: IPricedServiceCodeEntry[], group) => [
          ...codes,
          ...group.serviceCodes,
        ],
        []
      )
      .map((serviceCode) =>
        PricedServiceCodeEntry.init({
          ...serviceCode,
          chartedItemOrigin: toNamedDocument(config),
        })
      );
  }

  static getServiceGroups(
    config: WithRef<ITreatmentConfiguration>,
    groupTypes?: ServiceCodeGroupType[]
  ): IPricedServiceCodeGroup[] {
    return config.serviceCodeGroups
      .filter((group) => (groupTypes ? groupTypes.includes(group.type) : true))
      .map((group) => {
        const pricedGroup = PricedServiceCodeGroup.fromServiceCodeGroup(
          group,
          config
        );
        const selected = first(pricedGroup.serviceCodes);

        return {
          ...pricedGroup,
          selected: selected?.code,
        };
      });
  }

  static getSmartGroups(
    config: WithRef<ITreatmentConfiguration>
  ): IChartedServiceSmartGroup[] {
    return config.serviceCodeSmartGroups.map((serviceCodeSmartGroup) => {
      const codes = compact(
        serviceCodeSmartGroup.serviceCodes.map((code) =>
          ServiceProviderHandler.resolveServiceCode(
            serviceCodeSmartGroup.serviceCodeType,
            code
          )
        )
      );

      const pricedCodes = compact(codes)
        .map((codeInfo) => ({
          type: serviceCodeSmartGroup.serviceCodeType,
          code: codeInfo.code,
          taxStatus: codeInfo.taxStatus,
          chartedItemOrigin: toNamedDocument(config),
        }))
        .map((pricedCode) => PricedServiceCodeEntry.init(pricedCode));

      const group: IChartedServiceSmartGroup = ChartedServiceSmartGroup.init({
        ...serviceCodeSmartGroup,
        serviceCodes: pricedCodes,
      });

      const selected: IPricedServiceCodeEntry | undefined = first(
        group.serviceCodes
      );
      if (selected) {
        group.selected = selected.code;
      }
      return group;
    });
  }

  static removeSmartGroup(
    smartGroups: IServiceSmartGroup[],
    entry: IServiceSmartGroup
  ): IServiceSmartGroup[] {
    return smartGroups.filter((group) => group.uid !== entry.uid);
  }

  static addServiceCodeToGroup(
    groups: IServiceCodeGroup[],
    code: IServiceCode,
    type: ServiceCodeType
  ): IServiceCodeGroup[] {
    const entry = {
      type,
      code: code.code,
      taxStatus: code.taxStatus,
      quantity: 1,
    };

    const existingGroup = groups.find(
      (existing) => existing.type === ServiceCodeGroupType.Optional
    );

    const group = existingGroup ? existingGroup : ServiceCodeGroup.init();
    if (!existingGroup) {
      groups.push(group);
    }

    group.serviceCodes.push(entry);

    return groups;
  }

  static async convertNotificationsToAutomations(
    configuration: WithRef<ITreatmentConfiguration>,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedNotification>[]> {
    const notifications = await asyncForEach(
      configuration.automatedNotificationRefs,
      async (notificationRef) => {
        const notification = await getDoc(notificationRef);
        if (!AutomationConfiguration.isActive(notification)) {
          return;
        }
        const creator: INamedTypeDocument<ITreatmentConfiguration, string> = {
          ...toNamedDocument(configuration),
          type: AutomationCreator.TreatmentConfiguration,
        };
        return AutomatedNotification.generateFromConfig(
          notification,
          creator,
          event,
          brandRef,
          triggerAfterDate
        );
      }
    );
    return compact(notifications);
  }

  static async convertTasksToAutomations(
    configuration: WithRef<ITreatmentConfiguration>,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IGeneratedTask>[]> {
    const tasks = await asyncForEach(
      configuration.generatedTaskRefs,
      async (taskRef) => {
        const task = await getDoc(taskRef);
        if (!AutomationConfiguration.isActive(task)) {
          return;
        }
        const creator: INamedTypeDocument<ITreatmentConfiguration, string> = {
          ...toNamedDocument(configuration),
          type: AutomationCreator.TreatmentConfiguration,
        };
        return GeneratedTask.generateFromConfig(
          task,
          creator,
          event,
          brandRef,
          triggerAfterDate
        );
      }
    );
    return compact(tasks);
  }

  static async convertFormIssuesToAutomations(
    configuration: WithRef<ITreatmentConfiguration>,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedFormIssue>[]> {
    const notifications = await asyncForEach(
      configuration.automatedFormIssueRefs,
      async (formIssueRef) => {
        const formIssue = await Firestore.getDoc(formIssueRef);
        if (!AutomationConfiguration.isActive(formIssue)) {
          return;
        }
        const creator: INamedTypeDocument<ITreatmentConfiguration, string> = {
          ...toNamedDocument(configuration),
          type: AutomationCreator.TreatmentConfiguration,
        };
        return AutomatedFormIssue.generateFromConfig(
          formIssue,
          creator,
          event,
          brandRef,
          triggerAfterDate
        );
      }
    );
    return compact(notifications);
  }
}

export async function getAutomationsFromConfiguration(
  configuration: WithRef<ITreatmentConfiguration>,
  event: IEvent,
  brandRef: DocumentReference<IBrand>,
  triggerAfterDate?: Timestamp
): Promise<IAutomation<AnyAutomation>[]> {
  const tasks = await TreatmentConfiguration.convertTasksToAutomations(
    configuration,
    event,
    brandRef,
    triggerAfterDate
  );
  const notifications =
    await TreatmentConfiguration.convertNotificationsToAutomations(
      configuration,
      event,
      brandRef,
      triggerAfterDate
    );
  const formIssues =
    await TreatmentConfiguration.convertFormIssuesToAutomations(
      configuration,
      event,
      brandRef,
      triggerAfterDate
    );
  return [...tasks, ...notifications, ...formIssues];
}

export async function getAutomationsFromConfigurations$(
  configurations: WithRef<ITreatmentConfiguration>[],
  event: IEvent,
  brandRef: DocumentReference<IBrand>,
  triggerAfterDate?: Timestamp
): Promise<IAutomation<AnyAutomation>[]> {
  const uniqueConfigs = uniqWith(configurations, isSameRef);
  const automations = await asyncForEach(uniqueConfigs, (config) =>
    getAutomationsFromConfiguration(config, event, brandRef, triggerAfterDate)
  );
  return automations.flat();
}

export function getTreatmentConfigurations$(
  treatmentRefs: ITreatmentConfigurationRef[]
): Observable<WithRef<ITreatmentConfiguration>[]> {
  return combineLatest(
    treatmentRefs.map((treatmentRef) => doc$(treatmentRef.ref))
  );
}

export function getTreatmentRefQuantities(
  treatmentRefs: ITreatmentConfigurationRef[]
): number[] {
  return treatmentRefs.map((treatmentRef) => treatmentRef.quantity);
}

export function treatmentRefsToSumData$(
  treatmentRefs: ITreatmentConfigurationRef[]
): Observable<ITreatmentSumData[]> {
  const quantities = getTreatmentRefQuantities(treatmentRefs);
  return getTreatmentConfigurations$(treatmentRefs).pipe(
    map((treatments) => {
      return zipWith(
        quantities,
        treatments,
        (quantity: number, treatment: WithRef<ITreatmentConfiguration>) => {
          return { quantity, treatment };
        }
      );
    })
  );
}

export interface ITreatmentSumData {
  quantity: number;
  treatment: WithRef<ITreatmentConfiguration>;
}

export interface ITreatmentCategoryGroup {
  category?: WithRef<ITreatmentCategory>;
  treatments: WithRef<ITreatmentConfiguration>[];
}

export function treatmentConfigurationsToGroups(
  categories: WithRef<ITreatmentCategory>[],
  treatments: WithRef<ITreatmentConfiguration>[]
): ITreatmentCategoryGroup[] {
  const categoriesMap: Record<
    string,
    WithRef<ITreatmentCategory>
  > = categories.reduce(
    (acc, category) => ({ ...acc, [category.ref.path]: category }),
    {}
  );
  const categoryKeys = keys(categoriesMap);

  const groups = groupBy(treatments, (treatment) =>
    treatment.category && categoryKeys.includes(treatment.category.path)
      ? treatment.category?.path
      : undefined
  );

  const mapped = mapValues(groups, (items, key) => ({
    category: categoriesMap[key],
    treatments: sortBy(items, 'name'),
  }));

  return values(mapped);
}
