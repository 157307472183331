import { type Observable } from 'rxjs';

export type ResolverResult<T> = Observable<T> | Promise<T> | T;

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type PartialRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type NotEmpty<T> = keyof T extends never ? never : T;

export type ObjectOfType<T> = { [key: string]: T };

export type RequireProps<T extends object, K extends keyof T> = Omit<T, K> & {
  [MK in K]-?: NonNullable<T[MK]>;
};

export type PropertiesOf<K> = {
  [key in keyof K]: K[key];
};

export type PropertyNames<T> = {
  [K in keyof T]: T[K] extends unknown ? K : never;
}[keyof T];

export type FieldsOfType<T, Type> = {
  [K in keyof T]: T[K] extends Type ? K : never;
}[keyof T];

export type TypeGuardFn<T> = (item: unknown) => item is T;

export type UnwrapArray<T> = T extends (infer U)[] ? U : T;

export type JSONSerialised = JSONSerialisable<string>;

export type JSONSerialisable<T> = {
  data: T;
  type: 'jsonSerialisable';
};

// From: https://stackoverflow.com/a/47375979
type SharedPropertiesMap<A, B> = {
  [K in keyof A & keyof B]: A[K] extends B[K] ? K : never;
};

export type SharedProperties<A, B> = {
  [K in SharedPropertiesMap<A, B>[keyof A & keyof B]]: A[K];
};
