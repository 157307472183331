import { type IPermission } from './permission';

export enum FeatureVisibility {
  None = 'none',
  Dev = 'dev',
  Alpha = 'alpha',
  Beta = 'beta',
  Stable = 'stable',
}

export interface IFeature {
  label: string;
  visibility: FeatureVisibility;
  permissions: IPermission[];
  features?: IFeature[];
}

export const FEATURE_DEFAULTS: Pick<IFeature, 'visibility' | 'permissions'> = {
  visibility: FeatureVisibility.Dev,
  permissions: [],
};
