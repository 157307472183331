<div
  (click)="$event.stopPropagation()"
  class="flex items-center justify-between gap-2"
>
  <span>
    {{ label }}
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info</mat-icon>
  </span>
  <mat-slide-toggle
    [disabled]="disabled$ | async"
    [formControl]="formCtrl"
   />
</div>
