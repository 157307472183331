import {
  ICustomMapping,
  ICustomMappingHandler,
  ICustomMappings,
  ICustomMappingsHandler,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  upsertDocByProperty,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { PracticeMigration } from '../practice-migrations';

export abstract class BaseCustomMappingsHandler
  implements ICustomMappingsHandler
{
  abstract migrationType: string;
  abstract migrationVersion: string;
  abstract customMapping: ICustomMappings;
  abstract mappingHandlers: ICustomMappingHandler[];

  get mappings(): ICustomMapping[] {
    return this.mappingHandlers.map((handler) => handler.customMapping);
  }

  canHandle(mappings: ICustomMappings): boolean {
    return isEqual(mappings.metadata, this.customMapping.metadata);
  }

  async addEntities(migration: WithRef<IPracticeMigration>): Promise<void> {
    await asyncForEach(this.mappings, (mapping) =>
      upsertDocByProperty(
        PracticeMigration.customMappingCol({
          ref: migration.ref,
        }),
        mapping,
        'uid',
        mapping.metadata.type
      )
    );
  }
}
