<mat-toolbar color="accent">Refund Credit Transaction</mat-toolbar>

<form [formGroup]="form" (submit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field *ngIf="!this.data.fromCredit">
      <mat-label>Enter Amount to Refund</mat-label>
      <span matTextPrefix>$</span>
      <input matInput type="number" formControlName="amount" />
      <mat-error *ngIf="form.controls.amount.hasError('max')">
        You can refund a maximum of ${{ max$ | async }}
      </mat-error>
    </mat-form-field>

    <mat-selection-list
      [compareWith]="compareFn"
      formControlName="accountCredits"
    >
      <div mat-subheader>Used Account Credits</div>
      <ng-container *ngIf="data.credits as credits">
        <ng-container
          *ngFor="let credit of credits; trackBy: trackByAccountCredit"
        >
          <mat-list-option
            *ngIf="credit.used"
            checkboxPosition="before"
            [value]="credit"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
            >
              <div fxLayout="column" fxLayoutAlign="start start">
                <span class="description">
                  {{ credit.description }}
                </span>
                <small
                  class="reserved-for"
                  *ngIf="credit.reservedFor?.practitioner as practitioner"
                  >Reserved for: {{ practitioner.name }}</small
                >
              </div>

              <div class="amount-remaining">
                Used:
                <span class="warn">
                  {{ credit | map: creditAvailable : this | currency }}
                </span>
              </div>
            </div>
          </mat-list-option>
        </ng-container>
      </ng-container>
    </mat-selection-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
