import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  AccountCreditActionsService,
  GlobalStoreService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ObservableDataSource,
  TableFilters,
  TrackByFunctions,
  extendSortingDataAccessor,
  timestampSortingAccessor,
  type IFilterOption,
} from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  Patient,
  getTransactionProviderName,
} from '@principle-theorem/principle-core';
import {
  IAccountCredit,
  ITreatmentCategory,
  type IPatient,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type IAccountCreditReportRecord } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  multiMap,
  type FieldsOfType,
  type ITimePeriod,
  type WithRef,
  IReffable,
} from '@principle-theorem/shared';
import { compact, startCase, sum } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountCreditToCSV } from './account-credit-to-csv';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'pr-account-credits-report-table',
  templateUrl: './account-credits-report-table.component.html',
  styleUrls: ['./account-credits-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCreditsReportTableComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByTransactionRef = TrackByFunctions.ref<IAccountCreditReportRecord>();
  records$ = new ReplaySubject<IAccountCreditReportRecord[]>(1);
  filtered$: Observable<IAccountCreditReportRecord[]>;
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  dataSource: ObservableDataSource<IAccountCreditReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  pageSizeOptions = [50, 100, 250, 500];
  displayedColumns = [
    'patientName',
    'description',
    'reservedFor',
    'treatmentCategory',
    'issuedAt',
    'invoiceStatus',
    'invoice',
    'transactionAmounts',
    'accountCreditTotal',
    'accountCreditUsed',
    'accountCreditRemaining',
    'actions',
  ];
  providerFilters$: Observable<IFilterOption<string>[]>;
  selectedProviders$ = new BehaviorSubject<string[]>([]);
  stafferFilters$: Observable<IFilterOption<string>[]>;
  selectedStaff$ = new BehaviorSubject<string[]>([]);
  statusFilters$: Observable<IFilterOption<string>[]>;
  selectedStatuses$ = new BehaviorSubject<string[]>([]);
  csvTranslator = new AccountCreditToCSV();
  @Input() title = '';
  @Input() tooltip = '';

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  set tablePaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @Input()
  set records(records: IAccountCreditReportRecord[]) {
    if (records) {
      this.records$.next(records);
    }
  }

  constructor(
    private _globalStore: GlobalStoreService,
    public creditActions: AccountCreditActionsService,
    private _stateNav: StateBasedNavigationService
  ) {
    this.providerFilters$ = this.records$.pipe(
      TableFilters.create<IAccountCreditReportRecord>((record) =>
        compact(record.transactions.map(getTransactionProviderName))
      )
    );
    this.stafferFilters$ = this.records$.pipe(
      TableFilters.create((record) =>
        compact([record.accountCredit.reservedFor.practitioner?.name])
      )
    );
    this.statusFilters$ = this.records$.pipe(
      TableFilters.create((record) =>
        compact([record.invoice?.status]).map(startCase)
      )
    );

    const providerFilter = TableFilters.multiSelect(
      this.selectedProviders$,
      (record: IAccountCreditReportRecord, selected) =>
        record.transactions
          .map(getTransactionProviderName)
          .map(TableFilters.toFilterId)
          .includes(selected)
    );
    const stafferFilters = TableFilters.multiSelect(
      this.selectedStaff$,
      (record: IAccountCreditReportRecord, selected) => {
        const stafferName = TableFilters.toFilterId(
          record.accountCredit.reservedFor.practitioner?.name ?? ''
        );
        return stafferName === selected;
      }
    );
    const statusFilters = TableFilters.multiSelect(
      this.selectedStatuses$,
      (record: IAccountCreditReportRecord, selected) =>
        TableFilters.toFilterId(record.invoice?.status ?? '') === selected
    );

    this.filtered$ = this.records$.pipe(
      providerFilter,
      stafferFilters,
      statusFilters
    );

    this.dataSource = new ObservableDataSource(this.filtered$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getMobileNumber(patient: WithRef<IPatient>): string | undefined {
    return Patient.getMobileNumber(patient);
  }

  sumAmount$(
    key: FieldsOfType<IAccountCreditReportRecord['accountCredit'], number>
  ): Observable<number> {
    return this.filtered$.pipe(
      multiMap(
        (item) =>
          item.accountCredit[
            key as keyof IAccountCreditReportRecord['accountCredit']
          ]
      ),
      map(sum)
    );
  }

  getProviderName(transaction: ITransaction): string {
    return getTransactionProviderName(transaction);
  }

  filterActiveProviders$(
    transactions: WithRef<ITransaction>[]
  ): Observable<WithRef<ITransaction>[]> {
    return this.selectedProviders$.pipe(
      map((selectedProviders) => {
        if (!selectedProviders.length) {
          return transactions;
        }
        return transactions.filter((transaction) => {
          const provider = getTransactionProviderName(transaction);
          return selectedProviders.includes(TableFilters.toFilterId(provider));
        });
      })
    );
  }

  resolveTreatmentCategory$(
    record: IAccountCreditReportRecord
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    return this.resolveAccountCredit$(record).pipe(
      switchMap((credit) => {
        if (credit.reservedFor.treatmentCategory) {
          return this._globalStore.getTreatmentCategory$(
            credit.reservedFor.treatmentCategory
          );
        }
        return of(undefined);
      })
    );
  }

  resolveAccountCredit$(
    record: IAccountCreditReportRecord
  ): Observable<WithRef<IAccountCredit>> {
    return Firestore.doc$(record.accountCredit.ref);
  }

  accountCreditUrl$(credit: IReffable<IAccountCredit>): Observable<string[]> {
    return this._stateNav.link.brand$([
      'patients',
      AccountCredit.patientRef(credit).id,
      'account',
      'credits',
      credit.ref.id,
    ]);
  }

  private _sortingDataAccessor(
    data: IAccountCreditReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'issuedAt':
        return timestampSortingAccessor(data.accountCredit.createdAt);
      default:
        return;
    }
  }
}
