import { Component, Input } from '@angular/core';
import {
  type OrganisationStatus,
  ORGANISATION_STATUS_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-organisation-status',
    templateUrl: './organisation-status.component.html',
    styleUrls: ['./organisation-status.component.scss'],
    standalone: false
})
export class OrganisationStatusComponent {
  @Input() status: OrganisationStatus;
  statusColourMap: {
    [key in OrganisationStatus]: string;
  } = ORGANISATION_STATUS_MAP;
}
