import { type EntityAdapter, type EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export interface IEntityAction<T extends string = string> {
  id: T;
}

export function updateEntity<S, A extends Action & IEntityAction>(
  adapter: EntityAdapter<S>,
  getChanges: (before: S, action: A) => Partial<S>
) {
  return (state: EntityState<S>, action: A) => {
    const before = state.entities[action.id];
    if (!before) {
      return state;
    }
    const changes: Partial<S> = getChanges(before, action);
    return adapter.updateOne({ id: action.id, changes }, state);
  };
}

export function upsertEntity<S, A extends Action & IEntityAction>(
  adapter: EntityAdapter<S>,
  defaultEntity: S,
  getChanges: (before: S, action: A) => S
) {
  return (state: EntityState<S>, action: A) => {
    const before = state.entities[action.id] ?? {
      ...defaultEntity,
      id: action.id,
    };
    const changes: S = getChanges(before, action);
    return adapter.upsertOne(changes, state);
  };
}
