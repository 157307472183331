<mat-toolbar color="accent">
  <span fxFlex>Cancel Appointment Follow Up</span>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<form [formGroup]="form">
  <pt-alert
    alert="In nearly all cases we want to ensure that a follow up is set."
   />

  <div class="layout-margin">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Why isn't a follow up being set?</mat-label>
      <pr-content-editor
        autofocus
        formControlName="noFollowUpReason"
        [menuEnabled]="false"
        [required]="true"
       />
    </mat-form-field>
  </div>

  <pt-buttons-container padding="none" class="layout-margin">
    <button mat-stroked-button mat-dialog-close type="button">Close</button>
    <button
      mat-flat-button
      color="warn"
      [disabled]="form.invalid"
      (click)="cancelFollowUp()"
    >
      Cancel Follow Up
    </button>
  </pt-buttons-container>
</form>
