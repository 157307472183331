import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { FollowUpsDashboardComponent } from './pages/follow-ups-dashboard/follow-ups-dashboard.component';
import { NgFollowUpsModule } from './ng-follow-ups.module';

const routes: Routes = [
  {
    path: '',
    component: FollowUpsDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgFollowUpsModule],
  exports: [RouterModule],
})
export class FollowUpPagesRoutingModule {}
