<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<form [formGroup]="form">
  <div class="mx-auto flex max-w-5xl flex-col gap-4 p-4">
    <pr-content-container>
      <h2 class="text-xl font-bold">Brand</h2>

      <div class="flex flex-col gap-4">
        <mat-form-field class="w-full">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>

        <div formGroupName="settings">
          <mat-form-field class="w-full">
            <mat-label>Timezone</mat-label>
            <mat-select formControlName="timezone" required>
              <mat-option
                *ngFor="let timezone of timezones; trackBy: trackByTimezone"
                [value]="timezone"
              >
                {{ timezone }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex flex-col gap-4">
          <div class="relative">
            <p
              class="absolute -top-3 left-3 bg-white p-1 text-xs text-gray-500"
            >
              Brand Logo
            </p>
            @if (brandLogoUrl$ | async) {
              <div
                class="absolute right-1 top-[3px] z-50"
                matTooltip="Remove Logo"
              >
                <button mat-icon-button (click)="removeLogo()">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            }
            <div class="rounded border border-solid border-gray-300">
              <pt-image-upload
                [menuButton]="true"
                label="Upload Logo"
                [uploadPath]="logoUploadPath$ | async"
                (imageUpload)="updateImageUrl($event)"
              />
            </div>
          </div>

          @if (brandLogoUrl$ | async; as brandLogoUrl) {
            <pt-image-preview [src]="brandLogoUrl" />
          }
        </div>
      </div>
    </pr-content-container>

    <div fxLayout="column" fxLayoutGap="16px" formGroupName="settings">
      <pr-content-container>
        <h2 class="text-xl font-bold">Appointment Scheduling</h2>
        <div formGroupName="scheduling">
          <pt-settings-menu-slide-toggle-item
            label="Enable Waitlist By Default"
            tooltip="Select whether the waitlist is enabled by default"
            formControlName="defaultWaitlistSettingsOn"
            ptElementTarget="enable-waitlist-by-default-button"
          />
        </div>
      </pr-content-container>

      <pr-content-container>
        <h2 class="text-xl font-bold">Treatment Plan Management</h2>

        <div class="flex flex-col gap-4" formGroupName="treatmentPlanning">
          <pt-settings-menu-slide-toggle-item
            label="Auto-complete treatment plan after last step"
            tooltip="Enabling this will automatically transition Treatment Plans to 'Completed' when the final step of the plan is completed"
            formControlName="autoCompletePlanAfterLastStep"
            ptElementTarget="auto-complete-treatment-plan-after-last-step-button"
          />

          <pt-settings-menu-slide-toggle-item
            label="Include Completed Treatment Plans in the Timeline scheduling workflow"
            tooltip="Enabling this will give controls to add new steps to completed treatment plans in the timeline scheduling workflow"
            formControlName="includeCompletedTreatmentPlansInTimeline"
            ptElementTarget="include-completed-treatment-plans-in-timeline-button"
          />
        </div>
      </pr-content-container>

      <pr-content-container>
        <h2 class="text-xl font-bold">Online Bookings</h2>

        <div class="flex flex-col" formGroupName="onlineBookings">
          <pt-settings-menu-slide-toggle-item
            label="Restrict Availability to Pre Blocks"
            tooltip="Only show availability for pre-blocks with 'Can be Booked Online' is selected'"
            formControlName="restrictToPreBlocks"
          />
          <pt-settings-menu-text-field
            label="Booking Request Success Message"
            tooltip="Enter the message that will be displayed to the patient after they have submitted a booking request."
            formControlName="requestSuccessMessage"
            subscriptSizing="dynamic"
            [placeholder]="onlineBookingSuccessMessagePlaceholder"
          />
          <pt-settings-menu-text-field
            label="Booking Success Redirect URL"
            tooltip="Enter the URL that the patient will be redirected to after they have submitted a booking request."
            formControlName="redirectUrl"
            errorMessage="Please enter a valid URL"
            placeholder="https://your-domain.com"
            [validatorFn]="isUrlValidator"
          />
          <pr-tracking-codes [form]="trackingCodeForm" />
        </div>
      </pr-content-container>

      <pr-content-container>
        <h2 class="text-xl font-bold">Online Forms</h2>

        <div class="flex flex-col" formGroupName="patientDetailsForm">
          <div class="inner-container flex flex-col">
            <h4 class="sub-header">Patient Details</h4>

            <pt-settings-menu-multi-select
              label="Required Patient Fields"
              formControlName="requiredFields"
              tooltip="Select whether any optional patient fields should be required to be filled out"
              [options]="patientFields"
            />
            <span class="warn self-end">
              {{
                'patientDetailsForm'
                  | map: integrationWarningMessage$ : this
                  | async
              }}
            </span>
          </div>
        </div>
      </pr-content-container>

      <pr-content-container>
        <h2 class="text-xl font-bold">Patient</h2>

        <div class="flex flex-col gap-4" formGroupName="patient">
          <pt-settings-menu-multi-select
            label="Required Patient Fields"
            formControlName="requiredFields"
            tooltip="Select whether any optional patient fields should be required to be filled out"
            [options]="patientFields"
            ptElementTarget="required-patient-fields-button"
          />
          <span class="warn self-end">
            {{ 'patient' | map: integrationWarningMessage$ : this | async }}
          </span>
        </div>
      </pr-content-container>

      <pr-content-container>
        <h2 class="text-xl font-bold">Optional Display Information</h2>

        <div formGroupName="optionalDisplayInformation">
          <pt-settings-menu-multi-select
            label="Appointment Confirmation Page"
            formControlName="appointmentConfirmationInfo"
            tooltip="Select additional information to display on the appointment confirmation page"
            [options]="optionalAppointmentConfirmationInfo"
            ptElementTarget="optional-appointment-confirmation-info-button"
          />
        </div>
      </pr-content-container>
    </div>

    <div class="flex flex-col gap-4" *prIsManagement>
      <pr-content-container>
        <h2 class="text-xl font-bold">Management Only Settings</h2>

        <div class="flex flex-col gap-4" formGroupName="settings">
          <mat-form-field fxFlex>
            <mat-label>Enabled Managed Reports</mat-label>
            <mat-select multiple formControlName="availableSystemReports">
              @for (
                managedReport of managedReportOptions;
                track managedReport.type
              ) {
                <mat-option
                  [value]="managedReport.type"
                  [matTooltip]="managedReport.description"
                  matTooltipPosition="right"
                  matTooltipShowDelay="300"
                >
                  {{ managedReport.name }}
                </mat-option>
              }
            </mat-select>
            <mat-hint>{{ defaultReportsSummary }}</mat-hint>
          </mat-form-field>

          <pt-settings-menu-slide-toggle-item
            label="Display Migrated Treatment Chart Filter"
            tooltip="Allow filtering charted treatment by migration status of the parent Treatment Step"
            formControlName="displayMigratedChartFilter"
          />
        </div>
      </pr-content-container>
    </div>

    <pr-content-container margin="mb-2" class="sticky bottom-0 z-50">
      <pt-buttons-container>
        <button
          mat-flat-button
          color="primary"
          [disabled]="isDisabled$ | async"
          (click)="save()"
        >
          Save Changes
        </button>
      </pt-buttons-container>
    </pr-content-container>
  </div>
</form>
