import { TypesenseCluster } from '@principle-theorem/principle-core/interfaces';
import { TyroIClientUrl } from '@principle-theorem/tyro';
import npmPackage from '../../../../package.json';
import { type IEnvironment } from './ienvironment';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: IEnvironment = {
  production: false,
  useEmulator: false,
  version: npmPackage.version,
  durationStep: 5,
  includeDevTools: false,
  storeDevtoolsLogOnlyMode: false,
  firebase: {
    apiKey: 'AIzaSyCNu3BLkUjru3HREkeK2VJ_3RmsW2ZL910',
    authDomain: 'dev.principle.dental',
    databaseURL:
      'https://principle-dev-bridge.asia-southeast1.firebasedatabase.app',
    projectId: 'principle-dev',
    storageBucket: 'principle-dev.appspot.com',
    messagingSenderId: '371142544020',
    appId: '1:371142544020:web:cd90fe10f512f12b9a4281',
  },
  maps: {
    apiKey: 'AIzaSyAeMHeFTUB2_4r4q4vLfY-vdNSEr_A1H5U',
    language: 'en-AU',
    region: 'AU',
  },
  ngxAuthFirebaseUI: {
    enableFirestoreSync: false,
    toastMessageOnAuthSuccess: true,
    toastMessageOnAuthError: false,
    authGuardFallbackURL: '/login',
    authGuardLoggedInURL: '/',
    passwordMaxLength: 60,
    passwordMinLength: 7,
    nameMaxLength: 50,
    nameMinLength: 2,
    guardProtectedRoutesUntilEmailIsVerified: true,
  },
  passwordlessLogin: {
    url: 'http://localhost:4200/activate',
    handleCodeInApp: true,
  },
  intercom: {
    appId: 'kls329z5',
    secretKey: 'ffcqfVYtPAsJFGJPzmR3VPFzPHiUdWO_tGHN8sQg',
    appName: 'Principle',
  },
  slack: {
    clientId: '311078734548.838581520801',
  },
  analytics: {
    gst: {
      trackingIds: ['G-75JHLT06BG'],
    },
  },
  stackdriver: {
    key: 'AIzaSyAlkse_Sq5ivQC4foouCW-Ylw0eGKXYStw',
    projectId: 'principle-dev',
  },
  medipass: {
    appId: 'principle-web',
    appVersion: '1',
    env: 'stg',
  },
  stripe: {
    apiKey: 'pk_test_Kx7goga9gXHQUkfPGLKAyzQf000ZovwryH',
    connectClientId: 'ca_HjYFQsuqA7051MiGg0n20ERtOAhrXqhL',
  },
  logRocketKey: 'h6s5jg/principle-dev',
  tyro: {
    defaultIClient: TyroIClientUrl.Simulator,
    apiKey: 'pretendApiKey',
    posProductData: {
      posProductVendor: 'Principle Theorem',
      posProductName: 'Principle',
      posProductVersion: '1.0.0',
    },
  },
  hicapsConnect: {
    PmsName: 'Not 4 Public Release',
    PmsKey: '7952265',
  },
  typesense: {
    [TypesenseCluster.Patients]: {
      nodes: [
        {
          host: '4bwguad3r9jc2yiep-1.a1.typesense.net',
          port: 443,
          protocol: 'https',
        },
      ],
      apiKey: 'L6gg0F8Cb6o8z66CGZzgLoj8xJ5Fuufo',
      clusterName: TypesenseCluster.Patients,
    },
    [TypesenseCluster.Tasks]: {
      nodes: [
        {
          host: '4bwguad3r9jc2yiep-1.a1.typesense.net',
          port: 443,
          protocol: 'https',
        },
      ],
      apiKey: 'L6gg0F8Cb6o8z66CGZzgLoj8xJ5Fuufo',
      clusterName: TypesenseCluster.Tasks,
    },
  },
  appUrl: 'https://dev.principle.dental',
  podium: {
    clientId: '382634d1-e550-4a7f-9254-7d9c21515e3b',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
