<form [formGroup]="form">
  @if (practice$ | async; as practice) {
    <p class="font-bold">{{ practice.name }}</p>
  }
  <div class="flex items-start gap-4">
    <mat-form-field class="w-full">
      <mat-label>Adjust Horizontally</mat-label>
      <input matInput type="number" formControlName="horizontalOffset" />
      <mat-hint>
        Move horizontally in mm to the desired position (e.g., 0.5 or -0.5)
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Adjust Vertically</mat-label>
      <input matInput type="number" formControlName="verticalOffset" />
      <mat-hint>
        Move vertically in mm to the desired position (e.g., 0.5 or -0.5)
      </mat-hint>
    </mat-form-field>
  </div>
</form>
