<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="target"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayPositions]="positions$ | ngrxPush"
  (detach)="connectedOverlayDetach()"
>
  <div #tooltip role="tooltip" aria-label="Tooltip" class="tooltip-container">
    <ng-content></ng-content>
    <ng-template [cdkPortalOutlet]="portal"></ng-template>
  </div>
</ng-template>
