import { JsonSchemaFormService } from '@ajsf/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ILayoutNode, IOptions } from '../custom-form-widget-interfaces';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'pr-custom-form-textarea-widget',
  templateUrl: './custom-form-textarea-widget.component.html',
  styleUrls: ['./custom-form-textarea-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex' },
  standalone: false,
})
export class CustomFormTextareaWidgetComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formControl: FormControl;
  controlName: string;
  controlValue: string;
  controlDisabled = false;
  boundControl = false;
  options: IOptions;
  @Input() layoutNode: ILayoutNode;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    private jsf: JsonSchemaFormService,
    private _ngZone: NgZone
  ) {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit(): void {
    this.options = this.layoutNode.options || {};
    this.jsf.initializeControl(this);
    if (
      !this.options.notitle &&
      !this.options.description &&
      this.options.placeholder
    ) {
      this.options.description = this.options.placeholder;
    }
    this.formControl.setValidators(
      this.options.required ? [Validators.required] : []
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getLabel(options: IOptions): string | undefined {
    return options.notitle ? '' : options.title;
  }
}
