import { createAction, props } from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import { type PatientEntity } from '../reducers/states';

export enum PatientActionTypes {
  AddPatientFromResolver = '[Patient Resolver] Add Resolved Patient',
  SetSelectedPatient = '[Patients] Set Selected Patient',
}

export interface ILoadPatientScopeSuccessData {
  patient: PatientEntity;
}

export interface ISetSelectedPatientData {
  id: string;
}

export const addPatientFromResolver = createAction(
  PatientActionTypes.AddPatientFromResolver,
  props<SerialisedData<ILoadPatientScopeSuccessData>>()
);

export const setSelectedPatient = createAction(
  PatientActionTypes.SetSelectedPatient,
  props<SerialisedData<ISetSelectedPatientData>>()
);
