import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  IHasSourceIdentifier,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  getError,
  toISODate,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IPraktikaPatient,
} from '../../source/entities/patient';
import {
  PatientClinicalNoteSourceEntity,
  PraktikaClinicalNoteType,
  type IPraktikaClinicalNote,
  type IPraktikaClinicalNoteTranslations,
} from '../../source/entities/patient-clinical-notes';
import { PraktikaItemCodeMappingHandler } from '../mappings/item-code';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<IPraktikaPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    clinicalNotes: new PatientClinicalNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IClinicalNoteJobData<IPraktikaPatient>[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(staff$, sourceItemCodes$),
        map(([sourcePatients, staff, sourceItemCodes]) =>
          sourcePatients.map((sourcePatient) => ({
            staff,
            sourcePatient,
            sourceItemCodes,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalNoteJobData<IPraktikaPatient>
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    try {
      const notes = await this.sourceEntities.clinicalNotes.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

      const clinicalNotes = await buildClinicalNotes(notes, data.staff, [
        PraktikaClinicalNoteType.Clinical,
      ]);

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }
}

export async function buildClinicalNotes(
  notes: IGetRecordResponse<
    IPraktikaClinicalNote,
    IPraktikaClinicalNoteTranslations
  >[],
  staff: WithRef<ITranslationMap<IStaffer>>[],
  noteTypeFilters: PraktikaClinicalNoteType[] = []
): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
  return asyncForEach(
    notes
      .filter((note) => !note.data.data.date_deleted)
      .filter((note) => {
        if (!noteTypeFilters.length) {
          return true;
        }
        return noteTypeFilters.includes(note.data.data.type);
      }),
    async (note) => {
      const authorId = note.data.data.author_id?.toString();

      // Default provider is used from custom mapping with id: 0
      let sourceOwner = staff.find(
        (staffer) => staffer.sourceIdentifier === '0'
      )?.destinationIdentifier;

      if (authorId) {
        sourceOwner =
          staff.find((staffer) => staffer.sourceIdentifier === authorId)
            ?.destinationIdentifier ?? sourceOwner;
      }

      if (!sourceOwner) {
        throw new Error(`No author found for the id ${authorId ?? ''}`);
      }

      const owner = await Firestore.getDoc(sourceOwner);
      return {
        sourceIdentifier: note.record.uid,
        ...ClinicalNote.init({
          owner: stafferToNamedDoc(owner),
          content: note.data.data.text
            ? initVersionedSchema(note.data.data.text)
            : initVersionedSchema(),
          createdAt: note.data.translations.dateCreated,
          immutable: true,
          recordDate: toISODate(note.data.translations.dateCreated),
        }),
      };
    }
  );
}
