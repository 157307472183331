import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  TypedFormControl,
  TypedFormGroup,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  IOrganisation,
  IDemoSpace,
  RootCollection,
  ConfigDocuments,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  asColRef,
  getDoc$,
  httpsCallable,
  serialise,
  slugify,
  snapshot,
  toISODate,
} from '@principle-theorem/shared';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';

type SeedDemoFormData = {
  organisation: WithRef<IOrganisation>;
  prefixFileName: string;
  anchorDate: moment.Moment;
};

@Component({
  selector: 'pr-seed-demo-space',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
  ],
  templateUrl: './seed-demo-space.component.html',
  styleUrl: './seed-demo-space.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class SeedDemoSpaceComponent implements OnDestroy {
  private _dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);
  private _onDestroy$ = new Subject<void>();
  private _fileExists$ = new BehaviorSubject<boolean>(false);
  demoSpaces$: Observable<WithRef<IDemoSpace>>;
  seedingDemoSpace$ = new BehaviorSubject<boolean>(false);
  organisations$ = new ReplaySubject<WithRef<IOrganisation>[]>(1);
  fileName$ = new BehaviorSubject<string>('');

  form = new TypedFormGroup<SeedDemoFormData>({
    organisation: new TypedFormControl<WithRef<IOrganisation>>(undefined, [
      Validators.required,
    ]),
    prefixFileName: new TypedFormControl<string>(''),
    anchorDate: new TypedFormControl<moment.Moment>(undefined, [
      Validators.required,
    ]).withGuard(moment.isMoment),
  });

  @Input()
  set organisations(organisations: WithRef<IOrganisation>[]) {
    if (organisations) {
      this.organisations$.next(organisations);
    }
  }

  constructor() {
    this.demoSpaces$ = getDoc$(
      asColRef<IDemoSpace>(RootCollection.Configuration),
      ConfigDocuments.DemoSpace
    );

    this.form.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ organisation, prefixFileName }) => {
        if (!organisation) {
          this.fileName$.next('');
          return;
        }
        const fileName = this._generateFileName(prefixFileName);

        this.fileName$.next(fileName);
      });

    combineLatest([
      this.fileName$,
      this.demoSpaces$.pipe(map((demo) => demo.fileNames)),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([fileName, demoSpaces]) => {
        this._fileExists$.next(demoSpaces.includes(fileName));
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async seedDemoSpace(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const { organisation, prefixFileName, anchorDate } =
      this.form.getRawValue();
    const seedFileName = this._generateFileName(prefixFileName);
    const fileExists = await snapshot(this._fileExists$);

    if (fileExists) {
      const data = confirmationDialogData({
        title: 'Seed Demo Space Data',
        prompt: `The file ${seedFileName} already exists. Are you sure you want to overwrite it?`,
        submitLabel: 'Confirm',
      });
      const confirmed = await this._dialog
        .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
          ConfirmDialogComponent,
          DialogPresets.small({ data })
        )
        .afterClosed()
        .toPromise();

      if (!confirmed) {
        return;
      }
    }

    const seedData = serialise({
      orgRef: organisation.ref,
      seedFileName,
      anchorDate: toISODate(anchorDate),
    });
    this._snackBar.open('Seeding Started', 'Close');
    this.seedingDemoSpace$.next(true);
    try {
      await snapshot(httpsCallable('http-demoWorkspace-seed')(seedData));
      if (!fileExists) {
        const doc = await snapshot(this.demoSpaces$);
        const fileNames = [...doc.fileNames, seedFileName].sort();
        await Firestore.saveDoc({ ...doc, fileNames });
      }
      this._snackBar.open('Seeding Complete', 'Close');
    } catch (error) {
      this._snackBar.open('Failed 🫠', 'Close');
      throw new Error('Failed to seed demo space');
    }
    this.seedingDemoSpace$.next(false);
    this.form.reset();
  }

  async deleteDemoSpace(fileName: string): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Demo Space Data',
      prompt: `Are you sure you want to delete the file ${fileName}?`,
      submitLabel: 'Confirm',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    const doc = await snapshot(this.demoSpaces$);
    const fileNames = doc.fileNames.filter((name) => name !== fileName);

    await Firestore.patchDoc(doc.ref, { fileNames });
  }

  private _generateFileName(prefixFileName: string): string {
    const date = moment().format('DD-MM-YYYY');
    const name = prefixFileName ? `${prefixFileName}-${date}` : `${date}`;
    return `${slugify(name.toLowerCase()).trim()}.json`;
  }
}
