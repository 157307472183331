import { randNumber, randWord } from '@ngneat/falso';
import {
  IInvoice,
  IPaymentPlan,
  IPaymentSchedule,
  IStatusHistory,
  ITreatmentPlan,
  PaymentFrequency,
  PaymentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import {
  BaseFirestoreMock,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { PaymentPlan } from './payment-plan';

export class PaymentPlanMock
  extends BaseFirestoreMock
  implements Properties<IPaymentPlan>
{
  get name(): string {
    return randWord();
  }

  get durationLength(): number {
    return randNumber({
      min: 3,
      max: 12,
    });
  }

  get durationType(): string {
    return 'months';
  }

  get invoices(): DocumentReference<IInvoice>[] {
    return [MockDocRef<IInvoice>()];
  }

  get treatmentPlan(): DocumentReference<ITreatmentPlan> {
    return MockDocRef<ITreatmentPlan>();
  }

  get status(): PaymentPlanStatus {
    return PaymentPlanStatus.Active;
  }

  get statusHistory(): IStatusHistory<PaymentPlanStatus>[] {
    return [
      {
        status: PaymentPlanStatus.Draft,
        updatedAt: MockTimestamp(),
      },
      {
        status: PaymentPlanStatus.Active,
        updatedAt: MockTimestamp(),
      },
    ];
  }

  get paymentFrequency(): PaymentFrequency {
    return PaymentFrequency.Monthly;
  }

  get paymentSchedule(): IPaymentSchedule[] {
    return [MockPaymentSchdule(), MockPaymentSchdule()];
  }

  get total(): number {
    return randNumber({
      min: 200,
      max: 2000,
    });
  }

  get completedAt(): Timestamp {
    return MockTimestamp();
  }
}

function MockPaymentSchdule(): IPaymentSchedule {
  return {
    amount: randNumber({
      min: 200,
      max: 400,
    }),
    date: MockTimestamp(),
  };
}

// TODO: update to new payment plan format
export const PAYMENT_PLANS: IPaymentPlan[] = [
  {
    name: '3 month plan',
    durationType: 'months',
    durationLength: 3,
    paymentFrequency: PaymentFrequency.Weekly,
    paymentFormat: 'card',
  },
  {
    name: '12 month plan',
    durationType: 'year',
    durationLength: 1,
    paymentFrequency: PaymentFrequency.Fortnightly,
    paymentFormat: 'card',
  },
  {
    name: 'Invisalign base plan',
    durationType: 'months',
    durationLength: 24,
    paymentFrequency: PaymentFrequency.Monthly,
    paymentFormat: 'card',
  },
].map((data) => PaymentPlan.init(data));
