<ng-container *ngIf="buttonType$ | async as buttonType">
  <div *ngIf="buttonType === 'block'; else basic" class="block">
    <button
      fxFill
      mat-button
      [ngClass]="{ active: isActive$ | async, disabled: isDisabled$ | async }"
      (click)="runCommand()"
    >
      <div class="flex items-center p-1">
        <div class="icon">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <span>{{ buttonText }}</span>
        <span fxFlex></span>
        <span class="shortcut" *ngIf="shortcut">{{
          shortcut | translateForPlatform
        }}</span>
      </div>
    </button>
  </div>

  <ng-template #basic>
    <ng-container *ngIf="buttonType === 'submenu-detail'">
      <div class="submenu-detail">
        <button
          fxFill
          mat-menu-item
          [ngClass]="{ active: isActive$ | async }"
          [disabled]="isDisabled$ | async"
          (click)="runCommand()"
          [matTooltip]="
            tooltip +
            (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
          "
          matTooltipShowDelay="300"
          matTooltipPosition="after"
          [matTooltipDisabled]="textOnly"
        >
          <div class="flex items-center p-1">
            <mat-icon *ngIf="!textOnly">{{ icon }}</mat-icon>
            <span>{{ buttonText }}</span>
            <span fxFlex></span>
            <span class="shortcut" *ngIf="shortcut">{{
              shortcut | translateForPlatform
            }}</span>
          </div>
        </button>
      </div>
    </ng-container>
    <button class="flex grow !justify-start" mat-button (click)="runCommand()">
      <mat-icon>{{ icon }}</mat-icon>
      <span>{{ buttonText }}</span>
    </button>
  </ng-template>
</ng-container>
