<div fxLayout="row" fxLayoutAlign="start end">
  <ng-container *ngIf="total$ | async as total; else loader">
    <div
      class="mat-headline-5"
      [ngStyle]="{ 'font-size.px': size, 'line-height.px': size * 0.66 }"
    >
      {{ total | metric }}
    </div>
    <!-- <pr-measure-history-summary class="history-summary" *ngIf="showHistory">
    </pr-measure-history-summary> -->
  </ng-container>

  <ng-template #loader>
    <mat-spinner [diameter]="size / 2" />
  </ng-template>
</div>
