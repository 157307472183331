<div class="flex flex-col gap-4">
  @for (
    group of store.orGroups$ | async;
    track group.uid;
    let isFirst = $first;
    let isLast = $last
  ) {
    <div class="flex flex-col gap-4">
      @if (!isFirst) {
        <h3 class="!m-0 !font-bold">Or, if</h3>
      }

      @for (condition of group.and; track condition.uid) {
        <pr-conditional-logic-item
          [condition]="condition"
          (conditionChange)="store.updateCondition(group, $event)"
        >
          <div>
            <button
              mat-icon-button
              *ngIf="store.canDeleteConditions$ | async"
              (click)="store.deleteCondition(group, condition)"
            >
              <mat-icon class="opacity-50">delete</mat-icon>
            </button>
          </div>
        </pr-conditional-logic-item>
      }

      <div fxLayout="row" fxLayoutGap="8px">
        <button mat-stroked-button (click)="store.addCondition(group)">
          <mat-icon class="opacity-50">add</mat-icon> AND
        </button>

        <button mat-stroked-button (click)="store.addGroup()" *ngIf="isLast">
          <mat-icon class="opacity-50">add</mat-icon> OR
        </button>
      </div>
    </div>
  }
</div>
