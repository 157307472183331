import {
  IntegrationStorage,
  IntegrationType,
} from '@principle-theorem/integrations';
import { isObject } from '@principle-theorem/shared';
import { has, isString } from 'lodash';

export interface IMedipassIntegrationData {
  apiKey: string;
}

export function isMedipassIntegrationData(
  data: unknown
): data is IMedipassIntegrationData {
  return isObject(data) && has(data, 'apiKey') && isString(data.apiKey);
}

export class MedipassIntegrationStorage extends IntegrationStorage<IMedipassIntegrationData> {
  constructor() {
    super(IntegrationType.Medipass);
  }
}
