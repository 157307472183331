import {
  InlineNodes,
  SNIPPET_TRIGGER,
  type IMenuButton,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';
import { createSnippet } from './snippet-keymap';

const data: IMenuButton = {
  buttonType: 'basic',
  buttonText: 'Snippet',
  tooltip: 'Snippet',
  shortcut: SNIPPET_TRIGGER,
  icon: 'post_add',
  command: (editor) => createSnippet(editor),
  isActiveFn: (editor) => editor.isActive(InlineNodes.SnippetAutocomplete),
};

export function createSnippetMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data,
  });
}

export function createSnippetBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'block',
    },
  });
}
