<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Integrations</h1>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Principle Bridge</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <pr-practice-bridge-settings [practice]="practice$ | async" />
    </mat-card-content>
  </mat-card>

  <ng-container *prIsRegion="[region.Australia]">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Tyro</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <pr-practice-tyro-settings [practice]="practice$ | async" />
      </mat-card-content>
    </mat-card>

    <ng-container *ptFeatureEnabled="'hicaps-connect'">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Hicaps</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <pr-practice-hicaps-connect-settings [practice]="practice$ | async" />
        </mat-card-content>
      </mat-card>
    </ng-container>
  </ng-container>

  <ng-container *prIsRegion="[region.NewZealand]">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Smartpay</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <pr-practice-smartpay-settings [practice]="practice$ | async" />
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
