<ng-template #loader>
  <div class="flex-1">
    <mat-progress-bar mode="indeterminate" />
  </div>
</ng-template>

<div
  *ngIf="note$ | async as note; else loader"
  class="flex h-full flex-col justify-between"
>
  <div>
    <div
      class="sync-notes layout-padding flex flex-col items-center justify-center gap-4"
      *ngIf="(isSynchronised$ | async) === false"
    >
      <mat-icon>error_outline</mat-icon>
      <strong>Note Out Of Sync</strong>
      <p>
        Note currently being edited in another tab. Please sync to view the most
        recent changes.
      </p>
      <div>
        <button mat-stroked-button (click)="updateNote$.next()">
          <mat-icon>sync</mat-icon>
          Sync Note
        </button>
      </div>
    </div>
    <div
      class="flex-1"
      [ngClass]="{
        disabled: (disabled$ | async) || (isSynchronised$ | async) === false
      }"
    >
      <pr-content-editor
        [disabled]="(disabled$ | async) || (isSynchronised$ | async) === false"
        [formControl]="noteControl"
        [extensions]="extensions"
      />
    </div>
  </div>

  <div
    class="toolbar-container flex items-center justify-between"
    [ngClass]="{ 'toolbar-dialog': dialogDisplay$ | ngrxPush }"
  >
    <div class="flex gap-2">
      <ng-container *ngIf="displayNoteHistory$ | ngrxPush">
        <ng-container *ngIf="allNotes$ | async as notes">
          <ng-container *ngIf="notes.length">
            <button
              mat-icon-button
              (click)="openNotesHistory()"
              matTooltip="View Clinical Note History"
            >
              <mat-icon>history</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </ng-container>
      <button
        *ngIf="canLockNote$ | ngrxPush"
        mat-icon-button
        (click)="toggleLock()"
        [matTooltip]="lockNoteTooltip$ | async"
      >
        <mat-icon>{{ note.immutable ? 'lock' : 'lock_open' }}</mat-icon>
      </button>
    </div>
    <pr-practitioner-selector
      *ngIf="displayStafferSelector$ | ngrxPush"
      label="Recording As"
      [staffer]="staffer$ | ngrxPush"
      (stafferSelected)="staffer$.next($event)"
    />
  </div>
</div>
