import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  inject,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AddChartable,
  AddTreatmentToFirestoreStepProvider,
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '@principle-theorem/ng-clinical-charting';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Appointment,
  ChartedTreatmentUpdater,
  ClinicalChart,
  FeeSchedule,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IAppointment,
  type IChartedRef,
  type IChartedTreatment,
  type IFeeSchedule,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentStep,
  type ITreatmentStepDisplay,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isPathChanged$,
  isSameRef,
  patchDoc,
  snapshot,
  updateDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-treatment-sidebar',
  templateUrl: './appointment-treatment-sidebar.component.html',
  styleUrls: ['./appointment-treatment-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentTreatmentSidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _global = inject(GlobalStoreService);
  trackByTreatment = TrackByFunctions.ref<IChartedTreatment>('config.ref');
  treatmentPlan$: ReplaySubject<WithRef<ITreatmentPlan>> = new ReplaySubject(1);
  treatmentPlanLink$: Observable<string[]>;
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  treatmentStep$: Observable<WithRef<ITreatmentStep> | undefined>;
  treatments$: Observable<IChartedTreatment[]>;
  showPractitionerWarning$: Observable<boolean>;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set treatmentPlan(treatmentPlan: WithRef<ITreatmentPlan>) {
    if (treatmentPlan) {
      this.treatmentPlan$.next(treatmentPlan);
    }
  }

  constructor(
    public elementRef: ElementRef,
    private _chart: ChartFacade,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.treatmentStep$ = combineLatest([
      this.appointment$.pipe(isPathChanged$('ref.id')),
      this.treatmentPlan$,
    ]).pipe(
      switchMap(([appointment, treatmentPlan]) =>
        TreatmentPlan.findStepByAppointment$(treatmentPlan, appointment.ref)
      )
    );

    this.treatments$ = this.treatmentStep$.pipe(
      map((treatmentStep) => (treatmentStep ? treatmentStep.treatments : []))
    );

    this.showPractitionerWarning$ = combineLatest([
      this.appointment$.pipe(isPathChanged$('ref.id')),
      this.treatmentPlan$,
    ]).pipe(
      map(
        ([appointment, treatmentPlan]) =>
          treatmentPlan.practitioner !== undefined &&
          treatmentPlan.practitioner.ref &&
          !isSameRef(treatmentPlan.practitioner, appointment.practitioner)
      )
    );

    this.appointment$
      .pipe(
        map((appointment) => appointment.practitioner),
        switchMap((practitioner) => this._global.getStaffer$(practitioner.ref)),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((practitioner) =>
        this._chart.setChartingAs(ChartId.InAppointment, practitioner)
      );

    this.appointment$
      .pipe(
        switchMap((appointment) =>
          ClinicalChart.getLatestChart$({
            ref: Appointment.patientRef(appointment),
          })
        ),
        map((chart) => chart ?? ClinicalChart.init()),
        takeUntil(this._onDestroy$)
      )
      .subscribe((chart) =>
        this._chart.loadChartSuccess(ChartId.InAppointment, chart)
      );

    this.treatmentPlanLink$ = combineLatest([
      this.appointment$.pipe(
        map((appointment) => Appointment.patientRef(appointment))
      ),
      this.treatmentPlan$,
    ]).pipe(
      map(([patientRef, plan]) => [
        'patients',
        patientRef.id,
        'treatment-plans',
        plan.ref.id,
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addTreatment(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<void> {
    const step = await snapshot(this.treatmentStep$.pipe(filterUndefined()));
    const treatmentCategories = await snapshot(
      this._global.treatmentCategories$
    );
    const addSurfaceProviders = [
      new AddTreatmentToFirestoreStepProvider(
        step,
        treatmentCategories,
        await this._getFeeSchedule()
      ),
    ];
    await new AddChartable(addSurfaceProviders).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(
        this._organisation.staffer$.pipe(filterUndefined())
      ),
    });
  }

  async updateTreatments(
    treatmentStep: WithRef<ITreatmentStep>,
    treatments: IChartedTreatment[]
  ): Promise<void> {
    await patchDoc(treatmentStep.ref, {
      treatments: await ChartedTreatmentUpdater.syncPricingRules(treatments),
    });
  }

  async deleteTreatment(
    treatmentStep: WithRef<ITreatmentStep>,
    treatment: IChartedTreatment
  ): Promise<void> {
    const categories = await snapshot(this._global.treatmentCategories$);
    const step = await TreatmentStep.updateDisplayPrimaryCategory(
      TreatmentStep.deleteTreatment(treatmentStep, treatment.uuid),
      categories
    );
    await patchDoc(treatmentStep.ref, { ...step });
    this._snackBar.open(`Treatment removed`);
  }

  async updateStepDisplay(display: ITreatmentStepDisplay): Promise<void> {
    const step = await snapshot(this.treatmentStep$);
    if (!step) {
      return;
    }
    await updateDoc(step.ref, { display });
  }

  async updateStepDisplayOverride(
    treatmentStep: ITreatmentStep,
    selectedOverride: WithRef<ITreatmentCategory>
  ): Promise<void> {
    const display = TreatmentStep.updateDisplayOverrideCategory(
      treatmentStep,
      selectedOverride.ref
    );

    if (!display) {
      return;
    }

    await this.updateStepDisplay(display);
  }

  isIncomplete(step: WithRef<ITreatmentStep>): boolean {
    return TreatmentStep.isIncomplete(step);
  }

  isComplete(step: WithRef<ITreatmentStep>): boolean {
    return TreatmentStep.isComplete(step);
  }

  async updateChartable(
    data: IEditChartableData,
    step: WithRef<ITreatmentStep>
  ): Promise<void> {
    const categories = await snapshot(this._global.treatmentCategories$);
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(data, [
      new AddTreatmentToFirestoreStepProvider(
        step,
        categories,
        await this._getFeeSchedule()
      ),
    ]);
  }

  private async _getFeeSchedule(): Promise<WithRef<IFeeSchedule>> {
    const patient = await snapshot(
      this.appointment$.pipe(
        switchMap((appointment) => Appointment.patient$(appointment))
      )
    );
    const practitioner = await snapshot(
      this.appointment$.pipe(map((appointment) => appointment.practitioner))
    );
    const feeSchedule = await FeeSchedule.getPreferredOrDefault(
      practitioner,
      patient?.preferredFeeSchedule
    );
    return feeSchedule;
  }
}
