import {
  ChartableSurface,
  CHARTABLE_SURFACES,
  IChartedItemConfiguration,
  IConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  GreyColourShading,
  IIdentifiable,
  WithRef,
} from '@principle-theorem/shared';
import { ChartedItemDisplay } from '../core/charted-item-display';
import { ConditionConfiguration } from './condition-configuration';

type ConditionConfigurationSeedData = AtLeast<
  IChartedItemConfiguration,
  'name' | 'display' | 'availableSurfaces'
>;

export const GENERIC_CONDITION_CONFIG_ID = 'generic';

export enum ExistingTreatmentConfigurationName {
  MetallicRestoration = 'Metallic Restoration',
  CompositeRestoration = 'Composite Restoration',
  AmalgamRestoration = 'Amalgam Restoration',
  Crown = 'Crown',
  Cerec = 'Cerec',
  Implant = 'Implant',
  RootCanal = 'Root Canal',
  CrownAndBridge = 'Crown And Bridge',
  Veneer = 'Veneer',
}

export enum ConditionConfigurationName {
  Other = 'Other',
  MobileTooth = 'Mobile Tooth',
  LichenPlanus = 'Lichen Planus',
  RootFracture = 'Root Fracture',
  CompositeFilling = 'Composite Filling',
  ImpactedTooth = 'Impacted Tooth',
  PeriodontalDiseaseLocalised = 'Periodontal Disease - Localised',
  CrownVeneeredPFM = 'Crown - Veneered / PFM',
  DeepBite = 'Deep Bite',
  Swelling = 'Swelling',
  Fluorosis = 'Fluorosis',
  RootCanalTreatment = 'Root Canal Treatment',
  PeriodontalDiseaseGeneralised = 'Periodontal Disease - Generalised',
  ImplantFixture = 'Implant - Fixture',
  GingivalRecession = 'Gingival Recession',
  SensitivityTouchTapping = 'Sensitivity - Touch / Tapping',
  ClassIIMalocclusion = 'Class II Malocclusion',
  DriftedRotatedTooth = 'Drifted/Rotated Tooth',
  SplintUsed = 'Splint Used',
  DrainingSinus = 'Draining Sinus',
  Veneer = 'Veneer',
  Crowding = 'Crowding',
  GeographicTongue = 'Geographic Tongue',
  Halitosis = 'Halitosis',
  Overhang = 'Overhang',
  SensitivityGeneral = 'Sensitivity - General',
  TorusTori = 'Torus / Tori',
  TenderToPercussion = 'Tender to Percussion',
  CrownMetallic = 'Crown - Metallic',
  Candidiasis = 'Candidiasis',
  GingivitisGeneralised = 'Gingivitis - Generalised',
  GingivitisLocalised = 'Gingivitis - Localised',
  EarlyDemineralisationLesion = 'Early Demineralisation Lesion',
  TMJDisorder = 'TMJ Disorder',
  Pericoronitis = 'Pericoronitis',
  LeakingRestoration = 'Leaking Restoration',
  AbscessPeriapicalLesion = 'Abscess / Periapical Lesion',
  UneruptedTooth = 'Unerupted Tooth',
  ImplantCrown = 'Implant Crown',
  AnteriorOpenBite = 'Anterior Open Bite',
  Crack = 'Crack',
  PoorOralHygiene = 'Poor Oral Hygiene',
  InlayOnlayCeramic = 'Inlay / Onlay - Ceramic',
  Abfraction = 'Abfraction',
  PulpCanalObliteration = 'Pulp Canal Obliteration',
  FixedOrthoRetainerBondedWire = 'Fixed Ortho Retainer - Bonded Wire',
  EnamelDefect = 'Enamel Defect',
  Erosion = 'Erosion',
  WatchReviewRequired = 'Watch - Review Required',
  Discolouration = 'Discolouration',
  LineaAlba = 'Linea Alba',
  ChippedBrokenFilling = 'Chipped/Broken Filling',
  PeriodontalAbscess = 'Periodontal Abscess',
  Cerec = 'Cerec',
  MolarBand = 'Molar Band',
  AbrasionToothbrush = 'Abrasion - Toothbrush',
  PeggedTooth = 'Pegged Tooth',
  PainOnBiting = 'Pain on Biting',
  DeepFissure = 'Deep Fissure',
  Staining = 'Staining',
  ObstructiveSleepApnoea = 'Obstructive Sleep Apnoea',
  Attrition = 'Attrition',
  LostFilling = 'Lost Filling',
  PartiallyEruptedTooth = 'Partially-erupted Tooth',
  RootResorption = 'Root Resorption',
  SensitivityCold = 'Sensitivity - Cold',
  RetainedRoot = 'Retained Root',
  ChippedBrokenTooth = 'Chipped/Broken Tooth',
  DentalAnxiety = 'Dental Anxiety',
  Bruxism = 'Bruxism',
  Caries = 'Caries',
  ClassIIIMalocclusion = 'Class III Malocclusion',
  Pulpitis = 'Pulpitis',
  PosteriorCrossBite = 'Posterior Cross Bite',
  InlayOnlayMetallic = 'Inlay / Onlay - Metallic',
  AmalgamFilling = 'Amalgam Filling',
  Spacing = 'Spacing',
  BridgeAbutment = 'Bridge Abutment',
  NecroticPulp = 'Necrotic Pulp',
  MissingTooth = 'Missing Tooth',
  BridgePontic = 'Bridge Pontic',
  OverEruptedTooth = 'Over-erupted Tooth',
  Wear = 'Wear',
  Post = 'Post',
  CrownCeramicNonMetallic = 'Crown - Ceramic / Non-metallic',
  SensitivityPostOperative = 'Sensitivity - Post-operative',
  InfectedTooth = 'Infected Tooth',
  SensitivitySweet = 'Sensitivity - Sweet',
  OpenContact = 'Open Contact',
  PeriImplantitis = 'Peri-implantitis',
  FissureSealant = 'Fissure Sealant',
  Ulcer = 'Ulcer',
  SensitivityHot = 'Sensitivity - Hot',
  Denture = 'Denture',

  // Old conditions
  // Abfraction = 'Abfraction',
  // Attrition = 'Attrition',
  // Bruxism = 'Bruxism',
  // Caries = 'Caries',
  // ChippedBrokenFilling = 'Chipped/Broken Filling',
  // ChippedBrokenTooth = 'Chipped/Broken Tooth',
  // Crack = 'Crack',
  // Crowding = 'Crowding',
  // DeepFissures = 'Deep Fissures',
  // Discolouration = 'Discolouration',
  // Erosion = 'Erosion',
  // GingivalRecession = 'Gingival Recession',
  // GingivitisGeneralised = 'Gingivitis - Generalised',
  // GingivitisLocalised = 'Gingivitis - Localised',
  // GingivitisChronic = 'Gingivitis - Chronic',
  // GingivitisAcute = 'Gingivitis - Acute',
  // GingivitisSlight = 'Gingivitis - Slight',
  // GingivitisModerate = 'Gingivitis - Moderate',
  // GingivitisAdvanced = 'Gingivitis - Advanced',
  // ImpactedWisdomTooth = 'Impacted Wisdom Tooth',
  // LostFilling = 'Lost Filling',
  // MobileGrade1 = 'Mobile - Grade 1',
  // MobileGrade2 = 'Mobile - Grade 2',
  // MobileGrade3 = 'Mobile - Grade 3',
  // MissingTooth = 'Missing Tooth',
  // PoorOralHygiene = 'Poor Oral Hygiene',
  // PartiallyEruptedTooth = 'Partially Erupted Tooth',
  // Pericoronitis = 'Pericoronitis',
  // PeriodontalDiseaseGeneralised = 'Periodontal Disease - Generalised',
  // PeriodontalDiseaseLocalised = 'Periodontal Disease - Localised',
  // PeriodontalDiseaseChronic = 'Periodontal Disease - Chronic',
  // PeriodontalDiseaseAcute = 'Periodontal Disease - Acute',
  // PeriodontalDiseaseSlight = 'Periodontal Disease - Slight',
  // PeriodontalDiseaseModerate = 'Periodontal Disease - Moderate',
  // PeriodontalDiseaseAdvanced = 'Periodontal Disease - Advanced',
  // Pulpitis = 'Pulpitis',
  // RetainedRoot = 'Retained Root',
  // RootResorption = 'Root Resorption',
  // SensitivityAllTemperature = 'Sensitivity - All Temperature',
  // SensitivityCold = 'Sensitivity - Cold',
  // SensitivityGeneral = 'Sensitivity - General',
  // SensitivityHot = 'Sensitivity - Hot',
  // SensitivitySweet = 'Sensitivity - Sweet',
  // SensitivityTouch = 'Sensitivity - Touch',
  // SoftTissueConditionCandidiasis = 'Soft Tissue Condition - Candidiasis',
  // SoftTissueConditionLichenPlanus = 'Soft Tissue Condition - Lichen Planus',
  // SoftTissueConditionLineaAlba = 'Soft Tissue Condition - Linea Alba',
  // ToothbrushAbrasion = 'Toothbrush Abrasion',
  // UneruptedTooth = 'Unerupted Tooth',
}

export function buildConditionConfigurationSeedData(): (IConditionConfiguration &
  Partial<IIdentifiable>)[] {
  return CONDITION_CONFIGURATIONS.map(ConditionConfiguration.init);
}

export function getConditionByName(
  name: ConditionConfigurationName,
  conditionConfigurations: WithRef<IConditionConfiguration>[]
): WithRef<IConditionConfiguration> {
  const conditionConfiguration = conditionConfigurations.find(
    (condition) => condition.name === String(name)
  );

  if (!conditionConfiguration) {
    throw new Error(`Can't find condition`);
  }
  return conditionConfiguration;
}

const CONDITION_CONFIGURATIONS: (ConditionConfigurationSeedData &
  Partial<IIdentifiable>)[] = [
  {
    uid: GENERIC_CONDITION_CONFIG_ID,
    name: ConditionConfigurationName.Other,
    display: ChartedItemDisplay.init({
      colour: {
        name: new GreyColourShading().label,
        value: new GreyColourShading().shades.a400,
      },
      icon: {
        slug: '',
        name: '',
      },
      hasFill: true,
    }),
    availableSurfaces: [ChartableSurface.Unscoped, ...CHARTABLE_SURFACES],
  },
  {
    name: ConditionConfigurationName.MobileTooth,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-mobile', name: 'Mobile' },
      colour: { value: '#b0bec5', name: 'Blue Grey a200' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.WholeMouth],
    name: ConditionConfigurationName.LichenPlanus,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'Lichen Planus', slug: 'pr-lichen-planus' },
      colour: { value: '#ff4081', name: 'Pink a200' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.RootFracture,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { slug: 'pr-root-resorption', name: 'Root Resorption' },
      colour: { name: 'Blue a200', value: '#448aff' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Yellow a700', value: '#ffd600' },
      hideWhenResolved: false,
      icon: { name: 'Restoration', slug: 'pr-restoration' },
    }),
    name: ConditionConfigurationName.CompositeFilling,
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      icon: { name: 'Impacted', slug: 'pr-impacted' },
      hideWhenResolved: false,
      colour: { value: '#00e5ff', name: 'Cyan a400' },
    }),
    name: ConditionConfigurationName.ImpactedTooth,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#1de9b6', name: 'Teal a400' },
      icon: { slug: 'pr-periodontal', name: 'Peridontal' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.PeriodontalDiseaseLocalised,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#78909c', name: 'Blue Grey a400' },
      icon: { name: 'Crown', slug: 'pr-crown-small' },
      hideWhenResolved: false,
    }),
    name: ConditionConfigurationName.CrownVeneeredPFM,
    availableSurfaces: [ChartableSurface.Crown],
  },
  {
    name: ConditionConfigurationName.DeepBite,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'Arch', slug: 'pr-arch' },
      colour: { name: 'Indigo a200', value: '#536dfe' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth, ChartableSurface.Quadrant],
    name: ConditionConfigurationName.Swelling,
    display: ChartedItemDisplay.init({
      icon: { name: 'X-rays', slug: 'pr-x-rays' },
      colour: { name: 'Lime a200', value: '#eeff41' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Whitening', slug: 'pr-whitening' },
      colour: { name: 'Cyan a100', value: '#84ffff' },
    }),
    name: ConditionConfigurationName.Fluorosis,
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
  },
  {
    name: ConditionConfigurationName.RootCanalTreatment,
    display: ChartedItemDisplay.init({
      icon: { name: 'Root Canal', slug: 'pr-root-canal' },
      colour: { value: '#00e676', name: 'Green a400' },
      hideWhenResolved: false,
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    availableSurfaces: [ChartableSurface.WholeMouth, ChartableSurface.Quadrant],
    display: ChartedItemDisplay.init({
      colour: { value: '#00bfa5', name: 'Teal a700' },
      hideWhenResolved: false,
      icon: { name: 'Peridontal', slug: 'pr-periodontal' },
    }),
    name: ConditionConfigurationName.PeriodontalDiseaseGeneralised,
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.ImplantFixture,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { name: 'Blue Grey a400', value: '#78909c' },
      icon: { slug: 'pr-implant', name: 'Implant' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Gingivitis', slug: 'pr-gingivitis' },
      colour: { value: '#c51162', name: 'Pink a700' },
      hideWhenResolved: false,
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.GingivalRecession,
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth, ChartableSurface.Quadrant],
    name: ConditionConfigurationName.SensitivityTouchTapping,
    display: ChartedItemDisplay.init({
      colour: { value: '#b388ff', name: 'Deep Purple a100' },
      icon: { name: 'Sensitivity - Touch', slug: 'pr-sensitivity-touch' },
      hideWhenResolved: false,
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Indigo a100', value: '#8c9eff' },
      hideWhenResolved: false,
      icon: { slug: 'pr-crowding', name: 'Crowding' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
    name: ConditionConfigurationName.ClassIIMalocclusion,
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Impacted', slug: 'pr-impacted' },
      colour: { value: '#536dfe', name: 'Indigo a200' },
    }),
    name: ConditionConfigurationName.DriftedRotatedTooth,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { value: '#00e5ff', name: 'Cyan a400' },
      icon: { slug: 'pr-arch-guard', name: 'Arch - Guard' },
    }),
    name: ConditionConfigurationName.SplintUsed,
    availableSurfaces: [ChartableSurface.Arch],
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Whitening', slug: 'pr-whitening' },
      colour: { name: 'Yellow a700', value: '#ffd600' },
    }),
    name: ConditionConfigurationName.DrainingSinus,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Veneer', slug: 'pr-veneer' },
      hideWhenResolved: false,
      colour: { value: '#80d8ff', name: 'Light Blue a100' },
    }),
    name: ConditionConfigurationName.Veneer,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    name: ConditionConfigurationName.Crowding,
    availableSurfaces: [ChartableSurface.WholeMouth, ChartableSurface.Arch],
    display: ChartedItemDisplay.init({
      colour: { name: 'Indigo a200', value: '#536dfe' },
      icon: { slug: 'pr-crowding', name: 'Crowding' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#ff8a80', name: 'Red a100' },
      hideWhenResolved: false,
      icon: { name: 'Lichen Planus', slug: 'pr-lichen-planus' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
    name: ConditionConfigurationName.GeographicTongue,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Deep Purple a100', value: '#b388ff' },
      icon: { slug: 'pr-x-rays', name: 'X-rays' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
    name: ConditionConfigurationName.Halitosis,
  },
  {
    name: ConditionConfigurationName.Overhang,
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    display: ChartedItemDisplay.init({
      colour: { name: 'Deep Purple a200', value: '#7c4dff' },
      icon: { slug: 'pr-pericoronitis', name: 'Pericoronitis' },
    }),
  },
  {
    availableSurfaces: [
      ChartableSurface.WholeTooth,
      ChartableSurface.WholeMouth,
      ChartableSurface.Quadrant,
    ],
    name: ConditionConfigurationName.SensitivityGeneral,
    display: ChartedItemDisplay.init({
      icon: { name: 'Sensitivity - General', slug: 'pr-sensitivity-general' },
      hideWhenResolved: false,
      colour: { value: '#1de9b6', name: 'Teal a400' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'X-rays', slug: 'pr-x-rays' },
      colour: { value: '#ff1744', name: 'Red a400' },
    }),
    name: ConditionConfigurationName.TorusTori,
    availableSurfaces: [ChartableSurface.Arch],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.TenderToPercussion,
    display: ChartedItemDisplay.init({
      icon: {
        name: 'Sensitivity - Touch',
        slug: 'pr-sensitivity-touch',
      },
      colour: { name: 'Deep Orange a200', value: '#ff6e40' },
    }),
  },
  {
    name: ConditionConfigurationName.CrownMetallic,
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-crown-small', name: 'Crown' },
      colour: { value: '#616161', name: 'Grey a700' },
      hideWhenResolved: false,
    }),
    availableSurfaces: [ChartableSurface.Crown],
  },
  {
    name: ConditionConfigurationName.Candidiasis,
    availableSurfaces: [ChartableSurface.WholeMouth],
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-candidiasis', name: 'Candidiasis' },
      colour: { value: '#ff80ab', name: 'Pink a100' },
    }),
  },
  {
    name: ConditionConfigurationName.GingivitisGeneralised,
    display: ChartedItemDisplay.init({
      colour: { value: '#ff4081', name: 'Pink a200' },
      icon: { name: 'Gingivitis', slug: 'pr-gingivitis' },
    }),
    availableSurfaces: [
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
      ChartableSurface.Quadrant,
    ],
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { value: '#f50057', name: 'Pink a400' },
      icon: { slug: 'pr-gingivitis', name: 'Gingivitis' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.GingivitisLocalised,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#8d6e63', name: 'Brown a400' },
      hideWhenResolved: false,
      icon: { slug: 'pr-candidiasis', name: 'Candidiasis' },
    }),
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.EarlyDemineralisationLesion,
  },
  {
    name: ConditionConfigurationName.TMJDisorder,
    display: ChartedItemDisplay.init({
      colour: { value: '#64ffda', name: 'Teal a200' },
      icon: { slug: 'pr-arch-periodic', name: 'Arch - Periodic' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.Pericoronitis,
    display: ChartedItemDisplay.init({
      icon: { name: 'Pericoronitis', slug: 'pr-pericoronitis' },
      colour: { value: '#ff5252', name: 'Red a200' },
    }),
  },
  {
    name: ConditionConfigurationName.LeakingRestoration,
    display: ChartedItemDisplay.init({
      colour: { name: 'Brown a400', value: '#8d6e63' },
      icon: {
        name: 'Restoration Missing',
        slug: 'pr-restoration-missing',
      },
    }),
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
  },
  {
    name: ConditionConfigurationName.AbscessPeriapicalLesion,
    display: ChartedItemDisplay.init({
      colour: { value: '#aeea00', name: 'Lime a700' },
      icon: { slug: 'pr-root-resorption', name: 'Root Resorption' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Cyan a100', value: '#84ffff' },
      icon: { name: 'Partially Erupted', slug: 'pr-partially-erupted' },
      disablesSurface: true,
      hideWhenResolved: false,
    }),
    name: ConditionConfigurationName.UneruptedTooth,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    name: ConditionConfigurationName.ImplantCrown,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      colour: { name: 'Purple a400', value: '#d500f9' },
      hideWhenResolved: false,
      icon: {
        name: 'Core, Post & Crown',
        slug: 'pr-core-post-and-crown',
      },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#3d5afe', name: 'Indigo a400' },
      icon: { name: 'Arch', slug: 'pr-arch' },
    }),
    name: ConditionConfigurationName.AnteriorOpenBite,
    availableSurfaces: [],
  },
  {
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-crack', name: 'Crack' },
      colour: { value: '#00c853', name: 'Green a700' },
    }),
    name: ConditionConfigurationName.Crack,
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
  },
  {
    name: ConditionConfigurationName.PoorOralHygiene,
    availableSurfaces: [ChartableSurface.WholeMouth],
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { name: 'Purple a700', value: '#aa00ff' },
      icon: { name: 'Poor Hygiene', slug: 'pr-poor-hygiene' },
    }),
  },
  {
    name: ConditionConfigurationName.InlayOnlayCeramic,
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    display: ChartedItemDisplay.init({
      icon: { name: 'Cerec', slug: 'pr-cerec' },
      hideWhenResolved: false,
      colour: { value: '#ffab40', name: 'Orange a200' },
    }),
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
      ChartableSurface.WholeTooth,
    ],
    name: ConditionConfigurationName.Abfraction,
    display: ChartedItemDisplay.init({
      colour: { value: '#1de9b6', name: 'Teal a400' },
      icon: { slug: 'pr-root-resorption', name: 'Root Resorption' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Brown a400', value: '#8d6e63' },
      hideWhenResolved: false,
      icon: { slug: 'pr-retained-root', name: 'Retained Root' },
    }),
    name: ConditionConfigurationName.PulpCanalObliteration,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#0091ea', name: 'Light Blue a700' },
      icon: { slug: 'pr-bridge', name: 'Bridge' },
      hideWhenResolved: false,
    }),
    availableSurfaces: [ChartableSurface.Arch],
    name: ConditionConfigurationName.FixedOrthoRetainerBondedWire,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#e040fb', name: 'Purple a200' },
      icon: { slug: 'pr-abrasion', name: 'Abrasion' },
    }),
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.EnamelDefect,
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
      ChartableSurface.WholeTooth,
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
    ],
    display: ChartedItemDisplay.init({
      colour: { name: 'Deep Orange a100', value: '#ff9e80' },
      hideWhenResolved: false,
      icon: { name: 'Abrasion', slug: 'pr-abrasion' },
    }),
    name: ConditionConfigurationName.Erosion,
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
      ChartableSurface.WholeTooth,
    ],
    display: ChartedItemDisplay.init({
      icon: {
        name: 'Sensitivity - General',
        slug: 'pr-sensitivity-general',
      },
      hideWhenResolved: false,
      colour: { name: 'Deep Purple a700', value: '#6200ea' },
    }),
    name: ConditionConfigurationName.WatchReviewRequired,
  },
  {
    availableSurfaces: [
      ChartableSurface.WholeTooth,
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
      ChartableSurface.Quadrant,
      ChartableSurface.MultipleTeeth,
    ],
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-discolouration', name: 'Discolouration' },
      colour: { value: '#bdbdbd', name: 'Grey a400' },
    }),
    name: ConditionConfigurationName.Discolouration,
  },
  {
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-linea-alba', name: 'Linea Alba' },
      hideWhenResolved: false,
      colour: { value: '#aa00ff', name: 'Purple a700' },
    }),
    availableSurfaces: [ChartableSurface.WholeMouth],
    name: ConditionConfigurationName.LineaAlba,
  },
  {
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-chipped-broken', name: 'Chipped Broken' },
      colour: { value: '#3d5afe', name: 'Indigo a400' },
    }),
    name: ConditionConfigurationName.ChippedBrokenFilling,
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
  },
  {
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-pericoronitis', name: 'Pericoronitis' },
      colour: { value: '#00c853', name: 'Green a700' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.PeriodontalAbscess,
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.Cerec,
    display: ChartedItemDisplay.init({
      icon: { name: 'Cerec', slug: 'pr-cerec' },
      hideWhenResolved: false,
      colour: { value: '#ffd180', name: 'Orange a100' },
    }),
  },
  {
    name: ConditionConfigurationName.MolarBand,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-tooth', name: 'Tooth' },
      hideWhenResolved: false,
      colour: { value: '#78909c', name: 'Blue Grey a400' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Abrasion', slug: 'pr-abrasion' },
      colour: { value: '#ffab40', name: 'Orange a200' },
    }),
    name: ConditionConfigurationName.AbrasionToothbrush,
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Retained Root', slug: 'pr-retained-root' },
      colour: { value: '#ea80fc', name: 'Purple a100' },
    }),
    name: ConditionConfigurationName.PeggedTooth,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.PainOnBiting,
    display: ChartedItemDisplay.init({
      colour: { value: '#651fff', name: 'Deep Purple a400' },
      icon: {
        slug: 'pr-sensitivity-general',
        name: 'Sensitivity - General',
      },
    }),
  },
  {
    name: ConditionConfigurationName.DeepFissure,
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    display: ChartedItemDisplay.init({
      icon: { name: 'Deep Fissures', slug: 'pr-deep-fissures' },
      colour: { value: '#ff4081', name: 'Pink a200' },
      hideWhenResolved: false,
    }),
  },
  {
    display: ChartedItemDisplay.init({
      icon: { name: 'Candidiasis', slug: 'pr-candidiasis' },
      colour: { value: '#8d6e63', name: 'Brown a400' },
    }),
    name: ConditionConfigurationName.Staining,
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
      ChartableSurface.WholeTooth,
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
    ],
  },
  {
    display: ChartedItemDisplay.init({
      icon: {
        slug: 'pr-arch-consultation',
        name: 'Arch - Consultation',
      },
      colour: { name: 'Teal a400', value: '#1de9b6' },
    }),
    availableSurfaces: [],
    name: ConditionConfigurationName.ObstructiveSleepApnoea,
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { name: 'Brown a200', value: '#bcaaa4' },
      icon: { slug: 'pr-abrasion', name: 'Abrasion' },
    }),
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    name: ConditionConfigurationName.Attrition,
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'Restoration Missing', slug: 'pr-restoration-missing' },
      colour: { name: 'Indigo a700', value: '#304ffe' },
    }),
    name: ConditionConfigurationName.LostFilling,
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.PartiallyEruptedTooth,
    display: ChartedItemDisplay.init({
      colour: { name: 'Cyan a400', value: '#00e5ff' },
      icon: { slug: 'pr-partially-erupted', name: 'Partially Erupted' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      colour: { name: 'Pink a400', value: '#f50057' },
      icon: { slug: 'pr-root-resorption', name: 'Root Resorption' },
      hideWhenResolved: false,
    }),
    name: ConditionConfigurationName.RootResorption,
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { slug: 'pr-sensitivity-cold', name: 'Sensitivity - Cold' },
      colour: { name: 'Cyan a200', value: '#18ffff' },
    }),
    name: ConditionConfigurationName.SensitivityCold,
    availableSurfaces: [ChartableSurface.WholeTooth, ChartableSurface.Quadrant],
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#616161', name: 'Grey a700' },
      hideWhenResolved: false,
      icon: { slug: 'pr-retained-root', name: 'Retained Root' },
    }),
    name: ConditionConfigurationName.RetainedRoot,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    name: ConditionConfigurationName.ChippedBrokenTooth,
    display: ChartedItemDisplay.init({
      colour: { name: 'Indigo a200', value: '#536dfe' },
      icon: { slug: 'pr-chipped-broken', name: 'Chipped Broken' },
    }),
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
  },
  {
    name: ConditionConfigurationName.DentalAnxiety,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { name: 'Teal a400', value: '#1de9b6' },
      icon: { slug: 'pr-gingivitis', name: 'Gingivitis' },
    }),
    availableSurfaces: [],
  },
  {
    availableSurfaces: [ChartableSurface.WholeMouth],
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { value: '#ff80ab', name: 'Pink a100' },
      icon: { slug: 'pr-abrasion', name: 'Abrasion' },
    }),
    name: ConditionConfigurationName.Bruxism,
  },
  {
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-caries', name: 'Caries' },
      colour: { value: '#8d6e63', name: 'Brown a400' },
    }),
    availableSurfaces: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    name: ConditionConfigurationName.Caries,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Indigo a100', value: '#8c9eff' },
      icon: { name: 'Crowding', slug: 'pr-crowding' },
    }),
    name: ConditionConfigurationName.ClassIIIMalocclusion,
    availableSurfaces: [ChartableSurface.WholeMouth],
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Red a700', value: '#d50000' },
      icon: { slug: 'pr-pulpitis', name: 'Pulpitus' },
      hideWhenResolved: false,
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.Pulpitis,
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#3d5afe', name: 'Indigo a400' },
      icon: { name: 'Impacted', slug: 'pr-impacted' },
    }),
    name: ConditionConfigurationName.PosteriorCrossBite,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Blue Grey a400', value: '#78909c' },
      icon: { slug: 'pr-abrasion', name: 'Abrasion' },
    }),
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.InlayOnlayMetallic,
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.AmalgamFilling,
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-restoration', name: 'Restoration' },
      colour: { value: '#616161', name: 'Grey a700' },
      hideWhenResolved: false,
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { name: 'Indigo a100', value: '#8c9eff' },
      icon: { name: 'Bridge', slug: 'pr-bridge' },
    }),
    name: ConditionConfigurationName.Spacing,
    availableSurfaces: [
      ChartableSurface.WholeTooth,
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
    ],
  },
  {
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'Bridge', slug: 'pr-bridge' },
      colour: { value: '#aa00ff', name: 'Purple a700' },
    }),
    name: ConditionConfigurationName.BridgeAbutment,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    name: ConditionConfigurationName.NecroticPulp,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      colour: { value: '#b0bec5', name: 'Blue Grey a200' },
      icon: { slug: 'pr-pulpitis', name: 'Pulpitus' },
    }),
  },
  {
    display: ChartedItemDisplay.init({
      colour: { value: '#80d8ff', name: 'Light Blue a100' },
      icon: { name: 'Extraction', slug: 'pr-extraction' },
      disablesSurface: true,
      hideWhenResolved: false,
    }),
    name: ConditionConfigurationName.MissingTooth,
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    name: ConditionConfigurationName.BridgePontic,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { slug: 'pr-bridge', name: 'Bridge' },
      colour: { name: 'Purple a400', value: '#d500f9' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.OverEruptedTooth,
    display: ChartedItemDisplay.init({
      colour: { value: '#3d5afe', name: 'Indigo a400' },
      icon: { name: 'Tooth', slug: 'pr-tooth' },
    }),
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
      ChartableSurface.WholeTooth,
    ],
    display: ChartedItemDisplay.init({
      colour: { name: 'Brown a200', value: '#bcaaa4' },
      icon: { name: 'Abrasion', slug: 'pr-abrasion' },
    }),
    name: ConditionConfigurationName.Wear,
  },
  {
    name: ConditionConfigurationName.Post,
    availableSurfaces: [ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      colour: { value: '#455a64', name: 'Blue Grey a700' },
      hideWhenResolved: false,
      icon: {
        slug: 'pr-core-post-and-crown',
        name: 'Core, Post & Crown',
      },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.Crown],
    display: ChartedItemDisplay.init({
      icon: { name: 'Crown', slug: 'pr-crown-small' },
      hideWhenResolved: false,
      colour: { name: 'Light Blue a100', value: '#80d8ff' },
    }),
    name: ConditionConfigurationName.CrownCeramicNonMetallic,
  },
  {
    name: ConditionConfigurationName.SensitivityPostOperative,
    display: ChartedItemDisplay.init({
      colour: { name: 'Red a200', value: '#ff5252' },
      icon: {
        slug: 'pr-sensitivity-general',
        name: 'Sensitivity - General',
      },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    availableSurfaces: [ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.InfectedTooth,
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-extirpation', name: 'Extirpation' },
      colour: { value: '#76ff03', name: 'Light Green a400' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.Quadrant, ChartableSurface.WholeTooth],
    display: ChartedItemDisplay.init({
      icon: { name: 'Sensitivity - Sweet', slug: 'pr-sensitivity-sweet' },
      colour: { value: '#ff80ab', name: 'Pink a100' },
      hideWhenResolved: false,
    }),
    name: ConditionConfigurationName.SensitivitySweet,
  },
  {
    availableSurfaces: [ChartableSurface.Mesial, ChartableSurface.Distal],
    display: ChartedItemDisplay.init({
      icon: { name: 'Linea Alba', slug: 'pr-linea-alba' },
      colour: { value: '#00bfa5', name: 'Teal a700' },
    }),
    name: ConditionConfigurationName.OpenContact,
  },
  {
    name: ConditionConfigurationName.PeriImplantitis,
    display: ChartedItemDisplay.init({
      icon: { name: 'Implant', slug: 'pr-implant' },
      colour: { name: 'Red a700', value: '#d50000' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth],
  },
  {
    availableSurfaces: [
      ChartableSurface.Facial,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
      ChartableSurface.Distal,
      ChartableSurface.Lingual,
    ],
    name: ConditionConfigurationName.FissureSealant,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      colour: { name: 'Pink a100', value: '#ff80ab' },
      icon: { name: 'Deep Fissures', slug: 'pr-deep-fissures' },
    }),
  },
  {
    availableSurfaces: [ChartableSurface.WholeMouth],
    display: ChartedItemDisplay.init({
      icon: { slug: 'pr-whitening', name: 'Whitening' },
      colour: { name: 'Red a100', value: '#ff8a80' },
    }),
    name: ConditionConfigurationName.Ulcer,
  },
  {
    availableSurfaces: [ChartableSurface.Quadrant, ChartableSurface.WholeTooth],
    name: ConditionConfigurationName.SensitivityHot,
    display: ChartedItemDisplay.init({
      hideWhenResolved: false,
      icon: { name: 'Sensitivity - Hot', slug: 'pr-sensitivity-hot' },
      colour: { name: 'Blue Grey a400', value: '#78909c' },
    }),
  },
  {
    name: ConditionConfigurationName.Denture,
    display: ChartedItemDisplay.init({
      icon: { name: 'Arch', slug: 'pr-arch' },
      hideWhenResolved: false,
      colour: { value: '#40c4ff', name: 'Light Blue a200' },
    }),
    availableSurfaces: [ChartableSurface.WholeTooth, ChartableSurface.Arch],
  },
];
