import { type ITask } from '@principle-theorem/principle-core/interfaces';
import { Task } from '@principle-theorem/principle-core';
import {
  snapshot,
  toMoment,
  to24hrTime,
  type WithRef,
} from '@principle-theorem/shared';
import { type IRecurringTaskFormData } from './components/recurring-task-configuration-form/recurring-task-configuration-form';
import { type ITaskFormData } from './components/task-form/task-form.formgroup';

export function isTaskFormData(
  task: ITaskFormData | IRecurringTaskFormData
): task is ITaskFormData {
  return 'status' in task;
}

export async function taskToFormData(
  task: WithRef<ITask>
): Promise<Partial<ITaskFormData>> {
  const data: Omit<ITaskFormData, 'description'> = {
    title: task.title,
    type: task.type,
    priority: task.priority,
    status: task.status,
    assignee: await Task.getAssignee(task),
  };

  if (task.dueDate) {
    data.dueDate = toMoment(task.dueDate);
    if (!Task.dueEndOfDay(task)) {
      data.dueTime = task.dueDate ? to24hrTime(task.dueDate) : undefined;
    }
  }

  const recurrenceConfiguration = await snapshot(
    Task.recurrenceConfiguration$(task)
  );
  if (recurrenceConfiguration) {
    data.recurrencePattern = recurrenceConfiguration.recurrencePattern;
    data.recurringFrequency =
      recurrenceConfiguration.recurrencePattern.frequencyType;
  }
  return data;
}
