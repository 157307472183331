import {
  addDoc,
  deleteDoc,
  firstResult,
  isObject,
  isTimestamp,
  toTimestamp,
  uid,
  where,
  type CollectionReference,
  type Query,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isString } from 'lodash';
import { type ITemporaryOrgToken } from './temporary-org-token';
import { type ITokenStorage } from './temporary-tokens';

export interface ITemporaryOrgDataToken<T extends object = object>
  extends ITemporaryOrgToken {
  data: T;
}

export function isTemporaryOrgDataToken<T extends object>(
  data: unknown
): data is ITemporaryOrgDataToken<T> {
  return (
    isObject(data) &&
    isString(data.tokenUid) &&
    isTimestamp(data.expiresAt) &&
    isString(data.orgUid) &&
    'data' in data
  );
}

export class TemporaryOrgDataTokenStorage<T extends object>
  implements ITokenStorage<ITemporaryOrgDataToken<T>>
{
  async generate(
    data: Omit<ITemporaryOrgDataToken<T>, 'tokenUid'>,
    tokensCollection: CollectionReference<ITemporaryOrgDataToken<T>>,
    short = false
  ): Promise<string> {
    const tokenUid = uid(!short);
    await addDoc(
      tokensCollection,
      {
        ...data,
        tokenUid,
      },
      tokenUid
    );
    return tokenUid;
  }

  async get(
    tokenUid: string,
    tokensCollection: Query<ITemporaryOrgDataToken<T>>
  ): Promise<ITemporaryOrgDataToken<T> | undefined> {
    const data = await this._findSnapshot(tokensCollection, tokenUid);
    if (!data || !isTemporaryOrgDataToken<T>(data)) {
      return;
    }
    return data;
  }

  async has(
    tokenUid: string,
    tokensCollection: CollectionReference<ITemporaryOrgDataToken<T>>
  ): Promise<boolean> {
    const snapshot = await this._findSnapshot(tokensCollection, tokenUid);
    return snapshot ? true : false;
  }

  async remove(
    tokenUid: string,
    tokensCollection: CollectionReference<ITemporaryOrgDataToken<T>>
  ): Promise<void> {
    const snapshot = await this._findSnapshot(tokensCollection, tokenUid);
    if (!snapshot) {
      return;
    }
    await deleteDoc(snapshot.ref);
  }

  private async _findSnapshot(
    tokensCollection: Query<ITemporaryOrgDataToken<T>>,
    tokenUid: string,
    includeExpired: boolean = true
  ): Promise<WithRef<ITemporaryOrgDataToken<T>> | undefined> {
    return firstResult(
      tokensCollection,
      ...compact([
        where('tokenUid', '==', tokenUid),
        !includeExpired ? where('expiresAt', '>=', toTimestamp()) : undefined,
      ])
    );
  }
}
