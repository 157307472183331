import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  IInteractionV2,
  type IAppointment,
  InteractionType,
  IPatient,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  sortTimestamp,
  type WithRef,
  Timestamp,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { first } from 'lodash';

export interface IPendingFollowUp {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
  lastContactedAt?: Timestamp;
  treatmentCategory?: ITreatmentCategory;
}

export function appointmentToPendingFollowUp$(
  appointment: WithRef<IAppointment>
): Observable<IPendingFollowUp> {
  return combineLatest([
    Appointment.patient$(appointment),
    Appointment.interactions$(appointment).pipe(map(getLastContactedAt)),
    from(
      TreatmentStep.resolveTreatmentCategory(
        appointment.treatmentPlan.treatmentStep.display
      )
    ),
  ]).pipe(
    map(([patient, lastContactedAt, treatmentCategory]) => ({
      patient,
      appointment,
      lastContactedAt,
      treatmentCategory,
    }))
  );
}

function getLastContactedAt(
  interactions: WithRef<IInteractionV2>[]
): Timestamp | undefined {
  const sortedInteractions = interactions
    .filter((interaction) =>
      [
        InteractionType.Call,
        InteractionType.Sms,
        InteractionType.Email,
      ].includes(interaction.type)
    )
    .sort((interactionA, interactionB) =>
      sortTimestamp(interactionA.createdAt, interactionB.createdAt)
    );

  return first(sortedInteractions)?.createdAt;
}
