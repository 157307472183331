import { Directive, HostBinding, Input } from '@angular/core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';
import { DISABLED_TOOTH_OPACITY } from '../models/charted-item';

@Directive({
    selector: '[prChartSurfaceStyleFill]',
    standalone: false
})
export class ChartSurfaceStyleFillDirective {
  @HostBinding('style.fill') fill?: string;
  @HostBinding('style.opacity') opacity = 1;

  @Input()
  set view(view: IDentalChartViewSurface) {
    if (
      !view ||
      !view.texture ||
      !view.texture.colour ||
      !view.texture.colour.value
    ) {
      this.fill = undefined;
      this.opacity = 1;
      return;
    }
    this.fill = view.texture.colour.value;
    this.opacity = view.texture.disablesSurface ? DISABLED_TOOTH_OPACITY : 1;
  }
}
