import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import { type IChartConfig } from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import * as dc from 'dc';
import { first, get } from 'lodash';
import { type Subject } from 'rxjs';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { DataPointValueFormatter } from '../../data-point-value-formatter';
import {
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  applyBaseConfigToChart,
  ChartBuildHelpers,
  type IDCChartBuilder,
  type IDCChartEvent,
} from './dc-chart-builder';

export class DcSelectMenuBuilder implements IDCChartBuilder<dc.SelectMenu> {
  constructor(
    private _config: IBaseChartBuilderConfig = {
      ...DEFAULT_CHART_BUILDER_CONFIG,
      width: DEFAULT_CHART_BUILDER_CONFIG.width + 100,
    }
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<dc.SelectMenu> | undefined {
    const result = first(results);
    const measure = first(config.builderData.measures);
    if (!result || !measure) {
      return;
    }

    const chart = this.build(D3_CHART_ELEM_SELECTOR, result, config);

    applyBaseConfigToChart(this._config, chart);

    chart.order((dataA: unknown, dataB: unknown) => {
      const valueA = isObject(dataA)
        ? String(get(dataA, 'key.label', get(dataA, 'key', '')))
        : String(dataA);
      const valueB = isObject(dataB)
        ? String(get(dataB, 'key.label', get(dataB, 'key', '')))
        : String(dataB);

      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

    chart.keyAccessor((data: unknown) =>
      isObject(data)
        ? String(get(data, 'key.label', get(data, 'key', '')))
        : String(data)
    );

    chart.title((data: unknown) => {
      const format = DataPointValueFormatter.getFormatter(
        measure?.measure.metadata.formatter,
        measure?.measure.metadata.formatterValue
      );

      const key = String(get(data, 'key.label', get(data, 'key', '')));
      const value = this._getValue(data);
      return isObject(data) ? `${key} (${format(value)})` : format(value);
    });

    if (eventListener$) {
      chart.on('filtered', (eventChart) =>
        ChartBuildHelpers.onFilteredEvent(eventChart, config, eventListener$)
      );
    }

    return chart;
  }

  build(
    parent: string,
    result: IDCTransformResult,
    config: IChartConfig
  ): dc.SelectMenu {
    if (config.builderData.measures[0].filters?.length) {
      const { dimension, group } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        config.builderData.measures[0]
      );

      return dc
        .selectMenu(parent)
        .multiple(true)
        .numberVisible(15)
        .dimension(dimension)
        .group(group);
    }

    return dc
      .selectMenu(parent)
      .multiple(true)
      .numberVisible(15)
      .dimension(result.dimension)
      .group(result.group);
  }

  private _getValue(data: unknown): string {
    const value = get(data, 'value', '') as unknown;
    if (isObject(value)) {
      return String(value.valueOf());
    }
    return String(value);
  }
}
