<mat-toolbar color="accent">Move Chart to Section</mat-toolbar>

<mat-dialog-content fxLayout="column">
  <form [formGroup]="form" fxLayout="column" (ngSubmit)="submit()">
    <div class="flex flex-col gap-4">
      <mat-form-field class="flex-1">
        <mat-label> How do you want the data to be added together? </mat-label>
        <mat-select formControlName="sectionName">
          @for (sectionName of data.sectionNames; track sectionName) {
            <mat-option [value]="sectionName">
              {{ sectionName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="submit()">
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
