<div
  class="flex flex-col gap-2"
  *ngIf="treatmentStep$ | async as treatmentStep"
>
  <div class="flex items-center gap-4">
    <h2 class="!mb-0">Treatment</h2>
    <a [prBrandRouterLink]="treatmentPlanLink$ | async" class="action-link">
      View Plan
    </a>
    <span class="flex-1"></span>
    <ng-container *ngIf="treatmentStep | map: isIncomplete">
      <pr-add-charted-treatment-button
        [connectedTo]="elementRef"
        (addChartable)="addTreatment($event.chartable, $event.selectedSurfaces)"
        [inlcudeMultiTreatments]="false"
      />
    </ng-container>
  </div>
  <div class="step-container">
    <div class="flex justify-between gap-4">
      <p class="step-name">{{ treatmentStep.name }}</p>
      <pr-treatment-step-category-indicator
        [treatmentStepDisplay]="treatmentStep.display"
        (stepDisplayChange)="updateStepDisplay($event)"
      />
    </div>
    <pr-treatment-scope-list
      [disabled]="treatmentStep | map: isComplete"
      [treatments]="treatmentStep.treatments"
      [compact]="true"
      [step]="treatmentStep"
      [plan]="treatmentPlan$ | async"
      (treatmentsChange)="updateTreatments(treatmentStep, $event)"
      (treatmentDeleted)="deleteTreatment(treatmentStep, $event)"
      (categoryChanged)="updateStepDisplayOverride(treatmentStep, $event)"
      (updateChartable)="updateChartable($event, treatmentStep)"
    />
  </div>
</div>
