import { type IProfileLoader } from './profile-loader';
import { type IContact } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { LoadContactActionService } from '@principle-theorem/ng-interactions';

export class SubContactLoader implements IProfileLoader {
  showInitials = false;
  name: string;
  details: string;
  phone: string;

  constructor(
    public contact: IContact,
    public subcontact: WithRef<IContact>,
    public loader: LoadContactActionService
  ) {
    this.details = contact.name;
    this.name = subcontact.name;
    this.phone = subcontact.phone ?? '';
  }

  async do(): Promise<void> {
    await this.loader.do(this.subcontact);
  }
}
