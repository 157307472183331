import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  EventType,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IAssociatedTreatment,
  type IDestinationEntity,
  type IDestinationEntityJobRunOptions,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
  type ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  IReffable,
  ITimePeriod,
  asyncForAll,
  getError,
  initFirestoreModel,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type ISODateType,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientAppointmentDestinationEntity,
  IPatientAppointmentBuildData,
  PATIENT_APPOINTMENT_RESOURCE_TYPE,
  type IPatientAppointmentJobData,
  type IPatientAppointmentMigrationData,
} from '../../../destination/entities/patient-appointments';
import { findTreatmentStepForAppointment } from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentSourceEntity,
  type IOasisPatientAppointment,
  type IOasisPatientAppointmentTranslations,
} from '../../source/entities/patient-appointments';
import { PatientTreatmentsSourceEntity } from '../../source/entities/patient-treatments';
import {
  IOasisPatient,
  IOasisPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { OasisAppointmentBookToPractitionerMappingHandler } from '../mappings/appointment-book-to-practitioner';
import { OasisPracticeMappingHandler } from '../mappings/practices';
import { OasisStafferMappingHandler } from '../mappings/staff';
import {
  OASIS_PLAN_NAME,
  OasisTreatmentPlanDataBuilder,
  PatientTreatmentPlanDestinationEntity,
} from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IJobData
  extends IPatientAppointmentJobData<IOasisPatient, IOasisPatientTranslations> {
  practices: WithRef<ITranslationMap<IPractice>>[];
  appointmentBookToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientAppointmentDestinationEntity extends BasePatientAppointmentDestinationEntity<
  IOasisPatient,
  IOasisPatientTranslations,
  IJobData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();
  treatmentPlanName = OASIS_PLAN_NAME;

  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentsSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
  };

  customMappings = {
    staff: new OasisStafferMappingHandler(),
    practices: new OasisPracticeMappingHandler(),
    appointmentBookToPractitioner:
      new OasisAppointmentBookToPractitionerMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = Firestore.getDoc(migration.configuration.brand.ref);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practitioners$ = from(brand$).pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );

    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      withLatestFrom(
        staff$,
        practitioners$,
        brand$,
        from(this.customMappings.practices.getRecords(translationMap)),
        from(
          this.customMappings.appointmentBookToPractitioner.getRecords(
            translationMap
          )
        )
      ),
      map(
        ([
          sourcePatients,
          staff,
          practitioners,
          brand,
          practices,
          appointmentBookToPractitioner,
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            brand,
            practices,
            appointmentBookToPractitioner,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient with id ${sourcePatientId}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.id
      );

    try {
      const appointments: (IPatientAppointmentBuildData | undefined)[] =
        await asyncForAll(sourceAppointments, async (sourceAppointment) => {
          const appointmentUid = this.sourceEntities.appointments
            .getSourceRecordId(sourceAppointment.data.data)
            .toString();
          if (!sourceAppointment.data.data.appointmentPractitionerId) {
            throw new Error(
              `No practitioner found for appointment ${appointmentUid}`
            );
          }

          const appointmentBookId =
            sourceAppointment.data.data.appointmentPractitionerId;

          const practitionerMap = this.resolvePractitioner(
            data,
            appointmentBookId
          );

          if (!practitionerMap) {
            throw new Error(
              `No practitioner found for appointment book ${appointmentBookId}`
            );
          }

          const practiceMap = first(data.practices);

          if (!practiceMap?.destinationIdentifier) {
            throw new Error('Missing practice');
          }

          const practice = await Firestore.getDoc(
            practiceMap.destinationIdentifier
          );

          const createdAt = sourceAppointment.data.translations.from;

          const [appointment, interactions] = await this._buildAppointmentData(
            migration,
            translationMap,
            sourcePatientId,
            patient,
            practitionerMap,
            sourceAppointment,
            sourceAppointments,
            practice,
            migration.configuration.backupDate
          );

          const planUid =
            OasisTreatmentPlanDataBuilder.getPlanIdentifier(sourcePatientId);
          const stepUid = await OasisTreatmentPlanDataBuilder.getStepIdentifier(
            migration,
            this.sourceEntities.appointments,
            sourcePatientId,
            migration.configuration.timezone,
            undefined,
            sourceAppointment,
            sourceAppointments
          );

          const plan = TreatmentPlan.init({
            name: OASIS_PLAN_NAME,
            status: TreatmentPlanStatus.InProgress,
          });

          const appointmentDate = toMomentTz(
            sourceAppointment.data.translations.from,
            migration.configuration.timezone
          ).format(HISTORY_DATE_FORMAT);
          const summary = sourceAppointment.data.data.treatmentSummary;

          const name = summary
            ? `${sourceAppointment.data.data.treatmentSummary} - ${appointmentDate}`
            : appointmentDate;

          const step = TreatmentStep.init({
            name,
            status:
              appointment.status === AppointmentStatus.Complete
                ? TreatmentStepStatus.Complete
                : TreatmentStepStatus.Incomplete,
            schedulingRules: {
              duration: appointment.event
                ? Event.duration(appointment.event)
                : 0,
            },
          });

          return {
            sourcePatientId: sourcePatientId.toString(),
            patientRef,
            appointmentUid,
            createdAt,
            appointment,
            planUid,
            plan,
            stepUid,
            step,
            interactions,
          };
        });

      return {
        patientRef,
        sourcePatientId: sourcePatientId.toString(),
        appointments: compact(appointments),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  resolvePractitioner(
    data: IJobData,
    appointmentBookId: number
  ): DocumentReference<IStaffer> | undefined {
    const appointmentBookPractitionerMap =
      data.appointmentBookToPractitioner.find(
        (practitionerMap) =>
          practitionerMap.sourceIdentifier === appointmentBookId.toString()
      );

    if (!appointmentBookPractitionerMap?.sourceLink?.sourceIdentifier) {
      return;
    }

    return getPractitionerOrDefault(
      appointmentBookPractitionerMap.sourceLink.sourceIdentifier,
      data.staff,
      data.practitioners
    )?.ref;
  }

  private async _buildAppointmentData(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    sourcePatientId: number,
    patient: WithRef<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    sourceAppointment: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations
    >,
    patientAppointments: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations
    >[],
    practice: WithRef<IPractice>,
    backupDate: ISODateType
  ): Promise<[IPatientAppointmentBuildData['appointment'], IInteractionV2[]]> {
    const practitioner = await Firestore.getDoc(practitionerRef);

    const interactions = compact([sourceAppointment.data.data.notes]).map(
      (note) => {
        const createdAt = toMoment(
          sourceAppointment.data.translations.createdAt
        ).isAfter(moment())
          ? toTimestamp()
          : sourceAppointment.data.translations.createdAt;

        return Interaction.init({
          uid: sourceAppointment.record.uid,
          title: [toTextContent(`Added a note`)],
          content: initVersionedSchema(note),
          createdAt,
          pinned: true,
        });
      }
    );

    const appointmentStartTime = toMomentTz(
      sourceAppointment.data.translations.from,
      practice.settings.timezone
    );
    const appointmentEndTime = toMomentTz(
      sourceAppointment.data.translations.to,
      practice.settings.timezone
    );

    const associatedPlan = await resolveAssociatedPlan(
      migration,
      this.sourceEntities.appointments,
      sourcePatientId,
      practice,
      translationMap,
      sourceAppointment,
      patientAppointments
    );

    const appointment: IPatientAppointmentBuildData['appointment'] = {
      event: getAppointmentEvent(
        {
          from: appointmentStartTime,
          to: appointmentEndTime,
        },
        patient,
        practice,
        practitioner
      ),
      eventHistory: [],
      status: this.determineStatus(
        sourceAppointment,
        appointmentStartTime,
        backupDate
      ),
      statusHistory: [],
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      cancellationHistory: [],
      dependencies: [],
      tags: [],
      ...initFirestoreModel(),
      treatmentPlan: associatedPlan,
    };

    return [appointment, interactions];
  }

  private determineStatus(
    appointment: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations
    >,
    appointmentStartTime: Moment,
    backupDate: string
  ): AppointmentStatus {
    if (!appointment.data.data.categoryId) {
      return AppointmentStatus.Cancelled;
    }

    if (appointment.data.data.performedAt) {
      return AppointmentStatus.Complete;
    }

    if (appointmentStartTime.isBefore(toMoment(backupDate))) {
      return AppointmentStatus.Complete;
    }

    if (appointment.data.data.isConfirmed) {
      return AppointmentStatus.Confirmed;
    }

    return AppointmentStatus.Scheduled;
  }
}

export function getAppointmentEvent(
  period: ITimePeriod,
  patient?: WithRef<IPatient>,
  practice?: WithRef<IPractice>,
  practitioner?: WithRef<IStaffer>
): IEvent | undefined {
  if (!practice || !patient || !practitioner) {
    return;
  }
  return Event.init({
    from: toTimestamp(period.from),
    to: toTimestamp(period.to),
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}

async function resolveAssociatedPlan(
  migration: IReffable<IPracticeMigration>,
  appointmentResolver: PatientAppointmentSourceEntity,
  sourcePatientId: number,
  practice: WithRef<IPractice>,
  translationMap: TranslationMapHandler,
  appointment: IGetRecordResponse<
    IOasisPatientAppointment,
    IOasisPatientAppointmentTranslations
  >,
  patientAppointments: IGetRecordResponse<
    IOasisPatientAppointment,
    IOasisPatientAppointmentTranslations
  >[]
): Promise<IAssociatedTreatment | undefined> {
  const stepUid = await OasisTreatmentPlanDataBuilder.getStepIdentifier(
    migration,
    appointmentResolver,
    sourcePatientId,
    practice.settings.timezone,
    undefined,
    appointment,
    patientAppointments
  );

  const stepRef = await findTreatmentStepForAppointment(
    translationMap,
    stepUid
  );

  if (!stepRef) {
    return;
  }

  const step = await Firestore.getDoc(stepRef);
  const plan = await TreatmentStep.treatmentPlan(step);

  return TreatmentPlan.treatmentStepToAssociatedTreatment(plan, step);
}
