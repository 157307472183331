import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  HealthcareClaimStatus,
  IClaimableItem,
  ICustomLineItem,
  IHealthcareClaim,
  IHealthcareClaimGroup,
  IHealthcareClaimItem,
  IHealthcareClaimProviderGroup,
  IInvoice,
  IPractice,
  isCustomLineItem,
  IServiceCodeLineItem,
  IStaffer,
  isTreatmentLineItem,
  ITransaction,
  MAX_CLAIMABLE_ITEMS,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  duplicate,
  Firestore,
  getDoc,
  INamedDocument,
  isSameRef,
  reduceToSingleArray,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import {
  chunk,
  compact,
  fromPairs,
  groupBy,
  mapValues,
  uniqBy,
  values,
} from 'lodash';
import { v4 as uuid } from 'uuid';
import { ServiceProviderHandler } from '../clinical-charting/service-codes/service-provider';
import { stafferToNamedDoc } from '../common';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Staffer } from '../staffer/staffer';
import { isServiceCodeLineItem } from './custom-line-items';
import { Invoice } from './invoice';
import { LineItemActions } from './line-item-actions';

export function duplicateForQuantity(
  item: IServiceCodeLineItem
): IServiceCodeLineItem[] {
  return duplicate(item, item.quantity).map((single) => ({
    ...single,
    amount: roundTo2Decimals(single.amount),
    quantity: 1,
  }));
}

function applyClaimLimit<T extends IHealthcareClaimGroup>(group: T): T[] {
  const duplicated = HealthcareClaimGenerator.toSingleQuantities(group.items);
  const chunked = chunk(duplicated, MAX_CLAIMABLE_ITEMS);
  return chunked.map((singleQuantities) => ({
    ...group,
    items: HealthcareClaimGenerator.joinSingleQuantities(singleQuantities),
  }));
}

function getUniqueItemKey(item: IHealthcareClaimItem): string {
  return `${item.treatmentUid}:${item.serviceCodeUid}`;
}

export class HealthcareClaim {
  static init(
    data: AtLeast<IHealthcareClaim, 'practitioner'>
  ): IHealthcareClaim {
    return {
      uid: uuid(),
      status: HealthcareClaimStatus.Unclaimed,
      transactions: [],
      items: [],
      ...data,
    };
  }

  static shouldPersistClaim(claim: IHealthcareClaim): boolean {
    return (
      claim.status !== HealthcareClaimStatus.Unclaimed ||
      claim.transactions.length > 0
    );
  }

  static toOutdatedClaim<T extends IHealthcareClaim>(claim: T): T {
    return {
      ...claim,
      outdatedAt: toTimestamp(),
    };
  }

  static isClaimable(claim: IHealthcareClaim): boolean {
    return (
      claim.status !== HealthcareClaimStatus.Claimed &&
      claim.outdatedAt === undefined
    );
  }

  static hasTransaction<T>(
    claim: IHealthcareClaim,
    transactionRef: DocumentReference<ITransaction<T>>
  ): boolean {
    return claim.transactions.some((ref) => isSameRef(ref, transactionRef));
  }
}

export class HealthcareClaimGenerator {
  static async createClaims(
    lineItems: ICustomLineItem[],
    defaultPractitioner: WithRef<IStaffer>,
    practiceRef: DocumentReference<IPractice>
  ): Promise<IHealthcareClaim[]> {
    const grouped = await this.groupByProvider(
      this.getAllClaimableItems(lineItems, defaultPractitioner),
      practiceRef
    );
    const limited = grouped.reduce(
      (all: IHealthcareClaimProviderGroup[], group) => [
        ...all,
        ...applyClaimLimit(group),
      ],
      []
    );
    return limited.map((group) => ({
      ...group,
      uid: uuid(),
      status: HealthcareClaimStatus.Unclaimed,
      transactions: [],
      deleted: false,
    }));
  }

  static async groupByProvider(
    items: IHealthcareClaimItem[],
    practiceRef: DocumentReference<IPractice>
  ): Promise<IHealthcareClaimProviderGroup[]> {
    const providerMap = await HealthcareClaimGenerator.getProviderDataMap(
      items.map((item) => item.provider)
    );
    const groups = groupBy(items, (item) => item.provider.ref.path);
    const converted = mapValues(groups, (value, key) =>
      toHealthcareClaimProviderGroup(providerMap[key], value, practiceRef)
    );
    return values(converted);
  }

  static async getProviderDataMap(
    namedDocs: INamedDocument<IStaffer>[]
  ): Promise<Record<string, WithRef<IStaffer>>> {
    const staffPromises = uniqBy(namedDocs, 'ref.path').map((namedDoc) =>
      getDoc(namedDoc.ref)
    );
    const staff = await Promise.all(staffPromises);
    const pairs = staff.map((staffer) => [staffer.ref.path, staffer]);
    return fromPairs(pairs);
  }

  static getAllClaimableItems(
    lineItems: ICustomLineItem[],
    defaultPractitioner: WithRef<IStaffer>
  ): IHealthcareClaimItem[] {
    return this.getClaimableItems(lineItems).map((item) => {
      const provider =
        item.treatment.treatmentRef.attributedTo ??
        stafferToNamedDoc(defaultPractitioner);
      return {
        label: `${item.treatment.description}: ${item.serviceCode.description}`,
        treatmentUid: item.treatment.uid,
        serviceCodeUid: item.serviceCode.uid,
        quantity: item.quantity,
        provider,
      };
    });
  }

  static getClaimableItems(items: ICustomLineItem[]): IClaimableItem[] {
    const serviceCodes = items.filter(isTreatmentLineItem).map((treatment) =>
      treatment.items
        .filter((lineItem): lineItem is IServiceCodeLineItem =>
          isServiceCodeLineItem(lineItem)
        )
        .map((serviceCode) => {
          const overrideCode = ServiceProviderHandler.findServiceCode(
            serviceCode.code
          )?.claimCode;
          return {
            treatment,
            serviceCode: overrideCode
              ? { ...serviceCode, code: overrideCode.toString() }
              : serviceCode,
            quantity: serviceCode.quantity,
          };
        })
    );
    return reduceToSingleArray(serviceCodes).filter(
      (item) => item.serviceCode.amount > 0
    );
  }

  static calculateUnclaimed(
    claimable: IHealthcareClaimItem[],
    claimed: IHealthcareClaimItem[]
  ): IHealthcareClaimItem[] {
    return claimable
      .map((item) => {
        const exitingClaim = claimed.find((claim) =>
          this.isSameClaimItem(item, claim)
        );
        if (!exitingClaim) {
          return item;
        }
        const remaining = item.quantity - exitingClaim.quantity;
        return {
          ...item,
          quantity: remaining > 0 ? remaining : 0,
        };
      })
      .filter((item) => item.quantity > 0);
  }

  static isSameClaimItem(
    a: IHealthcareClaimItem,
    b: IHealthcareClaimItem
  ): boolean {
    return (
      getUniqueItemKey(a) === getUniqueItemKey(b) &&
      isSameRef(a.provider.ref, b.provider.ref)
    );
  }

  static toSingleQuantities(
    items: IHealthcareClaimItem[]
  ): IHealthcareClaimItem[] {
    return splitByQuantity(
      items,
      (item) => item.quantity,
      (item, _quantity) => ({ ...item, quantity: 1 })
    );
  }

  static joinSingleQuantities(
    items: IHealthcareClaimItem[]
  ): IHealthcareClaimItem[] {
    return items.reduce(
      (acc: IHealthcareClaimItem[], item: IHealthcareClaimItem) => {
        const existing = acc.find(
          (value) => getUniqueItemKey(item) === getUniqueItemKey(value)
        );
        if (existing) {
          existing.quantity += item.quantity;
          return acc;
        }
        return [...acc, item];
      },
      []
    );
  }
}

export interface IResolvedClaimItem {
  claimItem: IHealthcareClaimItem;
  resolved?: IClaimableItem;
}

export function resolveLineItem(
  invoice: IInvoice,
  claimItem: IHealthcareClaimItem
): IResolvedClaimItem {
  const treatment = LineItemActions.recursiveFindByUuid(
    invoice.items,
    claimItem.treatmentUid
  );
  if (
    !treatment ||
    !isCustomLineItem(treatment) ||
    !isTreatmentLineItem(treatment)
  ) {
    return { claimItem };
  }
  const serviceCode = LineItemActions.recursiveFindByUuid(
    treatment.items,
    claimItem.serviceCodeUid
  );
  if (!serviceCode || !isServiceCodeLineItem(serviceCode)) {
    return { claimItem };
  }
  return {
    claimItem,
    resolved: { treatment, serviceCode, quantity: claimItem.quantity },
  };
}

export function resolveClaimItems(
  invoice: IInvoice,
  claim: IHealthcareClaim
): IResolvedClaimItem[] {
  return claim.items.map((item) => resolveLineItem(invoice, item));
}

export function toClaimableItems(
  items: IResolvedClaimItem[]
): IClaimableItem[] {
  return compact(items.map((item) => item.resolved));
}

export function toSingleClaimItems(items: IClaimableItem[]): IClaimableItem[] {
  return reduceToSingleArray(
    items.map((item) => duplicate(item, item.quantity))
  ).map((item) => ({ ...item, quantity: 1 }));
}

function toHealthcareClaimProviderGroup(
  staffer: WithRef<IStaffer>,
  items: IHealthcareClaimItem[],
  practiceRef: DocumentReference<IPractice>
): IHealthcareClaimProviderGroup {
  return {
    practitioner: stafferToNamedDoc(staffer),
    providerData: Staffer.getProviderData(staffer, practiceRef),
    items,
  };
}

export function isReadyToBeClaimed(claim: IHealthcareClaim): boolean {
  return claim.providerData !== undefined;
}

export async function reloadProviderData(
  invoice: WithRef<IInvoice>,
  claim: IHealthcareClaim
): Promise<void> {
  const staffer = await OrganisationCache.staff.get.getDoc(
    claim.practitioner.ref
  );
  claim.providerData = Staffer.getProviderData(staffer, invoice.practice.ref);
  Invoice.upsertHealthcareClaim(invoice, claim);
  await Firestore.saveDoc(invoice);
}

export function splitByQuantity<T>(
  items: T[],
  getQuantity: (item: T) => number,
  getSingleItem: (item: T, quantity: number) => T
): T[] {
  const duplicated = items.map((item) => {
    const quantity = getQuantity(item);
    return duplicate(item, quantity).map((single) =>
      getSingleItem(single, quantity)
    );
  });
  return reduceToSingleArray(duplicated);
}
