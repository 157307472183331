import { type IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodeToNoteMappingHandler } from '../../../mappings/item-code-to-notes';
import { type IExactADAItem } from '../../source/entities/ada-item';
import { NoteItemCodeTreatmentSourceEntity } from '../../source/entities/note-item-code-treatments';
import { UsedItemCodeConditionSourceEntity } from '../../source/entities/used-item-code-conditions';

export class ExactItemCodeToNoteMappingHandler extends ItemCodeToNoteMappingHandler<
  IExactADAItem,
  UsedItemCodeConditionSourceEntity
> {
  override sourceEntity = new NoteItemCodeTreatmentSourceEntity();
  override translateFn = (record: IExactADAItem): IBaseMigrationItemCode => ({
    ...record,
  });
}
