import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ComponentsModule } from './components/components.module';
import { CreateLabActionService } from './create-lab-action.service';
import { CreateLabJobActionService } from './create-lab-job-action.service';
import { LabJobsEffects } from './store/lab-jobs.effects';
import { LabJobsFacade } from './store/lab-jobs.facade';
import { labJobsReducer, LAB_JOBS_FEATURE_KEY } from './store/lab-jobs.reducer';
import { LabJobsRouterEffects } from './store/labs-jobs.router.effects';

@NgModule({
  imports: [
    ComponentsModule,
    StoreModule.forFeature(LAB_JOBS_FEATURE_KEY, labJobsReducer),
    EffectsModule.forFeature([LabJobsEffects, LabJobsRouterEffects]),
  ],
  exports: [ComponentsModule],
  providers: [LabJobsFacade, CreateLabActionService, CreateLabJobActionService],
})
export class NgLabsModule {}
