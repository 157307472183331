<mat-form-field floatLabel="always" class="flex grow">
  <mat-label>Allocate To</mat-label>
  <mat-select
    [formControl]="formCtrl"
    [placeholder]="autoAllocated"
    [compareWith]="isSameRef"
  >
    <mat-option [value]="undefined">{{ autoAllocated }}</mat-option>
    @for (staffer of staff; track staffer.ref.path) {
      <mat-option [value]="staffer.ref">
        {{ staffer.name }}
      </mat-option>
    }
  </mat-select>
  <mat-hint>{{ autoAllocateTooltip }}</mat-hint>
</mat-form-field>
