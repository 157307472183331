import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  all$,
  filterUndefined,
  findProp,
  multiSortBy$,
  nameSorter,
  snapshot,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BrandPracticeCollectionResolverService } from '../../brand-practice-collection-resolver.service';
import {
  type IPracticeData,
  UpsertPracticeDialogComponent,
} from '../upsert-practice-dialog/upsert-practice-dialog.component';

@Component({
  selector: 'pr-practice-list',
  templateUrl: './practice-list.component.html',
  styleUrls: ['./practice-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeListComponent {
  practiceScope$: ReplaySubject<WithRef<IBrand> | WithRef<IPractice>> =
    new ReplaySubject(1);
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  practiceCol$: Observable<CollectionReference<IPractice>>;
  practices$: Observable<WithRef<IPractice>[]>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope,
    private _route: ActivatedRoute
  ) {
    this.practiceCol$ = this._route.data.pipe(
      findProp<CollectionReference<IPractice>>(
        BrandPracticeCollectionResolverService.resolverKey
      ),
      filterUndefined()
    );

    this.practices$ = this.practiceCol$.pipe(
      switchMap((practiceCol) => all$(undeletedQuery(practiceCol))),
      multiSortBy$(nameSorter())
    );

    this.breadcrumbs$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Practices' },
      ])
    );
  }

  async add(): Promise<void> {
    const practice = await this._dialog
      .open<UpsertPracticeDialogComponent, IPracticeData, IPractice>(
        UpsertPracticeDialogComponent,
        DialogPresets.medium({})
      )
      .afterClosed()
      .toPromise();

    if (!practice) {
      return;
    }
    const practiceCol = await snapshot(this.practiceCol$);
    await addDoc(practiceCol, practice);
    this._snackBar.open('Practice Added');
  }
}
