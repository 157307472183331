<div (click)="$event.stopPropagation()">
  <ng-container [ngSwitch]="type">
    <button
      *ngSwitchCase="'flat'"
      mat-flat-button
      matTooltip="Change Status"
      [color]="color"
      class="primary-button"
      [matMenuTriggerFor]="menu"
      [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    >
      {{ currentState }}
    </button>
    <button
      *ngSwitchCase="'raised'"
      mat-raised-button
      matTooltip="Change Status"
      [color]="color"
      class="primary-button"
      [matMenuTriggerFor]="menu"
      [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    >
      {{ currentState }}
    </button>
    <button
      *ngSwitchDefault
      mat-stroked-button
      matTooltip="Change Status"
      [color]="color"
      class="primary-button"
      [matMenuTriggerFor]="menu"
      [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    >
      {{ currentState }}
    </button>
  </ng-container>

  <ng-container [ngSwitch]="type">
    <button
      *ngSwitchCase="'flat'"
      mat-flat-button
      class="menu-button with-divider"
      [matTooltip]="nextState | map : buildTooltip$ : this | async"
      [disabled]="!nextState"
      [color]="color"
      (click)="buttonClick.emit()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
    <button
      *ngSwitchCase="'raised'"
      mat-raised-button
      class="menu-button with-divider"
      [matTooltip]="nextState | map : buildTooltip$ : this | async"
      [disabled]="!nextState"
      [color]="color"
      (click)="buttonClick.emit()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
    <button
      *ngSwitchDefault
      mat-stroked-button
      class="menu-button"
      [matTooltip]="nextState | map : buildTooltip$ : this | async"
      [disabled]="!nextState"
      [color]="color"
      (click)="buttonClick.emit()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </ng-container>

  <mat-menu #menu="matMenu" class="side-button-menu">
    <ng-content></ng-content>
  </mat-menu>
</div>
