import {
  IPracticeMigration,
  ISourceLinkOption,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable } from '@principle-theorem/shared';
import { IBaseMigrationAppointmentBook } from '../../../interfaces';
import { AppointmentBookToPractitionerMappingHandler } from '../../../mappings/appointment-book-to-practitioner';
import {
  ID4WAppointmentBook,
  PatientAppointmentBookSourceEntity,
} from '../../source/entities/appointment-book';
import { D4WStafferMappingHandler } from './staff';

export class D4WAppointmentBookToPractitionerMappingHandler extends AppointmentBookToPractitionerMappingHandler<
  ID4WAppointmentBook,
  PatientAppointmentBookSourceEntity
> {
  sourceEntity = new PatientAppointmentBookSourceEntity();
  translateFn = (
    record: ID4WAppointmentBook
  ): IBaseMigrationAppointmentBook => ({
    id: record.id.toString(),
    description: record.description,
  });

  override async getSourceLinkOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ISourceLinkOption[]> {
    const handler = new D4WStafferMappingHandler();
    const options = await handler.getSourceOptions(migration);
    const omitOption = {
      value: '0',
      label: 'Omit',
    };
    return [omitOption, ...options].map((option) => ({
      sourceIdentifier: option.value,
      sourceLabel: option.label,
      resourceType: handler.sourceEntity.sourceEntity.metadata.idPrefix,
    }));
  }
}
