<ng-container *ngIf="automation$ | async as automation">
  <button mat-icon-button [matMenuTriggerFor]="automationMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #automationMenu="matMenu">
    <ng-container *ngIf="canModify$ | async">
      <button mat-menu-item (click)="editAutomation.emit(automation)">
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
      <button mat-menu-item (click)="cancelAutomation.emit(automation)">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
    </ng-container>
    <ng-container *ngIf="canResend$ | async">
      <button mat-menu-item (click)="resendAutomation.emit(automation)">
        <mat-icon>send</mat-icon>
        <span>Resend</span>
      </button>
    </ng-container>
    <ng-container *ngIf="isCancelled$ | async">
      <button mat-menu-item (click)="restoreAutomation.emit(automation)">
        <mat-icon>restore</mat-icon>
        <span>Restore</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
