import {
  initVersionedSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import { Interaction, toMention } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  type IHasSourceIdentifier,
  type IInteractionV2,
  type ITranslationMap,
  InteractionType,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  type IDestinationEntityJobRunOptions,
  type IGetRecordResponse,
  MentionResourceType,
  CommunicationDirection,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, type WithRef } from '@principle-theorem/shared';
import { compact, upperFirst } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientInteractionDestinationEntity,
  type IBasePatientInteractionJobData,
  type IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientNoteSourceEntity } from '../../source/entities/patient-notes';
import {
  type IOasisPatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { OasisPatientNoteCategoryToNoteMappingHandler } from '../mappings/patient-note-categories-to-notes';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import {
  type IOasisPatientCommuncationTranslations,
  type IOasisPatientCommunication,
  type IOasisPatientCommunicationFilters,
  PatientCommunicationSourceEntity,
} from '../../source/entities/patient-communication';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<IOasisPatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
  noteCategories: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class PatientInteractionDestinationEntity extends BasePatientInteractionDestinationEntity<
  IOasisPatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();
  override batchLimit = 1000;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientNote: new PatientNoteSourceEntity(),
    patientCommunication: new PatientCommunicationSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    patientNoteCategories: new OasisPatientNoteCategoryToNoteMappingHandler(),
    staff: new OasisStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInteractionJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const noteCategories$ =
      this.customMappings.patientNoteCategories.getRecords$(translationMap);

    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      withLatestFrom(staff$, noteCategories$),
      map(([sourcePatients, staff, noteCategories]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          staff,
          noteCategories,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const sourcePatientNotes =
      await this.sourceEntities.patientNote.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const sourcePatientCommunications =
      await this.sourceEntities.patientCommunication.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const communicationInteractions = this._getPatientCommunicationInteractions(
      sourcePatientCommunications,
      await Firestore.getDoc(patientRef)
    );

    const interactions: (IInteractionV2 & IHasSourceIdentifier)[] = compact(
      sourcePatientNotes.map((note) => {
        const isClinicalNoteType = note.data.data.noteType === 0;
        if (isClinicalNoteType) {
          return;
        }

        const categoryId = note.data.data.categoryId;
        if (!categoryId) {
          return;
        }

        const noteCategoryMapping = data.noteCategories.find(
          (category) => category.sourceIdentifier === categoryId.toString()
        );

        if (!noteCategoryMapping?.destinationValue) {
          throw new Error(
            `Note category mapping not found for ${note.data.data.categoryId}`
          );
        }

        const isPatientNote = [
          ItemCodeNoteType.Omit,
          ItemCodeNoteType.ClinicalNote,
          ItemCodeNoteType.SterilisationRecord,
        ].includes(noteCategoryMapping.destinationValue);

        if (!isPatientNote) {
          return;
        }

        return {
          sourceIdentifier: note.record.uid,
          ...Interaction.init({
            type: InteractionType.Note,
            title: [
              toTextContent(
                note.data.data.initials
                  ? `${note.data.data.initials} added a note`
                  : 'Added a note'
              ),
            ],
            createdAt: note.data.translations.createdAt,
            content: initVersionedSchema(note.data.data.note || undefined),
          }),
        };
      })
    );

    if (data.sourcePatient.data.data.healthWarnings) {
      interactions.push({
        sourceIdentifier: `${data.sourcePatient.record.uid}-healthWarning`,
        ...Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Health warning')],
          content: initVersionedSchema(
            data.sourcePatient.data.data.healthWarnings
          ),
          pinned: true,
        }),
      });
    }

    if (data.sourcePatient.data.data.treatmentReminderNotes) {
      interactions.push({
        sourceIdentifier: `${data.sourcePatient.record.uid}-treatmentReminderNotes`,
        ...Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Treatment Reminder Notes')],
          content: initVersionedSchema(
            data.sourcePatient.data.data.treatmentReminderNotes
          ),
          pinned: true,
        }),
      });
    }

    return {
      patientRef,
      interactions: [...interactions, ...communicationInteractions],
    };
  }

  private _getPatientCommunicationInteractions(
    sourcePatientCommunications: IGetRecordResponse<
      IOasisPatientCommunication,
      IOasisPatientCommuncationTranslations,
      IOasisPatientCommunicationFilters
    >[],
    patient: WithRef<IPatient>
  ): (IInteractionV2 & IHasSourceIdentifier)[] {
    return sourcePatientCommunications.map((communication) => {
      const interactionType = communication.data.data.type;
      const isResponse =
        communication.data.data.direction === CommunicationDirection.Inbound;
      const type = isResponse ? InteractionType.SmsReceived : interactionType;
      const title = isResponse
        ? [
            toMentionContent(toMention(patient, MentionResourceType.Patient)),
            toTextContent(` replied via ${upperFirst(interactionType)}`),
          ]
        : [
            toTextContent(`${upperFirst(interactionType)} sent to `),
            toMentionContent(toMention(patient, MentionResourceType.Patient)),
          ];
      return {
        sourceIdentifier: communication.record.uid,
        ...Interaction.init({
          type,
          title,
          createdAt: communication.data.translations.createdAt,
          content: initVersionedSchema(
            compact([
              communication.data.data.messageSubject
                ? toParagraphContent(communication.data.data.messageSubject)
                : undefined,
              communication.data.data.messageBody
                ? toParagraphContent(communication.data.data.messageBody)
                : undefined,
            ])
          ),
        }),
      };
    });
  }
}
