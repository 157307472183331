import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconChipComponent } from '@principle-theorem/ng-principle-shared';
import { TreatmentStatus } from '../tooth-history.store';
import { MatTooltipModule } from '@angular/material/tooltip';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'pr-tooth-history-status-chip',
  standalone: true,
  imports: [CommonModule, IconChipComponent, MatTooltipModule],
  templateUrl: './tooth-history-status-chip.component.html',
  styleUrls: ['./tooth-history-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToothHistoryStatusChipComponent {
  readonly treatmentStatus = TreatmentStatus;

  @Input({ required: true }) status: TreatmentStatus;
  @Input({ transform: coerceBooleanProperty }) displayTooltip = true;
}
