import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { LabListComponent } from './lab-list/lab-list.component';
import { LabResolverService } from './lab-resolver.service';
import { LabNameResolverService } from './lab-name-resolver.service';
import { LabTabsComponent } from './lab-tabs/lab-tabs.component';
import { LabProfileComponent } from './lab-profile/lab-profile.component';
import { LabJobTypesListComponent } from './lab-job-types/lab-job-types-list/lab-job-types-list.component';
import { LabContactsComponent } from './lab-contacts/lab-contacts.component';
import { LabTimelineComponent } from './lab-timeline/lab-timeline.component';
import { LabPagesModule } from './lab-pages.module';
import { LabJobListComponent } from '../../components/lab-job-list/lab-job-list.component';

const routes: Routes = [
  {
    path: '',
    component: LabListComponent,
    data: {
      title: 'Labs',
    },
  },
  {
    path: ':uid',
    resolve: {
      lab: LabResolverService,
      title: LabNameResolverService,
    },
    children: [
      {
        path: '',
        component: LabTabsComponent,
        children: [
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full',
          },
          {
            path: 'profile',
            component: LabProfileComponent,
          },
          {
            path: 'lab-jobs',
            component: LabJobListComponent,
            data: { titleSuffix: 'Jobs' },
          },
          {
            path: 'lab-job-types',
            component: LabJobTypesListComponent,
            data: { titleSuffix: 'Job Types' },
          },
          {
            path: 'lab-contacts',
            component: LabContactsComponent,
            data: { titleSuffix: 'Contacts' },
          },
          {
            path: 'history',
            component: LabTimelineComponent,
            data: { titleSuffix: 'History' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LabPagesModule],
  exports: [RouterModule],
})
export class LabPagesRoutingModule {}
