<pr-page-splash>
  <ng-container *ngIf="!isProduction; else inBeta">
    <ng-container *ngIf="!emailSent; else checkInbox">
      <mat-vertical-stepper #stepper [linear]="true">
        <mat-step [stepControl]="userForm">
          <form [formGroup]="userForm">
            <ng-template matStepLabel>
              Let's get started with some details
            </ng-template>
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="16px"
            >
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name"
                  [required]="true"
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="email"
                  [required]="true"
                />
              </mat-form-field>
            </div>
            <div>
              <button mat-flat-button color="primary" matStepperNext>
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="organisationForm">
          <form [formGroup]="organisationForm">
            <ng-template matStepLabel>
              What's the name of your Organisation?
            </ng-template>
            <mat-form-field>
              <mat-label>Organisation Name</mat-label>
              <input
                matInput
                type="text"
                formControlName="name"
                [required]="true"
              />
            </mat-form-field>
            <div>
              <button mat-stroked-button matStepperPrevious>Back</button>
              <button
                mat-flat-button
                color="primary"
                (click)="save()"
                [disabled]="disabled"
              >
                Save
              </button>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </ng-container>
  </ng-container>
  <ng-template #checkInbox>
    <p>Please check your email inbox to verify your account.</p>
  </ng-template>

  <ng-template #inBeta>
    <div
      class="beta-message"
      fxLayout="column"
      fxLayoutAlign="space-between center"
      fxLayoutGap="32px"
    >
      <h2 class="layout-margin">We're still developing Principle</h2>
      <h3 class="layout-margin">
        We know you want to get started but we're still working on it. Please
        check back after March 2022.
      </h3>
    </div>
  </ng-template>
</pr-page-splash>
