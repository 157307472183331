import {
  type INamedDocument,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { type ITreatmentTemplateWithStep } from '../clinical-charting/treatment/treatment-plan-from-template';
import { type ICalendarEvent, isCalendarEvent } from '../event/calendar-event';
import {
  type IPatientFormData,
  type IPrimaryContactFormData,
} from '../patient/patient-scheduling';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';

export interface IAppointmentRequest extends ICalendarEvent, ISoftDelete {
  practitioner: INamedDocument<IStaffer>;
  practice: INamedDocument<IPractice>;
  patient: {
    primaryContact: IPrimaryContactFormData;
    patient?: IPatientFormData;
  };
  template: ITreatmentTemplateWithStep;
}

export function isAppointmentRequest(
  data: unknown
): data is IAppointmentRequest {
  return (
    isCalendarEvent(data) &&
    'patient' in data &&
    'practice' in data &&
    'practitioner' &&
    'template' in data
  );
}
