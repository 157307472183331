<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex class="layout-margin" *ngIf="ageChartBuilder">
    <h2>Age</h2>
    <pr-chart [chartBuilder]="ageChartBuilder" />
  </div>

  <div class="layout-margin pie-chart" *ngIf="genderChartBuilder">
    <h2>Gender</h2>
    <pr-chart [chartBuilder]="genderChartBuilder" />
  </div>
</div>

<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex class="layout-margin" *ngIf="distanceChartBuilder">
    <h2>Distance</h2>
    <pr-chart [chartBuilder]="distanceChartBuilder" />
  </div>

  <div class="layout-margin pie-chart" *ngIf="newVsExistingChartBuilder">
    <h2>New vs Existing</h2>
    <pr-chart [chartBuilder]="newVsExistingChartBuilder" />
  </div>
</div>
