<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">General Settings</h1>
  <form
    fxLayout="column"
    fxLayoutGap="16px"
    class="layout-margin"
    novalidate
    [formGroup]="form"
    (ngSubmit)="save()"
  >
    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" [required]="true" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          [required]="true"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" [required]="true" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px" formGroupName="settings">
      <mat-form-field fxFlex>
        <mat-label>Timezone</mat-label>
        <mat-select formControlName="timezone" [required]="true">
          <mat-option
            *ngFor="let timezone of timezones; trackBy: trackByTimezone"
            [value]="timezone"
          >
            {{ timezone }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" [required]="true" />
      </mat-form-field>
    </div>

    <pr-map-preview [coordinates]="coordinates$ | async" />

    <div formGroupName="settings" class="flex flex-row items-center gap-2">
      <mat-form-field
        class="flex grow"
        subscriptSizing="dynamic"
        floatLabel="always"
      >
        <mat-label>Default Fee Schedule</mat-label>
        <mat-select
          formControlName="defaultFeeSchedule"
          [compareWith]="isSameFeeSchedule"
          [placeholder]="unselectedFeeScheduleLabel$ | async"
        >
          <mat-select-trigger>
            {{ selectedFeeScheduleLabel$ | async }}
          </mat-select-trigger>

          <mat-option [value]="undefined">
            {{ unselectedFeeScheduleLabel$ | async }}
          </mat-option>

          @for (group of feeScheduleGroups$ | async; track group.name) {
            <div class="p-2 text-xs font-bold opacity-75">
              {{ group.name | titlecase }}
              <mat-icon
                class="icon-3 opacity-50"
                matTooltip="Fee Schedules from {{ group.scope | titlecase }}"
                >info</mat-icon
              >
            </div>
            @for (feeSchedule of group.schedules; track feeSchedule.ref.path) {
              <mat-option [value]="feeSchedule.ref">
                {{ feeSchedule.name }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <mat-icon
        class="opacity-50"
        matTooltip="This fee schedule will be used for new treatment plans created at this practice unless the patient has a preferred fee schedule set"
        >info</mat-icon
      >
    </div>

    <pt-buttons-container>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="!form.valid"
      >
        Save Changes
      </button>
    </pt-buttons-container>
  </form>
</div>
