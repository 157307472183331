<mat-toolbar color="accent">
  <span fxFlex
    >Medical History
    {{ data.form.date | moment | amDateFormat : dateFormat }}</span
  >
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>
  <pr-custom-form
    [form]="data.form"
    [disabled]="data.readonly"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
