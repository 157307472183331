import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { BrandAutomationListComponent } from './brand-automation-list/brand-automation-list.component';
import { PagesModule } from './pages.module';

const routes: Routes = [
  {
    path: '',
    component: BrandAutomationListComponent,
    data: {
      title: 'Automations',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PagesModule],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
