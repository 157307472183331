<div class="h-0.5">
  @if (loading$ | async) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>
<pr-toc-page
  sidebarTitle="Dates"
  [sidebarClass]="(showTableOfContents$ | async) ? '' : '!hidden'"
>
  <div class="flex flex-col gap-4 p-4">
    @if (dateGroups$ | async; as dateGroups) {
      @for (dateGroup of dateGroups; track dateGroup.name) {
        <h3
          class="date-group"
          ptTableOfContentsHeading
          [id]="dateGroup.id"
          *ngIf="showTableOfContents$ | async"
        >
          {{ dateGroup.name }}
        </h3>

        @for (group of dateGroup.groups; track group.name) {
          <h4
            class="appointment-group"
            id="{{ dateGroup.id }}-{{ group.name }}"
          >
            {{ group.name }}
          </h4>

          <ng-container *ngIf="group.appointments.length; else noAppointments">
            <mat-accordion class="popout no-content-padding">
              @for (eventable of group.appointments; track eventable.uid) {
                <mat-expansion-panel
                  hideToggle
                  [expanded]="
                    expanded$ | pairMap$: eventable : isExpanded | ngrxPush
                  "
                  (opened)="setExpanded(eventable)"
                  (closed)="resetExpanded()"
                >
                  <mat-expansion-panel-header
                    prEnableExpansionPanelHeaderActions
                    collapsedHeight="auto"
                    expandedHeight="auto"
                  >
                    <pr-appointment-header
                      fxFlex
                      [eventable]="eventable"
                      [showPractitionerIcon]="true"
                    />
                  </mat-expansion-panel-header>

                  <ng-template matExpansionPanelContent>
                    <mat-divider />
                    <pr-appointment-card
                      [appointment]="selectedAppointment$ | ngrxPush"
                      [actions]="actions"
                      (updateEventable)="updateEventable($event, eventable)"
                    />
                  </ng-template>
                </mat-expansion-panel>
              }
            </mat-accordion>
          </ng-container>

          <ng-template #noAppointments>
            <div class="mat-caption">No appointments</div>
          </ng-template>
        }
      }
    }
  </div>
</pr-toc-page>
