<mat-form-field fxFill>
  <mat-label>Assignee</mat-label>
  <input
    type="text"
    matInput
    [required]="true"
    [formControl]="assigneeCtrl"
    [matAutocomplete]="autoGroup"
    #autoComplete="matAutocompleteTrigger"
    (click)="autoComplete.openPanel()"
    (blur)="validateAssignee()"
  />
  <mat-autocomplete
    #autoGroup="matAutocomplete"
    [autoActiveFirstOption]="true"
    [displayWith]="displayFn"
  >
    <mat-optgroup
      *ngFor="let group of assignees$ | async; trackBy: trackByGroup"
      [label]="group.label"
    >
      <mat-option
        *ngFor="let assignee of group.assignees; trackBy: trackByAssignee"
        [value]="assignee"
      >
        {{ assignee | map : assigneeName }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
