import {
  FormLayoutElementType,
  IBaseFormLayoutElement,
  IChildFlexLayoutElement,
  ICustomFormData,
  IFormLayoutElement,
  IFormLayoutHtml,
  IJsonSchemaFormWithResolverConfig,
  IParentFlexLayout,
  IRootFormLayoutElement,
  IPatientFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import { merge } from 'lodash';

export function getCustomFormData<T extends object = object>(
  config: IJsonSchemaFormWithResolverConfig,
  data?: T
): ICustomFormData<T> {
  return { ...config, data };
}

export function getCustomSubmittedForm<T extends object = object>(
  config: ICustomFormData<T>,
  data: T
): IPatientFormSchema<T> {
  return {
    ...config,
    data,
    date: toTimestamp(),
  };
}

export function prefixLayoutItems<T extends IBaseFormLayoutElement>(
  items: T[],
  prefix: string
): T[] {
  return items.map((item) => {
    if ('key' in item) {
      item.key = `${prefix}.${item.key ?? ''}`;
    }
    if ('items' in item && Array.isArray(item.items)) {
      item.items = prefixLayoutItems(item.items, `${prefix}`);
    }
    return item;
  });
}

export function formToolbar(text: string): IFormLayoutHtml {
  return formHtml(
    `<div class="mat-toolbar mat-toolbar-single-row">${text}</div>`
  );
}

export function formFlexRow(
  items: IChildFlexLayoutElement[] = [],
  overrides?: Partial<IChildFlexLayoutElement>
): IParentFlexLayout {
  const flexBasis = items.length ? 100 / items.length : 100;
  return {
    type: FormLayoutElementType.Flex,
    options: { fxLayout: 'row wrap', flex: '1 1 100%' },
    items: items.map((item) =>
      merge(
        {
          options: {
            flex: `1 1 ${flexBasis}%`,
          },
          ...item,
        },
        overrides
      )
    ),
  };
}

export function formSubForm(
  key: string,
  items: (IParentFlexLayout | IFormLayoutElement)[]
): IRootFormLayoutElement {
  return {
    key,
    type: FormLayoutElementType.Flex,
    notitle: true,
    htmlClass: 'layout-margin',
    items: prefixLayoutItems(items, key),
  };
}

export function formHtml(html: string): IFormLayoutHtml {
  return {
    type: FormLayoutElementType.Help,
    helpvalue: html,
  };
}
