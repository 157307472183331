import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AppointmentViewSidebarComponent,
  IAppointmentSidebarData,
} from '@principle-theorem/ng-appointment';
import {
  CalendarEventEntity,
  type ICalendarEventStyle,
} from '@principle-theorem/ng-calendar/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { DynamicSidebarService } from '@principle-theorem/ng-shared';
import {
  getEventDisplayBounds,
  getEventTitle,
} from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  CalendarEventSummary,
  EVENT_TYPE_COLOUR_MAP,
  EventType,
  IScheduleSummaryEventable,
  isAppointmentEventType,
  isCalendarEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  exceedsLuminanceThreshold,
  filterUndefined,
  formatTimeFromTo,
  toMomentRange,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { CalendarEventEditSidebarComponent } from '../calendar-event-edit-sidebar/calendar-event-edit-sidebar.component';
import { CalendarEventSidebarStoreService } from '../calendar-event-edit-sidebar/calendar-event-sidebar-store.service';

export interface ICalendarEventTimeData {
  title: string;
  eventTime: string;
}

@Component({
    selector: 'pr-calendar-event-time',
    templateUrl: './calendar-event-time.component.html',
    styleUrls: ['./calendar-event-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalendarEventTimeComponent {
  appointmentType: EventType = EventType.Appointment;
  event$ = new ReplaySubject<CalendarEventEntity>(1);
  eventTimeData$: Observable<ICalendarEventTimeData>;
  index$ = new BehaviorSubject<number>(0);
  eventStyle$: Observable<Partial<ICalendarEventStyle>>;

  @Input() day: Moment;

  @Input()
  set index(index: number) {
    if (isNumber(index)) {
      this.index$.next(index);
    }
  }

  @Input()
  set event(event: CalendarEventEntity) {
    if (!event) {
      return;
    }
    this.event$.next(event);
  }

  constructor(
    private _sidebar: DynamicSidebarService,
    private _calendarEventSidebarStore: CalendarEventSidebarStoreService,
    private _currentScope: CurrentScopeFacade
  ) {
    this.eventTimeData$ = this.event$.pipe(
      map((event) => {
        const eventRange = toMomentRange(event.event.from, event.event.to);
        return {
          title: getEventTitle(event as IScheduleSummaryEventable),
          eventTime: formatTimeFromTo(eventRange.from, eventRange.to),
        };
      })
    );

    this.eventStyle$ = combineLatest([
      this.event$,
      this.index$,
      this._currentScope.currentPractice$.pipe(filterUndefined()),
    ]).pipe(
      map(([event, index, practice]) => {
        const bounds = getEventDisplayBounds(event.event, 960, 1, index * 10);
        const eventRange = toMomentRange(event.event.from, event.event.to);
        const startsBeforeDay = eventRange.from.isBefore(
          this.day.clone().startOf('day')
        );
        const endsAfterDay = eventRange.to.isAfter(
          this.day.clone().endOf('day')
        );
        const backgroundColor =
          practice.settings.eventColourOverrides?.[event.event.type] ??
          EVENT_TYPE_COLOUR_MAP[event.event.type];
        const color = exceedsLuminanceThreshold(backgroundColor, 0.5)
          ? 'rgba(0, 0, 0, .6)'
          : 'rgba(255, 255, 255, .9)';

        return {
          top: `${bounds.top}px`,
          height: `${bounds.height}px`,
          left: `${bounds.left}%`,
          backgroundColor,
          color,
          marginLeft: startsBeforeDay ? '-5px' : '0',
          marginRight: endsAfterDay ? '-5px' : '0',
        };
      })
    );
  }

  async selectEvent(event: CalendarEventEntity): Promise<void> {
    if (isAppointmentEventType(event.event.type)) {
      return this._sidebar.open<
        AppointmentViewSidebarComponent,
        IAppointmentSidebarData
      >(AppointmentViewSidebarComponent, {
        data: {
          appointment: event as AppointmentSummary,
        },
      });
    }
    if (isCalendarEventType(event.event.type)) {
      await this._calendarEventSidebarStore.setCalendarEventFromSummary(
        event as CalendarEventSummary
      );
      return this._sidebar.open(CalendarEventEditSidebarComponent);
    }
    // eslint-disable-next-line no-console
    console.error('Event not supported', event);
  }
}
