export interface IDynamicForm<Type = unknown> {
  [key: string]: IDynamicFormSelect<Type>;
}

export enum DynamicFormType {
  Select = 'select',
}

export type IDynamicFormControl<T> = {
  type: DynamicFormType;
  label: string;
} & (
  | {
      initialValue?: T;
      isMultiple?: false;
    }
  | {
      initialValue?: T[];
      isMultiple: boolean;
    }
);

export interface IDynamicFormSelectOption<T> {
  label: string;
  value: T;
}

export type IDynamicFormSelect<T> = IDynamicFormControl<T> & {
  type: DynamicFormType.Select;
  options: IDynamicFormSelectOption<T>[];
  compareWith: (a: T, b: T) => boolean;
};
