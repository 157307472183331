import {
  ChecklistItemAction,
  ChecklistType,
  IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { ChecklistItem } from './checklist-item';

const PRE_CHECK_ITEMS: IChecklistItem[] = [
  ChecklistItem.init({
    type: ChecklistType.Pre,
    title: `Update Patient Medical History`,
    action: ChecklistItemAction.None,
  }),
  ChecklistItem.init({
    type: ChecklistType.Pre,
    title: `Ensure required consent form was signed`,
    action: ChecklistItemAction.Print,
    data: `Here's that important consent form to print and sign`,
  }),
];

const POST_CHECK_ITEMS: IChecklistItem[] = [
  ChecklistItem.init({
    type: ChecklistType.Post,
    title: `Remind patient to avoid smoking for risk of dry socket`,
    action: ChecklistItemAction.None,
  }),
  ChecklistItem.init({
    type: ChecklistType.Post,
    title: 'Print doctors certificate',
    action: ChecklistItemAction.Print,
    data: `Doctors certificate`,
  }),
  ChecklistItem.init({
    type: ChecklistType.Post,
    title: 'Print prescription',
    action: ChecklistItemAction.Print,
    data: `Prescription information`,
  }),
];

export function MockChecklist(): IChecklistItem[] {
  return [...POST_CHECK_ITEMS, ...PRE_CHECK_ITEMS];
}
