import { v4 as uuid } from 'uuid';
import {
  type CollectionReference,
  type DocumentReference,
  type DocumentSnapshot,
} from '../adaptor';

export function docRefPath(): string {
  return `testing/document/reference/${uuid()}`;
}

export function docRef<T = unknown>(path?: string): DocumentReference<T> {
  const id = uuid();
  if (!path) {
    path = `testing/document/reference/${id}`;
  }
  return {
    type: 'document',
    id,
    path,
  } as unknown as DocumentReference<T>;
}

export function colRef<T = unknown>(path?: string): CollectionReference<T> {
  if (!path) {
    path = `testing/collection/reference`;
  }
  return {
    type: 'collection',
    id: '',
    path,
  } as unknown as CollectionReference<T>;
}

export function docSnapshot<T>(
  givenRef?: DocumentReference<T>
): DocumentSnapshot<T> {
  const ref = givenRef ?? docRef<T>();
  return {
    exists: true,
    ref,
    id: ref.id,
  } as unknown as DocumentSnapshot<T>;
}
