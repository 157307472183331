import { Injectable } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormDataProviderService {
  readonly patient$ = new BehaviorSubject<WithRef<IPatient> | undefined>(
    undefined
  );

  setPatient(patient: WithRef<IPatient> | undefined): void {
    this.patient$.next(patient);
  }
}
