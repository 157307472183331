import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IInvoice,
  InvoiceStatus,
  InvoiceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_FORMAT,
  type WithRef,
  filterUndefined,
  getDoc,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-invoice-header',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceHeaderComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  isDraft$: Observable<boolean>;
  isPaid$: Observable<boolean>;
  link$: Observable<string[]>;
  replacedInvoice$: Observable<WithRef<IInvoice>>;
  replacedInvoiceLink$: Observable<string[]>;
  cancelledDate$: Observable<Timestamp | undefined>;
  treatmentDate$: Observable<Timestamp | undefined>;
  readonly dateFormat = HISTORY_DATE_FORMAT;
  readonly invoiceType = InvoiceType;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(private _brandScope: CurrentBrandScope) {
    this.isDraft$ = this.invoice$.pipe(
      map((invoice: IInvoice) => invoice.status === InvoiceStatus.Draft)
    );
    this.isPaid$ = this.invoice$.pipe(
      map((invoice: IInvoice) => invoice.status === InvoiceStatus.Paid)
    );

    this.link$ = combineLatest([
      this._brandScope.doc$.pipe(filterUndefined()),
      this.invoice$,
    ]).pipe(map(([brand, invoice]) => this._getInvoiceLink(brand, invoice)));

    this.cancelledDate$ = this.invoice$.pipe(
      map((invoice) =>
        Invoice.isCancelledInvoice(invoice) ? invoice.cancelledAt : undefined
      )
    );

    this.replacedInvoice$ = this.invoice$.pipe(
      switchMap((invoice) =>
        Invoice.isReplacedInvoice(invoice)
          ? getDoc(invoice.cancellation.replacement)
          : of(undefined)
      ),
      filterUndefined()
    );

    this.replacedInvoiceLink$ = combineLatest([
      this._brandScope.doc$.pipe(filterUndefined()),
      this.replacedInvoice$,
    ]).pipe(map(([brand, invoice]) => this._getInvoiceLink(brand, invoice)));

    this.treatmentDate$ = this.invoice$.pipe(
      switchMap((invoice) =>
        Invoice.getAssociatedAppointment$(invoice).pipe(
          map((appointment) => appointment?.event?.from)
        )
      )
    );
  }

  private _getInvoiceLink(
    brand: WithRef<IBrand>,
    invoice: WithRef<IInvoice>
  ): string[] {
    const patientRef = Invoice.patientDocRef(invoice);
    return [
      '/',
      brand.slug,
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoice.ref.id,
    ];
  }
}
