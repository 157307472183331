import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
  type IConfirmAppointmentInfo,
  type IConfirmAppointmentRequest,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, unserialise$ } from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientConfirmAppointmentService {
  constructor(private _functions: FirebaseFunctionsService) {}

  resolveAppointmentInfo$(
    tokenUid: string
  ): Observable<IConfirmAppointmentInfo | undefined> {
    const payload = {
      action: PatientAction.GetPatientConfirmAppointmentInfo,
      data: { tokenUid },
    };

    const request = this._functions.call<
      PatientActionRequest<IConfirmAppointmentRequest>,
      SerialisedData<IConfirmAppointmentInfo>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return from(request).pipe(
      catchError(() => of(undefined)),
      unserialise$()
    );
  }
}
