import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GlobalStoreService,
  StafferSettingsStoreService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import { Interaction } from '@principle-theorem/principle-core';
import {
  type IInteraction,
  type IInteractionTypeMap,
  type IInteractionV2,
  INTERACTION_TYPES,
  type ITag,
  InteractionType,
  interactionTypeDisplayMap,
  isInteractionV2,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  type IGroup,
  type WithRef,
  customGroupBy,
  multiFilter,
  snapshot,
  sortByCreatedAt,
  sortTimestamp,
  toMoment,
} from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { intersection } from 'lodash';
import { BehaviorSubject, type Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-interaction-timeline',
    templateUrl: './interaction-timeline.component.html',
    styleUrls: ['./interaction-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionTimelineComponent {
  private _sortOrder$ = new BehaviorSubject<'asc' | 'desc'>('desc');
  private _interactions$ = new BehaviorSubject<
    (IInteraction | IInteractionV2)[]
  >([]);
  readonly dateFormat = CASUAL_DATE_WITH_YEAR;
  interactionsByDay$: Observable<
    IGroup<IInteraction | IInteractionV2, Timestamp>[]
  >;
  pinnedInteractions$: Observable<(IInteraction | IInteractionV2)[]>;
  eventTypes = INTERACTION_TYPES;
  tags$: Observable<WithRef<ITag>[]>;
  selectedTags$: Observable<DocumentReference<ITag>[]>;

  @Input()
  set sortOrder(direction: 'asc' | 'desc') {
    if (direction) {
      this._sortOrder$.next(direction);
    }
  }

  @Input()
  set interactions(interactions: (IInteraction | IInteractionV2)[]) {
    if (interactions) {
      this._interactions$.next(interactions);
    }
  }

  constructor(
    private _globalStore: GlobalStoreService,
    private _settings: StafferSettingsStoreService
  ) {
    const filteredInteractions$ = combineLatest([
      this._interactions$,
      this._settings.interactions$,
    ]).pipe(
      map(([interactions, settings]) =>
        this._getFilteredInteractions(
          interactions.slice(),
          settings.filters.filter((filter) =>
            INTERACTION_TYPES.includes(filter)
          ),
          settings.tagFilters
        )
      )
    );
    this.interactionsByDay$ = combineLatest([
      filteredInteractions$,
      this._sortOrder$,
    ]).pipe(
      map(([interactions, sortOrder]) =>
        this._getInteractionsByDay(interactions, sortOrder)
      )
    );
    this.pinnedInteractions$ = filteredInteractions$.pipe(
      map((interactions) => Interaction.getPinnedInteractions(interactions)),
      multiFilter((interaction) =>
        [InteractionType.Call, InteractionType.Note].includes(interaction.type)
      )
    );

    this.tags$ = this._globalStore.getTagsByType$(TagType.PatientNote);

    this.selectedTags$ = this._settings.interactions$.pipe(
      map((settings) => settings.tagFilters)
    );
  }

  async toggleFilter(filter: InteractionType): Promise<void> {
    const settings = await snapshot(this._settings.interactions$);
    const activeFilters = settings.filters;
    const index: number = activeFilters.indexOf(filter);
    if (index >= 0) {
      activeFilters.splice(index, 1);
    } else {
      activeFilters.push(filter);
    }

    this._settings.updateStafferSettings({
      interactions: {
        filters: activeFilters,
      },
    });
  }

  isFilterSelected$(type: InteractionType): Observable<boolean> {
    return this._settings.interactions$.pipe(
      map((settings) => settings.filters.includes(type))
    );
  }

  interactionType(type: keyof IInteractionTypeMap): string {
    return interactionTypeDisplayMap[type];
  }

  updateSelectedTags(tags: WithRef<ITag>[]): void {
    this._settings.updateStafferSettings({
      interactions: {
        tagFilters: tags.map((tag) => tag.ref),
      },
    });
  }

  private _getInteractionsByDay(
    interactions: (IInteraction | IInteractionV2)[],
    sortOrder: 'asc' | 'desc'
  ): IGroup<IInteraction | IInteractionV2, Timestamp>[] {
    const shouldReverse = sortOrder === 'asc';

    const sortedInteractions = interactions.sort(sortByCreatedAt);
    const orderedInteractions = shouldReverse
      ? sortedInteractions.reverse()
      : sortedInteractions;

    const sortedDays = customGroupBy(
      orderedInteractions,
      (interaction) => interaction.createdAt,
      (aCreatedAt, bCreatedAt) =>
        toMoment(aCreatedAt).isSame(toMoment(bCreatedAt), 'day')
    ).sort((a, b) => sortTimestamp(a.group, b.group));

    return shouldReverse ? sortedDays.reverse() : sortedDays;
  }

  private _getFilteredInteractions(
    interactions: (IInteraction | IInteractionV2)[],
    filters: InteractionType[],
    tagFilters: DocumentReference<ITag>[]
  ): (IInteraction | IInteractionV2)[] {
    if (!filters.length && !tagFilters.length) {
      return interactions;
    }
    return interactions.filter((interaction) => {
      const matchesType =
        filters.length > 0 ? filters.includes(interaction.type) : true;

      if (!tagFilters.length) {
        return matchesType;
      }

      if (!isInteractionV2(interaction)) {
        return false;
      }

      const filterTagRefs = tagFilters.map((tagRef) => tagRef.path);
      const tagRefs = interaction.tags.map((tag) => tag.ref.path);

      return matchesType && intersection(filterTagRefs, tagRefs).length > 0;
    });
  }
}
