import { type EntityState } from '@ngrx/entity';
import {
  Action,
  combineReducers,
  createAction,
  type MetaReducer,
} from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import { ChartActionTypes } from '../actions/action-types';
import { type IChartContextState } from './active-charts/chart-context-state';
import { activeChartsReducer } from './active-charts/reducer';
import {
  chartableConfigurationsReducer,
  type IChartableConfigurationsState,
} from './chartable-configurations/chartable-configurations.reducer';
import {
  feeScheduleReducer,
  type IFeeScheduleState,
} from './fee-schedule.reducer';
import {
  chartTreatmentPlanningReducer,
  type IChartTreatmentPlanningModuleState,
} from './treatment-plans';

export interface IChartState {
  treatmentPlanning: IChartTreatmentPlanningModuleState;
  feeSchedule: IFeeScheduleState;
  chartableConfigurations: IChartableConfigurationsState;
  activeCharts: EntityState<SerialisedData<IChartContextState>>;
}

export function reducers(
  state: IChartState | undefined,
  action: Action
): IChartState {
  return combineReducers({
    chartableConfigurations: chartableConfigurationsReducer,
    treatmentPlanning: chartTreatmentPlanningReducer,
    feeSchedule: feeScheduleReducer,
    activeCharts: activeChartsReducer,
  })(state, action);
}

export const reset = createAction(ChartActionTypes.ChartingAsReset);

export const metaReducers: MetaReducer<IChartState>[] = [];
