<div class="angular-component-with-content toc">
  <ul class="list">
    <li
      class="no-drag {{ 'item-' + heading.level }}"
      *ngFor="
        let heading of headings$ | async;
        index as index;
        trackBy: trackByHeading
      "
    >
      <a
        [fragment]="heading.id"
        [routerLink]="url$ | async"
        (click)="scrollToAnchor(heading.id)"
        >{{ heading.text }}</a
      >
    </li>
  </ul>
</div>
