import {
  ISODateType,
  WithRef,
  isINamedDocument,
  isObject,
  type INamedDocument,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { isEvent, type IEvent, type IEventable } from '../event/event';
import { type IStaffer } from '../staffer/staffer';
import { type ITag } from '../tag/tag';
import { IImplementsTreatmentTemplate } from '../clinical-charting/treatment/treatment-template';
export interface ISchedulingConflict {
  reason: string;
  blocking: boolean;
}

export type DayAvailabilityMap = Record<ISODateType, IAppointmentSuggestion[]>;

export interface IBookingSuggestionData {
  implementedBy: WithRef<IStaffer>[];
  availability: IPractitionerAvailability[];
}

export interface IImplementsTreatmentTemplateWithResolvedStaffer
  extends Omit<IImplementsTreatmentTemplate, 'staffer'> {
  staffer: WithRef<IStaffer>;
}

export interface IPractitionerAvailability {
  practitioner: WithRef<IStaffer>;
  daysWithAvailability: DayAvailabilityMap;
  nextAvailableAppointment?: IAppointmentSuggestion;
  display: {
    days: ISODateType[];
    expandable: boolean;
    expanded: boolean;
    nextAvailableInRange: boolean;
    hasAvailabilityInRange: boolean;
  };
}

export interface IAppointmentSuggestion {
  event: IEvent;
  schedulingConflicts: ISchedulingConflict[];
  practitioner: INamedDocument<IStaffer>;
  score: number;
  intersectingTags: INamedDocument<ITag>[];
  overlappingEvents: IEventable[];
  adjacentEvents: IEventable[];
}

export function isAppointmentSuggestion(
  data: unknown
): data is IAppointmentSuggestion {
  return (
    isObject(data) &&
    'event' in data &&
    isEvent(data.event) &&
    'practitioner' in data &&
    isINamedDocument(data.practitioner) &&
    isNumber(data.score) &&
    'intersectingTags' in data &&
    'schedulingConflicts' in data &&
    'overlappingEvents' in data &&
    'adjacentEvents' in data
  );
}

export interface IEnabledSuggestionMatchRules {
  distance: boolean;
  duration: boolean;
  overlap: boolean;
}
