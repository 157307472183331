<form [formGroup]="form" class="flex flex-col items-stretch justify-around">
  <mat-form-field class="mb-4 flex-1">
    <mat-label>Name</mat-label>
    <input
      type="text"
      matInput
      [required]="true"
      formControlName="name"
      autofocus
    />
  </mat-form-field>
</form>

<div class="flex gap-4">
  <div class="flex-1">
    <h3 mat-subtitle-1>Charting Options</h3>
    <pr-charting-options
      [chartingConfiguration]="conditionType"
      (selectedDisplayOptions)="selectedDisplayOptions($event)"
     />
  </div>
  <div class="flex-1">
    <h3 mat-subtitle-1>Available Surfaces</h3>
    <pr-available-surfaces
      [surfaces]="conditionType.availableSurfaces"
      (selectedSurfaces)="selectedSurfaces($event)"
     />
  </div>
</div>
