import { CdkDragDrop, CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CustomFormElement } from '@principle-theorem/principle-core';
import {
  AnyCustomFormElement,
  CustomFormElementCategory,
  CustomFormElementType,
} from '@principle-theorem/principle-core/interfaces';
import { firstValueFrom } from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilderElementPreviewComponent } from '../form-builder-element-preview/form-builder-element-preview.component';
import { FormBuilderService } from '../form-builder.service';
import {
  FormBuilderDragDrop,
  FormBuilderDropListEnterPredicate,
} from '../lib/form-builder-drag-drop';

@Component({
  selector: 'pr-form-builder-element',
  templateUrl: './form-builder-element.component.html',
  styleUrl: './form-builder-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    FormBuilderElementPreviewComponent,
  ],
})
export class FormBuilderElementComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formBuilder = inject(FormBuilderService);
  element$ = new ReplaySubject<AnyCustomFormElement>(1);
  dropListId$: Observable<string>;
  isInteractive$: Observable<boolean>;
  isSelected$: Observable<boolean>;
  isHorizontal$: Observable<boolean>;
  dropListCategory$: Observable<CustomFormElementCategory | undefined>;
  dropListEnterPredicate$: Observable<
    FormBuilderDropListEnterPredicate | undefined
  >;

  @Input()
  set element(element: AnyCustomFormElement) {
    if (element) {
      this.element$.next(element);
    }
  }

  @Output() dragDrop = new EventEmitter<CdkDragDrop<AnyCustomFormElement>>();
  @Output() dragStarted = new EventEmitter<CdkDragStart>();
  @Output() dragEnded = new EventEmitter<CdkDragEnd>();

  constructor() {
    this.dropListId$ = this.element$.pipe(map((node) => node.uid));
    this.isInteractive$ = this.element$.pipe(
      map((element) => element.type !== CustomFormElementType.Container)
    );
    this.isSelected$ = this.element$.pipe(
      switchMap((element) => this.formBuilder.isSelected$(element))
    );
    this.isHorizontal$ = this.element$.pipe(
      map((element) => CustomFormElement.isHorizontal(element))
    );
    this.dropListCategory$ = this.element$.pipe(
      map((element) => element.dropListCategory)
    );
    this.dropListEnterPredicate$ = this.dropListCategory$.pipe(
      map((dropListCategory) =>
        dropListCategory
          ? FormBuilderDragDrop.onlyAllowCategoryFn(dropListCategory)
          : undefined
      )
    );
    this.dropListId$
      .pipe(startWith(undefined), pairwise(), takeUntil(this._onDestroy$))
      .subscribe(([lastId, nextId]) => {
        if (lastId !== undefined) {
          this.formBuilder.unregisterDropLists([lastId]);
        }
        if (nextId !== undefined) {
          this.formBuilder.registerDropLists([nextId]);
        }
      });
    combineLatest([this.dropListId$, this._onDestroy$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([dropListId]) =>
        this.formBuilder.unregisterDropLists([dropListId])
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('click', ['$event'])
  async onClick(event: PointerEvent): Promise<void> {
    const isInteractive = await firstValueFrom(this.isInteractive$);
    if (!isInteractive) {
      return;
    }
    event.stopPropagation();
    const element = await firstValueFrom(this.element$);
    if (element) {
      this.formBuilder.selectElement(element.uid);
    }
  }
}
