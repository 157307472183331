<div class="flex items-center justify-end gap-2">
  <div
    *ngIf="name"
    class="mat-caption flex-1 truncate"
    [matTooltip]="tooltip"
    [matTooltipDisabled]="!tooltip"
  >
    {{ name }}
  </div>
  <div
    class="mat-caption price flex"
    [ngClass]="{ disabled: disabled, invalid: priceCtrl.invalid }"
  >
    $
    <input
      *ngIf="!disabled; else readOnlyPrice"
      matInput
      type="number"
      class="mr-1.5"
      min="0"
      step="1"
      autocomplete="off"
      [formControl]="priceCtrl"
      (keyup.enter)="$event.target.blur()"
    />
    <ng-template #readOnlyPrice>{{ priceCtrl.value }}</ng-template>

    @if (!disabled) {
      @if (canReset$ | async) {
        <button
          mat-icon-button
          class="icon-sm opacity-50"
          matTooltip="Reset to Fee Schedule price"
          (click)="resetPrice()"
        >
          <mat-icon>restart_alt</mat-icon>
        </button>
      } @else {
        <span class="w-6 flex-1"></span>
      }

      <button
        mat-icon-button
        class="icon-sm opacity-50"
        color="warn"
        [matTooltip]="'Delete ' + name"
        (click)="basePriceDeleted.emit()"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
</div>
