<div
  class="flex w-full flex-row justify-center gap-4 p-4"
  [ngClass]="{
    'text-green-500': type === 'success',
    'text-red-500': type === 'error'
  }"
>
  <mat-icon class="icon-lg">
    @if (type === 'error') {
      error
    } @else {
      check_circle_outline
    }
  </mat-icon>
  <h2 class="!m-0 text-xl !font-semibold"><ng-content /></h2>
</div>
