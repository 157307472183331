import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { type ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { isNumber } from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type DisplayColourBarDirection = 'horizontal' | 'vertical';

@Component({
  selector: 'pr-display-colour-bar',
  templateUrl: './display-colour-bar.component.html',
  styleUrls: ['./display-colour-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSharedModule],
})
export class DisplayColourBarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _size$ = new BehaviorSubject<number>(30);
  private _direction$ = new BehaviorSubject<DisplayColourBarDirection>(
    'vertical'
  );
  category$ = new ReplaySubject<ITreatmentCategory>(1);

  @HostBinding('style.width') width = 'auto';
  @HostBinding('style.height') height = 'auto';
  @HostBinding('class') cssClass = '';

  @Input()
  set direction(direction: DisplayColourBarDirection) {
    if (direction) {
      this._direction$.next(direction);
    }
  }

  @Input()
  set size(size: number) {
    if (isNumber(size)) {
      this._size$.next(size);
    }
  }

  @Input()
  set category(category: ITreatmentCategory) {
    if (category) {
      this.category$.next(category);
    }
  }

  constructor() {
    this._direction$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((direction) => (this.cssClass = direction));

    combineLatest([this._direction$, this._size$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([direction, size]) =>
        this._updateSizeBindings(direction, size)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _updateSizeBindings(
    direction: DisplayColourBarDirection,
    size: number
  ): void {
    const value = `${size}px`;
    this.height = direction === 'horizontal' ? value : 'auto';
    this.width = direction === 'horizontal' ? 'auto' : value;
  }
}
