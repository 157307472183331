import { ActivatedRouteSnapshot } from '@angular/router';
import { get, has } from 'lodash';

export function findInheritedRouteData<T = unknown>(
  route: ActivatedRouteSnapshot,
  key: string
): T | undefined {
  if (has(route.data, key)) {
    return get(route.data, key) as T;
  }
  if (route.parent) {
    return findInheritedRouteData(route.parent, key);
  }
}

export type RouterLinkData = string | string[];

export function openBlankTab(title: string, body: string): void {
  const tab = window.open('about:blank', '_blank');
  tab?.document.write(`
    <html>
      <head>
        <title>${title}</title>
      </head>
      <body>${body}</body>
    </html>
  `);
  tab?.document.close();
}
