import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IAppointment,
  IPinnedInteractionSummary,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { AppointmentPinnedNotesBloc } from '../../appointment-card/appointment-all-notes/appointment-pinned-notes-bloc';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'pr-appointment-history-card-pinned-notes',
    templateUrl: './appointment-history-card-pinned-notes.component.html',
    styleUrls: ['./appointment-history-card-pinned-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentHistoryCardPinnedNotesComponent {
  trackByPinnedNote = TrackByFunctions.uniqueId<IPinnedInteractionSummary>();
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  pinnedNotes: AppointmentPinnedNotesBloc;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.pinnedNotes = new AppointmentPinnedNotesBloc(this.appointment$);
  }
}
