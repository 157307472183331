import {
  randFullName,
  rand,
  randEmail,
  randPhoneNumber,
  randStreetAddress,
} from '@ngneat/falso';
import {
  FormStatus,
  GENDERS,
  ISubmittedForm,
  ISubmittedFormHistory,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  MockISODate,
  Properties,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';

export class PatientDetailsFormMock
  extends BaseFirestoreMock
  implements Properties<ISubmittedFormHistory>
{
  form = PATIENT_DETAILS_MOCK_DATA;
  status = FormStatus.Submitted;
  statusHistory = [];
  template = MockNamedDocument();
}

export const PATIENT_DETAILS_MOCK_DATA: ISubmittedForm = {
  data: {
    name: `${randFullName()}`,
    dateOfBirth: MockISODate(),
    gender: rand(GENDERS),
    email: randEmail(),
    contactNumbers: [
      { label: '', number: randPhoneNumber() },
      { label: 'mobile', number: '0478123456' },
    ],
    address: randStreetAddress(),
  },
  date: toTimestamp(),
  disableChanges: false,
};
