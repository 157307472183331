import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  IPatient,
  IPatientDetails,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, serialise } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentCreateSidebarService {
  private _triggerPatientSave$ = new BehaviorSubject<boolean>(false);
  private _hasPatientDetailsChanges$ = new BehaviorSubject<boolean>(false);
  private _patientDetails$ = new BehaviorSubject<IPatientDetails | undefined>(
    undefined
  );

  readonly triggerPatientSave$ = this._triggerPatientSave$.asObservable();
  constructor(private _dialog: MatDialog) {}

  clearPatientDetailsChanges(): void {
    this._hasPatientDetailsChanges$.next(false);
  }

  reset(): void {
    this._triggerPatientSave$.next(false);
    this._hasPatientDetailsChanges$.next(false);
    this._patientDetails$.next(undefined);
  }

  hasPatientDetailsChanges(
    currPatientDetails: IPatientDetails,
    patient: WithRef<IPatient> | undefined
  ): void {
    if (!this._patientDetails$.value) {
      this._patientDetails$.next(currPatientDetails);
    }
    const prevDetails = serialise(this._patientDetails$.value);
    const currDetails = serialise(currPatientDetails);
    if (!patient && currPatientDetails) {
      this._hasPatientDetailsChanges$.next(true);
      return;
    }

    !isEqual(prevDetails, currDetails) && !!this._patientDetails$.value
      ? this._hasPatientDetailsChanges$.next(true)
      : this._hasPatientDetailsChanges$.next(false);
  }

  async cleanUp(): Promise<void> {
    if (!this._hasPatientDetailsChanges$.value) {
      return;
    }

    const data = confirmationDialogData({
      title: 'Unsaved Patient Changes',
      prompt: `You have unsaved changes to the patient details. Would you like to save these changes?`,
      submitLabel: 'Save Changes',
      submitColor: 'primary',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    this._triggerPatientSave$.next(true);
  }
}
