import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import type crossfilter from 'crossfilter2';
import { type Crossfilter } from 'crossfilter2';
import * as dc from 'dc';
import { type BaseMixin } from 'dc';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { type IDCTooltip } from '../chart-builders/dc-chart-builders/dc-tooltip';

@Component({
    selector: 'pr-dc-chart',
    templateUrl: './dc-chart.component.html',
    styleUrls: ['./dc-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DcChartComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _chartDiv$ = new ReplaySubject<ElementRef<SVGSVGElement>>(1);
  private _legendDiv$ = new ReplaySubject<ElementRef<SVGSVGElement>>(1);
  private _legend$ = new ReplaySubject<dc.HtmlLegend | undefined>(1);
  private _tooltip$ = new BehaviorSubject<IDCTooltip | undefined>(undefined);
  private _chart$ = new ReplaySubject<dc.BaseMixin<unknown>>(1);
  private _autoResize$ = new BehaviorSubject<boolean>(true);
  private _ndx$ = new BehaviorSubject<
    crossfilter.Crossfilter<unknown> | undefined
  >(undefined);
  canResetFilter$: Observable<boolean>;
  chartWidth$ = this._chart$.pipe(map((chart) => chart.width()));
  @Input() label: string;
  @Input() hideReset: boolean = false;
  chartClasses$ = new ReplaySubject<string[]>(1);

  @Input()
  set chartClasses(chartClasses: string[]) {
    if (chartClasses) {
      this.chartClasses$.next(chartClasses);
    }
  }

  @Input()
  set chart(chart: dc.BaseMixin<unknown>) {
    if (chart) {
      this._chart$.next(chart);
    }
  }

  @Input()
  set legend(legend: dc.HtmlLegend | undefined) {
    this._legend$.next(legend);
  }

  @Input()
  set tooltip(tooltip: IDCTooltip | undefined) {
    this._tooltip$.next(tooltip);
  }

  @Input()
  set autoResize(autoResize: boolean) {
    this._autoResize$.next(coerceBooleanProperty(autoResize));
  }

  @Input()
  set crossfilter(ndx: Crossfilter<unknown>) {
    if (ndx) {
      this._ndx$.next(ndx);
    }
  }

  @ViewChild('dcChart', { static: true })
  set chartDiv(chartDiv: ElementRef<SVGSVGElement>) {
    if (chartDiv) {
      this._chartDiv$.next(chartDiv);
    }
  }

  @ViewChild('dcLegend', { static: true })
  set legendDiv(legendDiv: ElementRef<SVGSVGElement>) {
    if (legendDiv) {
      this._legendDiv$.next(legendDiv);
    }
  }

  constructor() {
    this.canResetFilter$ = combineLatest([
      this._chart$,
      this._listenToNdxEvents$().pipe(startWith(undefined)),
    ]).pipe(
      switchMap(async () => {
        const chart = await snapshot(this._chart$);
        return chart.hasFilter();
      })
    );

    combineLatest([
      this._chartDiv$,
      this._legendDiv$,
      this._chart$,
      this._legend$,
      this._tooltip$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([chartDiv, legendDiv, chart, legend, tooltip]) =>
        this._redraw(chartDiv, chart, legendDiv, legend, tooltip)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async resetFilter(): Promise<void> {
    const chart = await snapshot(this._chart$);
    chart.filterAll();
    dc.redrawAll();
  }

  private _redraw(
    chartDiv: ElementRef<SVGSVGElement>,
    chart: dc.BaseMixin<unknown>,
    legendDiv: ElementRef<SVGSVGElement>,
    legend?: dc.HtmlLegend,
    tooltip?: IDCTooltip
  ): void {
    chartDiv.nativeElement.childNodes.forEach((node) => node.remove());
    chart.anchor(chartDiv.nativeElement as unknown as BaseMixin<unknown>);
    if (legend && legendDiv) {
      legend.container(legendDiv.nativeElement as unknown as string);
      chart.legend(legend as unknown as dc.Legend);
    }
    if (tooltip) {
      tooltip.render(chart);
    }
    chart.render();
  }

  private _listenToNdxEvents$(): Observable<crossfilter.EventType> {
    return this._ndx$.pipe(
      distinctUntilChanged(),
      filterUndefined(),
      switchMap(
        (ndx) =>
          new Observable<crossfilter.EventType>((observer) =>
            ndx.onChange((eventType) => observer.next(eventType))
          )
      )
    );
  }
}
