<mat-toolbar color="accent" class="min-h-[50px]">
  {{ isEdit ? 'Edit' : 'Add' }} Automated Notification Configuration
</mat-toolbar>

<div class="flex flex-col overflow-scroll bg-slate-100">
  <form [formGroup]="form" class="flex flex-col gap-4 p-4">
    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Settings</h3>
      <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      @if (data.practices) {
        <mat-form-field
          class="flex flex-auto"
          subscriptSizing="dynamic"
          floatLabel="always"
        >
          <mat-label>Practice</mat-label>
          <mat-select formControlName="practiceRef" placeholder="All Practices">
            <mat-option [value]="undefined">All Practices</mat-option>
            @for (practice of data.practices; track practice.ref.path) {
              <mat-option [value]="practice.ref">
                {{ practice.name }}
              </mat-option>
            }
          </mat-select>

          <button
            mat-icon-button
            matIconSuffix
            (click)="
              $event.stopImmediatePropagation();
              form.controls.practiceRef.reset(undefined)
            "
            *ngIf="form.controls.practiceRef.value"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      }
    </div>

    <h2 class="!m-0 !mt-4">When do you want this to run?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Timing</h3>
      <pr-automation-timing-form
        [value]="form.controls.timing.value"
        (formChange)="timingChange($event)"
      />
    </div>

    @if (!noScope && availableConditions.length) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <div class="flex flex-row items-center gap-2">
          <h3 class="!m-0 !font-bold">Automation Conditions</h3>
          <mat-icon
            class="icon-sm opacity-50"
            [matTooltip]="automationConditionsHelp"
            >info</mat-icon
          >
        </div>

        <pr-conditional-logic-form
          [availableConditions]="availableConditions"
          [defaultCondition]="defaultCondition"
          [conditions]="form.value.requiredConditions"
          (conditionsUpdated)="requiredConditionsChange($event)"
        />
      </div>
    }

    <h2 class="!m-0 !mt-4">What do you want to happen?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Action</h3>

      <div class="flex flex-col gap-4">
        <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
          <mat-label>Action</mat-label>
          <mat-select formControlName="type">
            @for (type of formHelpers.types; track type.value) {
              <mat-option [value]="type.value">
                {{ type.label }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (form.showSubjectField()) {
          <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject" />
          </mat-form-field>
        }
      </div>
    </div>
    <pr-template-editor-form
      [template]="template$ | async"
      [templateType]="templateType$ | async"
      [isSystemTemplate]="false"
      [isAutomationTemplate]="true"
      [context]="mockContext$ | async"
      [hideNameField]="true"
      (templateChange)="templateChange($event)"
    />

    <pr-automation-treatments *ngIf="data.config" [config]="data.config" />
  </form>
</div>
<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      Submit
    </button>
  </pt-buttons-container>
</pt-page-footer>
