import { IIntegration, IntegrationType } from '@principle-theorem/integrations';
import { DocumentReference } from '@principle-theorem/shared';
import { IPractice } from '../practice/practice';

export function isPodiumIntegration(
  item: IIntegration
): item is IIntegration<IPodiumIntegrationData> {
  return item.type === IntegrationType.Podium;
}

export interface IPodiumLocationMap {
  locationUid: string;
  practiceRef: DocumentReference<IPractice>;
}

export interface IPodiumIntegrationData {
  oauth?: {
    scope: string[];
    refreshToken: string;
    accessToken: string;
  };
  webhook?: {
    uid: string;
    secret: string;
  };
  practiceLocations?: IPodiumLocationMap[];
}

export interface IPodiumLinkOptions {
  scopes: string[];
  oauthApiUrl: string;
  clientId: string;
  temporaryTokenUid: string;
}

export const PODIUM_SCOPES = [
  'read_locations',
  'read_messages',
  'write_messages',
  'read_templates',
];
