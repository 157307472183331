import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartedTreatmentUpdater,
  TreatmentStep,
  getAppointmentStepStatus,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedTreatment,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentStep,
  type PlanStepPairStatus,
} from '@principle-theorem/principle-core/interfaces';
import { doc$, snapshot, type WithRef } from '@principle-theorem/shared';
import { filter } from 'lodash';
import { ReplaySubject, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IEditChartableData } from '../../chartable-surface-updater';

@Component({
  selector: 'pr-treatment-step',
  templateUrl: './treatment-step.component.html',
  styleUrls: ['./treatment-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentStepComponent {
  step$ = new ReplaySubject<
    WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep
  >(1);
  plan$ = new ReplaySubject<
    IChartedMultiStepTreatment | WithRef<ITreatmentPlan>
  >(1);
  treatments$: Observable<IChartedTreatment[]>;
  appointment$: Observable<WithRef<IAppointment> | undefined>;
  status$: Observable<PlanStepPairStatus>;
  @Input() inlcudeMultiTreatments = true;
  @Input() hideDelete = false;
  @Output()
  stepChanged = new EventEmitter<
    Partial<IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>>
  >();
  @Output()
  stepDeleted = new EventEmitter<ITreatmentStep>();
  isComplete$: Observable<boolean>;
  @Output() updateChartable = new EventEmitter<IEditChartableData>();
  expanded = false;

  @Input()
  set step(step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep) {
    if (step) {
      this.step$.next(step);
    }
  }

  @Input()
  set plan(plan: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  constructor(private _snackBar: MatSnackBar) {
    this.isComplete$ = this.step$.pipe(
      map((step) => TreatmentStep.isComplete(step))
    );

    this.treatments$ = this.step$.pipe(map((step) => step.treatments));

    this.appointment$ = this.step$.pipe(
      switchMap((step) =>
        step.appointment ? doc$(step.appointment) : of(undefined)
      )
    );

    this.status$ = this.appointment$.pipe(
      map((appointment) => getAppointmentStepStatus(appointment))
    );
  }

  async updateTreatments(treatments: IChartedTreatment[]): Promise<void> {
    this.stepChanged.emit({
      treatments: await ChartedTreatmentUpdater.syncPricingRules(treatments),
    });
  }

  async deleteTreatment(treatment: IChartedTreatment): Promise<void> {
    const step = await snapshot(this.step$);
    const treatments = await ChartedTreatmentUpdater.syncPricingRules(
      filter(
        step.treatments,
        (stepTreatment) => stepTreatment.uuid !== treatment.uuid
      )
    );

    this.stepChanged.emit({ treatments });
    this._snackBar.open(`Treatment removed`);
  }

  async updateStepDisplayOverride(
    selectedOverride: WithRef<ITreatmentCategory>
  ): Promise<void> {
    const treatmentStep = await snapshot(this.step$);
    const display = TreatmentStep.updateDisplayOverrideCategory(
      treatmentStep,
      selectedOverride.ref
    );

    if (!display) {
      return;
    }

    this.stepChanged.emit({ display });
  }
}
