import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { getTimePeriodStartEnd, toTimestamp } from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type IReportRequest } from '../practitioner-transactions-report/practitioner-transactions-report.component';
import { PractitionerIncomeReportFacade } from '../store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../store/practitioner-income.reducers';

@Component({
    selector: 'pr-practitioner-income-table',
    templateUrl: './practitioner-income-table.component.html',
    styleUrls: ['./practitioner-income-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerIncomeTableComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dataPointInRangeDescription$: Observable<string>;
  request$ = new ReplaySubject<IReportRequest>(1);

  @Input()
  set request(request: IReportRequest) {
    if (request) {
      this.request$.next(request);
    }
  }

  constructor(public reportFacade: PractitionerIncomeReportFacade) {
    this.request$
      .pipe(
        map((request) => ({
          practice: first(request.practices),
          dateRange: request.dateRange,
        })),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ practice, dateRange }) => {
        if (!practice) {
          return;
        }

        const timePeriod = getTimePeriodStartEnd(
          dateRange,
          practice.settings.timezone
        );
        this.reportFacade.setQuery({
          startDate: toTimestamp(timePeriod.from),
          endDate: toTimestamp(timePeriod.to),
          practiceRef: practice.ref,
        });
      });

    this.dataPointInRangeDescription$ = this.reportFacade.reportType$.pipe(
      map((reportType) =>
        reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate
          ? 'created'
          : 'paid'
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
