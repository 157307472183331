import { type IFeature } from '@principle-theorem/feature-flags';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { cloneDeep } from 'lodash';
import { SidebarBadges } from './sidebar-badges';
import {
  type IRouteParams,
  type ISidebarItem,
  SidebarItem,
} from './sidebar-item';
import { type Observable } from 'rxjs';

export interface ISideBarItemScope {
  brand?: WithRef<IBrand>;
  practice?: WithRef<IPractice>;
  staffer?: WithRef<IStaffer>;
  userPractices$?: Observable<WithRef<IPractice>[]>;
}

export class SidebarItems {
  constructor(private _config: ISidebarItem[]) {}

  get(scope: ISideBarItemScope = {}): SidebarItem[] {
    const params: IRouteParams = {
      brandSlug: scope.brand ? scope.brand.slug : '',
      practiceSlug: scope.practice ? scope.practice.slug : '',
    };
    return this._loadItems(cloneDeep(this._config), params, scope);
  }

  private _loadItems(
    items: ISidebarItem[],
    params: IRouteParams,
    scope: ISideBarItemScope,
    parentFeature?: IFeature
  ): SidebarItem[] {
    return items.map((item: ISidebarItem) => {
      const badges = new SidebarBadges(scope);
      const newItem: SidebarItem = new SidebarItem(item, params, badges);
      if (!newItem.feature) {
        newItem.feature = parentFeature;
      }
      if (item.children) {
        newItem.children = this._loadItems(
          item.children,
          params,
          scope,
          newItem.feature
        );
      }
      return newItem;
    });
  }
}
