import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  type IFilterOption,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type ITreatmentPlan,
  type ITreatmentPlanWithPatient,
  TreatmentPlanStatus,
  TREATMENT_PLAN_STATUSES,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  createGroupMap,
  type WithRef,
  HISTORY_DATE_FORMAT,
  multiFilter,
  getEnumValues,
  isSameRef,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const treatmentPlanStatusFilterOptions: IFilterOption<
  TreatmentPlanStatus,
  ITreatmentPlan
>[] = getEnumValues(TreatmentPlanStatus).map((status) => ({
  id: status,
  label: status,
  filter: (plan) => plan.status === status,
}));

@Component({
  selector: 'pr-treatment-plan-list',
  templateUrl: './treatment-plan-list.component.html',
  styleUrls: ['./treatment-plan-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanListComponent {
  treatmentPlans$ = new ReplaySubject<ITreatmentPlanWithPatient[]>(1);
  trackByPlan = TrackByFunctions.ref<ITreatmentPlanWithPatient>('plan.ref');
  trackByStatus = TrackByFunctions.variable<TreatmentPlanStatus>();
  treatmentPlansStatusAggregate$: Observable<
    Pick<
      Record<TreatmentPlanStatus, ITreatmentPlanWithPatient[]>,
      TreatmentPlanStatus
    >
  >;
  readonly dateFormat = HISTORY_DATE_FORMAT;
  statusFilters = treatmentPlanStatusFilterOptions;
  activeStatuses$ = new BehaviorSubject<TreatmentPlanStatus[]>([
    TreatmentPlanStatus.Draft,
    TreatmentPlanStatus.InProgress,
    TreatmentPlanStatus.Offered,
    TreatmentPlanStatus.Accepted,
  ]);
  // includeConsultationsCtrl = new TypedFormControl<boolean>(false);
  planStatuses = TREATMENT_PLAN_STATUSES;

  @Output() selectPlan = new EventEmitter<WithRef<ITreatmentPlan>>();
  @Output() createPlan = new EventEmitter<void>();

  @Input() selectedPlan: DocumentReference<ITreatmentPlan>;
  @Input() todaysPlan: DocumentReference<ITreatmentPlan>;

  @Input()
  set treatmentPlans(plans: ITreatmentPlanWithPatient[]) {
    if (plans) {
      this.treatmentPlans$.next(plans);
    }
  }

  constructor() {
    this.treatmentPlansStatusAggregate$ = this.treatmentPlans$.pipe(
      map((treatmentPlans) => this._toAggregate(treatmentPlans)),
      switchMap((treatmentPlansAggregate) =>
        this.activeStatuses$.pipe(
          map((activeStatuses) => pick(treatmentPlansAggregate, activeStatuses))
        )
      )
    );
  }

  isTodaysPlan(plan: WithRef<ITreatmentPlan>): boolean {
    return this.todaysPlan ? isSameRef(plan, this.todaysPlan) : false;
  }

  isSelectedPlan(plan: WithRef<ITreatmentPlan>): boolean {
    return this.selectedPlan ? isSameRef(plan, this.selectedPlan) : false;
  }

  price$(pair: ITreatmentPlanWithPatient): Observable<number> {
    return TreatmentPlan.price$(pair.plan);
  }

  progress$(pair: ITreatmentPlanWithPatient): Observable<string> {
    const totalSteps$ = TreatmentPlan.orderedSteps$(pair.plan);
    const completedSteps$ = totalSteps$.pipe(
      multiFilter((step) => TreatmentStep.isComplete(step))
    );
    return combineLatest([totalSteps$, completedSteps$]).pipe(
      map(([total, completed]) => `${completed.length}/${total.length}`)
    );
  }

  setActiveStatus(statuses: TreatmentPlanStatus[]): void {
    this.activeStatuses$.next(statuses);
  }

  private _toAggregate(
    pairs: ITreatmentPlanWithPatient[]
  ): Record<TreatmentPlanStatus, ITreatmentPlanWithPatient[]> {
    return createGroupMap(
      pairs,
      (item) => item.plan.status,
      (item, currentKey) => {
        if (String(item.plan.status) === String(currentKey)) {
          return item;
        }
      }
    );
  }
}
