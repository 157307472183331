import { Validators } from '@angular/forms';
import { RelationshipFormGroup } from '@principle-theorem/ng-patient';
import { ContactNumberFormGroup } from '@principle-theorem/ng-principle-shared';
import {
  type ISetOptionParams,
  SplitCamelPipe,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type Gender,
  type IContactNumber,
  type IDVACard,
  type IFeeSchedule,
  type IMedicareCard,
  type IPatient,
  type IPatientRelationship,
  type IReferralSource,
  isPatientRelationship,
  isPatientWithPrimaryContact,
  type IStaffer,
  IPatientDetails,
  patientHasEmailOrContactNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isISODateType,
  type ISODateType,
} from '@principle-theorem/shared';
import { upperFirst } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  type Subject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

const CONTACT_ERROR_MESSAGE =
  'At least Email or Contact Number is required if patient has no primary contact';

export class PatientDetailsFormGroup extends TypedFormGroup<IPatientDetails> {
  contactDetailsRequired$ = new BehaviorSubject<boolean>(true);

  constructor(private _onDestroy$: Subject<void>) {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      dateOfBirth: new TypedFormControl<ISODateType>(undefined).withGuard(
        isISODateType
      ),
      email: new TypedFormControl<string>('', Validators.email),
      contactNumbers: new TypedFormArray<IContactNumber>([
        new ContactNumberFormGroup(false),
      ]),
      gender: new TypedFormControl<Gender>(undefined, Validators.required),
      referrer: new TypedFormControl<IReferralSource>(undefined),
      primaryContact: new RelationshipFormGroup(),
      preferredFeeSchedule: new TypedFormControl<INamedDocument<IFeeSchedule>>(
        undefined
      ),
      medicareCard: new TypedFormControl<IMedicareCard>(),
      dvaCard: new TypedFormControl<IDVACard>(),
      preferredHygienist: new TypedFormControl<INamedDocument<IStaffer>>(),
      preferredDentist: new TypedFormControl<INamedDocument<IStaffer>>(),
      relationships: new TypedFormArray<IPatientRelationship>([
        new RelationshipFormGroup(),
      ]),
    });

    this.controls.primaryContact.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (!value || !isPatientRelationship(value)) {
          this.controls.primaryContact.clearValidators();
          this.contactDetailsRequired$.next(true);
          this.updateValueAndValidity();
          return;
        }

        this.controls.primaryContact.setValidators([Validators.required]);
        this.contactDetailsRequired$.next(false);
        this.updateValueAndValidity();
      });
  }

  errorMessage$(): Observable<string> {
    return combineLatest([
      this.valueChanges,
      this.contactDetailsRequired$,
    ]).pipe(
      map(([value, contactDetailsRequired]) =>
        this.getErrorMessage(value, contactDetailsRequired)
      )
    );
  }

  getErrorMessage(
    currentValue: IPatientDetails,
    contactDetailsRequired: boolean
  ): string {
    if (
      contactDetailsRequired &&
      !patientHasEmailOrContactNumber(currentValue)
    ) {
      return CONTACT_ERROR_MESSAGE;
    }
    const keys: string[] = this._getRequiredErrorKeys();
    if (!keys.length) {
      return '';
    }

    const splitCamelPipe: SplitCamelPipe = new SplitCamelPipe();
    const required: string = keys
      .map((key: string) => upperFirst(key))
      .join(', ');
    const transformed: string = splitCamelPipe.transform(required);
    let errorMessage = `${transformed} is required`;
    if (keys.length > 1) {
      errorMessage = `${transformed} are required`;
    }
    return errorMessage;
  }

  patchPatient(
    patient: IPatient | IPatientDetails,
    options?: ISetOptionParams
  ): void {
    this.patchValue(
      {
        name: patient.name,
        dateOfBirth: patient.dateOfBirth,
        email: patient.email,
        contactNumbers: patient.contactNumbers,
        gender: patient.gender,
        referrer: patient.referrer,
        preferredFeeSchedule: patient.preferredFeeSchedule,
      },
      options
    );

    if (patient.relationships.length > 0) {
      this.addRelationshipControls(patient.relationships.length);
      this.controls.relationships.patchValue(patient.relationships, {
        emitEvent: false,
      });
    }

    if (isPatientWithPrimaryContact(patient)) {
      this.controls.primaryContact.setValue(patient.primaryContact);
    }
    this.markAllAsTouched();
  }

  addRelationshipControls(quantity: number = 1): void {
    const relationships = this.controls
      .relationships as TypedFormArray<IPatientRelationship>;
    for (let index = 0; index < quantity; index++) {
      relationships.push(new RelationshipFormGroup());
    }
  }

  private _getRequiredErrorKeys(): string[] {
    const keys: string[] = [];
    for (const key in this.controls) {
      if (this.controls[key as keyof IPatientDetails].hasError('required')) {
        keys.push(key);
      }
    }
    return keys;
  }
}
