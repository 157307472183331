import { Validators } from '@angular/forms';
import { MixedSchema, initVersionedSchema } from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { type INamedDocument } from '@principle-theorem/shared';

export interface INoteFormData {
  content: MixedSchema;
  tags: INamedDocument<ITag>[];
}

export class NoteFormGroup extends TypedFormGroup<INoteFormData> {
  constructor() {
    super({
      content: new TypedFormControl<MixedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
      tags: new TypedFormControl<INamedDocument<ITag>[]>([]),
    });
  }

  override reset(): void {
    super.reset(
      { content: initVersionedSchema(), tags: [] },
      { emitEvent: false }
    );
  }
}
