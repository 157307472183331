<div class="login-container flex h-full w-full flex-col">
  <pr-navbar />
  <div class="mx-auto my-auto">
    <mat-card appearance="outlined">
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  </div>
</div>
