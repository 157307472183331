<ng-container *ngIf="customReport$ | async as customReport">
  <pt-feature-info />
  <pt-breadcrumbs [breadcrumbs]="breadcrumbs" [current]="customReport.name" />

  <div class="layout-padding">
    @if (isRetired$ | async) {
      <pr-information-box>
        This data source has been retired. We advise against using this report
        as the data source is no longer maintained. Please contact us to find
        alternate ways of reporting on this data.

        <button mat-stroked-button class="shrink-0" (click)="intercom.open()">
          Contact Us
        </button>
      </pr-information-box>
    }

    @if (store.editMode$ | async) {
      <h2>Edit Custom Report</h2>
      <pr-custom-report-form [customReport]="customReport" />

      <div
        class="layout-margin"
        *ngIf="customReport.restrictAccessTo.length > 0"
      >
        <h3>Access Restricted To</h3>
        <div>
          <pr-query-filter-display
            *ngFor="
              let accessEntity of customReport.restrictAccessTo;
              trackBy: trackByAccessEntity
            "
            [label]="accessEntity.type | titlecase"
            [values]="accessEntity.name"
          />
        </div>
      </div>

      <pr-pre-selected-filters
        class="layout-margin"
        [customReport]="customReport"
      />
    } @else {
      <div class="layout-margin flex items-center justify-between gap-4">
        <h1>{{ customReport.name }}</h1>
        <div class="flex gap-2" *prHasPermission="editCustomReportPermissions">
          <button mat-stroked-button (click)="edit()">Edit</button>
          <button mat-stroked-button color="warn" (click)="delete()">
            Delete
          </button>
        </div>
      </div>

      <p class="layout-margin" *ngIf="customReport.description">
        {{ customReport.description }}
      </p>
      <div
        class="layout-margin"
        *ngIf="customReport.restrictAccessTo.length > 0"
      >
        <h3>Access Restricted To</h3>
        <div>
          <pr-query-filter-display
            *ngFor="
              let accessEntity of customReport.restrictAccessTo;
              trackBy: trackByAccessEntity
            "
            [label]="accessEntity.type | titlecase"
            [values]="accessEntity.name"
          />
        </div>
      </div>

      <pr-pre-selected-filters
        class="layout-margin"
        [customReport]="customReport"
      />

      <div class="flex flex-col gap-8">
        <pr-run-custom-report-form
          [customReport]="customReport"
          (runReport)="runReport($event)"
          (dateRangeChange)="dateRange.set($event)"
        />
      </div>
    }

    <pr-report-builder-loader>
      <div class="flex flex-col gap-8">
        <pr-report-builder-chart-filters />
        <pr-report-builder-results-table />
      </div>
    </pr-report-builder-loader>
  </div>
</ng-container>

@if (store.editMode$ | async) {
  <pr-custom-report-save-bar />
}
