<ng-container *ngrxLet="chat$ as chat">
  <div class="flex items-center">
    <div *ngIf="multiSelectEnabled$ | async" (click)="$event.stopPropagation()">
      <mat-checkbox
        color="primary"
        [checked]="
          chat | map: selectionList.isSelected$ : selectionList | async
        "
        (change)="selectionList.toggleSelected(chat)"
      />
    </div>

    <div *ngIf="participants$ | async as participants" class="with-group">
      @if (!participants.length) {
        <pt-user-icon
          *ngIf="staffer$ | async as staffer"
          [src]="staffer.ref | map: stafferImage$ : this | async"
          [name]="staffer.ref | map: stafferName$ : this | async"
          [diameter]="46"
        />
      } @else {
        @for (
          participant of participants$ | async;
          track participant.id;
          let index = $index;
          let count = $count
        ) {
          @if (count === 1) {
            <pt-user-icon
              [src]="participant | map: stafferImage$ : this | async"
              [name]="participant | map: stafferName$ : this | async"
              [diameter]="46"
            />
          } @else {
            <pt-user-icon
              class="participant-icon-{{ index + 1 }}"
              *ngIf="index < 2"
              [diameter]="32"
              [src]="participant | map: stafferImage$ : this | async"
              [name]="participant | map: stafferName$ : this | async"
            />
          }
        }
      }
    </div>

    <div class="menu-content flex flex-1 flex-col">
      <div class="flex min-w-0 items-end gap-1.5">
        <h3 class="mat-subtitle-1 chat-name min-w-0 truncate">
          {{ chatLabel$ | async }}
        </h3>
      </div>
      <div
        *ngrxLet="lastMessage$ as lastMessage"
        class="last-message"
        [ngClass]="{ unread: (actions.isRead$ | async) === false }"
      >
        @if (lastMessage) {
          {{ lastMessage | map: getTextContent }}
        }
      </div>
      <div *ngIf="lastMessageAt$ | async as lastMessageAt" class="message-time">
        {{ lastMessageAt | moment | amTimeAgo }}
      </div>
    </div>
    <div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
        <div
          *ngrxLet="chat$ as chat"
          [ngClass]="{
            'has-notification': (actions.isRead$ | async) === false
          }"
        ></div>
      </button>
      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <button
            *ngIf="actions.canMarkUnread$ | async"
            mat-menu-item
            color="warn"
            (click)="actions.markUnread()"
          >
            <mat-icon>mark_chat_unread</mat-icon>
            Mark as unread
          </button>
          <button
            *ngIf="actions.isOpen$ | async"
            mat-menu-item
            color="warn"
            (click)="actions.archiveChat()"
          >
            <mat-icon>archive</mat-icon>
            Archive chat
          </button>
          <button
            *ngIf="(actions.isOpen$ | async) === false"
            mat-menu-item
            color="warn"
            (click)="actions.reopenChat()"
          >
            <mat-icon>unarchive</mat-icon>
            Re-open chat
          </button>
          <button
            mat-menu-item
            *ngIf="(actions.isMuted$ | async) === false"
            (click)="actions.muteChat()"
          >
            <mat-icon>volume_off</mat-icon>
            <span>Mute Chat</span>
          </button>
          <button
            mat-menu-item
            *ngIf="actions.isMuted$ | async"
            (click)="actions.unmuteChat()"
          >
            <mat-icon>volume_up</mat-icon>
            <span>Unmute Chat</span>
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</ng-container>
