import { createSelector } from '@ngrx/store';
import { getChartModuleState } from '../../selectors';
import { treatmentPlansAdapter } from '../../reducers/treatment-plans/treatment-plans.reducer';

const { selectAll, selectEntities } = treatmentPlansAdapter.getSelectors();

export const selectTreatmentPlansState = createSelector(
  getChartModuleState,
  (chart) => chart.treatmentPlanning.treatmentPlans
);

export const getTreatmentPlans = createSelector(
  selectTreatmentPlansState,
  (state) => selectAll(state)
);

const getSelectedTreatmentPlanId = createSelector(
  selectTreatmentPlansState,
  (state) => state.selectedId
);

export const getTreatmentPlanEntities = createSelector(
  selectTreatmentPlansState,
  (state) => selectEntities(state)
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTreatmentPlanById = (id: string) =>
  createSelector(getTreatmentPlanEntities, (entities) => entities[id]);

export const getSelectedTreatmentPlan = createSelector(
  getTreatmentPlanEntities,
  getSelectedTreatmentPlanId,
  (entities, uid) => (uid ? entities[uid] : undefined)
);

export const getSelectedTreatmentPlansStepIds = createSelector(
  getSelectedTreatmentPlan,
  (plan) => (plan ? plan.steps.map((step) => step.id) : [])
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTreatmentStepIds = (planId: string) =>
  createSelector(getTreatmentPlanById(planId), (plan) =>
    plan ? plan.steps.map((step) => step.id) : []
  );
