import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MixedSchema,
  getMixedContent,
  initVersionedSchema,
  isMixedSchema,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import { AnyExtension } from '@tiptap/core';
import { ReplaySubject } from 'rxjs';
import { EditorPresetsService } from '../editor-presets.service';

@Component({
  selector: 'pr-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentViewComponent {
  extensions: AnyExtension[] = [];
  content$ = new ReplaySubject<MixedSchema>(1);

  constructor(editorPresets: EditorPresetsService) {
    this.extensions = editorPresets.defaultExtensions();
  }

  @Input()
  set content(content: MixedSchema | RawInlineNodes) {
    if (content) {
      this.content$.next(
        isMixedSchema(content)
          ? getMixedContent(content)
          : initVersionedSchema(content)
      );
    }
  }
}
