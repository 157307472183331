import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  type OnDestroy,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, Validators } from '@angular/forms';
import {
  TypedFormControl,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  AnyDynamicFormControl,
  DynamicFormType,
  IDynamicFormNumber,
  IDynamicFormSelect,
  type IDynamicForm,
} from '@principle-theorem/principle-core/interfaces';
import { shareReplayCold } from '@principle-theorem/shared';
import { isNull, keys, toPairs } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface IDynamicField<
  V = unknown,
  T extends AnyDynamicFormControl<V> = AnyDynamicFormControl<V>,
> {
  key: string;
  data: T;
  ctrl: TypedFormControl<V>;
}

@Component({
    selector: 'pr-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DynamicFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dynamicForm$ = new ReplaySubject<IDynamicForm>(1);
  fields: Signal<IDynamicField[] | undefined>;
  formGroup = new FormGroup({});
  @Output() formChange = new EventEmitter<unknown>();

  @Input()
  set dynamicForm(dynamicForm: IDynamicForm) {
    if (dynamicForm) {
      this.dynamicForm$.next(dynamicForm);
    }
  }

  constructor() {
    const fields$ = this.dynamicForm$.pipe(
      map((dynamicForm) => this._getFields(dynamicForm)),
      shareReplayCold()
    );
    fields$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((dynamicForm) => this._updateForm(dynamicForm));

    this.fields = toSignal(fields$);

    validFormGroupChanges$(this.formGroup)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.formChange.emit(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isSelectField(
    field: IDynamicField<unknown>
  ): field is IDynamicField<unknown, IDynamicFormSelect<unknown>> {
    return field.data.type === DynamicFormType.Select;
  }

  isNumberField(
    field: IDynamicField<unknown>
  ): field is IDynamicField<unknown, IDynamicFormNumber<number | undefined>> {
    return field.data.type === DynamicFormType.Number;
  }

  isRequired(data: AnyDynamicFormControl<unknown>): boolean {
    if (data.isRequired) {
      return true;
    }
    if (data.type === DynamicFormType.Select) {
      return data.options.every((option) => option.value !== undefined);
    }
    return false;
  }

  floatLabel(field: IDynamicField<unknown>): boolean {
    return this.isSelectField(field) && !this.isRequired(field.data);
  }

  placeholder(field: IDynamicField<unknown>): string | undefined {
    if (this.isSelectField(field) && !this.isRequired(field.data)) {
      const noneOption = field.data.options.find(
        (option) => option.value === undefined
      );
      return noneOption?.label;
    }
    return;
  }

  showClearButton(field: IDynamicField<unknown>): boolean {
    return !this.isSelectField(field) && !this.isRequired(field.data);
  }

  hasValue(value: unknown): boolean {
    return !isNull(value) && value !== undefined;
  }

  isEmpty(value: unknown): boolean {
    return isNull(value) || value === undefined;
  }

  clearField(event: Event, field: IDynamicField<unknown>): void {
    event.preventDefault();
    field.ctrl.reset(undefined);
  }

  private _getFields(dynamicForm: IDynamicForm): IDynamicField[] {
    return toPairs(dynamicForm).map(([key, data]) => {
      const validators = this.isRequired(data) ? [Validators.required] : [];
      const ctrl = new TypedFormControl<unknown>(data.initialValue, validators);
      return { key, data, ctrl };
    });
  }

  private _updateForm(fields: IDynamicField[]): void {
    keys(this.formGroup.controls).map((key) =>
      this.formGroup.removeControl(key, { emitEvent: false })
    );
    fields.map((field) =>
      this.formGroup.addControl(field.key, field.ctrl, { emitEvent: false })
    );
  }
}
