import {
  IMeasureMetadata,
  PaymentPlanAction,
  PaymentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IPaymentPlanEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PaymentPlanDimensionMeasures } from '../dimensions/payment-plan-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { ValueEqualsMeasureFilter } from '../measure-filters';
import {
  ComparableProperties,
  IMeasure,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { QueryFactory } from '../query-factory';

export class PaymentPlanEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<IPaymentPlanEventFact>>
{
  id = 'paymentPlanEvent';
  readonly table = BigQueryTable.PaymentPlanEvent;
  readonly name = 'Payment Plans';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get paymentPlan(): PaymentPlanDimensionMeasures {
    return new PaymentPlanDimensionMeasures(this.table, 'paymentPlan');
  }

  get event(): ModelEventDimensionMeasures<
    PaymentPlanAction,
    PaymentPlanStatus
  > {
    return new ModelEventDimensionMeasures(this.table, 'event', 'Payment Plan');
  }

  get latestEvent(): PaymentPlanEventFactMeasures {
    const measures = new PaymentPlanEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.PaymentPlan)
      .filterSoftDeleted(measures.paymentPlan.measureRef('.deleted'))
      .get();
    return measures;
  }

  get active(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'active',
      label: 'Active Payment Plans',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(PaymentPlanStatus.Active)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }
}
