import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { deleteDoc, unserialise$ } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { AppointmentRequestActions, SchedulingActions } from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';
import { cancelAction } from '@principle-theorem/ng-shared';

@Injectable()
export class AppointmentRequestEffects {
  private _actions$ = inject(Actions);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  completeRequest$ = createEffect(() => this._completeRequest$());
  cancelRequest$ = createEffect(() => this._cancelAppointmentRequest$(), {
    dispatch: false,
  });

  private _completeRequest$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.saveNewAppointmentSuccess),
      withLatestFrom(this._schedulingFacade.appointmentRequest$),
      concatMap(async ([_, request]) => {
        if (!request) {
          return cancelAction();
        }
        await deleteDoc(request.ref);
        return AppointmentRequestActions.clearAppointmentRequest();
      })
    );
  }

  private _cancelAppointmentRequest$(): Observable<void> {
    return this._actions$.pipe(
      ofType(AppointmentRequestActions.cancelAppointmentRequest),
      unserialise$(),
      concatMap(({ ref }) => deleteDoc(ref))
    );
  }
}
