import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IAutomatedNotificationConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-automated-notification-configuration-list-item',
  templateUrl:
    './automated-notification-configuration-list-item.component.html',
  styleUrls: [
    './automated-notification-configuration-list-item.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomatedNotificationConfigurationListItemComponent {
  notification$ = new ReplaySubject<
    WithRef<IAutomatedNotificationConfiguration>
  >(1);

  @Input()
  set notification(notification: WithRef<IAutomatedNotificationConfiguration>) {
    if (notification) {
      this.notification$.next(notification);
    }
  }
}
