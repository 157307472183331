<ng-container *ngIf="isInline; else buttonStatus">
  <span [class]="status" [ngClass]="{ dense: isDense }">{{
    status | titlecase
  }}</span>
</ng-container>
<ng-template #buttonStatus>
  <pr-status-label
    [label]="status"
    [color]="statusColourMap[status]"
    [dense]="isDense"
   />
</ng-template>
