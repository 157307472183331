import {
  ChatCollection,
  ChatStatus,
  ChatType,
  IChat,
  IChatMessage,
  IInteractionV2,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  DocumentReference,
  IReffable,
  asyncForEach,
  isSameRef,
  subCollection,
} from '@principle-theorem/shared';
import { OrganisationCache } from '../organisation/organisation-cache';

export class Chat {
  static init(overrides: AtLeast<IChat, 'ownerRef' | 'practiceRef'>): IChat {
    return {
      type: ChatType.Direct,
      status: ChatStatus.Open,
      participants: [overrides.ownerRef],
      deleted: false,
      ...overrides,
    };
  }

  static chatMessageCol(
    chat: IReffable<IChat>
  ): CollectionReference<IChatMessage> {
    return subCollection<IChatMessage>(chat.ref, ChatCollection.Messages);
  }

  static interactionCol(
    chat: IReffable<IChat>
  ): CollectionReference<IInteractionV2> {
    return subCollection<IInteractionV2>(chat.ref, ChatCollection.Interactions);
  }

  static async getTitle(
    chat: IChat,
    currentStaffer: DocumentReference<IStaffer>
  ): Promise<string> {
    if (chat.label) {
      return chat.label;
    }

    const usersToNotify = chat.participants.filter(
      (participant) => !isSameRef(participant, currentStaffer)
    );

    const participants = await asyncForEach(usersToNotify, (participantRef) =>
      OrganisationCache.staff.get.getDoc(participantRef)
    );

    const participantNames = participants.map(
      (participant) => participant.user.name
    );

    return participantNames.join(', ');
  }

  static userHasAccess(
    chat: IChat,
    stafferRef: DocumentReference<IStaffer>
  ): boolean {
    return chat.participants.some((participant) =>
      isSameRef(participant, stafferRef)
    );
  }
}
