import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IAccountCredit,
  IArraySorter,
  IBrand,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IInvoice,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITransaction,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  IIdentifiable,
  INamedDocument,
  Timestamp,
  WithRef,
  safeCombineLatest,
  toTimestamp,
} from '@principle-theorem/shared';
import { BaseDestinationEntity } from '../base-destination-entity';
import { IOasisPatient } from '../../oasis/source/entities/patients';
import { ItemCodeResourceMapType } from '../../mappings/item-codes-to-xlsx';
import { PatientIdFilter } from '../filters/patient-id-filter';
import { DestinationEntity } from '../destination-entity';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';

export const PATIENT_TRANSACTION_RESOURCE_TYPE = 'patientTransaction';
export const PATIENT_INVOICE_RESOURCE_TYPE = 'patientInvoices';

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_INVOICE_RESOURCE_TYPE,
    label: 'Patient Invoices',
    description: ``,
  },
});

export function buildExtendedData(
  payment: IGetRecordResponse<object>,
  provider: TransactionProvider,
  providerSubType?: INamedDocument
): object {
  const extendedData = {
    payment: payment.data.data,
    providerSubType: providerSubType,
  };

  if (provider === TransactionProvider.AccountCredit) {
    return {
      ...extendedData,
      accountCreditsUsed: [],
    };
  }

  return extendedData;
}

export interface IInvoiceUpdateData {
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
  accountCreditRefs: DocumentReference<IAccountCredit>[];
}

export interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: (IIdentifiable & ITransaction)[];
}

export interface IPatientInvoiceMigrationData {
  patientRef: DocumentReference<IPatient>;
  data: IInvoiceBuildData[];
  createdAt: Timestamp;
}

export interface IPatientInvoiceDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord<IOasisPatient>>;
  invoiceData: {
    invoiceRef: DocumentReference<IInvoice>;
    transactionRefs: DocumentReference<ITransaction>[];
  }[];
}

export interface IPatientInvoiceJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
  brand: WithRef<IBrand>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
}

export abstract class BasePatientInvoiceDestinationEntity<
  PatientRecord extends object,
  JobData extends IPatientInvoiceJobData<PatientRecord>,
> extends BaseDestinationEntity<
  IPatientInvoiceDestinationRecord,
  JobData,
  IPatientInvoiceMigrationData
> {
  destinationEntity = PATIENT_INVOICE_DESTINATION_ENTITY;

  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  override sorters: IArraySorter[] = [
    {
      key: 'invoice',
      sortByPath: ['reference', 'issuedAt.timestampValue'],
    },
    {
      key: 'transactions',
      sortByPath: 'reference',
    },
  ];

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  override filters = [
    new PatientIdFilter<JobData>((data) =>
      this.patientSourceEntity
        .getSourceRecordId(data.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientInvoiceDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return safeCombineLatest(
      record.data.invoiceData.map((data) =>
        combineLatest([
          Firestore.getDoc(data.invoiceRef),
          safeCombineLatest(
            data.transactionRefs.map((transactionRef) =>
              Firestore.getDoc(transactionRef)
            )
          ),
        ])
      )
    ).pipe(
      map(([invoiceRef, transactions]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Invoice',
            data: invoiceRef,
          },
        ];

        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transactions',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IPatientInvoiceMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage: errorMessage ?? 'Missing required properties for patient',
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  protected _buildSuccessResponse(
    patient: IGetRecordResponse<IOasisPatient>,
    updateData?: IInvoiceUpdateData[]
  ): IDestinationEntityRecord<IPatientInvoiceDestinationRecord> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref as DocumentReference<
          ISourceEntityRecord<IOasisPatient>
        >,
        invoiceData: updateData ?? [],
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
