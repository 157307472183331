import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IAppointment,
  type ILab,
  type IPatient,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';
import { LabJobManager } from '../../lab-job-manager';
import { type ILabJobFormData } from '../lab-job-form/lab-job.formgroup';

export interface ILabJobCreateData {
  practice: WithRef<IPractice>;
  lab?: WithRef<ILab>;
  patient?: WithRef<IPatient>;
  appointment?: WithRef<IAppointment>;
}

@Component({
  selector: 'pr-lab-job-create',
  templateUrl: './lab-job-create.component.html',
  styleUrls: ['./lab-job-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobCreateComponent {
  saving$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILabJobCreateData,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<LabJobCreateComponent>,
    private _organisation: OrganisationService,
    public dateService: DateService
  ) {}

  async save(data: ILabJobFormData): Promise<void> {
    this.saving$.next(true);
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const labJobManager: LabJobManager = new LabJobManager(
      this.data.practice,
      staffer
    );
    await labJobManager.add(data);
    this._snackBar.open('Lab Job Added');
    this._dialogRef.close();
  }
}
