<mat-toolbar color="accent">Update Health Cards</mat-toolbar>
<div class="layout-margin">
  <div *ngIf="data.medicareCard as new" class="medicare">
    <h3>Do you want to update the patient's Medicare Card?</h3>
    <div fxLayout="row" fxLayoutGap="8px">
      <pr-health-fund-card-preview
        *ngIf="data.patient.medicareCard as old; else noCardOnFile"
      >
        <div class="title">Current</div>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ old.number }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Card Number">
          {{ old.subNumerate }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Expiry Date">
          {{ old.expiryDate | moment | amDateFormat: dateFormat }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>

      <pr-health-fund-card-preview>
        <div class="title">New</div>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ new.number }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Card Number">
          {{ new.subNumerate }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Expiry Date">
          {{ new.expiryDate | moment | amDateFormat: dateFormat }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>
    </div>
  </div>

  <div *ngIf="data.healthFundCard as new" class="healthfund">
    <h3>Do you want to update the patient's Health Fund Card?</h3>
    <div fxLayout="row" fxLayoutGap="8px">
      <pr-health-fund-card-preview
        *ngIf="data.patient.healthFundCard as old; else noCardOnFile"
      >
        <div class="title">Current</div>
        <pr-health-fund-card-preview-item label="Health Fund Name">
          {{ old.fundCode ? old.fundCode : 'None' }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item></pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ old.membershipNumber }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Member Number">
          {{ old.memberNumber }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>

      <pr-health-fund-card-preview>
        <div class="title">New</div>
        <pr-health-fund-card-preview-item label="Health Fund Name">
          {{ new.fundCode ? new.fundCode : 'None' }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item></pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ new.membershipNumber }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Member Number">
          {{ new.memberNumber }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>
    </div>
  </div>

  <div *ngIf="data.dvaCard as new" class="dva">
    <h3>Do you want to update the patient's DVA Card?</h3>
    <div fxLayout="row" fxLayoutGap="8px">
      <pr-health-fund-card-preview
        *ngIf="data.patient.dvaCard as old; else noCardOnFile"
      >
        <div class="title">Current</div>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ old.number }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item></pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Expiry Date">
          {{ old.expiryDate | moment | amDateFormat: dateFormat }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>

      <pr-health-fund-card-preview>
        <div class="title">New</div>
        <pr-health-fund-card-preview-item label="Membership Number">
          {{ new.number }}
        </pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item></pr-health-fund-card-preview-item>
        <pr-health-fund-card-preview-item label="Expiry Date">
          {{ new.expiryDate | moment | amDateFormat: dateFormat }}
        </pr-health-fund-card-preview-item>
      </pr-health-fund-card-preview>
    </div>
  </div>

  <pt-buttons-container>
    <button mat-stroked-button mat-dialog-close>Skip</button>
    <button mat-flat-button color="primary" (click)="updateAll()">
      Update
    </button>
  </pt-buttons-container>
</div>

<ng-template #noCardOnFile>
  <pr-health-fund-card-preview>
    <div class="title">Current</div>
    <pr-health-fund-card-preview-item>
      No card on file
    </pr-health-fund-card-preview-item>
  </pr-health-fund-card-preview>
</ng-template>
