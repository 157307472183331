<form
  class="mx-2 my-4 flex flex-1 flex-col"
  [formGroup]="taskFormGroup"
  *ngIf="task$ | async as task"
>
  <mat-form-field>
    <mat-label>Title</mat-label>
    <pr-editor-input formControlName="title" [required]="true" />
  </mat-form-field>

  <pr-task-assignee [assigneeCtrl]="assignee" />

  <mat-form-field>
    <mat-label>Due Date (optional)</mat-label>
    <input
      matInput
      formControlName="dueDate"
      [matDatepicker]="dueDate"
      (focus)="dueDate.open()"
      (click)="dueDate.open()"
    />
    <mat-datepicker-toggle matIconSuffix [for]="dueDate" />
    <mat-datepicker #dueDate />
    <button
      mat-icon-button
      matIconSuffix
      aria-label="Clear"
      *ngIf="task.dueDate"
      (click)="clearDueDate($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <pr-date-selector
    placeholder="Due Time"
    [interval]="timeInterval"
    [formControl]="dueTime"
    [timeFrom]="openTime$ | async"
    [timeTo]="closeTime$ | async"
  />

  <mat-form-field>
    <mat-label>Priority</mat-label>
    <mat-select formControlName="priority" required>
      <mat-option
        *ngFor="let priority of taskPriorities; trackBy: trackByPriority"
        [value]="priority"
      >
        {{ priority | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <pt-buttons-container>
    <button
      mat-stroked-button
      type="button"
      [disabled]="!taskFormGroup.dirty"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!taskFormGroup.dirty"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</form>
