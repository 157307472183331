import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DEFAULT_FEE_SCHEDULE_NAME,
  type IFeeSchedule,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import { FirebaseFunctionsService } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type IOrganisation,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { FeeSchedule, Organisation } from '@principle-theorem/principle-core';
import { Subscription } from 'rxjs';
import { ADMIN_ROLE } from '../../../components/roles/permission-selector/permission-groups';
import {
  type INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';
import { Region } from '@principle-theorem/shared';

type UserFormData = Pick<IUser, 'name' | 'email'>;

type OrganisationFormData = Pick<IOrganisation, 'name' | 'region'>;

@Component({
    selector: 'pr-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SignUpComponent implements OnDestroy {
  private _orgSub: Subscription = Subscription.EMPTY;
  isProduction: boolean;
  submitting: boolean = false;
  emailSent: boolean = false;
  userForm: TypedFormGroup<UserFormData> = new TypedFormGroup<UserFormData>({
    name: new TypedFormControl<string>('', [Validators.required]),
    email: new TypedFormControl<string>('', [
      Validators.email,
      Validators.required,
    ]),
  });

  organisationForm: TypedFormGroup<OrganisationFormData> =
    new TypedFormGroup<OrganisationFormData>({
      name: new TypedFormControl<string>('', [Validators.required]),
      region: new TypedFormControl<Region>(undefined, [Validators.required]),
    });

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    private _functions: FirebaseFunctionsService,
    private _snackBar: MatSnackBar,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig
  ) {
    this.isProduction = this._config.production;
  }

  ngOnDestroy(): void {
    this._orgSub.unsubscribe();
  }

  get disabled(): boolean {
    return (
      this.submitting || this.userForm.invalid || this.organisationForm.invalid
    );
  }

  async save(): Promise<void> {
    this.submitting = true;
    const organisationData = this.organisationForm.getRawValue();
    const organisation: IOrganisation = Organisation.init(organisationData);

    if (await this._authFunctions.checkOrganisationExists(organisation.slug)) {
      this._snackBar.open(
        'Workspace with this name already exists. Please use a different name'
      );
      this.submitting = false;
      return;
    }

    const user: Pick<IUser, 'name' | 'email'> = this.userForm.getRawValue();
    const defaultSchedule: IFeeSchedule = FeeSchedule.init({
      name: DEFAULT_FEE_SCHEDULE_NAME,
      serviceCodeType: ServiceCodeType.ADA,
    });
    await this._functions.createOrganisation(
      organisation,
      user,
      defaultSchedule,
      ADMIN_ROLE
    );

    this._setEmail(user);

    this._snackBar.open('Setup Complete');
    this.submitting = false;
  }

  private _setEmail(user: Pick<IUser, 'email'>): void {
    localStorage.setItem('emailForSignIn', user.email);
    this.emailSent = true;
  }
}
