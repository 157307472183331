<form [formGroup]="formGroup" fxLayout="column">
  @for (field of fields(); track field.key) {
    @if (field | typeGuard: isSelectField; as selectField) {
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ field.data.label }}</mat-label>
        <mat-select
          [formControlName]="selectField.key"
          [compareWith]="selectField.data.compareWith"
          [multiple]="field.data.isMultiple"
        >
          @for (option of selectField.data.options; track option.label) {
            <mat-option [value]="option.value">
              {{ option.label | splitCamel | titlecase }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  }
</form>
