<div
  class="event-status-info"
  fxLayout="column"
  fxLayoutAlign="start end"
  fxLayoutGap="2px"
>
  <div
    *ngIf="isNotConfirmed$ | async"
    class="event-status-label error"
    matTooltip="Appointment not confirmed"
    [matTooltipDisabled]="!tooltipsEnabled"
  >
    C
  </div>
  <div
    *ngIf="showLabJobBadge$ | async"
    class="event-status-label"
    [ngClass]="{
      error: isLabJobLate$ | async,
      warn: labJobReqired$ | async
    }"
    [matTooltipDisabled]="!tooltipsEnabled"
    [matTooltip]="labJobTooltip$ | async"
  >
    L
  </div>
  <div
    *ngIf="hasArrived$ | async"
    class="event-status-label success"
    matTooltip="Patient has arrived"
    [matTooltipDisabled]="!tooltipsEnabled"
  >
    A
  </div>
</div>
