import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toTextContent } from '@principle-theorem/editor';
import { ManageCalendarEventService } from '@principle-theorem/ng-calendar';
import {
  BasicDialogService,
  isDisabled$,
  TrackByFunctions,
  type TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  CalendarEvent,
  Event,
  Practice,
  stafferToNamedDoc,
  stafferToParticipant,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  EventType,
  type ICalendarEvent,
  type IEvent,
  type IPractice,
  type IScheduleRange,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  HISTORY_DATE_FORMAT,
  isWithRef,
  mergeDayAndTime,
  type Time24hrType,
  to24hrTime,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';

export interface IEditStafferRosterDayData {
  day: Moment;
  staffer: WithRef<IStaffer>;
  practice: WithRef<IPractice>;
  events$: Observable<(ICalendarEvent | WithRef<ICalendarEvent>)[]>;
}

@Component({
    selector: 'pr-edit-staffer-roster-day-dialog',
    templateUrl: './edit-staffer-roster-day-dialog.component.html',
    styleUrls: ['./edit-staffer-roster-day-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditStafferRosterDayDialogComponent {
  trackByEvent = TrackByFunctions.index<
    ICalendarEvent | WithRef<ICalendarEvent>
  >();
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditStafferRosterDayData,
    private _manager: ManageCalendarEventService,
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar
  ) {}

  async eventTime(
    event: IEvent,
    field: keyof Pick<IEvent, 'from' | 'to'>
  ): Promise<Time24hrType> {
    const timezone = await TimezoneResolver.fromEvent(event);
    return to24hrTime(toMomentTz(event[field], timezone));
  }

  async add(): Promise<void> {
    const staffer = stafferToNamedDoc(this.data.staffer);
    await this._manager.add(
      CalendarEvent.init({
        title: [toTextContent('Rostered On')],
        isBlocking: false,
        event: Event.init({
          from: toTimestamp(
            mergeDayAndTime(
              this.data.day,
              Practice.openTime(this.data.practice)
            )
          ),
          to: toTimestamp(
            mergeDayAndTime(
              this.data.day,
              Practice.closeTime(this.data.practice)
            )
          ),
          practice: this.data.practice,
          participants: [stafferToParticipant(staffer)],
          organiser: staffer,
          creator: staffer,
          type: EventType.RosteredOn,
        }),
      })
    );
  }

  async delete(event: ICalendarEvent | WithRef<ICalendarEvent>): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt: 'Are you sure you want to delete this roster?',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    if (isWithRef<ICalendarEvent>(event)) {
      await deleteDoc(event.ref);
      return;
    }

    if (!event.scheduleRef) {
      return;
    }

    await this._manager.removeOccurenceFromSchedule(
      event.scheduleRef,
      toTimestamp(this.data.day)
    );
  }

  async updateEvent(
    calendarEvent: ICalendarEvent | WithRef<ICalendarEvent>,
    form: TypedFormGroup<IScheduleRange>
  ): Promise<void> {
    if (!form.valid) {
      return;
    }

    const time = form.getRawValue();
    await this._manager.update({
      ...calendarEvent,
      event: {
        ...calendarEvent.event,
        from: toTimestamp(mergeDayAndTime(this.data.day, time.from)),
        to: toTimestamp(mergeDayAndTime(this.data.day, time.to)),
      },
    });

    form.markAsPristine();
    form.updateValueAndValidity();

    this._snackBar.open('Roster updated');
  }

  isDisabled$(form: TypedFormGroup<IScheduleRange>): Observable<boolean> {
    return isDisabled$(form);
  }
}
