@if (store.form$ | async; as patientForm) {
  @if (store.relatedForms$ | async; as relatedForms) {
    <pr-patient-form-selector
      class="flex grow"
      [forms]="relatedForms"
      [selected]="patientForm"
      (formSelected)="formSelected.emit($event)"
    />
  }
}
