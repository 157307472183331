@if (appointment$ | async; as appointment) {
  <pr-timeline-appointment-tooltip [appointment]="appointment" />
} @else {
  <div class="layout-padding">
    <pr-generic-timeline-tooltip [eventable]="eventable$ | async" />
  </div>
}

<pr-timeline-gap-tooltip
  *ngIf="pendingGap$ | async as pendingGap"
  [pendingGap]="pendingGap"
/>
