/**
 * TODO: Extend ICanHaveInvoiceModifiers and implement logic to handle modifiers
 * Examples:
 *  - PercentDiscount
 *  - FlatDiscount
 *  - Tax
 *  - Shipping
 */

export enum InvoiceModifierType {
  Flat = 'flat',
  Percent = 'percent',
}

export interface IInvoiceModifier {
  description: string;
  type: InvoiceModifierType;
  value: number;
}

export interface ICanHaveInvoiceModifiers {
  modifiers?: IInvoiceModifier[];
}
