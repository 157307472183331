import {
  CollectionGroup,
  IBrand,
  IMultiTreatmentConfiguration,
  IStaffer,
  ITreatmentConfigurationRef,
  ITreatmentStepConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  WithRef,
  query$,
  snapshot,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ChartedItemConfiguration } from '../charted-item-configuration';
import { treatmentRefsToSumData$ } from './treatment-configuration';

export class TreatmentStepConfiguration {
  static init(
    overrides?: Partial<ITreatmentStepConfiguration>
  ): ITreatmentStepConfiguration {
    return {
      uid: uuid(),
      name: 'New Step',
      treatments: [],
      schedulingRules: {
        duration: 0,
      },
      ...overrides,
    };
  }
}

export class MultiTreatmentConfiguration {
  static init(
    overrides?: Partial<IMultiTreatmentConfiguration>
  ): IMultiTreatmentConfiguration {
    return {
      ...ChartedItemConfiguration.init(),
      steps: [],
      conditions: [],
      packages: [],
      ...overrides,
    };
  }

  static col(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): CollectionReference<IMultiTreatmentConfiguration> {
    return subCollection<IMultiTreatmentConfiguration>(
      parent.ref,
      CollectionGroup.MultiTreatmentConfigurations
    );
  }

  static all$(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): Observable<WithRef<IMultiTreatmentConfiguration>[]> {
    return query$(undeletedQuery(this.col(parent))).pipe(
      map((configuration) => sortBy(configuration, ['name']))
    );
  }

  static async calculateDuration(
    multiTreatmentConfig: IMultiTreatmentConfiguration
  ): Promise<number> {
    const treatmentStep: ITreatmentStepConfiguration | undefined =
      multiTreatmentConfig.steps[0];
    if (!treatmentStep) {
      return 0;
    }

    const treatmentRefs: ITreatmentConfigurationRef[] =
      treatmentStep.treatments;
    const sumData = await snapshot(treatmentRefsToSumData$(treatmentRefs));

    return sumData.reduce((durationTotal, treatmentSumData) => {
      durationTotal +=
        treatmentSumData.treatment.duration * treatmentSumData.quantity;
      return durationTotal;
    }, 0);
  }
}
