import { NgModule } from '@angular/core';
import { NgClinicalChartingStoreModule } from '@principle-theorem/ng-clinical-charting/store';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { PipesModule } from '@principle-theorem/ng-shared';
import { AppointmentTreatmentPlanResolverService } from './appointment-treatment-plan-resolver.service';
import { ChartDialogService } from './chart-dialog.service';
import { ChartedRefLabelPipe } from './charted-ref-label.pipe';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { TreatmentPlanResolverService } from './treatment-plan-resolver.service';
import { TreatmentProviderService } from './treatment-provider.service';

@NgModule({
  imports: [
    NgFirebaseModule,
    PipesModule,
    ComponentsModule,
    PagesModule,
    NgClinicalChartingStoreModule,
  ],
  declarations: [ChartedRefLabelPipe],
  providers: [
    TreatmentPlanResolverService,
    AppointmentTreatmentPlanResolverService,
    ChartDialogService,
    TreatmentProviderService,
  ],
  exports: [ComponentsModule, NgClinicalChartingStoreModule],
})
export class NgClinicalChartingModule {}
