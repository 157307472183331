<div fxFill>
  <div
    class="history-item"
    *ngFor="let history of historyItems$ | async; trackBy: trackByHistory"
  >
    <h3>{{ history.label }}</h3>
    <pr-transaction-display-line
      [transaction]="history.value"
      [invoice]="invoice$ | async"
      readonly
    />
  </div>
</div>
