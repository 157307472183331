<div class="flex-col justify-stretch gap-2">
  <mat-form-field
    class="toolbar-search !flex no-underline"
    subscriptSizing="dynamic"
  >
    <mat-label>Search</mat-label>
    <input
      #searchInput
      type="text"
      matInput
      autocomplete="off"
      [formControl]="search.search"
      (keyup.arrowdown)="selectionList.focus()"
      (keyup.enter)="selectFirstOption()"
    />

    <button
      *ngIf="search.search.value"
      matIconSuffix
      mat-icon-button
      (click)="clearResults()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <pr-empty-state
    *ngIf="emptySearch$ | async"
    image="search"
    title="search results"
    [searchInputValue]="searchInput.value"
  />

  <mat-selection-list
    #selectionList
    class="toolbar-search-list"
    hideSingleSelectionIndicator
    [multiple]="false"
    (compareWith)="(displayFn)"
  >
    @for (group of filteredGroups$ | async; track group.name) {
      @defer (on viewport) {
        <div *ngIf="group.options.length" mat-subheader class="dense">
          {{ group.name }}
        </div>

        @for (option of group.options; track option.name) {
          @defer (on viewport) {
            <mat-list-item
              [ngClass]="{ inactive: (option | map: isInactive) }"
              [ptIntercomTarget]="{ option, group } | map: getIntercomTarget"
              (click)="optionSelected(option)"
              (keyup.enter)="optionSelected(option)"
            >
              <mat-icon *ngIf="option.icon; else autoIcon" matListItemIcon>{{
                option.icon
              }}</mat-icon>

              <ng-template #autoIcon>
                <pt-user-icon
                  matListItemAvatar
                  [name]="option.name"
                  [src]="option | profileImage$ | async"
                  [diameter]="iconSize"
                />
              </ng-template>

              <div class="name" matListItemTitle>{{ option.name }}</div>

              <div matListItemMeta>
                <div class="flex items-center gap-4">
                  <ng-container *ngIf="option | map: isInactive">
                    <mat-chip-listbox [selectable]="false">
                      <mat-chip color="warn" highlighted>INACTIVE</mat-chip>
                    </mat-chip-listbox>
                  </ng-container>
                  <span
                    *ngIf="option.details"
                    class="details mat-caption truncate"
                  >
                    {{ option.details }}
                  </span>
                  <div class="details mat-caption">
                    <ng-container *ngIf="option.phone">{{
                      option.phone
                    }}</ng-container>
                  </div>
                </div>
              </div>
            </mat-list-item>
          } @placeholder (minimum 100ms) {
            <div></div>
          }
        }
      } @placeholder (minimum 100ms) {
        <div class="flex grow px-2">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              height: '30px',
              width: '30px',
              margin: '4px 0 0 4px'
            }"
          />

          <ngx-skeleton-loader
            class="mr-8 grow"
            [theme]="{
              height: '30px',
              margin: '4px 0 4px 8px'
            }"
          />
        </div>
      }
    }
  </mat-selection-list>

  <div *ngIf="search.loading$ | async" class="layout-margin">
    <pr-search-placeholder />
  </div>
</div>
