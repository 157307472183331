<ng-container *ngIf="menuButton; else iconButton">
  <button mat-menu-item (click)="fileInput.click()">
    <mat-icon>insert_photo</mat-icon>
    {{ label }}
  </button>
</ng-container>

<ng-template #iconButton>
  <ng-container *ngIf="iconButton; else buttonWithText">
    <button mat-icon-button [matTooltip]="label" (click)="fileInput.click()">
      <mat-icon>insert_photo</mat-icon>
    </button>
  </ng-container>
</ng-template>

<ng-template #buttonWithText>
  <button mat-stroked-button class="grow" (click)="fileInput.click()">
    <mat-icon>insert_photo</mat-icon>
    {{ label }}
  </button>
</ng-template>

<input
  class="upload-image-input"
  type="file"
  [accept]="imageAllowedTypes"
  #fileInput
  (change)="upload($event)"
/>
