import { CustomReport } from '@principle-theorem/principle-core';
import {
  ICustomReportDefinedColumn,
  type ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  CanGroupMeasuresProperty,
  type AccountCreditDimensionMeasures,
  type AppointmentDimensionMeasures,
  type ICanBeChartedProperty,
  type InvoiceDimensionMeasures,
  type PatientDimensionMeasures,
  type PatientInteractionDimensionMeasures,
  type TransactionDimensionMeasures,
  type TreatmentPlanDimensionMeasures,
} from '@principle-theorem/reporting';
import { groupBy, toPairs } from 'lodash';
import { IColumnSection } from './report-builder-data-source';
import { ReportBuilderHelpers } from './report-builder-helpers';

export function columnBuilder<T>(
  getProperties: (dimension: T) => CanBeChartedProperty[]
): (dimension: T) => CanBeChartedProperty[] {
  return (dimension: T) => getProperties(dimension);
}

export function groupBuilder<T>(
  getProperties: (dimension: T) => CanGroupMeasuresProperty[]
): (dimension: T) => CanGroupMeasuresProperty[] {
  return (dimension: T) => getProperties(dimension);
}

export class ReportBuilderColumns {
  static patient = columnBuilder((patient: PatientDimensionMeasures) => [
    patient.name,
    patient.email,
    patient.dateOfBirth,
    patient.address,
    patient.mobileNumber,
    patient.gender,
    patient.age,
    patient.tags,
    patient.referrer,
    patient.referrerType,
    patient.preferredPractice,
    patient.preferredDentist,
    patient.preferredHygienist,
    patient.hasHealthFundCard,
    patient.healthFundType,
    patient.depositsPaid,
    patient.creditTotal,
    patient.creditUsed,
    patient.creditRemaining,
    patient.treatmentsInvoiced,
    patient.productsInvoiced,
    patient.feesInvoiced,
    patient.subtotalInvoiced,
    patient.depositsInvoiced,
    patient.totalInvoiced,
    patient.discountsGiven,
    patient.writtenOff,
    patient.creditsUsed,
    patient.totalReceivable,
    patient.paymentsReceived,
    patient.outstanding,
    patient.lastVisitDate,
    patient.lastVisitPractitioner,
    patient.lastVisitTreatmentCategory,
    patient.lastVisitStatus,
    patient.nextVisitDate,
    patient.nextVisitPractitioner,
    patient.nextVisitBooked,
    patient.nextVisitTreatmentCategory,
    patient.nextVisitStatus,
    patient.count,
  ]);

  static patientInteraction = columnBuilder(
    (patientInteraction: PatientInteractionDimensionMeasures) => [
      patientInteraction.createdAt,
      patientInteraction.title,
      patientInteraction.content,
      patientInteraction.owner,
      patientInteraction.pinned,
      patientInteraction.type,
      patientInteraction.tags,
      patientInteraction.count,
    ]
  );

  static invoice = columnBuilder((invoice: InvoiceDimensionMeasures) => [
    invoice.reference,
    invoice.status,
    invoice.createdAt,
    invoice.depositTotal,
    invoice.productTotal,
    invoice.treatmentTotal,
    invoice.products,
    invoice.treatments,
    invoice.serviceCodes,
    invoice.due,
    invoice.total,
    invoice.issuedAt,
    invoice.paidAt,
    invoice.count,
  ]);

  static transaction = columnBuilder(
    (transaction: TransactionDimensionMeasures) => [
      transaction.reference,
      transaction.createdAt,
      transaction.status,
      transaction.type,
      transaction.provider,
      transaction.providerSubType,
      transaction.accountCredits,
      transaction.discounts,
      transaction.payments,
      transaction.amount,
      transaction.count,
    ]
  );

  static appointment = columnBuilder(
    (appointment: AppointmentDimensionMeasures) => [
      appointment.date,
      appointment.startTime,
      appointment.endTime,
      appointment.treatmentCategory,
      appointment.status,
      appointment.tags,
      appointment.isBookedOnline,
      appointment.dateBookedOnline,
      appointment.firstBookedAt,
      appointment.appointmentFirstBookedLeadTime,
      appointment.lastBookedAt,
      appointment.appointmentLastBookedLeadTime,
      appointment.count,
    ]
  );

  static treatmentPlan = columnBuilder(
    (treatmentPlan: TreatmentPlanDimensionMeasures) => [
      treatmentPlan.name,
      treatmentPlan.status,
      treatmentPlan.count,
    ]
  );

  static accountCredit = columnBuilder(
    (accountCredit: AccountCreditDimensionMeasures) => [
      accountCredit.createdAt,
      accountCredit.description,
      accountCredit.reservedForPractitioner,
      accountCredit.amount,
      accountCredit.used,
      accountCredit.depositUid,
      accountCredit.updatedAt,
      accountCredit.type,
      accountCredit.count,
    ]
  );

  static toColumns(
    properties: CanBeChartedProperty[]
  ): ICustomReportDefinedColumn[] {
    return properties.map((property) =>
      CustomReport.definedColumn(
        property,
        property.metadata.id,
        property.metadata.label
      )
    );
  }

  static toReferences(
    properties: ICanBeChartedProperty[]
  ): ReportingReference[] {
    return properties.map((property) => property.metadata.id);
  }

  static toColumnGroup(
    name: string,
    properties: CanBeChartedProperty[]
  ): IColumnSection {
    return {
      name,
      columns: properties.map((property) =>
        CustomReport.definedColumn(
          property,
          property.metadata.id,
          property.metadata.label
        )
      ),
    };
  }

  static toColumnGroups(properties: CanBeChartedProperty[]): IColumnSection[] {
    const allColumns = properties.map((property) =>
      CustomReport.definedColumn(
        property,
        property.metadata.id,
        property.metadata.label
      )
    );
    return toPairs(
      groupBy(
        allColumns,
        (column) =>
          ReportBuilderHelpers.getSectionFromPrefix(column.id) ?? 'general'
      )
    ).map(([name, columns]) => ({ name, columns }));
  }

  static mergeColumnGroups(groups: IColumnSection[]): IColumnSection[] {
    const byName = groupBy(groups, (group) => group.name);
    return toPairs(byName).map(([name, sections]) => {
      const columns = sections.map((section) => section.columns).flat();
      return { name, columns };
    });
  }
}
