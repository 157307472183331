import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { initVersionedSchema } from '@principle-theorem/editor';
import {
  EditorPresetsService,
  renderRawTemplate,
} from '@principle-theorem/ng-interactions';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { MOCK_ALL_RESOLVER } from '@principle-theorem/ng-templating';
import {
  AutomatedNotification,
  TemplateDefinition,
  getScopedConditions,
} from '@principle-theorem/principle-core';
import {
  AutomatedNotificationType,
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAutomationTiming,
  TemplateScope,
  isAutomatedNotificationConfiguration,
  type IAutomatedNotification,
  type IAutomatedNotificationConfiguration,
  type IAutomationConfiguration,
  type ITemplateContext,
  type ITemplateDefinition,
  type ITemplateScopeData,
  type TemplateType,
} from '@principle-theorem/principle-core/interfaces';
import {
  TIME_FORMAT_24HR,
  getDoc,
  isChanged$,
  mergeDayAndTime,
  snapshot,
  toMoment,
  toTimestamp,
  type DocumentReference,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { BehaviorSubject, Subject, from, of, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  AUTOMATION_CONDITIONS_HELP_TEXT,
  AUTOMATION_CUSTOM_CONFIGURATION_HELP_TEXT,
} from '../automated-notification-configuration-edit/automated-notification-configuration-form';
import {
  AutomatedNotificationFormHelpers,
  NotificationDialogForm,
} from './notification-dialog-form';

export interface IAutomatedNotificationDialogData {
  notification?: IAutomatedNotification;
  scope: TemplateScope;
  scopeData: ITemplateScopeData;
  useRelativeTime: boolean;
  triggerDate?: Timestamp;
  configRef?: DocumentReference<IAutomationConfiguration>;
}

export interface IAutomatedNotificationFormData extends IAutomatedNotification {
  triggerDate?: Moment;
  triggerTime?: string;
}

export interface IAutomatedNotificationReturnData
  extends IAutomatedNotification {
  triggerDate?: Timestamp;
  configRef?: DocumentReference<IAutomationConfiguration>;
}

@Component({
  selector: 'pr-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class NotificationDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  formHelpers = AutomatedNotificationFormHelpers;
  isEdit: boolean = false;
  templateType$: Observable<TemplateType>;
  templateContext$: Observable<ITemplateContext>;
  useCustomTemplate$ = new BehaviorSubject<boolean>(true);
  config$: Observable<WithRef<IAutomatedNotificationConfiguration> | undefined>;
  configTemplate$: Observable<ITemplateDefinition | undefined>;
  form = new NotificationDialogForm();
  scopeData$: Observable<ITemplateScopeData>;
  hasSubjectInput$: Observable<boolean>;
  noScope: boolean;
  availableConditions: ConditionLogicId[];
  defaultCondition = ConditionLogicId.Always;
  automationConditionsHelp = AUTOMATION_CONDITIONS_HELP_TEXT;
  customConfigurationHelp = AUTOMATION_CUSTOM_CONFIGURATION_HELP_TEXT;

  constructor(
    private _dialogRef: MatDialogRef<
      NotificationDialogComponent,
      IAutomatedNotificationReturnData
    >,
    @Inject(MAT_DIALOG_DATA) public data: IAutomatedNotificationDialogData,
    private _editorPresets: EditorPresetsService
  ) {
    if (data?.notification) {
      this.isEdit = true;
      this.form.patchValue(data.notification);
    }

    if (data?.triggerDate && !data.useRelativeTime) {
      this.form.enableTriggerControls();
      this.form.controls.triggerDate.setValue(toMoment(data.triggerDate));
      this.form.controls.triggerTime.setValue(
        toMoment(data.triggerDate).format(TIME_FORMAT_24HR)
      );
    }

    const useCustomTemplate =
      !data.configRef || !!data.notification?.customTemplate;
    this.useCustomTemplate$.next(useCustomTemplate);

    this.config$ = from(this._getConfig(data.configRef));
    this.configTemplate$ = this.config$.pipe(
      switchMap((config) =>
        config?.templateRef ? getDoc(config.templateRef) : of(undefined)
      )
    );

    this.hasSubjectInput$ = formControlChanges$(this.form.controls.type).pipe(
      startWith(data.notification?.type ?? this.form.value.type),
      map((value) => value === AutomatedNotificationType.EMAIL)
    );
    this.hasSubjectInput$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((hasSubject) => {
        this.form.controls.subject.setValidators(
          hasSubject ? [Validators.required] : []
        );
        this.form.controls.subject.updateValueAndValidity();
      });

    this.templateType$ = formControlChanges$(this.form.controls.type).pipe(
      distinctUntilChanged(),
      map((notificationType) =>
        AutomatedNotification.getTemplateType(notificationType)
      )
    );

    const scope$ = formControlChanges$(this.form.controls.customTemplate).pipe(
      map((customTemplate) => customTemplate?.scope ?? this.data.scope)
    );

    // TODO: Render based on actual scope data. https://app.clickup.com/t/860qzurhu
    this.scopeData$ = scope$.pipe(
      map((scope) => (scope === this.data.scope ? this.data.scopeData : {}))
    );
    this.templateContext$ = scope$.pipe(
      isChanged$(),
      switchMap(async (scope) => MOCK_ALL_RESOLVER.resolve([scope]))
    );

    this.useCustomTemplate$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (isCustomTemplate) => void this._useCustomTemplate(isCustomTemplate)
      );

    this.noScope = data.scope === TemplateScope.None;
    this.availableConditions = getScopedConditions(data.scope).filter(
      (condition) => condition !== ConditionLogicId.Never
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearTriggerDate(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.form.controls.triggerDate.reset();
  }

  requiredConditionsChange(event: ConditionLogicConfigurationCollection): void {
    this.form.controls.requiredConditions.setValue(event);
  }

  timingChange(event: IAutomationTiming): void {
    this.form.controls.timing.setValue(event);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.value;
    const notification = AutomatedNotification.init(formValue);
    if (notification.customTemplate) {
      notification.customTemplate.renderedTemplate = renderRawTemplate(
        {
          content: notification.customTemplate.content,
          scope: notification.customTemplate.scope ?? TemplateScope.None,
          type: AutomatedNotification.getTemplateType(notification.type),
          renderedTemplate: notification.customTemplate.renderedTemplate,
        },
        this._editorPresets.defaultToHTMLExtensions()
      );
    }

    const result: IAutomatedNotificationReturnData = { ...notification };
    const config = await snapshot(this.config$);
    if (config) {
      result.configRef = config.ref;
    }
    if (formValue.triggerDate) {
      const triggerDate = mergeDayAndTime(
        toMoment(formValue.triggerDate),
        moment(formValue.triggerTime, TIME_FORMAT_24HR)
      );
      result.triggerDate = toTimestamp(triggerDate);
    }

    this._dialogRef.close(result);
  }

  private async _getConfig(
    configRef?: DocumentReference<IAutomationConfiguration>
  ): Promise<WithRef<IAutomatedNotificationConfiguration> | undefined> {
    if (!configRef) {
      return undefined;
    }
    const config = await getDoc(configRef);
    if (!isAutomatedNotificationConfiguration(config)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Expected Automated Notification Configutation: ${config.ref.path}`
      );
      return undefined;
    }
    return config as WithRef<IAutomatedNotificationConfiguration>;
  }

  private async _useCustomTemplate(useCustomTemplate: boolean): Promise<void> {
    if (!useCustomTemplate) {
      this.form.enableCustomTemplateControls(false);
      return;
    }
    if (this.form.value.customTemplate?.content) {
      return;
    }
    const config = await snapshot(this.config$);
    if (!isAutomatedNotificationConfiguration(config)) {
      this.form.controls.customTemplate.patchValue({
        content: initVersionedSchema(),
        scope: this.data.scope,
      });
      return;
    }
    this.form.patchValue({ type: config.type });
    const rawTemplate = await TemplateDefinition.resolveRawTemplate(
      await getDoc(config.templateRef),
      await snapshot(this.scopeData$)
    );
    this.form.enableCustomTemplateControls(true, rawTemplate);
  }
}
