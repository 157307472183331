import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISterilisationPackContent } from '@principle-theorem/principle-core/interfaces';
import { isSameRef } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';

export interface IPackContentsDialogData {
  content: ISterilisationPackContent[];
}

@Component({
  selector: 'pr-pack-contents-dialog',
  standalone: true,
  imports: [CommonModule, NgMaterialModule, NgSharedModule],
  templateUrl: './pack-contents-dialog.component.html',
  styleUrl: './pack-contents-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackContentsDialogComponent {
  readonly dialogRef = inject(MatDialogRef<PackContentsDialogComponent>);
  data = inject<IPackContentsDialogData>(MAT_DIALOG_DATA);
  content$ = new BehaviorSubject<ISterilisationPackContent[]>([]);

  constructor() {
    this.content$.next(this.data.content);
  }

  save(): void {
    this.dialogRef.close(this.content$.value);
  }

  changeQuantity(content: ISterilisationPackContent, quantity: number): void {
    const currentContent = this.content$.value;
    const changedContent = currentContent.map((item) =>
      isSameRef(content.item, item.item) ? { ...item, quantity } : item
    );
    this.content$.next(changedContent);
  }
}
