import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type ChartSection,
  type ChartType,
  type ChartView,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import { Observable } from 'rxjs';
import { patchDoc, snapshot, WithRef } from '@principle-theorem/shared';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-chart-view-toolbar',
  templateUrl: './chart-view-toolbar.component.html',
  styleUrls: ['./chart-view-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartViewToolbarComponent {
  section$: Observable<ChartSection>;
  view$: Observable<ChartView>;
  chart$: Observable<WithRef<IClinicalChart> | IClinicalChart>;
  chartType$: Observable<ChartType>;
  @Input() showHistorySelector = true;

  constructor(
    private _chartStore: ChartFacade,
    private _patientScope: CurrentPatientScope
  ) {
    this.section$ = this._chartStore.chartSection$(ChartId.InAppointment);
    this.view$ = this._chartStore.chartView$(ChartId.InAppointment);
    this.chart$ = this._chartStore.clinicalChartState$(ChartId.InAppointment);
    this.chartType$ = this._chartStore.chartType$(ChartId.InAppointment);
  }

  async selectView(view: ChartView): Promise<void> {
    if (view) {
      const selectedPatient = await snapshot(this._patientScope.doc$);
      if (selectedPatient) {
        await patchDoc(selectedPatient.ref, {
          settings: {
            charting: {
              view,
            },
          },
        });
      }
      this._chartStore.setChartView(ChartId.InAppointment, view);
      this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
    }
  }

  selectSection(section: ChartSection): void {
    if (section) {
      this._chartStore.setChartSection(ChartId.InAppointment, section);
      this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
    }
  }

  selectChart(chart: WithRef<IClinicalChart>): void {
    this._chartStore.setChart(ChartId.InAppointment, chart);
  }

  setChartType(chartType: ChartType): void {
    this._chartStore.setChartType(ChartId.InAppointment, chartType);
  }
}
