<button
  class="opacity-80"
  mat-icon-button
  [matMenuTriggerFor]="moreMenu"
  matTooltip="User Settings"
  ptElementTarget="view-settings-button"
>
  <mat-icon>manage_accounts_outline</mat-icon>
</button>
<mat-menu class="view-settings" #moreMenu="matMenu">
  <form [formGroup]="stafferViewSettingsForm">
    <h3 class="settings-heading">User Settings</h3>

    <pt-settings-menu-toggle-group-item
      label="Timeline Layout"
      formControlName="orientation"
      ptElementTarget="orientation-button"
      [options]="timelineOrientations"
    />

    <pt-settings-menu-toggle-group-item
      label="Zoom"
      formControlName="zoom"
      ptElementTarget="zoom-button"
      [options]="zoomOptions"
    />

    <pt-settings-menu-slide-toggle-item
      label="Wide Columns"
      formControlName="thickTracks"
      ptElementTarget="thick-tracks-button"
      *ngIf="(timelineStore.orientation$ | ngrxPush) === 'vertical'"
    />

    <pt-settings-menu-slide-toggle-item
      label="Show Gridlines"
      formControlName="showGridlines"
      ptElementTarget="show-gridlines-button"
    />

    <pt-settings-menu-slide-toggle-item
      label="Hide Empty Days"
      formControlName="hideEmptyDays"
      ptElementTarget="hide-empty-days-button"
    />
  </form>
</mat-menu>
