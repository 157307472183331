import { IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { AIMenuButtonFilters } from '../ai-menu-button-filters';

export function createEmojifyButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Emojify',
      tooltip: `Add emojis ✨ to your text`,
      icon: 'celebration',
      command: (editor) => editor.chain().focus().aiEmojify().focus().run(),
      isDisabledFn: (editor) =>
        AIMenuButtonFilters.disableWithoutTextSelection(editor),
    },
  });
}
