import { type DocumentReference } from '@principle-theorem/shared';
import {
  type IChartedItemConfiguration,
  isChartedItemConfiguration,
} from '../charted-item-configuration';
import { isMultiTreatmentConfiguration } from '../treatment/multi-treatment-configuration';
import { isTreatmentConfiguration } from '../treatment/treatment-configuration';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConditionConfiguration extends IChartedItemConfiguration {}

export function isConditionConfiguration(
  item: unknown
): item is IConditionConfiguration {
  return (
    isChartedItemConfiguration(item) &&
    !isTreatmentConfiguration(item) &&
    !isMultiTreatmentConfiguration(item)
  );
}

export interface IHasConditions {
  conditions: DocumentReference<IConditionConfiguration>[];
}
