import {
  IAppointment,
  IBrand,
  IPractice,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  WithRef,
  collectionGroupQuery,
  firstResult,
  orderBy,
  resolveSequentially,
  snapshot,
  toMomentTz,
  toTimestamp,
  where,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Brand } from '../models/brand';
import { ResyncScheduleSummary } from './resync-schedule-summary';
import { ILogger } from '@principle-theorem/developer-tools';

export class SeedScheduleSummariesForBrands {
  constructor(private _logger: ILogger) {}

  async run(brands: WithRef<IBrand>[], from?: moment.Moment): Promise<void> {
    await resolveSequentially(brands, async (brand) => {
      const runner = new SeedScheduleSummaries(this._logger);
      await runner.run(brand, from);
    });
  }
}

export class SeedScheduleSummaries {
  constructor(private _logger: ILogger) {}

  async run(brand: WithRef<IBrand>, from?: moment.Moment): Promise<void> {
    const today = toTimestamp(toMomentTz(moment(), brand.settings.timezone));
    const practices = await snapshot(Brand.practices$({ ref: brand.ref }));

    this._logger.info(`Seeding Brand Schedule Summaries: ${brand.name}`);

    const furthestCalendarEvent =
      (await this._getFurthestCalendarEvent(brand, toTimestamp(today))) ??
      today;
    const furthestAppointment =
      (await this._getFurthestAppointment(practices, toTimestamp(today))) ??
      today;

    const furthestScheduledEvent = moment.max(
      toMomentTz(furthestCalendarEvent ?? today, brand.settings.timezone),
      toMomentTz(furthestAppointment ?? today, brand.settings.timezone)
    );

    await new ResyncScheduleSummary(this._logger).run(brand.ref, {
      from: from ?? moment().subtract(1, 'year'),
      to: furthestScheduledEvent.clone().endOf('day'),
    });
  }

  private async _getFurthestCalendarEvent(
    brand: WithRef<IBrand>,
    from: Timestamp
  ): Promise<Timestamp | undefined> {
    const result = await firstResult(
      Brand.calendarEventCol({ ref: brand.ref }),
      where('deleted', '==', false),
      where('event.from', '>', from),
      orderBy('event.from', 'desc')
    );

    if (!result) {
      return undefined;
    }

    return result.event.from;
  }

  private async _getFurthestAppointment(
    practices: WithRef<IPractice>[],
    from: Timestamp
  ): Promise<Timestamp | undefined> {
    const result = await firstResult(
      collectionGroupQuery<IAppointment>(
        PatientCollection.Appointments,
        where(
          'event.practice.ref',
          'in',
          practices.map((practice) => practice.ref)
        ),
        where('deleted', '==', false),
        where('event.from', '>', from),
        orderBy('event.from', 'desc')
      )
    );

    if (!result) {
      return undefined;
    }

    return result.event?.from;
  }
}
