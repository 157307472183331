import {
  type IFormSchema,
  FormSchemaPropertyType,
  FormLayoutElementType,
  type IChildFlexLayoutElement,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow } from '../custom-form-helpers';

export const COVID_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    haveTravelled: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Have you travelled in the last two months?',
    },
    whereTravelled: {
      type: FormSchemaPropertyType.String,
      title: 'Where did you travel to?',
    },
    whenTravelled: {
      type: FormSchemaPropertyType.String,
      title: 'When did you travel?',
    },
    haveTravelledInterstate: {
      type: FormSchemaPropertyType.Boolean,
      title:
        'Have you returned from any interstate travel in the last 14 days?',
    },
    whereTravelledInterstate: {
      type: FormSchemaPropertyType.String,
      title: 'Where did you travel from?',
    },
    confirmedContact: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Have you been in contact with a confirmed case of COVID-19?',
    },
    dateOfConfirmedContact: {
      type: FormSchemaPropertyType.String,
      title: 'What was the last date of contact?',
    },
    tested: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Have you been tested for COVID-19?',
    },
    haveReceivedResult: {
      type: FormSchemaPropertyType.String,
      title: 'Have you received the result?',
      enum: ['Yes', 'No'],
    },
    result: {
      type: FormSchemaPropertyType.String,
      title: 'What was the result?',
      enum: ['Negative', 'Positive'],
    },
    symptoms: {
      type: FormSchemaPropertyType.String,
      title: 'Are you currently experiencing any cold or flu symptoms?',
      enum: ['Yes', 'No'],
    },
  },
};

export const COVID_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Div,
    items: [
      formFlexRow([
        { key: 'haveTravelled' },
        { key: 'whereTravelled' },
        { key: 'whenTravelled' },
      ]),
      formFlexRow([
        { key: 'haveTravelledInterstate' },
        { key: 'whereTravelledInterstate' },
      ]),
      formFlexRow([
        { key: 'confirmedContact' },
        { key: 'dateOfConfirmedContact' },
      ]),
      formFlexRow([
        { key: 'tested' },
        { key: 'haveReceivedResult' },
        { key: 'result' },
      ]),
      { key: 'symptoms' },
    ],
  },
];
