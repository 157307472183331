import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateService } from '@principle-theorem/ng-principle-shared';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type ITreatmentPlan,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-plan-summary',
  templateUrl: './treatment-plan-summary.component.html',
  styleUrls: ['./treatment-plan-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanSummaryComponent {
  plan$: ReplaySubject<WithRef<ITreatmentPlan>> = new ReplaySubject(1);
  patient$: ReplaySubject<IPatient> = new ReplaySubject(1);
  progress$: Observable<number>;
  price$: Observable<number>;
  showProgress$: Observable<boolean>;
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(public dateService: DateService) {
    this.progress$ = this.plan$.pipe(
      switchMap((plan) => TreatmentPlan.progress$(plan))
    );
    this.price$ = this.plan$.pipe(
      switchMap((plan) => TreatmentPlan.price$(plan))
    );

    this.showProgress$ = this.plan$.pipe(
      map((plan) => plan.status === TreatmentPlanStatus.InProgress)
    );
  }

  @Input()
  set plan(plan: WithRef<ITreatmentPlan>) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  @Input()
  set patient(patient: IPatient) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
