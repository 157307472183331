import {
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ICreatedTask,
  InteractionType,
  IPractice,
  IRecurringTaskConfiguration,
  ITask,
  MentionResourceType,
  TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  Timestamp,
} from '@principle-theorem/shared';
import {
  addDoc,
  AtLeast,
  doc$,
  EndingType,
  getParentDocRef,
  initFirestoreModel,
  snapshot,
  Timezone,
  TIME_FORMAT,
  toMoment,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { toMention } from '../mention/mention';
import { RecurrencePattern } from '../schedule/recurrence-pattern';
import { Task } from './task';

export class RecurringTaskConfiguration {
  static init(
    overrides: AtLeast<IRecurringTaskConfiguration, 'owner'>
  ): IRecurringTaskConfiguration {
    return {
      title: [],
      description: initVersionedSchema(),
      priority: TaskPriority.Low,
      recurrencePattern: RecurrencePattern.init(),
      createdTasks: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static practice$(
    config: WithRef<IRecurringTaskConfiguration>
  ): Observable<WithRef<IPractice>> {
    return doc$(getParentDocRef<IPractice>(config.ref));
  }

  static lastCreatedTask(
    config: IRecurringTaskConfiguration
  ): ICreatedTask | undefined {
    if (!config.createdTasks.length) {
      return;
    }
    return config.createdTasks[config.createdTasks.length - 1];
  }

  static lastCreatedTaskDoc(
    config: IRecurringTaskConfiguration
  ): Observable<WithRef<ITask> | undefined> {
    const lastCreatedTask = this.lastCreatedTask(config);
    if (!lastCreatedTask) {
      return of(undefined);
    }
    return doc$<ITask>(lastCreatedTask.ref);
  }

  static async canGenerateTask(
    config: IRecurringTaskConfiguration
  ): Promise<boolean> {
    this.validateCompleted(config);
    if (config.completedAt || config.deleted) {
      return false;
    }

    const lastTask = await snapshot(this.lastCreatedTaskDoc(config));
    if (!lastTask) {
      return true;
    }

    if (!Task.complete(lastTask) && !lastTask.deleted) {
      return false;
    }

    return true;
  }

  static async generateNewTask(
    config: WithRef<IRecurringTaskConfiguration>,
    taskCollection: CollectionReference<ITask>,
    timezone: Timezone
  ): Promise<DocumentReference | undefined> {
    if (!(await this.canGenerateTask(config))) {
      return;
    }

    const task: ITask = Task.init({
      title: config.title,
      priority: config.priority,
      visibleFrom: this.determineVisibilityDate(config, timezone),
      assignedUser: config.assignedUser,
      assignedTeam: config.assignedTeam,
      recurrenceConfiguration: config.ref,
    });

    if (config.recurrenceTiming) {
      const dueDate: moment.Moment = toMoment(task.visibleFrom);
      dueDate.add(config.recurrenceTiming.amount, config.recurrenceTiming.unit);

      if (config.recurrenceTiming.time) {
        const dueTime: moment.Moment = moment(
          config.recurrenceTiming.time,
          TIME_FORMAT
        );
        dueDate.set({
          hours: dueTime.hours(),
          minutes: dueTime.minutes(),
        });
      }

      task.dueDate = toTimestamp(dueDate);
    }

    Task.addInteractions(task, [
      {
        owner: config.owner,
        type: InteractionType.TaskCreate,
        title: [
          toTextContent(`Task generated from configuration created by `),
          toMentionContent(
            toMention(config.owner, MentionResourceType.Staffer)
          ),
        ],
      },
    ]);

    if (config.description && config.owner) {
      Task.addInteractions(task, [
        {
          owner: config.owner,
          type: InteractionType.Note,
          title: [
            toMentionContent(
              toMention(config.owner, MentionResourceType.Staffer)
            ),
            toTextContent(` added a note`),
          ],
          content: config.description,
        },
      ]);
    }

    const taskRef = await addDoc(taskCollection, task);
    config.createdTasks.push({
      dateShown: task.visibleFrom,
      ref: taskRef,
    });

    return taskRef;
  }

  static determineVisibilityDate(
    config: IRecurringTaskConfiguration,
    timezone: Timezone
  ): Timestamp {
    const lastTask = this.lastCreatedTask(config);
    const lastTaskDate =
      lastTask && lastTask.dateShown ? toMoment(lastTask.dateShown) : undefined;
    const nextOccurence = RecurrencePattern.getNextTaskOccurrence(
      config.recurrencePattern,
      timezone,
      lastTaskDate
    );
    return toTimestamp(nextOccurence);
  }

  static validateCompleted(config: IRecurringTaskConfiguration): void {
    switch (config.recurrencePattern.endingType) {
      case EndingType.Count:
        if (
          config.recurrencePattern.occurrenceCount &&
          config.createdTasks.length >= config.recurrencePattern.occurrenceCount
        ) {
          config.completedAt = toTimestamp();
        }
        break;
      case EndingType.Date:
        const endingDate = config.recurrencePattern.endingDate;
        if (endingDate && moment().isAfter(toMoment(endingDate))) {
          config.completedAt = toTimestamp();
        }
        break;
      default:
        break;
    }
  }
}
