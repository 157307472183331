import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IPractitionerGrouping } from '@principle-theorem/reporting';

export interface IInvoicePaidSelectorCSV {
  practitioner: string;
  treatmentsInvoicedAmount: string;
  depositsTakenAmount: string;
  discountsAppliedAmount: string;
  creditsAppliedAmount: string;
  receiptedCommissionAmount: string;
}

export class InvoicePaidSelectorToCSV
  implements ICSVExport<IPractitionerGrouping, IInvoicePaidSelectorCSV>
{
  defaultFileName = 'practitioner-income-summary';
  headers = [
    'Practitioner',
    'Treatment Invoiced',
    'Deposits',
    'Discounts',
    'Account Credits',
    'Receipted Amount',
  ];

  translate(records: IPractitionerGrouping[]): IInvoicePaidSelectorCSV[] {
    return records.map((record) => ({
      practitioner: record.practitioner.name,
      treatmentsInvoicedAmount:
        record.total.treatmentsInvoicedAmount.toString(),
      depositsTakenAmount: record.total.depositsTakenAmount.toString(),
      discountsAppliedAmount: record.total.discountsAppliedAmount.toString(),
      creditsAppliedAmount: record.total.creditsAppliedAmount.toString(),
      receiptedCommissionAmount:
        record.total.receiptedCommissionAmount.toString(),
    }));
  }
}
