import {
  type CustomFormDataResolverConfigMap,
  type ICustomFormConfiguration,
  type IFormSchema,
  type IRootFormLayoutElement,
} from '@principle-theorem/principle-core/interfaces';
import {
  DENTAL_HISTORY_FORM_LAYOUT,
  DENTAL_HISTORY_FORM_SCHEMA,
} from './dental-history-form-schema';
import {
  GENERAL_MEDICAL_HISTORY_FORM_LAYOUT,
  GENERAL_MEDICAL_HISTORY_FORM_SCHEMA,
} from './general-medical-history-form-schema';
import {
  TERMS_AND_CONDITIONS_FORM_LAYOUT,
  TERMS_AND_CONDITIONS_FORM_SCHEMA,
} from './terms-and-conditions-schema';
import { formSubForm, formToolbar } from '../custom-form-helpers';

export const BASIC_MEDICAL_HISTORY_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    medicalHistory: GENERAL_MEDICAL_HISTORY_FORM_SCHEMA,
    dentalHistory: DENTAL_HISTORY_FORM_SCHEMA,
    termsAndConditions: TERMS_AND_CONDITIONS_FORM_SCHEMA,
  },
};

export const BASIC_MEDICAL_HISTORY_FORM_LAYOUT: IRootFormLayoutElement[] = [
  formSubForm('medicalHistory', GENERAL_MEDICAL_HISTORY_FORM_LAYOUT),
  formToolbar('Dental History & Concerns'),
  formSubForm('dentalHistory', DENTAL_HISTORY_FORM_LAYOUT),
  formToolbar('Consent & Signature'),
  formSubForm('termsAndConditions', TERMS_AND_CONDITIONS_FORM_LAYOUT),
];

export const BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG: CustomFormDataResolverConfigMap =
  {
    conditions: [
      {
        path: 'medicalHistory.conditions.asthma',
        mapTitle: 'Asthma',
      },
      {
        path: 'medicalHistory.conditions.hasGastrointestinalDisorder',
        mapTitle: 'Gastrointestinal Disorder',
      },
      {
        path: 'medicalHistory.conditions.hasJointReplacement',
        mapTitle: 'Joint Replacement',
      },
      {
        path: 'medicalHistory.conditions.cancerTreatmentChemotherapy',
        mapTitle: 'Chemotherapy',
      },
      {
        path: 'medicalHistory.conditions.cancerTreatmentRadiotherapy',
        mapTitle: 'Radiotherapy',
      },
      { path: 'medicalHistory.conditions.isPregnant', mapTitle: 'Pregnant' },
      {
        path: 'medicalHistory.conditions.hasPsychiatricDisorder',
        mapTitle: 'Psychiatric Disorder',
      },
      { path: 'medicalHistory.conditions.other', mapTitle: 'Other' },
    ],
    allergies: [
      { path: 'medicalHistory.allergies.penicillin', mapTitle: 'Penicillin' },
      { path: 'medicalHistory.allergies.latex', mapTitle: 'Latex' },
      { path: 'medicalHistory.allergies.other', mapTitle: 'Other' },
    ],
    history: [
      {
        path: 'dentalHistory.antibioticsBeforeTreatment',
        mapTitle: 'Antibiotics before treatment',
      },
      {
        path: 'dentalHistory.dentalAnxiety',
        mapTitle: 'Dental anxiety',
      },
    ],
  };

export const DEFAULT_MEDICAL_HISTORY_FORM_CONFIG: ICustomFormConfiguration = {
  name: 'Medical History Form',
  schema: BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  layout: BASIC_MEDICAL_HISTORY_FORM_LAYOUT,
  dataResolverConfig: BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG,
};

export function buildCustomFormConfiguration(
  config?: Partial<ICustomFormConfiguration>
): ICustomFormConfiguration {
  return {
    name: config?.name ?? DEFAULT_MEDICAL_HISTORY_FORM_CONFIG.name,
    schema: config?.schema ?? DEFAULT_MEDICAL_HISTORY_FORM_CONFIG.schema,
    layout: config?.layout ?? DEFAULT_MEDICAL_HISTORY_FORM_CONFIG.layout,
    dataResolverConfig:
      config?.dataResolverConfig ??
      DEFAULT_MEDICAL_HISTORY_FORM_CONFIG.dataResolverConfig,
  };
}
