import { type ModuleWithProviders, NgModule } from '@angular/core';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import {
  ContextualActionsModule,
  EditorPresetsService,
} from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { HotkeyModule } from 'angular2-hotkeys';
import { ComponentsModule } from './components/components.module';
import { CoreModule } from './core/core.module';
import {
  type INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from './ng-principle-config';

@NgModule({
  imports: [
    CoreModule,
    ContextualActionsModule,
    ComponentsModule,
    NgEditorModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    NgFeatureFlagsModule,
  ],
  exports: [
    NgPrincipleSharedModule,
    CoreModule,
    ComponentsModule,
    NgEditorModule,
    HotkeyModule,
  ],
  providers: [EditorPresetsService],
})
export class NgPrincipleModule {
  static forRoot(
    config: INgPrincipleConfig
  ): ModuleWithProviders<NgPrincipleModule> {
    return {
      ngModule: NgPrincipleModule,
      providers: [
        {
          provide: NG_PRINCIPLE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
