import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentBrandScope,
  CurrentPatientScope,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  Patient,
  SubmittedFormHistory,
  buildCustomFormConfiguration,
  getCustomFormData,
  getCustomSubmittedForm,
} from '@principle-theorem/principle-core';
import {
  FormStatus,
  PatientForm,
  type ICustomFormConfiguration,
  type ICustomFormData,
  type IPatient,
  type ISubmittedForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import { Subject, combineLatest, merge, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-medical-history-form',
  templateUrl: './medical-history-form.component.html',
  styleUrls: ['./medical-history-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalHistoryFormComponent {
  customForm$: Observable<ICustomFormData<unknown>>;
  latestForm$: Observable<ISubmittedForm | undefined>;
  patient$: Observable<WithRef<IPatient>>;
  submitting$ = new Subject<boolean>();
  form$: Observable<ICustomFormData<unknown> | ISubmittedForm>;
  clearForm$ = new Subject<void>();

  constructor(
    private _patientScope: CurrentPatientScope,
    private _brandScope: CurrentBrandScope,
    private _stateNav: StateBasedNavigationService
  ) {
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());

    this.latestForm$ = merge(
      this.patient$.pipe(
        switchMap((patient) =>
          Patient.formData$(patient, PatientForm.MedicalHistoryForm)
        )
      ),
      this.clearForm$.pipe(map(() => undefined))
    );

    this.customForm$ = this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.medicalHistoryFormConfig$(brand)),
      map((config) => this._toCustomMaterialForm(config))
    );

    this.form$ = combineLatest([this.latestForm$, this.customForm$]).pipe(
      map(([latest, custom]) => {
        if (latest) {
          return {
            ...latest,
            ...pick(custom, ['schema', 'layout', 'dataResolverConfig']),
          };
        }
        return custom;
      })
    );
  }

  async submit(data: object): Promise<void> {
    this.submitting$.next(true);
    const patient = await snapshot(this.patient$);
    const history = await snapshot(
      Patient.form$(patient, PatientForm.MedicalHistoryForm)
    );
    const customForm = await snapshot(this.customForm$);
    const formSubmission = getCustomSubmittedForm(customForm, data);
    await SubmittedFormHistory.add(
      patient,
      PatientForm.MedicalHistoryForm,
      { form: formSubmission, status: FormStatus.Confirmed },
      history
    );
    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'forms',
      PatientForm.MedicalHistoryForm,
    ]);
    this.submitting$.next(false);
  }

  clearForm(): void {
    this.clearForm$.next();
  }

  private _toCustomMaterialForm(
    config?: ICustomFormConfiguration
  ): ICustomFormData<unknown> {
    return getCustomFormData(buildCustomFormConfiguration(config));
  }
}
