import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  type Event,
  NavigationEnd,
  Router,
} from '@angular/router';
import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DynamicTitleService {
  default = '';
  separator = '-';

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  get title$(): Observable<string> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      map(() => this._getLastActivated()),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      switchMap((route: ActivatedRoute) => route.data),
      map((data) => this._getTitle(data))
    );
  }

  private _getLastActivated(): ActivatedRoute {
    let route: ActivatedRoute = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private _getTitle(data: unknown): string {
    const title: string =
      isObject(data) && isString(data.title) ? data.title : this.default;
    return this._applySuffix(title, data);
  }

  private _applySuffix(title: string, data: unknown): string {
    const suffix: string =
      isObject(data) && isString(data.titleSuffix) ? data.titleSuffix : '';
    return suffix.length ? `${title} ${suffix}` : title;
  }
}
