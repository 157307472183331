import {
  CustomMappingType,
  ICustomMapping,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  ISO_DATE_TIME_FORMAT,
  Timestamp,
  Timezone,
  toInt,
  toTimestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { CustomMapping } from '../../../custom-mapping';
import { SingleValueMappingHandler } from '../../../mappings/single-value';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { IPraktikaAppointment } from '../../source/entities/patient-appointment';

export const APPOINTMENT_STEP_SIZE_CUSTOM_MAPPING_TYPE =
  'appointmentStepSizeCustomMapping';

export const APPOINTMENT_STEP_SIZE_MAPPING: ICustomMapping = CustomMapping.init(
  {
    metadata: {
      label: 'Appointment Step Size',
      description: 'Praktika provides two step sizes for appointments.',
      type: APPOINTMENT_STEP_SIZE_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.SingleValue,
    inputType: 'select',
    placeholder: 'Select Appointment Step Size',
    hint: 'Default step size is 15 minutes.',
    options: [
      {
        label: '10 Minutes',
        value: '10',
      },
      {
        label: '15 Minutes',
        value: '15',
      },
    ],
  }
);

const defaultDuration = '15';

export class PraktikaAppointmentStepSizeMappingHandler extends SingleValueMappingHandler {
  customMapping = APPOINTMENT_STEP_SIZE_MAPPING;

  async getAppointmentDuration(
    migration: IReffable<IPracticeMigration>,
    appointment: IPraktikaAppointment
  ): Promise<number> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const stepSize = await this.getBySource(
      this.customMapping.metadata.type,
      translationMap
    );

    if (!stepSize?.destinationValue || !isString(stepSize.destinationValue)) {
      return appointment.appointment_duration * toInt(defaultDuration);
    }

    return appointment.appointment_duration * toInt(stepSize.destinationValue);
  }

  async getAppointmentEndTime(
    migration: IReffable<IPracticeMigration>,
    appointment: IPraktikaAppointment,
    timezone: Timezone
  ): Promise<Timestamp | undefined> {
    const duration = await this.getAppointmentDuration(migration, appointment);
    return appointment.appointment_starttime
      ? toTimestamp(
          moment
            .tz(
              appointment.appointment_starttime,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
            .add({ minutes: duration })
        )
      : undefined;
  }
}
