<mat-toolbar class="toolbar-header flex items-center gap-1">
  <pt-search-field class="flex-1" [slim]="true">
    <input
      matInput
      ptElementTarget="patient-search"
      placeholder="Search"
      [formControl]="searchCtrl"
      #search
    />
  </pt-search-field>

  <div class="flex">
    <button
      mat-icon-button
      matTooltip="Toggle Selection"
      (click)="toggleMultiSelection()"
    >
      <mat-icon>playlist_add_check</mat-icon>
    </button>

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      matTooltip="Filter Chats"
    >
      <mat-icon>filter_list</mat-icon>
    </button>

    <button mat-icon-button matTooltip="New Chat" (click)="addChat()">
      <mat-icon fontSet="material-symbols">edit_square</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <ng-template matMenuContent>
        <button
          mat-menu-item
          *ngFor="
            let filterOption of filterOptions;
            trackBy: trackByFilterOptions
          "
          (click)="setFilter(filterOption)"
        >
          {{ filterOption | splitCamel | titlecase }}
        </button>
      </ng-template>
    </mat-menu>

    <button
      mat-icon-button
      [matMenuTriggerFor]="settingsMenu"
      matTooltip="Chat Settings"
      ptElementTarget="view-settings-button"
    >
      <mat-icon>tune</mat-icon>
    </button>
    <mat-menu class="view-settings" #settingsMenu="matMenu">
      <form [formGroup]="settingsForm">
        <h3 class="settings-heading">Chat Settings</h3>

        <pt-settings-menu-select
          label="Notification Sound"
          tooltip="Select sound of chat notifications"
          formControlName="notificationSoundOverride"
          ptElementTarget="notification-sound-override-button"
          [options]="sounds"
        />

        <pt-settings-menu-slide-toggle-item
          label="Show All Practices"
          tooltip="Show all chats across the practices that you have access to"
          formControlName="showAllPractices"
          ptElementTarget="show-all-practices-button"
        />
      </form>
    </mat-menu>
  </div>
</mat-toolbar>

<ng-container *ngIf="(loading$ | async) === false; else loading">
  <div class="new-chat" *ngIf="multiSelectEnabled$ | async">
    <pr-chat-multi-action-toolbar
      [selectionList]="selectionList"
      [selected]="selectionList.selected$ | async"
      (closeToolbar)="toggleMultiSelection()"
    />
  </div>
</ng-container>

<mat-selection-list
  #chats
  class="current-chats"
  hideSingleSelectionIndicator
  [multiple]="false"
  [compareWith]="compareWithRef"
  (selectionChange)="setSelectedChat(chats.selectedOptions.selected[0]?.value)"
>
  <ng-container *ngIf="(loading$ | async) === false">
    <ng-container *ngIf="searchFilter.results$ | async as chats">
      <ng-container *ngIf="chats.length; else noFilteredResults">
        @if (preventRouteChange) {
          <mat-list-option
            *ngFor="let chat of chats; trackBy: trackByChat"
            [value]="chat"
            (click)="
              menuItem.actions.markRead(); chatSelected.emit(chat.chat.ref)
            "
          >
            <pr-chat-menu-item
              #menuItem
              [chat]="chat"
              [multiSelectEnabled]="multiSelectEnabled$ | async"
              [selectionList]="selectionList"
            />
          </mat-list-option>
        } @else {
          <mat-list-option
            *ngFor="let chat of chats; trackBy: trackByChat"
            [routerLink]="['./', chat.chat.ref.id]"
            routerLinkActive
            #rla="routerLinkActive"
            [selected]="rla.isActive"
            [value]="chat"
            (click)="menuItem.actions.markRead()"
          >
            <pr-chat-menu-item
              #menuItem
              [chat]="chat"
              [multiSelectEnabled]="multiSelectEnabled$ | async"
              [selectionList]="selectionList"
            />
          </mat-list-option>
        }
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
  </ng-template>

  <ng-template #noFilteredResults>
    <ng-container *ngIf="hasListFilter$ | async; else noResults">
      <pr-empty-state class="no-chats" title="filtered results" />
    </ng-container>
  </ng-template>

  <ng-template #noResults>
    <div class="flex grow flex-col items-center pt-8">
      <button mat-flat-button color="primary" (click)="addChat()">
        <mat-icon fontSet="material-symbols">edit_square</mat-icon>
        Start New Chat
      </button>

      <pr-empty-state class="no-chats" title="chats" />
    </div>
  </ng-template>
</mat-selection-list>
