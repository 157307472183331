import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AutomationsFacade } from './store/facades/automations.facade';
import { AutomationsEffects } from './store/effects/automations.effects';
import { StoreModule } from '@ngrx/store';
import { AUTOMATIONS_FEATURE_KEY, reducer } from './store/automations.reducer';
import { AutomationComponentsModule } from './components/components.module';
import { AutomationsListEffects } from './store/effects/automations-list.effects';
import { AutomationDialogService } from './services/automation-dialog.service';
import { AutomationConfigurationService } from './services/automation-configuration.service';

@NgModule({
  imports: [
    CommonModule,
    AutomationComponentsModule,
    StoreModule.forFeature(AUTOMATIONS_FEATURE_KEY, reducer),
    EffectsModule.forFeature([AutomationsEffects, AutomationsListEffects]),
  ],
  providers: [
    AutomationsFacade,
    AutomationDialogService,
    AutomationConfigurationService,
  ],
  exports: [AutomationComponentsModule],
})
export class NgAutomationsModule {}
