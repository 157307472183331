import { map, some, sum } from 'lodash';

export interface IPractitionerIncomeSummary {
  treatmentsInvoicedAmount: number;
  depositsTakenAmount: number;
  discountsAppliedAmount: number;
  discountsAppliedWarning: boolean;
  creditsAppliedAmount: number;
  creditsAppliedWarning: boolean;
  totalInvoicedAmount: number;
  invoicedCommissionAmount: number;
  invoicedCommissionWarning: boolean;
  receiptedCommissionAmount: number;
  receiptedCommissionWarning: boolean;
}

export class PractitionerIncomeSummary {
  static init(
    overrides: Partial<IPractitionerIncomeSummary> = {}
  ): IPractitionerIncomeSummary {
    return {
      treatmentsInvoicedAmount: 0,
      depositsTakenAmount: 0,
      discountsAppliedAmount: 0,
      discountsAppliedWarning: false,
      creditsAppliedAmount: 0,
      creditsAppliedWarning: false,
      totalInvoicedAmount: 0,
      invoicedCommissionAmount: 0,
      invoicedCommissionWarning: false,
      receiptedCommissionAmount: 0,
      receiptedCommissionWarning: false,
      ...overrides,
    };
  }

  static add(
    summaries: IPractitionerIncomeSummary[]
  ): IPractitionerIncomeSummary {
    return {
      treatmentsInvoicedAmount: sum(map(summaries, 'treatmentsInvoicedAmount')),
      depositsTakenAmount: sum(map(summaries, 'depositsTakenAmount')),
      discountsAppliedAmount: sum(map(summaries, 'discountsAppliedAmount')),
      discountsAppliedWarning: some(summaries, 'discountsAppliedWarning'),
      creditsAppliedAmount: sum(map(summaries, 'creditsAppliedAmount')),
      creditsAppliedWarning: some(summaries, 'creditsAppliedWarning'),
      totalInvoicedAmount: sum(map(summaries, 'totalInvoicedAmount')),
      invoicedCommissionAmount: sum(map(summaries, 'invoicedCommissionAmount')),
      invoicedCommissionWarning: some(summaries, 'invoicedCommissionWarning'),
      receiptedCommissionAmount: sum(
        map(summaries, 'receiptedCommissionAmount')
      ),
      receiptedCommissionWarning: some(summaries, 'receiptedCommissionWarning'),
    };
  }

  static reduce(
    summaries: IPractitionerIncomeSummary[]
  ): IPractitionerIncomeSummary {
    return summaries.reduce(
      (acc, summary) => PractitionerIncomeSummary.add([acc, summary]),
      PractitionerIncomeSummary.init()
    );
  }
}

export const PRACTITIONER_INCOME_TOOLTIPS: Record<string, string> = {
  treatmentsInvoicedHelp: 'Total of treatment invoiced before discounts',
  discountsAppliedWarning:
    'Discounts may not be attributed to this practitioner',
  creditsAppliedWarning: 'Credits may not be attributed to this practitioner',
  invoicedCommissionHelp: 'Treatment Invoiced - Discounts',
  invoicedCommissionWarning:
    'Some discounts may not be attributed to this practitioner',
  receiptedCommissionWarning:
    'Some discounts, deposits or credits may not be attributed to this practitioner',
  receiptedCommissionHelp: 'Treatment Invoiced + Deposits - Discounts',
};
