import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Patient, SubmittedForm } from '@principle-theorem/principle-core';
import {
  type IPatient,
  PatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type WithRef,
  isPathChanged$,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  type ArchivedFormsType,
  type SubmittedFormType,
} from '../patient-details-history-list/patient-details-history-list.store';

export interface IMedicalHistoryListState {
  patient: WithRef<IPatient> | undefined;
  submittedForm: SubmittedFormType;
  archivedForms: ArchivedFormsType;
  emptyState: boolean;
  loading: boolean;
  disabled: boolean;
}

const initialState: IMedicalHistoryListState = {
  patient: undefined,
  submittedForm: undefined,
  archivedForms: [],
  emptyState: false,
  loading: true,
  disabled: false,
};

@Injectable()
export class MedicalHistoryListStore extends ComponentStore<IMedicalHistoryListState> {
  readonly patient$ = this.select((store) => store.patient);
  readonly submittedForm$ = this.select((store) => store.submittedForm);
  readonly archivedForms$ = this.select((store) => store.archivedForms);
  readonly allForms$ = this.select(
    this.submittedForm$,
    this.archivedForms$,
    (submitted, archived) => compact([submitted, ...archived])
  );
  readonly loading$ = this.select((store) => store.loading);
  readonly disabled$ = this.select((store) => store.disabled);
  readonly emptyState$ = this.select(
    this.loading$,
    this.allForms$,
    (loading, forms) => !loading && !forms.length
  );

  readonly loadPatient = this.effect(
    (patient$: Observable<WithRef<IPatient> | undefined>) => {
      return patient$.pipe(
        filterUndefined(),
        isPathChanged$('ref.id'),
        tap((patient) => {
          this.setState({ ...initialState, patient });
          this.patchState({ loading: true });
          this.loadSubmittedForm(
            Patient.form$(patient, PatientForm.MedicalHistoryForm)
          );
        })
      );
    }
  );

  readonly loadSubmittedForm = this.effect(
    (form$: Observable<SubmittedFormType>) => {
      return form$.pipe(
        tap((submittedForm) => {
          this.patchState({ submittedForm });
          submittedForm
            ? this.loadArchivedForms(
                SubmittedForm.archivedSubmittedForms$(submittedForm)
              )
            : this.patchState({ archivedForms: [] });
          this.patchState({ loading: false });
        })
      );
    }
  );

  readonly loadArchivedForms = this.effect(
    (forms$: Observable<ArchivedFormsType>) => {
      return forms$.pipe(
        tap((archivedForms) => {
          this.patchState({ archivedForms });
        })
      );
    }
  );

  readonly loadDisabled = this.effect((disabled$: Observable<boolean>) => {
    return disabled$.pipe(tap((disabled) => this.patchState({ disabled })));
  });

  constructor() {
    super(initialState);
  }
}
