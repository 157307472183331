import { createEntityAdapter } from '@ngrx/entity';
import {
  CHART_ITEM_DISPLAY_TYPES,
  ChartSection,
  ChartType,
  ChartView,
  type ChartItemDisplayType,
  type IChartDataRenderConfig,
  type IClinicalChart,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  initFirestoreModel,
  serialise,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

export enum ChartId {
  InAppointment = 'inAppointmentChart',
  ChartableSurfaces = 'chartableSurfaceChart',
}

export interface IChartContextState {
  id: string;
  chart: WithRef<IClinicalChart> | IClinicalChart;
  filters: ChartItemDisplayType[];
  chartingAs: WithRef<IStaffer> | undefined;
  chartType: ChartType;
  chartView: ChartView;
  chartSection: ChartSection;
  isStacked: boolean;
  renderConfig: IChartDataRenderConfig;
  forceAllowInteractivity: boolean;
}

export const INITIAL_CHART = serialise({
  immutable: false,
  teeth: [],
  conditions: [],
  flaggedTreatment: {
    forks: [],
    treatments: [],
    multiTreatments: [],
  },
  ...initFirestoreModel(),
});
export const initialChartContextState = serialise({
  id: ChartId.InAppointment,
  chart: INITIAL_CHART,
  filters: [...CHART_ITEM_DISPLAY_TYPES],
  chartingAs: undefined,
  chartType: ChartType.Clinical,
  chartView: ChartView.Adult,
  chartSection: ChartSection.BothArches,
  isStacked: false,
  renderConfig: {
    teeth: [],
    chartedItems: [],
    selected: [],
    disabled: [],
  },
  forceAllowInteractivity: false,
});

export const chartAdapter =
  createEntityAdapter<SerialisedData<IChartContextState>>();
