import { User } from '@principle-theorem/principle-core';
import {
  ServiceTypeModality,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BaseStafferDestinationEntity,
  IStafferJobData,
  IStafferMigrationData,
} from '../../../destination/entities/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ProviderSourceEntity,
  type IOasisProvider,
} from '../../source/entities/providers';
import { OasisStafferMappingHandler } from '../mappings/staff';

export const STAFF_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'staff',
    label: 'Staff',
    description: ``,
  },
});

export class StafferDestinationEntity extends BaseStafferDestinationEntity<IOasisProvider> {
  destinationEntity = STAFF_DESTINATION_ENTITY;
  stafferSourceEntity = new ProviderSourceEntity();
  stafferCustomMapping = new OasisStafferMappingHandler();
  staffToUserCustomMapping = undefined;

  override sourceEntities = {
    staff: new ProviderSourceEntity(),
  };

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IStafferJobData<IOasisProvider>
  ):
    | IStafferMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.stafferRecord.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.stafferRecord.record,
        'Source staffer is invalid'
      );
    }

    const sourceStafferId = this.sourceEntities.staff
      .getSourceRecordId(data.stafferRecord.data.data)
      .toString();

    const user = User.init({
      name: data.stafferRecord.data.data.name,
      email: '',
      practices: migration.configuration.practices.map(
        (practice) => practice.ref
      ),
      brands: [migration.configuration.brand.ref],
      isEnabled: false,
    });

    return {
      sourceStafferId,
      user,
      stafferDetails: {
        providerNumber: data.stafferRecord.data.data.providerNumber ?? '',
        providerModality: ServiceTypeModality.GeneralDentist,
      },
    };
  }
}
