import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import {
  ButtonsContainerComponent,
  PipesModule,
} from '@principle-theorem/ng-shared';
import { type IDVACard } from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT } from '@principle-theorem/shared';
import { MomentModule } from 'ngx-moment';

@Component({
    selector: 'pr-dva-card-display',
    templateUrl: './dva-card-display.component.html',
    styleUrls: ['./dva-card-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatListModule,
        ButtonsContainerComponent,
        MatButtonModule,
        PipesModule,
        MomentModule,
    ]
})
export class DVACardDisplayComponent {
  readonly dateFormat: string = HISTORY_DATE_FORMAT;
  @Input() dvaCard?: IDVACard;
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}
