import {
  getSchemaText,
  initVersionedSchema,
  MixedSchema,
  RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  APPOINTMENT_STATUS_COLOUR_MAP,
  AppointmentCollection,
  AppointmentDependencyType,
  AppointmentStatus,
  AppointmentSummary,
  ChecklistType,
  EventType,
  IAppointment,
  IAppointmentDependency,
  IAutomation,
  IChecklistItem,
  IClinicalChart,
  IEvent,
  IEventHistory,
  IFollowUp,
  IInteractionV2,
  IInvoice,
  ILabJob,
  INextStage,
  InteractionType,
  IPatient,
  IPractice,
  IPrincipleMention,
  isAppointment,
  ISchedulingEvent,
  ISchedulingEventData,
  isEventable,
  IStaffer,
  IStatusHistory,
  ITreatmentPlan,
  ITreatmentStep,
  MentionResourceType,
  PatientCollection,
  PlanStepPairStatus,
  ResolvedAppointmentDependency,
  TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  addBulk,
  addDoc,
  all$,
  asDocRef,
  AtLeast,
  CASUAL_DATE_FORMAT,
  CollectionReference,
  DATE_TIME_FORMAT,
  DocumentReference,
  Firestore,
  firstResult$,
  getTimePeriodDuration,
  INamedDocument,
  initFirestoreModel,
  IReffable,
  multiFilter,
  multiSort,
  multiSwitchMap,
  orderBy,
  query$,
  sortByUpdatedAt,
  sortTimestampAsc,
  subCollection,
  Timestamp,
  toMoment,
  toTimePeriod,
  toTimestamp,
  Transaction,
  undeletedQuery,
  where,
  WithRef,
} from '@principle-theorem/shared';
import { first, startCase } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { stafferToNamedDoc } from '../common';
import { Event } from '../event/event';
import { Interaction } from '../interaction/interaction';
import { toMention } from '../mention/mention';
import { OrganisationCache } from '../organisation/organisation-cache';
import { FollowUp } from './follow-up';
import { SchedulingEvent } from './scheduling-event';

export class Appointment {
  static init(
    overrides: AtLeast<
      IAppointment,
      'practitioner' | 'treatmentPlan' | 'practice'
    >
  ): IAppointment {
    const firestoreModel = initFirestoreModel();
    return {
      eventHistory: [],
      status: AppointmentStatus.Unscheduled,
      statusHistory: [
        {
          status: overrides.status ?? AppointmentStatus.Unscheduled,
          updatedAt: firestoreModel.createdAt,
        },
      ],
      cancellationHistory: [],
      dependencies: [],
      tags: [],
      ...firestoreModel,
      ...overrides,
    };
  }

  static col(patient: IReffable<IPatient>): CollectionReference<IAppointment> {
    return subCollection<IAppointment>(patient, PatientCollection.Appointments);
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IAppointment>[]> {
    return all$(Appointment.col(patient));
  }

  static treatmentPlan$(
    appointment: IAppointment
  ): Observable<WithRef<ITreatmentPlan>> {
    return Firestore.doc$(appointment.treatmentPlan.ref);
  }

  static treatmentPlan(
    appointment: IAppointment
  ): Promise<WithRef<ITreatmentPlan>> {
    return Firestore.getDoc(appointment.treatmentPlan.ref);
  }

  static clinicalChart$(
    appointment: IAppointment
  ): Observable<WithRef<IClinicalChart> | undefined> {
    if (!appointment.clinicalChart) {
      return of(undefined);
    }
    return Firestore.doc$(appointment.clinicalChart);
  }

  static patient$(
    appointment: IReffable<IAppointment>
  ): Observable<WithRef<IPatient>> {
    return OrganisationCache.patients.doc$(Appointment.patientRef(appointment));
  }

  static async patient(
    appointment: IReffable<IAppointment>,
    transaction?: Transaction
  ): Promise<WithRef<IPatient>> {
    if (!transaction) {
      return OrganisationCache.patients.getDoc(
        Appointment.patientRef(appointment)
      );
    }
    return Firestore.getDoc(Appointment.patientRef(appointment), transaction);
  }

  static patientRef(
    appointment: IReffable<IAppointment>
  ): DocumentReference<IPatient> {
    return Firestore.getParentDocRef<IPatient>(appointment.ref.path);
  }

  static practice$(
    appointment: IAppointment
  ): Observable<WithRef<IPractice> | undefined> {
    const practice = Appointment.practice(appointment);
    if (!practice) {
      return of(undefined);
    }
    return OrganisationCache.practices.doc$(practice.ref);
  }

  static practice(appointment: IAppointment): INamedDocument<IPractice> {
    return appointment.practice;
  }

  static practitioner$(
    appointment: IAppointment
  ): Observable<WithRef<IStaffer>> {
    return OrganisationCache.staff.get.doc$(appointment.practitioner.ref);
  }

  static practitioner(appointment: IAppointment): Promise<WithRef<IStaffer>> {
    return OrganisationCache.staff.get.getDoc(appointment.practitioner.ref);
  }

  static staff$(appointment: IAppointment): Observable<WithRef<IStaffer>[]> {
    if (!isEventable(appointment)) {
      return of([]);
    }

    return combineLatest(
      Event.staff(appointment.event).map((participant) =>
        OrganisationCache.staff.get.doc$(participant.ref)
      )
    );
  }

  static invoice$(
    appointment: IAppointment
  ): Observable<WithRef<IInvoice> | undefined> {
    return appointment.invoiceRef
      ? Firestore.doc$(appointment.invoiceRef)
      : of(undefined);
  }

  static async invoice(
    appointment: IAppointment
  ): Promise<WithRef<IInvoice> | undefined> {
    return appointment.invoiceRef
      ? Firestore.getDoc(appointment.invoiceRef)
      : undefined;
  }

  static checklistCol(
    appointment: IReffable<IAppointment>
  ): CollectionReference<IChecklistItem> {
    return subCollection<IChecklistItem>(
      appointment,
      AppointmentCollection.Checklists
    );
  }

  static checklistItems$(
    appointment: IReffable<IAppointment>,
    type?: ChecklistType
  ): Observable<WithRef<IChecklistItem>[]> {
    const col = Appointment.checklistCol(appointment);
    const results$ = type
      ? query$(undeletedQuery(col), where('type', '==', type))
      : all$(undeletedQuery(col));
    return results$.pipe(
      multiSort((itemA, itemB) =>
        sortTimestampAsc(itemA.createdAt, itemB.createdAt)
      )
    );
  }

  static async addChecklistItems(
    appointment: IReffable<IAppointment>,
    checklists: IChecklistItem[]
  ): Promise<void> {
    const col = Appointment.checklistCol(appointment);
    await addBulk(col, checklists);
  }

  static async addChecklistItem(
    appointment: IReffable<IAppointment>,
    checklistItem: IChecklistItem
  ): Promise<void> {
    const col = Appointment.checklistCol(appointment);
    await addDoc(col, checklistItem);
  }

  static dependencies$(
    appointment: IAppointment
  ): Observable<ResolvedAppointmentDependency[]> {
    const dependencies = appointment.dependencies.map((dependency) =>
      Appointment.resolveDependency(dependency)
    );

    return combineLatest(dependencies);
  }

  static interactionCol(
    appointment: IReffable<IAppointment>
  ): CollectionReference<IInteractionV2> {
    return subCollection<IInteractionV2>(
      appointment.ref,
      AppointmentCollection.Interactions
    );
  }

  static interactions$(
    appointment: IReffable<IAppointment>
  ): Observable<WithRef<IInteractionV2>[]> {
    return all$(undeletedQuery(Appointment.interactionCol(appointment))).pipe(
      map((interactions) =>
        interactions.sort((interactionA, interactionB) =>
          sortTimestampAsc(interactionA.createdAt, interactionB.createdAt)
        )
      )
    );
  }

  static followUpCol(
    appointment: IReffable<IAppointment>
  ): CollectionReference<IFollowUp> {
    return subCollection<IFollowUp>(
      appointment.ref,
      AppointmentCollection.FollowUps
    );
  }

  static followUps$(
    appointment: IReffable<IAppointment>
  ): Observable<WithRef<IFollowUp>[]> {
    return all$(Appointment.followUpCol(appointment));
  }

  static schedulingEventCol(
    appointment: IReffable<IAppointment>
  ): CollectionReference<ISchedulingEvent> {
    return subCollection<ISchedulingEvent>(
      appointment.ref,
      AppointmentCollection.SchedulingEvents
    );
  }

  static schedulingEvents$(
    appointment: IReffable<IAppointment>
  ): Observable<WithRef<ISchedulingEvent>[]> {
    return all$(
      undeletedQuery(Appointment.schedulingEventCol(appointment))
    ).pipe(
      map((interactions) =>
        interactions.sort((interactionA, interactionB) =>
          sortTimestampAsc(interactionA.createdAt, interactionB.createdAt)
        )
      )
    );
  }

  static nextStage(
    appointment: WithRef<IAppointment> | AppointmentSummary
  ): INextStage | undefined {
    if (!appointment.event) {
      return;
    }

    const status = isAppointment(appointment)
      ? appointment.status
      : appointment.metadata.status;

    const due: moment.Moment = toMoment(appointment.event.from);

    switch (status) {
      case AppointmentStatus.Scheduled:
      case AppointmentStatus.Confirmed:
        return { name: 'arrival', due };
      case AppointmentStatus.Arrived:
        return { name: 'appointment', due };
      case AppointmentStatus.CheckedIn:
        return { name: 'appointment' };
      case AppointmentStatus.CheckingOut:
        return { name: 'checkout' };
      case AppointmentStatus.InProgress:
        return {
          name: 'checkout',
          due: due.add({ minutes: Event.duration(appointment.event) }),
        };
      default:
        return;
    }
  }

  static replaceEvent(appointment: IAppointment, newEvent?: IEvent): void {
    const lastEvent: IEventHistory | undefined =
      appointment.eventHistory[appointment.eventHistory.length - 1];
    const isSameEvent: boolean = lastEvent
      ? lastEvent.event === appointment.event
      : false;
    if (!isSameEvent) {
      appointment.eventHistory.push({
        createdAt: toTimestamp(),
        event: appointment.event,
      });
    }
    if (newEvent) {
      appointment.practice = newEvent.practice ?? appointment.practice;
      const newPractitioner =
        newEvent.organiser ?? first(Event.staff(newEvent));
      appointment.practitioner = newPractitioner ?? appointment.practitioner;
      newEvent.type = EventType.Appointment;
    }
    appointment.event = newEvent;
  }

  static canCheckIn(
    appointment: WithRef<IAppointment> | AppointmentSummary
  ): boolean {
    if (!appointment.event) {
      return false;
    }
    const isValidStatus =
      Appointment.isConfirmed(appointment) ||
      Appointment.isScheduled(appointment) ||
      Appointment.isArrived(appointment);
    const today = moment().startOf('day');
    const isToday =
      appointment.event &&
      toMoment(appointment.event.from).startOf('day').isSame(today);
    return isValidStatus && isToday;
  }

  static canConfirm(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Scheduled;
  }

  static canStart(appointment: IAppointment | AppointmentSummary): boolean {
    return (
      Appointment.isConfirmed(appointment) ||
      Appointment.isScheduled(appointment) ||
      Appointment.isArrived(appointment) ||
      Appointment.checkedIn(appointment)
    );
  }

  static canCheckOut(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.InProgress;
  }

  static canComplete(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.CheckingOut;
  }

  static inProgress(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.InProgress;
  }

  static isArrived(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Arrived;
  }

  static checkedIn(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.CheckedIn;
  }

  static isCheckingOut(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.CheckingOut;
  }

  static isConfirmed(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Confirmed;
  }

  static isUnscheduled(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Unscheduled;
  }

  static isScheduled(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Scheduled;
  }

  static isComplete(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Complete;
  }

  static isCancelled(appointment: IAppointment | AppointmentSummary): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Cancelled;
  }

  static matchesStatus(
    appointment: IAppointment | AppointmentSummary,
    statuses: AppointmentStatus[]
  ): boolean {
    return statuses.some(
      (status) => Appointment.getStatus(appointment) === status
    );
  }

  static canBeRescheduled(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    const status = Appointment.getStatus(appointment);
    return (
      status === AppointmentStatus.Scheduled ||
      status === AppointmentStatus.Confirmed ||
      status === AppointmentStatus.Unscheduled ||
      status === AppointmentStatus.InProgress
    );
  }

  static canBeCancelled(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    const status = Appointment.getStatus(appointment);
    return (
      status === AppointmentStatus.Scheduled ||
      status === AppointmentStatus.Confirmed ||
      status === AppointmentStatus.Arrived ||
      status === AppointmentStatus.CheckedIn
    );
  }

  static canChangeDuration(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    const status = Appointment.getStatus(appointment);
    return (
      status === AppointmentStatus.Scheduled ||
      status === AppointmentStatus.Confirmed ||
      status === AppointmentStatus.Arrived ||
      status === AppointmentStatus.CheckedIn ||
      status === AppointmentStatus.InProgress ||
      status === AppointmentStatus.CheckingOut
    );
  }

  static canRevertToConfirmed(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    const status = Appointment.getStatus(appointment);
    return (
      status === AppointmentStatus.Arrived ||
      status === AppointmentStatus.CheckedIn
    );
  }

  static canRevertToCheckedIn(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.InProgress;
  }

  static canRevertToScheduled(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    return Appointment.getStatus(appointment) === AppointmentStatus.Confirmed;
  }

  static isUnconfirmed(
    appointment: IAppointment | AppointmentSummary
  ): boolean {
    return [
      AppointmentStatus.Unscheduled,
      AppointmentStatus.Scheduled,
    ].includes(Appointment.getStatus(appointment));
  }

  static getStatus(
    appointment: IAppointment | AppointmentSummary
  ): AppointmentStatus {
    return isAppointment(appointment)
      ? appointment.status
      : appointment.metadata.status;
  }

  static isFutureAppointment(appointment: IAppointment): boolean {
    return Appointment.isAfter(appointment, moment());
  }

  static isAfter(
    appointment: IAppointment,
    time: moment.Moment = moment()
  ): boolean {
    return (
      isEventable(appointment) && toMoment(appointment.event.from).isAfter(time)
    );
  }

  static isBefore(
    appointment: IEvent,
    time: moment.Moment,
    granularity: moment.unitOfTime.StartOf
  ): boolean {
    return toMoment(appointment.from).isBefore(time, granularity);
  }

  static hasFollowUp(appointment: WithRef<IAppointment>): boolean {
    if (
      !appointment.activeFollowUp ||
      !appointment.activeFollowUp.createFollowUp
    ) {
      return false;
    }
    const followUpDate = appointment.activeFollowUp.followUpDate;
    const followUpDateSet = followUpDate
      ? toMoment(followUpDate).isAfter(moment())
      : false;
    return followUpDateSet;
  }

  static isBookable(
    appointment: WithRef<IAppointment>,
    includeEarlierToday: boolean = false
  ): boolean {
    const from = includeEarlierToday ? moment().startOf('day') : moment();
    return (
      Appointment.isUnscheduled(appointment) ||
      Appointment.isAfter(appointment, from) ||
      Appointment.hasFollowUp(appointment)
    );
  }

  static duration(appointment: IAppointment): number {
    if (isEventable(appointment)) {
      return Event.duration(appointment.event);
    }

    if (appointment.treatmentPlan) {
      return appointment.treatmentPlan.treatmentStep.duration;
    }

    return 0;
  }

  // TODO: Populate this value via a cloud function
  static getSchedulingConflicts(
    appointment: IAppointment | AppointmentSummary
  ): string[] {
    const conflicts: string[] = [];

    const practitoner = isAppointment(appointment)
      ? appointment.practitioner
      : Event.staff(appointment.event);

    if (Appointment.isUnconfirmed(appointment)) {
      conflicts.push('Awaiting Confirmation');
    }

    const hasAPractitioner = !!appointment.event && !!practitoner;

    if (!hasAPractitioner) {
      conflicts.push('Practitioner not available');
    }

    return conflicts;
  }

  static async addInteraction(
    appointment: IReffable<IAppointment>,
    interaction: IInteractionV2
  ): Promise<void> {
    await addDoc(Appointment.interactionCol(appointment), interaction);
  }

  static latestInteraction$(
    appointment: IReffable<IAppointment>
  ): Observable<WithRef<IInteractionV2> | undefined> {
    return firstResult$(
      undeletedQuery(Appointment.interactionCol(appointment)),
      orderBy('deleted'),
      orderBy('createdAt', 'desc')
    );
  }

  static async updateStatus(
    appointment: WithRef<IAppointment>,
    status: AppointmentStatus,
    interactionType: InteractionType = InteractionType.Appointment,
    owner?: INamedDocument<IStaffer>,
    content?: MixedSchema
  ): Promise<void> {
    if (appointment.status === status) {
      return;
    }
    appointment.statusHistory.push({
      status: appointment.status,
      updatedAt: toTimestamp(),
    });
    appointment.status = status;

    if (owner) {
      const formattedStatus = startCase(status);
      await Appointment.addInteraction(
        appointment,
        Interaction.init({
          title: [
            toMentionContent(toMention(owner, MentionResourceType.Staffer)),
            toTextContent(` updated status to ${formattedStatus}`),
          ],
          type: interactionType,
          owner,
          content,
        })
      );
    }
  }

  static async addFollowUp(
    appointment: WithRef<IAppointment>,
    followUp: IFollowUp,
    owner: INamedDocument<IStaffer>
  ): Promise<void> {
    await addDoc(Appointment.followUpCol(appointment), followUp);

    if (followUp.createFollowUp) {
      await Appointment.addInteraction(
        appointment,
        Interaction.init({
          title: [toTextContent(`added a follow up`)],
          content: initVersionedSchema(
            `${owner.name} has added a follow up for ${toMoment(
              followUp.followUpDate ?? moment()
            ).format(CASUAL_DATE_FORMAT)}`
          ),
          type: InteractionType.AppointmentReminder,
          owner,
        })
      );
      return;
    }
    await Appointment.addInteraction(
      appointment,
      Interaction.init({
        title: [toTextContent(`declined to add a follow up`)],
        content: initVersionedSchema(
          `${
            owner.name
          } has declined to add a follow up for the reason "${getSchemaText(
            followUp.noFollowUpReason
          )}"`
        ),
        type: InteractionType.AppointmentReminder,
        owner,
      })
    );
  }

  static async cancelFollowUp(
    appointment: WithRef<IAppointment>,
    owner: INamedDocument<IStaffer>
  ): Promise<void> {
    if (!Appointment.hasFollowUp(appointment)) {
      return;
    }
    const followUp = FollowUp.init({
      createFollowUp: false,
      followUpDate: undefined,
    });
    await addDoc(Appointment.followUpCol(appointment), followUp);

    await Appointment.addInteraction(
      appointment,
      Interaction.init({
        title: [toTextContent(`cancelled follow up`)],
        content: initVersionedSchema(
          `${owner.name} has cancelled the last follow up`
        ),
        type: InteractionType.AppointmentReminder,
        owner,
      })
    );
  }

  static async cancel(
    appointment: WithRef<IAppointment>,
    staffer: WithRef<IStaffer>,
    schedulingEventData: ISchedulingEventData,
    followUp: IFollowUp
  ): Promise<void> {
    const owner = stafferToNamedDoc(staffer);
    const eventBefore = SchedulingEvent.buildEventSnapshot(appointment);

    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.Unscheduled,
      InteractionType.AppointmentCancel
    );
    Appointment.replaceEvent(appointment);

    const reason = schedulingEventData.reason
      ? await Firestore.getDoc(schedulingEventData.reason)
      : undefined;
    appointment.cancellationHistory.push({
      reason: reason?.name ?? 'No reason provided',
      comments: schedulingEventData.comments ?? initVersionedSchema(),
    });

    if (SchedulingEvent.willCauseSchedulingEvent(eventBefore, undefined)) {
      const schedulingEvent = SchedulingEvent.init({
        scheduledByPractice: schedulingEventData.scheduledByPractice,
        scheduledByStaffer: staffer.ref,
        reason: schedulingEventData.reason,
        reasonSetManually: schedulingEventData.reasonSetManually,
        schedulingConditions: schedulingEventData.schedulingConditions,
        eventBefore,
      });

      await this.addSchedulingEvent(
        appointment,
        schedulingEvent,
        owner,
        schedulingEventData.comments
      );
    }

    await Firestore.saveDoc(appointment);
    await Appointment.addFollowUp(appointment, followUp, owner);
  }

  static async addSchedulingEvent(
    appointment: IReffable<IAppointment>,
    schedulingEvent: ISchedulingEvent,
    owner: INamedDocument<IStaffer>,
    content?: MixedSchema
  ): Promise<DocumentReference<ISchedulingEvent>> {
    const schedulingEventRef = await addDoc(
      Appointment.schedulingEventCol(appointment),
      schedulingEvent
    );

    const actionLabel = SchedulingEvent.getActionlabel(schedulingEvent);
    const interaction = Interaction.init({
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` ${actionLabel} appointment.`),
      ],
      type: SchedulingEvent.getInteractionType(schedulingEvent),
      owner,
      content: content ?? initVersionedSchema(),
      schedulingEvent: schedulingEventRef,
    });

    await Appointment.addInteraction(appointment, interaction);

    return schedulingEventRef;
  }

  static firstEnteredStatus(
    appointment: IAppointment,
    status: AppointmentStatus
  ): Timestamp | undefined {
    const event = appointment.statusHistory
      .sort(sortByUpdatedAt)
      .reverse()
      .find((log) => log.status === status);
    return event ? event.updatedAt : undefined;
  }

  static lastEnteredStatus(
    appointment: IAppointment,
    status: AppointmentStatus
  ): Timestamp | undefined {
    const event = Appointment.lastLogForStatus(appointment, status);
    return event ? event.updatedAt : undefined;
  }

  static previousStatus(
    appointment: IAppointment
  ): IStatusHistory<AppointmentStatus> | undefined {
    const ordered = appointment.statusHistory.sort(sortByUpdatedAt);
    return ordered[1];
  }

  static lastLogForStatus(
    appointment: IAppointment,
    status: AppointmentStatus
  ): IStatusHistory<AppointmentStatus> | undefined {
    return appointment.statusHistory
      .sort(sortByUpdatedAt)
      .find((log) => log.status === status);
  }

  static statusColour(status: AppointmentStatus): string {
    return APPOINTMENT_STATUS_COLOUR_MAP[status];
  }

  static statusTooltip(status: AppointmentStatus): string {
    return status.replace(/([A-Z])/g, ' $1');
  }

  static resolveDependency(
    dependency: IAppointmentDependency
  ): Observable<WithRef<ResolvedAppointmentDependency>> {
    switch (dependency.type) {
      default:
        return Firestore.doc$(asDocRef<ILabJob>(dependency.ref));
    }
  }

  static treatmentStep$(
    appointment: IAppointment
  ): Observable<WithRef<ITreatmentStep>> {
    return Firestore.doc$(appointment.treatmentPlan.treatmentStep.ref);
  }

  static treatmentStep(
    appointment: IAppointment,
    transaction?: Transaction
  ): Promise<WithRef<ITreatmentStep>> {
    return Firestore.getDoc(
      appointment.treatmentPlan.treatmentStep.ref,
      transaction
    );
  }

  static automations$(
    appointment: IAppointment
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return Firestore.doc$(appointment.treatmentPlan.treatmentStep.ref).pipe(
      switchMap((treatmentStep) => TreatmentStep.automations$(treatmentStep))
    );
  }

  static async addAutomations(
    appointment: WithRef<IAppointment>,
    automations: IAutomation<TreatmentStepAutomation>[]
  ): Promise<void> {
    const step = await Appointment.treatmentStep(appointment);
    return addBulk(TreatmentStep.automationCol(step), automations);
  }

  static async summary(
    appointment: WithRef<IAppointment>
  ): Promise<RawInlineNodes> {
    const treatmentSummary = `${appointment.treatmentPlan.name} - ${appointment.treatmentPlan.treatmentStep.name}`;
    if (appointment.event) {
      const practice = await OrganisationCache.practices.getDoc(
        appointment.event.practice.ref
      );
      const startTime = toMoment(appointment.event.from)
        .tz(practice.settings.timezone)
        .format(DATE_TIME_FORMAT);
      return [
        toMentionContent(
          toMention(
            {
              name: `${treatmentSummary} (${startTime})`,
              ref: appointment.ref,
            },
            MentionResourceType.Appointment
          )
        ),
      ];
    }
    return [
      toMentionContent(
        toMention(
          { name: `${treatmentSummary} (unscheduled)`, ref: appointment.ref },
          MentionResourceType.Appointment
        )
      ),
    ];
  }

  static async toMention(
    appointment: WithRef<IAppointment>
  ): Promise<IPrincipleMention> {
    return toMention(
      {
        name: getSchemaText(await Appointment.summary(appointment)),
        ref: appointment.ref,
      },
      MentionResourceType.Appointment
    );
  }

  static getWaitTime(appointment: WithRef<IAppointment>): number {
    const scheduled = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.Scheduled
    );
    const confirmed = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.Confirmed
    );
    const arrived = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.Arrived
    );
    const checkedIn = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.CheckedIn
    );

    const started = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.InProgress
    );

    if (!started) {
      return 0;
    }

    const fromTime = checkedIn || arrived || confirmed || scheduled;

    if (!fromTime) {
      return 0;
    }

    const range = toTimePeriod(fromTime, started);
    return getTimePeriodDuration(range, 'minutes');
  }

  static labJobs$(
    appointment: IAppointment | AppointmentSummary
  ): Observable<WithRef<ILabJob>[]> {
    return of(Appointment.getAppointmentDependencies(appointment)).pipe(
      multiFilter(
        (dependency) => dependency.type === AppointmentDependencyType.LabJob
      ),
      multiSwitchMap((dependency) =>
        Firestore.doc$(asDocRef<ILabJob>(dependency.ref))
      )
    );
  }

  static getAppointmentDependencies(
    appointment: IAppointment | AppointmentSummary
  ): IAppointmentDependency[] {
    return isAppointment(appointment)
      ? appointment.dependencies
      : appointment.metadata.dependencies;
  }

  static getCompletedAt(appointment: IAppointment): Timestamp | undefined {
    if (!Appointment.isComplete(appointment)) {
      return;
    }
    const lastCompletedStatus = Appointment.lastEnteredStatus(
      appointment,
      AppointmentStatus.Complete
    );
    return lastCompletedStatus ?? appointment.event?.to;
  }
}

export function getAppointmentStepStatus(
  appointment?: WithRef<IAppointment>
): PlanStepPairStatus {
  const currentStatus = [
    AppointmentStatus.Arrived,
    AppointmentStatus.CheckedIn,
    AppointmentStatus.InProgress,
  ];
  const completeStatus = [
    AppointmentStatus.Complete,
    AppointmentStatus.CheckingOut,
  ];

  if (!appointment || appointment.status === AppointmentStatus.Unscheduled) {
    return PlanStepPairStatus.Unscheduled;
  }

  if (currentStatus.includes(appointment.status)) {
    return PlanStepPairStatus.InProgress;
  }

  if (completeStatus.includes(appointment.status)) {
    return PlanStepPairStatus.Completed;
  }

  if (appointment.status === AppointmentStatus.Cancelled) {
    return PlanStepPairStatus.Cancelled;
  }

  return PlanStepPairStatus.Scheduled;
}
