import {
  IPricingRule,
  IPricingRuleProvider,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';

export class TieredPricingProvider implements IPricingRuleProvider {
  canProvide(rule: IPricingRule): boolean {
    return rule.type === PricingRuleType.Tiered;
  }

  determinePrice(
    pricingRule: IPricingRule,
    _unitCount: number,
    unitIndex: number
  ): number | undefined {
    const validRule = pricingRule.ruleItems.find(
      (rule) =>
        unitIndex + 1 >= rule.startUnit &&
        (!rule.endUnit || unitIndex + 1 <= rule.endUnit)
    );

    if (!validRule) {
      return 0;
    }

    return validRule.price;
  }
}
