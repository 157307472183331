<ng-container *ngIf="chartedItems$ | async as chartedItems">
  <button
    mat-menu-item
    [matMenuTriggerFor]="chartedItemsMenu"
    [disabled]="!chartedItems.length || (disabled$ | async)"
  >
    Remove Charted Item
  </button>

  <mat-menu #chartedItemsMenu="matMenu" class="pr-right-click-menu">
    <ng-template matMenuContent>
      <button
        mat-menu-item
        *ngFor="let chartedItem of chartedItems; trackBy: trackByChartedItem"
        (click)="removeChartedItem(chartedItem)"
      >
        <mat-icon
          *ngIf="chartedItem.config.display?.icon.slug"
          class="item-icon"
          [ngStyle]="{ color: chartedItem.config.display.colour.value }"
          [svgIcon]="chartedItem.config.display.icon.slug"
         />
        <span>{{ chartedItem.config.name }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
