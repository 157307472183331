import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';
import { PraktikaFeeScheduleMappingHandler } from './destination/mappings/fee-schedules';
import { PraktikaItemCodeMappingHandler } from './destination/mappings/item-code';
import { PraktikaItemCodeToTreatmentMappingHandler } from './destination/mappings/item-code-to-treatment';
import { PraktikaPracticeMappingHandler } from './destination/mappings/practices';
import { PraktikaStafferMappingHandler } from './destination/mappings/staff';
import { PraktikaStaffToUserMappingHandler } from './destination/mappings/staff-to-user';
import { PraktikaAppointmentStepSizeMappingHandler } from './destination/mappings/appointment-step-size';

export const PRAKTIKA_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'Praktika Custom Mappings',
    description: '',
  },
};

export class PraktikaCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'Praktika';
  migrationVersion = 'v2';
  customMapping = PRAKTIKA_MAPPINGS;

  mappingHandlers = [
    new PraktikaFeeScheduleMappingHandler(),
    new PraktikaItemCodeMappingHandler(),
    new PraktikaItemCodeToTreatmentMappingHandler(),
    new PraktikaPracticeMappingHandler(),
    new PraktikaStafferMappingHandler(),
    new PraktikaStaffToUserMappingHandler(),
    new PraktikaAppointmentStepSizeMappingHandler(),
  ];
}
