<div class="flex justify-between">
  <h3 class="mat-headline-6">Paired Devices</h3>
  <div class="flex items-end justify-center gap-2 p-4">
    <button mat-flat-button color="primary" (click)="addNewDevice()">
      <mat-icon>add</mat-icon>
      Add New Device
    </button>
  </div>
</div>
<mat-list>
  <mat-list-item
    *ngFor="let device of devices$ | async; trackBy: trackByDevice"
  >
    <span matListItemTitle>{{ device.name }}</span>

    <div matListItemLine class="device-id flex gap-1">
      <span>Id: {{ device.deviceId }}</span>
      <a href="javascript:void(0);" (click)="copy(device.deviceId)">
        <mat-icon matTooltip="Copy device Id to clipboard" color="accent"
          >content_copy</mat-icon
        >
      </a>
    </div>

    <div matListItemLine *ngIf="device.settings?.version" class="device-id">
      <span>Version: {{ device.settings.version }}</span>
    </div>

    <div matListItemMeta>
      <div class="flex items-center">
        <div class="mat-caption last-paired">
          <ng-container
            *ngIf="device.integrationKey as integrationKey; else notPaired"
          >
            <ng-container *ngIf="device.lastPaired">
              Last Paired:
              {{ device.lastPaired | moment | amDateFormat: dateFormat }}
            </ng-container>
          </ng-container>
          <ng-template #notPaired>
            <span class="never-paired">Never Paired</span>
          </ng-template>
        </div>

        <pr-bridge-device-status [status]="device.status" dense />

        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #moreMenu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item (click)="editDevice(device)">
              <mat-icon color="accent">edit</mat-icon>
              <span>Edit Name</span>
            </button>
            <button mat-menu-item (click)="viewLogs(device)">
              <mat-icon color="accent">feed</mat-icon>
              <span>View Logs</span>
            </button>
            <button
              mat-menu-item
              matTooltip="No settings synced for device"
              [matTooltipDisabled]="device.settings"
              [disabled]="!device.settings"
              (click)="updatedDeviceSettings(device)"
            >
              <mat-icon color="accent">settings</mat-icon>
              <span>Update Bridge Device Settings</span>
            </button>
            <button mat-menu-item (click)="generateIntegrationKey(device)">
              <mat-icon color="accent">sync</mat-icon>
              @if (device.integrationKey) {
                <span>Regenerate Access Key</span>
              } @else {
                <span>Generate Access Key</span>
              }
            </button>
            <button mat-menu-item (click)="deleteDevice(device)">
              <mat-icon color="warn">delete</mat-icon>
              <span>Delete</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </mat-list-item>
</mat-list>
