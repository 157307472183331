import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  IActionButton,
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  ISubmittedForm,
  PatientMetadataDisplayTarget,
  PatientStatus,
  type IPatient,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_WITH_YEAR,
  filterUndefined,
  multiFilter,
  multiFind,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { BehaviorSubject, ReplaySubject, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateService } from '../../date.service';
import { AccountSummaryBloc } from '../../models/account-summary-bloc';
import { StateBasedNavigationService } from '../../navigation/state-based-navigation.service';

@Component({
  selector: 'pr-patient-profile-summary',
  templateUrl: './patient-profile-summary.component.html',
  styleUrls: ['./patient-profile-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProfileSummaryComponent {
  trackByAction = TrackByFunctions.field<IActionButton>('name');
  profileImage = inject(ProfileImageService);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  expanded$ = new BehaviorSubject<boolean>(true);
  bloc: PatientProfileBloc;
  actions$ = new ReplaySubject<IActionButton[]>(1);
  emailAction$: Observable<IActionButton | undefined>;
  phoneActions$: Observable<IActionButton[]>;
  metadataTargetPatientName =
    PatientMetadataDisplayTarget.AppointmentSidebarBesidePatientName;
  metadataTargetProfileCard =
    PatientMetadataDisplayTarget.AppointmentSidebarUnderTags;

  @Input()
  set actions(actions: IActionButton[]) {
    if (actions) {
      this.actions$.next(actions);
    }
  }

  @Input() expandable = false;

  @Input()
  set patient(patient: WithRef<IPatient> | undefined) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    public stateNav: StateBasedNavigationService,
    public dateService: DateService
  ) {
    this.bloc = new PatientProfileBloc(this.patient$);
    this.emailAction$ = this.actions$.pipe(
      multiFind((action) => action.name === 'Email')
    );
    this.phoneActions$ = this.actions$.pipe(
      multiFilter((action) => ['Call', 'SMS'].includes(action.name))
    );
  }

  isDisabledPatient(status: PatientStatus): boolean {
    return Patient.isDisabledPatient(status);
  }
}

export class PatientProfileBloc {
  readonly dobFormat = DATE_WITH_YEAR;
  latestMedicalForm$: Observable<ISubmittedForm | undefined>;
  tags$: Observable<WithRef<ITag>[]>;
  age$: Observable<number | undefined>;
  phoneNumber$: Observable<string | undefined>;
  phoneLabel$: Observable<string | undefined>;
  healthFundName$: Observable<string | undefined>;
  account: AccountSummaryBloc;

  constructor(private _patient$: Observable<WithRef<IPatient>>) {
    this.account = new AccountSummaryBloc(
      this._patient$.pipe(filterUndefined())
    );
    this.tags$ = this._patient$.pipe(
      switchMap((patient) => (patient ? Patient.tags$(patient) : of([])))
    );
    this.age$ = this._patient$.pipe(
      map((patient) => (patient ? Patient.age(patient) : undefined))
    );

    const contactNumber$ = this._patient$.pipe(
      map((patient) => (patient ? first(patient.contactNumbers) : undefined))
    );
    this.phoneNumber$ = contactNumber$.pipe(
      map((contactNumber) => contactNumber?.number)
    );
    this.phoneLabel$ = contactNumber$.pipe(
      map((contactNumber) => contactNumber?.label)
    );
    this.healthFundName$ = this._patient$.pipe(
      map((patient) => patient.healthFundCard?.fundCode)
    );
  }
}
