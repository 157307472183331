import {
  IInvoice,
  InteractionType,
  InvoiceType,
} from '@principle-theorem/principle-core/interfaces';
import { multiFilter, WithRef } from '@principle-theorem/shared';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { hasTransactionsThatNeedRefunding$ } from './cancel-invoice';
import { Invoice, withTransactions$ } from './invoice';

export class InvoiceDisplayBloc {
  canViewTransactions$: Observable<boolean>;
  canAddTransactions$: Observable<boolean>;
  canEditInvoice$: Observable<boolean>;
  canCancelInvoice$: Observable<boolean>;
  requiresRefundsToCancel$: Observable<boolean>;
  canWriteOffInvoice$: Observable<boolean>;
  canIssueInvoice$: Observable<boolean>;
  canReplaceInvoice$: Observable<boolean>;
  canDelete$: Observable<boolean>;
  canRevertToIssued$: Observable<boolean>;
  canAmendInvoice$: Observable<boolean>;
  canBalanceOverpaymentWithDeposit$: Observable<boolean>;
  hasAmendmentHistory$: Observable<boolean>;
  hasRelatedAppointments$: Observable<boolean>;
  isDeleted$: Observable<boolean>;
  noteInteractionCount$: Observable<number>;
  isInvoice$: Observable<boolean>;
  isCreditNote$: Observable<boolean>;

  constructor(public invoice$: Observable<WithRef<IInvoice>>) {
    this.isDeleted$ = this.invoice$.pipe(map((invoice) => invoice.deleted));
    this.canViewTransactions$ = this.invoice$.pipe(
      map((invoice) => Invoice.canViewTransactions(invoice))
    );
    this.canAddTransactions$ = this.invoice$.pipe(
      map((invoice) => Invoice.canAddTransactions(invoice))
    );
    this.canEditInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canEditInvoice(invoice))
    );
    this.canCancelInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canCancelInvoice(invoice))
    );
    this.requiresRefundsToCancel$ = this.invoice$.pipe(
      switchMap((invoice) => hasTransactionsThatNeedRefunding$(invoice))
    );
    this.canWriteOffInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canWriteOffInvoice(invoice))
    );
    this.canIssueInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canIssueInvoice(invoice))
    );
    this.canReplaceInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canReplaceInvoice(invoice))
    );
    this.canAmendInvoice$ = this.invoice$.pipe(
      map((invoice) => Invoice.canAmendInvoice(invoice))
    );
    this.hasAmendmentHistory$ = this.invoice$.pipe(
      switchMap((invoice) => Invoice.amendmentHistory$(invoice)),
      map((amendmentHistory) => amendmentHistory.length > 0)
    );
    this.hasRelatedAppointments$ = this.invoice$.pipe(
      switchMap((invoice) => Invoice.getAssociatedAppointments$(invoice)),
      map((appointments) => appointments.length > 0)
    );
    this.canDelete$ = this.invoice$.pipe(
      switchMap((invoice) =>
        iif(
          () => Invoice.canEditInvoice(invoice),
          Invoice.getAssociatedAppointment$(invoice).pipe(
            map((appointment) => appointment === undefined)
          ),
          of(false)
        )
      )
    );
    this.canRevertToIssued$ = this.invoice$.pipe(
      map((invoice) => Invoice.canRevertToIssued(invoice))
    );
    this.canBalanceOverpaymentWithDeposit$ = this.invoice$.pipe(
      switchMap((invoice) => withTransactions$(invoice)),
      map(([invoice, transactions]) =>
        Invoice.isOverpaid(invoice, transactions)
      )
    );
    this.noteInteractionCount$ = this.invoice$.pipe(
      switchMap((invoice) => Invoice.interactions$(invoice)),
      multiFilter((interaction) => interaction.type === InteractionType.Note),
      map((interactions) => interactions.length)
    );
    this.isCreditNote$ = this.invoice$.pipe(
      map((invoice) => invoice.type === InvoiceType.CreditNote)
    );
    this.isInvoice$ = this.invoice$.pipe(
      map((invoice) => invoice.type !== InvoiceType.CreditNote)
    );
  }
}
