import { type ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IWorkspaceSelector } from '@principle-theorem/ng-auth';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { type IUserMetadata } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  patchDoc,
  snapshot,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { OrganisationWorkspaceSelectorComponent } from '../../../components/organisation-workspace-selector/organisation-workspace-selector.component';

@Component({
  selector: 'pr-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent {
  workspaceSelectors: ComponentType<IWorkspaceSelector>[] = [
    OrganisationWorkspaceSelectorComponent,
  ];

  constructor(private _org: OrganisationService) {}

  async afterSuccess(): Promise<void> {
    const user: WithRef<IUserMetadata> = await snapshot(
      this._org.user$.pipe(filterUndefined())
    );
    await patchDoc(user.ref, { lastSignInTime: toTimestamp() });
  }
}
