import { Organisation } from '@principle-theorem/principle-core';
import { DocumentReference } from '@principle-theorem/shared';
import { compact, uniq } from 'lodash';
import { SortOrder } from './querying';

export function toDatasetName(orgRef: DocumentReference<Organisation>): string {
  const prefix = 'org';
  return `${prefix}_${orgRef.id}`.toLowerCase();
}

export function getRowNumberAttribute(
  rowNumberAlias: string,
  uniqueBy: string = 'ref.referenceValue',
  orderBy?: string,
  orderByDirection: SortOrder = SortOrder.Descending
): string {
  const orderByProperty = uniq(compact([orderBy, 'timestamp.seconds']))
    .map((orderByProp) => `${orderByProp} ${orderByDirection}`)
    .join(', ');
  return `ROW_NUMBER() OVER(PARTITION BY ${uniqueBy} ORDER BY ${orderByProperty}) AS ${rowNumberAlias}`;
}
