import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  IEvent,
  IStaffer,
  ITypesenseWaitListWithRef,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  DocumentReference,
  Timezone,
  asDocRef,
  toDayOfWeek,
  toMomentTz,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import * as moment from 'moment-timezone';
import { Event } from '../event/event';

export enum ScoreClass {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface IAppointmentMatchScoreFields {
  duration: number;
  from?: moment.Moment;
  practitionerRef: DocumentReference<IStaffer>;
  urgency: WaitListUrgency;
  appointmentTimezone: Timezone;
  days: DayOfWeek[];
}

export class WaitListScore {
  static getMatchScore(
    typesenseWaitlist: ITypesenseWaitListWithRef,
    gapEvent: IEvent
  ): number {
    const scoreFields =
      WaitListScore.getAppointmentScoreFields(typesenseWaitlist);
    return WaitListScore.calculateMatchScore(scoreFields, gapEvent);
  }

  static calculateMatchScore(
    appointment: IAppointmentMatchScoreFields,
    gapEvent: IEvent
  ): number {
    const daysWeight = 0.4;
    const durationWeight = 0.6;

    const durationScore = WaitListScore.calculateDurationScore(
      appointment,
      gapEvent
    );

    if (!appointment.days.length) {
      return roundTo2Decimals(durationScore);
    }

    const dayScore = WaitListScore.calculateDaysScore(appointment, gapEvent);

    return roundTo2Decimals(
      sum([dayScore * daysWeight, durationScore * durationWeight])
    );
  }

  static calculateDaysScore(
    appointment: IAppointmentMatchScoreFields,
    gapEvent: IEvent
  ): number {
    const gapDay = toDayOfWeek(gapEvent.from, appointment.appointmentTimezone);
    return appointment.days.includes(gapDay) ? 1 : 0;
  }

  static calculateDurationScore(
    appointment: IAppointmentMatchScoreFields,
    gapEvent: IEvent
  ): number {
    const appointmentDuration = appointment.duration;
    const gapDuration = Event.duration(gapEvent);

    const durationDifference = Math.abs(gapDuration - appointmentDuration);
    return 1 - durationDifference / gapDuration;
  }

  static getAppointmentScoreFields(
    typesenseWaitList: ITypesenseWaitListWithRef
  ): IAppointmentMatchScoreFields {
    return {
      duration: typesenseWaitList.appointmentDuration ?? 0,
      from: typesenseWaitList.appointmentFrom
        ? toMomentTz(
            moment.unix(typesenseWaitList.appointmentFrom),
            typesenseWaitList.appointmentTimezone
          )
        : undefined,
      practitionerRef: asDocRef<IStaffer>(typesenseWaitList.practitionerRef),
      urgency: typesenseWaitList.urgency,
      days: typesenseWaitList.days,
      appointmentTimezone: typesenseWaitList.appointmentTimezone,
    };
  }

  static getScoreClass(score: number): ScoreClass {
    const highScore = 0.9;
    const mediumScore = 0.6;

    return score >= highScore
      ? ScoreClass.High
      : score >= mediumScore
        ? ScoreClass.Medium
        : ScoreClass.Low;
  }
}
