import {
  eventSpansDay,
  getDayRange,
  getRangeDuration,
  momentIsWithinDayBounds,
  multiFilter,
  rangeMaxDuration,
  rangeMinDuration,
  toMomentRange,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type MonoTypeOperatorFunction } from 'rxjs';
import { type CalendarEventEntity } from './calendar-event-entity';
import { IScheduleSummaryEventable } from '@principle-theorem/principle-core/interfaces';

export function filterToFullDayEvents$(): MonoTypeOperatorFunction<
  CalendarEventEntity[]
> {
  return multiFilter((event) => {
    const duration = getRangeDuration(getDayRange()).subtract(1, 'minute');
    const eventRange = toMomentRange(event.event.from, event.event.to);
    return rangeMinDuration(eventRange, duration);
  });
}

export function filterToPartialDayEvents$(): MonoTypeOperatorFunction<
  IScheduleSummaryEventable[]
> {
  return multiFilter((event) => {
    const duration = getRangeDuration(getDayRange()).subtract(1, 'minute');
    const eventRange = toMomentRange(event.event.from, event.event.to);
    return rangeMaxDuration(eventRange, duration);
  });
}

export function filterEventsIncludedInDay$(
  day: Moment
): MonoTypeOperatorFunction<CalendarEventEntity[]> {
  return multiFilter((event) => {
    const eventRange = toMomentRange(event.event.from, event.event.to);
    return (
      momentIsWithinDayBounds(eventRange.from, day) ||
      momentIsWithinDayBounds(eventRange.to, day) ||
      eventSpansDay(eventRange, day)
    );
  });
}

export function filterEventsStartEndOnDay$(
  day: Moment
): MonoTypeOperatorFunction<IScheduleSummaryEventable[]> {
  return multiFilter((event) => {
    const eventRange = toMomentRange(event.event.from, event.event.to);
    return (
      momentIsWithinDayBounds(eventRange.from, day) &&
      momentIsWithinDayBounds(eventRange.to, day)
    );
  });
}
