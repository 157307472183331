import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  PatientMetadataDisplay,
  SchedulingEventReason,
} from '@principle-theorem/principle-core';
import {
  IPatientMetadataDisplay,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  addDoc,
  asyncForEach,
  deleteDoc,
  isSameRef,
  where,
} from '@principle-theorem/shared';
import {
  EditCancellationReasonDialogComponent,
  IEditCancellationReasonRequest,
  IEditCancellationReasonResponse,
  SchedulingEventReasonFormData,
} from './edit-cancellation-reason-dialog/edit-cancellation-reason-dialog.component';

@Injectable()
export class SchedulingEventReasonService {
  constructor(
    private _brandScope: CurrentBrandScope,
    private _dialog: MatDialog
  ) {}

  async create(): Promise<void> {
    const response = await this._openEditDialog();
    if (!response) {
      return;
    }

    const brand = await this._brandScope.toPromise();
    const metadataKey = SchedulingEventReason.getMetadataKey(
      response.schedulingEventReason.name
    );
    const metadataDisplayRef = await addDoc(
      Brand.patientMetadataDisplayCol(brand),
      PatientMetadataDisplay.init({ ...response.metadataDisplay, metadataKey })
    );
    const reasonRef = await addDoc(
      Brand.cancellationReasonCol(brand),
      SchedulingEventReason.init({
        ...response.schedulingEventReason,
        metadataDisplayRef,
        metadataKey,
      })
    );

    await this._ensureSingleDefaults(response.schedulingEventReason, reasonRef);
  }

  async edit(
    cancellationReason: WithRef<ISchedulingEventReason>
  ): Promise<void> {
    const metadataDisplay = await Firestore.getDoc(
      cancellationReason.metadataDisplayRef
    );
    const response = await this._openEditDialog(
      cancellationReason,
      metadataDisplay
    );
    if (!response) {
      return;
    }
    await Firestore.saveDoc({
      ...cancellationReason,
      ...response.schedulingEventReason,
    });
    await Firestore.patchDoc(cancellationReason.metadataDisplayRef, {
      ...response.metadataDisplay,
    });
    await this._ensureSingleDefaults(
      response.schedulingEventReason,
      cancellationReason.ref
    );
  }

  async delete(
    cancellationReason: WithRef<ISchedulingEventReason>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Cancellation Reason',
      prompt: 'Are you sure you want to delete this cancellation reason?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(cancellationReason.ref);
    await deleteDoc(cancellationReason.metadataDisplayRef);
  }

  async restore(
    cancellationReason: WithRef<ISchedulingEventReason>
  ): Promise<void> {
    await Firestore.patchDoc(cancellationReason.ref, { deleted: false });
    await Firestore.patchDoc(cancellationReason.metadataDisplayRef, {
      deleted: false,
    });
  }

  private async _openEditDialog(
    cancellationReason?: WithRef<ISchedulingEventReason>,
    metadataDisplay?: WithRef<IPatientMetadataDisplay>
  ): Promise<IEditCancellationReasonResponse | undefined> {
    return this._dialog
      .open<
        EditCancellationReasonDialogComponent,
        IEditCancellationReasonRequest,
        IEditCancellationReasonResponse | undefined
      >(
        EditCancellationReasonDialogComponent,
        DialogPresets.flex({ data: { cancellationReason, metadataDisplay } })
      )
      .afterClosed()
      .toPromise();
  }

  private async _ensureSingleDefaults(
    updatedReason: SchedulingEventReasonFormData,
    reasonRef: DocumentReference<ISchedulingEventReason>
  ): Promise<void> {
    if (updatedReason.fillGapDefault) {
      await this._clearDefault(reasonRef, 'fillGapDefault');
    }
    if (updatedReason.moveToSameDayDefault) {
      await this._clearDefault(reasonRef, 'moveToSameDayDefault');
    }
  }

  private async _clearDefault(
    reasonRef: DocumentReference<ISchedulingEventReason>,
    key: keyof SchedulingEventReasonFormData
  ): Promise<void> {
    const brand = await this._brandScope.toPromise();
    const reasons = await Firestore.getDocs(
      Brand.cancellationReasonCol(brand),
      where(key, '==', true)
    );

    await asyncForEach(reasons, async (reason) => {
      if (isSameRef(reason.ref, reasonRef) || reason.deleted) {
        return;
      }

      await Firestore.patchDoc(reason.ref, { [key]: false });
    });
  }
}
