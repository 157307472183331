import { rand, randNumber, randSentence } from '@ngneat/falso';
import {
  initVersionedSchema,
  RawInlineNodes,
  toTextContent,
  VersionedSchema,
} from '@principle-theorem/editor';
import {
  IAppointment,
  IAppointmentDocument,
  IInteraction,
  ILab,
  ILabJob,
  ILabJobType,
  IPatient,
  IStatusHistory,
  LabJobStatus,
  LAB_JOB_STATUSES,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  INamedDocument,
  MockGenerator,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { Timestamp } from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { InteractionMock } from '../interaction/interaction.mock';
import { MockLabJobType } from '../lab/lab.mock';

export class LabJobMock
  extends BaseFirestoreMock
  implements Properties<ILabJob>
{
  get title(): RawInlineNodes {
    return [toTextContent(randSentence())];
  }

  get description(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get dueDate(): Timestamp {
    return MockTimestamp();
  }

  get completedDate(): Timestamp {
    return MockTimestamp();
  }

  get interactions(): IInteraction[] {
    return [MockGenerator.generate(InteractionMock)];
  }

  get lab(): INamedDocument<ILab> {
    return MockNamedDocument<ILab>();
  }

  get status(): LabJobStatus {
    return rand(LAB_JOB_STATUSES);
  }

  get statusHistory(): IStatusHistory<LabJobStatus>[] {
    return [
      {
        status: LabJobStatus.Sending,
        updatedAt: MockTimestamp(),
      },
      {
        status: LabJobStatus.Sent,
        updatedAt: MockTimestamp(),
      },
    ];
  }

  get patient(): INamedDocument<IPatient> {
    return MockNamedDocument<IPatient>();
  }

  get appointment(): IAppointmentDocument {
    return MockAppointmentDocument();
  }

  get type(): ILabJobType {
    return MockLabJobType();
  }

  get cost(): number {
    return randNumber({
      min: 200,
      max: 800,
    });
  }
}

function MockAppointmentDocument(): IAppointmentDocument {
  return {
    treatmentPlanName: randSentence(),
    ref: MockDocRef<IAppointment>(),
  };
}
