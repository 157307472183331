<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="50" class="layout-margin" *ngIf="newAndExistingChartBuilder">
    <h2>Patients Seen</h2>
    <pr-chart [chartBuilder]="newAndExistingChartBuilder" />
  </div>

  <div fxFlex="50" class="layout-margin" *ngIf="ftaAndUtaChartBuilder">
    <h2>FTAs &amp; UTAs</h2>
    <pr-chart [chartBuilder]="ftaAndUtaChartBuilder" />
  </div>
</div>

<div class="layout-margin">
  <pr-table-chart
    fxFlex
    [chart]="tableChartCard"
    [replacementHeaders]="[{ from: 'Owner', to: 'Practitioner' }]"
   />
</div>
