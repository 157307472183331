import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';
import { CorePracticeAppointmentBookToPractitionerMappingHandler } from './destination/mappings/appointment-book-to-practitioner';
import { CorePracticeEventClassToTreatmentCategoryMappingHandler } from './destination/mappings/event-class-to-treatment-category';
import { CorePracticeExcludedAppointmentBooksMappingHandler } from './destination/mappings/exclude-appointment-books';
import { CorePracticeItemCodeToConditionMappingHandler } from './destination/mappings/item-code-to-condition';
import { CorePracticeItemCodeToTreatmentMappingHandler } from './destination/mappings/item-code-to-treatment';
import { CorePracticeItemCodeMappingHandler } from './destination/mappings/item-codes';
import { CorePracticePracticeMappingHandler } from './destination/mappings/practices';
import { CorePracticeStafferMappingHandler } from './destination/mappings/staff';
import { CorePracticeStaffToUserMappingHandler } from './destination/mappings/staff-to-user';
import { CorePracticeMediaTagMappingHandler } from './destination/mappings/media-category-to-media-tag';
import { CorePracticeAllergyMappingHandler } from './destination/mappings/allergy-to-medical-history';
import { CorePracticeMedicalConditionMappingHandler } from './destination/mappings/medical-condition-to-medical-history';
import { CorePracticePaymentTypeMappingHandler } from './destination/mappings/payment-type-to-provider';

export const CORE_PRACTICE_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'Core Practice Custom Mappings',
    description: '',
  },
};

export class CorePracticeCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'Core Practice';
  migrationVersion = 'v1';
  customMapping = CORE_PRACTICE_MAPPINGS;

  mappingHandlers = [
    new CorePracticePracticeMappingHandler(),
    new CorePracticeStafferMappingHandler(),
    new CorePracticeItemCodeMappingHandler(),
    new CorePracticeItemCodeToConditionMappingHandler(),
    new CorePracticeItemCodeToTreatmentMappingHandler(),
    new CorePracticeAppointmentBookToPractitionerMappingHandler(),
    new CorePracticeExcludedAppointmentBooksMappingHandler(),
    new CorePracticeStaffToUserMappingHandler(),
    new CorePracticeEventClassToTreatmentCategoryMappingHandler(),
    new CorePracticeMediaTagMappingHandler(),
    new CorePracticeMedicalConditionMappingHandler(),
    new CorePracticeAllergyMappingHandler(),
    new CorePracticePaymentTypeMappingHandler(),
  ];
}
