import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';

export enum PraktikaTemplateType {
  Sms = 1,
  PhoneCall = 2,
  File = 3,
  Document = 4,
  Event = 5,
  Onlineform = 6,
  SignedDocument = 7,
}

export enum PraktikaCommunicationDirection {
  Out = 1,
  In = 2,
}

export interface IPraktikaPatientCommunication {
  id: number;
  type_id: PraktikaTemplateType;
  patient_id: number;
  direction_id: PraktikaCommunicationDirection;
  document_id: number | null;
  file_id: number | null; // TODO: Figure out how to resolve a file
  file_type_id: number | null;
  outcome_id: number | string | null;
  sms_status_id: number;
  summary: string;
  notes: string;
  content: string;
  created: string; // 2018-12-10 18:43
}

export function isPraktikaCommunicationRecord(
  item: unknown
): item is IPraktikaPatientCommunication {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.patient_id) &&
    isNumber(item.type_id) &&
    isNumber(item.direction_id) &&
    (isNumber(item.document_id) || isNull(item.document_id)) &&
    (isNumber(item.file_id) || isNull(item.file_id)) &&
    (isNumber(item.file_type_id) || isNull(item.file_type_id)) &&
    (isNumber(item.outcome_id) ||
      isString(item.outcome_id) ||
      isNull(item.outcome_id)) &&
    isNumber(item.sms_status_id) &&
    isString(item.summary) &&
    isString(item.notes) &&
    isString(item.content) &&
    isString(item.created)
  );
}

export const PATIENT_COMMUNICATION_RESOURCE_TYPE = 'patientCommunication';

export const PATIENT_COMMUNICATION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Communication List',
      description: '',
      idPrefix: PATIENT_COMMUNICATION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaPatientCommunicationFilters {
  patientId: string;
  created: Timestamp;
}

export interface IPraktikaPatientCommunicationTranslations {
  created: Timestamp;
}

const PATIENT_COMMUNICATION_SOURCE_QUERY = `
SELECT
  iRecordId as id,
  iTypeId as type_id,
  vchSummary as summary,
  iPatientNumber as patient_id,
  iDirectionId as direction_id,
  vchNote as notes,
  iFileId as file_id,
  iFileTypeId as file_type_id,
  iDocumentId as document_id,
  vchContent as content,
  iSMSStatusId as sms_status_id,
  dtCreated as created,
  iOutcomeId as outcome_id
FROM patient_comms
ORDER BY id DESC
`;

export class PatientCommunicationSourceEntity extends BaseSourceEntity<
  IPraktikaPatientCommunication,
  IPraktikaPatientCommunicationTranslations,
  IPraktikaPatientCommunicationFilters
> {
  sourceEntity = PATIENT_COMMUNICATION_SOURCE_ENTITY;
  entityResourceType = PATIENT_COMMUNICATION_RESOURCE_TYPE;
  sourceQuery = PATIENT_COMMUNICATION_SOURCE_QUERY;
  verifySourceFn = isPraktikaCommunicationRecord;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaPatientCommunicationFilters =
    'created';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    communication: IPraktikaPatientCommunication,
    timezone: Timezone
  ): IPraktikaPatientCommunicationTranslations {
    return {
      created: toTimestamp(
        moment.tz(communication.created, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaPatientCommunication): string {
    return `${data.id}-${data.type_id}`;
  }

  getSourceLabel(data: IPraktikaPatientCommunication): string {
    return data.id.toString();
  }

  getFilterData(
    data: IPraktikaPatientCommunication,
    timezone: Timezone
  ): IPraktikaPatientCommunicationFilters {
    return {
      created: toTimestamp(
        moment.tz(data.created, ISO_DATE_TIME_FORMAT, timezone)
      ),
      patientId: data.patient_id.toString(),
    };
  }
}
