import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { type IPractitionerIncomeReportGrouping } from '@principle-theorem/reporting';
import { ReplaySubject } from 'rxjs';
import { PractitionerTransactionsReportStore } from '../../practitioner-transactions-report.store';

export interface IFooterData {
  practitioner?: string;
  reservedAccountCreditAmount?: number;
  accountCreditAmount?: number;
  discountAmount?: number;
  paymentAmount?: number;
  practitionerProportionAmount?: number;
}

@Component({
    selector: 'pr-practitioner-transactions-report-summary-table',
    templateUrl: './practitioner-transactions-report-summary-table.component.html',
    styleUrls: [
        './practitioner-transactions-report-summary-table.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerTransactionsReportSummaryTableComponent
  implements OnDestroy
{
  tooltips = {
    reservedAccountCredit:
      'Account Credits that have been reserved for this practitioner. This is the equivalent to the Account Credit Report for the given practitioner (used for practices that pay a practitioner when the deposit is received).',
    accountCreditUsed: `Account Credits used to pay for practitioner's treatment`,
    discountsGiven: `Discounts given to patients for practitioner's treatment. This will include discounts given by the practitioner as well as general discounts given on the invoice.`,
    payments: `All payments received for practitioner's treatment (all transactions excluding account credits and discounts)`,
    practitionerProportion: `Amount of payments + account credits used to pay for the practitioner's treatment. Discounts are omitted from this value.`,
  };
  dataSource: ObservableDataSource<IPractitionerIncomeReportGrouping>;
  displayedColumns = [
    'practitioner',
    'reservedAccountCreditAmount',
    'accountCreditAmount',
    'discountAmount',
    'paymentAmount',
    'practitionerProportionAmount',
  ];
  summaries$ = new ReplaySubject<IPractitionerIncomeReportGrouping[]>(1);

  @Input() showHeaders = true;
  @Input() footerData?: IFooterData;

  @Input()
  set summaries(summaries: IPractitionerIncomeReportGrouping[]) {
    if (summaries) {
      this.summaries$.next(summaries);
    }
  }

  constructor(public store: PractitionerTransactionsReportStore) {
    this.dataSource = new ObservableDataSource(this.summaries$);
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }
}
