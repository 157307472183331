import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-patient-medical-alert-details',
    templateUrl: './patient-medical-alert-details.component.html',
    styleUrls: ['./patient-medical-alert-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientMedicalAlertDetailsComponent {
  trackByCondition = TrackByFunctions.variable<string>();
  trackByAllergies = TrackByFunctions.variable<string>();
  trackByHistory = TrackByFunctions.variable<string>();
  conditions$ = new ReplaySubject<string[]>(1);
  allergies$ = new ReplaySubject<string[]>(1);
  history$ = new ReplaySubject<string[]>(1);

  @Input()
  set conditions(conditions: string[]) {
    if (conditions) {
      this.conditions$.next(conditions);
    }
  }

  @Input()
  set allergies(allergies: string[]) {
    if (allergies) {
      this.allergies$.next(allergies);
    }
  }

  @Input()
  set history(history: string[]) {
    if (history) {
      this.history$.next(history);
    }
  }
}
