<mat-tab-group
  class="sticky"
  mat-align-tabs="center"
  [selectedIndex]="gapStore.displayTab$ | async"
>
  <mat-tab label="Gaps">
    <ng-template matTabContent>
      <ng-container *ngrxLet="gapStore.displayInSideBar$ as displayInSideBar">
        <div
          *ngrxLet="gapStore.selectedGap$ as selectedGap"
          class="sidebar-container"
        >
          @if (selectedGap && displayInSideBar) {
            <pr-gap-candidate-shortlist
              (clearGap)="gapStore.setSelectedGap(undefined)"
            />
          } @else {
            <pr-gap-item-list [groupedGaps]="groupedGaps$ | async" />
          }
        </div>
      </ng-container>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>Pending Gaps</span>
      <ng-container *ngrxLet="gapStore.pendingGaps$ | async as pendingGaps">
        <div
          *ngIf="!!pendingGaps.length"
          class="my-1"
          matBadgeOverlap="false"
          matBadgeColor="warn"
          [matBadge]="pendingGaps.length"
        ></div>
      </ng-container>
    </ng-template>
    <ng-template matTabContent>
      <div class="sidebar-container">
        <pr-pending-gaps-list
          [groupedPendingGaps]="groupedPendingGaps$ | async"
        />
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
