import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { isObject, type Timezone } from '@principle-theorem/shared';
import { isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE = 'appointmentStatusType';

export const APPOINTMENT_STATUS_TYPE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Status Type List',
      description: '',
      idPrefix: APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WAppointmentStatusType {
  id: number;
  abbreviation: string;
  description: string | null;
}

export function isD4WAppointmentStatusType(
  item: unknown
): item is ID4WAppointmentStatusType {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.abbreviation) &&
    (isString(item.description) || isNull(item.description))
  );
}

const APPOINTMENT_STATUS_TYPE_SOURCE_QUERY = `
SELECT
  column_type_status_id AS id,
  column_type_status_abbreviatio AS abbreviation,
  convert_empty_string_to_null(column_type_status_description) AS description
FROM a_slot_column_status_types
WHERE id IS NOT NULL
`;

export class AppointmentStatusTypeSourceEntity extends BaseSourceEntity<ID4WAppointmentStatusType> {
  sourceEntity = APPOINTMENT_STATUS_TYPE_SOURCE_ENTITY;
  entityResourceType = APPOINTMENT_STATUS_TYPE_RESOURCE_TYPE;
  sourceQuery = [
    `${APPOINTMENT_STATUS_TYPE_SOURCE_QUERY} AND convert_to_boolean(is_active) = TRUE`,
    APPOINTMENT_STATUS_TYPE_SOURCE_QUERY,
  ];
  verifySourceFn = isD4WAppointmentStatusType;
  override defaultOffsetSize = 1000;

  translate(
    _appointment: ID4WAppointmentStatusType,
    _timezone: Timezone
  ): unknown {
    return {};
  }

  getSourceRecordId(data: ID4WAppointmentStatusType): number {
    return data.id;
  }

  getSourceLabel(data: ID4WAppointmentStatusType): string {
    return `${data.id} (${data.abbreviation}) ${data.description ?? ''}`;
  }

  getFilterData(_data: ID4WAppointmentStatusType, _timezone: Timezone): object {
    return {};
  }
}
