import {
  ServiceProviderHandler,
  TreatmentConfiguration,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  ALL_SERVICE_CODES,
  CustomMappingAssociatedValueType,
  CustomMappingType,
  type IServiceCode,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITreatmentConfiguration,
  ITranslationMap,
  CustomMappingOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  isINamedDocument,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { type IBaseMigrationItemCode } from '../interfaces';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { ItemCodeResourceMapType, ItemCodesToXLSX } from './item-codes-to-xlsx';
import { XLSXToItemCodes } from './xlsx-to-item-codes';
import { ITEM_CODE_TO_SERVICE_CODE_MAPPINGS } from './ada-code-mappings';

export const ITEM_CODE_CUSTOM_MAPPING_TYPE = 'itemCodeMapping';

export const ITEM_CODE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Item Codes',
    description: `
      Define where non-ADA item codes from the source PMS should be mapped to in Principle. The different types and their effects are as follows:
        - Service Code: Map this to a real service code in Principle.
        - Treatment Configuration: Will map this service to a configuration in Principle. eg. 'BOTOX' could be mapped to a 'Botox Treatment' configuration.
        - Omit: Exclude this item code entirely from the migration

      If Service Code or Treatment Configuration are selected, a value MUST be selected.
    `,
    type: ITEM_CODE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export abstract class ItemCodesMappingHandler<
  ADAItem extends object,
  SourceEntity extends BaseSourceEntity<ADAItem>,
> extends BaseCustomMappingHandler<object, ItemCodeResourceMapType> {
  customMapping = ITEM_CODE_MAPPING;
  abstract translateFn: (record: ADAItem) => IBaseMigrationItemCode;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const itemCodes = await this.getItemCodeOptions(migration);
    return itemCodes.map((record) => ({
      label: `${record.itemCode} - ${record.description}`,
      value: record.itemCode,
    }));
  }

  selectedOptionRequiresValue(
    destinationValue: ItemCodeResourceMapType
  ): boolean {
    return [
      ItemCodeResourceMapType.TreatmentConfiguration,
      ItemCodeResourceMapType.ServiceCode,
    ].includes(destinationValue);
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: ItemCodeResourceMapType.ServiceCode,
          description: 'Map this to a service code',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.String,
          associatedValueDescription:
            'Which service code should be used for this?',
        },
        {
          value: ItemCodeResourceMapType.TreatmentConfiguration,
          description: 'Map this to a treatment configuration',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which treatment configuration should be used for this?',
        },
        {
          value: ItemCodeResourceMapType.Omit,
          description: '',
          hasAssociatedValue: false,
        },
      ])
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: ItemCodeResourceMapType
  ): Observable<{ name: string }[]> {
    if (destinationValue === ItemCodeResourceMapType.ServiceCode) {
      return of(
        sortBy(
          ALL_SERVICE_CODES.map((code) => ({ name: String(code.code) })),
          'name'
        )
      );
    }

    if (destinationValue === ItemCodeResourceMapType.TreatmentConfiguration) {
      return TreatmentConfiguration.all$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      );
    }

    return of([]);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `item-code-mapping`;
    const itemCodes = await this.getItemCodeOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      itemCodes,
      new ItemCodesToXLSX(
        await snapshot(this.getRecords$(translationMap)),
        await this._getTreatmentConfigurations(migration),
        ITEM_CODE_TO_SERVICE_CODE_MAPPINGS
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodes()
    );
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await snapshot(this.getRecords$(translationMap));
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = item.description
        ? `${item.code} - ${item.description}`
        : item.code;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value) {
        return;
      }

      const treatmentConfigurations =
        await this._getTreatmentConfigurations(migration);
      const treatmentConfiguration = treatmentConfigurations.find(
        (config) => config.name === item.associatedValue
      );
      if (treatmentConfiguration) {
        await this.upsertRecord(
          {
            associatedValue: treatmentConfiguration,
            destinationValue: ItemCodeResourceMapType.TreatmentConfiguration,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      const serviceCode = this.getServiceCode(item.associatedValue);
      if (serviceCode) {
        await this.upsertRecord(
          {
            associatedValue: serviceCode.code.toString(),
            destinationValue: ItemCodeResourceMapType.ServiceCode,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }
    });
  }

  getServiceCode(code?: string): IServiceCode | undefined {
    if (!code) {
      return;
    }
    return ServiceProviderHandler.findServiceCode(code);
  }

  async getItemCodeOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map(this.translateFn)
      .filter(
        (record) => !ServiceProviderHandler.findServiceCode(record.itemCode)
      );
    return sortBy(
      uniqBy(options, (option) => option.itemCode),
      'itemCode'
    );
  }

  private async _getTreatmentConfigurations(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentConfiguration>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          TreatmentConfiguration.col(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }
}

export function resolveMappedCode(
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[],
  sourceIdentifier: string,
  serviceCode: string
): IServiceCode | undefined {
  const code = ServiceProviderHandler.findServiceCode(serviceCode);
  if (code) {
    return code;
  }

  const mappedCode = sourceItemCodes.find(
    (itemCode) =>
      itemCode.sourceIdentifier === sourceIdentifier &&
      itemCode.destinationValue === ItemCodeResourceMapType.ServiceCode
  );
  if (!mappedCode?.associatedValue) {
    return;
  }

  if (isINamedDocument(mappedCode.associatedValue)) {
    return ServiceProviderHandler.findServiceCode(
      mappedCode.associatedValue.ref.id
    );
  }
  return ServiceProviderHandler.findServiceCode(mappedCode.associatedValue);
}
