import { Injectable } from '@angular/core';
import { type IReffable } from '@principle-theorem/shared';
import {
  type IRoutable,
  StateBasedNavigationService,
  type IContextualAction,
} from '@principle-theorem/ng-principle-shared';

@Injectable({
  providedIn: 'root',
})
export class LoadContactActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'View Contact';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(contact: IRoutable | IReffable): Promise<void> {
    const uid: string = 'uid' in contact ? contact.uid : contact.ref.id;
    await this._stateNav.practice(['contacts', uid]);
  }
}
