import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { AnyCustomFormElement } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilderService } from '../../../form-builder/form-builder.service';
import { PreviewFormIssueElementComponent } from './preview-form-issue-element/preview-form-issue-element.component';

@Component({
    selector: 'pr-preview-form-issue',
    imports: [CommonModule, PreviewFormIssueElementComponent],
    templateUrl: './preview-form-issue.component.html',
    styleUrl: './preview-form-issue.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormBuilderService]
})
export class PreviewFormIssueComponent implements OnDestroy {
  private _onDestroy$ = new Subject<boolean>();
  private _layout$ = new ReplaySubject<AnyCustomFormElement[]>(1);
  formBuilder = inject(FormBuilderService);
  @Output() layoutChange = new EventEmitter<AnyCustomFormElement[]>();

  @Input()
  set layout(layout: AnyCustomFormElement[]) {
    this._layout$.next(layout ?? []);
  }

  constructor() {
    this._layout$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((layout) => this.formBuilder.setLayout(layout));
    this.formBuilder.layout$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((layout) => this.layoutChange.emit(layout));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
