<div fxFill class="timeline-card" fxLayout="column">
  <div
    class="timeline-header"
    fxLayout="row"
    fxLayoutAlign="space-between end"
    fxLayoutGap="16px"
  >
    <pr-multi-staff-selector
      #staffSelector
      [staff]="staff$ | async"
      [selectedStaff]="selectedStaff$ | async"
      [showRosteredOffStaff]="timelineStore.showRosteredOffStaff$ | ngrxPush"
      (selectionChange)="selectStaff($event)"
      (orderChange)="updateStaffOrder($event)"
      (toggleRosteredOffStaff)="toggleRosteredOffStaff($event)"
    />

    <div class="flex items-center">
      <pt-button-with-menu
        type="stroked"
        class="mr-2"
        [label]="createButtonLabel$ | async"
        [checked]="isCreateMode$ | async"
        (buttonClick)="toggleMode()"
      >
        <button
          *ngFor="let createMode of createModes; trackBy: trackByCreateMode"
          mat-menu-item
          [ngClass]="{
            selected: createMode | map: isSelectedCreateType$ : this | async
          }"
          (click)="setCreateMode(createMode)"
        >
          <span>{{ createMode | splitCamel | titlecase }}</span>
        </button>
      </pt-button-with-menu>

      <div class="mr-3" [matTooltip]="pendingGapsTooltip$ | async">
        <pt-button-with-menu
          type="stroked"
          label="Gaps"
          (buttonClick)="gapStore.displayGaps(true)"
        >
          @if (gapStore.displayGapsOnTimeline$ | async) {
            <button
              mat-menu-item
              (click)="gapStore.displayGapsOnTimeline(false)"
            >
              <mat-icon>visibility_off</mat-icon>
              <span>Hide Gaps</span>
            </button>
          } @else {
            <button
              mat-menu-item
              [disabled]="gapStore.searchOutOfRange$ | async"
              (click)="gapStore.displayGapsOnTimeline(true)"
            >
              <mat-icon>visibility</mat-icon>
              <span>Show Gaps</span>
            </button>
          }
          @if ((gapStore.pendingGaps$ | async)?.length; as pendingGaps) {
            <button
              mat-menu-item
              (click)="gapStore.displayGaps(true, gapsTabIndex.PendingGaps)"
            >
              <mat-icon>pending_actions</mat-icon>
              <span>Pending Gaps</span>
            </button>
          }
        </pt-button-with-menu>

        <ng-container *ngrxLet="gapStore.pendingGaps$ | async as pendingGaps">
          <ng-container *ngIf="pendingGaps.length > 0">
            <span matBadgeColor="warn" [matBadge]="pendingGaps.length"></span>
          </ng-container>
        </ng-container>
      </div>

      <pr-timeline-user-settings />
      <pr-timeline-practice-settings
        #settings
        (refreshTimeline)="calendarEventsFacade.loadCalendarEvents()"
      />
    </div>
  </div>

  <div fxFlex class="timeline-container">
    <pr-eventable-timeline
      fxFill
      [practiceViewSettings]="settings.practiceViewSettings$ | ngrxPush"
      [timelineMode]="mode$ | ngrxPush"
      [createType]="createType$ | ngrxPush"
      [staff]="selectedStaff$ | ngrxPush"
      [showRosteredOffStaff]="timelineStore.showRosteredOffStaff$ | ngrxPush"
      [hideEmptyDays]="timelineStore.hideEmptyDays$ | ngrxPush"
      [dateRange]="dateRange$ | ngrxPush"
      [openingHours]="openingHours$ | ngrxPush"
      [showGridlines]="timelineStore.showGridlines$ | ngrxPush"
      [orientation]="timelineStore.orientation$ | ngrxPush"
      [zoom]="timelineStore.zoom$ | ngrxPush"
      [thickTracks]="timelineStore.thickTracks$ | ngrxPush"
      (eventSelected)="selectEvent($event)"
    />
  </div>
</div>
