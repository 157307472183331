import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { AppointmentResolverService } from './appointment-resolver.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SchedulingScenarioService } from './scheduling-scenario.service';

@NgModule({
  imports: [CommonModule, ComponentsModule],
  exports: [ComponentsModule],
  providers: [
    AppointmentResolverService,
    SchedulingScenarioService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class NgAppointmentModule {}
