<mat-list *ngIf="dvaCard; else noDetails">
  <mat-list-item>
    <span matListItemTitle>{{ dvaCard.number }}</span>
    <span matListItemLine>Number</span>
  </mat-list-item>
  <mat-list-item *ngIf="dvaCard.expiryDate">
    <span matListItemTitle>{{
      dvaCard.expiryDate | moment | amDateFormat : dateFormat
    }}</span>
    <span matListItemLine>Expiry Date</span>
  </mat-list-item>
</mat-list>

<ng-template #noDetails>
  <p class="mat-caption layout-margin">No Card Details</p>
</ng-template>

<pt-buttons-container padding="all-round">
  <button *ngIf="dvaCard" mat-stroked-button (click)="remove.emit()">
    Remove
  </button>
  <button mat-flat-button color="primary" (click)="edit.emit()">
    {{ dvaCard ? 'Edit' : 'Add' }} Card Details
  </button>
</pt-buttons-container>
