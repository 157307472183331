import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export interface IAITextPromptOutput {
  prompt: string;
}

export interface IAITextPromptData {
  initialPrompt?: string;
}

@Component({
  selector: 'pt-ai-text-prompt-dialog',
  templateUrl: './ai-text-prompt-dialog.component.html',
  styleUrls: ['./ai-text-prompt-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AITextPromptDialogComponent {
  form = new TypedFormGroup<IAITextPromptOutput>({
    prompt: new TypedFormControl<string>(),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      AITextPromptDialogComponent,
      IAITextPromptOutput
    >,
    @Inject(MAT_DIALOG_DATA) data: IAITextPromptData
  ) {
    if (data.initialPrompt) {
      this.form.patchValue({
        prompt: data.initialPrompt,
      });
    }
  }

  save(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
