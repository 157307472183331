import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum PatientPermissions {
  PatientManage = 'patients.manage',
  AccountCreditManage = 'patients.account.credit.manage',
  AccountInvoiceManage = 'patients.account.invoice.manage',
  AccountInvoiceAdmin = 'patients.account.invoice.admin',
  FormsManage = 'patients.medical-history.manage',
  ChartingHistory = 'patients.charting-history.manage',
  AutomationManage = 'patients.automations.manage',
  TreatmentPlanManage = 'patients.treatment-plans.manage',
  MediaManage = 'patients.media.manage',
  PrescriptionManage = 'patients.prescriptions.manage',
  ReceiveDeposits = 'patients.account.deposits.receive',
}

export const PATIENTS_FEATURE: IFeature = {
  label: 'patients',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: PatientPermissions.PatientManage,
      description: `User can manage a patient's general details, relationships, and social notes`,
    },
    {
      value: PatientPermissions.AccountCreditManage,
      description: `User can add and remove a patient's account credits`,
    },
    {
      value: PatientPermissions.AccountInvoiceManage,
      description: `User can add, cancel, and write off a patient's invoices`,
    },
    {
      value: PatientPermissions.AccountInvoiceAdmin,
      description: `User can refund/transfer/delete account credits, amend invoice/transaction dates and revert payments on a patient's invoices`,
    },
    {
      value: PatientPermissions.FormsManage,
      description: `User can add and update a patient's forms & medical history information`,
    },
    {
      value: PatientPermissions.ChartingHistory,
      description: `User can view a patient's clinical history and update latest chart`,
    },
    {
      value: PatientPermissions.AutomationManage,
      description: `User can view, edit, and cancel patient automations for previous and upcoming appointments`,
    },
    {
      value: PatientPermissions.TreatmentPlanManage,
      description: `User can view and edit a patient's treatment plans`,
    },
    {
      value: PatientPermissions.MediaManage,
      description: `User can view, edit, and delete a patient's documents and files`,
    },
    {
      value: PatientPermissions.PrescriptionManage,
      description: `User can view, edit, and delete a patient's prescriptions`,
    },
    {
      value: PatientPermissions.ReceiveDeposits,
      description: `User can have deposits assigned to them`,
    },
  ],
};
