import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HicapsApi,
  HicapsConnectMethod,
  HicapsConnectResult,
  IPMSHicapsConnectConfig,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import {
  BridgeCommandsService,
  BridgeDeviceSelectorService,
} from '@principle-theorem/ng-principle-bridge-cloud';
import {
  BasicDialogService,
  DialogPresets,
  openBlankTab,
} from '@principle-theorem/ng-shared';
import { HICAPS_CONNECT_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import {
  HicapsConnectApiAdaptor,
  HicapsConnectProcessHandler,
  HicapsConnectProcessStatus,
  HicapsConnectResponseCodes,
  IHicapsConnectApiHooks,
  IHicapsConnectProcess,
  Invoice,
  Practice,
} from '@principle-theorem/principle-core';
import {
  BridgeDeviceStatus,
  IBridgeDevice,
  IHealthcareClaim,
  IHicapsConnectTerminal,
  IInvoice,
  IPractice,
  IPracticeHicapsConnectSettings,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  IReffable,
  WithRef,
  addDoc,
  asyncForEach,
  deleteDoc,
  firstResult,
  isSameRef,
  snapshot,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { first, sortBy, uniqBy } from 'lodash';
import {
  INgPaymentsConfig,
  NG_PAYMENTS_CONFIG,
} from '../../../ng-payments-config';
import { HicapsConnectHelpers } from './hicaps-connect-helpers';
import {
  HicapsConnectSelectTerminalDialogComponent,
  IHicapsSelectTerminalRequest,
  IHicapsSelectTerminalResponse,
} from './hicaps-connect-select-terminal-dialog/hicaps-connect-select-terminal-dialog.component';
import {
  EditHicapsConnectTerminalDialogComponent,
  EditHicapsConnectTerminalFormData,
  IEditHicapsConnectTerminalRequest,
} from './edit-hicaps-connect-terminal-dialog/edit-hicaps-connect-terminal-dialog.component';

interface ITerminalBridgeDevicePair {
  terminal: WithRef<IHicapsConnectTerminal>;
  bridgeDevice: WithRef<IBridgeDevice>;
}

@Injectable()
export class HicapsConnectService {
  constructor(
    @Inject(NG_PAYMENTS_CONFIG) private _config: INgPaymentsConfig,
    private _basicDialog: BasicDialogService,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService,
    private _featureFlags: FeatureFlagsService,
    private _bridgeCommands: BridgeCommandsService
  ) {}

  getDeviceAPI(bridgeDeviceRef: DocumentReference<IBridgeDevice>): HicapsApi {
    const hooks: IHicapsConnectApiHooks = {
      afterProcessCreate: (hicapsProcess) =>
        this._afterProcessCreate(hicapsProcess),
    };
    const adaptor = new HicapsConnectApiAdaptor(
      bridgeDeviceRef,
      this._featureFlags.features,
      hooks
    );
    return new HicapsApi(adaptor);
  }

  getConfig(): IPMSHicapsConnectConfig {
    return this._config.hicapsConnect;
  }

  async getPracticeSettings(
    practiceRef: DocumentReference<IPractice>
  ): Promise<IPracticeHicapsConnectSettings | undefined> {
    const practice = await Firestore.getDoc(practiceRef);
    return practice.hicapsConnectSettings;
  }

  async syncTerminalsForDevice(practice: IReffable<IPractice>): Promise<void> {
    const deviceRef = await this._bridgeDeviceSelector.selectDevice(
      HICAPS_CONNECT_INTEGRATION
    );
    if (!deviceRef) {
      return;
    }

    const api = this.getDeviceAPI(deviceRef);
    const response = await snapshot(api.getTerminalList());

    if (response.result !== HicapsConnectResult.Success) {
      this._snackbar.open(`Unable to sync terminals: ${response.message}`);
      return;
    }

    const terminalIds = response.data;
    await asyncForEach(terminalIds, (terminalId) =>
      this._syncMerchantsForTerminal(api, practice, deviceRef, terminalId)
    );

    this._snackbar.open('Terminals synced');
  }

  async testTerminal(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const api = this.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(api.sendTerminalTest(terminal.terminalId));

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      await this._basicDialog.alert({
        title: 'Terminal Test Failed',
        prompt: `${response.message}`,
      });
      return;
    }
    if (
      !HicapsConnectResponseCodes.isClaimSuccessful(response.data.ResponseCode)
    ) {
      await this._basicDialog.alert({
        title: 'Terminal Test Failed',
        prompt: `${response.data.ResponseText}`,
        submitLabel: 'Close',
        submitColor: 'accent',
      });
      return;
    }
    await this._basicDialog.alert({
      title: 'Terminal Test Successful',
      prompt: `Terminal ${terminal.name} is working correctly`,
      submitLabel: 'Close',
      submitColor: 'accent',
    });
  }

  async editTerminal(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const result = await this._basicDialog
      .open<
        EditHicapsConnectTerminalDialogComponent,
        IEditHicapsConnectTerminalRequest,
        EditHicapsConnectTerminalFormData
      >(
        EditHicapsConnectTerminalDialogComponent,
        DialogPresets.medium({
          data: { title: 'Edit Terminal', formData: terminal },
        })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }
    await Firestore.patchDoc(terminal.ref, {
      name: result.name,
      practitionerRef: result.practitionerRef,
    });
  }

  async deleteTerminal(
    terminal: WithRef<IHicapsConnectTerminal>
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm({
      title: 'Delete Terminal',
      prompt: `Are you sure you want to delete ${terminal.name}`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    if (!confirmed) {
      return;
    }
    await deleteDoc(terminal.ref);
  }

  async selectTerminal(
    invoice: Pick<IInvoice, 'practice' | 'items' | 'claims'>,
    claim?: IHealthcareClaim
  ): Promise<WithRef<IHicapsConnectTerminal> | undefined> {
    const terminalDevicePairs = await this._getTerminalBridgeDevicePairs(
      invoice.practice
    );

    const defaultTerminal = this._getDefaultTerminal(terminalDevicePairs);
    if (defaultTerminal) {
      return defaultTerminal;
    }

    const practitionersOnInvoice = claim
      ? [claim.practitioner]
      : Invoice.staffOnInvoice(invoice);

    const terminals = terminalDevicePairs
      .map((pair) => pair.terminal)
      .filter((terminal) => {
        const hasPractitionerOnInvoice = practitionersOnInvoice.some(
          (practitionerOnInvoice) =>
            isSameRef(practitionerOnInvoice, terminal.practitionerRef)
        );
        return !terminal.practitionerRef || hasPractitionerOnInvoice;
      })
      .map((terminal) => {
        if (!terminal.practitionerRef || practitionersOnInvoice.length > 1) {
          return terminal;
        }

        const practitioner = practitionersOnInvoice.find(
          (practitionerOnInvoice) =>
            isSameRef(practitionerOnInvoice, terminal.practitionerRef)
        );

        if (!practitioner) {
          return terminal;
        }

        return {
          ...terminal,
          isSelected: true,
        };
      });

    const sortedTerminals = sortBy(terminals, (terminal) => terminal.name);

    const response = await this._dialog
      .open<
        HicapsConnectSelectTerminalDialogComponent,
        IHicapsSelectTerminalRequest,
        IHicapsSelectTerminalResponse
      >(
        HicapsConnectSelectTerminalDialogComponent,
        DialogPresets.medium({
          data: { terminals: sortedTerminals, practice: invoice.practice },
        })
      )
      .afterClosed()
      .toPromise();

    return response?.terminal;
  }

  async viewLogs(terminal: WithRef<IHicapsConnectTerminal>): Promise<void> {
    const api = this.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(api.principleHicapsLogsRead());
    if (!response.data) {
      this._snackbar.open(`Unable to load logs for ${terminal.name}`);
      return;
    }
    openBlankTab(`Principle Logs: ${terminal.name}`, response.data);
  }

  async associatePractitionerWithTerminal(
    terminal: WithRef<IHicapsConnectTerminal>,
    practitioner?: WithRef<IStaffer>
  ): Promise<void> {
    await Firestore.saveDoc({
      ...terminal,
      practitionerRef: practitioner?.ref,
    });

    if (practitioner?.ref) {
      this._snackbar.open('Practitioner associated with terminal');
      return;
    }
    this._snackbar.open('Practitioner reset for terminal');
  }

  private async _getTerminalBridgeDevicePairs(
    practice: IReffable<IPractice>
  ): Promise<ITerminalBridgeDevicePair[]> {
    const terminals = await Firestore.getDocs(
      undeletedQuery(Practice.hicapsConnectTerminalCol(practice))
    );
    const pairs = await asyncForEach(terminals, async (terminal) => {
      const bridgeDevice = await Firestore.getDoc(terminal.bridgeDevice);
      return { terminal, bridgeDevice };
    });
    return pairs.filter((pair) => !pair.bridgeDevice.deleted);
  }

  private async _syncMerchantsForTerminal(
    api: HicapsApi,
    practice: IReffable<IPractice>,
    deviceRef: DocumentReference<IBridgeDevice>,
    terminalId: string
  ): Promise<void> {
    const request = HicapsConnectHelpers.buildBaseRequest(this.getConfig(), {
      terminalId,
    });
    const merchants = await snapshot(api.sendAllMerchantList(request));

    if (merchants.result !== HicapsConnectResult.Success) {
      return;
    }

    const merchantList = uniqBy(
      merchants.data.MerchantListDetails,
      (merchant) => `${merchant.merchantId}-${merchant.terminalId}`
    );

    await asyncForEach(merchantList, async (merchant) => {
      try {
        await this._updateTerminalForDevice(
          practice,
          deviceRef,
          merchant.merchantId,
          merchants.data.ServerUrl,
          `${merchant.providerName} - TID:${terminalId}`
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  }

  private _getDefaultTerminal(
    pairs: ITerminalBridgeDevicePair[]
  ): WithRef<IHicapsConnectTerminal> | undefined {
    const firstPair = first(pairs);
    if (!firstPair || pairs.length > 1) {
      return;
    }
    if (firstPair.bridgeDevice.status !== BridgeDeviceStatus.Active) {
      return;
    }
    return firstPair.terminal;
  }

  private async _updateTerminalForDevice(
    practice: IReffable<IPractice>,
    bridgeDevice: DocumentReference<IBridgeDevice>,
    merchantId: string,
    terminalId: string,
    name: string = `TID:${terminalId} - MID:${merchantId}`
  ): Promise<DocumentReference<IHicapsConnectTerminal>> {
    const terminalCol = Practice.hicapsConnectTerminalCol(practice);
    const existing = await firstResult(
      undeletedQuery(terminalCol),
      where('merchantId', '==', merchantId),
      where('terminalId', '==', terminalId)
    );

    if (existing) {
      await Firestore.patchDoc(existing.ref, {
        terminalId,
        bridgeDevice,
        lastActive: toTimestamp(),
      });
      return existing.ref;
    }

    return addDoc(terminalCol, {
      merchantId,
      terminalId,
      bridgeDevice,
      lastActive: toTimestamp(),
      name,
      deleted: false,
    });
  }

  private async _afterProcessCreate<T extends HicapsConnectMethod>(
    hicapsProcess: WithRef<IHicapsConnectProcess<T>>
  ): Promise<void> {
    if (hicapsProcess.status !== HicapsConnectProcessStatus.Pending) {
      return;
    }
    const deviceCommand =
      HicapsConnectProcessHandler.toDeviceCommand(hicapsProcess);
    await this._bridgeCommands.sendCommand(
      deviceCommand.command,
      deviceCommand.deviceRef
    );
    await Firestore.patchDoc(hicapsProcess.ref, {
      status: HicapsConnectProcessStatus.InProgress,
    });
  }
}
