<pr-multi-step-treatment
  [stepDragDrop]="stepDragDrop"
  [steps]="treatmentSteps$ | async"
  [plan]="treatmentPlan$ | async"
  [disabled]="disabled"
  (stepDelete)="deleteStep($event)"
  (stepAdd)="addStep()"
  (stepChange)="updateStep($event)"
  (selectChild)="generateChildPlan()"
  (updateChartable)="updateChartable($event)"
 />
