import 'reflect-metadata';
import { type IMigrationMetadata } from './interfaces';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Migration(
  options: IMigrationMetadata
  // eslint-disable-next-line @typescript-eslint/ban-types
): (constructor: Function) => void {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Reflect.defineMetadata('migration:uid', options.uid, constructor.prototype);
    Reflect.defineMetadata(
      'migration:name',
      options.name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      constructor.prototype
    );
  };
}
