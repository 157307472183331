import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IMultiTreatmentConfiguration,
  isMultiTreatmentConfiguration,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ChartDialogService } from '../../chart-dialog.service';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';

export class AddMultiTreatmentToFirestorePlanProvider
  implements IChartedSurfaceProvider
{
  constructor(
    private _treatmentPlan: WithRef<ITreatmentPlan>,
    private _treatmentStep: WithRef<ITreatmentStep>,
    private _chartDialogService: ChartDialogService
  ) {}

  canProvide(chartable: AnyChartedItemConfiguration): boolean {
    return isMultiTreatmentConfiguration(chartable);
  }

  async addChartedItem(
    _: IChartSurfaceEvent,
    chartable: WithRef<IMultiTreatmentConfiguration>
  ): Promise<void> {
    const multiTreatment = await this._chartDialogService.chartMultiTreatment(
      chartable,
      []
    );
    if (!multiTreatment) {
      return;
    }

    const index = TreatmentPlan.getTreatmentStepIndex(
      this._treatmentPlan,
      this._treatmentStep
    );
    if (index === -1) {
      // eslint-disable-next-line no-console
      console.error(`addChartedItem - Step not found on plan`, {
        plan: this._treatmentPlan,
        step: this._treatmentStep,
      });
      return;
    }
    await TreatmentPlan.addTreatmentSteps(
      this._treatmentPlan,
      multiTreatment.steps,
      index + 1
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async removeSurfacesFromChartedItem(
    _surfaceRefs: Partial<IChartedRef>[],
    _item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    // Not implemented
  }
}
