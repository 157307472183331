import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  type IPricedServiceCodeEntry,
  type IServiceCode,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import { isNil } from 'lodash';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { snapshot } from '@principle-theorem/shared';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-service-line-item',
  templateUrl: './service-line-item.component.html',
  styleUrls: ['./service-line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceLineItemComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  priceCtrl = new TypedFormControl<number>(0, {
    updateOn: 'blur',
  });
  quantityCtrl = new TypedFormControl<number>(0);
  serviceCode$ = new ReplaySubject<IPricedServiceCodeEntry>(1);
  isFlat$: Observable<boolean>;
  @Input() name = '';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Input() compact = false;
  @Input() disableQuantity = false;
  @Output() itemChange = new EventEmitter<Partial<IPricedServiceCodeEntry>>();
  @Output() addServiceCode = new EventEmitter<IServiceCode>();

  @Input()
  set price(price: number | undefined) {
    if (!isNil(price)) {
      this.priceCtrl.setValue(price, { emitEvent: false });
    }
  }

  @Input()
  set quantity(quantity: number) {
    if (!isNaN(quantity)) {
      this.quantityCtrl.setValue(quantity, { emitEvent: false });
    }
  }

  @Input()
  set serviceCode(serviceCode: IPricedServiceCodeEntry) {
    if (serviceCode) {
      this.serviceCode$.next(serviceCode);
    }
  }

  constructor() {
    validFormControlChanges$(this.priceCtrl)
      .pipe(
        map((value) => roundTo2Decimals(value) ?? 0),
        takeUntil(this._onDestroy$)
      )
      .subscribe((priceOverride) => {
        this.price = priceOverride;
        this.itemChange.emit({ priceOverride });
      });

    this.quantityCtrl.valueChanges
      .pipe(
        debounceTime(500),
        map((value) => value ?? 0),
        takeUntil(this._onDestroy$)
      )
      .subscribe((quantity) => this.itemChange.emit({ quantity }));

    this.isFlat$ = this.serviceCode$.pipe(
      map(
        (serviceCode) => serviceCode.pricingRule.type === PricingRuleType.Flat
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  decreaseQty(): void {
    const quantity = this.quantityCtrl.value - 1;
    this.quantityCtrl.setValue(quantity);
  }

  increaseQty(): void {
    const quantity = this.quantityCtrl.value + 1;
    this.quantityCtrl.setValue(quantity);
  }

  async addTieredServiceCode(): Promise<void> {
    const resolvedCode = await snapshot(
      this.serviceCode$.pipe(
        map((serviceCode) =>
          ServiceProviderHandler.resolveServiceCode(
            serviceCode.type,
            serviceCode.code
          )
        )
      )
    );
    this.addServiceCode.emit(resolvedCode);
  }
}
