import { IProviderOption } from '../transaction-provider';
import { TyroEasyclaimBulkBillTransactionProvider } from '../tyro/easyclaim/tyro-easyclaim-bulk-bill-transaction-provider.service';
import { TyroEasyclaimFullyPaidTransactionProvider } from '../tyro/easyclaim/tyro-easyclaim-fully-paid-transaction-provider.service';
import { TyroEasyclaimPartPaidTransactionProvider } from '../tyro/easyclaim/tyro-easyclaim-part-paid-transaction-provider.service';
import { TyroHealthPointTransactionProvider } from '../tyro/health-point/tyro-health-point-transaction-provider.service';
import { TyroPurchaseTransactionProvider } from '../tyro/purchase/tyro-purchase-transaction-provider.service';

export const TYRO_IMAGE_URL = '/assets/integrations/tyro-rect.png';

export const TYRO_PURCHASE_PROVIDER_OPTION: IProviderOption = {
  label: 'Tyro',
  icon: 'credit_card',
  imageUrl: TYRO_IMAGE_URL,
  provider: TyroPurchaseTransactionProvider,
};

export const TYRO_HEALTH_POINT_PROVIDER_OPTION: IProviderOption = {
  label: 'Tyro Healthpoint',
  icon: 'health_and_safety',
  imageUrl: TYRO_IMAGE_URL,
  provider: TyroHealthPointTransactionProvider,
};

export const TYRO_EASYCLAIM_BULK_BILL_PROVIDER_OPTION: IProviderOption = {
  label: 'Tyro Bulk Bill',
  icon: 'local_hospital',
  imageUrl: TYRO_IMAGE_URL,
  provider: TyroEasyclaimBulkBillTransactionProvider,
};

export const TYRO_EASYCLAIM_FULLY_PAID_PROVIDER_OPTION: IProviderOption = {
  label: 'Tyro Fully Paid',
  icon: 'local_hospital',
  imageUrl: TYRO_IMAGE_URL,
  provider: TyroEasyclaimFullyPaidTransactionProvider,
};

export const TYRO_EASYCLAIM_PART_PAID_PROVIDER_OPTION: IProviderOption = {
  label: 'Tyro Part Paid',
  icon: 'local_hospital',
  imageUrl: TYRO_IMAGE_URL,
  provider: TyroEasyclaimPartPaidTransactionProvider,
};

export const TYRO_PROVIDER_OPTIONS: IProviderOption[] = [
  TYRO_PURCHASE_PROVIDER_OPTION,
  TYRO_HEALTH_POINT_PROVIDER_OPTION,
  TYRO_EASYCLAIM_BULK_BILL_PROVIDER_OPTION,
  TYRO_EASYCLAIM_FULLY_PAID_PROVIDER_OPTION,
  TYRO_EASYCLAIM_PART_PAID_PROVIDER_OPTION,
];
