<ng-container *ngIf="group$ | async as group">
  <div class="flex flex-col gap-1">
    <div class="flex items-center justify-between gap-2">
      <mat-form-field class="compact" subscriptSizing="dynamic">
        <mat-select
          class="mat-caption flex-1 truncate"
          panelWidth=""
          [formControl]="selectedCode"
          [compareWith]="compareWithFn"
        >
          <mat-option
            *ngFor="let code of group.serviceCodes; trackBy: trackByCode"
            [value]="code"
          >
            {{ code | map : getCodeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <pr-service-item
        disableName
        *ngIf="selectedCode.value as selected"
        [serviceCode]="selected"
        [disabled]="disabled$ | async"
        [compact]="compact"
        (itemUpdate)="itemUpdate(group, selected, $event)"
      >
        <div class="action-container flex">
          <button
            *ngIf="(disabled$ | async) === false"
            mat-icon-button
            [disabled]="!selected.priceOverride"
            (click)="resetPrice(group, selected)"
            matTooltip="Reset to Fee Schedule price"
            tabindex="-1"
            class="icon-sm opacity-50"
          >
            <mat-icon>restart_alt</mat-icon>
          </button>
          <ng-content></ng-content>
        </div>
      </pr-service-item>
    </div>
    <ng-container *ngIf="selectedCode.value as selected">
      <pr-service-pricing-rule-item-select
        *ngIf="selected.pricingRule.type !== 'flat'"
        [disabled]="true"
        [pricingRule]="selectedCode.value.pricingRule"
      />
    </ng-container>
  </div>
</ng-container>
