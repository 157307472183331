import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { type Lab, toMention } from '@principle-theorem/principle-core';
import {
  type ILab,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type WithRef,
  deleteDoc,
  doc$,
  filterUndefined,
  findProp,
  patchDoc,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  type ILabCreateData,
  LabCreateComponent,
} from '../lab-create/lab-create.component';

@Component({
  selector: 'pr-lab-profile',
  templateUrl: './lab-profile.component.html',
  styleUrls: ['./lab-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabProfileComponent {
  lab$: Observable<WithRef<ILab>>;

  constructor(
    private _route: ActivatedRoute,
    private _stateNav: StateBasedNavigationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogService: InteractionDialogsService
  ) {
    this.lab$ = this._route.data.pipe(
      findProp<WithRef<ILab>>('lab'),
      filterUndefined(),
      switchMap((lab) => doc$(lab.ref)),
      tap((lab) => {
        this.dialogService.dialogData = {
          contact: toMention(lab, MentionResourceType.Lab),
        };
      }),
      shareReplayCold()
    );
  }

  async openEdit(): Promise<void> {
    const lab = await snapshot(this.lab$);
    const data: ILabCreateData = {
      title: `Edit ${lab.name}`,
      submitLabel: 'Save',
      lab: lab,
    };
    const changes: Partial<Lab> | undefined = await this._dialog
      .open<LabCreateComponent, ILabCreateData, Partial<Lab>>(
        LabCreateComponent,
        DialogPresets.medium({ data, autoFocus: true })
      )
      .afterClosed()
      .toPromise();

    if (changes) {
      await this._updateLab(lab.ref, changes);
    }
  }

  async delete(): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Lab',
      prompt: 'Are you sure you want to delete this lab?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const deleteConfirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!deleteConfirmed) {
      return;
    }

    const lab = await snapshot(this.lab$);
    await deleteDoc(lab.ref);
    await this._stateNav.practice(['labs']);
  }

  private async _updateLab(
    labRef: DocumentReference<ILab>,
    data: Partial<ILab>
  ): Promise<void> {
    await patchDoc(labRef, data);
    this._snackBar.open('Lab Updated');
  }
}
