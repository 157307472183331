import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnInit,
} from '@angular/core';
import { type IContextualAction } from '@principle-theorem/ng-principle-shared';
import {
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { MentionActionResolverService } from '../mention-action-resolver.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { snapshot } from '@principle-theorem/shared';
import {
  ContextActionButtonBuilder,
  type IContextualActionButton,
} from '../../contextual-actions/contextual-actions-buttons';

@Component({
  selector: 'pr-mention-actions',
  templateUrl: './mention-actions.component.html',
  styleUrls: ['./mention-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionActionsComponent implements OnInit {
  trackByAction =
    TrackByFunctions.nestedField<IContextualActionButton>('action.name');
  resource$ = new ReplaySubject<IPrincipleMention>(1);
  templateResource$ = new BehaviorSubject<IPrincipleMention | undefined>(
    undefined
  );
  interactiveResource$ = new ReplaySubject<IInteractiveResource>(1);
  buttons: IContextualActionButton[] = [];
  @Input() moreButton = false;

  @Input()
  set interactiveResource(interactiveResource: IInteractiveResource) {
    if (interactiveResource) {
      this.interactiveResource$.next(interactiveResource);
    }
  }

  @Input()
  set resource(resource: IPrincipleMention) {
    if (resource) {
      this.resource$.next(resource);
    }
  }

  @Input()
  set templateResource(templateResource: IPrincipleMention) {
    if (templateResource) {
      this.templateResource$.next(templateResource);
    }
  }

  constructor(private _mentionActionResolver: MentionActionResolverService) {}

  async ngOnInit(): Promise<void> {
    const resource = await snapshot(this.resource$);
    this._resolveMentionActions(resource);
  }

  async runAction(action: IContextualAction): Promise<void> {
    const resource = await snapshot(this.resource$);
    const interactiveResource = await snapshot(this.interactiveResource$);
    const templateResource = await snapshot(this.templateResource$);
    action.do(resource, interactiveResource, templateResource);
  }

  /**
   * Injects the services for a mention's required actions
   *
   * @private
   * @memberof MentionResourceComponent
   */
  private _resolveMentionActions(resource: IPrincipleMention): void {
    this.buttons = ContextActionButtonBuilder.build(resource).createMany(
      this._mentionActionResolver.resolveActions(resource.resource.type)
    );
  }
}
