@if (automation$ | async | typeGuard: isGeneratedTask; as generatedTask) {
  <pr-content-text
    class="min-w-0 truncate"
    [content]="generatedTask.title"
    [ptTruncateTooltip]="generatedTask.title"
  />
}
@if (
  automation$ | async | typeGuard: isAutomatedNotification;
  as notification
) {
  {{ notification.name }}
}
@if (automation$ | async | typeGuard: isAutomatedFormIssue; as formIssue) {
  {{ formIssue.name }}
}
