import { Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IChartCard } from '../../../models/report/charts/chart-card';

@Component({
    selector: 'pr-single-chart-card',
    templateUrl: './single-chart-card.component.html',
    styleUrls: ['./single-chart-card.component.sass'],
    standalone: false
})
export class SingleChartCardComponent {
  trackByRange = TrackByFunctions.title<IRange>();
  @Input() chartCard: IChartCard;
  @Input() title: string;

  ranges: IRange[] = [
    { title: 'Last 7 days', value: '7 days' },
    { title: 'Last Month', value: '' },
  ];
  selectedRange = this.ranges[0];
}

interface IRange {
  title: string;
  value: string;
}
