import { randJobType } from '@ngneat/falso';
import { IRole } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';
import {
  ADMINISTRATOR_PERMISSIONS,
  DENTAL_ASSISTANT_PERMISSIONS,
  PRACTITIONER_PERMISSIONS,
} from './permission.mock';

export function MockRole(overrides: Partial<IRole>): IRole {
  return {
    name: randJobType(),
    permissions: [],
    ...initFirestoreModel(),
    ...overrides,
  };
}

export enum MockedRole {
  Receptionist = 'Receptionist',
  Practitioner = 'Practitioner',
  Manager = 'Manager',
}

export const MOCK_ROLES: IRole[] = [
  {
    name: MockedRole.Receptionist,
    permissions: DENTAL_ASSISTANT_PERMISSIONS,
  },
  {
    name: MockedRole.Practitioner,
    permissions: PRACTITIONER_PERMISSIONS,
  },
  {
    name: MockedRole.Manager,
    permissions: ADMINISTRATOR_PERMISSIONS,
  },
].map((data: Partial<IRole>) => MockRole(data));
