import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
    selector: 'pr-display-base-price',
    templateUrl: './display-base-price.component.html',
    styleUrls: ['./display-base-price.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayBasePriceComponent {
  @Input() price: number;
  @Input() name = '';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Output() basePriceDeleted = new EventEmitter<void>();
}
