import { TypedFormControl } from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isSameRef,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TagsInput {
  private _tagsCtrl = new TypedFormControl<WithRef<ITag>[]>([]);
  private _tags$ = new BehaviorSubject<WithRef<ITag>[]>([]);
  tags$: Observable<WithRef<ITag>[]> = this._tags$.asObservable();
  namedDocs$: Observable<INamedDocument[]>;

  constructor() {
    this.namedDocs$ = this._tagsCtrl.valueChanges.pipe(
      map((tags) => tags.map((tag) => toNamedDocument(tag)))
    );
  }

  setTags(tags: WithRef<ITag>[]): void {
    this._tagsCtrl.setValue(tags, { emitEvent: false });
    this._tags$.next(tags);
  }

  add(tag: WithRef<ITag>): void {
    const tags = [...this._tagsCtrl.value, tag];
    this._tagsCtrl.setValue(tags);
    this._tags$.next(tags);
  }

  remove(tag: WithRef<ITag>): void {
    const tags = this._tagsCtrl.value.filter((item) => !isSameRef(item, tag));
    this._tagsCtrl.setValue(tags);
    this._tags$.next(tags);
  }
}
