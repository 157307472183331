<mat-toolbar color="accent">Confirm Patient Details</mat-toolbar>

<mat-dialog-content class="!flex !h-full !flex-col">
  <div class="flex flex-1 gap-4">
    @if (patient.primaryContact && !patient.patient) {
      <pr-content-container height="h-full" class="flex-1">
        <ng-template
          [ngTemplateOutlet]="patientDetailsTemplate"
          [ngTemplateOutletContext]="{
            primary: patient.primaryContact,
            title: patientType.Patient,
            type: patientType.PrimaryContact
          }"
        />
        @if (primaryContactMatches(); as matches) {
          @if (matches.length) {
            <ng-template
              [ngTemplateOutlet]="patientMatchesTemplate"
              [ngTemplateOutletContext]="{
                matches,
                primary: patient.primaryContact,
                type: patientType.PrimaryContact
              }"
            />
          } @else {
            <ng-template
              [ngTemplateOutlet]="noMatchesTemplate"
              [ngTemplateOutletContext]="{ name: patient.primaryContact.name }"
            />
          }
        }
      </pr-content-container>
    }

    @if (patient.primaryContact && patient.patient) {
      <pr-content-container height="h-full" class="flex-1">
        <ng-template
          [ngTemplateOutlet]="patientDetailsTemplate"
          [ngTemplateOutletContext]="{
            primary: patient.patient,
            title: patientType.Patient
          }"
        />
        @if (patientMatches(); as matches) {
          @if (matches.length) {
            <ng-template
              [ngTemplateOutlet]="patientMatchesTemplate"
              [ngTemplateOutletContext]="{
                matches,
                primary: patient.patient,
                type: patientType.Patient
              }"
            />
          } @else {
            <ng-template
              [ngTemplateOutlet]="noMatchesTemplate"
              [ngTemplateOutletContext]="{ name: patient.patient.name }"
            />
          }
        }
      </pr-content-container>
      <pr-content-container height="h-full" class="flex-1">
        <ng-template
          [ngTemplateOutlet]="patientDetailsTemplate"
          [ngTemplateOutletContext]="{
            primary: patient.primaryContact,
            patient: patient.patient,
            title: patientType.PrimaryContact,
            type: patientType.PrimaryContact
          }"
        />
        @if (primaryContactMatches(); as matches) {
          @if (matches.length) {
            <ng-template
              [ngTemplateOutlet]="patientMatchesTemplate"
              [ngTemplateOutletContext]="{
                matches,
                primary: patient.primaryContact,
                type: patientType.PrimaryContact
              }"
            />
          } @else {
            <ng-template
              [ngTemplateOutlet]="noMatchesTemplate"
              [ngTemplateOutletContext]="{ name: patient.primaryContact.name }"
            />
          }
        }
      </pr-content-container>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="scheduleAppointment()">
      Schedule Appointment
    </button>
  </pt-buttons-container>
</mat-dialog-actions>

<ng-template
  #patientDetailsTemplate
  let-primary="primary"
  let-patient="patient"
  let-title="title"
  let-type="type"
>
  @if (title) {
    <p class="text-lg font-bold">{{ title | splitCamel | titlecase }}</p>
  }
  <div class="flex flex-col flex-wrap gap-2 md:flex-row md:gap-8">
    <div class="flex flex-row items-baseline gap-2 md:flex-col">
      <span class="text-xs uppercase text-slate-400">Name</span>
      {{ primary.name }}
    </div>
    <div class="flex flex-row items-baseline gap-2 md:flex-col">
      <span class="text-xs uppercase text-slate-400">Date Of Birth</span>
      {{ (primary.dateOfBirth | moment | amDateFormat: dateFormat) ?? 'N/A' }}
    </div>

    @if (type === patientType.PrimaryContact) {
      <div class="flex flex-row items-baseline gap-2 md:flex-col">
        <span class="text-xs uppercase text-slate-400">Email</span>
        {{ primary.email ?? 'N/A' }}
      </div>
      <div class="flex flex-row items-baseline gap-2 md:flex-col">
        <span class="text-xs uppercase text-slate-400">Mobile Number</span>
        {{ primary | map: getMobileNumber : this | async }}
      </div>
      @if (patient && patient.relationshipType) {
        <div class="flex flex-row items-baseline gap-2 md:flex-col">
          <span class="text-xs uppercase text-slate-400">
            Relationship to Patient
          </span>
          {{ patient.relationshipType | titlecase }}
        </div>
      }
    }
  </div>
</ng-template>

<ng-template
  #patientMatchesTemplate
  let-primary="primary"
  let-matches="matches"
  let-type="type"
>
  <div class="flex flex-col gap-2">
    <p class="!mt-6 text-lg font-bold">
      {{ matches.length }}
      {{ matches.length > 1 ? 'Matches' : 'Match' }} Found
    </p>

    <pr-information-box>
      <div>
        Found {{ matches.length }} other
        {{ matches.length > 1 ? 'patients' : 'patient' }} with similar details,
        select the patient that matches or
        <span class="font-bold">{{ primary.name }}</span>
        will be created as a new patient.
      </div>
    </pr-information-box>

    @for (match of matches; track match.ref.path) {
      <div
        class="hover:ring-primary-500 cursor-pointer rounded p-4 ring-2 duration-300 ease-in-out"
        (click)="handleSelection(type, match)"
        [ngClass]="
          type === patientType.PrimaryContact
            ? primarySelected()?.id === match.id
              ? 'ring-primary-500'
              : 'ring-slate-200'
            : patientSelected()?.id === match.id
              ? 'ring-primary-500'
              : 'ring-slate-200'
        "
      >
        <ng-template
          [ngTemplateOutlet]="patientDetailsTemplate"
          [ngTemplateOutletContext]="{
            primary: match,
            type: patientType.PrimaryContact
          }"
        />
      </div>
    }
  </div>
</ng-template>

<ng-template #noMatchesTemplate let-name="name">
  <p class="!mt-6 text-lg font-bold">0 Matches Found</p>
  <p>
    <span class="font-bold">{{ name }}</span> will be created as a new patient.
  </p>
</ng-template>
