import { BehaviorSubject, Observable } from 'rxjs';
import {} from '../../../transactions-display/transaction-display/transaction-display.component';
import { GeneralProviderActionsService } from '../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../transaction-action';
import { TransactionHelpers } from '../../transaction-helpers';

export function isCashExtendedData(_data: unknown): _data is unknown {
  return true;
}

export class AmendCashTransaction implements ITransactionAction<unknown> {
  icon = 'edit';
  label = 'Amend';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isCashExtendedData;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(data: ITransactionActionsData<unknown>): Observable<boolean> {
    return TransactionHelpers.canAmend$(data);
  }

  async do(data: ITransactionActionsData<unknown>): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.amend(
      data.invoice,
      data.latestTransaction,
      'Amend Transaction'
    );
    this.inProgress$.next(false);
  }
}
