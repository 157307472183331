import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IMediaCategoryXSLX,
  MEDIA_CATEGORY_HEADERS,
} from './media-categories-to-xlsx';

export class XSLXToMediaCategories implements IXSLXImport<IMediaCategoryXSLX> {
  headers = MEDIA_CATEGORY_HEADERS;

  translate(row: Row): IMediaCategoryXSLX {
    return {
      category: row.getCell('category').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IMediaCategoryXSLX['mapTo'],
    };
  }
}
