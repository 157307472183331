import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-conflicts-sidebar',
  templateUrl: './appointment-conflicts-sidebar.component.html',
  styleUrls: ['./appointment-conflicts-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentConflictsSidebarComponent {
  trackByConflict = TrackByFunctions.variable<string>();
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  conflicts$: Observable<string[]>;

  constructor() {
    this.conflicts$ = this.appointment$.pipe(
      map((appointment) => Appointment.getSchedulingConflicts(appointment))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
