import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { InvoiceStatus } from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import { first } from 'lodash';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { type DateRangeDataBuilder } from '../../../../models/report/data-builders/date-range-data-builder';
import { OutstandingInvoicesReportFacade } from '../store/outstanding-invoices.facade';

@Component({
    selector: 'pr-outstanding-invoices-report',
    templateUrl: './outstanding-invoices-report.component.html',
    styleUrls: ['./outstanding-invoices-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OutstandingInvoicesReportComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dataBuilder$ = new ReplaySubject<DateRangeDataBuilder>(1);

  @Input()
  set dataBuilder(dataBuilder: DateRangeDataBuilder) {
    if (dataBuilder) {
      this._dataBuilder$.next(dataBuilder);
    }
  }

  constructor(public reportFacade: OutstandingInvoicesReportFacade) {
    this._dataBuilder$
      .pipe(
        switchMap((builder) =>
          combineLatest([
            builder.practices$.pipe(map((practices) => first(practices))),
            builder.dateChange,
          ])
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([practice, range]) => {
        if (!practice) {
          return;
        }
        this.reportFacade.setQuery({
          startDate: toTimestamp(range.from),
          endDate: toTimestamp(range.to),
          practiceRef: practice.ref,
          status: InvoiceStatus.Issued,
        });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
