import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { Observable, combineLatest } from 'rxjs';
import { WithRef, filterUndefined } from '@principle-theorem/shared';
import {
  ISterilisationPack,
  ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import { map, switchMap } from 'rxjs/operators';
import { Brand } from '@principle-theorem/principle-core';
import { SterilisationRecordListComponent } from '../sterilisation-record-list/sterilisation-record-list.component';
import { SterilisationRecordActionsService } from '../sterilisation-record-actions/sterilisation-record-actions.service';
import { SterilisationStore } from '../../stores/sterilisation.store';

@Component({
    selector: 'pr-patient-sterilisation-records',
    imports: [CommonModule, SterilisationRecordListComponent],
    templateUrl: './patient-sterilisation-records.component.html',
    styleUrl: './patient-sterilisation-records.component.scss',
    providers: [SterilisationRecordActionsService, SterilisationStore],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientSterilisationRecordsComponent {
  private _patientScope = inject(CurrentPatientScope);
  private _organisation = inject(OrganisationService);
  store = inject(SterilisationStore);
  records$: Observable<WithRef<ISterilisationRecord>[]>;
  packOptions$: Observable<WithRef<ISterilisationPack>[]>;

  constructor() {
    this.store.loadPatientRecords(
      combineLatest([
        this._organisation.practice$.pipe(filterUndefined()),
        this._patientScope.doc$.pipe(filterUndefined()),
      ]).pipe(map(([practice, patient]) => ({ practice, patient })))
    );

    this.packOptions$ = this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.sterilisationPacks$(brand))
    );
  }
}
