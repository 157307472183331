<div class="flex flex-1 flex-col gap-2">
  <ng-container *ngIf="serviceCodesCtrl.controls.length; else noServiceCodes">
    <div
      class="flex flex-1 items-center gap-1"
      *ngFor="
        let control of serviceCodesCtrl.controls;
        trackBy: trackByIndex;
        index as index
      "
    >
      <div
        class="label"
        class="flex"
        *ngIf="control.value | map : resolveServiceCode as serviceCode"
      >
        <div>{{ serviceCode.code }} - {{ serviceCode.title }}</div>
        <mat-icon class="help-icon" [matTooltip]="serviceCode.description"
          >help_outline</mat-icon
        >
      </div>

      <span class="flex-1"></span>

      <span
        class="quantity"
        *ngIf="control.value | map : resolveQuantity : this | async as quantity"
        >{{ quantity }} x
      </span>

      <mat-form-field class="price-input compact" subscriptSizing="dynamic">
        <span matTextPrefix>$&nbsp;</span>
        <mat-label>Price</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="0.01"
          [formControl]="control.controls.price"
        />
      </mat-form-field>
    </div>
  </ng-container>

  <ng-template #noServiceCodes>
    <span class="mat-caption">No service codes</span>
  </ng-template>
</div>
