import { type IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodesMappingHandler } from '../../../mappings/item-codes';
import {
  ADAItemSourceEntity,
  type ID4WADAItem,
} from '../../source/entities/ada-item';

export class D4WItemCodeMappingHandler extends ItemCodesMappingHandler<
  ID4WADAItem,
  ADAItemSourceEntity
> {
  override sourceEntity = new ADAItemSourceEntity();
  override translateFn = (record: ID4WADAItem): IBaseMigrationItemCode => ({
    ...record,
  });
}
