import {
  MixedSchema,
  getSchemaText,
  versionedSchemaToHtml,
} from '@principle-theorem/editor';
import { type IRawTemplate } from '@principle-theorem/principle-core/interfaces';
import { AnyExtension } from '@tiptap/core';

export interface IRenderedTemplate {
  text: string;
  html: string;
}

export function renderRawTemplate(
  rawTemplate: IRawTemplate,
  extensions: AnyExtension[] = []
): IRenderedTemplate {
  return renderVersionedSchema(rawTemplate.content, extensions);
}

export function renderVersionedSchema(
  content: MixedSchema,
  extensions: AnyExtension[] = []
): IRenderedTemplate {
  return {
    text: getSchemaText(content),
    html: versionedSchemaToHtml(content, extensions),
  };
}
