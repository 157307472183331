import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type ITreatmentPlan } from '@principle-theorem/principle-core/interfaces';
import { type ISerialisedEntityState } from '@principle-theorem/ng-shared';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { TreatmentPlanActions } from '../../actions/treatment-plans';

export type ITreatmentPlansState = ISerialisedEntityState<
  SerialisedData<WithRef<ITreatmentPlan>>
>;

export const treatmentPlansAdapter: EntityAdapter<
  SerialisedData<WithRef<ITreatmentPlan>>
> = createEntityAdapter({
  selectId: (treatmentPlan) => treatmentPlan.ref.id,
  sortComparer: false,
});

export const initialTreatmentPlans: ITreatmentPlansState =
  treatmentPlansAdapter.getInitialState({
    loaded: false,
  });

export const treatmentPlansReducer: ActionReducer<
  ITreatmentPlansState,
  Action
> = createReducer(
  initialTreatmentPlans,

  on(TreatmentPlanActions.selectTreatmentPlan, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),

  on(TreatmentPlanActions.addTreatmentPlan, (state, { treatmentPlan }) => {
    return treatmentPlansAdapter.addOne(treatmentPlan, state);
  }),

  on(
    TreatmentPlanActions.updateTreatmentPlan,
    (state, { planRef, changes }) => {
      return treatmentPlansAdapter.updateOne(
        {
          id: planRef.id,
          changes,
        },
        state
      );
    }
  ),

  on(
    TreatmentPlanActions.loadTreatmentPlansSuccess,
    (state, { treatmentPlans }) =>
      treatmentPlansAdapter.setAll(treatmentPlans, {
        ...state,
        loaded: true,
      })
  ),

  on(TreatmentPlanActions.deleteTreatmentPlan, (state, { ref }) => {
    return treatmentPlansAdapter.removeOne(ref.id, state);
  }),

  on(TreatmentPlanActions.clearTreatmentPlans, (state) => {
    return treatmentPlansAdapter.removeAll({
      ...state,
      selectedId: undefined,
    });
  }),

  on(TreatmentPlanActions.addTreatmentSteps, (state, { stepRefs, index }) => {
    return treatmentPlansAdapter.map((treatmentPlan) => {
      const position = !index ? treatmentPlan.steps.length : index;
      const steps = [...treatmentPlan.steps];
      steps.splice(position, 0, ...stepRefs);

      return {
        ...treatmentPlan,
        steps,
      };
    }, state);
  }),

  on(TreatmentPlanActions.removeTreatmentStep, (state, step) => {
    return treatmentPlansAdapter.map((treatmentPlan) => {
      const stepToRemove = treatmentPlan.steps.find(
        (planStep) => planStep.id === step.ref.id
      );

      if (!stepToRemove) {
        return treatmentPlan;
      }

      return {
        ...treatmentPlan,
        steps: treatmentPlan.steps.filter(
          (planStep) => planStep.id !== step.ref.id
        ),
      };
    }, state);
  })
);
