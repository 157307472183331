<mat-toolbar color="accent">Create Team</mat-toolbar>

<mat-dialog-content>
  <pr-team-form #teamForm fxFlex (submitted)="submit($event)" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="teamForm.submit()">
      Create
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
