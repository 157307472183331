import {
  Money,
  TaxRate,
  TaxStrategy,
  roundTo2Decimals,
} from '@principle-theorem/accounting';
import {
  Invoice,
  Patient,
  Transaction,
  TransactionOperators,
  buildInvoiceForAppointment,
  productToLineItem,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IMigratedDataSummary,
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IInvoice,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IProductLineItem,
  type ISourceEntityRecord,
  type ITransaction,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  doc$,
  getDoc,
  getError,
  multiMap,
  sortByCreatedAt,
  toMomentTz,
  toTimestamp,
  type DocumentReference,
  type IIdentifiable,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentInvoiceAdjustmentSourceEntity,
  type IPraktikaAppointmentInvoiceAdjustment,
  type IPraktikaAppointmentInvoiceAdjustmentTranslations,
} from '../../source/entities/appointment-invoice-adjustment';
import {
  PatientAppointmentInvoicePaymentSourceEntity,
  PraktikaTransactionEffect,
  PraktikaTransationType,
  type IPraktikaAppointmentInvoicePayment,
  type IPraktikaAppointmentInvoicePaymentTranslations,
} from '../../source/entities/appointment-invoice-payment';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  type IPraktikaAppointment,
  type IPraktikaAppointmentFilters,
  type IPraktikaAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientAppointmentDestinationEntity } from './patient-appointments';
import { PatientDepositDestinationEntity } from './patient-deposits';
import { PatientTreatmentPlanDestinationEntity } from './patient-treatment-plan';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

interface IInvoiceBuildData {
  invoice: IInvoice;
  // accountCreditAmount: number;
  transactions: (IIdentifiable & ITransaction)[];
}

interface IInvoiceUpdateData {
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
}

interface IInvoiceSuccessData extends IInvoiceUpdateData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
}

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientInvoices',
    label: 'Patient Invoices',
    description: `Each service code will be nested under a "Custom Service Code" treatment on the invoice. Products will be shown as normal.

    If a refund to card needs to be done for a Praktika payment, it will need to be performed in Praktika, and record a manual refund in Principle.
    Transactions, invoice adjustments, refunds etc... will be done as expected in Principle.`,
  },
});

export const PATIENT_INVOICE_CUSTOM_MAPPING_TYPE = 'patientInvoice';

export interface IPatientInvoiceJobData {
  sourceAppointment: IGetRecordResponse<
    IPraktikaAppointment,
    IPraktikaAppointmentTranslations,
    IPraktikaAppointmentFilters
  >;
}

export class PatientInvoiceDestinationEntity extends BaseDestinationEntity<
  IInvoiceSuccessData,
  IPatientInvoiceJobData,
  IPatientInvoiceJobData
> {
  destinationEntity = PATIENT_INVOICE_DESTINATION_ENTITY;
  override canMigrateByDateRange = true;

  sourceCountComparison = new PatientAppointmentSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
    invoicePayments: new PatientAppointmentInvoicePaymentSourceEntity(),
    invoiceAdjustments: new PatientAppointmentInvoiceAdjustmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
    appointments: new PatientAppointmentDestinationEntity(),
    deposits: new PatientDepositDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientInvoiceJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceAppointment.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IInvoiceSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      doc$(record.data.invoiceRef),
      record.data.transactionRefs.length
        ? combineLatest(
            record.data.transactionRefs.map((transactionRef) =>
              doc$(transactionRef)
            )
          )
        : of([]),
    ]).pipe(
      map(([invoiceRef, transactions]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Invoice',
            data: invoiceRef,
          },
        ];

        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transactions',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp
  ): Observable<IPatientInvoiceJobData[]> {
    return this.sourceEntities.appointments
      .getRecords$(
        migration,
        10000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(multiMap((sourceAppointment) => ({ sourceAppointment })));
  }

  getDestinationEntityRecordUid(data: IPatientInvoiceJobData): string {
    return data.sourceAppointment.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ):
    | IPatientInvoiceJobData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    return data;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientInvoiceJobData
  ): IPatientInvoiceJobData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    _jobData: IPatientInvoiceJobData,
    migrationData: IPatientInvoiceJobData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: migrationData.sourceAppointment.record.uid,
      label: migrationData.sourceAppointment.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ): Promise<IDestinationEntityRecord> {
    const appointmentId =
      data.sourceAppointment.data.data.appointment_id.toString();
    const patientId =
      data.sourceAppointment.data.data.appointment_patientid.toString();
    const patientMap = await translationMapHandler.getDestination<IPatient>(
      patientId,
      PATIENT_RESOURCE_TYPE
    );
    const appointmentMap =
      await translationMapHandler.getDestination<IAppointment>(
        appointmentId,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    if (!patientMap || !appointmentMap) {
      const message: string[] = [];
      if (!patientMap) {
        message.push(`No patient for id ${patientId}`);
      }
      if (!appointmentMap) {
        message.push(`No appointment for id ${appointmentId}`);
      }

      return this._buildErrorResponse(
        data.sourceAppointment,
        message.join('; ')
      );
    }

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'appointmentId',
        appointmentId
      );

    const backupDate = toMomentTz(
      migration.configuration.backupDate,
      migration.configuration.timezone
    );
    if (
      !data.sourceAppointment.data.data.appointment_taxinvoice_id ||
      !procedures.length ||
      (data.sourceAppointment.data.translations.from &&
        toMomentTz(
          data.sourceAppointment.data.translations.from,
          migration.configuration.timezone
        ).isAfter(backupDate.endOf('day')))
    ) {
      return this._buildSuccessResponse(data.sourceAppointment);
    }

    const invoicePayments =
      await this.sourceEntities.invoicePayments.filterRecords(
        migration,
        'appointmentId',
        appointmentId
      );

    const invoiceAdjustments =
      await this.sourceEntities.invoiceAdjustments.filterRecords(
        migration,
        'appointmentId',
        appointmentId
      );

    try {
      const invoiceData = await this._buildInvoiceData(
        data.sourceAppointment,
        appointmentMap,
        patientMap,
        invoicePayments,
        invoiceAdjustments,
        procedures
      );

      const updateRefs = await this._upsertInvoice(
        invoiceData,
        appointmentId,
        translationMapHandler,
        appointmentMap,
        patientMap
      );

      return this._buildSuccessResponse(data.sourceAppointment, updateRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourceAppointment, getError(error));
    }
  }

  private _buildSuccessResponse(
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    updateData?: IInvoiceUpdateData
  ): IDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      data: {
        sourceRef: appointment.record.ref,
        ...updateData,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertInvoice(
    invoiceData: IInvoiceBuildData,
    appointmentUid: string,
    translationMap: TranslationMapHandler,
    appointmentRef: DocumentReference<IAppointment>,
    patientRef: DocumentReference<IPatient>
  ): Promise<IInvoiceUpdateData> {
    const invoiceDestinationRef = await translationMap.getDestination(
      appointmentUid,
      PATIENT_INVOICE_CUSTOM_MAPPING_TYPE
    );

    const invoiceRef = await FirestoreMigrate.upsertDoc(
      Patient.invoiceCol({
        ref: patientRef,
      }),
      { ...invoiceData.invoice },
      invoiceDestinationRef?.id
    );

    if (!invoiceDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: appointmentUid,
        destinationIdentifier: invoiceRef,
        resourceType: PATIENT_INVOICE_CUSTOM_MAPPING_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(appointmentRef, {
      invoiceRef,
    });

    const transactionRefs = await asyncForEach(
      invoiceData.transactions,
      (transaction) =>
        FirestoreMigrate.upsertDoc(
          Invoice.transactionCol({
            ref: invoiceRef,
          }),
          transaction,
          transaction.uid
        )
    );

    return {
      invoiceRef,
      transactionRefs,
    };
  }

  private async _buildInvoiceData(
    sourceAppointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    appointmentRef: DocumentReference<IAppointment>,
    patientRef: DocumentReference<IPatient>,
    invoicePayments: IGetRecordResponse<
      IPraktikaAppointmentInvoicePayment,
      IPraktikaAppointmentInvoicePaymentTranslations
    >[],
    invoiceAdjustments: IGetRecordResponse<
      IPraktikaAppointmentInvoiceAdjustment,
      IPraktikaAppointmentInvoiceAdjustmentTranslations
    >[],
    procedures: IGetRecordResponse<
      IPraktikaAppointmentProcedure,
      IPraktikaAppointmentProcedureTranslations,
      IPraktikaAppointmentProcedureFilters
    >[]
  ): Promise<IInvoiceBuildData> {
    const appointment = await getDoc(appointmentRef);
    if (!sourceAppointment.data.data.appointment_taxinvoice_id) {
      throw Error('No invoice for appointment');
    }

    const invoice = await buildInvoiceForAppointment(
      appointment,
      patientRef,
      TaxRate.GST,
      sourceAppointment.data.translations.createdAt
    );
    invoice.reference =
      sourceAppointment.data.data.appointment_taxinvoice_id.toString();
    invoice.items.push(
      ...getProducts(
        procedures
          .filter((procedure) => procedure.record.filters.isScheduled)
          .map((procedure) => procedure.data.data)
      )
    );

    const transactions = [
      ...getTransactions(invoicePayments, invoice.practice.ref),
      ...getAdjustments(invoiceAdjustments, invoice.practice.ref),
      ...getUsedAccountCredits(invoiceAdjustments, invoice.practice.ref),
    ];

    const status = getInvoiceStatus(
      sourceAppointment.data.data,
      Invoice.balance(invoice, transactions)
    );

    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    const issuedAt =
      lastTransaction?.createdAt ??
      sourceAppointment.data.translations.createdAt;

    if (status !== InvoiceStatus.Draft) {
      invoice.issuedAt = issuedAt;
    }

    Invoice.updateStatus(invoice, status, issuedAt);

    return {
      invoice,
      transactions,
    };
  }

  private _buildErrorResponse(
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? 'Missing required properties for invoice',
      failData: {
        appointmentRef: appointment.record.ref,
      },
    };
  }
}

function getProducts(
  procedures: IPraktikaAppointmentProcedure[]
): IProductLineItem[] {
  return compact(
    procedures.map((procedure) => {
      if (procedure.ada_code) {
        return;
      }

      const taxStatus = procedure.has_gst
        ? TaxStrategy.GSTApplicable
        : TaxStrategy.GSTFree;
      const cost = roundTo2Decimals(parseFloat(procedure.total_fee));

      return productToLineItem(
        {
          name: procedure.description,
          cost,
          taxStatus,
        },
        TaxRate.GST
      );
    })
  );
}

function getInvoiceStatus(
  appointment: IPraktikaAppointment,
  amountRemaining: number
): InvoiceStatus {
  if (amountRemaining === 0) {
    return InvoiceStatus.Paid;
  }

  if (!appointment.appointment_taxinvoice_date) {
    return InvoiceStatus.Draft;
  }

  if (amountRemaining > 0) {
    return InvoiceStatus.Issued;
  }

  if (amountRemaining < 0) {
    // eslint-disable-next-line no-console
    console.error(`Invoice overpaid by $${amountRemaining}`);
    return InvoiceStatus.Paid;
  }

  return InvoiceStatus.Draft;
}

function getTransactions(
  payments: IGetRecordResponse<
    IPraktikaAppointmentInvoicePayment,
    IPraktikaAppointmentInvoicePaymentTranslations
  >[],
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return payments.map((paymentRecord) => {
    const payment = paymentRecord.data.data;
    const uid = payment.id.toString();
    return {
      ...Transaction.init({
        ...Transaction.internalReference(TransactionProvider.Manual, uid),
        type:
          payment.effect === PraktikaTransactionEffect.Cr
            ? TransactionType.Incoming
            : TransactionType.Outgoing,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(Money.fromCents(payment.amount)),
        extendedData: payment,
        createdAt: paymentRecord.data.translations.effectiveDate,
        practiceRef,
      }),
    };
  });
}

function getUsedAccountCredits(
  adjustments: IGetRecordResponse<
    IPraktikaAppointmentInvoiceAdjustment,
    IPraktikaAppointmentInvoiceAdjustmentTranslations
  >[],
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return adjustments
    .filter(
      (adjustment) =>
        adjustment.data.data.type_id ===
        PraktikaTransationType.TransferFromDepositCr
    )
    .map((adjustmentRecord) => {
      const adjustment = adjustmentRecord.data.data;
      const uid = adjustment.id.toString();
      return Transaction.init({
        ...Transaction.internalReference(
          TransactionProvider.AccountCredit,
          uid
        ),
        type: TransactionType.Incoming,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(Money.fromCents(adjustment.amount)),
        extendedData: adjustment,
        createdAt: adjustmentRecord.data.translations.effectiveDate,
        practiceRef,
      });
    });
}

function getAdjustments(
  adjustments: IGetRecordResponse<
    IPraktikaAppointmentInvoiceAdjustment,
    IPraktikaAppointmentInvoiceAdjustmentTranslations
  >[],
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return adjustments
    .filter(
      (adjustmentRecord) =>
        ![
          PraktikaTransationType.TransferFromDepositCr,
          PraktikaTransationType.TransferFromDepositDr,
        ].includes(adjustmentRecord.data.data.type_id)
    )
    .map((adjustmentRecord) => {
      const adjustment = adjustmentRecord.data.data;
      const uid = adjustment.id.toString();
      return Transaction.init({
        ...Transaction.internalReference(TransactionProvider.Discount, uid),
        type: TransactionType.Incoming,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(Money.fromCents(adjustment.amount)),
        extendedData: adjustment,
        createdAt: adjustmentRecord.data.translations.effectiveDate,
        practiceRef,
      });
    });
}
