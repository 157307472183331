import {
  Component,
  HostBinding,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pt-colour-sliver',
  template: '',
  styleUrls: ['./colour-sliver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColourSliverComponent {
  @Input()
  @HostBinding('style.background-color')
  colour = 'none';
}
