import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  TaskStatus,
  TaskType,
  type RecurringTaskDueTiming,
  type TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import {
  type Frequency,
  type IRecurrencePattern,
  type Time24hrType,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getSummary } from '../../task-form-summary';
import {
  RecurringTaskConfigurationForm,
  type IRecurringTaskFormData,
} from '../recurring-task-configuration-form/recurring-task-configuration-form';

export interface ITaskFormData extends IRecurringTaskFormData {
  type: TaskType;
  status: TaskStatus;
  dueDate?: moment.Moment;
  dueTime?: string;
}

export class TaskFormGroup extends TypedFormGroup<ITaskFormData> {
  summary$: Observable<string> =
    this.controls.recurrenceTiming.valueChanges.pipe(
      startWith(''),
      map(() => getSummary(this.getRawValue()))
    );

  constructor() {
    super({
      ...new RecurringTaskConfigurationForm().controls,
      type: new TypedFormControl<TaskType>(TaskType.Misc, Validators.required),
      status: new TypedFormControl<TaskStatus>(TaskStatus.Open),
      dueDate: new TypedFormControl<moment.Moment>().withGuard(moment.isMoment),
      dueTime: new TypedFormControl<string>().withGuard(isString),
      recurrencePattern: new TypedFormControl<IRecurrencePattern>(undefined),
      recurringFrequency: new TypedFormControl<Frequency>(),
      recurrenceTiming: new TypedFormGroup<RecurringTaskDueTiming>({
        time: new TypedFormControl<Time24hrType>(),
        unit: new TypedFormControl<TimingUnit>(),
        amount: new TypedFormControl<number>(),
      }),
    });
  }
}
