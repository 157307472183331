import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import {
  EditorPresetsService,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { CustomFormElement } from '@principle-theorem/principle-core';
import {
  AnyCustomFormElement,
  CustomFormContentElement,
  ICustomFormContentOptions,
} from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, firstValueFrom } from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FormBuilderElementPreviewComponent } from '../../../../form-builder/form-builder-element-preview/form-builder-element-preview.component';
import { FormBuilderService } from '../../../../form-builder/form-builder.service';
import { TypeNarrowedCustomFormElement } from '../../../../form-builder/lib/type-narrowed-custom-form-element';
import { NgMaterialModule } from '@principle-theorem/ng-material';

@Component({
  selector: 'pr-preview-form-issue-element',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMaterialModule,
    PrincipleEditorModule,
    FormBuilderElementPreviewComponent,
  ],
  templateUrl: './preview-form-issue-element.component.html',
  styleUrl: './preview-form-issue-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex grow flex-col' },
})
export class PreviewFormIssueElementComponent implements OnDestroy {
  private _formBuilder = inject(FormBuilderService);
  private _editorPresets = inject(EditorPresetsService);
  private _onDestroy$ = new Subject<void>();
  private _type: TypeNarrowedCustomFormElement;
  element$ = new ReplaySubject<AnyCustomFormElement>(1);
  isHorizontal$: Observable<boolean>;
  editableContent$: Observable<CustomFormContentElement | undefined>;
  extensions = this._editorPresets.defaultToHTMLExtensions();
  contentCtrl = new TypedFormControl<VersionedSchema>(initVersionedSchema(), [
    Validators.required,
  ]);

  @Input()
  set element(element: AnyCustomFormElement) {
    if (element) {
      this.element$.next(element);
    }
  }

  constructor() {
    this._type = new TypeNarrowedCustomFormElement(this.element$);
    this.isHorizontal$ = this.element$.pipe(
      map((element) => CustomFormElement.isHorizontal(element))
    );
    this.editableContent$ = this._type.content$.pipe(
      map((content) => (content?.options.editableOnIssue ? content : undefined))
    );
    this._type.content$
      .pipe(
        distinctUntilChanged((a, b) => a?.uid === b?.uid),
        takeUntil(this._onDestroy$)
      )
      .subscribe((element) => this._patchForm(element));
    validFormControlChanges$(this.contentCtrl)
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((content) => void this._updateContent(content));
  }

  ngOnDestroy(): void {
    if (this.contentCtrl.valid) {
      void this._updateContent(this.contentCtrl.value);
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _patchForm(element?: CustomFormContentElement): void {
    const options = { emitEvent: false };
    if (!element) {
      this.contentCtrl.reset(undefined, options);
      return;
    }
    this.contentCtrl.patchValue(element.options.content, options);
  }

  private async _updateContent(content: VersionedSchema): Promise<void> {
    const element = await firstValueFrom(this._type.content$);
    if (!element) {
      return;
    }
    const options: ICustomFormContentOptions = {
      ...element.options,
      content,
    };
    this._formBuilder.updateElement({ ...element, options });
  }
}
