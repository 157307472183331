import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  IPatientFormsTokenData,
  IPatientPortalForm,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  IPatientFormEditDialogRequest,
  IPatientFormEditDialogResponse,
  PatientFormEditDialogComponent,
} from '../../components/patient-form-edit-dialog/patient-form-edit-dialog.component';
import {
  InvalidTokenError,
  PatientPortalUI,
} from '../../lib/patient-portal-ui';
import { PatientPortalFunctionsService } from '../../patient-portal-functions.service';

interface IPatientFormsState {
  loading: boolean;
  submittedFormUids: string[];
  errorMessage?: string;
  tokenUid?: string;
  tokenData?: IPatientFormsTokenData;
}

const INITIAL_STATE: IPatientFormsState = {
  loading: false,
  submittedFormUids: [],
};

@Injectable()
export class PatientFormsStore extends ComponentStore<IPatientFormsState> {
  private _functions = inject(PatientPortalFunctionsService);
  private _dialog = inject(MatDialog);
  errorMessage = this.selectSignal((state) => state.errorMessage);
  loading = this.selectSignal((state) => state.loading);
  tokenData = this.selectSignal((state) => state.tokenData);
  formsData = this.selectSignal(
    this.tokenData,
    (tokenData) => tokenData?.formsData
  );
  patientForms = this.selectSignal(
    this.formsData,
    (formsData) => formsData?.patientForms ?? []
  );

  readonly loadTokenUid = this.effect(
    (tokenUid$: Observable<string | undefined>) =>
      tokenUid$.pipe(
        tap((tokenUid) => this.patchState({ tokenUid, loading: true })),
        switchMap((tokenUid) => this._getPatientFormsData(tokenUid)),
        tapResponse(
          (tokenData) => this.patchState({ tokenData, loading: false }),
          (e) =>
            this.patchState({
              errorMessage: PatientPortalUI.getErrorMessage(e),
              loading: false,
            })
        )
      )
  );

  constructor() {
    super(INITIAL_STATE);
  }

  reload(): void {
    this.loadTokenUid(this.state().tokenUid);
  }

  hasBeenSubmitted$(form: IPatientPortalForm): Observable<boolean> {
    return this.select((state) => state.submittedFormUids.includes(form.uid));
  }

  async openPatientForm(selectedForm: IPatientPortalForm): Promise<void> {
    const state = this.state();
    const hasBeenSubmitted = state.submittedFormUids.includes(selectedForm.uid);
    if (hasBeenSubmitted || !state.tokenUid || !state.tokenData) {
      return;
    }

    const request: IPatientFormEditDialogRequest = {
      tokenUid: state.tokenUid,
      selectedForm,
      healthCardTypes: state.tokenData?.formsData.healthCardTypes,
      referralSources: state.tokenData?.formsData.referralSources,
      requiredFields: state.tokenData?.formsData.requiredFields,
    };

    const response = await this._dialog
      .open<
        PatientFormEditDialogComponent,
        IPatientFormEditDialogRequest,
        IPatientFormEditDialogResponse
      >(
        PatientFormEditDialogComponent,
        DialogPresets.fullscreen({ data: request })
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }
    this.patchState({
      submittedFormUids: [
        ...this.state().submittedFormUids,
        response.submittedFormUid,
      ],
    });
  }

  private async _getPatientFormsData(
    tokenUid?: string
  ): Promise<IPatientFormsTokenData> {
    if (!tokenUid) {
      throw new InvalidTokenError();
    }
    return this._functions.getPatientFormsData(tokenUid);
  }
}
