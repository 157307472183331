import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ITag } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

interface IFilteredTags {
  tags: WithRef<ITag>[];
  units: number;
}

export class TagLimitBloc {
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  shownTags$: Observable<WithRef<ITag>[]>;
  hiddenTags$: Observable<WithRef<ITag>[]>;
  hiddenTagCount$: Observable<number>;

  constructor(
    public allTags$: Observable<WithRef<ITag>[]>,
    public limit$: Observable<number | undefined>,
    paddingUnits$: Observable<number> = of(2)
  ) {
    this.shownTags$ = combineLatest([
      this.allTags$,
      this.limit$,
      paddingUnits$,
    ]).pipe(
      map(([tags, limit, paddingUnits]) =>
        isNumber(limit) ? this._limitTags(tags, limit, paddingUnits) : tags
      )
    );
    this.hiddenTags$ = combineLatest([this.allTags$, this.shownTags$]).pipe(
      map(([tags, shownTags]) => {
        const shownTagPaths = shownTags.map((tag) => tag.ref.path);
        return tags.filter((tag) => !shownTagPaths.includes(tag.ref.path));
      })
    );
    this.hiddenTagCount$ = this.hiddenTags$.pipe(
      map((hiddenTags) => hiddenTags.length)
    );
  }

  private _limitTags(
    tags: WithRef<ITag>[],
    limit: number,
    paddingUnits = 2
  ): WithRef<ITag>[] {
    const result: IFilteredTags = tags.reduce(
      (acc: IFilteredTags, tag: WithRef<ITag>) => {
        const tagUnits = paddingUnits + tag.name.length + paddingUnits;
        const units = acc.units + tagUnits;
        if (units > limit) {
          return acc;
        }
        return {
          units,
          tags: [...acc.tags, tag],
        };
      },
      { units: 0, tags: [] }
    );

    return result.tags;
  }
}
