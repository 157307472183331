import { getFromSchema } from '@ajsf/core';
import { NOT_APPLICABLE_OPTION } from '@principle-theorem/principle-core';
import {
  FormSchemaPropertyType,
  isFormSchema,
  type CustomFormDataResolverConfigMap,
  type ICustomFormDataResolverConfig,
  type IFormSchema,
  type IFormSchemaProperty,
  type IPatientFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { get, isString, sortBy, uniq, uniqBy } from 'lodash';

interface IFormPropertyValue<T> {
  path: string;
  property: IFormSchemaProperty;
  value: T;
}

export function resolveCustomFormData(
  form: IPatientFormSchema,
  dataField: string,
  fallbackDataResolverConfig?: CustomFormDataResolverConfigMap
): string[] {
  const resolverConfigs = getConfigItems(
    form,
    dataField,
    fallbackDataResolverConfig
  );

  const keyValues = resolverConfigs.reduce(
    (acc: IKeyValue<string>[], configItem) => [
      ...acc,
      ...resolveKeyValues(configItem, form),
    ],
    []
  );

  const sortPaths = resolverConfigs.map((config) => config.path);

  const labels = sortBy(
    uniqBy(keyValues, (item) => item.key),
    (keyValue) => getSortPathIndex(keyValue, sortPaths)
  ).map((item) => item.value);

  return uniq(labels);
}

function resolveKeyValues(
  configItem: ICustomFormDataResolverConfig,
  form: IPatientFormSchema
): IKeyValue<string>[] {
  const path = isString(configItem) ? configItem : configItem.path;
  const properties = getSelectedPropertyValues(form, path);

  return properties.map((propertyValue) => ({
    key: propertyValue.path,
    value: transformValue(configItem, propertyValue),
  }));
}

function getSelectedPropertyValues<T = unknown>(
  form: IPatientFormSchema,
  dataPath: string = ''
): IFormPropertyValue<T>[] {
  const data: unknown = get(form.data, dataPath, {});
  const schemaPath = `/${dataPath.split('.').join('/')}`;
  const schema = getFromSchema(form.schema, schemaPath) as
    | IFormSchema
    | IFormSchemaProperty;

  if (!isFormSchema(schema)) {
    if (!isObject(data)) {
      return data && data !== NOT_APPLICABLE_OPTION
        ? [{ path: dataPath, property: schema, value: data as T }]
        : [];
    }
    return [];
  }

  if (isObject(data) && isFormSchema(schema)) {
    return Object.entries(data)
      .filter(([_key, value]) =>
        value && value !== NOT_APPLICABLE_OPTION ? true : false
      )
      .map(([key, value]): [string, T, IFormSchemaProperty] => [
        key,
        value as T,
        schema.properties[key] as IFormSchemaProperty,
      ])
      .map(([key, value, property]) => ({
        path: `${dataPath}.${key}`,
        property,
        value,
      }));
  }
  // eslint-disable-next-line no-console
  console.error(`Value does not match schema. Resolving: ${dataPath}`);
  return [];
}

function getConfigItems(
  form: IPatientFormSchema,
  dataField: string,
  fallbackDataResolverConfig?: CustomFormDataResolverConfigMap
): ICustomFormDataResolverConfig[] {
  const configItems = get(form.dataResolverConfig, dataField);
  if (configItems) {
    return configItems;
  }
  return fallbackDataResolverConfig
    ? get(fallbackDataResolverConfig, dataField, [])
    : [];
}

function transformValue(
  config: ICustomFormDataResolverConfig,
  data: IFormPropertyValue<unknown>
): string {
  const value = config.mapValue ?? `${String(data.value)}`;
  const title = config.mapTitle ?? data.property.title;
  if (data.property.type === FormSchemaPropertyType.String) {
    return title ? `${title}: ${value}` : value;
  }
  if (data.property.type === FormSchemaPropertyType.Boolean) {
    return title;
  }
  return value;
}

interface IKeyValue<T = unknown> {
  key: string;
  value: T;
}

function getSortPathIndex<T>(
  keyValue: IKeyValue<T>,
  sortPaths: string[]
): number {
  return sortPaths.findIndex((sortPath) => keyValue.key.startsWith(sortPath));
}
