import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { multiSwitchMap, type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-tags-sidebar-list',
    templateUrl: './appointment-tags-sidebar-list.component.html',
    styleUrls: ['./appointment-tags-sidebar-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentTagsSidebarListComponent {
  private _global = inject(GlobalStoreService);
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  tags$: Observable<WithRef<ITag>[]>;
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.tags$ = this.appointment$.pipe(
      map((appointment) => appointment.tags),
      multiSwitchMap((tag) => this._global.getTag$(tag.ref)),
      map(compact)
    );
  }
}
