import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type ICustomReport,
  type ICustomReportFilter,
  type ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import { resolveReportingProperty } from '@principle-theorem/reporting';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type IReportBuilderDataSource,
  ReportBuilderDataSource,
} from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { resolveDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-sources';

@Component({
  selector: 'pr-pre-selected-filters',
  templateUrl: './pre-selected-filters.component.html',
  styleUrls: ['./pre-selected-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreSelectedFiltersComponent {
  customReport$ = new ReplaySubject<WithRef<ICustomReport>>(1);
  dataSource$: Observable<IReportBuilderDataSource>;
  timestampLabel$: Observable<string | undefined>;
  staticFilters$: Observable<ICustomReportFilter[]>;

  @Input()
  set customReport(customReport: WithRef<ICustomReport>) {
    if (customReport) {
      this.customReport$.next(customReport);
    }
  }

  constructor() {
    this.dataSource$ = this.customReport$.pipe(
      map((report) => resolveDataSource(report.dataSource)),
      filterUndefined()
    );
    this.staticFilters$ = this.customReport$.pipe(
      map((report) => report.staticFilters)
    );
    this.timestampLabel$ = combineLatest([
      this.customReport$,
      this.dataSource$,
    ]).pipe(
      map(([report, dataSource]) =>
        this._resolvePropertyLabel(
          dataSource,
          report.queryScopes.dateRange?.queryableTimestamp
        )
      )
    );
  }

  private _resolvePropertyLabel(
    dataSource: IReportBuilderDataSource,
    propertyId?: ReportingReference
  ): string | undefined {
    if (!propertyId) {
      return;
    }
    const property = resolveReportingProperty(dataSource.factTable, propertyId);
    if (!property) {
      return;
    }
    return ReportBuilderDataSource.getPropertyLabel(property);
  }
}
