import {
  InlineNodes,
  isNodeSchema,
  type NodeSchema,
  type RawSchema,
  recursiveReplace,
  type SchemaDataProvider,
} from '@principle-theorem/editor';
import {
  type IMigration,
  type IMigrationLogger,
  Migration,
} from '@principle-theorem/migration-runner';
import { last, without } from 'lodash';

@Migration({
  name: 'move-link-mark-to-node',
  uid: '131f5a54-94a6-4a0a-a637-2587cd44ad5f',
})
export class MoveLinkMarkToNodeMigration implements IMigration {
  async up(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      const parent = last(parents);
      if (!hasLinkMark(item, parent)) {
        return item;
      }
      const link =
        item.marks && item.marks.find((mark) => mark.type === 'link');
      if (!link) {
        return item;
      }

      return {
        type: InlineNodes.Link,
        attrs: link.attrs,
        content: [{ ...item, marks: without(item.marks, link) }],
      };
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }

  async down(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      if (!isLinkNodeSchema(item, last(parents)) || !item.content) {
        return item;
      }
      const newNode: NodeSchema = {
        ...item.content[0],
        marks: [
          ...(item.content[0].marks || []),
          {
            type: 'link',
            attrs: item.attrs,
          },
        ],
      };
      return newNode;
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }
}

function isLinkNodeSchema(
  item: object,
  parent?: object
): item is NodeSchema<InlineNodes.Link> {
  return (
    isNodeSchema(item, parent) && String(item.type) === String(InlineNodes.Link)
  );
}

function hasLinkMark(item: object, parent?: object): item is NodeSchema {
  return isNodeSchema(item, parent) &&
    item.marks &&
    item.marks.some((mark) => mark.type === 'link')
    ? true
    : false;
}
