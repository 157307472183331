import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { IHealthInsuranceDetails } from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, isChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-health-insurance-details-form',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
    ],
    templateUrl: './health-insurance-details-form.component.html',
    styleUrl: './health-insurance-details-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthInsuranceDetailsFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  healthInsuranceForm = new TypedFormGroup<IHealthInsuranceDetails>({
    provider: new TypedFormControl<string | undefined>(undefined),
    membershipNumber: new TypedFormControl<string>(''),
  });

  @Input()
  set healthInsuranceDetails(healthInsuranceDetails: IHealthInsuranceDetails) {
    if (healthInsuranceDetails) {
      this.healthInsuranceForm.setValue(healthInsuranceDetails, {
        emitEvent: false,
      });
    }
  }

  @Output() healthInsuranceDetailsChange = new EventEmitter<
    IHealthInsuranceDetails | undefined
  >();

  constructor() {
    validFormGroupChanges$(this.healthInsuranceForm)
      .pipe(isChanged$(), debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((change) => {
        const formHasValues = Object.values(change).some((value) => value);
        this.healthInsuranceDetailsChange.emit(
          formHasValues ? change : undefined
        );
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
