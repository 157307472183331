import { Injectable } from '@angular/core';
import {
  ISmartpayPurchaseRequestParams,
  ISmartpayTerminalPairRequest,
  ISmartpayTransactionPendingResponse,
} from '@principle-theorem/principle-core/interfaces';
import {
  SerialisedData,
  getError,
  httpsCallable,
  serialise,
  snapshot,
} from '@principle-theorem/shared';

@Injectable()
export class SmartpayService {
  async initiateTransaction(
    request: ISmartpayPurchaseRequestParams
  ): Promise<ISmartpayTransactionPendingResponse> {
    try {
      const result = await snapshot(
        httpsCallable<
          SerialisedData<ISmartpayPurchaseRequestParams>,
          ISmartpayTransactionPendingResponse
        >('http-smartpay-addTransaction')(serialise(request))
      );
      return result;
    } catch (error) {
      throw new Error(`Failed to add transaction: ${getError(error)}`);
    }
  }

  async pairTerminal(request: ISmartpayTerminalPairRequest): Promise<void> {
    try {
      await snapshot(
        httpsCallable<SerialisedData<ISmartpayTerminalPairRequest>, void>(
          'http-smartpay-pairTerminal'
        )(serialise(request))
      );
    } catch (error) {
      throw new Error(`Failed to pair terminal: ${getError(error)}`);
    }
  }
}
