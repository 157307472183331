import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
  selector: 'pr-forms-dashboard',
  templateUrl: './forms-dashboard.component.html',
  styleUrls: ['./forms-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsDashboardComponent {
  patient$: Observable<WithRef<IPatient>>;

  constructor(patientScope: CurrentPatientScope) {
    this.patient$ = patientScope.doc$.pipe(
      filterUndefined(),
      shareReplayCold()
    );
  }
}
