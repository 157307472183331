<div fxLayout="column">
  <pt-notification
    *ngFor="
      let notification of notifications$ | ngrxPush;
      trackBy: trackByNotification
    "
    [notification]="notification"
    [currentUser]="currentUser$ | ngrxPush"
    [extensions]="extensions$ | ngrxPush"
   />
</div>
