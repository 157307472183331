import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { type Observable } from 'rxjs';
import { snapshot } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthGuard {
  constructor(protected _auth: AuthService) {}

  abstract canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree;

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }

  async isLoggedIn(): Promise<boolean> {
    const authUser = await snapshot(this._auth.authUser$);
    return authUser ? true : false;
  }
}
