import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { shareReplayCold } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  isMobile$: Observable<boolean>; // max-width: 599px and orientation: portrait, max-width: 959px and orientation: landscape
  isTablet$: Observable<boolean>; // min-width: 600px and max-width: 839px and orientation: portrait, min-width: 960px and max-width: 1279px and orientation: landscape
  isDesktop$: Observable<boolean>; //  840px and orientation: portrait, min-width: 1280px and orientation: landscape
  minWidthLargeDesktop$: Observable<boolean>; // 1280px and up

  constructor(private _breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(
        map((result) => result.matches),
        shareReplayCold()
      );
    this.isTablet$ = this._breakpointObserver
      .observe([Breakpoints.Tablet])
      .pipe(
        map((result) => result.matches),
        shareReplayCold()
      );
    this.isDesktop$ = this._breakpointObserver.observe([Breakpoints.Web]).pipe(
      map((result) => result.matches),
      shareReplayCold()
    );
    this.minWidthLargeDesktop$ = this._breakpointObserver
      .observe(['(min-width: 1280px)'])
      .pipe(
        map((result) => result.matches),
        shareReplayCold()
      );
  }
}
