import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import {
  EditorPresetsService,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  AnyCustomFormElement,
  CustomFormElementCategory,
  CustomFormSectionElement,
  ICustomFormSectionOptions,
} from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, firstValueFrom } from '@principle-theorem/shared';
import { last } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { FormBuilderService } from '../../form-builder.service';
import { CustomFormElementBlueprint } from '../../lib/custom-form-element-blueprints';

@Component({
    selector: 'pr-form-builder-section-options',
    templateUrl: './form-builder-section-options.component.html',
    styleUrl: './form-builder-section-options.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgMaterialModule,
        PrincipleEditorModule,
    ]
})
export class FormBuilderSectionOptionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _editorPresets = inject(EditorPresetsService);
  private _formBuilder = inject(FormBuilderService);
  element$ = new ReplaySubject<CustomFormSectionElement>(1);
  extensions = this._editorPresets.defaultToHTMLExtensions();
  form = new TypedFormGroup<ICustomFormSectionOptions>({
    title: new TypedFormControl<string>('', []),
    numberOfColumns: new TypedFormControl<number>(1, [Validators.required]),
  });

  @ViewChild('autoFocusElement', { static: true })
  autoFocusElementRef: ElementRef<HTMLElement>;

  @Input()
  set element(element: CustomFormSectionElement) {
    this.element$.next(element);
  }

  constructor() {
    this.element$
      .pipe(distinctUntilKeyChanged('uid'), takeUntil(this._onDestroy$))
      .subscribe((section) => {
        this._patchForm(section);
        this._focus();
      });

    validFormGroupChanges$(this.form)
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((options) => void this._updateOptions(options));
  }

  ngOnDestroy(): void {
    if (this.form.valid) {
      void this._updateOptions(this.form.value);
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _patchForm(element: CustomFormSectionElement): void {
    const options: ICustomFormSectionOptions = {
      ...element.options,
      title: element.options?.title ?? '',
    };
    this.form.patchValue(options, { emitEvent: false });
  }

  private async _updateOptions(
    options: ICustomFormSectionOptions
  ): Promise<void> {
    const element = await firstValueFrom(this.element$);
    if (!element) {
      return;
    }

    const children = this._rebuildColumns(element, options.numberOfColumns);
    this._formBuilder.updateElement({
      ...element,
      children,
      options: this._getOptionsValue(options),
    });
  }

  private _rebuildColumns(
    element: CustomFormSectionElement,
    numberOfColumns: number
  ): AnyCustomFormElement[] {
    const removedColumns = element.children.filter(
      (_child, index) => index >= numberOfColumns
    );
    const elementsFromRemovedColumns = removedColumns.flatMap(
      (column) => column.children
    );

    const keepColumns = element.children.filter(
      (_child, index) => index < numberOfColumns
    );

    element.children = Array.from({ length: numberOfColumns }).map(
      (_, index) =>
        keepColumns[index] ??
        CustomFormElementBlueprint.createContainer(
          'column',
          [],
          CustomFormElementCategory.Field
        )
    );

    const lastColumn = last(element.children);
    if (lastColumn) {
      lastColumn.children = [
        lastColumn.children,
        elementsFromRemovedColumns,
      ].flat();
    }

    return element.children;
  }

  private _getOptionsValue(
    options: ICustomFormSectionOptions
  ): ICustomFormSectionOptions {
    return {
      title: options.title?.length ? options.title : undefined,
      numberOfColumns: options.numberOfColumns,
    };
  }

  private _focus(): void {
    this.autoFocusElementRef?.nativeElement.focus();
  }
}
