import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { type MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, type Params } from '@angular/router';
import {
  CurrentBrandScope,
  CurrentPracticeScope,
  ITaskBlockFilters,
  OrganisationService,
  StateBasedNavigationService,
  TypesenseSearchService,
  type ITimeRange,
} from '@principle-theorem/ng-principle-shared';
import {
  ObservableDataTable,
  PaginatedFirestoreTable,
  SelectionListStore,
  SidebarManagerService,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  ITypesenseTaskWithRef,
} from '@principle-theorem/principle-core';
import {
  TASK_PRIORITIES,
  TaskStatus,
  type IStaffer,
  type ITeam,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc,
  isSameRef,
  multiSwitchMap,
  shareReplayCold,
  slugify,
  snapshot,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { camelCase } from 'lodash';
import {
  Subject,
  combineLatest,
  interval,
  merge,
  of,
  type Observable,
} from 'rxjs';
import {
  auditTime,
  map,
  startWith,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { CreateTaskActionService } from '../../create-task-action.service';
import { TaskFilter, TaskStatusFilter } from '../../task-filter';
import { TaskLoadDialogBloc } from '../../task-load-dialog-bloc';
import { TaskManager } from '../../task-manager';
import {
  ALL_TASKS_PRESET,
  MY_TASKS_PRESET,
  TASK_PRESET_FILTERS,
  TaskPresetFilter,
  WATCHED_TASKS_PRESET,
  type ITaskPresetFilter,
} from '../../task-preset-options';
import {
  TASK_DUE_DATE_SORT,
  TASK_SORT_OPTIONS,
  taskSortOptionById,
  type ITaskSortOption,
} from '../../task-sort-options';

@Component({
    selector: 'pr-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SelectionListStore],
    standalone: false
})
export class TaskListComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _taskManager: TaskManager;
  private _taskLoadDialogBloc: TaskLoadDialogBloc;
  trackByPreset = TrackByFunctions.uniqueId<ITaskPresetFilter>();
  trackBySortOption = TrackByFunctions.uniqueId<ITaskSortOption>();
  trackByTeam = TrackByFunctions.ref<WithRef<ITeam>>();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  trackByPriority = TrackByFunctions.variable<TaskPriority>();
  taskPresetOptions: ITaskPresetFilter[] = TASK_PRESET_FILTERS;
  presetBadgeCounts$: { [key: string]: Observable<number> };
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize = 50;
  recordCount$: Observable<number>;
  tasks$: Observable<ITypesenseTaskWithRef[]>;

  staff$: Observable<WithRef<IStaffer>[]>;
  teams$: Observable<WithRef<ITeam>[]>;
  emptyState$: Observable<boolean>;
  emptySearch$: Observable<boolean>;
  pollTasks$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  paginatedTable = new PaginatedFirestoreTable<ITypesenseTaskWithRef>(
    this.pageSize
  );
  sortControl = new TypedFormControl<ITaskSortOption>();
  searchCtrl = new TypedFormControl<string>('');

  selectedItem: ITypesenseTaskWithRef;

  priorities: TaskPriority[] = TASK_PRIORITIES;
  selectedSortOption: ITaskSortOption = TASK_DUE_DATE_SORT;
  sortOptions: ITaskSortOption[] = TASK_SORT_OPTIONS;
  taskFilter: TaskFilter;

  constructor(
    public _snackBar: MatSnackBar,
    private _createTaskAction: CreateTaskActionService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _practiceScope: CurrentPracticeScope,
    private _brandScope: CurrentBrandScope,
    private _organisation: OrganisationService,
    dialog: MatDialog,
    stateNav: StateBasedNavigationService,
    public display: SidebarManagerService,
    public selectionList: SelectionListStore<ITypesenseTaskWithRef>,
    private _search: TypesenseSearchService
  ) {
    const practice$ = this._practiceScope
      .asObservable$()
      .pipe(filterUndefined());

    this._taskLoadDialogBloc = new TaskLoadDialogBloc(
      this._route,
      dialog,
      stateNav,
      this._onDestroy$,
      practice$
    );

    this.teams$ = this._brandScope.model$.pipe(
      switchMap((brand?) => (brand ? Brand.teams$(brand) : of([])))
    );
    this.staff$ = this._brandScope.model$.pipe(
      switchMap((brand?) => (brand ? Brand.staff$(brand) : of([])))
    );

    this._organisation.staffer$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((staffer: WithRef<IStaffer>) => {
        this._taskManager = new TaskManager(staffer);
      });

    this.taskFilter = new TaskFilter(
      this.staff$,
      this.teams$,
      this._route.queryParams.pipe(takeUntil(this._onDestroy$))
    );

    const sortBy$ = combineLatest([
      this.taskFilter.activeSort$,
      this.taskFilter.activeSortDirection$,
    ]).pipe(map(([sort, direction]) => `${sort}:${direction}`));

    const filters$ = combineLatest([
      this.taskFilter.preset$,
      this.taskFilter.status$,
      this.taskFilter.filteredRange$,
      practice$,
      this._organisation.staffer$.pipe(filterUndefined()),
    ]).pipe(
      auditTime(200),
      map(([preset, status, dateRange, practice, staffer]) => {
        let filters: ITaskBlockFilters = {
          practiceRef: practice.ref,
          dateRange: dateRange?.fromTo,
          statuses: [],
          showDeleted: false,
          ownerRef: undefined,
          assigneeRefs: undefined,
        };

        switch (camelCase(preset)) {
          case TaskPresetFilter.MyTasks:
            filters = {
              ...filters,
              ...MY_TASKS_PRESET.filter(staffer),
            };
            break;
          case TaskPresetFilter.WatchedTasks:
            filters = {
              ...filters,
              ...WATCHED_TASKS_PRESET.filter(staffer),
            };
            break;
          default:
            break;
        }

        switch (status) {
          case TaskStatusFilter.Active:
            filters.statuses = [TaskStatus.Open];
            break;
          case TaskStatusFilter.Completed:
            filters.statuses = [TaskStatus.Complete];
            break;
          case TaskStatusFilter.Deleted:
            filters.showDeleted = true;
            break;
          default:
            break;
        }

        return filters;
      })
    );

    const search$ = merge(
      this.searchCtrl.valueChanges,
      this.pollTasks$.pipe(
        switchMap(() =>
          interval(1000).pipe(
            takeWhile((timer) => {
              return timer < 10;
            }),
            switchMap(() => this._search.clearTaskCache()),
            map(() => this.searchCtrl.value)
          )
        )
      )
    ).pipe(
      startWith(''),
      map((search) => search || '*')
    );

    const queryResult$ = this._search
      .taskQuery$(
        search$,
        filters$,
        sortBy$,
        this.paginatedTable.pageIndex$.pipe(map((index) => index + 1)),
        this.paginatedTable.pageSize$
      )
      .pipe(
        tap((results) => {
          this.selectionList.loadOptions(results.results);
        }),
        shareReplayCold()
      );

    this.recordCount$ = queryResult$.pipe(
      map((queryResult) => queryResult.numberFound)
    );

    this.tasks$ = queryResult$.pipe(map((queryResult) => queryResult.results));
    this._loadDataTable(this.tasks$);

    this._initialiseSort();

    this.selectionList.setCompareFn(isSameRef);

    this.presetBadgeCounts$ = {
      [MY_TASKS_PRESET.id]: this._badgeCount$(MY_TASKS_PRESET),
      [WATCHED_TASKS_PRESET.id]: this._badgeCount$(WATCHED_TASKS_PRESET),
      [ALL_TASKS_PRESET.id]: this._badgeCount$(ALL_TASKS_PRESET),
    };

    this.emptyState$ = this.tasks$.pipe(map((tasks) => !tasks.length));

    this.emptySearch$ = combineLatest([this.emptyState$, this.tasks$]).pipe(
      map(([emptyState, filteredData]) => !emptyState && !filteredData.length)
    );

    this.sortControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((sort) => {
        void this._updateQueryParams({ sort: slugify(sort.id) });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async updatePresetFilters(filterType: TaskPresetFilter): Promise<void> {
    await this._updateQueryParams({
      preset: slugify(filterType),
    });
  }

  async updateTaskStatusFilter(statusFilter: TaskStatusFilter): Promise<void> {
    await this._updateQueryParams({
      status: slugify(statusFilter),
    });
  }

  /**
   * Open the task form dialog
   */
  async addNewTask(): Promise<void> {
    await this._createTaskAction.do();
    this.pollTasks$.next();
  }

  async openTask(task: ITypesenseTaskWithRef): Promise<void> {
    this._taskLoadDialogBloc.openTask$.next(await getDoc(task.ref));
  }

  resetSelected(): void {
    this.selectionList.resetSelected();
  }

  /**
   * Method will open the snackbar and run the passed callback only if the the snackbar was
   * not dismissed by the Cancel action
   * @param message string
   * @param callback function
   * @returns void
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  callActionAfterDismiss(message: string, callback: () => {}): void {
    this._snackBar
      .open(message, 'Cancel')
      .afterDismissed()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((dismissEvent: MatSnackBarDismiss) => {
        if (!dismissEvent.dismissedByAction) {
          callback();
          this.resetSelected();
        }
      });
  }

  async updateAssignee(
    assignee: WithRef<ITeam> | WithRef<IStaffer>
  ): Promise<void> {
    const selectedTasks = await snapshot(
      this.selectionList.selected$.pipe(
        multiSwitchMap((task) => getDoc(task.ref))
      )
    );
    this.callActionAfterDismiss('Updating Task Assignee', async () => {
      await this._taskManager.updateAssignedUser(selectedTasks, assignee);
      this.pollTasks$.next();
    });
  }

  async updatePriority(priority: TaskPriority): Promise<void> {
    const selectedTasks = await snapshot(
      this.selectionList.selected$.pipe(
        multiSwitchMap((task) => getDoc(task.ref))
      )
    );
    this.callActionAfterDismiss('Updating Task Priority', async () => {
      await this._taskManager.updatePriority(selectedTasks, priority);
      this.pollTasks$.next();
    });
  }

  async updateDueDate(event: MatDatepickerInputEvent<Date>): Promise<void> {
    const selectedTasks = await snapshot(
      this.selectionList.selected$.pipe(
        multiSwitchMap((task) => getDoc(task.ref))
      )
    );
    this.callActionAfterDismiss('Updating Due Date', async () => {
      await this._taskManager.updateDueDate(
        selectedTasks,
        event.value ? toMoment(event.value) : undefined
      );
      this.pollTasks$.next();
    });
  }

  async updateCompleted(isComplete: boolean): Promise<void> {
    let message = 'Marking Tasks Completed';
    if (!isComplete) {
      message = 'Marking Tasks Incomplete';
    }
    const selectedTasks = await snapshot(
      this.selectionList.selected$.pipe(
        multiSwitchMap((task) => getDoc(task.ref))
      )
    );
    this.callActionAfterDismiss(message, async () => {
      await this._taskManager.updateCompleted(selectedTasks, isComplete);
      this.pollTasks$.next();
    });
  }

  async dateRangeChange(range: ITimeRange): Promise<void> {
    await this._updateQueryParams({ dateRange: slugify(range.title) });
  }

  async resetFilters(): Promise<void> {
    this.sortControl.reset(TASK_DUE_DATE_SORT, { emitEvent: false });
    await this._router.navigate([], {
      queryParams: {},
      replaceUrl: true,
    });
  }

  async resetDate(): Promise<void> {
    await this._updateQueryParams({
      dateRange: undefined,
    });
  }

  updateTask(): void {
    this.pollTasks$.next();
  }

  private _badgeCount$(presetFilter: ITaskPresetFilter): Observable<number> {
    if (!presetFilter.showBadge) {
      return of(0);
    }

    return combineLatest([
      this._practiceScope.asObservable$().pipe(filterUndefined()),
      this._organisation.staffer$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([practice, staffer]) =>
        this._search.taskQuery$(
          of('*'),
          of({
            ...presetFilter.filter(staffer),
            statuses: [TaskStatus.Open],
            showDeleted: false,
            practiceRef: practice.ref,
          }),
          of([])
        )
      ),
      map((queryResult) => queryResult.numberFound),
      shareReplayCold()
    );
  }

  private async _updateQueryParams(params: Params): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  private _initialiseSort(): void {
    const sort = this._route.snapshot.queryParamMap.get('sort') || undefined;
    if (!sort) {
      this.sortControl.setValue(TASK_DUE_DATE_SORT);
      return;
    }
    const sortOption = taskSortOptionById(sort);
    this.sortControl.setValue(sortOption || TASK_DUE_DATE_SORT);
  }

  private _loadDataTable(records$: Observable<ITypesenseTaskWithRef[]>): void {
    this.paginatedTable.dataTable = new ObservableDataTable(records$);
    this.paginatedTable.dataTable.pageSize = this.pageSize;
    this.paginatedTable.dataTable.pageSizeOptions = this.pageSizeOptions;
    this.paginatedTable.dataTable.displayColumns = [];
  }
}
