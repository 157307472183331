import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartEffects } from './effects/chart.effects';
import { ChartableConfigurationEffects } from './effects/chartable-configuration.effects';
import { TreatmentPlansEffects } from './effects/treatment-plans/treatment-plans.effects';
import { TreatmentStepsEffects } from './effects/treatment-plans/treatment-steps.effects';
import { ChartFacade } from './facades/chart.facade';
import { ChartedConfigurationFacade } from './facades/charted-configuration.facade';
import { FeeScheduleFacade } from './facades/fee-schedule.facade';
import { PerioChartFacade } from './facades/perio-chart.facade';
import {
  TreatmentPlanFacade,
  TreatmentStepsFacade,
} from './facades/treatment-plans';
import { metaReducers, reducers } from './reducers/reducers';
import { CHART_FEATURE_KEY } from './selectors';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CHART_FEATURE_KEY, reducers, { metaReducers }),
    EffectsModule.forFeature([
      ChartEffects,
      ChartableConfigurationEffects,
      TreatmentPlansEffects,
      TreatmentStepsEffects,
    ]),
  ],
  providers: [
    ChartFacade,
    PerioChartFacade,
    ChartedConfigurationFacade,
    TreatmentPlanFacade,
    TreatmentStepsFacade,
    FeeScheduleFacade,
  ],
})
export class NgClinicalChartingStoreModule {}
