import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { ConnectedDialogModule } from './connected-dialog/connected-dialog.module';
import { BaseMenuComponent } from './right-click-menu/base-menu-component/base-menu.component';
import { MenuTriggerPlaceholderComponent } from './right-click-menu/menu-trigger-placeholder/menu-trigger-placeholder.component';
import { RightClickMenuDirective } from './right-click-menu/right-click-menu.directive';

@NgModule({
  imports: [CommonModule, NgMaterialModule, ConnectedDialogModule],
  declarations: [
    MenuTriggerPlaceholderComponent,
    RightClickMenuDirective,
    BaseMenuComponent,
  ],
  exports: [
    ConnectedDialogModule,
    MenuTriggerPlaceholderComponent,
    RightClickMenuDirective,
  ],
})
export class NgCoreModule {}
