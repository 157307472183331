import { getTransactionAmount } from '@principle-theorem/principle-core';
import {
  MeasureFormatter,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { ITransactionDimension } from '@principle-theorem/reporting/interfaces';
import { isNumber } from 'lodash';
import { BaseDimensionMeasures } from '../base-measures';
import {
  DataAccessorFactory,
  getProperty,
  MeasurePath,
} from '../data-accessor-factory';
import {
  basicFilterAccessor,
  MeasureTransformMap,
  noFilterAccessor,
} from '../measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class TransactionDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<ITransactionDimension>
{
  get uid(): CanBeChartedProperty {
    const propertyName = 'uid';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Uid',
        summary: 'The identifier for the transaction',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get provider(): CanDoAllProperty {
    const propertyName = 'provider';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Provider',
        summary:
          'The type of transaction that was added. Common examples are card, cash, and manual.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get providerSubType(): CanDoAllProperty {
    const propertyName = 'providerSubType';
    const keyMeasure = this.measureRef(`${propertyName}.key`);
    const valueMeasure = this.measureRef(`${propertyName}.value`);
    const nullValue = 'None';
    return new CanDoAllProperty({
      metadata: {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Provider Subtype',
        summary:
          'The subtype of the transaction provider. This is commonly used to see the subtype of a manual transaction.',
      },
      measure: {
        propertyName: keyMeasure.attributePath,
        query: this.buildQuery()
          .attributes([keyMeasure.attributePath, valueMeasure.attributePath])
          .get(),
        dataAccessor: DataAccessorFactory.property<string>(
          keyMeasure.factPropertyPath,
          nullValue
        ),
        filterAccessor: basicFilterAccessor(valueMeasure.factPropertyPath, [
          nullValue,
        ]),
      },
      groupMeasure: {
        queryAttributes: [keyMeasure.attributePath, valueMeasure.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          valueMeasure.factPropertyPath,
          nullValue
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          keyMeasure.factPropertyPath,
          nullValue
        ),
      },
    });
  }

  get reference(): CanBeChartedProperty {
    const propertyName = 'reference';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Reference',
        summary:
          'The reference for the transaction. This is commonly a mix of the transaction uid and they provider type.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get type(): CanDoAllProperty {
    const propertyName = 'type';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Type',
        summary:
          'The type of transaction that was added. Common examples are incoming and outgoing.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get status(): CanDoAllProperty {
    const measure = this.measureRef('status');
    return MeasurePropertyFactory.enum<TransactionStatus>(
      {
        id: this._pathWithPrefix('status'),
        label: 'Transaction Status',
        summary: 'The status of the transaction',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      TransactionStatus.Pending
    );
  }

  get from(): CanBeChartedProperty {
    const propertyName = 'from';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction From',
        summary: `The origin source of the transaction. This would commonly be the patient's name, however for an outgoing transaction (such as a refund), this would be the practice's name.`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get to(): CanBeChartedProperty {
    const propertyName = 'to';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction To',
        summary: `The destination source of the transaction. This would commonly be the practice's name, however for an outgoing transaction (such as a refund), this would be the patient's name.`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get amount(): CanBeChartedProperty {
    const propertyName = 'amount';
    const measure = this.measureRef(propertyName);
    const typeMeasure = this.measureRef('type');
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('amount'),
        label: 'Transaction Amount',
        summary:
          'The amount of the transaction. Incoming transactions will be positive, while outgoing transactions with be negative.',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery()
          .attributes([measure.attributePath, typeMeasure.attributePath])
          .get(),
        dataAccessor: (fact) => {
          const type = getProperty<TransactionType>(
            fact,
            typeMeasure.factPropertyPath,
            TransactionType.Incoming
          );
          const amount = getProperty<number>(fact, measure.factPropertyPath, 0);
          const adjustedAmount = getTransactionAmount({
            type,
            amount,
          });

          return adjustedAmount;
        },
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  // TODO: Figure out the best way to handle this. True income value. i.e.
  // incoming is positive, outgoing is negative. Only include completed.
  // get incomeAmount(): CanBeChartedProperty {
  //   const propertyName = 'amount';
  //   const measure = this.measureRef(propertyName);
  //   const typeMeasure = this.measureRef('type');
  //   return MeasurePropertyFactory.number(
  //     {
  //       id: this._pathWithPrefix('incomeAmount'),
  //       label: 'Transaction Amount',
  //       summary: '',
  //     },
  //     measure,
  //     this.buildQuery()
  //       .attributes([measure.attributePath, typeMeasure.attributePath])
  //       .get()
  //   );
  // }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Transaction Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get createdAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('createdAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('createdAt'),
        label: 'Transaction Created Date',
        summary:
          'Date that the transaction was created. This is useful for grouping transactions by day.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get accountCredits(): CanBeChartedProperty {
    const propertyName = 'accountCredits';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Account Credit Amount',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes(this._queryForProvider()).get(),
        dataAccessor: (fact) =>
          this._valueForProviders(
            fact,
            (provider) => provider === TransactionProvider.AccountCredit
          ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get discounts(): CanBeChartedProperty {
    const propertyName = 'discounts';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Discount Amount',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes(this._queryForProvider()).get(),
        dataAccessor: (fact) =>
          this._valueForProviders(
            fact,
            (provider) => provider === TransactionProvider.Discount
          ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get payments(): CanBeChartedProperty {
    const propertyName = 'payments';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix(propertyName),
        label: 'Transaction Payment Amount',
        summary: `This will include any transactions that aren't discounts or account credits.`,
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes(this._queryForProvider()).get(),
        dataAccessor: (fact) =>
          this._valueForProviders(fact, (provider) => {
            const exclude = [
              undefined,
              TransactionProvider.AccountCredit,
              TransactionProvider.Discount,
            ];
            return !exclude.includes(provider);
          }),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  private _queryForProvider(): string[] {
    return [
      this._pathWithPrefix('type'),
      this._pathWithPrefix('amount'),
      this._pathWithPrefix('provider'),
    ];
  }

  private _valueForProviders(
    fact: unknown,
    providerFilterFn: (provider: TransactionProvider | undefined) => boolean
  ): number {
    const typeMeasure = this.measureRef('type');
    const providerMeasure = this.measureRef('provider');
    const amountMeasure = this.measureRef('amount');
    const type = getProperty<TransactionType | undefined>(
      fact,
      typeMeasure.factPropertyPath,
      undefined
    );
    const provider = getProperty<TransactionProvider | undefined>(
      fact,
      providerMeasure.factPropertyPath,
      undefined
    );
    const amount = getProperty<number | undefined>(
      fact,
      amountMeasure.factPropertyPath,
      undefined
    );
    const includeProvider = providerFilterFn(provider);
    if (!type || !isNumber(amount) || !includeProvider) {
      return 0;
    }
    const isNegative = type === TransactionType.Outgoing;
    return isNegative ? -amount : amount;
  }
}
