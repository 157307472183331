import type * as moment from 'moment-timezone';
import { isObject } from '../common';
import { toMoment, toTimestamp } from '../time/time';
import { type Timestamp } from './firestore/adaptor';
import { type ITimestamps } from './interfaces';

export function isTimestamp(timestamp: unknown): timestamp is Timestamp {
  return (
    isObject(timestamp) &&
    'seconds' in timestamp &&
    'nanoseconds' in timestamp &&
    'toDate' in timestamp &&
    typeof timestamp.toDate === 'function'
  );
}

/**
 * Sort timestamps in descending order. From the future to the past.
 */
export function sortTimestamp(a?: Timestamp, b?: Timestamp): number {
  if (!a || !b) {
    return 0;
  }
  return sortMoment(toMoment(a), toMoment(b));
}

/**
 * Sort moments in descending order. From the future to the past.
 */
export function sortMoment(a?: moment.Moment, b?: moment.Moment): number {
  if (!a || !b) {
    return 0;
  }
  if (a.isAfter(b)) {
    return -1;
  }
  return a.isBefore(b) ? 1 : 0;
}

export function sortByCreatedAt<T extends { createdAt: Timestamp }>(
  a: T,
  b: T
): number {
  return sortTimestamp(a.createdAt, b.createdAt);
}

export function sortByUpdatedAt<T extends { updatedAt: Timestamp }>(
  a: T,
  b: T
): number {
  return sortTimestamp(a.updatedAt, b.updatedAt);
}

export function initTimestamps(): ITimestamps {
  return {
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
  };
}
