import { IBaseMigrationStaffer } from '../../../interfaces';
import { StaffToUserMappingHandler } from '../../../mappings/staff-to-user';
import { ID4WStaffer, StafferSourceEntity } from '../../source/entities/staff';

export class D4WStaffToUserMappingHandler extends StaffToUserMappingHandler<
  ID4WStaffer,
  StafferSourceEntity
> {
  override sourceEntity = new StafferSourceEntity();
  translateFn = (record: ID4WStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: `${record.first_name} ${record.surname}`,
    providerNo: record.provider_no,
  });
}
