import { type IPracticeMigration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef, toTimestamp } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import { getBucketStoragePath, CopyFiles } from '../../../storage';
import { PatientSourceEntity } from '../../source/entities/patients';
import {
  type IPatientFileData,
  PatientDocumentDestinationEntity,
} from './patient-documents';
import { PatientDestinationEntity } from './patients';

const imageAllowedTypes = ['jpeg', 'jpg', 'png'];

export const PATIENT_XRAYS_RESOURCE_TYPE = 'patientXrays';

export const PATIENT_XRAYS_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_XRAYS_RESOURCE_TYPE,
    label: 'Patient X-Rays',
    description: 'Patient X-Rays',
  },
});

export class PatientXrayDestinationEntity extends PatientDocumentDestinationEntity {
  override destinationEntity = PATIENT_XRAYS_DESTINATION_ENTITY;
  override sourceCountComparison = new PatientSourceEntity();

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  override async getPatientFiles(
    migration: WithRef<IPracticeMigration>,
    patientId: string
  ): Promise<IPatientFileData[]> {
    const splitId = patientId.split('');
    while (splitId.length < 6) {
      splitId.unshift('0');
    }
    const patientFolderPath = splitId.join('');

    const projectId = migration.configuration.projectId;
    const bucketName = getBucketStoragePath(migration.source);
    const bucketPath = `images/${patientFolderPath}`;

    const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
      .storage;

    const [files] = await storage.bucket(bucketName).getFiles({
      prefix: bucketPath,
    });

    return files
      .filter((file) => {
        const extension = file.name.split('.').pop();
        return extension
          ? imageAllowedTypes.includes(extension.toLowerCase())
          : false;
      })
      .map((file) => ({
        name: file.name.split('/').pop() ?? file.name,
        filePath: file.name,
        createdAt: toTimestamp(),
      }));
  }
}
