<svg:g [ngClass]="{ disabled: disabled$ | async }">
  <svg:path class="background" [attr.d]="path$ | async" />

  <ng-container *ngIf="view$ | async as view">
    <svg:g
      *ngIf="selector$ | async as selector"
      class="no-events"
      prSvgIcon
      [icon]="view.texture?.icon.slug"
      [width]="selector.width"
      [height]="selector.height"
      [ngStyle]="{ color: 'white' }"
      [prSVGTransform]="selector"
    />

    <ng-container *ngIf="badge$ | async as badge">
      <svg:g
        prChartedItemsBadge
        [radius]="22"
        *ngIf="badge > 1"
        [prSVGTransform]="indicator$ | async"
        [count]="badge"
      />
    </ng-container>
  </ng-container>
</svg:g>
