import { Editor } from '@tiptap/core';
import { ISelectMenuData } from '../../menu-bar/select-menu/select-menu-view';
import { BlockNodes } from '@principle-theorem/editor';
import {
  IImageEditMenuData,
  ImageEditMenuComponent,
} from './image-edit-menu/image-edit-menu.component';

export function createImageMenu(
  editor: Editor
): ISelectMenuData<ImageEditMenuComponent, IImageEditMenuData> {
  return {
    repositionDialogOnSelectionChange: true,
    shouldShow: (props) => props.editor.isActive(BlockNodes.Image),
    pluginKey: `${BlockNodes.Image}-select-menu`,
    nodeType: BlockNodes.Image,
    component: ImageEditMenuComponent,
    data: {
      editor,
    },
  };
}
