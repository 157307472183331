import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';
import { type ID4WPatientFileCategory } from '../../source/entities/patient-file-category';

export interface IFileCategoryXSLX {
  folder: string;
  mapTo: string;
}

export const FILE_CATEGORY_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'folder',
    header: 'Folder',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To Tag',
    width: 30,
  },
];

export class FileCategoriesToXSLX
  implements IXSLXExport<ID4WPatientFileCategory, IFileCategoryXSLX>
{
  headers = FILE_CATEGORY_HEADERS;

  constructor(private _mediaTags: INamedDocument<ITag>[]) {}

  translate(
    records: ID4WPatientFileCategory[]
  ): Record<keyof IFileCategoryXSLX, XSLXCell>[] {
    const options = sortBy(this._mediaTags, 'name').map(
      (tag) => `tag(${tag.ref.id}) - ${tag.name}`
    );
    return records.map((record) => ({
      folder: {
        value: record.name,
      },
      mapTo: {
        value: undefined,
        dataValidation: {
          type: 'list',
          formulae: [`"${options.join(',')}"`],
        },
      },
    }));
  }
}
