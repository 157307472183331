<ng-container *ngIf="compact; else detail">
  <span class="absolute right-0 top-0" *ngIf="loader.isRunning$ | async">
    <mat-spinner color="primary" diameter="14" strokeWidth="2" />
  </span>
  <button
    class="transition-colors"
    [ngClass]="{
      'loading': loader.isRunning$ | async,
    }"
    mat-button
    [matTooltip]="
      tooltip + (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
    "
    matTooltipShowDelay="300"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon [ngClass]="{ '!font-bold	': loader.isRunning$ | async }">{{
      icon
    }}</mat-icon>
  </button>
</ng-container>

<ng-template #detail>
  <button
    [ngClass]="{
      loading: loader.isRunning$ | async
    }"
    mat-menu-item
    class="flex"
    matTooltipShowDelay="300"
    matTooltipPosition="after"
    [matTooltip]="
      tooltip + (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
    "
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>{{ icon }}</mat-icon>
    <span class="min-w-0 truncate">{{ buttonText }}</span>
  </button>
</ng-template>

<mat-menu #menu="matMenu" class="submenu-panel">
  <pt-submenu-bar
    [menuItems]="submenuItems$ | async"
    [editor]="editor$ | async"
  />
</mat-menu>
