import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import {
  IBridgeDevice,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  EzdentToDeviceCommand,
  EzdentTriggerImageCaptureCommand,
} from '@principle-theorem/principle-bridge-core';

@Injectable()
export class EzdentFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async triggerImageCapture(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: EzdentTriggerImageCaptureCommand = {
      type: EzdentToDeviceCommand.TriggerImageCapture,
      data: {
        patient,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
