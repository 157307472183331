import * as moment from 'moment-timezone';
import {
  Component,
  Input,
  ContentChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { type Moment, duration } from 'moment-timezone';
import { EventableHeaderTitleDirective } from './eventable-header-title.directive';
import { ReplaySubject, type Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { durationToHumanisedTime } from '@principle-theorem/shared';
import { EventableNextStageComponent } from '../eventable-next-stage/eventable-next-stage.component';

@Component({
    selector: 'pr-eventable-header-template',
    templateUrl: './eventable-header-template.component.html',
    styleUrls: ['./eventable-header-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventableHeaderTemplateComponent {
  start$: ReplaySubject<Moment> = new ReplaySubject(1);
  end$: ReplaySubject<Moment> = new ReplaySubject(1);
  duration$: Observable<string>;
  dateFormat = 'h:mm a';
  @Input() title = '';
  @ContentChild(EventableHeaderTitleDirective)
  titleDirective?: EventableHeaderTitleDirective;
  @ContentChild(EventableNextStageComponent)
  nextStage?: EventableNextStageComponent;

  constructor() {
    this.duration$ = combineLatest([this.start$, this.end$]).pipe(
      map(([start, end]) => durationToHumanisedTime(duration(end.diff(start))))
    );
  }

  @Input()
  set start(start: Moment) {
    if (start) {
      this.start$.next(moment(start));
    }
  }

  @Input()
  set end(end: Moment) {
    if (end) {
      this.end$.next(moment(end));
    }
  }
}
