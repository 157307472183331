import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IAccountSummary } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-account-invoice-summary',
  templateUrl: './account-invoice-summary.component.html',
  styleUrls: ['./account-invoice-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInvoiceSummaryComponent {
  @Input() summary: IAccountSummary;
}
