@if (groupedPendingGaps$ | async; as groups) {
  @if (groups.length) {
    <strong *ngIf="gapStore.pendingGaps$ | async as pendingAmount">
      {{ pendingAmount.length }} Pending Gaps
    </strong>

    @for (group of groups; track group.day) {
      <pr-gap-day-header [gapDayPair]="group" />
      <div class="flex flex-col gap-1.5">
        @for (gap of group.gaps; track gap.uid) {
          <pr-gap-item [gap]="gap" />
          @for (candidate of gap.metadata.candidates; track candidate.ref.id) {
            <pr-gap-shortlist-item [gap]="gap" [gapCandidate]="candidate" />
          }
        }
      </div>
    }
  } @else {
    <pr-empty-state image="list" title="pending gaps" />
  }
}
