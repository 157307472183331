import { type Permission } from './permission';

export class PermissionDisplay {
  constructor(public name: string, public value: Permission) {}

  isChecked(selected: Permission[]): boolean {
    return selected.includes(this.value);
  }
}

export class PermissionGroup {
  constructor(public name: string, public children: PermissionDisplay[] = []) {}

  isChecked(selected: Permission[]): boolean {
    return this.children.every((permission: PermissionDisplay) =>
      permission.isChecked(selected)
    );
  }

  isIndeterminate(selected: Permission[]): boolean {
    if (this.isChecked(selected)) {
      return false;
    }
    return this.children.some((permission: PermissionDisplay) =>
      permission.isChecked(selected)
    );
  }
}
