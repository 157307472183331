import { Component, Input } from '@angular/core';
import { type IAccountDetails } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-invoice-account-details',
  templateUrl: './invoice-account-details.component.html',
  styleUrls: ['./invoice-account-details.component.scss'],
})
export class InvoiceAccountDetailsComponent {
  @Input() account: IAccountDetails;
}
