import {
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateModule,
} from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { sanitise } from '@principle-theorem/ng-appointment/store';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import {
  NgIntercomComponentsModule,
  NgIntercomModule,
} from '@principle-theorem/ng-intercom';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  NG_MEDIPASS_CONFIG_PROVIDER,
  NgMedipassModule,
} from '@principle-theorem/ng-medipass';
import { NgNotificationsModule } from '@principle-theorem/ng-notifications';
import { NgPaymentsModule } from '@principle-theorem/ng-payments';
import {
  MedipassOrgConfigProvider,
  NgPrincipleModule,
  TyroOrgConfigProvider,
} from '@principle-theorem/ng-principle';
import {
  BasicRouterStateSerializer,
  CurrentScopeEffects,
  CurrentScopeFacade,
  MAPS_CONFIG,
  NgPrincipleSharedModule,
  OrganisationService,
  PrincipleLoggerMetadataResolverService,
  principleCoreMetaReducers,
  principleCoreReducers,
} from '@principle-theorem/ng-principle-shared';
import {
  AppVersionModule,
  FIREBASE_PROJECT_ID_PROVIDER,
  GCloudErrorHandlerService,
  LOGGER_METADATA_RESOLVER,
  NgSharedModule,
  ORGANISATION_SERVICE,
} from '@principle-theorem/ng-shared';
import { NgSlackIntegrationModule } from '@principle-theorem/ng-slack-integration';
import { NgStripeModule } from '@principle-theorem/ng-stripe';
import { NG_TYRO_CONFIG_PROVIDER } from '@principle-theorem/ng-tyro';
import { NOTIFICATION_PROVIDERS } from '@principle-theorem/principle-core';
import { ALL_FEATURES } from '@principle-theorem/principle-core/features';
import {
  FUNCTIONS_REGION,
  firebaseFunctionUrl,
} from '@principle-theorem/shared';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { HotkeyModule } from 'angular2-hotkeys';
import {
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2Module,
} from 'angulartics2';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, PRINCIPLE_DI_CONFIG } from './app.config';
import { ExternalLinkModule } from './external-link/external-link.module';
import { LayoutModule } from './layout/layout.module';
import { SchedulingAlertsModule } from './scheduling-alerts/scheduling-alerts.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    NgFirebaseModule,
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebase,
      undefined,
      environment.ngxAuthFirebaseUI
    ),
    NgIntercomModule.forRoot(environment.intercom),
    IntercomModule.forRoot({
      appId: environment.intercom.appId,
      updateOnRouterChange: true,
    }),
    NgIntercomComponentsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgPrincipleModule.forRoot({
      production: environment.production,
      tyro: environment.tyro,
      medipass: environment.medipass,
    }),
    NgSlackIntegrationModule.forRoot({
      projectId: environment.firebase.projectId,
      slack: {
        clientId: environment.slack.clientId,
      },
    }),
    GoogleMapsModule,
    LayoutModule,
    SchedulingAlertsModule,
    NgFeatureFlagsModule.forRoot({
      production: environment.production,
      features: ALL_FEATURES,
    }),
    ExternalLinkModule,
    StoreModule.forRoot(principleCoreReducers, {
      ...principleCoreMetaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([CurrentScopeEffects]),
    Angulartics2Module.forRoot(environment.analytics),
    NgMedipassModule,
    StoreRouterConnectingModule.forRoot({
      serializer: BasicRouterStateSerializer,
      routerState: RouterState.Minimal,
    }),
    NgEditorModule.forRoot(),
    NgNotificationsModule.forRoot({
      providers: NOTIFICATION_PROVIDERS,
    }),
    NgStripeModule.forRoot(environment.stripe),
    NgSharedModule.forRoot({
      stackdriver: environment.stackdriver,
      appUrl: environment.appUrl,
      production: environment.production,
    }),
    NgPrincipleSharedModule.forRoot({
      projectId: environment.firebase.projectId,
      podium: {
        clientId: environment.podium.clientId,
      },
      typesense: environment.typesense,
    }),
    AppVersionModule.forRoot(environment.version),
    NgMaterialModule,
    NativeDateModule,
    MatNativeDateModule,
    NgPaymentsModule.forRoot({
      medipass: {
        webhookEndpoint: firebaseFunctionUrl(
          FUNCTIONS_REGION,
          environment.firebase.projectId,
          'http-medipass-webhookHandler'
        ),
      },
      hicapsConnect: {
        PmsName: environment.hicapsConnect.PmsName,
        PmsKey: environment.hicapsConnect.PmsKey,
      },
    }),
    environment.includeDevTools
      ? StoreDevtoolsModule.instrument({
          name: 'Principle NgRx DevTools',
          maxAge: 20,
          logOnly: environment.storeDevtoolsLogOnlyMode,
          actionSanitizer: sanitise,
          connectInZone: true,
        })
      : [],
    HotkeyModule.forRoot(),
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    {
      provide: LOGGER_METADATA_RESOLVER,
      useClass: PrincipleLoggerMetadataResolverService,
    },
    { provide: ErrorHandler, useClass: GCloudErrorHandlerService },
    Title,
    { provide: APP_CONFIG, useValue: PRINCIPLE_DI_CONFIG },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: PRINCIPLE_DI_CONFIG.snackbarConfig,
    },
    CurrentScopeFacade,
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulator ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulator ? ['localhost', 5001] : undefined,
    },
    {
      provide: NG_MEDIPASS_CONFIG_PROVIDER,
      useClass: MedipassOrgConfigProvider,
    },
    {
      provide: NG_TYRO_CONFIG_PROVIDER,
      useClass: TyroOrgConfigProvider,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '$' },
    {
      provide: FIREBASE_PROJECT_ID_PROVIDER,
      useValue: environment.firebase.projectId,
    },
    { provide: MAPS_CONFIG, useValue: environment.maps },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: () => principleCoreMetaReducers,
    },
    { provide: ORGANISATION_SERVICE, useExisting: OrganisationService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'auto',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
      } as MatTabsConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(angulartics: Angulartics2GoogleGlobalSiteTag) {
    angulartics.startTracking();
  }
}
