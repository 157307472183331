import { MixedSchema } from '@principle-theorem/editor';
import {
  Brand,
  ContextResolverCollection,
  EmptyContextProvider,
  Patient,
  TemplateDefinition,
  compileTemplateSchema,
} from '@principle-theorem/principle-core';
import {
  IPractice,
  MentionResourceType,
  TemplateScope,
  type IBrand,
  type IPatient,
  type IPrincipleMention,
  type ITemplateContext,
  type ITemplateContextOption,
  type ITemplateDefinition,
  type ITemplateScopeData,
  type ITemplatesWithContext,
  type TemplateType,
} from '@principle-theorem/principle-core/interfaces';
import {
  getCount,
  query$,
  snapshot,
  undeletedQuery,
  where,
  type DocumentReference,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { AppointmentMentionContextProvider } from './appointment-mention-context-resolver';
import { GapMentionContextProvider } from './gap-mention-context-resolver';
import { InvoiceMentionContextProvider } from './invoice-mention-context-resolver';
import { PatientMentionContextProvider } from './patient-mention-context-resolver';

export interface IPopulatedTemplate {
  name: string;
  content: MixedSchema;
}

export async function populateTemplate(
  template: ITemplateDefinition,
  context: ITemplateContext,
  scopeData: ITemplateScopeData
): Promise<IPopulatedTemplate> {
  const rawTemplate = await TemplateDefinition.resolveRawTemplate(
    template,
    scopeData
  );
  return {
    name: template.name,
    content: compileTemplateSchema(rawTemplate.content, context),
  };
}

export class MentionToTemplates {
  constructor(
    private _practiceRef: DocumentReference<IPractice>,
    private _appUrl: string
  ) {}

  async getTemplates(
    mention: IPrincipleMention,
    brandRef: DocumentReference<IBrand>,
    type?: TemplateType
  ): Promise<ITemplatesWithContext | undefined> {
    const scopes = await this._mentionToTemplateScopes(mention);
    if (!scopes.length) {
      return;
    }
    const contextOptions = await this._scopeToContextOptions(scopes, mention);
    const templates = await this._findTemplates(scopes, brandRef, type);
    return { templates, contextOptions };
  }

  private async _mentionToTemplateScopes(
    mention: IPrincipleMention
  ): Promise<TemplateScope[]> {
    switch (mention.resource.type) {
      case MentionResourceType.Patient:
        return this._getPatientScopes(
          mention.resource.ref as DocumentReference<IPatient>
        );
      case MentionResourceType.Appointment:
        return [
          TemplateScope.Patient,
          TemplateScope.Appointment,
          TemplateScope.Invoice,
          TemplateScope.None,
        ];
      default:
        return [TemplateScope.None];
    }
  }

  private async _findTemplates(
    scopes: TemplateScope[],
    brandRef: DocumentReference<IBrand>,
    type?: TemplateType
  ): Promise<ITemplateDefinition[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.templateCol({ ref: brandRef })),
        ...compact([
          where('scope', 'in', scopes),
          where('ownerScope', '==', brandRef),
          type ? where('type', '==', type) : undefined,
        ])
      )
    );
  }

  private async _scopeToContextOptions(
    scopes: TemplateScope[],
    mention: IPrincipleMention
  ): Promise<ITemplateContextOption[]> {
    return new ContextResolverCollection([
      new AppointmentMentionContextProvider(mention, this._appUrl),
      new PatientMentionContextProvider(
        mention,
        this._practiceRef,
        this._appUrl
      ),
      new InvoiceMentionContextProvider(mention, this._appUrl),
      new GapMentionContextProvider(mention, this._appUrl),
      new EmptyContextProvider(),
    ]).resolve(scopes);
  }

  private async _getPatientScopes(
    patientRef: DocumentReference<IPatient>
  ): Promise<TemplateScope[]> {
    const appointmentCount = await getCount(
      undeletedQuery(Patient.appointmentCol({ ref: patientRef }))
    );
    const invoiceCount = await getCount(
      undeletedQuery(Patient.invoiceCol({ ref: patientRef }))
    );

    return compact([
      TemplateScope.None,
      TemplateScope.Patient,
      appointmentCount && TemplateScope.Appointment,
      invoiceCount && TemplateScope.Invoice,
    ]);
  }
}
