import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  ObservableDataTable,
  TrackByFunctions,
  extendSortingDataAccessor,
} from '@principle-theorem/ng-shared';
import { Brand, Contact, toMention } from '@principle-theorem/principle-core';
import {
  MentionResourceType,
  isContact,
  isLab,
  type IBrand,
  type IContact,
  type IContactDetails,
  type ILab,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  ContactFormDialogComponent,
  type IContactFormData,
} from '../../components/contact-form-dialog/contact-form-dialog.component';

@Component({
    selector: 'pr-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _contactCol$: Observable<WithRef<IContact>[]>;
  private _labCol$: Observable<WithRef<ILab>[]>;
  trackByContact = TrackByFunctions.ref<WithRef<IContact>>();
  dataTable: ObservableDataTable<WithRef<IContact> | WithRef<ILab>>;
  emptyState$: Observable<boolean>;
  emptySearch$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) {
    this.dataTable.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataTable.dataSource.sort = sort;
  }

  constructor(
    private _brandScope: CurrentBrandScope,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogService: InteractionDialogsService
  ) {
    this._contactCol$ = this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand: WithRef<IBrand>) => Brand.contacts$(brand))
    );

    this._labCol$ = this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand: WithRef<IBrand>) => Brand.labs$(brand))
    );

    const data$: Observable<(WithRef<IContact> | WithRef<ILab>)[]> =
      combineLatest([this._contactCol$, this._labCol$]).pipe(
        map(([contacts, labs]) => [...contacts, ...labs]),
        takeUntil(this._onDestroy$)
      );

    this.dataTable = new ObservableDataTable(data$);
    this.dataTable.displayColumns = ['name', 'address', 'phone', 'email'];
    this.dataTable.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );

    this.emptyState$ = combineLatest([
      data$,
      this.dataTable.dataSource.loading$,
    ]).pipe(map(([contacts, loading]) => !contacts.length && !loading));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.dataTable.ngOnDestroy();
  }

  async searchContacts(value: string): Promise<void> {
    this.dataTable.applyFilter(value);
    const emptyState = await snapshot(this.emptyState$);
    !emptyState && !this.dataTable.dataSource.filteredData.length
      ? this.emptySearch$.next(true)
      : this.emptySearch$.next(false);
  }

  async addContact(): Promise<void> {
    const data: IContactFormData = {
      title: 'Create Contact',
      submitLabel: 'Create',
    };
    const config: MatDialogConfig = DialogPresets.medium({
      data,
      autoFocus: true,
    });
    const contactData = await this._dialog
      .open<
        ContactFormDialogComponent,
        IContactFormData,
        Partial<IContactDetails>
      >(ContactFormDialogComponent, config)
      .afterClosed()
      .toPromise();

    if (!contactData) {
      return;
    }
    const contact: IContact = Contact.init(contactData);
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    await addDoc(Brand.contactCol(brand), contact);
    this._snackBar.open('Contact Created');
  }

  isContact(contact: WithRef<IContact> | WithRef<ILab>): boolean {
    return !isLab(contact);
  }

  openEmail(contact: WithRef<IContact> | WithRef<ILab>): void {
    this.setDialogData(contact);
    this.dialogService.openEmail();
  }

  openPhone(contact: WithRef<IContact> | WithRef<ILab>): void {
    this.setDialogData(contact);
    this.dialogService.openPhone();
  }

  setDialogData(contact: WithRef<IContact> | WithRef<ILab>): void {
    const type = isContact(contact)
      ? MentionResourceType.Contact
      : MentionResourceType.Lab;

    this.dialogService.dialogData = {
      contact: toMention(contact, type),
    };
  }

  private _sortingDataAccessor(
    data: WithRef<IContact> | WithRef<ILab>,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'name':
        return data.name.toLowerCase();
      case 'address':
        return data.address?.toLowerCase();
      case 'phone':
        return data.phone;
      case 'email':
        return data.email?.toLowerCase();
      default:
        return;
    }
  }
}
