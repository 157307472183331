@if (status === treatmentStatus.Complete) {
  <pr-icon-chip
    icon="check_circle"
    iconClass="text-green-500"
    [matTooltip]="displayTooltip ? 'Treatment has been completed' : ''"
    matTooltipShowDelay="300"
  >
    {{ treatmentStatus.Complete | titlecase }}
  </pr-icon-chip>
}

@if (status === treatmentStatus.Flagged) {
  <pr-icon-chip
    icon="flag_2"
    iconClass="text-warn-500"
    [matTooltip]="
      displayTooltip
        ? 'Treatment flagged but not yet added to a treatment plan'
        : ''
    "
    matTooltipShowDelay="300"
  >
    {{ treatmentStatus.Flagged | titlecase }}
  </pr-icon-chip>
}

@if (status === treatmentStatus.Planned) {
  <pr-icon-chip
    icon="edit_note"
    iconClass="text-amber-500"
    [matTooltip]="
      displayTooltip
        ? 'Treatment is in a treatment plan but not yet completed'
        : ''
    "
    matTooltipShowDelay="300"
  >
    {{ treatmentStatus.Planned | titlecase }}
  </pr-icon-chip>
}
