import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import { Interaction } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  IInteractionV2,
  ITranslationMap,
  InteractionType,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { toTimestamp, type WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientInteractionDestinationEntity,
  IBasePatientInteractionJobData,
  IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientInteractionSourceEntity } from '../../source/entities/patient-interactions';
import {
  ICorePracticePatient,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';

interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<ICorePracticePatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientInteractionDestinationEntity extends BasePatientInteractionDestinationEntity<
  ICorePracticePatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientInteraction: new PatientInteractionSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientInteractionJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(staff$),
        map(([sourcePatients, staff]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const sourceInteractions =
      await this.sourceEntities.patientInteraction.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const interactions: (IInteractionV2 & IHasSourceIdentifier)[] =
      sourceInteractions.map((interaction) => ({
        sourceIdentifier: interaction.record.uid,
        ...Interaction.init({
          type: InteractionType.Note,
          title: interaction.data.data.note
            ? [toTextContent(interaction.data.data.note)]
            : [],
          createdAt: toTimestamp(moment.utc(interaction.data.data.createDate)),
          content: initVersionedSchema(interaction.data.data.note || undefined),
        }),
      }));

    return {
      patientRef,
      interactions,
    };
  }
}
