import {
  ISO_DATE_FORMAT,
  ISO_TIME_FORMAT,
  TypeGuard,
  TypeGuardFn,
  isObject,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { HicapsConnect, HicapsConnectExtended } from './interfaces';

export interface IPMSHicapsConnectConfig {
  PmsName: string;
  PmsKey: string;
}

type DataBaseType = object | string | boolean | undefined;

export enum HicapsConnectMethod {
  // Principle Hicaps Log Management
  PrincipleHicapsLogs_Read = 'principleHicapsLogsRead',
  PrincipleHicapsLogs_Delete = 'principleHicapsLogsDelete',
  PrincipleHicapsLogs_ReadAndDelete = 'principleHicapsLogsReadAndDelete',
  // Hicaps Api Methods
  GetVersion = 'getVersion',
  GetDefaultServer = 'getDefaultServer',
  GetDefaultServerConfig = 'getDefaultServerConfig',
  ClearTerminalList = 'ClearTerminalList',
  GetStandAloneMode = 'getStandAloneMode',
  GetTerminalList = 'getTerminalList',
  GetTerminalListById = 'getTerminalListById',
  DisplayStatusWindow = 'DisplayStatusWindow',
  SendAllItemList = 'sendAllItemList',
  SendAllItemResponseCodeList = 'sendAllItemResponseCodeList',
  SendAllMerchantList = 'sendAllMerchantList',
  SendAllPharmItemList = 'sendAllPharmItemList',
  SendAllProviderList = 'sendAllProviderList',
  SendAllTransCodeListResponse = 'sendAllTransCodeListResponse',
  SendCardList = 'sendCardList',
  SendCardRead = 'sendCardRead',
  SendCashout = 'sendCashout',
  SendClaimCancelRequest = 'sendClaimCancelRequest',
  SendClaimPharmRequest = 'sendClaimPharmRequest',
  SendClaimRequest = 'sendClaimRequest',
  SendEftposDeposit = 'sendEftposDeposit',
  SendEftposTransListing = 'sendEftposTransListing',
  SendGetTerminals = 'sendGetTerminals',
  SendHicapsTotals = 'sendHicapsTotals',
  SendHicapsTransListing = 'sendHicapsTransListing',
  SendMedicareClaimRequest = 'sendMedicareClaimRequest',
  SendMedicareMerchantList = 'sendMedicareMerchantList',
  SendMerchantList = 'sendMerchantList',
  SendPrintLastReceipt = 'sendPrintLastReceipt',
  SendQuotePharmRequest = 'sendQuotePharmRequest',
  SendQuoteRequest = 'sendQuoteRequest',
  SendRefund = 'sendRefund',
  SendSale = 'sendSale',
  SendSaleCashout = 'sendSaleCashout',
  SendSettlement = 'sendSettlement',
  SendTerminalTest = 'sendTerminalTest',
}

/**
 * Hicaps Type Mapping
 */

type HicapsConnectRequestMap = {
  [T in HicapsConnectMethod]: {
    [HicapsConnectMethod.PrincipleHicapsLogs_Read]: undefined;
    [HicapsConnectMethod.PrincipleHicapsLogs_Delete]: undefined;
    [HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete]: undefined;
    [HicapsConnectMethod.GetVersion]: undefined;
    [HicapsConnectMethod.GetDefaultServer]: string;
    [HicapsConnectMethod.GetDefaultServerConfig]: string;
    [HicapsConnectMethod.ClearTerminalList]: undefined;
    [HicapsConnectMethod.GetStandAloneMode]: undefined;
    [HicapsConnectMethod.GetTerminalList]: undefined;
    [HicapsConnectMethod.GetTerminalListById]: undefined;
    [HicapsConnectMethod.DisplayStatusWindow]: boolean;
    [HicapsConnectMethod.SendAllItemList]: HicapsConnect.AllItemListRequest;
    [HicapsConnectMethod.SendAllItemResponseCodeList]: HicapsConnect.AllItemResponseCodeListRequest;
    [HicapsConnectMethod.SendAllMerchantList]: HicapsConnect.AllMerchantListRequest;
    [HicapsConnectMethod.SendAllPharmItemList]: HicapsConnect.AllPharmItemListRequest;
    [HicapsConnectMethod.SendAllProviderList]: HicapsConnect.AllProviderListRequest;
    [HicapsConnectMethod.SendAllTransCodeListResponse]: HicapsConnect.AllTransCodeListRequest;
    [HicapsConnectMethod.SendCardList]: HicapsConnect.CardListRequest;
    [HicapsConnectMethod.SendCardRead]: HicapsConnect.CardReadRequest;
    [HicapsConnectMethod.SendCashout]: HicapsConnect.CashoutRequest;
    [HicapsConnectMethod.SendClaimCancelRequest]: HicapsConnect.ClaimCancelRequest;
    [HicapsConnectMethod.SendClaimPharmRequest]: HicapsConnect.ClaimPharmRequest;
    [HicapsConnectMethod.SendClaimRequest]: HicapsConnect.ClaimRequest;
    [HicapsConnectMethod.SendEftposDeposit]: HicapsConnect.EftposDepositRequest;
    [HicapsConnectMethod.SendEftposTransListing]: HicapsConnect.EftposTransListingRequest;
    [HicapsConnectMethod.SendGetTerminals]: HicapsConnect.GetTerminalRequest;
    [HicapsConnectMethod.SendHicapsTotals]: HicapsConnect.HicapsTotalsRequest;
    [HicapsConnectMethod.SendHicapsTransListing]: HicapsConnect.HicapsTransListingRequest;
    [HicapsConnectMethod.SendMedicareClaimRequest]: HicapsConnect.MedicareClaimRequest;
    [HicapsConnectMethod.SendMedicareMerchantList]: HicapsConnect.MedicareMerchantListRequest;
    [HicapsConnectMethod.SendMerchantList]: HicapsConnect.MerchantListRequest;
    [HicapsConnectMethod.SendPrintLastReceipt]: HicapsConnect.PrintLastReceiptRequest;
    [HicapsConnectMethod.SendQuotePharmRequest]: HicapsConnect.QuotePharmRequest;
    [HicapsConnectMethod.SendQuoteRequest]: HicapsConnect.QuoteRequest;
    [HicapsConnectMethod.SendRefund]: HicapsConnect.RefundRequest;
    [HicapsConnectMethod.SendSale]: HicapsConnect.SaleRequest;
    [HicapsConnectMethod.SendSaleCashout]: HicapsConnect.SaleCashoutRequest;
    [HicapsConnectMethod.SendSettlement]: HicapsConnect.SettlementRequest;
    [HicapsConnectMethod.SendTerminalTest]: string;
  }[T];
};

type HicapsConnectResponseMap = {
  [T in HicapsConnectMethod]: {
    [HicapsConnectMethod.PrincipleHicapsLogs_Read]: string;
    [HicapsConnectMethod.PrincipleHicapsLogs_Delete]: Record<string, never>;
    [HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete]: string;
    [HicapsConnectMethod.GetVersion]: string;
    [HicapsConnectMethod.GetDefaultServer]: string;
    [HicapsConnectMethod.GetDefaultServerConfig]: string;
    [HicapsConnectMethod.ClearTerminalList]: Record<string, never>;
    [HicapsConnectMethod.GetStandAloneMode]: undefined;
    [HicapsConnectMethod.GetTerminalList]: string[];
    [HicapsConnectMethod.GetTerminalListById]: string[];
    [HicapsConnectMethod.DisplayStatusWindow]: Record<string, never>;
    [HicapsConnectMethod.SendAllItemList]: HicapsConnect.AllItemListResponse;
    [HicapsConnectMethod.SendAllItemResponseCodeList]: HicapsConnect.AllItemResponseCodeListResponse;
    [HicapsConnectMethod.SendAllMerchantList]: HicapsConnect.AllMerchantListResponse;
    [HicapsConnectMethod.SendAllPharmItemList]: HicapsConnect.AllPharmItemListResponse;
    [HicapsConnectMethod.SendAllProviderList]: HicapsConnect.AllProviderListResponse;
    [HicapsConnectMethod.SendAllTransCodeListResponse]: HicapsConnect.AllTransCodeListResponse;
    [HicapsConnectMethod.SendCardList]: HicapsConnect.CardListResponse;
    [HicapsConnectMethod.SendCardRead]: HicapsConnect.CardReadResponse;
    [HicapsConnectMethod.SendCashout]: HicapsConnect.CashoutResponse;
    [HicapsConnectMethod.SendClaimCancelRequest]: HicapsConnect.ClaimCancelResponse;
    [HicapsConnectMethod.SendClaimPharmRequest]: HicapsConnect.ClaimPharmResponse;
    [HicapsConnectMethod.SendClaimRequest]: HicapsConnect.ClaimResponse;
    [HicapsConnectMethod.SendEftposDeposit]: HicapsConnect.EftposDepositResponse;
    [HicapsConnectMethod.SendEftposTransListing]: HicapsConnect.EftposTransListingResponse;
    [HicapsConnectMethod.SendGetTerminals]: HicapsConnect.GetTerminalResponse;
    [HicapsConnectMethod.SendHicapsTotals]: HicapsConnect.HicapsTotalsResponse;
    [HicapsConnectMethod.SendHicapsTransListing]: HicapsConnect.HicapsTransListingResponse;
    [HicapsConnectMethod.SendMedicareClaimRequest]: HicapsConnect.MedicareClaimResponse;
    [HicapsConnectMethod.SendMedicareMerchantList]: HicapsConnect.MedicareMerchantListResponse;
    [HicapsConnectMethod.SendMerchantList]: HicapsConnect.MerchantListResponse;
    [HicapsConnectMethod.SendPrintLastReceipt]: HicapsConnect.PrintLastReceiptResponse;
    [HicapsConnectMethod.SendQuotePharmRequest]: HicapsConnect.QuotePharmResponse;
    [HicapsConnectMethod.SendQuoteRequest]: HicapsConnect.QuoteResponse;
    [HicapsConnectMethod.SendRefund]: HicapsConnect.RefundResponse;
    [HicapsConnectMethod.SendSale]: HicapsConnect.SaleResponse;
    [HicapsConnectMethod.SendSaleCashout]: HicapsConnect.SaleCashoutResponse;
    [HicapsConnectMethod.SendSettlement]: HicapsConnect.SettlementResponse;
    [HicapsConnectMethod.SendTerminalTest]: HicapsConnect.TerminalTestResponse;
  }[T];
};

type HicapsConnectExtendedDataMap = {
  [T in HicapsConnectMethod]: {
    [HicapsConnectMethod.PrincipleHicapsLogs_Read]: undefined;
    [HicapsConnectMethod.PrincipleHicapsLogs_Delete]: undefined;
    [HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete]: undefined;
    [HicapsConnectMethod.GetVersion]: undefined;
    [HicapsConnectMethod.GetDefaultServer]: undefined;
    [HicapsConnectMethod.GetDefaultServerConfig]: undefined;
    [HicapsConnectMethod.ClearTerminalList]: undefined;
    [HicapsConnectMethod.GetStandAloneMode]: undefined;
    [HicapsConnectMethod.GetTerminalList]: undefined;
    [HicapsConnectMethod.GetTerminalListById]: undefined;
    [HicapsConnectMethod.DisplayStatusWindow]: undefined;
    [HicapsConnectMethod.SendAllItemList]: undefined;
    [HicapsConnectMethod.SendAllItemResponseCodeList]: undefined;
    [HicapsConnectMethod.SendAllMerchantList]: undefined;
    [HicapsConnectMethod.SendAllPharmItemList]: undefined;
    [HicapsConnectMethod.SendAllProviderList]: undefined;
    [HicapsConnectMethod.SendAllTransCodeListResponse]: undefined;
    [HicapsConnectMethod.SendCardList]: undefined;
    [HicapsConnectMethod.SendCardRead]: undefined;
    [HicapsConnectMethod.SendCashout]: undefined;
    [HicapsConnectMethod.SendClaimCancelRequest]: HicapsConnectExtended.SendClaimCancelRequest;
    [HicapsConnectMethod.SendClaimPharmRequest]: HicapsConnectExtended.SendClaimPharmRequest;
    [HicapsConnectMethod.SendClaimRequest]: HicapsConnectExtended.SendClaimRequest;
    [HicapsConnectMethod.SendEftposDeposit]: undefined;
    [HicapsConnectMethod.SendEftposTransListing]: undefined;
    [HicapsConnectMethod.SendGetTerminals]: undefined;
    [HicapsConnectMethod.SendHicapsTotals]: undefined;
    [HicapsConnectMethod.SendHicapsTransListing]: undefined;
    [HicapsConnectMethod.SendMedicareClaimRequest]: HicapsConnectExtended.SendMedicareClaimRequest;
    [HicapsConnectMethod.SendMedicareMerchantList]: undefined;
    [HicapsConnectMethod.SendMerchantList]: undefined;
    [HicapsConnectMethod.SendPrintLastReceipt]: undefined;
    [HicapsConnectMethod.SendQuotePharmRequest]: HicapsConnectExtended.SendClaimPharmRequest;
    [HicapsConnectMethod.SendQuoteRequest]: HicapsConnectExtended.SendClaimRequest;
    [HicapsConnectMethod.SendRefund]: undefined;
    [HicapsConnectMethod.SendSale]: undefined;
    [HicapsConnectMethod.SendSaleCashout]: undefined;
    [HicapsConnectMethod.SendSettlement]: undefined;
    [HicapsConnectMethod.SendTerminalTest]: undefined;
  }[T];
};

type HicapsConnectTypeMap<T extends HicapsConnectMethod> = {
  methodName: T;
  request: HicapsConnectRequestMap[T];
  extendedData: HicapsConnectExtendedDataMap[T];
  response: HicapsConnectResponseMap[T];
};

/**
 * Principle Hicaps Executable Types
 */

export enum HicapsConnectResult {
  Success = 'success',
  Error = 'error',
}

export interface IPrincipleHicapsConnectRequest<
  RequestData extends DataBaseType,
  ExtendedData,
> {
  processUid: string;
  methodName: HicapsConnectMethod;
  data: RequestData;
  extendedData: ExtendedData;
}

export type PrincipleHicapsConnectRequest<T extends HicapsConnectMethod> =
  IPrincipleHicapsConnectRequest<
    HicapsConnectTypeMap<T>['request'],
    HicapsConnectTypeMap<T>['extendedData']
  >;

interface IPrincipleHicapsConnectResponse<T extends DataBaseType> {
  processUid: string;
  timestamp: string;
  result: HicapsConnectResult;
  message: string;
  data: T;
}

function isPrincipleHicapsConnectResponse<T extends DataBaseType>(
  dataTypeGuardFn: TypeGuardFn<T>
): TypeGuardFn<IPrincipleHicapsConnectResponse<T>> {
  return TypeGuard.interface<IPrincipleHicapsConnectResponse<T>>({
    processUid: isString,
    timestamp: isString,
    result: TypeGuard.enumValue(HicapsConnectResult),
    message: isString,
    data: dataTypeGuardFn,
  });
}

export type PrincipleHicapsConnectSuccess<T extends HicapsConnectMethod> =
  IPrincipleHicapsConnectResponse<HicapsConnectTypeMap<T>['response']> & {
    result: HicapsConnectResult.Success;
  };

export type PrincipleHicapsConnectError = IPrincipleHicapsConnectResponse<
  undefined | string
> & {
  result: HicapsConnectResult.Error;
};

export type PrincipleHicapsConnectResponse<T extends HicapsConnectMethod> =
  | PrincipleHicapsConnectSuccess<T>
  | PrincipleHicapsConnectError;

/**
 * Helper Methods
 */

export class PrincipleHicapsConnect {
  static createSuccessResponse<T extends HicapsConnectMethod>(
    processUid: string,
    data: PrincipleHicapsConnectSuccess<T>['data']
  ): PrincipleHicapsConnectSuccess<T> {
    return {
      processUid,
      timestamp: moment().toISOString(),
      result: HicapsConnectResult.Success,
      message: 'Terminal Response',
      data,
    };
  }

  static createErrorResponse(
    processUid: string,
    message: string
  ): PrincipleHicapsConnectError {
    return {
      processUid,
      timestamp: moment().toISOString(),
      result: HicapsConnectResult.Error,
      message,
      data: undefined,
    };
  }

  static createRequest<T extends HicapsConnectMethod>(
    processUid: string,
    methodName: T,
    data: HicapsConnectRequestMap[T],
    extendedData: HicapsConnectExtendedDataMap[T]
  ): PrincipleHicapsConnectRequest<T> {
    return {
      processUid,
      methodName,
      data,
      extendedData,
    };
  }

  static hasSuccessResult<T extends HicapsConnectMethod>(
    request: PrincipleHicapsConnectResponse<T>
  ): request is PrincipleHicapsConnectSuccess<T> {
    return request.result === HicapsConnectResult.Success;
  }

  /**
   * Formats the given date in ISO format, without a timezone offset.
   * The timezone offset is deliberatly omitted to ensure the date is
   * interpreted as local time by the HICAPS terminal.
   */
  static toDateString(date: Moment): string {
    const format = `${ISO_DATE_FORMAT}T${ISO_TIME_FORMAT}.SSS[Z]`;
    return date.format(format);
  }

  static isReferralPeriodType(
    data: unknown
  ): data is HicapsConnect.ReferralPeriodType {
    return isString(data) && ['S', 'I'].includes(data);
  }

  static isReferralOverrideType(
    data: unknown
  ): data is HicapsConnect.ReferralOverrideType {
    return isString(data) && ['L', 'E', 'N'].includes(data);
  }

  static isSuccessResponse<T extends HicapsConnectMethod>(
    dataGuardFn?: TypeGuardFn<HicapsConnectTypeMap<T>['response']>
  ): TypeGuardFn<PrincipleHicapsConnectSuccess<T>> {
    const isResponseGuard = isPrincipleHicapsConnectResponse<
      HicapsConnectTypeMap<T>['response']
    >(dataGuardFn ?? TypeGuard.noGuard());

    return (data: unknown): data is PrincipleHicapsConnectSuccess<T> =>
      isObject(data) &&
      data.result === HicapsConnectResult.Success &&
      isResponseGuard(data);
  }

  static isErrorResponse(): TypeGuardFn<PrincipleHicapsConnectError> {
    const isResponseGuard = isPrincipleHicapsConnectResponse<
      undefined | string
    >(TypeGuard.undefinedOr(isString));

    return (data: unknown): data is PrincipleHicapsConnectError =>
      isObject(data) &&
      data.result === HicapsConnectResult.Error &&
      isResponseGuard(data);
  }

  static isResponse<T extends HicapsConnectMethod>(
    dataGuardFn?: TypeGuardFn<HicapsConnectTypeMap<T>['response']>
  ): TypeGuardFn<PrincipleHicapsConnectResponse<T>> {
    const isSuccess = this.isSuccessResponse(dataGuardFn);
    const isError = this.isErrorResponse();

    return (data: unknown): data is PrincipleHicapsConnectError =>
      isSuccess(data) || isError(data);
  }
}

export enum HicapsConnectReceiptType {
  Provider = 'provider',
  Customer = 'customer',
}
