<pt-feature-info />
<pt-breadcrumbs current="Appointment Gaps" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<ng-container *ngIf="gaps$ | async as gaps; else loader">
  <div class="wrapper">
    <pr-gap-list *ngIf="gaps.length; else noGaps" [gaps]="gaps" />
    <ng-template #noGaps>
      <pr-empty-state image="list" title="appointment gaps" />
    </ng-template>
  </div>
</ng-container>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate" />
</ng-template>
