import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToTreatmentMappingHandler } from '../../../mappings/base-item-code-to-treatment';
import {
  ADAItemSourceEntity,
  ID4WADAItem,
} from '../../source/entities/ada-item';

export class D4WItemCodeToTreatmentMappingHandler extends BaseItemCodeToTreatmentMappingHandler<
  ID4WADAItem,
  ADAItemSourceEntity
> {
  sourceEntity = new ADAItemSourceEntity();
  translateFn = (record: ID4WADAItem): IBaseMigrationItemCode => ({
    id: record.id,
    itemCode: record.itemCode,
    description: record.description ?? '',
  });
}
