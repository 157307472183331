import { randBoolean, randSentence } from '@ngneat/falso';
import {
  initVersionedSchema,
  VersionedSchema,
} from '@principle-theorem/editor';
import { INote } from '@principle-theorem/principle-core/interfaces';
import { BaseFirestoreMock, Properties } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class NoteMock extends BaseFirestoreMock implements Properties<INote> {
  get uid(): string {
    return uuid();
  }

  get content(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get pinned(): boolean {
    return randBoolean();
  }
}
