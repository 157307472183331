<ng-container *ngIf="suggestedTreatments$ | async as suggestedTreatments">
  <h5>Treatment Suggestions</h5>
  <mat-list class="mat-elevation-z1">
    <mat-list-item
      *ngFor="let treatment of suggestedTreatments; trackBy: trackByTreatment"
    >
      <mat-icon
        matListItemIcon
        *ngIf="treatment.display?.icon.slug"
        [ngStyle]="{ color: treatment.display.colour.value }"
        [svgIcon]="treatment.display.icon.slug"
       />
      <span matListItemTitle>{{ treatment.name }}</span>
      <pt-buttons-container>
        <button mat-icon-button (click)="addTreatment(treatment)">
          <mat-icon>add</mat-icon>
        </button>
      </pt-buttons-container>
    </mat-list-item>
  </mat-list>
</ng-container>
