import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  AutomationStatus,
  type AutomationType,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues, type SerialisedData } from '@principle-theorem/shared';
import { AutomationActions, AutomationListActions } from './actions';
import { type AutomationEntity } from '@principle-theorem/principle-core';
import { type ISerialisedEntityState } from '@principle-theorem/ng-shared';

export const AUTOMATIONS_FEATURE_KEY = 'automations';

export interface IAutomationsState
  extends ISerialisedEntityState<AutomationEntity> {
  statusFilter: AutomationStatus[];
  typeFilter?: AutomationType;
  practiceFilter?: string;
  selectedIds: string[];
}

export interface IAutomationsPartialState {
  readonly [AUTOMATIONS_FEATURE_KEY]: IAutomationsState;
}

export const automationsAdapter: EntityAdapter<
  SerialisedData<AutomationEntity>
> = createEntityAdapter({
  selectId: (automation) => automation.uid,
  sortComparer: false,
});

export const initialAutomationState: IAutomationsState =
  automationsAdapter.getInitialState({
    loaded: false,
    selectedIds: [],
    statusFilter: getEnumValues(AutomationStatus),
  });

export const automationsReducer = createReducer(
  initialAutomationState,

  on(AutomationActions.loadAutomationsSuccess, (state, { automations }) =>
    automationsAdapter.upsertMany(automations, {
      ...state,
      loaded: true,
    })
  ),

  on(AutomationActions.resetStateOnLoadSuccess, (state, { automations }) =>
    automationsAdapter.setAll(automations, {
      ...state,
      selectedId: undefined,
      selectedIds: [],
      loaded: true,
      statusFilter: getEnumValues(AutomationStatus),
    })
  ),

  on(AutomationActions.setAutomations, (state, { automations }) =>
    automationsAdapter.setAll(automations, state)
  ),

  on(AutomationActions.addAutomations, (state, { automations }) =>
    automationsAdapter.upsertMany(automations, state)
  ),

  on(AutomationActions.addAutomation, (state, { automation }) =>
    automationsAdapter.upsertOne(automation, state)
  ),

  on(AutomationActions.updateAutomation, (state, { update }) =>
    automationsAdapter.updateOne(update, state)
  ),

  on(AutomationActions.updateAutomations, (state, { updates }) =>
    automationsAdapter.updateMany(updates, state)
  ),

  on(AutomationActions.removeAutomation, (state, { id }) =>
    automationsAdapter.removeOne(id, state)
  ),

  on(AutomationActions.removeAutomations, (state, { ids }) =>
    automationsAdapter.removeMany(ids, state)
  ),

  on(AutomationActions.cancelAutomation, (state, { automation }) =>
    automationsAdapter.updateOne(
      {
        id: automation.uid,
        changes: { status: AutomationStatus.Cancelled, triggerDate: undefined },
      },
      state
    )
  ),

  on(AutomationActions.selectAutomation, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),

  on(AutomationActions.unselectAutomation, (state) => ({
    ...state,
    selectedId: undefined,
  })),

  on(AutomationActions.resetAutomationsState, (state) =>
    automationsAdapter.removeAll({
      ...state,
      selectedId: undefined,
      selectedIds: [],
      loaded: false,
      statusFilter: getEnumValues(AutomationStatus),
    })
  ),

  on(AutomationListActions.setStatusFilter, (state, { statusFilter }) => ({
    ...state,
    statusFilter,
  })),

  on(AutomationListActions.setTypeFilter, (state, { typeFilter }) => ({
    ...state,
    typeFilter,
  })),

  on(AutomationListActions.setPracticeFilter, (state, { practiceFilter }) => ({
    ...state,
    practiceFilter,
  })),

  on(AutomationListActions.selectMultipleAutomations, (state, { ids }) => ({
    ...state,
    selectedIds: ids,
  })),

  on(AutomationListActions.unselectAll, (state) => ({
    ...state,
    selectedIds: [],
  })),

  on(AutomationListActions.selectAutomation, (state, { id }) => ({
    ...state,
    selectedIds: [...state.selectedIds, id],
  })),

  on(AutomationListActions.unselectAutomation, (state, { id }) => ({
    ...state,
    selectedIds: state.selectedIds.filter((selectedId) => selectedId !== id),
  })),

  on(AutomationListActions.cancelSelectedConfirmed, (state, { ids }) => {
    const updates = ids.map((id) => ({
      id,
      changes: { status: AutomationStatus.Cancelled },
    }));
    return automationsAdapter.updateMany(updates, state);
  })
);

export function reducer(
  state: IAutomationsState,
  action: Action
): IAutomationsState {
  return automationsReducer(state, action);
}
