import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { IPractitionerProportionInvoiceAmount } from '@principle-theorem/principle-core';
import {
  IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';

export interface ITransactionAttributeToDialogResponse {
  attributedTo?: DocumentReference<IStaffer>;
}

export interface ITransactionAttributeToDialogRequest {
  transaction: WithRef<ITransaction>;
  invoicePractitioners: IPractitionerProportionInvoiceAmount[];
}

@Component({
    selector: 'pr-transaction-attribute-to-dialog',
    templateUrl: './transaction-attribute-to-dialog.component.html',
    styleUrls: ['./transaction-attribute-to-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionAttributeToDialogComponent {
  form = new TypedFormGroup<ITransactionAttributeToDialogResponse>({
    attributedTo: new TypedFormControl<DocumentReference<IStaffer> | undefined>(
      undefined
    ),
  });
  staff: INamedDocument<IStaffer>[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITransactionAttributeToDialogRequest,
    private _dialogRef: MatDialogRef<
      TransactionAttributeToDialogComponent,
      ITransactionAttributeToDialogResponse
    >
  ) {
    this.form.patchValue(data.transaction, { emitEvent: false });
    this.staff = data.invoicePractitioners.map(
      (proportion) => proportion.practitioner
    );
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this._dialogRef.close(this.form.value);
  }
}
