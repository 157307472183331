<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">General Settings</h1>

  <form
    fxLayout="column"
    fxLayoutGap="16px"
    [formGroup]="form"
    (ngSubmit)="save()"
    class="layout-margin"
  >
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="mb-4">Charting</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div
          fxLayout="row"
          fxLayoutGap="16px"
          formGroupName="charting"
          class="mt-2"
        >
          <mat-form-field fxFlex>
            <mat-label>Preferred Chart View</mat-label>
            <mat-select formControlName="view" required>
              <mat-option
                *ngFor="let chartView of chartViews; trackBy: trackByChartView"
                [value]="chartView"
              >
                {{ chartView }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <pt-buttons-container>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="!form.valid"
      >
        Save Changes
      </button>
    </pt-buttons-container>
  </form>
</div>
