import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { PracticeListItemComponent } from './practice-list-item/practice-list-item.component';
import { PracticeListComponent } from './practice-list/practice-list.component';
import { UpsertPracticeDialogComponent } from './upsert-practice-dialog/upsert-practice-dialog.component';

@NgModule({
  declarations: [
    PracticeListComponent,
    PracticeListItemComponent,
    UpsertPracticeDialogComponent,
  ],
  imports: [
    NgSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    NgPrincipleAccountingModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
  ],
  exports: [PracticeListComponent, PracticeListItemComponent],
})
export class PracticesModule {}
