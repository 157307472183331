import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  TrackByFunctions,
  type TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  ContactNumberLabel,
  type IContactNumber,
  isContactNumber,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'pr-contact-number-input',
    templateUrl: './contact-number-input.component.html',
    styleUrls: ['./contact-number-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactNumberInputComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class ContactNumberInputComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  private _onDestroy$: Subject<void> = new Subject();
  onChange: (_: unknown) => void;
  onTouched: (_: unknown) => void;
  trackByOption = TrackByFunctions.variable<string>();
  isRequired$ = new BehaviorSubject<boolean>(false);
  labelOptions = getEnumValues(ContactNumberLabel);
  filteredOptions$: Observable<string[]>;

  @Input() form: TypedFormGroup<IContactNumber>;
  @Input() isFirst: boolean = false;

  @Input()
  set required(required: BooleanInput) {
    this.isRequired$.next(coerceBooleanProperty(required));
  }

  ngOnInit(): void {
    this.filteredOptions$ = this.form.controls.label.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterLabel(value))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: IContactNumber): void {
    if (isContactNumber(value)) {
      this.form.setValue(value);
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clear(): void {
    this.form.reset();
  }

  private _filterLabel(value: string | undefined): ContactNumberLabel[] {
    if (!value) {
      return this.labelOptions;
    }
    const filterValue = value.toLowerCase();
    return this.labelOptions.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
