import {
  ChartableSurface,
  IConditionConfiguration,
  IMultiTreatmentConfiguration,
  IRelativeSchedulingRules,
  ITreatmentConfiguration,
  ITreatmentConfigurationRef,
  ITreatmentStepConfiguration,
  ITreatmentTemplate,
  TreatmentConfigurationName,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  ConditionConfigurationName,
  getConditionByName,
} from '../conditions/condition-configuration-seed-data';
import { ChartedItemDisplay } from '../core/charted-item-display';
import {
  MultiTreatmentConfiguration,
  TreatmentStepConfiguration,
} from './multi-treatment-configuration';
import { TreatmentTemplateName } from './treatment-template-seed-data';

interface IMultiTreatmentConfigurationMock {
  treatmentTemplateName?: TreatmentTemplateName;
  config: Partial<IMultiTreatmentConfiguration>;
}

export interface IMultiTreatmentConfigurationSeedData {
  treatmentTemplate?: WithRef<ITreatmentTemplate>;
  config: IMultiTreatmentConfiguration;
}

interface ITreatmentConfigurationMock {
  name: TreatmentConfigurationName;
  quantity?: number;
}

interface ITreatmentConfigurationQuantity {
  quantity: number;
  config: WithRef<ITreatmentConfiguration>;
}

class TreatmentStepConfigurationMocker {
  constructor(
    public treatmentConfigurations: WithRef<ITreatmentConfiguration>[]
  ) {}

  mock(
    name: string,
    selectedTreatments: ITreatmentConfigurationMock[],
    schedulingRules: Partial<IRelativeSchedulingRules> = {}
  ): ITreatmentStepConfiguration {
    const filteredTreatments: ITreatmentConfigurationQuantity[] =
      this._getSelectedTreatments(selectedTreatments);

    const duration: number = filteredTreatments.reduce(
      (
        total: number,
        filteredTreatment: ITreatmentConfigurationQuantity
      ): number => {
        return total + filteredTreatment.config.duration;
      },
      0
    );

    const treatments: ITreatmentConfigurationRef[] = filteredTreatments.map(
      (treatment: ITreatmentConfigurationQuantity) => {
        return {
          name: treatment.config.name,
          display: treatment.config.display,
          ref: treatment.config.ref,
          quantity: treatment.quantity,
        };
      }
    );

    return TreatmentStepConfiguration.init({
      name,
      treatments,
      schedulingRules: {
        duration,
        ...schedulingRules,
      },
    });
  }

  private _getSelectedTreatments(
    selectedTreatments: ITreatmentConfigurationMock[]
  ): ITreatmentConfigurationQuantity[] {
    const filteredTreatments = selectedTreatments.map((selectedTreatment) => {
      const treatmentConfig = this.treatmentConfigurations.find(
        (config) => config.name === String(selectedTreatment.name)
      );
      if (!treatmentConfig) {
        return;
      }
      return {
        config: treatmentConfig,
        quantity: selectedTreatment.quantity || 1,
      };
    });
    return compact(filteredTreatments);
  }
}

export enum MultiTreatmentConfigurationName {
  // ExamAndClean = 'New Patient Comprehensive Examination & Clean',
  // InvisalignConsultation = 'Invisalign Consultation',
  // EmergencyConsultation = 'Emergency Consultation',
  // Mouthguard = 'Occlusal Splint',
  // Invisalign = 'Invisalign',
  // RootCanal = 'Root Canal',
  // Whitening = 'Whitening - Take Home',
  // Implant = 'Implant',
  // Veneer = 'Veneer',
  // CrownMetallic = 'Crown - Metallic',
  // CrownNonMetallic = 'Crown - Non Metallic',
  // CrownVeneer = 'Crown - Veneer',
  // PostCoreCrownMetallic = 'Post, Core & Crown - Metallic',
  // PostCoreCrownNonMetallic = 'Post, Core & Crown - Non Metallic',
  // PostCoreCrownVeneer = 'Post, Core & Crown - Veneer',
  // ThreeUnitBridge = '3 Unit Bridge',
  // InternalBleaching = 'Internal Bleaching',
  RootCanalTreatment = 'Root Canal Treatment',
  PeriodontalTreatmentActive = 'Periodontal Treatment - Active',
  CrownMetallic = 'Crown - Metallic',
  CrownPFMVeneered = 'Crown - PFM / Veneered',
  Implant = 'Implant',
  OcclusalSplint = 'Occlusal Splint',
  SurgicalExtraction = 'Surgical Extraction',
  InlayOnlayIndirectRestorationCeramic = 'Inlay / Onlay Indirect Restoration - Ceramic',
  Invisalign = 'Invisalign',
  RootCanalReTreatment = 'Root Canal Re-Treatment',
  PostCoreCrownPFMVeneered = 'Post, Core & Crown - PFM / Veneered',
  NewPatientComprehensiveExaminationClean = 'New Patient Comprehensive Examination & Clean',
  NewPatientComprehensiveExamination = 'New Patient Comprehensive Examination',
  MandibularAdvancementSplint = 'Mandibular Advancement Splint',
  CosmeticRestorativeWorkUpTryIn = 'Cosmetic/Restorative Work Up / Try in',
  PostCoreCrownMetallic = 'Post, Core & Crown - Metallic',
  InvisalignConsultation = 'Invisalign Consultation',
  Veneer = 'Veneer',
  Bridge = 'Bridge',
  InlayOnlayIndirectRestorationMetallic = 'Inlay / Onlay Indirect Restoration - Metallic',
  TeethWhiteningTakeHome = 'Teeth Whitening - Take Home',
  Mouthguard = 'Mouthguard',
  PostCoreCrownCeramicNonMetallic = 'Post, Core & Crown - Ceramic / Non-metallic',
  LimitedEmergencyExamination = 'Limited / Emergency Examination',
  InternalBleaching = 'Internal Bleaching',
  Consultation = 'Consultation',
  CrownCeramicNonMetallic = 'Crown - Ceramic / Non-metallic',
}

export function getMultiTreatmentByName(
  name: MultiTreatmentConfigurationName,
  treatmentConfigurations: WithRef<IMultiTreatmentConfiguration>[]
): WithRef<IMultiTreatmentConfiguration> | undefined {
  return treatmentConfigurations.find(
    (treatment) => treatment.name === String(name)
  );
}

export function buildMultiTreatmentConfigurationSeedData(
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[],
  treatmentTemplates: WithRef<ITreatmentTemplate>[],
  conditionConfigurations: WithRef<IConditionConfiguration>[]
): IMultiTreatmentConfigurationSeedData[] {
  const stepMocker: TreatmentStepConfigurationMocker =
    new TreatmentStepConfigurationMocker(treatmentConfigurations);
  const newMocks: IMultiTreatmentConfigurationSeedData[] = [
    {
      config: {
        steps: [
          stepMocker.mock('RCT Stage 1 Chemomechanical Prep', [
            {
              name: TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
            },
          ]),
          stepMocker.mock('RCT Stage 2 Obturation', [
            {
              name: TreatmentConfigurationName.RootCanalObturation,
            },
          ]),
        ],
        display: ChartedItemDisplay.init({
          hideWhenResolved: false,
          icon: { name: 'Root Canal', slug: 'pr-root-canal' },
          colour: { value: '#ff6e40', name: 'Deep Orange a200' },
        }),
        conditions: [
          getConditionByName(
            ConditionConfigurationName.AbscessPeriapicalLesion,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.NecroticPulp,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.InfectedTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PulpCanalObliteration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.SensitivityHot,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Pulpitis,
            conditionConfigurations
          ).ref,
        ],
        availableSurfaces: [ChartableSurface.WholeTooth],
        name: MultiTreatmentConfigurationName.RootCanalTreatment,
      },
    },
    {
      config: {
        conditions: [
          getConditionByName(
            ConditionConfigurationName.PeriodontalDiseaseGeneralised,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PeriodontalDiseaseLocalised,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PeriodontalAbscess,
            conditionConfigurations
          ).ref,
        ],
        steps: [
          stepMocker.mock('Periodontal Treatment - Quadrant', [
            {
              name: TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
            },
          ]),
          stepMocker.mock('Periodontal Treatment - Quadrant', [
            {
              name: TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
            },
          ]),
          stepMocker.mock('Periodontal Treatment - Quadrant', [
            {
              name: TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
            },
          ]),
          stepMocker.mock('Periodontal Treatment - Quadrant', [
            {
              name: TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
            },
          ]),
        ],
        name: MultiTreatmentConfigurationName.PeriodontalTreatmentActive,
        display: ChartedItemDisplay.init({
          colour: { name: 'Green a700', value: '#00c853' },
          icon: { slug: 'pr-scale-and-clean', name: 'Scale & Clean' },
        }),
        availableSurfaces: [ChartableSurface.WholeMouth],
      },
    },
    {
      config: {
        steps: [
          stepMocker.mock('Crown Preparation, Impression and Temporisation', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Crown Bonding',
            [
              {
                name: TreatmentConfigurationName.CrownIssueBondingMetallic,
              },
            ],
            { minDays: 14 }
          ),
        ],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Caries,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LostFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LeakingRestoration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PainOnBiting,
            conditionConfigurations
          ).ref,
        ],
        display: ChartedItemDisplay.init({
          icon: { slug: 'pr-crown-small', name: 'Crown' },
          hideWhenResolved: false,
          colour: { name: 'Blue Grey a700', value: '#455a64' },
        }),
        name: MultiTreatmentConfigurationName.CrownMetallic,
        availableSurfaces: [ChartableSurface.Crown],
      },
    },
    {
      config: {
        steps: [
          stepMocker.mock('Crown Preparation, Impression and Temporisation', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Crown Bonding',
            [
              {
                name: TreatmentConfigurationName.CrownIssueBondingVeneeredPFM,
              },
            ],
            { minDays: 14 }
          ),
        ],
        name: MultiTreatmentConfigurationName.CrownPFMVeneered,
        display: ChartedItemDisplay.init({
          colour: { value: '#bcaaa4', name: 'Brown a200' },
          hideWhenResolved: false,
          icon: { slug: 'pr-crown-small', name: 'Crown' },
        }),
        availableSurfaces: [ChartableSurface.Crown],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LostFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Caries,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LeakingRestoration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PainOnBiting,
            conditionConfigurations
          ).ref,
        ],
      },
    },
    {
      config: {
        availableSurfaces: [ChartableSurface.WholeTooth],
        name: MultiTreatmentConfigurationName.Implant,
        conditions: [
          getConditionByName(
            ConditionConfigurationName.MissingTooth,
            conditionConfigurations
          ).ref,
        ],
        steps: [
          stepMocker.mock('Implant Planning / Work Up', [
            {
              name: TreatmentConfigurationName.ImplantPlanningWorkUp,
            },
          ]),
          stepMocker.mock('Implant Surgery - Fixture Placement', [
            {
              name: TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
            },
          ]),
          stepMocker.mock(
            'Implant Review and Suture Removal',
            [
              {
                name: TreatmentConfigurationName.ImplantReviewAfterFixturePlacement,
              },
            ],
            { minDays: 7 }
          ),
          stepMocker.mock(
            'Implant Impression',
            [
              {
                name: TreatmentConfigurationName.ImplantImpression,
              },
            ],
            { minDays: 70 }
          ),
          stepMocker.mock(
            'Implant Restoration / Crown Issue',
            [
              {
                name: TreatmentConfigurationName.ImplantRestoration,
              },
            ],
            { minDays: 14 }
          ),
        ],
        display: ChartedItemDisplay.init({
          icon: { name: 'Implant', slug: 'pr-implant' },
          colour: { value: '#ff6d00', name: 'Orange a700' },
          hideWhenResolved: false,
        }),
      },
    },
    {
      config: {
        availableSurfaces: [ChartableSurface.Arch],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Bruxism,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.TMJDisorder,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Attrition,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Erosion,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Wear,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PainOnBiting,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.SensitivityGeneral,
            conditionConfigurations
          ).ref,
        ],
        display: ChartedItemDisplay.init({
          hideWhenResolved: false,
          colour: { name: 'Lime a700', value: '#aeea00' },
          icon: { name: 'Arch - Guard', slug: 'pr-arch-guard' },
        }),
        steps: [
          stepMocker.mock('Occlusal Splint Impression / Scan', [
            {
              name: TreatmentConfigurationName.SplintImpressionScan,
            },
          ]),
          stepMocker.mock('Occlusal Splint Issue', [
            {
              name: TreatmentConfigurationName.SplintIssue,
            },
          ]),
        ],
        name: MultiTreatmentConfigurationName.OcclusalSplint,
      },
    },
    {
      config: {
        steps: [
          stepMocker.mock('Surgical Extraction', [
            {
              name: TreatmentConfigurationName.ExtractionSurgical,
            },
          ]),
          stepMocker.mock(
            'Review and/or Suture Removal',
            [
              {
                name: TreatmentConfigurationName.ReviewOfRecentTreatment,
              },
            ],
            { minDays: 7 }
          ),
        ],
        name: MultiTreatmentConfigurationName.SurgicalExtraction,
        availableSurfaces: [ChartableSurface.WholeTooth],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.ImpactedTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.UneruptedTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.InfectedTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.DrainingSinus,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Swelling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RootFracture,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RootResorption,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RetainedRoot,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.NecroticPulp,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Pulpitis,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PeriodontalAbscess,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.AbscessPeriapicalLesion,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
        ],
        display: ChartedItemDisplay.init({
          disablesSurface: true,
          colour: { name: 'Red a700', value: '#d50000' },
          icon: { name: 'Extraction', slug: 'pr-extraction' },
          hideWhenResolved: false,
        }),
      },
    },
    {
      config: {
        name: MultiTreatmentConfigurationName.InlayOnlayIndirectRestorationCeramic,
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Wear,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LostFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LeakingRestoration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Caries,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Attrition,
            conditionConfigurations
          ).ref,
        ],
        availableSurfaces: [
          ChartableSurface.Facial,
          ChartableSurface.Mesial,
          ChartableSurface.Occlusal,
          ChartableSurface.Distal,
          ChartableSurface.Lingual,
        ],
        display: ChartedItemDisplay.init({
          icon: { name: 'Abrasion', slug: 'pr-abrasion' },
          hideWhenResolved: false,
          colour: { name: 'Lime a700', value: '#aeea00' },
        }),
        steps: [
          stepMocker.mock(
            'Inlay / Onlay Preparation, Impression and Temporisation',
            [
              {
                name: TreatmentConfigurationName.InlayOnlayPreparationAndImpression,
              },
            ]
          ),
          stepMocker.mock(
            'Inlay / Onlay Bonding',
            [
              {
                name: TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
              },
            ],
            { minDays: 14 }
          ),
        ],
      },
    },
    {
      config: {
        availableSurfaces: [ChartableSurface.WholeMouth],
        display: ChartedItemDisplay.init({
          icon: { name: 'Arch', slug: 'pr-arch' },
          colour: { name: 'Indigo a200', value: '#536dfe' },
          hideWhenResolved: false,
        }),
        steps: [
          stepMocker.mock('Invisalign Work Up', [
            {
              name: TreatmentConfigurationName.InvisalignWorkUp,
            },
          ]),
          stepMocker.mock('Invisalign Clincheck Review', [
            {
              name: TreatmentConfigurationName.InvisalignClincheckReview,
            },
          ]),
          stepMocker.mock('Invisalign Start First Aligners', [
            {
              name: TreatmentConfigurationName.InvisalignIssuingFirstAligners,
            },
          ]),
          stepMocker.mock('Invisalign Bonding of Attachments, IPR', [
            {
              name: TreatmentConfigurationName.InvisalignBondingOfAttachments,
            },
          ]),
          stepMocker.mock('Invisalign Mid-treatment Review', [
            {
              name: TreatmentConfigurationName.InvisalignMidTreatmentReview,
            },
          ]),
          stepMocker.mock('Invisalign Mid-Treatment Review', [
            {
              name: TreatmentConfigurationName.InvisalignMidTreatmentReview,
            },
          ]),
          stepMocker.mock(
            'Invisalign End of Treatment - Attachment Removal/Wires/Scan for Retainers',
            [
              {
                name: TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
              },
              {
                name: TreatmentConfigurationName.InvisalignEndOfTreatment,
              },
            ]
          ),
          stepMocker.mock('Issue Retainers', [
            {
              name: TreatmentConfigurationName.InvisalignIssueRetainers,
            },
          ]),
        ],
        name: MultiTreatmentConfigurationName.Invisalign,
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crowding,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Spacing,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ClassIIMalocclusion,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ClassIIIMalocclusion,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.DeepBite,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.AnteriorOpenBite,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PosteriorCrossBite,
            conditionConfigurations
          ).ref,
        ],
      },
    },
    {
      config: {
        name: MultiTreatmentConfigurationName.RootCanalReTreatment,
        availableSurfaces: [ChartableSurface.WholeTooth],
        display: ChartedItemDisplay.init({
          colour: { name: 'Red a100', value: '#ff8a80' },
          hideWhenResolved: false,
          icon: { slug: 'pr-root-canal', name: 'Root Canal' },
        }),
        steps: [
          stepMocker.mock('RCT - Re-treatment - Removal of root filling', [
            {
              name: TreatmentConfigurationName.RootCanalReTreatmentRemovalOfRootFilling,
            },
          ]),
          stepMocker.mock('RCT - Chemomechanical Preparation', [
            {
              name: TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
            },
          ]),
          stepMocker.mock('RCT - Obturation', [
            {
              name: TreatmentConfigurationName.RootCanalObturation,
            },
          ]),
        ],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.RootCanalTreatment,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.InfectedTooth,
            conditionConfigurations
          ).ref,
        ],
      },
    },
    {
      config: {
        name: MultiTreatmentConfigurationName.PostCoreCrownPFMVeneered,
        steps: [
          stepMocker.mock('Post, Core & Crown Stage 1', [
            {
              name: TreatmentConfigurationName.PostAndCorePreparationAndImpression,
            },
          ]),
          stepMocker.mock('Post, Core & Crown Stage 2', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
            {
              name: TreatmentConfigurationName.PostAndCorePlacement,
            },
          ]),
          stepMocker.mock('Post, Core & Crown Stage 3', [
            {
              name: TreatmentConfigurationName.CrownIssueBondingVeneeredPFM,
            },
          ]),
        ],
        availableSurfaces: [ChartableSurface.WholeTooth],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RootCanalTreatment,
            conditionConfigurations
          ).ref,
        ],
        display: ChartedItemDisplay.init({
          colour: { name: 'Brown a200', value: '#bcaaa4' },
          icon: { name: 'Core, Post & Crown', slug: 'pr-core-post-and-crown' },
          hideWhenResolved: false,
        }),
      },
    },
    {
      config: {
        availableSurfaces: [ChartableSurface.WholeMouth],
        display: ChartedItemDisplay.init({
          hideWhenResolved: false,
          colour: { name: 'Light Blue a200', value: '#40c4ff' },
          icon: { slug: 'pr-arch-guard', name: 'Arch - Guard' },
        }),
        conditions: [
          getConditionByName(
            ConditionConfigurationName.ObstructiveSleepApnoea,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Bruxism,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.TMJDisorder,
            conditionConfigurations
          ).ref,
        ],
        name: MultiTreatmentConfigurationName.MandibularAdvancementSplint,
        steps: [
          stepMocker.mock('MAS Impressions / Scan', [
            {
              name: TreatmentConfigurationName.MandibularAdvancementSplintImpressionsScan,
            },
          ]),
          stepMocker.mock(
            'MAS Issue',
            [
              {
                name: TreatmentConfigurationName.MandibularAdvancementSplintIssue,
              },
            ],
            { minDays: 14 }
          ),
          stepMocker.mock(
            'MAS Review',
            [
              {
                name: TreatmentConfigurationName.MandibularAdvancementSplintReviewAdjustmentRepair,
              },
            ],
            { minDays: 14 }
          ),
        ],
      },
    },
    {
      config: {
        conditions: [],
        display: ChartedItemDisplay.init({
          colour: { value: '#1de9b6', name: 'Teal a400' },
          icon: { name: 'Veneer', slug: 'pr-veneer' },
        }),
        name: MultiTreatmentConfigurationName.CosmeticRestorativeWorkUpTryIn,
        steps: [
          stepMocker.mock(
            'Cosmetic/Restorative Work Up / Impressions / Records',
            [
              {
                name: TreatmentConfigurationName.CosmeticOrRestorativeWorkUp,
              },
            ]
          ),
          stepMocker.mock(
            'Diagnostic Mock Up Try-in / Treatment Confirmation',
            [
              {
                name: TreatmentConfigurationName.DiagnosticWaxUpMockUpTryIn,
              },
            ],
            { minDays: 10 }
          ),
        ],
        availableSurfaces: [],
      },
    },
    {
      config: {
        name: MultiTreatmentConfigurationName.PostCoreCrownMetallic,
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RootCanalTreatment,
            conditionConfigurations
          ).ref,
        ],
        steps: [
          stepMocker.mock('Post, Core & Crown Stage 1', [
            {
              name: TreatmentConfigurationName.PostAndCorePreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Post, Core & Crown Stage 2',
            [
              {
                name: TreatmentConfigurationName.CrownPreparationAndImpression,
              },
              {
                name: TreatmentConfigurationName.PostAndCorePlacement,
              },
            ],
            { minDays: 14 }
          ),
          stepMocker.mock(
            'Post, Core & Crown Stage 3',
            [
              {
                name: TreatmentConfigurationName.CrownIssueBondingMetallic,
              },
            ],
            { minDays: 14 }
          ),
        ],
        display: ChartedItemDisplay.init({
          colour: { value: '#616161', name: 'Grey a700' },
          icon: { slug: 'pr-core-post-and-crown', name: 'Core, Post & Crown' },
          hideWhenResolved: false,
        }),
        availableSurfaces: [ChartableSurface.WholeTooth],
      },
    },
    {
      config: {
        display: ChartedItemDisplay.init({
          colour: { name: 'Light Blue a100', value: '#80d8ff' },
          hideWhenResolved: false,
          icon: { name: 'Veneer', slug: 'pr-veneer' },
        }),
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Discolouration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Spacing,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Crowding,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Wear,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
        ],
        availableSurfaces: [ChartableSurface.WholeTooth],
        steps: [
          stepMocker.mock('Veneer Preparation and Impressions', [
            {
              name: TreatmentConfigurationName.VeneerPreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Veneer Bonding',
            [
              {
                name: TreatmentConfigurationName.VeneerIssueBonding,
              },
            ],
            { minDays: 14 }
          ),
        ],
        name: MultiTreatmentConfigurationName.Veneer,
      },
    },
    {
      config: {
        conditions: [],
        name: MultiTreatmentConfigurationName.Bridge,
        steps: [
          stepMocker.mock('Bridge Preparation and Impression', [
            {
              name: TreatmentConfigurationName.BridgePreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Bridge Issue',
            [
              {
                name: TreatmentConfigurationName.BridgeIssuePontics,
              },
              {
                name: TreatmentConfigurationName.BridgeIssueAbutmentsOrRetainers,
              },
            ],
            { minDays: 14 }
          ),
        ],
        availableSurfaces: [
          ChartableSurface.WholeTooth,
          ChartableSurface.MultipleTeeth,
        ],
        display: ChartedItemDisplay.init({
          hideWhenResolved: false,
          icon: { name: 'Bridge', slug: 'pr-bridge' },
          colour: { value: '#7c4dff', name: 'Deep Purple a200' },
        }),
      },
    },
    {
      config: {
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Caries,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LostFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LeakingRestoration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PainOnBiting,
            conditionConfigurations
          ).ref,
        ],
        display: ChartedItemDisplay.init({
          icon: { slug: 'pr-abrasion', name: 'Abrasion' },
          colour: { name: 'Blue Grey a400', value: '#78909c' },
        }),
        availableSurfaces: [
          ChartableSurface.Facial,
          ChartableSurface.Mesial,
          ChartableSurface.Occlusal,
          ChartableSurface.Distal,
          ChartableSurface.Lingual,
        ],
        steps: [
          stepMocker.mock(
            'Inlay / Onlay Preparation, Impression and Temporisation',
            [
              {
                name: TreatmentConfigurationName.InlayOnlayPreparationAndImpression,
              },
            ]
          ),
          stepMocker.mock(
            'Inlay / Onlay Bonding',
            [
              {
                name: TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
              },
            ],
            { minDays: 14 }
          ),
        ],
        name: MultiTreatmentConfigurationName.InlayOnlayIndirectRestorationMetallic,
      },
    },
    {
      config: {
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Discolouration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Staining,
            conditionConfigurations
          ).ref,
        ],
        availableSurfaces: [ChartableSurface.WholeMouth],
        display: ChartedItemDisplay.init({
          colour: { value: '#b388ff', name: 'Deep Purple a100' },
          icon: { name: 'Whitening', slug: 'pr-whitening' },
        }),
        name: MultiTreatmentConfigurationName.TeethWhiteningTakeHome,
        steps: [
          stepMocker.mock('Whitening Stage 1 - Impressions', [
            {
              name: TreatmentConfigurationName.TeethWhiteningImpressions,
            },
          ]),
          stepMocker.mock('Whitening Stage 2 - Issue', [
            {
              name: TreatmentConfigurationName.TeethWhiteningIssue,
            },
          ]),
        ],
      },
    },
    {
      config: {
        display: ChartedItemDisplay.init({
          icon: { slug: 'pr-arch-guard', name: 'Arch - Guard' },
          colour: { value: '#00c853', name: 'Green a700' },
        }),
        availableSurfaces: [ChartableSurface.WholeMouth],
        name: MultiTreatmentConfigurationName.Mouthguard,
        conditions: [],
        steps: [
          stepMocker.mock('Mouthguard Impression / Scan', [
            {
              name: TreatmentConfigurationName.MouthguardImpressionsScan,
            },
          ]),
          stepMocker.mock(
            'Mouthguard Issue',
            [
              {
                name: TreatmentConfigurationName.MouthguardIssue,
              },
            ],
            { minDays: 14 }
          ),
        ],
      },
    },
    {
      config: {
        display: ChartedItemDisplay.init({
          icon: { name: 'Core, Post & Crown', slug: 'pr-core-post-and-crown' },
          colour: { name: 'Brown a100', value: '#d7ccc8' },
          hideWhenResolved: false,
        }),
        availableSurfaces: [ChartableSurface.WholeTooth],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.RootCanalTreatment,
            conditionConfigurations
          ).ref,
        ],
        steps: [
          stepMocker.mock('Post, Core & Crown Stage 1', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
          ]),
          stepMocker.mock('Post, Core & Crown Stage 2', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
            {
              name: TreatmentConfigurationName.PostAndCorePlacement,
            },
          ]),
          stepMocker.mock('Post, Core & Crown Stage 3', [
            {
              name: TreatmentConfigurationName.CrownIssueBondingCeramicNonMetallic,
            },
          ]),
        ],
        name: MultiTreatmentConfigurationName.PostCoreCrownCeramicNonMetallic,
      },
    },
    {
      config: {
        display: ChartedItemDisplay.init({
          icon: { name: 'Whitening', slug: 'pr-whitening' },
          colour: { value: '#d500f9', name: 'Purple a400' },
          hideWhenResolved: false,
        }),
        steps: [
          stepMocker.mock('Internal Bleaching Stage 1', [
            {
              name: TreatmentConfigurationName.InternalBleachingPlacement,
            },
          ]),
          stepMocker.mock('Internal Bleaching Stage 2', [
            {
              name: TreatmentConfigurationName.InternalBleachingRestoration,
            },
          ]),
        ],
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Discolouration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.RootCanalTreatment,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Staining,
            conditionConfigurations
          ).ref,
        ],
        availableSurfaces: [ChartableSurface.WholeTooth],
        name: MultiTreatmentConfigurationName.InternalBleaching,
      },
    },
    {
      config: {
        conditions: [
          getConditionByName(
            ConditionConfigurationName.Caries,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.Crack,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenFilling,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.ChippedBrokenTooth,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.LeakingRestoration,
            conditionConfigurations
          ).ref,
          getConditionByName(
            ConditionConfigurationName.PainOnBiting,
            conditionConfigurations
          ).ref,
        ],
        steps: [
          stepMocker.mock('Crown Preparation, Impression and Temporisation', [
            {
              name: TreatmentConfigurationName.CrownPreparationAndImpression,
            },
          ]),
          stepMocker.mock(
            'Crown Bonding',
            [
              {
                name: TreatmentConfigurationName.CrownIssueBondingCeramicNonMetallic,
              },
            ],
            { minDays: 14 }
          ),
        ],
        availableSurfaces: [ChartableSurface.Crown],
        name: MultiTreatmentConfigurationName.CrownCeramicNonMetallic,
        display: ChartedItemDisplay.init({
          colour: { name: 'Brown a100', value: '#d7ccc8' },
          icon: { slug: 'pr-crown-small', name: 'Crown' },
          hideWhenResolved: false,
        }),
      },
    },
  ].map((seedData: IMultiTreatmentConfigurationMock) => ({
    treatmentTemplate: treatmentTemplates.find(
      (treatmentTemplate) =>
        String(seedData.treatmentTemplateName) === treatmentTemplate.name
    ),
    config: MultiTreatmentConfiguration.init(seedData.config),
  }));

  return newMocks;
}
