import { IMigrationLogger } from '@principle-theorem/migration-runner';
import {
  IBrand,
  ICustomFormConfiguration,
  ICustomFormContent,
  OrganisationCollection,
  ReservedCustomFormId,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  FirestoreTransactionRunner,
  WithRef,
  addDoc,
  bufferedQuery$,
  collectionGroupQuery,
} from '@principle-theorem/shared';
import { compact, isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { Brand } from '../brand';
import { CustomFormConfiguration } from './custom-form-configuration';
import { CustomFormContent } from './custom-form-content';
import {
  BASIC_MEDICAL_HISTORY_CUSTOM_FORM_LAYOUT,
  BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG,
  BASIC_MEDICAL_HISTORY_FORM_LAYOUT,
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
} from './schema/medical-history-form-schema';

export class MedicalHistoryFormSeeder {
  static getBrands$(): Observable<WithRef<IBrand>[]> {
    return bufferedQuery$(
      collectionGroupQuery<IBrand>(OrganisationCollection.Brands),
      500,
      'ref'
    );
  }

  static async seedForBrand(
    brand: WithRef<IBrand>,
    dryRun: boolean = false,
    logger?: IMigrationLogger
  ): Promise<void> {
    const existingForm = await Firestore.safeGetDoc(
      Brand.medicalHistoryFormRef(brand)
    );
    return existingForm
      ? this.updateExistingConfig(brand, existingForm, dryRun, logger)
      : this.addNewConfig(brand, dryRun, logger);
  }

  static async updateExistingConfig(
    brand: WithRef<IBrand>,
    formConfig: WithRef<ICustomFormConfiguration>,
    dryRun: boolean,
    logger?: IMigrationLogger
  ): Promise<void> {
    const content = await CustomFormConfiguration.getContent(formConfig);
    return !content
      ? this.addContent(brand, formConfig.ref, dryRun, logger)
      : this.addMissingPropertiesToExistingContent(
          brand,
          content,
          dryRun,
          logger
        );
  }

  static async addNewConfig(
    brand: WithRef<IBrand>,
    dryRun: boolean,
    logger?: IMigrationLogger
  ): Promise<void> {
    this.log(logger, brand, `Add Form Config`);

    const customForm = CustomFormConfiguration.init({
      name: 'Medical History Form',
      autoConfirm: false,
      prefillFromPreviousSubmission: false,
    });

    await FirestoreTransactionRunner.run(async (transaction) => {
      const customFormRef = await addDoc<ICustomFormConfiguration>(
        Brand.customFormConfigCol(brand),
        customForm,
        ReservedCustomFormId.MedicalHistory,
        transaction.transaction
      );
      await this.addContent(
        brand,
        customFormRef,
        dryRun,
        logger,
        transaction.transaction
      );
    });
  }

  static async addContent(
    brand: WithRef<IBrand>,
    customFormRef: DocumentReference<ICustomFormConfiguration>,
    dryRun: boolean,
    logger?: IMigrationLogger,
    transaction?: FirebaseFirestore.Transaction
  ): Promise<void> {
    this.log(logger, brand, `Add Form content`);
    const customFormContent = CustomFormContent.init({
      layout: BASIC_MEDICAL_HISTORY_CUSTOM_FORM_LAYOUT,
      dataResolverConfig: BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG,
      jsonSchemaForm: {
        schema: BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
        layout: BASIC_MEDICAL_HISTORY_FORM_LAYOUT,
      },
    });
    if (dryRun) {
      return;
    }
    const customFormContentRef = await addDoc(
      CustomFormConfiguration.contentHistoryCol({ ref: customFormRef }),
      customFormContent,
      undefined,
      transaction
    );
    await Firestore.patchDoc(
      customFormRef,
      { activeContent: customFormContentRef },
      transaction
    );
  }

  static async addMissingPropertiesToExistingContent(
    brand: WithRef<IBrand>,
    content: WithRef<ICustomFormContent>,
    dryRun: boolean,
    logger?: IMigrationLogger
  ): Promise<void> {
    const changes: Partial<ICustomFormContent> = {};

    if (!content.dataResolverConfig) {
      changes.dataResolverConfig =
        BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG;
    }
    if (!content.jsonSchemaForm.layout) {
      changes.jsonSchemaForm = {
        ...changes.jsonSchemaForm,
        layout: BASIC_MEDICAL_HISTORY_FORM_LAYOUT,
      };
    }
    if (!content.jsonSchemaForm.schema) {
      changes.jsonSchemaForm = {
        ...changes.jsonSchemaForm,
        schema: BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
      };
    }

    if (isEmpty(changes)) {
      this.log(logger, brand, `Existing found skipped`);
      return;
    }
    if (!dryRun) {
      await Firestore.patchDoc(content.ref, changes);
    }
    this.log(logger, brand, `Added missing properties`, changes);
  }

  static log(
    logger: IMigrationLogger | undefined,
    brand: WithRef<IBrand>,
    message: string,
    data?: object
  ): void {
    if (!logger) {
      return;
    }
    const mainLine = `${message}: ${brand.name} (${brand.ref.path})`;
    const dataLine = data ? JSON.stringify(data) : undefined;
    const lines = compact([mainLine, dataLine]).join('\n');
    logger.info(lines);
  }
}
