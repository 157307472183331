import { type IFirestoreModel, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface ITag extends IFirestoreModel {
  name: string;
  hexColour: string;
}

export function isTag(item: unknown): item is ITag {
  return isObject(item) && isString(item.name) && isString(item.hexColour);
}
