import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { Brand, Chat } from '@principle-theorem/principle-core';
import { Firestore, doc, snapshotDefined } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export class HasAccessToChatGuard {
  constructor(
    private _organisation: OrganisationService,
    private _currentScope: CurrentScopeFacade
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const staffer = await snapshotDefined(this._organisation.staffer$);
    const brand = await snapshotDefined(this._currentScope.currentBrand$);

    const uid = next.paramMap.get('uid') ?? undefined;
    if (!uid) {
      return false;
    }

    const chat = await Firestore.getDoc(doc(Brand.chatCol(brand), uid));
    return chat ? Chat.userHasAccess(chat, staffer.ref) : false;
  }
}
