import { type WithRef } from '@principle-theorem/shared';
import { type IPractice } from '../../practice/practice';
import { type ITemplateContext, type ITemplateScopeData } from '../templating';

export interface IPracticeScopeData extends ITemplateScopeData {
  practice: WithRef<IPractice>;
}

export interface IPracticeTemplateContext extends ITemplateContext {
  practiceName: string;
  practiceEmail: string;
  practicePhoneNumber: string;
  practiceAddress: string;
}
