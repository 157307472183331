import {
  DocumentReference,
  WithRef,
  asyncForEach,
} from '@principle-theorem/shared';
import {
  PatientDocumentDestinationEntity as ExactPatientDocumentsDestinationEntity,
  IPatientFileMigrationData,
  PATIENT_DOCUMENTS_RESOURCE_TYPE,
} from '../../../exact/destination/entities/patient-documents';
import { TranslationMapHandler } from '../../../translation-map';
import {
  type IMedia,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { getBucketStoragePath } from '../../../storage';
import { FirestoreMigrate } from '../../../destination/destination';
import { Media, Patient } from '@principle-theorem/principle-core';
import { compact } from 'lodash';

enum DentrixDocumentType {
  Document = 'Document',
  Xray = 'XRay',
}

const DENTRIX_PATH_MAP: { [key in DentrixDocumentType]: string } = {
  [DentrixDocumentType.Document]: 'documents',
  [DentrixDocumentType.Xray]: 'images',
};

export class PatientDocumentDestinationEntity extends ExactPatientDocumentsDestinationEntity {
  override async addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    migrationData: IPatientFileMigrationData
  ): Promise<[DocumentReference<IMedia>[], string[]]> {
    const failedFiles: string[] = [];
    const fileRefs = await asyncForEach(
      migrationData.documents,
      async (document) => {
        const location =
          DENTRIX_PATH_MAP[document.data.data.type as DentrixDocumentType];
        const filePath = [location, document.data.data.source_path].join('/');

        const copyStorage = this.getCopyStorage(
          getBucketStoragePath(migration.source),
          migrationData.patientRef,
          migration.configuration.projectId,
          migration.configuration.destinationBucket
        );

        const destinationFilePath = await copyStorage.copy(filePath);
        if (!destinationFilePath) {
          failedFiles.push(filePath);
          return;
        }

        const documentDestinationRef = await translationMap.getDestination(
          document.record.uid,
          PATIENT_DOCUMENTS_RESOURCE_TYPE
        );

        const mediaRef = await FirestoreMigrate.upsertDoc(
          Patient.mediaCol({ ref: migrationData.patientRef }),
          {
            ...Media.init({
              name: document.data.data.title,
              path: destinationFilePath,
            }),
            createdAt: document.data.translations.date,
          },
          documentDestinationRef?.id
        );

        if (!documentDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: document.record.uid,
            destinationIdentifier: mediaRef,
            resourceType: PATIENT_DOCUMENTS_RESOURCE_TYPE,
          });
        }

        return mediaRef;
      }
    );

    return [compact(fileRefs), failedFiles];
  }
}
