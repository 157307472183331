import { DEFAULT_INCREMENT, shareReplayCold } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';

export type OptionFinderQuery = [Moment, Moment];

export const DEFAULT_TIME_INCREMENT = moment.duration(
  DEFAULT_INCREMENT,
  'minutes'
);

export abstract class EventOptionFinder<T> {
  currentRequest$: Observable<OptionFinderQuery>;
  startDate$ = new ReplaySubject<Moment>(1);
  endDate$ = new ReplaySubject<Moment>(1);
  timeIncrement: moment.Duration = DEFAULT_TIME_INCREMENT;

  constructor(
    public model$: Observable<T>,
    startDate: Moment,
    endDate: Moment
  ) {
    this.startDate$.next(startDate);
    this.endDate$.next(endDate);
    this.currentRequest$ = combineLatest([
      this.startDate$.asObservable(),
      this.endDate$.asObservable(),
    ]).pipe(shareReplayCold());
  }
}
