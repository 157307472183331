import { IBrand, IStaffer } from '@principle-theorem/principle-core/interfaces';
import { upsertBulkUniqueCol, WithRef } from '@principle-theorem/shared';
import { ConditionConfiguration } from './condition-configuration';
import { buildConditionConfigurationSeedData } from './condition-configuration-seed-data';

export async function upsertConditionConfigurations(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<void> {
  return upsertBulkUniqueCol(
    buildConditionConfigurationSeedData()
      .filter((conditionConfiguration) => !!conditionConfiguration.uid)
      .map((conditionConfiguration) => ({
        collectionRef: ConditionConfiguration.col(entity),
        data: conditionConfiguration,
        uid: conditionConfiguration.uid,
      }))
  );
}

export async function setConditionConfigurations(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<void> {
  return upsertBulkUniqueCol(
    buildConditionConfigurationSeedData().map((conditionConfiguration) => ({
      collectionRef: ConditionConfiguration.col(entity),
      data: conditionConfiguration,
      uid: conditionConfiguration.uid,
    }))
  );
}
