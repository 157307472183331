import {
  DayOfWeek,
  getEnumValues,
  isEnumValue,
  isObject,
  type Time24hrType,
} from '@principle-theorem/shared';

export enum TimingDirection {
  Before = 'before',
  After = 'after',
  Immediately = 'immediately',
}

export enum TimingUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export const TIMING_UNITS: TimingUnit[] = getEnumValues(TimingUnit);

export interface IAutomationTiming {
  direction: TimingDirection;
  unit: TimingUnit;
  amount: number;
  time?: Time24hrType;
  daysOfWeek?: DayOfWeek[];
}

export type RelativeTimingWithTime = IAutomationTiming & {
  unit:
    | TimingUnit.Days
    | TimingUnit.Weeks
    | TimingUnit.Months
    | TimingUnit.Years;
  time: Time24hrType;
};

export type AutomationTiming = IAutomationTiming | RelativeTimingWithTime;

export function isRelativeTimingWithTime(
  item: IAutomationTiming
): item is RelativeTimingWithTime {
  return (
    !!item.time && ![TimingUnit.Hours, TimingUnit.Minutes].includes(item.unit)
  );
}

export const DEFAULT_AUTOMATION_TIMING: IAutomationTiming = {
  direction: TimingDirection.After,
  unit: TimingUnit.Days,
  amount: 1,
};

export const IMMEDIATE_AUTOMATION_TIMING: IAutomationTiming = {
  direction: TimingDirection.Immediately,
  unit: TimingUnit.Minutes,
  amount: 1,
};

export function isAutomationTiming(item: unknown): item is IAutomationTiming {
  return (
    isObject(item) &&
    'direction' in item &&
    isEnumValue(TimingDirection, item.direction) &&
    'unit' in item &&
    isEnumValue(TimingUnit, item.unit)
  );
}
