import {
  IStaffer,
  ITask,
  ITaskFilter,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, WithRef, isSameRef } from '@principle-theorem/shared';

export abstract class TaskFilter implements ITaskFilter {
  abstract name: string;
  abstract icon: string;
  abstract slug: string;
  abstract filter(task: WithRef<ITask>, staffer: WithRef<IStaffer>): boolean;

  protected _isAssignedUser(
    task: WithRef<ITask>,
    staffer: WithRef<IStaffer>
  ): boolean {
    if (!task.assignedUser) {
      return false;
    }
    return isSameRef(task.assignedUser, staffer);
  }

  protected _isAssignedTeam(
    task: WithRef<ITask>,
    staffer: WithRef<IStaffer>
  ): boolean {
    return staffer.teams.some((team: INamedDocument) =>
      task.assignedTeam ? isSameRef(task.assignedTeam, team) : false
    );
  }

  protected _isOwner(
    task: WithRef<ITask>,
    staffer: WithRef<IStaffer>
  ): boolean {
    return task.owner ? isSameRef(task.owner, staffer) : false;
  }
}
