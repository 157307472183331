import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  HealthcareClaim,
  HealthcareClaimGenerator,
  IResolvedClaimItem,
  resolveLineItem,
  Staffer,
  stafferToNamedDoc,
  toClaimableItems,
} from '@principle-theorem/principle-core';
import {
  IClaimableItem,
  IHealthcareClaim,
  IHealthcareClaimItem,
  IInvoice,
  IProviderData,
  IStaffer,
  MAX_CLAIMABLE_ITEMS,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { take } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IClaimOption {
  id: string;
  item: IClaimableItem;
}

export interface IBuildHealthcareClaimRequest {
  submitLabel: string;
  invoice: IInvoice;
  practitioner: WithRef<IStaffer>;
  unclaimedItems: IResolvedClaimItem[];
  defaultSelectedNum?: number;
}

@Component({
  selector: 'pr-build-claim-dialog',
  templateUrl: './build-claim-dialog.component.html',
  styleUrls: ['./build-claim-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildClaimDialogComponent {
  trackByItem = TrackByFunctions.index<IHealthcareClaimItem>();
  options: IClaimOption[] = [];
  selected$ = new BehaviorSubject<IClaimOption[]>([]);
  providerData?: IProviderData;
  stafferImage$: Observable<string | undefined>;
  hasAllSelected$: Observable<boolean>;
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBuildHealthcareClaimRequest,
    public dialogRef: MatDialogRef<BuildClaimDialogComponent, IHealthcareClaim>,
    private _globalStore: GlobalStoreService
  ) {
    this.providerData = Staffer.getProviderData(
      this.data.practitioner,
      this.data.invoice.practice.ref
    );
    this.stafferImage$ = this._globalStore.getStafferImage$(
      this.data.practitioner
    );

    const singles = HealthcareClaimGenerator.toSingleQuantities(
      this.data.unclaimedItems.map((item) => item.claimItem)
    ).map((item) => resolveLineItem(this.data.invoice, item));

    this.options = toClaimableItems(singles).map((item, index) => ({
      id: `${index}`,
      item,
    }));
    this.selected$.next(
      take(this.options, data.defaultSelectedNum ?? MAX_CLAIMABLE_ITEMS)
    );
    this.hasAllSelected$ = this.selected$.pipe(
      map(() => this.options.every((option) => this.isSelected(option)))
    );

    this.isDisabled$ = this.selected$.pipe(
      map((selected) => !selected.length || !this.providerData)
    );
  }

  compareItemsFn(a: IClaimOption, b: IClaimOption): boolean {
    return a.id === b.id;
  }

  isSelected(option: IClaimOption): boolean {
    return this.selected$.value
      .map((selected) => selected.id)
      .includes(option.id);
  }

  isSelected$(option: IClaimOption): Observable<boolean> {
    return this.selected$.pipe(map(() => this.isSelected(option)));
  }

  updateSelected(change: MatSelectionListChange): void {
    const selected = change.source.selectedOptions.selected.map(
      (option) => option.value as IClaimOption
    );
    this.selected$.next(selected);
  }

  toggleAll(checked: boolean): void {
    const selected = checked ? this.options : [];
    this.selected$.next(selected);
  }

  submit(): void {
    const claim = this._toClaim(this.data.practitioner, this.selected$.value);
    this.dialogRef.close(claim);
  }

  private _toClaim(
    practitioner: WithRef<IStaffer>,
    selected: IClaimOption[]
  ): IHealthcareClaim {
    const provider = stafferToNamedDoc(practitioner);
    const items: IHealthcareClaimItem[] = selected
      .map((option) => option.item)
      .map((item) => ({
        label: `${item.treatment.description}: ${item.serviceCode.description}`,
        treatmentUid: item.treatment.uid,
        serviceCodeUid: item.serviceCode.uid,
        quantity: item.quantity,
        provider,
      }));
    return HealthcareClaim.init({
      practitioner: provider,
      providerData: Staffer.getProviderData(
        practitioner,
        this.data.invoice.practice.ref
      ),
      items: HealthcareClaimGenerator.joinSingleQuantities(items),
    });
  }
}
