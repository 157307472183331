import { InvoiceDisplayBloc } from '@principle-theorem/principle-core';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import { deleteDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { InvoiceActionService } from './components/invoice-display/invoice-action.service';

export class AccountInvoiceBloc extends InvoiceDisplayBloc {
  constructor(
    private _actions: InvoiceActionService,
    invoice$: Observable<WithRef<IInvoice>>
  ) {
    super(invoice$);
  }

  async amend(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.amend(invoice);
  }

  async balanceOverpaymentWithDeposit(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.balanceOverpaymentWithDeposit(invoice);
  }

  async cancel(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.cancel(invoice);
  }

  async issue(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.issue(invoice);
  }

  async openAmendmentHistory(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.openAmendmentHistory(invoice);
  }

  async openInteractionHistory(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.openInteractionHistory(invoice);
  }

  async openRelatedAppointments(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.openRelatedAppointments(invoice);
  }

  async print(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.print(invoice);
  }

  async sendAsSMS(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.sendAsSMS(invoice);
  }

  async sendAsEmail(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.sendAsEmail(invoice);
  }

  async writeOff(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.writeOff(invoice);
  }

  async replaceInvoice(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.replace(invoice);
  }

  async delete(): Promise<void> {
    try {
      const invoice = await snapshot(this.invoice$);
      const canDelete = await snapshot(this.canDelete$);
      if (!canDelete) {
        throw new Error(`Cannot Delete Invoice: ${invoice.ref.path}`);
      }
      await deleteDoc(invoice.ref);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async revertToIssued(): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    await this._actions.issue(invoice);
  }
}
