import { isArray } from 'lodash';
import { type KeySchema } from 'typesense/lib/Typesense/Key';
import { IntegrationStorage, IntegrationType } from './integrations';

export interface ITypesenseIntegrationKeyData extends KeySchema {
  value: string;
  clusterName?: string;
}

export type ITypesenseIntegrationData =
  | ITypesenseIntegrationKeyData
  | ITypesenseIntegrationKeyData[];

export class TypesenseIntegration extends IntegrationStorage<ITypesenseIntegrationData> {
  constructor() {
    super(IntegrationType.Typesense);
  }

  static getKey(
    data: ITypesenseIntegrationData,
    clusterName?: string
  ): string | undefined {
    return TypesenseIntegration.getClusterConfig(data, clusterName)?.value;
  }

  static getClusterConfig(
    data: ITypesenseIntegrationData,
    clusterName?: string
  ): ITypesenseIntegrationKeyData | undefined {
    if (isArray(data)) {
      return data.find(
        (credentials) => !!TypesenseIntegration.getKey(credentials, clusterName)
      );
    }

    if (!clusterName || data.clusterName === clusterName) {
      return data;
    }
  }
}
