import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatFormFieldAppearance,
  SubscriptSizing,
} from '@angular/material/form-field';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StaffSelectorStore } from './staff-selector.store';
import { type Permission } from '@principle-theorem/feature-flags';

@Component({
    selector: 'pr-staff-selector',
    templateUrl: './staff-selector.component.html',
    styleUrls: ['./staff-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StaffSelectorComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class StaffSelectorComponent implements ControlValueAccessor, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _changed$: Subject<WithRef<IStaffer> | undefined> = new Subject();
  private _touched$: Subject<void> = new Subject();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  staff$: Observable<WithRef<IStaffer>[]>;
  selectedCtrl = new TypedFormControl<WithRef<IStaffer> | undefined>();
  @Input() disabled = false;
  @Output() stafferSelected = new EventEmitter<WithRef<IStaffer> | undefined>();
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() label = 'Select staffer';
  @Input() showNone = false;
  @Input() showClear = true;
  practice$ = new BehaviorSubject<WithRef<IPractice> | undefined>(undefined);
  brand$ = new BehaviorSubject<WithRef<IBrand> | undefined>(undefined);
  permissions$ = new BehaviorSubject<Permission[]>([]);

  @Input()
  set brand(brand: WithRef<IBrand>) {
    this.brand$.next(brand ?? undefined);
  }

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      this._setDefault(staffer);
    }
  }

  @Input()
  set permissions(permissions: Permission[]) {
    if (permissions) {
      this.permissions$.next(permissions);
    }
  }

  constructor(store: StaffSelectorStore) {
    store.loadStaff(
      combineLatest([this.brand$, this.practice$, this.permissions$])
    );
    this.staff$ = store.staff$;

    this.selectedCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((selection) => {
        this.stafferSelected.emit(selection);
        this._changed$.next(selection);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(value: WithRef<IStaffer>, staffer: WithRef<IStaffer>): boolean {
    if (!value || !staffer) {
      return false;
    }
    return value.ref && staffer.ref ? isSameRef(value, staffer) : false;
  }

  setValue(staffer?: WithRef<IStaffer>): void {
    if (!staffer) {
      this.selectedCtrl.reset();
    }
    this._changed$.next(staffer ? staffer : undefined);
  }

  writeValue(value?: WithRef<IStaffer>): void {
    if (!value) {
      return;
    }
    this.selectedCtrl.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectedCtrl.disable();
      return;
    }
    this.selectedCtrl.enable();
  }

  clearStaffer(event: Event): void {
    event.stopPropagation();
    this.selectedCtrl.reset();
    this.selectedCtrl.markAsDirty();
    this.selectedCtrl.updateValueAndValidity();
  }

  private _setDefault(staffer: WithRef<IStaffer>): void {
    this.selectedCtrl.setValue(staffer, { emitEvent: false });
  }
}
