import { isString } from 'lodash';
import { isObject } from '../../common';
import { snapshot } from '../../rxjs';
import { httpsCallable } from './firebase-functions-scheduler';

export async function wakeUpFunction(functionName: string): Promise<void> {
  await snapshot(httpsCallable<IColdStartCheck>(functionName)(coldStartCheck));
}

const coldStartCheck: IColdStartCheck = {
  action: 'cold-start-check',
};

export interface IColdStartCheck {
  action: 'cold-start-check';
}

export function isColdStartCheck(item: unknown): item is IColdStartCheck {
  return (
    isObject(item) &&
    isString(item.action) &&
    item.action === 'cold-start-check'
  );
}
