import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';

@Injectable()
export class IsAuthorisedGuard extends AuthGuard {
  constructor(
    private _router: Router,
    private _organisation: OrganisationService,
    auth: AuthService
  ) {
    super(auth);
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._organisation.user$.pipe(
      map((user) => {
        if (user || state.url === '/') {
          return true;
        }
        return this._router.createUrlTree(['/']);
      })
    );
  }
}
