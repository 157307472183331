@if (notification$ | async; as notification) {
  <div
    class="notification flex flex-col gap-2 rounded-lg pb-1.5 drop-shadow-md"
  >
    <div class="bg-primary-500 w-full rounded-t-lg px-5 py-1">
      <pr-content-view class="!text-white" [content]="notification.title" />
    </div>
    <pr-content-view class="px-5" [content]="notification.content" />

    @if (notification.actions) {
      <div class="flex justify-end gap-4">
        @for (action of notification.actions; track action.label) {
          <pt-loader-button
            [color]="action.colour"
            [type]="action.style"
            (clicked)="action.actionFn()"
          >
            {{ action.label }}
          </pt-loader-button>
        }
      </div>
    }
  </div>
}
