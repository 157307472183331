import { Directive, Input } from '@angular/core';
import { type IMenuButton } from '@principle-theorem/editor';
import { ReplaySubject } from 'rxjs';
import { type MenuButtonLoaderFn } from '../menu-buttons';
import { EditorMenuItemComponent } from './editor-menu-item.component';

export interface ISubmenuButton extends IMenuButton {
  submenuItems: MenuButtonLoaderFn[];
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EditorSubmenuItemComponent extends EditorMenuItemComponent {
  command: () => {
    //
  };
  @Input() override buttonText = '';
  @Input() override tooltip = '';
  @Input() shortcut = '';
  @Input() icon = '';
  @Input()
  submenuItems$: ReplaySubject<MenuButtonLoaderFn[]> = new ReplaySubject(1);

  set submenuItems(submenuItems: MenuButtonLoaderFn[]) {
    if (submenuItems) {
      this.submenuItems$.next(submenuItems);
    }
  }
}
