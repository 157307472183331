import {
  IBrand,
  IPatientTokenData,
  IOrganisation,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, getParentDocRef, WithRef } from '@principle-theorem/shared';
import { createCSRF } from '@principle-theorem/temporary-tokens';
import { getTokenExpiryFromAppointment } from '../appointment/patient-confirm-appointment';
import { Organisation } from '../organisation/organisation';
import { Patient } from './patient';

export async function generatePatientMedicalHistoryToken(
  org: WithRef<IOrganisation>,
  patient: WithRef<IPatient>
): Promise<string> {
  const appointment = await Patient.getNextScheduledAppointment(patient);
  const tokenExpiry = await getTokenExpiryFromAppointment(appointment);
  const tokenData: IPatientTokenData = {
    patient: patient.ref,
  };
  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    tokenExpiry,
    true
  );
}

export async function generateMedicalHistoryUrl(
  patient: WithRef<IPatient>,
  appUrl: string
): Promise<string> {
  const brandRef = getParentDocRef<IBrand>(patient.ref.path);
  const orgRef = getParentDocRef<IOrganisation>(brandRef.path);
  const token = await generatePatientMedicalHistoryToken(
    await getDoc(orgRef),
    patient
  );

  return [appUrl, 'history', token].join('/');
}
