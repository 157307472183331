import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  ConnectedDialogCloseDirective,
  ConnectedDialogTitleDirective,
  ConnectedDialogActionsDirective,
  ConnectedDialogContentDirective,
} from './connected-dialog-content-directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    OverlayModule,
    PortalModule,
    A11yModule,
  ],
  declarations: [
    ConnectedDialogCloseDirective,
    ConnectedDialogTitleDirective,
    ConnectedDialogActionsDirective,
    ConnectedDialogContentDirective,
  ],
  exports: [
    ConnectedDialogCloseDirective,
    ConnectedDialogTitleDirective,
    ConnectedDialogActionsDirective,
    ConnectedDialogContentDirective,
  ],
})
export class ConnectedDialogModule {}
