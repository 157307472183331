import { type IMention } from '@principle-theorem/editor';
import { type DocumentReference } from '@principle-theorem/shared';
import { isObject } from '@principle-theorem/shared';

export function isDeprecatedMention(
  mention: IPrincipleMention | IMentionDeprecated
): mention is IMentionDeprecated {
  return !('resource' in mention);
}

/**
 * @deprecated
 */
export interface IMentionDeprecated {
  uid: string;
  key: string;
  ref: DocumentReference;
  resourceType: MentionResourceType;
}

export type IPrincipleMention = IMention<MentionResourceType>;

export interface IMentionable {
  mentions: (IPrincipleMention | IMentionDeprecated)[];
}

export interface IMentionContext {
  mention: IPrincipleMention;
  comment: string;
}

export enum MentionResourceType {
  Patient = 'patient',
  Staffer = 'staffer',
  Lab = 'lab',
  LabJob = 'labJob',
  Appointment = 'appointment',
  Task = 'task',
  Contact = 'contact',
  SubContact = 'subContact',
  Candidate = 'candidate',
  Team = 'team',
  Invoice = 'invoice',
  Gap = 'gap',
  Chat = 'chat',
  Form = 'form',
}

export const MENTION_RESOURCE_TYPES: MentionResourceType[] = [
  MentionResourceType.Patient,
  MentionResourceType.Staffer,
  MentionResourceType.Lab,
  MentionResourceType.LabJob,
  MentionResourceType.Appointment,
  MentionResourceType.Task,
  MentionResourceType.Contact,
  MentionResourceType.SubContact,
  MentionResourceType.Candidate,
  MentionResourceType.Team,
  MentionResourceType.Invoice,
  MentionResourceType.Gap,
  MentionResourceType.Chat,
  MentionResourceType.Form,
];

export function isPrincipleMention(data: unknown): data is IPrincipleMention {
  return isObject(data) && 'uid' in data && 'key' in data && 'resource' in data;
}
