import {
  IContextBuilder,
  ITemplateContext,
  IUser,
} from '@principle-theorem/principle-core/interfaces';

export interface IFormRequestTemplateContext extends ITemplateContext {
  recipientName: string;
  recipientEmail: string;
  formRequestLink: string;
}

export class FormRequestContextBuilder
  implements IContextBuilder<IFormRequestTemplateContext>
{
  constructor(private _user: IUser, private _link: string) {}

  build(): IFormRequestTemplateContext {
    return {
      recipientName: this._user.name,
      recipientEmail: this._user.email,
      formRequestLink: this._link,
    };
  }
}
