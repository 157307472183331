import {
  type IBrand,
  type IMultiTreatmentConfiguration,
  isStaffer,
  type IStaffer,
  type ITreatmentTemplate,
  TreatmentTemplateType,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  addDoc,
  getDoc,
  getParentDocRef,
  runTransaction,
  snapshot,
  updateDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { Brand } from '../../brand';
import { ConditionConfiguration } from '../conditions/condition-configuration';
import { MultiTreatmentConfiguration } from './multi-treatment-configuration';
import {
  type IMultiTreatmentConfigurationSeedData,
  buildMultiTreatmentConfigurationSeedData,
} from './multi-treatment-configuration-seed-data';
import { TreatmentConfiguration } from './treatment-configuration';
import { TreatmentTemplate } from './treatment-template';

async function getTreatmentTemplates(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<WithRef<ITreatmentTemplate>[]> {
  if (isStaffer(entity)) {
    const brand = await getDoc(getParentDocRef<IBrand>(entity.ref));
    return snapshot(Brand.treatmentTemplates$(brand));
  }
  return snapshot(Brand.treatmentTemplates$(entity));
}

export async function addMultiTreatmentConfigurations(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<void> {
  const treatmentTemplates = await getTreatmentTemplates(entity);
  const treatmentConfigurations = await snapshot(
    TreatmentConfiguration.all$(entity)
  );
  const conditionConfigurations = await snapshot(
    ConditionConfiguration.all$(entity)
  );
  const multiTreatmentConfigCol = MultiTreatmentConfiguration.col(entity);

  const configurationPromises: Promise<void>[] =
    buildMultiTreatmentConfigurationSeedData(
      treatmentConfigurations,
      treatmentTemplates,
      conditionConfigurations
    ).map(async (seedData) => {
      const multiTreatmentConfigRef = await addDoc(
        multiTreatmentConfigCol,
        seedData.config
      );
      if (isStaffer(entity)) {
        return updateImplementor(entity, seedData, multiTreatmentConfigRef);
      }
    });

  await Promise.all(configurationPromises);
}

async function updateImplementor(
  staffer: WithRef<IStaffer>,
  seedData: IMultiTreatmentConfigurationSeedData,
  multiTreatmentConfigRef: DocumentReference<IMultiTreatmentConfiguration>
): Promise<void> {
  if (!seedData.treatmentTemplate) {
    return;
  }

  const templateRef = seedData.treatmentTemplate.ref;
  const duration: number = await MultiTreatmentConfiguration.calculateDuration(
    seedData.config
  );

  await runTransaction(async (transaction) => {
    const treatmentTemplate = await getDoc(templateRef, transaction);
    TreatmentTemplate.updateImplementor(
      treatmentTemplate,
      staffer,
      {
        name: seedData.config.name,
        ref: multiTreatmentConfigRef,
        type: TreatmentTemplateType.MultiTreatment,
      },
      duration
    );
    await updateDoc(treatmentTemplate.ref, treatmentTemplate, transaction);
  });
}
