import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IMedia } from '@principle-theorem/principle-core/interfaces';
import { MediaDisplayItem } from '../../media-display-item';
import { WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-media-tile',
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaTileComponent extends MediaDisplayItem {
  @Input()
  set media(media: WithRef<IMedia>) {
    if (media) {
      this.media$.next(media);
      this.namedDocsToTags.namedDocs$.next(media.tags);
    }
  }
}
