import {
  DataStreamEvent,
  StorageResponseAPI,
  type IDataStreamEvent,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IOrganisation,
  type IPractice,
  type IQueryScopeRequests,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IQueryScope,
  type IReportingQuery,
  type IReportingQueryRequest,
} from '@principle-theorem/reporting';
import {
  snapshot,
  toTimestamp,
  type DocumentReference,
} from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';
import { from, type Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export class BigQueryDataSource<FactRow> {
  constructor(
    private _api: StorageResponseAPI,
    private _orgRef$: Observable<DocumentReference<IOrganisation>>
  ) {}

  async buildAllScopeRequests(
    brandRef$: Observable<DocumentReference<IBrand>>,
    practiceRefs$: Observable<DocumentReference<IPractice>[]>,
    fromDate: moment.Moment,
    toDate: moment.Moment,
    timestampTarget: string = 'timestamp.timestampValue'
  ): Promise<IQueryScopeRequests> {
    const brandRef = await snapshot(brandRef$);
    const practiceRefs = await snapshot(practiceRefs$);
    return {
      brand: { brandRef },
      practice: { practiceRefs },
      dateRange: {
        from: toTimestamp(fromDate),
        to: toTimestamp(toDate),
        queryableTimestamp: timestampTarget,
      },
    };
  }

  async build(
    query: IReportingQuery,
    scopeRequests: IQueryScopeRequests
  ): Promise<FactRow[]> {
    try {
      const response$ = this.get$(query, scopeRequests).pipe(
        filter(DataStreamEvent.isComplete),
        map((event) => event.value)
      );
      return await snapshot(response$);
    } catch (error) {
      return [];
    }
  }

  get$(
    query: IReportingQuery,
    scopeRequests: IQueryScopeRequests
  ): Observable<IDataStreamEvent<FactRow[]>> {
    const fiveMinutes = 300000;
    return from(this._getQueryScope(scopeRequests)).pipe(
      map((scope) => ({ scope, query })),
      switchMap((request) =>
        this._api.get$<IReportingQueryRequest, FactRow[]>(
          'http-reporting-queryFn',
          request,
          {
            timeout: fiveMinutes,
          }
        )
      )
    );
  }

  private async _getQueryScope(
    scopeRequests: IQueryScopeRequests
  ): Promise<IQueryScope> {
    return {
      orgRef: await snapshot(this._orgRef$),
      scopeRequests,
    };
  }
}
