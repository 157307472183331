import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { SourceEntity } from '../../../source/source-entity';
import { ADAItemSourceEntity } from './ada-item';

export const PATIENT_NOTE_ITEM_CODE_TREATMENT_RESOURCE_TYPE =
  'noteItemCodeTreatments';

export const PATIENT_NOTE_ITEM_CODE_TREATMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Item Codes with Notes',
      description: '',
      idPrefix: PATIENT_NOTE_ITEM_CODE_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

const TREATMENT_SOURCE_QUERY = `
SELECT
  DISTINCT(servicecode) AS item_code,
  servicedescription AS description,
  DENSE_RANK() OVER (results) AS id
FROM convtreatment
WHERE
  servicecode != '' AND
  notes != '' AND
  notes != servicedescription
WINDOW results AS (ORDER BY servicecode)
ORDER BY id
  `;

export class NoteItemCodeTreatmentSourceEntity extends ADAItemSourceEntity {
  override sourceEntity = PATIENT_NOTE_ITEM_CODE_TREATMENT_SOURCE_ENTITY;
  override entityResourceType = PATIENT_NOTE_ITEM_CODE_TREATMENT_RESOURCE_TYPE;
  override sourceQuery = TREATMENT_SOURCE_QUERY;
}
