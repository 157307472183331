import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pr-google-analytics-tracking-code-help',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './google-analytics-tracking-code-help.component.html',
  styleUrl: './google-analytics-tracking-code-help.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAnalyticsTrackingCodeHelpComponent {}
