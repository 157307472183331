import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_ALLERGY_OPTIONS_RESOURCE_TYPE = 'patientAllergyOptions';

export const PATIENT_ALLERGY_OPTIONS_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Allergy Options',
      description: '',
      idPrefix: PATIENT_ALLERGY_OPTIONS_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientAllergyOptions {
  description: string; // "Codeine"
}

export function isCorePracticePatientAllergyOption(
  item: unknown
): item is ICorePracticePatientAllergyOptions {
  return TypeGuard.interface<ICorePracticePatientAllergyOptions>({
    description: isString,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientAllergyOptionsTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientAllergyOptionsFilters {}

const PATIENT_ALLERGY_OPTIONS_SOURCE_QUERY = `
SELECT
  Description AS description
FROM tblPatientAllergy
WHERE description IS NOT NULL
GROUP BY Description
`;

export class PatientAllergyOptionsSourceEntity extends BaseSourceEntity<
  ICorePracticePatientAllergyOptions,
  ICorePracticePatientAllergyOptionsTranslations,
  ICorePracticePatientAllergyOptionsFilters
> {
  sourceEntity = PATIENT_ALLERGY_OPTIONS_SOURCE_ENTITY;
  entityResourceType = PATIENT_ALLERGY_OPTIONS_RESOURCE_TYPE;
  sourceQuery = PATIENT_ALLERGY_OPTIONS_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientAllergyOption;

  translate(
    _data: ICorePracticePatientAllergyOptions,
    _timezone: Timezone
  ): ICorePracticePatientAllergyOptionsTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientAllergyOptions): string {
    return data.description;
  }

  getSourceLabel(data: ICorePracticePatientAllergyOptions): string {
    return data.description;
  }

  getFilterData(
    _data: ICorePracticePatientAllergyOptions,
    _timezone: Timezone
  ): ICorePracticePatientAllergyOptionsFilters {
    return {};
  }
}
