import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum CalendarPermissions {
  CalendarManage = 'calendar.manage',
}

export const CALENDAR_FEATURE: IFeature = {
  label: 'calendars',
  visibility: FeatureVisibility.Stable,
  permissions: [],
};
