<mat-toolbar color="accent" class="min-h-[50px]">
  {{ isEdit ? 'Edit' : 'Add' }} Automated Notification
</mat-toolbar>

<div class="flex flex-col overflow-scroll bg-slate-100">
  <form [formGroup]="form" class="flex flex-col gap-4 p-4">
    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Settings</h3>

      <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>

    <h2 class="!m-0 !mt-4">When do you want this to run?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Timing</h3>

      @if (data.useRelativeTime) {
        <pr-automation-timing-form
          [value]="form.controls.timing.value"
          (formChange)="timingChange($event)"
        />
      } @else {
        <div class="flex flex-row gap-4">
          <mat-form-field fxFlex subscriptSizing="dynamic">
            <mat-label>Trigger Date</mat-label>
            <input
              matInput
              formControlName="triggerDate"
              required
              [matDatepicker]="triggerDate"
              (focus)="triggerDate.open()"
              (click)="triggerDate.open()"
            />
            <mat-datepicker-toggle matIconSuffix [for]="triggerDate" />
            <mat-datepicker #triggerDate />
            <button
              mat-icon-button
              matIconSuffix
              aria-label="Clear"
              *ngIf="form.controls.triggerDate.value"
              type="button"
              (click)="clearTriggerDate($event)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <pr-date-selector
            fxFlex
            placeholder="Trigger Time"
            [required]="true"
            [interval]="formHelpers.timeInterval"
            formControlName="triggerTime"
            subscriptSizing="dynamic"
          />
        </div>
      }
    </div>

    @if (!noScope && availableConditions.length) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <div class="flex flex-row items-center gap-2">
          <h3 class="!m-0 !font-bold">Automation Conditions</h3>
          <mat-icon
            class="icon-sm opacity-50"
            [matTooltip]="automationConditionsHelp"
            >info</mat-icon
          >
        </div>
        <pr-conditional-logic-form
          [availableConditions]="availableConditions"
          [defaultCondition]="defaultCondition"
          [conditions]="form.value.requiredConditions"
          (conditionsUpdated)="requiredConditionsChange($event)"
        />
      </div>
    }

    <h2 class="!m-0 !mt-4">What do you want to happen?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <div class="flex flex-row items-center gap-2">
        <h3 class="!m-0 !font-bold">Custom Configuration</h3>
        <mat-icon
          class="icon-sm opacity-50"
          [matTooltip]="customConfigurationHelp"
          >info</mat-icon
        >
        <div class="flex flex-auto"></div>
        <mat-slide-toggle
          [checked]="useCustomTemplate$.value"
          (change)="useCustomTemplate$.next($event.checked)"
        />
      </div>
      @if (useCustomTemplate$ | async) {
        <div class="flex flex-row gap-4">
          <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
            <mat-label>Action</mat-label>
            <mat-select formControlName="type">
              @for (type of formHelpers.types; track type.value) {
                <mat-option [value]="type.value">
                  {{ type.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          @if (form.showSubjectField()) {
            <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
              <mat-label>Subject</mat-label>
              <input matInput formControlName="subject" />
            </mat-form-field>
          }
        </div>
        <ng-container formGroupName="customTemplate">
          <pr-template-editor-textarea
            formControlName="content"
            [required]="true"
            [context]="templateContext$ | async"
            [templateType]="templateType$ | async"
          />
        </ng-container>
      }
    </div>

    @if (config$ | async; as config) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <h3 class="!m-0 !font-bold">
          Automation Configuration: &#39;{{ config.name }}&#39;
        </h3>

        @if (useCustomTemplate$ | async) {
          <pr-information-box>
            This has been replaced by the Custom Configuration above.
            <button mat-stroked-button (click)="useCustomTemplate$.next(false)">
              Use this Configuration
            </button>
          </pr-information-box>
        }

        <pr-automated-notification-config-preview
          [config]="config"
          [scopeData]="scopeData$ | async"
        />

        @if (useCustomTemplate$ | async) {
          <div></div>
        }
      </div>
    }
  </form>
</div>

<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      Submit
    </button>
  </pt-buttons-container>
</pt-page-footer>
