import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  ChartedCondition,
  ChartedSurface,
  ClinicalChart,
  ClinicalNote,
  ConditionConfiguration,
  ConditionConfigurationName,
  ExistingTreatmentConfigurationName,
  MockAllTeeth,
  TreatmentConfiguration,
  TreatmentPlanProposal,
  getQuadrant,
  getQuadrantIndex,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  IChartedCondition,
  ITranslationMap,
  PerioDataPoint,
  PerioMeasurement,
  Quadrant,
  type FailedDestinationEntityRecord,
  type IClinicalChart,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPerioRecord,
  type IPracticeMigration,
  type IStaffer,
  type IToothRef,
  type ITreatmentConfiguration,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IReffable,
  Timezone,
  asyncForEach,
  find$,
  getDoc$,
  getError,
  multiFilter,
  snapshot,
  toISODate,
  toInt,
  toMoment,
  toTimestamp,
  where,
  type DocumentReference,
  type IIdentifiable,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, groupBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getChartedRefs } from '../../../d4w/destination/entities/patient-treatment-plan';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientClinicalChartDestinationEntity,
  IBaseClinicalChartJobData,
  IClinicalChartMigrationData,
  IGeneratedCharts,
} from '../../../destination/entities/patient-clinical-charts';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointments';
import {
  CORE_PRACTICE_CONDITION_MAP,
  CorePracticeMissingToothTypeItems,
  CorePracticePatientChartedItemType,
  ICorePracticePatientChartedItem,
  ICorePracticePatientChartedItemFilters,
  ICorePracticePatientChartedItemTranslations,
  PatientChartedItemSourceEntity,
} from '../../source/entities/patient-charted-items';
import {
  CORE_PACTICE_PRIODONTAL_TYPE_MAP,
  ICorePracticePatientPeriodontalChartItem,
  ICorePracticePatientPeriodontalChartItemFilters,
  ICorePracticePatientPeriodontalChartItemTranslations,
  PatientPeriodontalChartItemSourceEntity,
  PeriodontalRowType,
} from '../../source/entities/patient-periodontal-chart-items';
import {
  ICorePracticePatientPeriodontalChart,
  ICorePracticePatientPeriodontalChartFilters,
  ICorePracticePatientPeriodontalChartTranslations,
  PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE,
  PatientPeriodontalChartSourceEntity,
} from '../../source/entities/patient-periodontal-charts';
import {
  ICorePracticePatient,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { CorePracticeItemCodeMappingHandler } from '../mappings/item-codes';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_CLINICAL_CHART_CUSTOM_MAPPING_TYPE =
  'patientClinicalChart';

export const PATIENT_CLINICAL_CHART_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: PATIENT_CLINICAL_CHART_CUSTOM_MAPPING_TYPE,
      label: 'Patient Clinical Charts',
      description: `The conversion will be done as follows:
        Each Periodontal Chart will be added as a separate clinical chart.

        A "latest" chart will be added to include all:
          - Dentition Records
          - Clinical Notes
          - Conditions
          - Flagged Treatments

        This acts as the baseline chart going forward in Principle. All of the most latest information will be on this chart. The dates for when notes, conditions, and treatments were added will be mirrored.

        Some things to be aware of:

        If no practitioner is available for the following, the preferred practitioner for the patient has been used:
          - Conditions
          - Flagged Treatments
      `,
    },
  }
);

export interface IClinicalChartJobData
  extends IBaseClinicalChartJobData<ICorePracticePatient> {
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
}

export class PatientClinicalChartDestinationEntity extends BasePatientClinicalChartDestinationEntity<
  ICorePracticePatient,
  IClinicalChartJobData
> {
  destinationEntity = PATIENT_CLINICAL_CHART_DESTINATION_ENTITY;
  periodontalResourceType = PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    periodontalChart: new PatientPeriodontalChartSourceEntity(),
    periodontalChartItems: new PatientPeriodontalChartItemSourceEntity(),
    chartedItems: new PatientChartedItemSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    itemCodes: new CorePracticeItemCodeMappingHandler(),
    itemCodeToTreatment: new CorePracticeItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IClinicalChartJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.id >= toInt(fromId) &&
            patient.data.data.id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          defaultTreatmentConfiguration$,
          treatmentConfigurations$,
          sourceItemCodes$,
          treatmentConfigurationMappings$
        ),
        map(
          ([
            sourcePatients,
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              staff,
              sourcePatient,
              defaultTreatmentConfiguration,
              treatmentConfigurations,
              sourceItemCodes,
              treatmentConfigurationMappings,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<
    | IClinicalChartMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    const defaultProviderMap = data.staff.find(
      (staffer) => staffer.sourceIdentifier === '0'
    );

    if (!defaultProviderMap?.destinationIdentifier) {
      return this._buildErrorResponse(
        errorResponseData,
        `No provider for patient. Set default provider in custom mapping with id: 0`
      );
    }

    try {
      const charts = await this._buildClinicalChartData(
        migration,
        translationMap,
        defaultProviderMap.destinationIdentifier,
        sourcePatientId,
        data
      );

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        charts,
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  private async _buildClinicalChartData(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    practitionerRef: DocumentReference<IStaffer>,
    patientUid: number,
    data: IClinicalChartJobData
  ): Promise<IGeneratedCharts> {
    const practitioner = await Firestore.getDoc(practitionerRef);

    const sourcePerioCharts =
      await this.sourceEntities.periodontalChart.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const sourcePerioChartItems =
      await this.sourceEntities.periodontalChartItems.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const perioClinicalCharts = buildPerioCharts(
      sourcePerioCharts,
      sourcePerioChartItems
    );

    const chartedItems = await this.sourceEntities.chartedItems.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const clinicalCharts = await buildClinicalCharts(
      practitioner,
      migration,
      translationMap,
      chartedItems,
      data
    );

    return {
      clinicalCharts: compact(clinicalCharts),
      perioCharts: compact(perioClinicalCharts),
    };
  }
}

function buildPerioCharts(
  sourcePerioCharts: IGetRecordResponse<
    ICorePracticePatientPeriodontalChart,
    ICorePracticePatientPeriodontalChartTranslations,
    ICorePracticePatientPeriodontalChartFilters
  >[],
  sourcePerioChartItems: IGetRecordResponse<
    ICorePracticePatientPeriodontalChartItem,
    ICorePracticePatientPeriodontalChartItemTranslations,
    ICorePracticePatientPeriodontalChartItemFilters
  >[]
): (IClinicalChart & IIdentifiable)[] {
  return sourcePerioCharts.map((sourcePerioChart) => {
    const uid = sourcePerioChart.data.data.id.toString();
    const createdAt = toTimestamp(
      moment.utc(sourcePerioChart.data.data.chartDate)
    );
    const perioChartItems = compact(
      sourcePerioChartItems
        .filter(
          (sourcePerioChartItem) =>
            sourcePerioChart.data.data.id ===
            sourcePerioChartItem.data.data.periodontalChartId
        )
        .map((sourcePerioChartItem) => sourcePerioChartItem.data.data)
    );

    const perioRecords = buildPerioRecords(perioChartItems);

    return {
      ...ClinicalChart.init({
        perioRecords,
        teeth: MockAllTeeth(),
        createdAt,
        createdBy: undefined,
        immutable: true,
      }),
      uid,
    };
  });
}

async function buildClinicalCharts(
  practitioner: WithRef<IStaffer>,
  migration: WithRef<IPracticeMigration>,
  _translationMap: TranslationMapHandler,
  chartedItems: IGetRecordResponse<
    ICorePracticePatientChartedItem,
    ICorePracticePatientChartedItemTranslations,
    ICorePracticePatientChartedItemFilters
  >[],
  data: IClinicalChartJobData
): Promise<(IClinicalChart & IIdentifiable)[]> {
  const missingTeeth = await CorePracticeToothBuilders.buildMissingTeeth(
    chartedItems
      .filter(
        (chartedItem) =>
          chartedItem.data.data.type ===
            CorePracticePatientChartedItemType.MissingTooth &&
          chartedItem.data.data.diagnosis ===
            CorePracticeMissingToothTypeItems.MissingTooth
      )
      .map((chartedItem) => chartedItem.data.data),
    practitioner,
    migration.configuration.brand,
    migration.configuration.timezone
  );

  const sourceConditions = chartedItems
    .filter(
      (chartedItem) =>
        chartedItem.data.data.type ===
        CorePracticePatientChartedItemType.PreExistingTreatment
    )
    .map((chartedItem) => chartedItem.data.data);

  const conditions = compact(
    await asyncForEach(sourceConditions, async (chartedItem) => {
      if (!chartedItem.diagnosis) {
        return;
      }

      const conditionMap = CORE_PRACTICE_CONDITION_MAP[chartedItem.diagnosis];
      if (!conditionMap) {
        return;
      }

      return CorePracticeToothBuilders.buildConditionFromTeeth(
        [chartedItem],
        practitioner,
        migration.configuration.brand,
        migration.configuration.timezone,
        conditionMap
      );
    })
  ).flat();

  return [
    {
      uid: data.sourcePatient.record.uid,
      ...ClinicalChart.init({
        conditions: [...missingTeeth, ...conditions],
        teeth: MockAllTeeth(),
        flaggedTreatment: TreatmentPlanProposal.init(),
        createdAt: toTimestamp(toMoment(migration.configuration.backupDate)),
        createdBy: stafferToNamedDoc(practitioner),
      }),
    },
  ];
}

export function buildToothRef(
  toothNumber: ToothNumber | null
): IToothRef | undefined {
  const quadrant = toothNumber ? getQuadrant(toothNumber) : undefined;
  const quadrantIndex = toothNumber ? getQuadrantIndex(toothNumber) : undefined;

  if (!quadrant || !quadrantIndex) {
    return;
  }
  return {
    quadrant,
    quadrantIndex,
  };
}

export function buildPerioRecords(
  perioChartItems: ICorePracticePatientPeriodontalChartItem[]
): IPerioRecord[] {
  const teethResults = groupBy(perioChartItems, (item) => item.tooth);

  return compact(
    Object.values(teethResults).map((toothPerioChartItems) => {
      const toothData = toothPerioChartItems[0];
      const toothRef = buildToothRef(toothData.tooth);
      if (!toothRef) {
        return;
      }

      return {
        toothRef,
        data: {
          [PerioMeasurement.Mobility]: determinePerioValue(
            toothPerioChartItems,
            PerioMeasurement.Mobility,
            PerioDataPoint.FacialDistal,
            toothRef
          ),
          [PerioMeasurement.Recession]: {
            facialMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.FacialMesial,
              toothRef
            ),
            facialCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.FacialCentral,
              toothRef
            ),
            facialDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.FacialDistal,
              toothRef
            ),
            palatalMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.PalatalMesial,
              toothRef
            ),
            palatalCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.PalatalCentral,
              toothRef
            ),
            palatalDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Recession,
              PerioDataPoint.PalatalDistal,
              toothRef
            ),
          },
          [PerioMeasurement.Pocket]: {
            facialMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.FacialMesial,
              toothRef
            ),
            facialCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.FacialCentral,
              toothRef
            ),
            facialDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.FacialDistal,
              toothRef
            ),
            palatalMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.PalatalMesial,
              toothRef
            ),
            palatalCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.PalatalCentral,
              toothRef
            ),
            palatalDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Pocket,
              PerioDataPoint.PalatalDistal,
              toothRef
            ),
          },
          [PerioMeasurement.Bleeding]: {
            facialMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.FacialMesial,
              toothRef
            ),
            facialCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.FacialCentral,
              toothRef
            ),
            facialDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.FacialDistal,
              toothRef
            ),
            palatalMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.PalatalMesial,
              toothRef
            ),
            palatalCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.PalatalCentral,
              toothRef
            ),
            palatalDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Bleeding,
              PerioDataPoint.PalatalDistal,
              toothRef
            ),
          },
          [PerioMeasurement.Furcation]: {
            facialMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.FacialMesial,
              toothRef
            ),
            facialCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.FacialCentral,
              toothRef
            ),
            facialDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.FacialDistal,
              toothRef
            ),
            palatalMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.PalatalMesial,
              toothRef
            ),
            palatalCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.PalatalCentral,
              toothRef
            ),
            palatalDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Furcation,
              PerioDataPoint.PalatalDistal,
              toothRef
            ),
          },
          [PerioMeasurement.Suppuration]: {
            facialMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.FacialMesial,
              toothRef
            ),
            facialCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.FacialCentral,
              toothRef
            ),
            facialDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.FacialDistal,
              toothRef
            ),
            palatalMesial: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.PalatalMesial,
              toothRef
            ),
            palatalCentral: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.PalatalCentral,
              toothRef
            ),
            palatalDistal: determinePerioValue(
              toothPerioChartItems,
              PerioMeasurement.Suppuration,
              PerioDataPoint.PalatalDistal,
              toothRef
            ),
          },
        },
      };
    })
  );
}

export function determinePerioValue(
  records: ICorePracticePatientPeriodontalChartItem[],
  measurement: PerioMeasurement,
  measurementPoint: PerioDataPoint,
  toothRef: IToothRef
): number {
  const rightSectionDataPointMap: Record<PerioDataPoint, number> = {
    [PerioDataPoint.FacialDistal]: 1,
    [PerioDataPoint.FacialCentral]: 2,
    [PerioDataPoint.FacialMesial]: 3,
    [PerioDataPoint.PalatalDistal]: 4,
    [PerioDataPoint.PalatalCentral]: 5,
    [PerioDataPoint.PalatalMesial]: 6,
  };

  const leftSectionDataPointMap: Record<PerioDataPoint, number> = {
    [PerioDataPoint.FacialMesial]: 1,
    [PerioDataPoint.FacialCentral]: 2,
    [PerioDataPoint.FacialDistal]: 3,
    [PerioDataPoint.PalatalMesial]: 4,
    [PerioDataPoint.PalatalCentral]: 5,
    [PerioDataPoint.PalatalDistal]: 6,
  };

  const matchingRecord = records.find((record) => {
    const perioMeasurementType = CORE_PACTICE_PRIODONTAL_TYPE_MAP[record.type];

    if (!perioMeasurementType || perioMeasurementType !== measurement) {
      return false;
    }

    const dataPointMapNumber =
      record.periodontalRow === PeriodontalRowType.Buccal
        ? record.periodontalColumn
        : record.periodontalColumn + 3;

    if (
      measurement === PerioMeasurement.Mobility &&
      perioMeasurementType === PerioMeasurement.Mobility
    ) {
      if (record.periodontalRow === PeriodontalRowType.Palatal) {
        return false;
      }
      return record.periodontalColumn === 1;
    }

    const dataPointMap = [
      Quadrant.AdultUpperRight,
      Quadrant.AdultLowerRight,
      Quadrant.DeciduousUpperRight,
      Quadrant.DeciduousLowerRight,
    ].includes(toothRef.quadrant)
      ? rightSectionDataPointMap
      : leftSectionDataPointMap;

    const isMatchingDataPoint =
      dataPointMap[measurementPoint] === dataPointMapNumber;

    if (!isMatchingDataPoint) {
      return false;
    }

    return true;
  });

  if (!matchingRecord) {
    return 0;
  }

  return matchingRecord.value;
}

export class CorePracticeToothBuilders {
  static async buildMissingTeeth(
    chartedItems: ICorePracticePatientChartedItem[],
    practitioner: WithRef<IStaffer>,
    brand: IReffable<IBrand>,
    timezone: Timezone
  ): Promise<IChartedCondition[]> {
    return CorePracticeToothBuilders.buildConditionFromTeeth(
      chartedItems,
      practitioner,
      brand,
      timezone,
      ConditionConfigurationName.MissingTooth
    );
  }

  static async buildConditionFromTeeth(
    chartedItems: ICorePracticePatientChartedItem[],
    practitioner: WithRef<IStaffer>,
    brand: IReffable<IBrand>,
    timezone: Timezone,
    configurationName:
      | ConditionConfigurationName
      | ExistingTreatmentConfigurationName
  ): Promise<IChartedCondition[]> {
    const missingToothCondition = await snapshot(
      find$(
        ConditionConfiguration.col(brand),
        where('name', '==', configurationName)
      )
    );

    if (!missingToothCondition) {
      throw new Error(`Missing tooth condition is missing`);
    }

    return compact(
      chartedItems.map((chartedItem) => {
        const createdAt = toTimestamp(
          moment.utc(chartedItem.date).tz(timezone)
        );
        const notes = chartedItem.note
          ? [
              ClinicalNote.init({
                owner: stafferToNamedDoc(practitioner),
                content: initVersionedSchema(chartedItem.note),
                recordDate: toISODate(createdAt),
                createdAt: createdAt,
                updatedAt: createdAt,
              }),
            ]
          : [];

        return {
          ...ChartedCondition.fromConfig(missingToothCondition),
          chartedSurfaces: getChartedRefs(
            chartedItem.tooth,
            chartedItem.surfaces
          ).map((chartedRef) =>
            ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              chartedAt: toTimestamp(),
              chartedRef,
            })
          ),
          notes,
        };
      })
    );
  }
}
