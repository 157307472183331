import { Money } from '@principle-theorem/accounting';
import {
  IAllocationSummary,
  ICustomLineItem,
  IInvoice,
  IInvoiceTransactionAllocations,
  INamedAllocationTarget,
  IStaffer,
  ITransaction,
  ITransactionAllocation,
  SpecialTransactionAllocationTarget,
  TransactionAllocationTarget,
  TransactionProvider,
  isDiscountExtendedData,
  isTreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  sortByCreatedAt,
} from '@principle-theorem/shared';
import * as Dinero from 'dinero.js';
import { compact, max, sum, uniqWith, zip } from 'lodash';
import { OrganisationCache } from '../organisation/organisation-cache';
import { determineTransactionSign } from '../transaction/transaction';
import { TransactionOperators } from '../transaction/transaction-operators';
import { AllocationTarget } from './allocations/allocation-target';
import {
  AmountAllocation,
  IAmountAllocation,
} from './allocations/amount-allocation';
import { Invoice } from './invoice';

export const UNALLOCATED: INamedAllocationTarget = {
  name: 'Unallocated',
  ref: SpecialTransactionAllocationTarget.Unallocated,
};

export interface ILineItemAllocation extends IAmountAllocation {
  lineItem: ICustomLineItem;
  lineItemParent?: ICustomLineItem;
}

export class TransactionAllocation {
  static getTransactionAllocatedTo(
    transaction: ITransaction<unknown>
  ): DocumentReference<IStaffer> | undefined {
    if (
      transaction.provider === TransactionProvider.Discount &&
      isDiscountExtendedData(transaction.extendedData)
    ) {
      return transaction.extendedData.practitionerRef;
    }
  }

  static getAllocatedAmount(
    alreadyAllocated: IAmountAllocation[],
    allocationTarget: TransactionAllocationTarget
  ): number {
    const allocated = alreadyAllocated.filter((allocation) =>
      AllocationTarget.isSame(allocation.allocatedTo, allocationTarget)
    );
    return sum(allocated.map((allocation) => Money.amount(allocation.amount)));
  }

  static isAllocatedTo(
    allocation: ITransactionAllocation,
    allocatedTo: TransactionAllocationTarget
  ): boolean {
    return AllocationTarget.isSame(allocation.allocatedTo, allocatedTo);
  }

  static async toNamedAllocationTarget(
    allocationTarget: TransactionAllocationTarget
  ): Promise<INamedAllocationTarget> {
    if (AllocationTarget.isUnallocated(allocationTarget)) {
      return UNALLOCATED;
    }
    const staffer = await OrganisationCache.staff.get.getDoc(allocationTarget);
    const user = await OrganisationCache.users.get.getDoc(staffer.user.ref);
    return {
      name: user.name,
      ref: allocationTarget,
    };
  }

  static getInvoiceAllocations(
    invoice: IInvoice,
    allTransactions: WithRef<ITransaction<unknown>>[]
  ): IInvoiceTransactionAllocations[] {
    const transactions = new TransactionOperators(allTransactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse();

    const allocatedTransactions = transactions.filter(
      (transaction) =>
        !!TransactionAllocation.getTransactionAllocatedTo(transaction)
    );
    const sharedTransactions = transactions.filter(
      (transaction) =>
        !TransactionAllocation.getTransactionAllocatedTo(transaction)
    );
    const orderedTransactions = [
      ...allocatedTransactions.result(),
      ...sharedTransactions.result(),
    ];

    return orderedTransactions.reduce(
      (
        transactionAllocations: IInvoiceTransactionAllocations[],
        transaction
      ) => {
        const alreadyAllocated = transactionAllocations
          .map((transactionAllocation) => transactionAllocation.allocations)
          .flat();
        const allocations = this.getTransactionAllocations(
          invoice,
          transaction,
          alreadyAllocated
        );
        return [
          ...transactionAllocations,
          { transactionRef: transaction.ref, allocations },
        ];
      },
      []
    );
  }

  static getTransactionAllocations(
    invoice: IInvoice,
    transaction: ITransaction<unknown>,
    transactionAllocations: ITransactionAllocation[]
  ): ITransactionAllocation[] {
    const transactionAmount = Money.from(
      determineTransactionSign(transaction.type, transaction.amount)
    );
    const allocatedTo = this.getTransactionAllocatedTo(transaction);
    const invoicedAmounts = this.getInvoicedAmounts(invoice);
    const alreadyAllocated = this.toAmountAllocations(transactionAllocations);
    const allocations = this.allocateTransaction(
      invoicedAmounts,
      alreadyAllocated,
      transactionAmount,
      allocatedTo
    );
    return this.toTransactionAllocations(allocations, transactionAmount);
  }

  static allocateTransaction(
    invoicedAmounts: IAmountAllocation[],
    alreadyAllocated: IAmountAllocation[],
    transactionAmount: Dinero.Dinero,
    allocatedTo?: DocumentReference<IStaffer>
  ): IAmountAllocation[] {
    if (transactionAmount.isZero()) {
      return [];
    }
    if (allocatedTo) {
      return this.allocateAssignedTransaction(transactionAmount, allocatedTo);
    }
    if (transactionAmount.isNegative()) {
      return this.allocateOutgoingTransaction(
        invoicedAmounts,
        alreadyAllocated,
        transactionAmount
      );
    }
    return this.allocateIncomingTransaction(
      invoicedAmounts,
      alreadyAllocated,
      transactionAmount
    );
  }

  static allocateAssignedTransaction(
    transactionAmount: Dinero.Dinero,
    allocatedTo: DocumentReference<IStaffer>
  ): IAmountAllocation[] {
    return [AmountAllocation.create(allocatedTo, transactionAmount)];
  }

  /**
   * Because overpayments get assigned to "unallocated" we need to refund them
   * from "unallocated" before assigning the remaining refund amount.
   * This builds the portion of the this transaction that should be taken from
   * what has already assigned to "unallocated", essentially removing any
   * assigned overpayments.
   */
  static allocateRefundsToUnallocatedOverpayments(
    invoicedAmounts: IAmountAllocation[],
    preExistingAllocations: IAmountAllocation[],
    amount: Dinero.Dinero
  ): IAmountAllocation[] {
    if (amount.isZero() || amount.isPositive()) {
      return [];
    }
    const overAllocations = this.getOverpaymentAllocations(
      invoicedAmounts,
      preExistingAllocations
    );
    const unallocatedOverpayment = overAllocations.find((overAllocation) =>
      AllocationTarget.isUnallocated(overAllocation.allocatedTo)
    );
    if (!unallocatedOverpayment) {
      return [];
    }
    const assignableAmount = max([
      -Money.amount(unallocatedOverpayment.amount),
      Money.amount(amount),
    ]);
    const unallocated = AmountAllocation.create(
      SpecialTransactionAllocationTarget.Unallocated,
      assignableAmount
    );
    // We assign general overpayments to unallocated, so we attempt to refund
    // those overpayments first. Any other overpayments were manually assigned
    // by the user and so we do not touch them.
    return AmountAllocation.omitEmpty([unallocated]);
  }

  static getOverpaymentAllocations(
    invoicedAmounts: IAmountAllocation[],
    receivedAmounts: IAmountAllocation[]
  ): IAmountAllocation[] {
    const overAllocations = AmountAllocation.subtract(
      invoicedAmounts,
      receivedAmounts
    ).map((owed) => {
      const overpaymentValue =
        Money.amount(owed.amount) < 0 ? Math.abs(Money.amount(owed.amount)) : 0;
      return AmountAllocation.create(owed.allocatedTo, overpaymentValue);
    });
    return AmountAllocation.omitEmpty(overAllocations);
  }

  static allocateIncomingTransaction(
    invoicedAmounts: IAmountAllocation[],
    alreadyAllocated: IAmountAllocation[],
    amount: Dinero.Dinero
  ): IAmountAllocation[] {
    if (amount.isZero() || amount.isNegative()) {
      return [];
    }
    const remainingOwed = AmountAllocation.subtract(
      invoicedAmounts,
      alreadyAllocated
    );
    return this.allocateBasedOnOwed(amount, remainingOwed);
  }

  static allocateOutgoingTransaction(
    invoicedAmounts: IAmountAllocation[],
    alreadyAllocated: IAmountAllocation[],
    amount: Dinero.Dinero
  ): IAmountAllocation[] {
    if (amount.isZero() || amount.isPositive()) {
      return [];
    }
    const overpaymentAllocations =
      this.allocateRefundsToUnallocatedOverpayments(
        invoicedAmounts,
        alreadyAllocated,
        amount
      );
    const remainingAmount = this.getRemainingAmount(
      amount,
      overpaymentAllocations
    );

    const allocations = this.allocatedBasedOnInvoiced(
      remainingAmount,
      invoicedAmounts
    );
    return AmountAllocation.add(overpaymentAllocations, allocations);
  }

  static allocatedBasedOnInvoiced(
    amount: Dinero.Dinero,
    invoicedAmounts: IAmountAllocation[]
  ): IAmountAllocation[] {
    const proportions = invoicedAmounts.map((invoiced) =>
      Money.amount(invoiced.amount)
    );
    const allocatedAmounts = Money.allocate(amount, proportions);

    return zip(invoicedAmounts, allocatedAmounts).map(
      ([invoiced, allocatedAmount]) =>
        AmountAllocation.create(invoiced?.allocatedTo, allocatedAmount)
    );
  }

  static allocateBasedOnOwed(
    amount: Dinero.Dinero,
    amountsOwed: IAmountAllocation[]
  ): IAmountAllocation[] {
    const simpleProportions = amountsOwed.map((owed) =>
      Money.amount(owed.amount)
    );
    const allocatedAmounts = Money.allocate(amount, simpleProportions);
    const allocations = zip(amountsOwed, allocatedAmounts).map(
      ([owed, allocatedAmount]) => {
        const allocation = AmountAllocation.create(
          owed?.allocatedTo,
          allocatedAmount
        );
        return owed
          ? AmountAllocation.clamp(allocation, Money.amount(owed.amount))
          : allocation;
      }
    );

    const remainder = amount.subtract(AmountAllocation.sum(allocations));
    if (Money.amount(remainder) === 0) {
      return allocations;
    }

    const remainingOwed = AmountAllocation.subtract(amountsOwed, allocations);
    const totalOwed = AmountAllocation.sum(remainingOwed);

    if (Money.amount(totalOwed) > 0) {
      const remainderAllocations = this.allocateBasedOnOwed(
        remainder,
        remainingOwed
      );
      return AmountAllocation.add(allocations, remainderAllocations);
    }

    const unallocated = AmountAllocation.create(
      SpecialTransactionAllocationTarget.Unallocated,
      remainder
    );
    return AmountAllocation.add(allocations, [unallocated]);
  }

  static getRemainingAmount(
    amount: Dinero.Dinero,
    allocations: IAmountAllocation[]
  ): Dinero.Dinero {
    const allocatedAmount = AmountAllocation.sum(allocations);
    return amount.subtract(allocatedAmount);
  }

  static getInvoicedAmounts(invoice: IInvoice): IAmountAllocation[] {
    const practitionerAmounts = Invoice.getPractitionerProportionsOnInvoice(
      invoice,
      false
    ).map((proportion) =>
      AmountAllocation.create(proportion.practitioner.ref, proportion.amount)
    );

    const practitionerTotal = AmountAllocation.sum(practitionerAmounts);
    const unallocated = AmountAllocation.create(
      SpecialTransactionAllocationTarget.Unallocated,
      Money.from(Invoice.total(invoice)).subtract(practitionerTotal)
    );

    return AmountAllocation.add(practitionerAmounts, [unallocated]);
  }

  static getAllocationsSummaries(
    invoiceAllocations: IInvoiceTransactionAllocations[]
  ): IAllocationSummary[] {
    const allAllocations = invoiceAllocations
      .map((invoiceAllocation) => invoiceAllocation.allocations)
      .flat();

    return allAllocations.reduce(
      (acc: IAllocationSummary[], allocation: ITransactionAllocation) => {
        const existingAllocation = acc.find((existing) =>
          AllocationTarget.isSame(existing.allocatedTo, allocation.allocatedTo)
        );
        if (existingAllocation) {
          existingAllocation.allocatedAmount += allocation.allocatedAmount;
          return acc;
        }
        const allocationSummary: IAllocationSummary = {
          allocatedTo: allocation.allocatedTo,
          allocatedAmount: allocation.allocatedAmount,
        };
        return [...acc, allocationSummary];
      },
      []
    );
  }

  static toTransactionAllocations(
    allocations: IAmountAllocation[],
    transactionAmount: Dinero.Dinero
  ): ITransactionAllocation[] {
    const safeTransactionAmount = Money.amount(transactionAmount);
    const cleanAllocations = AmountAllocation.sort(
      AmountAllocation.omitEmpty(allocations)
    );
    return cleanAllocations.map((allocation) => ({
      allocatedTo: allocation.allocatedTo,
      allocatedAmount: Money.amount(allocation.amount),
      allocatedProportion:
        Money.amount(allocation.amount) / safeTransactionAmount,
    }));
  }

  static toAmountAllocations(
    allocations: ITransactionAllocation[]
  ): IAmountAllocation[] {
    return allocations.map((allocation) =>
      AmountAllocation.create(
        allocation.allocatedTo,
        allocation.allocatedAmount
      )
    );
  }

  static allocateTransactionToLineItems(
    invoice: IInvoice,
    transactionAllocation: IAmountAllocation,
    previousTransactionAllocations: IAmountAllocation[]
  ): ILineItemAllocation[] {
    const previousAllocations = this.allocateTransactionsToLineItems(
      invoice,
      previousTransactionAllocations
    );
    return TransactionAllocation.allocateToLineItems(
      invoice,
      transactionAllocation,
      previousAllocations
    );
  }

  static allocateTransactionsToLineItems(
    invoice: IInvoice,
    transactionAllocations: IAmountAllocation[]
  ): ILineItemAllocation[] {
    return transactionAllocations.reduce(
      (acc: ILineItemAllocation[], allocation) => {
        const result = this.allocateToLineItems(invoice, allocation, acc);
        return [...acc, ...result];
      },
      []
    );
  }

  static allocateToLineItems(
    invoice: IInvoice,
    rootAllocation: IAmountAllocation,
    alreadyAllocated: ILineItemAllocation[]
  ): ILineItemAllocation[] {
    const lineItems = this.getAllocatableLineItems(invoice).filter((item) =>
      AllocationTarget.isSame(item.allocatedTo, rootAllocation.allocatedTo)
    );
    const owed = this.getLineItemsOwed(lineItems, alreadyAllocated);
    const allocatable = owed.length ? owed : lineItems;
    const rawAllocations = TransactionAllocation.allocateBasedOnOwed(
      rootAllocation.amount,
      allocatable
    );
    const allocations = zip(allocatable, rawAllocations).map(
      ([item, rawAllocation]) => {
        if (!item || !rawAllocation) {
          return;
        }
        return {
          ...rawAllocation,
          lineItem: item.lineItem,
          lineItemParent: item.lineItemParent,
        };
      }
    );
    return compact(allocations);
  }

  static getAllocatableLineItems(invoice: IInvoice): ILineItemAllocation[] {
    return invoice.items
      .map((lineItem) => {
        const staffer = Invoice.getLineItemAllocatedTo(lineItem, false);
        const allocatedTo =
          staffer?.ref ?? SpecialTransactionAllocationTarget.Unallocated;

        if (isTreatmentLineItem(lineItem)) {
          return lineItem.items.map((subLineItem) => ({
            allocatedTo,
            lineItem: subLineItem,
            lineItemParent: lineItem,
            amount: Money.from(subLineItem.amount).multiply(
              subLineItem.quantity
            ),
          }));
        }

        const amount = Money.from(lineItem.amount).multiply(lineItem.quantity);
        return [{ lineItem, allocatedTo, amount }];
      })
      .flat()
      .filter((allocation) => !allocation.amount.isZero());
  }

  static getLineItemsOwed(
    invoicedLineItems: ILineItemAllocation[],
    alreadyAllocated: ILineItemAllocation[]
  ): ILineItemAllocation[] {
    return invoicedLineItems
      .map((invoicedLineItem) => {
        const existingLineItemAllocations = alreadyAllocated.filter(
          (allocation) =>
            allocation.lineItem?.uid === invoicedLineItem.lineItem?.uid
        );
        const preAllocatedAmount = AmountAllocation.sum(
          existingLineItemAllocations
        );

        return {
          lineItem: invoicedLineItem.lineItem,
          lineItemParent: invoicedLineItem.lineItemParent,
          allocatedTo: invoicedLineItem.allocatedTo,
          amount: Money.from(invoicedLineItem.amount).subtract(
            preAllocatedAmount
          ),
        };
      })
      .filter((lineItem) => !lineItem.amount.isZero());
  }

  static haveAllocationsChanged(
    invoiceBefore?: WithRef<IInvoice>,
    invoiceAfter?: WithRef<IInvoice>
  ): boolean {
    const before = invoiceBefore ? this.getInvoicedAmounts(invoiceBefore) : [];
    const after = invoiceAfter ? this.getInvoicedAmounts(invoiceAfter) : [];

    const targets = uniqWith(
      [before, after].flat().map((allocation) => allocation.allocatedTo),
      AllocationTarget.isSame
    );

    return targets.some((target) => {
      const allocationsBefore = before.filter((allocation) =>
        AllocationTarget.isSame(allocation.allocatedTo, target)
      );
      const allocationsAfter = after.filter((allocation) =>
        AllocationTarget.isSame(allocation.allocatedTo, target)
      );
      const amountBefore = AmountAllocation.sum(allocationsBefore);
      const amountAfter = AmountAllocation.sum(allocationsAfter);
      return !amountBefore.equalsTo(amountAfter);
    });
  }
}
