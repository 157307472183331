<pr-chart-view-toolbar [showHistorySelector]="showHistorySelector">
  <pr-chart-filters-selector (selectionChange)="setFilters($event)" />

  <pr-chart-clear-selection-button (clearSelection)="clearSelectedSurfaces()" />
</pr-chart-view-toolbar>

<pr-clinical-chart
  [stacked]="'sm' | map : media.isActive : media"
  [chartedItems]="filteredChartedItems$ | async"
  [disabled]="(canEdit$ | async) === false"
/>

<div class="flex basis-1/2 gap-4">
  <div class="flex flex-1 flex-col gap-4">
    <pr-conditions-quick-chart
      *ngIf="canEdit$ | async"
      (chartableAdded)="addChartable($event)"
    />

    <pr-conditions-search
      *ngIf="canEdit$ | async"
      [selectedSurfaces]="selectedSurfaces$ | async"
      (chartableAdded)="addChartable($event)"
    />

    <pr-charted-conditions
      [selectedSurfaces]="selectedSurfaces$ | async"
      [disabled]="(canEdit$ | async) === false"
    />
  </div>

  <div class="flex min-w-0 flex-1 flex-col gap-4">
    <pr-treatments-quick-chart
      *ngIf="canEdit$ | async"
      (chartableAdded)="addChartable($event)"
    />

    <pr-treatments-search
      *ngIf="canEdit$ | async"
      [selectedSurfaces]="selectedSurfaces$ | async"
      (chartableAdded)="addChartable($event)"
    />

    <pr-charted-treatments
      [selectedSurfaces]="selectedSurfaces$ | async"
      [disabled]="(canEdit$ | async) === false"
    />
  </div>
</div>
