<div class="flex h-full w-full flex-col">
  <mat-toolbar class="flex flex-shrink-0" color="accent">
    Medicare - {{ data.title }}
  </mat-toolbar>

  <div class="flex flex-auto flex-col overflow-scroll">
    <mat-vertical-stepper #stepper>
      <mat-step [stepControl]="medicareCardForm.medicareForm">
        <ng-template matStepLabel>Medicare Card</ng-template>
        <pr-medicare-card-form
          #medicareCardForm="prMedicareCardForm"
          [medicareCard]="medicareCard"
          (medicareCardChange)="updateMedicareCard($event)"
        />
      </mat-step>

      <mat-step [stepControl]="referralForm.referralForm">
        <ng-template matStepLabel>Referral Information</ng-template>
        <pr-claim-referral-form
          #referralForm="prClaimReferralForm"
          [maxDate]="maxDate"
          (referralChange)="referralData = $event"
        />
      </mat-step>

      <mat-step [stepControl]="claimItems.itemsForm">
        <ng-template matStepLabel>Select Claim Items</ng-template>
        <pr-select-claim-items
          #claimItems="prSelectClaimItems"
          [isPartPaid]="data.isPartPaid"
          [(items)]="itemClaims"
        />
      </mat-step>
    </mat-vertical-stepper>
  </div>

  <pt-buttons-container
    class="border border-t border-solid border-slate-200 px-4"
  >
    <button
      mat-flat-button
      color="primary"
      [disabled]="stepper.selectedIndex === 0"
      (click)="stepper.previous()"
    >
      Previous
    </button>

    <button
      mat-flat-button
      color="primary"
      *ngIf="stepper.selectedIndex !== 2"
      (click)="stepper.next()"
    >
      Next
    </button>

    <button
      mat-flat-button
      color="primary"
      *ngIf="stepper.selectedIndex === 2"
      [disabled]="
        isInvalid(
          medicareCardForm.medicareForm,
          referralForm.referralForm,
          claimItems.itemsForm
        )
      "
      (click)="submit()"
    >
      Submit
    </button>
  </pt-buttons-container>
</div>
