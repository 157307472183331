@if (bloc.isInvoice$ | async) {
  <div *ngIf="practitionerClaims$ | async as practitionerClaims">
    <h2>Healthcare Claims</h2>
    <pr-healthcare-claims-list
      *ngIf="practitionerClaims.length; else noClaims"
      [invoice]="invoice$ | async"
      [practitionerClaims]="practitionerClaims"
    />
    <ng-template #noClaims>
      <p class="mat-caption">
        No healthcare claims are available for this invoice.
      </p>
    </ng-template>
  </div>
}

<div>
  <h2>Transactions</h2>
  <ng-container *ngIf="bloc.canAddTransactions$ | async">
    <pr-add-transaction [invoice]="invoice$ | async" />
  </ng-container>

  <ng-container *ngIf="transactions$ | async as transactions">
    <div class="transactions" *ngIf="transactions.length">
      <pr-transactions-display
        [invoice]="invoice$ | async"
        [transactions]="transactions"
      />
    </div>
  </ng-container>
</div>
