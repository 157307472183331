import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { filterUndefined } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IDCChartBuilder } from '../chart-builders/dc-chart-builders/dc-chart-builder';
import { type IDynamicChartData } from '../chart-builders/dynamic-chart-resolver';
import {
  buildDCChartDisplay,
  type IDCChartDisplay,
  loadDCDataResults$,
} from './dc-chart-display';

@Component({
    selector: 'pr-dynamic-dc-chart',
    templateUrl: './dynamic-dc-chart.component.html',
    styleUrls: ['./dynamic-dc-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DynamicDcChartComponent {
  chart$: Observable<IDCChartDisplay<unknown>>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    data: IDynamicChartData<IDCChartBuilder<unknown>>
  ) {
    this.chart$ = combineLatest([
      loadDCDataResults$(data.config, data.dataBuilder),
    ]).pipe(
      map(([results]) => {
        return buildDCChartDisplay(data.chartBuilder, data.config, results);
      }),
      filterUndefined()
    );
  }
}
