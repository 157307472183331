<h2>Notes</h2>
<div fxLayout="column" fxLayoutGap="8px">
  <ng-container
    *ngFor="let noteSummary of noteSummaries$ | ngrxPush; trackBy: trackByNote"
  >
    <a (click)="noteSummary.open()">
      {{ noteSummary.count }} {{ noteSummary.name }}
    </a>
  </ng-container>

  <h3>Pinned Notes</h3>
  <small class="mat-caption"
    >Pinned notes will be shown on the appointment card on the timeline</small
  >
  <div
    *ngFor="let note of pinnedNotes; trackBy: trackByPinnedNote"
    class="pinned-notes-container flex flex-col items-stretch gap-2"
  >
    <mat-form-field appearance="outline" fxFlex>
      <pr-content-editor
        fxFill
        placeholder="Add a pinned note"
        [menuEnabled]="false"
        [formControl]="note.controls.content"
        (keydown.meta.enter)="saveNote(note)"
      />
      <div matSuffix class="flex items-end">
        <button
          mat-flat-button
          class="mb-1 mr-1"
          color="primary"
          type="button"
          [disabled]="note.controls.content | map: isDisabled$ | async"
          (click)="saveNote(note)"
        >
          Save
        </button>
      </div>
    </mat-form-field>
  </div>
</div>
