import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ComponentLoader,
  filterRenderers,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  AppIntegrationFeature,
  IHealthcareClaim,
  IPatient,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BridgeCommandsService,
  IAvailableBridgeFeature,
} from '../../../bridge-commands.service';
import { IIntegrationActionData } from '../../../integration-action-data';
import { INTEGRATION_ACTION_RENDERERS } from '../../../integration-action-renderers';

export interface ITransactionActionsData<T> {
  transaction: WithRef<ITransaction<T>>;
  invoice: WithRef<IPatient>;
  claim?: IHealthcareClaim;
}

@Component({
  selector: 'pr-integration-display',
  templateUrl: './integration-display.component.html',
  styleUrls: ['./integration-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationDisplayComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  appIntegrationFeature$ = new ReplaySubject<AppIntegrationFeature>(1);
  trackByDefinition =
    TrackByFunctions.index<ComponentLoader<unknown, IIntegrationActionData>>();
  actionsComponents$: Observable<
    ComponentLoader<unknown, IIntegrationActionData>[]
  >;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set appIntegrationFeature(appIntegrationFeature: AppIntegrationFeature) {
    if (appIntegrationFeature) {
      this.appIntegrationFeature$.next(appIntegrationFeature);
    }
  }

  constructor(bridgeCommands: BridgeCommandsService) {
    this.actionsComponents$ = combineLatest([
      this.patient$,
      bridgeCommands.availableFeatures$,
      this.appIntegrationFeature$,
    ]).pipe(
      map(([patient, availableFeatures, appIntegrationFeature]) =>
        this._resolveActionsComponentLoader(
          patient,
          availableFeatures,
          appIntegrationFeature
        )
      )
    );
  }

  private _resolveActionsComponentLoader(
    patient: WithRef<IPatient>,
    availableFeatures: IAvailableBridgeFeature[],
    appIntegrationFeature: AppIntegrationFeature
  ): ComponentLoader<unknown, IIntegrationActionData>[] {
    const data: IIntegrationActionData = {
      patient,
      availableFeatures,
      appIntegrationFeature,
    };
    return filterRenderers(INTEGRATION_ACTION_RENDERERS, data).map(
      (renderer) => ({
        component: renderer.component,
        data,
      })
    );
  }
}
