import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  BaseTable,
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  ContactResourceType,
  type IBrand,
  type IContact,
  type IContactDetails,
  type IPrincipleMention,
  isLab,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { Brand, Contact, toMention } from '@principle-theorem/principle-core';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  patchDoc,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ContactFormDialogComponent,
  type IContactFormData,
} from '../contact-form-dialog/contact-form-dialog.component';

@Component({
    selector: 'pr-member-contact-list',
    templateUrl: './member-contact-list.component.html',
    styleUrls: ['./member-contact-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MemberContactListComponent
  extends BaseTable<IContact>
  implements OnDestroy, AfterViewInit
{
  private _onDestroy$ = new Subject<void>();
  contact$ = new ReplaySubject<WithRef<IContact>>(1);
  parentType: ContactResourceType = ContactResourceType.Contact;
  emptyState$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);
  @ViewChild(MatSort) declare sort: MatSort;
  @ViewChild(MatPaginator) declare paginator: MatPaginator;

  override displayColumns: string[] = [
    'name',
    'jobTitle',
    'phone',
    'mobileNumber',
    'email',
    'actions',
  ];

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _brandScope: CurrentBrandScope,
    private _dialogService: InteractionDialogsService
  ) {
    super();

    const contacts$ = combineLatest([
      this._brandScope.doc$.pipe(filterUndefined()),
      this.contact$,
    ]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([brand, contact]) => Brand.contactMembers$(brand, contact)),
      tap(() => this.loading$.next(false))
    );

    contacts$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((contacts: IContact[]) => {
        this.dataSource.data = contacts;
      });

    this.emptyState$ = combineLatest([contacts$, this.loading$]).pipe(
      map(([contacts, loading]) => !contacts.length && !loading)
    );
  }

  @Input()
  set contact(contact: WithRef<IContact>) {
    if (!contact) {
      return;
    }
    if (isLab(contact)) {
      this.parentType = ContactResourceType.Lab;
    }
    this.contact$.next(contact);
  }

  ngAfterViewInit(): void {
    this.initSortAndPagination();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addSubContact(): Promise<void> {
    const data: Partial<IContactDetails> | undefined =
      await this._openContactForm({
        title: `Create Member`,
        submitLabel: 'Create',
      });

    if (!data) {
      return;
    }
    await this._create(data);
  }

  async editSubContact(subContact: WithRef<IContact>): Promise<void> {
    const data: Partial<IContactDetails> | undefined =
      await this._openContactForm({
        title: `Edit ${subContact.name}`,
        submitLabel: 'Save',
        contact: subContact,
      });

    if (!data) {
      return;
    }
    await patchDoc(subContact.ref, data);
    this._snackBar.open('Contact Saved');
  }

  async deleteContact(subContact: WithRef<IContact>): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Member',
      prompt: 'Are you sure you want to delete this member?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    await deleteDoc(subContact.ref);
  }

  openPhone(subContact: WithRef<IContact>): void {
    this.setDialogData(subContact);
    this._dialogService.openPhone();
  }

  openSMS(subContact: WithRef<IContact>): void {
    this.setDialogData(subContact);
    this._dialogService.openSMS();
  }

  openEmail(subContact: WithRef<IContact>): void {
    this.setDialogData(subContact);
    this._dialogService.openEmail();
  }

  setDialogData(subContact: WithRef<IContact>): void {
    const contact: IPrincipleMention = toMention(
      subContact,
      MentionResourceType.Contact
    );
    contact.key = subContact.name;
    this._dialogService.dialogData = { contact };
  }

  private async _openContactForm(
    data: IContactFormData
  ): Promise<Partial<IContactDetails> | undefined> {
    const config: MatDialogConfig = DialogPresets.medium({
      data,
      autoFocus: true,
    });
    return this._dialog
      .open<
        ContactFormDialogComponent,
        IContactFormData,
        Partial<IContactDetails>
      >(ContactFormDialogComponent, config)
      .afterClosed()
      .toPromise();
  }

  private async _create(data: Partial<IContactDetails>): Promise<void> {
    const contact: IContact = Contact.init(data);
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    const parentContact: WithRef<IContact> = await snapshot(this.contact$);

    contact.parentRef = {
      ...toNamedDocument(parentContact),
      type: this.parentType,
    };
    await addDoc(Brand.contactCol(brand), contact);
    this._snackBar.open('Contact Created');
  }
}
