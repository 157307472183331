import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { HotkeysService } from '@principle-theorem/ng-principle';

@Component({
    selector: 'pr-portal-page',
    templateUrl: './portal-page.component.html',
    styleUrls: ['./portal-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PortalPageComponent implements OnDestroy {
  constructor(
    private _intercom: IntercomService,
    private _hotkeys: HotkeysService
  ) {
    this._intercom.hideIcon();
    this._hotkeys.disable();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
    this._hotkeys.enable();
  }
}
