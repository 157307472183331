import { type ITimePeriod } from '@principle-theorem/shared';

export enum TimeRangeTitle {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  NextWeek = 'Next Week',
  LastWeek = 'Last Week',
}

export interface ITimeRange {
  title: TimeRangeTitle;
  fromTo: ITimePeriod;
}
