import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString, isUndefined } from 'lodash';
import {
  ExactTransactionType,
  PatientTransactionsSourceEntity,
} from '../../../exact/source/entities/patient-transactions';
import { convertExactId } from '../../../exact/util/helpers';

interface ITransactionMetadata {
  type: ExactTransactionType;
  paymentType: string;
  openAmount: number;
  procedureCode: string;
  procedureDescription: string;
}

interface IExactTransactionResult {
  id: string;
  patient_id: string;
  amount: string;
  date: string;
  description: string;
  reference: string | null;
  user_code: string | null;
}

export interface IDentrixTransaction
  extends Omit<IExactTransactionResult, 'amount'>,
    Partial<ITransactionMetadata> {
  amount: number;
}

function isDentrixTransaction(data: unknown): data is IDentrixTransaction {
  return TypeGuard.interface<IDentrixTransaction>({
    id: isString,
    patient_id: isString,
    amount: isNumber,
    date: isString,
    description: isString,
    type: TypeGuard.undefinedOr(TypeGuard.enumValue(ExactTransactionType)),
    procedureCode: [isString, isUndefined],
    procedureDescription: [isString, isUndefined],
    paymentType: [isString, isUndefined],
    openAmount: [isNumber, isNull],
    reference: [isString, isNull],
    user_code: [isString, isNull],
  })(data);
}

export class DentrixTransactionSourceEntity extends PatientTransactionsSourceEntity {
  override verifySourceFn = isDentrixTransaction;
  override transformDataFn = flow([transformTransactionResults]);
}

function transformTransactionResults(
  rows: IExactTransactionResult[]
): IDentrixTransaction[] {
  return rows.map((row) => ({
    ...row,
    id: convertExactId(row.id),
    patient_id: convertExactId(row.patient_id),
    amount: parseFloat(row.amount),
    date: row.date,
    description: row.description,
    ...splitDescription(row.description),
  }));
}

function splitDescription(description: string): Partial<ITransactionMetadata> {
  const parts = description.split('\r\n');
  const typeDescription = parts.find((item) => item.includes('Type'));
  const paymentDescription = parts.find((item) => item.includes('Particulars'));
  const openDescription = parts.find((item) => item.includes('Open'));
  const type = typeDescription
    ? determineType(typeDescription.split(':')[1].trim().toLowerCase())
    : undefined;
  const procedure = parts
    .find((item) => item.includes('Procedure:'))
    ?.split(':')[1];
  const procedureCode = procedure?.split(',')[0]?.trim();
  const procedureDescription = procedure?.split(',')[1]?.trim();

  return {
    type,
    procedureCode,
    procedureDescription,
    paymentType: paymentDescription
      ? paymentDescription.split(':')[1].trim().toLowerCase()
      : undefined,
    openAmount: openDescription
      ? parseFloat(openDescription.split(':')[1].trim())
      : 0,
  };
}

function determineType(type: string): ExactTransactionType | undefined {
  if (type.includes(ExactTransactionType.Invoice)) {
    return ExactTransactionType.Invoice;
  }
  if (type.includes(ExactTransactionType.Adjustment)) {
    return ExactTransactionType.Adjustment;
  }
  if (type.includes(ExactTransactionType.Payment)) {
    return ExactTransactionType.Payment;
  }
  if (type.includes('transfer from')) {
    return ExactTransactionType.TransferFrom;
  }
  if (type.includes('transfer to')) {
    return ExactTransactionType.TransferTo;
  }
  if (type.includes('write off')) {
    return ExactTransactionType.WriteOff;
  }
  if (type.includes(ExactTransactionType.Procedure)) {
    return ExactTransactionType.Procedure;
  }
  return undefined;
}
