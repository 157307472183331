import {
  BridgeDeviceCollection,
  BridgeDeviceStatus,
  IBridgeDevice,
  IBridgeDeviceFeature,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  DocumentReference,
  Firestore,
  IAttachment,
  IReffable,
  WithRef,
  subCollection,
} from '@principle-theorem/shared';
import { FirebaseApp } from 'firebase/app';
import { DatabaseReference, getDatabase, ref } from 'firebase/database';
import { BridgeDeviceFeature } from './bridge-device-feature';
import { IBridgeIntegration } from '@principle-theorem/principle-bridge-core';

export class BridgeDevice {
  static init(overrides: AtLeast<IBridgeDevice, 'name'>): IBridgeDevice {
    return {
      features: [],
      status: BridgeDeviceStatus.Inactive,
      deleted: false,
      ...overrides,
    };
  }

  static practiceDoc(
    bridgeDevice: IReffable<IBridgeDevice>
  ): DocumentReference<IPractice> {
    return Firestore.getParentDocRef(bridgeDevice.ref.path);
  }

  static getDeviceDatabaseRef(
    organisationId: string,
    deviceId: string,
    suffix: string = '',
    appInstance?: FirebaseApp
  ): DatabaseReference {
    return ref(
      getDatabase(appInstance),
      this.getDeviceDatabasePath(organisationId, deviceId, suffix)
    );
  }

  static getDeviceDatabasePath(
    organisationId: string,
    deviceId: string,
    suffix: string = ''
  ): string {
    return `/organisations/${organisationId}/devices/${deviceId}${suffix}`;
  }

  static storagePath(bridgeDevice: IReffable<IBridgeDevice>): string {
    return bridgeDevice.ref.path;
  }

  static fileCol(
    bridgeDevice: IReffable<IBridgeDevice>
  ): CollectionReference<IAttachment> {
    return subCollection(bridgeDevice.ref, BridgeDeviceCollection.Files);
  }

  static hasFeatureEnabled(
    bridgeDevice: IBridgeDevice,
    feature: IBridgeDeviceFeature
  ): boolean {
    return bridgeDevice.features.some(
      (deviceFeature) =>
        BridgeDeviceFeature.isFeature(deviceFeature, feature.label) &&
        BridgeDeviceFeature.isEnabled(deviceFeature)
    );
  }

  static hasIntegrationEnabled(
    bridgeDevice: IBridgeDevice,
    integration: IBridgeIntegration
  ): boolean {
    return bridgeDevice.features.some(
      (deviceFeature) =>
        BridgeDeviceFeature.isFeature(deviceFeature, integration.label) &&
        BridgeDeviceFeature.isEnabled(deviceFeature)
    );
  }

  static async patchTwainDevice(
    bridgeDevice: WithRef<IBridgeDevice>,
    twainDeviceUid: string,
    changes: Partial<IBridgeDevice>
  ): Promise<void> {
    const updatedTwainDevices = bridgeDevice.twainSettings?.devices.map(
      (device) =>
        device.uid === twainDeviceUid ? { ...device, ...changes } : device
    );

    await Firestore.patchDoc(bridgeDevice.ref, {
      twainSettings: {
        syncing: bridgeDevice.twainSettings?.syncing ?? false,
        devices: updatedTwainDevices ?? [],
      },
    });
  }
}
