import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  BridgeDeviceStatus,
  IBridgeDevice,
  IHicapsConnectTerminal,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, doc$ } from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable } from 'rxjs';

export interface IHicapsSelectTerminalRequest {
  terminals: WithRef<IHicapsConnectTerminal>[];
}

export interface IHicapsSelectTerminalResponse {
  terminal: WithRef<IHicapsConnectTerminal>;
}

@Component({
  selector: 'pr-hicaps-connect-select-terminal-dialog',
  templateUrl: './hicaps-connect-select-terminal-dialog.component.html',
  styleUrls: ['./hicaps-connect-select-terminal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HicapsConnectSelectTerminalDialogComponent {
  trackByTerminal = TrackByFunctions.ref<WithRef<IHicapsConnectTerminal>>();

  constructor(
    private _dialogRef: MatDialogRef<
      HicapsConnectSelectTerminalDialogComponent,
      IHicapsSelectTerminalResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: IHicapsSelectTerminalRequest
  ) {}

  bridgeDevice$(
    terminal: WithRef<IHicapsConnectTerminal>
  ): Observable<WithRef<IBridgeDevice>> {
    return doc$(terminal.bridgeDevice);
  }

  isDisabled(bridgeDevice: WithRef<IBridgeDevice>): boolean {
    return bridgeDevice.status !== BridgeDeviceStatus.Active;
  }

  selectTerminal(event: MatSelectionListChange): void {
    const terminal = first(
      event.source.selectedOptions.selected.map(
        (option) => option.value as WithRef<IHicapsConnectTerminal>
      )
    );
    if (!terminal) {
      return;
    }
    this._dialogRef.close({ terminal });
  }
}
