import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type IRole } from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

type IRoleFormData = Pick<IRole, 'name' | 'permissions'>;

@Component({
    selector: 'pr-manage-role',
    templateUrl: './manage-role.component.html',
    styleUrls: ['./manage-role.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ManageRoleComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  roleForm: TypedFormGroup<IRoleFormData> = new TypedFormGroup<IRoleFormData>({
    name: new TypedFormControl<string>('', [Validators.required]),
    permissions: new TypedFormControl<string[]>([]),
  });
  role$ = new ReplaySubject<WithRef<IRole>>(1);

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {
    this.role$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((role) => this.roleForm.patchValue(role));
  }

  @Input()
  set role(role: WithRef<IRole>) {
    if (role) {
      this.role$.next(role);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    if (!this.roleForm.valid) {
      return;
    }
    const role = await snapshot(this.role$);
    const updatedRole = this.roleForm.getRawValue();
    await patchDoc(role.ref, updatedRole);
    this._snackBar.open(`${updatedRole.name} Role Saved`);
  }

  async delete(): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Role',
      prompt: 'Are you sure you want to delete this role?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    const role = await snapshot(this.role$);
    await deleteDoc(role.ref);
    await this._router.navigate(['../'], { relativeTo: this._route });
  }

  canSubmit(): boolean {
    return this.roleForm.valid && this.roleForm.touched;
  }
}
