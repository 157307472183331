import { createAction, props } from '@ngrx/store';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import {
  type IChartedCondition,
  type IChartedSurface,
} from '@principle-theorem/principle-core/interfaces';
import { type AtLeast, type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface IChartConditionAdd extends IEntityAction<ChartId> {
  condition: IChartedCondition;
}

export const addCondition = createAction(
  ChartActionTypes.AddCondition,
  props<SerialisedData<IChartConditionAdd>>()
);

export interface IChartConditionUpdate extends IEntityAction<ChartId> {
  condition: AtLeast<IChartedCondition, 'uuid'>;
}

export const updateCondition = createAction(
  ChartActionTypes.UpdateCondition,
  props<SerialisedData<IChartConditionUpdate>>()
);

export type IChartSurfaceRemove = Pick<IChartedSurface, 'uuid'> &
  IEntityAction<ChartId>;

export const removeCondition = createAction(
  ChartActionTypes.RemoveCondition,
  props<SerialisedData<IChartSurfaceRemove>>()
);
