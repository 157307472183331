import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  IConfirmGapOfferData,
  IConfirmGapOfferRequest,
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
} from '@principle-theorem/principle-core/interfaces';
import { SerialisedData, unserialise$ } from '@principle-theorem/shared';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientGapOfferService {
  constructor(private _functions: FirebaseFunctionsService) {}

  resolveGapOfferData$(
    tokenUid: string
  ): Observable<IConfirmGapOfferData | undefined> {
    const payload = {
      action: PatientAction.GetPatientGapOffer,
      data: { tokenUid },
    };
    const request = this._functions.call<
      PatientActionRequest<IConfirmGapOfferRequest>,
      SerialisedData<IConfirmGapOfferData>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return from(request).pipe(
      catchError(() => of(undefined)),
      unserialise$()
    );
  }
}
