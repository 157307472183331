import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum PatientsReportType {
  ByPatientVisitDate = 'byPatientVisitDate',
  ByReferralSource = 'byReferralSource',
}

interface IReportType {
  label: string;
  value: PatientsReportType;
}

@Component({
  selector: 'pr-patients-report-type-selector',
  templateUrl: './patients-report-type-selector.component.html',
  styleUrls: ['./patients-report-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientsReportTypeSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByReportType = TrackByFunctions.field<IReportType>('value');
  reportTypes: IReportType[] = [
    {
      label: 'By Visit Date',
      value: PatientsReportType.ByPatientVisitDate,
    },
    {
      label: 'By Referral Source',
      value: PatientsReportType.ByReferralSource,
    },
  ];
  reportTypeControl = new TypedFormControl<PatientsReportType>();
  @Output() typeSelected = new EventEmitter<PatientsReportType>();

  constructor() {
    validFormControlChanges$(this.reportTypeControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((reportType) => this.typeSelected.emit(reportType));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
