import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { convertExactId } from '../../util/helpers';
import { PatientSourceEntity } from './patient';

export const PATIENT_EXTERNAL_LINKS_SOURCE_TYPE = 'patientExternalLink';

export const PATIENT_EXTERNAL_LINKS_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient External Folder Links',
      description: ``,
      idPrefix: PATIENT_EXTERNAL_LINKS_SOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IExactPatientExternalLink {
  id: number;
  patient_id: string;
  external_link_identifier: string;
  external_link_type: string;
}

export interface IExactPatientExternalLinkFilters {
  patientId: string;
}

function isExactPatientExternalLink(
  item: unknown
): item is IExactPatientExternalLink {
  return TypeGuard.interface<IExactPatientExternalLink>({
    id: isNumber,
    patient_id: isString,
    external_link_identifier: isString,
    external_link_type: isString,
  })(item);
}

const EXTERNAL_LINKS_QUERY = `
WITH services AS (
  SELECT
    patientid::text AS patient_id,
    externallinkidentifier AS external_link_identifier,
    externallinktype AS external_link_type
  FROM
    convexternallink
) SELECT
  services.*,
  ROW_NUMBER() OVER (ORDER BY services.patient_id) AS id
FROM services
`;

export class PatientExternalLinksSourceEntity extends BaseSourceEntity<
  IExactPatientExternalLink,
  object,
  IExactPatientExternalLinkFilters
> {
  sourceEntity = PATIENT_EXTERNAL_LINKS_SOURCE_ENTITY;
  entityResourceType = PATIENT_EXTERNAL_LINKS_SOURCE_TYPE;
  sourceQuery = EXTERNAL_LINKS_QUERY;
  verifySourceFn = isExactPatientExternalLink;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([
    (rows: IExactPatientExternalLink[]) =>
      rows.map((row) => ({
        ...row,
        patient_id: convertExactId(row.patient_id),
      })),
  ]);

  translate(): Record<symbol, unknown> {
    return {};
  }

  getSourceRecordId(data: IExactPatientExternalLink): string {
    return data.id.toString();
  }

  getSourceLabel(record: IExactPatientExternalLink): string {
    return `${record.id} ${record.patient_id} ${record.external_link_identifier}`;
  }

  getFilterData(
    data: IExactPatientExternalLink
  ): IExactPatientExternalLinkFilters {
    return { patientId: data.patient_id };
  }
}
