@if (store.loading$ | async) {
  @if (loadingState) {
    <pr-list-skeleton />
  }
} @else {
  <div class="flex flex-col gap-4">
    @for (group of store.groups$ | async; track group.name) {
      <div>
        <div class="flex flex-row gap-4">
          <h3 class="!m-0 flex grow !font-bold">{{ group.name }}</h3>
        </div>
        <div class="flex flex-col">
          @for (form of group.forms; track form.ref.path) {
            <div
              matRipple
              class="flex cursor-pointer flex-row items-center gap-4 px-4 py-2 hover:bg-gray-100"
              (click)="formSelected.emit(form)"
            >
              <div class="flex w-28 grow">
                {{ form.updatedAt | moment | amDateFormat: dateTimeFormat }}
              </div>
              <pr-form-status [status]="form.status" />
            </div>
          }
        </div>
      </div>
    } @empty {
      <div class="flex flex-col items-center justify-center gap-4">
        @if (imageEmptyState) {
          <pr-empty-state image="list" title="Forms" />
        } @else {
          <div class="text-xs opacity-50">No forms have been issued</div>
        }
      </div>
    }
  </div>
}
