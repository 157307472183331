import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type TypedAbstractControl } from '@principle-theorem/ng-shared';
import {
  Invoice,
  resolveClaimItems,
  toClaimableItems,
  toSingleClaimItems,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  type IADACodeClaim,
  type IBulkBillDialogResponse,
  type IHealthcareClaim,
  type IInvoice,
  type IMedicareCard,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, toMoment, type WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_NO_REFERRAL } from '../claim-referral-form/claim-referral-form';
import { lineItemToClaim } from '../select-claim-items/select-claim-items.component';
import { divideContributionAmountAcrossItems } from './part-paid-contribution';

export interface IBulkBillDialogData {
  title: string;
  isPartPaid: boolean;
  invoice: WithRef<IInvoice>;
  claim: IHealthcareClaim;
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-bulk-bill-dialog',
  templateUrl: './bulk-bill-dialog.component.html',
  styleUrls: ['./bulk-bill-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkBillDialogComponent {
  medicareCard: IMedicareCard;
  itemClaims: IADACodeClaim[] = [];
  referralData = DEFAULT_NO_REFERRAL;
  maxDate: moment.Moment;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBulkBillDialogData,
    private _dialogRef: MatDialogRef<
      BulkBillDialogComponent,
      IBulkBillDialogResponse
    >
  ) {
    this.maxDate = getMaxReferralDate(data.invoice);

    this.medicareCard = {
      number: '',
      subNumerate: '',
      ...data.patient.medicareCard,
    };
    void this._setClaimItems(data);
  }

  submit(): void {
    this._dialogRef.close({
      itemClaims: this.itemClaims,
      medicareCard: this.medicareCard,
      referralData: this.referralData,
    });
  }

  isInvalid(...forms: TypedAbstractControl<unknown>[]): boolean {
    return forms.some((form) => form.invalid);
  }

  updateMedicareCard(cardUpdates: IMedicareCard): IMedicareCard {
    return (this.medicareCard = cardUpdates);
  }

  private async _setClaimItems(data: IBulkBillDialogData): Promise<void> {
    this.itemClaims = await snapshot(this._getClaimItems$(data));
  }

  private _getClaimItems$(
    data: IBulkBillDialogData
  ): Observable<IADACodeClaim[]> {
    const items = toSingleClaimItems(
      toClaimableItems(resolveClaimItems(data.invoice, data.claim))
    ).map((item) => lineItemToClaim(item.serviceCode));
    if (!data.isPartPaid) {
      return of(items);
    }
    return Invoice.transactions$(data.invoice).pipe(
      map((transactions) =>
        new TransactionOperators(transactions).paidToDate()
      ),
      map((paidToDate) =>
        divideContributionAmountAcrossItems(items, paidToDate)
      )
    );
  }
}

export function getMaxReferralDate(invoice: WithRef<IInvoice>): moment.Moment {
  const today = moment().startOf('day');
  const serviceDate = toMoment(invoice.createdAt);
  return serviceDate.isBefore(today) ? serviceDate : today;
}
