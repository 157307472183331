import { Injectable } from '@angular/core';
import { JSONSchemaObject, StorageMap } from '@ngx-pwa/local-storage';
import {
  type IUserWorkspace,
  shareReplayCold,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

const WORKSPACE_STORAGE_KEY = 'workspace';

const storageSchema: JSONSchemaObject = {
  type: 'object',
  properties: {
    workspace: {
      type: 'string',
    },
    uid: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private _workspaces$: ReplaySubject<IUserWorkspace[]> = new ReplaySubject(1);
  value$: Observable<IUserWorkspace | undefined>;
  workspace$: Observable<string | undefined>;
  workspaces$: Observable<IUserWorkspace[]>;

  constructor(private _storage: StorageMap) {
    this.value$ = this._storage
      .watch<IUserWorkspace>(WORKSPACE_STORAGE_KEY, storageSchema)
      .pipe(shareReplayCold());

    this.workspace$ = this.value$.pipe(
      map((value) => value?.workspace),
      shareReplayCold()
    );

    this.workspaces$ = this._workspaces$.asObservable().pipe(shareReplayCold());
  }

  async setWorkspace(workspace: IUserWorkspace): Promise<void> {
    await this._storage
      .set(WORKSPACE_STORAGE_KEY, workspace, storageSchema)
      .toPromise();
  }

  setWorkspaces(workspaces: IUserWorkspace[]): void {
    this._workspaces$.next(workspaces);
  }

  async reset(): Promise<void> {
    this.setWorkspaces([]);
    await this._storage.delete(WORKSPACE_STORAGE_KEY).toPromise();
  }
}
