import {
  CollectionGroup,
  HasFeeSchedules,
  IFeeSchedule,
  IPricingRule,
  IScopedServiceCode,
  IServiceCode,
  IServiceCodeFee,
  ITreatmentConfiguration,
  PricingRuleType,
  toScopedServiceCode,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  IReffable,
  WithRef,
  getDoc,
  initFirestoreModel,
  isSameRef,
  multiSwitchMap,
  query$,
  reduce2DArray,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference, doc } from '@principle-theorem/shared';
import { first, isNil, isNumber, sortBy } from 'lodash';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { PricingRule } from '../pricing-rules/pricing-rule';
import { PricingRuleItem } from '../pricing-rules/pricing-rule-item';
import { DEFAULT_SCHEDULE_ID } from './fee-schedule-collection';
import { TaxStrategy } from '@principle-theorem/accounting';

export class FeeSchedule {
  static init(
    overrides: AtLeast<IFeeSchedule, 'serviceCodeType'>
  ): IFeeSchedule {
    return {
      name: 'Fee Schedule',
      serviceCodes: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(parent: HasFeeSchedules): CollectionReference<IFeeSchedule> {
    return subCollection<IFeeSchedule>(
      parent.ref,
      CollectionGroup.FeeSchedules
    );
  }

  static all$(parent: HasFeeSchedules): Observable<WithRef<IFeeSchedule>[]> {
    return query$(undeletedQuery(this.col(parent))).pipe(
      map((feeSchedule) => sortBy(feeSchedule, ['name']))
    );
  }

  static resolveSchedules$(): OperatorFunction<
    HasFeeSchedules[],
    WithRef<IFeeSchedule>[]
  > {
    return (source$) =>
      source$.pipe(
        multiSwitchMap((parent) => FeeSchedule.all$(parent)),
        reduce2DArray()
      );
  }

  static async getPreferredOrDefault(
    reffable: HasFeeSchedules,
    preferredFeeSchedule?: IReffable<IFeeSchedule>
  ): Promise<WithRef<IFeeSchedule>> {
    const feeScheduleRef = preferredFeeSchedule
      ? preferredFeeSchedule.ref
      : doc(FeeSchedule.col(reffable), DEFAULT_SCHEDULE_ID);
    return getDoc(feeScheduleRef);
  }

  static getServiceFeeByCode(
    schedule: IFeeSchedule,
    code: IScopedServiceCode
  ): IPricingRule | undefined {
    const existing: IServiceCodeFee | undefined = this.findServiceFeeByCode(
      schedule,
      code
    );

    if (!existing) {
      return;
    }

    const firstRuleItem = first(existing.pricingRule.ruleItems);
    if (
      existing.pricingRule.type === PricingRuleType.Flat &&
      isNil(firstRuleItem?.price)
    ) {
      return;
    }

    return existing.pricingRule;
  }

  static upsertServiceFeeByCode(
    schedule: IFeeSchedule,
    serviceCode: IScopedServiceCode,
    pricingRule: IPricingRule
  ): void {
    const existing: IServiceCodeFee | undefined = this.findServiceFeeByCode(
      schedule,
      serviceCode
    );
    if (existing) {
      existing.pricingRule = pricingRule;
      return;
    }
    schedule.serviceCodes.push({
      serviceCode,
      pricingRule,
    });
  }

  static upsertTreatmentFee(
    schedule: IFeeSchedule,
    treatment: IReffable<ITreatmentConfiguration>,
    price?: number,
    taxStrategy?: TaxStrategy.GSTApplicable | TaxStrategy.GSTFree
  ): void {
    const existing = schedule.treatmentFees?.find((fee) =>
      isSameRef(fee.treatment, treatment)
    );
    if (existing) {
      if (taxStrategy) {
        existing.taxStrategy = taxStrategy;
      }
      if (price) {
        existing.price = price;
      }
      return;
    }
    if (!schedule.treatmentFees) {
      schedule.treatmentFees = [];
    }
    schedule.treatmentFees.push({
      treatment: treatment.ref,
      price: price ?? 0,
      taxStrategy: taxStrategy ?? TaxStrategy.GSTFree,
    });
  }

  static removeServiceFeeByCode(
    schedule: IFeeSchedule,
    code: IScopedServiceCode
  ): void {
    schedule.serviceCodes = schedule.serviceCodes.filter(
      (serviceCode) =>
        serviceCode.serviceCode.code !== code.code ||
        serviceCode.serviceCode.type !== code.type
    );
  }

  static findServiceFeeByCode(
    schedule: IFeeSchedule,
    code: IScopedServiceCode
  ): IServiceCodeFee | undefined {
    return schedule.serviceCodes.find(
      (serviceCode) =>
        serviceCode.serviceCode.code === code.code &&
        serviceCode.serviceCode.type === code.type
    );
  }

  static getTreatmentFee(
    schedule: IFeeSchedule,
    treatment: DocumentReference<ITreatmentConfiguration>
  ): number {
    const foundPrice = schedule.treatmentFees?.find((fee) =>
      isSameRef(fee.treatment, treatment)
    )?.price;
    return foundPrice ?? 0;
  }

  static getTreatmentTaxStrategy(
    schedule: IFeeSchedule,
    treatment: DocumentReference<ITreatmentConfiguration>
  ): TaxStrategy {
    const foundTaxStrategy = schedule.treatmentFees?.find((fee) =>
      isSameRef(fee.treatment, treatment)
    )?.taxStrategy;
    return foundTaxStrategy ?? TaxStrategy.GSTFree;
  }
}

export function serviceCodeToFee(
  serviceCode: IServiceCode,
  price?: number
): IServiceCodeFee {
  return {
    serviceCode: toScopedServiceCode(serviceCode),
    pricingRule: PricingRule.init({
      type: PricingRuleType.Flat,
      ruleItems: [
        PricingRuleItem.init({
          price: price
            ? price
            : isNumber(serviceCode.fee)
              ? serviceCode.fee
              : 0,
        }),
      ],
    }),
  };
}
