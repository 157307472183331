export interface IBaseMigrationItemCode {
  id: number | string;
  itemCode: string;
  description?: string;
}

export interface IBaseMigrationStaffer {
  id: number | string;
  name: string | null;
  providerNo?: string | null;
}

export interface IBaseMigrationAppointmentBook {
  id: number | string;
  description: string;
}
