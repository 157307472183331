import { randFullName } from '@ngneat/falso';
import { ChartView } from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  IStafferDimension,
} from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  BigQueryReffableMock,
  MockBigQueryDocRef,
  MockBigQueryNamedDocument,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import { PractitionerMock } from '@principle-theorem/principle-core';

const staffer = MockGenerator.generate(PractitionerMock);

export class StafferDimensionMock
  extends BigQueryFirestoreModelMock
  implements IStafferDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  user = MockGenerator.generate<
    BigQueryNamedDocumentMock,
    DimensionsCommon.IBigQueryNamedDocument
  >(BigQueryNamedDocumentMock);
  teams = [MockGenerator.generate(BigQueryNamedDocumentMock)];
  tasks = staffer.tasks;
  quickChartingConfiguration = {
    conditions: [
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
    ],
    treatments: [
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
    ],
    multiTreatments: [
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
      MockGenerator.generate(BigQueryReffableMock),
    ],
  };
  providerCommission = staffer.providerCommission;
  settings = {
    charting: {
      view: ChartView.Adult,
    },
  };
  providerDetails = staffer.providerDetails.map((providerData) => ({
    ...providerData,
    practiceRef: MockGenerator.generate(SerialisedDocumentReferenceMock),
  }));
}

export const MOCKED_STAFF = [
  `Dr ${randFullName()}`,
  `Dr ${randFullName()}`,
  `Dr ${randFullName()}`,
  `Dr ${randFullName()}`,
].map((name) =>
  MockGenerator.generate(StafferDimensionMock, {
    ref: MockBigQueryDocRef(name, 'staff'),
    user: MockBigQueryNamedDocument(name, 'users'),
  })
);

export function stafferDimensionNamedDoc(
  value: IStafferDimension
): DimensionsCommon.IBigQueryNamedDocument {
  return {
    name: value.user.name,
    ref: value.ref,
  };
}
