<mat-toolbar color="accent">
  Edit {{ data.interaction | map: getInteractionLabel : this }}
</mat-toolbar>

<mat-dialog-content>
  <pr-note-form
    #form
    [interaction]="data.interaction"
    (noteChange)="noteFormData$.next($event)"
  />
  @if (showScheduleEventReasonSelector$ | async) {
    @if (schedulingEvent$ | async; as schedulingEvent) {
      <pr-scheduling-event-reason-selector
        [formControl]="reasonControl"
        [reasons]="schedulingEvent | map: getReasons$ : this | async"
        [schedulingConditions]="schedulingEvent.schedulingConditions"
      />
    }
  }
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.noteForm.valid | map: isDisabled$ : this | async"
      (click)="submit()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
