import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-patient-details-display',
  templateUrl: './patient-details-display.component.html',
  styleUrls: ['./patient-details-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDetailsDisplayComponent {
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
