import {
  EventType,
  IAppointment,
  ICandidate,
  ICandidateCalendarEvent,
  IEvent,
  IGap,
  ILabJob,
  isEventable,
  isLabJob,
  IStaffer,
  ParticipantType,
  ResolvedAppointmentDependency,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  INamedDocument,
  toMoment,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Appointment } from '../appointment/appointment';
import { CandidateCalendarEvent } from '../event/calendar-event';
import { Event } from '../event/event';

export async function waitListCandidateToEvent(
  gap: IGap,
  waitlistCandidate: IWaitListCandidate,
  user: INamedDocument<IStaffer>
): Promise<ICandidateCalendarEvent> {
  const candidate: ICandidate = waitlistCandidate.candidate;
  const waitlistParticipant = await Appointment.patient(
    waitlistCandidate.appointment
  );
  return CandidateCalendarEvent.init({
    candidate: candidate,
    event: Event.init({
      from: candidate.offerTimeFrom,
      to: candidate.offerTimeTo,
      type: EventType.GapCandidate,
      practice: Appointment.practice(waitlistCandidate.appointment),
      participants: [
        ...gap.event.participants,
        {
          ...toNamedDocument(waitlistParticipant),
          type: ParticipantType.Patient,
        },
      ],
      creator: toNamedDocument(user),
    }),
  });
}

export interface IWaitListCandidate {
  candidate: ICandidate;
  appointment: WithRef<IAppointment>;
  productivity: number;
}

export class WaitListCandidate {
  static init(
    overrides: AtLeast<IWaitListCandidate, 'candidate' | 'appointment'>
  ): IWaitListCandidate {
    return {
      productivity: 1,
      ...overrides,
    };
  }

  static getWarnings$(
    appointment: WithRef<IAppointment>,
    event: IEvent
  ): Observable<string[]> {
    return Appointment.dependencies$(appointment).pipe(
      startWith([]),
      map((dependencies) => {
        return this.getDependencyWarnings(dependencies, event);
      })
    );
  }

  // TODO: Score should also take the waitlistItem preferred days of week when calculating
  static getMatchScore(
    appointment: WithRef<IAppointment>,
    event: IEvent
  ): number {
    const distanceWeight = 0.2;
    const durationWeight = 0.2;
    const practiceWeight = 0.3;
    const practitionerWeight = 0.3;

    let distanceRatio = 1;
    const durationRatio =
      Event.duration(event) / Appointment.duration(appointment);

    if (isEventable(appointment) && event) {
      distanceRatio = toMoment(appointment.event.from).diff(
        toMoment(event.from),
        'days'
      );
    }

    // Score will flatten out progressively
    let distanceScore: number = Math.log2(distanceRatio + 1);
    if (distanceScore > 1 || isNaN(distanceScore)) {
      distanceScore = 1;
    }

    // As the duration ratio drifts from the current duration
    // it will have an exponentially worse score
    let durationScore: number = durationRatio / Math.pow(durationRatio, 2);

    if (durationRatio < 1) {
      durationScore = durationRatio / Math.pow(1 + distanceRatio, 2);
    }

    let practiceScore = 1;
    if (
      isEventable(appointment) &&
      event.practice.ref.path !== appointment.event.practice.ref.path
    ) {
      practiceScore = 0;
    }

    let practitionerScore = 1;
    if (
      isEventable(appointment) &&
      appointment.practitioner &&
      event.organiser &&
      event.organiser.ref.path !== appointment.practitioner.ref.path
    ) {
      practitionerScore = 0;
    }

    const score: number =
      this.combineScoreAndWeight(durationScore, durationWeight) +
      this.combineScoreAndWeight(distanceScore, distanceWeight) +
      this.combineScoreAndWeight(practiceScore, practiceWeight) +
      this.combineScoreAndWeight(practitionerScore, practitionerWeight);
    return score;
  }

  static combineScoreAndWeight(score: number, weight: number): number {
    if (!score || !weight) {
      return 0;
    }
    return score * weight;
  }

  static getDependencyWarnings(
    dependencies: ResolvedAppointmentDependency[],
    event: IEvent
  ): string[] {
    const labJobWarnings: string[] = dependencies
      .filter((dependency) => isLabJob(dependency))
      .map((labJob) => this.getLabJobWarning(labJob, event));

    return [...labJobWarnings].filter((warning: string) => warning !== '');
  }

  static getLabJobWarning(labJob: ILabJob, event: IEvent): string {
    const labJobDue: Moment = labJob.dueDate
      ? toMoment(labJob.dueDate)
      : moment();
    const eventStart: Moment = toMoment(event.from);
    if (labJobDue.isAfter(eventStart)) {
      return `Required Lab Job is due before this appointment`;
    }
    return '';
  }
}
