<div class="drilldown-chart">
  <form
    [formGroup]="form"
    class="layout-padding"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center"
    fxLayoutGap.gt-sm="16px"
  >
    <mat-form-field>
      <mat-label>Measure</mat-label>
      <mat-select formControlName="mainMeasure">
        <mat-option
          *ngFor="let measure of measures; trackBy: trackByMeasure"
          [value]="measure"
          [disabled]="measure === form.controls.optionalMeasure.value"
        >
          {{ measure.metadata.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span fxHide fxShow.gt-sm class="mat-caption">vs</span>

    <mat-form-field>
      <mat-select
        placeholder="Select a measure"
        formControlName="optionalMeasure"
      >
        <mat-option>None</mat-option>
        <mat-option
          *ngFor="let measure of measures; trackBy: trackByMeasure"
          [value]="measure"
          [disabled]="measure === form.controls.mainMeasure.value"
        >
          {{ measure.metadata.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="layout-padding" *ngIf="chartBuilder">
    <pr-chart [chartBuilder]="chartBuilder" />
  </div>
</div>
