import { FirebaseOptions } from 'firebase/app';
import { type Observable, type SchedulerLike } from 'rxjs';

export class FirestoreScheduler {
  public static appName?: string;
  public static appType: 'client' | 'admin' = 'client';
  public static options: FirebaseOptions = {};
  public static scheduler?: SchedulerLike;
  public static stabiliser$: <T>(obs$: Observable<T>) => Observable<T>;

  static init(
    scheduler: SchedulerLike,
    stabiliser: <T>(obs$: Observable<T>) => Observable<T>,
    appName?: string,
    options?: FirebaseOptions
  ): void {
    FirestoreScheduler.setScheduler(scheduler);
    FirestoreScheduler.setStabiliser(stabiliser);
    if (appName) {
      FirestoreScheduler.setAppName(appName);
    }
    if (options) {
      FirestoreScheduler.setOptions(options);
    }
  }

  static setAppType(appType: 'client' | 'admin'): void {
    FirestoreScheduler.appType = appType;
  }

  static setScheduler(scheduler: SchedulerLike): void {
    FirestoreScheduler.scheduler = scheduler;
  }

  static setStabiliser(
    stabiliser: <T>(obs$: Observable<T>) => Observable<T>
  ): void {
    FirestoreScheduler.stabiliser$ = stabiliser;
  }

  static setAppName(name: string): void {
    FirestoreScheduler.appName = name;
  }

  static setOptions(options: FirebaseOptions): void {
    FirestoreScheduler.options = options;
  }
}
