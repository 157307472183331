import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartedItemConfiguration } from '@principle-theorem/principle-core';
import { type IChartedItemConfiguration } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-edit-condition',
  templateUrl: './edit-condition.component.html',
  styleUrls: ['./edit-condition.component.scss'],
})
export class EditConditionComponent {
  private _condition: IChartedItemConfiguration;

  constructor(
    private _dialogRef: MatDialogRef<EditConditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditConditionData
  ) {}

  setCurrentCondition(currentCondition: IChartedItemConfiguration): void {
    this._condition = ChartedItemConfiguration.init({
      display: currentCondition.display,
      clinicalNotes: currentCondition.clinicalNotes,
      name: currentCondition.name,
      availableSurfaces: currentCondition.availableSurfaces,
    });
  }

  save(): void {
    this._dialogRef.close({
      ...this.data.condition,
      ...this._condition,
    });
  }
}

export interface IEditConditionData {
  condition: IChartedItemConfiguration;
}
