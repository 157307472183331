import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { ConditionConfigEditComponent } from './condition-config/condition-config-edit/condition-config-edit.component';
import { ConditionConfigListComponent } from './condition-config/condition-config-list/condition-config-list.component';
import { ConditionConfigResolverService } from './condition-config/condition-config-resolver.service';
import { MultiTreatmentConfigEditComponent } from './multi-treatment-config/multi-treatment-config-edit/multi-treatment-config-edit.component';
import { MultiTreatmentConfigListComponent } from './multi-treatment-config/multi-treatment-config-list/multi-treatment-config-list.component';
import { MultiTreatmentConfigResolverService } from './multi-treatment-config/multi-treatment-config-resolver.service';
import { PagesModule } from './pages.module';
import { TreatmentConfigEditComponent } from './treatment-config/treatment-config-edit/treatment-config-edit.component';
import { TreatmentConfigListComponent } from './treatment-config/treatment-config-list/treatment-config-list.component';
import { TreatmentConfigResolverService } from './treatment-config/treatment-config-resolver.service';

const routes: Routes = [
  {
    path: 'treatments',
    component: TreatmentConfigListComponent,
  },
  {
    path: 'treatments/:uid',
    component: TreatmentConfigEditComponent,
    resolve: {
      treatmentConfig: TreatmentConfigResolverService,
    },
  },
  {
    path: 'multi-treatments',
    component: MultiTreatmentConfigListComponent,
  },
  {
    path: 'multi-treatments/:uid',
    component: MultiTreatmentConfigEditComponent,
    resolve: {
      multiTreatmentConfig: MultiTreatmentConfigResolverService,
    },
  },
  {
    path: 'conditions',
    component: ConditionConfigListComponent,
  },
  {
    path: 'conditions/:uid',
    component: ConditionConfigEditComponent,
    resolve: {
      conditionConfig: ConditionConfigResolverService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PagesModule],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
